import * as React from "react";
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={187} height={187} viewBox="0 0 187 187" fill="none" {...props}>
      <G clipPath="url(#clip0_767_12661)">
        <Path
          d="M77.62 168.996c4.49 1.43 9.606 2.236 15.05 2.236 5.446 0 10.561-.806 15.053-2.236-4.345-4.015-9.534-6.582-15.052-6.582-5.518 0-10.67 2.53-15.052 6.582z"
          fill="#C0CAD8"
        />
        <Path
          d="M168.076 104.883c0 36.594-34.174 55.257-76.36 55.257-42.184.019-76.321-18.7-76.321-55.257s34.173-73.535 76.359-73.535c42.185 0 76.322 36.942 76.322 73.535z"
          fill="#E4EAF1"
        />
        <Path
          d="M59.817 38.717v83.71c9.716 3.099 20.497 4.785 31.937 4.785 11.403 0 22.22-1.686 31.937-4.785v-83.71c-9.717-4.675-20.534-7.389-31.937-7.389-11.404.018-22.22 2.713-31.937 7.389z"
          fill="#1F71F4"
        />
        <Path
          d="M87.226 67.721H85.63l1.082-4.876 2.273-10.395.11-.569h-8.122l2.989-13.218h-4.804l11.202-7.315 7.7 7.315h-3.703l-1.155 5.28h10.688L87.226 67.72z"
          fill="#fff"
        />
        <Path
          d="M36.24 125.928c-9.863-12.211.807-52.856 14.117-61.143 17.875-11.146 70.584-9.753 87.323 2.989 12.136 9.24 20.68 49.298 11.293 59.84-18.773 20.992-95.114 20.112-112.733-1.686z"
          fill="#1B1B1D"
        />
        <Path
          d="M21.06 116.506l-8.764-2.236c-1.613-.404-2.786-2.164-2.786-4.162V95.423c0-2.017 1.173-3.758 2.823-4.162l8.763-2.236c2.2-.55 4.235 1.466 4.235 4.161v19.159c-.036 2.658-2.071 4.711-4.271 4.161z"
          fill="#C0CAD8"
        />
        <Path
          d="M16.092 115.331l-8.048-2.017c-1.504-.366-2.567-1.98-2.567-3.831V96.026c0-1.87 1.063-3.428 2.566-3.795l8.049-2.017c2.017-.513 3.868 1.357 3.868 3.795v17.509c0 2.493-1.888 4.326-3.868 3.813z"
          fill="#E4EAF1"
        />
        <Path
          d="M162.411 117.678l8.763-2.236c1.65-.404 2.824-2.164 2.824-4.162V96.595c0-2.017-1.174-3.759-2.824-4.162l-8.763-2.237c-2.2-.55-4.235 1.467-4.235 4.162v19.159c-.018 2.695 2.035 4.748 4.235 4.161z"
          fill="#C0CAD8"
        />
        <Path
          d="M167.416 116.541l8.049-2.017c1.503-.367 2.566-1.98 2.566-3.795V97.272c0-1.87-1.063-3.428-2.566-3.795l-8.049-2.017c-2.016-.513-3.868 1.357-3.868 3.795v17.509c-.018 2.42 1.852 4.29 3.868 3.777z"
          fill="#E4EAF1"
        />
        <Path
          d="M88.198 105.525c-2.714 9.881-12.449 13.933-22.33 11.22-9.882-2.714-16.189-11.165-13.476-21.047 2.714-9.882 12.925-15.694 22.807-12.98 9.882 2.713 15.712 12.906 12.999 22.807zM132.638 100.994c0 7.774-6.307 12.247-14.08 12.247-7.774 0-14.08-4.473-14.08-12.247 0-7.773 6.306-14.08 14.08-14.08 7.773 0 14.08 6.307 14.08 14.08z"
          fill="#fff"
        />
        <Path
          d="M4.762 16.074l30.25 5.152L24.929 41.85l25.208 15.474L9.803 47.02l10.084-20.643L4.762 16.074zM182.138 32.574l-13.696 21.744-12.173-6.564-9.753 15.18 6.948-24.548 12.173 6.563 16.501-12.375z"
          fill="#FFBB4D"
        />
        <Path
          d="M105.944 128.622c0 3.373-4.216 6.087-9.442 6.087-5.224 0-9.441-2.732-9.441-6.087 0-3.355 4.216-11.587 9.441-11.587 5.226 0 9.442 8.232 9.442 11.587z"
          fill="#6B3C41"
        />
        <Path
          d="M89.39 132.603c1.722 1.302 4.252 2.127 7.113 2.127 2.86 0 5.39-.843 7.113-2.127-.917-3.08-3.74-3.501-7.113-3.501-3.374 0-6.179.421-7.114 3.501z"
          fill="#F9595F"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_767_12661">
          <Path fill="#fff" transform="translate(4.762 16.074)" d="M0 0H177.377V155.156H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
