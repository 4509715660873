import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 18, height = 18, color = "#E11934" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 18 18" fill="none">
      <Path
        d="M9.0013 0.666992C4.40964 0.666992 0.667969 4.40866 0.667969 9.00033C0.667969 13.592 4.40964 17.3337 9.0013 17.3337C13.593 17.3337 17.3346 13.592 17.3346 9.00033C17.3346 4.40866 13.593 0.666992 9.0013 0.666992ZM12.268 9.62533H5.6013C5.25964 9.62533 4.9763 9.34199 4.9763 9.00033C4.9763 8.65866 5.25964 8.37533 5.6013 8.37533H12.268C12.6096 8.37533 12.893 8.65866 12.893 9.00033C12.893 9.34199 12.618 9.62533 12.268 9.62533Z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
