import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
const SvgComponent = ({ width = 24, height = 24, stroke = "#1F71F4" }) => (
  <Svg width={width} height={height} fill="none">
    <G clipPath="url(#a)">
      <Path
        stroke={stroke}
        strokeWidth={1.4}
        d="m7.948 10.146-.751-2.398a.448.448 0 0 1 .185-.511l1.42-.913a.897.897 0 0 0 .315-1.16L7.05 1.09A.897.897 0 0 0 5.624.852L4.42 2.027a3.139 3.139 0 0 0-.777 3.263l4.402 12.862c.248.726.93 1.213 1.697 1.213h.51m-2.304-9.219h6.803c.247 0 .448.2.448.448v1.071c0 .248-.2.449-.448.449H8.564m-.616-1.968.616 1.968m0 0 .782 2.496a1.794 1.794 0 0 0 1.711 1.257h8.412a.897.897 0 0 1 .782 1.336l-.7 1.246a1.794 1.794 0 0 1-1.563.916h-1.339m-6.398 0-1.995 3.358a.448.448 0 0 0 .386.677h9.744c.353 0 .568-.39.38-.688l-2.117-3.347m-6.398 0h6.398"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
