import { WebViewMessageEvent } from "react-native-webview";

enum DefinedMessageNames {
  AvatarUpload = "avatar-upload",
  ForwardToken = "forward-token",
  BackToLogin = "back-to-login",
}

type SuccessPayload<T> = {
  status: "success";
  data: T;
};

type ErrorPayload = {
  status: "error";
  error?: string;
};

type Payload<T> = SuccessPayload<T> | ErrorPayload;

type Messages =
  | {
      name: DefinedMessageNames.AvatarUpload;
      payload: Payload<{ avatarUrl: string }>;
    }
  | {
      name: DefinedMessageNames.ForwardToken;
      payload: Payload<{ token?: string; refreshToken?: string }>;
    }
  | {
      name: DefinedMessageNames.BackToLogin;
    };

const isAvatarUploadData = (
  data: any
): data is {
  type: "avatar-uploaded" | "avatar-upload-failed";
  payload?: string;
} => {
  return (
    data &&
    typeof data === "object" &&
    "type" in data &&
    ["avatar-uploaded", "avatar-upload-failed"].includes(data.type)
  );
};

const getMessageFromEvent = (event: WebViewMessageEvent): Messages | null => {
  try {
    const data = JSON.parse(event?.nativeEvent?.data);
    // because the shape of the avatar upload data differs from the other messages, we need to manually detect and map it into the standard message format
    if (isAvatarUploadData(data)) {
      return {
        name: DefinedMessageNames.AvatarUpload,
        payload: {
          status: data.type === "avatar-uploaded" ? "success" : "error",
          data: {
            avatarUrl: data.payload,
          },
        },
      };
    }

    // ensure that we will not process any shape that we don't expect
    if (![DefinedMessageNames.ForwardToken, DefinedMessageNames.BackToLogin].includes(data.name)) {
      console.log("unprocessable message: ", JSON.stringify(data, null, 2));
      throw new Error("unprocessable message");
    }

    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const webViewHelper = { getMessageFromEvent };
export default webViewHelper;
export { DefinedMessageNames };
