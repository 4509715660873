import { useCallback, useState } from "react";
import { request } from "utils/gql";

export const EXPENSE_EMPLOYEE_REGISTER = /* GraphQL */ `
  mutation MobileEmployeeRegister($object: ExpenseEmployeeRegisterInput!) {
    expenseEmployeeRegister(object: $object) {
      group_id
      invoice_email
      message
      success
      token
      user_id
    }
  }
`;

const useExpenseEmployeeRegister = () => {
  const [loading, setLoading] = useState(false);

  const registerEmployee = useCallback(async (object) => {
    setLoading(true);
    try {
      const result = await request(EXPENSE_EMPLOYEE_REGISTER, {
        object,
      });

      setLoading(false);
      return result;
    } catch (error) {
      setLoading(false);
      return error;
    }
  }, []);

  return {
    registerEmployee,
    loading,
  };
};

export default useExpenseEmployeeRegister;
