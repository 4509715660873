import * as React from "react";
import Svg, { Defs, G, Rect } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent({ width = 26, height = 26, color = "#E2E8F0" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 26 26" fill="none">
      <G>
        <Rect x={1} y={1} width={24} height={24} rx={6} fill="#fff" />
        <Rect x={1.5} y={1.5} width={23} height={23} rx={5.5} stroke={color} />
      </G>
      <Defs />
    </Svg>
  );
}

export default SvgComponent;
