import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileBokBookingFlightQuery, MobileBokBookingFlightQueryVariables } from "types";

const BOK_BOOKING_FLIGHT = gql`
  query MobileBokBookingFlight($input: BokBookingFlightInput!) {
    bokBookingFlight(input: $input) {
      orderCode
      price
      listBooking {
        airline {
          code
        }
        flightValue
        bookingCode
      }
    }
  }
`;

const useBokBookingFlight = () =>
  useLazyQuery<MobileBokBookingFlightQuery, MobileBokBookingFlightQueryVariables>(BOK_BOOKING_FLIGHT);

export default useBokBookingFlight;
