import { ComponentProps, ForwardedRef, forwardRef, useMemo } from "react";
import { GestureResponderEvent, StyleSheet, TextInput as RNTextInput, TouchableOpacity, View } from "react-native";
import { TextInput as PaperTextInput } from "react-native-paper";

import { Colors, Fonts, FontTypes } from "theme";

import RightTextInputType from "constants/RightTextInputType";
import { FieldProps } from "formik";
import { isString } from "lodash";
import { NumericFormat } from "react-number-format";
import AppText from "./AppText";

export type TextInputProps = Omit<ComponentProps<typeof PaperTextInput>, "error"> & {
  touched?: boolean;
  rightType?: string;
  error?: string | boolean;
  numberMask?: boolean;
};

const TextInputInner = forwardRef(
  (
    {
      theme,
      right,
      rightType,
      left,
      error,
      touched,
      style,
      multiline,
      disabled = false,
      numberMask,
      ...props
    }: TextInputProps,
    ref: ForwardedRef<RNTextInput>
  ) => {
    const hasError = !!error && touched;

    const calculatedRightIcon = useMemo(() => {
      if (!right) {
        return undefined;
      }
      if ((right as any).type === PaperTextInput.Icon) {
        return right;
      }

      return rightType === RightTextInputType.AFFIX ? (
        <PaperTextInput.Affix textStyle={styles.rightText} text={right as string} />
      ) : (
        <PaperTextInput.Icon
          style={styles.rightIcon}
          theme={{
            colors: {
              text: Colors.while,
            },
          }}
          name={() => right}
        />
      );
    }, [right, rightType]);

    return (
      <View style={style}>
        <PaperTextInput
          dense
          ref={ref}
          mode="outlined"
          multiline={multiline}
          style={[styles.container, disabled && styles.disableContainer]}
          outlineColor={Colors.grayscale10}
          activeOutlineColor={Colors.primary50}
          theme={{
            roundness: 8,
            colors: {
              error: Colors.alert50,
              background: Colors.while,
              text: Colors.grayscale80,
            },
            fonts: {
              regular: {
                fontFamily: FontTypes.medium,
              },
            },
            ...theme,
          }}
          right={calculatedRightIcon}
          left={
            left ? (
              <PaperTextInput.Icon
                style={styles.rightIcon}
                theme={{
                  colors: {
                    text: Colors.while,
                  },
                }}
                name={() => left}
              />
            ) : undefined
          }
          render={({ onChangeText, value, ...innerProps }) => {
            if (numberMask) {
              return (
                <NumericFormat
                  value={value}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  renderText={(val) => {
                    return (
                      <RNTextInput
                        {...innerProps}
                        value={val}
                        style={StyleSheet.flatten([
                          innerProps.style,
                          styles.innerInput,
                          multiline ? styles.multiline : null,
                        ])}
                        onChangeText={(text) => {
                          onChangeText(text.replace(/\./g, ""));
                        }}
                      />
                    );
                  }}
                />
              );
            }
            return (
              <RNTextInput
                {...innerProps}
                textAlign={disabled ? "left" : null}
                value={value}
                onChangeText={onChangeText}
                style={StyleSheet.flatten([innerProps.style, styles.innerInput, multiline ? styles.multiline : null])}
              />
            );
          }}
          error={hasError}
          {...props}
        />
        {hasError && isString(error) && (
          <View style={styles.errorContainer}>
            <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
              {error}
            </AppText>
          </View>
        )}
      </View>
    );
  }
);

const TextInputWithFormikField = forwardRef(
  (
    { field, form, keyboardType, numberMask, ...props }: FieldProps & Omit<TextInputProps, "value" | "onChangeText">,
    ref: ForwardedRef<RNTextInput>
  ) => {
    const isNumberMode = keyboardType === "numeric" && numberMask;

    return (
      <TextInputInner
        ref={ref}
        value={isNumberMode ? (field.value ? Number(field.value) : "") : field.value}
        onChangeText={(text) => {
          if (isNumberMode && isNaN(text as any)) {
            form.setFieldValue(field.name, 0 as any);
            return;
          }
          form.setFieldValue(field.name, isNumberMode ? (Number(text) as any) : text);
        }}
        keyboardType={keyboardType}
        numberMask={numberMask}
        error={form.errors[field.name] as string}
        touched={form.touched[field.name] as boolean}
        {...props}
      />
    );
  }
);

const TextInputViewOnly = forwardRef(
  (
    {
      onPress,
      right,
      left,
      ...props
    }: Omit<TextInputProps, "pointerEvents"> & {
      onPress: ((event: GestureResponderEvent) => void) | undefined;
    },
    ref: ForwardedRef<RNTextInput>
  ) => {
    const clonedRight = useMemo(() => {
      if (!right) {
        return undefined;
      }
      return (
        <PaperTextInput.Icon
          onPress={(right as any).props?.onPress ?? onPress}
          style={styles.rightIcon}
          theme={{
            colors: {
              text: Colors.while,
            },
          }}
          name={() => right}
        />
      );
    }, [right, onPress]);
    const clonedLeft = useMemo(() => {
      if (!left) {
        return undefined;
      }
      return (
        <TouchableOpacity onPress={onPress} hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}>
          {left}
        </TouchableOpacity>
      );
    }, [left, onPress]);
    return (
      <TouchableOpacity onPress={onPress}>
        <TextInputInner
          ref={ref}
          pointerEvents={"box-none"}
          editable={false}
          right={clonedRight}
          left={clonedLeft}
          {...props}
        />
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  innerInput: {
    margin: 0,
    zIndex: 1,
    height: 48,
    flexGrow: 1,
    fontSize: 16,
    paddingTop: 10,
    textAlign: "left",
    paddingBottom: 10,
    paddingHorizontal: 14,
    color: Colors.grayscale80,
    textAlignVertical: "top",
    fontFamily: FontTypes.medium,
  },
  multiline: {
    height: 100,
  },
  rightIcon: {
    paddingTop: 8,
  },
  rightText: {
    marginTop: 6,
  },
  container: {
    height: 48,
  },
  disableContainer: {
    backgroundColor: Colors.grayscale0,
  },
  errorContainer: { flexDirection: "row", alignItems: "center", marginTop: 6 },
  errorText: { marginLeft: 5 },
});

const TextInput = Object.assign(TextInputInner, { Formik: TextInputWithFormikField, ViewOnly: TextInputViewOnly });
export default TextInput;
