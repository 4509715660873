import { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { AppText, CurrencyText } from "components";
import { Colors, Fonts } from "theme";
import { CircleCheckedBox, ExpenseItemIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { ExpenseTransaction } from "screens/Card/TransactionDetailScreen/types";

interface ExpenseSelectItemProps {
  item: ExpenseTransaction;
  isChecked?: boolean;
  handleSelectExpense?: (item: ExpenseTransaction) => () => void;
}
const ExpenseSelectItem: FC<ExpenseSelectItemProps> = ({ isChecked, item, handleSelectExpense }) => {
  const { t } = useTranslation("app/screens/Card/TransactionDetailScreen");
  return (
    <>
      <TouchableOpacity style={styles.expenseItem} onPress={handleSelectExpense(item)}>
        <View style={styles.expenseBody}>
          <View style={styles.leftIcon}>
            {isChecked ? <CircleCheckedBox width={48} height={48} /> : <ExpenseItemIcon width={48} height={48} />}
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.titleContainer}>
              <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
                {item?.note ?? t("need_explanation")}
              </AppText>
              <CurrencyText style={Fonts.NumericN200}>{item?.totalAmountWithVat}</CurrencyText>
            </View>
            <View style={styles.pricingContainer}>
              <AppText style={Fonts.BodyMedium}>{item?.expenseRequest?.docSequence || "--"}</AppText>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                {dayjs(item?.expenseDate).format(CONSTANTS.FORMAT_DAY)}
              </AppText>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <View style={styles.divider} />
    </>
  );
};
export default ExpenseSelectItem;
const styles = StyleSheet.create({
  expenseItem: {
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: Colors.white,
  },
  leftIcon: { marginRight: 16 },
  contentContainer: {
    flex: 1,
  },
  titleContainer: { flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", flex: 1, gap: 10 },
  flex: { flex: 1 },
  pricingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  expenseBody: { flexDirection: "row", alignItems: "center", paddingVertical: 12 },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
