import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Rect width={20} height={20} rx={10} fill="#F0F6FF" />
      <Path
        d="M10 8.822l4.126-4.125 1.178 1.179L11.179 10l4.125 4.125-1.178 1.178L10 11.179l-4.125 4.125-1.179-1.178L8.822 10 4.697 5.876l1.179-1.179L10 8.822z"
        fill="#60A5FA"
      />
    </Svg>
  );
}

export default SvgComponent;
