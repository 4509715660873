import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M3.5 10.096V4.404c0-.256.087-.47.26-.644a.875.875 0 01.644-.26h5.692c.256 0 .47.087.644.26.173.173.26.388.26.644v5.692c0 .256-.087.47-.26.644a.874.874 0 01-.644.26H4.404a.874.874 0 01-.644-.26.874.874 0 01-.26-.644zM5 9.5h4.5V5H5v4.5zM3.5 19.596v-5.692c0-.256.087-.47.26-.644a.875.875 0 01.644-.26h5.692c.256 0 .47.087.644.26.173.173.26.388.26.644v5.692c0 .256-.087.47-.26.644a.875.875 0 01-.644.26H4.404a.875.875 0 01-.644-.26.875.875 0 01-.26-.644zM5 19h4.5v-4.5H5V19zm8-8.904V4.404c0-.256.087-.47.26-.644a.875.875 0 01.644-.26h5.692c.256 0 .47.087.644.26.173.173.26.388.26.644v5.692c0 .256-.087.47-.26.644a.874.874 0 01-.644.26h-5.692a.874.874 0 01-.644-.26.875.875 0 01-.26-.644zm1.5-.596H19V5h-4.5v4.5zm4.125 11v-1.875H20.5V20.5h-1.875zM13 14.875V13h1.875v1.875H13zm1.875 1.875v-1.875h1.875v1.875h-1.875zM13 18.625V16.75h1.875v1.875H13zm1.875 1.875v-1.875h1.875V20.5h-1.875zm1.875-1.875V16.75h1.875v1.875H16.75zm0-3.75V13h1.875v1.875H16.75zm1.875 1.875v-1.875H20.5v1.875h-1.875z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
