import { GestureResponderEvent, StyleProp, StyleSheet, ViewStyle } from "react-native";
import { Colors } from "theme";
import { FC } from "react";
import { IconCustom, TouchableOpacityCustom } from "components/index";

interface CreateCircleButtonProp {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  eventName?: string;
}

const CreateCircleButton: FC<CreateCircleButtonProp> = ({ onPress, style, eventName }) => {
  return (
    <TouchableOpacityCustom eventName={eventName} activeOpacity={0.8} onPress={onPress} style={[styles.button, style]}>
      <IconCustom name="add-circle" />
    </TouchableOpacityCustom>
  );
};

export default CreateCircleButton;
const styles = StyleSheet.create({
  button: {
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    backgroundColor: Colors.primary50,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: Colors.primary20,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 5,
  },
});
