import Svg, { Circle, G, Mask, Path } from "react-native-svg";

const SvgComponent = ({ props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={26} height={20} fill="none" {...props}>
    <Mask id="a" width={20} height={20} x={0} y={0} maskUnits="userSpaceOnUse">
      <Path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill="#37B24D"
        d="M8.817 11.789 6.881 9.853a.604.604 0 0 0-.435-.177.589.589 0 0 0-.443.177.598.598 0 0 0-.181.439c0 .172.06.318.181.439l2.287 2.287c.15.15.326.226.527.226s.377-.076.527-.226l4.637-4.636a.603.603 0 0 0 .177-.436.589.589 0 0 0-.177-.443.598.598 0 0 0-.44-.18.598.598 0 0 0-.439.18L8.817 11.79Zm1.184 6.128a7.713 7.713 0 0 1-3.087-.623A7.999 7.999 0 0 1 4.4 15.602a7.994 7.994 0 0 1-1.693-2.514 7.704 7.704 0 0 1-.624-3.086c0-1.095.208-2.125.624-3.088A7.997 7.997 0 0 1 4.398 4.4a7.993 7.993 0 0 1 2.514-1.693 7.705 7.705 0 0 1 3.087-.623c1.094 0 2.124.207 3.087.623.963.415 1.802.98 2.514 1.692a7.992 7.992 0 0 1 1.693 2.513c.416.963.623 1.992.623 3.087a7.71 7.71 0 0 1-.623 3.087 7.994 7.994 0 0 1-1.692 2.514 7.992 7.992 0 0 1-2.513 1.693 7.705 7.705 0 0 1-3.087.624ZM10 16.667c1.861 0 3.437-.646 4.73-1.938 1.29-1.291 1.937-2.868 1.937-4.729 0-1.861-.646-3.437-1.938-4.729-1.292-1.292-2.868-1.938-4.73-1.938-1.86 0-3.437.646-4.728 1.938-1.292 1.292-1.938 2.868-1.938 4.73 0 1.86.646 3.437 1.938 4.728 1.291 1.292 2.868 1.938 4.729 1.938Z"
      />
    </G>
    <Circle cx={20} cy={14} r={6} fill="#fff" />
    <Mask id="b" width={12} height={12} x={14} y={8} maskUnits="userSpaceOnUse">
      <Path fill="#D9D9D9" d="M14 8h12v12H14z" />
    </Mask>
    <G mask="url(#b)">
      <Path
        fill="#37B24D"
        d="M20.375 13.848v-1.973a.363.363 0 0 0-.108-.267A.363.363 0 0 0 20 11.5a.362.362 0 0 0-.267.108.363.363 0 0 0-.108.267v2.088a.462.462 0 0 0 .136.325L21.473 16c.07.07.156.105.261.106A.354.354 0 0 0 22 16a.359.359 0 0 0 .109-.264.359.359 0 0 0-.109-.263l-1.625-1.625Zm-.374 4.902a4.627 4.627 0 0 1-1.853-.374 4.8 4.8 0 0 1-1.508-1.015 4.795 4.795 0 0 1-1.016-1.508 4.622 4.622 0 0 1-.374-1.852c0-.657.125-1.275.374-1.853.25-.578.588-1.08 1.015-1.508.428-.428.93-.766 1.508-1.016a4.623 4.623 0 0 1 1.852-.374c.657 0 1.275.125 1.853.374.578.25 1.08.588 1.508 1.015.428.428.766.93 1.016 1.508s.374 1.195.374 1.852-.125 1.275-.374 1.853a4.8 4.8 0 0 1-1.015 1.508c-.428.428-.93.766-1.508 1.016a4.622 4.622 0 0 1-1.852.374Z"
      />
    </G>
  </Svg>
);

export default SvgComponent;
