import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { MobileMyTeamsQuery, MobileMyTeamsQueryVariables } from "types/generated";

const ORG_MY_TEAMS_QUERY = gql`
  fragment SingleTeam on OrgMyTeamsOutput {
    teamId
    code
    name
    nameEn
    isManager
    teamType {
      teamTypeId
      name
      nameEn
    }
  }
  query MobileMyTeams($companyId: UUID!, $employeeId: UUID!) {
    orgMyTeams(companyId: $companyId, employeeId: $employeeId) {
      ...SingleTeam
    }
  }
`;

export const useOrgMyTeamsQuery = (options?: QueryHookOptions<MobileMyTeamsQuery, MobileMyTeamsQueryVariables>) => {
  const { company, user } = useAuth();
  return useQuery<MobileMyTeamsQuery, MobileMyTeamsQueryVariables>(ORG_MY_TEAMS_QUERY, {
    variables: {
      companyId: company.company_id,
      employeeId: user.employee_id,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
