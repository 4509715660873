import { gql, useMutation } from "@apollo/client";

const DELETE_REPORT = gql`
  mutation ExpDeleteExpenseReport($expenseReportId: UUID!) {
    expDeleteExpenseReport(input: { expenseReportId: $expenseReportId }) {
      expenseReportId
      message
    }
  }
`;

const useExpenseDeleteReportMutation = () => {
  const [deleteExpenseReport, results] = useMutation(DELETE_REPORT);

  return {
    deleteExpenseReport,
    ...results,
  };
};

export default useExpenseDeleteReportMutation;
