export const Keys = {
  token: "token",
  refreshToken: "refreshToken",
  IS_FIRST_LAUNCH: "IS_FIRST_LAUNCH",
  USER_INFO: "USER_INFO",
  NUMBER_UNPROVIDED_INVOICES: "NUMBER_UNPROVIDED_INVOICES",
  ID_NOTIFICATION_UNPROVIDED_INVOICES: "ID_NOTIFICATION_UNPROVIDED_INVOICES",
  COMPANY_ID: "COMPANY_ID",
  USERNAME: "USERNAME",
  USER_PASSWORD: "USER_PASSWORD",
  USER_FULLNAME: "USER_FULLNAME",
  FLAG_TURN_ON_LOCAL_AUTH: "FLAG_TURN_ON_LOCAL_AUTH",
  FLAG_FIRST_INFORM_ABOUT_LOCAL_AUTH: "FLAG_FIRST_INFORM_ABOUT_LOCAL_AUTH",
  RECENT_CHOOSE_APPROVAL: "RECENT_CHOOSE_APPROVAL",
  FLAG_IS_VIEW_WALKTHROUGH: "FLAG_IS_VIEW_WALKTHROUGH",
  RECENT_COMPANY_TEAM: "RECENT_COMPANY_TEAM",
  FLAG_IS_SET_COMPANY_TEAM_DEFAULT: "FLAG_IS_SET_COMPANY_TEAM_DEFAULT",
  COMPANY_TEAM_DEFAULT: "COMPANY_TEAM_DEFAULT",
  READ_PROMOTION_IDS: "READ_PROMOTION_IDS",
  IS_VIEW_ALERT_REGISTER_CARD_SUCCESS: "IS_VIEW_ALERT_REGISTER_CARD_SUCCESS",
  IS_VIEW_ALERT_CARD_READY: "IS_VIEW_ALERT_CARD_READY",
  RECENT_CHOOSE_REPORT_EMPLOYEE: "RECENT_CHOOSE_REPORT_EMPLOYEE",
  RECENT_LOCATION_FROM: "RECENT_LOCATION_FROM",
  RECENT_LOCATION_TO: "RECENT_LOCATION_TO",
  RECENT_BUS_LOCATION_FROM: "RECENT_BUS_LOCATION_FROM",
  RECENT_BUS_LOCATION_TO: "RECENT_BUS_LOCATION_TO",
  RECENT_HOTEL_LOCATION: "RECENT_HOTEL_LOCATION",
  RECENT_AIRPORT_TO_CHOOSE: "RECENT_AIRPORT_TO_CHOOSE",
  RECENT_AIRPORT_FROM_CHOOSE: "RECENT_AIRPORT_FROM_CHOOSE",
  /*-- two key to show rating internal modal when user submit report/request success --*/
  COUNT_SUBMIT_SUCCESS: "COUNT_REPORT_SUBMIT_SUCCESS",
  LAST_SUBMIT_DATE_RATING_INTERNAL: "LAST_SUBMIT_DATE_RATING_INTERNAL",
  /*-- end --*/
  VIEWED_NEW_FEATURE_IDS: "VIEWED_NEW_FEATURE_IDS",
  /*-- guide flow --*/
  COMPLETED_GUIDE_IDS: "COMPLETED_GUIDE_IDS",
};
