import { AppText, IconCustom } from "components";
import React, { useCallback, useState } from "react";
import { DatePickerMode } from "constants/DateSelectConstant";
import { DatePickerModal } from "react-native-paper-dates";
import { CalendarDate } from "react-native-paper-dates/lib/typescript/Date/Calendar";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts, FontTypes } from "theme";
import { CONSTANTS } from "constants/constants";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatTime } from "utils";

const CalendarDatePicker = ({
  style,
  name,
  rules,
  control,
  error,
  datePickerModalProps,
  mode = "range",
  label,
  format = "DD/MM/YYYY",
  onConfirm = undefined,
  warning = "",
  setPosition = undefined,
}) => {
  const { t } = useTranslation("app/components/InputCustom/CalendarDatePicker");

  const [isVisibleDatePicker, setIsVisibleDatePicker] = useState(false);
  const [dates, setDates] = useState<{
    fromDate: Date | CalendarDate;
    toDate?: Date | CalendarDate;
  }>({
    fromDate: undefined,
    toDate: undefined,
  });

  const handleConfirm = useCallback(
    (date, onChange) => {
      if (mode === DatePickerMode.RANGE) {
        console.log(JSON.stringify(date, null, 2));
        setIsVisibleDatePicker(false);
        const fromDate = dayjs(date.startDate).startOf("day").toDate();
        const toDate = dayjs(date.endDate ?? date.startDate)
          .startOf("day")
          .toDate();
        setDates({
          fromDate,
          toDate,
        });
        onChange({
          fromDate,
          toDate,
        });
        onConfirm?.({
          fromDate,
          toDate,
        });
      } else {
        setIsVisibleDatePicker(false);
        const fromDate = dayjs(date?.date).startOf("day").toDate();
        setDates({
          fromDate: fromDate,
          toDate: fromDate,
        });
        onChange({
          fromDate: fromDate,
          toDate: fromDate,
        });
        onConfirm?.({
          fromDate: fromDate,
          toDate: fromDate,
        });
      }
    },
    [mode, onConfirm]
  );

  return (
    <View onLayout={(event) => setPosition?.(event.nativeEvent.layout.y)}>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          const dateValue = value as any;
          return (
            <>
              <View style={style}>
                <TouchableOpacity
                  onPress={() => setIsVisibleDatePicker(true)}
                  style={[styles.input, Boolean(error) && { borderColor: Colors.alert50 }]}
                >
                  {Boolean(dateValue) && <AppText style={styles.label}>{label}</AppText>}
                  <AppText
                    style={styles.categoryValue}
                    color={
                      error
                        ? Colors.alert50
                        : dateValue
                        ? CONSTANTS.COMMON.TEXT_INPUT_COLOR
                        : CONSTANTS.COMMON.PLACEHOLDER_COLOR
                    }
                    numberOfLines={1}
                  >
                    {dateValue
                      ? mode === DatePickerMode.RANGE
                        ? `${formatTime(dateValue.fromDate, format)} - ${formatTime(dateValue.toDate, format)}`
                        : formatTime(dateValue.fromDate, format)
                      : label}
                  </AppText>
                  <IconCustom name="calendar-month" />
                </TouchableOpacity>
                {error?.message ? (
                  <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                    {error?.message}
                  </AppText>
                ) : warning ? (
                  <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                    {warning}
                  </AppText>
                ) : null}
              </View>
              <DatePickerModal
                {...datePickerModalProps}
                locale="en"
                mode={mode}
                onDismiss={() => {
                  setIsVisibleDatePicker(false);
                }}
                visible={isVisibleDatePicker}
                {...(mode === DatePickerMode.RANGE
                  ? {
                      startDate: dates.fromDate || new Date(),
                      endDate: dates.toDate || new Date(),
                    }
                  : {
                      date: dates.fromDate || new Date(),
                    })}
                onConfirm={(date) => handleConfirm(date, onChange)}
                saveLabel={t("save")}
                label={mode === DatePickerMode.RANGE ? t("selected_range") : t("selected_date")}
                startLabel={t("from_date")}
                endLabel={t("to_date")}
              />
            </>
          );
        }}
      />
    </View>
  );
};
export default CalendarDatePicker;

const styles = StyleSheet.create({
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    flexDirection: "row",
  },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  categoryValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
});
