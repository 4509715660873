import * as React from "react";
import Svg, { Path, Rect, Ellipse, G, Mask, Defs, LinearGradient, Stop } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={273} height={123} fill="none" {...props}>
    <Path
      fill="#F0F6FF"
      fillRule="evenodd"
      d="M90.1 12.872C90.1 5.763 95.861 0 102.97 0h157.156C267.236 0 273 5.763 273 12.872c0 7.11-5.763 12.872-12.873 12.872H201.96a12.849 12.849 0 0 1 4.781 10.012c0 7.053-5.673 12.78-12.704 12.871h36.945c7.109 0 12.872 5.763 12.872 12.872 0 7.11-5.763 12.872-12.872 12.872H77.405c7.035-.086 12.712-5.815 12.712-12.871 0-7.109-5.763-12.872-12.872-12.872H36.66c-7.085-.028-12.82-5.78-12.82-12.872 0-7.11 5.762-12.872 12.871-12.872H94.88a12.847 12.847 0 0 1-4.78-10.012ZM0 87.245c0-7.11 5.763-12.872 12.872-12.872h183.664c.633 0 1.255.046 1.864.134h-7.059c-6.309 0-11.424 5.115-11.424 11.424 0 6.31 5.115 11.425 11.424 11.425h22.702c6.349.792 11.262 6.208 11.262 12.772 0 7.109-5.763 12.872-12.872 12.872H73.832c-7.11 0-12.873-5.763-12.873-12.872 0-4.043 1.865-7.651 4.78-10.011H12.872C5.763 100.117 0 94.354 0 87.245Z"
      clipRule="evenodd"
    />
    <Rect width={2.127} height={7.812} x={217.148} y={94} fill="#75A4FE" rx={1.064} />
    <Rect width={2.127} height={7.812} x={217.148} y={104.414} fill="#75A4FE" rx={1.064} />
    <Rect
      width={2.117}
      height={7.849}
      x={219.774}
      y={104.414}
      fill="#75A4FE"
      rx={1.059}
      transform="rotate(-90 219.774 104.414)"
    />
    <Rect
      width={2.117}
      height={7.849}
      x={208}
      y={104.414}
      fill="#75A4FE"
      rx={1.059}
      transform="rotate(-90 208 104.414)"
    />
    <Path
      fill="#fff"
      stroke="#1F71F4"
      strokeWidth={2.016}
      d="M218.488 50.72c0 2.6-2.118 4.714-4.74 4.714-2.622 0-4.74-2.114-4.74-4.713s2.118-4.713 4.74-4.713c2.622 0 4.74 2.114 4.74 4.713Z"
    />
    <Ellipse cx={59.255} cy={87.235} fill="#75A4FE" rx={4.255} ry={4.235} />
    <Rect width={2.127} height={7.411} x={96.613} y={5.293} fill="#75A4FE" rx={1.064} />
    <Rect width={2.127} height={7.411} x={96.745} y={15.258} fill="#75A4FE" rx={1.064} />
    <Rect
      width={2.117}
      height={7.446}
      x={98.739}
      y={14.82}
      fill="#75A4FE"
      rx={1.059}
      transform="rotate(-90 98.74 14.82)"
    />
    <Path fill="#75A4FE" d="M90.226 15.258a1.059 1.059 0 0 1 0-2.117h5.328a1.059 1.059 0 0 1 0 2.117h-5.328Z" />
    <G filter="url(#a)">
      <Rect width={41.887} height={61.639} x={92.933} y={37.801} fill="url(#b)" rx={3.279} />
    </G>
    <Rect width={32.352} height={1.362} x={97.697} y={52.445} fill="#DCEBFE" rx={0.681} />
    <Rect width={32.352} height={1.362} x={97.697} y={58.574} fill="#DCEBFE" rx={0.681} />
    <Rect width={32.352} height={1.362} x={97.697} y={64.703} fill="#DCEBFE" rx={0.681} />
    <Rect width={32.352} height={1.362} x={97.697} y={70.832} fill="#DCEBFE" rx={0.681} />
    <Rect width={32.352} height={1.362} x={97.697} y={84.797} fill="#DCEBFE" rx={0.681} />
    <G filter="url(#c)">
      <Rect width={41.887} height={61.639} x={139.11} y={37.801} fill="url(#d)" rx={3.279} />
    </G>
    <Rect width={32.352} height={1.362} x={143.875} y={52.445} fill="#DBE9FC" rx={0.681} />
    <Rect width={32.352} height={1.362} x={143.875} y={58.574} fill="#DBE9FC" rx={0.681} />
    <Rect width={32.352} height={1.362} x={143.875} y={64.703} fill="#DBE9FC" rx={0.681} />
    <Rect width={32.352} height={1.362} x={143.875} y={70.832} fill="#DBE9FC" rx={0.681} />
    <Rect width={32.352} height={1.362} x={143.875} y={84.797} fill="#DBE9FC" rx={0.681} />
    <G filter="url(#e)">
      <Rect width={46.314} height={68.109} x={113.948} y={33.715} fill="url(#f)" rx={3.279} />
    </G>
    <Rect width={30.308} height={4.427} x={122.122} y={49.719} fill="#DBE9FC" rx={1.517} />
    <Rect width={30.308} height={4.427} x={122.122} y={60.957} fill="#DBE9FC" rx={1.517} />
    <Rect width={30.308} height={4.427} x={122.122} y={72.195} fill="#DBE9FC" rx={1.517} />
    <Rect width={13.622} height={4.427} x={138.803} y={83.773} fill="#DBE9FC" rx={1.517} />
    <Path
      fill="#1F71F4"
      fillRule="evenodd"
      d="M158.046 44.953c6.109 0 11.067-4.958 11.067-11.068 0-6.109-4.958-11.067-11.067-11.067-6.11 0-11.068 4.958-11.068 11.067 0 6.11 4.958 11.068 11.068 11.068Zm0-16.601a1.11 1.11 0 0 1 1.106 1.106v4.427a1.11 1.11 0 0 1-1.106 1.107 1.11 1.11 0 0 1-1.107-1.107v-4.427a1.11 1.11 0 0 1 1.107-1.106Zm1.106 8.854h-2.213v2.213h2.213v-2.213Z"
      clipRule="evenodd"
    />
    <Mask id="g" fill="#fff">
      <Path
        fillRule="evenodd"
        d="M114.178 28a3.545 3.545 0 0 0-3.545 3.546v1.253H90.546A3.546 3.546 0 0 0 87 36.344v65.309a3.546 3.546 0 0 0 3.546 3.546h20.165a3.547 3.547 0 0 0 3.467 2.801h44.975c1.702 0 3.125-1.2 3.467-2.801h19.829a3.547 3.547 0 0 0 3.546-3.546V36.344a3.546 3.546 0 0 0-3.546-3.545h-19.751v-1.253A3.545 3.545 0 0 0 159.153 28h-44.975Z"
        clipRule="evenodd"
      />
    </Mask>
    <Path
      fill="#1F71F4"
      d="M110.633 32.799v2.952h2.952v-2.952h-2.952Zm.078 72.4 2.887-.617-.499-2.335h-2.388v2.952Zm51.909 0v-2.952h-2.388l-.499 2.335 2.887.617Zm.078-72.4h-2.952v2.952h2.952v-2.952Zm-49.113-1.253c0-.328.266-.594.593-.594v-5.904a6.498 6.498 0 0 0-6.497 6.498h5.904Zm0 1.253v-1.253h-5.904v1.253h5.904Zm-2.952-2.952H90.546v5.904h20.087v-5.904Zm-20.087 0a6.498 6.498 0 0 0-6.498 6.497h5.904c0-.327.266-.593.594-.593v-5.904Zm-6.498 6.497v65.309h5.904V36.344h-5.904Zm0 65.309a6.498 6.498 0 0 0 6.498 6.498v-5.904a.594.594 0 0 1-.594-.594h-5.904Zm6.498 6.498h20.165v-5.904H90.546v5.904Zm23.632-3.103a.595.595 0 0 1-.58-.466l-5.774 1.234a6.499 6.499 0 0 0 6.354 5.136v-5.904Zm44.975 0h-44.975v5.904h44.975v-5.904Zm.58-.466a.595.595 0 0 1-.58.466v5.904a6.499 6.499 0 0 0 6.354-5.136l-5.774-1.234Zm22.716-2.335H162.62v5.904h19.829v-5.904Zm.594-.594a.594.594 0 0 1-.594.594v5.904a6.498 6.498 0 0 0 6.498-6.498h-5.904Zm0-65.309v65.309h5.904V36.344h-5.904Zm-.594-.593c.328 0 .594.266.594.593h5.904a6.498 6.498 0 0 0-6.498-6.497v5.904Zm-19.751 0h19.751v-5.904h-19.751v5.904Zm-2.952-4.205v1.253h5.904v-1.253h-5.904Zm-.593-.594c.327 0 .593.266.593.593h5.904a6.498 6.498 0 0 0-6.497-6.497v5.904Zm-44.975 0h44.975v-5.904h-44.975v5.904Z"
      mask="url(#g)"
    />
    <Defs>
      <LinearGradient id="b" x1={114.338} x2={115.572} y1={99.901} y2={27.631} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={0.479} stopColor="#fff" />
        <Stop offset={1} stopColor="#FCFDFF" />
      </LinearGradient>
      <LinearGradient id="d" x1={160.516} x2={161.749} y1={99.901} y2={27.631} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={0.479} stopColor="#fff" />
        <Stop offset={1} stopColor="#FCFDFF" />
      </LinearGradient>
      <LinearGradient id="f" x1={137.616} x2={138.978} y1={102.334} y2={22.477} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={0.479} stopColor="#fff" />
        <Stop offset={1} stopColor="#FCFDFF" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default SvgComponent;
