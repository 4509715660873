// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  FlatList,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import sortBy from "lodash/sortBy";
import Modal from "react-native-modal";
import { WhiteSpace } from "@ant-design/react-native";
import { Avatar, Searchbar, Text } from "react-native-paper";
import { useTranslation } from "react-i18next";

import { Icon, Line, PaperInput } from "components";
import { HEIGHT_WINDOW } from "constants/Layout";
import ColorsDefault, { Colors } from "constants/Colors";
import Fonts from "constants/Fonts";
import LanguageStatus from "constants/LanguageStatus";
import TagIcon from "assets/images/svg/icons/TagIcon";
import MenuIcon from "assets/images/svg/icons/MenuIcon";
import useQueryExpenseCategory from "hooks/useQueryExpenseCategory";
import { debounce, filter } from "lodash";
import { removeAccents } from "utils/searchObject";

interface IExpenseCategoryChooser {
  showAll: any;
  isPaddingHorizontal?: boolean;
  selectedCategoryId?: string;
  onSelect: (item: ExpensePoliciesCategories) => void;
  isCustomInput: boolean;
  initialOpen?: boolean;
  callback?: () => void;
  error?: any;
  renderCategoryButton?: (onPressOpen: () => void) => ReactNode;
}

const ExpenseCategoryChooser: React.FC<IExpenseCategoryChooser> = ({
  selectedCategoryId,
  onSelect,
  renderCategoryButton,
  initialOpen = false,
  isPaddingHorizontal = false,
  callback,
  error,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { t, i18n } = useTranslation("layouts/ExpenseCategoryChooser");
  const isTouched = useRef(false);
  const flatListRef = useRef<FlatList>(null);
  const [keyword, setKeyword] = useState("");
  const { data: dataCategories } = useQueryExpenseCategory();

  const categoryOptions = useMemo(() => {
    if (!keyword) {
      return sortBy(dataCategories, (category) => category?.expense_category?.title_en === "Other");
    }

    const result = filter(dataCategories, (category) => {
      const keywordUnicode = removeAccents(keyword).toLowerCase();
      const titleUnicode = removeAccents(category?.expense_category?.title ?? "").toLowerCase();
      const titleEnUnicode = removeAccents(category?.expense_category?.title_en ?? "").toLowerCase();
      const codeUnicode = removeAccents(category?.expense_category?.title_en ?? "").toLocaleLowerCase();
      return (
        titleEnUnicode.includes(keywordUnicode) ||
        titleUnicode.includes(keywordUnicode) ||
        codeUnicode.includes(keywordUnicode)
      );
    });
    return sortBy(result, (category) => category?.expense_category?.title_en === "Other");
  }, [dataCategories, keyword]);

  const [isVisible, setIsVisible] = useState(false);

  const onPressOpen = useCallback(() => {
    setIsVisible(true);
  }, []);

  const onPressClose = useCallback(() => {
    setIsVisible(false);
    isTouched.current = true;
  }, []);

  const categorySelected: ExpenseCategories | undefined = useMemo(() => {
    if (selectedCategoryId) {
      const policy = categoryOptions.find((category) => category.expense_category_id === selectedCategoryId);
      return policy?.expense_category;
    }
    return undefined;
  }, [categoryOptions, selectedCategoryId]);

  const scrollToTop = useCallback(() => {
    if (flatListRef.current?.scrollToOffset) {
      flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
    }
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [categoryOptions, scrollToTop]);

  useLayoutEffect(() => {
    if (initialOpen) {
      requestAnimationFrame(() => {
        onPressOpen();
      });
    }
  }, [initialOpen, onPressOpen]);

  const debouncedSelect = useMemo(
    () =>
      debounce((item: any) => {
        onSelect(item);
      }, 500),
    [onSelect]
  );

  const renderItem = useCallback(
    ({ item }: { item: ExpensePoliciesCategories }) => {
      return (
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={() => {
            onPressClose();

            debouncedSelect(item);
          }}
          style={StyleSheet.flatten([
            styles.wrapperItem,
            {
              backgroundColor:
                categorySelected?.title === item?.expense_category?.title
                  ? ColorsDefault.grey9
                  : ColorsDefault.whiteBackground,
            },
          ])}
          key={item.expense_category_id}
        >
          <Avatar.Icon
            style={{ backgroundColor: "rgba(0, 134, 255, 0.1)" }}
            icon={() => <MenuIcon width={24} height={24} />}
            size={32}
          />

          <Text style={styles.textItem}>
            {i18n.language === LanguageStatus.EN && item?.expense_category?.title_en
              ? item.expense_category.title_en
              : item.expense_category.title}
            {item?.expense_category?.code && `(${item?.expense_category?.code})`}
          </Text>
        </TouchableOpacity>
      );
    },
    [categorySelected?.title, debouncedSelect, i18n.language, onPressClose]
  );

  const handleChangeText = useCallback((text) => {
    setKeyword(text);
  }, []);

  const categoryDefaultButton = useMemo(
    () => (
      <TouchableOpacity onPress={onPressOpen} style={isPaddingHorizontal ? styles.touchableOpacity : undefined}>
        <PaperInput
          pointerEvents="none"
          label={`${t("category")}`}
          editable={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          placeholder={t("placeHolder_choose")}
          value={
            i18n.language === LanguageStatus.EN && categorySelected?.title_en
              ? categorySelected?.title_en
              : categorySelected?.title
          }
          leftImage={<TagIcon />}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={error}
          touched={isTouched.current}
        />
      </TouchableOpacity>
    ),
    [categorySelected?.title, categorySelected?.title_en, error, i18n.language, isPaddingHorizontal, onPressOpen, t]
  );

  const renderListEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <WhiteSpace size="md" />
        <MenuIcon />
        <Text style={styles.emptyText}>{t("no_result")}</Text>
      </View>
    );
  };

  return (
    <View>
      {renderCategoryButton ? renderCategoryButton(onPressOpen) : categoryDefaultButton}
      <Modal
        isVisible={isVisible}
        style={styles.modalContainer}
        onBackdropPress={onPressClose}
        onModalHide={callback}
        customBackdrop={
          <TouchableWithoutFeedback onPress={onPressClose}>
            <View style={styles.backgroundOverlay} />
          </TouchableWithoutFeedback>
        }
      >
        <KeyboardAvoidingView behavior={"padding"} enabled={Platform.OS === "ios"}>
          <View style={styles.wrapperContent}>
            <View style={styles.headerStyle}>
              <View>
                <Icon
                  onPress={onPressClose}
                  type="MaterialCommunityIcons"
                  name="close"
                  size={24}
                  color={Colors.Ink400}
                />
              </View>
              <View style={styles.wrapperIcon}>
                <Text style={styles.titleStyle}>{t("choose")}</Text>
              </View>
            </View>
            <View style={styles.wrapperSearchInput}>
              <WhiteSpace size="sm" />
              <Searchbar
                placeholder={t("search")}
                autoCapitalize="none"
                returnKeyLabel={t("next")}
                onChangeText={handleChangeText}
                selectionColor={ColorsDefault.blue22}
                inputStyle={styles.inputStyle}
                style={styles.styleSearch}
                enablesReturnKeyAutomatically
                returnKeyType="search"
                value={keyword}
              />
            </View>
            <FlatList
              ref={flatListRef}
              keyboardShouldPersistTaps="always"
              keyboardDismissMode="on-drag"
              contentContainerStyle={{ paddingTop: 15, minHeight: 299 }}
              data={categoryOptions}
              renderItem={renderItem}
              ListEmptyComponent={renderListEmptyComponent}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => `${item.expense_category_id}`}
              ItemSeparatorComponent={() => <Line hasBackground={false} size="sm" />}
              ListFooterComponent={() => <Line hasBackground={false} size="xl" />}
            />
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  touchableOpacity: { flex: 1, backgroundColor: "white", paddingHorizontal: 15 },
  emptyContainer: {
    flex: 1,
    minHeight: 250,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  emptyText: {
    marginLeft: 8,
    alignSelf: "center",
  },
  styleSearch: {
    borderRadius: 10,
    borderColor: Colors.grey200,
    borderWidth: 1,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    height: 40,
  },
  inputStyle: { fontFamily: Fonts.fontFamily, fontSize: 14, color: Colors.Ink500 },
  wrapperSearchInput: {
    paddingHorizontal: 15,
    paddingVertical: 8,
    backgroundColor: ColorsDefault.whiteBackground,
  },
  modalContainer: {
    justifyContent: "flex-end",
    margin: 0,
  },
  wrapperIcon: {
    flex: 1,
    alignItems: "center",
  },
  wrapperContent: {
    justifyContent: "flex-end",
    margin: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: ColorsDefault.whiteBackground,
    maxHeight: HEIGHT_WINDOW / 2,
  },

  headerStyle: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    borderColor: ColorsDefault.lineColor,
    borderBottomWidth: 1,
  },
  wrapperItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  textItem: { marginLeft: 15, fontSize: 16, color: ColorsDefault.blue5 },
  backgroundOverlay: { flex: 1, backgroundColor: ColorsDefault.blue4, opacity: 0.3 },
  titleStyle: { fontFamily: Fonts.fontFamilyBold, fontSize: 14, color: ColorsDefault.blue4 },
});

export default ExpenseCategoryChooser;
