import { useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Divider, Menu } from "react-native-paper";

import { SafeAreaView } from "react-native-safe-area-context";

import DropDown from "./Dropdown";

const CustomDropdownPicker = ({ value, items, onSelectItem, placeholder, error = false, onFocus }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open && onFocus) {
      onFocus();
    }
  }, [open, onFocus]);

  return (
    <SafeAreaView>
      <DropDown
        label={placeholder}
        mode={"flat"}
        visible={open}
        showDropDown={() => setOpen(true)}
        onDismiss={() => setOpen(false)}
        value={value}
        setValue={onSelectItem}
        list={items}
      />
    </SafeAreaView>
  );
};

export default CustomDropdownPicker;
