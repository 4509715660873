import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { ExpValidateExpenseRequestAmountMutationVariables, ExpValidateExpenseRequestAmountMutation } from "types";

const VALIDATE_EXPENSE_REQUEST_AMOUNT = gql`
  mutation ExpValidateExpenseRequestAmount($input: ExpValidateExpenseRequestAmountInput!) {
    expValidateExpenseRequestAmount(input: $input) {
      budgets {
        isValid
        expenseCategoryId
        isOverBudget
        overBudgetAmount
        remainingSpendingPlanAmount
        restrictionType
        message
        budget {
          budgetPlanId
          budgetItemId
          fiscalYear
          status
          budgetAmount
          actualSpendingAmount
          expenseCategoryId
          companyTeamId
        }
      }
    }
  }
`;

export const useValidateExpenseRequestDetailAmountMutation = (
  options?: MutationHookOptions<
    ExpValidateExpenseRequestAmountMutation,
    ExpValidateExpenseRequestAmountMutationVariables
  >
) => {
  return useMutation<ExpValidateExpenseRequestAmountMutation, ExpValidateExpenseRequestAmountMutationVariables>(
    VALIDATE_EXPENSE_REQUEST_AMOUNT,
    { ...options }
  );
};
