import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={37} height={20} viewBox="0 0 37 20" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.477 4.477 0 10 0h16.667c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10z"
        fill="#E2E8F0"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.666 10a8.333 8.333 0 1116.667 0 8.333 8.333 0 01-16.667 0z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
