import Config from "app/config";
import * as Device from "expo-device";
import { Platform } from "react-native";
export class BizziIdError extends Error {
  public constructor(message: string, public payload?: any) {
    super(message);
    this.payload = payload;
    this.name = "BizziIdError";
  }
}

const baseUrl = Config.BIZZI_ID_ENDPOINT;
const defaultOptions = {
  method: "GET",
  headers:
    Platform.OS === "web"
      ? {
          "content-type": "application/json",
        }
      : {
          "content-type": "application/json",
          "client-device": JSON.stringify({
            isDevice: Device.isDevice,
            osName: Device.osName,
            osVersion: Device.osVersion,
            platformApiLevel: Device.platformApiLevel,
            deviceName: `${Device.manufacturer} ${Device.modelName}`,
          }),
        },
};

const request = async (url: string, options: RequestInit | undefined) => {
  if (!url) {
    throw new Error("url must be defined");
  }

  const response = await fetch(baseUrl + "/" + url, {
    ...defaultOptions,
    ...options,
  });

  const { status } = response;
  const payload = await response.json();
  if (status >= 400) {
    throw new BizziIdError("Network response was not OK", payload);
  }

  return payload;
};

export default {
  request,
};
