import { useTranslation } from "react-i18next";
import React, { FC, useMemo, useRef } from "react";
import { Platform, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { Colors, Fonts } from "theme";
import { useAuth } from "contexts/AuthContext";
import { DirectInbox } from "assets/images/svg/icons";
import { useSuggestInvoicesQuery } from "hooks/useSuggestInvoicesQuery";
import { AppText, BottomSheetFlatListModalCustom, BottomSheetModalCustomMethods, Button, IconCustom } from "components";
import { CONSTANTS } from "constants/constants";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";
import { ExpenseFormValues } from "screens/Expense/components/ExpenseForm/types";
import InvoiceItem from "../InvoiceItem";
import dayjs from "dayjs";
import { FieldErrors } from "react-hook-form";

interface ElectronicInvoiceTabProps {
  style?: ViewStyle;
  invoice?: ExpenseFormValues["invoice"];
  prefer?: {
    amount?: string;
    expenseDate?: string;
  };
  onSelect?: (invoice: ExpenseFormValues["invoice"]["invoiceElectronic"]) => void;
  error?: FieldErrors<ExpenseFormValues>["invoice"];
}
const ElectronicInvoiceTab: FC<ElectronicInvoiceTabProps> = ({ style, onSelect, invoice, prefer, error }) => {
  const eInvoiceBottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
  const navigation = useNavigation<any>();

  const {
    user,
    company: { company_id },
  } = useAuth();
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const options = useMemo(
    () => ({
      variables: {
        companyId: company_id,
        userId: user.id,
        prefer: {
          amount: prefer?.amount === "" ? undefined : Number(prefer?.amount), //in the case: create expense not input amount --> send undefined amount
          expenseDate: dayjs(prefer?.expenseDate).startOf("day").toISOString(),
        },
      },
    }),
    [company_id, prefer?.amount, prefer?.expenseDate, user.id]
  );

  const [onFetchData, { loading, data: invoicesData }] = useSuggestInvoicesQuery(options);

  const data = invoicesData?.expSuggestInvoices?.invoices ?? [];

  const onClear = () => {
    onSelect(null);
  };
  const renderListEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <DirectInbox />
        <AppText style={[Fonts.H500, styles.centerText]}>{t("no_electronic_invoice_title")}</AppText>
        <AppText style={[Fonts.BodyMedium, styles.centerText]}>{t("no_electronic_invoice_description")}</AppText>
      </View>
    );
  };

  const handlePressItem = (item: ExpenseFormValues["invoice"]["invoiceElectronic"]) => () => {
    eInvoiceBottomSheetRef.current?.close();
    if (invoice?.invoiceElectronic?.invoiceId && item.invoiceId === invoice?.invoiceElectronic?.invoiceId) {
      onClear?.();
      return;
    }
    onSelect?.(item);
  };
  const handleViewDetail = (invoiceId: string) => () => {
    navigation.navigate(SCREEN_NAME.InvoiceDetailScreen, {
      invoiceId,
      autofill: true,
      forceHideButton: true,
    });
  };

  const renderItem = ({ item }) => {
    const isActive = item.invoiceId === invoice?.invoiceElectronic?.invoiceId;

    return (
      <View style={isActive && styles.activeContainer}>
        <TouchableOpacity onPress={handlePressItem(item)} style={styles.itemWrapper}>
          <InvoiceItem handleViewDetail={handleViewDetail(item?.invoiceId)} item={item} active={isActive} />
        </TouchableOpacity>
      </View>
    );
  };
  const handleOpenInvoiceModal = () => {
    eInvoiceBottomSheetRef.current?.present();
    onFetchData();
  };

  return (
    <View>
      {invoice?.invoiceElectronic?.invoiceId ? (
        <View style={style}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(SCREEN_NAME.InvoiceDetailScreen, {
                invoiceId: invoice?.invoiceElectronic?.invoiceId,
                autofill: true,
                forceHideButton: true,
              });
            }}
          >
            <InvoiceItem item={invoice?.invoiceElectronic as ExpInvoice} expenseView={true} onClear={onClear} />
          </TouchableOpacity>
        </View>
      ) : (
        <Button
          style={[style, styles.selectInvoice]}
          type="secondary"
          icon={() => <IconCustom name="add-circle-outline" width={32} height={32} />}
          onPress={handleOpenInvoiceModal}
        >
          {t("electronic_invoice_title")}
        </Button>
      )}
      {Boolean(error?.message) && (
        <AppText style={[Fonts.BodySmall, styles.errorMessage]} color={Colors.alert50}>
          {error.message}
        </AppText>
      )}
      <BottomSheetFlatListModalCustom
        ref={eInvoiceBottomSheetRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={t("electronic_invoice_title")}
        loading={loading}
        listProps={{
          keyboardDismissMode: "on-drag",
          keyboardShouldPersistTaps: "always",
          showsVerticalScrollIndicator: false,
          contentContainerStyle: [{ paddingTop: 20 }, Platform.OS === "web" && { paddingBottom: 80 }],
          data,
          keyExtractor: ({ invoiceId }) => invoiceId,
          renderItem,
          ListEmptyComponent: renderListEmptyComponent,
          ItemSeparatorComponent: () => <View style={styles.divider} />,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  itemWrapper: {
    marginHorizontal: 20,
  },
  activeContainer: {
    backgroundColor: Colors.primary0,
  },
  centerText: {
    textAlign: "center",
  },
  emptyContainer: {
    marginTop: Platform.OS === "web" ? 50 : "50%",
    alignItems: "center",
    paddingHorizontal: 32,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
    marginBottom: 10,
  },
  selectInvoice: {
    marginTop: 10,
  },
  errorMessage: { marginTop: 10 },
});

export default ElectronicInvoiceTab;
