import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MobileNtfMarkNotificationMutation, MobileNtfMarkNotificationMutationVariables } from "types";

const MUTATION = gql`
  mutation MobileNtfMarkNotification($input: NtfMarkNotificationInput!) {
    ntfMarkNotification(input: $input) {
      success
    }
  }
`;

const useMarkNotification = () => {
  return useMutation<MobileNtfMarkNotificationMutation, MobileNtfMarkNotificationMutationVariables>(MUTATION);
};

export default useMarkNotification;
