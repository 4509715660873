import { FC } from "react";
import { AppText, Badge } from "components";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import { ReservationStatus } from "../types";
import { ViewStyle } from "react-native";

type ReservationStatusBadgeProps = {
  status: ReservationStatus | string;
  style?: ViewStyle;
};

const ReservationStatusBadge: FC<ReservationStatusBadgeProps> = ({ status, style }) => {
  const { t } = useTranslation("app/screens/FlightBooking/components/FlightBookingComponent");
  switch (status) {
    case ReservationStatus.Issued:
      return (
        <Badge style={style} text={<AppText style={Fonts.BodySmall}>{t("paid")}</AppText>} color={Colors.success0} />
      );
    case ReservationStatus.Hold:
      return (
        <Badge
          style={style}
          text={<AppText style={Fonts.BodySmall}>{t("not_paid")}</AppText>}
          color={Colors.warning0}
        />
      );
    case ReservationStatus.Expired:
      return (
        <Badge style={style} text={<AppText style={Fonts.BodySmall}>{t("expired")}</AppText>} color={Colors.alert0} />
      );
    default:
      return null;
  }
};

export default ReservationStatusBadge;
