// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { View, StyleSheet, useWindowDimensions, Platform } from "react-native";
import { Button, Text } from "react-native-paper";

import BizziBot from "assets/images/svg/BizziBot";
import BizziBotHappy from "assets/images/svg/BizziBotHappy";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";

const RegisterWarningEnabledExpense = ({ route, navigation }) => {
  const { width: WWindow } = useWindowDimensions();
  const { logout } = useAuth();
  const { t } = useTranslation("screens/RegisterOnboarding/RegisterWarningEnabledExpense");
  const { isAdmin, companyId } = route?.params ?? {};
  const { width } = useWindowDimensions();
  if (isAdmin) {
    return (
      <View style={styles.container}>
        <View style={styles.center}>
          <BizziBot />
          <Text
            style={[
              styles.textInform,
              {
                fontSize: width > 1024 ? 24 : 16,
                marginTop: 16,
                maxWidth: 800,
                paddingHorizontal: width > 425 ? 60 : 20,
              },
            ]}
          >
            {t("description_for_admin")}
          </Text>
          <Button
            style={{ marginTop: 32 }}
            labelStyle={{ fontWeight: "600" }}
            onPress={async () => {
              if (Platform.OS === "web") {
                window.open("https://spend.bizzi.vn/companies");
                return;
              }

              if (logout) {
                await logout();
              }
            }}
          >
            {t("back")}
          </Button>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.center}>
        <BizziBotHappy />
        <Text style={[styles.textInform, { fontSize: width > 1024 ? 24 : 16, marginTop: 16, maxWidth: 800 }]}>
          {t("description_for_user")}
        </Text>
        <Button
          labelStyle={{ fontWeight: "600" }}
          style={{ marginTop: 32 }}
          onPress={async () => {
            if (Platform.OS === "web") {
              window.open("https://spend.bizzi.vn/companies");
              return;
            }

            if (logout) {
              await logout();
            }
            // navigation.navigate(SCREEN_NAME.LoginScreen);
          }}
        >
          {t("back")}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    paddingHorizontal: 15,
  },
  textInform: {
    fontWeight: "700",

    textAlign: "center",
    color: "#0F172A",
  },
});

export default RegisterWarningEnabledExpense;
