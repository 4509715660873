import { ApprovalTimeLine, AppText, LoadingView } from "components";
import { ScrollView, StyleSheet, View } from "react-native";
import React, { useMemo } from "react";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import ConditionHeader from "screens/ApprovalFlowScreen/components/ConditionHeader";
import useExpApplicableApprovalFlowsQuery from "screens/ApprovalFlowScreen/hooks/useExpApplicableApprovalFlowsQuery";
import { useAuth } from "contexts/AuthContext";
import ManualState from "screens/ApprovalFlowScreen/components/ManualState";
import { MobileExpApplicableApprovalFlowsQuery } from "types";
import { APPROVAL_STATUS } from "constants/constants";
import { ApprovalTimeLineItem } from "components/ApprovalTimeLine";

const ReportTab = () => {
  const { t } = useTranslation("app/screens/ApprovalFlowScreen");

  const {
    company,
    user: { employee_id },
  } = useAuth();
  const { data, loading } = useExpApplicableApprovalFlowsQuery({
    input: { companyId: company.company_id, employeeId: employee_id, objectType: "EXPENSE_REPORT" },
  });
  const approvalFlows = useMemo(() => {
    if (!data?.expApplicableApprovalFlows?.approvalFlows) {
      return [];
    }
    const results = [];
    for (const item of data?.expApplicableApprovalFlows?.approvalFlows) {
      results.push(item);
      if (!item.conditions.length) {
        break;
      }
    }
    return results;
  }, [data]);

  const formatApprovalTimeLineData = (
    data: MobileExpApplicableApprovalFlowsQuery["expApplicableApprovalFlows"]["approvalFlows"][0]["approvalTemplates"]
  ): ApprovalTimeLineItem[] => {
    if (!data) {
      return [];
    }
    return data
      ?.map((item) => {
        const approvers = item?.approvers?.map((approver) => ({
          employeeId: approver?.employeeId as string,
          employee: {
            avatar: approver?.employee?.avatarUrl || approver?.employee?.defaultAvatarUrl,
            fullname: approver?.employee?.fullname,
          },
          status: APPROVAL_STATUS.AWAITING as number,
        }));
        return {
          name: item?.name,
          status: APPROVAL_STATUS.AWAITING as number,
          approvers,
        };
      })
      ?.filter((item) => item?.approvers?.length);
  };
  return (
    <View style={styles.container}>
      {loading ? (
        <LoadingView />
      ) : (
        <>
          <ScrollView contentContainerStyle={styles.contentContainer} showsVerticalScrollIndicator={false}>
            <View>
              {data?.expApplicableApprovalFlows?.approvalType === "AUTO" ? (
                <>
                  <AppText style={[Fonts.BodyMedium, { marginBottom: 20 }]}>{t("report_description")}</AppText>
                  {approvalFlows
                    ?.filter((item) => {
                      if (item?.approvalTemplates?.length === 1 && !item?.approvalTemplates?.[0]?.approvers?.length) {
                        return false;
                      }
                      return true;
                    })
                    ?.map((item, index) => {
                      const isExistOneApprovalTemplates =
                        item?.approvalTemplates?.filter((item) => item?.approvers?.length)?.length === 1;
                      return (
                        <View
                          key={index}
                          style={[styles.cardContainer, isExistOneApprovalTemplates && { paddingBottom: 17 }]}
                        >
                          <ConditionHeader noConditionTitle={t("no_condition_report")} conditions={item?.conditions} />
                          <ApprovalTimeLine
                            lineColor={Colors.primary50}
                            data={formatApprovalTimeLineData(item?.approvalTemplates)}
                          />
                        </View>
                      );
                    })}
                </>
              ) : (
                <ManualState title={t("approval_flow_report_manual")} />
              )}
            </View>
          </ScrollView>
        </>
      )}
    </View>
  );
};
export default ReportTab;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingTop: 20, flexGrow: 1, paddingBottom: 40, paddingHorizontal: 20 },
  cardContainer: {
    borderRadius: 16,
    marginBottom: 20,
    backgroundColor: Colors.white,
    paddingTop: 17,
    paddingHorizontal: 14,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
  },
});
