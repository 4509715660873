import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpInsightsExpenseOverviewQuery, MobileExpInsightsExpenseOverviewQueryVariables } from "types";

const QUERY = gql`
  query MobileExpInsightsExpenseOverview($input: ExpInsightsInput!) {
    expInsights(input: $input) {
      spendingPlan {
        totalClosedAmount
        totalAmount
      }
      approvedExpense {
        totalAmount
        totalAmountDiffPercentage
        totalApprovedAmount
        totalApprovedExpenses
      }
      budget {
        totalAmount
      }
      pendingExpense {
        totalAmount
        totalClaimedExpenses
        totalClaimedAmount
        totalUnclaimedAmount
        totalUnclaimedExpenses
      }
      remainingSpendingPlan {
        totalAmount
        totalPendingRequests
        totalRemainingRequests
        totalPendingAmount
        totalRemainingAmount
      }
      topSpendingCategories(top: 4) {
        categories {
          categoryId
          titleEn
          title
          totalAmount
          totalAmountPrevious
          totalAmountDiffPercentage
        }
        totalCategories
        otherAmount
      }
    }
  }
`;

const useExpenseOverviewQuery = (variables: MobileExpInsightsExpenseOverviewQueryVariables, onCompleted, onError) => {
  return useQuery<MobileExpInsightsExpenseOverviewQuery, MobileExpInsightsExpenseOverviewQueryVariables>(QUERY, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted,
    onError,
  });
};

export default useExpenseOverviewQuery;
