import SCREEN_NAME from "navigation/ScreenName";
import { AppText, Button, CurrencyText, IconCustom } from "components";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import sumBy from "lodash/sumBy";
import useQueryExpenseCategory from "hooks/useQueryExpenseCategory";
import { Divider } from "react-native-paper";
import { Colors, Fonts } from "theme";
import AllowanceItem from "./AllowanceItem";
import { StyleSheet, View } from "react-native";
import dayjs from "dayjs";
import { useAuth } from "contexts/AuthContext";
import { useValidateExpenseRequestDetailAmountMutation } from "hooks/request/useValidateExpenseRequestDetailAmountMutation";
import { getTitleFromCategory } from "utils";
import { Control, useFieldArray, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { ExpenseRequestFormValues, OverBudget } from "screens/ExpenseRequest/types";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { ALLOWANCE_TYPE, REQUEST_TYPE, RESTRICTION_BUDGET_TYPE } from "constants/constants";

interface AllowanceType {
  amount: number;
  expenseCategoryId: string;
}

interface ValidatedBudgetType {
  expenseCategoryId?: string;
  isOverBudget?: boolean;
  isValid?: boolean;
  message?: string;
}
const GroupHeader = ({
  group,
  validatedBudgets,
  category,
}: {
  group: Record<string, any>;
  validatedBudgets: ValidatedBudgetType[];
  category: Record<string, any>;
}) => {
  const validatedBudget = validatedBudgets?.find((item) => item.expenseCategoryId === group.expenseCategoryId);
  const isValidBudget = !validatedBudget || validatedBudget.isValid;
  return (
    <>
      <View style={styles.groupLine}>
        <AppText numberOfLines={1} ellipsizeMode={"tail"} style={[Fonts.NumericN300, styles.categoryTitle]}>
          {getTitleFromCategory({ title: category?.title, titleEn: category?.title_en })}
        </AppText>
        <CurrencyText
          style={StyleSheet.flatten([Fonts.SentenceSubtitleLarge])}
          color={!isValidBudget && Colors.alert50}
        >
          {group.totalAmount}
        </CurrencyText>
      </View>
      {isValidBudget || (
        <AppText style={styles.alertText} color={Colors.alert50}>
          {validatedBudget.message}
        </AppText>
      )}
    </>
  );
};
interface AllowancesSectionProps {
  setValue?: UseFormSetValue<ExpenseRequestFormValues>;
  watch?: UseFormWatch<ExpenseRequestFormValues>;
  control?: Control<ExpenseRequestFormValues>;
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
}
const AllowancesSection: FC<AllowancesSectionProps> = ({ setValue, watch, control, getValues }) => {
  const navigation = useNavigation<any>();
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const { company } = useAuth();
  const [validateExpenseRequestBudgetAmount] = useValidateExpenseRequestDetailAmountMutation();
  const [validatedBudgets, setValidatedBudgets] = useState([]);
  const { data: categories } = useQueryExpenseCategory();
  const budgetDate = watch("budgetDate", null);
  const companyTeam = watch("companyTeam", null);
  const travelDate = watch("travelDate", null);
  const requestType = watch("expenseRequestType") as REQUEST_TYPE;
  const passengers = watch("passengers", []);
  const {
    append,
    update,
    remove,
    fields: allowances,
  } = useFieldArray({
    name: "allowances",
    control,
  });
  const handleRevalidateAdvanceAmount = () => {
    const cashAdvanceAmount = getValues("cashAdvance.amount");
    setValue("cashAdvance.amount", cashAdvanceAmount, { shouldValidate: true });
  };
  useEffect(() => {
    handleValidateExpenseAmount();
  }, [allowances, budgetDate, companyTeam]);
  const handleValidateExpenseAmount = async () => {
    const allowancesItems = allowances as [];
    if (allowancesItems.length) {
      const expenseAllowancesVariables = allowancesItems
        ?.map((item: AllowanceType) => ({
          expenseCategoryId: item?.expenseCategoryId,
          allowanceAmount: item?.amount,
        }))
        .filter((item) => item?.allowanceAmount && item?.expenseCategoryId);
      const body = {
        input: {
          companyId: company.company_id,
          companyTeamId: companyTeam?.companyTeamId,
          budgetDate: dayjs(budgetDate).toISOString(),
          expenseAllowances: expenseAllowancesVariables,
        },
      };
      const validatedBudgetAmount = await validateExpenseRequestBudgetAmount({
        variables: body,
      });
      const budgets = validatedBudgetAmount?.data?.expValidateExpenseRequestAmount?.budgets;
      const overBudgets: Record<string, OverBudget> = {};
      budgets.forEach(({ expenseCategoryId, isValid, message, restrictionType, overBudgetAmount }) => {
        if (!isValid && restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT) {
          overBudgets[expenseCategoryId] = {
            message,
            restrictionType,
            overBudgetAmount,
            expenseCategoryId,
          } as OverBudget;
        }
      });
      setValidatedBudgets(budgets ?? []);
      setValue("overBudgets", overBudgets);
    }
  };
  const displayedAllowances = useMemo(() => {
    return Object.values(
      (Array.isArray(allowances) ? allowances?.filter((item) => item?.expenseCategoryId) : []).reduce((acc, val) => {
        if (!acc[val.expenseCategoryId]) {
          acc[val.expenseCategoryId] = {
            expenseCategoryId: val.expenseCategoryId,
            //expenseCategory: getExpenseCategory(val.expenseCategoryId)?.expense_category,
            list: [val],
          };
        } else {
          acc[val.expenseCategoryId].list.push(val);
        }
        return acc;
      }, {})
    ).map((item: any) => {
      return {
        ...item,
        totalAmount: sumBy(item.list, (v: any) => {
          if (
            [ALLOWANCE_TYPE.PERDIEM, ALLOWANCE_TYPE.BOOKING_BUS, ALLOWANCE_TYPE.BOOKING_FLIGHT].includes(v?.type) &&
            getValues("passengers")?.length > 1
          ) {
            return v.amount * getValues("passengers").length;
          }
          return v.amount;
        }),
      };
    });
  }, [allowances, travelDate, passengers]);

  const handleRemoveAllowance = (id) => () => {
    const foundIndex = allowances?.findIndex((item) => item?.id === id);
    remove(foundIndex);
  };
  return (
    <View style={{ marginTop: 15 }}>
      {displayedAllowances.map((group) => {
        return (
          <View style={{ paddingBottom: 16 }} key={group.expenseCategoryId}>
            <GroupHeader
              group={group}
              validatedBudgets={validatedBudgets}
              category={categories?.find((i) => i?.expense_category_id === group.expenseCategoryId)?.expense_category}
            />
            <Divider style={{ backgroundColor: Colors.grayscale10, marginHorizontal: -16, height: 1, marginTop: 16 }} />
            {group?.list?.map((allowance, index) => (
              <Fragment key={allowance.id}>
                {index > 0 && <Divider style={{ backgroundColor: Colors.grayscale10, marginRight: -16, height: 1 }} />}
                <AllowanceItem
                  watch={watch}
                  value={allowance}
                  getValues={getValues}
                  style={styles.allowanceItem}
                  onDelete={handleRemoveAllowance(allowance.id)}
                  onEdit={() => {
                    navigation.navigate(SCREEN_NAME.AddAllowanceScreen, {
                      edit: {
                        id: allowance.id,
                        allowance,
                        index: allowances?.findIndex((i) => i?.id === allowance.id),
                      },
                      update,
                      type: requestType,
                      control,
                      callback: handleRevalidateAdvanceAmount,
                    });
                  }}
                  travelDate={travelDate}
                />
              </Fragment>
            ))}
          </View>
        );
      })}
      <Button
        type="secondary"
        style={{ marginBottom: 5 }}
        icon={() => <IconCustom name="add-circle-outline" />}
        onPress={() => {
          navigation.navigate(SCREEN_NAME.AddAllowanceScreen, {
            append,
            type: requestType,
            control,
            callback: handleRevalidateAdvanceAmount,
          });
        }}
      >
        {requestType === REQUEST_TYPE.purchase ? t("add_product_service") : t("add_budget")}
      </Button>
    </View>
  );
};
export default AllowancesSection;

const styles = StyleSheet.create({
  allowanceItem: {
    marginVertical: 20,
  },
  groupLine: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  categoryTitle: {
    flex: 1,
    paddingRight: 16,
  },
  alertText: {
    ...Fonts.Caption,
    textAlign: "right",
  },
});
