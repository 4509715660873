import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileFlightBookingInfoQuery, MobileFlightBookingInfoQueryVariables } from "types";

const QUERY = gql`
  query MobileFlightBookingInfo($expenseRequestId: UUID!) {
    expExpenseRequestDetail(expenseRequestId: $expenseRequestId) {
      expenseTravels {
        expenseBookings {
          expenseBookingId
          bookingType
          expenseTravelBookings {
            bookingInformation
            bookingProvider
            bookingType
            currency
            expenseTravelBookingId
            orderCode
            status
            totalAmount
          }
        }
      }
    }
  }
`;

const useFlightBookingInfoQuery = (variables) => {
  return useQuery<MobileFlightBookingInfoQuery, MobileFlightBookingInfoQueryVariables>(QUERY, {
    variables,
  });
};
export default useFlightBookingInfoQuery;
