import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M9.518 15.616c-1.708 0-3.154-.593-4.339-1.777-1.184-1.185-1.777-2.631-1.777-4.339 0-1.708.593-3.154 1.777-4.338C6.364 3.977 7.81 3.385 9.518 3.385c1.707 0 3.153.592 4.338 1.777 1.185 1.184 1.777 2.63 1.777 4.338 0 .714-.12 1.396-.36 2.046-.24.65-.56 1.216-.96 1.696l5.755 5.754c.138.139.209.313.212.522a.707.707 0 01-.212.532.717.717 0 01-.527.217.717.717 0 01-.527-.217l-5.754-5.754c-.5.413-1.075.736-1.725.97-.65.233-1.322.35-2.017.35zm0-1.5c1.288 0 2.38-.448 3.274-1.342.894-.894 1.341-1.985 1.341-3.274 0-1.288-.447-2.38-1.341-3.274-.894-.894-1.986-1.341-3.274-1.341-1.289 0-2.38.447-3.274 1.341-.895.894-1.342 1.986-1.342 3.274 0 1.289.447 2.38 1.342 3.274.894.894 1.985 1.342 3.274 1.342z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
