import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 .667l5.478 1.217a.667.667 0 01.522.65v6.659a4 4 0 01-1.781 3.328L8 15.333l-4.219-2.812A3.998 3.998 0 012 9.193V2.535c0-.313.217-.583.522-.65L8 .666zm-.694 4a.667.667 0 011.334 0V8a.667.667 0 01-1.334 0V4.667zm.667 6a.667.667 0 100-1.333.667.667 0 000 1.333z"
        fill="#E11934"
      />
    </Svg>
  );
}

export default SvgComponent;
