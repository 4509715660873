import { AppText } from "components";
import { ExpenseStatus } from "constants/ExpenseApproval";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { ExpenseDetailsView } from "../types";
import { TouchableOpacity } from "react-native-gesture-handler";

type TextWithBadgedProps = {
  items: Array<ReactNode>;
};
const TextWithBadged: FC<TextWithBadgedProps> = ({ items }) => {
  return (
    <View style={styles.container}>
      {items.map((item) => {
        if (typeof item === "string") {
          return item.split(" ").map((str) => (
            <AppText style={[Fonts.BodyRegular, styles.mb8]} color={Colors.grayscale70}>
              {`${str} `}
            </AppText>
          ));
        }

        return (
          <>
            {item}
            <AppText> </AppText>
          </>
        );
      })}
    </View>
  );
};

type SummaryMessageProps = {
  expense: Partial<ExpenseDetailsView>;
  onReportDocSequenceClick?: () => void;
};
const SummaryMessage: FC<SummaryMessageProps> = ({ expense, onReportDocSequenceClick }) => {
  const { t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");

  const messages = [
    t("expense"),
    <View style={[styles.textBadge, styles.mb8]}>
      <AppText style={Fonts.BodyMedium}>{expense?.totalAmountWithVat} ₫</AppText>
    </View>,
  ];

  if (expense.expenseCategoryTitle) {
    messages.push(
      t("with_category"),
      <View style={[styles.textBadge, styles.mb8]}>
        <AppText style={Fonts.BodyMedium}>{expense.expenseCategoryTitle}</AppText>
      </View>
    );
  }

  switch (expense.status) {
    case ExpenseStatus.READY:
      if (expense.expenseReport?.expenseReportId) {
        return (
          <TextWithBadged
            items={[
              ...messages,
              t("already_added_to_an_expense_report"),
              <View style={styles.mb8}>
                <TouchableOpacity style={styles.textBadge} onPress={onReportDocSequenceClick}>
                  <AppText style={Fonts.BodyRegular} color={Colors.primary50}>
                    {expense.expenseReport?.docSequence}
                  </AppText>
                </TouchableOpacity>
              </View>,
            ]}
          />
        );
      }

      return <TextWithBadged items={[...messages, t("ready_for_an_expense_report")]} />;

    case ExpenseStatus.NEED_DETAILS:
      return <TextWithBadged items={[...messages, t("need_to_be_added_more_information")]} />;

    case ExpenseStatus.AWAITING_INVOICE:
      return <TextWithBadged items={[...messages, t("need_vat_invoice")]} />;

    default:
      return null;
  }
};

const styles = StyleSheet.create({
  mb8: {
    marginBottom: 4,
  },
  textBadge: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    backgroundColor: Colors.grayscale05,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

export default SummaryMessage;
