import * as React from "react";
import Svg, { Path, Rect, Ellipse } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={273} height={123} fill="none" {...props}>
    <Path
      fill="#F0F6FF"
      fillRule="evenodd"
      d="M90.1 12.872C90.1 5.763 95.861 0 102.97 0h157.156C267.236 0 273 5.763 273 12.872c0 7.11-5.763 12.872-12.873 12.872H201.96a12.849 12.849 0 0 1 4.781 10.012c0 7.053-5.673 12.78-12.704 12.871h36.945c7.109 0 12.872 5.763 12.872 12.872 0 7.11-5.763 12.872-12.872 12.872H77.405c7.035-.086 12.712-5.815 12.712-12.871 0-7.109-5.763-12.872-12.872-12.872H36.66c-7.085-.028-12.82-5.78-12.82-12.872 0-7.11 5.762-12.872 12.871-12.872H94.88a12.847 12.847 0 0 1-4.78-10.012ZM0 87.245c0-7.11 5.763-12.872 12.872-12.872h183.664c.633 0 1.255.046 1.864.134h-7.059c-6.309 0-11.424 5.115-11.424 11.424 0 6.31 5.115 11.425 11.424 11.425h22.702c6.349.792 11.262 6.208 11.262 12.772 0 7.109-5.763 12.872-12.872 12.872H73.832c-7.11 0-12.873-5.763-12.873-12.872 0-4.043 1.865-7.651 4.78-10.011H12.872C5.763 100.117 0 94.354 0 87.245Z"
      clipRule="evenodd"
    />
    <Rect width={2.127} height={7.812} x={192.984} y={101.52} fill="#75A4FE" rx={1.064} />
    <Rect width={2.127} height={7.812} x={192.984} y={111.936} fill="#75A4FE" rx={1.064} />
    <Rect
      width={2.117}
      height={7.849}
      x={195.611}
      y={111.936}
      fill="#75A4FE"
      rx={1.059}
      transform="rotate(-90 195.611 111.936)"
    />
    <Rect
      width={2.117}
      height={7.849}
      x={183.836}
      y={111.936}
      fill="#75A4FE"
      rx={1.059}
      transform="rotate(-90 183.836 111.936)"
    />
    <Path
      fill="#fff"
      stroke="#1F71F4"
      strokeWidth={2.016}
      d="M188.674 74.372c0 2.599-2.118 4.713-4.741 4.713-2.622 0-4.74-2.114-4.74-4.713 0-2.598 2.118-4.713 4.74-4.713 2.623 0 4.741 2.115 4.741 4.713Z"
    />
    <Ellipse cx={82.786} cy={37.054} fill="#75A4FE" rx={4.255} ry={4.235} />
    <Rect width={2.127} height={7.411} x={96.611} y={5.293} fill="#75A4FE" rx={1.064} />
    <Rect width={2.127} height={7.411} x={96.744} y={15.256} fill="#75A4FE" rx={1.064} />
    <Rect
      width={2.117}
      height={7.446}
      x={98.738}
      y={14.821}
      fill="#75A4FE"
      rx={1.059}
      transform="rotate(-90 98.738 14.821)"
    />
    <Path fill="#75A4FE" d="M90.225 15.256a1.059 1.059 0 0 1 0-2.117h5.328a1.059 1.059 0 0 1 0 2.117h-5.328Z" />
    <Rect width={48.691} height={51.867} x={107.047} y={33.163} fill="#fff" rx={12.279} />
    <Rect width={31.755} height={30.697} x={135.627} y={60.685} fill="#fff" rx={15.348} />
    <Path
      stroke="#1F71F4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3.176}
      d="M117.988 22.932v10.055M144.803 22.932v10.055M161.563 61.914V44.72c0-10.056-5.028-16.76-16.759-16.76h-26.816c-11.732 0-16.759 6.704-16.759 16.76V73.21c0 10.056 5.027 16.76 16.759 16.76h22.391M114.635 53.099h20.111M114.635 69.858h8.782"
    />
    <Path
      stroke="#1F71F4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.176}
      d="M151.708 77.87a1.608 1.608 0 0 0-.402 0 5.479 5.479 0 0 1-5.296-5.48 5.49 5.49 0 0 1 5.497-5.497 5.5 5.5 0 0 1 5.497 5.497c-.017 2.966-2.346 5.38-5.296 5.48ZM162.807 88.931a16.65 16.65 0 0 1-11.296 4.391c-4.358 0-8.313-1.66-11.296-4.391.167-1.575 1.173-3.117 2.966-4.324 4.592-3.05 12.101-3.05 16.659 0 1.794 1.207 2.799 2.749 2.967 4.324Z"
    />
    <Path
      stroke="#1F71F4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.176}
      d="M151.508 93.321c9.256 0 16.759-7.504 16.759-16.76 0-9.256-7.503-16.76-16.759-16.76s-16.76 7.504-16.76 16.76c0 9.256 7.504 16.76 16.76 16.76Z"
    />
  </Svg>
);
export default SvgComponent;
