import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = () => (
  <Svg width={46} height={15} viewBox="0 0 46 15" fill="none">
    <Path
      d="M25.4795 11.6782L29.6779 7.14283L30.0138 5.28125L21.9996 5.28125L21.7614 8.07167L24.5461 8.07167L24.5851 8.18049L20.3281 12.7198L20.0117 14.5814L28.4867 14.5814L28.971 11.7909L25.5146 11.7909L25.4795 11.6782Z"
      fill="white"
    />
    <Path
      d="M31.3532 8.07167L34.1378 8.07167L34.1769 8.18049L29.9199 12.7198L29.6035 14.5814L38.0785 14.5814L38.5628 11.7909L35.1064 11.7909L35.0713 11.6782L39.2736 7.14283L39.6056 5.28125L31.5914 5.28125L31.3532 8.07167Z"
      fill="white"
    />
    <Path
      d="M17.027 2.94531L16.6247 5.28103L19.4718 5.28103L16.488 6.06608L15.0117 14.5812L18.5423 14.5812L20.5732 2.94531L17.027 2.94531Z"
      fill="white"
    />
    <Path
      d="M42.0367 2.94531L41.6306 5.28103L44.3723 5.28103L41.4978 6.06608L40.0137 14.5812L43.5482 14.5812L45.5791 2.94531L42.0367 2.94531Z"
      fill="white"
    />
    <Path
      d="M11.1388 0.548828L3.94865 0.548828L3.29642 3.46751L0.804687 14.6175L6.06155 14.6175L6.41695 13.063L7.16291 9.70904L7.20197 9.52638L4.54229 9.52638L5.51868 5.25136L3.95646 5.25136L7.62767 2.89232L10.1467 5.25136L8.92431 5.25136L8.53376 6.95359L12.0487 6.95359L6.58489 14.6175L7.5066 14.6175C8.22587 14.6203 8.94379 14.5552 9.65074 14.4232C10.3007 14.3045 10.9221 14.0645 11.4824 13.7159C11.9716 13.4153 12.4116 13.0417 12.7869 12.6083C13.3655 11.9725 13.7691 11.1987 13.9586 10.362C14.1441 9.68108 14.1044 8.95884 13.8453 8.30217C13.7048 8.00929 13.5055 7.74817 13.2596 7.53494C13.0138 7.32171 12.7266 7.16089 12.4159 7.06241C12.8804 6.86758 13.3114 6.60136 13.693 6.27348C14.2907 5.71802 14.7001 4.99144 14.8646 4.19426C15.0568 3.47727 14.9819 2.7153 14.6537 2.04897C14.1174 1.07738 12.9457 0.577328 11.1388 0.548828Z"
      fill="white"
    />
  </Svg>
);
export default SVGComponent;
