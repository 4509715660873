import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#3FCA54", size = 24, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.647 7.238a1 1 0 01.116 1.409l-7.091 8a1 1 0 01-1.525 0l-3.91-4.429a1 1 0 111.526-1.293l3.146 3.53 6.329-7.102a1 1 0 011.409-.115z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
