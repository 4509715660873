import Svg, { SvgProps, Path } from "react-native-svg";

function SvgComponent({ height = 16, width = 16, style = {}, color = "#E11934" }: SvgProps) {
  return (
    <Svg width={height} height={width} viewBox="0 0 16 16" fill="none" style={style}>
      <Path
        d="M10.793 1.332H5.206c-2.426 0-3.873 1.447-3.873 3.873v5.58c0 2.434 1.447 3.88 3.873 3.88h5.58c2.427 0 3.874-1.446 3.874-3.873V5.205c.006-2.426-1.44-3.873-3.867-3.873zm.42 5.88a.503.503 0 01-.707 0L8.5 5.205V12c0 .273-.227.5-.5.5a.504.504 0 01-.5-.5V5.205L5.493 7.212a.503.503 0 01-.707 0 .494.494 0 01-.146-.353c0-.127.053-.26.146-.354l2.86-2.86a.5.5 0 01.707 0l2.86 2.86a.503.503 0 010 .707z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
