import { useEffect, useRef, useState } from "react";

const useInteractionManager = () => {
  const [isReady, setIsReady] = useState(false);
  const timeoutId = useRef(null);
  // delay render all component in the case ui screen is large to navigate is smooth
  useEffect(() => {
    timeoutId.current = setTimeout(() => {
      setIsReady(true);
    }, 250);
    return () => clearTimeout(timeoutId.current);
  }, []);
  return isReady;
};

export default useInteractionManager;
