import { View, StyleSheet } from "react-native";
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { Colors } from "theme";

const PaginationDot: React.FC<{
  index: number;
  length: number;
  animValue: Animated.SharedValue<number>;
  size?: number;
}> = (props) => {
  const { animValue, index, length, size = 8 } = props;

  const animStyle = useAnimatedStyle(() => {
    let inputRange = [index - 1, index, index + 1];
    let outputRange = [-size, 0, size];

    if (index === 0 && animValue?.value > length - 1) {
      inputRange = [length - 1, length, length + 1];
      outputRange = [-size, 0, size];
    }

    return {
      opacity: interpolate(animValue?.value, inputRange, [0, 1, 0], Extrapolate.CLAMP),
      transform: [
        {
          translateX: interpolate(animValue?.value, inputRange, outputRange, Extrapolate.CLAMP),
        },
      ],
    };
  }, [animValue, index, length]);
  return (
    <View style={[styles.container, { width: size, height: size, borderRadius: size / 2 }]}>
      <Animated.View style={[styles.dotItem, { width: size, height: size, borderRadius: size / 2 }, animStyle]} />
    </View>
  );
};
export default PaginationDot;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEEEE",
    marginHorizontal: 4,
    overflow: "hidden",
  },
  dotItem: {
    backgroundColor: Colors.primary50,
    flex: 1,
  },
});
