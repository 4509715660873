import React, { memo, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { ActivityIndicator, RefreshControl, StyleSheet, View } from "react-native";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import Animated, {
  Easing,
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useNavigation, useRoute } from "@react-navigation/native";

import { Colors } from "theme";
import {
  AnimatedFlatList,
  CopilotStepCustom,
  CreateCircleButton,
  EmptyData,
  SkeletonListLoading,
  SortingLabel,
} from "components";
import { EmptyExpenseRequest } from "assets/images/svg/icons";
import ExpenseRequestItem from "components/ExpenseRequestItem";
import Filters from "./Filters";
import { CONSTANTS, REQUEST_STATUS } from "constants/constants";
import { clampReanimated } from "utils";
import SCREEN_NAME from "navigation/ScreenName";
import { RequestListScreenRouteProp, StackNavigation } from "navigation/type";
import { MyExpenseRequestTabMethods, REQUEST_SORT_OPTIONS } from "../types";
import { EVENT } from "constants/Tracking";
import useExpenseRequests from "../hooks/useExpenseRequests";
import { NetworkStatus } from "@apollo/client";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { analyticService } from "services/AnalyticsService";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";

const FILTER_CONTAINER_HEIGHT = 82;
const PAGE_SIZE = 10;

const MyExpenseRequestTab = React.forwardRef<MyExpenseRequestTabMethods, unknown>(({ routeParams }, ref) => {
  useImperativeHandle(ref, () => ({
    /** scroll to top before refetch */
    refetch: () => {
      onRefreshData();
    },
  }));
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const { shouldShowWalkthrough = false } = routeParams ?? {};
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestListScreen");
  const sortOptions = useMemo(
    () =>
      [
        { label: t("created_at_desc"), value: REQUEST_SORT_OPTIONS.CREATED_AT_DESC },
        { label: t("created_at_asc"), value: REQUEST_SORT_OPTIONS.CREATED_AT_ASC },
        {
          label: t("expense_asc"),
          value: REQUEST_SORT_OPTIONS.AMOUNT_ASC,
        },
        {
          label: t("expense_desc"),
          value: REQUEST_SORT_OPTIONS.AMOUNT_DESC,
        },
      ].reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [t]
  );
  const navigation = useNavigation<StackNavigation>();
  const route = useRoute<RequestListScreenRouteProp>();
  const { status } = route.params ?? {};

  const [sortOption, setSortOption] = useState<SelectItem>(sortOptions[REQUEST_SORT_OPTIONS.CREATED_AT_DESC]);
  const variables = useMemo(() => {
    return {
      where: {
        companyId,
        employeeId,
        senderId: employeeId,
        statuses: status ? [status] : undefined,
      },
      limit: PAGE_SIZE,
      offset: 0,
      sortOption: sortOption.value,
    };
  }, [companyId, employeeId, status, sortOption]);
  /*-- scroll header to top when apply sort --*/
  useEffect(() => {
    translationY.value = withTiming(0, {
      duration: 150,
      easing: Easing.linear,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption]);

  /*-- start walkthrough flow --*/
  const { start, steps } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) => steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [routeParams, stepNameToStartWalkthrough]);
  /*-- end --*/
  const { data, loading, fetchMore, refetch, networkStatus } = useExpenseRequests(variables);
  const expenseRequestList = data?.expMobileExpenseRequests?.requests ?? [];
  const total = data?.expMobileExpenseRequests?.total ?? 0;

  const handleChangeStatus = (value) => {
    const nextStatus = value === status ? undefined : value;
    navigation.setParams({ status: nextStatus });
  };

  const handleLoadMore = async () => {
    if (loading || expenseRequestList?.length >= total) {
      return;
    }

    await fetchMore({
      variables: { offset: expenseRequestList.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expMobileExpenseRequests: {
            requests: [
              ...prev?.expMobileExpenseRequests?.requests,
              ...fetchMoreResult?.expMobileExpenseRequests?.requests,
            ],
            total: fetchMoreResult?.expMobileExpenseRequests?.total,
          },
        };
      },
    });
  };

  const renderFooter = () => {
    if (loading && expenseRequestList?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const renderEmpty = () => {
    if (loading) {
      return null;
    }
    return <EmptyData title={t("empty_title")} description={t("empty_description")} icon={<EmptyExpenseRequest />} />;
  };
  const onRefreshData = () => {
    translationY.value = 0;
    refetch();
  };
  const renderItem = ({ item, index }) => (
    <ExpenseRequestItem
      item={item}
      isLast={index === total - 1}
      showApprovalFlow={[REQUEST_STATUS.SUBMITTED, REQUEST_STATUS.REJECTED].includes(item.status)}
      isRejected={item.status === REQUEST_STATUS.REJECTED}
      onRefreshData={onRefreshData}
    />
  );

  const navigateToCreateExpenseRequest = (shouldShowWalkthrough) => {
    navigation.navigate(SCREEN_NAME.NewRequestScreen, {
      onRefreshData: refetch,
      shouldShowWalkthrough,
    });
  };

  const translationY = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      translationY.value,
      [0, FILTER_CONTAINER_HEIGHT],
      [0, -FILTER_CONTAINER_HEIGHT],
      Extrapolation.CLAMP
    );
    return {
      transform: [{ translateY: translateY }],
    };
  });
  const scrollHandler = useAnimatedScrollHandler<{ prevY?: number }>({
    onScroll: (event, ctx) => {
      let { y } = event.contentOffset;
      if (y < 0) {
        y = 0;
      }
      const dy = y - (ctx?.prevY ?? 0);
      translationY.value = clampReanimated(translationY.value + dy, 0, FILTER_CONTAINER_HEIGHT);
      ctx.prevY = y;
    },
  });

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.floatHeader, animatedStyle]}>
        <Filters value={route.params?.status} onChange={handleChangeStatus} />
        <View style={styles.sortingContainer}>
          <SortingLabel
            sortingModalProps={{
              showClearButton: false,
              title: t("sort_by"),
              data: Object.values(sortOptions),
              value: sortOption,
              setValue: setSortOption,
              onPress: () =>
                analyticService.logEvent({ name: EVENT.REQUEST.TAP_OPEN_SORT_MODAL, properties: { tab: "mine" } }),
            }}
          />
        </View>
      </Animated.View>

      {(loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={styles.loadingContainer} />
      ) : (
        <AnimatedFlatList
          onScroll={scrollHandler}
          data={expenseRequestList}
          keyExtractor={(item) => (item as any)?.expenseRequestId}
          renderItem={renderItem}
          estimatedItemSize={88}
          contentContainerStyle={styles.listContainer}
          refreshControl={
            <RefreshControl
              progressViewOffset={CONSTANTS.EXPENSE_LIST.HEADER_STATUS_HEIGHT + 10}
              tintColor={Colors.primary50}
              refreshing={false}
              onRefresh={refetch}
            />
          }
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
      <CopilotStepCustom
        title={"walkthrough_request_title_step1"}
        text={"walkthrough_request_description_step1"}
        style={styles.floatButton}
        order={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.Step1}
        image={BizziBotSticker01}
        walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REQUEST.NAME}
        onConfirm={() => navigateToCreateExpenseRequest(true)}
        confirmText={t("continue")}
        singleStep={true}
      >
        <CreateCircleButton
          eventName={EVENT.REQUEST.TAP_CREATE}
          onPress={() => navigateToCreateExpenseRequest(false)}
        />
      </CopilotStepCustom>
    </View>
  );
});
export default memo(MyExpenseRequestTab);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingBottom: 50,
    paddingTop: FILTER_CONTAINER_HEIGHT - 10,
    paddingHorizontal: 20,
  },
  floatButton: { position: "absolute", right: 15, bottom: 20, zIndex: 1000 },
  loadingMore: { marginTop: 10 },
  loadingContainer: { marginTop: FILTER_CONTAINER_HEIGHT - 10 },
  floatHeader: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1,
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
  sortingContainer: {
    paddingTop: 6,
    paddingBottom: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
