// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "@apollo/client";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { DeleteExpenseAttachmentsMutation, DeleteExpenseAttachmentsMutationVariables } from "types/generated";

const DELETE_EXPENSE_ATTACHMENTS = gql`
  mutation MobileDeleteExpenseAttachments($deleteAttachmentsExp: expense_attachments_bool_exp!) {
    deleteAttachments: update_expense_attachments(where: $deleteAttachmentsExp, _set: { deleted_at: "now()" }) {
      affectedRows: affected_rows
      returning {
        expenseAttachmentId: expense_attachment_id
      }
    }
  }
`;

export const useExpDeleteExpenseAttachments = (
  options?: MutationHookOptions<DeleteExpenseAttachmentsMutation, DeleteExpenseAttachmentsMutationVariables>
) => {
  return useMutation(DELETE_EXPENSE_ATTACHMENTS, { ...options });
};
