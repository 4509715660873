import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, IconCustom, ImageCustom, useWindowDimensions } from "components";
import { Colors, Fonts } from "theme";
import Carousel from "react-native-reanimated-carousel";
import PaginationDot from "screens/Card/CardListScreen/components/PaginationDot";
import { useSharedValue } from "react-native-reanimated";
import { useRef } from "react";
import { getTitleI18n, twoDimensionalDistance } from "utils";

const modeConfig = { parallaxScrollingScale: 1, parallaxScrollingOffset: 32 };
const CARD_HEIGHT = 130;

const CarouselUserGuideVideo = ({ videos, handlePlayVideo }) => {
  const { width } = useWindowDimensions();
  const progressValue = useSharedValue<number>(0);
  const CARD_WIDTH = width - 40;
  const mouseLogs = useRef([]);

  const onPlayVideo = (id: string, video: string, thumbnail: string) => () => {
    handlePlayVideo?.(id, video, thumbnail);
  };

  const renderItem = ({ item }) => {
    const content = (
      <>
        <View style={styles.thumbnailContainer}>
          <ImageCustom
            style={styles.thumbnail}
            source={{
              uri: item?.thumbnail_url,
            }}
          />
          <View style={styles.playIcon}>
            <IconCustom width={30} height={30} name="play-arrow" fill={Colors.white} />
          </View>
        </View>
        <View style={styles.descriptionContainer}>
          <AppText style={Fonts.BodyMedium}>{getTitleI18n({ vi: item?.title, en: item?.title_en })}</AppText>
          <AppText style={Fonts.BodyRegular} color={Colors.grayscale60} numberOfLines={4}>
            {getTitleI18n({ vi: item?.description, en: item?.description_en })}
          </AppText>
        </View>
      </>
    );
    return (
      <View style={styles.cardContainer}>
        {Platform.OS === "web" ? (
          <div
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              borderRadius: 20,
              backgroundColor: Colors.grayscale0,
              width: CARD_WIDTH,
              height: CARD_HEIGHT,
            }}
            onMouseDown={({ clientX: x, clientY: y }) => mouseLogs.current.push({ x, y })}
            onMouseUp={({ clientX: x, clientY: y }) => mouseLogs.current.push({ x, y })}
            onClick={() => {
              const acceptedRadius = 10;
              const distance = twoDimensionalDistance(mouseLogs.current[0], mouseLogs.current[1]);
              if (distance < acceptedRadius) {
                onPlayVideo(item?.id, item?.url, item?.thumbnail_url)();
              }
              mouseLogs.current = [];
            }}
          >
            {content}
          </div>
        ) : (
          <TouchableOpacity
            onPress={onPlayVideo(item?.id, item?.url, item?.thumbnail_url)}
            activeOpacity={0.9}
            style={[styles.videoItem, { width: CARD_WIDTH, height: CARD_HEIGHT }]}
          >
            {content}
          </TouchableOpacity>
        )}
      </View>
    );
  };
  if (!videos?.length) {
    return null;
  }
  return (
    <View>
      <Carousel
        style={{
          width,
        }}
        vertical={false}
        overscrollEnabled={false}
        loop={true}
        autoPlay={false}
        autoPlayInterval={4000}
        width={width}
        height={CARD_HEIGHT}
        mode="parallax"
        modeConfig={modeConfig}
        data={videos}
        onProgressChange={(_, absoluteProgress) => (progressValue.value = absoluteProgress)}
        scrollAnimationDuration={500}
        renderItem={renderItem}
        onConfigurePanGesture={(gestureChain) => {
          gestureChain.activeOffsetX([-10, 10]);
        }}
      />
      {videos?.length > 1 && (
        <View style={styles.paginationContainer}>
          {videos?.map((item, index) => {
            return (
              <PaginationDot key={index} size={6} length={videos.length} animValue={progressValue} index={index} />
            );
          })}
        </View>
      )}
    </View>
  );
};
export default CarouselUserGuideVideo;
const styles = StyleSheet.create({
  cardContainer: { alignItems: "center" },
  videoItem: {
    gap: 12,
    paddingHorizontal: 10,
    borderRadius: 20,
    flexDirection: "row",
    backgroundColor: Colors.grayscale0,
  },
  descriptionContainer: { flex: 1, gap: 4, paddingTop: 12 },
  thumbnail: { width: 100, height: 100, borderRadius: 10 },
  playIcon: {
    position: "absolute",
    alignSelf: "center",
    backgroundColor: "rgba(0,0,0,0.55)",
    borderRadius: 50,
    padding: 2,
  },
  thumbnailContainer: { justifyContent: "center" },
  paginationContainer: { flexDirection: "row", alignSelf: "center", marginTop: 7 },
});
