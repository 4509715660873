/* eslint-disable react-native/no-inline-styles */
import { FC, memo, useEffect, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";

import { Colors, Fonts } from "theme";
import { BOOKING_FLIGHT_STATUS, BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { AppText, Button, CopyContainer, FeatureWrapper, IconCustom, TextView } from "components";
import WeirdTextView from "./WeirdTextView";
import dayjs from "dayjs";
import LanguageStatus from "constants/LanguageStatus";
import { formatTime, getTitleI18n } from "utils";
import { BusTicketFromIcon, BusTicketToIcon } from "assets/images/svg/icons";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { BookingPolicy, getTransformedData, TransportData } from "./mappingUtils";
import PolicyNote, { FlightBookingPolicy } from "./PolicyNote";
import RequestStatus from "constants/RequestStatus";
import { useNavigation } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import isBetween from "dayjs/plugin/isBetween";
import { StackNavigation } from "navigation/type";
import { BokCabinClass, BookingInformation, FlightTicketType } from "screens/FlightBooking/types";
import { useFlightBookingApis } from "screens/FlightBooking/context/FlightBookingProvider";
import ReservationStatusBadge from "screens/FlightBooking/components/ReservationStatusBadge";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useMasterData } from "contexts/MasterDataContext";

dayjs.extend(isBetween);
const CabinClassMaxSet = {
  1: BokCabinClass.Economy,
  2: BokCabinClass.DeluxeEconomy,
  3: BokCabinClass.Business,
  4: BokCabinClass.First,
  5: BokCabinClass.Suites,
};
export enum TransportationTypes {
  Airplane = "ExpFlightBooking",
  Bus = "ExpTransportationBooking",
}

type TransportationSectionProps = {
  title: string;
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
  type: TransportationTypes;
};

const TransportationSection: FC<TransportationSectionProps> = ({ type, title, expenseRequest }) => {
  const bookingCtxApis = useFlightBookingApis();
  const {
    setting: { request: requestConfig },
  } = useMasterData();
  const navigation = useNavigation<StackNavigation>();
  const { t, i18n } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  useEffect(() => {
    dayjs.locale(i18n.language === LanguageStatus.VN ? "vi" : "en");
  }, [i18n.language]);

  const bookingInfo: ReturnType<typeof getTransformedData> = useMemo(() => {
    const expenseTravels = expenseRequest?.expenseTravels ?? [];
    const expenseBookings = expenseTravels.flatMap((expenseTravel) => expenseTravel.expenseBookings);

    const bookingMatchType = expenseBookings.find((booking) => booking.bookingSettings.__typename === type);
    if (!bookingMatchType) {
      return null;
    }

    return getTransformedData(bookingMatchType);
  }, [type, expenseRequest]);

  if (!bookingInfo) {
    return null;
  }
  const handleViewTicket = (status) => () => {
    if (status === BOOKING_FLIGHT_STATUS.ISSUED) {
      navigation.navigate(SCREEN_NAME.FlightTicketDetailScreen, {
        expenseRequestId: expenseRequest?.expenseRequestId,
      });
    } else if (status === BOOKING_FLIGHT_STATUS.HOLD) {
      navigation.navigate(SCREEN_NAME.ReservationOverviewScreen, {
        expenseRequestId: expenseRequest?.expenseRequestId,
      });
    }
  };

  const bookingSettings = bookingInfo?.bookingSettings as TransportData;
  const bookingPolicies = bookingInfo?.bookingPolicies as BookingPolicy;

  const handleBookTicket = () => {
    const locationPolicies = (bookingPolicies as any).locationPolicies ?? (bookingPolicies as any).locationPolices;

    const flightBooking = expenseRequest.expenseTravels?.[0]?.expenseBookings?.find(
      (x) => x.bookingType === BOOKING_TYPE.FLIGHT
    );

    const routeParams = {
      fromScreen: SCREEN_NAME.DetailRequestScreen,
      expenseRequestId: expenseRequest?.expenseRequestId,
      expenseTravelId: expenseRequest.expenseTravels[0].expenseTravelId,
      type: FlightTicketType.Departure,
      departureDate: bookingSettings?.departureDate
        ? dayjs(bookingSettings.departureDate).startOf("day").toISOString()
        : null,
      returnDate: bookingSettings?.returnDate ? dayjs(bookingSettings.returnDate).startOf("day").toISOString() : null,
      cabinClass: CabinClassMaxSet[bookingPolicies.maxSeatLevel],
      fromCode: bookingSettings.departure.station.code,
      fromMaxAmount: locationPolicies?.find((item) => item?.location?.code === bookingSettings.departure.location.code)
        ?.amount,
      toCode: bookingSettings.arrival.station.code,
      toMaxAmount: locationPolicies?.find((item) => item?.location?.code === bookingSettings.arrival.location.code)
        ?.amount,
      expenseBookingId: flightBooking?.expenseBookingId,
    };
    bookingCtxApis.init(expenseRequest?.expenseRequestId);
    navigation.navigate(SCREEN_NAME.FlightSearchScreen, routeParams);
  };
  const renderTicketSection = () => {
    if (type !== TransportationTypes.Airplane || expenseRequest.status !== RequestStatus.APPROVED) {
      return null;
    }
    const flightBooking = expenseRequest.expenseTravels?.[0]?.expenseBookings?.find(
      (x) => x.bookingType === BOOKING_TYPE.FLIGHT
    );
    const booking = flightBooking?.expenseTravelBookings?.[0];
    const { travelDay, minAdvanceBookingDays, maxAdvanceBookingDays } = bookingPolicies;
    const departureAt = dayjs(travelDay);
    const enableSince = maxAdvanceBookingDays ? departureAt.subtract(maxAdvanceBookingDays, "day") : null;
    const expiredAt = minAdvanceBookingDays ? departureAt.subtract(minAdvanceBookingDays, "day") : null;

    const notAllowedInSituations = [
      // chỉ được đăt vé trong một khoảng thời gian
      enableSince && expiredAt && !dayjs().isBetween(enableSince, expiredAt, "day", "[]"),
      // chỉ được đăt vé từ ngày
      enableSince && !expiredAt && !dayjs().isBetween(enableSince, departureAt, "day", "[]"),
      // chỉ được đăt vé đến ngày
      !enableSince && expiredAt && dayjs().isAfter(expiredAt, "day"),
      // không được đăt vé sau ngày muốn đi
      !enableSince && !expiredAt && dayjs().isAfter(departureAt, "day"),
    ];

    const disabled = notAllowedInSituations.some((x) => x);

    if (booking) {
      const bookingInformation = booking.bookingInformation as BookingInformation;
      const listBooking = bookingInformation?.listBooking ?? [];
      const bookingDeparture = listBooking?.[0];
      const bookingReturn = listBooking?.[1];
      return (
        <>
          <View style={styles.reservationSection}>
            <AppText color={Colors.grayscale60} style={[Fonts.BodyXSmall, { marginBottom: 2 }]}>
              {t("reservation_code")}
            </AppText>
            <ReservationStatusBadge style={styles.reservationStatusBadge} status={booking.status} />
            <View style={styles.flexRow}>
              {[bookingDeparture, bookingReturn].map((item, index) => {
                if (!item) {
                  return null;
                }
                return (
                  <View key={index} style={styles.bookingCodeContainer}>
                    <AppText style={Fonts.BodyMedium}>
                      {item?.fares?.[0]?.flights?.[0]?.departure?.code}
                      {" - "}
                      {item?.fares?.[0]?.flights?.[0]?.arrival?.code}
                    </AppText>
                    <CopyContainer
                      value={item?.bookingCode}
                      message={t("booking_code_copied_to_clipboard")}
                      icon={<IconCustom fill={Colors.primary50} name="content-copy" />}
                    >
                      <AppText style={Fonts.SentenceSubtitleLarge} color={Colors.primary50}>
                        {item?.bookingCode}
                      </AppText>
                    </CopyContainer>
                  </View>
                );
              })}
            </View>
          </View>
          {booking?.status === BOOKING_FLIGHT_STATUS.EXPIRED ? (
            <Button style={styles.viewTicketButton} onPress={handleBookTicket} disabled={disabled}>
              {t("book_ticket_again")}
            </Button>
          ) : (
            <Button style={styles.viewTicketButton} onPress={handleViewTicket(booking.status)}>
              {t("view_my_ticket")}
            </Button>
          )}
        </>
      );
    }
    return (
      <View style={styles.gap16}>
        <PolicyNote title={t("employee_policy")} data={bookingPolicies as FlightBookingPolicy} />
        <Button onPress={handleBookTicket} disabled={disabled}>
          {t("book_ticket")}
        </Button>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <AppText style={[Fonts.SentenceSubtitleLarge, styles.mb16]}>{title}</AppText>
      <View style={styles.gap8}>
        {Boolean(requestConfig?.flightBooking?.withVendor) && (
          <TextView value={bookingSettings?.vendorName} label={t("vendor")} />
        )}
        <WeirdTextView
          label={t("location_from")}
          primaryContent={getTitleI18n({
            vi: bookingSettings?.departure?.location?.name,
            en: bookingSettings?.departure?.location?.nameEn,
          })}
          secondaryContent={
            type === TransportationTypes.Airplane
              ? bookingSettings?.departure?.station?.code +
                " - " +
                getTitleI18n({
                  vi: bookingSettings?.departure?.station?.name,
                  en: bookingSettings?.departure?.station?.nameEn,
                })
              : null
          }
          icon={type === TransportationTypes.Airplane ? <IconCustom name="flight-takeoff" /> : <BusTicketFromIcon />}
        />
        <WeirdTextView
          label={t("location_to")}
          primaryContent={getTitleI18n({
            vi: bookingSettings?.arrival?.location?.name,
            en: bookingSettings?.arrival?.location?.nameEn,
          })}
          secondaryContent={
            type === TransportationTypes.Airplane
              ? bookingSettings?.arrival?.station?.code +
                " - " +
                getTitleI18n({
                  vi: bookingSettings?.arrival?.station?.name,
                  en: bookingSettings?.arrival?.station?.nameEn,
                })
              : null
          }
          icon={type === TransportationTypes.Airplane ? <IconCustom name="flight-land" /> : <BusTicketToIcon />}
        />
        <TextView label={t("departure_date")} value={formatTime(bookingSettings.departureDate, "ddd, DD/MM/YYYY")} />
        {Boolean(bookingSettings.departureNote) && (
          <TextView label={t("departure_note")} value={bookingSettings.departureNote} />
        )}
        {Boolean(bookingSettings.returnDate) && (
          <>
            <TextView
              label={`${t("return_date")} (${t("round_trip")})`}
              value={formatTime(bookingSettings.returnDate, "ddd, DD/MM/YYYY")}
            />
            {Boolean(bookingSettings.returnNote) && (
              <TextView label={t("return_note")} value={bookingSettings.returnNote} />
            )}
          </>
        )}
        <FeatureWrapper featureName={FEATURE_FLAGS.RELEASE_SMP_8722}>{renderTicketSection()}</FeatureWrapper>
      </View>
    </View>
  );
};
export default memo(TransportationSection);
const styles = StyleSheet.create({
  viewTicketButton: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: 10,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  gap8: {
    gap: 8,
  },
  gap16: {
    gap: 16,
  },
  reservationSection: {
    gap: 4,
  },
  mb16: {
    marginBottom: 16,
  },
  container: {
    marginTop: 8,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  bookingCodeContainer: {
    flex: 1,
    gap: 2,
  },
  reservationStatusBadge: {
    alignSelf: "flex-start",
  },
});
