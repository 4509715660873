import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#D9D9D9", width = 25, height = 25 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 25 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={width} height={height}>
        <Path fill={fill} d="M0.54834 0.822144H24.54834V24.822144H0.54834z" />
      </Mask>
      <G mask="url(#a)">
        <Path d="M9.048 18.918V6.726l9.577 6.096-9.577 6.096z" fill={fill} />
      </G>
    </Svg>
  );
}

export default SvgComponent;
