// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import Constants from "expo-constants";

import { navigate, navigateBaseOnNotificationPayload } from "app/navigation/RootNavigation";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import * as Device from "expo-device";
import { useAuth } from "./AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import app from "../../app.json";
import useMarkNotification from "screens/Notification/hooks/useMarkNotification";
import { NTF_PLATFORM, NTF_TOKEN_PROVIDER } from "constants/constants";
import Config from "app/config";

const REGISTER_DEVICE = gql`
  mutation MobileNtfRegisterDevice($input: NtfRegisterDeviceInput!) {
    ntfRegisterDevice(input: $input)
  }
`;

const REMOVE_DEVICE = gql`
  mutation MobileNtfRemoveDevice($input: NtfRemoveDeviceInput!) {
    ntfRemoveDevice(input: $input)
  }
`;

const MARK_NOTIFICATION_READ = gql`
  mutation MobileMarkNotificationRead($notificationId: uuid!) {
    update_user_notifications_by_pk(pk_columns: { user_notification_id: $notificationId }, _set: { read_at: "now()" }) {
      payload
    }
  }
`;

interface INotifcationContext {
  removeTokenNotification: () => Promise<any>;
  schedulePushNotification: (
    time: number,
    title: string,
    body: string,
    data: any,
    repeats?: boolean
  ) => Promise<string>;
  cancelScheduledNotification: (id: string) => Promise<null | undefined>;
}

export const NotificationContext = createContext<INotifcationContext>({});

const { Provider, Consumer } = NotificationContext;

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

const NotificationProvider = ({ children }) => {
  const { user, company, selectCompany } = useAuth();
  const [tokenDevice, setTokenDevice] = useState("");
  // const notificationListener = useRef<Subscription_Root>();
  const responseListener = useRef(null);

  const [registerDevice] = useMutation(REGISTER_DEVICE);
  const [removeDevice] = useMutation(REMOVE_DEVICE);

  const [markNotificationRead] = useMutation(MARK_NOTIFICATION_READ);
  const [markReadNotification] = useMarkNotification();

  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  const lastNotificationResponseNotificationId =
    lastNotificationResponse?.notification?.request?.content?.data?.notification_id;

  const registerForPushNotificationsAsync = useCallback(async () => {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();

      let finalStatus = existingStatus;

      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        // alert("Failed to get push token for push notification!");
        return;
      }
      try {
        token = await Notifications.getExpoPushTokenAsync({ development: !Config.IS_PRODUCTION });
        if (token?.data) {
          setTokenDevice(token?.data);
        }
        const metadata = {
          ...Constants.manifest,
          version: app?.expo?.version,
          hash: process.env.GIT_COMMIT_SHA,
        };
        delete metadata?.android?.googleServicesFile;
        await registerDevice({
          variables: {
            input: {
              device: { deviceName: Device.modelName, osVersion: Device.osVersion, metadata: metadata },
              platform:
                Platform.OS === "ios"
                  ? NTF_PLATFORM.IOS
                  : Platform.OS === "android"
                  ? NTF_PLATFORM.ANDROID
                  : NTF_PLATFORM.WEB,
              provider: NTF_TOKEN_PROVIDER.EXPO,
              token: token?.data,
            },
          },
        });
      } catch (error) {}
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }
  }, [registerDevice]);

  const handleNotification = useCallback(
    (data) => {
      try {
        // const formatData = JSON.parse(data);
        const notificationId = data?.notification_id;
        const companyId = data?.company_id;
        setTimeout(() => {
          if (notificationId) {
            try {
              markReadNotification({ variables: { input: { notificationId, read: true, seen: true } } });
            } catch (error) {
              console.log(error);
            }
          }
        }, 2500);

        if (companyId !== company.company_id) {
          selectCompany(companyId);
        }

        if (company.company_id === "32360905-2f83-4afd-a51c-985a95ed3219") {
          navigate(SCREEN_NAME.NotificationScreen);
          return;
        }
        setTimeout(() => {
          navigateBaseOnNotificationPayload(data);
        }, 250);
      } catch (error) {
        console.error(error);
      }
    },
    [company.company_id, markNotificationRead, markReadNotification, selectCompany]
  );

  useEffect(() => {
    if (lastNotificationResponseNotificationId && Platform.OS === "android") {
      const data = lastNotificationResponse?.notification?.request?.content?.data;

      if (data) {
        handleNotification(data);
      }
    }
  }, [lastNotificationResponseNotificationId]);

  const schedulePushNotification = useCallback(
    async (time: number, title: string, body: string, data: any, trigger) => {
      const idNotification = await Notifications.scheduleNotificationAsync({
        content: {
          title,
          body,
          data,
        },
        trigger: trigger || { seconds: time },
      });

      return idNotification;
    },
    []
  );

  const cancelScheduledNotification = useCallback(async (id) => {
    try {
      await Notifications.cancelScheduledNotificationAsync(id);
    } catch (error) {
      return null;
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      registerForPushNotificationsAsync();
      responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
        const data = response?.notification?.request?.content?.data;

        if (data) {
          handleNotification(data);
        }
      });

      Notifications.addNotificationReceivedListener((response) => {
        console.log("addNotificationReceivedListener", response);
      });
    }
  }, [registerForPushNotificationsAsync, user?.id, handleNotification]);

  // useEffect(() => {
  //   responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
  //     const data = response?.notification?.request?.content?.data;

  //     // console.log(response);

  //     Alert.alert("notification", JSON.stringify(data));

  //     if (data) {
  //       handleNotification(data);
  //     }
  //   });

  //   responseListener.current = Notifications.addNotificationReceivedListener((response) => {
  //     Alert.alert("notification", JSON.stringify(response));

  //     // console.log(response?.request, "1234234");
  //     // const data = response?.notification?.request?.content?.data;
  //     // if (data) {
  //     //   handleNotification(data);
  //     // }
  //   });

  //   return () => {
  //     if (responseListener.current) {
  //       Notifications.removeNotificationSubscription(responseListener.current);
  //     }
  //   };
  // }, []);

  const removeTokenNotification = useCallback(async () => {
    try {
      await removeDevice({
        variables: { input: { provider: NTF_TOKEN_PROVIDER.EXPO, token: tokenDevice } },
      });
    } catch (error) {}
  }, [tokenDevice, removeDevice]);

  const value = useMemo(() => {
    return { removeTokenNotification, schedulePushNotification, cancelScheduledNotification };
  }, [removeTokenNotification, schedulePushNotification, cancelScheduledNotification]);

  return <Provider value={value}>{children}</Provider>;
};

NotificationProvider.Consumer = Consumer;

export default NotificationProvider;
