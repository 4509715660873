import React from "react";
import { ActivityIndicator, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Colors } from "theme";
import { AppText } from "components";

interface LoadingViewProps {
  style?: StyleProp<ViewStyle>;
  size?: number | "small" | "large" | undefined;
  title?: string;
}

const LoadingView: React.FC<LoadingViewProps> = ({ style, size, title }) => {
  return (
    <View style={[styles.container, style]}>
      <ActivityIndicator color={Colors.primary50} size={size} />
      {Boolean(title) && <AppText>{title}</AppText>}
    </View>
  );
};
export default LoadingView;
const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: "center", alignItems: "center" },
});
