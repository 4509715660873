import React, { forwardRef, ReactNode, useImperativeHandle, useRef } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useWindowDimensions } from "components/index";

export enum SlideViewSections {
  main = "main",
  side = "side",
}

interface ISlideViewProps {
  renderMain?: () => ReactNode;
  renderSide?: () => ReactNode;
}

export interface SlideViewRef {
  slideIn: (section: SlideViewSections) => void;
}

const SlideView = forwardRef<SlideViewRef, ISlideViewProps>((props, ref) => {
  const { renderMain, renderSide } = props;
  const { width } = useWindowDimensions();

  const scrollViewRef = useRef<ScrollView>(null);

  useImperativeHandle(ref, () => ({
    slideIn,
  }));

  const slideIn = (section: SlideViewSections) => {
    scrollViewRef.current?.scrollTo({
      x: section === SlideViewSections.main ? 0 : width,
      animated: true,
    });
  };

  return (
    <ScrollView
      ref={scrollViewRef}
      horizontal
      scrollEnabled={false}
      showsHorizontalScrollIndicator={false}
      bounces={false}
    >
      <View style={[styles.hFull, { width }]}>{renderMain()}</View>
      <View style={[styles.hFull, { width }]}>{renderSide()}</View>
    </ScrollView>
  );
});

export default SlideView;

const styles = StyleSheet.create({
  hFull: {
    height: "100%",
  },
});
