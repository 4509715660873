import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { Colors } from "theme";

function SvgComponent({ size = 24, color = Colors.grayscale60, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 16v-5m0-9C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M12.006 8h-.01" stroke="#94A3B8" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
}

export default SvgComponent;
