/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useMemo, useReducer } from "react";
import { FlightBookingContextData, FlightTicket } from "screens/FlightBooking/types";
import bookingContextUtils from "./bookingContextUtils";
import { Platform } from "react-native";

const FlightBookingContext = createContext<FlightBookingContextData>({});
const FlightBookingApiContext = createContext({
  init: (payload: string) => {},
  chooseDeparture: (payload: FlightTicket) => {},
  chooseReturn: (payload: FlightTicket) => {},
  saveContactPassenger: (payload: Pick<FlightBookingContextData, "contact" | "passenger">) => {},
  saveBaggageDeparture: (payload: FlightBookingContextData["baggages"]["departure"]) => {},
  saveBaggageReturn: (payload: FlightBookingContextData["baggages"]["return"]) => {},
});

const enum BookingActionType {
  init,
  choose_departure,
  choose_return,
  save_contact_passenger,
  save_baggage_departure,
  save_baggage_return,
}

type BookingAction =
  | { type: BookingActionType.init; payload: string }
  | { type: BookingActionType.choose_departure | BookingActionType.choose_return; payload: FlightTicket }
  | {
      type: BookingActionType.save_contact_passenger;
      payload: Pick<FlightBookingContextData, "contact" | "passenger">;
    }
  | { type: BookingActionType.save_baggage_departure; payload: FlightBookingContextData["baggages"]["departure"] }
  | { type: BookingActionType.save_baggage_return; payload: FlightBookingContextData["baggages"]["return"] };

const reducer = (state: FlightBookingContextData, action: BookingAction): FlightBookingContextData => {
  const { type, payload } = action;
  let nextState;
  switch (type) {
    case BookingActionType.init:
      nextState = { expenseRequestId: payload };
      break;
    case BookingActionType.choose_departure:
      nextState = {
        ...state,
        tickets: {
          ...state.tickets,
          departure: payload,
        },
      };
      break;
    case BookingActionType.choose_return:
      nextState = {
        ...state,
        tickets: {
          ...state.tickets,
          return: payload,
        },
      };
      break;
    case BookingActionType.save_contact_passenger:
      nextState = {
        ...state,
        passenger: payload.passenger,
        contact: payload.contact,
      };
      break;
    case BookingActionType.save_baggage_departure:
      nextState = {
        ...state,
        baggages: {
          ...state.baggages,
          departure: payload,
        },
      };
      break;
    case BookingActionType.save_baggage_return:
      nextState = {
        ...state,
        baggages: {
          ...state.baggages,
          return: payload,
        },
      };
      break;
    default:
      nextState = state;
      break;
  }

  if (Platform.OS === "web") {
    bookingContextUtils.setStorage(nextState);
  }

  return nextState;
};

const FlightBookingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, Platform.OS === "web" ? bookingContextUtils.getStorage() : {});

  const apis = useMemo(
    () => ({
      init: (payload) => dispatch({ type: BookingActionType.init, payload }),
      chooseDeparture: (payload) => dispatch({ type: BookingActionType.choose_departure, payload }),
      chooseReturn: (payload) => dispatch({ type: BookingActionType.choose_return, payload }),
      saveContactPassenger: (payload) => dispatch({ type: BookingActionType.save_contact_passenger, payload }),
      saveBaggageDeparture: (payload) => dispatch({ type: BookingActionType.save_baggage_departure, payload }),
      saveBaggageReturn: (payload) => dispatch({ type: BookingActionType.save_baggage_return, payload }),
    }),
    []
  );

  return (
    <FlightBookingContext.Provider value={state}>
      <FlightBookingApiContext.Provider value={apis}>{children}</FlightBookingApiContext.Provider>
    </FlightBookingContext.Provider>
  );
};

const useFlightBooking = () => useContext(FlightBookingContext);
const useFlightBookingApis = () => useContext(FlightBookingApiContext);

export default FlightBookingProvider;
export { useFlightBooking, useFlightBookingApis };
