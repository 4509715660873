import React, { memo, useMemo, useRef, forwardRef, useState, useEffect, useImperativeHandle } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import {
  AppText,
  Button,
  FloatFooter,
  KeyboardAwareScrollViewCustom,
  BottomSheetModalCustomMethods,
  BottomSheetModalCustom,
  TextInputCustom,
  SelectCustom,
  SlideView,
  ToastManager,
} from "components";

import { Fonts } from "theme";
import { ArrowLeftIcon, CloseIcon } from "assets/images/svg/icons";
import BankListView from "../BankListView";
import useBanksQuery from "screens/Expense/hooks/useBanksQuery";
import { useForm } from "react-hook-form";
import { ACCOUNT_TYPES, CONSTANTS } from "constants/constants";
import { PaymentInfoFormValues } from "../ExpenseForm/types";
import { SlideViewRef, SlideViewSections } from "components/SlideView";
import useUpdateEmployeeProfile from "hooks/ussUpdateProfile";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Toast } from "@ant-design/react-native";
import { convertViToEn } from "utils";

export interface PaymentInfoFormRef {
  present: ({ accountType, values }: { accountType?: string; values?: PaymentInfoFormValues }) => void;
  close: () => void;
}
interface PaymentInfoFormProps {
  onSuccess?: (values: PaymentInfoFormValues) => void;
}

const initialValues: PaymentInfoFormValues = {
  description: "",
  bankOption: null,
  accountNumber: "",
  accountHolderName: "",
  branchName: "",
};

const PaymentInfoForm = forwardRef<PaymentInfoFormRef, PaymentInfoFormProps>(({ onSuccess }, ref) => {
  useImperativeHandle(ref, () => ({
    present: handlePresent,
    close: handleClose,
  }));
  const { t } = useTranslation("app/screens/Expense/components/PaymentInfoForm");
  const accountTypeRef = useRef<ACCOUNT_TYPES | undefined>();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm<PaymentInfoFormValues>({
    defaultValues: initialValues,
    mode: CONSTANTS.COMMON.VALIDATE_RULE,
  });

  const handleClose = () => {
    paymentInfoBottomSheetRef.current?.close();
  };

  const handlePresent = (params: { accountType: ACCOUNT_TYPES; values?: PaymentInfoFormValues }) => {
    accountTypeRef.current = params?.accountType;
    reset(params?.values || initialValues);
    refetch();
    paymentInfoBottomSheetRef?.current?.present();
  };

  const snapPoints = useMemo(() => [CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT], []);
  const slideViewRef = useRef<SlideViewRef>(null);
  const paymentInfoBottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

  const {
    user: { employee_id: employeeId },
  } = useAuth();
  const { updateEmployeeProfile } = useUpdateEmployeeProfile();

  const onSubmit = async (values: PaymentInfoFormValues) => {
    try {
      Toast.loading("");
      const selectedBank = values?.bankOption?.metadata;
      await updateEmployeeProfile({
        employeeId: employeeId,
        input: {
          bankAccountBook: {
            accountNumber: values?.accountNumber,
            aliasName: values?.description,
            bankCode: selectedBank?.bankCode,
            bankId: selectedBank?.bankId,
            bankName: selectedBank?.bankName,
            branchName: values?.branchName,
            logoUrl: selectedBank?.logoUrl,
            accountHolderName: values?.accountHolderName,
            bankShortName: selectedBank?.bankShortName,
            type: accountTypeRef.current,
          },
        },
      });
      ToastManager.success(t("create_successfully"));
      onSuccess(values);
      handleClose();
      Toast.removeAll();
    } catch (error) {
      Toast.removeAll();
      handleClose();
    }
  };

  const [, { data, refetch, loading }] = useBanksQuery();
  const banks = data?.expBanks?.banks || [];

  const [textSearch, setTextSearch] = useState("");
  const [filteredBanks, setFilteredBanks] = useState([]);

  useEffect(() => {
    if (textSearch) {
      const textSearchInLowerCase = convertViToEn(textSearch).toLocaleLowerCase();
      const filtered = banks.filter((bank) =>
        [bank.bankShortName, bank.bankCode, bank.bankName].some((t) =>
          convertViToEn(String(t)).toLocaleLowerCase().includes(textSearchInLowerCase)
        )
      );
      setFilteredBanks(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearch]);

  const handleSelectBank = (bank) => {
    setValue("bankOption", { key: bank.bankId, label: `${bank.bankShortName} (${bank.bankCode})`, metadata: bank });
    slideViewRef?.current?.slideIn(SlideViewSections.main);
  };

  const renderMain = () => (
    <>
      <View style={styles.header}>
        <AppText style={Fonts.H400}>{t("title")}</AppText>
        <TouchableOpacity style={styles.buttonClose} onPress={handleClose}>
          <CloseIcon />
        </TouchableOpacity>
      </View>
      <KeyboardAwareScrollViewCustom
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="on-drag"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.mb}>
          <SelectCustom
            name="bankOption"
            control={control}
            onPress={() => slideViewRef.current?.slideIn(SlideViewSections.side)}
            label={t("bank")}
            rules={{ required: t("required") }}
          />
        </View>
        <TextInputCustom
          name="accountNumber"
          style={styles.mb}
          control={control}
          label={t("account_number")}
          placeholder={t("account_number")}
          keyboardType="numeric"
          error={errors.accountNumber}
          rules={{ required: t("required") }}
        />
        <TextInputCustom
          name="accountHolderName"
          style={styles.mb}
          control={control}
          label={t("account_holder_name")}
          placeholder={t("account_holder_name")}
          error={errors.accountHolderName}
          rules={{ required: t("required") }}
        />
        <TextInputCustom
          name="branchName"
          style={styles.mb}
          control={control}
          label={t("branch_name")}
          placeholder={t("branch_name")}
          error={errors.branchName}
        />
        <TextInputCustom
          name="description"
          style={styles.mb}
          control={control}
          label={t("description")}
          placeholder={t("description")}
          error={errors.description}
        />
      </KeyboardAwareScrollViewCustom>
      <FloatFooter style={styles.gridRow2}>
        <Button onPress={paymentInfoBottomSheetRef.current.close} type="secondary" style={styles.bottomButton}>
          {t("cancel")}
        </Button>
        <Button onPress={handleSubmit(onSubmit)} type="primary" style={styles.bottomButton}>
          {t("save")}
        </Button>
      </FloatFooter>
    </>
  );

  const renderSide = () => (
    <>
      <View style={styles.headerBankCategory}>
        <TouchableOpacity
          style={styles.buttonBackFilter}
          onPress={() => {
            slideViewRef.current?.slideIn(SlideViewSections.main);
            setTextSearch("");
          }}
        >
          <ArrowLeftIcon />
        </TouchableOpacity>
        <AppText style={[Fonts.H400, { marginLeft: 10 }]}>{t("select_a_bank")}</AppText>
      </View>

      <BankListView
        data={textSearch ? filteredBanks : banks}
        textSearch={textSearch}
        onChangeSearch={setTextSearch}
        handleClearSearch={() => setTextSearch("")}
        loading={loading}
        handleSelectBank={handleSelectBank}
      />
    </>
  );

  return (
    <BottomSheetModalCustom ref={paymentInfoBottomSheetRef} snapPoints={snapPoints} showHeader={false}>
      <SlideView ref={slideViewRef} renderMain={renderMain} renderSide={renderSide} />
    </BottomSheetModalCustom>
  );
});

export default memo(PaymentInfoForm);

const styles = StyleSheet.create({
  mb: {
    marginBottom: 16,
  },
  gridRow2: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    paddingBottom: 0,
  },
  bottomButton: {
    flex: 1,
    ...Fonts.BodyLarge,
  },
  contentContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingTop: 10 },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 15,
    paddingBottom: 15,
  },
  buttonClose: { padding: 5 },
  headerBankCategory: {
    paddingTop: 17,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonBackFilter: { paddingHorizontal: 5, paddingVertical: 2 },
});
