import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#D9D9D9" d="M0.563965 0.888916H24.563965V24.888916000000002H0.563965z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M2.564 7.889v-5h5v2h-3v3h-2zm0 15v-5h2v3h3v2h-5zm15 0v-2h3v-3h2v5h-5zm3-15v-3h-3v-2h5v5h-2zm-2.5 10.5h1.5v1.5h-1.5v-1.5zm0-3h1.5v1.5h-1.5v-1.5zm-1.5 1.5h1.5v1.5h-1.5v-1.5zm-1.5 1.5h1.5v1.5h-1.5v-1.5zm-1.5-1.5h1.5v1.5h-1.5v-1.5zm3-3h1.5v1.5h-1.5v-1.5zm-1.5 1.5h1.5v1.5h-1.5v-1.5zm-1.5-1.5h1.5v1.5h-1.5v-1.5zm6-8v6h-6v-6h6zm-8 8v6h-6v-6h6zm0-8v6h-6v-6h6zm-1.5 12.5v-3h-3v3h3zm0-8v-3h-3v3h3zm8 0v-3h-3v3h3z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
