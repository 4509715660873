import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpPredictLocationsNearbyQuery, MobileExpPredictLocationsNearbyQueryVariables } from "types";

const QUERY = gql`
  query MobileExpPredictLocationsNearby($input: ExpPredictLocationsNearbyInput!) {
    expPredictLocationsNearby(input: $input) {
      predictedLocations {
        mainText
        secondaryText
        latitude
        longitude
      }
    }
  }
`;

const useExpPredictLocations = (variables: MobileExpPredictLocationsNearbyQueryVariables) => {
  return useLazyQuery<MobileExpPredictLocationsNearbyQuery, MobileExpPredictLocationsNearbyQueryVariables>(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpPredictLocations;
