// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ActivityIndicator, Toast } from "@ant-design/react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import BizziIcon from "assets/images/svg/icons/BizziIcon";
import { AppText } from "components";
import { Colors } from "constants/Colors";
import Layout from "constants/Layout";
import Constants from "expo-constants";

import useExpenseRequestOTP from "hooks/useExpenseRequestOTP";
import useExpenseVerifyRequestOTP from "hooks/useExpenseVerifyOTP";
import { OTPInputView } from "layouts";
import get from "lodash/get";
import SCREEN_NAME from "navigation/ScreenName";
import { RegisterOTPScreenNavigationProp, RegisterOTPScreenRouteProp } from "navigation/type";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { Button } from "react-native-paper";
import { stylesCommon } from "./commonStyle";
import RegisterStatus from "./RegisterSatus";
import { BackButtonIcon } from "assets/images/svg/icons";

const PIN_COUNT = 6;

export default function RegisterOTPScreen() {
  const navigation = useNavigation<RegisterOTPScreenNavigationProp>();
  const route = useRoute<RegisterOTPScreenRouteProp>();
  const { t } = useTranslation("screens/RegisterOnboarding/RegisterOTPScreen");
  const { width: WWidth } = useWindowDimensions();
  const IsMobile = WWidth <= 768;
  const { email, phone, fullname, challenge_id, referralBy, ipa_client, employee_title } = route?.params ?? {};
  const [challengeId, setChallengeId] = useState(challenge_id);
  const [code, setCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onVerifyRequestOTP } = useExpenseVerifyRequestOTP();
  const { onRequestOTP, loading: loadingRequestOTP } = useExpenseRequestOTP();

  useEffect(() => {
    setChallengeId(challenge_id);
  }, [challenge_id]);

  const resendOtp = useCallback(async (): Promise<void> => {
    Keyboard.dismiss();
    Toast.removeAll();
    setIsSubmitting(true);
    try {
      const results = await onRequestOTP({
        email,
        requiredExistence: false,
        type: "EXPENSE_REGISTER",
      });

      const newChallengeId = get(results, "data.expenseRequestOTP.challenge_id");

      if (newChallengeId) {
        Toast.success(t("otp_code_has_been_sent_to_x_2", { email }));
        setChallengeId(newChallengeId);
      } else {
        Toast.fail(t("unknown"));
      }
    } catch {
      Toast.fail(t("unknown"));
    }
    setIsSubmitting(false);
  }, [email, onRequestOTP, t]);

  const onCodeFilled = useCallback(
    async (answer: string): Promise<void> => {
      setIsSubmitting(true);
      Keyboard.dismiss();

      try {
        setCode(answer);
        const result = await onVerifyRequestOTP({
          type: "EXPENSE_REGISTER",
          answer,
          challenge_id: challengeId,
        });
        if (get(result, "data.expenseVerifyOTP.success")) {
          setIsSubmitting(false);
          requestAnimationFrame(() => {
            !ipa_client
              ? navigation.navigate(SCREEN_NAME.RegisterPasswordScreen, {
                  email,
                  phone,
                  fullname,
                  referralBy,
                  employee_title,
                })
              : navigation.navigate(SCREEN_NAME.RegisterSetupCompanyScreen, {
                  email,
                  phone,
                  fullname,
                  referralBy,
                  ipa_client,
                  employee_title,
                });
          });
        } else {
          Toast.fail(t("incorrect_opt_number"), 1, () => {
            Keyboard.dismiss();
          });
        }
      } catch {
        Toast.fail(t("unknown"), 1, () => {
          Keyboard.dismiss();
        });
      }
      setIsSubmitting(false);
    },
    [onVerifyRequestOTP, challengeId, ipa_client, navigation, email, phone, fullname, referralBy, t, employee_title]
  );

  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <ScrollView>
        <View style={stylesCommon.container}>
          <View style={IsMobile ? stylesCommon.contentMobile : stylesCommon.content}>
            <View style={stylesCommon.logoContainer}>
              <TouchableOpacity onPress={() => navigation.goBack()} style={stylesCommon.backButton}>
                <BackButtonIcon />
              </TouchableOpacity>
              <BizziIcon />
            </View>
            <View style={IsMobile ? stylesCommon.registerFormMobile : stylesCommon.registerForm}>
              <AppText style={stylesCommon.title}>{t("title")}</AppText>
              <AppText style={styles.description}>{t("otp_code_has_been_sent_to_x", { email })}</AppText>

              <OTPInputView
                pinCount={PIN_COUNT}
                autoFocusOnLoad
                codeInputFieldStyle={styles.underlineStyleBase}
                codeInputHighlightStyle={styles.underlineStyleHighLighted}
                onCodeFilled={onCodeFilled}
              />

              <Button
                uppercase={false}
                mode="contained"
                disabled={code.length !== PIN_COUNT || isSubmitting}
                style={styles.confirm}
                onPress={() => onCodeFilled(code)}
                contentStyle={{ paddingVertical: 3 }}
              >
                {t("confirm")}
              </Button>

              <View style={styles.wrapper_resend_code}>
                <AppText style={styles.resendCodeLabel}>{t("did_not_receive_code")}</AppText>
                <TouchableOpacity onPress={resendOtp}>
                  <AppText style={styles.resendCode}>{t("resend_code")}</AppText>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {!IsMobile && (
            <View style={stylesCommon.status}>
              <RegisterStatus activeStep={1} />
            </View>
          )}
        </View>
        {(loadingRequestOTP || isSubmitting) && <ActivityIndicator animating toast size="large" />}
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  description: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray80,

    marginBottom: 60,
  },
  resendCodeLabel: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray100,
  },
  resendCode: {
    fontSize: 16,
    color: Colors.blue500,
    textAlign: "left",
    marginLeft: 3,
    lineHeight: 24,
  },
  underlineStyleBase: {
    width: 80,
    height: 90,
    borderWidth: 0,
    backgroundColor: Colors.white500,
    borderRadius: 8,
  },
  underlineStyleHighLighted: {
    borderBottomColor: Colors.blue500,
  },
  wrapper_resend_code: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 40,
  },
  confirm: {
    marginTop: 24,
  },
});
