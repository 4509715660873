// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useLayoutEffect, useRef } from "react";
import { Linking, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import useSupportedAuthenticationTypes from "hooks/useSupportedAuthenticationTypes";

import FaceIdIcon from "assets/images/svg/icons/FaceId";
import FingerPrint from "assets/images/svg/icons/FingerPrint";
import { useNavigation } from "@react-navigation/native";

import useEnrolledLocalAuth from "hooks/useEnrolledLocalAuth";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { AlertNotification, AlertNotificationHandle, AppText, BackHeader, SwitchButton } from "components";
import { BizziBotWarning2, IrisIcon, BiometricsIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";

const LocalAuthenticationScreen = () => {
  const { isFaceIdAvailable, isFingerprintAvailable, isIrisAvailable, isMultipleSupport } =
    useSupportedAuthenticationTypes();
  const { isEnrolled, onEnrolled, removeLocalAuth, isShowWarning } = useEnrolledLocalAuth();

  const { t } = useTranslation("screens/ProfileTab/LocalAuthenticationScreen");
  const navigation = useNavigation<any>();
  const alertBottomSheetRef = useRef<AlertNotificationHandle>(null);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("verify_way")} />,
    } as any);
  }, [navigation, t]);
  useEffect(() => {
    if (isShowWarning) {
      alertBottomSheetRef.current?.confirm({
        icon: <BizziBotWarning2 />,
        title: t("verify_way"),
        description: t("face_id_description_require_permission"),
        confirmText: t("open_setting"),
        cancelText: t("cancel"),
        onConfirm: () => {
          alertBottomSheetRef.current?.close();
          Linking.openSettings();
        },
      });
    }
  }, [isShowWarning]);
  const renderIcon = () => {
    if (isMultipleSupport) {
      return <BiometricsIcon width={125} height={126} color={isEnrolled ? "#0086FF" : Colors.grayscale60} />;
    }
    return isFingerprintAvailable ? (
      <FingerPrint color={isEnrolled ? "#0086FF" : Colors.grayscale60} height={120} width={120} />
    ) : isFaceIdAvailable ? (
      <FaceIdIcon color={isEnrolled ? "#0086FF" : Colors.grayscale60} height={120} width={120} />
    ) : isIrisAvailable ? (
      <IrisIcon color={isEnrolled ? "#0086FF" : Colors.grayscale60} />
    ) : null;
  };
  const renderLabel = () => {
    if (isMultipleSupport) {
      return t("biometrics");
    }
    return isFingerprintAvailable
      ? t("finger_print")
      : isFaceIdAvailable
      ? t("face_id")
      : isIrisAvailable
      ? t("iris")
      : "";
  };
  return (
    <View style={styles.container}>
      {renderIcon()}
      <AppText style={styles.hintText}>{renderLabel()}</AppText>
      <View style={styles.rowWithSpaceBetween}>
        <Text>{isEnrolled ? t("turn_on") : t("turn_off")}</Text>
        <SwitchButton
          value={isEnrolled}
          onValueChange={(e) => {
            if (!e) {
              removeLocalAuth();
            } else {
              onEnrolled();
            }
          }}
        />
      </View>
      <AlertNotification ref={alertBottomSheetRef} />
    </View>
  );
};

export default LocalAuthenticationScreen;

const styles = StyleSheet.create({
  container: {
    paddingTop: 100,
    flex: 1,
    alignItems: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
  },
  hintText: {
    ...Fonts.H400,
    marginVertical: 20,
    textAlign: "center",
  },
  rowWithSpaceBetween: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.grayscale0,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 16,
  },
});
