import * as React from "react";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";
const SVGComponent = ({ color = "#94A3B8", ...props }) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G clipPath="url(#clip0_6885_441504)">
      <Path
        d="M17 11.9V16.1C17 19.6 15.6 21 12.1 21H7.9C4.4 21 3 19.6 3 16.1V11.9C3 8.4 4.4 7 7.9 7H12.1C15.6 7 17 8.4 17 11.9Z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21 7.9V12.1C21 15.6 20.5 17 17 17V13.5C17 9.5 17 7 10.5 7H7C7 4 8.4 3 11.9 3H16.1C19.6 3 21 4.4 21 7.9Z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_6885_441504">
        <Rect width={24} height={24} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SVGComponent;
