import { BottomSheetFlatList } from "@gorhom/bottom-sheet";
import { FC } from "react";
import { FlatList, FlatListProps, Platform } from "react-native";

const BottomSheetFlatListCustom: FC<FlatListProps<any>> = (props) => {
  if (Platform.OS === "web") {
    return <FlatList {...props} />;
  }
  return <BottomSheetFlatList {...props} />;
};
export default BottomSheetFlatListCustom;
