import { AlertNotification, AlertNotificationHandle, BackHeader, StatusBarCustom, ToastManager } from "components";
import { useAuth } from "contexts/AuthContext";
import { FC, useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors } from "theme";
import CompanyList from "../components/CompanyList";
import i18n from "i18next";

interface SelectCompanyScreenProps {
  navigation: any;
  route: any;
}

const SwitchCompanyScreen: FC<SelectCompanyScreenProps> = ({ navigation, route }) => {
  const {
    user,
    selectCompany,
    company: { company_id: companyId },
  } = useAuth();
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);

  const companies = useMemo(() => user.employees?.map((emp) => emp.company)?.filter(Boolean) ?? [], [user.employees]);
  const shouldBackToTop = route?.params?.shouldBackToTop ?? true;

  const { t } = useTranslation("app/screens/Company/SwitchCompanyScreen/SwitchCompanyScreen");

  const onSelect = useCallback(
    async (company_id) => {
      try {
        alertNotificationRef.current?.loading();
        await selectCompany(company_id);
        if (shouldBackToTop) {
          navigation.popToTop();
        } else {
          navigation.goBack();
        }
        alertNotificationRef.current?.close();
        ToastManager.success(t("switch_company_success"));
      } catch (e) {
        alertNotificationRef.current?.error({
          title: i18n.t("common:error"),
          description: i18n.t("common:internal_error"),
        });
      }
    },
    [navigation, selectCompany, t, shouldBackToTop]
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("switch_company_header")} onPress={() => navigation.goBack()} />,
    } as any);
  }, [navigation, t]);

  return (
    <>
      <StatusBarCustom barStyle="dark-content" />
      <View style={StyleSheet.flatten([styles.container])}>
        <CompanyList activeCompanyId={companyId} companies={companies} onSelect={onSelect} />
      </View>
      <AlertNotification ref={alertNotificationRef} />
    </>
  );
};

export default SwitchCompanyScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale05,
    flexDirection: "column",
  },
});
