import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileOrgEmployeeInfoQuery, MobileOrgEmployeeInfoQueryVariables } from "types";

const QUERY = gql`
  query MobileOrgEmployeeInfo($companyId: UUID!, $employeeId: UUID!) {
    orgEmployeeInfo(companyId: $companyId, employeeId: $employeeId) {
      fullname
      phone
      email
      avatarUrl
      defaultAvatarUrl
      employeeCode
      teams {
        code
        name
        nameEn
      }
      title
      employeeId
    }
  }
`;
const useGetEmployeeInfo = (variables?: MobileOrgEmployeeInfoQueryVariables) => {
  return useLazyQuery<MobileOrgEmployeeInfoQuery, MobileOrgEmployeeInfoQueryVariables>(QUERY, { variables });
};

export default useGetEmployeeInfo;
