import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const REMOVE_REQUEST_ATTACHMENT = gql`
  mutation MobileUpdateExpenseAttachmentsByPk(
    $expense_request_attachment_id: uuid!
    $attachment: expense_request_attachments_set_input!
  ) {
    update_expense_request_attachments_by_pk(
      pk_columns: { expense_request_attachment_id: $expense_request_attachment_id }
      _set: $attachment
    ) {
      expense_request_attachment_id
    }
  }
`;

const ADD_REQUEST_ATTACHMENT = gql`
  mutation MobileInsertExpenseRequestAttachments($attachments: [expense_request_attachments_insert_input!]!) {
    insert_expense_request_attachments(objects: $attachments) {
      affected_rows
      returning {
        expense_request_attachment_id
      }
    }
  }
`;

const useRequestAttachment = () => {
  const [deleteRequestAttachments] = useMutation<{
    update_expense_request_attachments_by_pk: ExpenseRequestAttachments;
  }>(REMOVE_REQUEST_ATTACHMENT);
  const [addRequestAttachments] = useMutation<{
    insert_expense_request_attachments: { returning: ExpenseRequestAttachments };
  }>(ADD_REQUEST_ATTACHMENT);

  return {
    addRequestAttachments,
    deleteRequestAttachments,
  };
};

export default useRequestAttachment;
