import * as React from "react";
import Svg, { Ellipse, Path, Rect } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg width={273} height={123} viewBox="0 0 273 123" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0991 12.8721C90.0991 5.76304 95.8621 0 102.971 0H260.127C267.236 0 273 5.76304 273 12.8721C273 19.9812 267.237 25.7442 260.127 25.7442H201.96C204.876 28.1041 206.741 31.7122 206.741 35.7559C206.741 42.8089 201.068 48.5369 194.037 48.627H230.982C238.091 48.627 243.854 54.39 243.854 61.4991C243.854 68.6082 238.091 74.3712 230.982 74.3712H77.4051C84.4404 74.2854 90.1171 68.5556 90.1171 61.5C90.1171 54.391 84.354 48.6279 77.2449 48.6279H36.6612C29.5756 48.6004 23.8401 42.848 23.8401 35.7559C23.8401 28.6468 29.6032 22.8838 36.7122 22.8838H94.8799C91.9636 20.524 90.0991 16.9158 90.0991 12.8721ZM0 87.245C0 80.1359 5.76305 74.3729 12.8721 74.3729H196.536C197.169 74.3729 197.791 74.4186 198.4 74.5068H191.341C185.032 74.5068 179.917 79.6217 179.917 85.9312C179.917 92.2407 185.032 97.3556 191.341 97.3556H214.043C220.392 98.1478 225.305 103.564 225.305 110.128C225.305 117.237 219.542 123 212.433 123H73.8316C66.7225 123 60.9594 117.237 60.9594 110.128C60.9594 106.085 62.8236 102.477 65.7393 100.117H12.8721C5.76306 100.117 0 94.3541 0 87.245Z"
      fill="#F0F6FF"
    />
    <Rect x={192.984} y={101.52} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
    <Rect x={192.984} y={111.936} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={195.61}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05865}
      transform="rotate(-90 195.61 111.936)"
      fill="#75A4FE"
    />
    <Rect
      x={183.836}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05865}
      transform="rotate(-90 183.836 111.936)"
      fill="#75A4FE"
    />
    <Path
      d="M188.674 74.3723C188.674 76.9708 186.556 79.0855 183.933 79.0855C181.311 79.0855 179.193 76.9708 179.193 74.3723C179.193 71.7738 181.311 69.6592 183.933 69.6592C186.556 69.6592 188.674 71.7738 188.674 74.3723Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.01559}
    />
    <Ellipse cx={82.7849} cy={37.054} rx={4.25457} ry={4.23462} fill="#75A4FE" />
    <Rect x={96.6133} y={5.29297} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
    <Rect x={96.7451} y={15.2559} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={98.7393}
      y={14.8213}
      width={2.11731}
      height={7.44551}
      rx={1.05865}
      transform="rotate(-90 98.7393 14.8213)"
      fill="#75A4FE"
    />
    <Path
      d="M90.2256 15.2559C89.641 15.2559 89.167 14.7819 89.167 14.1972V14.1972C89.167 13.6125 89.641 13.1385 90.2256 13.1385L95.5538 13.1385C96.1385 13.1385 96.6125 13.6125 96.6125 14.1972V14.1972C96.6125 14.7819 96.1385 15.2559 95.5538 15.2559L90.2256 15.2559Z"
      fill="#75A4FE"
    />
    <Path
      d="M133.574 22.792C121.3 22.792 111.324 32.7674 111.324 45.042V55.7591C111.324 58.0212 110.36 61.4699 109.211 63.3982L104.946 70.4812C102.313 74.857 104.13 79.7149 108.951 81.3466C124.934 86.6866 142.178 86.6866 158.161 81.3466C162.648 79.8632 164.613 74.5603 162.166 70.4812L157.901 63.3982C156.789 61.4699 155.824 58.0212 155.824 55.7591V45.042C155.824 32.8045 145.812 22.792 133.574 22.792Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <Path
      d="M133.568 31.0713C124.574 31.0713 117.265 38.4566 117.265 47.5442V55.4786C117.265 57.1534 116.558 59.7067 115.716 61.1343L112.591 66.3782C110.662 69.6179 111.993 73.2145 115.525 74.4225C127.237 78.376 139.872 78.376 151.584 74.4225C154.872 73.3243 156.312 69.3983 154.518 66.3782L151.394 61.1343C150.578 59.7067 149.872 57.1534 149.872 55.4786V47.5442C149.872 38.4841 142.535 31.0713 133.568 31.0713Z"
      fill="#F0F6FF"
    />
    <Path
      d="M140.435 23.8659C139.285 23.5321 138.098 23.2725 136.875 23.1242C133.315 22.6792 129.903 22.9388 126.714 23.8659C127.789 21.1217 130.459 19.1934 133.574 19.1934C136.689 19.1934 139.359 21.1217 140.435 23.8659Z"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M144.699 83.7451C144.699 89.8639 139.693 93.8059 133.574 93.8059C130.533 93.8059 127.329 93.0129 125.326 91.0104C123.056 88.74 122.449 87.8502 122.449 84.8094"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeMiterlimit={10}
    />
    <Path
      d="M137.133 76.9334C141.371 76.6307 150.574 75.4804 153.48 73.3008"
      stroke="#1F71F4"
      strokeWidth={2.72449}
      strokeLinecap="round"
    />
  </Svg>
);
export default SVGComponent;
