// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  // eslint-disable-next-line react-native/split-platform-components
  ActionSheetIOS,
  ActivityIndicator,
  FlatList,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import * as MediaLibrary from "expo-media-library";
import * as ImagePicker from "expo-image-picker";
import { WIDTH_WINDOW } from "app/constants/Layout";
import { AppText, BackHeader, BizziButton } from "app/components";
import ImageTile from "./ItemImage";
import { useTranslation } from "react-i18next";
import { Button, FloatFooter } from "components";
import { useNavigation } from "@react-navigation/native";
import { Colors, Fonts } from "theme";
const numColumns = 4;
const loadCount = 50;

const ImageBrowser = ({ renderSelectedComponent, loadCompleteMetadata, callback, max }) => {
  const navigation = useNavigation();
  const { t } = useTranslation("app/screens/ImageBrowser/ImageBrowserScreen");
  const [photos, setPhotos] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [after, setAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasCameraRollPermission, setHasCameraRollPermission] = useState(null);
  const [isCheckPermission, setIsCheckPermission] = useState(false);
  const [accessType, setAccessType] = useState<"all" | "limited">();
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("browsing_images")}
          headerRight={
            accessType !== "all" && Platform.OS === "ios" ? (
              <TouchableOpacity
                onPress={() =>
                  ActionSheetIOS.showActionSheetWithOptions(
                    {
                      options: [t("cancel"), t("select_more_photo"), t("change_setting_photo")],
                      cancelButtonIndex: 0,
                    },
                    (buttonIndex) => {
                      if (buttonIndex === 0) {
                        // cancel action
                      } else if (buttonIndex === 1) {
                        // select more photos
                        MediaLibrary.presentPermissionsPickerAsync();
                      } else if (buttonIndex === 2) {
                        // change permission from setting app
                        onPressOpenSetting();
                      }
                    }
                  )
                }
              >
                <AppText style={Fonts.SentenceBodyMedium} color={Colors.primary50}>
                  {t("manage_photo")}
                </AppText>
              </TouchableOpacity>
            ) : null
          }
        />
      ),
    } as any);
  }, [navigation, t, selected, accessType]);

  useEffect(() => {
    getPermissionsAsync();
    const mediaListener = MediaLibrary.addListener(() => {
      handleGetPhotos();
    });
    return () => {
      mediaListener.remove();
    };
  }, []);
  const getPermissionsAsync = async () => {
    const { status: mediaStatus } = await MediaLibrary.requestPermissionsAsync();
    const { status: cameraRollStatus } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    const permission = await MediaLibrary.getPermissionsAsync();
    setAccessType(permission.accessPrivileges);
    setHasCameraRollPermission(cameraRollStatus === "granted" && mediaStatus === "granted");
    setIsCheckPermission(true);
    handleGetPhotos();
  };
  const handleGetPhotos = () => {
    const params = {
      first: loadCount,
      assetType: MediaLibrary.MediaType.photo,
      sortBy: [MediaLibrary.SortBy.creationTime],
      // album: "photos",
    };
    MediaLibrary.getAssetsAsync({ ...params }).then((data) => processPhotos(data, true));
  };
  const handleLoadMorePhotos = () => {
    if (!hasCameraRollPermission || !photos?.length) {
      return;
    }
    const params = {
      first: loadCount,
      assetType: MediaLibrary.MediaType.photo,
      sortBy: [MediaLibrary.SortBy.creationTime],
      // album: "photos",
    };
    if (after) {
      params.after = after;
    }
    if (!hasNextPage) {
      return;
    }
    MediaLibrary.getAssetsAsync({ ...params }).then(processPhotos);
  };
  const processPhotos = (data, isFirstPage = false) => {
    if (data.totalCount) {
      if (!isFirstPage && after === data.endCursor) {
        return;
      }
      const uris = data.assets;
      setPhotos(isFirstPage ? uris : [...photos, ...uris]);
      setAfter(data.endCursor);
      setHasNextPage(data.hasNextPage);
    } else {
      if (isFirstPage) {
        setPhotos([]);
      }
      setIsEmpty(true);
      setHasNextPage(false);
    }
  };

  const getItemLayout = (data, index) => {
    const length = WIDTH_WINDOW / 4;
    return { length, offset: length * index, index };
  };

  const renderImageTile = ({ item, index }) => {
    const newSelected = selected.indexOf(index) !== -1;
    const selectedItemNumber = selected.indexOf(index) + 1;
    return (
      <ImageTile
        selectedItemNumber={selectedItemNumber}
        uri={item?.uri}
        index={index}
        selected={newSelected}
        selectImage={selectImage}
        renderSelectedComponent={renderSelectedComponent}
      />
    );
  };

  const selectImage = (index: number) => {
    let newSelected = Array.from(selected);
    if (newSelected.indexOf(index) === -1) {
      newSelected.push(index);
    } else {
      const deleteIndex = newSelected.indexOf(index);
      newSelected.splice(deleteIndex, 1);
    }
    if (newSelected.length > max) {
      return;
    }
    if (!newSelected) {
      newSelected = [];
    }
    setSelected(newSelected);
  };

  const renderEmptyStay = () => {
    return (
      <View style={styles.emtyStyle}>
        <TouchableOpacity>
          <AppText style={styles.titleEmtyStyle}>{t("empty_description")}</AppText>
        </TouchableOpacity>
      </View>
    );
  };

  const onPressOpenSetting = () => {
    Linking.openSettings();
  };

  const renderPreloader = () => {
    if (isCheckPermission && !hasCameraRollPermission) {
      return (
        <View style={styles.preLoaderStyle}>
          <TouchableOpacity>
            <AppText style={styles.titlePreLoaderStyle}>{t("description_require_permission")}</AppText>
          </TouchableOpacity>
          <BizziButton
            color={Colors.alert50}
            title={t("button_title_open_setting")}
            onPress={onPressOpenSetting}
            style={{ marginTop: 30, maxWidth: 150 }}
          />
        </View>
      );
    }
  };

  const prepareCallback = async () => {
    const selectedPhotos = selected.map((i) => photos[i]);
    if (!loadCompleteMetadata) {
      callback(Promise.all(selectedPhotos));
    } else {
      const assetsInfo = Promise.all(selectedPhotos.map((i) => MediaLibrary.getAssetInfoAsync(i)));
      callback(assetsInfo);
    }
  };

  const listFooterComponent = () => {
    if (hasNextPage && isCheckPermission && hasCameraRollPermission) {
      return (
        <View style={styles.loadingStyle}>
          <ActivityIndicator size="small" color={Colors.primary50} />
        </View>
      );
    }
    return null;
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={photos}
        contentContainerStyle={styles.contentContainer}
        extraData={selected}
        scrollEventThrottle={16}
        numColumns={4}
        key={numColumns}
        renderItem={renderImageTile}
        //keyExtractor={this.keyExtractor}
        onEndReached={handleLoadMorePhotos}
        onEndReachedThreshold={0.5}
        ListEmptyComponent={isEmpty ? renderEmptyStay() : renderPreloader()}
        initialNumToRender={24}
        getItemLayout={getItemLayout}
        ListFooterComponent={listFooterComponent}
      />
      <FloatFooter>
        <Button disabled={!selected?.length} onPress={prepareCallback}>
          {t("confirm")}
          {selected?.length ? ` (${selected?.length})` : ""}
        </Button>
      </FloatFooter>
    </View>
  );
};
ImageBrowser.defaultProps = {
  loadCompleteMetadata: true,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  emtyStyle: { paddingHorizontal: 15, paddingTop: 50, alignItems: "center" },
  titleEmtyStyle: { color: Colors.grayscale60, textAlign: "center", fontSize: 16 },
  preLoaderStyle: { paddingHorizontal: 15, paddingTop: 50, alignItems: "center" },
  titlePreLoaderStyle: { color: Colors.alert50, textAlign: "center", fontSize: 16 },
  loadingStyle: { marginVertical: 15 },
  contentContainer: { paddingBottom: 80 },
});
export default ImageBrowser;
