import * as React from "react";
import Svg, { Path, Rect, Ellipse } from "react-native-svg";

const SVGComponent = () => (
  <Svg width={273} height={123} viewBox="0 0 273 123" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0991 12.8721C90.0991 5.76305 95.8621 0 102.971 0H260.127C267.236 0 273 5.76305 273 12.8721C273 19.9812 267.236 25.7443 260.127 25.7443H201.96C204.876 28.1041 206.741 31.7123 206.741 35.7559C206.741 42.8082 201.069 48.5358 194.039 48.627H230.982C238.091 48.627 243.854 54.39 243.854 61.4991C243.854 68.6082 238.091 74.3712 230.982 74.3712H77.2449C84.354 74.3712 90.1171 68.6082 90.1171 61.4991C90.1171 54.39 84.354 48.627 77.2449 48.627H36.542C29.5114 48.5358 23.8401 42.8082 23.8401 35.7559C23.8401 28.6468 29.6032 22.8838 36.7122 22.8838H94.8799C91.9636 20.524 90.0991 16.9158 90.0991 12.8721ZM0 87.2451C0 80.136 5.76305 74.3729 12.8721 74.3729H196.536C197.169 74.3729 197.791 74.4186 198.399 74.5068H193.338C187.028 74.5068 181.913 79.6217 181.913 85.9312C181.913 92.2407 187.028 97.3556 193.338 97.3556H214.043C220.392 98.1479 225.305 103.564 225.305 110.128C225.305 117.237 219.542 123 212.433 123H73.8316C66.7225 123 60.9594 117.237 60.9594 110.128C60.9594 106.085 62.8235 102.477 65.7392 100.117H12.8721C5.76306 100.117 0 94.3541 0 87.2451Z"
      fill="#F0F6FF"
    />
    <Rect x={192.984} y={101.52} width={2.12729} height={7.81221} rx={1.06364} fill="#75A4FE" />
    <Rect x={192.984} y={111.936} width={2.12729} height={7.81221} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={195.611}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05866}
      transform="rotate(-90 195.611 111.936)"
      fill="#75A4FE"
    />
    <Rect
      x={183.836}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05866}
      transform="rotate(-90 183.836 111.936)"
      fill="#75A4FE"
    />
    <Path
      d="M188.674 74.3733C188.674 76.9718 186.556 79.0864 183.933 79.0864C181.311 79.0864 179.193 76.9718 179.193 74.3733C179.193 71.7747 181.311 69.6601 183.933 69.6601C186.556 69.6601 188.674 71.7747 188.674 74.3733Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.01559}
    />
    <Ellipse cx={82.7858} cy={37.053} rx={4.25457} ry={4.23462} fill="#75A4FE" />
    <Rect x={96.6113} y={5.29297} width={2.12729} height={7.41059} rx={1.06364} fill="#75A4FE" />
    <Rect x={96.7441} y={15.2559} width={2.12729} height={7.41059} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={98.7383}
      y={14.8203}
      width={2.11731}
      height={7.44551}
      rx={1.05866}
      transform="rotate(-90 98.7383 14.8203)"
      fill="#75A4FE"
    />
    <Path
      d="M90.2247 15.2559C89.64 15.2559 89.166 14.7819 89.166 14.1972C89.166 13.6125 89.64 13.1385 90.2247 13.1385L95.5529 13.1385C96.1375 13.1385 96.6115 13.6125 96.6115 14.1972C96.6115 14.7819 96.1375 15.2559 95.5529 15.2559L90.2247 15.2559Z"
      fill="#75A4FE"
    />
    <Rect x={102} y={27} width={61} height={72} rx={11} fill="white" />
    <Rect x={110} y={33} width={45} height={60} rx={11} fill="#F0F6FF" />
    <Path
      d="M163.312 59.9625V44.52C163.312 29.9113 159.905 26.25 146.202 26.25H118.798C105.095 26.25 101.688 29.9113 101.688 44.52V85.3375C101.688 102.375 118 98.75 134.312 98.75"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M163.312 59.9625V44.52C163.312 29.9113 159.905 26.25 146.202 26.25H118.798C105.095 26.25 101.688 29.9113 101.688 44.52V85.3375C101.688 102.375 118 98.75 134.312 98.75"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M118 44.375H147" stroke="#1F71F4" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M121.625 58.875H143.375" stroke="#1F71F4" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <Path
      d="M172.676 86.0599C172.676 95.2311 165.233 102.674 156.062 102.674C146.891 102.674 139.447 95.2311 139.447 86.0599C139.447 76.8886 146.891 69.4453 156.062 69.4453C165.233 69.4453 172.676 76.8886 172.676 86.0599Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M162.226 91.343L157.075 88.2693C156.178 87.7376 155.447 86.4583 155.447 85.4116V78.5996"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SVGComponent;
