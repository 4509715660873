import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 20, height = 20, color = "#37B24D" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <Path
        d="M15.45 3.434l-4.584-1.717c-.475-.175-1.25-.175-1.725 0L4.558 3.434c-.883.333-1.6 1.367-1.6 2.308v6.75c0 .675.442 1.567.983 1.967l4.584 3.425c.808.608 2.133.608 2.941 0l4.584-3.425c.541-.409.983-1.292.983-1.967v-6.75c.008-.941-.708-1.975-1.583-2.308zM12.9 8.1l-3.584 3.583a.618.618 0 01-.441.183.618.618 0 01-.442-.183L7.1 10.334a.629.629 0 010-.883.629.629 0 01.883 0l.9.9 3.142-3.142a.629.629 0 01.883 0 .631.631 0 01-.008.892z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
