import * as React from "react";
import Svg, { G, Mask, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <G clipPath="url(#clip0_7673_591)">
        <Mask id="a" maskUnits="userSpaceOnUse" x={-1} y={-1} width={25} height={25}>
          <Path fill="#D9D9D9" d="M-0.15625 -0.297852H23.84375V23.702148H-0.15625z" />
        </Mask>
        <G mask="url(#a)">
          <Path
            d="M4.094 17.202a.726.726 0 01-.535-.216.726.726 0 01-.215-.534c0-.213.072-.39.215-.535a.726.726 0 01.535-.215h3.875c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.391-.216.535a.726.726 0 01-.534.215H4.094zm0-4.75a.726.726 0 01-.535-.216.726.726 0 01-.215-.534c0-.213.072-.39.215-.534a.726.726 0 01.535-.216h9.682c.213 0 .391.072.535.216a.727.727 0 01.215.534c0 .213-.072.391-.215.535a.726.726 0 01-.535.215H4.094zm0-4.75a.725.725 0 01-.535-.216.726.726 0 01-.215-.534c0-.213.072-.39.215-.534a.726.726 0 01.535-.216h15.5c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.391-.216.535a.726.726 0 01-.534.215h-15.5z"
            fill="#0F172A"
          />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_7673_591">
          <Path fill="#fff" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
