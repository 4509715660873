import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { GetExpenseQuery, GetExpenseQueryVariables } from "types";

const QUERY_LATEST_EXPENSES = gql`
  query GetExpense($companyId: uuid!, $userId: uuid!, $employeeId: UUID!, $from: timestamptz) {
    expUnClaimedExpenses(where: { employeeId: $employeeId }, limit: 5, offset: 0) {
      expenses {
        docSequence
        expenseCardTransactions {
          cardTransaction {
            cardTransactionId
          }
        }
        note
        expenseId
        invoiceId
        provider
        category
        totalAmountWithVat
        expenseDate
        receivedInvoice
        status
        expenseCategory {
          expenseCategoryId
          title
        }
        expenseReport {
          expenseReportId
          status
          docSequence
        }
      }
    }
    allExpenses: expenses_aggregate(
      where: {
        company_id: { _eq: $companyId }
        user_id: { _eq: $userId }
        deleted_at: { _is_null: true }
        created_at: { _gte: $from }
      }
    ) {
      aggregate {
        sum {
          totalAmountWithVat: total_amount_with_vat
        }
        count(distinct: true)
      }
    }
    nonClaimedExpenses: expenses_aggregate(
      where: {
        _and: [
          { user_id: { _eq: $userId } }
          { company_id: { _eq: $companyId } }
          { deleted_at: { _is_null: true } }
          { _or: [{ expense_report_id: { _is_null: true } }, { expense_report: { status: { _in: [0] } } }] }
        ]
      }
    ) {
      aggregate {
        sum {
          totalAmountWithVat: total_amount_with_vat
        }
        count(distinct: true)
      }
    }
    waitingApproveExpenses: expenses_aggregate(
      where: {
        user_id: { _eq: $userId }
        company_id: { _eq: $companyId }
        deleted_at: { _is_null: true }
        expense_report: { status: { _in: [1, 2, 3] } }
      }
    ) {
      aggregate {
        sum {
          totalAmountWithVat: total_amount_with_vat
        }
        count(distinct: true)
      }
    }
    countingCompanies: companies_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const useLatestExpenseQuery = ({
  companyId,
  userId,
  from,
  employeeId,
}: {
  companyId: string;
  userId: string;
  from: string;
  employeeId: string;
}) => {
  const [fetchData, result] = useLazyQuery<GetExpenseQuery, GetExpenseQueryVariables>(QUERY_LATEST_EXPENSES, {
    variables: { companyId, userId, from, employeeId },
    fetchPolicy: "network-only",
  });

  return {
    ...result,
    fetchData,
  };
};

export default useLatestExpenseQuery;
