import Svg, { Path } from "react-native-svg";

function SvgComponent(props: any) {
  return (
    <Svg width="67" height="66" viewBox="0 0 67 66" fill="none">
      <Path
        d="M33.5 33C41.0939 33 47.25 26.8439 47.25 19.25C47.25 11.6561 41.0939 5.5 33.5 5.5C25.9061 5.5 19.75 11.6561 19.75 19.25C19.75 26.8439 25.9061 33 33.5 33Z"
        stroke="#475569"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.87695 60.5C9.87695 49.8575 20.4645 41.25 33.4995 41.25C36.1395 41.25 38.6971 41.6075 41.0896 42.2675"
        stroke="#475569"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M61 49.5C61 50.38 60.89 51.2325 60.67 52.0575C60.4225 53.1575 59.9825 54.23 59.405 55.165C57.5075 58.355 54.015 60.5 50 60.5C47.1675 60.5 44.6101 59.4274 42.6851 57.6674C41.8601 56.9524 41.145 56.1 40.595 55.165C39.5775 53.515 39 51.5625 39 49.5C39 46.53 40.1825 43.8076 42.1075 41.8276C44.115 39.7651 46.92 38.5 50 38.5C53.245 38.5 56.1874 39.9025 58.1674 42.1575C59.9274 44.11 61 46.695 61 49.5Z"
        stroke="#FE4D65"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M54.0973 49.4453H45.9023"
        stroke="#FE4D65"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
