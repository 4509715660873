import AlertNotification, { AlertNotificationHandle } from "components/AlertNotification/AlertNotification";
import AppText from "components/AppText";
import IconCustom from "components/IconCustom";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Fonts, Colors } from "theme";

const ApprovalFlowTooltip = () => {
  const { t } = useTranslation("app/components/HowTo/ApprovalFlowTooltip");
  const alertNotification = useRef<AlertNotificationHandle>(null);

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          alertNotification.current.info({
            title: t("approval_status"),
            description: (
              <View style={styles.container}>
                <View style={styles.row}>
                  <IconCustom name="timeline-check-circle" />
                  <AppText style={Fonts.BodyMedium}>{t("approved")}</AppText>
                </View>
                <View style={styles.row}>
                  <IconCustom name="timeline-circle" stroke={Colors.primary50} />
                  <AppText style={Fonts.BodyMedium}>{t("waiting")}</AppText>
                </View>
                <View style={styles.row}>
                  <IconCustom name="timeline-circle" stroke={Colors.grayscale40} />
                  <AppText style={Fonts.BodyMedium}>{t("have_not_reached")}</AppText>
                </View>
                <View style={[styles.row, styles.rowWithWeirdIcon]}>
                  <View>
                    <IconCustom name="check-circle-outline" />
                    <View style={styles.scheduleIcon}>
                      <IconCustom name="schedule-filled" fill={Colors.success50} />
                    </View>
                  </View>
                  <AppText style={[Fonts.BodyMedium, { marginLeft: 4 }]}>{t("auto_approve_on_this_level")}</AppText>
                </View>
              </View>
            ),
            confirmText: t("understood"),
            onConfirm: () => alertNotification.current.close(),
          });
        }}
      >
        <IconCustom name="info" />
      </TouchableOpacity>
      <AlertNotification ref={alertNotification} />
    </>
  );
};

export default ApprovalFlowTooltip;

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    gap: 12,
  },
  rowWithWeirdIcon: {
    gap: 6,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  scheduleIcon: {
    position: "absolute",
    bottom: 0,
    right: -4,
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 6,
  },
});
