import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1F71F4" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M11.25 16.75v.567c0 .116.043.217.13.303.086.087.187.13.303.13h.615c.12 0 .226-.045.316-.136a.434.434 0 00.136-.316v-.548H14c.213 0 .39-.072.534-.216A.726.726 0 0014.75 16v-3a.726.726 0 00-.216-.534.726.726 0 00-.534-.216h-3.25v-1.5H14c.213 0 .39-.072.534-.216A.727.727 0 0014.75 10a.725.725 0 00-.216-.535A.726.726 0 0014 9.25h-1.25v-.567a.415.415 0 00-.13-.303.415.415 0 00-.303-.13h-.615a.433.433 0 00-.316.136.434.434 0 00-.136.316v.548H10a.726.726 0 00-.534.216.726.726 0 00-.216.534v3c0 .213.072.39.216.534a.725.725 0 00.534.216h3.25v1.5H10a.725.725 0 00-.534.216.726.726 0 00-.216.534c0 .213.072.391.216.535a.726.726 0 00.534.215h1.25zM6.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h6.732a1.8 1.8 0 011.275.527l4.658 4.658A1.8 1.8 0 0119.5 8.96v10.732c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.308z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
