import { ImageStyle, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { AppText, ImageCustom } from "components/index";
import { Colors, Fonts } from "theme";
import React, { FC, memo } from "react";

interface AvatarUserProps {
  style?: ViewStyle | ImageStyle;
  containerStyle?: ViewStyle | ViewStyle[];
  size?: number;
  textStyle?: TextStyle;
  borderWidth?: number;
  borderColor?: string;
  backgroundColor?: string;
  url?: string;
  fullname?: string;
}
const AvatarUser: FC<AvatarUserProps> = ({
  url,
  backgroundColor,
  borderWidth,
  borderColor = "#FFF",
  style,
  containerStyle,
  fullname,
  size,
  textStyle,
}) => {
  if (url) {
    return (
      <ImageCustom
        contentFit="cover"
        source={{ uri: url }}
        containerStyle={containerStyle}
        style={[
          styles.avatarImage,
          size && { width: size, height: size, borderRadius: size / 2 },
          borderWidth && { borderWidth },
          borderColor && { borderColor },
          backgroundColor && { backgroundColor },
          style as ImageStyle,
        ]}
      />
    );
  }
  //return <View style={{ width: 100, height: 100, backgroundColor: "red" }} />;
  const firstName = fullname ? fullname?.split(" ").pop() : "B";

  return (
    <View
      style={[
        styles.avatar,
        size && { width: size, height: size, borderRadius: size / 2 },
        borderWidth && { borderWidth },
        borderColor && { borderColor },
        backgroundColor && { backgroundColor },
        style,
        containerStyle,
      ]}
    >
      <AppText style={textStyle ? textStyle : Fonts.H200} color={Colors.white}>
        {firstName?.charAt(0)?.toUpperCase() ?? ""}
      </AppText>
    </View>
  );
};
export default memo(AvatarUser);

const styles = StyleSheet.create({
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 48 / 2,
    backgroundColor: Colors.dataBlue,
    alignItems: "center",
    justifyContent: "center",
  },
  avatarImage: {
    width: 48,
    height: 48,
    borderRadius: 48 / 2,
    alignItems: "center",
    justifyContent: "center",
  },
});
