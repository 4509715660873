import { gql, useMutation } from "@apollo/client";
import {
  MobileGenerateExpRequestDownloadFileMutation,
  MobileGenerateExpRequestDownloadFileMutationVariables,
} from "types";

const GENERATE_EXP_REPORT_DOWNLOAD_FILE = gql`
  mutation MobileGenerateExpRequestDownloadFile($input: ExpGenerateExpenseRequestDownloadFileInput!) {
    expGenerateExpenseRequestDownloadFile(input: $input) {
      success
    }
  }
`;

const useExpGenerateRequestDownloadFile = () => {
  const [generateRequestDownloadFile, { loading }] = useMutation<
    MobileGenerateExpRequestDownloadFileMutation,
    MobileGenerateExpRequestDownloadFileMutationVariables
  >(GENERATE_EXP_REPORT_DOWNLOAD_FILE);
  const mutation = async ({ input }) => {
    const { data } = await generateRequestDownloadFile({
      variables: { input },
    });
    return data;
  };
  return { generateExpRequestDownloadFile: mutation, loading };
};

export default useExpGenerateRequestDownloadFile;
