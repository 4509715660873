import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileQueryExpenseRequestsQuery, MobileQueryExpenseRequestsQueryVariables } from "types";

export const QUERY_EXPENSE_REQUEST = gql`
  query MobileQueryExpenseRequests(
    $where: ExpMobileExpenseRequestCondition!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
    $sortOption: ExpMobileExpenseRequestsSortOptions
  ) {
    expMobileExpenseRequests(where: $where, limit: $limit, offset: $offset, sortOption: $sortOption) {
      total
      requests {
        createdAt
        closedAt
        closedBy
        docSequence
        expenseRequestId
        expenseRequestType
        isClosed
        status
        title
        companyEmployee {
          fullname
        }
        totalAmount
        updatedAt
        requestedAt
        expenseApprovalRules {
          status
          expenseApprovalRuleEmployees {
            employee {
              employeeId
              user {
                avatarUrl
                userId
                defaultAvatarUrl
                fullname
              }
            }
          }
        }
      }
    }
  }
`;

const useExpenseRequests = (variables: MobileQueryExpenseRequestsQueryVariables) =>
  useQuery<MobileQueryExpenseRequestsQuery, MobileQueryExpenseRequestsQueryVariables>(QUERY_EXPENSE_REQUEST, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !variables.where.companyId || !variables.where.employeeId,
  });

export default useExpenseRequests;
