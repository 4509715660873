import { ScrollView, StyleSheet, View } from "react-native";
import { BackHeader, UploadFileView } from "components";
import React, { useLayoutEffect } from "react";
import { Colors } from "theme";
import { CONSTANTS } from "constants/constants";

const AttachmentFileScreen = ({ navigation, route }) => {
  const { title, files } = route?.params ?? {};
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={title} />,
    } as unknown);
  }, [title]);
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer} showsVerticalScrollIndicator={false}>
        <UploadFileView files={files} />
      </ScrollView>
    </View>
  );
};
export default AttachmentFileScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingTop: 10, paddingBottom: 40, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
});
