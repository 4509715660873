import AntDesign from "react-native-vector-icons/AntDesign";

import React from "react";
import { Text, View } from "react-native";
import Colors from "constants/Colors";
import { stylesCommon } from "./commonStyle";

const TextInputError = ({ ...props }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center", zIndex: 1 }}>
      <AntDesign name="exclamationcircleo" size={16} color={Colors.alert50} style={{ marginTop: 10 }} />
      <Text style={[stylesCommon.error, { marginLeft: 10 }]}>{props.children}</Text>
    </View>
  );
};

export default TextInputError;
