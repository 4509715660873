import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={107} height={107} viewBox="0 0 107 107" fill="none" {...props}>
      <G clipPath="url(#clip0_5202_304479)">
        <Path
          d="M43.592 91.795c2.546.81 5.445 1.268 8.532 1.268 3.086 0 5.986-.457 8.532-1.268-2.463-2.276-5.404-3.73-8.532-3.73-3.118 0-6.038 1.433-8.532 3.73z"
          fill="#C0CAD8"
        />
        <Path
          d="M96.57 58.394C95.252 79.095 75.247 88.428 51.386 86.9c-23.86-1.517-42.494-13.333-41.174-34.014 1.32-20.68 21.98-40.363 45.84-38.846 23.861 1.517 41.84 23.653 40.52 44.354z"
          fill="#E4EAF1"
        />
        <Path
          d="M37.72 17.064l-3.024 47.347c5.383 2.11 11.431 3.45 17.895 3.866 6.454.416 12.637-.145 18.249-1.558l3.024-47.348c-5.331-2.993-11.359-4.915-17.802-5.33-6.454-.417-12.678.727-18.342 3.023z"
          fill="#1F71F4"
        />
        <Path
          d="M52.186 34.45l-.904-.062.79-2.723 1.662-5.799.083-.311-4.593-.291 2.162-7.368-2.713-.177 6.61-3.73 4.084 4.416-2.1-.135-.852 2.94 6.038.385L52.186 34.45z"
          fill="#fff"
        />
        <Path
          d="M21.238 65.544c-5.144-7.264 2.359-29.867 10.184-34.086 10.517-5.664 40.28-2.972 49.29 4.843 6.527 5.674 9.925 28.64 4.23 34.252-11.369 11.203-54.528 7.95-63.704-5.009z"
          fill="#1B1B1D"
        />
        <Path
          d="M12.997 59.662l-4.884-1.58c-.894-.28-1.497-1.32-1.424-2.452l.53-8.314c.073-1.133.8-2.089 1.746-2.255l5.04-.946c1.257-.228 2.349.977 2.245 2.515l-.686 10.84c-.114 1.506-1.351 2.587-2.567 2.192z"
          fill="#C0CAD8"
        />
        <Path
          d="M10.222 58.82l-4.479-1.423c-.831-.26-1.372-1.206-1.31-2.266l.489-7.607c.062-1.05.727-1.902 1.58-2.058l4.624-.852c1.154-.218 2.141.904 2.058 2.286l-.634 9.904c-.083 1.413-1.226 2.38-2.328 2.016z"
          fill="#E4EAF1"
        />
        <Path
          d="M92.913 65.43l5.04-.946c.946-.166 1.673-1.122 1.746-2.255l.53-8.314c.073-1.132-.53-2.172-1.444-2.463l-4.885-1.58c-1.216-.384-2.452.676-2.546 2.204l-.686 10.84c-.104 1.537.977 2.763 2.245 2.514z"
          fill="#C0CAD8"
        />
        <Path
          d="M95.781 64.972l4.625-.852c.862-.156 1.517-1.029 1.579-2.058l.489-7.607c.062-1.05-.478-1.984-1.309-2.244l-4.48-1.424c-1.122-.364-2.244.623-2.327 2.006l-.634 9.904c-.084 1.36.904 2.483 2.057 2.275z"
          fill="#E4EAF1"
        />
        <Path
          d="M45.358 64.89s2.172 2.608 7.597 3.294c5.425.686 7.95-1.684 7.95-1.684s-.468 4.167-8.179 3.367c-7.71-.8-7.368-4.978-7.368-4.978z"
          fill="#474749"
        />
        <Path
          d="M86.73 54.33l-3.357-.332c-.073-.01-.135.01-.208.021-.447-5.84-4.936-10.683-11.016-11.276-5.975-.582-11.369 3.129-13.073 8.605-1.372-1.434-2.858-2.255-4.448-2.421h-.042c-1.61-.156-3.273.364-4.957 1.528-.571-5.716-5.02-10.403-11.005-10.995-6.454-.634-12.232 3.761-13.417 9.976-.02 0-.03-.01-.051-.02l-3.357-.333a1.127 1.127 0 00-1.226 1.008 1.127 1.127 0 001.008 1.226l3.356.333c.021 0 .042-.01.052-.01-.052 6.328 4.76 11.763 11.213 12.397 6.683.655 12.471-4.094 13.355-10.641 1.725-1.622 3.346-2.38 4.8-2.235h.032c1.434.156 2.837 1.206 4.157 3.108-.405 6.609 4.5 12.408 11.182 13.063 6.838.675 12.751-4.303 13.416-11.089l3.367.322a1.127 1.127 0 001.227-1.008 1.127 1.127 0 00-1.008-1.226z"
          fill="#fff"
        />
        <Path
          d="M20.438 37.953c0-5.29 4.78-9.51 9.508-9.51-7.326 0-9.509-3.574-9.509-9.508 0 5.934-4.053 9.509-9.508 9.509 5.455 0 9.508 4.739 9.508 9.509zM88.091 48.252c0-4.336 3.918-7.795 7.794-7.795-6.005 0-7.794-2.93-7.794-7.794 0 4.864-3.322 7.794-7.794 7.794 4.472 0 7.794 3.885 7.794 7.795zM92.248 34.742c0-6.07 5.486-10.912 10.912-10.912-8.408 0-10.912-4.103-10.912-10.912 0 6.81-4.65 10.912-10.912 10.912 6.261 0 10.912 5.438 10.912 10.912z"
          fill="#FFC261"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5202_304479">
          <Path fill="#fff" transform="translate(4.434 13.957)" d="M0 0H98.0405V79.1058H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
