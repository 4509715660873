// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { PartialState, Route, getFocusedRouteNameFromRoute } from "@react-navigation/native";
import { StackNavigationOptions, TransitionPresets } from "@react-navigation/stack";
import { Colors } from "constants/Colors";
import Fonts from "constants/Fonts";
import { Platform, View } from "react-native";
import { DefaultTheme, configureFonts } from "react-native-paper";

import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "./ScreenName";

const fontConfig = {
  android: {
    regular: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
    medium: {
      fontFamily: Fonts.fontFamilyMedium,
      fontWeight: "600",
    },
    light: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
    thin: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
  },
  ios: {
    regular: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
    medium: {
      fontFamily: Fonts.fontFamilyMedium,
      fontWeight: "600",
    },
    light: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
    thin: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
  },
  default: {
    regular: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
    medium: {
      fontFamily: Fonts.fontFamilyMedium,
      fontWeight: "600",
    },
    light: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
    thin: {
      fontFamily: Fonts.fontFamily,
      fontWeight: "normal",
    },
  },
};

export const THEME = {
  animation: {
    scale: 1.0,
  },
  dark: false,
  roundness: 4,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.blue500,
    text: Colors.Ink500,
    placeholder: Colors.Ink300,
  },
  fonts: configureFonts(fontConfig),
};

export const getHeaderTitle = (
  route: Partial<Route<string, object | undefined>> & {
    state?:
      | PartialState<
          Readonly<{
            key: string;
            index: number;
            routeNames: string[];
            history?: unknown[] | undefined;
            routes: (Readonly<{ key: string; name: string }> &
              Readonly<{ params?: object | undefined }> & {
                state?: Readonly<any> | PartialState<Readonly<any>> | undefined;
              })[];
            type: string;
            stale: false;
          }>
        >
      | undefined;
  },
  t
) => {
  const routeName = getFocusedRouteNameFromRoute(route) || route.name;

  switch (routeName) {
    case SCREEN_NAME.NotificationScreen:
      return t("notifications");
    case SCREEN_NAME.InboxScreen:
    case SCREEN_NAME.InboxTabScreen:
      return t("inbox");
    case SCREEN_NAME.AuthStack:
      return t("OTPScreen");
    case SCREEN_NAME.AboutScreen:
      return t("about_us");
    case SCREEN_BOTTOM_TAB.ExpensesScreen:
      return t("expense");
    case SCREEN_NAME.ProfileScreen:
      return t("profile");
    case SCREEN_BOTTOM_TAB.ReportsScreen:
      return t("ReportsScreen");
    case SCREEN_BOTTOM_TAB.ReportsScreen:
      return t("LocalAuthentication");
    default:
      return t(`${SCREEN_NAME[routeName]}`);
  }
};

export const options = (params, t): StackNavigationOptions => {
  const configRoute = params?.navigation ? params?.route ?? params : params;
  return {
    headerShown: true,
    headerTitle: getHeaderTitle(configRoute, t),
    headerTitleStyle: {
      fontFamily: Fonts.fontGoogleSansMedium,
      color: Colors.white500,
      alignSelf: "center",
      fontSize: 18,
      fontWeight: "600",
    },
    headerTitleAlign: "center",
    headerTintColor: Colors.white500,
    headerBackTitleVisible: true,

    headerBackTitle: "",
    headerTruncatedBackTitle: "",
    headerStyle: {
      backgroundColor: Colors.blue500,
      elevation: 0,
      shadowOpacity: 0,
    },
    headerRight: () => <View />,
    headerLeftContainerStyle: {
      paddingLeft: Platform.OS === "ios" ? 10 : 0,
    },
    // headerLeft: () => <AntDesign name="left" size={24} color="black" />,
    headerRightContainerStyle: {
      paddingRight: Platform.OS === "ios" ? 10 : 10,
    },
    ...TransitionPresets.SlideFromRightIOS,
  };
};
