import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 16, height = 16, fill = "#37B24D" }) {
  return (
    <Svg width={width} height={height} fill="none">
      <Mask id="a" width={16} height={16} x={0} y={0} maskUnits="userSpaceOnUse">
        <Path fill="#D9D9D9" d="M0 0h16v16H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          fill={fill}
          d="m8.4 8.002-2.6-2.6a.632.632 0 0 1-.184-.467c0-.189.061-.344.184-.467a.632.632 0 0 1 .466-.183c.19 0 .345.061.467.183L9.8 7.535a.585.585 0 0 1 .141.217.738.738 0 0 1 .042.25.738.738 0 0 1-.042.25.585.585 0 0 1-.141.216l-3.067 3.067a.632.632 0 0 1-.467.184.632.632 0 0 1-.466-.184.632.632 0 0 1-.184-.466c0-.19.061-.345.184-.467l2.6-2.6Z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
