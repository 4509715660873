import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 48, height = 48, color = "#0086FF" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <Path
        d="M14 19a1.666 1.666 0 01-1.667-1.667v-1.666a1.666 1.666 0 113.333 0v1.666c0 .92-.745 1.667-1.666 1.667zM32.334 19a1.666 1.666 0 01-1.667-1.667v-1.666a1.666 1.666 0 113.333 0v1.666c0 .92-.745 1.667-1.666 1.667zM20.666 29H19a1.666 1.666 0 110-3.333h1.666c.92 0 1.667-.749 1.667-1.667v-8.333a1.666 1.666 0 113.333 0V24c0 2.757-2.243 5-5 5zM23.166 37.334a8.471 8.471 0 01-7.268-4.154 1.666 1.666 0 112.871-1.691A5.126 5.126 0 0023.166 34a5.13 5.13 0 004.398-2.513 1.667 1.667 0 012.872 1.692 8.474 8.474 0 01-7.27 4.154zM42.334 17.333c-.92 0-1.667-.746-1.667-1.666V9A1.67 1.67 0 0039 7.333h-6.666a1.667 1.667 0 010-3.333H39c2.757 0 5 2.243 5 5v6.667c0 .92-.746 1.666-1.666 1.666zM5.667 17.333c-.92 0-1.667-.746-1.667-1.666V9c0-2.757 2.243-5 5-5h6.667a1.667 1.667 0 010 3.333H9A1.67 1.67 0 007.333 9v6.667c0 .92-.746 1.666-1.666 1.666zM39 44h-6.666a1.667 1.667 0 010-3.333H39A1.67 1.67 0 0040.667 39v-6.666a1.667 1.667 0 013.333 0V39c0 2.757-2.243 5-5 5zM15.667 44H9c-2.757 0-5-2.243-5-5v-6.666a1.667 1.667 0 013.333 0V39A1.67 1.67 0 009 40.667h6.667a1.667 1.667 0 010 3.333z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
