import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import { AppText, IconCustom, useWindowDimensions } from "components";
import { Colors, Fonts, FontTypes } from "theme";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "constants/constants";
import { useCopilot } from "react-native-copilot";

const TooltipComponent = () => {
  const {
    goToNext,
    goToPrev,
    stop,
    currentStep,
    isFirstStep: isFirstStepCopilot,
    isLastStep: isLastStepCopilot,
    totalStep,
  } = useCopilot();
  const isFirstStep =
    isFirstStepCopilot || currentStep.order === 0 || (currentStep.order === 1 && !currentStep.activeStep);
  const isLastStep =
    (isLastStepCopilot && !currentStep?.activeStep) ||
    (isLastStepCopilot && currentStep?.activeStep === currentStep?.totalStep);
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [stepActive, setStepActive] = useState(0);
  const isHaveTitle = currentStep?.text?.includes?.(CONSTANTS.WALK_THROUGH.AddTitleKey);
  const title = isHaveTitle ? currentStep?.text?.split?.(CONSTANTS.WALK_THROUGH.AddTitleKey)?.[0] : "";
  const description = isHaveTitle
    ? currentStep?.text?.split(CONSTANTS.WALK_THROUGH.AddTitleKey)?.[1]
    : currentStep?.text;
  const handleNext = () => {
    if (currentStep?.onConfirm) {
      currentStep.onConfirm();
    }
    if (isLastStep) {
      stop();
      return;
    }
    setStepActive((prevState) => prevState + 1);
    setTimeout(
      () => {
        goToNext();
      },
      currentStep?.onConfirm ? 500 : 0
    );
  };
  const handlePrev = () => {
    setStepActive((prevState) => prevState - 1);
    if (currentStep?.onBack) {
      currentStep.onBack();
      return;
    }
    goToPrev();
  };
  const renderFooterContent = () => {
    const { singleStep, confirmText, onConfirm } = currentStep;
    if (singleStep) {
      return (
        <>
          <View style={styles.flex} />
          <TouchableOpacity
            style={onConfirm ? styles.buttonNext : styles.understoodButton}
            onPress={() => {
              stop();
              onConfirm?.();
            }}
          >
            <AppText style={[Fonts.BodySmall, styles.titleButton]}>
              {confirmText ?? t("common:understood" as any)}
            </AppText>
            {onConfirm && <IconCustom name="arrow-forward" />}
          </TouchableOpacity>
        </>
      );
    }
    return (
      <>
        {!isFirstStep ? (
          <View style={styles.flex}>
            <TouchableOpacity style={styles.buttonPrev} onPress={handlePrev}>
              <IconCustom name="arrow-back" fill={Colors.primary50} width={20} height={20} />
              <AppText style={[Fonts.BodySmall, styles.titleButton]}>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  t("common:back")
                }
              </AppText>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.flex} />
        )}
        <View style={{ marginTop: 7 }}>
          <AppText style={Fonts.Caption}>
            {currentStep?.activeStep ? currentStep?.activeStep : stepActive + 1}
            <AppText style={Fonts.Caption} color={Colors.grayscale60}>
              /{currentStep?.totalStep ? currentStep?.totalStep : totalStep}
            </AppText>
          </AppText>
        </View>
        <View style={styles.flex}>
          <TouchableOpacity style={styles.buttonNext} onPress={handleNext}>
            <AppText style={[Fonts.BodySmall, styles.titleButton]}>
              {currentStep?.confirmText ? currentStep?.confirmText : t(isLastStep ? "common:finish" : "common:next")}
            </AppText>
            <IconCustom name="arrow-forward" />
          </TouchableOpacity>
        </View>
      </>
    );
  };
  return (
    <View style={[styles.container, { width: width - 40 }]}>
      {Boolean(title) && (
        <AppText style={[Fonts.NumericN200, styles.title]} color={Colors.brand100}>
          {title}
        </AppText>
      )}
      <AppText style={Fonts.BodyMedium}>{description}</AppText>
      <View style={styles.footer}>{renderFooterContent()}</View>
    </View>
  );
};
export default TooltipComponent;

const styles = StyleSheet.create({
  container: { backgroundColor: Colors.white, padding: 12, paddingBottom: 5, borderRadius: 16 },
  buttonPrev: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
    paddingRight: 4,
    paddingVertical: 4,
  },
  buttonNext: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-end",
    paddingLeft: 4,
    paddingVertical: 4,
  },
  understoodButton: {
    alignSelf: "flex-end",
    paddingLeft: 4,
    paddingTop: 4,
    paddingBottom: 8,
  },
  titleButton: { fontFamily: FontTypes.semiBold, color: Colors.primary50 },
  footer: { flexDirection: "row", marginTop: 4, justifyContent: "space-between" },
  title: { marginBottom: 8 },
  flex: { flex: 1 },
});
