import { forwardRef, useRef, useState } from "react";
import { View, StyleSheet, Platform, Linking } from "react-native";
import { WebView as ReactNativeWebview, WebViewProps } from "react-native-webview";
import { ActivityIndicator } from "react-native-paper";

type WebViewProp = {
  loading?: boolean;
  uri: string;
  headers?: Record<string, string>;
  script?: string;
  webViewProps?: WebViewProps;
  loadingAssertion?: (url: string) => boolean;
  notRedirectUrlToBrowser?: boolean;
};

const WebView = forwardRef<any, WebViewProp>(
  (
    {
      loading = false,
      uri,
      headers = {},
      script = "",
      webViewProps = {},
      loadingAssertion,
      notRedirectUrlToBrowser = false,
    },
    ref
  ) => {
    const [onload, setOnload] = useState(true);
    const onLoadEnd = () => {
      if (uri && onload) {
        setTimeout(() => {
          setOnload(false);
        }, 250);
      }
    };

    const onShouldStartLoadWithRequest = (event) => {
      if (notRedirectUrlToBrowser) {
        return true;
      }
      const isExternalLink = Platform.OS === "ios" ? event.navigationType === "click" : true;
      if (event.url.slice(0, 4) === "http" && isExternalLink) {
        Linking.canOpenURL(event.url).then((supported) => {
          if (supported) {
            Linking.openURL(event.url);
          }
        });
        return false;
      }
      return true;
    };
    return (
      <View ref={ref} style={styles.webviewContainer}>
        <ReactNativeWebview
          source={{ uri, headers }}
          onLoadStart={(event) => {
            if (!loadingAssertion) {
              setOnload(true);
              return;
            }

            const showLoading = loadingAssertion(event.nativeEvent.url);
            if (showLoading !== onload) {
              setOnload(showLoading);
            }
          }}
          onLoadEnd={onLoadEnd}
          onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
          injectedJavaScriptBeforeContentLoaded={`
            window.isNativeApp = true;
            ${script}
          `}
          {...webViewProps}
        />
        {loading || onload ? <ActivityIndicator style={styles.indicator} /> : null}
      </View>
    );
  }
);

export default WebView;

export type { WebViewProp };

const styles = StyleSheet.create({
  indicator: { position: "absolute", alignSelf: "center", top: "45%" },
  webviewContainer: {
    flex: 1,
    height: "100%",
  },
  webview: { opacity: 0.99, overflow: "hidden" },
});
