import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 24, color = "#7F8A93" }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M11 8.873a4 4 0 112 0v2.126h4a2 2 0 012 2v2.126a4 4 0 11-2 0v-2.126H7v2.126a4 4 0 11-2 0v-2.126a2 2 0 012-2h4V8.873zm1-1.874a2 2 0 100-4 2 2 0 000 4zm-6 14a2 2 0 100-4 2 2 0 000 4zm10-2a2 2 0 104 0 2 2 0 00-4 0z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
