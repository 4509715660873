import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

function SvgComponent({ size = 24, color = "#0F172A", ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <G clipPath="url(#clip0_5886_398789)">
        <Path
          d="M2 17.2142V20.5238L2.9033 19.3113C4.7905 16.7782 7.76362 15.2857 10.9225 15.2857H11.7561V19.8131C11.7561 20.2557 12.2887 20.4801 12.6054 20.1709L21.6335 11.3578C21.8345 11.1616 21.8344 10.8383 21.6334 10.6421L12.6063 1.82997C12.2897 1.52083 11.7561 1.74425 11.7561 2.1868V7.19044L11.0284 7.26148C5.90623 7.7615 2 12.0676 2 17.2142Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5886_398789">
          <Rect width="22" height="22" fill="white" transform="translate(1 1)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
