import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 24, color = "#4D5C6A" }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 1H7a2 2 0 00-2 2v18a2 2 0 002 2h10a2 2 0 002-2V3a2 2 0 00-2-2zM7 3h3a1 1 0 001 1h2a1 1 0 001-1h3v18H7V3z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
