import Colors from "constants/Colors";
import Fonts from "constants/Fonts";
import { WIDTH_WINDOW } from "constants/Layout";

import { StyleSheet } from "react-native";

const isMobile = WIDTH_WINDOW < 768;

export const stylesCommon = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.whiteBackground,
    flexDirection: "row",
    // paddingBottom: 150,
  },
  content: { paddingVertical: 50, paddingHorizontal: 80, width: "75%", display: "flex" },
  contentMobile: { paddingVertical: 48, paddingHorizontal: 20, width: "100%", display: "flex" },
  title: {
    fontFamily: Fonts.fontFamilyMedium,
    fontSize: isMobile ? 30 : 40,
    lineHeight: isMobile ? 38 : 48,
    color: Colors.black,
    marginVertical: 16,
    marginTop: 45,
    fontWeight: "500",
  },
  description: {
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: 20,
    color: Colors.gray80,
    marginBottom: isMobile ? 12 : 48,
  },
  status: { backgroundColor: Colors.backgroundGray, width: "25%", display: "flex", minHeight: "100vh", height: "100%" },
  registerForm: {
    width:
      WIDTH_WINDOW >= 1300
        ? WIDTH_WINDOW >= 1440
          ? "50%"
          : "70%"
        : WIDTH_WINDOW >= 992
        ? "60%"
        : WIDTH_WINDOW >= 768
        ? "100%"
        : "70%",
    alignSelf: "center",
  },
  registerFormMobile: { width: "100%", alignSelf: "center" },
  error: {
    fontSize: 12,
    lineHeight: 16,
    marginTop: 8,
    color: Colors.alert50,
    fontWeight: "500",
  },
  iconError: {
    position: "absolute",
  },
  textError: { marginLeft: 24 },
  buttonRegister: {
    marginTop: 24,
  },
  placeholderStyle: {
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray40,
  },
  dropdownInput: {
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderColor: Colors.gray10,
    borderWidth: 1,
  },
  dropdownContainer: {
    display: "flex",
    flexDirection: "row",
    color: Colors.alert50,
    marginTop: 20,
    borderColor: Colors.gray10,
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  dropdownItem: {
    flexDirection: "row",
    display: "flex",
    paddingVertical: 10,
    paddingHorizontal: 18,
    borderRadius: 4,
  },
  dropdonwItemText: {
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    color: Colors.gray100,
  },
  dropdownItemSelected: {
    backgroundColor: Colors.primary0,
  },
  dropdownPlaceholder: {
    fontSize: 16,
    lineHeight: 24,
    color: "#94A3B8",
  },
  dropdownLabelStyle: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray100,
  },
  ph20: {
    paddingHorizontal: 15,
  },
  logoContainer: { flexDirection: "row", alignItems: "center" },
  backButton: { marginRight: 10 },
});
