// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ActivityIndicator } from "@ant-design/react-native";
import AddedCompanyBefore from "assets/images/svg/icons/AddedCompanyBefore";
import Colors from "constants/Colors";
import Fonts from "constants/Fonts";
import useGetCompanyInGroup from "hooks/useGetCompanyInGroup";
import useIsUserAdmin from "hooks/useIsUserAdmin";
import isEmpty from "lodash/isEmpty";
import SCREEN_NAME from "navigation/ScreenName";
import { RegisterSetupCompanyScreenNavigationProp, RegisterSetupCompanyScreenRouteProp } from "navigation/type";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import cookie from "js-cookie";
import { stylesCommon } from "./commonStyle";
import RegisterContainer from "./RegisterContainer";

interface IRegisterSetupcompanyScreen {
  navigation: RegisterSetupCompanyScreenNavigationProp;
  route: RegisterSetupCompanyScreenRouteProp;
}

const RegisterSetupCompany: FC<IRegisterSetupcompanyScreen> = ({ navigation, route }) => {
  const { t } = useTranslation("screens/RegisterOnboarding/RegisterSetupCompanyScreen");
  const token = cookie.get("token");
  const { width } = useWindowDimensions();
  const { isAdmin, loading: loadingCheckUserAdmin, userInfo } = useIsUserAdmin({ token });
  const { companies, loading } = useGetCompanyInGroup({ token });
  const isAnyCompanyEnabledExpense = useMemo(
    () => !isEmpty(companies) && companies.some((c) => c.expense_code),
    [companies]
  );

  useEffect(() => {
    if (!loadingCheckUserAdmin && isAnyCompanyEnabledExpense) {
      navigation.navigate(SCREEN_NAME.RegisterWarningEnabledExpenseScreen, { isAdmin });
    }

    if (!loadingCheckUserAdmin && !isAdmin) {
      navigation.navigate(SCREEN_NAME.RegisterWarningEnabledExpenseScreen, { isAdmin });
    }
  }, [isAnyCompanyEnabledExpense, navigation, isAdmin, loadingCheckUserAdmin]);

  if (loading || loadingCheckUserAdmin) {
    return (
      <View style={styles.centerContainer}>
        <ActivityIndicator />
      </View>
    );
  }

  if (isAdmin) {
    return (
      <RegisterContainer activeStep={3}>
        <Text
          style={[
            stylesCommon.title,
            stylesCommon.ph20,
            {
              fontSize: width >= 1024 ? 40 : 30,
            },
          ]}
        >
          {t("title")}
        </Text>
        <Text style={[stylesCommon.description, styles.description, stylesCommon.ph20]}>{t("description")}</Text>
        <View
          style={[
            styles.listCompany,
            {
              flexDirection: width >= 768 ? "row" : "column",
              width: width >= 768 ? "auto" : "auto",
              marginRight: width >= 768 ? "0" : 15,
            },
          ]}
        >
          {companies.map((company) => (
            <View
              key={company.company_id}
              style={[styles.companyItem, { width: width >= 1024 ? "30%" : width >= 768 ? "48%" : "100%" }]}
            >
              <TouchableWithoutFeedback
                onPress={() => {
                  navigation.navigate(SCREEN_NAME.RegisterInviteColleagueScreen, {
                    email: userInfo?.email,
                    fullname: userInfo?.email,
                    company_id: company.company_id,
                    token,
                  });
                }}
              >
                <View>
                  <Text style={styles.label}>{t("company_name")}:</Text>
                  <Text style={styles.detail}>{company.name}</Text>
                  <Text style={styles.label}>{t("tax_code")}:</Text>
                  <Text style={styles.detail}>{company.tax_code}</Text>
                  <Text style={styles.label}>{t("admin")}:</Text>
                  <Text style={styles.detail}>{userInfo?.fullname}</Text>
                </View>
              </TouchableWithoutFeedback>
            </View>
          ))}
        </View>
      </RegisterContainer>
    );
  }
  return (
    <View style={styles.setupCompanyError}>
      <AddedCompanyBefore />
      <Text style={styles.textNotification}>{t("a_company_added_before")}</Text>
    </View>
  );
};

export default RegisterSetupCompany;

const styles = StyleSheet.create({
  description: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray80,
    marginBottom: 24,
  },
  listCompany: {
    flexDirection: "row",
    gap: "16px",
    flex: 1,
    flexWrap: "wrap",
    marginTop: 24,
    marginLeft: 15,
    marginBottom: 15,
  },
  companyItem: {
    fontFamily: Fonts.Inter,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 24,
    boxShadow: "0px 2px 8px rgba(58, 57, 76, 0.08), 0px 2px 4px rgba(58, 57, 76, 0.04)",
    borderRadius: 8,
    padding: 16,
    width: "30%",
  },
  label: {
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
    color: Colors.gray60,
    marginBottom: 2,
  },
  detail: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray100,
    marginBottom: 8,
  },
  setupCompanyError: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    fontFamily: Fonts.Inter,
  },
  textNotification: {
    marginTop: 23,
    textAlign: "center",
    fontWeight: "700",
    fontSize: 24,
    lineHeight: 32,
    color: Colors.gray100,
    fontFamily: Fonts.Inter,
    width: "35%",
  },
  centerContainer: { flex: 1, justifyContent: "center", alignItems: "center" },
});
