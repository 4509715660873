import IconPDF from "./icon_pdf.png";
import IconExcel from "./icon_excel.png";
import IconDoc from "./icon_doc.png";
import BizziBotWelcome from "./BizziBotWelcome.png";
import Onboarding01 from "./Onboarding01.png";
import Onboarding02 from "./Onboarding02.png";
import Onboarding03 from "./Onboarding03.png";
import BizziBotLogin from "./BizziBotLogin.png";
import WelcomeBackground from "./WelcomeBackground.png";
import BizziBotSticker01 from "./BizziBotSticker01.png";
import BizziBotSticker02 from "./BizziBotSticker02.png";
import BizziBotSticker03 from "./BizziBotSticker03.png";
import CardEmptyState from "./CardEmptyState.png";
import BizziCard from "./BizziCard.png";
import ApprovalFlowManualState from "./ApprovalFlowManualState.png";
import NoelBackground from "./NoelBackground.png";
import BizziBotNoelWelcome from "./BizziBotNoelWelcome.png";
import VietQrLogo from "./VietQrLogo.png";
import NapasLogo from "./NapasLogo.png";
import VNPayLogo from "./VNPayLogo.png";
import UpdateAppBackground from "./UpdateAppBackground.png";
import LoginWithZalo from "./LoginWithZalo.png";

export {
  IconPDF,
  IconExcel,
  IconDoc,
  BizziBotWelcome,
  Onboarding01,
  Onboarding02,
  Onboarding03,
  BizziBotLogin,
  WelcomeBackground,
  BizziBotSticker01,
  BizziBotSticker02,
  BizziBotSticker03,
  CardEmptyState,
  BizziCard,
  ApprovalFlowManualState,
  NoelBackground,
  BizziBotNoelWelcome,
  VietQrLogo,
  NapasLogo,
  VNPayLogo,
  UpdateAppBackground,
  LoginWithZalo,
};
