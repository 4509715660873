import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ size }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 62 62" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={62} height={62}>
        <Path d="M.163 0h61.655v61.655H.163V0z" fill="#fff" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M15.577 0a3.862 3.862 0 00-3.85 3.853v53.952a3.864 3.864 0 003.853 3.85h38.535a3.863 3.863 0 003.852-3.853V15.414L42.558 0h-26.98z"
          fill="#E2E5E7"
        />
        <Path d="M46.404 15.414h11.561L42.551 0v11.562a3.865 3.865 0 003.853 3.852z" fill="#B0B7BD" />
        <Path d="M57.961 26.973L46.399 15.41h11.562v11.565-.003z" fill="#CAD1D8" />
        <Path
          d="M50.256 50.093a1.933 1.933 0 01-1.93 1.926H5.94a1.933 1.933 0 01-1.926-1.926V30.829c0-1.063.864-1.93 1.926-1.93h42.387c1.06 0 1.927.867 1.927 1.927l.003 19.267z"
          fill="#42A8F1"
        />
        <Path
          d="M48.333 52.02H11.725v1.926h36.608a1.93 1.93 0 001.926-1.927v-1.926a1.928 1.928 0 01-1.926 1.926z"
          fill="#CAD1D8"
        />
        <Path
          d="M12.797 47c-.365 0-.547-.195-.547-.586V36.383c0-.39.182-.586.547-.586H19.5c.39 0 .586.17.586.508v.922c0 .338-.195.507-.586.507h-4.969v2.485h4.555c.39 0 .586.169.586.508v.921c0 .339-.195.508-.586.508H14.53v2.907h5.219c.39 0 .586.169.586.507v.922c0 .339-.195.508-.586.508h-6.953zm10 0c-.365 0-.547-.195-.547-.586V36.383c0-.39.182-.586.547-.586h2.265c.303 0 .519.208.649.625l2.117 6.851c.094.297.159.542.195.735.037.187.068.411.094.672h.047a7.49 7.49 0 01.094-.672c.036-.193.101-.438.195-.735l2.117-6.851c.13-.417.347-.625.649-.625h2.265c.365 0 .547.195.547.586v10.031c0 .39-.182.586-.547.586h-1.187c-.365 0-.547-.195-.547-.586v-6.453c0-.313.013-.625.04-.938h-.056l-2.257 7.344c-.13.422-.355.633-.672.633h-1.328c-.318 0-.542-.21-.672-.633l-2.258-7.344h-.055c.026.313.04.625.04.938v6.453c0 .39-.183.586-.548.586h-1.187zm14.281 0c-.364 0-.547-.195-.547-.586V36.383c0-.39.183-.586.547-.586h1.266c.312 0 .468.195.468.586v8.68h4.555c.39 0 .586.169.586.507v.922c0 .339-.195.508-.586.508h-6.289z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
