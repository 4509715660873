import { FC, memo, useState } from "react";
import { ActivityIndicator, Platform, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { AppText, IconCustom, ImageCustom } from "components";
import { Colors, Fonts } from "theme";
import { EXTENSION_FILE, UploadFileItem } from "./types";
import PdfFileIcon from "components/UploadFiles/icons/PdfFileIcon";
import { formatBytes, formatTime } from "utils";
import * as mime from "react-native-mime-types";
import PNGIcon from "components/UploadFiles/icons/PNGIcon";
import JPGIcon from "components/UploadFiles/icons/JPGIcon";
import XlsIcon from "components/UploadFiles/icons/XlsIcon";
import DocIcon from "components/UploadFiles/icons/DocIcon";
import XmlIcon from "components/UploadFiles/icons/XmlIcon";
import ZipIcon from "components/UploadFiles/icons/ZipIcon";
import CsvIcon from "components/UploadFiles/icons/CsvIcon";
import MsgIcon from "components/UploadFiles/icons/MsgIcon";
import FileDefaultIcon from "components/UploadFiles/icons/FileDefaultIcon";
import EmlIcon from "components/UploadFiles/icons/EmlIcon";
import FileViewer from "react-native-file-viewer";
import { v4 } from "uuid";
import * as FileSystem from "expo-file-system";
import { ProgressBar } from "react-native-paper";

interface FileItemProps {
  file: UploadFileItem;
  style?: ViewStyle;
  width?: number;
  height?: number;
  handlePreviewImage?: () => void;
  onRemove?: () => void;
  loading?: boolean;
}
const FileItem: FC<FileItemProps> = ({ handlePreviewImage, file, width, height, onRemove, loading }) => {
  const { name, uri, size, fileType, createdAt } = file;
  const ext = mime.extension(fileType);

  const [isDownload, setIsDownload] = useState(false);

  const [downloadProgress, setDownloadProgress] = useState(0);
  const isLocalFile = !uri?.startsWith("http");
  const isImageFile = [EXTENSION_FILE.PNG, EXTENSION_FILE.JPG, EXTENSION_FILE.JPEG].includes(ext as EXTENSION_FILE);

  const renderFileIcon = (type = "normal") => {
    const iconSize = type === "normal" ? 64 : 20;
    switch (ext) {
      case EXTENSION_FILE.PDF: {
        return <PdfFileIcon size={iconSize} />;
      }
      case EXTENSION_FILE.PNG: {
        return <PNGIcon size={iconSize} />;
      }
      case EXTENSION_FILE.JPEG:
      case EXTENSION_FILE.JPG: {
        return <JPGIcon size={iconSize} />;
      }
      case EXTENSION_FILE.XML: {
        return <XmlIcon size={iconSize} />;
      }
      case EXTENSION_FILE.XLS:
      case EXTENSION_FILE.XSL:
      case EXTENSION_FILE.XLSX:
      case EXTENSION_FILE.XSLT: {
        return <XlsIcon size={iconSize} />;
      }
      case EXTENSION_FILE.DOC:
      case EXTENSION_FILE.DOCX: {
        return <DocIcon size={iconSize} />;
      }
      case EXTENSION_FILE.ZIP: {
        return <ZipIcon size={iconSize} />;
      }
      case EXTENSION_FILE.EML: {
        return <EmlIcon size={iconSize} />;
      }
      case EXTENSION_FILE.MSG: {
        return <MsgIcon size={iconSize} />;
      }
      case EXTENSION_FILE.CSV: {
        return <CsvIcon size={iconSize} />;
      }
      default:
        return <FileDefaultIcon size={iconSize} />;
    }
  };
  const callback = (downloadProgress) => {
    const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    setDownloadProgress(progress);
  };
  const thumbnailImage = isImageFile ? uri : "";

  const renderFileImage = () => {
    if (thumbnailImage) {
      return (
        <>
          <ImageCustom
            contentFit="cover"
            style={[styles.fileItem, { width: "100%", height }]}
            containerStyle={[styles.fileItem, { width: "100%", height }]}
            source={{ uri: thumbnailImage }}
          />
          <View style={styles.fileSmallIcon}>{renderFileIcon("small")}</View>
        </>
      );
    }
    return <View>{renderFileIcon()}</View>;
  };
  const handleDownloadFile = async (fileUri) => {
    if (isLocalFile) {
      await FileViewer.open(fileUri);
      return;
    }
    if (isDownload) {
      return;
    }
    setIsDownload(true);
    setDownloadProgress(0);
    try {
      const fileName = name || `bizzi_${v4()}.${ext}`;
      const downloadResumable = FileSystem.createDownloadResumable(
        fileUri,
        FileSystem.documentDirectory + fileName,
        {},
        callback
      );
      const { uri } = await downloadResumable.downloadAsync();
      await FileViewer.open(uri);
    } catch (e) {
      setIsDownload(false);
    }
    setIsDownload(false);
  };

  const handelDownloadFileOnWeb = (fileUri) => {
    window.open(fileUri, "_blank", "noopener noreferrer");
  };
  const handleClickFile = () => {
    if (isImageFile) {
      handlePreviewImage();
      return;
    }
    Platform.OS === "web" ? handelDownloadFileOnWeb(uri) : handleDownloadFile(uri);
  };
  return (
    <TouchableOpacity style={[styles.container, { width }]} disabled={isDownload} onPress={handleClickFile}>
      <View style={[styles.fileContainer, { width, height }]}>{renderFileImage()}</View>
      {isDownload && (
        <>
          <View style={[styles.downloadingBackground, { width, height }]} />
          <View style={styles.progressBar}>
            <ProgressBar progress={downloadProgress} color={Colors.info50} />
          </View>
        </>
      )}
      {loading && (
        <>
          <View style={[styles.downloadingBackground, { width, height }]} />
          <View style={styles.progressBar}>
            <ActivityIndicator color={Colors.primary50} />
          </View>
        </>
      )}
      {onRemove && (
        <TouchableOpacity style={styles.removeButton} onPress={onRemove}>
          <IconCustom name="cancel" />
        </TouchableOpacity>
      )}
      <AppText style={Fonts.SentenceCaptionMedium} ellipsizeMode="middle" numberOfLines={1}>
        {name}
      </AppText>
      {Boolean(createdAt) && (
        <View style={styles.infoRow}>
          <AppText style={Fonts.SentenceCaptionMedium} color={Colors.grayscale60}>
            {formatBytes(size)}
          </AppText>
          <View style={styles.dotIcon} />
          <AppText style={Fonts.SentenceCaptionMedium} numberOfLines={1} color={Colors.grayscale60}>
            {formatTime(createdAt)}
          </AppText>
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 3,
  },
  fileItem: {
    borderRadius: 8,
    borderColor: Colors.grayscale10,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  fileContainer: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.grayscale10,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  infoRow: { flexDirection: "row", gap: 3, alignItems: "center" },
  dotIcon: { width: 2, height: 2, borderRadius: 1, backgroundColor: Colors.grayscale60 },
  fileSmallIcon: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: 29,
    height: 29,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    justifyContent: "center",
    paddingLeft: 4,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  progressBar: { position: "absolute", top: 55, width: "90%", alignSelf: "center" },
  downloadingBackground: { backgroundColor: "rgba(0,0,0,0.5)", position: "absolute", borderRadius: 8 },
  removeButton: {
    top: -7,
    right: -7,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    flexDirection: "row",
    borderRadius: 12,
    backgroundColor: Colors.while,
  },
});

export default memo(FileItem);
