import React, { createContext, FC, useContext, useRef } from "react";
import { ScrollView } from "react-native";
import { Colors } from "theme";

type Layout = { x: number; y: number; width: number; height: number };

type LayoutLookup = {
  layouts: Record<string, Layout>;
};

type ScrollContextApis = {
  scrollForName: (selector: string, threshold?: number) => void;
  setLayoutData: (id: string, layout: Layout, mode?: "cache" | "override") => void;
};

const ScrollContextApis = createContext<ScrollContextApis>({
  scrollForName: () => {
    console.log("scrollToDefinedName is not implemented");
  },
  setLayoutData: () => {
    console.log("setLayoutForName is not implemented");
  },
});

export const useScrollContextApis = () => useContext(ScrollContextApis);

type ScrollViewCustomProps = {
  renderContent: (contextApis: ScrollContextApis) => React.ReactNode;
} & ScrollView["props"];

export const ScrollViewCustom: FC<ScrollViewCustomProps> = ({ renderContent, ...props }) => {
  const containerRef = useRef<ScrollView>();
  const layoutLookup = useRef<LayoutLookup>({ layouts: {} });
  const contextApis = useRef<ScrollContextApis>({
    scrollForName: (selector, threshold = 0) => {
      if (!selector) {
        return;
      }

      const scrollOffset = selector.split(">")?.reduce((acc, id) => {
        return acc + layoutLookup.current.layouts[id]?.y;
      }, 0);

      if (!scrollOffset) {
        return;
      }

      const scrollY = Math.max(scrollOffset - threshold, 0);
      containerRef.current.scrollTo({
        y: scrollY,
        animated: true,
      });
    },
    setLayoutData: (id, layout, mode = "override") => {
      if (mode === "cache" && layoutLookup.current.layouts[id]) {
        return;
      }

      layoutLookup.current.layouts[id] = layout;
    },
  });

  return (
    <ScrollContextApis.Provider value={contextApis.current}>
      <ScrollView ref={containerRef} {...props}>
        {renderContent(contextApis.current)}
      </ScrollView>
    </ScrollContextApis.Provider>
  );
};
