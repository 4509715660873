export interface InvoiceFilterData {
  createdExpenseStatus?: { label: string; value: INVOICE_EXPENSE_STATUS };
  invoiceDate?: {
    fromDate?: string;
    toDate?: string;
    labelDateValue?: string;
  };
}

export enum INVOICE_EXPENSE_STATUS {
  ALL = "ALL",
  CREATED = "CREATED",
  NOT_CREATED = "NOT_CREATED",
}
