import { useQuery, gql } from "@apollo/client";
import { ExpGetConvoStatsQuery, ExpGetConvoStatsQueryVariables } from "types/generated";

const QUERY_EXP_CONVO_STATS = gql`
  query expGetConvoStats($input: ExpConvoStatsInput!) {
    expConvoStats(input: $input) {
      messageCount
      unreadMessageCount
    }
  }
`;

const useExpGetConvoStats = ({ input }: ExpGetConvoStatsQueryVariables) => {
  const { data, loading, refetch } = useQuery<ExpGetConvoStatsQuery, ExpGetConvoStatsQueryVariables>(
    QUERY_EXP_CONVO_STATS,
    {
      variables: { input },
      fetchPolicy: "network-only",
      skip: !input.companyId || !input.objectId,
    }
  );

  return {
    data: data ?? { expConvoStats: undefined },
    loading,
    refetch,
  };
};

export default useExpGetConvoStats;
