import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width, height, fill = "#1C1B1F" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#D9D9D9" d="M0.00992584 0.243416H24.00992584V24.243416H0.00992584z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M5.318 20.743c-.499 0-.925-.176-1.278-.53a1.742 1.742 0 01-.53-1.277V5.55c0-.498.177-.925.53-1.278.353-.353.779-.53 1.278-.53h13.384c.499 0 .925.177 1.278.53.353.354.53.78.53 1.278v2.721h-1.5v-2.72a.3.3 0 00-.087-.222.3.3 0 00-.22-.087H5.317a.3.3 0 00-.222.087.3.3 0 00-.086.221v13.385a.3.3 0 00.086.22.3.3 0 00.222.087h13.384a.3.3 0 00.221-.086.3.3 0 00.087-.221v-2.722h1.5v2.722c0 .498-.177.924-.53 1.278-.353.353-.78.53-1.278.53H5.318zm8-4c-.499 0-.925-.176-1.278-.53a1.742 1.742 0 01-.53-1.277V9.55c0-.498.177-.925.53-1.278.353-.353.779-.53 1.278-.53h6.384c.499 0 .925.177 1.278.53.353.354.53.78.53 1.278v5.385c0 .498-.177.924-.53 1.278-.353.353-.78.53-1.278.53h-6.384zm6.384-1.5a.3.3 0 00.221-.086.3.3 0 00.087-.221V9.55a.3.3 0 00-.087-.221.3.3 0 00-.22-.087h-6.385a.3.3 0 00-.222.087.3.3 0 00-.086.221v5.385a.3.3 0 00.086.22.3.3 0 00.222.087h6.384zm-3.692-1.5c.417 0 .77-.145 1.062-.437.292-.292.438-.646.438-1.063 0-.416-.146-.77-.438-1.062a1.446 1.446 0 00-1.062-.438c-.417 0-.77.146-1.063.438a1.447 1.447 0 00-.437 1.062c0 .417.146.771.437 1.063.292.292.646.437 1.063.437z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
