import {
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  Button,
  EmployeeChooser,
  FloatFooter,
} from "components";
import { CONSTANTS } from "constants/constants";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EmployeeOption } from "./EmployeeOption";
import { Platform, StyleSheet } from "react-native";
import { Fonts } from "theme";
import { useTranslation } from "react-i18next";
import { isIphoneX } from "constants/Layout";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";

export interface EmployeeChooserPopupRef {
  present: () => void;
  close: () => void;
}
interface EmployeeChooserPopupProps {
  title: string;
  initialValue?: EmployeeOption;
  onSubmit?: (option: EmployeeOption) => void;
  onClear?: () => void;
}

const EmployeeChooserPopup = forwardRef<EmployeeChooserPopupRef, EmployeeChooserPopupProps>(
  ({ title, initialValue, onSubmit, onClear }, ref) => {
    const { t } = useTranslation("app/components/AdvanceSearch/EmployeeChooser");
    useImperativeHandle(ref, () => ({
      present: handlePresent,
      close: handleClose,
    }));
    const [chosen, setChosen] = useState<EmployeeOption>();
    useEffect(() => {
      setChosen(initialValue);
    }, [initialValue]);

    const selectEmployeeRef = useRef<BottomSheetModalCustomMethods>();
    const handleClose = () => {
      setChosen(null);
      selectEmployeeRef.current?.close();
    };

    const handlePresent = () => {
      selectEmployeeRef?.current?.present();
    };

    const handleValueChange = useCallback(
      (chosen) => {
        setChosen(chosen);
      },
      [setChosen]
    );

    const handleSubmit = async () => {
      handleClose();
      onSubmit?.({ ...chosen, region: chosen.region === "outside" ? undefined : chosen.region });

      const recentChosenInJson = await AsyncStorage.getItem(Keys.RECENT_CHOOSE_REPORT_EMPLOYEE);
      let recentChosen =
        recentChosenInJson && Array.isArray(JSON.parse(recentChosenInJson)) ? JSON.parse(recentChosenInJson) : [];

      recentChosen = recentChosen.filter((recent) => recent.employeeId !== chosen.employeeId);
      recentChosen.splice(4);
      await AsyncStorage.setItem(Keys.RECENT_CHOOSE_REPORT_EMPLOYEE, JSON.stringify([chosen, ...recentChosen]));
    };

    const handleClear = () => {
      handleClose();
      onClear?.();
    };

    return (
      <BottomSheetModalCustom
        title={title}
        ref={selectEmployeeRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
      >
        <EmployeeChooser initialValue={initialValue} onValueChange={handleValueChange} />
        <FloatFooter style={styles.gridRow2}>
          <Button onPress={handleClear} type="secondary" style={styles.bottomButton}>
            {t("clear")}
          </Button>
          <Button onPress={handleSubmit} disabled={!chosen} type="primary" style={styles.bottomButton}>
            {t("apply")}
          </Button>
        </FloatFooter>
      </BottomSheetModalCustom>
    );
  }
);

export default EmployeeChooserPopup;
const styles = StyleSheet.create({
  gridRow2: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  bottomButton: {
    flex: 1,
    ...Fonts.BodyLarge,
  },
});
