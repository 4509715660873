import { useEffect } from "react";
import Svg, { Path, Mask, G } from "react-native-svg";
import Animated, {
  Easing,
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withTiming,
  cancelAnimation,
  ReduceMotion,
} from "react-native-reanimated";

function SvgComponent() {
  const rotation = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${rotation.value}deg`,
        },
      ],
    };
  }, [rotation.value]);

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 800,
        easing: Easing.linear,
        reduceMotion: ReduceMotion.Never,
      }),
      -1
    );
    return () => cancelAnimation(rotation);
  }, []);

  return (
    <Animated.View style={animatedStyle}>
      <Svg width={67} height={66} fill="none">
        <Path
          fill="#DCEBFE"
          fillRule="evenodd"
          d="M58 33c0 13.531-10.969 24.5-24.5 24.5S9 46.531 9 33 19.969 8.5 33.5 8.5 58 19.469 58 33ZM33.5 60.5C48.688 60.5 61 48.188 61 33S48.688 5.5 33.5 5.5 6 17.812 6 33s12.312 27.5 27.5 27.5Z"
          clipRule="evenodd"
        />
        <Mask id="a" width={55} height={56} x={6} y={5} maskUnits="userSpaceOnUse">
          <Path
            fill="#fff"
            fillRule="evenodd"
            d="M58 33c0 13.531-10.969 24.5-24.5 24.5S9 46.531 9 33 19.969 8.5 33.5 8.5 58 19.469 58 33ZM33.5 60.5C48.688 60.5 61 48.188 61 33S48.688 5.5 33.5 5.5 6 17.812 6 33s12.312 27.5 27.5 27.5Z"
            clipRule="evenodd"
          />
        </Mask>
        <G mask="url(#a)">
          <Path
            fill="#1F71F4"
            fillRule="evenodd"
            d="M33.5 13.75c10.632 0 19.25 8.619 19.25 19.25H61C61 17.812 48.688 5.5 33.5 5.5v8.25Z"
            clipRule="evenodd"
          />
        </G>
      </Svg>
    </Animated.View>
  );
}

export default SvgComponent;
