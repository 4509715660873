import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <G clipPath="url(#clip0_5537_10916)">
        <Path
          d="M8 .667l5.478 1.217a.667.667 0 01.522.65v6.659a4 4 0 01-1.781 3.328L8 15.333l-4.219-2.812A3.998 3.998 0 012 9.193V2.535c0-.313.217-.583.522-.651L8 .667zm2.968 4.814l-3.3 3.3-1.885-1.886-.943.943 2.829 2.829 4.242-4.243-.943-.943z"
          fill="#37B24D"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5537_10916">
          <Path fill="#fff" d="M0 0H16V16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
