import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import groupBy from "lodash/groupBy";

import { Colors } from "theme";
import ExpenseGroupedByRequestAndBudgetListSection from "screens/Report/components/CreateOrEditForm/ExpenseGroupedByRequestAndBudgetListSection";
import { isEmpty, orderBy } from "lodash";
import { useValidateExpenseReportAmountMutation } from "hooks/useValidateExpenseReportAmount";
import { useAuth } from "contexts/AuthContext";
import { formatMoney } from "utils";

const NO_REQUEST_KEY = "no_request";

const ExpenseGroupedByRequestTabView = ({
  validateAmount,
  setValidateAmount,
  expenseList,
  isValidateBudget = false,
  handleRemoveExpense,
  handleRemoveRequest,
  onRefreshData,
  enablePartialApproval = false,
}) => {
  const { company } = useAuth();
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
  const [expenseListGroupByRequest, setExpenseListGroupByRequest] = useState([]);
  const [validateExpenseReportAmount] = useValidateExpenseReportAmountMutation();
  useEffect(() => {
    if (isEmpty(expenseList)) {
      setExpenseListGroupByRequest([]);
      return;
    }
    const expensesGroupByRequest = groupBy(
      orderBy(expenseList, ["expense_request_id"], "asc"),
      ({ expenseRequestId }) => expenseRequestId ?? NO_REQUEST_KEY
    );
    const data = Object.keys(expensesGroupByRequest).map((expenseRequestId) => {
      const expenseGroupByRequest = expensesGroupByRequest[expenseRequestId] ?? undefined;
      const expenseRequest = expenseGroupByRequest[0].expenseRequest;
      const title = expenseGroupByRequest[0].expenseRequestId
        ? `${expenseRequest?.docSequence} - ${expenseRequest?.title}`
        : t("no_expense_request");
      const status = expenseGroupByRequest[0].status;
      const titleColor = expenseGroupByRequest[0].expenseRequestId ? Colors.primary50 : Colors.grayscale60;
      const dataGroupByCategory = groupBy(expenseGroupByRequest, (i) => i?.expenseCategory?.expenseCategoryId);
      return {
        title,
        titleColor,
        status,
        expenseRequestId: expenseRequestId === NO_REQUEST_KEY ? undefined : expenseRequestId,
        groupedExpenses: Object.keys(dataGroupByCategory).map((categoryId) => {
          return {
            categoryId: dataGroupByCategory[categoryId][0]?.expenseCategory?.expenseCategoryId,
            expenses: dataGroupByCategory[categoryId],
          };
        }),
      };
    });
    setExpenseListGroupByRequest(data);
    if (isValidateBudget && expenseList?.length > 0) {
      handleValidateExpenseAmount();
    }
  }, [expenseList]);

  const handleValidateExpenseAmount = async () => {
    const expenseParamsValidate = expenseList
      .filter((item) => item.totalAmountWithoutVat > 0)
      .map((item) => ({
        expenseDate: item.expenseDate,
        spendingAmount: item.totalAmountWithoutVat,
        spendingAmountWithVat: item?.totalAmountWithVat,
        spendingAmountWithoutVat: item?.totalAmountWithoutVat,
        expenseCategoryId: item?.expenseCategory?.expenseCategoryId,
        expenseRequestId: item.expenseRequestId ?? undefined,
        companyTeamId: item.companyTeamId ?? undefined,
      }));
    const rs = await validateExpenseReportAmount({
      variables: {
        input: {
          companyId: company.company_id,
          expenses: expenseParamsValidate,
        },
      },
    });
    const validateAmount = rs?.data?.validateExpenseReportAmount?.expenseRequests ?? [];
    const formatMessageValidateReportAmount = validateAmount.map((item) => {
      const overRequestAmount = item.overRequestAmount;
      const message = item.message;
      const overRequestAmountMessage = overRequestAmount
        ? t("validate_budget_message", { message, overdueAmount: formatMoney(overRequestAmount) })
        : message;
      return { ...item, message: overRequestAmountMessage };
    });
    setValidateAmount(formatMessageValidateReportAmount);
  };

  return (
    <View style={styles.container}>
      <ExpenseGroupedByRequestAndBudgetListSection
        groupBy="request"
        expenses={expenseListGroupByRequest}
        handleRemoveExpense={handleRemoveExpense}
        requestValidateReportAmount={validateAmount}
        handleRemoveRequest={handleRemoveRequest}
        onRefreshData={onRefreshData}
        enablePartialApproval={enablePartialApproval}
      />
    </View>
  );
};
export default ExpenseGroupedByRequestTabView;
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
  },
});
