import {
  AppText,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  CurrencyInput,
  IconCustom,
  RadioSelect,
  SelectCustom,
  SwitchButton,
  TextInputCustom,
  TouchableOpacityCustom,
} from "components";
import { Colors, Fonts } from "theme";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import React, { FC, useEffect, useRef, useState, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { DollarCircleOutline, MoneyTransferOutline, VNDIcon } from "assets/images/svg/icons";
import BankListView from "screens/Expense/components/BankListView";
import useBanksQuery from "screens/Expense/hooks/useBanksQuery";
import { ACCOUNT_TYPES, CONSTANTS, PAYMENT_METHOD } from "constants/constants";
import { BankAccount, PaymentInfoFormValues } from "screens/Expense/components/ExpenseForm/types";
import PaymentInfoForm from "screens/Expense/components/PaymentInfoForm/PaymentInfoForm";
import BankAccountChooser, {
  PaymentChooserFormRef,
} from "screens/Expense/components/BankAccountChooser/BankAccountChooser";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { convertViToEn, startLayoutAnimation } from "utils";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import PickerCustom from "../../../../components/InputCustom/PickerCustom";
import { MobileExpBankQrParserQuery } from "types";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";
import { EVENT } from "constants/Tracking";

interface AdvancePaymentSectionProps {
  name?: string;
  control?: Control<ExpenseRequestFormValues>;
  rules?: RegisterOptions<ExpenseRequestFormValues, "paymentInfo">;
  errors?: FieldErrors<ExpenseRequestFormValues>;
  setValue?: UseFormSetValue<ExpenseRequestFormValues>;
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
  watch?: UseFormWatch<ExpenseRequestFormValues>;
  totalAmount?: number;
  setPosition?: (key: string) => (y: number) => void;
  containerRef?: RefObject<any>;
}
const getPaymentOptions = (t) => {
  return [
    { label: t("personal_payment"), value: ACCOUNT_TYPES.PERSONAL },
    { label: t("enterprise_payment"), value: ACCOUNT_TYPES.ENTERPRISE },
  ];
};
const AdvancePaymentSection: FC<AdvancePaymentSectionProps> = ({
  setValue,
  control,
  getValues,
  errors,
  watch,
  totalAmount,
  setPosition,
  containerRef,
}) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const navigation = useNavigation<any>();

  const selectBankModalRef = useRef<BottomSheetModalCustomMethods>(null);
  const bankAccountChooserRef = useRef<PaymentChooserFormRef>(null);
  const [paymentInfoPersonal, setPaymentInfoPersonal] = useState<ExpenseRequestFormValues["paymentInfo"]>(null);
  const [paymentInfoBusiness, setPaymentInfoBusiness] = useState<ExpenseRequestFormValues["paymentInfo"]>(null);
  const savePaymentModalRef = useRef<any>(null);
  const [, { data, refetch, loading }] = useBanksQuery();
  const banks = data?.expBanks?.banks || [];
  const isShowSaveButton = watch([
    "paymentInfo.accountHolderName",
    "paymentInfo.accountNumber",
    "paymentInfo.bank",
  ])?.every((item) => Boolean(item));
  const isEnableCurrency = watch("currency.isOn");
  const exchangeRate = watch("currency.exchangeRate");
  const [textSearch, setTextSearch] = useState("");
  const [filteredBanks, setFilteredBanks] = useState([]);

  useEffect(() => {
    if (textSearch) {
      const textSearchInLowerCase = convertViToEn(textSearch).toLocaleLowerCase();
      const filtered = banks.filter((bank) =>
        [bank.bankShortName, bank.bankCode, bank.bankName].some((t) =>
          convertViToEn(String(t)).toLocaleLowerCase().includes(textSearchInLowerCase)
        )
      );
      setFilteredBanks(filtered);
    }
  }, [textSearch]);

  const handleOpenBankModal = () => {
    selectBankModalRef?.current?.present();
    if (!data) {
      refetch();
    }
  };
  const handleOpenSavePaymentModal = () => {
    const paymentInfo = getValues("paymentInfo") as ExpenseRequestFormValues["paymentInfo"];
    const values = {
      bankOption: {
        key: paymentInfo?.bank?.key,
        label: paymentInfo?.bank?.label,
        metadata: paymentInfo?.bank?.metadata,
      },
      accountNumber: paymentInfo?.accountNumber,
      accountHolderName: paymentInfo?.accountHolderName,
      branchName: paymentInfo?.branchName,
    } as PaymentInfoFormValues;
    savePaymentModalRef?.current?.present({ accountType: paymentInfo.type, values });
  };
  const onSelectPaymentAccount = (values: BankAccount) => {
    const paymentInfo = {
      type: values?.type,
      bank: { key: values?.bankId, label: `${values?.bankShortName} (${values?.bankCode})`, metadata: values },
      accountHolderName: values?.accountHolderName,
      accountNumber: values?.accountNumber,
      branchName: values?.branchName,
    } as ExpenseRequestFormValues["paymentInfo"];
    bankAccountChooserRef?.current?.close();
    setValue("paymentInfo.type", paymentInfo.type);
    setValue("paymentInfo.bank", paymentInfo.bank, { shouldValidate: true });
    setValue("paymentInfo.accountHolderName", paymentInfo.accountHolderName, { shouldValidate: true });
    setValue("paymentInfo.accountNumber", paymentInfo.accountNumber, { shouldValidate: true });
    setValue("paymentInfo.branchName", paymentInfo.branchName);
  };
  const handleOpenPaymentForm = () => {
    bankAccountChooserRef?.current?.close();
    savePaymentModalRef?.current?.present({ accountType: getValues("paymentInfo.type") });
  };
  const setPaymentInfoValue = ({
    bank = null,
    accountHolderName = "",
    accountNumber = "",
    branchName = "",
    description = "",
  }) => {
    setValue("paymentInfo.bank", bank, { shouldValidate: true });
    setValue("paymentInfo.accountHolderName", accountHolderName, { shouldValidate: true });
    setValue("paymentInfo.accountNumber", accountNumber, { shouldValidate: true });
    setValue("paymentInfo.branchName", branchName);
    setValue("paymentInfo.description", description);
  };
  const handleSelectType = (type) => {
    const paymentInfo = { ...getValues("paymentInfo") };
    /*-- save current payment info for each type --*/
    if (type === ACCOUNT_TYPES.PERSONAL) {
      setPaymentInfoBusiness(paymentInfo);
    } else if (type === ACCOUNT_TYPES.ENTERPRISE) {
      setPaymentInfoPersonal(paymentInfo);
    }
    setValue("paymentInfo.type", type);
    /*-- end --*/
    if (type === ACCOUNT_TYPES.PERSONAL) {
      setPaymentInfoValue({
        bank: paymentInfoPersonal?.bank,
        accountHolderName: paymentInfoPersonal?.accountHolderName,
        accountNumber: paymentInfoPersonal?.accountNumber,
        branchName: paymentInfoPersonal?.branchName,
        description: paymentInfoPersonal?.description,
      });
    } else if (type === ACCOUNT_TYPES.ENTERPRISE) {
      setPaymentInfoValue({
        bank: paymentInfoBusiness?.bank,
        accountHolderName: paymentInfoBusiness?.accountHolderName,
        accountNumber: paymentInfoBusiness?.accountNumber,
        branchName: paymentInfoBusiness?.branchName,
        description: paymentInfoBusiness?.description,
      });
    }
  };

  const onChoosePaymentMethod = (item, onSelect) => () => {
    setValue("cashAdvance.paymentMethod", item?.value);
    onSelect();
  };
  const hasCashAdvance = watch("hasCashAdvance");
  const paymentMethod = watch("cashAdvance.paymentMethod");
  const [offsetYParent, setOffsetYParent] = useState(0);
  const handleSetPosition = (key) => (offsetY) => {
    setPosition(key)(offsetYParent + offsetY);
  };
  const onToggleCashAdvance = (value) => {
    startLayoutAnimation();
    setValue("hasCashAdvance", value);
  };
  const onFinishScanQrCode = (data: MobileExpBankQrParserQuery["expBankQRParser"]["paymentInfo"]) => {
    setPaymentInfoValue({
      bank: {
        key: data?.bankId,
        label: `${data?.bankShortName} (${data?.bankCode})`,
        metadata: data as BankAccount,
      },
      accountHolderName: data?.accountHolderName,
      accountNumber: data?.accountNumber,
      branchName: data?.branchName,
      description: data?.description,
    });
  };
  const navigateToScanQrCode = () => {
    navigation.navigate(SCREEN_NAME.ScanQrCodeScreen, { onFinish: onFinishScanQrCode });
  };
  return (
    <View style={styles.container} onLayout={(event) => setOffsetYParent(event.nativeEvent.layout.y)}>
      <View style={styles.titleContainer}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("advance")}</AppText>
        <SwitchButton onValueChange={onToggleCashAdvance} value={hasCashAdvance} />
      </View>
      {hasCashAdvance && (
        <>
          <View style={styles.contentContainer}>
            {isEnableCurrency && (
              <CurrencyInput
                name="cashAdvance.foreignAmount"
                containerRef={containerRef}
                setPosition={handleSetPosition("cashAdvance.foreignAmount")}
                placeholder="0"
                right={<IconCustom name="attach-money" width={26} height={26} fill="#94A3B8" />}
                control={control}
                rules={{
                  required: t("required"),
                  max: { value: totalAmount / exchangeRate, message: t("advance_max") },
                }}
                error={errors?.cashAdvance?.foreignAmount}
                label={t("cash_advance_foreign_amount")}
                onChange={(amount) => {
                  setValue("cashAdvance.amount", amount * getValues("currency.exchangeRate"));
                }}
              />
            )}
            <CurrencyInput
              disabled={isEnableCurrency}
              containerRef={containerRef}
              setPosition={handleSetPosition("cashAdvance.amount")}
              right={<VNDIcon />}
              style={[styles.textInput, { marginBottom: 10 }]}
              name="cashAdvance.amount"
              control={control}
              label={t("advance_amount")}
              placeholder={t("advance_amount")}
              error={errors?.cashAdvance?.amount}
              rules={
                !isEnableCurrency
                  ? {
                      required: t("required"),
                      min: { value: 1000, message: t("advance_min", { min: 1000 }) },
                      max: { value: totalAmount, message: t("advance_max") },
                    }
                  : undefined
              }
            />
            <TextInputCustom
              containerRef={containerRef}
              setPosition={handleSetPosition("cashAdvance.description")}
              multiline
              autoHeight
              name="cashAdvance.description"
              control={control}
              label={t("advance_purpose")}
              placeholder={t("advance_purpose")}
              error={errors?.cashAdvance?.description}
              maxLength={1000}
              rules={{
                required: t("required"),
              }}
            />
            <PickerCustom
              required
              style={{ marginTop: 8 }}
              title={t("payment_method")}
              control={control}
              name="cashAdvance.paymentMethod"
              label={t("payment_method")}
              options={[
                { label: t("bank_transfers"), value: PAYMENT_METHOD.BANK_TRANSFER, icon: <MoneyTransferOutline /> },
                { label: t("cash"), value: PAYMENT_METHOD.CASH, icon: <DollarCircleOutline /> },
              ]}
              renderPickerItem={(item, onSelect) => {
                const isActive = item?.value === getValues("cashAdvance.paymentMethod");
                return (
                  <TouchableOpacity
                    key={item?.label}
                    style={[styles.paymentItem, isActive && { backgroundColor: Colors.primary0 }]}
                    onPress={onChoosePaymentMethod(item, onSelect)}
                  >
                    <View style={styles.optionIcon}>{item?.icon}</View>
                    <AppText style={[Fonts.BodyLarge, styles.centerContent]}>{item?.label}</AppText>
                  </TouchableOpacity>
                );
              }}
            />
            {paymentMethod !== PAYMENT_METHOD.CASH && (
              <View style={styles.paymentInfoContainer}>
                <View style={styles.paymentInfoHeader}>
                  <RadioSelect
                    style={{ marginBottom: 12, marginTop: 5 }}
                    options={getPaymentOptions(t)}
                    name="paymentInfo.type"
                    control={control}
                    onSelect={(value) => handleSelectType(value)}
                  />
                  {Platform.OS !== "web" && (
                    <TouchableOpacityCustom eventName={EVENT.EXPENSE.SCAN_QR_CODE} onPress={navigateToScanQrCode}>
                      <IconCustom name="qr-code-scanner" fill={Colors.grayscale60} />
                    </TouchableOpacityCustom>
                  )}
                </View>
                <SelectCustom
                  containerRef={containerRef}
                  setPosition={handleSetPosition("paymentInfo.bank")}
                  style={styles.textInput}
                  name="paymentInfo.bank"
                  control={control}
                  onPress={handleOpenBankModal}
                  label={t("bank")}
                  rules={{ required: t("required") }}
                  error={errors?.paymentInfo?.bank}
                />
                <TextInputCustom
                  containerRef={containerRef}
                  setPosition={handleSetPosition("paymentInfo.accountNumber")}
                  style={styles.textInput}
                  name="paymentInfo.accountNumber"
                  control={control}
                  keyboardType="numeric"
                  label={t("account_number")}
                  placeholder={t("account_number")}
                  rules={{ required: t("required") }}
                  absoluteRight={
                    <TouchableOpacity
                      activeOpacity={1}
                      style={styles.rightButton}
                      onPress={() => bankAccountChooserRef?.current?.present(getValues("paymentInfo.type"))}
                    >
                      <IconCustom name="demography" />
                    </TouchableOpacity>
                  }
                  error={errors?.paymentInfo?.accountNumber}
                />
                <TextInputCustom
                  containerRef={containerRef}
                  setPosition={handleSetPosition("paymentInfo.accountHolderName")}
                  style={styles.textInput}
                  name="paymentInfo.accountHolderName"
                  control={control}
                  label={t("account_holder_name")}
                  placeholder={t("account_holder_name")}
                  rules={{ required: t("required") }}
                  error={errors?.paymentInfo?.accountHolderName}
                  autoCapitalize={"characters"}
                />
                <TextInputCustom
                  style={styles.textInput}
                  name="paymentInfo.branchName"
                  control={control}
                  label={t("branch_name")}
                  placeholder={t("branch_name")}
                />
                <TextInputCustom
                  multiline
                  autoHeight
                  style={styles.textInput}
                  name="paymentInfo.description"
                  control={control}
                  label={t("description_payment")}
                  placeholder={t("description_payment")}
                />
                {isShowSaveButton && (
                  <TouchableOpacity onPress={handleOpenSavePaymentModal} style={styles.buttonSavePayment}>
                    <AppText style={Fonts.BodyLarge} color={Colors.primary50}>
                      {t("save_payment")}
                    </AppText>
                  </TouchableOpacity>
                )}
              </View>
            )}

            <BottomSheetModalCustom
              title={t("bank")}
              ref={selectBankModalRef}
              snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
            >
              <BankListView
                data={textSearch ? filteredBanks : banks}
                textSearch={textSearch}
                onChangeSearch={setTextSearch}
                handleClearSearch={() => setTextSearch("")}
                loading={loading}
                handleSelectBank={(bank: BankAccount) => {
                  selectBankModalRef?.current?.close();
                  setValue(
                    "paymentInfo.bank",
                    { key: bank.bankId, label: `${bank.bankShortName} (${bank.bankCode})`, metadata: bank },
                    { shouldValidate: true }
                  );
                  setValue("paymentInfo.accountNumber", "");
                  setValue("paymentInfo.accountHolderName", "");
                  setValue("paymentInfo.branchName", "");
                }}
              />
            </BottomSheetModalCustom>
            <PaymentInfoForm ref={savePaymentModalRef} />
            <BankAccountChooser
              handleOpenPaymentForm={handleOpenPaymentForm}
              onSelect={onSelectPaymentAccount}
              ref={bankAccountChooserRef}
            />
          </View>
        </>
      )}
    </View>
  );
};
export default AdvancePaymentSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentContainer: { overflow: "hidden", marginTop: 10 },
  paymentInfoContainer: { marginTop: 12 },
  textInput: {
    marginVertical: 6,
  },
  rightButton: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttonSavePayment: { marginTop: 7 },

  paymentItem: {
    flexDirection: "row",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 17,
  },
  optionIcon: {
    marginRight: 16,
  },
  centerContent: {
    alignItems: "center",
  },
  paymentInfoHeader: { flexDirection: "row", alignItems: "center" },
});
