import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.002 17.22l-3.89 2.345a.72.72 0 01-.436.107.792.792 0 01-.396-.147.84.84 0 01-.273-.332.648.648 0 01-.03-.453l1.032-4.417-3.434-2.973a.737.737 0 01-.245-.387.702.702 0 01.027-.428.845.845 0 01.233-.34.807.807 0 01.415-.172l4.533-.396 1.76-4.171a.68.68 0 01.291-.344.819.819 0 01.825 0 .68.68 0 01.291.344l1.76 4.17 4.533.397a.806.806 0 01.415.172c.101.088.179.201.233.34a.701.701 0 01.028.428.737.737 0 01-.246.387l-3.434 2.973 1.032 4.417a.647.647 0 01-.03.453.84.84 0 01-.273.332.792.792 0 01-.395.147.72.72 0 01-.436-.107l-3.89-2.346z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
