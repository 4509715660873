import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={28} height={26} viewBox="0 0 28 26">
      <Path
        d="M24.333 7.333a.967.967 0 01-.712-.287.968.968 0 01-.288-.713V4.667h-1.666a.967.967 0 01-.713-.288.968.968 0 01-.287-.713c0-.283.095-.52.287-.712a.968.968 0 01.713-.287h1.666V1c0-.283.096-.52.288-.713A.968.968 0 0124.334 0c.283 0 .52.096.712.287.192.192.287.43.287.713v1.667H27c.283 0 .52.096.712.287.192.192.288.43.288.713 0 .284-.096.521-.288.712a.968.968 0 01-.712.288h-1.667v1.666c0 .284-.096.521-.287.713a.968.968 0 01-.713.287zM12.667 20.82c1.53 0 2.826-.532 3.89-1.596 1.065-1.064 1.597-2.36 1.597-3.89 0-1.53-.532-2.828-1.596-3.892-1.065-1.064-2.361-1.596-3.891-1.596-1.53 0-2.827.532-3.891 1.596S7.18 13.803 7.18 15.333c0 1.53.532 2.827 1.596 3.891s2.36 1.596 3.89 1.596zm0-2c-.985 0-1.812-.335-2.482-1.005-.67-.67-1.006-1.497-1.006-2.482 0-.984.335-1.812 1.006-2.482.67-.67 1.497-1.005 2.482-1.005.984 0 1.812.335 2.482 1.005.67.67 1.005 1.498 1.005 2.482 0 .985-.335 1.812-1.005 2.482-.67.67-1.498 1.005-2.482 1.005zM2.41 25.334a2.327 2.327 0 01-1.71-.7 2.327 2.327 0 01-.7-1.71V7.743C0 7.07.233 6.5.7 6.034a2.327 2.327 0 011.71-.7h4.072l1.744-1.89c.22-.238.486-.428.797-.567.31-.14.636-.21.977-.21h6.744c.283 0 .52.097.712.288.192.192.288.43.288.713v2.115c0 .502.175.929.527 1.28.351.352.778.528 1.28.528h.86v.859c0 .502.175.929.526 1.28.352.352.779.527 1.28.527h2.116c.284 0 .521.096.713.288.191.191.287.429.287.712v11.667c0 .674-.233 1.244-.7 1.71-.466.467-1.037.7-1.71.7H2.41z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
