import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  MobileCrdSuggestAssignableExpenseCardTransactionsQuery,
  MobileCrdSuggestAssignableExpenseCardTransactionsQueryVariables,
} from "types";

const QUERY = gql`
  query MobileCrdSuggestAssignableExpenseCardTransactions(
    $input: CrdSuggestAssignableExpenseCardTransactionsInput!
    $limit: Int!
  ) {
    crdSuggestAssignableExpenseCardTransactions(input: $input, limit: $limit) {
      transactions {
        status
        companyId
        amount
        cardTransactionId
        transactionDate
        location
        card {
          cardNumber
          cardId
          maskedCardNumber
          prefixCardNumber
          suffixCardNumber
        }
        cardTransactionExpenses {
          expenseId
          cardTransactionId
          expense {
            docSequence
          }
        }
      }
    }
  }
`;

const useSuggestAssignableExpenseCardTransactions = (
  variables: MobileCrdSuggestAssignableExpenseCardTransactionsQueryVariables
) => {
  return useLazyQuery<
    MobileCrdSuggestAssignableExpenseCardTransactionsQuery,
    MobileCrdSuggestAssignableExpenseCardTransactionsQueryVariables
  >(QUERY, {
    variables,
  });
};

export default useSuggestAssignableExpenseCardTransactions;
