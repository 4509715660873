import { EVENT } from "constants/Tracking";
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import {
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetTextInputCustom,
  Button,
} from "components";
import React, { useEffect, useRef, useState } from "react";
import { useReportManage } from "screens/Report/context/ReportManageProvider";
import { useTranslation } from "react-i18next";
import { Fonts } from "theme";
import BizziBotWarning2 from "assets/images/svg/icons/BizziBotWarning2";
import { useMasterData } from "contexts/MasterDataContext";
import LanguageStatus from "constants/LanguageStatus";

const ButtonApproveReport = ({ reportTitle, onApprove, disabled, expenseIds, isCanPartialApprove }) => {
  const { approvedExpenseIds, setApprovedExpenseIds } = useReportManage();
  const totalExpense = expenseIds?.length;
  const { t, i18n } = useTranslation("app/screens/Report/ReportDetailScreen");
  const bottomSheetModalRef = useRef<BottomSheetModalCustomMethods>(null);
  const [reason, setReason] = useState("");
  const { withApprovalReason } = useMasterData();

  useEffect(() => {
    if (isCanPartialApprove) {
      setApprovedExpenseIds(expenseIds);
    }
  }, []);

  const onClickApprove = async () => {
    // flow partial approve report and flow input reason when approve
    if ((approvedExpenseIds?.length && approvedExpenseIds?.length < totalExpense) || withApprovalReason) {
      bottomSheetModalRef?.current?.present();
      return;
    }
    onApprove();
  };

  return (
    <>
      <Button
        eventName={EVENT.REPORT.APPROVED}
        style={styles.footerButtonRight}
        disabled={disabled || (isCanPartialApprove && !approvedExpenseIds?.length)}
        onPress={onClickApprove}
        type="success"
      >
        {`${t("approve")}${isCanPartialApprove ? ` (${approvedExpenseIds.length}/${totalExpense})` : ""}`}
      </Button>
      <BottomSheetScrollViewModalCustom ref={bottomSheetModalRef} wrapperByScrollView={false}>
        <TouchableWithoutFeedback onPress={Platform.OS !== "web" && Keyboard.dismiss}>
          <View style={styles.container}>
            <BizziBotWarning2 width={80} height={80} />
            {withApprovalReason &&
            (!isCanPartialApprove || !approvedExpenseIds?.length || approvedExpenseIds?.length === totalExpense) ? (
              <View style={styles.labelContainer}>
                <AppText style={Fonts.H400}>{t("approve_report_title")}</AppText>
                <AppText style={[Fonts.BodyMedium, styles.confirmText]} numberOfLines={2}>
                  {t("approve_report_description")}
                  <AppText style={Fonts.H200}>{` "${reportTitle}" `}</AppText>
                  {i18n.language === LanguageStatus.EN ? "report?" : null}
                </AppText>
              </View>
            ) : (
              <View style={styles.labelContainer}>
                <AppText style={Fonts.H400}>{t("partial_approve_report_title")}</AppText>
                <AppText style={[Fonts.BodyMedium, styles.confirmText]} numberOfLines={2}>
                  {t("partial_approve_report")}
                  <AppText style={Fonts.H200}>{` ${approvedExpenseIds.length}/${totalExpense} `}</AppText>
                  {t("partial_approve_report_description", { remainExpense: totalExpense - approvedExpenseIds.length })}
                  <AppText style={Fonts.H200}>{` ${t("draft")}.`}</AppText>
                </AppText>
              </View>
            )}
            <BottomSheetTextInputCustom
              autoFocus
              value={reason}
              onChangeText={setReason}
              placeholder={t("reason_approve")}
            />
          </View>
        </TouchableWithoutFeedback>

        <View style={styles.footerButton}>
          <Button type="white" style={styles.buttonClose} onPress={() => bottomSheetModalRef?.current?.close()}>
            {t("close")}
          </Button>
          <Button
            style={styles.buttonApprove}
            onPress={() => {
              bottomSheetModalRef?.current?.close();
              onApprove(reason, approvedExpenseIds);
            }}
          >
            {t("confirm")}
          </Button>
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default ButtonApproveReport;

const styles = StyleSheet.create({
  footerButtonRight: { flex: 1, marginLeft: 6 },
  container: { paddingHorizontal: 20, justifyContent: "center", alignItems: "center" },
  labelContainer: { alignItems: "center", marginTop: 20 },
  confirmText: { marginTop: 8, textAlign: "center" },
  footerButton: { flexDirection: "row", paddingHorizontal: 20, marginTop: 20, paddingBottom: 10 },
  buttonClose: { flex: 1, marginRight: 8 },
  buttonApprove: { flex: 1, marginLeft: 8 },
});
