import Svg, { Path, SvgProps } from "react-native-svg";

const SvgComponent = ({ fill = "#0F172A", width = 24, height = 24 }: SvgProps) => (
  <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <Path d="M12.75 15.92h.65c.65 0 1.19-.58 1.19-1.28 0-.87-.31-1.04-.82-1.22l-1.01-.35v2.85h-.01z" fill={fill} />
    <Path
      d="M11.97 1.898c-5.52.02-9.99 4.51-9.97 10.03.02 5.52 4.51 9.99 10.03 9.97 5.52-.02 9.99-4.51 9.97-10.03-.02-5.52-4.51-9.98-10.03-9.97zm2.29 10.1c.78.27 1.83.85 1.83 2.64 0 1.54-1.21 2.78-2.69 2.78h-.65v.58c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.58h-.36c-1.64 0-2.97-1.38-2.97-3.08 0-.41.34-.75.75-.75s.75.34.75.75c0 .87.66 1.58 1.47 1.58h.36v-3.38l-1.51-.54c-.78-.27-1.83-.85-1.83-2.64 0-1.54 1.21-2.78 2.69-2.78h.65v-.58c0-.41.34-.75.75-.75s.75.34.75.75v.58h.36c1.64 0 2.97 1.38 2.97 3.08 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.87-.66-1.58-1.47-1.58h-.36v3.38l1.51.54z"
      fill={fill}
    />
    <Path d="M9.42 9.368c0 .87.31 1.04.82 1.22l1.01.35v-2.86h-.65c-.65 0-1.18.58-1.18 1.29z" fill={fill} />
  </Svg>
);

export default SvgComponent;
