import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpenseReportApproveQuery, MobileExpenseReportApproveQueryVariables } from "types";

const QUERY_REPORTS = gql`
  query MobileExpenseReportApprove(
    $where: ExpMobileExpenseReportCondition!
    $sortOption: ExpMobileExpenseReportsSortOptions
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
  ) {
    expWaitingApprovalExpenseReports: expMobileExpenseReports(
      where: $where
      sortOption: $sortOption
      limit: $limit
      offset: $offset
    ) {
      total
      reports {
        allocatedStatus
        expenseReportId
        title
        docSequence
        expenses {
          expenseId
          totalAmountWithVat
        }
        user {
          userId
          fullname
          email
        }
        companyEmployee {
          fullname
        }
        createdAt
        requestedAt
        paidAt
        rejectedAt
        approvedAt
        status
      }
    }
  }
`;

const useExpenseReportsApproval = (variables: MobileExpenseReportApproveQueryVariables) => {
  return useQuery<MobileExpenseReportApproveQuery, MobileExpenseReportApproveQueryVariables>(QUERY_REPORTS, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !variables.where.companyId || !variables.where.companyEmployeeId,
  });
};

export default useExpenseReportsApproval;
