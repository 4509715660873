import { AppText, LoadingView } from "components";
import { StyleSheet, View, ScrollView } from "react-native";
import { Colors, Fonts } from "theme";
import { FC } from "react";
import { CONSTANTS } from "constants/constants";
import { EmptyPolicy } from "assets/images/svg/icons";
import { useTranslation } from "react-i18next";
import { MobileExpEmployeeExpensePolicyTravelQuery } from "types";

type NumberOfTravelDaysPolicyProp = {
  loading: boolean;
  limitation: MobileExpEmployeeExpensePolicyTravelQuery["expEmployeeExpensePolicyTravel"]["limitation"];
};

const NumberOfTravelDaysPolicy: FC<NumberOfTravelDaysPolicyProp> = ({ limitation, loading }) => {
  const { t } = useTranslation("app/screens/PolicyEmployee/PolicyEmployeeScreen");
  if (loading) {
    return <LoadingView style={styles.loadingView} />;
  }

  if (!limitation || !limitation.isEnabled) {
    return (
      <View style={styles.emptyContainer}>
        <EmptyPolicy />
        <View style={[styles.emptyContent, styles.gap8]}>
          <AppText style={[Fonts.H400, styles.textCenter]} color={Colors.grayscale100}>
            {t("empty_limitation_policy")}
          </AppText>
          <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale100}>
            {t("empty_limitation_policy_description")}
          </AppText>
        </View>
      </View>
    );
  }

  return (
    <ScrollView contentContainerStyle={[styles.scrollView, styles.gap24]} showsVerticalScrollIndicator={false}>
      <AppText style={Fonts.BodyMedium}>{t("business_travel_day_description")}</AppText>
      <View style={styles.gap8}>
        <AppText style={Fonts.SentenceSubTitleXLarge}>{t("maximum_travel_day")}</AppText>
        <AppText style={Fonts.BodyMedium}>
          {t("limitation_maximum_travel_day")}
          <AppText style={Fonts.H200}>
            {limitation.maxTravelDays}{" "}
            {
              {
                monthly: t("days_per_month"),
                quarterly: t("day_per_quarter"),
              }[limitation.period]
            }
          </AppText>
        </AppText>
      </View>
    </ScrollView>
  );
};

export default NumberOfTravelDaysPolicy;

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  gap24: { gap: 24 },
  gap8: { gap: 8 },
  textCenter: {
    textAlign: "center",
  },
  loadingView: {
    backgroundColor: Colors.white,
  },
  emptyContainer: {
    paddingVertical: 80,
    alignItems: "center",
  },
  emptyContent: {
    padding: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
