import { Toast } from "@ant-design/react-native";
import config from "app/config";
import { BizziTextIcon, CircleLoginIcon, CopyIcon, ShareIcon } from "assets/images/svg/icons";
import axios from "axios";
import { AppText, Button, Line, LoadingView } from "components";
import * as Clipboard from "expo-clipboard";
import * as Device from "expo-device";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Share, StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Colors, Fonts } from "theme";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { SCREEN_NAME_TRACKING } from "navigation/ScreenName";

export default function VatInfoScreen() {
  const [isNotFound, setIsNotFound] = useState(false);
  const [vatInfo, setVatInfo] = useState(null);
  const [deviceType, setDeviceType] = useState<Device.DeviceType | null>(null);

  const { employeeId, phone } = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const employeeId = urlParams.get("e");
    const phone = urlParams.get("phone");

    return { employeeId, phone };
  }, []);

  const isLoading = useMemo(() => {
    return !vatInfo && !isNotFound;
  }, [isNotFound, vatInfo]);

  const shareMessage = useMemo(() => {
    if (!vatInfo) {
      return "";
    }

    const rows = [
      ["TÊN CÔNG TY/COMPANY", vatInfo.name],
      ["MÃ SỐ THUẾ/TAX CODE", vatInfo.tax_code],
      ["ĐỊA CHỈ/ADDRESS", vatInfo.address],
      ["EMAIL", vatInfo.email],
      ["SỐ ĐIỆN THOẠI/PHONE", vatInfo.phone],
    ];

    const message = rows
      .filter(([_title, value]) => Boolean(value))
      .map(([title, value]) => `${title}: ${value}`)
      .join("\n");

    return message;
  }, [vatInfo]);

  const copyText = useCallback(async (text) => {
    Clipboard.setString(text);
    Toast.success("Copied", 1);
  }, []);

  const onShare = useCallback(async () => {
    const title = "THÔNG TIN XUẤT HOÁ ĐƠN / COMPANY INFORMATION";
    try {
      const result = await Share.share(
        {
          title,
          message: shareMessage,
        },
        {
          subject: shareMessage, // ios
          dialogTitle: title, // Android
        }
      );
      if (result.action === Share.sharedAction) {
        analyticService.logEvent({ name: EVENT.OTHER.SHARED_VAT_INFO, properties: { action: result.action } });
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  }, [shareMessage]);

  useEffect(() => {
    if (!employeeId) {
      setIsNotFound(true);
      return;
    }

    const params = new URLSearchParams();
    params.append("e", employeeId);

    if (phone) {
      params.append("phone", phone);
    }

    axios
      .get(`${config.EXPENSE_SERVICE_ENDPOINT}/vat-info?${params.toString()}`)
      .then(({ data }) => {
        setVatInfo(data.data);
      })
      .catch(() => {
        setIsNotFound(true);
      });
  }, [employeeId, phone]);

  useEffect(() => {
    analyticService.logEvent({ name: SCREEN_NAME_TRACKING.VatInfoScreen });
  }, []);

  useEffect(() => {
    Device.getDeviceTypeAsync().then((type) => setDeviceType(type));
  });

  const canShare = [Device.DeviceType.PHONE, Device.DeviceType.TABLET].includes(deviceType);

  return (
    <View style={styles.screenContainer}>
      <View style={styles.circleIcon}>
        <CircleLoginIcon />
      </View>
      <View style={styles.bizziTextIcon}>
        <BizziTextIcon width={32} height={32} />
      </View>
      <AppText style={[Fonts.H400, styles.header]}>
        THÔNG TIN XUẤT HOÁ ĐƠN{"\n"}
        COMPANY INFORMATION
      </AppText>
      {vatInfo && (
        <View style={[styles.section, styles.boxShadow]}>
          <View style={styles.row}>
            <View style={styles.content}>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                {/* {t("full_name")} */}
                TÊN CÔNG TY / COMPANY
              </AppText>
              <AppText style={[Fonts.H300, styles.value]} color={Colors.grayscale100}>
                {vatInfo.name}
              </AppText>
            </View>
            <TouchableOpacity
              style={styles.btnCopy}
              onPress={() => {
                copyText(vatInfo.name);
              }}
            >
              <CopyIcon />
            </TouchableOpacity>
          </View>
          <Line />
          <View style={styles.row}>
            <View style={styles.content}>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                MÃ SỐ THUẾ / TAX CODE
              </AppText>
              <AppText style={[Fonts.H300, styles.value]} color={Colors.grayscale100}>
                {vatInfo.tax_code}
              </AppText>
            </View>
            <TouchableOpacity
              style={styles.btnCopy}
              onPress={() => {
                copyText(vatInfo.tax_code);
              }}
            >
              <CopyIcon />
            </TouchableOpacity>
          </View>
          <Line />
          <View style={styles.row}>
            <View style={styles.content}>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                ĐỊA CHỈ / ADDRESS
              </AppText>
              <AppText style={[Fonts.H300, styles.value]} color={Colors.grayscale100}>
                {vatInfo.address}
              </AppText>
            </View>
            <TouchableOpacity
              style={styles.btnCopy}
              onPress={() => {
                copyText(vatInfo.address);
              }}
            >
              <CopyIcon />
            </TouchableOpacity>
          </View>
          <Line />
          {vatInfo.email && (
            <>
              <View style={styles.row}>
                <View style={styles.content}>
                  <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                    EMAIL
                  </AppText>
                  <AppText style={[Fonts.H300, styles.value]} color={Colors.grayscale100}>
                    {vatInfo.email}
                  </AppText>
                </View>
                <TouchableOpacity
                  style={styles.btnCopy}
                  onPress={() => {
                    copyText(vatInfo.email);
                  }}
                >
                  <CopyIcon />
                </TouchableOpacity>
              </View>
              <Line />
            </>
          )}
          {vatInfo.phone && (
            <View style={styles.row}>
              <View style={styles.content}>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  SỐ ĐIỆN THOẠI / PHONE
                </AppText>
                <AppText style={[Fonts.H300, styles.value]} color={Colors.grayscale100}>
                  {vatInfo.phone}
                </AppText>
              </View>
              <TouchableOpacity
                style={styles.btnCopy}
                onPress={() => {
                  copyText(vatInfo.phone);
                }}
              >
                <CopyIcon />
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}

      {vatInfo &&
        (canShare ? (
          <Button
            onPress={() => {
              onShare();
            }}
            style={styles.btnAction}
          >
            <View style={styles.btnActionContent}>
              <ShareIcon color={Colors.white} /> CHIA SẺ
            </View>
          </Button>
        ) : (
          <Button
            onPress={() => {
              copyText(shareMessage);
            }}
            style={styles.btnAction}
          >
            <View style={styles.btnActionContent}>
              <CopyIcon color={Colors.white} /> SAO CHÉP
            </View>
          </Button>
        ))}

      {isLoading && <LoadingView size={"large"} />}

      {isNotFound && (
        <View style={[styles.section, styles.boxShadow]}>
          <AppText style={styles.notFound}>Không tìm thấy / Not found</AppText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  circleIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 0,
  },
  bizziTextIcon: { position: "absolute", left: 20, top: 20, zIndex: 0 },
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: 20,
  },
  header: {
    marginTop: 40,
    paddingVertical: 20,
    textAlign: "center",
    zIndex: 1,
  },
  boxShadow: {
    backgroundColor: Colors.white,
    borderRadius: 16,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
  },
  section: {
    backgroundColor: Colors.white,
    borderRadius: 16,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  content: { flex: 1 },
  value: {
    marginTop: 4,
  },
  btnCopy: {
    marginLeft: 12,
  },
  notFound: {
    padding: 20,
  },
  btnAction: {
    marginTop: 10,
  },
  btnActionContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 48,
  },
});
