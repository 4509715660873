import React, {
  ForwardedRef,
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FlatListProps, Keyboard, StyleSheet, TextInput as RNTextInput, Platform, FlatList } from "react-native";
import {
  BottomSheetFlatListCustom,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  Line,
  SearchInput,
} from "components";
import { BottomSheetModalCustomProps } from "components/BottomSheetModalCustom/BottomSheetModalCustom";
import { SearchInputProps } from "components/SearchInput";
import { BottomSheetModal, useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { SearchIcon } from "assets/images/svg/icons";
import { filter } from "lodash";

const BottomSearchInput = forwardRef(
  ({ onFocus, onBlur, ...props }: SearchInputProps, ref: ForwardedRef<RNTextInput>) => {
    const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

    const handleOnFocus = useCallback(
      (args) => {
        shouldHandleKeyboardEvents.value = true;
        onFocus?.(args);
      },
      [onFocus, shouldHandleKeyboardEvents]
    );
    const handleOnBlur = useCallback(
      (args) => {
        shouldHandleKeyboardEvents.value = false;
        onBlur?.(args);
      },
      [onBlur, shouldHandleKeyboardEvents]
    );
    return <SearchInput ref={ref} {...props} onFocus={handleOnFocus} onBlur={handleOnBlur} />;
  }
);

type BottomSheetListProps<T> = Pick<BottomSheetModalCustomProps, "title" | "snapPoints"> &
  Pick<SearchInputProps, "placeholder"> &
  Pick<FlatListProps<T>, "keyExtractor" | "ListEmptyComponent" | "renderItem" | "ItemSeparatorComponent"> & {
    data: T[];
    filterFunction?: (value: T, keyword: string) => boolean;
  };
const BottomSheetList = forwardRef(
  <T,>(
    {
      title,
      snapPoints,
      placeholder,
      data,
      filterFunction = undefined,
      renderItem,
      ListEmptyComponent,
      keyExtractor,
      ItemSeparatorComponent,
    }: BottomSheetListProps<T>,
    ref: Ref<BottomSheetModalCustomMethods>
  ) => {
    const bottomSheetRef = useRef<BottomSheetModal>(null);
    useImperativeHandle(
      ref,
      () => ({
        present: () => {
          bottomSheetRef?.current?.present?.();
        },
        close: () => {
          bottomSheetRef?.current?.close?.();
        },
      }),
      []
    );

    const [keyword, setKeyword] = useState("");

    const displayData = useMemo(() => {
      if ((keyword?.length ?? 0) === 0) {
        return data ?? [];
      }
      return filter(data, (v) => filterFunction(v, keyword));
    }, [data, filterFunction, keyword]);

    return (
      <BottomSheetModalCustom
        keyboardBehavior={"extend"}
        keyboardBlurBehavior={"restore"}
        ref={bottomSheetRef}
        title={title}
        snapPoints={snapPoints}
        onDismiss={() => {
          setKeyword("");
          Keyboard.dismiss();
        }}
      >
        {typeof filterFunction === "function" && (
          <>
            <BottomSearchInput onChangeText={setKeyword} style={styles.searchInput} placeholder={placeholder} />
            <Line hasBackground={false} size="xl" />
          </>
        )}
        <BottomSheetFlatListCustom
          data={displayData}
          renderItem={renderItem}
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="always"
          ListEmptyComponent={ListEmptyComponent}
          ItemSeparatorComponent={ItemSeparatorComponent}
          keyExtractor={keyExtractor}
          ListFooterComponent={() => <Line hasBackground={false} size="xl" />}
        />
      </BottomSheetModalCustom>
    );
  }
) as <T>(props: BottomSheetListProps<T> & { ref?: ForwardedRef<BottomSheetModalCustomMethods> }) => JSX.Element;

export default BottomSheetList;

const styles = StyleSheet.create({
  searchInput: {
    marginHorizontal: 20,
  },
});
