import { FC, ReactNode } from "react";
import { StyleProp, StyleSheet, Text, TextProps, TextStyle } from "react-native";

import { Colors } from "theme";

type BaseTextProps = { word: ReactNode | string; color: (typeof Colors)[keyof typeof Colors] } & TextProps;
const BaseText: FC<BaseTextProps> = ({ word, style, color, ...rest }) => {
  return (
    <Text
      style={StyleSheet.flatten([
        {
          // phongtruongbizzi: color inside style must have higher priority than prop
          color: (style as TextStyle)?.color ?? color,
        },
        style,
      ])}
      {...rest}
    >
      {word}
    </Text>
  );
};

type AppTextProps = {
  style?: StyleProp<TextStyle>;
  color?: (typeof Colors)[keyof typeof Colors];
  individual?: boolean;
} & TextProps;

const AppText: FC<AppTextProps> = (props) => {
  const { children, style, color = Colors.grayscale100, individual, ...rest } = props;

  switch (typeof children) {
    case "string":
      if (individual) {
        return (
          <>
            {children.split(" ").map((word, i) => {
              return <BaseText word={`${word} `} color={color} style={style} {...rest} />;
            })}
          </>
        );
      }
      return <BaseText word={children} color={color} style={style} {...rest} />;
    default:
      return <BaseText word={children} color={color} style={style} {...rest} />;
  }
};
export default AppText;
AppText.defaultProps = {
  color: Colors.grayscale100,
};
