import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileQueryCrdCardQuery, MobileQueryCrdCardQueryVariables } from "types";

const QUERY = gql`
  query MobileQueryCrdCard($cardId: UUID!, $companyId: UUID!) {
    crdCard(input: { cardId: $cardId, companyId: $companyId }) {
      card {
        cardId
        activatedAt
        balance
        billingDay
        cardHolderEmployeeId
        cardNumber
        cardholderName
        cardholderPhoneNumber
        color
        companyId
        createdAt
        creditLimit
        expiredAt
        groupId
        lastSyncedAt
        maskedCardNumber
        paymentDueDay
        prefixCardNumber
        status
        suffixCardNumber
        updatedAt
        totalAvailablePoint
        assignee {
          employeeId
          fullname
        }
        cardholderEmployee {
          employeeId
          fullname
        }
        currentUsageSchedule {
          fromDate
          toDate
          cardTransactions {
            amount
          }
        }
      }
    }
  }
`;

const useEmployeeCardDetailQuery = (variables: MobileQueryCrdCardQueryVariables, options) => {
  return useQuery<MobileQueryCrdCardQuery, MobileQueryCrdCardQueryVariables>(QUERY, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export default useEmployeeCardDetailQuery;
