import React, { ChangeEvent, FC } from "react";
import { ScrollView, StyleSheet, ViewStyle } from "react-native";
import { Control, Controller } from "react-hook-form";
import { Colors, Fonts } from "theme";
import { Button } from "react-native-paper";

interface RadioSelectProps {
  title?: string;
  name?: string;
  control?: Control<any>;
  style?: ViewStyle;
  options?: { label?: string; value?: string | number | ChangeEvent<any> }[];
  onSelect?: (value: string | number) => void;
}
const RadioSelect: FC<RadioSelectProps> = ({ options, name, control, style, onSelect }) => {
  return (
    <Controller
      name={name as never}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <ScrollView style={style} horizontal showsHorizontalScrollIndicator={false}>
            {options.map(({ label, value: optionValue }) => {
              const isSelected = optionValue === value;
              return (
                <Button
                  key={optionValue as any}
                  uppercase={false}
                  color={Colors.grayscale100}
                  style={[styles.buttonFilterStyle, isSelected && styles.buttonFilterActiveStyle]}
                  labelStyle={[Fonts.BodyMedium, isSelected && { color: Colors.black }]}
                  onPress={() => {
                    onChange?.(optionValue as any);
                    onSelect?.(optionValue as any);
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </ScrollView>
        );
      }}
    />
  );
};
export default RadioSelect;

const styles = StyleSheet.create({
  buttonFilterStyle: {
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: Colors.while,
    borderColor: Colors.grayscale10,
    marginRight: 8,
  },
  buttonFilterActiveStyle: {
    borderColor: Colors.primary50,
    shadowColor: Colors.primary50,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
