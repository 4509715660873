/* eslint-disable react-native/no-inline-styles */

import { StyleSheet, View, ViewStyle } from "react-native";
import { AppText, useWindowDimensions } from "components";
import { Colors, Fonts } from "theme";
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { FC, useEffect, useState } from "react";
import { convertToNearest, formatMoneyString } from "utils";
import { CONSTANTS } from "constants/constants";
import Svg, { Text } from "react-native-svg";
import { TouchableOpacity } from "react-native-gesture-handler";

const NUMBER_OF_Y_AXIS_LINE = 5;
const PADDING_RIGHT = 24;
const MARGIN_LEFT = CONSTANTS.COMMON.CONTAINER_PADDING;
const Y_AXIS_WIDTH = 1;
const BAR_MIN_WIDTH = 2;

const ProgressLine = ({
  amount,
  limitedAmount,
  percent,
  title,
  limitedPercent,
  containerWidth,
  showSecondaryBar,
  chartConfig,
}) => {
  const { width } = useWindowDimensions();
  const fullWith = percent * containerWidth > BAR_MIN_WIDTH ? percent * containerWidth : BAR_MIN_WIDTH;
  const budgetWidth = limitedPercent * containerWidth > BAR_MIN_WIDTH ? limitedPercent * containerWidth : BAR_MIN_WIDTH;

  const position = useSharedValue(0);
  const progress = useSharedValue(0);
  const isLimitExceeded = percent > limitedPercent;
  useEffect(() => {
    progress.value = withTiming(1, {
      duration: 500,
      easing: Easing.linear,
    });
    if (percent === 0) {
      position.value = 0;
    } else {
      position.value = withTiming(percent, {
        duration: 500,
        easing: Easing.linear,
      });
    }
  }, [percent]);

  const style = useAnimatedStyle(() => {
    if (percent === 0) {
      return { width: 2 };
    }

    const width = interpolate(position.value, [0, 1], [0, 100], Extrapolate.CLAMP);
    return {
      width: `${width}%`,
    };
  });
  const spendingLineStyle = useAnimatedStyle(() => {
    const opacity = interpolate(progress.value, [0, 0.5, 0.9, 1], [0, 0, 0, 1], Extrapolate.CLAMP);
    return {
      opacity: opacity,
    };
  });
  const budgetLineStyle = useAnimatedStyle(() => {
    const width = interpolate(progress.value, [0, 1], [0, 100], Extrapolate.CLAMP);
    return {
      width: `${width}%`,
    };
  });
  const [positionX, setPositionX] = useState(0);
  const onLayoutSpendingLine = (event) => {
    setPositionX(event.nativeEvent.layout.x);
  };

  return (
    <View
      style={{
        marginTop: 16,
        width: width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING - PADDING_RIGHT,
      }}
    >
      <AppText style={[Fonts.BodyMedium, { marginBottom: 8 }]} numberOfLines={1}>
        {title}
      </AppText>
      <View style={{ justifyContent: "center" }}>
        <Animated.View style={[styles.lineChart, style, { backgroundColor: chartConfig.primaryColor }]}>
          {isLimitExceeded && showSecondaryBar && (
            <View
              style={{
                backgroundColor: chartConfig.alertColor,
                height: "100%",
                width: fullWith - positionX,
                alignSelf: "flex-end",
                borderTopRightRadius: 2,
                borderBottomRightRadius: 2,
              }}
            />
          )}
        </Animated.View>

        {showSecondaryBar && (
          <Animated.View
            onLayout={onLayoutSpendingLine}
            style={[spendingLineStyle, { left: `${limitedPercent * 100}%` }]}
          />
        )}

        <AppText style={[Fonts.NumericN100, { position: "absolute", transform: [{ translateX: fullWith + 4 }] }]}>
          {formatMoneyString(amount)}
        </AppText>
      </View>
      {showSecondaryBar && (
        <View style={{ justifyContent: "center", marginTop: 8 }}>
          <View style={{ width: budgetWidth }}>
            <Animated.View
              style={[styles.lineChart, budgetLineStyle, { backgroundColor: chartConfig.secondaryColor }]}
            />
          </View>

          <AppText style={[Fonts.NumericN100, { position: "absolute", transform: [{ translateX: budgetWidth + 4 }] }]}>
            {formatMoneyString(limitedAmount)}
          </AppText>
        </View>
      )}
    </View>
  );
};

export type BarChartDataItem = {
  title: string;
  amount: number;
  limitedAmount?: number;
  metadata?: any;
};

interface HorizontalBarChartProps {
  style?: ViewStyle;
  data: BarChartDataItem[];
  onItemPress?: (item: any) => void;
  legendAnimationStyle: ViewStyle;
  showSecondaryBar: boolean;
  chartConfig: {
    primaryColor: string;
    secondaryColor: string;
    alertColor: string;
  };
}

const HorizontalBarChart: FC<HorizontalBarChartProps> = ({
  legendAnimationStyle,
  data,
  onItemPress,
  showSecondaryBar,
  chartConfig,
}) => {
  const { width } = useWindowDimensions();
  const containerWidth = width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING;
  const yAxisGap = (containerWidth - PADDING_RIGHT) / (NUMBER_OF_Y_AXIS_LINE - 1);

  const maxAmountValue = Math.max(...[...data?.map((i) => i.amount), ...data?.map((i) => i?.limitedAmount || 0)]);
  const amountMaxNearest = convertToNearest(maxAmountValue);
  const amountBetween = Math.round(amountMaxNearest / 4);
  const amountRanges = [0, amountBetween, amountBetween * 2, amountBetween * 3, amountBetween * 4];

  const xLabels = (
    <Svg
      width={containerWidth + CONSTANTS.COMMON.CONTAINER_PADDING}
      height={16}
      style={{ marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING }}
    >
      {amountRanges?.map((item, index) => (
        <Text
          key={index}
          textAnchor={index === 0 ? "start" : "middle"}
          x={0}
          y={12}
          dx={index * yAxisGap}
          fill={Colors.grayscale80}
          {...Fonts.BodySmall}
        >
          {item ? formatMoneyString(item) : 0}
        </Text>
      ))}
    </Svg>
  );

  const yAxises = Array(5)
    .fill(0)
    .map((x, index) => {
      const left = index * yAxisGap;
      return (
        <View
          key={index}
          style={{
            width: Y_AXIS_WIDTH,
            position: "absolute",
            left,
            height: "100%",
            backgroundColor: Colors.grayscale05,
          }}
        />
      );
    });

  const bars = data?.map((item, index) => {
    const percent = amountMaxNearest ? item?.amount / amountMaxNearest : 0;
    const limitedPercent = amountMaxNearest ? item?.limitedAmount / amountMaxNearest : 0;

    const progressLine = (
      <ProgressLine
        key={index}
        title={item?.title}
        amount={item.amount}
        percent={percent}
        limitedPercent={limitedPercent}
        limitedAmount={item.limitedAmount}
        containerWidth={width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING - PADDING_RIGHT}
        showSecondaryBar={showSecondaryBar}
        chartConfig={chartConfig}
      />
    );
    return onItemPress ? (
      <TouchableOpacity onPress={() => onItemPress(item.metadata)}>{progressLine}</TouchableOpacity>
    ) : (
      progressLine
    );
  });

  return (
    <View style={{ marginLeft: MARGIN_LEFT, overflow: "hidden" }}>
      {data?.length > 0 && (
        <Animated.View style={[styles.legendSection, legendAnimationStyle]}>{xLabels}</Animated.View>
      )}
      <View>
        {yAxises}
        {bars}
      </View>
    </View>
  );
};
export default HorizontalBarChart;
const styles = StyleSheet.create({
  lineChart: {
    // backgroundColor: Colors.primary50,
    height: 12,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
  },
  legendSection: {
    backgroundColor: Colors.white,
    zIndex: 1,
  },
});
