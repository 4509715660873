import { useNavigation } from "@react-navigation/native";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Toast } from "@ant-design/react-native";
import { BizziBotWarningCircle, DeleteIcon } from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  AllocatedStatus,
  AppText,
  BottomSheetModalCustomMethods,
  Button,
  CurrencyText,
  IconCustom,
} from "components";
import StatusExpense from "components/ExpenseItem/StatusExpense";
import LanguageStatus from "constants/LanguageStatus";
import { CONSTANTS } from "constants/constants";
import dayjs from "dayjs";
import useUnlinkExpensesFromRequest from "hooks/request/useUnlinkExpensesFromRequest";
import i18n from "i18next";
import SCREEN_NAME from "navigation/ScreenName";
import { ExpenseDetailScreenNavigationProp } from "navigation/type";
import ChooseExpenseListModal from "screens/ExpenseRequest/ExpenseRequestDetailScreen/components/ChooseExpenseListModal/ChooseExpenseListModal";
import { ExpenseActualListByGroup } from "screens/ExpenseRequest/ExpenseRequestDetailScreen/types";
import { Colors, Fonts } from "theme";
import ReportStatus from "constants/ReportStatus";
import { useAuth } from "contexts/AuthContext";
import { MobileExpExpenseRequestDetailQuery } from "types";

interface ExpenseActualList {
  expenseActualList: ExpenseActualListByGroup[];
  expenseRequest?: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
  onRefreshDetail?: () => void;
  isLocked?: boolean;
  setPosition?: (y: number) => void;
}
const ExpenseActualList: FC<ExpenseActualList> = ({
  onRefreshDetail,
  expenseRequest,
  expenseActualList,
  isLocked = false,
  setPosition,
}) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const { user } = useAuth();

  const navigation = useNavigation<ExpenseDetailScreenNavigationProp>();
  const chooseExpenseListModalRef = useRef<BottomSheetModalCustomMethods>();
  const alertNotificationRef = useRef<AlertNotificationHandle>();
  const { unlinkExpensesFromRequest } = useUnlinkExpensesFromRequest();

  const expenseListIsEmpty = expenseActualList?.length === 0;
  const handleOpenExpenseListModal = () => {
    if (isLocked) {
      alertNotificationRef?.current?.info({
        icon: <BizziBotWarningCircle />,
        title: t("unable_to_add_expense_title"),
        description: t("unable_to_add_expense_description"),
        confirmText: t("understood"),
        onConfirm: () => {
          alertNotificationRef?.current?.close();
        },
      });
      return;
    }
    chooseExpenseListModalRef?.current?.present();
  };
  const handleRemoveExpense = (item) => async () => {
    try {
      Toast.loading("");
      await unlinkExpensesFromRequest({
        variables: { expenseRequestId: expenseRequest?.expenseRequestId, expenseIds: [item.expenseId] },
      });
      await onRefreshDetail?.();
      Toast.removeAll();
    } catch (e) {
      Toast.removeAll();
    }
  };

  const companyTeamIds = expenseRequest.expenseRequestsTeams?.map(({ companyTeamId }) => companyTeamId);

  const isOwnerRequest = expenseRequest?.companyEmployee?.employeeId === user?.employee_id;
  const handleNavigateToExpense = (item) => () => {
    navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, { expenseId: item?.expenseId });
  };
  if (!isOwnerRequest && expenseListIsEmpty) {
    return null;
  }
  return (
    <>
      <View onLayout={(event) => setPosition?.(event.nativeEvent.layout.y)}>
        <View style={styles.titleContainer}>
          <AppText style={Fonts.SentenceSubtitleXLarge}>{t("expense_list_actual")}</AppText>
        </View>
        <View>
          {expenseActualList?.map((expenseByGroup) => (
            <View style={{ marginTop: 4 }} key={expenseByGroup?.categoryId}>
              <View style={styles.expenseGroupTitleContainer}>
                <View style={styles.row}>
                  <AppText style={[Fonts.NumericN300, styles.expenseByGroupTitle]} numberOfLines={1}>
                    {i18n.language === LanguageStatus.VN ? expenseByGroup?.title : expenseByGroup?.titleEn}
                  </AppText>
                  <CurrencyText style={Fonts.SentenceSubtitleLarge}>{expenseByGroup?.totalAmount}</CurrencyText>
                </View>
              </View>
              {expenseByGroup?.expenses?.map((item, index) => {
                const isLastItem = index === expenseByGroup?.expenses?.length - 1;
                const canUnlinkExpensesFromRequest =
                  !item?.expenseReport ||
                  [ReportStatus.CANCEL, ReportStatus.REJECTED].includes(item.expenseReport.status);
                return (
                  <View style={styles.expenseListContainer} key={item?.expenseId}>
                    <TouchableOpacity style={styles.expenseItem} onPress={handleNavigateToExpense(item)}>
                      <View style={styles.expenseIcon}>
                        <IconCustom name="sell" />
                      </View>
                      <View style={styles.flex}>
                        {Boolean(item?.expenseCardTransactions?.[0]) && (
                          <AppText style={[Fonts.BodySmall, { marginBottom: 4 }]} color={Colors.grayscale60}>
                            {t("expense_from_transaction")}
                          </AppText>
                        )}
                        <View style={styles.rowCenter}>
                          <AppText style={[Fonts.BodyMedium, styles.expenseCategoryTitle]} numberOfLines={1}>
                            {item?.note || t("need_explanation")}
                          </AppText>
                          <CurrencyText
                            style={Fonts.NumericN200}
                            foreignAmount={item.foreignTotalAmountWithVat}
                            foreignAmountStyle={Fonts.SentenceBodyMedium}
                          >
                            {item?.totalAmountWithVat}
                          </CurrencyText>
                        </View>
                        <View style={styles.docSequence}>
                          <AppText style={Fonts.BodySmall} numberOfLines={1}>
                            {item?.docSequence}
                          </AppText>
                          <AppText style={Fonts.BodySmall} color={Colors.grayscale60} numberOfLines={1}>
                            {dayjs(item?.expenseDate).format("DD/MM/YYYY")}
                          </AppText>
                        </View>
                        <View style={styles.statusRow}>
                          <View style={styles.statusContainer}>
                            <StatusExpense value={item?.status} />
                            <AllocatedStatus value={item?.allocatedStatus} />
                          </View>
                          {canUnlinkExpensesFromRequest && (
                            <TouchableOpacity onPress={handleRemoveExpense(item)}>
                              <DeleteIcon />
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    </TouchableOpacity>
                    {!isLastItem && <View style={styles.divider} />}
                  </View>
                );
              })}
            </View>
          ))}
        </View>
        {isOwnerRequest && (
          <View style={styles.buttonAddExpenseContainer}>
            {Boolean(expenseRequest?.isClosed) && (
              <AppText style={[Fonts.Caption, { marginBottom: 9 }]} color={Colors.grayscale70}>
                {t("add_expense_to_request_disabled")}
              </AppText>
            )}
            <Button
              type="secondary"
              icon={() => (
                <IconCustom
                  name="add-circle-outline"
                  width={32}
                  height={32}
                  fill={expenseRequest?.isClosed ? Colors.grayscale60 : Colors.primary50}
                />
              )}
              onPress={handleOpenExpenseListModal}
              disabled={expenseRequest?.isClosed}
            >
              {t("add_expense")}
            </Button>
          </View>
        )}
      </View>
      <ChooseExpenseListModal
        expenseList={expenseRequest?.expenses}
        onRefreshDetail={onRefreshDetail}
        requestId={expenseRequest?.expenseRequestId}
        isAdvanceRequest={Boolean(expenseRequest?.cashAdvances?.[0])}
        ref={chooseExpenseListModalRef}
        companyTeamIds={companyTeamIds}
      />
      <AlertNotification autoHeight ref={alertNotificationRef} />
    </>
  );
};

export default ExpenseActualList;

const styles = StyleSheet.create({
  titleContainer: {
    paddingVertical: 20,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  expenseItem: { flexDirection: "row", alignItems: "center", paddingVertical: 15, gap: 16 },
  flex: { flex: 1 },
  buttonAddExpenseContainer: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 20,
  },
  expenseCategoryTitle: { flex: 1, marginRight: 10 },
  row: { flexDirection: "row", justifyContent: "space-between" },
  expenseListContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    paddingVertical: 5,
    // marginBottom: 4,
  },
  expenseGroupTitleContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    paddingTop: 20,
    paddingBottom: 11,
    marginBottom: 1,
  },
  expenseByGroupTitle: { flex: 1, marginRight: 10 },
  divider: { height: 1, backgroundColor: Colors.grayscale05, marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING },
  statusRow: { flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 4 },
  rowCenter: { flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 4 },
  docSequence: { flexDirection: "row", justifyContent: "space-between", marginTop: 3 },
  statusContainer: { flexDirection: "row", gap: 4 },
  expenseIcon: {
    width: 48,
    height: 48,
    borderRadius: 48 / 2,
    backgroundColor: Colors.primary0,
    justifyContent: "center",
    alignItems: "center",
  },
});
