import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 77, height = 76, color = "#0F172A" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 77 76" fill="none">
      <Path
        d="M54.334 67.29H22.666a2.392 2.392 0 01-2.375-2.375 2.392 2.392 0 012.375-2.375h31.666c9.057 0 13.459-4.401 13.459-13.458V26.915c0-9.056-4.402-13.458-13.459-13.458H22.668c-9.057 0-13.459 4.402-13.459 13.458a2.392 2.392 0 01-2.375 2.375 2.392 2.392 0 01-2.375-2.375c0-11.558 6.65-18.208 18.209-18.208h31.666c11.559 0 18.209 6.65 18.209 18.208v22.167c0 11.558-6.65 18.208-18.209 18.208z"
        fill={color}
      />
      <Path
        d="M38.5 40.75c-2.66 0-5.352-.823-7.41-2.502l-9.913-7.916c-1.013-.824-1.203-2.312-.38-3.325.824-1.014 2.312-1.204 3.325-.38l9.912 7.916c2.407 1.932 6.492 1.932 8.898 0l9.912-7.916c1.014-.824 2.533-.665 3.325.38.823 1.013.665 2.533-.38 3.325l-9.912 7.916c-2.026 1.679-4.718 2.502-7.378 2.502zM25.834 54.625h-19a2.392 2.392 0 01-2.375-2.375 2.392 2.392 0 012.375-2.375h19a2.392 2.392 0 012.375 2.375 2.392 2.392 0 01-2.375 2.375zM16.334 41.957h-9.5a2.392 2.392 0 01-2.375-2.375 2.392 2.392 0 012.375-2.375h9.5a2.392 2.392 0 012.375 2.375 2.392 2.392 0 01-2.375 2.375z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
