import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RefreshControl, StyleSheet, View } from "react-native";

import { EmptyData, Line, SkeletonListLoading } from "components";
import { SPACING_X_SMALL } from "constants/Layout";
import { useAuth } from "contexts/AuthContext";

import { FlashList } from "@shopify/flash-list";
import { EmptyInvoice } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import StatusList from "screens/Expense/ExpenseListScreen/components/StatusList";
import { Colors } from "theme";
import { clampReanimated } from "utils";
import SCREEN_NAME from "navigation/ScreenName";
import { useReceivedInvoicesQuery } from "hooks/useReceivedInvoicesQuery";
import InvoiceItem from "screens/Inbox/InboxScreen/components/InvoiceItem";
import { useCopilot } from "react-native-copilot";
import DummyInvoiceWalkthroughData from "./DummyInvoiceWalkthroughData.json";

const FILTER_CONTAINER_HEIGHT = 52;

const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);

enum INVOICE_EXPENSE_STATUS {
  ALL = "ALL",
  CREATED = "CREATED",
  NOT_CREATED = "NOT_CREATED",
}

const LIST_STATUS = [
  {
    title: "expense_created",
    value: INVOICE_EXPENSE_STATUS.CREATED,
  },
  {
    title: "no_expense_created",
    value: INVOICE_EXPENSE_STATUS.NOT_CREATED,
  },
];
const PAGE_SIZE = 10;
const InvoiceTab = ({ navigation }) => {
  const {
    user,
    company: { company_id: companyId },
  } = useAuth();
  const { t } = useTranslation("app/screens/Inbox/InboxScreen");
  const [createdExpenseStatus, setCreatedExpenseStatus] = useState(INVOICE_EXPENSE_STATUS.ALL); //ALL CREATED NOT_CREATED
  const [isLoadingMore, setLoadingMore] = useState(false);
  const { visible: visibleWalkthrough } = useCopilot();
  const [onFetchData, { data, refetch, loading, fetchMore }] = useReceivedInvoicesQuery({
    variables: {
      companyId,
      userId: user.id,
      limit: PAGE_SIZE,
      offset: 0,
      invoiceFilters: { createdExpenseStatus } as ExpInvoicesFilters,
    },
  });
  const invoiceList = (visibleWalkthrough ? DummyInvoiceWalkthroughData : data?.expReceivedInvoices?.invoices) ?? [];
  const total = data?.expReceivedInvoices?.total ?? 0;

  useEffect(() => {
    onFetchData();
  }, []);
  useEffect(() => {
    if (!loading) {
      setLoadingMore(false);
    }
  }, [loading]);

  const handleLoadMore = () => {
    if (loading || isLoadingMore || !invoiceList?.length || invoiceList.length >= total) {
      return;
    }
    setLoadingMore(true);
    fetchMore?.({
      variables: { offset: invoiceList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expReceivedInvoices: {
            total: fetchMoreResult.expReceivedInvoices.total,
            invoices: [...prev.expReceivedInvoices.invoices, ...fetchMoreResult.expReceivedInvoices.invoices],
          },
        };
      },
    });
  };
  const renderEmptyComponent = () => {
    if (!loading && invoiceList?.length === 0) {
      return (
        <EmptyData
          icon={<EmptyInvoice />}
          title={t("empty_einvoice_title")}
          description={t("empty_einvoice_description")}
        />
      );
    }
    return null;
  };

  const onRefresh = () => {
    refetch();
  };

  const handleClickItem = (item) => () => {
    navigation.navigate(SCREEN_NAME.InvoiceDetailScreen, {
      invoiceId: item?.invoiceId,
      autofill: true,
      onRefreshData: refetch,
    });
  };
  const renderItem = ({ item }) => {
    return <InvoiceItem onRefreshData={refetch} item={item} onPress={handleClickItem(item)} />;
  };

  const ListFooterComponent = () => {
    if (!loading || !invoiceList?.length) {
      return null;
    }
    return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
  };

  const ItemSeparatorComponent = () => (
    <Line hasBackground={false} containerStyle={{ paddingVertical: SPACING_X_SMALL }} />
  );

  const handleChangeStatus = (status: INVOICE_EXPENSE_STATUS) => {
    if (createdExpenseStatus !== status) {
      setCreatedExpenseStatus(status);
    } else {
      setCreatedExpenseStatus(INVOICE_EXPENSE_STATUS.ALL);
    }
  };
  const translationY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event, ctx) => {
      let { y } = event.contentOffset;
      if (y < 0) {
        y = 0;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const dy = y - (ctx?.prevY ?? 0);
      translationY.value = clampReanimated(translationY.value + dy, 0, FILTER_CONTAINER_HEIGHT);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ctx.prevY = y;
    },
  });
  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      translationY.value,
      [0, FILTER_CONTAINER_HEIGHT],
      [0, -FILTER_CONTAINER_HEIGHT],
      Extrapolation.CLAMP
    );
    return {
      transform: [{ translateY: translateY }],
    };
  });
  return (
    <View style={styles.container}>
      <Animated.View style={[styles.floatHeader, animatedStyle]}>
        <StatusList data={LIST_STATUS} currentStatus={createdExpenseStatus} handleChangeStatus={handleChangeStatus} />
      </Animated.View>
      {loading && !isLoadingMore ? (
        <SkeletonListLoading style={styles.loading} />
      ) : (
        <AnimatedFlashList
          estimatedItemSize={130}
          contentContainerStyle={styles.contentContainerStyle}
          data={invoiceList}
          ItemSeparatorComponent={ItemSeparatorComponent}
          renderItem={renderItem}
          keyExtractor={(item: ExpInvoice) => item?.invoiceId}
          refreshControl={
            <RefreshControl
              progressViewOffset={CONSTANTS.EXPENSE_LIST.HEADER_STATUS_HEIGHT + 10}
              tintColor={Colors.primary50}
              refreshing={false}
              onRefresh={onRefresh}
            />
          }
          onEndReached={handleLoadMore}
          onScroll={scrollHandler}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.2}
          ListEmptyComponent={renderEmptyComponent}
          ListFooterComponent={ListFooterComponent}
        />
      )}
    </View>
  );
};

export default InvoiceTab;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingTop: FILTER_CONTAINER_HEIGHT,
    paddingBottom: 50,
  },
  loadingMore: { marginTop: 10 },
  loading: { marginTop: FILTER_CONTAINER_HEIGHT + 10 },
  floatHeader: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1,
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
});
