import {
  AppText,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  IconCustom,
  RadioSelect,
  SelectCustom,
  SwitchButton,
  TextInputCustom,
  TouchableOpacityCustom,
  useSetRelativePosition,
} from "components";
import { Colors, Fonts } from "theme";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import React, { FC, useEffect, useRef, useState, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import BankListView from "screens/Expense/components/BankListView";
import useBanksQuery from "screens/Expense/hooks/useBanksQuery";
import { ACCOUNT_TYPES, CONSTANTS } from "constants/constants";
import { BankAccount, ExpenseFormValues, PaymentInfoFormValues } from "screens/Expense/components/ExpenseForm/types";
import PaymentInfoForm from "../PaymentInfoForm/PaymentInfoForm";
import BankAccountChooser, {
  PaymentChooserFormRef,
} from "screens/Expense/components/BankAccountChooser/BankAccountChooser";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { convertViToEn, startLayoutAnimation } from "utils";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { useMasterData } from "contexts/MasterDataContext";
import { useNavigation } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import { MobileExpBankQrParserQuery } from "types";
import { EVENT } from "constants/Tracking";

interface PaymentInfoSectionProps {
  name?: string;
  control?: Control<ExpenseFormValues>;
  rules?: RegisterOptions<ExpenseFormValues, "paymentInfo">;
  errors?: FieldErrors<ExpenseFormValues>;
  setValue?: UseFormSetValue<ExpenseFormValues>;
  getValues?: UseFormGetValues<ExpenseFormValues>;
  currentType?: ACCOUNT_TYPES;
  watch?: UseFormWatch<ExpenseFormValues>;
  setPosition?: (y: number) => void;

  containerRef?: RefObject<any>;
}
const getPaymentOptions = (t) => {
  return [
    { label: t("personal_payment"), value: ACCOUNT_TYPES.PERSONAL },
    { label: t("enterprise_payment"), value: ACCOUNT_TYPES.ENTERPRISE },
  ];
};
const PaymentInfoSection: FC<PaymentInfoSectionProps> = ({
  setValue,
  control,
  getValues,
  currentType,
  errors,
  watch,
  setPosition,
  containerRef,
}) => {
  const childRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef, setPosition });

  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const { setting } = useMasterData();
  const navigation = useNavigation<any>();
  const isRequiredPaymentInfo = setting?.requiredPaymentInfo;

  const selectBankModalRef = useRef<BottomSheetModalCustomMethods>(null);
  const bankAccountChooserRef = useRef<PaymentChooserFormRef>(null);
  const [paymentInfoPersonal, setPaymentInfoPersonal] = useState<ExpenseFormValues["paymentInfo"]>(null);
  const [paymentInfoBusiness, setPaymentInfoBusiness] = useState<ExpenseFormValues["paymentInfo"]>(null);
  const savePaymentModalRef = useRef<any>(null);
  const [, { data, refetch, loading }] = useBanksQuery();
  const banks = data?.expBanks?.banks || [];
  const isShowSaveButton = watch([
    "paymentInfo.accountHolderName",
    "paymentInfo.accountNumber",
    "paymentInfo.bank",
  ])?.every((item) => Boolean(item));
  const [textSearch, setTextSearch] = useState("");
  const [filteredBanks, setFilteredBanks] = useState([]);
  const previousType = useRef<ACCOUNT_TYPES>(null);

  useEffect(() => {
    if (textSearch) {
      const textSearchInLowerCase = convertViToEn(textSearch).toLocaleLowerCase();
      const filtered = banks.filter((bank) =>
        [bank.bankShortName, bank.bankCode, bank.bankName].some((t) =>
          convertViToEn(String(t)).toLocaleLowerCase().includes(textSearchInLowerCase)
        )
      );
      setFilteredBanks(filtered);
    }
  }, [textSearch]);

  const handleOpenBankModal = () => {
    selectBankModalRef?.current?.present();
    if (!data) {
      refetch();
    }
  };
  const handleOpenSavePaymentModal = () => {
    const paymentInfo = getValues("paymentInfo") as ExpenseFormValues["paymentInfo"];
    const values = {
      bankOption: {
        key: paymentInfo?.bank?.key,
        label: paymentInfo?.bank?.label,
        metadata: paymentInfo?.bank?.metadata,
      },
      accountNumber: paymentInfo?.accountNumber,
      accountHolderName: paymentInfo?.accountHolderName,
      branchName: paymentInfo?.branchName,
    } as PaymentInfoFormValues;
    savePaymentModalRef?.current?.present({ accountType: currentType, values });
  };
  const onSelectPaymentAccount = (values: BankAccount) => {
    const paymentInfo = {
      type: values?.type,
      bank: { key: values?.bankId, label: `${values?.bankShortName} (${values?.bankCode})`, metadata: values },
      accountHolderName: values?.accountHolderName,
      accountNumber: values?.accountNumber,
      branchName: values?.branchName,
    } as ExpenseFormValues["paymentInfo"];
    bankAccountChooserRef?.current?.close();
    setValue("paymentInfo.type", paymentInfo.type);
    setValue("paymentInfo.bank", paymentInfo.bank, { shouldValidate: true });
    setValue("paymentInfo.accountHolderName", paymentInfo.accountHolderName, { shouldValidate: true });
    setValue("paymentInfo.accountNumber", paymentInfo.accountNumber, { shouldValidate: true });
    setValue("paymentInfo.branchName", paymentInfo.branchName);
  };
  const handleOpenPaymentForm = () => {
    bankAccountChooserRef?.current?.close();
    savePaymentModalRef?.current?.present({ accountType: currentType });
  };
  const setPaymentInfoValue = ({
    bank = null,
    accountHolderName = "",
    accountNumber = "",
    branchName = "",
    description = "",
  }) => {
    setValue("paymentInfo.bank", bank);
    setValue("paymentInfo.accountHolderName", accountHolderName);
    setValue("paymentInfo.accountNumber", accountNumber);
    setValue("paymentInfo.branchName", branchName);
    setValue("paymentInfo.description", description);
  };
  const handleSelectType = (type) => {
    previousType.current = currentType;
    setValue("paymentInfo.type", type);
    const paymentInfo = { ...getValues("paymentInfo") };
    /*-- save current payment info for each type --*/
    if (currentType === ACCOUNT_TYPES.PERSONAL) {
      setPaymentInfoPersonal(paymentInfo);
    } else if (currentType === ACCOUNT_TYPES.ENTERPRISE) {
      setPaymentInfoBusiness(paymentInfo);
    }
    /*-- end --*/
    if (type === ACCOUNT_TYPES.PERSONAL) {
      setPaymentInfoValue({
        bank: paymentInfoPersonal?.bank,
        accountHolderName: paymentInfoPersonal?.accountHolderName,
        accountNumber: paymentInfoPersonal?.accountNumber,
        branchName: paymentInfoPersonal?.branchName,
        description: paymentInfoPersonal?.description,
      });
    } else if (type === ACCOUNT_TYPES.ENTERPRISE) {
      setPaymentInfoValue({
        bank: paymentInfoBusiness?.bank,
        accountHolderName: paymentInfoBusiness?.accountHolderName,
        accountNumber: paymentInfoBusiness?.accountNumber,
        branchName: paymentInfoBusiness?.branchName,
        description: paymentInfoBusiness?.description,
      });
    }
  };

  const handleToggleSection = (isOn: boolean) => {
    startLayoutAnimation();
    if (isOn) {
      handleSelectType(previousType.current ?? ACCOUNT_TYPES.PERSONAL);
    } else {
      previousType.current = currentType;
      handleSelectType(ACCOUNT_TYPES.NONE);
    }
  };
  const onFinishScanQrCode = (data: MobileExpBankQrParserQuery["expBankQRParser"]["paymentInfo"]) => {
    setValue(
      "paymentInfo.bank",
      {
        key: data?.bankId,
        label: `${data?.bankShortName} (${data?.bankCode})`,
        metadata: data as BankAccount,
      },
      { shouldValidate: true }
    );
    setValue("paymentInfo.accountNumber", data.accountNumber, { shouldValidate: true });
    setValue("paymentInfo.accountHolderName", data.accountHolderName, { shouldValidate: true });
    setValue("paymentInfo.bankName", data.bankName);
    setValue("paymentInfo.bankShortName", data.bankShortName);
    setValue("paymentInfo.branchName", data.branchName);
    setValue("paymentInfo.description", data.description);
  };
  const navigateToScanQrCode = () => {
    navigation.navigate(SCREEN_NAME.ScanQrCodeScreen, { onFinish: onFinishScanQrCode });
  };

  return (
    <View style={styles.container} ref={childRef}>
      <View style={styles.header}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("payment_info")}</AppText>
        {!isRequiredPaymentInfo && (
          <SwitchButton
            value={[ACCOUNT_TYPES.ENTERPRISE, ACCOUNT_TYPES.PERSONAL].includes(currentType)}
            onValueChange={handleToggleSection}
          />
        )}
      </View>
      {[ACCOUNT_TYPES.ENTERPRISE, ACCOUNT_TYPES.PERSONAL].includes(currentType) && (
        <View style={styles.paymentInfoHeader}>
          <RadioSelect
            options={getPaymentOptions(t)}
            name="paymentInfo.type"
            control={control}
            onSelect={(value) => handleSelectType(value)}
          />
          {Platform.OS !== "web" && (
            <TouchableOpacityCustom eventName={EVENT.EXPENSE.SCAN_QR_CODE} onPress={navigateToScanQrCode}>
              <IconCustom name="qr-code-scanner" fill={Colors.grayscale60} />
            </TouchableOpacityCustom>
          )}
        </View>
      )}

      {currentType !== ACCOUNT_TYPES.NONE && (
        <>
          <View style={styles.paymentInfoContainer}>
            <SelectCustom
              name="paymentInfo.bank"
              control={control}
              onPress={handleOpenBankModal}
              label={t("bank")}
              rules={{ required: t("required") }}
              error={errors?.paymentInfo?.bank}
            />
            <TextInputCustom
              name="paymentInfo.accountNumber"
              control={control}
              keyboardType="numeric"
              label={t("account_number")}
              placeholder={t("account_number")}
              rules={{ required: t("required") }}
              absoluteRight={
                <TouchableOpacity
                  activeOpacity={1}
                  style={styles.rightButton}
                  onPress={() => bankAccountChooserRef?.current?.present(currentType, getValues("invoice"))}
                >
                  <IconCustom name="demography" />
                </TouchableOpacity>
              }
              error={errors?.paymentInfo?.accountNumber}
            />
            <TextInputCustom
              name="paymentInfo.accountHolderName"
              control={control}
              label={t("account_holder_name")}
              placeholder={t("account_holder_name")}
              rules={{ required: t("required") }}
              error={errors?.paymentInfo?.accountHolderName}
              autoCapitalize={"characters"}
            />
            <TextInputCustom
              name="paymentInfo.branchName"
              control={control}
              label={t("branch_name")}
              placeholder={t("branch_name")}
            />
            <TextInputCustom
              name="paymentInfo.description"
              control={control}
              label={t("description_payment")}
              placeholder={t("description_payment")}
              multiline
              autoHeight
            />
            {isShowSaveButton && (
              <TouchableOpacity onPress={handleOpenSavePaymentModal} style={styles.buttonSavePayment}>
                <AppText style={Fonts.BodyLarge} color={Colors.primary50}>
                  {t("save_payment")}
                </AppText>
              </TouchableOpacity>
            )}
          </View>
          <BottomSheetModalCustom
            title={t("bank")}
            ref={selectBankModalRef}
            snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
          >
            <BankListView
              data={textSearch ? filteredBanks : banks}
              textSearch={textSearch}
              onChangeSearch={setTextSearch}
              handleClearSearch={() => setTextSearch("")}
              loading={loading}
              handleSelectBank={(bank: BankAccount) => {
                selectBankModalRef?.current?.close();
                setValue(
                  "paymentInfo.bank",
                  { key: bank.bankId, label: `${bank.bankShortName} (${bank.bankCode})`, metadata: bank },
                  { shouldValidate: true }
                );
                setValue("paymentInfo.accountNumber", "");
                setValue("paymentInfo.accountHolderName", "");
                setValue("paymentInfo.branchName", "");
              }}
            />
          </BottomSheetModalCustom>
          <PaymentInfoForm ref={savePaymentModalRef} />
          <BankAccountChooser
            handleOpenPaymentForm={handleOpenPaymentForm}
            onSelect={onSelectPaymentAccount}
            ref={bankAccountChooserRef}
          />
        </>
      )}
    </View>
  );
};
export default PaymentInfoSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.while,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
    gap: 16,
  },
  header: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
  },
  paymentInfoContainer: { gap: 16 },
  rightButton: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  buttonSavePayment: { marginTop: 7 },
  paymentInfoHeader: { flexDirection: "row", alignItems: "center" },
});
