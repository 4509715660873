import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M5.058 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V3.75c0-.213.072-.39.216-.534A.726.726 0 014 3c.213 0 .391.072.535.216a.726.726 0 01.215.534v14.942c0 .077.032.148.096.212a.294.294 0 00.212.096H20c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.391-.216.535A.726.726 0 0120 20.5H5.058zm2.5-3.25A.837.837 0 016.94 17a.837.837 0 01-.249-.616V9.962c0-.246.083-.451.25-.617a.837.837 0 01.616-.249h1.23c.257 0 .471.087.644.26.174.174.26.39.26.647v6.344a.872.872 0 01-.26.644.877.877 0 01-.644.259h-1.23zm4.75 0A.838.838 0 0111.69 17a.838.838 0 01-.249-.616V5c0-.256.087-.47.26-.644a.874.874 0 01.644-.26h1.23c.246 0 .452.083.617.249a.838.838 0 01.25.617v11.384c0 .256-.087.47-.26.644a.875.875 0 01-.645.26h-1.23zm4.75 0A.838.838 0 0116.44 17a.837.837 0 01-.249-.616V14c0-.256.087-.47.26-.644a.875.875 0 01.644-.26h1.23c.246 0 .452.083.617.249a.837.837 0 01.25.616v2.385c0 .256-.087.47-.26.644a.875.875 0 01-.645.26h-1.23z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
