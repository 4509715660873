import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1F71F4" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6 21.5c-.699 0-1.29-.242-1.774-.726A2.413 2.413 0 013.5 19v-1.077c0-.497.177-.923.531-1.277a1.74 1.74 0 011.277-.53H6.5V4.307c0-.497.177-.923.531-1.277A1.74 1.74 0 018.308 2.5h10.384c.497 0 .923.177 1.277.531.354.354.531.78.531 1.277V19c0 .699-.242 1.29-.726 1.774A2.413 2.413 0 0118 21.5H6zM18 20c.283 0 .52-.096.712-.288A.968.968 0 0019 19V4.308a.3.3 0 00-.087-.221.3.3 0 00-.22-.087H8.307a.3.3 0 00-.221.087.3.3 0 00-.087.22v11.808h7.192c.497 0 .923.177 1.277.531.354.354.531.78.531 1.277V19c0 .283.096.52.288.712.191.192.429.288.712.288zM9.942 8.692a.725.725 0 01-.534-.215.726.726 0 01-.216-.535c0-.213.072-.39.216-.534a.726.726 0 01.534-.216h7.116c.212 0 .39.072.534.216a.726.726 0 01.216.535c0 .212-.072.39-.216.534a.726.726 0 01-.534.215H9.942zm0 2.885a.725.725 0 01-.534-.216.726.726 0 01-.216-.534c0-.213.072-.391.216-.535a.726.726 0 01.534-.215h7.116c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.39-.216.534a.726.726 0 01-.534.216H9.942z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
