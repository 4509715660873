import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#FBBC05", size = 24, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 18H2a2 2 0 01-2-2V2a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2zM1 12.5l1.418-3.775L1.083 5.5h1.81l.577 1.989.603-1.989h1.749L4.475 8.777 5.902 12.5H4.138l-.715-2.26-.676 2.26H1zm5.412 0v-7h2.653l.475 3.469.494-3.469h2.653v7H11.06V7.06l-.96 5.44H9.002L8.06 7.06l-.018 5.44h-1.63zm7.075-7v7H17v-1.35h-1.83V5.5h-1.683z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
