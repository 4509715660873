import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, color = "#fff" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.862 20.256a24.259 24.259 0 004.277.005 2.582 2.582 0 01-2.14 1.14 2.591 2.591 0 01-2.137-1.145zm-1.753-.221a23.708 23.708 0 01-3.69-.913c-.91-.3-1.6-.95-1.9-1.77-.3-.82-.2-1.76.29-2.58l1.27-2.12c.28-.47.53-1.35.53-1.9v-2.1c0-4.09 3.32-7.41 7.41-7.41s7.41 3.32 7.41 7.41v2.1c0 .54.25 1.43.53 1.9l1.27 2.12c.47.78.55 1.71.24 2.56-.31.85-.99 1.5-1.85 1.79a23.046 23.046 0 01-3.726.922A4.087 4.087 0 0112 22.902c-1.07 0-2.12-.44-2.88-1.2a4.125 4.125 0 01-1.01-1.667zm-2-11.373c0-3.26 2.65-5.91 5.91-5.91 3.26 0 5.91 2.65 5.92 5.92v2.1c0 .81.32 1.98.74 2.67l1.27 2.12c.23.39.28.85.12 1.27-.15.42-.48.74-.91.88-4.6 1.53-9.66 1.53-14.26 0-.48-.16-.82-.46-.97-.86-.15-.4-.09-.86.17-1.29l1.27-2.13c.42-.69.74-1.86.74-2.67v-2.1z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
