import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={49} height={48} viewBox="0 0 49 48" fill="none">
      <Rect x={0.40625} width={48.095} height={47.3462} rx={14.2039} fill="#1F71F4" />
      <Path
        d="M30.372 33.733H18.535c-3.55 0-5.918-1.775-5.918-5.918V19.53c0-4.143 2.367-5.919 5.918-5.919h11.837c3.551 0 5.918 1.776 5.918 5.919v8.285c0 4.143-2.367 5.918-5.918 5.918z"
        stroke="#fff"
        strokeWidth={1.77548}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M30.372 20.121l-3.705 2.96c-1.22.97-3.22.97-4.439 0l-3.693-2.96"
        stroke="#fff"
        strokeWidth={1.77548}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
