import { Colors, Fonts } from "theme";
import { BottomSheetTextInput } from "@gorhom/bottom-sheet";
import { StyleSheet, TextInput, TextInputProps, ViewStyle, Platform } from "react-native";
import { FC, useEffect, useRef } from "react";

interface BottomSheetTextInputCustomProps extends TextInputProps {
  autoFocus?: boolean;
  value?: string;
  onChangeText?: (value: string) => void;
  placeholder?: string;
  style?: ViewStyle;
  isBottomSheetInput?: boolean;
}
const BottomSheetTextInputCustom: FC<BottomSheetTextInputCustomProps> = ({
  autoFocus,
  value,
  onChangeText,
  placeholder,
  style,
  isBottomSheetInput = Platform.OS !== "web",
  ...rest
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        inputRef?.current?.focus?.();
      }, 100);
    }
  }, []);

  if (!isBottomSheetInput) {
    return (
      <TextInput
        textAlignVertical="top"
        returnKeyType="done"
        ref={inputRef}
        defaultValue={value}
        onChangeText={onChangeText}
        multiline
        placeholder={placeholder}
        placeholderTextColor={Colors.grayscale60}
        style={[Fonts.BodyMedium, styles.textInput, style]}
        {...rest}
      />
    );
  }
  return (
    <BottomSheetTextInput
      textAlignVertical="top"
      returnKeyType="done"
      ref={inputRef}
      defaultValue={value}
      onChangeText={onChangeText}
      multiline
      placeholder={placeholder}
      placeholderTextColor={Colors.grayscale60}
      style={[Fonts.BodyMedium, styles.textInput, style]}
      {...rest}
    />
  );
};
export default BottomSheetTextInputCustom;
const styles = StyleSheet.create({
  textInput: {
    color: Colors.grayscale100,
    borderWidth: 1,
    padding: 10,
    borderColor: Colors.primary50,
    width: "100%",
    height: 120,
    borderRadius: 4,
    marginTop: 20,
  },
});
