// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  Linking,
  Keyboard,
} from "react-native";
import { Toast } from "@ant-design/react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import Constants from "expo-constants";
import { VerifyCodeIcon } from "app/assets/images/svg/icons";
import get from "lodash/get";
import { HEADER_BAR_HEIGHT } from "constants/Layout";
import { AppText, BizziButton } from "components";
import Layout, { SPACING_DEFAULT } from "constants/Layout";
import { OTPInputView } from "layouts";
import { Colors } from "constants/Colors";
import * as IntentLauncher from "expo-intent-launcher";
import { OTPScreenScreenNavigationProp, OTPScreenScreenRouteProp } from "navigation/type";
import { Appbar } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";

const PIN_COUNT = 6;

export default function OTPScreen() {
  const navigation = useNavigation<OTPScreenScreenNavigationProp>();
  const route = useRoute<OTPScreenScreenRouteProp>();
  const { top, bottom } = useSafeAreaInsets();
  const { t } = useTranslation("screens/Auth/OTPScreen");
  const { email, next, required_existence = true } = route?.params ?? {};
  const [code, setCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { verifyOTP, requestOTPFromEmail } = useAuth();

  const resendOtp = useCallback(async (): Promise<void> => {
    Keyboard.dismiss();
    Toast.removeAll();
    setIsSubmitting(true);
    try {
      const result = await requestOTPFromEmail({ email, requiredExistence: required_existence });

      if (get(result, "data.idSendEmailOtp.success")) {
        Toast.success(t("otp_code_has_been_sent_to_x", { email }));
      } else {
        const errorCode = get(result, "data.idSendEmailOtp.success");
        if (errorCode) {
          Toast.fail(`${t("error")} (${errorCode})`);
        } else {
          Toast.fail(t("unknown"));
        }
      }
    } catch {
      Toast.fail(t("error.unknown"));
    }
    setIsSubmitting(false);
  }, [email, required_existence, requestOTPFromEmail, t]);

  const onCodeFilled = useCallback(
    async (otp: string): Promise<void> => {
      setIsSubmitting(true);
      Keyboard.dismiss();
      try {
        setCode(otp);
        const result = await verifyOTP({
          email,
          otp,
        });
        if (get(result, "data.idVerifyOtp.success")) {
          requestAnimationFrame(() => {
            navigation.navigate(next, {
              email,
              token: get(result, "data.idVerifyOtp.token"),
              key: get(result, "data.idVerifyOtp.key"),
            });
          });
        } else {
          Toast.fail(t("incorrect_opt_number"), 1, () => {
            Keyboard.dismiss();
          });
        }
      } catch {
        setCode("");
        Toast.fail(t("unknown"), 1, () => {
          Keyboard.dismiss();
        });
      }
      setIsSubmitting(false);
    },
    [navigation, email, next, verifyOTP, t]
  );

  const openMailClientIOS = useCallback(() => {
    Linking.canOpenURL("message:0")
      .then((supported) => {
        if (!supported) {
          console.log("Cant handle url");
        } else {
          return Linking.openURL("message:0").catch((error) => {
            console.log(error);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const openMailClientAndroid = useCallback(() => {
    const activityAction = "android.intent.action.MAIN";
    const intentParams: IntentLauncher.IntentLauncherParams = {
      flags: 268435456, // Intent.FLAG_ACTIVITY_NEW_TASK
      category: "android.intent.category.APP_EMAIL",
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    IntentLauncher.startActivityAsync(activityAction, intentParams).catch(() => {});
  }, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <Appbar
        style={[
          styles.navbarStyle,
          { height: Platform.OS === "ios" ? HEADER_BAR_HEIGHT : HEADER_BAR_HEIGHT + top, paddingTop: top },
        ]}
      >
        <Appbar.Action icon="keyboard-backspace" onPress={goBack} />
      </Appbar>
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.banner}>
            <VerifyCodeIcon />
          </View>

          <AppText style={styles.description}>{t("otp_code_has_been_sent_to_x", { email })}</AppText>

          <OTPInputView
            pinCount={PIN_COUNT}
            autoFocusOnLoad
            codeInputFieldStyle={styles.underlineStyleBase}
            codeInputHighlightStyle={styles.underlineStyleHighLighted}
            onCodeFilled={onCodeFilled}
          />

          <View style={styles.wrapper_resend_code}>
            <AppText style={styles.resendCodeLabel}>{t("did_not_receive_code")}</AppText>
            <TouchableOpacity style={{ alignSelf: "flex-start" }} onPress={resendOtp}>
              <AppText style={styles.resendCode}>{t("resend_code")}</AppText>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <View style={[styles.buttonContainer, { paddingBottom: bottom + SPACING_DEFAULT }]}>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, justifyContent: "space-around" }}>
            <BizziButton
              color={Colors.white500}
              textColor={Colors.blue500}
              activeStyle={{ backgroundColor: Colors.blue100 }}
              style={styles.buttonOpenInbox}
              onPress={() => {
                if (Platform.OS === "android") {
                  openMailClientAndroid();
                } else {
                  openMailClientIOS();
                }
              }}
              disabled={isSubmitting}
              title={t("title_open_inbox")}
            />
          </View>

          <View style={{ flex: 1, marginLeft: 20 }}>
            <BizziButton
              disabled={code.length !== PIN_COUNT || isSubmitting}
              onPress={() => onCodeFilled(code)}
              title={t("next")}
              loading={isSubmitting}
            />
          </View>
        </View>
      </View>
      {/* {loading && <ActivityIndicator animating toast size="large" />} */}
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  description: {
    fontSize: 14,
    lineHeight: 18,
    color: Colors.Ink300,
    textAlign: "center",
    marginTop: SPACING_DEFAULT * 2,
    marginHorizontal: SPACING_DEFAULT,
  },
  resendCodeLabel: {
    fontSize: 14,
    color: Colors.Ink500,
    lineHeight: 18,
  },
  resendCode: {
    fontSize: 14,
    color: Colors.blue500,
    textAlign: "left",
    marginLeft: 3,
    lineHeight: 18,
  },
  buttonContainer: {
    marginHorizontal: SPACING_DEFAULT,
    paddingBottom: SPACING_DEFAULT,
  },

  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    backgroundColor: Colors.white500,
    borderRadius: 8,
  },
  underlineStyleHighLighted: {
    borderBottomColor: Colors.blue500,
  },
  banner: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 20,
  },
  navbarStyle: { shadowOpacity: 0, elevation: 0, backgroundColor: "transparent" },
  wrapper_resend_code: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 40,
  },
  buttonOpenInbox: { borderColor: Colors.blue300, borderWidth: 1 },
});
