import { AppText, BottomSheetModalCustom, BottomSheetModalCustomMethods, useWindowDimensions } from "components";
import { useTranslation } from "react-i18next";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Keyboard, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { CONSTANTS } from "constants/constants";
import TransactionListView from "screens/Expense/components/SelectTransactionModal/TransactionListView";
import { WINDOW_WIDTH } from "@gorhom/bottom-sheet";
import { Fonts } from "theme";
import { ArrowLeftIcon, CloseIcon } from "assets/images/svg/icons";
import FilterView from "screens/Expense/components/SelectTransactionModal/FilterView";
import dayjs, { Dayjs } from "dayjs";
import { TimeFilterData, TYPE_TIME_FILTER } from "screens/Card/TransactionHistoryListScreen/types";
import { TIME_FILTER_DATA } from "screens/Card/TransactionHistoryListScreen/constants";
import { useAuth } from "contexts/AuthContext";
import useAssignableTransactionsQuery from "screens/Expense/hooks/useAssignableTransactionsQuery";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";
import groupBy from "lodash/groupBy";
import useSuggestAssignableExpenseCardTransactions from "screens/Expense/hooks/useSuggestAssignableExpenseCardTransactions";

const PAGE_SIZE = 10;
const DAY_OF_WEEK = {
  0: { en: "Sun", vi: "Chủ nhật" },
  1: { en: "Mon", vi: "Thứ hai" },
  2: { en: "Wed", vi: "Thứ ba" },
  3: { en: "Thu", vi: "Thứ tư" },
  4: { en: "Fri", vi: "Thứ năm" },
  5: { en: "Sat", vi: "Thứ sáu" },
  6: { en: "Sun", vi: "Thứ bảy" },
};
interface SelectTransactionModalProps {
  onSelectTransaction: (transaction: CrdCardTransaction) => void;
  value?: string;
  expenseDate?: string;
  amount: number;
}
const SelectTransactionModal = forwardRef<any, SelectTransactionModalProps>(
  ({ onSelectTransaction, value, amount, expenseDate }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        onFetchData();
        bottomSheetRef?.current?.present?.();
      },
    }));
    const { t } = useTranslation("app/screens/Expense/components/SelectTransactionModal");
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
    const { width } = useWindowDimensions();

    const {
      user: { employee_id: employeeId },
      company: { company_id: companyId },
    } = useAuth();
    const scrollViewRef = useRef<ScrollView>(null);
    const [currentFilter, setCurrentFilter] = useState<TimeFilterData>(TIME_FILTER_DATA[0]);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(30, "day").startOf("day"));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf("day"));
    const [textSearch, setTextSearch] = useState("");
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const debounceSearchTimeoutId = useRef(null);
    const isLoadMore = useRef(true);
    const [
      ,
      {
        data: suggestCardTransactions,
        refetch: refetchSuggestCardTransactions,
        loading: loadingSuggestCardTransaction,
      },
    ] = useSuggestAssignableExpenseCardTransactions({
      input: { companyEmployeeId: employeeId, companyId, expenseDate, spendingAmount: Number(amount) },
      limit: 1,
    });
    const onFetchData = () => {
      refetch();
      refetchSuggestCardTransactions();
    };
    const [, { data, fetchMore, refetch }] = useAssignableTransactionsQuery(
      {
        condition: {
          companyEmployeeId: employeeId,
          companyId: companyId,
          from: startDate.toISOString(),
          to: endDate.toISOString(),
          location: textSearch,
        },
        limit: PAGE_SIZE,
        offset: 0,
      },
      (data) => {
        /*-- group data by transactionDate --*/
        const tmp = data?.crdAssignableExpenseCardTransactions?.transactions?.map((item) => ({
          ...item,
          transactionDateFormat:
            i18n.language === LanguageStatus.EN
              ? `${DAY_OF_WEEK[dayjs(item?.transactionDate).day()].en}, ${dayjs(item?.transactionDate).format(
                  "MM/DD/YYYY"
                )}`
              : `${DAY_OF_WEEK[dayjs(item?.transactionDate).day()].vi}, ${dayjs(item?.transactionDate).format(
                  "DD/MM/YYYY"
                )}`,
        }));
        const groups = groupBy(tmp ?? [], (item) => item?.transactionDateFormat);
        const newTransactionList = Object.keys(groups).map((key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // set last item to custom style last item
          groups[key][groups[key].length - 1].isLastItem = true;
          return {
            title: groups[key][0].transactionDateFormat,
            data: groups[key],
          };
        });
        setTransactionList(newTransactionList);
        setLoading(false);
        setLoadingMore(false);
        setTimeout(() => {
          isLoadMore.current = false;
        }, 1000);
      }
    );
    const [transactionList, setTransactionList] = useState([]);
    const total = data?.crdAssignableExpenseCardTransactions?.totalEntries ?? 0;
    const suggestCardTransaction =
      suggestCardTransactions?.crdSuggestAssignableExpenseCardTransactions?.transactions?.[0];
    const handleSelectTransaction = (item) => {
      onSelectTransaction(item);
      bottomSheetRef?.current?.close();
    };
    const onChangeSearch = (text) => {
      if (debounceSearchTimeoutId.current) {
        clearTimeout(debounceSearchTimeoutId.current);
      }
      debounceSearchTimeoutId.current = setTimeout(() => {
        setLoading(true);
        setTextSearch(text);
      }, 500);
    };
    const handleClearSearch = () => {
      Keyboard.dismiss();
      setTextSearch("");
      setLoading(true);
    };
    const handleLoadMore = async () => {
      const quantityCurrentTransaction = data?.crdAssignableExpenseCardTransactions?.transactions?.length;
      if (isLoadMore.current || loading || quantityCurrentTransaction === 0 || quantityCurrentTransaction >= total) {
        return;
      }
      isLoadMore.current = true;
      setLoadingMore(true);
      await fetchMore?.({
        variables: { offset: quantityCurrentTransaction },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            crdAssignableExpenseCardTransactions: {
              transactions: [
                ...prev?.crdAssignableExpenseCardTransactions?.transactions,
                ...fetchMoreResult?.crdAssignableExpenseCardTransactions?.transactions,
              ],
              totalEntries: fetchMoreResult?.crdAssignableExpenseCardTransactions?.totalEntries,
            },
          };
        },
      });
    };

    const renderHeaderHandle = () => {
      return null;
    };

    const handleApplyFilter = async (filterValue: TYPE_TIME_FILTER, from: Dayjs, to: Dayjs) => {
      const filter = TIME_FILTER_DATA.find((item) => item?.value === filterValue);
      setCurrentFilter(filter);
      setLoading(true);
      handleBackFromFilter();
      /*--- reload data when click apply filter ---*/
      if (startDate.toISOString() === from.toISOString() && endDate.toISOString() === to.toISOString()) {
        refetch?.({
          condition: {
            companyEmployeeId: employeeId,
            companyId: companyId,
            from: startDate.toISOString(),
            to: endDate.toISOString(),
          },
          limit: PAGE_SIZE,
          offset: 0,
        });
      } else {
        setStartDate(from);
        setEndDate(to);
      }
      /*--- end ---*/
    };

    /*---reset filter when close modal --*/
    const onDismiss = () => {
      setTextSearch("");
      setCurrentFilter(TIME_FILTER_DATA[0]);
      setStartDate(dayjs().subtract(30, "day").startOf("day"));
      setEndDate(dayjs().endOf("day"));
    };

    const handleClickFilter = () => {
      scrollViewRef.current?.scrollTo({
        x: WINDOW_WIDTH,
        animated: true,
      });
    };
    const handleBackFromFilter = () => {
      scrollViewRef.current?.scrollTo({
        x: 0,
        animated: true,
      });
    };

    return (
      <BottomSheetModalCustom
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        ref={bottomSheetRef}
        title={t("select_transaction")}
        handleComponent={renderHeaderHandle}
        onDismiss={onDismiss}
      >
        <ScrollView
          ref={scrollViewRef}
          horizontal
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          bounces={false}
          pagingEnabled
        >
          {/*--transaction list--*/}
          <View style={{ width, flex: 1 }}>
            <View style={styles.header}>
              <AppText style={Fonts.H400}>{t("select_transaction")}</AppText>
              <TouchableOpacity style={styles.buttonClose} onPress={() => bottomSheetRef?.current?.close()}>
                <CloseIcon />
              </TouchableOpacity>
            </View>
            <TransactionListView
              suggestCardTransaction={suggestCardTransaction}
              data={transactionList}
              loading={loading || loadingSuggestCardTransaction}
              startDate={startDate}
              endDate={endDate}
              handleClickFilter={handleClickFilter}
              handleLoadMore={handleLoadMore}
              textSearch={textSearch}
              onChangeSearch={onChangeSearch}
              currentFilter={currentFilter}
              handleClearSearch={handleClearSearch}
              loadingMore={loadingMore}
              handleSelectTransaction={handleSelectTransaction}
              currentValue={value}
              total={total}
            />
          </View>
          {/*----end-----*/}

          {/*---filter view ---*/}
          <View style={{ width, flex: 1 }}>
            <View style={styles.headerCategory}>
              <TouchableOpacity style={styles.buttonBackFilter} onPress={handleBackFromFilter}>
                <ArrowLeftIcon />
              </TouchableOpacity>
              <AppText style={[Fonts.H400, { marginLeft: 10 }]}>{t("filter")}</AppText>
            </View>
            <FilterView handleApplyFilter={handleApplyFilter} />
          </View>
          {/*----end-----*/}
        </ScrollView>
      </BottomSheetModalCustom>
    );
  }
);
export default SelectTransactionModal;
const styles = StyleSheet.create({
  modalContent: { width: WINDOW_WIDTH, flex: 1 },
  flex: { flex: 1 },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 15,
    paddingBottom: 15,
  },
  headerCategory: {
    paddingTop: 17,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonClose: { padding: 5 },
  buttonBackFilter: { paddingHorizontal: 5, paddingVertical: 2 },
});
