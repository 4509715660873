import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#fff" d="M0.167969 0.730713H24.167969V24.730713H0.167969z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.168 2.73h10l-2 7h4l-2.925 4.226-1.425-1.425.55-.8h-1.35l-1.175-1.175 1.675-5.825h-5.35v2.15l-2-2V2.73zm3 20v-8h-3v-4.15L1.543 4.957 2.968 3.53l18.4 18.4-1.425 1.425-6.025-6.025-3.75 5.4z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
