import React, { useEffect, useRef, useState } from "react";
import { Alert, Dimensions, Linking, StyleSheet, TouchableOpacity, View } from "react-native";
import { AlertNotification, AlertNotificationHandle, AppText, IconCustom, ImageCustom } from "components";
import { useExpBankQRParser } from "screens/ScanQrCodeScreen/hooks/useExpBankQRParser";
import { MobileExpBankQrParserQuery } from "types";
import { goBack } from "navigation/RootNavigation";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import { Camera, FlashMode, PermissionResponse } from "expo-camera";
import { ErrorIcon } from "assets/images/svg/icons";
import { NapasLogo, VietQrLogo, VNPayLogo } from "assets/images";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { BarCodeScanner } from "expo-barcode-scanner";
import { PermissionStatus } from "expo-modules-core/src/PermissionsInterface";

const deviceHeight = Dimensions.get("window").height;
const deviceWidth = Dimensions.get("window").width;
const EdgeQRCode = ({ position }: { position: string }) => {
  const edgeWidth = 20;
  const edgeHeight = 20;
  const edgeColor = "#FFF";
  const edgeBorderWidth = 4;
  const edgeRadius = 0;

  const defaultStyle = {
    width: edgeWidth,
    height: edgeHeight,
    borderColor: edgeColor,
  };
  const edgeBorderStyle: any = {
    topRight: {
      borderRightWidth: edgeBorderWidth,
      borderTopWidth: edgeBorderWidth,
      borderTopRightRadius: edgeRadius,
      top: edgeRadius,
      right: edgeRadius,
    },
    topLeft: {
      borderLeftWidth: edgeBorderWidth,
      borderTopWidth: edgeBorderWidth,
      borderTopLeftRadius: edgeRadius,
      top: edgeRadius,
      left: edgeRadius,
    },
    bottomRight: {
      borderRightWidth: edgeBorderWidth,
      borderBottomWidth: edgeBorderWidth,
      borderBottomRightRadius: edgeRadius,
      bottom: edgeRadius,
      right: edgeRadius,
    },
    bottomLeft: {
      borderLeftWidth: edgeBorderWidth,
      borderBottomWidth: edgeBorderWidth,
      borderBottomLeftRadius: edgeRadius,
      bottom: edgeRadius,
      left: edgeRadius,
    },
  };
  return <View style={[defaultStyle, styles[position + "Edge"], edgeBorderStyle[position]]} />;
};

const opacity = "rgba(0, 0, 0, .6)";
const styles: any = StyleSheet.create({
  // action
  headerContainer: {
    position: "absolute",
    width: "100%",
    top: 0,
    paddingHorizontal: 20,
  },
  actionRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  // layout
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  cameraContainer: {
    flex: 1,
    width: deviceHeight,
    height: deviceHeight / 2,
  },

  layerTop: {
    flex: 1,
    //backgroundColor: opacity,
    justifyContent: "flex-end",
    paddingBottom: 20,
  },
  layerCenter: {
    flex: 1,
    flexDirection: "row",
  },
  layerLeft: {
    flex: 1,
    //backgroundColor: opacity,
  },
  focused: {
    flex: 1,
    position: "relative",
    borderRadius: 4,
  },
  layerRight: {
    flex: 1,
    //backgroundColor: opacity,
  },
  layerBottom: {
    flex: 1,
    //backgroundColor: opacity,
    paddingTop: 40,
  },
  bankLogoContainer: {
    alignSelf: "center",
    flexDirection: "row",
    gap: 30,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  topLeftEdge: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  topRightEdge: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  bottomLeftEdge: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  bottomRightEdge: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});

const ScanQrCodeScreen = (props) => {
  const { route } = props;
  const { t } = useTranslation("app/screens/ScanQrCodeScreen");
  const [scanned, setScanned] = useState<boolean>(false);
  const [startCamera, setStartCamera] = useState<boolean>(false);

  const { onFinish } = route?.params;
  const alertRef = useRef<AlertNotificationHandle>();
  const [onFetchDataFromQrCode] = useExpBankQRParser();
  const [isFlashMode, setFlashMode] = useState(false);
  const isScanned = useRef(false);
  const onBarCodeScanned = async (result) => {
    if (isScanned.current) {
      return;
    }
    setScanned(true);
    isScanned.current = true;
    if (result?.data) {
      const data = await onFetchDataFromQrCode({ variables: { content: result?.data } });
      if (data?.data?.expBankQRParser?.isValid) {
        onFinish?.(
          data?.data?.expBankQRParser?.paymentInfo as MobileExpBankQrParserQuery["expBankQRParser"]["paymentInfo"]
        );
        goBack();
        analyticService.logEvent({ name: EVENT.EXPENSE.SCAN_QR_CODE_SUCCESS });
        return;
      }
    }
    alertRef?.current?.info({
      icon: <ErrorIcon />,
      title: t("scan_code_error_title"),
      description: t("scan_code_error_description"),
      onConfirm: () => alertRef?.current?.close(),
    });
    analyticService.logEvent({ name: EVENT.EXPENSE.SCAN_QR_CODE_FAILED });
  };

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const permissionResult: PermissionResponse = await BarCodeScanner.requestPermissionsAsync();
      setStartCamera(permissionResult?.status === PermissionStatus.GRANTED);
      if (permissionResult?.status !== PermissionStatus.GRANTED) {
        Alert.alert(
          "",
          t("description_require_permission"),
          [
            {
              text: t("button_title_open_setting"),
              onPress: () => {
                Linking.openSettings();
              },
            },
            {
              text: t("button_cancel"),
              style: "cancel",
            },
          ],
          { cancelable: false }
        );
      }
    };
    getBarCodeScannerPermissions();
  }, []);
  const renderCameraContent = () => (
    <>
      <View style={styles.layerTop}>
        <View style={styles.bankLogoContainer}>
          <ImageCustom style={{ height: 20, width: 62 }} source={VietQrLogo} />
          <ImageCustom style={{ height: 23, width: 70 }} source={VNPayLogo} />
          <ImageCustom style={{ height: 20, width: 72 }} source={NapasLogo} />
        </View>
      </View>
      <View style={styles.layerCenter}>
        <View style={styles.layerLeft} />
        <View style={styles.focused}>
          <EdgeQRCode position="topRight" />
          <EdgeQRCode position="topLeft" />
          <EdgeQRCode position="bottomRight" />
          <EdgeQRCode position="bottomLeft" />
        </View>
        <View style={styles.layerRight} />
      </View>
      <View style={styles.layerBottom}>
        <View style={styles.bankLogoContainer}>
          <AppText style={Fonts.BodyMedium} color={Colors.white}>
            {t("scan_code_description")}
          </AppText>
        </View>
      </View>
    </>
  );

  const { top } = useSafeAreaInsets();
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      {startCamera ? (
        <Camera
          flashMode={isFlashMode ? FlashMode.torch : FlashMode.off}
          onBarCodeScanned={scanned ? undefined : onBarCodeScanned}
          style={styles.cameraContainer}
        >
          {renderCameraContent()}
        </Camera>
      ) : (
        <View style={styles.cameraContainer}>{renderCameraContent()}</View>
      )}

      {/*-- Camera Header --*/}
      <View style={[styles.headerContainer, { top: top + 10 }]}>
        <View style={styles.actionRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <IconCustom name="arrow-back" fill="#FFF" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setFlashMode((prevState) => !prevState)}>
            <IconCustom name={isFlashMode ? "flash-on" : "flash-off"} fill="#FFF" />
          </TouchableOpacity>
        </View>
      </View>
      {/*-- end --*/}
      <AlertNotification
        onDismiss={() => {
          isScanned.current = false;
          setScanned(false);
        }}
        ref={alertRef}
      />
    </View>
  );
};
export default ScanQrCodeScreen;
