import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M4.5 19v-8.845a1.795 1.795 0 01.723-1.446l5.692-4.289a1.738 1.738 0 011.083-.361c.407 0 .769.12 1.087.361l5.692 4.289c.226.165.403.376.53.632.129.256.193.527.193.814V19c0 .409-.148.761-.443 1.057A1.443 1.443 0 0118 20.5h-3.192a.875.875 0 01-.644-.26.874.874 0 01-.26-.644v-4.885a.874.874 0 00-.26-.644.874.874 0 00-.644-.26h-2a.875.875 0 00-.644.26.874.874 0 00-.26.644v4.885c0 .256-.087.47-.26.644a.875.875 0 01-.644.26H6c-.409 0-.761-.148-1.057-.443A1.443 1.443 0 014.5 19z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
