import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M11.25 16.75v.567c0 .114.044.215.13.302.088.087.189.131.303.131h.615c.12 0 .226-.045.316-.136a.434.434 0 00.136-.316v-.548H14a.726.726 0 00.535-.215.726.726 0 00.215-.535v-3a.726.726 0 00-.215-.535.726.726 0 00-.535-.215h-3.25v-1.5H14a.726.726 0 00.535-.215.726.726 0 00.215-.535.726.726 0 00-.215-.535A.726.726 0 0014 9.25h-1.25v-.567a.415.415 0 00-.13-.302.415.415 0 00-.303-.131h-.615a.433.433 0 00-.316.136.434.434 0 00-.136.316v.548H10a.726.726 0 00-.535.215.726.726 0 00-.215.535v3c0 .213.072.391.215.535a.726.726 0 00.535.215h3.25v1.5H10a.726.726 0 00-.535.215.726.726 0 00-.215.535c0 .213.072.391.215.535a.726.726 0 00.535.215h1.25zM6.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h6.732c.241 0 .473.047.696.14.222.094.415.223.58.387l4.657 4.658c.164.164.293.357.387.58.093.222.14.454.14.695v10.732c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.308zm0-1.5h11.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V8.85L13.15 4H6.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v15.384c0 .077.032.148.096.212a.294.294 0 00.212.096z"
          fill="#94A3B8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
