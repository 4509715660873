import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ fill = "#fff" }) {
  return (
    <Svg width={9} height={10} viewBox="0 0 9 10">
      <Path
        d="M3.762 2.302L.746 5.317a.411.411 0 01-.304.129.433.433 0 01-.31-.138A.457.457 0 010 5.001a.408.408 0 01.131-.308L3.83.995a.484.484 0 01.369-.15.484.484 0 01.37.15l3.698 3.698c.08.081.122.181.124.3a.436.436 0 01-.124.315.429.429 0 01-.312.136.429.429 0 01-.312-.136L4.636 2.302v6.636a.423.423 0 01-.125.312.423.423 0 01-.312.126.423.423 0 01-.312-.126.423.423 0 01-.125-.312V2.302z"
        fill={fill}
      />
    </Svg>
  );
}

export default SvgComponent;
