import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Keyboard,
  RefreshControl,
  SectionList,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import i18n from "i18next";
import groupBy from "lodash/groupBy";

import {
  AppText,
  BackHeader,
  BottomSheetModalCustomMethods,
  EmptyData,
  IconCustom,
  SearchInput,
  SkeletonListLoading,
} from "components";
import { Colors, Fonts } from "theme";
import { SearchNotFoundIcon, TransactionEmpty } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import LanguageStatus from "constants/LanguageStatus";
import useEmployeeCardTransactionsQuery from "./hooks/useEmployeeCardTransactionsQuery";
import TransactionItem from "./components/TransactionItem";
import FilterTransactionModal from "./components/FilterTransactionModal";
import { TIME_FILTER_DATA } from "./constants";
import { TimeFilterData, TYPE_TIME_FILTER } from "./types";
import SCREEN_NAME from "navigation/ScreenName";

const PAGE_SIZE = 10;
const DAY_OF_WEEK = {
  0: { en: "Sun", vi: "Chủ nhật" },
  1: { en: "Mon", vi: "Thứ hai" },
  2: { en: "Wed", vi: "Thứ ba" },
  3: { en: "Thu", vi: "Thứ tư" },
  4: { en: "Fri", vi: "Thứ năm" },
  5: { en: "Sat", vi: "Thứ sáu" },
  6: { en: "Sun", vi: "Thứ bảy" },
};

const TransactionHistoryListScreen = ({ navigation, route }) => {
  const { t } = useTranslation("app/screens/Card/TransactionHistoryListScreen");
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("transaction_history")} />,
    } as any);
  }, []);

  const { cardId } = route?.params ?? {};
  const [currentFilter, setCurrentFilter] = useState<TimeFilterData>(TIME_FILTER_DATA[0]);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(30, "day").startOf("day"));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf("day"));
  const [textSearch, setTextSearch] = useState("");
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const debounceSearchTimeoutId = useRef(null);
  const isLoadMore = useRef(true);
  const searchInputRef = useRef(null);

  const filterModalRef = useRef<BottomSheetModalCustomMethods>(null);

  const {
    company: { company_id: companyId },
  } = useAuth();
  const [onFetchData, { data, fetchMore, refetch, called }] = useEmployeeCardTransactionsQuery(
    {
      limit: PAGE_SIZE,
      offset: 0,
      companyId,
      cardId,
      where: { from: startDate.toISOString(), to: endDate.toISOString(), location: textSearch },
    },
    (data) => {
      /*-- group data by transactionDate --*/
      const tmp = data?.crdEmployeeCardTransactions?.transactions?.map((item) => ({
        ...item,
        transactionDate:
          i18n.language === LanguageStatus.EN
            ? `${DAY_OF_WEEK[dayjs(item?.transactionDate).day()].en}, ${dayjs(item?.transactionDate).format(
                "MM/DD/YYYY"
              )}`
            : `${DAY_OF_WEEK[dayjs(item?.transactionDate).day()].vi}, ${dayjs(item?.transactionDate).format(
                "DD/MM/YYYY"
              )}`,
      }));
      const groups = groupBy(tmp ?? [], (item) => item?.transactionDate);
      const newTransactionList = Object.keys(groups).map((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // set last item to custom style last item
        groups[key][groups[key].length - 1].isLastItem = true;
        return {
          title: groups[key][0].transactionDate,
          data: groups[key],
        };
      });
      setTransactionList(newTransactionList);
      setLoading(false);
      setLoadingMore(false);
      setTimeout(() => {
        isLoadMore.current = false;
      }, 1000);
    }
  );
  const [transactionList, setTransactionList] = useState([]);
  const total = data?.crdEmployeeCardTransactions?.totalEntries ?? 0;

  useEffect(() => {
    onFetchData();
  }, []);

  const handleApplyFilter = async (filterValue: TYPE_TIME_FILTER, from: Dayjs, to: Dayjs) => {
    const filter = TIME_FILTER_DATA.find((item) => item?.value === filterValue);
    setCurrentFilter(
      filter || {
        title: "customize",
        value: TYPE_TIME_FILTER.CUSTOM,
      }
    );
    setLoading(true);

    /*--- reload data when click apply filter ---*/
    if (startDate.toISOString() === from.toISOString() && endDate.toISOString() === to.toISOString()) {
      refetch?.({
        limit: PAGE_SIZE,
        offset: 0,
        companyId,
        cardId,
        where: { from: startDate.toISOString(), to: endDate.toISOString(), location: textSearch },
      });
    } else {
      setStartDate(from);
      setEndDate(to);
    }
    /*--- end ---*/
  };
  const onRefresh = () => {
    refetch?.();
  };
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      setLoading(true);
      setTextSearch(text);
    }, 500);
  };
  const handleClearSearch = () => {
    searchInputRef?.current?.clear();
    Keyboard.dismiss();
    setTextSearch("");
    setLoading(true);
  };
  const handleLoadMore = async () => {
    const quantityCurrentTransaction = data?.crdEmployeeCardTransactions?.transactions?.length;
    if (isLoadMore.current || loading || quantityCurrentTransaction === 0 || quantityCurrentTransaction >= total) {
      return;
    }
    isLoadMore.current = true;
    setLoadingMore(true);
    await fetchMore?.({
      variables: { offset: quantityCurrentTransaction },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          crdEmployeeCardTransactions: {
            transactions: [
              ...prev?.crdEmployeeCardTransactions?.transactions,
              ...fetchMoreResult?.crdEmployeeCardTransactions?.transactions,
            ],
            totalEntries: fetchMoreResult?.crdEmployeeCardTransactions?.totalEntries,
          },
        };
      },
    });
  };
  const handleNavigateTransactionDetail = (item) => () => {
    navigation.navigate(SCREEN_NAME.TransactionDetailScreen, {
      cardTransactionId: item?.cardTransactionId,
      companyId: item?.companyId,
      onRefreshData: refetch,
    });
  };
  const renderItem = ({ item }) => {
    return <TransactionItem onPress={handleNavigateTransactionDetail(item)} item={item} />;
  };

  const renderEmpty = () => {
    if (loading || !called) {
      return null;
    }
    if (textSearch) {
      return <EmptyData icon={<SearchNotFoundIcon />} title={t("search_not_found")} />;
    }
    return <EmptyData icon={<TransactionEmpty />} title={t("empty_transaction")} />;
  };

  const renderFooter = () => {
    if (loadingMore) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const renderSectionHeader = ({ section: { title } }) => (
    <View style={[styles.sectionHeader, { paddingTop: 0 }]}>
      <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
        {title}
      </AppText>
    </View>
  );
  return (
    <View style={styles.container}>
      <View style={styles.filterContainer}>
        <SearchInput
          ref={searchInputRef}
          placeholder={t("search_transaction")}
          onChangeText={onChangeSearch}
          right={
            textSearch ? (
              <TouchableOpacity onPress={handleClearSearch}>
                <IconCustom name="cancel" />
              </TouchableOpacity>
            ) : null
          }
        />
        <View style={styles.filterHeader}>
          <AppText numberOfLines={1} style={Fonts.H200}>
            {total > 1 ? t("transactions", { value: total }) : t("transaction", { value: total })}
          </AppText>
          <View style={styles.flex}>
            <TouchableOpacity style={styles.buttonFilter} onPress={() => filterModalRef?.current?.present()}>
              <AppText style={Fonts.BodyMedium} color={Colors.grayscale80} numberOfLines={1}>
                {currentFilter?.value === TYPE_TIME_FILTER.CUSTOM
                  ? `${startDate.format(CONSTANTS.FORMAT_DAY)} - ${endDate.format(CONSTANTS.FORMAT_DAY)}`
                  : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    t(currentFilter?.title)}
              </AppText>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      {loading && !loadingMore ? (
        <SkeletonListLoading />
      ) : (
        <SectionList
          //keyboardShouldPersistTabs="handled"
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefresh} />}
          keyExtractor={(item, index) => item?.transactionId ?? index}
          sections={transactionList}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
          renderSectionHeader={renderSectionHeader}
          stickySectionHeadersEnabled
        />
      )}
      <FilterTransactionModal handleApplyFilter={handleApplyFilter} ref={filterModalRef} />
    </View>
  );
};
export default TransactionHistoryListScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  filterHeader: { flexDirection: "row", marginTop: 12, alignItems: "center", paddingBottom: 7 },
  filterContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  buttonFilter: {
    paddingVertical: 6,
    alignSelf: "flex-start",
    paddingHorizontal: 12,
    borderRadius: 20,
    backgroundColor: Colors.grayscale05,
    marginLeft: 12,
  },
  listContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingBottom: 80, paddingTop: 15 },
  loadingMore: { marginTop: 10 },
  flex: { flex: 1 },
  sectionHeader: { backgroundColor: Colors.white, marginTop: 0, paddingBottom: 3 },
});
