import { EVENT } from "constants/Tracking";
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import {
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetTextInputCustom,
  Button,
} from "components";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Fonts } from "theme";
import BizziBotWarning2 from "assets/images/svg/icons/BizziBotWarning2";
import { useMasterData } from "contexts/MasterDataContext";
import LanguageStatus from "constants/LanguageStatus";

const ButtonApproveRequest = ({ requestTitle, onApprove, reason, setReason, disabled }) => {
  const { t, i18n } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const bottomSheetModalRef = useRef<BottomSheetModalCustomMethods>(null);
  const { withApprovalReason } = useMasterData();

  const onClickApprove = async () => {
    if (withApprovalReason) {
      bottomSheetModalRef?.current?.present();
      return;
    }
    onApprove();
  };

  return (
    <>
      <Button
        eventName={EVENT.REQUEST.APPROVED}
        style={styles.footerButtonRight}
        disabled={disabled}
        onPress={onClickApprove}
        type="success"
      >
        {t("approve")}
      </Button>
      <BottomSheetScrollViewModalCustom ref={bottomSheetModalRef} wrapperByScrollView={false}>
        <TouchableWithoutFeedback onPress={Platform.OS !== "web" && Keyboard.dismiss}>
          <View style={styles.container}>
            <BizziBotWarning2 width={80} height={80} />
            <View style={styles.labelContainer}>
              <AppText style={Fonts.H400}>{t("approve_request_title")}</AppText>
              <AppText style={[Fonts.BodyMedium, styles.confirmText]} numberOfLines={2}>
                {t("approve_request_description")}
                <AppText style={Fonts.H200}>{` "${requestTitle}"`}</AppText>
                {i18n.language === LanguageStatus.EN ? " request?" : "?"}
              </AppText>
            </View>
            <BottomSheetTextInputCustom
              autoFocus
              value={reason}
              onChangeText={setReason}
              placeholder={t("reason_approve")}
            />
          </View>
        </TouchableWithoutFeedback>

        <View style={styles.footerButton}>
          <Button type="white" style={styles.buttonClose} onPress={() => bottomSheetModalRef?.current?.close()}>
            {t("close")}
          </Button>
          <Button
            style={styles.buttonApprove}
            onPress={() => {
              bottomSheetModalRef?.current?.close();
              onApprove();
            }}
          >
            {t("confirm")}
          </Button>
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default ButtonApproveRequest;

const styles = StyleSheet.create({
  footerButtonRight: { flex: 1, marginLeft: 6 },
  container: {
    paddingHorizontal: 20,
    paddingTop: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  labelContainer: { alignItems: "center", marginTop: 20 },
  confirmText: { marginTop: 8, textAlign: "center" },
  footerButton: { flexDirection: "row", paddingHorizontal: 20, marginTop: 20, paddingBottom: 10 },
  buttonClose: { flex: 1, marginRight: 8 },
  buttonApprove: { flex: 1, marginLeft: 8 },
});
