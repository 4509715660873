// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { gql, MutationHookOptions, useMutation } from "@apollo/client";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ExpInsertExpenseReportMutation, ExpInsertExpenseReportMutationVariables } from "types";

const INSERT_EXPENSE_REPORT = gql`
  mutation MobileInsertExpenseReport($expenseReport: ExpInsertExpenseReportInput!) {
    expInsertExpenseReport(input: $expenseReport) {
      message
      expenseReportId
    }
  }
`;

export const useExpInsertExpenseReportMutation = (
  options?: MutationHookOptions<ExpInsertExpenseReportMutation, ExpInsertExpenseReportMutationVariables>
) => {
  return useMutation(INSERT_EXPENSE_REPORT, options);
};
