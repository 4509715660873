import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpInsightsSpendingTeamsQuery, MobileExpInsightsSpendingTeamsQueryVariables } from "types";

const QUERY = gql`
  query MobileExpInsightsSpendingTeams($input: ExpInsightsInput!) {
    expInsights(input: $input) {
      topSpendingTeams(top: 4) {
        teams {
          teamId
          name
          nameEn
          totalAmount
          totalAmountPrevious
          totalAmountDiffPercentage
        }
        totalTeams
        otherAmount
      }
    }
  }
`;

const useSpendingTeamsQuery = (variables: MobileExpInsightsSpendingTeamsQueryVariables, skip) => {
  return useQuery<MobileExpInsightsSpendingTeamsQuery, MobileExpInsightsSpendingTeamsQueryVariables>(QUERY, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip,
  });
};

export default useSpendingTeamsQuery;
