import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  ToastManager,
  useBackHandler,
} from "components";
import { useAuth } from "contexts/AuthContext";
import dayjs from "dayjs";
import { useCreateExpenseRequest, useSubmitExpenseRequest } from "hooks/request/useLegacyExpenseRequests";
import React, { FC, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import {
  BusBookingSettings,
  ExpenseRequestFormAction,
  ExpenseRequestFormValues,
  FlightBookingSettings,
  HotelBookingSettings,
  OverBudget,
} from "screens/ExpenseRequest/types";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { BizziBotWarning, BizziBotWarning2 } from "assets/images/svg/icons";
import { isEmpty } from "lodash";
import { countNumberOfDays, removeDuplicateObjects, saveRecentCompanyTeamToStorage } from "utils";
import ExpenseRequestForm from "screens/ExpenseRequest/components/ExpenseRequestForm/ExpenseRequestForm";
import {
  ALLOWANCE_TYPE,
  PAYMENT_METHOD,
  REQUEST_TYPE,
  RESTRICTION_BUDGET_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import { Colors, Fonts } from "theme";
import { goBack } from "navigation/RootNavigation";
import { analyticService, withTrackingSession } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { NewRequestScreenNavigationProp, NewRequestScreenRouteProp } from "navigation/type";
import SCREEN_NAME from "navigation/ScreenName";
import { FeedbackObjectType, useInAppFeedback } from "contexts/InAppFeedbackContext";
import { executeRouteFunction } from "utils/route";

type CreateExpenseRequestScreenProps = {
  route: NewRequestScreenRouteProp;
  navigation: NewRequestScreenNavigationProp;
};

const CreateExpenseRequestScreen: FC<CreateExpenseRequestScreenProps> = ({ navigation, route }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/CreateExpenseRequestScreen");
  const { type, shouldShowWalkthrough = false } = route?.params ?? {};
  const { user, groupId, company } = useAuth();
  const [disabledButton, setDisabledButton] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const ref = useRef<AlertNotificationHandle>(null);
  const { createExpenseRequest } = useCreateExpenseRequest();
  const { submitExpenseRequest } = useSubmitExpenseRequest();
  const { requestInAppFeedback } = useInAppFeedback();

  const handleGoBack = useCallback(() => {
    if (!isDirty) {
      goBack();
      return;
    }
    ref.current?.warning({
      icon: <BizziBotWarning />,
      title: t("cancel_title"),
      description: t("cancel_confirm"),
      cancelText: t("close"),
      confirmText: t("cancel_change"),
      onConfirm: () => {
        ref.current?.close();
        goBack();
      },
    });
  }, [isDirty, navigation]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("new_request")} onPress={handleGoBack} />,
    } as any);
  }, [handleGoBack, navigation, t]);

  useBackHandler(() => {
    if (isDirty) {
      ref.current?.warning({
        icon: <BizziBotWarning />,
        title: t("cancel_title"),
        description: t("cancel_confirm"),
        cancelText: t("close"),
        confirmText: t("cancel_change"),
        onConfirm: () => {
          ref.current?.close();
          goBack();
        },
      });
      return true;
    }
    return false;
  });

  const onCreateRequest = async ({
    type,
    values,
    budgetValidate,
    message,
  }: {
    type: ExpenseRequestFormAction;
    values: ExpenseRequestFormValues;
    budgetValidate?: OverBudget[];
    message?: string;
  }) => {
    setDisabledButton(true);
    try {
      ref?.current?.loading();
      const body: any = {
        expenseRequestType: values?.expenseRequestType,
        title: values?.title,
        allowances:
          values?.allowances
            ?.filter((item) => item?.type === ALLOWANCE_TYPE.USER_REQUEST)
            ?.map((item) => ({
              amount: item.amount,
              foreignAmount: values?.currency?.isOn ? item.foreignAmount : undefined,
              description: item.description,
              expenseCategoryId: item.expenseCategoryId,
              productName: item.productName,
              quantity: item.quantity ? Number(item.quantity) : undefined,
              unitPrice: item.unitPrice ? Number(item.unitPrice) : undefined,
              uom: item.uom,
            })) ?? [],
        companyId: company.company_id,
        groupId: groupId,
        employeeId: user?.employee_id,
        budgetDate: dayjs(values?.budgetDate).toISOString(),
        description: values?.description,
        teamId: values?.companyTeam?.companyTeamId,
        attachments: values?.attachments,
        currency: values?.currency?.isOn ? values?.currency?.name : undefined,
        exchangeRate: values?.currency?.isOn ? values?.currency?.exchangeRate : undefined,
        note: values?.note,
      };

      /*-- flow save travel info --*/
      if (values?.expenseRequestType === REQUEST_TYPE.travel) {
        const allowancePerDiems = values?.allowances?.filter((item) => item?.type === ALLOWANCE_TYPE.PERDIEM);
        const numOfDays = countNumberOfDays(values?.travelDate?.fromDate, values?.travelDate?.toDate);
        body.travel = {
          fromCityId: values?.travel?.fromCity?.metadata?.locationId,
          toCityId: values?.travel?.toCity?.metadata?.locationId,
          fromDate: dayjs(values?.travelDate?.fromDate).startOf("day").toISOString(),
          toDate: dayjs(values?.travelDate?.toDate).endOf("day").toISOString(),
          perDiems: allowancePerDiems?.map((item) => ({
            expenseCategoryId: item?.expenseCategoryId,
            amount: item?.amount / numOfDays,
          })),
          expenseBookings: [],
        };

        // save passenger for use case TMG company
        if (!isEmpty(values?.passengers) && values?.enablePassenger) {
          body.travel.passengers = values.passengers;
        }

        /*-- in the case company not setup category for perdiem --> use old work flow --*/
        if (values?.travel?.perDiem && !body?.travel?.perDiems?.length) {
          body.travel.perDiem = values.travel.perDiem;
          delete body.travel.perDiems;
        }
        if (values?.flightBooking?.isOn) {
          const { flightBooking } = values;
          const bookingSettings: FlightBookingSettings = {
            departureAirport: {
              code: flightBooking?.from?.code,
              name: flightBooking?.from?.name,
              nameEn: flightBooking?.from?.nameEn,
              location: {
                code: flightBooking?.from?.location?.code,
                name: flightBooking?.from?.location?.name,
                nameEn: flightBooking?.from?.location?.nameEn,
              },
            },
            arrivalAirport: {
              code: flightBooking?.to?.code,
              name: flightBooking?.to?.name,
              nameEn: flightBooking?.to?.nameEn,
              location: {
                code: flightBooking?.to?.location?.code,
                name: flightBooking?.to?.location?.name,
                nameEn: flightBooking?.to?.location?.nameEn,
              },
            },
            isRoundTrip: flightBooking?.isRoundTrip,
            departureDate: dayjs(flightBooking?.departureDate).toISOString(),
            departureNote: flightBooking?.departureNote,
            returnDate: flightBooking?.isRoundTrip ? dayjs(flightBooking?.returnDate).toISOString() : undefined,
            returnNote: flightBooking?.isRoundTrip ? flightBooking?.returnNote : undefined,
            vendorName: flightBooking?.vendorName ?? undefined,
          };
          const allowanceFlightBooking = values?.allowances?.find(
            (item) => item?.type === ALLOWANCE_TYPE.BOOKING_FLIGHT
          );
          if (allowanceFlightBooking) {
            body.travel.expenseBookings.push({
              expenseCategoryId: allowanceFlightBooking.expenseCategoryId,
              totalAmount: allowanceFlightBooking?.amount || 0,
              flightBooking: bookingSettings,
            });
          }
        }
        if (values?.transportationBooking?.isOn) {
          const { transportationBooking } = values;
          const bookingSettings: BusBookingSettings = {
            pickupLocation: {
              code: transportationBooking?.from?.metadata?.code,
              name: transportationBooking?.from?.metadata?.name,
              nameEn: transportationBooking?.from?.metadata?.nameEn,
            },
            dropOffLocation: {
              code: transportationBooking?.to?.metadata?.code,
              name: transportationBooking?.to?.metadata?.name,
              nameEn: transportationBooking?.to?.metadata?.nameEn,
            },
            departureDate: dayjs(transportationBooking?.departureDate).toISOString(),
            returnDate: transportationBooking?.isRoundTrip
              ? dayjs(transportationBooking?.returnDate).toISOString()
              : undefined,
            departureNote: transportationBooking?.departureNote,
            returnNote: transportationBooking?.returnNote,
            isRoundTrip: transportationBooking?.isRoundTrip,
          };
          const allowanceTransportationBooking = values?.allowances?.find(
            (item) => item?.type === ALLOWANCE_TYPE.BOOKING_BUS
          );
          if (allowanceTransportationBooking) {
            body.travel.expenseBookings.push({
              expenseCategoryId: allowanceTransportationBooking.expenseCategoryId,
              totalAmount: allowanceTransportationBooking?.amount || 0,
              transportationBooking: bookingSettings,
            });
          }
        }
        if (values?.hotelBooking?.isOn) {
          const { hotelBooking, hotelBookingDate } = values;
          const bookingSettings: HotelBookingSettings = {
            location: {
              code: hotelBooking?.location?.metadata?.code,
              name: hotelBooking?.location?.metadata?.name,
              nameEn: hotelBooking?.location?.metadata?.nameEn,
            },
            checkInDate: dayjs(hotelBookingDate?.fromDate).startOf("day").toISOString(),
            checkOutDate: dayjs(hotelBookingDate?.toDate).endOf("day").toISOString(),
            note: hotelBooking?.note,
            companyHotelCode: values.hotelBooking.companyHotelCode,
            hotelName: values.hotelBooking.hotelName,
            numberOfRooms: values.hotelBooking.numberOfRooms,
            roomRate: values.hotelBooking.roomRate,
          };
          const allowanceHotelBooking = values?.allowances?.find((item) => item?.type === ALLOWANCE_TYPE.BOOKING_HOTEL);
          if (allowanceHotelBooking) {
            body.travel.expenseBookings.push({
              expenseCategoryId: allowanceHotelBooking?.expenseCategoryId,
              totalAmount: allowanceHotelBooking?.amount || 0,
              hotelBooking: bookingSettings,
            });
          }
        }
      }
      /*--- end ---*/
      /*--- flow save cash advance ---*/
      if (values?.hasCashAdvance) {
        body.cashAdvances = [
          {
            amount: Number(values?.cashAdvance?.amount),
            foreignAmount: values?.currency?.isOn ? values?.cashAdvance?.foreignAmount : undefined,
            description: values?.cashAdvance?.description,
            paymentMethod: values?.cashAdvance?.paymentMethod,
            paymentInfo:
              values?.cashAdvance?.paymentMethod === PAYMENT_METHOD.BANK_TRANSFER
                ? {
                    accountHolderName: values?.paymentInfo?.accountHolderName,
                    accountNumber: values?.paymentInfo?.accountNumber,
                    bankCode: values?.paymentInfo?.bank?.metadata?.bankCode,
                    bankId: values?.paymentInfo?.bank?.metadata?.bankId,
                    bankName: values?.paymentInfo?.bank?.metadata?.bankName,
                    bankShortName: values?.paymentInfo?.bank?.metadata?.bankShortName,
                    branchName: values?.paymentInfo?.branchName,
                    description: values?.paymentInfo?.description,
                    logoUrl: values?.paymentInfo?.bank?.metadata?.logoUrl,
                    type: values?.paymentInfo?.type,
                  }
                : undefined,
          },
        ];
      }
      const {
        data: { expenseRequest },
      } = await createExpenseRequest(body);

      setDisabledButton(false);
      if (type === ExpenseRequestFormAction.SUBMIT) {
        analyticService.logEvent({ name: EVENT.REQUEST.CREATED_SUCCESS });
        analyticService.logEvent({ name: EVENT.REQUEST.SUBMITTED });
        const { data: submitData } = await submitExpenseRequest(expenseRequest?.expenseRequestId, values.approverId);
        const { success: isSuccess } = submitData?.expenseRequest ?? {};
        if (!isSuccess) {
          throw new Error("create_request_failed");
        }
        ref?.current?.close();
        analyticService.logEvent({
          sessionId: SCREEN_NAME.NewRequestScreen,
          name: EVENT.REQUEST.SUBMITTED_SUCCESS,
          properties: {
            message,
            object_id: expenseRequest?.expenseRequestId,
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            over_budgets: budgetValidate?.map((item) => ({
              expense_category_id: item.expenseCategoryId,
              company_team_id: values?.companyTeam?.companyTeamId,
              over_amount: item.overBudgetAmount,
              expense_date: values?.budgetDate,
            })),
          },
        });
        ToastManager.success(t("send_request_success"));
        setTimeout(() => {
          requestInAppFeedback(FeedbackObjectType.ExpenseRequest);
        }, 1000);
      } else {
        ref?.current?.close();
        analyticService.logEvent({ sessionId: SCREEN_NAME.NewRequestScreen, name: EVENT.REQUEST.SAVED_DRAFT_SUCCESS });
        ToastManager.success(t("create_success"));
      }
      executeRouteFunction(route, "onRefreshData");
      goBack();
    } catch (error: any) {
      setDisabledButton(false);
      const message = [
        SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW,
        SERVER_ERROR_CODE.EXPENSE_UNSUPPORTED_APPROVAL_METHOD,
      ].includes(error?.message)
        ? t("no_approval_flow_error")
        : t("send_request_internal_error");

      const noApprovalFlowError = [
        SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW,
        SERVER_ERROR_CODE.EXPENSE_UNSUPPORTED_APPROVAL_METHOD,
      ].includes(error?.message);
      ref?.current?.error({
        title: t("send_claim_failed"),
        description: message,
        confirmText: noApprovalFlowError ? t("view_approval_flow") : undefined,
        onConfirm: noApprovalFlowError
          ? () => {
              ref?.current?.close();
              (navigation as any).replace(SCREEN_NAME.ApprovalFlowScreen, { index: 0 });
            }
          : undefined,
      });
      analyticService.logEvent({
        name:
          type === ExpenseRequestFormAction.SUBMIT ? EVENT.REQUEST.SUBMITTED_FAILED : EVENT.REQUEST.SAVED_DRAFT_FAILED,
        properties: {
          message,
          error: JSON.stringify(error),
        },
      });
    }
  };

  const handleSubmit = (type: ExpenseRequestFormAction, values: ExpenseRequestFormValues) => {
    setDisabledButton(true);
    // flow save recent company team to storage
    if (values?.companyTeam) {
      saveRecentCompanyTeamToStorage({
        companyId: company?.company_id,
        employeeId: user?.employee_id,
        companyTeam: values?.companyTeam,
      });
    }
    if (type === ExpenseRequestFormAction.SUBMIT) {
      setTimeout(() => {
        setDisabledButton(false);
      }, 1000);
      const budgetValidate = Object.values(values?.overBudgets ?? {}) as OverBudget[];
      if (!isEmpty(budgetValidate)) {
        const isRestrict = budgetValidate.some((item) => item?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT);
        if (isRestrict) {
          analyticService.logEvent({
            name: EVENT.SYSTEM_ALERT.RESTRICT_REQUEST_BUDGET,
            properties: {
              message: t("restrict_budget_request"),
              restriction_type: RESTRICTION_BUDGET_TYPE.RESTRICT,
              object_type: TRACKING_OBJECT_TYPE.REQUEST,
              over_budgets: budgetValidate?.map((item) => ({
                expense_category_id: item?.expenseCategoryId,
                company_team_id: values?.companyTeam?.companyTeamId,
                over_amount: item?.overBudgetAmount,
                expense_date: values?.budgetDate,
              })),
            },
          });
          ref.current.info({
            title: t("budget_amount_warning_title"),
            icon: <BizziBotWarning2 />,
            description: t("restrict_budget_request"),
            confirmText: t("understood"),
            onConfirm: () => {
              ref?.current?.close();
            },
          });
          return;
        }
        analyticService.logEvent({
          name: EVENT.SYSTEM_ALERT.WARNING_REQUEST_BUDGET,
          properties: {
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            message: removeDuplicateObjects(budgetValidate, "message")
              ?.map((item) => item?.message)
              ?.join(".\n"),
            restriction_type: RESTRICTION_BUDGET_TYPE.WARNING,
            over_budgets: budgetValidate?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: values?.companyTeam?.companyTeamId,
              over_amount: item?.overBudgetAmount,
              expense_date: values?.budgetDate,
            })),
          },
        });
        const messageBudgets = removeDuplicateObjects(budgetValidate, "message").map((item) => item?.message);
        ref.current.confirm({
          title: t("budget_amount_warning_title"),
          icon: <BizziBotWarning2 />,
          description: (
            <>
              {messageBudgets?.map((item, index) => (
                <AppText
                  key={index}
                  color={Colors.grayscale80}
                  style={[Fonts.BodyMedium, styles.textCenter]}
                >{`${item}.`}</AppText>
              ))}
              <AppText color={"grayscale80"} style={[Fonts.BodyMedium, styles.textCenter]}>
                {t("budget_amount_warning_context")}
              </AppText>
            </>
          ),
          cancelText: t("go_back"),
          confirmText: t("confirm"),
          onConfirm: () => {
            onCreateRequest({
              type,
              values,
              budgetValidate,
              message: `${messageBudgets.join("\n")}\n${t("budget_amount_warning_context")}`,
            });
          },
        });
      } else {
        ref.current?.confirm({
          title: t("submit"),
          description: t("submit_confirmation_text"),
          confirmText: t("continue"),
          cancelText: t("cancel"),
          onConfirm: () => {
            onCreateRequest({ type, values });
          },
        });
      }
      return;
    }
    onCreateRequest({ type, values });
  };
  return (
    <View style={styles.container}>
      <ExpenseRequestForm
        shouldShowWalkthrough={shouldShowWalkthrough}
        type={type}
        disabledButton={disabledButton}
        setIsDirty={setIsDirty}
        onSubmit={handleSubmit}
      />
      <AlertNotification ref={ref} />
    </View>
  );
};

export default withTrackingSession(CreateExpenseRequestScreen, SCREEN_NAME.NewRequestScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale05,
  },
  textCenter: {
    textAlign: "center",
  },
});
