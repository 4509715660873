import { StyleSheet, View } from "react-native";
import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { InfoCircleIcon2 } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";

const CannotUpdateExpenseSection = () => {
  const { t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");
  return (
    <View style={styles.container}>
      <InfoCircleIcon2 />
      <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
        {t("cannot_update_expense")}
      </AppText>
    </View>
  );
};
export default CannotUpdateExpenseSection;
const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    backgroundColor: Colors.grayscale0,
    flexDirection: "row",
    gap: 8,
    marginHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 8,
  },
  flex: { flex: 1 },
});
