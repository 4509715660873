// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { useCallback } from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import { ActivityIndicator, Toast } from "@ant-design/react-native";
import { BizziButton, AppText } from "app/components";
import toLower from "lodash/toLower";

import { ScrollView } from "react-native-gesture-handler";

import { useAuth } from "app/contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import Config from "app/config";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { SPACING_DEFAULT } from "constants/Layout";
import { Colors } from "constants/Colors";
import { VerifyCompanyScreenNavigationProp, VerifyCompanyScreenRouteProp } from "navigation/type";
import { useTranslation } from "react-i18next";

interface IVerifyCompanyScreen {
  navigation: VerifyCompanyScreenNavigationProp;
  route: VerifyCompanyScreenRouteProp;
}

const VerifyCompanyScreen: React.FC<IVerifyCompanyScreen> = ({ navigation, route }) => {
  const [loading, setLoading] = React.useState(false);
  const { email, password, ...company } = route?.params ?? {};
  const { setUser } = useAuth();
  const { t } = useTranslation("screens/Auth/VerifyCompanyScreen");
  const { bottom } = useSafeAreaInsets();

  const onSubmit = useCallback(async () => {
    Keyboard.dismiss();
    setLoading(true);
    try {
      const res = await authClient.registerWithCompanyCode({ email, password }, company?.company_code);
      await AsyncStorage.setItem(Keys.token, res.token);
      const user = await authClient.getUser();
      if (Platform.OS !== "web") {
        await SecureStore.setItemAsync(Keys.USERNAME, userObject.email);
        await SecureStore.setItemAsync(Keys.USER_PASSWORD, userObject.password);
        await SecureStore.setItemAsync(Keys.USER_FULLNAME, user?.fullname || "");
      }
      setUser(user);
      Toast.success(t("register_success"));
      setLoading(false);
      navigation.replace(SCREEN_NAME.HomeBottomTabNavigator);
    } catch (error) {
      console.error(error);
      setLoading(false);
      Toast.fail(t(`${toLower(error)}`));
    }
  }, [email, password, company, navigation, t, setUser]);

  return (
    <View style={styles.mainContainer}>
      <ScrollView keyboardDismissMode="on-drag" showsVerticalScrollIndicator={false}>
        <View style={styles.container}>
          <View style={styles.header}>
            <AppText style={styles.headerTitle}>{t("company_title")}</AppText>
          </View>
          <View style={styles.body}>
            <View style={styles.row}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("company_name")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.company_name}</AppText>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("tax_code")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.tax_code}</AppText>
              </View>
            </View>

            <View style={styles.row}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("company_code")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.company_code}</AppText>
              </View>
            </View>

            <View style={styles.row}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("address")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.address}</AppText>
              </View>
            </View>

            <View style={[styles.row, styles.noborderBottom]}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("accountant_email")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.accountant_email}</AppText>
              </View>
            </View>
          </View>

          <View style={styles.header}>
            <AppText style={styles.headerTitle}>{t("employee_title")}</AppText>
          </View>

          <View style={styles.body}>
            <View style={styles.row}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("employee_name")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.fullname}</AppText>
              </View>
            </View>

            <View style={styles.row}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("phone_number")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.phone}</AppText>
              </View>
            </View>

            <View style={styles.row}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("department")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{company.department}</AppText>
              </View>
            </View>

            <View style={[styles.row, styles.noborderBottom]}>
              <View style={styles.itemTitle}>
                <AppText style={styles.titleStyle}>{t("email_to_receive_invoice")}</AppText>
              </View>
              <View style={styles.itemContent}>
                <AppText style={styles.contentTextStyle}>{`${company.phone}@${Config.EXPENSE_EMAIL_SUFFIX}`}</AppText>
              </View>
            </View>
          </View>
          <ActivityIndicator animating={loading} toast size="large" />
        </View>
      </ScrollView>
      <View style={[styles.wrapperInput, { paddingBottom: SPACING_DEFAULT + bottom }]}>
        <BizziButton disabled={loading} onPress={onSubmit} loading={loading} title={t("button_finish")} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingBottom: 40,
  },
  header: {
    marginTop: SPACING_DEFAULT,
    paddingHorizontal: SPACING_DEFAULT,
  },
  headerTitle: {
    color: Colors.Ink500,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "500",
  },
  body: {
    marginTop: SPACING_DEFAULT,

    backgroundColor: Colors.white500,
    paddingHorizontal: SPACING_DEFAULT,
  },
  wrapperInput: {
    backgroundColor: Colors.white500,
    paddingHorizontal: SPACING_DEFAULT,
    paddingTop: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingVertical: 15,
    borderBottomColor: Colors.Ink100,
    borderBottomWidth: 1,
    // justifyContent: "flex-start",
  },
  itemTitle: {
    flex: 1,
  },
  itemContent: {
    flex: 2,
  },
  contentTextStyle: {
    textAlign: "right",
    color: Colors.Ink500,
    fontSize: 14,
    lineHeight: 20,
  },
  titleStyle: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.Ink300,
  },
  noborderBottom: { borderBottomWidth: 0 },
});

export { VerifyCompanyScreen };

export default VerifyCompanyScreen;
