import React, { FC, ReactElement, ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import BizziBotGrinSweat from "assets/images/svg/BizziBotGrinSweat";
import { AppText } from "components/index";
import { CONSTANTS } from "constants/constants";
import { Fonts } from "theme";

interface EmptyDataProps {
  icon?: ReactElement;
  title?: string;
  description?: string;
  style?: StyleProp<ViewStyle>;
  button?: ReactNode;
}

const EmptyData: FC<EmptyDataProps> = ({ style, icon = <BizziBotGrinSweat />, title, description, button }) => {
  return (
    <View style={[styles.emptyContainer, style]}>
      {icon}
      <View style={styles.emptyTextContainer}>
        {title && <AppText style={[Fonts.H400, styles.textCenter]}>{title}</AppText>}
        <AppText style={[Fonts.BodyMedium, styles.descriptionEmpty]}>{description}</AppText>
        {button}
      </View>
    </View>
  );
};
export default EmptyData;

const styles = StyleSheet.create({
  emptyContainer: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 60,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  textCenter: { textAlign: "center", paddingHorizontal: 6 },
  descriptionEmpty: { textAlign: "center", marginTop: 10, paddingHorizontal: 6 },
  emptyTextContainer: { marginTop: 25 },
});
