import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { Colors } from "theme";

function SvgComponent({ size = 24, fill: color = Colors.grayscale60, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M21 12C21 16.968 16.968 21 12 21C7.032 21 3.999 15.996 3.999 15.996M3.999 15.996H8.067M3.999 15.996V20.496M3 12C3 7.032 6.996 3 12 3C18.003 3 21 8.004 21 8.004M21 8.004V3.504M21 8.004H17.004"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
