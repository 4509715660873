import { AppText, ButtonInput, SwitchButton, TextInputCustom, useSetRelativePosition } from "components";
import SCREEN_NAME from "navigation/ScreenName";
import React, { FC, useRef, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, View } from "react-native";
import { Control, UseFormSetValue } from "react-hook-form";
import { ExpenseFormValues, LOCATION_TYPE } from "screens/Expense/components/ExpenseForm/types";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { useMasterData } from "contexts/MasterDataContext";
import { startLayoutAnimation } from "utils";

interface LocationInfoSectionProps {
  control: Control<ExpenseFormValues>;
  setValue: UseFormSetValue<ExpenseFormValues>;
  errors: FieldErrors<ExpenseFormValues>;
  watch?: UseFormWatch<ExpenseFormValues>;
  setPosition?: (offsetY: number) => void;
  containerRef?: RefObject<any>;
  expenseReportId?: string;
}
const LocationInfoSection: FC<LocationInfoSectionProps> = ({
  control,
  containerRef,
  setValue,
  errors,
  watch,
  setPosition,
  expenseReportId,
}) => {
  const childRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef, setPosition });

  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const navigation = useNavigation<any>();
  const currentType = watch("placeDetails.type");
  const { setting } = useMasterData();
  const isRequiredLocation = setting?.requiredLocation;
  const needValidatedRequiredLocation =
    (isRequiredLocation && expenseReportId) || currentType === LOCATION_TYPE.AVAILABLE;

  const enableToggleLocation = !isRequiredLocation;
  const handleToggleSection = (isOn: boolean) => {
    startLayoutAnimation();
    setValue("placeDetails.type", isOn ? LOCATION_TYPE.AVAILABLE : LOCATION_TYPE.NONE);
  };

  return (
    <View style={styles.container} ref={childRef}>
      <View style={styles.header}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("location_info")}</AppText>
        {enableToggleLocation && (
          <SwitchButton value={currentType === LOCATION_TYPE.AVAILABLE} onValueChange={handleToggleSection} />
        )}
      </View>
      {isRequiredLocation || currentType === LOCATION_TYPE.AVAILABLE ? (
        <View style={styles.content}>
          <ButtonInput
            name="placeDetails.location_name"
            control={control}
            label={t("provider_placeholder")}
            placeholder={t("provider_placeholder")}
            error={errors?.placeDetails?.location_name}
            onPress={() => {
              navigation.navigate(SCREEN_NAME.SuggestionAddressScreen, {
                callback: (item: any) => {
                  if (item?.placeDetails) {
                    setValue(
                      "placeDetails",
                      { ...item.placeDetails, type: LOCATION_TYPE.AVAILABLE },
                      { shouldValidate: true }
                    );
                  }
                },
              });
            }}
            rules={needValidatedRequiredLocation ? { required: t("required") } : {}}
          />
          <TextInputCustom
            control={control}
            name="placeDetails.location_address"
            label={t("address_placeholder")}
            placeholder={t("address_placeholder")}
            multiline
            error={errors?.placeDetails?.location_address}
            rules={needValidatedRequiredLocation ? { required: t("required") } : {}}
          />
        </View>
      ) : null}
    </View>
  );
};
export default LocationInfoSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.while,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
    gap: 16,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    gap: 16,
  },
});
