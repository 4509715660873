import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M8.852 17.825l3.15-1.9 3.15 1.924-.825-3.6 2.775-2.4-3.65-.325-1.45-3.4-1.45 3.375-3.65.325 2.775 2.425-.825 3.575zm3.15-.137l-3.89 2.346a.72.72 0 01-.436.107.79.79 0 01-.396-.147.84.84 0 01-.273-.332.647.647 0 01-.03-.453l1.032-4.417-3.434-2.973a.737.737 0 01-.245-.388.702.702 0 01.027-.428.845.845 0 01.233-.34.807.807 0 01.415-.171l4.533-.396 1.76-4.171a.68.68 0 01.291-.345.819.819 0 01.825 0 .68.68 0 01.291.345l1.76 4.17 4.533.397a.806.806 0 01.415.172.845.845 0 01.233.34.701.701 0 01.028.427.737.737 0 01-.246.388l-3.434 2.973 1.032 4.417a.647.647 0 01-.03.453.84.84 0 01-.273.332.791.791 0 01-.395.147.72.72 0 01-.436-.107l-3.89-2.346z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
