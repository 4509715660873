// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { Keys } from "app/constants/Keys";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as LocalAuthentication from "expo-local-authentication";
import { Platform } from "react-native";

const useEnrolledLocalAuth = () => {
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);

  const checkEnrolled = useCallback(async () => {
    const results = await AsyncStorage.getItem(Keys.FLAG_TURN_ON_LOCAL_AUTH);

    setIsEnrolled(!!results);
  }, []);

  useEffect(() => {
    checkEnrolled();
  }, []);

  const isValidLocalAuth = useCallback(async () => {
    setIsShowWarning(false);
    try {
      const isBiometricAvailable = await LocalAuthentication.hasHardwareAsync();

      let supportedBiometrics;
      if (isBiometricAvailable) {
        supportedBiometrics = await LocalAuthentication.supportedAuthenticationTypesAsync();
      }

      const savedBiometrics = await LocalAuthentication.isEnrolledAsync();

      if (!savedBiometrics) {
        setIsShowWarning(true);
        return false;
      }

      const isAuthenticated = await LocalAuthentication.authenticateAsync({
        disableDeviceFallback: Platform.OS !== "android",
      });

      if (isAuthenticated.success) {
        return true;
      }

      return false;
    } catch (error) {
      // Alert.alert("error", JSON.stringify(error));
      return false;
    }
  }, []);

  const removeLocalAuth = useCallback(async () => {
    await AsyncStorage.removeItem(Keys.FLAG_TURN_ON_LOCAL_AUTH);
    // await SecureStore.deleteItemAsync(Keys.USERNAME);
    // await SecureStore.deleteItemAsync(Keys.USER_PASSWORD);
    checkEnrolled();
  }, [checkEnrolled]);

  const onEnrolled = useCallback(async () => {
    // Alert.alert("ttestt", "123");
    const isValid = await isValidLocalAuth();

    if (isValid) {
      await AsyncStorage.setItem(Keys.FLAG_TURN_ON_LOCAL_AUTH, "true");
      checkEnrolled();
    }
  }, [checkEnrolled, isValidLocalAuth]);

  return {
    isEnrolled,
    onEnrolled,
    isValidLocalAuth,
    removeLocalAuth,
    isShowWarning,
    refreshCheckingEnrolled: checkEnrolled,
  };
};

export default useEnrolledLocalAuth;
