import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MobileBatchRejectRequestsMutation, MobileBatchRejectRequestsMutationVariables } from "types";

const BATCH_REJECT_REQUEST = gql`
  mutation MobileBatchRejectRequests($requestIds: [UUID!]!, $reason: String) {
    expenseRejectRequests(request_ids: $requestIds, reason: $reason) {
      requestId
      success
      message
      status
    }
  }
`;
const useBatchRejectRequests = () =>
  useMutation<MobileBatchRejectRequestsMutation, MobileBatchRejectRequestsMutationVariables>(BATCH_REJECT_REQUEST);

export default useBatchRejectRequests;
