// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileExpenseRequestDetailQuery, MobileExpenseRequestDetailQueryVariables } from "types/generated";
import { useMemo } from "react";
import { useAuth } from "contexts/AuthContext";
import { useIsFeatureEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { ExpenseRequestFormValues, HOTEL_BOOKING_TYPE } from "screens/ExpenseRequest/types";
import { v4 } from "uuid";
import { ACCOUNT_TYPES, ALLOWANCE_TYPE, BOOKING_TYPE, PAYMENT_METHOD } from "constants/constants";
import { getTitleFromLocation, isForeignCurrency } from "utils";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";

const GET_DETAIL_REQUEST_BY_ID = gql`
  query MobileExpenseRequestDetail($expenseRequestId: UUID!) {
    expenseRequest: expExpenseRequestDetail(expenseRequestId: $expenseRequestId) {
      note
      exchangeRate
      currency
      title
      expenseRequestId
      status
      budgetDate
      expenseRequestType
      description
      expenseRequestAttachments {
        type
        fileUrl
        fileName
        expenseAttachmentId
        fileSize
        fileType
      }
      expenseTravels {
        passengers {
          fullname
        }
        fromLocationId
        toLocationId
        expenseBookings {
          bookingType
          expenseCategoryId
          totalAmount
          bookingSettings {
            ... on ExpHotelBooking {
              location {
                code
                name
                nameEn
              }
              checkInDate
              checkOutDate
              note
              companyHotelCode
              hotelName
              numberOfRooms
              roomRate
            }
            ... on ExpTransportationBooking {
              pickupLocation {
                code
                name
                nameEn
              }
              dropOffLocation {
                code
                name
                nameEn
              }
              isRoundTrip
              departureDate
              returnDate
              departureNote
              returnNote
            }
            ... on ExpFlightBooking {
              departureAirport {
                code
                name
                nameEn
                location {
                  code
                  name
                  nameEn
                }
              }
              arrivalAirport {
                code
                name
                nameEn
                location {
                  code
                  name
                  nameEn
                }
              }
              isRoundTrip
              departureDate
              returnDate
              departureNote
              returnNote
              vendorName
            }
          }
        }
        fromLocation {
          code
          name
          nameEn
          parent {
            code
            name
            nameEn
          }
        }
        toLocation {
          code
          name
          nameEn
          parent {
            code
            name
            nameEn
          }
        }
        perDiemAmount
        fromDate
        toDate
        expenseAllowances: expenseAllowancesV2 {
          foreignAmount
          expenseAllowanceId
          expenseTravelId
          expenseRequestId
          expenseCategoryId
          description
          amount
          accountCode
          currency
          allowanceType
          customFields
          expenseCategory {
            expenseCategoryId
            title
            titleEn
          }
        }
      }
      expenseAllowances {
        foreignAmount
        allowanceType
        customFields
        expenseAllowanceId
        expenseTravelId
        expenseRequestId
        expenseCategoryId
        description
        amount
        accountCode
        currency
        expenseCategory {
          title
          title_en: titleEn
        }
        productName
        quantity
        unitPrice
        uom
      }
      expenseRequestsTeams {
        companyTeam {
          companyTeamId
          name
          nameEn
          code
          nameEn
        }
      }
      cashAdvances {
        foreignAmount
        amount
        cashAdvanceId
        createdAt
        description
        expenseRequestId
        paymentInfo {
          accountHolderName
          accountNumber
          bankCode
          bankId
          bankName
          bankShortName
          branchName
          description
          logoUrl
          type
        }
        paymentMethod
        status
        updatedAt
      }
    }
  }
`;

const useExpenseRequestDetailQuery = (expenseRequestId: string) => {
  const FEATURE_TEAM_MGMT = useIsFeatureEnabled(FEATURE_FLAGS.FEATURE_TEAM_MGMT);
  const { data: rawData, loading } = useQuery<
    MobileExpenseRequestDetailQuery,
    MobileExpenseRequestDetailQueryVariables & { includeTeam: boolean }
  >(GET_DETAIL_REQUEST_BY_ID, {
    variables: { expenseRequestId, includeTeam: FEATURE_TEAM_MGMT },
  });

  const { user, groupId, company } = useAuth();
  const data: ExpenseRequestFormValues = useMemo(() => {
    if (!rawData) {
      return undefined;
    }
    const {
      expenseRequest: {
        title,
        status,
        description,
        budgetDate,
        expenseRequestsTeams,
        expenseRequestType,
        expenseTravels,
        expenseAllowances,
        expenseRequestAttachments,
        cashAdvances,
        currency,
        exchangeRate,
        note,
      },
    } = rawData;
    const paymentInfo = cashAdvances?.[0]?.paymentInfo;
    const flightBooking = expenseTravels?.[0]?.expenseBookings?.find(
      (item) => item?.bookingType === BOOKING_TYPE.FLIGHT
    );
    const transportationBooking = expenseTravels?.[0]?.expenseBookings?.find(
      (item) => item?.bookingType === BOOKING_TYPE.TRANSPORTATION
    );
    const hotelBooking = expenseTravels?.[0]?.expenseBookings?.find((item) => item?.bookingType === BOOKING_TYPE.HOTEL);
    const passengers = expenseTravels?.[0]?.passengers?.map((item) => ({ fullname: item.fullname }));
    const numberOfPassengers = expenseTravels?.[0]?.passengers?.length || 1;
    return {
      hasCashAdvance: Boolean(cashAdvances?.[0]),
      cashAdvance: {
        amount: cashAdvances?.[0]?.amount,
        description: cashAdvances?.[0]?.description,
        paymentMethod: cashAdvances?.[0]?.paymentMethod || PAYMENT_METHOD.BANK_TRANSFER,
        foreignAmount: cashAdvances?.[0]?.foreignAmount,
      },
      paymentInfo: {
        type: (paymentInfo?.type as ACCOUNT_TYPES) || ACCOUNT_TYPES.PERSONAL,
        bank: paymentInfo?.bankId
          ? {
              key: paymentInfo?.bankId,
              label: `${paymentInfo?.bankShortName} (${paymentInfo?.bankCode})`,
              metadata: {
                bankCode: paymentInfo?.bankCode,
                bankId: paymentInfo?.bankId,
                bankName: paymentInfo?.bankName,
                bankShortName: paymentInfo?.bankShortName,
                logoUrl: paymentInfo?.logoUrl,
              },
            }
          : null,
        accountNumber: paymentInfo?.accountNumber,
        accountHolderName: paymentInfo?.accountHolderName,
        bankName: paymentInfo?.bankName,
        branchName: paymentInfo?.branchName,
        description: paymentInfo?.description,
      },
      status,
      expenseRequestType: expenseRequestType as ExpenseRequestType,
      title: title,
      companyTeam: expenseRequestsTeams?.[0]?.companyTeam
        ? {
            companyTeamId: expenseRequestsTeams?.[0]?.companyTeam?.companyTeamId,
            code: expenseRequestsTeams?.[0]?.companyTeam?.code,
            name: expenseRequestsTeams?.[0]?.companyTeam?.name,
            nameEn: expenseRequestsTeams?.[0]?.companyTeam?.nameEn,
          }
        : null,
      budgetDate: budgetDate,
      description: description,
      ...(expenseRequestType === "travel" && {
        travel: {
          fromCity: {
            label: getTitleFromLocation(expenseTravels?.[0]?.fromLocation),
            metadata: {
              ...expenseTravels?.[0]?.fromLocation,
              locationId: expenseTravels?.[0]?.fromLocationId,
            },
          },
          toCity: {
            label: getTitleFromLocation(expenseTravels?.[0]?.toLocation),
            metadata: {
              ...expenseTravels?.[0]?.toLocation,
              locationId: expenseTravels?.[0]?.toLocationId,
            },
          },
          perDiem: expenseTravels?.[0]?.expenseAllowances?.find(
            (item) => item?.allowanceType === ALLOWANCE_TYPE.PERDIEM && !item?.expenseCategoryId
          )?.amount,
        },
        travelDate: {
          fromDate: expenseTravels?.[0]?.fromDate,
          toDate: expenseTravels?.[0]?.toDate,
        },
      }),
      allowances: [
        ...((expenseRequestType === "travel" ? expenseTravels?.[0]?.expenseAllowances : expenseAllowances) ?? []),
      ]
        .filter((item) => item?.expenseCategoryId)
        .map((allowance) => {
          const bookingType = allowance?.customFields?.booking_type;
          let allowanceType = ALLOWANCE_TYPE.USER_REQUEST;
          if (bookingType === BOOKING_TYPE.FLIGHT) {
            allowanceType = ALLOWANCE_TYPE.BOOKING_FLIGHT;
          } else if (bookingType === BOOKING_TYPE.HOTEL) {
            allowanceType = ALLOWANCE_TYPE.BOOKING_HOTEL;
          } else if (bookingType === BOOKING_TYPE.TRANSPORTATION) {
            allowanceType = ALLOWANCE_TYPE.BOOKING_BUS;
          } else if (allowance?.customFields?.allowance_type) {
            allowanceType = allowance.customFields.allowance_type;
          }
          if (
            [ALLOWANCE_TYPE.BOOKING_FLIGHT, ALLOWANCE_TYPE.BOOKING_BUS, ALLOWANCE_TYPE.PERDIEM].includes(allowanceType)
          ) {
            allowance.amount = allowance.amount / numberOfPassengers;
            allowance.foreignAmount = allowance.foreignAmount / numberOfPassengers;
          }

          return {
            id: v4(), // fake id field unique to set key, add, delete, edit allowances
            accountCode: allowance.accountCode,
            amount: allowance.amount,
            foreignAmount: allowance.foreignAmount,
            description: allowance.description,
            expenseCategoryId: allowance.expenseCategoryId,
            expenseCategory: allowance.expenseCategory,
            type: allowanceType,
            productName: allowance.productName,
            quantity: allowance.quantity,
            unitPrice: allowance.unitPrice,
            uom: allowance.uom,
          };
        }),
      attachments: (expenseRequestAttachments ?? []).map((attachment) => ({
        id: attachment.expenseAttachmentId,
        uri: attachment.fileUrl,
        name: attachment.fileName,
        type: attachment.fileType,
        fileType: attachment.fileType,
      })),
      flightBooking: !flightBooking
        ? { isEnabled: false, isOn: false, isRoundTrip: true }
        : {
            isEnabled: true,
            isOn: true,
            vendorName: flightBooking?.bookingSettings?.vendorName,
            from: {
              location: {
                code: flightBooking?.bookingSettings?.departureAirport?.location?.code,
                name: flightBooking?.bookingSettings?.departureAirport?.location?.name,
                nameEn: flightBooking?.bookingSettings?.departureAirport?.location?.nameEn,
              },
              code: flightBooking?.bookingSettings?.departureAirport?.code,
              name: flightBooking?.bookingSettings?.departureAirport?.name,
              nameEn: flightBooking?.bookingSettings?.departureAirport?.nameEn,
            },
            to: {
              location: {
                code: flightBooking?.bookingSettings?.arrivalAirport?.location?.code,
                name: flightBooking?.bookingSettings?.arrivalAirport?.location?.name,
                nameEn: flightBooking?.bookingSettings?.arrivalAirport?.location?.nameEn,
              },
              code: flightBooking?.bookingSettings?.arrivalAirport?.code,
              name: flightBooking?.bookingSettings?.arrivalAirport?.name,
              nameEn: flightBooking?.bookingSettings?.arrivalAirport?.nameEn,
            },
            departureDate: flightBooking?.bookingSettings?.departureDate,
            departureNote: flightBooking?.bookingSettings?.departureNote,
            isRoundTrip: flightBooking?.bookingSettings?.isRoundTrip,
            returnDate: flightBooking?.bookingSettings?.returnDate,
            returnNote: flightBooking?.bookingSettings?.returnNote,
            expenseCategory: {
              expenseCategoryId: flightBooking?.expenseCategoryId,
            },
          },
      transportationBooking: !transportationBooking
        ? { isEnabled: false, isOn: false, isRoundTrip: true }
        : {
            isEnabled: true,
            isOn: true,
            from: {
              label: getTitleFromLocation({
                code: transportationBooking?.bookingSettings?.pickupLocation?.code,
                name: transportationBooking?.bookingSettings?.pickupLocation?.name,
                nameEn: transportationBooking?.bookingSettings?.pickupLocation?.nameEn,
              }),
              metadata: {
                code: transportationBooking?.bookingSettings?.pickupLocation?.code,
                name: transportationBooking?.bookingSettings?.pickupLocation?.name,
                nameEn: transportationBooking?.bookingSettings?.pickupLocation?.nameEn,
              },
            },
            to: {
              label: getTitleFromLocation({
                code: transportationBooking?.bookingSettings?.dropOffLocation?.code,
                name: transportationBooking?.bookingSettings?.dropOffLocation?.name,
                nameEn: transportationBooking?.bookingSettings?.dropOffLocation?.nameEn,
              }),
              metadata: {
                code: transportationBooking?.bookingSettings?.dropOffLocation?.code,
                name: transportationBooking?.bookingSettings?.dropOffLocation?.name,
                nameEn: transportationBooking?.bookingSettings?.dropOffLocation?.nameEn,
              },
            },
            isRoundTrip: transportationBooking?.bookingSettings?.isRoundTrip || false,
            departureDate: transportationBooking?.bookingSettings?.departureDate,
            departureNote: transportationBooking?.bookingSettings?.departureNote,
            returnDate: transportationBooking?.bookingSettings?.returnDate,
            returnNote: transportationBooking?.bookingSettings?.returnNote,
            expenseCategory: {
              expenseCategoryId: transportationBooking?.expenseCategoryId,
            },
          },
      hotelBooking: !hotelBooking
        ? { isEnabled: false, isOn: false }
        : {
            isEnabled: true,
            isOn: true,
            location: {
              label: getTitleFromLocation(hotelBooking?.bookingSettings?.location),
              metadata: {
                code: hotelBooking?.bookingSettings?.location?.code,
                name: hotelBooking?.bookingSettings?.location?.name,
                nameEn: hotelBooking?.bookingSettings?.location?.nameEn,
              },
            },
            note: hotelBooking?.bookingSettings?.note,
            expenseCategory: {
              expenseCategoryId: hotelBooking?.expenseCategoryId,
            },

            hotelType: hotelBooking?.bookingSettings?.companyHotelCode
              ? HOTEL_BOOKING_TYPE.INTERNAL
              : HOTEL_BOOKING_TYPE.EXTERNAL,
            companyHotelCode: hotelBooking?.bookingSettings?.companyHotelCode,
            hotelName: hotelBooking?.bookingSettings?.hotelName,
            numberOfRooms: hotelBooking?.bookingSettings?.numberOfRooms,
            roomRate: hotelBooking?.bookingSettings?.roomRate,
          },
      hotelBookingDate: {
        fromDate: dayjs(hotelBooking?.bookingSettings?.checkInDate).toDate(),
        toDate: dayjs(hotelBooking?.bookingSettings?.checkOutDate).toDate(),
      },
      currency: isForeignCurrency(currency) ? { isOn: true, name: currency, exchangeRate } : undefined,
      note,
      // passengers info
      enablePassenger: !isEmpty(passengers),
      passengers: !isEmpty(passengers) ? passengers : undefined,
    };
  }, [FEATURE_TEAM_MGMT, company.company_id, groupId, rawData, user.employee_id]);

  return { data, loading };
};

export default useExpenseRequestDetailQuery;
