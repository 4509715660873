// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import ScreenName from "navigation/ScreenName";
import { FC, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";

import { ArrowRightOutlinedIcon } from "assets/images/svg/icons";
import ExpenseReportItemIcon from "assets/images/svg/icons/ExpenseReportItemIcon";
import { AllocatedStatus, AppText, CurrencyText, TouchableOpacityCustom } from "components";
import StatusExpenseReport from "components/ExpenseReportItem/StatusExpenseReport";
import { COMPANIES } from "constants/Companies";
import ReportStatus from "constants/ReportStatus";
import { CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import { ExpenseDetailScreenNavigationProp } from "navigation/type";
import { Colors, Fonts } from "theme";
import { EVENT } from "constants/Tracking";

const INVALID_INVOICE_STATUS = 1000;

interface ExpenseItemProps {
  item: {
    note?: string;
    provider?: string;
    category?: string;
    status: number;
    overrideStatus: number;
    expenseId: string;
    invoiceId?: string;
    totalAmountWithVat: number;
    expenseDate: string;
    receivedInvoice?: boolean;
    expenseCategory?: { title: string; expenseCategoryId: string };
    docSequence?: string;
  };
  onRefreshData?: () => void;
}

const ExpenseReportItem: FC<ExpenseItemProps> = ({ item, onRefreshData }) => {
  const { t } = useTranslation("app/components/ExpenseReportItem");
  const navigation = useNavigation<ExpenseDetailScreenNavigationProp>();
  const handleClickItem = () => {
    navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, { invoiceId: invoiceId, expenseId: expenseId, onRefreshData });
  };
  const onUpdateExpense = useCallback(() => {
    navigation.navigate(ScreenName.EditExpenseScreen, { expenseId, onRefreshData });
  }, [expenseId, navigation]);

  const { company } = useAuth();
  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );

  const { invoice, expenseReport, docSequence } = item;

  const { note, totalAmountWithVat, invoiceId, expenseId, expenseDate, status } = useMemo(() => {
    const isValidInvoice =
      invoice?.invoiceValidations?.[0]?.isLegalSeller === 1 &&
      invoice?.invoiceValidations?.[0]?.isCorrectCompany === 1 &&
      invoice?.invoiceValidations?.[0]?.validationAggregation?.status === "success";
    return {
      ...item,
      status:
        isTLNExpenseCompany &&
        expenseReport?.status === ReportStatus.SUBMITTED &&
        Boolean(invoice?.invoiceId) &&
        !isValidInvoice
          ? INVALID_INVOICE_STATUS
          : expenseReport?.status,
    };
  }, [invoice?.invoiceValidations, invoice?.invoiceId, item, isTLNExpenseCompany, expenseReport?.status]);

  return (
    <>
      <TouchableOpacityCustom
        eventName={EVENT.EXPENSE.TAP_VIEW_DETAIL}
        style={styles.container}
        onPress={handleClickItem}
      >
        <ExpenseReportItemIcon />
        <View style={styles.contentContainer}>
          {item?.expenseCardTransactions?.[0] && (
            <AppText style={styles.expenseFromCardTitle} color={Colors.grayscale60}>
              {t("expense_from_transaction")}
            </AppText>
          )}
          <View style={styles.titleContainer}>
            <AppText style={[Fonts.BodyMedium, styles.flex, { paddingRight: 5 }]} numberOfLines={1}>
              {note?.trim() || t("need_explanation")}
            </AppText>
            <CurrencyText style={Fonts.NumericN200}>{totalAmountWithVat}</CurrencyText>
          </View>
          <View style={styles.pricingContainer}>
            <AppText style={Fonts.BodySmall}>{docSequence}</AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {dayjs(expenseDate).format(CONSTANTS.FORMAT_DAY)}
            </AppText>
          </View>
          <View style={styles.row}>
            <View style={styles.statusContainer}>
              <StatusExpenseReport value={status} />
              <AllocatedStatus value={item?.allocatedStatus} />
            </View>
            {status === ReportStatus.REJECTED && (
              <TouchableOpacityCustom
                eventName={EVENT.EXPENSE.TAP_UPDATE}
                style={styles.buttonContainer}
                onPress={onUpdateExpense}
              >
                <AppText style={[Fonts.BodyMedium, styles.buttonText]} color={Colors.primary50}>
                  {t("update")}
                </AppText>
                <ArrowRightOutlinedIcon />
              </TouchableOpacityCustom>
            )}
          </View>
        </View>
      </TouchableOpacityCustom>
      <View style={styles.divider} />
    </>
  );
};

export default memo(ExpenseReportItem);
const styles = StyleSheet.create({
  container: {
    gap: 16,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 20,
  },
  contentContainer: {
    flex: 1,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 43,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  titleContainer: { flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", flex: 1 },
  flex: { flex: 1 },
  pricingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginTop: 3,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 4,
    flexWrap: "wrap",
  },
  buttonContainer: {
    marginTop: 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonText: {
    marginRight: 2,
  },
  expenseFromCardTitle: { ...Fonts.BodySmall, marginBottom: 3 },
  statusContainer: { flexDirection: "row", gap: 4, marginTop: 4 },
});
