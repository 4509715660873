import { identify, Identify, init, reset, setSessionId, setUserId, track } from "@amplitude/analytics-react-native";
import app from "../../app.json";
import * as FirebaseAnalytics from "expo-firebase-analytics";
import Config from "app/config";
import dayjs from "dayjs";
import { navigationRef } from "navigation/RootNavigation";
import { SCREEN_NAME_TRACKING } from "navigation/ScreenName";
import { Platform } from "react-native";
import { useEffect } from "react";

const appVersion = app.expo.version;
type TrackEvent = { sessionId?: string; name: string; properties?: { [key: string]: unknown } };

class AnalyticService {
  sessionStore: Record<string, any> = {};
  companyId: string;
  constructor() {
    init(Config.AMPLITUDE_API_KEY, null, {
      trackingSessionEvents: true,
      flushQueueSize: 60,
      appVersion,
    });
    setSessionId(new Date().getTime());
  }
  setUser({ id, username, email, fullname, employeeId, companyId, companyName }) {
    this.companyId = companyId;
    /*-- set user for Amplitude analytics --*/
    const user = new Identify();
    user.set("user_id", id);
    user.set("email", email);
    user.set("fullname", fullname);
    user.set("employee_id", employeeId);
    user.set("company_id", companyId);
    user.set("company_name", companyName);
    identify(user);
    setUserId(username);

    /*-- set user for Firebase analytics --*/
    FirebaseAnalytics.setUserId(email);
  }
  logout() {
    reset();
  }
  logEvent({ sessionId, name, properties }: TrackEvent) {
    const screenName = navigationRef.current?.getCurrentRoute()?.name;
    const familiarScreenName = SCREEN_NAME_TRACKING[screenName] || screenName;
    const payload = { current_screen: familiarScreenName, company_id: this.companyId, ...properties } as any;

    const session = sessionId ? this.sessionStore[sessionId] : undefined;
    if (session) {
      payload.spending_time_mili = dayjs().diff(session.start, "millisecond");
    }

    track(name, payload);
    if (Platform.OS !== "web") {
      FirebaseAnalytics.logEvent("screen_view", {
        screen_name: name,
      });
    }
  }
  startSessions(sessionIds: string[]) {
    const start = dayjs();
    sessionIds.forEach((sessionId) => {
      this.sessionStore[sessionId] = { start };
    });
  }
  endSessions(sessionIds: string[]) {
    sessionIds.forEach((sessionId) => {
      delete this.sessionStore[sessionId];
    });
  }
}

export const analyticService = new AnalyticService();

export const withTrackingSession = (Component, screenName) => {
  return (props) => {
    useEffect(() => {
      analyticService.startSessions([screenName]);
      return () => {
        analyticService.endSessions([screenName]);
      };
    }, []);

    return <Component {...props} />;
  };
};
