import React, { FC, memo, useContext, useEffect } from "react";
import { Image, StyleSheet, useWindowDimensions, View } from "react-native";
import { useTranslation } from "react-i18next";
import Modal from "react-native-modal";
import { useCopilot } from "react-native-copilot";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { AppText, Button } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { WelcomeBackground } from "assets/images";
import FeatureManagementContext from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { Keys } from "constants/Keys";

interface WelcomeModalProps {
  checkInformAboutLocalAuth: () => void;
  visible?: boolean;
  setVisible?: (value: boolean) => void;
}
const WelcomeModal: FC<WelcomeModalProps> = ({ checkInformAboutLocalAuth, visible, setVisible }) => {
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { features } = useContext(FeatureManagementContext);
  const { start } = useCopilot();
  const { width } = useWindowDimensions();
  const MODAL_WIDTH = Math.min(width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING, CONSTANTS.COMMON.MODAL_MAX_WIDTH);

  const handleShowWalkthrough = async () => {
    const flagFeatureWalkthrough = features[FEATURE_FLAGS.FEATURE_APP_WALKTHROUGH];
    const isViewWalkthrough = await AsyncStorage.getItem(Keys.FLAG_IS_VIEW_WALKTHROUGH);
    if (flagFeatureWalkthrough?.enabled && !isViewWalkthrough) {
      AsyncStorage.setItem(Keys.FLAG_IS_VIEW_WALKTHROUGH, "true");
      setVisible(true);
    } else {
      checkInformAboutLocalAuth();
    }
  };

  useEffect(() => {
    if (Object.keys(features).length > 0) {
      handleShowWalkthrough();
    }
  }, [features]);

  // useEffect(() => {
  //   const listener = () => {
  //     // check and show face id & touch id authentication modal when finish walkthrough
  //     checkInformAboutLocalAuth();
  //     // only show confetti when it is onboarding walkthrough flow
  //     //console.log("finish walkthrough", currentStep);
  //     if (!currentStep?.singleStep) {
  //       //showConfettiAnimation();
  //     }
  //   };
  //   copilotEvents.on("stop", listener);
  //   return () => {
  //     copilotEvents.off("stop", listener);
  //   };
  // }, [currentStep]);

  const handleSkip = () => {
    setVisible(false);
    checkInformAboutLocalAuth();
  };

  const handleContinue = () => {
    setVisible(false);
    setTimeout(() => {
      start({
        fromStep: CONSTANTS.WALK_THROUGH.FIRST_LAUNCH.Step1.toString(),
        walkthroughName: CONSTANTS.WALK_THROUGH.FIRST_LAUNCH.NAME,
      });
    }, 400);
  };

  return (
    <Modal style={styles.modalStyle} isVisible={visible} animationIn="slideInUp">
      <View style={{ width: MODAL_WIDTH }}>
        <Image source={WelcomeBackground} style={{ width: MODAL_WIDTH, height: MODAL_WIDTH / 2.8 }} />
        <View style={styles.body}>
          <AppText style={[Fonts.H300, styles.welcomeText]}>{t("welcome_to_expense_app")}</AppText>
          <AppText style={[Fonts.BodyMedium, styles.description]}>{t("welcome_description")}</AppText>
          <View style={styles.footerButton}>
            <Button type="secondary" onPress={handleSkip} style={styles.buttonSkip}>
              {t("skip")}
            </Button>
            <Button onPress={handleContinue} style={styles.buttonContinue}>
              {t("next")}
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};
export default memo(WelcomeModal);

const styles = StyleSheet.create({
  modalStyle: { justifyContent: "center", alignItems: "center" },
  body: {
    paddingTop: 16,
    paddingBottom: 20,
    paddingHorizontal: 15,
    backgroundColor: Colors.white,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  footerButton: { flexDirection: "row", justifyContent: "space-between", marginTop: 16 },
  welcomeText: { textAlign: "center" },
  buttonSkip: { flex: 1, marginRight: 6 },
  buttonContinue: { flex: 1, marginLeft: 6 },
  description: { textAlign: "center", marginTop: 16 },
});
