// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import { CopilotProvider } from "react-native-copilot";
import Svg, { Circle, Path, Rect } from "react-native-svg";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import TooltipComponent from "../Walkthrough/TooltipComponent";
import { CONSTANTS } from "constants/constants";
import { WIDTH_WINDOW } from "constants/Layout";
import { AppText, useWindowDimensions } from "components";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
const { width: screenWidth } = Dimensions.get("window");
const ArrowLeftIcon = () => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M4.9025 19.84L11.4225 13.32C12.1925 12.55 12.1925 11.29 11.4225 10.52L4.9025 4"
        stroke="#94A3B8"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.9025 19.84L18.4225 13.32C19.1925 12.55 19.1925 11.29 18.4225 10.52L11.9025 4"
        stroke="#94A3B8"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const ArrowTopComponentIcon = () => (
  <Svg width={22} height={12} viewBox="0 0 22 12" fill="none">
    <Path d="M0 0H22L12.4743 10.3917C11.6816 11.2564 10.3184 11.2564 9.52569 10.3917L0 0Z" fill="white" />
  </Svg>
);
const ArrowBottomComponentIcon = () => (
  <Svg width={22} height={12} viewBox="0 0 22 12" fill="none">
    <Path d="M0 12H22L12.4743 1.60834C11.6816 0.743603 10.3184 0.743602 9.52569 1.60834L0 12Z" fill="white" />
  </Svg>
);
const DividerComponentIcon = () => (
  <Svg width={8} height={22} viewBox="0 0 8 22" fill="none">
    <Circle cx={4} cy={4} r={4} fill="white" />
    <Rect x={3} y={6} width={1.5} height={16} fill="white" />
  </Svg>
);

const roundedRectangleSvgPath = ({ position, canvasSize, size, step }) => {
  if (!step) {
    return null;
  }
  const borderRadius = step.borderRadius || 16;
  // if (
  //   step?.order === CONSTANTS.WALK_THROUGH.OVERVIEW.Step1 ||
  //   step?.order === CONSTANTS.WALK_THROUGH.OVERVIEW.Step2 ||
  //   step?.order === CONSTANTS.WALK_THROUGH.OVERVIEW.Step3 ||
  //   step?.order === CONSTANTS.WALK_THROUGH.OVERVIEW.Step4
  // ) {
  //   borderRadius = 16;
  // }
  const offsetX = step?.offsetX || 0;
  const offsetY = step?.offsetY || 0;

  const sizeX = size.x._value - 2 * borderRadius - offsetX;
  const sizeY = size.y._value - 2 * borderRadius;
  const _sizeX = 0 - sizeX + offsetX;
  const _sizeY = 0 - sizeY;

  if (
    step?.order === CONSTANTS.WALK_THROUGH.OVERVIEW.Step1 &&
    step.walkthroughName === CONSTANTS.WALK_THROUGH.OVERVIEW.NAME
  ) {
    return `M 0 0 H ${canvasSize.x} V ${canvasSize.y} H 0 V 0 Z M ${position.x._value + borderRadius - 5} ${
      position.y._value - 60
    } Z h ${sizeX} a ${borderRadius} ${borderRadius} 0 0 1 ${borderRadius} ${borderRadius} v ${
      sizeY + 60
    } a ${borderRadius} ${borderRadius} 0 0 1 -${borderRadius} ${borderRadius} h ${_sizeX} a ${borderRadius} ${borderRadius} 0 0 1 -${borderRadius} -${borderRadius} v ${
      _sizeY - 60
    } a ${borderRadius} ${borderRadius} 0 0 1 ${borderRadius} -${borderRadius} z`;
  }
  return `M 0 0 H ${canvasSize.x} V ${canvasSize.y} H 0 V 0 Z M ${position.x._value + borderRadius} ${
    position.y._value + offsetY / 2
  } Z h ${sizeX} a ${borderRadius} ${borderRadius} 0 0 1 ${borderRadius} ${borderRadius} v ${sizeY} a ${borderRadius} ${borderRadius} 0 0 1 -${borderRadius} ${borderRadius} h ${_sizeX} a ${borderRadius} ${borderRadius} 0 0 1 -${borderRadius} -${borderRadius} v ${_sizeY} a ${borderRadius} ${borderRadius} 0 0 1 ${borderRadius} -${borderRadius} z`;
};
const CopilotProviderCustom = ({ children }) => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { width } = useWindowDimensions();
  return (
    <CopilotProvider
      arrowSize={10}
      androidStatusBarVisible={false}
      backdropColor={"rgba(15, 23, 42, 0.7)"}
      svgMaskPath={roundedRectangleSvgPath}
      tooltipComponent={TooltipComponent}
      stepNumberComponent={() => null}
      stepIsScroll={[CONSTANTS.WALK_THROUGH.OVERVIEW.Step5]}
      tooltipStyle={[
        styles.tooltipStyle,
        Platform.OS === "web" && { left: (screenWidth - width) / 2 + CONSTANTS.COMMON.CONTAINER_PADDING },
      ]}
      skipComponent={
        <View style={styles.skipButton}>
          <View style={styles.skipIcon}>
            <ArrowLeftIcon />
          </View>
          <AppText style={Fonts.BodyLarge} color={Colors.grayscale60}>
            {t("skip")}
          </AppText>
        </View>
      }
      skipComponentStyle={[
        styles.skipComponentStyle,
        { bottom: bottom + 18 },
        Platform.OS === "web" && { right: (screenWidth - WIDTH_WINDOW) / 2 + CONSTANTS.COMMON.CONTAINER_PADDING },
      ]}
      dividerComponent={<DividerComponentIcon />}
      arrowTopComponent={<ArrowTopComponentIcon />}
      arrowBottomComponent={<ArrowBottomComponentIcon />}
    >
      {children}
    </CopilotProvider>
  );
};
export default CopilotProviderCustom;

const styles = StyleSheet.create({
  tooltipStyle: {
    padding: 0,
    width: WIDTH_WINDOW - 40,
    maxWidth: WIDTH_WINDOW - 40,
    left: 20,
    right: 20,
    borderRadius: 16,
  },
  skipButton: { flexDirection: "row", alignItems: "center" },
  skipIcon: { marginRight: 5 },
  skipComponentStyle: { position: "absolute", right: 20 },
});
