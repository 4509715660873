import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.05 19.75c-2.162 0-4.003-.753-5.522-2.258C5.01 15.987 4.251 14.156 4.251 12v-.78l-1.324 1.322a.725.725 0 01-.522.213.707.707 0 01-.531-.213.717.717 0 01-.218-.527c0-.206.073-.382.218-.527l2.494-2.494A.867.867 0 015 8.724c.241 0 .452.09.633.27l2.494 2.495c.139.138.21.312.213.522a.707.707 0 01-.213.531.718.718 0 01-.527.218.718.718 0 01-.526-.218L5.75 11.22V12c0 1.737.612 3.213 1.835 4.428 1.223 1.215 2.712 1.822 4.465 1.822.293 0 .584-.02.874-.06.29-.04.577-.107.86-.204a.879.879 0 01.602.02.81.81 0 01.426.396.678.678 0 01.018.585.664.664 0 01-.443.388c-.377.133-.762.23-1.156.288a8.049 8.049 0 01-1.18.087zm-.1-14c-.292 0-.583.02-.874.06-.29.04-.576.107-.858.203a.901.901 0 01-.61-.02.808.808 0 01-.434-.395.654.654 0 01-.023-.57.646.646 0 01.423-.388 7 7 0 011.175-.295 7.594 7.594 0 011.201-.095c2.163 0 4.004.753 5.523 2.258C18.99 8.013 19.75 9.844 19.75 12v.78l1.324-1.322a.725.725 0 01.522-.213.707.707 0 01.531.213c.145.144.218.32.218.527a.717.717 0 01-.218.527l-2.494 2.494a.867.867 0 01-.633.27.867.867 0 01-.632-.27l-2.494-2.495a.724.724 0 01-.213-.522.707.707 0 01.213-.531.717.717 0 01.527-.218c.206 0 .381.073.526.218l1.323 1.323V12c0-1.737-.611-3.213-1.834-4.428-1.223-1.215-2.712-1.822-4.466-1.822z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
