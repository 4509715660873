// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native";

import AntDesign from "react-native-vector-icons/AntDesign";

import Colors from "constants/Colors";
import { useTranslation } from "react-i18next";

const IconDetail = ({ status, text, ...props }) => {
  const { width } = useWindowDimensions();

  const IS_TABLET = width <= 992;
  if (status === "pass") {
    return (
      <Text style={[styles.statusDetail, { fontSize: IS_TABLET ? 14 : 16 }]}>
        <Text style={[styles.iconStatus, { marginLeft: IS_TABLET ? -6 : -12.5, marginRight: IS_TABLET ? 20 : 40 }]}>
          <AntDesign name="checkcircle" size={IS_TABLET ? 30 : 40} color={Colors.success50} />
        </Text>
        {text}
      </Text>
    );
  }
  if (status === "pending") {
    return (
      <Text style={[styles.statusDetail, styles.pendingText, { fontSize: IS_TABLET ? 14 : 16 }]}>
        <Text
          style={[styles.iconStatus, styles.pendingIcon, { width: IS_TABLET ? 30 : 40, height: IS_TABLET ? 30 : 40 }]}
        >
          {props.children}
        </Text>
        {text}
      </Text>
    );
  }
  return (
    <Text style={[styles.statusDetail, { fontSize: IS_TABLET ? 14 : 16 }]}>
      <Text
        style={[
          styles.iconStatus,
          styles.activeIcon,
          {
            marginLeft: IS_TABLET ? 5 : 0,
            width: IS_TABLET ? 30 : 40,
            height: IS_TABLET ? 30 : 40,
            padding: IS_TABLET ? 1 : 12,
            marginTop: IS_TABLET ? -5 : -10,
          },
        ]}
      >
        {props.children}
      </Text>
      {text}
    </Text>
  );
};

const RegisterStatus = ({ activeStep }) => {
  const { t } = useTranslation("screens/RegisterOnboarding/RegisterStatus");

  return (
    <View style={styles.status}>
      <View style={styles.lineLink} />
      <IconDetail status={activeStep === 1 ? "acitve" : "pass"} text={t("register_account")}>
        1
      </IconDetail>
      <IconDetail
        status={activeStep === 2 ? "acitve" : activeStep === 3 ? "pass" : "pending"}
        text={t("register_company")}
      >
        2
      </IconDetail>
      <IconDetail status={activeStep === 3 ? "active" : "pending"} text={t("invite_colleague")}>
        3
      </IconDetail>
    </View>
  );
};

const styles = StyleSheet.create({
  status: {
    margin: "auto",
  },
  lineLink: {
    alignSelf: "center",
    position: "absolute",
    borderLeftColor: Colors.gray10,
    borderLeftWidth: 1,
    height: 300,
    width: 1,
    zIndex: -1,
    left: 20,
    transform: [{ translateY: 60 }],
  },
  statusDetail: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 24,
    marginVertical: 60,
  },
  iconStatus: {
    // backgroundColor: Colors.black,
    height: 40,
    width: 40,
    borderRadius: 24,
    padding: 12,
    color: Colors.whiteText,

    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 28,

    textAlign: "center",
    marginTop: "-10px",
    paddingTop: "6px",
    marginRight: "20px",
    ...(Platform.OS === "web" && { float: "left" }),
  },
  pendingText: { color: Colors.gray60 },
  pendingIcon: { backgroundColor: Colors.whiteBackground, color: Colors.gray60 },
  activeIcon: { backgroundColor: Colors.blackBackground, color: Colors.whiteText },
});

export default RegisterStatus;
