// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Fonts from "constants/Fonts";
import { WIDTH_WINDOW } from "constants/Layout";
import { ActivityIndicator } from "@ant-design/react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import BizziIcon from "assets/images/svg/icons/BizziIcon";
import { Button, AppText } from "components";
import Colors, { Colors as ExtraColors } from "constants/Colors";
import Layout from "constants/Layout";
import Constants from "expo-constants";

import { OTPInputView } from "layouts";
import { useCallback, useEffect, useRef, useState } from "react";
import { KeyboardAvoidingView, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import * as authClient from "app/utils/authClient";
import SCREEN_NAME from "navigation/ScreenName";
import { LoginOTPScreenNavigationProp, LoginOTPScreenRouteProp } from "navigation/type";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";

const PIN_COUNT = 6;
const TIME_GAP = 20;

export default function LoginOTPScreen() {
  const navigation = useNavigation<LoginOTPScreenNavigationProp>();
  const route = useRoute<LoginOTPScreenRouteProp>();
  const { username, password, challengeId } =
    route?.params ?? ({} as { username: string; password: string; challengeId: string });
  const { login } = useAuth();
  const { t } = useTranslation("screens/Auth/LoginOTPScreen");

  const [hasError, setHasError] = useState(false);
  const [count, setCount] = useState<number>(TIME_GAP);
  const [code, setCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const challengeIdRef = useRef<string>(challengeId);
  const intervalRef = useRef<any>();

  const countDown = useCallback(() => {
    intervalRef.current = setInterval(() => {
      setCount((c) => c - 1);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(countDown, [countDown]);

  useEffect(() => {
    if (count === 0) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, [count, countDown]);

  const handleVerifyOTP = useCallback(async () => {
    if (
      code.length !== PIN_COUNT ||
      [username, password, challengeIdRef.current].some((x) => typeof x === "undefined")
    ) {
      return;
    }

    try {
      await login({
        challengeId: challengeIdRef.current,
        username,
        password,
        answer: code,
      });
    } catch (error) {
      navigation.navigate(SCREEN_NAME.LoginScreen, {
        error: error as any,
        tab: 0,
      });
    }
  }, [code, navigation, password, username, login]);

  const resendOtp = useCallback(async (): Promise<void> => {
    if (count !== 0) {
      return;
    }

    setIsSubmitting(true);
    setHasError(false);

    try {
      const { challenge_id } = await authClient.userRequestMFA(username);
      challengeIdRef.current = challenge_id as string;
      setCount(TIME_GAP);
      countDown();
    } catch (error) {
      setHasError(true);
    }
    setIsSubmitting(false);
  }, [count, username, countDown]);

  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.content}>
            <BizziIcon />

            <View style={styles.form}>
              <AppText style={styles.textTitle}>{t("login_authentication")}</AppText>
              <AppText style={styles.textContent}>
                {t("please_enter")} <AppText style={styles.textBold}>{t("otp")}</AppText>{" "}
                {t("send_to_channel", {
                  mfaChannel: "email",
                })}
              </AppText>

              <OTPInputView
                pinCount={PIN_COUNT}
                autoFocusOnLoad
                codeInputFieldStyle={styles.underlineStyleBase}
                codeInputHighlightStyle={styles.underlineStyleHighLighted}
                onCodeChanged={setCode}
              />

              <Button
                disabled={code.length !== PIN_COUNT || isSubmitting}
                style={styles.confirm}
                onPress={handleVerifyOTP}
              >
                {t("login_authentication")}
              </Button>

              <View style={styles.resendCodeSection}>
                <View style={styles.resendCodeContainer}>
                  <AppText style={styles.resendCodeLabel}>{t("resend_otp_message")}</AppText>
                  <TouchableOpacity onPress={resendOtp}>
                    <AppText style={styles.textActive}>
                      {t("resend", {
                        resendAfter: count
                          ? t("resend_after", {
                              count,
                            })
                          : null,
                      })}
                    </AppText>
                  </TouchableOpacity>
                </View>
                {hasError ? (
                  <View>
                    <AppText style={styles.error}>{t("unexpected_error")}</AppText>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
        {isSubmitting && <ActivityIndicator animating toast size="large" />}
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundWhite,
    flexDirection: "row",
  },
  content: { paddingVertical: 48, paddingHorizontal: 20, width: "100%", display: "flex" },
  form: { width: "100%", alignSelf: "center" },
  textTitle: {
    fontFamily: Fonts.fontFamilyMedium,
    fontSize: WIDTH_WINDOW < 768 ? 30 : 40,
    lineHeight: WIDTH_WINDOW < 768 ? 38 : 48,
    color: Colors.black,
    marginVertical: 16,
    marginTop: 45,
    fontWeight: "500",
  },
  textContent: {
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: 20,
    color: Colors.gray80,
    marginBottom: WIDTH_WINDOW < 768 ? 12 : 48,
  },
  textBold: {
    fontWeight: "bold",
  },
  textActive: {
    fontSize: 16,
    color: ExtraColors.blue500,
    textAlign: "left",
    marginLeft: 3,
    lineHeight: 24,
  },
  underlineStyleBase: {
    width: 80,
    height: 90,
    borderWidth: 0,
    backgroundColor: ExtraColors.white500,
    borderRadius: 8,
  },
  underlineStyleHighLighted: {
    borderBottomColor: ExtraColors.blue500,
  },
  confirm: {
    marginTop: 24,
  },
  resendCodeSection: {
    flex: 1,
    flexDirection: "column",
    paddingTop: 40,
  },
  resendCodeContainer: {
    flex: 1,
    flexDirection: "row",
  },
  resendCodeLabel: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray100,
  },
  error: {
    fontSize: 14,
    color: Colors.warningColor,
    textAlign: "left",
    marginTop: 14,
  },
});
