import Svg, { Rect, Path } from "react-native-svg";

function SvgComponent({ width = 56, height = 56, color = "#FFFFFF" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 56 56" fill="none">
      <Rect width={width} height={height} rx={28} fill="#37B24D" />
      <Path
        d="M33.067 14.947c-.547-.546-1.494-.173-1.494.587v4.653c0 1.947 1.654 3.56 3.667 3.56 1.267.014 3.027.014 4.533.014.76 0 1.16-.894.627-1.427-1.92-1.933-5.36-5.413-7.333-7.387z"
        fill={color}
      />
      <Path
        d="M39.333 25.588H35.48a5.742 5.742 0 01-5.734-5.733V16c0-.733-.6-1.333-1.333-1.333H22.76c-4.107 0-7.427 2.667-7.427 7.427v11.813c0 4.76 3.32 7.427 7.427 7.427h10.48c4.106 0 7.426-2.667 7.426-7.427v-6.987c0-.733-.6-1.333-1.333-1.333zm-12 10.08H22c-.547 0-1-.453-1-1 0-.547.453-1 1-1h5.333c.547 0 1 .453 1 1 0 .547-.453 1-1 1zM30 30.335h-8c-.547 0-1-.454-1-1 0-.547.453-1 1-1h8c.546 0 1 .453 1 1 0 .546-.454 1-1 1z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
