import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgComponent({ width = 24, height = 24 }: SvgProps) {
  return (
    <Svg width={width} height={height} fill="none">
      <Path
        fill="#fff"
        d="M8.578 3.425h5.25l1.75 1.75h3.5a.875.875 0 0 1 .875.875V18.3a.875.875 0 0 1-.875.875H3.328a.875.875 0 0 1-.875-.875V6.05a.875.875 0 0 1 .875-.875h3.5l1.75-1.75Zm2.625 14a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Zm0-1.75a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"
      />
    </Svg>
  );
}

export default SvgComponent;
