import React, { FC } from "react";
import { View, ViewStyle, StyleSheet } from "react-native";

import { Colors } from "theme";
import { LineChart } from "react-native-chart-kit";

type ThumbnailLineChartProps = {
  width: number;
  height: number;
  data: number[];
};

const scale = 2;
const ThumbnailLineChart: FC<ThumbnailLineChartProps> = (props) => {
  const { width, height, data } = props;

  const containerWidth = scale * width;
  const svgHeight = scale * height;
  const svgWidth = scale * width;

  const containerStyle: ViewStyle = {
    width: containerWidth,
    position: "absolute",
    transform: [{ scale: 1 / scale }, { translateY: -height }, { translateX: -width }],
  };

  return (
    <View style={{ width, height }}>
      {data.length && (
        <View style={containerStyle}>
          <LineChart
            bezier
            key="home:thumbnailLineChart"
            data={{
              labels: [],
              datasets: [
                {
                  data,
                  strokeWidth: 4,
                },
              ],
            }}
            width={svgWidth}
            height={svgHeight}
            horizontalOffset={7}
            chartConfig={{
              color: () => Colors.primary50,
              useShadowColorFromDataset: true,
            }}
            style={styles.chartStyle}
            fromZero={true}
            transparent={true}
            withInnerLines={false}
            withOuterLines={false}
            withHorizontalLabels={false}
            withVerticalLabels={false}
            getDotProps={(dataPoint: any, index: number) => {
              return {
                r: 4,
                strokeWidth: 3,
                stroke: Colors.primary50,
                fill: index === data.length - 1 ? Colors.primary50 : Colors.white,
              };
            }}
            getDotColor={(_, index) => (index === data.length - 1 ? Colors.primary50 : Colors.white)}
          />
        </View>
      )}
    </View>
  );
};

export default ThumbnailLineChart;
const styles = StyleSheet.create({
  chartStyle: {
    paddingRight: 0,
  },
});
