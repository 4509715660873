import { FlashList } from "@shopify/flash-list";
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RefreshControl, StyleSheet, View } from "react-native";
import { NetworkStatus } from "@apollo/client";

import { useNavigation, useRoute } from "@react-navigation/native";
import { EmptyExpenseReport } from "assets/images/svg/icons";
import {
  AnimatedFlatList,
  CopilotStepCustom,
  CreateCircleButton,
  EmptyData,
  SkeletonListLoading,
  SortingLabel,
} from "components";
import { CONSTANTS, EXPENSE_REPORT_STATUS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import { NewReportScreenNavigationProp } from "navigation/type";
import Animated, {
  Easing,
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { Colors } from "theme";
import useExpenseReportQuery from "../hooks/useExpenseReportQuery";
import ReportItem from "./ReportItem";
import ReportStatusList from "./ReportStatusList";
import FlashListItem from "components/FlashListItem";
import { clampReanimated } from "utils";
import { EVENT } from "constants/Tracking";
import { MyExpenseReportTabMethods, MyExpenseReportTabProps, REPORT_SORT_OPTIONS } from "../types";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { analyticService } from "services/AnalyticsService";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";

const REPORT_STATUS_OPTIONS = [
  {
    title: "report_status_draft",
    value: EXPENSE_REPORT_STATUS.DRAFT,
  },
  {
    title: "report_status_awaiting",
    value: EXPENSE_REPORT_STATUS.SUBMITTED,
  },
  {
    title: "report_status_approve",
    value: EXPENSE_REPORT_STATUS.APPROVED,
  },
  {
    title: "report_status_reject",
    value: EXPENSE_REPORT_STATUS.REJECTED,
  },
  {
    title: "report_status_cancel",
    value: EXPENSE_REPORT_STATUS.CANCEL,
  },
];
const PAGE_SIZE = 10;
const FILTER_CONTAINER_HEIGHT = 82;
const MyReportTab = forwardRef<MyExpenseReportTabMethods, MyExpenseReportTabProps>(({ routeParams }, ref) => {
  useImperativeHandle(ref, () => ({
    onRefresh: () => {
      translationY.value = 0;
      flashListRef.current?.scrollToOffset({ animated: false, offset: 0 });
      refetch();
    },
  }));
  const navigation = useNavigation<NewReportScreenNavigationProp>();
  const route = useRoute<any>();
  const { shouldShowWalkthrough = false } = routeParams ?? {};
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const { t } = useTranslation("app/screens/Report/ReportListScreen");
  const sortOptions = useMemo(
    () =>
      [
        { label: t("created_at_desc"), value: REPORT_SORT_OPTIONS.CREATED_AT_DESC },
        { label: t("created_at_asc"), value: REPORT_SORT_OPTIONS.CREATED_AT_ASC },
        {
          label: t("expense_asc"),
          value: REPORT_SORT_OPTIONS.AMOUNT_ASC,
        },
        {
          label: t("expense_desc"),
          value: REPORT_SORT_OPTIONS.AMOUNT_DESC,
        },
      ].reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [t]
  );
  const [sortOption, setSortOption] = useState<SelectItem>(sortOptions[REPORT_SORT_OPTIONS.CREATED_AT_DESC]);
  const flashListRef = useRef<FlashList<any>>(null);

  /*-- start walkthrough flow --*/
  const { start, steps } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) => steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [routeParams, stepNameToStartWalkthrough]);
  /*-- end --*/

  const variables = useMemo(() => {
    const statusInNumber = parseInt(route.params?.status);
    return {
      where: {
        companyId,
        companyEmployeeId: employeeId,
        senderId: employeeId,
        statuses: isNaN(statusInNumber) ? undefined : [statusInNumber],
      },
      sortOption: sortOption.value,
      limit: PAGE_SIZE,
      offset: 0,
    };
  }, [companyId, employeeId, route, sortOption]);

  const { data, loading, called, refetch, fetchMore, networkStatus } = useExpenseReportQuery(variables);

  /*-- scroll header to top when apply sort --*/
  useEffect(() => {
    translationY.value = withTiming(0, {
      duration: 150,
      easing: Easing.linear,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption]);

  const reportList = data?.expExpenseReports?.reports ?? [];
  const total = data?.expExpenseReports?.total ?? 0;

  const navigateToCreateReport =
    (shouldShowWalkthrough = false) =>
    () => {
      navigation.navigate(SCREEN_NAME.NewReportScreen, {
        shouldShowWalkthrough,
        onRefreshData: () => {
          translationY.value = 0;
          flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
          refetch();
        },
      });
    };

  const handleLoadMore = () => {
    if (loading || reportList?.length === 0 || reportList?.length >= total) {
      return;
    }
    fetchMore?.({
      variables: { offset: reportList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expExpenseReports: {
            reports: [...prev.expExpenseReports.reports, ...fetchMoreResult.expExpenseReports.reports],
            total: fetchMoreResult.expExpenseReports.total,
          },
        };
      },
    });
  };

  const handleClickItem = (item) => () => {
    const { expenseReportId, status, companyEmployeeId } = item;
    const isEdit = employeeId === companyEmployeeId && status === EXPENSE_REPORT_STATUS.DRAFT;
    if (isEdit) {
      navigation.navigate(SCREEN_NAME.EditReportScreen, {
        expenseReportId: expenseReportId,
        onRefreshData: () => {
          translationY.value = 0;
          flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
          refetch();
        },
      });
      return;
    }
    navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
      expenseReportId: expenseReportId,
      onRefreshData: () => {
        translationY.value = 0;
        flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
        refetch();
      },
    });
  };

  const handleChangeStatus = (status: number) => {
    flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
    console.log("status", status);
    navigation.setParams({ status: route.params?.status === status ? undefined : status });
    // setCurrentStatus(currentStatus !== status ? status : undefined);
  };

  const renderItem = ({ item }) => {
    const { status, companyEmployeeId } = item;
    const isEdit = employeeId === companyEmployeeId && status === EXPENSE_REPORT_STATUS.DRAFT;
    return (
      <FlashListItem>
        <ReportItem
          eventName={isEdit ? EVENT.REPORT.TAP_UPDATE : EVENT.REPORT.TAP_VIEW_DETAIL}
          onPress={handleClickItem(item)}
          item={item}
          showApprovalFlow={[EXPENSE_REPORT_STATUS.SUBMITTED, EXPENSE_REPORT_STATUS.REJECTED].includes(item.status)}
          isRejected={item.status === EXPENSE_REPORT_STATUS.REJECTED}
        />
      </FlashListItem>
    );
  };

  const renderEmpty = () => {
    if (loading || !called) {
      return null;
    }
    return (
      <EmptyData
        icon={<EmptyExpenseReport />}
        title={t("empty_expense_report")}
        description={t("empty_expense_report_description")}
      />
    );
  };

  const renderFooter = () => {
    if (loading && reportList.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };

  const translationY = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      translationY.value,
      [0, FILTER_CONTAINER_HEIGHT],
      [0, -FILTER_CONTAINER_HEIGHT],
      Extrapolation.CLAMP
    );
    return {
      transform: [{ translateY: translateY }],
    };
  });
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event, ctx) => {
      let { y } = event.contentOffset;
      if (y < 0) {
        y = 0;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const dy = y - (ctx?.prevY ?? 0);
      translationY.value = clampReanimated(translationY.value + dy, 0, FILTER_CONTAINER_HEIGHT);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ctx.prevY = y;
    },
  });

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.floatHeader, animatedStyle]}>
        <ReportStatusList
          data={REPORT_STATUS_OPTIONS}
          currentStatus={route.params?.status}
          handleChangeStatus={handleChangeStatus}
        />
        <View style={styles.sortingContainer}>
          <SortingLabel
            sortingModalProps={{
              showClearButton: false,
              title: t("sort_by"),
              data: Object.values(sortOptions),
              value: sortOption,
              setValue: setSortOption,
              onPress: () =>
                analyticService.logEvent({ name: EVENT.REQUEST.TAP_OPEN_SORT_MODAL, properties: { tab: "mine" } }),
            }}
          />
        </View>
      </Animated.View>
      {(loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={styles.loadingContainer} />
      ) : (
        <AnimatedFlatList
          ref={flashListRef}
          onScroll={scrollHandler}
          refreshControl={
            <RefreshControl
              progressViewOffset={CONSTANTS.EXPENSE_LIST.HEADER_STATUS_HEIGHT + 10}
              tintColor={Colors.primary50}
              refreshing={false}
              onRefresh={refetch}
            />
          }
          keyExtractor={(item: any, index) => item?.expenseReportId ?? index}
          data={reportList}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          estimatedItemSize={88}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
      <CopilotStepCustom
        title={"walkthrough_report_title_step1"}
        text={"walkthrough_report_description_step1"}
        style={styles.floatButton}
        order={CONSTANTS.WALK_THROUGH.CREATE_REPORT.Step1}
        image={BizziBotSticker01}
        walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME}
        onConfirm={navigateToCreateReport(true)}
        confirmText={t("continue")}
        autoScroll={false}
        singleStep={true}
      >
        <CreateCircleButton eventName={EVENT.REPORT.TAP_CREATE} onPress={navigateToCreateReport(false)} />
      </CopilotStepCustom>
    </View>
  );
});
export default memo(MyReportTab);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingTop: FILTER_CONTAINER_HEIGHT - 10,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 50,
  },
  loadingMore: { marginTop: 10 },
  floatButton: { position: "absolute", right: 15, bottom: 20 },
  loadingContainer: { marginTop: FILTER_CONTAINER_HEIGHT - 10 },
  floatHeader: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1,
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
  sortingContainer: {
    paddingTop: 6,
    paddingBottom: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
