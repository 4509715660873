import Svg, { Path } from "react-native-svg";
import React from "react";
function SvgComponent(props) {
  return (
    <Svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.667 8a7.333 7.333 0 1014.666 0A7.333 7.333 0 00.667 8zm5.138 3.138L8 8.943l2.195 2.195.943-.943L8.943 8l2.195-2.195-.943-.943L8 7.057 5.805 4.862l-.943.943L7.057 8l-2.195 2.195.943.943z"
        fill="#42526E"
        {...props}
      />
    </Svg>
  );
}

export default SvgComponent;
