import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent({ size }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 64 64" fill="none">
      <G clipPath="url(#clip0_700_3985)">
        <Path d="M16 0c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4V16L44 0H16z" fill="#E2E5E7" />
        <Path d="M48 16h12L44 0v12c0 2.2 1.8 4 4 4z" fill="#B0B7BD" />
        <Path d="M60 28L48 16h12v12z" fill="#CAD1D8" />
        <Path
          d="M52 52c0 1.101-.899 2-2 2H6a2.006 2.006 0 01-2-2V32c0-1.099.899-2 2-2h44c1.101 0 2 .901 2 2v20z"
          fill="#84BD5A"
        />
        <Path
          d="M12.797 48c-.365 0-.547-.195-.547-.586V37.383c0-.39.182-.586.547-.586h6.18c.39 0 .585.17.585.508v.922c0 .338-.195.507-.585.507H14.53v2.72h3.805c.39 0 .586.168.586.507v.922c0 .338-.195.508-.586.508H14.53v4.023c0 .39-.182.586-.547.586h-1.187zm9.187 0c-.364 0-.547-.195-.547-.586V37.383c0-.39.183-.586.547-.586h1.188c.364 0 .547.195.547.586v10.031c0 .39-.183.586-.547.586h-1.188zm4.782 0c-.365 0-.547-.195-.547-.586V37.383c0-.39.182-.586.547-.586h1.265c.313 0 .469.195.469.586v8.68h4.555c.39 0 .586.169.586.507v.922c0 .339-.196.508-.586.508h-6.29zm9.078 0c-.365 0-.547-.195-.547-.586V37.383c0-.39.182-.586.547-.586h6.703c.39 0 .586.17.586.508v.922c0 .338-.196.507-.586.507h-4.969v2.485h4.555c.39 0 .586.169.586.508v.921c0 .339-.196.508-.586.508h-4.555v2.907h5.219c.39 0 .586.169.586.507v.922c0 .339-.196.508-.586.508h-6.953z"
          fill="#fff"
        />
        <Path d="M50 54H12v2h38c1.101 0 2-.899 2-2v-2c0 1.101-.899 2-2 2z" fill="#CAD1D8" />
      </G>
      <Defs>
        <ClipPath id="clip0_700_3985">
          <Path fill="#fff" d="M0 0H64V64H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
