import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M9.997 18.333c4.584 0 8.334-3.75 8.334-8.333s-3.75-8.333-8.334-8.333c-4.583 0-8.333 3.75-8.333 8.333s3.75 8.333 8.333 8.333zM10 6.667v4.166M9.992 13.334H10"
        stroke="#E11934"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
