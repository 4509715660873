import * as React from "react";
import Svg, { Path, Rect, Ellipse, G, Mask, Defs, LinearGradient, Stop } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={273} height={130} viewBox="0 0 273 130" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.1 12.872C90.1 5.763 95.861 0 102.97 0h157.156C267.236 0 273 5.763 273 12.872c0 7.11-5.763 12.872-12.873 12.872H201.96a12.849 12.849 0 014.781 10.012c0 7.053-5.673 12.78-12.704 12.871h36.945c7.109 0 12.872 5.763 12.872 12.872 0 7.11-5.763 12.872-12.872 12.872H77.405c7.035-.086 12.712-5.815 12.712-12.871 0-7.109-5.763-12.872-12.872-12.872H36.66c-7.085-.028-12.82-5.78-12.82-12.872 0-7.11 5.762-12.872 12.871-12.872H94.88a12.847 12.847 0 01-4.78-10.012zM0 87.245c0-7.11 5.763-12.872 12.872-12.872h183.664c.633 0 1.255.046 1.864.134h-7.059c-6.309 0-11.424 5.115-11.424 11.424 0 6.31 5.115 11.425 11.424 11.425h22.702c6.349.792 11.262 6.208 11.262 12.772 0 7.109-5.763 12.872-12.872 12.872H73.832c-7.11 0-12.873-5.763-12.873-12.872 0-4.043 1.865-7.651 4.78-10.011H12.872C5.763 100.117 0 94.354 0 87.245z"
        fill="#F0F6FF"
      />
      <Rect x={208.148} y={111} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
      <Rect x={208.148} y={121.414} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={210.773}
        y={121.414}
        width={2.11731}
        height={7.84902}
        rx={1.05865}
        transform="rotate(-90 210.773 121.414)"
        fill="#75A4FE"
      />
      <Rect
        x={199}
        y={121.414}
        width={2.11731}
        height={7.84902}
        rx={1.05865}
        transform="rotate(-90 199 121.414)"
        fill="#75A4FE"
      />
      <Path
        d="M205.488 46.72c0 2.6-2.118 4.714-4.74 4.714-2.622 0-4.74-2.114-4.74-4.713s2.118-4.713 4.74-4.713c2.622 0 4.74 2.114 4.74 4.713z"
        fill="#fff"
        stroke="#60A5FA"
        strokeWidth={2.01559}
      />
      <Ellipse cx={53.2546} cy={97.2346} rx={4.25457} ry={4.23462} fill="#75A4FE" />
      <Rect x={96.6133} y={5.29297} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
      <Rect x={96.7461} y={15.2578} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={98.7422}
        y={14.8203}
        width={2.11731}
        height={7.44551}
        rx={1.05865}
        transform="rotate(-90 98.742 14.82)"
        fill="#75A4FE"
      />
      <Path d="M90.227 15.258a1.059 1.059 0 110-2.117h5.328a1.059 1.059 0 010 2.117h-5.328z" fill="#75A4FE" />
      <Path
        d="M96.888 49.929l18.736-1.09c.262-.015.524.04.76.157l14.726 7.38c.513.257 1.128.199 1.584-.15l12.689-9.695c.43-.328 1.003-.4 1.5-.188l24.959 10.624c.441.187.765.574.874 1.04l12.598 54.049c.282 1.209-.941 2.218-2.074 1.712l-25.586-11.414a1.498 1.498 0 00-1.18-.019l-24.652 10.094a1.5 1.5 0 01-1.099.016l-26.935-10.147a1.502 1.502 0 00-1.09.012l-22.8 9.177c-1.168.47-2.361-.62-1.998-1.826l17.637-58.665a1.502 1.502 0 011.351-1.067z"
        stroke="#1F71F4"
        strokeWidth={3}
      />
      <Ellipse cx={98.8608} cy={86.884} rx={1.97409} ry={1.92702} fill="#E5F3FF" />
      <G filter="url(#filter0_ddd_6863_60403)">
        <Ellipse cx={137.556} cy={18.8503} rx={2.60691} ry={2.31514} fill="#fff" />
      </G>
      <Rect
        width={0.908688}
        height={3.65061}
        rx={0.454344}
        transform="matrix(-.9665 -.25665 .26628 -.96388 104.84 32.453)"
        fill="#F3F4F6"
      />
      <Rect
        width={0.916898}
        height={3.65171}
        rx={0.458449}
        transform="matrix(.26628 -.96388 .9665 .25665 102.996 30.55)"
        fill="#F3F4F6"
      />
      <Rect
        width={1.29135}
        height={5.21345}
        rx={0.645675}
        transform="matrix(.86834 .49593 -.50876 .86089 117.875 41.332)"
        fill="#F3F4F6"
      />
      <Rect
        width={1.30943}
        height={5.18949}
        rx={0.654713}
        transform="matrix(-.50876 .86089 -.86834 -.49594 119.695 44.621)"
        fill="#F3F4F6"
      />
      <Rect
        width={1.27964}
        height={5.26079}
        rx={0.639819}
        transform="matrix(.26364 .9646 -.96718 .25406 189.043 29.71)"
        fill="#E5F3FF"
      />
      <Rect
        width={1.32132}
        height={5.14243}
        rx={0.660658}
        transform="matrix(-.96717 .25406 -.26364 -.9646 187.984 33.309)"
        fill="#E5F3FF"
      />
      <Path
        d="M101.071 56.75l14.697-.805a1.5 1.5 0 01.724.142l11.565 5.465c.494.233 1.076.18 1.52-.14l9.888-7.124c.418-.301.96-.367 1.438-.175l19.642 7.884a1.5 1.5 0 01.899 1.033l9.838 39.802c.296 1.2-.901 2.224-2.04 1.745l-19.776-8.32a1.504 1.504 0 00-1.124-.017l-19.502 7.53c-.335.13-.705.135-1.043.015l-21.333-7.578a1.5 1.5 0 00-1.035.01l-17.52 6.65c-1.175.446-2.346-.663-1.964-1.86l13.778-43.213c.189-.594.726-1.009 1.348-1.043z"
        fill="#FAFAFC"
      />
      <Path
        d="M118.826 55.81l-.202.457.202-.457a2.003 2.003 0 00-.913-.169l-15.848.814a2.002 2.002 0 00-1.792 1.354l-14.746 43.323c-.54 1.584.988 3.091 2.565 2.531l18.978-6.747a1 1 0 01.651-.006l22.935 7.631c.427.142.89.136 1.313-.017l20.982-7.589c.23-.083.481-.08.708.01l21.398 8.433c1.526.601 3.088-.787 2.67-2.373L167.2 63.112a2.003 2.003 0 00-1.231-1.363l-21.129-7.944a2.001 2.001 0 00-1.824.214l-10.725 7.238a1 1 0 01-.965.086l-12.5-5.533z"
        fill="#FAFAFC"
        stroke="#E2E8F0"
      />
      <Path d="M131 104.5l1.32-28.495V59.047l12.173-4.063L153 96.5l-22 8z" fill="url(#paint0_linear_6863_60403)" />
      <Path
        d="M107.085 96.484l10.643-39.474a.751.751 0 00-.772-.945l-15.621.979a.75.75 0 00-.666.513L86.644 99.905c-1.493 3.226-.19 4.838 1.862 3.763l18.113-6.675a.752.752 0 00.466-.51z"
        fill="url(#paint1_linear_6863_60403)"
      />
      <Path
        d="M89.5 77.5c7.459 8.184 39.41 13.142 47-6.5 2.267 8.302 21.101 21.392 38 5"
        stroke="#BFDBFE"
        strokeWidth={1.12624}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2.25 2.25"
      />
      <Mask id="a" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.606 45c1.553-2.525 2.439-5.438 2.439-8.54 0-9.643-8.562-17.46-19.124-17.46s-19.124 7.817-19.124 17.46c0 2.291.483 4.48 1.362 6.484.029.312.113.635.26.963 2.77 6.185 9.516 16.052 14.642 23.05 1.53 2.088 4.612 2.13 6.169.06 6.291-8.362 10.607-16.039 13.105-21.254.121-.253.211-.509.271-.763z"
        />
      </Mask>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.606 45c1.553-2.525 2.439-5.438 2.439-8.54 0-9.643-8.562-17.46-19.124-17.46s-19.124 7.817-19.124 17.46c0 2.291.483 4.48 1.362 6.484.029.312.113.635.26.963 2.77 6.185 9.516 16.052 14.642 23.05 1.53 2.088 4.612 2.13 6.169.06 6.291-8.362 10.607-16.039 13.105-21.254.121-.253.211-.509.271-.763z"
        fill="#1F71F4"
      />
      <Path
        d="M152.606 45l-.852-.524-.084.137-.037.157.973.23zm-34.447-2.056l.996-.093-.015-.16-.065-.149-.916.402zm.26.963l-.912.409.912-.41zm14.642 23.05l.806-.592-.806.591zm6.169.06l-.8-.601.8.601zm13.105-21.254l-.902-.432.902.432zm1.71-9.303c0 2.908-.829 5.64-2.291 8.016l1.703 1.048c1.646-2.674 2.588-5.768 2.588-9.064h-2zM135.921 20c10.098 0 18.124 7.453 18.124 16.46h2c0-10.279-9.097-18.46-20.124-18.46v2zm-18.124 16.46c0-9.007 8.027-16.46 18.124-16.46v-2c-11.027 0-20.124 8.181-20.124 18.46h2zm1.278 6.082a15.093 15.093 0 01-1.278-6.082h-2c0 2.434.514 4.758 1.446 6.885l1.832-.803zm-1.912.495c.04.427.155.857.344 1.279l1.825-.818a2.032 2.032 0 01-.177-.647l-1.992.186zm.344 1.279c2.819 6.296 9.628 16.243 14.747 23.231l1.613-1.182c-5.132-7.006-11.815-16.792-14.535-22.867l-1.825.818zm14.747 23.231c1.92 2.622 5.808 2.685 7.775.071l-1.599-1.202c-1.147 1.525-3.423 1.506-4.563-.05l-1.613 1.181zm7.775.071c6.334-8.419 10.684-16.153 13.208-21.423l-1.804-.864c-2.471 5.16-6.754 12.78-13.003 21.085l1.599 1.202zm13.208-21.423c.151-.317.265-.64.342-.965l-1.946-.46a2.792 2.792 0 01-.2.56l1.804.865z"
        fill="#60A5FA"
        mask="url(#a)"
      />
      <Path
        d="M143.101 36.463c0 3.905-3.21 7.083-7.185 7.083-3.975 0-7.186-3.178-7.186-7.083 0-3.906 3.211-7.084 7.186-7.084s7.185 3.178 7.185 7.084z"
        fill="#fff"
        stroke="#60A5FA"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_6863_60403"
          x1={142.345}
          y1={89.4294}
          x2={130.892}
          y2={90.6989}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#EBECF2" />
          <Stop offset={1} stopColor="#E8EAF0" stopOpacity={0.6} />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_6863_60403"
          x1={103.049}
          y1={54.9248}
          x2={103.049}
          y2={104.385}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E8EAF0" />
          <Stop offset={1} stopColor="#E8EAF0" stopOpacity={0.6} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
