import Svg, { Rect, Path } from "react-native-svg";

function SvgComponent({ width = 56, height = 56, color = "#fff" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 56 56" fill="none">
      <Rect width={width} height={height} rx={28} fill="#FB83AE" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.438 18.668a3.771 3.771 0 00-3.771 3.771v7.762c0 4.175 0 6.263.902 7.815a6.666 6.666 0 002.416 2.417c1.553.902 3.64.902 7.815.902h4.4c4.175 0 6.263 0 7.815-.902a6.666 6.666 0 002.417-2.417c.902-1.552.902-3.64.902-7.815V22.44a3.771 3.771 0 00-3.772-3.771c-1 0-1.959-.397-2.666-1.105L34 16.668l-.092-.092a6.666 6.666 0 00-4.606-1.908H26.7a6.666 6.666 0 00-4.606 1.908l-.093.092-.895.895a3.771 3.771 0 01-2.667 1.105zM25 21.335a1 1 0 011-1h4a1 1 0 010 2h-4a1 1 0 01-1-1zM28 36a5.344 5.344 0 005.334-5.333A5.344 5.344 0 0028 25.335a5.344 5.344 0 00-5.333 5.333A5.344 5.344 0 0028 36.001z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
