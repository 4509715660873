import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={107} height={106} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#C0CAD8"
        d="M43.59 91.309c2.547.81 5.446 1.268 8.533 1.268 3.086 0 5.986-.457 8.532-1.268-2.463-2.276-5.404-3.73-8.532-3.73-3.118 0-6.038 1.433-8.532 3.73Z"
      />
      <Path
        fill="#E4EAF1"
        d="M96.57 57.91c-1.32 20.7-21.326 30.033-45.186 28.505C27.524 84.898 8.89 73.082 10.21 52.401c1.32-20.68 21.98-40.363 45.84-38.846 23.86 1.517 41.839 23.653 40.52 44.354Z"
      />
      <Path
        fill="#1F71F4"
        d="m37.718 16.58-3.025 47.348c5.384 2.11 11.432 3.45 17.896 3.866 6.453.416 12.637-.146 18.249-1.559l3.024-47.347c-5.331-2.993-11.359-4.916-17.802-5.331-6.454-.416-12.679.727-18.343 3.024Z"
      />
      <Path
        fill="#fff"
        d="m52.184 33.966-.904-.062.79-2.723 1.663-5.799.083-.312-4.593-.29 2.161-7.369-2.712-.176 6.61-3.731 4.084 4.417-2.1-.136-.852 2.942 6.038.384-10.267 12.855Z"
      />
      <Path
        fill="#1B1B1D"
        d="M21.236 65.06c-5.144-7.265 2.36-29.868 10.185-34.087C41.938 25.309 71.7 28 80.71 35.816c6.526 5.674 9.925 28.64 4.23 34.252-11.37 11.203-54.528 7.95-63.705-5.009Z"
      />
      <Path
        fill="#C0CAD8"
        d="M12.995 59.18 8.11 57.6c-.893-.28-1.496-1.32-1.423-2.452l.53-8.314c.072-1.133.8-2.089 1.746-2.255l5.04-.946c1.257-.228 2.348.977 2.244 2.515l-.685 10.84c-.115 1.506-1.351 2.587-2.567 2.192Z"
      />
      <Path
        fill="#E4EAF1"
        d="m10.22 58.339-4.479-1.424c-.831-.26-1.372-1.205-1.31-2.265l.49-7.607c.061-1.05.727-1.902 1.579-2.058l4.624-.852c1.154-.219 2.141.904 2.058 2.286l-.634 9.904c-.083 1.413-1.226 2.38-2.328 2.016Z"
      />
      <Path
        fill="#C0CAD8"
        d="m92.912 64.945 5.04-.946c.946-.166 1.673-1.122 1.746-2.255l.53-8.314c.073-1.132-.53-2.172-1.445-2.462l-4.884-1.58c-1.216-.385-2.453.675-2.546 2.203l-.686 10.84c-.104 1.537.977 2.763 2.245 2.514Z"
      />
      <Path
        fill="#E4EAF1"
        d="m95.78 64.488 4.624-.852c.862-.156 1.517-1.03 1.58-2.058l.488-7.607c.062-1.05-.478-1.985-1.309-2.245l-4.48-1.423c-1.122-.364-2.244.623-2.327 2.005l-.634 9.904c-.083 1.361.904 2.484 2.057 2.276Z"
      />
      <Path
        fill="#fff"
        d="M46.926 53.701c1.258-.582-1.05-4.572-6.443-4.344-4.884.208-7.722 3.918-6.558 4.583.81.468 3.18-2.484 6.776-2.754 3.544-.27 5.383 2.9 6.225 2.515ZM74.507 56.144c1.33-.374-.332-4.687-5.694-5.3-4.854-.55-8.242 2.671-7.192 3.513.728.582 3.523-1.954 7.119-1.663 3.554.291 4.874 3.7 5.767 3.45Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M4.433 13.473h98.04v79.106H4.433z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
