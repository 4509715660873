import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
  AppText,
  BackHeader,
  CopilotStepCustom,
  IconCustom,
  Line,
  LoadingView,
  TopTabLineView,
  useWindowDimensions,
} from "components";
import { ScrollView, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Colors, Fonts } from "theme";
import useQueryExpenseCategory from "hooks/useQueryExpenseCategory";
import { getTitleFromCategory } from "utils";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "constants/constants";
import { POLICY_TYPE } from "screens/PolicyEmployee/types";
import HotelPolicy from "./HotelPolicy";
import { Route } from "react-native-tab-view";
import PerdiemPolicy from "./PerdiemPolicy";
import FlightPolicy from "./FlightPolicy";
import NumberOfTravelDaysPolicy from "./NumberOfTravelDaysPolicy";
import TransportationPolicy from "./TransportationPolicy";
import { EmptyPolicy } from "assets/images/svg/icons";
import { useAuth } from "contexts/AuthContext";
import useExpEmployeeExpensePolicyTravel from "screens/ExpenseRequest/hooks/useExpEmployeeExpensePolicyTravel";
import { useCopilot } from "react-native-copilot";
import { BizziBotSticker02 } from "assets/images";
import { MobileExpEmployeeExpensePolicyTravelQuery } from "types";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DummyCategoryWalkthroughData = require("./DummyCategoryWalkthroughData.json");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DummyTravelPolicyWalkthroughData = require("./DummyTravelPolicyWalkthroughData.json");

const TravelPolicy = ({ shouldShowWalkthrough = false }) => {
  const { t } = useTranslation("app/screens/PolicyEmployee/PolicyEmployeeScreen");
  const routes = [
    { key: "allowances", title: t("allowances") },
    { key: "travel_day", title: t("travel_day") },
    { key: "flight_ticket", title: t("flight_ticket") },
    { key: "bus_ticket", title: t("bus_ticket") },
    { key: "hotel", title: t("hotel") },
  ];
  const { width, isTablet } = useWindowDimensions();
  const { user } = useAuth();
  const [fetchPolicyTravel, { data, loading }] = useExpEmployeeExpensePolicyTravel({
    employeeId: user.employee.employee_id,
  });
  const travelPolicy = (
    shouldShowWalkthrough ? DummyTravelPolicyWalkthroughData : data
  ) as MobileExpEmployeeExpensePolicyTravelQuery;
  const [index, setIndex] = useState(0);
  const calculatedTabWidth = useMemo(() => {
    if (!isTablet) {
      return "auto";
    }

    return Math.floor(width / routes.length);
    //Math.max(, DEFAULT_TAB_WIDTH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    fetchPolicyTravel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading && !shouldShowWalkthrough) {
    return <LoadingView style={styles.loadingContainer} />;
  }

  const checklist = [
    travelPolicy?.expEmployeeExpensePolicyTravel?.perdiem?.isEnabled,
    travelPolicy?.expEmployeeExpensePolicyTravel?.limitation?.isEnabled,
    travelPolicy?.expEmployeeExpensePolicyTravel?.flightBooking?.isEnabled,
    travelPolicy?.expEmployeeExpensePolicyTravel?.transportationBooking?.isEnabled,
    travelPolicy?.expEmployeeExpensePolicyTravel?.hotelBooking?.isEnabled,
  ];

  if (checklist.every((x) => !x)) {
    return (
      <View style={styles.emptyContainer}>
        <EmptyPolicy />
        <View style={[styles.emptyContent, styles.gap8]}>
          <AppText style={[Fonts.H400, styles.textCenter]} color={Colors.grayscale100}>
            {t("no_policy")}
          </AppText>
          <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale100}>
            {t("no_policy_description")}
          </AppText>
        </View>
      </View>
    );
  }

  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case "allowances":
        return (
          <PerdiemPolicy
            shouldShowWalkthrough={shouldShowWalkthrough}
            perdiem={travelPolicy?.expEmployeeExpensePolicyTravel?.perdiem}
            loading={loading}
          />
        );
      case "travel_day":
        return (
          <NumberOfTravelDaysPolicy
            limitation={travelPolicy?.expEmployeeExpensePolicyTravel?.limitation}
            loading={loading}
          />
        );
      case "flight_ticket":
        return (
          <FlightPolicy flightBooking={travelPolicy?.expEmployeeExpensePolicyTravel?.flightBooking} loading={loading} />
        );
      case "bus_ticket":
        return (
          <TransportationPolicy
            transportationBooking={travelPolicy?.expEmployeeExpensePolicyTravel?.transportationBooking}
            loading={loading}
          />
        );
      case "hotel":
        return (
          <HotelPolicy hotelBooking={travelPolicy?.expEmployeeExpensePolicyTravel?.hotelBooking} loading={loading} />
        );
      default:
        return null;
    }
  };

  return (
    <TopTabLineView
      renderScene={renderScene}
      routes={routes}
      index={index}
      setIndex={setIndex}
      tabStyle={{ width: calculatedTabWidth, paddingHorizontal: 20 }}
      scrollEnabled={true}
    />
  );
};

const SpendPolicy = ({ shouldShowWalkthrough = false }) => {
  const navigation = useNavigation();
  const { t } = useTranslation("app/screens/PolicyEmployee/PolicyEmployeeScreen");
  const { data, loading } = useQueryExpenseCategory();
  const { start, stop } = useCopilot();
  const dataCategories = shouldShowWalkthrough ? DummyCategoryWalkthroughData : data;
  const { handleFinishGuideAction } = useWalkthroughGuide();
  const categories = useMemo(() => {
    if (!dataCategories) {
      return [];
    }

    const otherCategory = dataCategories?.find((item) => item?.expense_category?.title_en.toLowerCase() === "others");
    let result = dataCategories
      ?.filter((item) => item?.expense_category?.title_en?.toLowerCase() !== "others")
      .sort((a, b) => a?.expense_category?.title.localeCompare(b?.expense_category?.title));
    if (otherCategory) {
      result = [...result, ...[otherCategory]];
    }
    return result;
  }, [dataCategories]);

  if (loading && !dataCategories) {
    return <LoadingView style={styles.loadingContainer} />;
  }

  return (
    <ScrollView contentContainerStyle={styles.contentContainer} showsVerticalScrollIndicator={false}>
      <AppText style={styles.categoryTitle}>{t("category_title")}</AppText>
      <CopilotStepCustom
        order={CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.Step3}
        title={"walkthrough_category_title_step3"}
        text={"walkthrough_category_description_step3"}
        image={BizziBotSticker02}
        walkthroughName={CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME}
        activeStep={3}
        totalStep={3}
        offsetX={-5}
        onConfirm={handleFinishGuideAction}
        onBack={() => {
          stop();
          navigation.goBack();
          setTimeout(() => {
            start({
              fromStep: CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.Step2.toString(),
              walkthroughName: CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME,
            });
          }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
        }}
      >
        {dataCategories?.map((item) => (
          <View key={item?.expense_category?.expense_category_id}>
            <View style={styles.categoryItem}>
              <IconCustom name="sell-outline" fill={Colors.grayscale60} />
              <AppText style={[Fonts.BodyMedium, styles.flex]}>
                {getTitleFromCategory(item?.expense_category)}{" "}
                {item.expense_category?.code ? `(${item.expense_category?.code})` : null}
              </AppText>
            </View>
            <Line />
          </View>
        ))}
      </CopilotStepCustom>
    </ScrollView>
  );
};

const PolicyEmployeeScreen = ({ route }) => {
  const { type, shouldShowWalkthrough = false, walkthroughName } = route?.params;
  const navigation = useNavigation<any>();
  const { t } = useTranslation("app/screens/PolicyEmployee/PolicyEmployeeScreen");

  /*-- start walkthrough flow --*/
  const { start, steps } = useCopilot();
  const orderWalkthroughStepActive =
    walkthroughName === CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME
      ? CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.Step3
      : CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.Step3;
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) => steps[key].walkthroughName === walkthroughName && steps[key].order === orderWalkthroughStepActive
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);
  /*-- end --*/
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={type === POLICY_TYPE.SPEND ? t("category") : t("travel")} />,
    } as any);
  }, []);

  return (
    <View style={styles.container}>
      {type === POLICY_TYPE.SPEND && <SpendPolicy shouldShowWalkthrough={shouldShowWalkthrough} />}
      {type === POLICY_TYPE.REQUEST_TRAVEL && <TravelPolicy shouldShowWalkthrough={shouldShowWalkthrough} />}
    </View>
  );
};
export default PolicyEmployeeScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: {
    paddingTop: 15,
    flexGrow: 1,
    paddingHorizontal: 20,
  },
  categoryItem: { paddingVertical: 13, flexDirection: "row", gap: 16, alignItems: "center" },
  flex: { flex: 1 },
  loadingContainer: { alignItems: "center", alignSelf: "center" },
  categoryTitle: { ...Fonts.BodyMedium, marginBottom: 12 },
  emptyContainer: {
    paddingVertical: 80,
    alignItems: "center",
  },
  emptyContent: {
    padding: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  textCenter: {
    textAlign: "center",
  },
  gap8: { gap: 8 },
});
