import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 24, color = "#4D5C6A" }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6zm18 0H4v12h16V6z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.293 15.707a1 1 0 010-1.414L15.586 12l-2.293-2.293a1 1 0 011.414-1.414l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0zM10.707 8.293a1 1 0 010 1.414L8.414 12l2.293 2.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
