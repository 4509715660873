import React, { useLayoutEffect, useMemo } from "react";
import { BackHeader, LoadingView, useInteractionManager } from "components";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { CustomTable } from "components/Table";

enum TextAlignStyle {
  LEFT = "left",
  CENTER = "center",
}
const columns = (t) => [
  {
    key: "description",
    title: t("description"),
    width: 250,
    textAlign: TextAlignStyle.LEFT,
  },
  {
    key: "uom",
    title: t("uom"),
    width: 100,
    textAlign: TextAlignStyle.LEFT,
  },
  {
    key: "amount",
    title: t("amount"),
    width: 150,
    textAlign: TextAlignStyle.CENTER,
  },
  {
    key: "customer",
    title: t("customer"),
    width: 120,
    textAlign: TextAlignStyle.LEFT,
  },
  {
    key: "cost_center",
    title: t("cost_center"),
    width: 120,
    textAlign: TextAlignStyle.LEFT,
  },
  {
    key: "bu_code",
    title: t("bu_code"),
    width: 120,
    textAlign: TextAlignStyle.LEFT,
  },
  {
    key: "invoice_number",
    title: t("invoice_number"),
    width: 120,
    textAlign: TextAlignStyle.LEFT,
  },
  {
    key: "task_code",
    title: t("task_code"),
    width: 120,
    textAlign: TextAlignStyle.LEFT,
  },
];
const AllocatedExpenseScreen = ({ navigation, route }) => {
  const { t } = useTranslation("app/screens/Expense/AllocatedExpenseScreen");
  const { expenses } = route?.params;
  const isReady = useInteractionManager();

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("allocated_expense_title", { count: expenses?.length ?? 0 })} />,
    } as any);
  }, [navigation, t]);

  const tableData = useMemo(() => {
    if (!expenses?.length) {
      return [];
    }
    return expenses.map(({ description, uom, amount, customer, cost_center, bu_code, invoice_number, task_code }) => ({
      description,
      uom,
      amount,
      customer,
      cost_center,
      bu_code,
      invoice_number,
      task_code,
    }));
  }, []);
  return (
    <View style={styles.container}>
      {!isReady ? (
        <LoadingView />
      ) : (
        <CustomTable isScrollTable={true} columns={columns(t)} bordered dataSource={tableData} />
      )}
    </View>
  );
};
export default AllocatedExpenseScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#fff", paddingHorizontal: 10 },
});
