import React, { FC, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Platform, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";

import { AppText, BottomSheetFlatListCustom, IconCustom, SearchInput } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";
import RecentList from "./RecentList";
import EmployeeList from "./EmployeeList";
import { EmployeeChooserContext } from "./EmployeeChooserContext";
import EmployeeItem, { EmployeeOption } from "./EmployeeOption";
import { Keys } from "constants/Keys";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useAuth } from "contexts/AuthContext";
import useEmployeesQuery from "./useEmployeesQuery";
import { NetworkStatus } from "@apollo/client";

interface EmployeeChooserProps {
  initialValue?: EmployeeOption;
  onValueChange?: (item) => void;
}

const EmployeeChooser: FC<EmployeeChooserProps> = ({ initialValue, onValueChange }) => {
  const { t } = useTranslation("app/components/AdvanceSearch/EmployeeChooser");

  const {
    company: { company_id: companyId },
  } = useAuth();
  const [, { loading, networkStatus, data, refetch, fetchMore }] = useEmployeesQuery({
    where: { companyId },
    limit: CONSTANTS.COMMON.PAGE_SIZE,
    offset: 0,
  });
  const employees = (data?.orgEmployees?.data ?? []) as OrgEmployee[];
  const lastItemLoaded = useRef(false);

  const [chosen, setChosen] = useState(initialValue);
  useEffect(() => {
    onValueChange(chosen);
  }, [chosen, onValueChange]);

  const [recentChosen, setRecentChosen] = useState<EmployeeOption[]>([]);
  const getRecentChosen = async () => {
    const employeeLocals = await AsyncStorage.getItem(Keys.RECENT_CHOOSE_REPORT_EMPLOYEE);

    if (employeeLocals && Array.isArray(JSON.parse(employeeLocals)) && employeeLocals.length > 0) {
      setRecentChosen(JSON.parse(employeeLocals));
    }
  };
  useEffect(() => {
    getRecentChosen();
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle search
  const debounceSearchTimeoutId = useRef(null);
  const [textSearch, setTextSearch] = useState<string>("");
  const textSearchRef = useRef<TextInput>();

  // auto focus when open modal select employee
  useEffect(() => {
    if (Platform.OS !== "web") {
      setTimeout(() => {
        textSearchRef?.current?.focus();
      }, 100);
    }
  }, []);

  const handleClearSearch = async () => {
    setTextSearch("");
    textSearchRef?.current?.setNativeProps({ text: "" });
  };
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      setTextSearch(text);
    }, 500);
  };

  const renderItem = ({ item }) => {
    const employee = item as EmployeeOption;
    const checked = chosen?.region === "outside" && chosen?.employeeId === employee.employeeId;
    return (
      <EmployeeItem
        employee={employee}
        checked={checked}
        onPress={() => {
          if (checked) {
            return;
          }
          setChosen({ ...employee, region: "outside" });
        }}
      />
    );
  };

  const handleLoadMore = () => {
    if (
      employees.length < CONSTANTS.COMMON.PAGE_SIZE ||
      lastItemLoaded.current ||
      networkStatus !== NetworkStatus.ready
    ) {
      return;
    }

    fetchMore({
      variables: { offset: employees?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        const lastPatchSize = fetchMoreResult.orgEmployees.data.length;
        if (lastPatchSize === 0) {
          lastItemLoaded.current = true;
          return prev;
        }

        if (lastPatchSize < CONSTANTS.COMMON.PAGE_SIZE) {
          lastItemLoaded.current = true;
        }
        return {
          orgEmployees: {
            data: [...prev.orgEmployees.data, ...fetchMoreResult.orgEmployees.data],
          },
        };
      },
    });
  };

  const renderFooter = () => {
    if (loading && employees?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };

  return (
    <EmployeeChooserContext.Provider value={{ chosen, setChosen, recentChosen, setRecentChosen }}>
      <View style={styles.container}>
        <View style={styles.filterContainer}>
          <SearchInput
            numberOfLines={1}
            style={styles.searchInput}
            ref={textSearchRef}
            placeholder={t("search")}
            onChangeText={onChangeSearch}
            right={
              textSearch ? (
                <TouchableOpacity onPress={handleClearSearch}>
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : null
            }
          />
        </View>

        {textSearch ? (
          <EmployeeList textSearch={textSearch} />
        ) : (
          <BottomSheetFlatListCustom
            contentContainerStyle={styles.contentContainer}
            ListHeaderComponent={
              <View>
                <RecentList initialValue={initialValue} />
                <AppText style={[styles.sectionTitle, Fonts.H300]}>{t("employee_list")}</AppText>
              </View>
            }
            data={employees}
            showsVerticalScrollIndicator={false}
            renderItem={renderItem}
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.2}
            ListFooterComponent={renderFooter}
          />
        )}
      </View>
    </EmployeeChooserContext.Provider>
  );
};

export default EmployeeChooser;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  filterContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 4,
  },
  searchInput: { ...Fonts.BodySmall, color: Colors.grayscale100, backgroundColor: Colors.white },
  sectionTitle: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 10,
  },
  contentContainer: { paddingBottom: 56 },
  loadingMore: { marginTop: 10 },
});
