// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  LayoutChangeEvent,
  ScrollView,
  TextStyle,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";
import { Checkbox, Divider, Menu, TextInput, TouchableRipple, useTheme } from "react-native-paper";
import React, { ReactNode, forwardRef, useEffect, useState, useCallback, Fragment, useRef } from "react";
import { Theme } from "react-native-paper/lib/typescript/types";
import { TextInputProps } from "react-native-paper/lib/typescript/components/TextInput/TextInput";
import Colors from "constants/Colors";
import useOnClickOutside from "hooks/Web/useOnClickOutside";

type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

export interface DropDownPropsInterface {
  visible: boolean;
  multiSelect?: boolean;
  onDismiss: () => void;
  showDropDown: () => void;
  value: any;
  setValue: (_value: any) => void;
  label?: string | undefined;
  placeholder?: string | undefined;
  mode?: "outlined" | "flat" | undefined;
  inputProps?: TextInputPropsWithoutTheme;
  list: Array<{
    label: string;
    value: string | number;
    custom?: ReactNode;
  }>;
  dropDownContainerMaxHeight?: number;
  dropDownContainerHeight?: number;
  activeColor?: string;
  theme?: Theme;
  dropDownStyle?: ViewStyle;
  dropDownItemSelectedTextStyle?: TextStyle;
  dropDownItemSelectedStyle?: ViewStyle;
  dropDownItemStyle?: ViewStyle;
  dropDownItemTextStyle?: TextStyle;
  accessibilityLabel?: string;
}

type TextInputPropsWithoutTheme = Without<TextInputProps, "theme">;

const DropDown = forwardRef<TouchableWithoutFeedback, DropDownPropsInterface>((props, ref) => {
  const { width: windowWidth } = useWindowDimensions();
  const activeTheme = useTheme();
  const {
    multiSelect = false,
    visible,
    onDismiss,
    showDropDown,
    value,
    setValue,
    activeColor,
    mode,
    label,
    placeholder,
    inputProps,
    list,
    dropDownContainerMaxHeight,
    dropDownContainerHeight,
    theme,
    dropDownStyle,
    dropDownItemStyle,
    dropDownItemSelectedStyle,
    dropDownItemTextStyle,
    dropDownItemSelectedTextStyle,
    accessibilityLabel,
  } = props;
  const [displayValue, setDisplayValue] = useState("");
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    left: 0,
    right: 0,
    top: 0,
  });

  const onLayout = (event: LayoutChangeEvent) => {
    setInputLayout(event.nativeEvent.layout);
  };

  const innerRef = useRef();

  useOnClickOutside(innerRef, () => onDismiss);

  useEffect(() => {
    if (multiSelect) {
      const _labels = list
        .filter((_) => value.indexOf(_.value) !== -1)
        .map((_) => _.label)
        .join(", ");
      setDisplayValue(_labels);
    } else {
      const _label = list.find((_) => _.value === value)?.label;
      if (_label) {
        setDisplayValue(_label);
      }
    }
  }, [list, value]);

  const isActive = useCallback(
    (currentValue: any) => {
      if (multiSelect) {
        return value.indexOf(currentValue) !== -1;
      } else {
        return value === currentValue;
      }
    },
    [value]
  );

  const adjustAnchorPoint = (e: any) => {
    const { width: menuWidth, height: menuHeight, x: menuLeft, y: menuTop } = e.nativeEvent.layout;

    const left = menuLeft + menuWidth > windowWidth ? inputLayout.left - menuWidth : inputLayout.left;

    const top =
      menuTop + menuHeight > document.body.clientHeight // <-- calculate from top of document instead of window
        ? inputLayout.top - menuHeight
        : inputLayout.top;

    // setInputLayout();
  };

  const setActive = useCallback(
    (currentValue: any) => {
      if (multiSelect) {
        const valueIndex = value.indexOf(currentValue);
        const values = value.split(",");
        if (valueIndex === -1) {
          setValue([...values, currentValue].join(","));
        } else {
          setValue([...values].filter((value) => value !== currentValue).join(","));
        }
      } else {
        setValue(currentValue);
      }
    },
    [value]
  );

  return (
    <Menu
      visible={visible}
      onDismiss={onDismiss}
      theme={{
        colors: {
          surface: "white",
        },
      }}
      anchor={
        <TouchableRipple ref={ref} onPress={showDropDown} accessibilityLabel={accessibilityLabel}>
          <View pointerEvents={"none"} onLayout={onLayout} ref={innerRef}>
            <TextInput
              value={displayValue}
              mode={mode}
              label={label}
              placeholder={placeholder}
              pointerEvents={"none"}
              underlineColor="transparent"
              theme={theme}
              style={{
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                borderRadius: 8,
                backgroundColor: Colors.whiteBackground,
                borderWidth: 1,
                borderColor: Colors.gray10,
              }}
              right={<TextInput.Icon name={visible ? "menu-up" : "menu-down"} />}
              {...inputProps}
            />
          </View>
        </TouchableRipple>
      }
      style={{
        maxWidth: inputLayout?.width,
        width: inputLayout?.width,
        marginTop: inputLayout?.height,
        top: inputLayout.top,
        ...dropDownStyle,
      }}
    >
      {visible && (
        <ScrollView
          bounces={false}
          style={{
            ...(dropDownContainerHeight
              ? {
                  height: dropDownContainerHeight,
                }
              : {
                  maxHeight: dropDownContainerMaxHeight || 200,
                }),
          }}
        >
          <View style={{ flex: 1 }} onLayout={adjustAnchorPoint}>
            {list.map((_item, _index) => (
              <Fragment key={_item.value}>
                <TouchableRipple
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    setActive(_item.value);
                    if (onDismiss) {
                      onDismiss();
                    }
                  }}
                >
                  <Fragment>
                    <Menu.Item
                      titleStyle={{
                        color: isActive(_item.value)
                          ? activeColor || (theme || activeTheme).colors.primary
                          : (theme || activeTheme).colors.text,
                        ...(isActive(_item.value) ? dropDownItemSelectedTextStyle : dropDownItemTextStyle),
                      }}
                      title={_item.custom || _item.label}
                      style={{
                        flex: 1,
                        maxWidth: inputLayout?.width,
                        ...(isActive(_item.value) ? dropDownItemSelectedStyle : dropDownItemStyle),
                      }}
                    />
                    {multiSelect && (
                      <Checkbox.Android
                        theme={{
                          colors: { accent: activeTheme?.colors.primary },
                        }}
                        status={isActive(_item.value) ? "checked" : "unchecked"}
                        onPress={() => setActive(_item.value)}
                      />
                    )}
                  </Fragment>
                </TouchableRipple>
                <Divider />
              </Fragment>
            ))}
          </View>
        </ScrollView>
      )}
    </Menu>
  );
});

export default DropDown;
