import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MobileBatchApproveReportsMutation, MobileBatchApproveReportsMutationVariables } from "types";

const BATCH_APPROVE_REPORTS = gql`
  mutation MobileBatchApproveReports($reportIds: [UUID!]!) {
    expenseApproveReports(report_ids: $reportIds) {
      reportId
      success
      message
      status
    }
  }
`;
const useBatchApproveReports = () =>
  useMutation<MobileBatchApproveReportsMutation, MobileBatchApproveReportsMutationVariables>(BATCH_APPROVE_REPORTS);

export default useBatchApproveReports;
