import { i18n } from "i18next";
import isEmpty from "lodash/isEmpty";
import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import {
  CircleCheckedBox,
  CloseSearchIcon,
  EmptyExpenseRequest,
  MoneySendOutlined,
  SearchIcon,
  SearchNotFoundIcon,
  BizziBotWarningCircle,
} from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetModalCustomMethods,
  CurrencyText,
  EmptyData,
  IconCustom,
  Line,
  SearchInput,
} from "components";
import { CONSTANTS } from "constants/constants";
import LanguageStatus from "constants/LanguageStatus";
import RequestCategoryStatus from "constants/RequestCategoryStatus";
import RequestStatus from "constants/RequestStatus";
import { useAssignableExpenseRequest } from "hooks/request/useAssignableExpenseRequest";
import { Colors, Fonts, FontTypes } from "theme";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import MoneySendIcon from "assets/images/svg/icons/MoneySendIcon";
import dayjs from "dayjs";
import StatusExpenseRequest from "components/ExpenseRequestItem/StatusExpenseRequest";
import { useNavigation } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import { removeAccents } from "utils/searchObject";
import { ExpenseRequest } from "screens/ExpenseRequest/types";

interface CompanyTeamInfo {
  companyTeamId?: string;
  code?: string;
  name?: string;
  nameEn?: string;
}

export interface ExpenseRequestInfo {
  title?: string;
  docSequence?: string;
  expenseRequestType?: string;
  companyTeamId?: string;
  expenseTravelId?: string;
  expenseRequestId?: string;
}

export interface RequestSelectModalProps {
  selectedExpenseRequestId?: string | null;
  selectedCompanyTeam?: CompanyTeamInfo;
  selectedCompanyTeamId?: string | null;
  selectedRequest?: ExpenseRequestInfo;
  onClear?: () => void;
  style?: StyleProp<ViewStyle>;
  label?: string;
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  value?: string;
  title?: string;
}

const Empty = memo(
  ({
    companyTeamName,
    t,
    i18n,
  }: {
    i18n: i18n;
    t: TFunction;
    companyTeamName: RequestSelectModalProps["selectedCompanyTeam"];
  }) => {
    const description = companyTeamName?.companyTeamId
      ? t("empty_text_for_unit", {
          unit: `${companyTeamName.code} - ${
            i18n.language === LanguageStatus.EN && companyTeamName?.nameEn
              ? companyTeamName.nameEn
              : companyTeamName.name
          }`,
        })
      : "";

    return (
      <EmptyData icon={<EmptyExpenseRequest />} title={t("empty_approved_expense_request")} description={description} />
    );
  }
);

const ItemSeparatorComponent = () => {
  return <Line hasBackground={true} size="sm" containerStyle={styles.divider} />;
};
const ExpenseRequestItem = ({ item, onPress, handleViewRequestDetail, isActive }) => {
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const disabled = item?.isLocked;
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        {isActive ? (
          <CircleCheckedBox width={48} height={48} />
        ) : (
          <MoneySendIcon
            background={disabled ? Colors.grayscale05 : Colors.primary0}
            color={disabled ? Colors.grayscale60 : Colors.primary50}
          />
        )}
        <View style={styles.info}>
          <View style={styles.titleContainer}>
            <AppText
              style={[Fonts.BodyMedium, styles.title]}
              numberOfLines={1}
              color={disabled ? Colors.grayscale60 : Colors.grayscale100}
            >
              {item?.title}
            </AppText>
            <CurrencyText style={Fonts.NumericN200} color={disabled ? Colors.grayscale60 : Colors.grayscale100}>
              {item?.totalAmount}
            </CurrencyText>
          </View>
          <View style={styles.titleContainer}>
            <AppText style={Fonts.BodySmall} color={disabled ? Colors.grayscale60 : Colors.grayscale100}>
              {item.docSequence}
            </AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {dayjs(item?.updatedAt).format(CONSTANTS.FORMAT_DAY)}
            </AppText>
          </View>
          <View style={styles.titleContainer}>
            <StatusExpenseRequest
              status={item?.status}
              textColor={disabled ? Colors.grayscale60 : Colors.grayscale100}
            />
            <TouchableOpacity style={styles.viewDetail} onPress={handleViewRequestDetail}>
              <AppText style={Fonts.BodySmall} color={Colors.primary50}>
                {t("view")}
              </AppText>
              <IconCustom name="arrow-right-alt" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const RequestSelectInput: FC<RequestSelectModalProps> = ({
  error,
  style,
  selectedRequest,
  selectedCompanyTeam,
  selectedCompanyTeamId,
  name,
  rules,
  control,
  onClear,
  value,
  label,
}) => {
  // variables
  const onChangeRequest = useRef(null);
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const expenseRequestBottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
  const navigation = useNavigation<any>();
  const [requestData, setRequestData] = useState<ExpenseRequest[]>([]);

  const { i18n, t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const [onFetchData, { data, loading, called }] = useAssignableExpenseRequest();

  const { assignableExpenseRequests } = useMemo(() => {
    const { total = 0, assignableExpenseRequests = [] } = data?.assignableExpenseRequest ?? {};
    return { total, assignableExpenseRequests };
  }, [data?.assignableExpenseRequest]);
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    const requestOptions: Array<ExpenseRequest> = [];
    assignableExpenseRequests.forEach((item) => {
      const {
        title,
        status,
        currency,
        updatedAt,
        totalAmount,
        docSequence,
        companyTeams,
        expenseTravels,
        expenseRequestId,
        expenseRequestType,
        isLocked,
        cashAdvances,
      } = item;

      const companyTeamIds: string[] = companyTeams?.map(({ companyTeamId }) => companyTeamId) ?? [];

      const requestOption: ExpenseRequest = {
        title,
        currency,
        updatedAt,
        totalAmount,
        docSequence,
        expenseTravels,
        companyTeamIds,
        expenseRequestId,
        status: status as RequestStatus,
        expenseRequestType: expenseRequestType as string,
        isLocked,
        cashAdvances,
      };
      if (
        selectedRequest?.expenseRequestType === RequestCategoryStatus.OTHER_REQUEST &&
        selectedRequest?.expenseRequestId === requestOption.expenseRequestId
      ) {
        requestOptions.unshift(requestOption);
        return;
      }
      if (requestOption.expenseTravels?.[0]?.expenseTravelId === selectedRequest?.expenseTravelId) {
        requestOptions.unshift(requestOption);
        return;
      }
      requestOptions.push(requestOption);
    });

    if (!isEmpty(requestOptions) && selectedCompanyTeamId) {
      const options = requestOptions.filter(({ companyTeamIds }) => {
        return companyTeamIds.includes(selectedCompanyTeamId);
      });
      setRequestData(
        options?.filter((item) => {
          const keywordUnicode = removeAccents(keyword).toLowerCase();
          const titleUnicode = removeAccents(item?.title ?? "").toLowerCase();
          const code = (item?.docSequence ?? "").toLowerCase();
          return titleUnicode.includes(keywordUnicode) || code.includes(keywordUnicode);
        })
      );
      return;
    }
    if (!isEmpty(requestOptions)) {
      const options = requestOptions.filter(({ companyTeamIds }) => {
        return isEmpty(companyTeamIds);
      });
      setRequestData(
        options?.filter((item) => {
          const keywordUnicode = removeAccents(keyword).toLowerCase();
          const titleUnicode = removeAccents(item?.title ?? "").toLowerCase();
          const code = (item?.docSequence ?? "").toLowerCase();
          return titleUnicode.includes(keywordUnicode) || code.includes(keywordUnicode);
        })
      );
    }
  }, [assignableExpenseRequests, selectedCompanyTeamId, selectedRequest, keyword]);

  const handleSelectRequest = (item) => () => {
    if (item?.isLocked) {
      alertNotificationRef.current.info({
        icon: <BizziBotWarningCircle />,
        title: t("cannot_select_request_title"),
        description: t("cannot_select_request_description"),
        confirmText: t("understood"),
        onConfirm: () => {
          alertNotificationRef?.current?.close();
        },
      });
      return;
    }
    expenseRequestBottomSheetRef.current?.close();
    const { docSequence, expenseRequestId, title, cashAdvances } = item;
    onChangeRequest?.current?.({ title, docSequence, expenseRequestId, isAdvanceRequest: Boolean(cashAdvances?.[0]) });
  };
  const handleViewRequestDetail = (expenseRequestId: string) => () => {
    navigation.navigate(SCREEN_NAME.DetailRequestScreen, {
      expenseRequestId,
    });
  };
  const renderItem = ({ item }) => {
    const isActive = item?.expenseRequestId === value;
    return (
      <ExpenseRequestItem
        isActive={isActive}
        item={item}
        onPress={handleSelectRequest(item)}
        handleViewRequestDetail={handleViewRequestDetail(item?.expenseRequestId)}
      />
    );
  };

  const isLoading = !called || loading;

  const handleOpenModal = () => {
    setKeyword("");
    expenseRequestBottomSheetRef.current?.present();
    onFetchData?.();
  };
  const getRequestTitle = (request) => {
    return request?.docSequence ? `${request?.docSequence} - ${request?.title}` : "";
  };

  const renderSearch = () => (
    <SearchInput
      style={styles.searchInput}
      left={<SearchIcon />}
      placeholder={t("search")}
      onChangeText={setKeyword}
      autoCapitalize="none"
      enablesReturnKeyAutomatically
      returnKeyType="search"
      value={keyword || ""}
      right={
        keyword ? (
          <TouchableOpacity onPress={() => setKeyword("")}>
            <CloseSearchIcon />
          </TouchableOpacity>
        ) : null
      }
    />
  );
  return (
    <View>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <View style={style}>
            <TouchableOpacity
              onPress={() => {
                onChangeRequest.current = onChange;
                handleOpenModal();
              }}
              style={styles.input}
            >
              {Boolean(value) && <AppText style={styles.label}>{label}</AppText>}
              <AppText
                style={styles.requestTitle}
                color={value ? CONSTANTS.COMMON.TEXT_INPUT_COLOR : CONSTANTS.COMMON.PLACEHOLDER_COLOR}
                numberOfLines={1}
              >
                {value ? getRequestTitle(value) : label}
              </AppText>
              {value ? (
                <TouchableOpacity onPress={onClear}>
                  <CloseSearchIcon />
                </TouchableOpacity>
              ) : (
                <MoneySendOutlined />
              )}
            </TouchableOpacity>
            {Boolean(error?.message) && (
              <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                {error?.message?.toString()}
              </AppText>
            )}
          </View>
        )}
      />
      <BottomSheetFlatListModalCustom
        renderSearch={renderSearch}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        ref={expenseRequestBottomSheetRef}
        title={t("choose", { label: label.toLowerCase() })}
        loading={isLoading}
        listProps={{
          keyboardShouldPersistTaps: "always",
          keyboardDismissMode: "on-drag",
          showsVerticalScrollIndicator: false,
          contentContainerStyle: styles.contentContainer,
          data: requestData,
          keyExtractor: ({ expenseRequestId }) => expenseRequestId,
          renderItem,
          ListEmptyComponent: keyword ? (
            <EmptyData title={t("search_not_found")} icon={<SearchNotFoundIcon />} />
          ) : (
            <Empty i18n={i18n} t={t} companyTeamName={selectedCompanyTeam} />
          ),
          ItemSeparatorComponent,
        }}
      />
      <AlertNotification ref={alertNotificationRef} />
    </View>
  );
};
export default RequestSelectInput;
const styles = StyleSheet.create({
  divider: {
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 45,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    flexDirection: "row",
  },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  requestTitle: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
  container: {
    paddingVertical: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  info: {
    paddingLeft: 16,
    flex: 1,
    marginVertical: -2,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 2,
  },
  title: {
    flex: 1,
    marginRight: 8,
  },
  contentContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: Platform.OS === "web" ? 60 : 20,
  },
  viewDetail: {
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
  },
  searchInput: { marginHorizontal: 20, marginBottom: 5 },
});
