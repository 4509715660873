import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#94A3B8", size = 16 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M9.62 3.953L13.667 8 9.62 12.047M2.333 8h11.22"
        stroke={color}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
