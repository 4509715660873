import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#D9D9D9" d="M0.54834 0.822144H24.54834V24.822144H0.54834z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M8.423 7.568l2.704-3.548c.18-.24.393-.416.64-.529.246-.113.507-.169.781-.169.275 0 .535.056.782.169.247.113.46.289.64.53l2.703 3.547 4.135 1.396c.395.127.703.351.924.672.221.321.332.676.332 1.064 0 .179-.026.357-.079.535a1.65 1.65 0 01-.258.512l-2.692 3.737.1 3.965c.016.527-.157.97-.52 1.331-.365.362-.788.542-1.272.542-.015 0-.182-.022-.502-.065l-4.293-1.231-4.292 1.23a.818.818 0 01-.258.058c-.089.006-.17.008-.244.008-.488 0-.913-.18-1.275-.541a1.726 1.726 0 01-.517-1.332l.1-3.99-2.677-3.712a1.66 1.66 0 01-.258-.514 1.84 1.84 0 01.251-1.588c.22-.324.526-.554.92-.69l4.125-1.387z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
