import { useLazyQuery, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpInvoiceDetailQuery, MobileExpInvoiceDetailQueryVariables } from "types";

const QUERY = gql`
  query MobileExpInvoiceDetail($companyId: UUID!, $invoiceId: UUID!) {
    expInvoiceDetail(input: { companyId: $companyId, invoiceId: $invoiceId }) {
      invoiceType
      totalAmountWithVat
      totalAmountWithoutVat
      totalVatAmount
      sellerLegalName
      sellerTaxCode
      sellerAddressLine
      buyerLegalName
      invoiceId
      buyerAddressLine
      discountAmount
      buyerTaxCode
      buyerDisplayName
      invoiceNumber
      invoiceSeries
      templateCode
      issuedDate
      receivedAt
      invoiceValidations {
        isExpenseInvoiceValid
        invoiceValidationId
        isCorrectCompany
        isLegalSeller
        isSignatureValid
        isDigestValid
        isIssued
        companySnapshot
        tchdScreenshot
        tchdScreenshotAt
        gdtResults
        manualValidations
        signatureErrors
        certValidations
        isVerifying
        validationAggregation
        evidences
      }
      invoiceIo
      invoiceItems {
        itemName
        invoiceItemId
        totalPriceBeforeVat
      }
      expense {
        expenseId
        expenseReport {
          expenseReportId
          status
        }
      }
      validationTask {
        createdAt
        status
        taskId
        canTriggerAfter
      }
    }
  }
`;
const useInvoiceDetailQuery = (variables: MobileExpInvoiceDetailQueryVariables) => {
  return useLazyQuery<MobileExpInvoiceDetailQuery, MobileExpInvoiceDetailQueryVariables>(QUERY, {
    variables,
    fetchPolicy: "network-only",
  });
};

export default useInvoiceDetailQuery;
