// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { ExpUpdateExpenseReportMutation, ExpUpdateExpenseReportMutationVariables } from "types";

const CREATE_EXPENSE_REPORT = gql`
  mutation MobileUpdateExpenseReport($expenseReportId: UUID!, $expenseReport: ExpUpdateExpenseReportInput!) {
    expUpdateExpenseReport(expenseReportId: $expenseReportId, input: $expenseReport) {
      message
      expenseReportId
    }
  }
`;

export const useExpUpdateExpenseReportMutation = (
  options?: MutationHookOptions<ExpUpdateExpenseReportMutation, ExpUpdateExpenseReportMutationVariables>
) => {
  return useMutation(CREATE_EXPENSE_REPORT, options);
};
