import { FC, useState } from "react";

import { StyleProp, View, ViewStyle } from "react-native";

import TextInput, { TextInputProps } from "./TextInput";
import { FieldProps } from "formik";
import { DatePickerModal } from "react-native-paper-dates";
import { CalendarIcon } from "assets/images/svg/icons";
import dayjs from "dayjs";

interface DatetimePickerFieldProps extends TextInputProps {
  label?: string;
  value?: string;
  format?: string;
  placeholder?: string;
  style?: StyleProp<ViewStyle>;
  onChange: (date: any) => void;
  minimumDate: Date | undefined;
  maximumDate: Date | undefined;
}

const DatePickerField = Object.assign(
  (({ label, value, format = "DD/MM/YYYY", placeholder, style, onChange, ...rest }) => {
    const [date, setDate] = useState(value ? dayjs(value).toDate() : dayjs().toDate());
    const [open, setOpen] = useState(false);
    const dismiss = () => {
      setOpen(false);
    };

    const confirm = (params) => {
      setOpen(false);
      setDate(params.date);
      onChange(params.date);
    };

    return (
      <View style={style}>
        <TextInput.ViewOnly
          onPress={() => setOpen(true)}
          value={date ? dayjs(date).format(format) : ""}
          placeholder={placeholder}
          right={<CalendarIcon />}
          label={label}
        />
        <DatePickerModal
          locale={"vi"}
          mode="single"
          visible={open}
          date={date}
          onDismiss={dismiss}
          onConfirm={confirm}
          label={label}
        />
      </View>
    );
  }) as FC<DatetimePickerFieldProps>,
  {
    Formik: (_: FieldProps & DatetimePickerFieldProps) => null,
  }
);

export default DatePickerField;
