import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";
const SVGComponent = ({ width = 48, height = 48, color = "#1F71F4", backgroundColor = "#DCEBFE" }) => (
  <Svg width={width} height={height} viewBox="0 0 48 48" fill="none">
    <Rect width={width} height={height} rx={24} fill={backgroundColor} />
    <Path
      d="M16.1704 27.2998L20.7004 31.8298C22.5604 33.6898 25.5804 33.6898 27.4504 31.8298L31.8404 27.4398C33.7004 25.5798 33.7004 22.5598 31.8404 20.6898L27.3004 16.1698C26.3504 15.2198 25.0404 14.7098 23.7004 14.7798L18.7004 15.0198C16.7004 15.1098 15.1104 16.6998 15.0104 18.6898L14.7704 23.6898C14.7104 25.0398 15.2204 26.3498 16.1704 27.2998Z"
      fill={color}
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21.5 24C22.8807 24 24 22.8807 24 21.5C24 20.1193 22.8807 19 21.5 19C20.1193 19 19 20.1193 19 21.5C19 22.8807 20.1193 24 21.5 24Z"
      fill="white"
    />
  </Svg>
);
export default SVGComponent;
