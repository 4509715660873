import React, { FC, useMemo, useRef, useState } from "react";
import {
  AppText,
  BackHeader,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  CurrencyText,
  HorizontalBarChart,
  IconCustom,
  LoadingView,
  RadioSelectFilter,
  SelectCompanyTeam,
} from "components";
import { Colors, Fonts } from "theme";
import { StyleSheet, View } from "react-native";
import useSpendingTrendCategoryQuery from "screens/ExpenseOverview/DashboardDetailScreen/hooks/useSpendingTrendCategoryQuery";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { getTitleFromCategory, getTitleFromCompanyTeam } from "utils";
import { CHART_TYPE, EmployeeItem, SORT_OPTIONS } from "screens/ExpenseOverview/DashboardDetailScreen/types";
import dayjs from "dayjs";
import { CONSTANTS } from "constants/constants";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { MobileExpSpendingTrendCategoryQuery } from "types";
import FilterByCategoryButton from "screens/ExpenseOverview/DashboardDetailScreen/components/FilterByCategoryButton";
import { ExpenseCategory } from "./types";
import { CompanyTeam } from "components/AdvanceSearch/SelectCompanyTeam";
import { getLastName, sortChartData } from "screens/ExpenseOverview/DashboardDetailScreen/helpers";
import FilterByEmployeeButton from "screens/ExpenseOverview/DashboardDetailScreen/components/FilterByEmployeeButton";

enum SCREEN_CODE {
  CATEGORY_110 = "CATEGORY_110",
  EMPLOYEE_100 = "EMPLOYEE_100",
  EMPLOYEE_101 = "EMPLOYEE_101",
}
enum LIMIT_TYPES {
  SPENDING_PLAN = "SPENDING_PLAN",
  BUDGET = "BUDGET",
}

type GetScreenCodeArgs = {
  type: CHART_TYPE;
  teamId?: string;
  employeeId?: string;
  categoryId?: string;
};

const getScreenCode = ({ type, teamId, employeeId, categoryId }: GetScreenCodeArgs): SCREEN_CODE => {
  return `${type}_${Number(!!teamId)}${Number(!!employeeId)}${Number(!!categoryId)}` as SCREEN_CODE;
};

type SelectItem = {
  name: string;
  nameEn: string;
  budgetAmount: number;
  spendingPlanAmount: number;
  actualSpendingAmount: number;
};

type SummarySectionProps = {
  type: LIMIT_TYPES;
  fromDate: string;
  toDate: string;
  data: MobileExpSpendingTrendCategoryQuery;
  screenCode?: SCREEN_CODE;
};

const SummarySection: FC<SummarySectionProps> = ({ type, fromDate, toDate, data, screenCode }) => {
  const { t } = useTranslation("app/screens/ExpenseOverview/DashboardDetailScreen");

  const totalBudgetAmount = useMemo(() => {
    if (!data?.expSpendingTrend?.spendingCategory) {
      return 0;
    }
    return data?.expSpendingTrend?.spendingCategory.reduce((total, current) => total + current.budgetAmount, 0);
  }, [data]);

  const employeeSpendingPlanAmount = useMemo(() => {
    if (!data?.expSpendingTrend?.spendingEmployee) {
      return 0;
    }
    return data?.expSpendingTrend?.spendingEmployee.reduce((total, current) => total + current.spendingPlanAmount, 0);
  }, [data]);

  const totalActualSpendingAmount = useMemo(() => {
    if (screenCode === SCREEN_CODE.CATEGORY_110 || screenCode === SCREEN_CODE.EMPLOYEE_101) {
      return (
        data?.expSpendingTrend?.spendingEmployee?.reduce((total, current) => total + current.actualSpendingAmount, 0) ||
        0
      );
    }
    return (
      data?.expSpendingTrend?.spendingCategory?.reduce((total, current) => total + current.actualSpendingAmount, 0) || 0
    );
  }, [data]);
  const hadBudget = data?.expSpendingTrend?.budget !== null;

  const getTimeLabel = () => {
    const newFromDate = dayjs(fromDate);
    const newToDate = dayjs(toDate);
    if (
      newFromDate.isSame(newToDate, "month") &&
      newFromDate.isSame(newToDate, "year") &&
      newToDate.isSame(dayjs(), "day")
    ) {
      return newToDate.format("MM/YYYY");
    } else if (newFromDate.isSame(newToDate, "month") && newFromDate.isSame(newToDate, "year")) {
      return newFromDate.format("MM/YYYY");
    } else if (newFromDate.isSame(newToDate, "year")) {
      return `${newFromDate.format("MM")}-${newToDate.format("MM/YYYY")}`;
    }
    return `${newFromDate.format("MM/YYYY")}-${newToDate.format("MM/YYYY")}`;
  };

  if (type === LIMIT_TYPES.SPENDING_PLAN) {
    return (
      <View style={styles.section}>
        <AppText style={[Fonts.H300, styles.mb15]}>
          {t("total")} {getTimeLabel()}
        </AppText>
        <View style={styles.descriptionRow}>
          <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("expense_approved")}</AppText>
          <CurrencyText style={Fonts.NumericN200}>{totalActualSpendingAmount}</CurrencyText>
        </View>
        <View style={[styles.descriptionRow, styles.mt15]}>
          <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("spending_plan")}</AppText>
          <CurrencyText style={Fonts.NumericN200}>{employeeSpendingPlanAmount}</CurrencyText>
        </View>
      </View>
    );
  }

  const isOverBudget = hadBudget && totalActualSpendingAmount - totalBudgetAmount > 0;
  return (
    <View style={styles.section}>
      <AppText style={[Fonts.H300, styles.mb15]}>
        {t("total")} {getTimeLabel()}
      </AppText>
      {hadBudget && (
        <View style={[styles.descriptionRow, styles.mb15]}>
          <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("budget")}</AppText>
          <CurrencyText style={Fonts.NumericN200}>{totalBudgetAmount}</CurrencyText>
        </View>
      )}
      <View style={styles.descriptionRow}>
        <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("expense_approved")}</AppText>
        <CurrencyText style={Fonts.NumericN200}>{totalActualSpendingAmount}</CurrencyText>
      </View>
      {hadBudget && (
        <View style={[styles.descriptionRow, styles.mt15]}>
          <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("remaining_budget")}</AppText>
          <CurrencyText style={Fonts.NumericN200} color={isOverBudget ? Colors.alert50 : Colors.grayscale100}>
            {totalBudgetAmount - totalActualSpendingAmount}
          </CurrencyText>
        </View>
      )}
    </View>
  );
};

const DashboardDetailScreen = ({ route }) => {
  const { title, subTitle, employeeId, categoryId, companyTeamId, fromDate, toDate, type } = route?.params ?? {};
  const { t } = useTranslation("app/screens/ExpenseOverview/DashboardDetailScreen");
  const { company } = useAuth();
  const [currentSort, setCurrentSort] = useState({ label: t("expense_dec"), value: SORT_OPTIONS.EXPENSE_DEC });
  const [currentCategory, setCurrentCategory] = useState<ExpenseCategory>(
    categoryId ? { expenseCategoryId: categoryId, title: subTitle } : null
  );
  const [currentCompanyTeam, setCurrentCompanyTeam] = useState<CompanyTeam>(
    typeof companyTeamId !== "undefined" ? { teamId: companyTeamId, name: subTitle } : null
  );
  const [currentEmployee, setCurrentEmployee] = useState<EmployeeItem>(
    employeeId ? { employeeId, fullname: subTitle } : null
  );
  const [chartType, setChartType] = useState<CHART_TYPE>(type);

  const { data, loading, refetch } = useSpendingTrendCategoryQuery({
    input: {
      companyId: company.company_id,
      expenseCategoryIds: categoryId ? [categoryId] : undefined,
      employeeIds: employeeId ? [employeeId] : undefined,
      fromDate,
      toDate,
      companyTeamIds: typeof companyTeamId !== "undefined" ? [companyTeamId] : undefined,
    },
  });
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
  const [selectedItem, setSelectedItem] = useState<SelectItem>(null);

  const screenCode = useMemo(() => {
    return getScreenCode({ type, teamId: companyTeamId, employeeId, categoryId });
  }, [type, companyTeamId, employeeId, categoryId]);
  const hadBudget = data?.expSpendingTrend?.budget !== null;

  const limitType =
    !hadBudget || [SCREEN_CODE.CATEGORY_110, SCREEN_CODE.EMPLOYEE_100, SCREEN_CODE.EMPLOYEE_101].includes(screenCode)
      ? LIMIT_TYPES.SPENDING_PLAN
      : LIMIT_TYPES.BUDGET;
  const sortOptions = useMemo(() => {
    return [
      { label: t("expense_dec"), value: SORT_OPTIONS.EXPENSE_DEC },
      { label: t("expense_asc"), value: SORT_OPTIONS.EXPENSE_ASC },
      {
        label: limitType === LIMIT_TYPES.SPENDING_PLAN ? t("spending_plan_dec") : t("budget_dec"),
        value: SORT_OPTIONS.BUDGET_DEC,
      },
      {
        label: limitType === LIMIT_TYPES.SPENDING_PLAN ? t("spending_plan_asc") : t("budget_asc"),
        value: SORT_OPTIONS.BUDGET_ASC,
      },
      { label: t("alphabet"), value: SORT_OPTIONS.ALPHABET },
    ];
  }, [limitType]);
  const renderFilterButton = () => {
    if ((chartType === CHART_TYPE.CATEGORY && !currentCompanyTeam) || currentCategory) {
      return (
        <FilterByCategoryButton
          isFiltering={Boolean(currentCategory)}
          setValue={(value) => {
            /*-- filter by category --> show chart company team --*/
            if (value) {
              setChartType(CHART_TYPE.TEAM);
            } else {
              setChartType(CHART_TYPE.CATEGORY);
            }
            if (value?.expenseCategoryId !== currentCategory?.expenseCategoryId) {
              refetch({
                input: {
                  companyId: company.company_id,
                  expenseCategoryIds: value ? [value.expenseCategoryId] : undefined,
                  employeeIds: employeeId ? [employeeId] : undefined,
                  fromDate,
                  toDate,
                },
              });
            }
            setCurrentCategory(value);
          }}
          value={currentCategory}
          title={t("select_category")}
        />
      );
    } else if (chartType === CHART_TYPE.EMPLOYEE || currentEmployee) {
      return (
        <FilterByEmployeeButton
          isFiltering={Boolean(currentEmployee)}
          setValue={(value) => {
            if (value) {
              setChartType(CHART_TYPE.CATEGORY);
            } else {
              setChartType(CHART_TYPE.EMPLOYEE);
            }
            if (value?.employeeId !== currentEmployee?.employeeId) {
              refetch({
                input: {
                  companyId: company.company_id,
                  companyTeamIds: currentCompanyTeam ? [currentCompanyTeam.teamId] : undefined,
                  employeeIds: value?.employeeId ? [value.employeeId] : undefined,
                  fromDate,
                  toDate,
                },
              });
            }
            setCurrentEmployee(value);
          }}
          value={currentEmployee}
          title={t("select_employee")}
        />
      );
    }
    return (
      <SelectCompanyTeam
        type="filter"
        setValue={(value) => {
          /*-- filter by category --> show chart company team --*/
          if (value) {
            setChartType(CHART_TYPE.CATEGORY);
          } else {
            setChartType(CHART_TYPE.TEAM);
          }
          if (value?.teamId !== currentCompanyTeam?.teamId) {
            refetch({
              input: {
                companyId: company.company_id,
                employeeIds: employeeId ? [employeeId] : undefined,
                companyTeamIds: value?.teamId ? [value.teamId] : undefined,
                fromDate,
                toDate,
              },
            });
          }
          setCurrentCompanyTeam(value);
        }}
        value={currentCompanyTeam}
        title={t("select_company_team")}
      />
    );
  };
  const renderSubTitlePage = () => {
    if (currentCategory) {
      return getTitleFromCategory({ title: currentCategory.title, titleEn: currentCategory.titleEn });
    }
    if (currentEmployee) {
      return currentEmployee.fullname;
    }
    if (currentCompanyTeam) {
      return getTitleFromCompanyTeam({
        code: currentCompanyTeam?.code,
        name: currentCompanyTeam?.name,
        nameEn: currentCompanyTeam?.nameEn,
      });
    }
    if (chartType === CHART_TYPE.CATEGORY) {
      return t("all_category");
    } else if (chartType === CHART_TYPE.TEAM) {
      return t("all_company_team");
    }
  };
  const spendingTeams = useMemo(() => {
    if (!data?.expSpendingTrend?.spendingTeam) {
      return [];
    }
    return data?.expSpendingTrend?.spendingTeam
      ?.filter((item) => {
        if (limitType === LIMIT_TYPES.SPENDING_PLAN) {
          return item?.actualSpendingAmount || item.spendingPlanAmount;
        }
        return item?.actualSpendingAmount || item?.budgetAmount;
      })
      ?.map((item) => ({
        title: getTitleFromCompanyTeam({
          code: item?.code,
          name: item?.name || t("general_expense"),
          nameEn: item?.nameEn,
        }),
        amount: item?.actualSpendingAmount,
        limitedAmount: limitType === LIMIT_TYPES.SPENDING_PLAN ? item.spendingPlanAmount : item?.budgetAmount,
        metadata: item,
      }))
      ?.sort((a, b) => {
        return sortChartData(a, b, currentSort);
      });
  }, [data?.expSpendingTrend?.spendingTeam, limitType, t, currentSort?.value]);
  const spendingCategories = useMemo(() => {
    if (!data?.expSpendingTrend?.spendingCategory) {
      return [];
    }
    return data?.expSpendingTrend?.spendingCategory
      ?.filter((item) => {
        if (limitType === LIMIT_TYPES.SPENDING_PLAN) {
          return item?.actualSpendingAmount || item.spendingPlanAmount;
        }
        return item?.budgetAmount || item?.actualSpendingAmount;
      })
      ?.map((item) => ({
        title: getTitleFromCategory({ title: item?.title, titleEn: item?.titleEn }),
        amount: item?.actualSpendingAmount,
        limitedAmount: limitType === LIMIT_TYPES.SPENDING_PLAN ? item.spendingPlanAmount : item?.budgetAmount,
        metadata: item,
      }))
      ?.sort((a, b) => {
        return sortChartData(a, b, currentSort);
      });
  }, [data?.expSpendingTrend?.spendingCategory, limitType, currentSort?.value]);

  const spendingEmployees = useMemo(() => {
    if (!data?.expSpendingTrend?.spendingEmployee) {
      return [];
    }
    return data?.expSpendingTrend?.spendingEmployee
      ?.filter((item) => item?.actualSpendingAmount || item?.spendingPlanAmount)
      ?.map((item) => ({
        title: item?.fullname,
        amount: item?.actualSpendingAmount,
        limitedAmount: limitType === LIMIT_TYPES.SPENDING_PLAN ? item.spendingPlanAmount : undefined,
        metadata: item,
      }))
      ?.sort((a, b) => {
        return sortChartData({ ...a, title: getLastName(a.title) }, { ...b, title: getLastName(b.title) }, currentSort);
      });
  }, [data?.expSpendingTrend?.spendingEmployee, limitType, currentSort?.value]);

  const chartData = {
    [CHART_TYPE.CATEGORY]: spendingCategories,
    [CHART_TYPE.TEAM]: spendingTeams,
    [CHART_TYPE.EMPLOYEE]: spendingEmployees,
  }[chartType];

  const getChartLabel = () => {
    if (currentCategory) {
      return t(spendingTeams?.length > 1 ? "allocated_to_units" : "allocated_to_unit", {
        amount: spendingTeams?.length,
      });
    } else if (currentCompanyTeam) {
      return t(spendingCategories?.length > 1 ? "allocated_to_categories" : "allocated_to_category", {
        amount: spendingCategories?.length,
      });
    }
    switch (type) {
      case CHART_TYPE.CATEGORY: {
        return t(spendingCategories?.length > 1 ? "allocated_to_categories" : "allocated_to_category", {
          amount: spendingCategories?.length,
        });
      }
      case CHART_TYPE.TEAM: {
        return t(spendingTeams?.length > 1 ? "allocated_to_units" : "allocated_to_unit", {
          amount: spendingTeams?.length,
        });
      }
      case CHART_TYPE.EMPLOYEE: {
        return t(spendingEmployees?.length > 1 ? "allocated_to_employees" : "allocated_to_employee", {
          amount: spendingEmployees?.length,
        });
      }
    }
  };

  const renderModalContent = () => {
    if (!selectedItem) {
      return null;
    }

    const { budgetAmount, spendingPlanAmount, actualSpendingAmount } = selectedItem;
    const isOverSpendingPlan = actualSpendingAmount > spendingPlanAmount;
    if (limitType === LIMIT_TYPES.SPENDING_PLAN) {
      return (
        <View style={[styles.chartModalBody, styles.legend]}>
          <View style={[styles.flexRow, styles.spaceBetween, styles.gap8]}>
            <View style={[styles.flexRow, styles.flex, styles.gap8]}>
              <View style={[styles.legendDot, { backgroundColor: Colors.primary50 }]} />
              <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("approved_expenses_under_spending_plan")}</AppText>
            </View>
            <CurrencyText style={Fonts.NumericN200}>{Math.min(actualSpendingAmount, spendingPlanAmount)}</CurrencyText>
          </View>
          {isOverSpendingPlan && (
            <View style={[styles.flexRow, styles.spaceBetween, styles.gap8]}>
              <View style={[styles.flexRow, styles.flex, styles.gap8]}>
                <View style={[styles.legendDot, { backgroundColor: Colors.alert50 }]} />
                <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("approved_expenses_over_spending_plan")}</AppText>
              </View>
              <CurrencyText style={Fonts.NumericN200}>{actualSpendingAmount - spendingPlanAmount}</CurrencyText>
            </View>
          )}
          <View style={[styles.flexRow, styles.spaceBetween, styles.gap8]}>
            <View style={[styles.flexRow, styles.flex, styles.gap8]}>
              <View style={[styles.legendDot, { backgroundColor: Colors.success50 }]} />
              <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("spending_plan")}</AppText>
            </View>
            <CurrencyText style={Fonts.NumericN200}>{spendingPlanAmount}</CurrencyText>
          </View>
        </View>
      );
    }

    const isOverBudget = actualSpendingAmount > budgetAmount && hadBudget;
    return (
      <View style={[styles.chartModalBody, styles.legend]}>
        <View style={[styles.flexRow, styles.spaceBetween, styles.gap8]}>
          <View style={[styles.flexRow, styles.flex, styles.gap8]}>
            <View style={[styles.legendDot, { backgroundColor: Colors.primary50 }]} />
            <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("approved_expenses_under_budget")}</AppText>
          </View>
          <CurrencyText style={Fonts.NumericN200}>{Math.min(actualSpendingAmount, budgetAmount)}</CurrencyText>
        </View>
        {isOverBudget && (
          <View style={[styles.flexRow, styles.spaceBetween, styles.gap8]}>
            <View style={[styles.flexRow, styles.flex, styles.gap8]}>
              <View style={[styles.legendDot, { backgroundColor: Colors.alert50 }]} />
              <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("approved_expenses_over_budget")}</AppText>
            </View>
            <CurrencyText style={Fonts.NumericN200}>{actualSpendingAmount - budgetAmount}</CurrencyText>
          </View>
        )}
        {hadBudget && (
          <View style={[styles.flexRow, styles.spaceBetween, styles.gap8]}>
            <View style={[styles.flexRow, styles.flex, styles.gap8]}>
              <View style={[styles.legendDot, { backgroundColor: Colors.warning50 }]} />
              <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("budget")}</AppText>
            </View>
            <CurrencyText style={Fonts.NumericN200}>{budgetAmount}</CurrencyText>
          </View>
        )}
      </View>
    );
  };

  const renderLegend = () => {
    if (limitType === LIMIT_TYPES.SPENDING_PLAN) {
      return (
        <View style={styles.legend}>
          <View style={styles.legendItem}>
            <View style={[styles.legendDot, { backgroundColor: Colors.primary50 }]} />
            <AppText>{t("approved_expenses_under_spending_plan")}</AppText>
          </View>
          {chartData.some((x) => x.amount > x.metadata.spendingPlanAmount) && (
            <View style={styles.legendItem}>
              <View style={[styles.legendDot, { backgroundColor: Colors.alert50 }]} />
              <AppText>{t("approved_expenses_over_spending_plan")}</AppText>
            </View>
          )}
          <View style={styles.legendItem}>
            <View style={[styles.legendDot, { backgroundColor: Colors.success50 }]} />
            <AppText>{t("spending_plan")}</AppText>
          </View>
        </View>
      );
    }

    return (
      <View style={styles.legend}>
        <View style={styles.legendItem}>
          <View style={[styles.legendDot, { backgroundColor: Colors.primary50 }]} />
          <AppText>{t("approved_expenses_under_budget")}</AppText>
        </View>

        {hadBudget && (
          <>
            {chartData.some((x) => x.amount > x.limitedAmount) && (
              <View style={styles.legendItem}>
                <View style={[styles.legendDot, { backgroundColor: Colors.alert50 }]} />
                <AppText>{t("approved_expenses_over_budget")}</AppText>
              </View>
            )}
            <View style={styles.legendItem}>
              <View style={[styles.legendDot, { backgroundColor: Colors.warning50 }]} />
              <AppText>{t("budget")}</AppText>
            </View>
          </>
        )}
      </View>
    );
  };

  const [sectionBoundingRect, setSectionBoundingRect] = useState(null);
  const [containerBoundingRect, setContainerBoundingRect] = useState(null);
  const translationY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler((event) => {
    translationY.value = event.contentOffset.y;
  });

  const animatedStyle = useAnimatedStyle(() => {
    if ([sectionBoundingRect, containerBoundingRect].some((x) => !x)) {
      return {};
    }

    // 1. in case scroll offset less than legend's y we transform nothing
    // 2. otherwise the legend is shifted downward by a distance equal to the difference between the scroll offset and the legend's y.
    const distanceToTop = sectionBoundingRect.y + containerBoundingRect.y;
    const translateY = Math.max(translationY.value - distanceToTop, 0);

    // 3. If the distance exceeds the section height, it will be adjusted to the section height.
    const yMax = containerBoundingRect.height - styles.section.padding;
    const interpolateTranslateY = interpolate(translateY, [0, yMax], [0, yMax], Extrapolate.CLAMP);

    return {
      transform: [
        {
          translateY: interpolateTranslateY,
        },
      ],
    };
  }, [sectionBoundingRect, containerBoundingRect]);

  return (
    <View style={styles.container}>
      <BackHeader headerTitle={title} description={renderSubTitlePage()} headerRight={renderFilterButton()} />
      {loading ? (
        <LoadingView />
      ) : (
        <Animated.ScrollView
          scrollEventThrottle={16}
          onScroll={scrollHandler}
          contentContainerStyle={styles.contentContainer}
          showsVerticalScrollIndicator={false}
        >
          <SummarySection screenCode={screenCode} type={limitType} fromDate={fromDate} toDate={toDate} data={data} />
          <View
            style={styles.section}
            onLayout={({ nativeEvent }) => {
              setSectionBoundingRect(nativeEvent.layout);
            }}
          >
            <View style={styles.chartHeader}>
              <AppText style={Fonts.H300}>{getChartLabel()}</AppText>
              <RadioSelectFilter
                showClearButton={false}
                title={t("sort_by")}
                renderIconButton={() => <IconCustom name="sort" />}
                data={sortOptions}
                value={currentSort}
                setValue={(value) => {
                  setCurrentSort(value);
                }}
              />
            </View>
            {chartData?.length > 0 ? (
              renderLegend()
            ) : (
              <AppText style={styles.emptyText} color={Colors.grayscale60}>
                {t("no_data")}
              </AppText>
            )}
            <View
              style={styles.chartContainer}
              onLayout={({ nativeEvent }) => {
                setContainerBoundingRect(nativeEvent.layout);
              }}
            >
              <HorizontalBarChart
                legendAnimationStyle={animatedStyle}
                style={{ paddingLeft: CONSTANTS.COMMON.CONTAINER_PADDING }}
                onItemPress={(metadata) => {
                  bottomSheetRef.current?.present();
                  console.log(JSON.stringify(metadata, null, 2));
                  setSelectedItem({
                    name:
                      chartType === CHART_TYPE.TEAM
                        ? metadata?.name || t("general_expense")
                        : metadata.title ?? metadata.fullname,
                    nameEn:
                      chartType === CHART_TYPE.TEAM
                        ? metadata.nameEn || metadata?.name || t("general_expense")
                        : metadata.titleEn ?? metadata.fullname,
                    budgetAmount: metadata?.budgetAmount,
                    spendingPlanAmount: metadata?.spendingPlanAmount,
                    actualSpendingAmount: metadata.actualSpendingAmount,
                  });
                }}
                data={chartData}
                showSecondaryBar={
                  (limitType === LIMIT_TYPES.BUDGET && hadBudget) || limitType === LIMIT_TYPES.SPENDING_PLAN
                }
                chartConfig={{
                  primaryColor: Colors.primary50,
                  secondaryColor: limitType === LIMIT_TYPES.SPENDING_PLAN ? Colors.success50 : Colors.warning50,
                  alertColor: Colors.alert50,
                }}
              />
            </View>
          </View>
        </Animated.ScrollView>
      )}
      <BottomSheetScrollViewModalCustom
        wrapperByScrollView={false}
        title={getTitleFromCategory({
          title: selectedItem?.name ?? "",
          titleEn: selectedItem?.nameEn ?? "",
        })}
        ref={bottomSheetRef}
      >
        {renderModalContent()}
      </BottomSheetScrollViewModalCustom>
    </View>
  );
};
export default DashboardDetailScreen;
const styles = StyleSheet.create({
  container: { flex: 1 },
  contentContainer: { gap: 10 },
  section: { padding: CONSTANTS.COMMON.CONTAINER_PADDING, backgroundColor: Colors.white },
  descriptionRow: { flexDirection: "row", gap: 10, alignItems: "center" },
  flex: { flex: 1 },
  chartModalBody: {
    gap: 20,
    paddingVertical: 10,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  flexRow: { flexDirection: "row", alignItems: "center" },
  spaceBetween: { justifyContent: "space-between" },
  legend: {
    marginTop: 16,
    gap: 8,
  },
  legendItem: {
    alignItems: "center",
    flexDirection: "row",
    gap: 8,
  },
  legendDot: {
    width: 12,
    height: 12,
    borderRadius: 2,
  },
  mb15: {
    marginBottom: 15,
  },
  mt15: {
    marginTop: 15,
  },
  chartContainer: {
    marginTop: 20,
    marginHorizontal: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  gap8: {
    gap: 8,
  },
  emptyText: { ...Fonts.BodyMedium, textAlign: "center", marginTop: 25 },
  chartHeader: { flexDirection: "row", justifyContent: "space-between" },
});
