import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  EmptyData,
  IconCustom,
  RadioButton,
  SearchInput,
} from "components";
import React, { FC, useEffect, useRef, useState } from "react";
import { Colors, Fonts } from "theme";
import { SearchNotFoundIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";
import { getTitleI18n } from "utils";
import { removeAccents } from "utils/searchObject";
import { filter } from "lodash";
import { ExpenseCategory } from "../types";
import useGetCategoriesCompany from "screens/ExpenseOverview/DashboardDetailScreen/hooks/useGetCategoriesCompany";
import { useAuth } from "contexts/AuthContext";

interface SelectExpenseCategoryProps {
  title?: string;
  value?: ExpenseCategory;
  setValue?: (value: ExpenseCategory) => void;
  isFiltering?: boolean;
}

const FilterByCategoryButton: FC<SelectExpenseCategoryProps> = ({ title, value, setValue, isFiltering }) => {
  const { t } = useTranslation("app/screens/ExpenseOverview/DashboardDetailScreen");
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const searchRef = useRef<TextInput>();
  const [currentValue, setCurrentValue] = useState<ExpenseCategory>(null);
  // only call API when open this modal
  const [isFetchData, setIsFetchData] = useState(false);

  const {
    company: { company_id: companyId },
  } = useAuth();
  const { data, loading } = useGetCategoriesCompany({
    variables: { companyId },
    skip: !isFetchData,
  });
  const [categoryOptions, setCategoryOptions] = useState<ExpenseCategory[]>([]);

  useEffect(() => {
    if (data?.expenseCategories.length > 0) {
      setCategoryOptions(data?.expenseCategories);
    }
  }, [data]);

  const [keyword, setKeyword] = useState("");
  const debounceSearchTimeoutId = useRef(null);
  const onChangeSearch = (text) => {
    setKeyword(text);
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      const newTeamOptions = filter(data?.expenseCategories, (category) => {
        const keywordUnicode = removeAccents(text).toLowerCase();
        const titleUnicode = removeAccents(category?.title ?? "").toLowerCase();
        const titleEnUnicode = removeAccents(category?.titleEn ?? "").toLowerCase();
        const codeUnicode = removeAccents(category?.code ?? "").toLocaleLowerCase();
        return (
          titleEnUnicode.includes(keywordUnicode) ||
          titleUnicode.includes(keywordUnicode) ||
          codeUnicode.includes(keywordUnicode)
        );
      });
      setCategoryOptions(newTeamOptions);
    }, 400);
  };

  const handleSelectExpenseCategory = (item) => () => {
    setCurrentValue(item);
  };

  const handleClearSearch = () => {
    searchRef?.current?.setNativeProps({ text: "" });
    setKeyword("");
    setCategoryOptions(data?.expenseCategories);
  };

  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    if (currentValue) {
      setValue(null);
    }
  };

  const handleApplyFilter = () => {
    bottomSheetRef?.current?.close();
    setValue(currentValue);
  };
  const handleOpenModal = () => {
    setIsFetchData(true);
    handleClearSearch();
    bottomSheetRef?.current?.present();
    setCurrentValue(value);
  };

  const renderFooter = () => {
    return (
      <View style={styles.footerButton}>
        <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
          {t("clear_filter")}
        </Button>
        <Button style={styles.flex} onPress={handleApplyFilter} disabled={!currentValue}>
          {t("apply")}
        </Button>
      </View>
    );
  };

  const renderSearch = () => (
    <SearchInput
      style={styles.searchInput}
      placeholder={t("search_category")}
      onChangeText={onChangeSearch}
      autoCapitalize="none"
      enablesReturnKeyAutomatically
      returnKeyType="search"
      ref={searchRef}
      right={
        keyword ? (
          <TouchableOpacity onPress={handleClearSearch}>
            <IconCustom name="cancel" />
          </TouchableOpacity>
        ) : null
      }
    />
  );

  const renderItem = ({ item: category }) => {
    const isChecked = currentValue?.expenseCategoryId === category?.expenseCategoryId;
    return (
      <View key={category.expenseCategoryId}>
        <TouchableOpacity
          activeOpacity={0.9}
          style={styles.companyTeamItem}
          onPress={handleSelectExpenseCategory(category)}
        >
          <AppText style={[Fonts.BodyLarge, styles.flex]}>
            {getTitleI18n({ vi: category.title, en: category.titleEn })}
            {category.code ? ` (${category.code})` : ""}
          </AppText>
          <RadioButton checked={isChecked} />
        </TouchableOpacity>
        <View style={styles.divider} />
      </View>
    );
  };

  return (
    <>
      <TouchableOpacity onPress={handleOpenModal}>
        <IconCustom name="filter-alt" />
        {isFiltering && (
          <View style={styles.filterDot}>
            <AppText style={Fonts.BodyXSmall} color={Colors.white}>
              {1}
            </AppText>
          </View>
        )}
      </TouchableOpacity>

      <BottomSheetFlatListModalCustom
        loading={loading}
        renderSearch={renderSearch}
        renderFooter={renderFooter}
        ref={bottomSheetRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={title}
        listProps={{
          contentContainerStyle: { paddingBottom: 80 },
          data: categoryOptions,
          keyExtractor: (item) => item?.expenseCategoryId,
          renderItem,
          ListEmptyComponent: <EmptyData title={t("search_not_found")} icon={<SearchNotFoundIcon />} />,
        }}
      />
    </>
  );
};
export default FilterByCategoryButton;
const styles = StyleSheet.create({
  flex: { flex: 1 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  companyTeamItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
  },
  searchInput: { marginHorizontal: 20, marginBottom: 5 },
  footerButton: { flexDirection: "row", gap: 8 },
  filterDot: {
    position: "absolute",
    right: -3,
    top: -5,
    backgroundColor: Colors.info50,
    borderRadius: 10,
    height: 16,
    width: 16,
    justifyContent: "center",
    alignItems: "center",
  },
});
