import Svg, { Path, Rect } from "react-native-svg";

const MoneySendIcon = ({ background = "#F0F6FF", color = "#475569" }) => (
  <Svg width="48" height="48" viewBox="0 0 48 48" fill="none">
    <Rect width="48" height="48" rx="24" fill={background} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.28 13.3C34.47 13.38 34.61 13.53 34.69 13.71C34.73 13.81 34.75 13.9 34.75 14V18C34.75 18.41 34.41 18.75 34 18.75C33.59 18.75 33.25 18.41 33.25 18V15.81L31.58 17.48C31.25 17.1 30.9 16.75 30.52 16.42L32.19 14.75H30C29.59 14.75 29.25 14.41 29.25 14C29.25 13.59 29.59 13.25 30 13.25H34C34.1 13.25 34.19 13.27 34.28 13.3ZM30.52 16.42L28.47 18.47C28.18 18.76 28.18 19.24 28.47 19.53C28.62 19.68 28.81 19.75 29 19.75C29.19 19.75 29.38 19.68 29.53 19.53L31.58 17.48C33.08 19.24 34 21.51 34 24C34 29.52 29.52 34 24 34C18.48 34 14 29.52 14 24C14 18.48 18.48 14 24 14C26.49 14 28.76 14.92 30.52 16.42ZM25.75 23.82C26.39 24.05 27.25 24.51 27.25 25.98C27.25 27.23 26.26 28.26 25.05 28.26H24.75V28.51C24.75 28.92 24.41 29.26 24 29.26C23.59 29.26 23.25 28.92 23.25 28.51V28.26H23.17C21.84 28.26 20.75 27.14 20.75 25.76C20.75 25.34 21.09 25 21.5 25C21.91 25 22.25 25.34 22.25 25.75C22.25 26.3 22.66 26.75 23.17 26.75H23.25V24.53L22.25 24.18C21.61 23.95 20.75 23.49 20.75 22.02C20.75 20.77 21.74 19.74 22.95 19.74H23.25V19.5C23.25 19.09 23.59 18.75 24 18.75C24.41 18.75 24.75 19.09 24.75 19.5V19.75H24.83C26.16 19.75 27.25 20.87 27.25 22.25C27.25 22.66 26.91 23 26.5 23C26.09 23 25.75 22.66 25.75 22.25C25.75 21.7 25.34 21.25 24.83 21.25H24.75V23.47L25.75 23.82ZM22.74 22.77C22.4 22.65 22.25 22.57 22.25 22.03C22.25 21.6 22.57 21.25 22.95 21.25H23.25V22.95L22.74 22.77ZM25.05 26.75H24.75V25.05L25.26 25.23C25.6 25.35 25.75 25.43 25.75 25.97C25.75 26.4 25.44 26.75 25.05 26.75Z"
      fill={color}
    />
  </Svg>
);

export default MoneySendIcon;
