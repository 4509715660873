import i18n from "i18next";
import merge from "lodash/merge";
import { initReactI18next } from "react-i18next";

import resources from "translations/resources";
import LanguageStatus from "constants/LanguageStatus";

import vi from "./vn/index";
import en from "./en/index";

const resourcesOutdate = {
  [LanguageStatus.VN]: vi,
  [LanguageStatus.EN]: en,
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources: merge(resourcesOutdate, resources),
  lng: LanguageStatus.VN, // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export default i18n;
