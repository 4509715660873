import { TouchableOpacity, View, StyleSheet } from "react-native";
import {
  AppText,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  TouchableOpacityCustom,
} from "components/index";
import { FC, ReactNode, useRef, forwardRef, useImperativeHandle } from "react";
import { Colors, Fonts } from "theme";
export type SelectActionItem = {
  label: string;
  value: string | number;
  icon?: ReactNode;
};
interface SelectActionPickerProps {
  eventName?: string;
  icon?: ReactNode;
  title?: string;
  options?: SelectActionItem[];
  onSelect?: (action: SelectActionItem["value"]) => void;
}
const SelectActionPicker: FC<SelectActionPickerProps> = forwardRef(
  ({ eventName, icon, title, options, onSelect }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef.current?.present();
      },
    }));
    const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
    const handleOpenModal = () => {
      bottomSheetRef.current?.present();
    };
    const handleClickItem = (value) => () => {
      bottomSheetRef.current?.close();
      onSelect(value);
    };
    return (
      <View>
        <TouchableOpacityCustom eventName={eventName} onPress={handleOpenModal}>
          {icon}
        </TouchableOpacityCustom>
        <BottomSheetScrollViewModalCustom wrapperByScrollView={false} ref={bottomSheetRef} title={title}>
          <View>
            {options.map((item) => (
              <TouchableOpacity key={item.value} style={styles.actionItem} onPress={handleClickItem(item.value)}>
                {item?.icon}
                <AppText style={Fonts.BodyMedium}>{item?.label}</AppText>
              </TouchableOpacity>
            ))}
          </View>
        </BottomSheetScrollViewModalCustom>
      </View>
    );
  }
);
export default SelectActionPicker;

const styles = StyleSheet.create({
  actionItem: {
    gap: 16,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderColor: Colors.grayscale05,
  },
});
