import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M3.5 20.5V19h17v1.5h-17zm1.562-4.549L2.5 11.563l1.204-.242 1.77 1.55 4.943-1.339-4.05-6.85 1.508-.465 6.85 6.13 4.952-1.32c.456-.125.87-.04 1.238.256.37.296.554.687.554 1.174 0 .316-.093.592-.28.83-.186.24-.429.402-.727.49l-15.4 4.174z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
