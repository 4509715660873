import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, fill = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M11.25 16.75h1.5v-4h4v-1.5h-4v-4h-1.5v4h-4v1.5h4v4zm.752 4.75a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749zM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
