import React, { FC, memo } from "react";
import { Image, StyleSheet, useWindowDimensions, View } from "react-native";
import { useTranslation } from "react-i18next";
import Modal from "react-native-modal";

import { AppText, Button } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { UpdateAppBackground } from "assets/images";
import LottieView from "lottie-react-native";
import * as Updates from "expo-updates";

interface OTAUpdateModalProps {
  visible?: boolean;
  setVisible?: (value: boolean) => void;
}
const OTAUpdateModal: FC<OTAUpdateModalProps> = ({ visible, setVisible }) => {
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { width } = useWindowDimensions();
  const MODAL_WIDTH = Math.min(width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING, CONSTANTS.COMMON.MODAL_MAX_WIDTH);

  const handleCancel = () => {
    setVisible(false);
  };
  const handleConfirm = () => {
    Updates.reloadAsync();
  };

  return (
    <Modal style={styles.modalStyle} isVisible={visible} animationIn="slideInUp">
      <View style={{ width: MODAL_WIDTH }}>
        <Image source={UpdateAppBackground} style={{ width: MODAL_WIDTH, height: MODAL_WIDTH / 2.2 }} />
        <LottieView
          autoPlay={true}
          speed={1}
          style={{
            height: MODAL_WIDTH / 2.2,
            position: "absolute",
            top: 0,
            alignSelf: "center",
          }}
          source={require("assets/lottie/rocket-update-app.json")}
        />
        <View style={styles.body}>
          <AppText style={[Fonts.H300, styles.welcomeText]}>{t("ota_update_title")}</AppText>
          <AppText style={[Fonts.BodyMedium, styles.description]}>{t("ota_update_description")}</AppText>
          <View style={styles.footerButton}>
            <Button type="secondary" onPress={handleCancel} style={styles.buttonSkip}>
              {t("later")}
            </Button>
            <Button onPress={handleConfirm} style={styles.buttonContinue}>
              {t("upgrade")}
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};
export default memo(OTAUpdateModal);

const styles = StyleSheet.create({
  modalStyle: { justifyContent: "center", alignItems: "center" },
  body: {
    paddingTop: 16,
    paddingBottom: 20,
    paddingHorizontal: 15,
    backgroundColor: Colors.white,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  footerButton: { flexDirection: "row", justifyContent: "space-between", marginTop: 16 },
  welcomeText: { textAlign: "center" },
  buttonSkip: { flex: 1, marginRight: 6 },
  buttonContinue: { flex: 1, marginLeft: 6 },
  description: { textAlign: "center", marginTop: 16 },
});
