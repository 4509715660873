import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileExpExpenseRequestDetailQuery, MobileExpExpenseRequestDetailQueryVariables } from "types";

const GET_DETAIL_REQUEST_BY_ID = gql`
  query MobileExpExpenseRequestDetail($expenseRequestId: UUID!, $includeFlightBooking: Boolean!) {
    expExpenseRequestDetail(expenseRequestId: $expenseRequestId) {
      note
      exchangeRate
      currency
      isLocked
      totalAmount
      title
      paymentMethod
      employeeId
      expenseRequestId
      status
      isClosed
      closedAt
      budgetDate
      expenseRequestType
      docSequence
      description
      createdAt
      requestedAt
      updatedAt
      currency
      linkedPurchaseRequests {
        purchaseRequest {
          purchaseRequestId
          docSequence
          title
          status
        }
      }
      linkedPriceComparisonRequests {
        priceComparisonRequest {
          priceComparisonRequestId
          title
          docSequence
          status
          updatedAt
        }
      }
      expenseRequestAttachments {
        type
        fileUrl
        fileName
        expenseAttachmentId
        fileSize
        createdAt
        fileType
      }
      expenseTravels {
        passengers {
          fullname
        }
        expenseBookings {
          expenseBookingId @include(if: $includeFlightBooking)
          bookingType
          bookingPolicies
          bookingSettings {
            ... on ExpHotelBooking {
              __typename
              location {
                code
                name
                nameEn
              }
              checkInDate
              checkOutDate
              note
              companyHotelCode
              hotelName
              numberOfRooms
              roomRate
            }
            ... on ExpTransportationBooking {
              __typename
              pickupLocation {
                code
                name
                nameEn
              }
              dropOffLocation {
                code
                name
                nameEn
              }
              isRoundTrip
              departureDate
              returnDate
              departureNote
              returnNote
            }
            ... on ExpFlightBooking {
              __typename
              departureAirport {
                code
                name
                nameEn
                location {
                  code
                  name
                  nameEn
                }
              }
              arrivalAirport {
                code
                name
                nameEn
                location {
                  code
                  name
                  nameEn
                }
              }
              vendorName
              isRoundTrip
              departureDate
              returnDate
              departureNote
              returnNote
            }
          }
          expenseTravelBookings @include(if: $includeFlightBooking) {
            expenseTravelBookingId
            bookingInformation
            orderCode
            status
          }
        }
        expenseTravelId
        expenseRequestId
        companyId
        createdAt
        currency
        deletedBy
        description
        employeeId
        fromDate
        fromLocationId
        groupId
        perDiemAmount
        title
        toDate
        toLocationId
        totalAmount
        travelDuration
        updatedAt
        fromLocation {
          code
          locationId
          name
          nameEn
          parent {
            code
            name
            nameEn
          }
        }
        toLocation {
          code
          locationId
          name
          nameEn
          parent {
            code
            name
            nameEn
          }
        }
        expenseAllowances: expenseAllowancesV2 {
          foreignAmount
          allowanceType
          customFields
          expenseAllowanceId
          expenseTravelId
          expenseRequestId
          expenseCategoryId
          description
          amount
          accountCode
          currency
          expenseCategory {
            expenseCategoryId
            title
            titleEn
          }
        }
      }
      companyAttachments {
        companyAttachmentId
        objectId
        objectType
        attachmentType
        fileName
        fileUrl
        thumbnailUrl
        mimeType
        fileSize
        fileMetadata
        customFields
        fileObject
        createdAt
        createdBy
      }
      expenseAllowances {
        foreignAmount
        expenseAllowanceId
        expenseTravelId
        expenseRequestId
        expenseCategoryId
        description
        amount
        accountCode
        currency
        expenseCategory {
          title
          titleEn
        }
        productName
        quantity
        unitPrice
        uom
      }
      companyEmployee {
        employeeId
        userId
        email
        fullname
        avatarUrl
        defaultAvatarUrl
      }
      company {
        name
        address
        taxCode
        companyId
        accountantEmail
      }
      expenses {
        foreignTotalAmountWithVat
        allocatedStatus
        docSequence
        expenseCardTransactions {
          cardTransaction {
            cardTransactionId
          }
        }

        status
        note
        address
        expenseId
        provider
        category
        invoiceType
        invoice {
          invoiceId
        }
        expenseCategory {
          title
          expenseCategoryId
          titleEn
        }
        expenseReport {
          expenseReportId
          status
          docSequence
          requestedAt
        }
        expenseDate
        expenseTravelId
        companyTeamId
        expenseReportId
        expenseRequestId
        expenseInfo
        customFields
        receivedInvoice
        totalAmountWithVat
      }
      expenseRequestsTeams {
        companyTeamId
        companyTeam {
          companyTeamId
          name
          nameEn
          code
          companyTeamId
          nameEn
        }
      }
      expenseApprovalLogs {
        status
        userId
        reason
        createdAt
        companyEmployeeId
        expenseApprovalLogId
        expenseApprovalRuleId
        companyEmployee {
          employeeId
          fullname
          avatarUrl
          defaultAvatarUrl
        }
      }
      cashAdvances {
        foreignAmount
        amount
        cashAdvanceId
        createdAt
        description
        expenseRequestId
        paymentStatus
        paymentInfo {
          accountHolderName
          accountNumber
          bankCode
          bankId
          bankName
          bankShortName
          branchName
          description
          logoUrl
          type
        }
        paymentMethod
        status
        updatedAt
      }
      priceComparisonVendors {
        totalAmount
        vendorName
      }
      expenseApprovalRules {
        settings {
          approvalFlow {
            title
          }
        }
        order
        status
        name
        condition
        createdAt
        updatedAt
        approvalType
        expenseApprovalRuleId
        expenseApprovalRuleEmployees {
          status
          approvalMethod
          employee {
            phone
            fullname
            email
            userId
            avatarUrl
            defaultAvatarUrl
          }
          employeeId
        }
      }
      xlsx: generatedDocument(documementType: xlsx) {
        status
        url
      }
      pdf: generatedDocument(documementType: pdf) {
        status
        url
      }
      zip: generatedDocument(documementType: zip) {
        status
        url
      }
    }
  }
`;

const useExpenseRequestDetailQuery = ({
  variables,
  onCompleted,
  onError,
}: {
  variables: MobileExpExpenseRequestDetailQueryVariables;
  onCompleted: (data: MobileExpExpenseRequestDetailQuery) => void;
  onError: () => void;
}) => {
  return useLazyQuery<MobileExpExpenseRequestDetailQuery, MobileExpExpenseRequestDetailQueryVariables>(
    GET_DETAIL_REQUEST_BY_ID,
    { variables, fetchPolicy: "network-only", onCompleted, onError }
  );
};
export default useExpenseRequestDetailQuery;
