import isEmpty from "lodash/isEmpty";
import { useLazyQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import gql from "graphql-tag";
import { useEffect } from "react";

const NUMBER_ROWS_PER_PAGE = 10;

const QUERY_UNCLAIMED_EXPENSES = gql`
  query MobileUnclaimedExpenses($limit: Int, $offset: Int, $expensesConditions: expenses_bool_exp!) {
    expenses(where: $expensesConditions, order_by: { created_at: desc }, limit: $limit, offset: $offset) {
      expenseCardTransactions: expense_card_transactions {
        cardTransactionId: card_transaction_id
      }
      docSequence: doc_sequence
      expenseId: expense_id
      invoiceId: invoice_id
      provider
      category
      totalAmountWithVat: total_amount_with_vat
      expenseDate: expense_date
      note
      status
      invoiceType: invoice_type
      receivedInvoice: received_invoice
    }
    expenses_aggregate(where: $expensesConditions) {
      aggregate {
        count
      }
    }
  }
`;

const useExpenseUnClaimed = (companyTeamIds?: string[]) => {
  const { user } = useAuth();
  const expensesConditions: ExpensesBoolExp = {
    deleted_at: { _is_null: true },
    expense_request_id: { _is_null: true },
    expense_travel_id: { _is_null: true },
    status: { _in: [1, 2, 3, 4] },
    employee_id: { _eq: user.employee_id },
    company_id: { _eq: user.company.company_id },
    _or: [{ expense_report_id: { _is_null: true } }, { expense_report: { status: { _in: [0, 2, 5] } } }],
  };

  if (isEmpty(companyTeamIds)) {
    expensesConditions._not = { company_team: {} };
  } else {
    expensesConditions.company_team = {
      company_team_id: { _in: companyTeamIds },
    };
  }

  const [callQuery, results] = useLazyQuery<{
    expenses_aggregate: ExpensesAggregate;
    expenses: Expenses[];
  }>(QUERY_UNCLAIMED_EXPENSES, {
    variables: { limit: NUMBER_ROWS_PER_PAGE, offset: 0, expensesConditions },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!results.called && callQuery) {
      callQuery();
    }
  }, [callQuery, results]);

  return results;
};

export default useExpenseUnClaimed;
