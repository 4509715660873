import { FC } from "react";
import { Platform, ScrollView, ScrollViewProps } from "react-native";
import { BottomSheetScrollView } from "@gorhom/bottom-sheet";

const BottomSheetScrollViewCustom: FC<ScrollViewProps> = (props) => {
  if (Platform.OS === "web") {
    return <ScrollView {...props}>{props.children}</ScrollView>;
  }
  return <BottomSheetScrollView {...props}>{props.children}</BottomSheetScrollView>;
};
export default BottomSheetScrollViewCustom;
