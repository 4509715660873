import { useCallback, useState } from "react";
import { request } from "utils/gql";

export const EXPENSE_ENABLED_APP = /* GraphQL */ `
  mutation MobileEnableExpenseApp($object: expenseEnableExpenseAppInput!) {
    expenseEnableExpenseApp(object: $object) {
      group_id
      message
      success
      company_id
    }
  }
`;

const useEnabledExpenseApp = () => {
  const [loading, setLoading] = useState(false);

  const enabledExpenseApp = useCallback(async (object, token) => {
    setLoading(true);
    const result = await request(
      EXPENSE_ENABLED_APP,
      {
        object,
      },
      token
    );
    setLoading(false);

    return result;
  }, []);

  return {
    enabledExpenseApp,
    loading,
  };
};

export default useEnabledExpenseApp;
