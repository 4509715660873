// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { request } from "utils/gql";

export const EXPENSE_IS_EMAIL_AVAILABLE = /* GraphQL */ `
  query MobileIsEmailAvailable($email: EmailAddress!) {
    expenseIsEmailAvailable(email: $email) {
      email
      isAvailable
    }
  }
`;

const useExpenseIsEmailAvailable = ({ email }: { email: string }) => {
  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  const expenseIsEmailAvailable = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await request(EXPENSE_IS_EMAIL_AVAILABLE, {
        email,
      });
      setIsAvailable(data?.expenseIsEmailAvailable?.isAvailable ?? false);
    } catch (error) {}
    setLoading(false);
  }, [email]);

  useEffect(() => {
    expenseIsEmailAvailable();
  }, [expenseIsEmailAvailable, email]);

  return {
    isAvailable,
    loading,
  };
};

export default useExpenseIsEmailAvailable;
