import {
  AlertNotification,
  AlertNotificationHandle,
  ApproverChooserModal,
  AppText,
  BackHeader,
  BottomSheetModalCustomMethods,
  Button,
  CurrencyText,
  EmptyData,
  FloatFooter,
  IconCustom,
  LoadingView,
  ToastManager,
  TopTabLineView,
  TouchableOpacityCustom,
  useWindowDimensions,
} from "components";
import { ErrorInvoiceIcon } from "assets/images/svg/icons";

import { StyleSheet, View } from "react-native";
import {
  APPROVAL_STATUS,
  CHAT_TYPE,
  EXP_CONVO_TYPE,
  EXPENSE_STATUS,
  REQUEST_STATUS,
  REQUEST_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import SCREEN_NAME from "navigation/ScreenName";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Fonts, FontTypes } from "theme";
import useInteractionManager from "components/hooks/useInteractionManager";
import { ApprovalType } from "constants/ExpenseApproval";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useAuth } from "contexts/AuthContext";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import dayjs from "dayjs";
import useExpenseApproveRequest from "hooks/request/useExpenseApproveRequest";
import { useValidateExpenseRequestDetailAmountMutation } from "hooks/request/useValidateExpenseRequestDetailAmountMutation";
import i18n from "i18next";
import { isEmpty } from "lodash";
import groupBy from "lodash/groupBy";
import { isUserMod } from "utils";
import CancelExpenseRequestModal from "./components/CancelExpenseRequestModal";
import RejectExpenseRequestModal from "./components/RejectExpenseRequestModal";
import { ExpenseActualListByGroup, ExpenseListGroupByCategory } from "./types";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import useExpenseRequestDetailQuery from "./hooks/useExpenseRequestDetailQuery";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { Route } from "react-native-tab-view";
import DetailTab from "./components/DetailTab/DetailTab";
import SpendPlanningTab from "./components/SpendPlanningTab/SpendPlanningTab";
import ActivityLogTab from "./components/ActivityLogTab/ActivityLogTab";
import useExpGetConvoStats from "hooks/useExpGetConvoStats";
import { goBack } from "navigation/RootNavigation";
import { EVENT } from "constants/Tracking";
import { analyticService, withTrackingSession } from "services/AnalyticsService";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { executeRouteFunction } from "utils/route";
import { REQUEST_PAYMENT_STATUS } from "screens/ExpenseRequest/constants";
import ButtonApproveRequest from "screens/ExpenseRequest/ExpenseRequestDetailScreen/components/ButtonApproveRequest";

const ExpenseRequestDetailScreen = ({ route, navigation }) => {
  const { expenseRequestId, onRefreshData, companyId } = route?.params ?? {};
  const { company, user, switchCompany } = useAuth();
  const { width } = useWindowDimensions();
  const [FEATURE_EXP_CASH_ADVANCE, RELEASE_SMP_8722] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_EXP_CASH_ADVANCE,
    FEATURE_FLAGS.RELEASE_SMP_8722,
  ]);
  const cancelRequestModalRef = useRef<BottomSheetModalCustomMethods>();
  const rejectRequestModalRef = useRef<BottomSheetModalCustomMethods>();
  const approverChooserModalRef = useRef<BottomSheetModalCustomMethods>();
  const alertNotificationRef = useRef<AlertNotificationHandle>();
  const spendPlanningTabRef = useRef<any>();
  const isReady = useInteractionManager();
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const [disabledButton, setDisabledButton] = useState(false);
  const [expenseRequest, setExpenseRequest] = useState<MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]>();
  const [expenseListGroupByCategory, setExpenseListGroupByCategory] = useState<ExpenseListGroupByCategory[]>();
  const [expenseActualListByGroup, setExpenseActualListByGroup] = useState<ExpenseActualListByGroup[]>();
  const [footerHeight, setFooterHeight] = useState(0);
  const [approverId, setApproverId] = useState(null);
  const detailTabRef = useRef<any>();
  const {
    data: { expConvoStats },
    loading: loadingChat,
    refetch: refetchConvoStats,
  } = useExpGetConvoStats({
    input: {
      companyId: companyId || company.company_id,
      objectId: expenseRequestId,
      type: EXP_CONVO_TYPE.REQUEST,
    },
  });

  /*--- this screen have 2 view: detail view for normal user and approve view for approver */
  const [isApprovedOrRejectView, approvalType] = useMemo(() => {
    if (expenseRequest?.status !== REQUEST_STATUS.SUBMITTED) {
      return [false, ""];
    }
    const isApproverThisRequest = expenseRequest?.expenseApprovalRules?.find((item) => {
      return item.expenseApprovalRuleEmployees.find(
        (i) => i?.employee?.userId === user?.id && item?.status === APPROVAL_STATUS.AWAITING
      );
    });

    return [Boolean(isApproverThisRequest), isApproverThisRequest?.approvalType];
  }, [expenseRequest]);
  const [loading, setLoading] = useState(true);
  const [onFetchRequestDetail, { refetch }] = useExpenseRequestDetailQuery({
    variables: { expenseRequestId, includeFlightBooking: RELEASE_SMP_8722 },
    onCompleted: async (result) => {
      switchCompany(result?.expExpenseRequestDetail?.company?.companyId);
      const expenseRequest = result?.expExpenseRequestDetail;
      setExpenseRequest(expenseRequest);
      const isTravelRequest = expenseRequest?.expenseRequestType === REQUEST_TYPE.travel;
      const expenseAllowances = isTravelRequest
        ? expenseRequest?.expenseTravels?.[0]?.expenseAllowances ?? []
        : expenseRequest?.expenseAllowances ?? [];

      const item: Record<string, any> = groupBy(expenseAllowances ?? [], (i) => (i as any)?.expenseCategoryId);
      const expenseListGroup = Object.keys(item).map((key) => {
        const totalAmount = item?.[key]?.reduce((total, currentValue) => {
          return currentValue?.amount + total;
        }, 0);
        const foreignAmount = item?.[key]?.reduce((total, currentValue) => {
          return currentValue?.foreignAmount + total;
        }, 0);
        return {
          title: item?.[key]?.[0]?.expenseCategory?.title ?? t("business_travels"),
          titleEn: item?.[key]?.[0]?.expenseCategory?.titleEn ?? t("business_travels"),
          categoryId: item?.[key]?.[0]?.expenseCategoryId ?? "",
          expenseAllowances: item?.[key] ?? [],
          totalAmount: totalAmount ?? 0,
          budget: null,
          foreignAmount,
        };
      });

      /*--- expense list actual in the case status is Approve ---*/
      if (expenseRequest?.status === REQUEST_STATUS.APPROVED) {
        const item = groupBy(expenseRequest?.expenses ?? [], (i) => i?.expenseCategory?.expenseCategoryId);
        const expenseActualListGroup = Object.keys(item).map((key) => {
          const totalAmount = item?.[key]?.reduce((total, currentValue) => {
            return currentValue?.totalAmountWithVat + total;
          }, 0);
          const foreignAmount = item?.[key]?.reduce((total, currentValue) => {
            return currentValue?.foreignTotalAmountWithVat + total;
          }, 0);

          return {
            title: item?.[key]?.[0]?.expenseCategory?.title ?? "",
            titleEn: item?.[key]?.[0]?.expenseCategory?.titleEn ?? "",
            categoryId: item?.[key]?.[0]?.expenseCategory?.expenseCategoryId ?? "",
            expenses: item?.[key] ?? [],
            totalAmount: totalAmount ?? 0,
            foreignAmount,
          };
        });
        setExpenseActualListByGroup(expenseActualListGroup);
      }
      /*---- flag validate budget ----*/
      if (expenseRequest?.status === REQUEST_STATUS.SUBMITTED) {
        await onValidateBudget(
          expenseListGroup,
          expenseRequest?.budgetDate,
          expenseRequest?.expenseRequestsTeams?.[0]?.companyTeam?.companyTeamId
        );
      } else {
        setExpenseListGroupByCategory(expenseListGroup);
      }
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });
  const [validateExpenseRequestBudgetAmount] = useValidateExpenseRequestDetailAmountMutation();
  const onValidateBudget = async (
    expenseListGroup: ExpenseListGroupByCategory[],
    budgetDate?: string,
    companyTeamId?: string
  ) => {
    try {
      const expenseAllowancesVariables = expenseListGroup?.map((item) => ({
        expenseCategoryId: item?.categoryId,
        allowanceAmount: item?.totalAmount,
      }));
      const body = {
        input: {
          companyId: company.company_id,
          companyTeamId,
          budgetDate: dayjs(budgetDate).toISOString(),
          expenseAllowances: expenseAllowancesVariables?.filter(
            (item) => item?.expenseCategoryId && item?.allowanceAmount > 0
          ),
        },
      };
      const rs = await validateExpenseRequestBudgetAmount({
        variables: body,
      });
      const budgets = rs?.data?.expValidateExpenseRequestAmount?.budgets;
      if (budgets?.length > 0) {
        const newExpenseListGroup = expenseListGroup?.map((item) => {
          const budget = budgets.find((i) => i.expenseCategoryId === item?.categoryId);
          return {
            ...item,
            budget: budget,
          };
        });
        setExpenseListGroupByCategory(newExpenseListGroup);
      } else {
        setExpenseListGroupByCategory(expenseListGroup);
      }
    } catch (e) {}
  };
  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      onFetchRequestDetail();
      refetchConvoStats();
    });
    return unsubscribe;
  }, []);

  const handleNavigateToChatScreen = () => {
    navigation.navigate(SCREEN_NAME.CommentScreen, {
      objectId: expenseRequestId,
      type: CHAT_TYPE.REQUEST,
      docSequence: expenseRequest?.docSequence,
    });
  };
  const renderHeaderRight = () => {
    if (loading) {
      return null;
    }

    return (
      <View style={{ flexDirection: "row", gap: 12 }}>
        {!loadingChat && (
          <TouchableOpacityCustom
            style={[styles.ChatButtonContainer, { gap: expConvoStats?.unreadMessageCount ? 9 : 4 }]}
            onPress={handleNavigateToChatScreen}
          >
            <View>
              <IconCustom name="chat" fill={Colors.grayscale60} />
              {!!expConvoStats?.unreadMessageCount && (
                <View style={styles.notificationBadge}>
                  <AppText style={styles.notificationBadgeText} color={Colors.white}>
                    {expConvoStats.unreadMessageCount > 9 ? "9+" : `${expConvoStats.unreadMessageCount}`}
                  </AppText>
                </View>
              )}
            </View>
            {!!expConvoStats?.messageCount && (
              <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale60}>
                ({expConvoStats?.messageCount})
              </AppText>
            )}
          </TouchableOpacityCustom>
        )}
        {expenseRequest?.expenseRequestType === REQUEST_TYPE.travel &&
          expenseRequest?.status === REQUEST_STATUS.APPROVED && (
            <TouchableOpacityCustom
              eventName={EVENT.REQUEST.SHARE_TRAVEL_INFO}
              onPress={() => detailTabRef?.current?.share()}
            >
              <IconCustom name="share" fill={Colors.grayscale60} />
            </TouchableOpacityCustom>
          )}
      </View>
    );
  };

  const handleApprove = async () => {
    if (approvalType === ApprovalType.MANUAL) {
      approverChooserModalRef?.current?.present();
      return;
    }
    setDisabledButton(true);
    await onApproveRequest();
  };

  const { approvalRequest, approvalRequestWithAddApproval } = useExpenseApproveRequest();
  const [reasonApprove, setReasonApprove] = useState("");

  const onApproveRequest = async () => {
    try {
      setDisabledButton(true);
      let results;
      approverChooserModalRef?.current?.close();
      alertNotificationRef?.current?.loading();
      if (approverId) {
        results = await approvalRequestWithAddApproval({
          requestId: expenseRequestId,
          approver_id: approverId,
        });
      } else {
        results = await approvalRequest({
          variables: {
            requestId: expenseRequestId,
            reason: reasonApprove,
          },
        });
      }
      if (!results?.data?.expenseApproveRequest?.success) {
        throw new Error();
      }
      setDisabledButton(false);
      /* log event approved success */
      const expenseApprovalRules = expenseRequest?.expenseApprovalRules ?? [];
      const approvalRulesWaitingIndex = expenseApprovalRules.findIndex(
        (item) =>
          item?.status === APPROVAL_STATUS.AWAITING &&
          item?.expenseApprovalRuleEmployees?.some((employee) => employee?.employeeId === user?.employee_id)
      );
      const isFinalApprovalRule = approvalRulesWaitingIndex === expenseApprovalRules.length - 1;
      const companyTeam = expenseRequest?.expenseRequestsTeams?.[0]?.companyTeam;
      analyticService.logEvent({
        sessionId: SCREEN_NAME.DetailRequestScreen,
        name: EVENT.REQUEST.APPROVED_SUCCESS,
        properties: {
          object_id: expenseRequestId,
          object_type: TRACKING_OBJECT_TYPE.REQUEST,
          is_final: isFinalApprovalRule,
          over_budgets: expenseListGroupByCategory
            ?.filter((item) => !item?.budget?.isValid)
            ?.map((item) => ({
              expense_category_id: item?.budget?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.budget?.overBudgetAmount,
              expense_date: expenseRequest?.budgetDate,
            })),
        },
      });
      /*--- end ---*/
      alertNotificationRef?.current?.close();
      ToastManager.success(t("description_approval_success"));
      executeRouteFunction(route, "onRefreshData");
      goBack();
    } catch (error) {
      setDisabledButton(false);
      const errorTitle = t("approve_request_failed");
      let errorMessage = i18n.t("common:internal_error");
      switch (error?.message) {
        case SERVER_ERROR_CODE.NOT_OPEN_TO_APPROVE: {
          errorMessage = t("not_open_to_approve");
          break;
        }
        case SERVER_ERROR_CODE.ITEM_CANCELLED: {
          errorMessage = t("request_is_cancelled");
          break;
        }
        case SERVER_ERROR_CODE.NO_PERMISSION: {
          errorMessage = t("no_permission");
          break;
        }
      }
      analyticService.logEvent({
        sessionId: SCREEN_NAME.DetailRequestScreen,
        name: EVENT.REQUEST.APPROVED_FAILED,
        properties: {
          message: errorMessage,
        },
      });
      alertNotificationRef.current?.error({
        title: errorTitle,
        description: errorMessage,
      });
    }
  };
  const handleNavigateToSpendTab = () => {
    setIndex(1);
  };
  const handleScrollToExpenseActualList = () => {
    handleNavigateToSpendTab();
    spendPlanningTabRef?.current?.scrollToExpenseActualList();
  };
  const handleScrollToExpenseSpendingList = () => {
    handleNavigateToSpendTab();
    spendPlanningTabRef?.current?.scrollToExpenseSpendingList();
  };
  const handleScrollToCashAdvanceSection = () => {
    handleNavigateToSpendTab();
    spendPlanningTabRef?.current?.scrollToCashAdvanceSection();
  };
  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const renderFooterButton = () => {
    if (loading) {
      return null;
    }
    const disabledUpdateOrCancelRequest = expenseRequest?.expenseRequestType === REQUEST_TYPE.price_comparison;
    const isPaidAt = expenseRequest?.cashAdvances?.[0]?.paymentStatus === REQUEST_PAYMENT_STATUS.PAID;

    const amountInfo =
      expenseRequest?.expenseRequestType !== REQUEST_TYPE.price_comparison ? (
        <>
          <View style={styles.totalExpenseRow}>
            <AppText style={[Fonts.BodyRegular, styles.totalExpenseTitle]}>{t("total")}</AppText>
            <TouchableOpacityCustom
              eventName={EVENT.REQUEST.TAP_TOTAL_AMOUNT}
              onPress={handleScrollToExpenseSpendingList}
            >
              <CurrencyText style={Fonts.NumericN400} color={Colors.success100}>
                {expenseRequest?.totalAmount}
              </CurrencyText>
            </TouchableOpacityCustom>
          </View>
          {FEATURE_EXP_CASH_ADVANCE && (
            <View style={[styles.totalExpenseRow, { marginTop: -5 }]}>
              <AppText style={[Fonts.BodyRegular, styles.totalExpenseTitle]}>{t("advance")}</AppText>
              <TouchableOpacityCustom
                eventName={EVENT.REQUEST.TAP_CASH_ADVANCE_AMOUNT}
                onPress={handleScrollToCashAdvanceSection}
              >
                <CurrencyText style={Fonts.NumericN200}>{expenseRequest?.cashAdvances?.[0]?.amount}</CurrencyText>
              </TouchableOpacityCustom>
            </View>
          )}
        </>
      ) : null;
    if (isApprovedOrRejectView) {
      return (
        <FloatFooter style={styles.footer} onLayout={onLayoutFooter}>
          <View>
            {amountInfo}
            <View style={styles.row}>
              <Button
                eventName={EVENT.REQUEST.REJECTED}
                type="danger"
                disabled={disabledButton}
                style={styles.leftButton}
                onPress={() => rejectRequestModalRef?.current?.present()}
              >
                {t("reject")}
              </Button>
              <ButtonApproveRequest
                reason={reasonApprove}
                setReason={setReasonApprove}
                disabled={disabledButton}
                onApprove={handleApprove}
                requestTitle={expenseRequest?.title}
              />
            </View>
          </View>
        </FloatFooter>
      );
    }
    const isOwnerRequest = expenseRequest?.companyEmployee?.employeeId === user?.employee_id;
    if (
      isOwnerRequest &&
      expenseRequest?.status === REQUEST_STATUS.SUBMITTED &&
      !disabledUpdateOrCancelRequest &&
      !isPaidAt
    ) {
      return (
        <FloatFooter style={styles.footer} onLayout={onLayoutFooter}>
          <View>
            {amountInfo}
            <Button
              eventName={EVENT.REQUEST.CANCELED}
              type="danger"
              onPress={() => cancelRequestModalRef?.current?.present()}
            >
              {t("cancel_request")}
            </Button>
          </View>
        </FloatFooter>
      );
    }
    if (
      isOwnerRequest &&
      [REQUEST_STATUS.REJECTED, REQUEST_STATUS.CANCELLED, REQUEST_STATUS.DRAFT].includes(
        expenseRequest?.status as REQUEST_STATUS
      ) &&
      !disabledUpdateOrCancelRequest
    ) {
      return (
        <FloatFooter style={styles.footer} onLayout={onLayoutFooter}>
          {amountInfo}
          <Button
            eventName={EVENT.REQUEST.TAP_UPDATE}
            onPress={() =>
              navigation.navigate(SCREEN_NAME.EditRequestScreen, {
                expenseRequestId: expenseRequest?.expenseRequestId,
              })
            }
          >
            {t("update_request")}
          </Button>
        </FloatFooter>
      );
    }
    if (isOwnerRequest && expenseRequest?.status === REQUEST_STATUS.APPROVED) {
      const expenseActualListValid = expenseRequest?.expenses?.filter(
        (item) => item?.status === EXPENSE_STATUS.READY && !item?.expenseReport?.docSequence
      );
      const expenseIds = expenseActualListValid?.map((item) => item?.expenseId);
      const totalAmountExpense = expenseActualListValid?.reduce(
        (total, currentValue) => total + currentValue?.totalAmountWithVat,
        0
      );
      return (
        <FloatFooter style={styles.footer} onLayout={onLayoutFooter}>
          <View>
            <View style={styles.totalExpenseRow}>
              <TouchableOpacityCustom style={styles.flex} onPress={handleScrollToExpenseActualList}>
                <AppText style={[Fonts.BodyRegular, styles.totalExpenseTitle]}>
                  {t("expense_valid", { value: expenseActualListValid?.length })}
                </AppText>
              </TouchableOpacityCustom>
              <CurrencyText style={Fonts.NumericN400} color={Colors.success100}>
                {totalAmountExpense}
              </CurrencyText>
            </View>
            <View style={styles.buttonRow}>
              {(expenseRequest?.employeeId === user?.employee_id || isUserMod(user)) &&
                !expenseRequest?.isClosed &&
                expenseRequest?.status === REQUEST_STATUS.APPROVED &&
                isEmpty(expenseRequest?.expenses) &&
                !disabledUpdateOrCancelRequest &&
                !isPaidAt && (
                  <Button
                    eventName={EVENT.REQUEST.CANCELED}
                    type="dangerSecondary"
                    style={styles.flex}
                    onPress={() => cancelRequestModalRef?.current?.present()}
                  >
                    {t("cancel_request")}
                  </Button>
                )}
              <Button
                eventName={EVENT.REPORT.TAP_CREATE}
                style={styles.flex}
                disabled={expenseActualListValid?.length <= 0}
                onPress={() =>
                  navigation.navigate(SCREEN_NAME.NewReportScreen, {
                    expenseIds,
                    companyTeam: expenseRequest?.expenseRequestsTeams?.[0]?.companyTeam,
                  })
                }
              >
                {t("create_report")}
              </Button>
            </View>
          </View>
        </FloatFooter>
      );
    }
    if (
      isUserMod(user) &&
      !expenseRequest?.isClosed &&
      expenseRequest?.status === REQUEST_STATUS.APPROVED &&
      isEmpty(expenseRequest?.expenses) &&
      !disabledUpdateOrCancelRequest &&
      !isPaidAt
    ) {
      return (
        <FloatFooter style={styles.footer} onLayout={onLayoutFooter}>
          <Button
            eventName={EVENT.REQUEST.CANCELED}
            type="dangerSecondary"
            onPress={() => cancelRequestModalRef?.current?.present()}
          >
            {t("cancel_request")}
          </Button>
        </FloatFooter>
      );
    }
    return null;
  };
  const isLoading = loading || !isReady;
  const [index, setIndex] = useState(0);

  const routes = useMemo(() => {
    if (!expenseRequest?.expenseRequestType) {
      return [];
    }

    if (expenseRequest.expenseRequestType === REQUEST_TYPE.price_comparison) {
      return [
        { key: "detail", title: t("detail") },
        { key: "activity", title: t("activity") },
      ];
    }
    return [
      { key: "detail", title: t("detail") },
      {
        key: "spending_plan",
        title: t("spending_plan"),
      },
      { key: "activity", title: t("activity") },
    ];
  }, [expenseRequest?.expenseRequestType]);

  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case "detail":
        return (
          <DetailTab
            ref={detailTabRef}
            contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
            isApproverView={isApprovedOrRejectView}
            expenseRequest={expenseRequest}
            refetch={refetch}
            expenseListGroupByCategory={expenseListGroupByCategory}
          />
        );
      case "spending_plan":
        return (
          <SpendPlanningTab
            ref={spendPlanningTabRef}
            contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
            expenseListGroupByCategory={expenseListGroupByCategory}
            expenseRequest={expenseRequest}
            expenseActualListByGroup={expenseActualListByGroup}
            onRefreshDetail={refetch}
          />
        );
      case "activity":
        return (
          <ActivityLogTab
            contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
            logs={expenseRequest?.expenseApprovalLogs}
          />
        );
      default:
        return null;
    }
  };

  if (!isLoading && !expenseRequest) {
    return (
      <View style={styles.container}>
        <BackHeader
          containerStyle={{ paddingBottom: 5 }}
          headerTitle={t("request_detail")}
          headerRight={renderHeaderRight()}
        />
        <EmptyData
          style={styles.errorContainer}
          icon={<ErrorInvoiceIcon />}
          title={t("request_not_found_title")}
          description={t("request_not_found_description")}
        />
      </View>
    );
  }

  return (
    <BottomSheetModalProvider>
      <View style={styles.container}>
        <BackHeader
          containerStyle={{ paddingBottom: 5 }}
          headerTitle={t("request_detail")}
          headerRight={renderHeaderRight()}
        />
        <>
          {isLoading ? (
            <LoadingView />
          ) : (
            <TopTabLineView
              lazy={false}
              renderScene={renderScene}
              routes={routes}
              index={index}
              setIndex={setIndex}
              tabStyle={{ width: width / routes.length }}
            />
          )}
          {renderFooterButton()}
          <CancelExpenseRequestModal
            ref={cancelRequestModalRef}
            title={expenseRequest?.title}
            requestId={expenseRequest?.expenseRequestId}
            onRefreshData={onRefreshData}
          />
          <RejectExpenseRequestModal
            ref={rejectRequestModalRef}
            requestId={expenseRequest?.expenseRequestId}
            title={expenseRequest?.title}
            expenseRequest={expenseRequest}
            expenseListGroupByCategory={expenseListGroupByCategory}
            onRefreshData={() => onRefreshData(route.params.expenseRequestId)}
          />
          <ApproverChooserModal
            isRequired={false}
            ref={approverChooserModalRef}
            approverId={approverId}
            onSubmit={onApproveRequest}
            onApproverId={(approverId: string) => setApproverId(approverId)}
            title={t("select_approver_next")}
            titleSecond={t("select_approver_next")}
            description={t("description_select_approver")}
            confirmText={t("approve")}
            onClose={() => setApproverId(null)}
          />
          <AlertNotification ref={alertNotificationRef} />
        </>
      </View>
    </BottomSheetModalProvider>
  );
};
export default withTrackingSession(ExpenseRequestDetailScreen, SCREEN_NAME.DetailRequestScreen);

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  footer: {},
  ChatButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  flex: { flex: 1 },
  totalExpenseRow: { flexDirection: "row", marginBottom: 10, alignItems: "center" },
  totalExpenseTitle: { flex: 1, marginRight: 10 },
  row: { flexDirection: "row" },
  leftButton: { flex: 1, marginRight: 6 },
  buttonRow: { flexDirection: "row", gap: 8 },
  notificationBadge: {
    position: "absolute",
    right: 0,
    paddingHorizontal: 4,
    borderRadius: 8,
    backgroundColor: Colors.alert50,
    transform: [{ translateX: 6 }],
  },
  notificationBadgeText: {
    fontFamily: FontTypes.medium,
    fontSize: 8,
    lineHeight: 12,
  },
  errorContainer: { backgroundColor: Colors.white, paddingTop: 70 },
});
