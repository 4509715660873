import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, FlatList, Keyboard, Platform, RefreshControl, StyleSheet, View } from "react-native";
import { FlashList } from "@shopify/flash-list";

import {
  BizziBotEyesTwinkle,
  BizziBotWarning2,
  CancelReportWarningIcon,
  EmptyWaitingApproval,
} from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  BottomSheetTextInputCustom,
  CurrencyText,
  EmptyData,
  SkeletonListLoading,
  SortingLabel,
  ToastManager,
} from "components";
import { CONSTANTS, KIND_OF_APPROVAL, TRACKING_ACTION_TYPE, TRACKING_OBJECT_TYPE } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { Colors, Fonts } from "theme";
import useExpenseReportApprovalQuery from "./hooks/useExpenseReportApprovalQuery";
import ReportItem, { ReportItemDisplayMode } from "./components/ReportItem";
import useSelectList from "../../../hooks/useSelectList";
import BatchApprovalLayout from "components/BatchApprovalLayout/BatchApprovalLayout";
import useBatchApproveReports from "hooks/reports/useBatchApproveReports";
import { ApprovalModal, ApprovalModalApi, ApprovalModalSummary } from "components/BatchApprovalLayout/ApprovalModal";
import useBatchRejectReports from "hooks/reports/useBatchRejectReports";
import { REPORT_SORT_OPTIONS } from "./types";
import SCREEN_NAME from "navigation/ScreenName";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { FeedbackObjectType, useInAppFeedback } from "contexts/InAppFeedbackContext";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { ReportBatchApprovalScreenProps } from "navigation/type";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";

const PAGE_SIZE = 10;

const ReportBatchApprovalScreen: FC<ReportBatchApprovalScreenProps> = ({ route, navigation }) => {
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const { requestInAppFeedback } = useInAppFeedback();
  const { i18n, t } = useTranslation("app/screens/Report/ReportListScreen");
  const sortOptions = useMemo(
    () =>
      [
        { label: t("requested_at_asc"), value: REPORT_SORT_OPTIONS.REQUESTED_AT_ASC },
        { label: t("requested_at_desc"), value: REPORT_SORT_OPTIONS.REQUESTED_AT_DESC },
        {
          label: t("expense_asc"),
          value: REPORT_SORT_OPTIONS.AMOUNT_ASC,
        },
        {
          label: t("expense_desc"),
          value: REPORT_SORT_OPTIONS.AMOUNT_DESC,
        },
      ].reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [t]
  );
  const flatListRef = useRef<FlatList>(null);

  const [sortOption, setSortOption] = useState<SelectItem>(
    sortOptions[route?.params?.sortOption ?? REPORT_SORT_OPTIONS.REQUESTED_AT_ASC]
  );
  const { data, loading, called, refetch, fetchMore } = useExpenseReportApprovalQuery({
    where: {
      companyId,
      companyEmployeeId: employeeId,
      kindOfApproval: KIND_OF_APPROVAL.AWAITING_MY_APPROVAL,
    },
    limit: PAGE_SIZE,
    sortOption: sortOption.value,
    offset: 0,
  });
  const reportList = data?.expWaitingApprovalExpenseReports?.reports ?? [];
  const total = data?.expWaitingApprovalExpenseReports?.total ?? 0;
  const keyExtractor = useCallback((item) => item.expenseReportId, []);
  const { selected, selectStatus, handleClickItem, handleSelectAll, onClear } = useSelectList(reportList, keyExtractor);
  const selectedAmount = [...selected].reduce((acc, id) => {
    const expenses = reportList.find((report) => report.expenseReportId === id)?.expenses ?? [];
    return (acc += expenses.reduce((acc, expense) => (acc += expense.totalAmountWithVat), 0));
  }, 0);

  const handleLoadMore = async () => {
    if (loading || reportList.length >= total) {
      return;
    }
    await fetchMore({
      variables: { offset: reportList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          ...prev,
          expWaitingApprovalExpenseReports: {
            reports: [
              ...prev?.expWaitingApprovalExpenseReports?.reports,
              ...fetchMoreResult?.expWaitingApprovalExpenseReports?.reports,
            ],
            total: fetchMoreResult?.expWaitingApprovalExpenseReports?.total,
          },
        };
      },
    });
  };

  const renderItem = ({ item }) => {
    const key = keyExtractor(item);
    return (
      <ReportItem
        showCreator
        item={item}
        mode={ReportItemDisplayMode.Selectable}
        selected={selected.has(key)}
        onPress={({ target, value }) => {
          if (target === "view-detail") {
            analyticService.logEvent({ name: EVENT.REPORT.TAP_VIEW_DETAIL });
            navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
              expenseReportId: value,
              onRefreshData: () => {
                refetch?.();
              },
            });
          } else {
            handleClickItem(value);
          }
        }}
      />
    );
  };

  const approvalModalRef = useRef<ApprovalModalApi>(null);
  const notificationRef = useRef<AlertNotificationHandle>();
  const rejectModalRef = useRef<AlertNotificationHandle>();

  const [rejectReason, setRejectReason] = useState("");
  const [batchApprove] = useBatchApproveReports();
  const [batchReject] = useBatchRejectReports();
  const handleApproveSelected = async () => {
    analyticService.logEvent({ name: EVENT.BATCH_APPROVAL.SUBMITTED });
    notificationRef.current.loading(t("batch_approve_processing"));
    const reportIdsToApprove = [...selected].slice(0, CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL);
    try {
      const response = await batchApprove({ variables: { reportIds: reportIdsToApprove } });
      const failedReports = response.data.expenseApproveReports.reduce((acc, res) => {
        if (!res.success) {
          const report = reportList.find((report) => report.expenseReportId === res.reportId);
          acc.push({ error: res, obj: { ...report, status: res.status } });
        }
        return acc;
      }, []);
      notificationRef.current.close();
      if (failedReports.length > 0) {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS_PARTIAL,
          properties: {
            succeeded: reportIdsToApprove.length - failedReports.length,
            failed: failedReports.length,
            messages: failedReports.map((res) => res?.error?.message),
            object_type: TRACKING_OBJECT_TYPE.REPORT,
            action: TRACKING_ACTION_TYPE.APPROVED,
          },
        });
        approvalModalRef.current.show({
          summaryComponent: (
            <ApprovalModalSummary
              entry="report"
              successCount={reportIdsToApprove.length - failedReports.length}
              errorCount={failedReports.length}
            />
          ),
          data: failedReports,
          onConfirm: () => {
            approvalModalRef.current.close();
          },
        });
      } else {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS,
          properties: {
            succeeded: reportIdsToApprove.length - failedReports.length,
            failed: failedReports.length,
            object_type: TRACKING_OBJECT_TYPE.REPORT,
            action: TRACKING_ACTION_TYPE.APPROVED,
          },
        });
        requestInAppFeedback(FeedbackObjectType.ApprovalObject);
        ToastManager.success(
          t("batch_approved", {
            numberOfEntry: reportIdsToApprove.length,
          })
        );
      }
      onClearSelected();
    } catch (error) {
      analyticService.logEvent({
        name: EVENT.BATCH_APPROVAL.SUBMIT_FAILED,
        properties: {
          message: error?.message || i18n.t("common:internal_error").toString(),
          object_type: TRACKING_OBJECT_TYPE.REPORT,
          action: TRACKING_ACTION_TYPE.APPROVED,
        },
      });
      notificationRef?.current?.error({
        title: t("approve_failed"),
        description: error?.message || i18n.t("common:internal_error").toString(),
      });
    }
  };
  const onClearSelected = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
    onClear();
    refetch();
  };

  const handleRejectSelected = async () => {
    Keyboard.dismiss();
    analyticService.logEvent({ name: EVENT.BATCH_APPROVAL.SUBMITTED });
    rejectModalRef.current.close();
    notificationRef.current.loading(t("batch_reject_processing"));
    const reportIdsToReject = [...selected].slice(0, CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL);
    try {
      const response = await batchReject({ variables: { reportIds: reportIdsToReject, reason: rejectReason } });
      const failedReports = response.data.expenseRejectReports.reduce((acc, res) => {
        if (!res.success) {
          const report = reportList.find((report) => report.expenseReportId === res.reportId);
          acc.push({ error: res, obj: { ...report, status: res.status } });
        }
        return acc;
      }, []);
      notificationRef.current.close();
      if (failedReports.length > 0) {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS_PARTIAL,
          properties: {
            succeeded: reportIdsToReject.length - failedReports.length,
            failed: failedReports.length,
            messages: failedReports.map((res) => res?.error?.message),
            object_type: TRACKING_OBJECT_TYPE.REPORT,
            action: TRACKING_ACTION_TYPE.REJECTED,
          },
        });
        approvalModalRef.current.show({
          summaryComponent: (
            <ApprovalModalSummary
              entry="report"
              successCount={reportIdsToReject.length - failedReports.length}
              errorCount={failedReports.length}
            />
          ),
          data: failedReports,
          onConfirm: () => {
            notificationRef.current.close();
            approvalModalRef.current.close();
          },
        });
      } else {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS,
          properties: {
            succeeded: reportIdsToReject.length - failedReports.length,
            failed: failedReports.length,
            object_type: TRACKING_OBJECT_TYPE.REPORT,
            action: TRACKING_ACTION_TYPE.REJECTED,
          },
        });
        requestInAppFeedback(FeedbackObjectType.ApprovalObject);
        ToastManager.success(
          t("batch_rejected", {
            numberOfEntry: reportIdsToReject.length,
          })
        );
      }
      onClearSelected();
    } catch (error) {
      analyticService.logEvent({
        name: EVENT.BATCH_APPROVAL.SUBMIT_FAILED,
        properties: {
          message: error?.message || i18n.t("common:internal_error").toString(),
          object_type: TRACKING_OBJECT_TYPE.REPORT,
          action: TRACKING_ACTION_TYPE.REJECTED,
        },
      });
      notificationRef?.current?.error({
        title: t("reject_failed"),
        description: error?.message || i18n.t("common:internal_error").toString(),
      });
    } finally {
      setRejectReason("");
    }
  };

  const showConfirmPopup =
    ({ action }: { action: "approve" | "reject" }) =>
    () => {
      switch (action) {
        case "approve":
          notificationRef.current?.confirm({
            disableClose: true,
            icon: selected.size > 20 ? <BizziBotWarning2 width={80} height={80} /> : <BizziBotEyesTwinkle />,
            title:
              selected.size > 20
                ? t("approve_confirm_title_over")
                : t("approve_confirm_title", { numberOfEntry: selected.size }),
            description:
              selected.size > 20
                ? t("approve_confirm_with_warning_subtitle", {
                    numberOfEntry: CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL,
                  })
                : t("approve_confirm_subtitle"),
            cancelText: t("cancel"),
            confirmText: t("confirm"),
            onCancel: () => {
              notificationRef.current.close();
            },
            onConfirm: handleApproveSelected,
          });
          break;
        case "reject":
          rejectModalRef.current?.confirm({
            icon: <CancelReportWarningIcon />,
            title:
              selected.size > 20
                ? t("reject_confirm_title_over")
                : t("reject_confirm_title", {
                    numberOfEntry: selected.size,
                  }),
            description: (
              <View style={styles.rejectModalContent}>
                <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale80}>
                  {selected.size > 20
                    ? t("reject_confirm_with_warning_subtitle", {
                        numberOfEntry: CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL,
                      })
                    : t("reject_confirm_subtitle")}
                </AppText>
                <BottomSheetTextInputCustom
                  autoFocus={Platform.OS !== "web"}
                  value={rejectReason}
                  onChangeText={setRejectReason}
                  placeholder={t("please_enter_reason")}
                />
              </View>
            ),
            cancelText: t("cancel"),
            confirmText: t("confirm"),
            onCancel: () => {
              setRejectReason("");
              notificationRef.current.close();
            },
            onConfirm: handleRejectSelected,
          });
          break;
      }
    };

  const [footerHeight, setFooterHeight] = useState(0);
  const onFooterLayout = (event) => {
    if (event.nativeEvent.layout.height) {
      setFooterHeight(event.nativeEvent.layout.height);
    }
  };

  return (
    <BottomSheetModalProvider>
      <View style={styles.container}>
        <BackHeader headerTitle={t("batch_approval")} />
        <BatchApprovalLayout
          onFooterLayout={onFooterLayout}
          selected={selected}
          selectStatus={selectStatus}
          onAllButtonPress={handleSelectAll}
          onApprove={showConfirmPopup({ action: "approve" })}
          onReject={showConfirmPopup({ action: "reject" })}
          summaryComponent={
            <View style={styles.summaryContainer}>
              <View style={styles.summaryRow}>
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
                  {t("has_selected_count_maximum", {
                    count: selected.size,
                  })}
                </AppText>
              </View>
              <View style={styles.summaryRow}>
                <AppText>{t("total_amount")}</AppText>
                <CurrencyText style={Fonts.NumericN400} color={Colors.success50}>
                  {selectedAmount}
                </CurrencyText>
              </View>
            </View>
          }
          sortingComponent={
            <SortingLabel
              sortingModalProps={{
                showClearButton: false,
                title: t("sort_by"),
                data: Object.values(sortOptions),
                value: sortOption,
                setValue: setSortOption,
              }}
            />
          }
        >
          {loading && !data ? (
            <SkeletonListLoading />
          ) : (
            <FlashList
              refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
              keyExtractor={keyExtractor}
              data={reportList}
              extraData={selected}
              ref={flatListRef}
              contentContainerStyle={{ ...styles.listContainer, paddingBottom: footerHeight }}
              renderItem={renderItem}
              estimatedItemSize={88}
              onEndReached={handleLoadMore}
              showsVerticalScrollIndicator={false}
              onEndReachedThreshold={0.15}
              ListEmptyComponent={
                !loading &&
                called && (
                  <EmptyData
                    icon={<EmptyWaitingApproval />}
                    title={t("empty_approval_title")}
                    description={t("empty_approval_description")}
                  />
                )
              }
              ListFooterComponent={
                loading &&
                reportList?.length > 0 && (
                  <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />
                )
              }
            />
          )}
        </BatchApprovalLayout>
        <ApprovalModal
          ref={approvalModalRef}
          keyExtractor={keyExtractor}
          renderItem={({ item }) => (
            <ReportItem
              isLast={true}
              showCreator
              item={item}
              onPress={({ value }) => {
                const expenseReportId = value;
                navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
                  expenseReportId,
                  onRefreshData: (id) => {
                    id && approvalModalRef.current.removeItem(id);
                  },
                });
              }}
            />
          )}
        />
      </View>
      <AlertNotification ref={notificationRef} />
      <AlertNotification ref={rejectModalRef} />
    </BottomSheetModalProvider>
  );
};
export default ReportBatchApprovalScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  listContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  loadingMore: { marginTop: 10 },
  summaryContainer: {
    gap: 4,
    paddingVertical: 4,
  },
  summaryRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
  },
  rejectModalContent: {
    alignItems: "center",
  },
  textCenter: { textAlign: "center" },
});
