import { AppText, Line, LoadingView, PercentLabel, PieChart } from "components";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import { CloseSmallIcon, WarningIcon } from "assets/images/svg/icons";
import { FC, memo, useEffect, useState } from "react";
import { MobileExpInsightsExpenseOverviewQuery } from "types";
import { PieChartDataItem } from "components/Chart/PieChart";
import { formatMoneyString } from "utils";
import { useTranslation } from "react-i18next";
import { HEIGHT_WINDOW } from "constants/Layout";
import { TIME_FILTER_OPTIONS } from "screens/ExpenseOverview/types";

interface OverviewChartSectionProps {
  loading?: boolean;
  expenseData?: MobileExpInsightsExpenseOverviewQuery["expInsights"];
  isCompare?: boolean;

  currentTimeFilter: TIME_FILTER_OPTIONS;
  isShowWarning: boolean;
  setShowWarning: (value: boolean) => void;
  refreshing: boolean;
}
type AmountItem = { amount: number; percent: number; color: string; textColor?: string; showPercent?: boolean };
type ChartDataOverview = {
  approvedExpense: AmountItem;
  pendingExpense?: AmountItem;
  remainingSpendingPlan?: AmountItem;
  remainingBudget?: AmountItem;
  closedSpendingPlan?: AmountItem;
};
const OverviewChartSection: FC<OverviewChartSectionProps> = ({
  loading,
  expenseData,
  isCompare,
  currentTimeFilter,
  isShowWarning,
  setShowWarning,
  refreshing,
}) => {
  const [chartDataOverview, setChartDataOverview] = useState<ChartDataOverview>();
  const [chartData, setChartData] = useState<PieChartDataItem[]>([]);

  const { t } = useTranslation("app/screens/ExpenseOverview/ExpenseOverviewScreen");
  const isSetupBudget = Boolean(expenseData?.budget);
  const totalBudgetAmount = expenseData?.budget?.totalAmount ?? 0;
  const totalSpendingPlanAmount = expenseData?.spendingPlan?.totalAmount ?? 0;
  const approvedExpenseAmount = expenseData?.approvedExpense?.totalAmount ?? 0;
  const pendingExpenseAmount = expenseData?.pendingExpense?.totalAmount ?? 0;
  const remainingSpendingPlanAmount = isSetupBudget ? expenseData?.remainingSpendingPlan?.totalAmount : 0;
  const remainingBudgetAmount =
    totalBudgetAmount - pendingExpenseAmount - approvedExpenseAmount - remainingSpendingPlanAmount;
  const total = isSetupBudget ? totalBudgetAmount : totalSpendingPlanAmount;
  useEffect(() => {
    if (expenseData) {
      const minPercent = 0.01;
      let newChartDataOverview: ChartDataOverview;
      let percentApprovedExpense = total ? Math.round((approvedExpenseAmount / total) * 100) / 100 : 0;
      let percentPendingExpense = total ? Math.round((pendingExpenseAmount / total) * 100) / 100 : 0;
      let percentRemainingSpendingPlan = total ? Math.round((remainingSpendingPlanAmount / total) * 100) / 100 : 0;
      if (percentApprovedExpense === 0 && approvedExpenseAmount !== 0) {
        percentApprovedExpense = minPercent;
      }
      if (percentPendingExpense === 0 && pendingExpenseAmount !== 0) {
        percentPendingExpense = minPercent;
      }
      if (percentRemainingSpendingPlan === 0 && remainingSpendingPlanAmount !== 0) {
        percentRemainingSpendingPlan = minPercent;
      }
      if (approvedExpenseAmount > total) {
        newChartDataOverview = {
          approvedExpense: {
            amount: approvedExpenseAmount,
            percent: 1,
            color: Colors.alert50,
            textColor: Colors.white,
            showPercent: false,
          },
        };
      } else if (approvedExpenseAmount + pendingExpenseAmount > total) {
        newChartDataOverview = {
          approvedExpense: {
            amount: approvedExpenseAmount,
            percent: percentApprovedExpense,
            color: Colors.primary50,
            textColor: Colors.white,
          },
          pendingExpense: {
            amount: pendingExpenseAmount,
            percent: 1 - percentApprovedExpense,
            color: Colors.alert50,
            textColor: Colors.white,
            showPercent: false,
          },
        };
      } else if (approvedExpenseAmount + pendingExpenseAmount + remainingSpendingPlanAmount > total) {
        newChartDataOverview = {
          approvedExpense: {
            amount: approvedExpenseAmount,
            percent: percentApprovedExpense,
            color: Colors.primary50,
            textColor: Colors.white,
          },
          pendingExpense: {
            amount: pendingExpenseAmount,
            percent: percentPendingExpense,
            color: Colors.primary10,
            textColor: Colors.grayscale100,
          },
          remainingSpendingPlan: {
            amount: remainingSpendingPlanAmount,
            percent: 1 - percentApprovedExpense - percentPendingExpense,
            color: Colors.alert50,
            textColor: Colors.white,
            showPercent: false,
          },
        };
      } else {
        newChartDataOverview = {
          approvedExpense: {
            amount: approvedExpenseAmount,
            percent: percentApprovedExpense,
            color: Colors.primary50,
            textColor: Colors.white,
          },
          pendingExpense: {
            amount: pendingExpenseAmount,
            percent: percentPendingExpense,
            color: Colors.primary10,
            textColor: Colors.grayscale100,
          },
          remainingSpendingPlan: {
            amount: remainingSpendingPlanAmount,
            percent: percentRemainingSpendingPlan,
            color: Colors.success50,
            textColor: Colors.white,
          },
          remainingBudget: {
            amount: remainingBudgetAmount,
            percent: 1 - percentApprovedExpense - percentPendingExpense - percentRemainingSpendingPlan,
            color: Colors.grayscale05,
            textColor: Colors.grayscale100,
          },
        };
      }
      if (!totalBudgetAmount) {
        delete newChartDataOverview.remainingBudget;
      }
      setChartDataOverview(newChartDataOverview);
      let chartData = Object.keys(newChartDataOverview).map((item) => ({
        percent: newChartDataOverview[item].percent,
        color: newChartDataOverview[item].color,
        textColor: newChartDataOverview[item].textColor,
        showPercent: newChartDataOverview[item].showPercent,
      }));
      const totalPercent = chartData.reduce((total, item) => total + item?.percent, 0);
      // show remain percent if exist
      if (!isSetupBudget && totalPercent < 1) {
        chartData.push({
          percent: 1 - totalPercent,
          color: Colors.grayscale10,
          textColor: Colors.grayscale100,
          showPercent: true,
        });
      }
      //in the case show empty state
      if (!totalBudgetAmount && chartData.every((item) => !item?.percent)) {
        chartData = [
          {
            percent: 1,
            color: Colors.grayscale05,
            showPercent: false,
            textColor: "",
          },
        ];
      }

      setChartData(chartData);
    }
  }, [expenseData]);
  const approveExpenseAmountOverBudget = approvedExpenseAmount > total;
  const pendingExpenseAmountOverBudget =
    pendingExpenseAmount &&
    (approvedExpenseAmount + pendingExpenseAmount > total || !chartDataOverview?.pendingExpense);
  const remainingSpendingPlanAmountOverBudget =
    remainingSpendingPlanAmount &&
    (approvedExpenseAmount + pendingExpenseAmount + remainingSpendingPlanAmount > total ||
      !chartDataOverview?.remainingSpendingPlan);
  const renderWarningContent = () => {
    if (
      !isShowWarning ||
      (!approveExpenseAmountOverBudget && !pendingExpenseAmountOverBudget && !remainingSpendingPlanAmountOverBudget)
    ) {
      return null;
    }
    let content = null;
    let timeLabel;
    switch (currentTimeFilter) {
      case TIME_FILTER_OPTIONS.MONTH: {
        timeLabel = t("this_month");
        break;
      }
      case TIME_FILTER_OPTIONS.QUARTER: {
        timeLabel = t("this_quarter");
        break;
      }
      case TIME_FILTER_OPTIONS.YEAR:
      case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
        timeLabel = t("this_year");
        break;
      }
      case TIME_FILTER_OPTIONS.CUSTOMIZE: {
        timeLabel = t("this_time");
        break;
      }
    }
    if (approveExpenseAmountOverBudget && (pendingExpenseAmountOverBudget || remainingSpendingPlanAmountOverBudget)) {
      content = (
        <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
          {t("total_budget", { label: timeLabel })}{" "}
          <AppText style={Fonts.H200} color={Colors.grayscale80}>
            {`${t(isSetupBudget ? "is_over_budget" : "is_over_spending_plan")} ${formatMoneyString(
              approvedExpenseAmount - total,
              true
            )}`}
          </AppText>{" "}
          {`${t("can_is_over_budget")} ${formatMoneyString(
            approvedExpenseAmount - total + pendingExpenseAmount + remainingSpendingPlanAmount,
            true
          )}`}
        </AppText>
      );
    } else if (approveExpenseAmountOverBudget) {
      content = (
        <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
          {t("total_budget", { label: timeLabel })}{" "}
          <AppText style={Fonts.H200} color={Colors.grayscale80}>
            {`${t(isSetupBudget ? "is_over_budget" : "is_over_spending_plan")} ${formatMoneyString(
              approvedExpenseAmount + pendingExpenseAmount + remainingSpendingPlanAmount - total,
              true
            )}`}
          </AppText>
        </AppText>
      );
    } else if (pendingExpenseAmountOverBudget || remainingSpendingPlanAmountOverBudget) {
      content = (
        <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
          {t(isSetupBudget ? "can_over_budget" : "can_over_spending_plan", {
            label: timeLabel,
            amount: formatMoneyString(
              approvedExpenseAmount + pendingExpenseAmount + remainingSpendingPlanAmount - total,
              true
            ),
          })}
        </AppText>
      );
    }
    return (
      <View style={styles.warningContainer}>
        <WarningIcon />
        {content}
        <TouchableOpacity onPress={() => setShowWarning(false)}>
          <CloseSmallIcon />
        </TouchableOpacity>
      </View>
    );
  };
  if (loading) {
    return <LoadingView style={[styles.container, { height: HEIGHT_WINDOW / 1.4 }]} />;
  }

  return (
    <View style={styles.container}>
      {renderWarningContent()}
      <View style={{ alignItems: "center", paddingBottom: 20 }}>
        <PieChart
          refreshing={refreshing}
          totalTitle={isSetupBudget ? t("budget") : t("spending_plan")}
          total={isSetupBudget ? formatMoneyString(totalBudgetAmount) : formatMoneyString(totalSpendingPlanAmount)}
          data={chartData}
        />
      </View>
      <View style={styles.card}>
        <View style={styles.headerRow}>
          <View style={styles.row}>
            <View style={[styles.row, styles.flex]}>
              <View
                style={[
                  styles.dotIcon,
                  { backgroundColor: approveExpenseAmountOverBudget ? Colors.alert50 : Colors.primary50 },
                ]}
              />
              <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1} ellipsizeMode="head">
                {t("expense_approved")}{" "}
                {isCompare && (
                  <PercentLabel
                    style={styles.percentLabel}
                    value={Math.round(expenseData?.approvedExpense?.totalAmountDiffPercentage)}
                  />
                )}
              </AppText>
            </View>
            <AppText style={Fonts.NumericN200}>{formatMoneyString(expenseData?.approvedExpense?.totalAmount)}</AppText>
          </View>
          {Boolean(approveExpenseAmountOverBudget) && (
            <AppText style={styles.overBudgetAmountText}>
              {t(isSetupBudget ? "over_budget_amount" : "over_spending_plan", {
                amount: formatMoneyString(approvedExpenseAmount - total, true),
              })}
            </AppText>
          )}
        </View>
        <View style={styles.contentRow}>
          <AppText style={[Fonts.NumericN200, styles.flex]} color={Colors.grayscale70}>
            {expenseData?.approvedExpense?.totalApprovedExpenses}
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{` ${
              expenseData?.approvedExpense?.totalApprovedExpenses > 1
                ? t("expenses_approved")
                : t("expense_approved").toLowerCase()
            }`}</AppText>
          </AppText>
          <AppText style={Fonts.NumericN200} color={Colors.grayscale70}>
            {formatMoneyString(expenseData?.approvedExpense?.totalApprovedAmount)}
          </AppText>
        </View>
      </View>
      <View style={[styles.card, styles.mt20]}>
        <View style={styles.headerRow}>
          <View style={styles.row}>
            <View style={[styles.row, styles.flex]}>
              <View
                style={[
                  styles.dotIcon,
                  { backgroundColor: pendingExpenseAmountOverBudget ? Colors.alert50 : Colors.primary10 },
                ]}
              />
              <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
                {t("expense_pending")}
              </AppText>
            </View>
            <AppText style={Fonts.NumericN200}>{formatMoneyString(expenseData?.pendingExpense?.totalAmount)}</AppText>
          </View>
          {Boolean(pendingExpenseAmountOverBudget) && (
            <AppText style={styles.overBudgetAmountText}>
              {t(isSetupBudget ? "over_budget_amount" : "over_spending_plan", {
                amount: formatMoneyString(
                  chartDataOverview?.pendingExpense
                    ? approvedExpenseAmount + pendingExpenseAmount - total
                    : pendingExpenseAmount,
                  true
                ),
              })}
            </AppText>
          )}
        </View>
        <View style={styles.contentRow}>
          <AppText style={[Fonts.NumericN200, styles.flex]} color={Colors.grayscale70}>
            {expenseData?.pendingExpense?.totalClaimedExpenses}
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{` ${
              expenseData?.pendingExpense?.totalClaimedExpenses > 1 ? t("expenses_reported") : t("expense_reported")
            }`}</AppText>
          </AppText>
          <AppText style={Fonts.NumericN200} color={Colors.grayscale70}>
            {formatMoneyString(expenseData?.pendingExpense?.totalClaimedAmount)}
          </AppText>
        </View>
        <View style={styles.contentRow}>
          <AppText style={[Fonts.NumericN200, styles.flex]} color={Colors.grayscale70}>
            {expenseData?.pendingExpense?.totalUnclaimedExpenses}
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{` ${
              expenseData?.pendingExpense?.totalUnclaimedExpenses > 1
                ? t("expenses_not_report")
                : t("expense_not_report")
            }`}</AppText>
          </AppText>
          <AppText style={Fonts.NumericN200} color={Colors.grayscale70}>
            {formatMoneyString(expenseData?.pendingExpense?.totalUnclaimedAmount)}
          </AppText>
        </View>
      </View>
      {isSetupBudget ? (
        <>
          <View style={[styles.card, styles.mt20]}>
            <View style={styles.headerRow}>
              <View style={styles.row}>
                <View style={[styles.row, styles.flex]}>
                  {isSetupBudget && (
                    <View
                      style={[
                        styles.dotIcon,
                        {
                          backgroundColor: remainingSpendingPlanAmountOverBudget ? Colors.alert50 : Colors.success50,
                        },
                      ]}
                    />
                  )}
                  <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
                    {t("remaining_plain_amount")}
                  </AppText>
                </View>
                <AppText style={Fonts.NumericN200}>
                  {formatMoneyString(expenseData?.remainingSpendingPlan?.totalAmount)}
                </AppText>
              </View>
              {Boolean(remainingSpendingPlanAmountOverBudget) && (
                <AppText style={styles.overBudgetAmountText}>
                  {t("over_budget_amount", {
                    amount: formatMoneyString(
                      chartDataOverview?.remainingSpendingPlan
                        ? approvedExpenseAmount + pendingExpenseAmount + remainingSpendingPlanAmount - total
                        : remainingSpendingPlanAmount,
                      true
                    ),
                  })}
                </AppText>
              )}
            </View>

            <View style={styles.contentRow}>
              <AppText style={[Fonts.NumericN200, styles.flex]} color={Colors.grayscale70}>
                {expenseData?.remainingSpendingPlan?.totalRemainingRequests}
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{` ${
                  expenseData?.remainingSpendingPlan?.totalRemainingRequests > 1
                    ? t("remaining_spending_plan")
                    : t("remaining_spending_plan")
                }`}</AppText>
              </AppText>
              <AppText style={Fonts.NumericN200} color={Colors.grayscale70}>
                {formatMoneyString(expenseData?.remainingSpendingPlan?.totalRemainingAmount)}
              </AppText>
            </View>
            <View style={styles.contentRow}>
              <AppText style={[Fonts.NumericN200, styles.flex]} color={Colors.grayscale70}>
                {expenseData?.remainingSpendingPlan?.totalPendingRequests}
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{` ${
                  expenseData?.remainingSpendingPlan?.totalPendingRequests > 1
                    ? t("requests_pending")
                    : t("request_pending")
                }`}</AppText>
              </AppText>
              <AppText style={Fonts.NumericN200} color={Colors.grayscale70}>
                {formatMoneyString(expenseData?.remainingSpendingPlan?.totalPendingAmount)}
              </AppText>
            </View>
          </View>
          <View style={[styles.card, styles.mt20]}>
            <View style={styles.headerRow}>
              <View style={styles.row}>
                <View style={[styles.row, styles.flex]}>
                  <View style={[styles.dotIcon, { backgroundColor: Colors.grayscale10 }]} />
                  <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
                    {t("budget_no_use")}
                  </AppText>
                </View>
                <AppText style={Fonts.NumericN200}>
                  {formatMoneyString(remainingBudgetAmount > 0 ? remainingBudgetAmount : 0)}
                </AppText>
              </View>
            </View>
          </View>
        </>
      ) : (
        <>
          <View style={[styles.card, styles.mt20]}>
            <View style={styles.headerRow}>
              <View style={styles.row}>
                <View style={[styles.row, styles.flex]}>
                  <View
                    style={[
                      styles.dotIcon,
                      {
                        backgroundColor: Colors.grayscale10,
                      },
                    ]}
                  />
                  <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
                    {t("expense_not_been_allocated")}
                  </AppText>
                </View>
                <AppText style={Fonts.NumericN200}>
                  {formatMoneyString(
                    totalSpendingPlanAmount - approvedExpenseAmount - pendingExpenseAmount > 0
                      ? totalSpendingPlanAmount - approvedExpenseAmount - pendingExpenseAmount
                      : 0
                  )}
                </AppText>
              </View>
            </View>
          </View>
          <Line containerStyle={{ marginVertical: 16 }} />
          <View style={styles.card}>
            <View style={styles.contentRow}>
              <AppText style={[Fonts.NumericN200, styles.flex]} color={Colors.grayscale70}>
                {expenseData?.remainingSpendingPlan?.totalRemainingRequests}
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{` ${
                  expenseData?.remainingSpendingPlan?.totalRemainingRequests > 1
                    ? t("remaining_spending_plan")
                    : t("remaining_spending_plan")
                }`}</AppText>
              </AppText>
              <AppText style={Fonts.NumericN200} color={Colors.grayscale70}>
                {formatMoneyString(expenseData?.remainingSpendingPlan?.totalRemainingAmount)}
              </AppText>
            </View>
            <View style={styles.contentRow}>
              <AppText style={[Fonts.NumericN200, styles.flex]} color={Colors.grayscale70}>
                {expenseData?.remainingSpendingPlan?.totalPendingRequests}
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{` ${
                  expenseData?.remainingSpendingPlan?.totalPendingRequests > 1
                    ? t("requests_pending")
                    : t("request_pending")
                }`}</AppText>
              </AppText>
              <AppText style={Fonts.NumericN200} color={Colors.grayscale70}>
                {formatMoneyString(expenseData?.remainingSpendingPlan?.totalPendingAmount)}
              </AppText>
            </View>
          </View>
        </>
      )}
    </View>
  );
};
export default memo(OverviewChartSection);
const styles = StyleSheet.create({
  container: { paddingHorizontal: 20, paddingBottom: 20 },
  dotIcon: { width: 10, height: 10, borderRadius: 5 },
  flex: { flex: 1 },
  overBudgetAmountText: { ...Fonts.BodySmall, color: Colors.alert50, marginTop: 8, textAlign: "right" },
  warningContainer: {
    backgroundColor: Colors.warning0,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginBottom: 20,
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
  },
  card: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.grayscale10,
  },
  headerRow: {
    padding: 12,
    backgroundColor: Colors.grayscale0,
    borderColor: Colors.grayscale05,
    borderWidth: 1,
    borderRadius: 4,
  },
  contentRow: { flexDirection: "row", gap: 4, paddingLeft: 30, padding: 12 },
  mt20: {
    marginTop: 20,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    gap: 8,
  },
  percentLabel: {
    paddingTop: 6,
  },
});
