import {
  AppText,
  DatePickerCustom,
  IconCustom,
  SwitchButton,
  TextInputCustom,
  useSetRelativePosition,
} from "components";
import { Colors, Fonts } from "theme";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import React, { forwardRef, RefObject, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { ALLOWANCE_TYPE, BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { startLayoutAnimation } from "utils";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import dayjs from "dayjs";
import { BusTicketFromIcon, BusTicketToIcon } from "assets/images/svg/icons";
import LocationInput from "./LocationInput";
import { Keys } from "constants/Keys";
import { v4 } from "uuid";
import useExpensePoliciesBookingByLocation from "screens/ExpenseRequest/hooks/useExpensePoliciesBookingByLocation";
import { useAuth } from "contexts/AuthContext";
import { useMasterData } from "contexts/MasterDataContext";

interface BusTicketSectionProps {
  name?: string;
  control?: Control<ExpenseRequestFormValues>;
  rules?: RegisterOptions<ExpenseRequestFormValues, "paymentInfo">;
  errors?: FieldErrors<ExpenseRequestFormValues>;
  setValue?: UseFormSetValue<ExpenseRequestFormValues>;
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
  watch?: UseFormWatch<ExpenseRequestFormValues>;
  totalAmount?: number;
  setPosition?: (key: string) => (y: number) => void;
  setDateModalInfo?: any;
  containerRef?: RefObject<any>;
}
const BusTicketSection = forwardRef<any, BusTicketSectionProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleLoadAllowances: handleLoadPoliciesPerdiem,
  }));
  const { setValue, control, errors, watch, setPosition, setDateModalInfo, getValues, containerRef } = props;
  const childRef = useRef(null);
  useSetRelativePosition({
    containerRef,
    childRef,
    setPosition: (y) => {
      setPosition("transportationBooking")(y);
    },
  });

  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const {
    user: { employee_id },
  } = useAuth();
  const {
    setting: { allowPastTimeOnExpenseRequestCreation },
  } = useMasterData();

  const [onFetchPoliciesBooking] = useExpensePoliciesBookingByLocation();
  const transportationBooking = watch("transportationBooking");

  const handleLoadPoliciesPerdiem = async () => {
    const allowances = getValues("allowances")?.filter((item) => item?.type !== ALLOWANCE_TYPE.BOOKING_BUS);
    const locationToCode = transportationBooking?.to?.metadata?.code;
    const locationFromCode = transportationBooking?.from?.metadata?.code;
    if (!locationToCode && !locationFromCode) {
      setValue("allowances", allowances);
      return;
    }
    let totalAmount = 0;
    let expenseCategory = null;
    if (locationToCode) {
      const rs = await onFetchPoliciesBooking({
        variables: {
          input: { employeeId: employee_id, locationCode: locationToCode, bookingType: BOOKING_TYPE.TRANSPORTATION },
        },
      });
      expenseCategory = rs?.data?.expExpensePoliciesBookingByLocation?.expenseCategory;
      if (!expenseCategory) {
        setValue("transportationBooking.to", null);
      }
      totalAmount += rs?.data?.expExpensePoliciesBookingByLocation?.amount;
    }
    if (transportationBooking?.isRoundTrip && locationFromCode) {
      const rs = await onFetchPoliciesBooking({
        variables: {
          input: { employeeId: employee_id, locationCode: locationFromCode, bookingType: BOOKING_TYPE.TRANSPORTATION },
        },
      });
      // trong trường hợp bật khứ hồi: số tiền trợ cấp điểm về sẽ là số tiền lớn nhất giữa chiều đi và chiều về
      totalAmount = Math.max(rs?.data?.expExpensePoliciesBookingByLocation?.amount ?? 0, totalAmount) * 2;
      if (!rs?.data?.expExpensePoliciesBookingByLocation?.expenseCategory) {
        setValue("transportationBooking.from", null);
      }
      if (!expenseCategory) {
        expenseCategory = rs?.data?.expExpensePoliciesBookingByLocation?.expenseCategory;
      }
    }
    if (!expenseCategory || !getValues("transportationBooking.isOn")) {
      startLayoutAnimation();
      setValue("allowances", allowances);
      return;
    }
    const allowanceItem = {
      id: v4(),
      amount: totalAmount,
      expenseCategoryId: expenseCategory?.expenseCategoryId,
      type: ALLOWANCE_TYPE.BOOKING_BUS,
      expenseCategory: {
        title: expenseCategory?.title,
        titleEn: expenseCategory?.titleEn,
      },
    };
    const travelPerdiemAllowances = allowances.filter((i) => i?.type === ALLOWANCE_TYPE.PERDIEM);
    const flightBooking = allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_FLIGHT);
    let insertToIndex = travelPerdiemAllowances?.length || 0;
    if (flightBooking) {
      insertToIndex += 1;
    }
    // insert bus booking allowances after flight booking and travel perdiem allowances for each day if exist
    allowances.splice(insertToIndex, 0, allowanceItem);
    startLayoutAnimation();
    setValue("allowances", allowances);
  };
  const onToggleBusTicket = () => {
    const allowances = getValues("allowances");
    const isEnable = !transportationBooking?.isOn;
    startLayoutAnimation();
    if (!isEnable) {
      setValue(
        "allowances",
        allowances?.filter((item) => item?.type !== ALLOWANCE_TYPE.BOOKING_BUS)
      );
    } else if (!allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_BUS)) {
      handleLoadPoliciesPerdiem();
    }
    setValue("transportationBooking.isOn", !transportationBooking?.isOn);
  };
  const onToggleIsRoundTrip = () => {
    startLayoutAnimation();
    setValue("transportationBooking.isRoundTrip", !transportationBooking?.isRoundTrip);
    handleLoadPoliciesPerdiem();
  };

  const handleSetLocation = (key, value, config) => {
    setValue(key, value, config);
    handleLoadPoliciesPerdiem();
  };
  const handleSwapLocation = () => {
    const from = getValues("transportationBooking.from");
    const to = getValues("transportationBooking.to");
    setValue("transportationBooking.from", to);
    setValue("transportationBooking.to", from);
    handleLoadPoliciesPerdiem();
  };
  return (
    <View style={styles.container} ref={childRef}>
      <View style={styles.titleContainer}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("bus_ticket")}</AppText>
        <SwitchButton onValueChange={onToggleBusTicket} value={transportationBooking?.isOn} />
      </View>
      {transportationBooking?.isOn && (
        <>
          <View style={styles.contentContainer}>
            <View>
              <LocationInput
                isSearchAllWithoutPolicy
                name="transportationBooking.from"
                control={control}
                label={t("location_from")}
                style={styles.textInput}
                setValue={handleSetLocation}
                error={errors?.transportationBooking?.from}
                rules={{ required: true }}
                leftIcon={<BusTicketFromIcon />}
                value={transportationBooking?.from}
                searchDescription={t("search_bus_location_from_description")}
                recentLocationKey={`${employee_id}${Keys.RECENT_BUS_LOCATION_FROM}`}
                bookingType={BOOKING_TYPE.TRANSPORTATION}
                handleAutoAddAllowances={handleLoadPoliciesPerdiem}
              />
              <LocationInput
                name="transportationBooking.to"
                control={control}
                label={t("location_to")}
                style={styles.textInput}
                setValue={handleSetLocation}
                error={errors?.transportationBooking?.to}
                rules={{ required: true }}
                leftIcon={<BusTicketToIcon />}
                value={transportationBooking?.to}
                searchDescription={t("search_bus_location_to_description")}
                recentLocationKey={`${employee_id}${Keys.RECENT_BUS_LOCATION_TO}`}
                bookingType={BOOKING_TYPE.TRANSPORTATION}
                handleAutoAddAllowances={handleLoadPoliciesPerdiem}
                notFoundDescription={t("search_airline_not_found_description")}
              />

              <TouchableHighlight
                style={styles.travelLocationsSwapButton}
                onPress={handleSwapLocation}
                underlayColor={Colors.grayscale10}
              >
                <IconCustom name="compare-arrows" />
              </TouchableHighlight>
            </View>
            <DatePickerCustom
              rules={{ required: t("required") }}
              formatValue="ddd, DD/MM/YYYY"
              style={styles.textInput}
              name="transportationBooking.departureDate"
              control={control}
              label={t("from_date")}
              placeholder={t("from_date")}
              error={errors?.transportationBooking?.departureDate}
              minimumDate={allowPastTimeOnExpenseRequestCreation ? undefined : dayjs().toDate()}
              onPress={() =>
                setDateModalInfo({
                  visible: true,
                  field: "transportationBooking.departureDate",
                  date: getValues("transportationBooking.departureDate"),
                  minimumDate: allowPastTimeOnExpenseRequestCreation ? undefined : dayjs().toDate(),
                })
              }
            />
            <TextInputCustom
              style={[styles.textInput, { marginTop: 2 }]}
              multiline
              autoHeight
              name="transportationBooking.departureNote"
              control={control}
              label={t("airline_ticket_note")}
              placeholder={t("airline_ticket_note")}
            />
            <View style={styles.roundTripRow}>
              <AppText style={Fonts.BodyLarge} color={Colors.grayscale80}>
                {t("round_trip")}
              </AppText>
              <SwitchButton
                size="small"
                onValueChange={onToggleIsRoundTrip}
                value={transportationBooking?.isRoundTrip}
              />
            </View>
            {transportationBooking?.isRoundTrip && (
              <View style={{ marginTop: 6 }}>
                <DatePickerCustom
                  rules={{ required: t("required") }}
                  formatValue="ddd, DD/MM/YYYY"
                  style={styles.textInput}
                  name="transportationBooking.returnDate"
                  error={errors?.transportationBooking?.returnDate}
                  control={control}
                  label={t("return_date")}
                  placeholder={t("return_date")}
                  minimumDate={transportationBooking.departureDate}
                  onPress={() =>
                    setDateModalInfo({
                      visible: true,
                      field: "transportationBooking.returnDate",
                      date: getValues("transportationBooking.returnDate"),
                      minimumDate: getValues("transportationBooking.departureDate"),
                    })
                  }
                />
                <TextInputCustom
                  style={styles.textInput}
                  multiline
                  autoHeight
                  name="transportationBooking.returnNote"
                  control={control}
                  label={t("airline_ticket_return_trip_note")}
                  placeholder={t("airline_ticket_return_trip_note")}
                  maxLength={1000}
                />
              </View>
            )}
          </View>
        </>
      )}
    </View>
  );
});
export default BusTicketSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentContainer: { overflow: "hidden", marginTop: 10 },
  roundTripRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    justifyContent: "flex-end",
    marginTop: 6,
  },
  textInput: {
    marginVertical: 8,
  },
  travelLocationsSwapButton: {
    position: "absolute",
    right: 16,
    bottom: 48,
    zIndex: 1,
    padding: 6,
    backgroundColor: Colors.white,
    borderRadius: 18,
  },
});
