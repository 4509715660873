import {
  LayoutChangeEvent,
  Platform,
  StatusBarProps,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native";
import { FC, ReactNode } from "react";
import { SafeAreaView } from "react-native-safe-area-context";

import { Colors, Fonts } from "theme";
import { goBack } from "navigation/RootNavigation";
import { AppText } from "components/index";
import IconCustom from "components/IconCustom";
import { isIphoneX } from "constants/Layout";

interface BackHeaderProps extends TouchableOpacityProps {
  headerTitle?: string;
  headerRight?: ReactNode;
  backIcon?: ReactNode;
  statusBarProps?: StatusBarProps;
  containerStyle?: StyleProp<ViewStyle>;
  color?: string;
  onPress?: () => void;
  description?: string;
  headerComponent?: ReactNode;
  onLayout?: (event: LayoutChangeEvent) => void;
}

const BackHeader: FC<BackHeaderProps> = ({
  color = Colors.grayscale100,
  headerTitle,
  headerRight,
  containerStyle,
  backIcon,
  onPress,
  description,
  headerComponent,
  onLayout,
}) => {
  const handleGoBack = () => {
    if (onPress) {
      onPress();
      return;
    }
    goBack();
  };
  return (
    <View onLayout={onLayout}>
      <SafeAreaView
        edges={["top"]}
        style={[styles.container, Platform.OS === "web" && styles.containerWeb, containerStyle]}
      >
        <TouchableOpacity style={styles.headerBackTitle} onPress={handleGoBack}>
          {backIcon ? backIcon : <IconCustom name="west" width={24} height={24} fill={color} />}
        </TouchableOpacity>
        <View style={styles.headerContainer}>
          {headerComponent ? (
            headerComponent
          ) : (
            <AppText numberOfLines={1} style={[Fonts.H400, styles.title]} color={color}>
              {headerTitle}
            </AppText>
          )}
          {Boolean(description) && (
            <AppText style={[Fonts.BodySmall, { marginTop: 2 }]} color={Colors.grayscale80}>
              {description}
            </AppText>
          )}
        </View>
        <View>{headerRight}</View>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 12,
    paddingRight: 20,
    paddingTop: isIphoneX() ? 5 : 18,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: Colors.white,
  },
  containerWeb: { paddingTop: 12 },
  headerContainer: { flex: 1, marginLeft: 10 },
  headerBackTitle: {
    marginRight: 8,
    paddingVertical: 2,
    paddingLeft: 20,
  },
  title: {},
});

export default BackHeader;
