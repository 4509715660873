import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={25} viewBox="0 0 24 25" fill="none">
      <Path
        d="M8 2.846v3M16 2.846v3M21 14.476v-5.13c0-3-1.5-5-5-5H8c-3.5 0-5 2-5 5v8.5c0 3 1.5 5 5 5h6.68M7 11.846h6M7 16.846h2.62"
        stroke="#94A3B8"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.06 19.236a.481.481 0 00-.12 0A1.638 1.638 0 0118 15.96a1.638 1.638 0 01.06 3.275zM21.369 22.535a4.967 4.967 0 01-3.37 1.31c-1.3 0-2.48-.495-3.37-1.31.05-.47.35-.93.885-1.29 1.37-.91 3.61-.91 4.97 0 .535.36.835.82.885 1.29z"
        stroke="#94A3B8"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18 23.846a5 5 0 100-10 5 5 0 000 10z"
        stroke="#94A3B8"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
