import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M11.386 19.5a.856.856 0 01-.631-.254.856.856 0 01-.254-.63v-5.79l-5.598-7.11c-.192-.257-.22-.524-.084-.8.137-.278.367-.416.692-.416h12.98c.325 0 .555.138.692.415.136.277.108.544-.084.8l-5.598 7.112v5.788a.857.857 0 01-.254.631.857.857 0 01-.63.254h-1.231zM12 12.3l4.95-6.3h-9.9l4.95 6.3z"
          fill="#1C1B1F"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
