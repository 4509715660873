import { TouchableOpacity, TouchableOpacityProps, View, ViewProps } from "react-native";

import { RadioIcon, CheckedRadioIcon } from "assets/images/svg/icons";

interface RadioButtonProps extends TouchableOpacityProps {
  checked: boolean;
}

const RadioButton = ({ checked, ...props }: RadioButtonProps) => {
  if (props?.onPress) {
    return <TouchableOpacity {...props}>{checked ? <CheckedRadioIcon /> : <RadioIcon />}</TouchableOpacity>;
  }
  return <View {...(props as ViewProps)}>{checked ? <CheckedRadioIcon /> : <RadioIcon />}</View>;
};

RadioButton.defaultProps = {
  checked: false,
};

export default RadioButton;
