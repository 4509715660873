import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";
import Animated, {
  cancelAnimation,
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { useEffect } from "react";

function SvgComponent() {
  const rotation = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${rotation.value}deg`,
        },
      ],
    };
  }, [rotation.value]);

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 800,
        easing: Easing.linear,
      }),
      -1
    );
    return () => cancelAnimation(rotation);
  }, []);
  return (
    <Animated.View style={animatedStyle}>
      <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
        <Path
          d="M15.204 8c.44 0 .8.357.756.795a8 8 0 11-4.04-7.769c.383.216.469.716.216 1.076-.252.36-.746.442-1.135.236a6.408 6.408 0 103.358 6.456c.054-.436.405-.794.845-.794z"
          fill="url(#paint0_linear_10181_151956)"
        />
        <Defs>
          <LinearGradient
            id="paint0_linear_10181_151956"
            x1={12.5}
            y1={6.5}
            x2={18.5}
            y2={8}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#475569" />
            <Stop offset={1} stopColor="#475569" stopOpacity={0} />
          </LinearGradient>
        </Defs>
      </Svg>
    </Animated.View>
  );
}

export default SvgComponent;
