import groupBy from "lodash/groupBy";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import Animated, { CurvedTransition } from "react-native-reanimated";

import { BizziBootInfoSuccess, EmptyResultFound } from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  EmptyData,
  IconCustom,
  TextView,
  ToastManager,
} from "components";
import { CONSTANTS } from "constants/constants";
import LanguageStatus from "constants/LanguageStatus";
import { useOrgMyTeamsQuery } from "hooks/useOrgMyTeamsQuery";
import { Colors, Fonts, FontTypes } from "theme";
import { SingleTeamFragment } from "types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import { useAuth } from "contexts/AuthContext";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { getTitleFromCompanyTeam } from "utils";

interface CompanyTeam extends SingleTeamFragment {
  isDefault?: boolean;
}
interface CompanyTeamValue {
  companyTeamId: string;
  code: string;
  name?: string;
  nameEn?: string;
}
export interface CompanyTeamSelectProps {
  style?: ViewStyle;
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  value?: string;
  onClear?: () => void;
  setValue?: (name: string, companyTeam: CompanyTeamValue, config?: Record<string, any>) => void;
  autoFillCompanyDefault?: boolean;
  onSelect?: (companyTeamId: string) => void;
  disabled?: boolean;
}

const CompanyTeamSelect: React.FC<CompanyTeamSelectProps> = ({
  style,
  name,
  rules,
  control,
  error,
  onClear,
  setValue,
  autoFillCompanyDefault = false,
  onSelect,
  disabled = false,
}) => {
  const {
    user: { employee_id },
    company: { company_id },
  } = useAuth();
  const alertNotification = useRef<AlertNotificationHandle>();
  const { t, i18n } = useTranslation("app/components/InputCustom/CompanyTeamSelect");

  const teamBottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

  const { data: teamOptions } = useOrgMyTeamsQuery();

  /*-- flow get recent and default company team --*/
  const loadCompanyTeamsFromStorage = async () => {
    const companyTeamIds = teamOptions?.orgMyTeams?.map((i) => i?.teamId);
    const newCompanyTeams = [...teamOptions.orgMyTeams] as CompanyTeam[];
    const companyTeamDefaultStorage = await AsyncStorage.getItem(`${Keys.COMPANY_TEAM_DEFAULT}${employee_id}`);
    if (companyTeamDefaultStorage) {
      const foundTeamIndex = teamOptions?.orgMyTeams?.findIndex(
        (i) => i?.teamId === JSON.parse(companyTeamDefaultStorage)?.teamId
      );
      if (foundTeamIndex >= 0) {
        newCompanyTeams[foundTeamIndex].isDefault = true;
        if (autoFillCompanyDefault) {
          const companyTeam = {
            name: newCompanyTeams[foundTeamIndex]?.name,
            nameEn: newCompanyTeams[foundTeamIndex]?.nameEn,
            companyTeamId: newCompanyTeams[foundTeamIndex]?.teamId,
            code: newCompanyTeams[foundTeamIndex]?.code,
          };
          setValue(name, companyTeam, { shouldDirty: false });
        }
      }
    }
    const teams = groupBy(newCompanyTeams, ({ teamType }) => teamType?.teamTypeId);
    const companyTeamsByGroup = Object.keys(teams).map((key) => {
      const newTeams = teams[key];
      const index = newTeams.findIndex((i) => i?.isDefault);
      if (index >= 0) {
        // move item default to first
        const tmp = { ...newTeams[index] };
        newTeams.splice(index, 1);
        newTeams.unshift(tmp);
      }
      return {
        teamType: teams[key][0].teamType,
        teams: newTeams,
      };
    });
    /*--- get recent teams is selected from storage ---*/
    const recentTeamsStorage = await AsyncStorage.getItem(`${Keys.RECENT_COMPANY_TEAM}${company_id}${employee_id}`);
    /*-- remove company not exist current list company teams --*/
    const recentTeams = JSON.parse(recentTeamsStorage)?.filter((i) => companyTeamIds.includes(i?.companyTeamId));
    /*-- in the case company team of user < 3 --> not showing recent company team --*/
    if (recentTeams?.length > 0 && teamOptions?.orgMyTeams?.length >= 3) {
      const teamGroupRecent = {
        teamType: { teamTypeId: Keys.RECENT_COMPANY_TEAM },
        teams: recentTeams,
      };
      setCompanyTeamsGroupByType([...[teamGroupRecent], ...companyTeamsByGroup]);
      return;
    }
    setCompanyTeamsGroupByType(companyTeamsByGroup);
  };
  useEffect(() => {
    if (teamOptions?.orgMyTeams?.length > 0) {
      loadCompanyTeamsFromStorage();
    }
  }, [teamOptions]);

  const onPressClose = () => {
    teamBottomSheetRef.current?.close();
  };

  const onPressItem = (item) => () => {
    onPressClose();
    setValue(name, {
      name: item?.name,
      nameEn: item?.nameEn,
      companyTeamId: item?.teamId,
      code: item?.code,
    });
    onSelect?.(item?.teamId);
  };
  const [companyTeamsGroupByType, setCompanyTeamsGroupByType] = useState([]);
  const handleChangeCompanyDefault = (item, index, teamIndex) => async () => {
    const isSetCompanyDefault = await AsyncStorage.getItem(Keys.FLAG_IS_SET_COMPANY_TEAM_DEFAULT);
    AsyncStorage.setItem(Keys.FLAG_IS_SET_COMPANY_TEAM_DEFAULT, "true");
    if (!isSetCompanyDefault) {
      teamBottomSheetRef?.current?.close();
      // only fill company team default for create screen --> not fill for update screen
      if (autoFillCompanyDefault) {
        const companyTeam = { name: item?.name, nameEn: item?.nameEn, companyTeamId: item?.teamId, code: item?.code };
        setValue(name, companyTeam, { shouldDirty: false });
      }
      alertNotification?.current?.info({
        icon: <BizziBootInfoSuccess />,
        title: t("title_set_default_success"),
        description: (
          <AppText style={[Fonts.SentenceBodyMedium, styles.textCenter]} color={Colors.grayscale80}>
            {`${t("unit")}`}
            <AppText style={Fonts.NumericN200} color={Colors.grayscale80}>
              {` ${getTitleFromCompanyTeam({ code: item?.code, name: item?.name, nameEn: item?.nameEn })} `}
            </AppText>
            {t("description_set_default_success")}
          </AppText>
        ),
        confirmText: t("got_it"),
        onConfirm: () => alertNotification?.current?.close(),
      });
    }
    const newCompanyTeamsGroupByType = [...companyTeamsGroupByType];
    newCompanyTeamsGroupByType[index].teams.splice(teamIndex, 1); // remove to move item to first
    /*-- select company team default ---*/
    if (!item?.isDefault) {
      /*-- remove current team default if exist --*/
      const currentTeamDefaultIndex = newCompanyTeamsGroupByType?.findIndex((i) => i?.teams?.some((i) => i?.isDefault));
      if (currentTeamDefaultIndex >= 0) {
        newCompanyTeamsGroupByType[currentTeamDefaultIndex].teams = newCompanyTeamsGroupByType[
          currentTeamDefaultIndex
        ].teams.map((item) => ({
          ...item,
          isDefault: false,
        }));
      }
      /*-- end --*/
      newCompanyTeamsGroupByType[index].teams.unshift({ ...item, isDefault: true });
      AsyncStorage.setItem(`${Keys.COMPANY_TEAM_DEFAULT}${employee_id}`, JSON.stringify(item));
      ToastManager.success(t("title_set_default_success"));
    } else {
      /*-- remove company default --*/
      AsyncStorage.removeItem(`${Keys.COMPANY_TEAM_DEFAULT}${employee_id}`);
      // in the case: remove default company -> re-order company team list
      const teams = groupBy(teamOptions.orgMyTeams, ({ teamType }) => teamType?.teamTypeId);
      const companyTeamsByGroup = Object.keys(teams).map((key) => {
        const newTeams = teams[key];
        return {
          teamType: teams[key][0].teamType,
          teams: newTeams,
        };
      });
      newCompanyTeamsGroupByType[index].teams = companyTeamsByGroup
        ?.find((i) => i?.teamType?.teamTypeId === newCompanyTeamsGroupByType[index]?.teamType?.teamTypeId)
        ?.teams?.map((i) => ({
          ...i,
          isDefault: false,
        }));
    }
    setCompanyTeamsGroupByType(newCompanyTeamsGroupByType);
  };

  const renderItem = ({
    item,
    currentCompanyTeam,
    index,
  }: {
    item: {
      teamType: CompanyTeam["teamType"];
      teams: CompanyTeam[];
    };
    currentCompanyTeam: CompanyTeamValue;
    index: number;
  }) => {
    return (
      <View key={item?.teamType?.teamTypeId}>
        <View style={styles.title}>
          <AppText style={Fonts.H300}>
            {item?.teamType?.teamTypeId === Keys.RECENT_COMPANY_TEAM
              ? t("recent_company_team")
              : i18n.language === LanguageStatus.EN && item?.teamType?.nameEn
              ? item?.teamType?.nameEn
              : item?.teamType?.name}
          </AppText>
        </View>
        {item?.teams?.map((team, teamIndex) => (
          <Animated.View key={team?.teamId} layout={CurvedTransition.duration(270)}>
            <View
              style={[
                styles.wrapperItem,
                item?.teamType?.teamTypeId !== Keys.RECENT_COMPANY_TEAM && {
                  backgroundColor: currentCompanyTeam?.companyTeamId === team?.teamId ? Colors.primary0 : Colors.while,
                },
              ]}
            >
              <TouchableOpacity style={styles.companyTeamItem} activeOpacity={0.9} onPress={onPressItem(team)}>
                <AppText style={Fonts.BodyLarge}>
                  {team.code} - {i18n.language === LanguageStatus.EN && team?.nameEn ? team.nameEn : team.name}
                </AppText>
              </TouchableOpacity>
              {item?.teamType?.teamTypeId !== Keys.RECENT_COMPANY_TEAM && (
                <TouchableOpacity
                  style={styles.starButton}
                  onPress={handleChangeCompanyDefault(team, index, teamIndex)}
                >
                  {team?.isDefault ? (
                    <IconCustom name="star" />
                  ) : (
                    <IconCustom name="star-outline" fill={Colors.grayscale60} />
                  )}
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.divider} />
          </Animated.View>
        ))}
      </View>
    );
  };

  const handleOpenModal = () => {
    teamBottomSheetRef.current?.present();
  };

  const getCompanyTeamTitle = (companyTeam) => {
    return `${companyTeam.code}-${
      i18n.language === LanguageStatus.EN && companyTeam?.nameEn ? companyTeam.nameEn : companyTeam.name
    }`;
  };
  return (
    <View>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { value } }) => {
          return (
            <>
              <View style={style}>
                {disabled ? (
                  <TextView
                    disabledView
                    label={t("unit")}
                    value={value ? getCompanyTeamTitle(value) : t("no_value_unit")}
                  />
                ) : (
                  <TouchableOpacity
                    onPress={() => {
                      handleOpenModal();
                    }}
                    style={styles.input}
                  >
                    {Boolean(value) && <AppText style={styles.label}>{t("unit")}</AppText>}
                    <AppText
                      style={styles.companyTeamValue}
                      color={
                        error
                          ? Colors.alert50
                          : value
                          ? CONSTANTS.COMMON.TEXT_INPUT_COLOR
                          : CONSTANTS.COMMON.PLACEHOLDER_COLOR
                      }
                      numberOfLines={1}
                    >
                      {value ? getCompanyTeamTitle(value) : t("unit")}
                    </AppText>
                    {value ? (
                      <TouchableOpacity onPress={onClear}>
                        <IconCustom name="cancel" />
                      </TouchableOpacity>
                    ) : (
                      <IconCustom name="expand-more" />
                    )}
                  </TouchableOpacity>
                )}
                {Boolean(error?.message) && (
                  <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                    {error?.message?.toString()}
                  </AppText>
                )}
              </View>
              <BottomSheetScrollViewModalCustom ref={teamBottomSheetRef} title={t("choose")}>
                {companyTeamsGroupByType?.length === 0 ? (
                  <EmptyData style={styles.empty} icon={<EmptyResultFound />} title={t("unit_empty_placeholder")} />
                ) : (
                  companyTeamsGroupByType.map((item, index) => renderItem({ item, index, currentCompanyTeam: value }))
                )}
              </BottomSheetScrollViewModalCustom>
            </>
          );
        }}
      />
      <AlertNotification ref={alertNotification} />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    marginTop: 20,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  wrapperItem: {
    paddingLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingRight: 10,
    flexDirection: "row",
  },
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 13,
    flexDirection: "row",
  },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  companyTeamValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
  companyTeamItem: { flex: 1, paddingVertical: 16 },
  starButton: {
    paddingLeft: 10,
    paddingVertical: 8,
    alignSelf: "center",
    paddingRight: 10,
  },
  textCenter: { textAlign: "center" },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  empty: { marginTop: 30, marginBottom: 20 },
});

export default CompanyTeamSelect;
