// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { gql, MutationHookOptions, useMutation } from "@apollo/client";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ExpUpdateExpenseMutation, ExpUpdateExpenseMutationVariables } from "types";

const UPDATE_EXPENSE = gql`
  mutation MobileExpUpdateExpense($expenseId: UUID!, $expense: ExpUpdateExpenseInput!) {
    expUpdateExpense(expenseId: $expenseId, input: $expense) {
      message
      expenseId
      status
      expenseReport {
        docSequence
        expenseReportId
        title
      }
    }
  }
`;

export const useExpUpdateExpenseMutation = (
  options?: MutationHookOptions<ExpUpdateExpenseMutation, ExpUpdateExpenseMutationVariables>
) => {
  return useMutation(UPDATE_EXPENSE, options);
};
