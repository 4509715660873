import { Switch, Platform } from "react-native";
import { Colors } from "theme";
import React, { FC } from "react";
interface SwitchButtonProps {
  value?: any;
  onValueChange?: any;
  size?: "normal" | "small";
}
const SwitchButton: FC<SwitchButtonProps> = ({ value, onValueChange, size = "normal" }) => {
  return (
    <Switch
      {...Platform.select({
        web: {
          activeThumbColor: "white",
        },
      })}
      style={size === "small" && { transform: [{ scaleX: 0.83 }, { scaleY: 0.83 }] }}
      trackColor={{ false: Colors.grayscale10, true: Colors.primary50 }}
      thumbColor={Colors.white}
      ios_backgroundColor={Colors.grayscale10}
      onValueChange={onValueChange}
      value={value}
    />
  );
};

export default SwitchButton;
