import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 25, height = 25, color = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 25 24" fill="none">
      <Path
        d="M20.54 6.82l-5.76-4.03c-1.57-1.1-3.98-1.04-5.49.13L4.28 6.83c-1 .78-1.79 2.38-1.79 3.64v6.9c0 2.55 2.07 4.63 4.62 4.63h10.78c2.55 0 4.62-2.07 4.62-4.62V10.6c0-1.35-.87-3.01-1.97-3.78zM13.25 18c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-3c0-.41.34-.75.75-.75s.75.34.75.75v3z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
