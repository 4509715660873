interface Feedback {
  stars: number[];
  title: string;
  options: string[];
}

interface Configuration {
  title: string;
  message: string;
  feedbacks: Feedback[];
}

interface Configurations {
  vi: Configuration;
  en: Configuration;
}

export enum FeedBackObjectType {
  ExpenseRequest = "ExpenseRequest",
  ExpenseReport = "ExpenseReport",
}

export enum FeedBackTriggerEvent {
  OnSubmitted = "OnSubmitted",
  OnClicked = "OnClicked",
}
interface Trigger {
  event: FeedBackTriggerEvent;
  object: FeedBackObjectType;
  times: number;
  period: number;
  unit: string;
  countdown: number;
  countdownKey: string;
}

export interface FeedbackConfig {
  id: string;
  trigger: Trigger;
  configurations: Configurations;
}
