export interface MyExpenseReportTabMethods {
  onRefresh: (sort?: REPORT_SORT_OPTIONS) => void;
}
export interface MyExpenseReportTabProps {
  routeParams: any;
}
export interface WaitingApprovalExpenseReportTabMethods {
  onRefresh: () => void;
}

export enum REPORT_SORT_OPTIONS {
  CREATED_AT_ASC = "created_date_asc",
  CREATED_AT_DESC = "created_date_desc",
  REQUESTED_AT_ASC = "submission_date_asc",
  REQUESTED_AT_DESC = "submission_date_desc",
  AMOUNT_ASC = "amount_asc",
  AMOUNT_DESC = "amount_desc",
}
