import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#0086FF", size = 18, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M9 1.125a7.876 7.876 0 10.001 15.751A7.876 7.876 0 009 1.125zm3.375 8.297c0 .077-.063.14-.14.14H9.562v2.672c0 .078-.064.141-.141.141h-.844a.141.141 0 01-.14-.14V9.562H5.765a.141.141 0 01-.141-.141v-.844c0-.077.063-.14.14-.14h2.673V5.765c0-.078.063-.141.14-.141h.844c.077 0 .14.063.14.14v2.673h2.672c.078 0 .141.063.141.14v.844z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
