import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { MobileValidateExpenseReportAmountMutation, MobileValidateExpenseReportAmountMutationVariables } from "types";

const VALIDATE_EXPENSE_REPORT_AMOUNT = gql`
  mutation MobileValidateExpenseReportAmount($input: ExpValidateExpenseReportAmountInput!) {
    validateExpenseReportAmount: expValidateExpenseReportAmount(input: $input) {
      expenseRequests {
        expenseCategoryId
        expenseRequestId
        isValid
        message
        overRequestAmount
        restrictionType
      }
      budgets {
        expenseCategoryId
        isValid
        message
        overBudgetAmount
        restrictionType
        expenseDate
      }
    }
  }
`;

export const useValidateExpenseReportAmountMutation = (
  options?: MutationHookOptions<
    MobileValidateExpenseReportAmountMutation,
    MobileValidateExpenseReportAmountMutationVariables
  >
) => {
  return useMutation<MobileValidateExpenseReportAmountMutation, MobileValidateExpenseReportAmountMutationVariables>(
    VALIDATE_EXPENSE_REPORT_AMOUNT,
    { ...options }
  );
};
