import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";
import { Platform } from "react-native";
import { detectUrlString, getContentTypeFromFilename } from "utils";
import { EXPENSE_ATTACHMENT_TYPE } from "constants/constants";

export const GET_UPLOAD_FILE_SIGNED_URL = gql`
  mutation MobileExpenseGetUploadFileURL($objects: [ExpenseUploadFileURLInput!]!) {
    urls: expenseGetUploadFileURL(objects: $objects) {
      data {
        uploadURL
        downloadURL
        filename
        contentType
      }
    }
  }
`;

const useUploadFileSignedURL = () => {
  const [getUploadFileSignedURL] = useMutation(GET_UPLOAD_FILE_SIGNED_URL);

  const uploadImage = useCallback(
    async ({
      objectId,
      companyId,
      images,
      type,
      subAttachmentType,
    }: {
      objectId: string;
      companyId: string;
      images: any;
      type?: EXPENSE_ATTACHMENT_TYPE;
      subAttachmentType: string;
    }): Promise<
      {
        file_name: string;
        file_url: string;
        file_type: string;
        file_metadata: {
          width: number;
          height: number;
        };
        file_size: number;
        type?: string;
      }[]
    > => {
      const newAttachments = images.filter(({ uri }) => !detectUrlString(uri));
      const {
        data: { urls },
      } = await getUploadFileSignedURL({
        variables: {
          objects: newAttachments.map((image) => {
            const filename = image.uri.split("/").pop();
            const contentType = getContentTypeFromFilename(Platform.OS === "web" ? image.name : filename);

            return {
              objectId,
              filename: image.name,
              contentType: image.fileType || contentType || "unknown",
              companyId,
              subAttachmentType,
            };
          }),
        },
      });

      const attachments = await Promise.all(
        urls.data.map(async (record, index) => {
          const { uploadURL, downloadURL, filename, contentType } = record;
          const image = newAttachments[index];
          if (!image) {
            return false;
          }

          const response = await fetch(image.uri);
          const blob = await response.blob();

          await fetch(uploadURL, {
            method: "PUT",
            headers: {
              "Content-Type": contentType,
            },
            body: blob,
          });
          return {
            file_name: filename,
            file_url: downloadURL,
            file_type: contentType,
            file_metadata: {
              width: image.width,
              height: image.height,
            },
            file_size: blob.size,
            type,
          };
        })
      );

      return attachments;
    },
    [getUploadFileSignedURL]
  );

  return {
    getUploadFileSignedURL,
    uploadImage,
  };
};

export default useUploadFileSignedURL;
