import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={20} height={20}>
        <Path d="M0 0h20v20H0V0z" fill="#D9D9D9" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M9.374 10.626h4.583v-1.25H6.04v1.25h3.334zM10 17.917a7.711 7.711 0 01-3.088-.623 7.997 7.997 0 01-2.514-1.692 7.994 7.994 0 01-1.692-2.513 7.704 7.704 0 01-.624-3.087c0-1.095.208-2.124.623-3.088A7.997 7.997 0 014.397 4.4 7.993 7.993 0 016.91 2.708a7.704 7.704 0 013.087-.624c1.095 0 2.124.208 3.088.623.963.416 1.801.98 2.514 1.692a7.992 7.992 0 011.693 2.513c.415.963.623 1.992.623 3.087a7.713 7.713 0 01-.623 3.088A7.997 7.997 0 0115.6 15.6a7.995 7.995 0 01-2.513 1.693 7.705 7.705 0 01-3.087.623z"
          fill="#E11934"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
