import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileExpConvoUrlQuery, MobileExpConvoUrlQueryVariables } from "types/generated";

const QUERY = gql`
  query MobileExpConvoUrl($input: ExpConvoUrlInput!) {
    expConvoUrl(input: $input) {
      url
    }
  }
`;

export const useExpConvoChatUrl = (variables: MobileExpConvoUrlQueryVariables) => {
  return useQuery<MobileExpConvoUrlQuery, MobileExpConvoUrlQueryVariables>(QUERY, {
    variables,
  });
};
