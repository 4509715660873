import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = () => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
    <Path
      d="M18.3337 9.99935C18.3337 14.5993 14.6003 18.3327 10.0003 18.3327C5.40033 18.3327 1.66699 14.5993 1.66699 9.99935C1.66699 5.39935 5.40033 1.66602 10.0003 1.66602C14.6003 1.66602 18.3337 5.39935 18.3337 9.99935Z"
      fill="#DCEBFE"
      stroke="#1F71F4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="M13.0914 12.6495L10.5081 11.1078C10.0581 10.8411 9.69141 10.1995 9.69141 9.67448V6.25781" fill="#DCEBFE" />
    <Path
      d="M13.0914 12.6495L10.5081 11.1078C10.0581 10.8411 9.69141 10.1995 9.69141 9.67448V6.25781"
      stroke="#1F71F4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SVGComponent;
