import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M21.5 6.308v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283zM4 8.404h16V6.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H4.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.096zm0 3.192v6.096c0 .077.032.148.096.212a.294.294 0 00.212.096h15.384a.294.294 0 00.212-.096.294.294 0 00.096-.212v-6.096H4z"
          fill="#94A3B8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
