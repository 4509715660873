import React, { FC, memo, ReactNode } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { BottomSheetHandle, BottomSheetHandleProps } from "@gorhom/bottom-sheet";

import { AppText, Line } from "components";
import { CloseIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";

interface HeaderBottomSheetProps extends BottomSheetHandleProps {
  title?: string;
  description?: string;
  onClose?: () => void;
  children?: ReactNode;
  renderDescription?: () => ReactNode;
}

const HeaderBottomSheetModal: FC<HeaderBottomSheetProps> = ({
  title,
  description,
  onClose,
  children,
  renderDescription,
  ...rest
}) => {
  return (
    <>
      <BottomSheetHandle {...rest} style={styles.paddingBottom}>
        {title ? (
          <>
            <View style={styles.header}>
              <AppText style={[Fonts.H400, styles.title]}>{title}</AppText>
              {!!description && (
                <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
                  {description}
                </AppText>
              )}
              {renderDescription?.()}
              <TouchableOpacity style={styles.buttonClose} onPress={onClose}>
                <CloseIcon />
              </TouchableOpacity>
            </View>
            <View style={styles.divider}>
              <Line color={Colors.grayscale05} />
            </View>
          </>
        ) : (
          <View style={styles.header}>
            <TouchableOpacity style={styles.buttonClose} onPress={onClose}>
              <CloseIcon />
            </TouchableOpacity>
          </View>
        )}
      </BottomSheetHandle>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  title: {
    paddingRight: 24,
  },
  buttonClose: { position: "absolute", right: 10, top: 5, padding: 0 },
  divider: {
    paddingTop: 8,
    marginLeft: -CONSTANTS.COMMON.CONTAINER_PADDING,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  paddingBottom: {
    paddingBottom: 0,
  },
});

export default memo(HeaderBottomSheetModal);
