import React, { FC, useRef } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { AppText, BottomSheetModalCustomMethods, BottomSheetScrollViewModalCustom, Button } from "components";
import { Colors, Fonts } from "theme";
import { InfoCircleSolidIcon } from "assets/images/svg/icons";
import { getTitleFromCompanyTeam } from "utils";
import { MobileExpenseDetailsByPkQuery } from "types";
import TextView from "components/TextView";
import { useTranslation } from "react-i18next";
import Line from "components/Line";

interface AllocatedStatusInfoProps {
  parent?: MobileExpenseDetailsByPkQuery["expExpenseDetails"]["parent"];
}

const AllocatedStatusInfo: FC<AllocatedStatusInfoProps> = ({ parent }) => {
  const { t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
  if (!parent) {
    return null;
  }
  return (
    <>
      <TextView
        numberOfLines={1}
        value={parent?.note ? `${parent?.docSequence} - ${parent?.note}` : parent?.docSequence}
        label={t("allocated_from")}
        icon={
          <TouchableOpacity onPress={() => bottomSheetRef.current?.present()}>
            <InfoCircleSolidIcon />
          </TouchableOpacity>
        }
      />
      <BottomSheetScrollViewModalCustom
        title={t("allocated_from")}
        ref={bottomSheetRef}
        containerStyle={styles.modalStyle}
        renderFooter={() => (
          <Button style={styles.footer} type="secondary" onPress={() => bottomSheetRef.current?.close()}>
            {t("close")}
          </Button>
        )}
      >
        <View style={styles.rowItem}>
          <AppText style={styles.title} color={Colors.grayscale60}>
            {t("expense_code")}
          </AppText>
          <AppText style={Fonts.BodyLarge}>{parent?.docSequence}</AppText>
        </View>
        <Line />
        <View style={styles.rowItem}>
          <AppText style={styles.title} color={Colors.grayscale60}>
            {t("describe")}
          </AppText>
          <AppText style={Fonts.BodyLarge}>
            {parent?.note || t("no_value", { label: t("describe").toLowerCase() })}
          </AppText>
        </View>
        <Line />
        <View style={styles.rowItem}>
          <AppText style={styles.title} color={Colors.grayscale60}>
            {t("unit")}
          </AppText>
          <AppText style={Fonts.BodyLarge}>
            {parent?.companyTeam
              ? getTitleFromCompanyTeam({
                  code: parent?.companyTeam?.code,
                  name: parent?.companyTeam?.name,
                  nameEn: parent?.companyTeam?.nameEn,
                })
              : t("no_value", { label: t("unit").toLowerCase() })}
          </AppText>
        </View>
        <Line />
        <View style={styles.rowItem}>
          <AppText style={styles.title} color={Colors.grayscale60}>
            {t("employee")}
          </AppText>
          <AppText style={Fonts.BodyLarge}>{parent?.companyEmployee?.fullname}</AppText>
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default AllocatedStatusInfo;

const styles = StyleSheet.create({
  title: { ...Fonts.Caption, marginBottom: 8 },
  rowItem: { paddingVertical: 8 },
  footer: { marginTop: 8 },
  modalStyle: { paddingHorizontal: 20, paddingBottom: 90 },
});
