import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M3.5 20.881V3.119c0-.103.044-.173.13-.208a.228.228 0 01.247.043l.715.629a.44.44 0 00.585 0l.838-.704a.44.44 0 01.585 0l.838.704a.44.44 0 00.585 0l.839-.704a.44.44 0 01.584 0l.839.704a.44.44 0 00.584 0l.839-.704a.44.44 0 01.584 0l.839.704a.439.439 0 00.584 0l.839-.704a.44.44 0 01.584 0l.839.704a.44.44 0 00.584 0l.839-.704a.439.439 0 01.585 0l.838.704a.44.44 0 00.585 0l.715-.629a.228.228 0 01.246-.043c.087.035.131.105.131.208v17.762c0 .104-.044.173-.13.209a.228.228 0 01-.247-.044l-.715-.628a.44.44 0 00-.585 0l-.838.703a.44.44 0 01-.585 0l-.838-.703a.44.44 0 00-.585 0l-.839.703a.44.44 0 01-.584 0l-.839-.703a.44.44 0 00-.584 0l-.839.703a.44.44 0 01-.584 0l-.839-.703a.44.44 0 00-.584 0l-.839.703a.44.44 0 01-.584 0l-.839-.703a.44.44 0 00-.585 0l-.838.703a.44.44 0 01-.585 0l-.838-.703a.44.44 0 00-.585 0l-.715.628a.227.227 0 01-.246.044c-.087-.036-.131-.105-.131-.209zM7 16.442h10a.726.726 0 00.535-.215.726.726 0 00.215-.534.726.726 0 00-.215-.535.726.726 0 00-.535-.215H7a.726.726 0 00-.535.215.726.726 0 00-.215.535c0 .212.072.39.215.534a.726.726 0 00.535.215zm0-3.692h10a.726.726 0 00.535-.215.726.726 0 00.215-.535.726.726 0 00-.215-.534.726.726 0 00-.535-.216H7a.726.726 0 00-.535.216.726.726 0 00-.215.534c0 .213.072.391.215.535A.726.726 0 007 12.75zm0-3.692h10a.726.726 0 00.535-.215.726.726 0 00.215-.535.726.726 0 00-.215-.535.726.726 0 00-.535-.215H7a.726.726 0 00-.535.215.726.726 0 00-.215.535c0 .213.072.391.215.535A.726.726 0 007 9.058zM5 19.1h14V4.9H5v14.2z"
          fill="#94A3B8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
