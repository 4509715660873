import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A", width = 24, height = 24 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={width} height={height}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.371 12.75l5.17 5.17a.704.704 0 01.22.521.743.743 0 01-.236.532.784.784 0 01-.527.225.7.7 0 01-.527-.225l-6.34-6.34A.829.829 0 014.875 12a.829.829 0 01.256-.633l6.34-6.34a.715.715 0 01.514-.212.748.748 0 01.54.212.735.735 0 01.233.535c0 .2-.078.38-.233.534L7.37 11.25h11.377c.213 0 .391.072.535.216a.726.726 0 01.215.534.726.726 0 01-.215.535.726.726 0 01-.535.215H7.371z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
