import { AppText, CurrencyText, IconCustom, useWindowDimensions } from "components/index";
import React, { FC, ReactNode, useState } from "react";
import { LayoutChangeEvent, StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import Animated, { Easing, FadeIn, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { startLayoutAnimation } from "utils";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface FloatFooterExpandProps {
  totalAmount: number;
  priceLabel: string;
  footerButton?: ReactNode;
  expandComponent: ReactNode;
  onLayout?: (e: LayoutChangeEvent) => void;
}
const AnimatedTouchableWithoutFeedback = Animated.createAnimatedComponent(TouchableOpacity);
const FloatFooterExpand: FC<FloatFooterExpandProps> = ({
  totalAmount,
  priceLabel,
  footerButton,
  expandComponent,
  onLayout,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const rotation = useSharedValue(180);
  const { bottom } = useSafeAreaInsets();

  const animatedIconStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${rotation.value}deg`,
        },
      ],
    };
  }, [rotation.value]);
  const handleExpand = () => {
    setIsExpand((prevState) => !prevState);
    if (isExpand) {
      rotation.value = withTiming(180, {
        duration: 400,
        easing: Easing.linear,
      });
      startLayoutAnimation();
    } else {
      startLayoutAnimation();
      rotation.value = withTiming(0, {
        duration: 400,
        easing: Easing.linear,
      });
    }
  };
  const { width, height } = useWindowDimensions();
  return (
    <>
      {isExpand && (
        <AnimatedTouchableWithoutFeedback
          activeOpacity={1}
          onPress={handleExpand}
          entering={FadeIn.duration(200)}
          style={{ width: width, height, backgroundColor: "rgba(0,0,0,0.6)", position: "absolute", top: 0 }}
        />
      )}
      <View style={styles.container} onLayout={onLayout}>
        <TouchableOpacity style={styles.footerDescription} onPress={handleExpand}>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale100}>
            {priceLabel}
          </AppText>
          <View style={styles.totalAmountContainer}>
            <CurrencyText style={Fonts.NumericN400} color={Colors.success50}>
              {totalAmount}
            </CurrencyText>
            <Animated.View style={animatedIconStyle}>
              <IconCustom name="expand-more" />
            </Animated.View>
          </View>
        </TouchableOpacity>
        {isExpand ? <View style={styles.expandContainer}>{expandComponent}</View> : null}
        <View style={[styles.footerButtonContainer, { paddingBottom: bottom || 10 }]}>{footerButton}</View>
      </View>
    </>
  );
};
export default FloatFooterExpand;
const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    backgroundColor: Colors.white,
    width: "100%",
    paddingTop: 8,
    paddingHorizontal: 20,
    shadowColor: Colors.grayscale100,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 4,
  },
  expandContainer: { marginBottom: 8 },
  footerButtonContainer: { backgroundColor: Colors.white },
  footerDescription: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  totalAmountContainer: { alignItems: "center", flexDirection: "row", gap: 4 },
});
