import { AppText, CurrencyText, LoadingView } from "components";
import { CONSTANTS } from "constants/constants";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import useBokBaggage from "../hooks/useBokBaggage";
import { FlightTicket, BaggageBooking } from "../types";

type LuggageSelectProps = {
  ticket: FlightTicket;
  value?: BaggageBooking;
  onChange?: (luggage: BaggageBooking) => void;
};
const baggageDefault = {
  code: null,
  value: `0`,
  price: 0,
};

const LuggageSelect: FC<LuggageSelectProps> = ({ ticket, value: initialValue, onChange }) => {
  const { t } = useTranslation("app/screens/FlightBooking/LuggageScreen/LuggageScreen");
  const [value, setValue] = useState(initialValue || baggageDefault);

  const { loading, data } = useBokBaggage({
    variables: {
      input: {
        fareId: ticket.fareId,
        flights: [{ flightValue: ticket.flights[0].flightValue }],
        session: ticket.session,
      },
    },
    onCompleted: (data) => {
      const match = data.bokBaggage?.baggage?.find((b) => b.code === value?.code);
      if (!match) {
        setValue(baggageDefault);
        setTimeout(() => {
          onChange?.(baggageDefault as BaggageBooking);
        });
      }
    },
  });

  const handleSelectLuggage = (luggage) => () => {
    const nextValue = luggage.code === value?.code ? null : luggage;
    setValue(nextValue);
    setTimeout(() => {
      onChange?.(nextValue);
    });
  };

  return (
    <View style={styles.extraLuggageSection}>
      <AppText style={Fonts.SentenceSubtitleLarge}>{t("extra_luggage")}</AppText>
      {loading ? (
        <LoadingView />
      ) : data?.bokBaggage?.baggage?.length ? (
        <View style={styles.luggageContainer}>
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={styles.scrollView}
          >
            {[...[baggageDefault], ...data.bokBaggage.baggage].map((luggage) => {
              const isSelected = luggage.code === value?.code;
              return (
                <TouchableOpacity
                  key={luggage.code}
                  style={[styles.luggage, isSelected && styles.selectedLuggage]}
                  onPress={handleSelectLuggage(luggage)}
                >
                  <AppText style={Fonts.H200} color={isSelected ? Colors.primary50 : Colors.grayscale100}>
                    {luggage.value}kg
                  </AppText>
                  <CurrencyText style={Fonts.BodyXSmall} color={isSelected ? Colors.primary50 : Colors.grayscale70}>
                    {luggage.price}
                  </CurrencyText>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      ) : (
        <AppText style={[Fonts.BodyMedium, styles.noExtraLuggageSupported]} color={Colors.grayscale60}>
          ({t("no_extra_luggage_supported")})
        </AppText>
      )}
    </View>
  );
};

export default LuggageSelect;
const styles = StyleSheet.create({
  extraLuggageSection: {
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    gap: 16,
  },
  luggageContainer: {
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  luggage: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 14,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: Colors.grayscale40,
    borderRadius: 4,
    minWidth: 76,
  },
  selectedLuggage: {
    borderColor: Colors.primary50,
    backgroundColor: Colors.primary0,
  },
  scrollView: {
    gap: 10,
    backgroundColor: Colors.white,
  },
  noExtraLuggageSupported: {
    textAlign: "center",
  },
});
