import React, { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { AppText, CurrencyText } from "components";
import { Colors, Fonts } from "theme";
import { formatCardNumber } from "utils";
import SCREEN_NAME from "navigation/ScreenName";
import { MobileExpenseDetailsByPkQuery } from "types";
import dayjs from "dayjs";
import { TransactionIcon } from "assets/images/svg/icons";
import { useTranslation } from "react-i18next";

interface TransactionSectionProps {
  cardTransaction: MobileExpenseDetailsByPkQuery["expExpenseDetails"]["expenseCardTransactions"][0]["cardTransaction"];
}
const TransactionSection: FC<TransactionSectionProps> = ({ cardTransaction }) => {
  const navigation = useNavigation<any>();
  const { t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");

  const handleNavigateTransactionDetail = () => {
    navigation.navigate(SCREEN_NAME.TransactionDetailScreen, {
      cardTransactionId: cardTransaction?.cardTransactionId,
      companyId: cardTransaction?.companyId,
    });
  };
  if (!cardTransaction) {
    return (
      <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale70}>
        {t("no_value", { label: (t("card_transaction") ?? "").toLocaleLowerCase() })}
      </AppText>
    );
  }
  return (
    <>
      <TouchableOpacity style={styles.transactionItem} onPress={handleNavigateTransactionDetail}>
        <TransactionIcon />
        <View style={styles.flex}>
          <View style={styles.location}>
            <AppText style={Fonts.BodyMedium}>
              {formatCardNumber(cardTransaction?.card?.prefixCardNumber, cardTransaction?.card?.suffixCardNumber)}
            </AppText>
            <CurrencyText style={Fonts.H200}>{Math.abs(cardTransaction?.amount)}</CurrencyText>
          </View>
          <View style={styles.transactionItemRow}>
            <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={2}>
              {cardTransaction?.location}
            </AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {dayjs(cardTransaction?.transactionDate).format("DD/MM/YYYY")}
            </AppText>
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};
export default TransactionSection;

const styles = StyleSheet.create({
  transactionItem: { flexDirection: "row", gap: 8, alignItems: "center" },
  transactionItemRow: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginVertical: 3,
    justifyContent: "space-between",
  },
  flex: { flex: 1 },
  location: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  textCenter: { textAlign: "center" },
});
