import { BokCabinClass, FlightTicket } from "screens/FlightBooking/types";

type FilterFlightTicket = {
  minAmount: number;
  maxAmount: number;
  numberOfStop?: number[];
  takeOffTimeGroups?: FlightTicketTime[];
  landingTimeGroups?: FlightTicketTime[];
  landingEndTime?: string[];
  vendors?: string[]; //vendor code
  cabinClass?: BokCabinClass[];
};
type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : K;
}[keyof T];
type FilterFlightTicketStringKeys = StringKeys<FilterFlightTicket>;

enum FlightTicketTime {
  Morning1 = "Morning1",
  Morning2 = "Morning2",
  Afternoon = "Afternoon",
  Night = "Night",
}
type FlightTicketFilter = FlightTicket & {
  takeOffTimeGroupType: string;
  landingTimeGroupType: string;
};

export type { FilterFlightTicket, FilterFlightTicketStringKeys, FlightTicketFilter };
export { FlightTicketTime };
