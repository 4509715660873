import { StyleSheet, View } from "react-native";
import { AppText, CurrencyText, IconCustom, Line } from "components";
import { Colors, Fonts } from "theme";
import { formatTime } from "utils";
import React, { FC } from "react";
import { CONSTANTS } from "constants/constants";
import { BokCabinClass, FlightTicketType } from "screens/FlightBooking/types";
import { useTranslation } from "react-i18next";
import { RootStackParamList } from "navigation/type";
import SCREEN_NAME from "navigation/ScreenName";

const FlightTicketCabinClass = {
  [BokCabinClass.Economy]: "cabin_class_economy",
  [BokCabinClass.DeluxeEconomy]: "cabin_class_deluxe_economy",
  [BokCabinClass.Business]: "cabin_class_business",
  [BokCabinClass.First]: "cabin_class_first",
  [BokCabinClass.Suites]: "cabin_class_suites",
};
interface SummarySectionProps {
  type?: FlightTicketType;
  data: RootStackParamList[typeof SCREEN_NAME.FlightSearchScreen];
}

const SummarySection: FC<SummarySectionProps> = ({ type, data }) => {
  const { t } = useTranslation("app/screens/FlightBooking/FlightSearchScreen");
  return (
    <View style={styles.summarySection}>
      {/* trip info */}
      <View style={styles.tripContainer}>
        {type === FlightTicketType.Departure ? <IconCustom name="flight-takeoff" /> : <IconCustom name="flight-land" />}
        <View style={[styles.allSpaceLeft, styles.gap4]}>
          <View style={[styles.flexRow, styles.gap8]}>
            <AppText style={Fonts.BodyMedium}>
              {type === FlightTicketType.Departure ? data.fromCode : data.toCode}
            </AppText>
            <IconCustom name="arrow-forward" fill={Colors.grayscale70} />
            <AppText style={Fonts.BodyMedium}>
              {type === FlightTicketType.Departure ? data.toCode : data.fromCode}
            </AppText>
          </View>
          <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
            {formatTime(type === FlightTicketType.Departure ? data.departureDate : data.returnDate, "ddd, DD/MM/YYYY")},{" "}
            {t("number_of_passenger", { value: 1 })}
          </AppText>
        </View>
      </View>
      <Line size="sm" />

      {/* policies */}
      <View>
        <View style={styles.policyItem}>
          <AppText style={Fonts.BodySmall}>{t("allowance_amount")}</AppText>
          <CurrencyText style={[Fonts.H100, styles.policyItemRight]}>
            {data.type === FlightTicketType.Departure ? data.toMaxAmount : data.fromMaxAmount}
          </CurrencyText>
        </View>
        {data.cabinClass && (
          <View style={styles.policyItem}>
            <AppText style={Fonts.BodySmall}>{t("max_seat_level")}</AppText>
            <AppText style={[Fonts.H100, styles.policyItemRight]}>
              {t(FlightTicketCabinClass[data.cabinClass] as any)}
            </AppText>
          </View>
        )}
      </View>
    </View>
  );
};
export default SummarySection;

const styles = StyleSheet.create({
  summarySection: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 8,
  },
  tripContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    paddingVertical: 4,
  },
  policyItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    gap: 16,
  },
  policyItemRight: {
    flex: 1,
    textAlign: "right",
  },
  flexRow: { flexDirection: "row", alignItems: "center" },
  allSpaceLeft: {
    flex: 1,
  },
  gap8: {
    gap: 8,
  },
  gap4: {
    gap: 4,
  },
});
