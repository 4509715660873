import Svg, { Path, SvgProps } from "react-native-svg";
import { Colors } from "theme";

function SvgComponent({ width = 24, height = 24, color = Colors.grayscale60, ...props }: SvgProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 16.8c-.7 0-1.4-.27-1.93-.8L3.55 9.48a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l6.52 6.52c.48.48 1.26.48 1.74 0l6.52-6.52c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L13.93 16c-.53.53-1.23.8-1.93.8z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
