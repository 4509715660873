import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M15.3 8.902l-1.114-1.104a.41.41 0 00-.306-.128.448.448 0 00-.317.143.432.432 0 00-.14.312c0 .114.047.218.14.312l1.104 1.094c.181.18.392.27.633.27s.452-.09.633-.27l2.503-2.52a.438.438 0 00.136-.306.42.42 0 00-.136-.317.432.432 0 00-.311-.14.432.432 0 00-.311.14L15.3 8.902zM4.25 19.75a.726.726 0 01-.534-.216A.726.726 0 013.5 19c0-.213.072-.391.216-.535a.726.726 0 01.534-.215h10c.213 0 .39.072.534.216A.726.726 0 0115 19c0 .213-.072.391-.216.535a.726.726 0 01-.534.215h-10zm11.751-7c-1.313 0-2.434-.463-3.36-1.389-.928-.926-1.391-2.046-1.391-3.36 0-1.313.463-2.434 1.389-3.36.926-.928 2.046-1.391 3.36-1.391 1.313 0 2.434.463 3.36 1.389.927.926 1.391 2.046 1.391 3.36 0 1.313-.463 2.434-1.389 3.36-.926.928-2.046 1.391-3.36 1.391zm-11.751-1a.726.726 0 01-.534-.216A.726.726 0 013.5 11c0-.213.072-.391.216-.535a.726.726 0 01.534-.215h4.617c.213 0 .39.072.535.214a.72.72 0 01.215.532.743.743 0 01-.215.526.706.706 0 01-.535.228H4.25zm0 4a.726.726 0 01-.534-.216A.726.726 0 013.5 15c0-.213.072-.391.216-.535a.726.726 0 01.534-.215h8.252c.212 0 .39.072.534.214a.72.72 0 01.216.532.768.768 0 01-.203.526.696.696 0 01-.547.228H4.25z"
          fill="#0F172A"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
