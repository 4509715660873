import { FC, memo } from "react";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";

import { AppText } from "components";
import { EXPENSE_REPORT_STATUS } from "constants/constants";

const STATUS = {
  [EXPENSE_REPORT_STATUS.DRAFT]: {
    text: "draft",
    color: Colors.grayscale05,
  },
  [EXPENSE_REPORT_STATUS.REJECTED]: {
    text: "rejected",
    color: Colors.alert05,
  },
  [EXPENSE_REPORT_STATUS.SUBMITTED]: {
    text: "waiting",
    color: Colors.primary05,
  },
  [EXPENSE_REPORT_STATUS.AWAITING_ACCOUNTANT_APPROVAL]: {
    text: "waiting",
    color: Colors.warning0,
  },
  [EXPENSE_REPORT_STATUS.APPROVED]: {
    text: "approval",
    color: Colors.success0,
  },
  [EXPENSE_REPORT_STATUS.CANCEL]: {
    text: "cancel",
    color: Colors.grayscale05,
  },
  [1000]: {
    text: "invalid_invoice",
    color: Colors.alert05,
  },
};

interface StatusExpenseProps {
  value: number;
}

const StatusExpenseReport: FC<StatusExpenseProps> = ({ value }) => {
  const { t } = useTranslation("app/components/ExpenseReportItem");
  if (!STATUS[value]?.color) {
    return null;
  }
  return (
    <View style={[styles.container, { backgroundColor: STATUS[value].color }]}>
      <AppText style={Fonts.BodySmall}>{t(STATUS[value].text)}</AppText>
    </View>
  );
};

export default memo(StatusExpenseReport);
const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
  },
});
