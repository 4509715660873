import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={49} height={48} viewBox="0 0 49 48" fill="none">
      <Rect x={0.5} width={48.095} height={47.3462} rx={14.2039} fill="#37B24D" />
      <Path
        d="M36.349 31.165c0 .426-.095.864-.296 1.29a4.881 4.881 0 01-.805 1.208c-.58.639-1.22 1.1-1.941 1.396-.71.296-1.48.45-2.309.45-1.207 0-2.497-.284-3.858-.864-1.361-.58-2.723-1.361-4.072-2.344a34.02 34.02 0 01-3.883-3.314 33.65 33.65 0 01-3.302-3.87c-.97-1.35-1.752-2.7-2.32-4.037-.568-1.35-.852-2.64-.852-3.87 0-.805.142-1.575.426-2.285a5.445 5.445 0 011.361-1.976c.758-.746 1.586-1.113 2.462-1.113.332 0 .663.071.959.213.308.142.58.355.793.663l2.746 3.87c.213.296.367.569.474.829.106.248.165.497.165.722 0 .284-.082.568-.248.84a4.028 4.028 0 01-.663.84l-.9.936a.633.633 0 00-.19.473c0 .095.013.178.037.273.035.094.07.165.094.236.213.39.58.9 1.1 1.515a40.55 40.55 0 001.717 1.87c.64.628 1.255 1.208 1.882 1.74.616.521 1.125.876 1.527 1.09.06.023.13.059.213.094.095.036.19.047.296.047a.651.651 0 00.485-.2l.9-.888c.296-.296.58-.521.852-.663.272-.166.545-.249.84-.249.226 0 .462.047.723.154.26.107.532.26.828.462l3.918 2.781c.308.213.521.462.651.758.119.296.19.592.19.923z"
        stroke="#fff"
        strokeWidth={1.77548}
        strokeMiterlimit={10}
      />
      <Path
        d="M32.24 20.121c0-.71-.556-1.799-1.384-2.687-.758-.816-1.764-1.456-2.758-1.456M36.383 20.122a8.28 8.28 0 00-8.285-8.286"
        stroke="#fff"
        strokeWidth={1.77548}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
