const trackingTypes = (name) => {
  return {
    TAP: `Tapped ${name}`,
    TAP_CREATE: `Tapped Create ${name}`,
    TAP_UPDATE: `Tapped Update ${name}`,
    TAP_VIEW_DETAIL: `Tapped View ${name} Detail`,
    TAP_VIEW_LIST: `Tapped View ${name} List`,
    TAP_VIEW: `Tapped View ${name}`,
    TAP_DELETE: `Tapped Delete ${name}`,
  };
};
export const EVENT = {
  EXPENSE: {
    TAP_CREATE: "Tapped Create Expense",
    TAP_UPDATE: "Tapped Update Expense",
    TAP_VIEW_DETAIL: "Tapped View Expense Detail",
    TAP_OPEN_SORT_MODAL: trackingTypes("Open Sort Expense Modal").TAP,

    CREATED: "Created Expense",
    CREATED_SUCCESS: "Created Expense Successfully",
    CREATED_FAILED: "Created Expense Unsuccessfully",

    UPDATED: "Updated Expense",
    UPDATED_SUCCESS: "Updated Expense Successfully",
    UPDATED_FAILED: "Updated Expense Unsuccessfully",
    SCAN_QR_CODE: "Scanned QR Code",
    SCAN_QR_CODE_SUCCESS: "Scan QR Code Successfully",
    SCAN_QR_CODE_FAILED: "Scan QR Code Unsuccessfully",
  },
  REPORT: {
    TAP_VIEW_DETAIL: `Tapped View Report Detail`,
    TAP_UPDATE: "Tapped Update Report",
    TAP_TOTAL_AMOUNT: "Tapped Total Amount Report",
    TAP_CREATE: "Tapped Create Report",

    SAVED_DRAFT: "Saved Draft Report",
    SAVED_DRAFT_SUCCESS: "Saved Draft Report Successfully",
    SAVED_DRAFT_FAILED: "Saved Draft Report Unsuccessfully",

    CREATED: "Created Report",
    CREATED_SUCCESS: "Created Report Successfully",
    CREATED_FAILED: "Created Report Unsuccessfully",

    UPDATED: "Updated Report",
    UPDATED_SUCCESS: "Updated Report Successfully",
    UPDATED_FAILED: "Updated Report Unsuccessfully",

    SUBMITTED: "Submitted Report",
    SUBMITTED_SUCCESS: "Submitted Report Successfully",
    SUBMITTED_FAILED: "Submitted Report Unsuccessfully",

    CANCELED: "Cancelled Report",
    CANCELED_SUCCESS: "Cancelled Report Successfully",
    CANCELED_FAILED: "Cancelled Report Unsuccessfully",

    APPROVED: "Approved Report",
    APPROVED_SUCCESS: "Approved Report Successfully",
    APPROVED_FAILED: "Approved Report Unsuccessfully",

    REJECTED: "Rejected Report",
    REJECTED_SUCCESS: "Rejected Report Successfully",
    REJECTED_FAILED: "Rejected Report Unsuccessfully",

    TAP_OPEN_SORT_MODAL: trackingTypes("Open Sort Report Modal").TAP,
    SORTED: "Sorted Report List",
  },
  BATCH_APPROVAL: {
    TAPPED: "Tapped Batch Approval",
    SUBMITTED: "Submitted Batch Approval",
    SUBMIT_SUCCESS: "Submitted Batch Approval Successfully",
    SUBMIT_SUCCESS_PARTIAL: "Submitted Batch Approval Partial Successfully",
    SUBMIT_FAILED: "Submitted Batch Approval Unsuccessfully",
  },
  REQUEST: {
    TAP_CREATE: "Tapped Create Request",
    TAP_UPDATE: "Tapped Update Request",
    TAP_VIEW_DETAIL: "Tapped View Request Detail",
    TAP_TOTAL_AMOUNT: "Tapped Total Amount Request",
    TAP_CASH_ADVANCE_AMOUNT: "Tapped Cash Advance Amount Request",
    SHARE_TRAVEL_INFO: "Share Travel Request Info",

    SAVED_DRAFT: "Saved Draft Request",
    SAVED_DRAFT_SUCCESS: "Saved Draft Request Successfully",
    SAVED_DRAFT_FAILED: "Saved Draft Request Unsuccessfully",

    CREATED: "Created Request",
    CREATED_SUCCESS: "Created Request Successfully",
    CREATED_FAILED: "Created Request Unsuccessfully",

    UPDATED: "Updated Request",
    UPDATED_SUCCESS: "Updated Request Successfully",
    UPDATED_FAILED: "Updated Request Unsuccessfully",

    SUBMITTED: "Submitted Request",
    SUBMITTED_SUCCESS: "Submitted Request Successfully",
    SUBMITTED_FAILED: "Submitted Request Unsuccessfully",

    CANCELED: "Cancelled Request",
    CANCELED_SUCCESS: "Canceled Request Successfully",
    CANCELED_FAILED: "Canceled Request Unsuccessfully",

    REJECTED: "Rejected Request",
    REJECTED_SUCCESS: "Rejected Request Successfully",
    REJECTED_FAILED: "Rejected Request Unsuccessfully",

    APPROVED: "Approved Request",
    APPROVED_SUCCESS: "Approved Request Successfully",
    APPROVED_FAILED: "Approved Request Unsuccessfully",
    TAP_OPEN_SORT_MODAL: trackingTypes("Open Sort Request Modal").TAP,
    SORTED: "Sorted Request List",
  },
  PROFILE: {
    ...trackingTypes("Profile"),
    DELETED_ACCOUNT_SUCCESS: "Deleted Account Successfully",
    DELETE_ACCOUNT_FAILED: "Deleted Account Unsuccessfully",
  },
  EMAIL: {
    UPDATED: "Updated Email",
    UPDATED_SUCCESS: "Updated Email Successfully",
    UPDATED_FAILED: "Updated Email Unsuccessfully",
    TAP_VIEW_LIST: "Tapped View Email List",
  },
  ACCOUNT: trackingTypes("Account"),
  AUTH: {
    TAP_LOGIN: trackingTypes("Login").TAP,
    LOGIN_SUCCESS: "Login Successfully",
    LOGIN_FAILED: "Login Unsuccessfully",
    TAP_FORGOT_PASSWORD: trackingTypes("Forgot Password").TAP,
    TAP_LOGIN_BY_BIOMETRICS: trackingTypes("Login By Biometrics").TAP,
    REQUEST_OTP_FORGOT_PASSWORD: "Request OTP Forgot Password",
  },
  OTHER: {
    VIEW_CATEGORY_POLICY: trackingTypes("Expense Category Policy").TAP_VIEW,
    VIEW_TRAVEL_REQUEST_POLICY: trackingTypes("Travel Request Policy").TAP_VIEW,
    VIEW_APPROVAL_FLOW: trackingTypes("Approval Flow").TAP_VIEW,
    SWITCH_COMPANY: trackingTypes("Switch Company").TAP,
    CHANGE_LANGUAGE: trackingTypes("Change Language").TAP,
    OPEN_AUTHENTICATION: trackingTypes("Open Biometrics Authentication").TAP,
    VIEW_TERM_OF_CONDITION: trackingTypes("Term Of Condition").TAP_VIEW,
    VIEW_ABOUT_BIZZI: trackingTypes("About Bizzi").TAP_VIEW,
    VIEW_CONTACT: trackingTypes("Contact").TAP_VIEW,
    VIEW_GUIDE: trackingTypes("Guide").TAP_VIEW,
    TAP_RATING: trackingTypes("Rating").TAP,
    TAP_LOGOUT: trackingTypes("Logout").TAP,
    VIEW_INVOICE_INFORMATION: trackingTypes("Invoice Information").TAP_VIEW,
    CALL_PHONE_CONTACT: trackingTypes("Call Phone Contact").TAP,
    OPEN_MAIL_CONTACT: trackingTypes("Open Mail Contact").TAP,
    VIEW_COMMENT: trackingTypes("Comment").TAP_VIEW,
    VIEW_EMPLOYEE_INFO: trackingTypes("Employee Information").TAP_VIEW,
    SEND_MAIL_EMPLOYEE: trackingTypes("Send Mail To Employee").TAP,
    CALL_PHONE_EMPLOYEE: trackingTypes("Call Phone To Employee").TAP,
    VIEW_INVOICE_DETAIL: trackingTypes("Invoice Detail").TAP_VIEW,
    SHARED_VAT_INFO: "Shared Vat Info",
    TAP_OPEN_RATING_INTERNAL: "Tapped Open In-app Feedback",
    OPEN_RATING_INTERNAL: "Open In-app Feedback",
    RATING_INTERNAL_SUCCESS: "In-app Feedback Successfully",
    TAP_OPEN_SORT_WAITING_APPROVAL: trackingTypes("Open Sort Waiting Approval Modal").TAP,
    SORT_WAITING_APPROVAL_LIST: "Sorted Waiting Approval List",
  },
  NOTIFICATION: {
    VIEW_PROMOTION_LIST: "Viewed Promotion List Screen",
    VIEW_NOTIFICATION_LIST: "Viewed Notification List Screen",
    MARK_READ_ALL: "Marked Read All Notification",
    TAP_VIEW_NOTIFICATION_LIST: "Tapped View Notification",
    TAP_PROMOTION_BANNER: "Tapped Promotion Banner",
    VIEW_PROMOTION_BANNER: "Viewed Promotion Banner",
    TAP_VIEW_DETAIL: trackingTypes("Notification").TAP_VIEW_DETAIL,
  },
  DASHBOARD: {
    VIEW_ADMIN_DASHBOARD: trackingTypes("Admin Dashboard").TAP_VIEW,
    VIEW_PERSONAL_DASHBOARD: trackingTypes("Personal Dashboard").TAP_VIEW,
  },
  HOME: {
    VIEW_SUBMITTED_TO_ME: trackingTypes("View List of Submitted to Me").TAP,
    VIEW_INBOX: trackingTypes("Inbox List").TAP_VIEW,
    OPEN_CAMERA: trackingTypes("Open Camera").TAP,
    VIEW_NOTIFICATION_LIST: trackingTypes("Notification List").TAP_VIEW,
    VIEW_NEW_EXPENSE_LIST: trackingTypes("New Expense List").TAP_VIEW,
    VIEW_REQUEST_LIST_NEED_TO_BE_APPROVED: trackingTypes("Request List Need To Be Approved").TAP_VIEW,
    VIEW_REPORT_LIST_NEED_TO_BE_APPROVED: trackingTypes("Report List Need To Be Approved").TAP_VIEW,
    VIEW_CAMPAIGN: trackingTypes("Campaign Detail").TAP_VIEW,
    VIEW_INVOICE_INFORMATION: trackingTypes("Invoice VAT").TAP_VIEW,
    TAP_BANNER: "Tapped Home Screen Banner",
    VIEW_BANNER: "Viewed Home Screen Banner",
    TAP_VIEW_GUIDE_VIDEO: "Tapped view guide video",
    TAP_CLOSE_GUIDE_VIDEO: "Tapped close guide video",
  },
  INVOICE: {
    ...trackingTypes("Invoice"),
    TAP_TRANSFER_INVOICE: trackingTypes("Transfer Invoice").TAP,
    TAP_DELETE_INVOICE: trackingTypes("Delete Invoice").TAP,
    DELETED_INVOICE: trackingTypes("Delete Invoice").TAP,
    TRANSFERRED_INVOICE: "Transferred Invoice",
    TRANSFERRED_INVOICE_SUCCESS: "Transferred Invoice Successfully",
  },
  CARD_TRANSACTION: {
    ASSIGN_TRANSACTION: "Assigned Transaction",
    ASSIGN_TRANSACTION_SUCCESS: "Assigned Transaction Successfully",
  },
  SYSTEM_ALERT: {
    WARNING_EXPENSE_BUDGET: "Alerted On Warning Expense Budget",
    RESTRICT_EXPENSE_BUDGET: "Alerted On Restrict Expense Budget",
    WARNING_REQUEST_BUDGET: "Alerted On Warning Request Budget",
    RESTRICT_REQUEST_BUDGET: "Alerted On Restrict Request Budget",
    WARNING_REPORT_BUDGET: "Alerted On Warning Report Budget",
    RESTRICT_REPORT_BUDGET: "Alerted On Restrict Report Budget",
  },
  WALKTHROUGH: {
    TAP_ONBOARDING_COURSE: "Tapped Onboarding Course",
    TAP_ONBOARDING_COURSE_ACTION: "Tapped Onboarding Course Action",
    SKIP_ONBOARDING_COURSE: "Skip Onboarding Course Action",
    FINISHED_ONBOARDING_COURSE_ACTION: "Finished Onboarding Course Action",
    TAP_BIZZI_BOT: "Tapped Bizzi Bot",
  },
};
