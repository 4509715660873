import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MobileNtfDeleteNotificationMutation, MobileNtfDeleteNotificationMutationVariables } from "types";

const MUTATION = gql`
  mutation MobileNtfDeleteNotification($input: NtfDeleteNotificationInput!) {
    ntfDeleteNotification(input: $input) {
      success
    }
  }
`;

const useDeleteNotification = () => {
  return useMutation<MobileNtfDeleteNotificationMutation, MobileNtfDeleteNotificationMutationVariables>(MUTATION);
};

export default useDeleteNotification;
