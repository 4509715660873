enum RequestStatus {
  DRAFT = "draft",
  READY = "ready",
  WAITING = "waiting",
  SUBMITTED = "submitted",
  REJECTED = "rejected",
  APPROVED = "approved",
  STORED = "stored",
  CANCELLED = "cancelled",
  CLOSED = "closed",
}

export const REQUEST_TYPE = {
  travel: "travel",
  other: "other",
};

export default RequestStatus;
