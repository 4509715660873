import { ApprovedStatusIcon, FolderCloudOutlined, RejectedStatusIcon } from "assets/images/svg/icons";
import { AppText } from "components/index";
import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";
import { Colors, Fonts } from "theme";

export enum StatusDetailType {
  waiting = "waiting",
  approved = "approved",
  canceled = "canceled",
  rejected = "rejected",
  draft = "draft",
}
const WaitingIcon = () => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
    <Path
      d="M12.6982 1.66699H7.29824C4.16491 1.66699 3.92324 4.48366 5.61491 6.01699L14.3816 13.9837C16.0732 15.517 15.8316 18.3337 12.6982 18.3337H7.29824C4.16491 18.3337 3.92324 15.517 5.61491 13.9837L14.3816 6.01699C16.0732 4.48366 15.8316 1.66699 12.6982 1.66699Z"
      stroke="#1F71F4"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

const CancelStatusIcon = () => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
    <Path
      d="M10.0013 18.3337C14.5846 18.3337 18.3346 14.5837 18.3346 10.0003C18.3346 5.41699 14.5846 1.66699 10.0013 1.66699C5.41797 1.66699 1.66797 5.41699 1.66797 10.0003C1.66797 14.5837 5.41797 18.3337 10.0013 18.3337Z"
      stroke="#E11934"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.64062 12.3583L12.3573 7.6416"
      stroke="#E11934"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.3573 12.3583L7.64062 7.6416"
      stroke="#E11934"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

const STATUS = {
  [StatusDetailType.draft]: {
    title: "draft",
    color: Colors.grayscale100,
    backgroundColor: Colors.grayscale10,
    icon: <FolderCloudOutlined />,
  },
  [StatusDetailType.waiting]: {
    title: "waiting",
    color: Colors.warning50,
    backgroundColor: Colors.primary10,
    icon: <WaitingIcon />,
  },
  [StatusDetailType.approved]: {
    title: "approved",
    color: Colors.success50,
    backgroundColor: Colors.success0,
    icon: <ApprovedStatusIcon />,
  },
  [StatusDetailType.canceled]: {
    title: "cancelled",
    color: Colors.alert50,
    backgroundColor: Colors.alert05,
    icon: <CancelStatusIcon />,
  },
  [StatusDetailType.rejected]: {
    title: "rejected",
    color: Colors.alert50,
    backgroundColor: Colors.alert05,
    icon: <RejectedStatusIcon />,
  },
};
interface StatusDetailProps {
  title?: string;
  backgroundColor?: string;
  textColor?: string;
  style?: StyleProp<ViewStyle>;
}
const StatusDetail: React.FC<StatusDetailProps> = (props) => {
  const { style, title, backgroundColor, textColor = Colors.grayscale100 } = props;

  if (!title) {
    return null;
  }
  return (
    <View style={[styles.container, style, { backgroundColor }]}>
      <AppText style={Fonts.BodySmall} color={textColor}>
        {title}
      </AppText>
    </View>
  );
};
export default StatusDetail;
const styles = StyleSheet.create({
  container: { height: 24, justifyContent: "center", paddingHorizontal: 8, borderRadius: 4, alignSelf: "flex-start" },
});
