import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <Mask id="a" width={16} height={16} x={0} y={0} maskUnits="userSpaceOnUse">
      <Path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill="#73788B"
        d="M6.23 8.511a.483.483 0 0 1-.356-.143.484.484 0 0 1-.144-.356V3.658L4.185 5.203a.453.453 0 0 1-.338.138.512.512 0 0 1-.513-.501.49.49 0 0 1 .155-.357l2.313-2.312A.55.55 0 0 1 6.225 2c.08 0 .155.013.227.039a.54.54 0 0 1 .2.132L8.982 4.5c.099.1.147.214.146.345a.525.525 0 0 1-.508.501.466.466 0 0 1-.351-.15L6.73 3.658v4.36a.487.487 0 0 1-.5.494Zm3.788 5.647a.667.667 0 0 1-.228-.04.54.54 0 0 1-.2-.13l-2.33-2.33a.466.466 0 0 1-.146-.345.525.525 0 0 1 .508-.501.466.466 0 0 1 .352.15L9.512 12.5V8.14a.487.487 0 0 1 .5-.494c.142 0 .26.048.357.144a.484.484 0 0 1 .143.356V12.5l1.545-1.545a.453.453 0 0 1 .338-.138.512.512 0 0 1 .513.5.49.49 0 0 1-.155.357l-2.313 2.313a.55.55 0 0 1-.422.17Z"
      />
    </G>
  </Svg>
);
export default SvgComponent;
