import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { Route } from "react-native-tab-view";
import { CheckFillIcon } from "assets/images/svg/icons";
import { BackHeader, TopTabView, TouchableOpacityCustom } from "components";
import { Colors } from "theme";
import NotificationListTabView, { NotificationListTabViewMethods } from "./components/NotificationListTabView";
import PromotionListTabView from "./components/PromotionListTabView";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

const NotificationScreen = ({ navigation, route }) => {
  const { t } = useTranslation("app/screens/Notification/NotificationScreen");
  const { notificationCount } = route?.params ?? {};
  const [index, setIndex] = useState<number>(0);
  const notificationTabRef = useRef<NotificationListTabViewMethods>();
  const [isChangeTab, setIsChangeTab] = useState(false);
  const routes = [
    { key: "notification", title: t("general") },
    { key: "promotion", title: t("promotion") },
  ];
  useEffect(() => {
    if (index === 1) {
      setIsChangeTab(true);
      analyticService.logEvent({ name: EVENT.NOTIFICATION.VIEW_PROMOTION_LIST });
    } else if (isChangeTab) {
      analyticService.logEvent({ name: EVENT.NOTIFICATION.VIEW_NOTIFICATION_LIST });
    }
  }, [index]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("notification")}
          headerRight={
            index === 0 ? (
              <TouchableOpacityCustom
                eventName={EVENT.NOTIFICATION.MARK_READ_ALL}
                onPress={notificationTabRef?.current?.onMarkReadNotificationAll}
              >
                <CheckFillIcon />
              </TouchableOpacityCustom>
            ) : null
          }
        />
      ),
    } as any);
  }, [index]);
  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case "notification":
        return (
          <NotificationListTabView notificationUnseenCount={notificationCount as number} ref={notificationTabRef} />
        );
      case "promotion":
        return <PromotionListTabView />;
      default:
        return null;
    }
  };
  return (
    <View style={styles.container}>
      <TopTabView renderScene={renderScene} routes={routes} index={index} setIndex={setIndex} />
    </View>
  );
};
export default NotificationScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white, paddingTop: 10 },
});
