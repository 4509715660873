import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.778 4h16c.982 0 1.778.796 1.778 1.778v12.444c0 .982-.796 1.778-1.778 1.778h-16A1.778 1.778 0 012 18.222V5.778C2 4.796 2.796 4 3.778 4zm0 5.883v8.34h16v-8.34l-8 4-8-4zm0-1.988l8 4 8-4V5.779h-16v2.117z"
        fill="#42526E"
      />
    </Svg>
  );
}

export default SvgComponent;
