import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { MobileValidateExpenseAmountMutation, MobileValidateExpenseAmountMutationVariables } from "types";

const VALIDATE_EXPENSE_AMOUNT = gql`
  mutation MobileValidateExpenseAmount($input: ExpValidateExpenseAmountInput!) {
    expValidateExpenseAmount: expValidateExpenseAmount(input: $input) {
      budget {
        isOverBudget
        isValid
        message
        overBudgetAmount
        restrictionType
      }
      expenseRequest {
        isValid
        isOverRequestAmount
        overRequestAmount
        restrictionType
        message
      }
    }
  }
`;

export const useValidateExpenseAmountMutation = (
  options?: MutationHookOptions<MobileValidateExpenseAmountMutation, MobileValidateExpenseAmountMutationVariables>
) => {
  return useMutation<MobileValidateExpenseAmountMutation, MobileValidateExpenseAmountMutationVariables>(
    VALIDATE_EXPENSE_AMOUNT,
    { ...options }
  );
};
