import { TouchableOpacity, Platform } from "react-native";
import React, { FC, ReactNode } from "react";
import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { AppText } from "components";
import { Fonts } from "theme";

export type CustomMenuOption = { title: string; onSelect: () => void };

interface CustomMenuProps {
  options?: CustomMenuOption[];
  children: ReactNode;
  menuMaxWidth?: number;
}

const CustomMenu: FC<CustomMenuProps> = ({ options, children, menuMaxWidth }) => {
  return (
    <Menu>
      <MenuTrigger
        customStyles={{
          triggerWrapper: {
            top: 0,
          },
          TriggerTouchableComponent: TouchableOpacity,
        }}
      >
        {children}
      </MenuTrigger>
      <MenuOptions
        customStyles={{
          optionsContainer: {
            width: Platform.OS === "web" ? menuMaxWidth : "auto",
            borderRadius: 10,
            marginTop: 20,
            paddingVertical: 5,
            paddingHorizontal: 5,
          },
        }}
      >
        {options.map((item) => (
          <MenuOption
            onSelect={item.onSelect}
            customStyles={{
              OptionTouchableComponent: TouchableOpacity,
            }}
          >
            <AppText allowFontScaling={false} style={Fonts.BodyMedium}>
              {item.title}
            </AppText>
          </MenuOption>
        ))}
      </MenuOptions>
    </Menu>
  );
};

export default CustomMenu;
