import { DatePickerMode } from "constants/DateSelectConstant";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { InfoCircleIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import React, { FC, useEffect, useMemo, useRef } from "react";
import LocationInput from "screens/ExpenseRequest/components/ExpenseRequestForm/LocationInput";
import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import CalendarDatePicker from "components/InputCustom/CalendarDatePicker";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { AppText, IconCustom, CopilotStepCustom } from "components";
import { Keys } from "constants/Keys";
import dayjs from "dayjs";
import useExpenseRequestTravelUsage from "screens/ExpenseRequest/hooks/useExpenseRequestTravelUsage";
import { useAuth } from "contexts/AuthContext";
import { countNumberOfDays, getWarningMessageTravelDateIfExist } from "utils";
import { BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { BizziBotSticker02 } from "assets/images";
import { useCopilot } from "react-native-copilot";
import { useMasterData } from "contexts/MasterDataContext";

interface TravelSelectorProps {
  mode: DatePickerMode;
  control: Control<ExpenseRequestFormValues, any>;
  setValue: UseFormSetValue<ExpenseRequestFormValues>;
  getValues: UseFormGetValues<ExpenseRequestFormValues>;
  errors?: FieldErrors<ExpenseRequestFormValues>;
  watch?: UseFormWatch<ExpenseRequestFormValues>;
  limitationEnable?: boolean;
  handleAutoAddAllowances?: (type: BOOKING_TYPE) => void;
}
const TravelSelector: FC<TravelSelectorProps> = ({
  setValue,
  mode,
  control,
  getValues,
  errors,
  watch,
  limitationEnable,
  handleAutoAddAllowances,
}) => {
  const {
    setting: { allowPastTimeOnExpenseRequestCreation },
  } = useMasterData();
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const [onFetchRequestTravelUsage] = useExpenseRequestTravelUsage();
  const { visible: visibleWalkthrough } = useCopilot();
  const {
    user: { employee_id },
  } = useAuth();
  const handleFetchExpenseRequestTravelUsage = async (fromDate: string, toDate: string) => {
    const rs = await onFetchRequestTravelUsage({
      variables: {
        input: {
          travelDateRange: {
            fromDate,
            toDate,
          },
          employeeId: employee_id,
        },
      },
    });
    if (rs?.data?.expExpenseRequestTravelUsage?.periods?.length) {
      setValue("travelUsage", rs.data.expExpenseRequestTravelUsage.periods);
    }
  };
  const fromDate = watch("travelDate.fromDate");
  const toDate = watch("travelDate.toDate");
  const travelUsage = watch("travelUsage", []);
  const numOfDays = countNumberOfDays(fromDate, toDate);
  useEffect(() => {
    if (getValues("travel.toCity.metadata.code")) {
      locationInputRef?.current?.handleAutoGenerateAllowancePerdiem?.(getValues("travel.toCity.metadata"));
    }
  }, [numOfDays]);

  /*-- in the case change fromDate and toDate -->  --*/
  useEffect(() => {
    if (limitationEnable) {
      handleFetchExpenseRequestTravelUsage(dayjs(fromDate).toISOString(), dayjs(toDate).toISOString());
    }
  }, [limitationEnable]);
  /*-- flow auto fill date and return date for flight booking & transportation booking--*/
  /*useEffect(() => {
    if (fromDate) {
      !getValues("flightBooking.departureDate") &&
        setValue("flightBooking.departureDate", fromDate, { shouldValidate: true });
      !getValues("transportationBooking.departureDate") &&
        setValue("transportationBooking.departureDate", fromDate, { shouldValidate: true });
      !getValues("hotelBookingDate.fromDate") &&
        setValue("hotelBookingDate.fromDate", fromDate, { shouldValidate: true });
    }
  }, [fromDateString]);
  useEffect(() => {
    if (toDate) {
      !getValues("flightBooking.returnDate") && setValue("flightBooking.returnDate", toDate, { shouldValidate: true });
      !getValues("transportationBooking.returnDate") &&
        setValue("transportationBooking.returnDate", toDate, { shouldValidate: true });
      !getValues("hotelBookingDate.toDate") && setValue("hotelBookingDate.toDate", toDate, { shouldValidate: true });
    }
  }, [toDateString]);*/
  /*-- end --*/
  const handleSwapLocation = () => {
    const fromCity = getValues("travel.fromCity");
    const toCity = getValues("travel.toCity");
    setValue("travel.fromCity", toCity);
    setValue("travel.toCity", fromCity);
    locationInputRef?.current?.handleAutoGenerateAllowancePerdiem?.(getValues("travel.toCity.metadata"));
  };
  const warningMessage = useMemo(() => {
    if (!limitationEnable) {
      return "";
    }
    return getWarningMessageTravelDateIfExist(travelUsage, fromDate, toDate);
  }, [travelUsage]);
  const handleSetLocation = (key, value, config) => {
    setValue(key, value, config);
  };
  const locationInputRef = useRef<any>();
  const onConfirmDate = ({ fromDate, toDate }) => {
    if (getValues("travel.toCity.metadata.code")) {
      locationInputRef?.current?.handleAutoGenerateAllowancePerdiem?.(getValues("travel.toCity.metadata"));
    }
    handleFetchExpenseRequestTravelUsage(dayjs(fromDate).toISOString(), dayjs(toDate).toISOString());
  };

  return (
    <View style={styles.tabViewTravel}>
      <CalendarDatePicker
        format="ddd, DD/MM/YYYY"
        datePickerModalProps={{
          validRange: allowPastTimeOnExpenseRequestCreation ? undefined : { startDate: new Date() },
        }}
        style={{ marginTop: 10, marginBottom: 8 }}
        mode={mode}
        label={t("date")}
        name="travelDate"
        control={control}
        rules={{ required: true }}
        error={errors?.travelDate}
        onConfirm={onConfirmDate}
        warning={visibleWalkthrough ? "" : warningMessage}
      />
      <View style={styles.travelLocations}>
        <LocationInput
          isSearchAllWithoutPolicy
          name={"travel.fromCity"}
          control={control}
          label={t("from_loc")}
          style={styles.field}
          setValue={handleSetLocation}
          error={errors?.travel?.fromCity}
          rules={{ required: true }}
          value={watch("travel.fromCity")}
          searchDescription={t("search_location_from_description")}
          recentLocationKey={`${employee_id}${Keys.RECENT_LOCATION_FROM}`}
          getValues={getValues}
        />
        <LocationInput
          ref={locationInputRef}
          isSearchAllWithoutPolicy
          name={"travel.toCity"}
          control={control}
          label={t("to_loc")}
          style={styles.field}
          setValue={handleSetLocation}
          error={errors?.travel?.toCity}
          rules={{ required: true }}
          value={watch("travel.toCity")}
          searchDescription={t("search_location_to_description")}
          recentLocationKey={`${employee_id}${Keys.RECENT_LOCATION_TO}`}
          getValues={getValues}
          handleAutoAddAllowances={(type) => handleAutoAddAllowances(type)}
        />
        <TouchableHighlight
          style={styles.travelLocationsSwapButton}
          onPress={handleSwapLocation}
          underlayColor={Colors.grayscale10}
        >
          <IconCustom name="compare-arrows" />
        </TouchableHighlight>
      </View>
      {limitationEnable && travelUsage?.length > 0 && (
        <View style={styles.remainingLimit}>
          <InfoCircleIcon size={16} />
          <AppText style={Fonts.SentenceCaptionLarge}>
            {t("travel_day_remain")}{" "}
            {travelUsage.map((item, index) => (
              <AppText key={index} style={Fonts.NumericN100}>
                {item?.period === "monthly"
                  ? t("travel_remain_by_month", {
                      day:
                        item?.maxTravelDays - item?.totalTravelDays > 0
                          ? item?.maxTravelDays - item?.totalTravelDays
                          : 0,
                      month: item?.periodValue,
                    })
                  : t("travel_remain_by_squatter", {
                      day:
                        item?.maxTravelDays - item?.totalTravelDays > 0
                          ? item?.maxTravelDays - item?.totalTravelDays
                          : 0,
                      squatter: item?.periodValue,
                    })}
                {index !== travelUsage?.length - 1 ? ", " : ""}
              </AppText>
            ))}
          </AppText>
        </View>
      )}
    </View>
  );
};
export default TravelSelector;
const styles = StyleSheet.create({
  field: { marginVertical: 8 },
  tabViewTravel: { paddingHorizontal: 20, paddingBottom: 8 },
  travelLocations: { position: "relative" },
  travelLocationsSwapButton: {
    position: "absolute",
    right: 16,
    bottom: 48,
    zIndex: 1,
    padding: 6,
    backgroundColor: Colors.white,
    borderRadius: 18,
  },
  remainingLimit: {
    flexDirection: "row",
    gap: 4,
    marginTop: 4,
    padding: 8,
    backgroundColor: Colors.grayscale05,
    borderRadius: 4,
  },
});
