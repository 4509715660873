// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import InputFieldFlat from "components/Input/PaperInputFlat";
import Layout, { HEIGHT_WINDOW } from "constants/Layout";
import Constants from "expo-constants";
import { FieldArray, Formik } from "formik";

import { KeyboardAvoidingView } from "layouts";
import React, { useCallback, useRef, useState } from "react";
import {
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from "react-native";

import { stylesCommon } from "./commonStyle";
import RegisterSatus from "./RegisterSatus";
import { RegisterInviteColleagueScreenNavigationProp, RegisterInviteColleagueScreenRouteProp } from "navigation/type";
import { useTranslation } from "react-i18next";
import BizziIcon from "assets/images/svg/icons/BizziIcon";
import * as Yup from "yup";
import Colors from "constants/Colors";
import { Button, Chip, Divider, TextInput } from "react-native-paper";
import Entypo from "react-native-vector-icons/Entypo";
import Modal from "react-native-modal";
import { Icon } from "components";
import Fonts from "constants/Fonts";
import { BackButtonIcon, PlusIcon } from "assets/images/svg/icons";
import useExpenseRegisterCustomer from "hooks/register/useRegisterCustomer";
import { ActivityIndicator, Toast } from "@ant-design/react-native";
import { useAuth } from "contexts/AuthContext";
import trim from "lodash/trim";
import CustomDropdownPicker from "layouts/CustomDropdownPicker";
import toLower from "lodash/toLower";
import TextInputError from "./TextInputError";
import LanguageStatus from "constants/LanguageStatus";
import SCREEN_NAME from "navigation/ScreenName";
import useEnabledExpenseApp from "hooks/register/useEnabledExpenseApp";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { StatusBar } from "expo-status-bar";

interface IRegisterInviteColluagueScreen {
  navigation: RegisterInviteColleagueScreenNavigationProp;
  route: RegisterInviteColleagueScreenRouteProp;
}

const RegisterInviteColleague: React.FC<IRegisterInviteColluagueScreen> = ({ navigation, route }) => {
  const { t, i18n } = useTranslation("screens/RegisterOnboarding/RegisterInviteColleagueScreen");
  const {
    email,
    phone,
    fullname,
    password,
    tax_code,
    company_name,
    address,
    business_areas,
    company_size,
    referralBy,
    company_id,
    token,
    employee_title,
  } = route?.params ?? {};
  const { width } = useWindowDimensions();
  const IsMobile = width <= 992;
  const { setUserData, refreshDataUser } = useAuth();
  const isUseWebLayout = !IsMobile;

  const [visibleDepartmentModal, setVisibleDepartmentModal] = useState(false);

  const [newDepartment, setNewDepartment] = useState("");

  const selectedDepartmentInput = useRef(null);

  const { registerNewCustomer, loading } = useExpenseRegisterCustomer();
  const { enabledExpenseApp, loading: loadingEnabledExpenseApp } = useEnabledExpenseApp();

  const [departments, setDepartments] = useState([
    {
      value: "Product",
      name: "Product",
    },
    {
      value: "Marketing",
      name: "Marketing",
    },
    {
      value: "Sale",
      name: "Sale",
    },
  ]);

  const onSubmit = useCallback(
    async (values) => {
      if (company_id) {
        try {
          const resultEnabledApp = await enabledExpenseApp(
            {
              company_id,
              referral_code: referralBy,
              employees: values.employees.map((e) => {
                return {
                  ...e,
                  email: trim(e.email),
                };
              }),
            },
            token
          );

          if (resultEnabledApp?.data?.expenseEnableExpenseApp?.success) {
            if (Platform.OS === "web") {
              navigation.navigate(SCREEN_NAME.RegisterInformScreen, {
                isAdmin: true,
                companyId: resultEnabledApp?.data?.expenseEnableExpenseApp?.company_id,
              });
            } else {
              await refreshDataUser();
              navigation.navigate(SCREEN_NAME.HomeBottomTabNavigator);
            }
          } else {
            if (
              toLower(resultEnabledApp?.errors?.[0]?.message) === t(toLower(resultEnabledApp?.errors?.[0]?.message))
            ) {
              Toast.fail(t("unknown"));
              return;
            }
            Toast.fail(
              t(toLower(resultEnabledApp?.errors?.[0]?.message), {
                emails: resultEnabledApp?.errors?.[0]?.extensions?.existingEmployeeEmails?.join(","),
              })
            );
          }
        } catch (errors) {
          if (errors?.message === SERVER_ERROR_CODE.COMPANY_ENABLED_EXPENSE) {
            Toast.fail(t(toLower(SERVER_ERROR_CODE.COMPANY_ENABLED_EXPENSE)));
            return;
          }

          if (errors?.message === SERVER_ERROR_CODE.NOT_SUPPORT_ENABLED_MULTI_COMPANY_EXPENSE) {
            Toast.fail(t(toLower(SERVER_ERROR_CODE.NOT_SUPPORT_ENABLED_MULTI_COMPANY_EXPENSE)));
            return;
          }

          Toast.fail(t("unknown"));
        }

        return;
      }

      try {
        const results = await registerNewCustomer({
          email,
          phone,
          fullname,
          password,
          tax_code,
          company_name,
          field: business_areas,
          size: company_size,
          address,
          employees: values.employees.map((e) => {
            return {
              ...e,
              email: trim(e.email),
            };
          }),
          referral_code: referralBy,
        });

        if (results?.data?.expenseRegister?.success) {
          if (Platform.OS === "web") {
            navigation.navigate(SCREEN_NAME.RegisterInformScreen, {
              isAdmin: true,
              companyId: results?.data?.expenseRegister?.company_id,
            });
          } else {
            setUserData({ token: results?.data?.expenseRegister?.token });
          }
        } else {
          if (
            results?.data?.expenseRegister?.message === SERVER_ERROR_CODE.EXPENSE_EMPLOYEE_PHONE_EXISTS ||
            results?.data?.expenseRegister?.message === SERVER_ERROR_CODE.EMPLOYEE_PHONE_EXISTS
          ) {
            Toast.fail(t("phone_exists"));
            return;
          }

          if (results?.data?.expenseRegister?.message === SERVER_ERROR_CODE.EXPENSE_EMPLOYEE_EMAIL_EXISTS) {
            Toast.fail(t("email_exists"));
            return;
          }

          Toast.fail(t("unknown"));
        }
      } catch (error) {
        if (error?.message === SERVER_ERROR_CODE.EXPENSE_EMPLOYEE_PHONE_EXISTS) {
          Toast.fail(t("phone_exists"));
          return;
        }

        if (error?.message === SERVER_ERROR_CODE.EXPENSE_EMPLOYEE_EMAIL_EXISTS) {
          Toast.fail(
            t("email_exists", { email: error?.graphQLErrors?.[0]?.extensions?.existingEmployeeEmails?.join(",") ?? "" })
          );
          return;
        }
        console.log(error);
        Toast.fail(t("unknown"));
      }
    },
    [
      registerNewCustomer,
      email,
      phone,
      fullname,
      password,
      tax_code,
      company_name,
      address,
      business_areas,
      company_size,
      setUserData,
      t,
      navigation,
      referralBy,
      company_id,
      refreshDataUser,
      enabledExpenseApp,
      token,
    ]
  );

  const listDepartmentVN = [
    { label: "Ban Quản lý", value: "Ban Quản lý" },
    { label: "Kinh doanh", value: "Kinh doanh" },
    { label: "Chăm sóc khách hàng", value: "Chăm sóc khách hàng" },
    { label: "Nhân sự hành chính", value: "Nhân sự hành chính" },
    { label: "Tài chính", value: "Tài chính" },
    { label: "Kế toán", value: "Kế toán" },
    { label: "Công nghệ", value: "Công nghệ" },
    { label: "Sản phẩm", value: "Sản phẩm" },
    { label: "Thị trường", value: "Thị trường" },
    { label: "Sản xuất", value: "Sản xuất" },
    { label: "Mua hàng", value: "Mua hàng" },
    { label: "Pháp lý", value: "Pháp lý" },
    { label: "Đầu tư", value: "Đầu tư" },
    { label: "Chuyển đổi số", value: "Chuyển đổi số" },
  ];

  const listDepartmentEN = [
    { label: "Management", value: "Management" },
    { label: "Business development", value: "Business development" },
    { label: "Customer service", value: "Customer service" },
    { label: "Human resources", value: "Human resources" },
    { label: "Finance", value: "Finance" },
    { label: "Accounting", value: "Accounting" },
    { label: "Technology", value: "Technology" },
    { label: "Product", value: "Product" },
    { label: "Marketing", value: "Marketing" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Purchasing", value: "Purchasing" },
    { label: "Legal", value: "Legal" },
    { label: "Investment", value: "Investment" },
    { label: "Digital transformation", value: "Digital transformation" },
  ];

  const initialValues = {
    email: "",
    employees: [
      {
        title: employee_title,
        role: "admin",
        email: trim(email),
        department: i18n.language === LanguageStatus.EN ? listDepartmentEN[0].label : listDepartmentVN[0].label,
      },
    ],
  };

  const yupSchema = Yup.object().shape({
    email: Yup.string().email(t("please_input_correct_email")),
    employees: Yup.array().of(
      Yup.object().shape({
        role: Yup.string().required(t("required")),
        email: Yup.string().email(t("please_input_correct_email")),
        department: Yup.string().required(t("required")),
      })
    ),
  });

  const onCloseModal = useCallback(() => {
    setVisibleDepartmentModal(false);
  }, []);

  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <StatusBar style="dark" {...(Platform.OS === "android" ? { backgroundColor: "transparent" } : {})} />
      <ScrollView>
        <View style={stylesCommon.container}>
          <View style={IsMobile ? [stylesCommon.contentMobile, { paddingHorizontal: 5 }] : stylesCommon.content}>
            <View style={stylesCommon.ph20}>
              <View style={stylesCommon.logoContainer}>
                <TouchableOpacity onPress={() => navigation.goBack()} style={stylesCommon.backButton}>
                  <BackButtonIcon />
                </TouchableOpacity>
                <BizziIcon />
              </View>
            </View>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validateOnMount
              enableReinitialize
              validationSchema={yupSchema}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setValues,
                  validateForm,
                  setFieldError,
                } = props;

                return (
                  <ScrollView keyboardDismissMode="on-drag" keyboardShouldPersistTaps="always">
                    <View
                      style={
                        IsMobile
                          ? [stylesCommon.registerFormMobile, { paddingHorizontal: 0 }]
                          : [stylesCommon.registerForm, styles.formStyle]
                      }
                    >
                      <Text style={[stylesCommon.title, stylesCommon.ph20]}>{t("title")}</Text>
                      <Text style={[stylesCommon.description, styles.description, stylesCommon.ph20]}>
                        {t("description")}
                      </Text>
                      <View style={isUseWebLayout && { flexDirection: "row", alignItems: "center" }}>
                        <View style={[{ flex: 1 }, isUseWebLayout && { marginRight: 15 }]}>
                          <View style={stylesCommon.ph20}>
                            <TextInput
                              keyboardType="email-address"
                              autoCapitalize={"none"}
                              placeholder={t("input_email_of_new_employee")}
                              style={[styles.inputEmail, touched?.email && !!errors?.email && styles.inputError]}
                              underlineColor="transparent"
                              activeUnderlineColor={Platform.OS !== "web" ? Colors.primary50 : "transparent"}
                              onBlur={handleBlur("email")}
                              value={values.email}
                              editable={values?.employees?.length < 5}
                              onChangeText={handleChange("email")}
                              onSubmitEditing={async () => {
                                Keyboard.dismiss();
                                const results = await validateForm(values);
                                if (results?.email) {
                                  return;
                                }

                                const isExists = values.employees.findIndex((e) => e.email === values.email) >= 0;

                                if (isExists) {
                                  setFieldError("email", t("email_added", { email: values.email }));

                                  return;
                                }

                                if (values?.employees?.length >= 5) {
                                  return;
                                }
                                if (values.email.trim()) {
                                  const user = {
                                    role: "user",
                                    email: values.email.trim(),
                                    department:
                                      i18n.language === LanguageStatus.EN
                                        ? listDepartmentEN[0].label
                                        : listDepartmentVN[0].label,
                                  };
                                  if (!(touched?.email && !!errors?.email)) {
                                    setValues({
                                      email: "",
                                      employees: [...values.employees, user],
                                    });
                                  }
                                }
                              }}
                            />
                          </View>

                          {touched?.email && !!errors?.email && IsMobile && (
                            <View style={stylesCommon.ph20}>
                              <TextInputError>{errors?.email}</TextInputError>
                            </View>
                          )}
                          {IsMobile && values?.employees.length > 0 && (
                            <View style={{ flex: 1, flexDirection: "row", marginVertical: 15, flexWrap: "wrap" }}>
                              {values?.employees.map((employee, index) => {
                                return (
                                  <View
                                    key={employee.email}
                                    style={[{ marginBottom: 15, marginLeft: 15 }, isUseWebLayout && { marginLeft: 15 }]}
                                  >
                                    <Chip
                                      onClose={
                                        index > 0
                                          ? () => {
                                              const filterEmployees = values.employees.filter(
                                                (e) => e.email !== employee.email
                                              );
                                              setValues({
                                                employees: filterEmployees,
                                              });
                                            }
                                          : undefined
                                      }
                                    >
                                      {employee.email}
                                    </Chip>
                                  </View>
                                );
                              })}
                            </View>
                          )}
                        </View>
                        <View style={stylesCommon.ph20}>
                          <Button
                            uppercase={false}
                            mode="contained"
                            style={{ backgroundColor: "#F0F6FF", shadowColor: "transparent" }}
                            contentStyle={[{ paddingVertical: 3 }, isUseWebLayout && { width: 98, height: 56 }]}
                            onPress={() => {
                              if (values.email.trim()) {
                                const user = {
                                  role: "user",
                                  email: values.email.trim(),
                                  department:
                                    i18n.language === LanguageStatus.EN
                                      ? listDepartmentEN[0].label
                                      : listDepartmentVN[0].label,
                                };
                                if (!(touched?.email && !!errors?.email)) {
                                  setValues({
                                    email: "",
                                    employees: [...values.employees, user],
                                  });
                                }
                              }
                            }}
                            disabled={!(values?.employees?.length < 5)}
                            labelStyle={{
                              color: "#146EFF",
                              fontFamily: Fonts.fontFamilyMedium,
                              fontSize: 16,
                              fontWeight: "600",
                            }}
                          >
                            {t("add")}
                          </Button>
                        </View>
                      </View>
                      {touched?.email && !!errors?.email && !IsMobile && (
                        <View style={stylesCommon.ph20}>
                          <TextInputError>{errors?.email}</TextInputError>
                        </View>
                      )}
                      {isUseWebLayout && values?.employees.length > 0 && (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            marginVertical: 15,
                            flexWrap: "wrap",
                            marginLeft: 15,
                          }}
                        >
                          {values?.employees.map((employee, index) => {
                            return (
                              <View key={employee.email} style={{ marginBottom: 10, marginLeft: index > 0 ? 15 : 0 }}>
                                <Chip
                                  onClose={
                                    index > 0
                                      ? () => {
                                          const filterEmployees = values.employees.filter(
                                            (e) => e.email !== employee.email
                                          );
                                          setValues({
                                            employees: filterEmployees,
                                          });
                                        }
                                      : undefined
                                  }
                                >
                                  {employee.email}
                                </Chip>
                              </View>
                            );
                          })}
                        </View>
                      )}

                      <FieldArray
                        name="employees"
                        render={({ push, remove, replace }) => {
                          return (
                            <View style={stylesCommon.ph20}>
                              {values.employees.map((employee, index) => (
                                <View
                                  style={
                                    isUseWebLayout && {
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }
                                  }
                                >
                                  {IsMobile && index > 0 && <Divider style={{ marginTop: 30 }} />}
                                  <InputFieldFlat
                                    label={t("role")}
                                    value={t(employee.role)}
                                    disabled
                                    widthInput={isUseWebLayout ? styles.itemRole : { width: "100%" }}
                                  />
                                  <InputFieldFlat
                                    label={t("employee_email")}
                                    value={employee.email}
                                    disabled
                                    autoCapitalize="none"
                                    onChangeText={(value) => replace(index, { ...employee, email: value })}
                                    widthInput={isUseWebLayout ? styles.itemEmail : { width: "100%" }}
                                  />
                                  <View
                                    style={[isUseWebLayout && styles.itemDepartment, IsMobile && { marginTop: 25 }]}
                                  >
                                    <CustomDropdownPicker
                                      placeholder={t("department")}
                                      value={employee.department}
                                      onSelectItem={(item) => {
                                        replace(index, { ...employee, department: item });
                                      }}
                                      items={i18n.language === LanguageStatus.EN ? listDepartmentEN : listDepartmentVN}
                                    />
                                  </View>
                                  {isUseWebLayout ? (
                                    <View
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignSelf: "center",
                                        opacity: index !== 0 ? 1 : 0,
                                        marginTop: 25,
                                      }}
                                    >
                                      <Entypo
                                        name="circle-with-minus"
                                        size={20}
                                        color={Colors.alert50}
                                        onPress={() => {
                                          if (index !== 0) {
                                            remove(index);
                                          }
                                        }}
                                      />
                                    </View>
                                  ) : (
                                    index !== 0 && (
                                      <Button
                                        mode="contained"
                                        style={{ shadowColor: "transparent", marginTop: 15 }}
                                        contentStyle={{ backgroundColor: "#FFE4E6", paddingVertical: 3 }}
                                        onPress={() => {
                                          if (index !== 0) {
                                            remove(index);
                                          }
                                        }}
                                      >
                                        <Entypo
                                          name="circle-with-minus"
                                          size={16}
                                          color={Colors.alert50}
                                          style={{ alignSelf: "center" }}
                                        />
                                        <Text
                                          style={{
                                            color: "#E11934",
                                            marginLeft: 5,
                                            fontWeight: "500",
                                            fontFamily: Fonts.fontFamilyMedium,
                                          }}
                                        >
                                          {t("remove")}
                                        </Text>
                                      </Button>
                                    )
                                  )}
                                </View>
                              ))}
                            </View>
                          );
                        }}
                      />

                      <View
                        style={[
                          stylesCommon.ph20,
                          { position: "relative" },
                          isUseWebLayout && { flexDirection: "row", justifyContent: "flex-end" },
                        ]}
                      >
                        <Button
                          contentStyle={[{ paddingVertical: 3 }, isUseWebLayout && { paddingHorizontal: 55 }]}
                          style={{ marginTop: 20 }}
                          labelStyle={{ fontFamily: Fonts.fontFamilyMedium, fontSize: 16, fontWeight: "600" }}
                          mode="contained"
                          onPress={() => handleSubmit()}
                          uppercase={false}
                        >
                          {values.employees.length > 1 ? t("invite_colleague") : t("SKIP")}
                        </Button>
                      </View>
                    </View>
                    <Modal
                      style={styles.modalStyle}
                      isVisible={visibleDepartmentModal}
                      animationIn="slideInUp"
                      onBackdropPress={onCloseModal}
                      onBackButtonPress={onCloseModal}
                      customBackdrop={
                        <TouchableWithoutFeedback onPress={onCloseModal}>
                          <View style={styles.backgroundOverlay} />
                        </TouchableWithoutFeedback>
                      }
                    >
                      <View style={styles.wrapperContent}>
                        <View style={styles.headerStyle}>
                          <View>
                            <Icon
                              onPress={onCloseModal}
                              type="MaterialCommunityIcons"
                              name="close"
                              size={24}
                              color="#4D5C6A"
                            />
                          </View>

                          <View style={styles.wrapperIcon}>
                            <Text style={styles.titleStyle}>{t("select_department")}</Text>
                          </View>

                          <View style={styles.hideIcon}>
                            <Icon
                              onPress={onCloseModal}
                              type="MaterialCommunityIcons"
                              name="close"
                              size={24}
                              color="#4D5C6A"
                            />
                          </View>
                        </View>
                        <View style={{ flexDirection: "row", paddingHorizontal: 15, marginTop: 15 }}>
                          <View style={{ flex: 1, marginRight: 15 }}>
                            <TextInput
                              mode="outlined"
                              style={{ height: 40 }}
                              value={newDepartment}
                              onChangeText={(e) => setNewDepartment(e)}
                            />
                          </View>
                          <View style={{ alignSelf: "center" }}>
                            <TouchableWithoutFeedback
                              onPress={() => {
                                setDepartments((currentDepartment) => {
                                  return [
                                    ...currentDepartment,
                                    {
                                      value: newDepartment,
                                      name: newDepartment,
                                    },
                                  ];
                                });
                                setNewDepartment("");
                                Keyboard.dismiss();
                              }}
                            >
                              <PlusIcon size={24} />
                            </TouchableWithoutFeedback>
                          </View>
                        </View>
                        <ScrollView>
                          <View style={styles.content}>
                            {departments.map((item) => {
                              return (
                                <View
                                  key={item.value}
                                  style={{ flexDirection: "row", justifyContent: "space-between" }}
                                >
                                  <TouchableOpacity
                                    onPress={() => {
                                      const listEmployees = values.employees.map((e, index) => {
                                        if (selectedDepartmentInput.current === index) {
                                          return {
                                            ...e,
                                            department: item.value,
                                          };
                                        }
                                        return e;
                                      });
                                      setValues({ employees: listEmployees });
                                      selectedDepartmentInput.current = null;
                                      onCloseModal();
                                    }}
                                  >
                                    <View style={styles.itemStyle}>
                                      <Text style={styles.itemTextStyle}>{item.name}</Text>
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              );
                            })}
                          </View>
                        </ScrollView>
                      </View>
                    </Modal>
                  </ScrollView>
                );
              }}
            </Formik>
          </View>
          {!IsMobile && (
            <View style={stylesCommon.status}>
              <RegisterSatus activeStep={3} />
            </View>
          )}
        </View>
      </ScrollView>
      {(loading || loadingEnabledExpenseApp) && <ActivityIndicator animating toast size="large" />}
    </KeyboardAvoidingView>
  );
};

export default RegisterInviteColleague;

const styles = StyleSheet.create({
  formStyle: { width: "100%" },
  description: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray80,
    marginBottom: 24,
  },
  inputEmail: {
    borderWidth: 1,
    borderRadius: 8,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    color: "#0F172A",

    backgroundColor: Colors.whiteBackground,
    borderColor: "#E2E8F0",
  },
  itemRole: { width: "30%" },
  itemEmail: { width: "30%" },
  itemDepartment: { width: "30%", alignSelf: "flex-end" },
  modalStyle: {
    justifyContent: "flex-end",
    margin: 0, // This is the important style you need to set
  },
  content: {
    marginBottom: 15,
    backgroundColor: Colors.whiteBackground,
  },
  headerStyle: {
    alignItems: "center",
    flexDirection: "row",

    paddingHorizontal: 15,
    paddingVertical: 10,
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
  },
  titleStyle: { fontFamily: Fonts.fontFamilyMedium, fontSize: 14, color: "#001629", lineHeight: 20, fontWeight: "600" },

  wrapperContent: {
    backgroundColor: Colors.whiteBackground,
    maxHeight: (HEIGHT_WINDOW * 2) / 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: (HEIGHT_WINDOW * 2) / 3,
  },
  wrapperIcon: {
    flex: 1,
    alignItems: "center",
  },

  itemStyle: {
    paddingHorizontal: 15,
    paddingVertical: 15,
  },

  itemTextStyle: {
    color: "#253858",
    fontSize: 14,
    lineHeight: 20,
  },
  hideIcon: {
    opacity: 0,
  },
  backgroundOverlay: { flex: 1, backgroundColor: "#091E42", opacity: 0.3 },
  inputError: {
    backgroundColor: Colors.alert0,
    borderColor: Colors.alert50,
  },
});
