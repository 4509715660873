import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const QUERY_EXP_MOBILE_EXPENSES = gql`
  query MobileExpenseSearch($where: ExpMobileExpensesWhereInput!, $limit: NonNegativeInt!, $offset: NonNegativeInt!) {
    expMobileExpenses(where: $where, limit: $limit, offset: $offset) {
      total
      expenses {
        allocatedStatus
        docSequence
        expenseCardTransactions {
          cardTransaction {
            cardTransactionId
          }
        }
        note
        expenseId
        invoiceId
        provider
        category
        totalAmountWithVat
        expenseDate
        receivedInvoice
        status
        expenseCategory {
          expenseCategoryId
          title
        }
        expenseReport {
          expenseReportId
          status
          docSequence
        }
      }
    }
  }
`;

const useExpenseSearchQuery = (variables: unknown) => {
  return useLazyQuery(QUERY_EXP_MOBILE_EXPENSES, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpenseSearchQuery;
