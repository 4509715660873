import { memo } from "react";
import { StyleSheet, View } from "react-native";
import ContentLoader, { Rect } from "react-content-loader/native";

import { Colors } from "theme";

const ReportCardLoading = () => {
  return (
    <View style={styles.container}>
      <ContentLoader height={241} speed={1} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
        <Rect x="0" y="0" rx="4" ry="4" width="100%" height="20" />
        <Rect x="0" y="30" rx="3" ry="3" width="140" height="10" />
        <Rect x="0" y="70" rx="4" ry="4" width="100%" height="20" />
        <Rect x="0" y="100" rx="3" ry="3" width="140" height="10" />
        <Rect x="0" y="140" rx="4" ry="4" width="100%" height="20" />
        <Rect x="0" y="170" rx="3" ry="3" width="140" height="10" />
        <Rect x="0" y="200" rx="4" ry="4" width="100%" height="20" />
        <Rect x="0" y="230" rx="3" ry="3" width="140" height="10" />
      </ContentLoader>
    </View>
  );
};

export default memo(ReportCardLoading);
const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: Colors.white,
    borderRadius: 16,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
  },
});
