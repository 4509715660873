import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 33, height = 32, color = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 33 32" fill="none">
      <Path
        d="M16.5 29.335c7.333 0 13.333-6 13.333-13.334 0-7.333-6-13.333-13.334-13.333-7.333 0-13.333 6-13.333 13.333 0 7.334 6 13.334 13.333 13.334zM11.166 16h10.667M16.5 21.335V10.668"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
