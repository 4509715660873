import { Menu, Provider } from "react-native-paper";

import React, { ReactNode, FC, Fragment, useCallback, useState } from "react";
import { Platform, StyleSheet, TouchableOpacity } from "react-native";

import { ThreeDots } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";

interface MenuItem {
  title: string;
  onPressItem?: () => void;
  color?: any;
}

interface ActionIconButtonMenuProps {
  items: MenuItem[];
}

const ActionIconButtonMenu: FC<ActionIconButtonMenuProps> = ({ items }) => {
  const [visible, setVisible] = useState(false);

  const openMenu = useCallback(() => setVisible(true), []);
  const closeMenu = useCallback(() => setVisible(false), []);

  const handleClickItem = useCallback(
    (onPressItem?: () => void) => () => {
      setVisible(false);
      onPressItem?.();
    },
    []
  );
  const Container = Platform.OS === "web" ? Provider : Fragment;

  return (
    <Container>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        contentStyle={styles.popup}
        style={Platform.OS === "web" ? ({ display: "flex", width: "min-content", left: "unset", right: 0 } as any) : {}}
        anchor={
          <TouchableOpacity onPress={openMenu}>
            <ThreeDots />
          </TouchableOpacity>
        }
      >
        {items.map(({ title, onPressItem, color = Colors.grayscale100 }) => {
          return (
            <Menu.Item
              key={title}
              title={title}
              style={styles.itemStyle}
              titleStyle={[styles.titleStyle, { color }, Fonts.BodyMedium]}
              contentStyle={styles.contentStyle}
              onPress={handleClickItem(onPressItem)}
            />
          );
        })}
      </Menu>
    </Container>
  );
};

const styles = StyleSheet.create({
  popup: {
    marginTop: 28,
    paddingVertical: 4,
    ...(Platform.OS === "web"
      ? {
          borderWidth: 1,
          borderColor: Colors.grayscale05,
          transform: null,
          opacity: 1,
        }
      : {}),
  },
  itemStyle: {
    padding: 0,
    margin: 0,
    height: 28,
    minWidth: 0,
  },
  contentStyle: {
    minWidth: 0,
  },
  titleStyle: {
    padding: 0,
    margin: 0,
  },
});

export default ActionIconButtonMenu;
