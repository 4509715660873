import React, { FC, ReactElement, ReactNode } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { Colors, Fonts } from "theme";
import AppText from "./AppText";
import { formatMoney } from "utils";
import { IconCustom } from "components/index";
import i18n from "i18next";

type TextViewType = "text" | "location_from" | "location_to";

interface TextViewProps {
  error?: string;
  type?: TextViewType;
  label?: string;
  value: string | number;
  style?: StyleProp<ViewStyle>;
  icon?: ReactNode;
  hyperlink?: boolean;
  onPress?: () => void;
  numberOfLines?: number;
  emptyText?: string;
  isMoney?: boolean;
  textColor?: string;
  labelLeft?: string;
  footer?: ReactElement;
  disabledView?: boolean;
  currency?: string;
  prefix?: string;
  suffix?: string;
}

const TextView: FC<TextViewProps> = (props) => {
  const {
    type = "text",
    label,
    value,
    icon,
    hyperlink = false,
    onPress,
    style,
    error = "",
    numberOfLines,
    emptyText = label ? i18n.t("common:empty_value", { label: label.toLowerCase() }) : "",
    isMoney = false,
    textColor,
    labelLeft,
    footer,
    disabledView = false,
    currency = "₫",
    prefix,
    suffix = "₫",
  } = props;

  const isValue = Boolean(value) || value === 0;

  const renderMoneyLabel = () => {
    if (error) {
      return error;
    }
    if (value && isMoney) {
      return prefix ? `${prefix}${formatMoney(value)} ${!prefix ? suffix : ""}` : `${formatMoney(value)} ${suffix}`;
    }
    if (isValue) {
      return value;
    }
    return emptyText;
  };

  if (disabledView) {
    return (
      <View style={styles.disabledContainer}>
        <AppText style={[Fonts.Caption, styles.labelDisabled]}>{label}</AppText>
        <AppText style={[Fonts.BodyLarge, styles.flex]} color={Colors.grayscale80}>
          {value}
        </AppText>
      </View>
    );
  }
  return (
    <View style={style}>
      <AppText style={[Fonts.SentenceCaptionMedium, styles.label]}>{label}</AppText>
      <View style={styles.container}>
        <View style={styles.body}>
          {type === "location_from" && (
            <View style={styles.locationLeftIcon}>
              <IconCustom name="adjust" />
            </View>
          )}
          {type === "location_to" && (
            <View style={styles.locationLeftIcon}>
              <IconCustom name="location-on" />
            </View>
          )}
          {hyperlink ? (
            <TouchableOpacity style={styles.flex} onPress={onPress}>
              <AppText
                numberOfLines={numberOfLines || 1}
                style={[Fonts.SentenceBodyMedium, styles.text]}
                color={Colors.primary50}
              >
                {value}
              </AppText>
            </TouchableOpacity>
          ) : (
            <AppText
              numberOfLines={numberOfLines}
              style={[Fonts.BodyMedium, styles.text]}
              color={
                error ? Colors.alert50 : textColor ? textColor : isValue ? Colors.grayscale100 : Colors.grayscale60
              }
            >
              {labelLeft && labelLeft}
              {renderMoneyLabel()}
            </AppText>
          )}
          <View style={styles.iconContainer}>{icon}</View>
        </View>
        {footer && <View style={styles.footer}>{footer}</View>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    flex: 1,
  },
  label: {
    marginBottom: 2,
    color: Colors.grayscale60,
  },
  container: {
    borderBottomWidth: 1,
    borderColor: Colors.grayscale0,
    paddingBottom: 6,
  },
  disabledContainer: {
    paddingVertical: 12,
    paddingLeft: 16,
    paddingRight: 12,
    minHeight: 48,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Colors.grayscale0,
    alignItems: "center",
  },
  labelDisabled: {
    position: "absolute",
    top: -8,
    left: 16,
    color: Colors.grayscale60,
  },
  body: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footer: {
    marginTop: 2,
  },
  locationLeftIcon: {
    marginRight: 4,
  },
  iconContainer: { marginLeft: 6 },
  flex: { flex: 1 },
});

export default TextView;
