import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.154 21.558c-.486 0-.9-.17-1.24-.511-.34-.34-.51-.753-.51-1.24 0-.485.17-.898.51-1.239.34-.34.754-.51 1.24-.51s.899.17 1.24.51c.34.34.51.754.51 1.24s-.17.899-.51 1.239c-.341.34-.754.51-1.24.51zm9.692 0c-.486 0-.899-.17-1.24-.511-.34-.34-.51-.753-.51-1.24 0-.485.17-.898.51-1.239.341-.34.754-.51 1.24-.51s.9.17 1.24.51c.34.34.51.754.51 1.24s-.17.899-.51 1.239c-.34.34-.754.51-1.24.51zM6.016 5.75l2.534 5.308h6.817a.28.28 0 00.154-.043.377.377 0 00.115-.12l2.683-4.876c.039-.07.042-.133.01-.187-.032-.055-.087-.082-.164-.082H6.015zm-.72-1.5h13.885c.409 0 .718.174.927.522.21.348.22.704.03 1.066l-3.204 5.804c-.164.289-.38.513-.65.674-.27.161-.566.242-.888.242H8.1l-1.158 2.115c-.051.077-.053.16-.005.25s.12.135.217.135h10.692c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.534.726.726 0 01-.535.216H7.154c-.667 0-1.168-.288-1.503-.863-.335-.575-.347-1.149-.035-1.722l1.426-2.565L3.404 3.75H2.25a.725.725 0 01-.534-.216A.725.725 0 011.5 3c0-.213.072-.391.216-.534a.725.725 0 01.534-.216h1.529a.896.896 0 01.813.517l.704 1.483z"
          fill="#1C1B1F"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
