// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";

import { ExpenseItemIcon } from "assets/images/svg/icons";
import { AllocatedStatus, AppText, CurrencyText } from "components";
import StatusExpense from "components/ExpenseItem/StatusExpense";
import { ALLOCATED_STATUS, CONSTANTS, EXPENSE_STATUS } from "constants/constants";
import ReportStatus from "constants/ReportStatus";
import SCREEN_NAME from "navigation/ScreenName";
import { ExpenseDetailScreenNavigationProp } from "navigation/type";
import { Colors, Fonts } from "theme";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

interface ExpenseItemProps {
  item: {
    allocatedStatus?: ALLOCATED_STATUS;
    note?: string;
    provider?: string;
    category?: string;
    status: number;
    expenseId: string;
    invoiceId?: string;
    totalAmountWithVat?: number;
    expenseDate?: string;
    receivedInvoice?: boolean;
    expenseCategory?: { title: string; expenseCategoryId: string };
    expenseReport?: {
      expenseReportId?: string;
      status?: ReportStatus | string;
    };
    expenseCardTransactions?: {
      cardTransaction: {
        cardTransactionId: string;
      };
    }[];
  };
  onRefreshData?: () => void;
  isExpenseTransaction?: boolean;
  onPress?: (item: any) => void;
}

const ExpenseItem: FC<ExpenseItemProps> = ({ item, onRefreshData, isExpenseTransaction, onPress }) => {
  const { t } = useTranslation("app/components/ExpenseItem");
  const navigation = useNavigation<ExpenseDetailScreenNavigationProp>();
  const handleClickItem = () => {
    if (onPress) {
      onPress();
      return;
    }
    const isCanEdit =
      (!item.expenseReport && item?.status !== EXPENSE_STATUS.READY) ||
      item.expenseReport?.status === ReportStatus.CANCEL ||
      item.expenseReport?.status === ReportStatus.DRAFT ||
      item.expenseReport?.status === ReportStatus.REJECTED;
    if (isCanEdit) {
      analyticService.logEvent({ name: EVENT.EXPENSE.TAP_UPDATE });
      navigation.navigate(SCREEN_NAME.EditExpenseScreen, {
        invoiceId: item?.invoiceId,
        expenseId: item?.expenseId,
        onRefreshData,
      });
      return;
    }
    analyticService.logEvent({ name: EVENT.EXPENSE.TAP_VIEW_DETAIL });
    navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, {
      invoiceId: item?.invoiceId,
      expenseId: item?.expenseId,
      onRefreshData,
    });
  };
  return (
    <>
      <TouchableOpacity style={styles.container} onPress={handleClickItem}>
        <ExpenseItemIcon />
        <View style={styles.contentContainer}>
          {isExpenseTransaction || Boolean(item?.expenseCardTransactions?.[0]) ? (
            <AppText style={styles.expenseFromCardTitle} color={Colors.grayscale60}>
              {t("expense_from_transaction")}
            </AppText>
          ) : null}
          <View style={styles.titleContainer}>
            <AppText style={[Fonts.BodyMedium, styles.flex, { paddingRight: 5 }]} numberOfLines={1}>
              {item?.note || t("need_explanation")}
            </AppText>
            <CurrencyText style={Fonts.NumericN200}>{item?.totalAmountWithVat}</CurrencyText>
          </View>
          <View style={styles.docSequenceContainer}>
            <AppText style={Fonts.BodySmall}>{item?.docSequence}</AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {dayjs(item?.expenseDate).format(CONSTANTS.FORMAT_DAY)}
            </AppText>
          </View>
          <View style={styles.statusContainer}>
            <StatusExpense value={item?.status} />
            <AllocatedStatus value={item?.allocatedStatus} />
          </View>
        </View>
      </TouchableOpacity>
      <View style={styles.divider} />
    </>
  );
};
export default memo(ExpenseItem);
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    gap: 16,
  },
  contentContainer: {
    flex: 1,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 43,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  titleContainer: { flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", flex: 1 },
  flex: { flex: 1 },
  docSequenceContainer: {
    flexDirection: "row",
    marginTop: 3,
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  expenseFromCardTitle: { ...Fonts.BodySmall, marginBottom: 3 },
  statusContainer: { flexDirection: "row", gap: 4, marginTop: 4 },
});
