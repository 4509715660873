export enum CHART_TYPE {
  CATEGORY = "CATEGORY",
  TEAM = "TEAM",
  EMPLOYEE = "EMPLOYEE",
}

export type ExpenseCategory = {
  order?: number;
  code?: string;
  title?: string;
  titleEn?: string | null;
  expenseCategoryId: string;
};

export enum SORT_OPTIONS {
  EXPENSE_DEC = "EXPENSE_DEC",
  EXPENSE_ASC = "EXPENSE_ASC",
  BUDGET_DEC = "BUDGET_DEC",
  BUDGET_ASC = "BUDGET_ASC",
  ALPHABET = "ALPHABET",
}
export type EmployeeItem = {
  avatarUrl?: string;
  defaultAvatarUrl?: string;
  email?: string;
  employeeId: string;
  employeeCode?: string;
  fullname?: string;
};
