import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 25, height = 24, color = "#94A3B8" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 25 24" fill="none">
      <Path
        d="M20.17 11.75h-3.42c-.41 0-.75-.34-.75-.75V4.01c0-.74.29-1.43.81-1.95s1.21-.81 1.95-.81h.01c1.25.01 2.43.5 3.33 1.39.9.91 1.39 2.11 1.39 3.36v2.42c.01 1.99-1.33 3.33-3.32 3.33zm-2.67-1.5h2.67c1.16 0 1.83-.67 1.83-1.83V6c0-.86-.34-1.68-.95-2.3-.61-.6-1.42-.94-2.28-.95h-.01c-.33 0-.65.13-.89.37s-.37.55-.37.89v6.24z"
        fill={color}
      />
      <Path
        d="M9.75 23.33c-.47 0-.91-.18-1.24-.52l-1.66-1.67a.246.246 0 00-.33-.02L4.81 22.4c-.53.4-1.23.47-1.83.17-.6-.3-.97-.9-.97-1.57V6c0-3.02 1.73-4.75 4.75-4.75h12c.41 0 .75.34.75.75s-.34.75-.75.75c-.69 0-1.25.56-1.25 1.25v17c0 .67-.37 1.27-.97 1.57-.59.3-1.3.23-1.83-.17L13 21.12a.243.243 0 00-.32.02L11 22.82c-.34.33-.78.51-1.25.51zm-3.09-3.76c.46 0 .91.17 1.25.52l1.66 1.67c.06.06.14.07.18.07.04 0 .12-.01.18-.07l1.68-1.68c.62-.62 1.6-.68 2.29-.15l1.7 1.27c.11.08.21.05.26.02.05-.03.14-.09.14-.22V4c0-.45.11-.88.3-1.25H6.75C4.53 2.75 3.5 3.78 3.5 6v15c0 .14.09.2.14.23.06.03.16.05.26-.03l1.71-1.28c.31-.23.68-.35 1.05-.35z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
