import { WhiteSpace } from "@ant-design/react-native";
import React, { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetModalCustomMethods,
  IconCustom,
  useSetRelativePosition,
} from "components";
import { Colors, Fonts, FontTypes } from "theme";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { CONSTANTS } from "constants/constants";

export type SelectBottomCustomItem = {
  key?: string | number;
  label: string;
  value: string | number | Record<string, any>;
  leftIcon?: ReactNode;
};

export interface SelectBottomCustomProps {
  style?: ViewStyle;
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  value?: string;
  setPosition?: (y: number) => void;
  containerRef?: any;
  options?: SelectBottomCustomItem[];
  loading?: boolean;
  label?: string;
  snapPoints?: number[] | string[];
  onSelect?: (value: any) => void;
}

const SelectBottomCustom: React.FC<SelectBottomCustomProps> = ({
  style,
  name,
  rules,
  control,
  error,
  setPosition,
  containerRef,
  options,
  loading,
  label,
  snapPoints,
  onSelect,
}) => {
  const textRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef: textRef, setPosition });
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

  const { t } = useTranslation("app/components/InputCustom");

  const handleSelectItem = (item: SelectBottomCustomItem, onChange) => () => {
    bottomSheetRef.current?.close();
    onChange(item.value);
    onSelect?.(item.value);
  };

  const renderItem = ({
    item,
    onChange,
  }: {
    item: SelectBottomCustomItem;
    onChange: (value: SelectBottomCustomItem["value"]) => void;
  }) => {
    return (
      <View>
        <TouchableOpacity onPress={handleSelectItem(item, onChange)} style={styles.wrapperItem}>
          {item?.leftIcon}
          <AppText style={[Fonts.BodyLarge, styles.textItem]}>{item.label}</AppText>
        </TouchableOpacity>
        <View style={styles.divider} />
      </View>
    );
  };
  const renderListEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <WhiteSpace size="md" />
        <IconCustom name="sell-outline" />
        <AppText style={styles.emptyText}>{t("no_result")}</AppText>
      </View>
    );
  };
  const handleOpenModal = () => {
    bottomSheetRef?.current?.present?.();
  };
  const getLabelFromValue = (value) => {
    if (typeof value === "object") {
      return value?.title || value?.name || value?.label;
    }
    return options?.find((i) => i?.value === value)?.label;
  };

  return (
    <View style={[styles.container, style]} ref={textRef}>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <>
            <View>
              <TouchableOpacity
                onPress={handleOpenModal}
                style={[styles.input, Boolean(error) && { borderColor: Colors.alert50 }]}
              >
                {Boolean(value) && <AppText style={styles.label}>{label}</AppText>}
                <AppText
                  style={styles.categoryValue}
                  color={
                    error
                      ? Colors.alert50
                      : value
                      ? CONSTANTS.COMMON.TEXT_INPUT_COLOR
                      : CONSTANTS.COMMON.PLACEHOLDER_COLOR
                  }
                  numberOfLines={1}
                >
                  {value ? getLabelFromValue(value) : label}
                </AppText>
                {value ? (
                  <TouchableOpacity onPress={() => onChange(null)}>
                    <IconCustom name="cancel" />
                  </TouchableOpacity>
                ) : (
                  <IconCustom name="expand-more" />
                )}
              </TouchableOpacity>
              {Boolean(error?.message) && (
                <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                  {error.message?.toString()}
                </AppText>
              )}
            </View>
            <BottomSheetFlatListModalCustom
              loading={loading}
              snapPoints={snapPoints ? snapPoints : undefined}
              title={t("choose", { label: label.toLowerCase() })}
              ref={bottomSheetRef}
              listProps={{
                keyboardDismissMode: "on-drag",
                keyboardShouldPersistTaps: "always",
                showsVerticalScrollIndicator: false,
                data: options,
                keyExtractor: (item, index) => item.key ?? index.toString(),
                ListEmptyComponent: renderListEmptyComponent,
                renderItem: ({ item }) => renderItem({ item, onChange: onChange as any }),
              }}
            />
          </>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginTop: 6 },
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 13,
    flexDirection: "row",
  },
  emptyContainer: {
    flex: 1,
    minHeight: 250,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  emptyText: {
    marginLeft: 8,
    alignSelf: "center",
  },
  wrapperItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 16,
  },
  textItem: { marginLeft: 15, fontSize: 16, flex: 1 },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  categoryValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});

export default SelectBottomCustom;
