import { gql, QueryHookOptions, useLazyQuery, useQuery } from "@apollo/client";

import { MobileReceivedInvoicesQuery, MobileReceivedInvoicesQueryVariables } from "types";

const RECEIVED_INVOICES = gql`
  query MobileReceivedInvoices(
    $invoiceId: UUID
    $companyId: UUID!
    $userId: UUID!
    $limit: NonNegativeInt
    $offset: NonNegativeInt
    $invoiceFilters: ExpInvoicesFilters
  ) {
    expReceivedInvoices(
      input: {
        invoiceId: $invoiceId
        companyId: $companyId
        userId: $userId
        limit: $limit
        offset: $offset
        invoiceFilters: $invoiceFilters
      }
    ) {
      invoices {
        sellerTaxCode
        invoiceId
        invoiceNumber
        sellerLegalName
        sellerAddressLine
        totalAmountWithVat
        receivedAt
        expense {
          expenseId
        }
        invoiceItems {
          invoiceItemId
          itemName
        }
        invoiceValidations {
          isExpenseInvoiceValid
        }
      }
      total
    }
  }
`;

export const useReceivedInvoicesQuery = (
  options?: QueryHookOptions<MobileReceivedInvoicesQuery, MobileReceivedInvoicesQueryVariables>
) => {
  return useLazyQuery(RECEIVED_INVOICES, {
    ...options,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
};
