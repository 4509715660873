import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 24, color = "#7F8A93", ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4a1 1 0 011-1h9a1 1 0 01.707.293l8.087 8.087a2.8 2.8 0 010 3.948l-.002.002-6.453 6.452a2.801 2.801 0 01-3.962 0l-8.084-8.075A1 1 0 013 13V4zm2 1v7.585l7.792 7.784a.8.8 0 001.132 0l6.451-6.451.001-.001a.8.8 0 000-1.127L12.585 5H5z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 10.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
