import { RequestListScreenProps } from "navigation/type";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors } from "theme";
import { HeaderPageBottomTab, IconCustom, SelectActionPicker, TopTabView } from "components";
import React, { FC, useMemo, useRef } from "react";
import { ExpenseRequestListTabs } from "screens/ExpenseRequest/types";
import SCREEN_NAME from "navigation/ScreenName";
import { TouchableOpacity } from "@gorhom/bottom-sheet";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import MyExpenseRequestTab from "screens/ExpenseRequest/ExpenseRequestListScreen/components/MyExpenseRequestTab";
import WaitingApprovalExpenseRequestTab from "screens/ExpenseRequest/ExpenseRequestListScreen/components/WaitingApprovalExpenseRequestTab";
import {
  MyExpenseRequestTabMethods,
  WaitingApprovalExpenseRequestTabMethods,
} from "screens/ExpenseRequest/ExpenseRequestListScreen/types";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useAuth } from "contexts/AuthContext";
import { BizziBotSticker02 } from "assets/images";
import { CopilotSingleStepCustom } from "components/Walkthrough";
import { useMasterData } from "contexts/MasterDataContext";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

const TabIndex = {
  [ExpenseRequestListTabs.WAITING_FOR_APPROVAL]: 0,
  [ExpenseRequestListTabs.MINE]: 1,
};

const TabName = {
  [0]: ExpenseRequestListTabs.WAITING_FOR_APPROVAL,
  [1]: ExpenseRequestListTabs.MINE,
};
const MORE_ACTIONS = (t) => [
  { label: t("approve_all_request"), value: "approve", icon: <IconCustom name="data-check" /> },
  { label: t("history"), value: "history", icon: <IconCustom name="schedule" /> },
];

const ExpenseRequestListScreen: FC<RequestListScreenProps> = ({ navigation, route }) => {
  const { user } = useAuth();
  const routeParams = route.params;
  const {
    setting: { isBatchApproveEnabled },
  } = useMasterData();
  const { top } = useSafeAreaInsets();
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestListScreen");
  const routes = useMemo(
    () => [
      { key: ExpenseRequestListTabs.WAITING_FOR_APPROVAL, title: t("tab_waiting_for_approval") },
      { key: ExpenseRequestListTabs.MINE, title: t("tab_mine") },
    ],
    [t]
  );

  const index = TabIndex[route.params?.tab] ?? 1;
  const onTabPress = (event) => {
    navigation.setParams({ ...route.params, tab: event.route.key });
  };
  const setIndex = (index) => {
    const nextTab = TabName[index];
    if (route.params?.tab !== nextTab) {
      navigation.setParams({ ...route.params, tab: TabName[index] });
    }
  };

  const approvalRequestRef = useRef<MyExpenseRequestTabMethods>();
  const myRequestRef = useRef<WaitingApprovalExpenseRequestTabMethods>();
  const renderScene = ({ route }) => {
    switch (route.key) {
      case ExpenseRequestListTabs.WAITING_FOR_APPROVAL:
        return <WaitingApprovalExpenseRequestTab ref={approvalRequestRef} />;
      case ExpenseRequestListTabs.MINE:
        return <MyExpenseRequestTab routeParams={routeParams} ref={myRequestRef} />;
    }
  };
  const navigateToSearchReportScreen = () => {
    navigation.navigate(SCREEN_NAME.RequestSearchScreen, {
      awaitingMyApproval: index === 0,
      employee:
        index === 0
          ? null
          : {
              email: user?.email,
              employeeId: user?.employee_id,
              fullname: user?.employee?.fullname,
              avatarUrl: user?.avatar,
            },
      onRefreshData: () => {
        index === 0 ? approvalRequestRef?.current?.refetch?.() : myRequestRef?.current?.refetch?.();
      },
    });
  };
  const onClickAction = (action) => {
    if (action === "history") {
      navigation.navigate(SCREEN_NAME.HistoryRequestScreen);
    } else {
      analyticService.logEvent({ name: EVENT.BATCH_APPROVAL.TAPPED });
      navigation.navigate(SCREEN_NAME.RequestBatchApprovalScreen, { sortOption: route?.params?.approvalTabSortOption });
    }
  };
  const renderRightComponent = () => (
    <View style={styles.rightButtonContainer}>
      <TouchableOpacity style={styles.rightButtonIcon} onPress={navigateToSearchReportScreen}>
        <IconCustom name="search" />
      </TouchableOpacity>
      {isBatchApproveEnabled ? (
        <CopilotSingleStepCustom
          featureName={FEATURE_FLAGS.RELEASE_SMP_9191}
          title={"mark_approve_title_feature"}
          text={"mark_approve_description_feature"}
          image={BizziBotSticker02}
          style={styles.rightButtonIcon}
        >
          <SelectActionPicker
            title={t("action")}
            icon={<IconCustom name="more-vert" />}
            options={MORE_ACTIONS(t)}
            onSelect={onClickAction}
          />
        </CopilotSingleStepCustom>
      ) : (
        <TouchableOpacity
          style={styles.rightButtonIcon}
          onPress={() => navigation.navigate(SCREEN_NAME.HistoryRequestScreen)}
        >
          <IconCustom name="schedule" />
        </TouchableOpacity>
      )}
    </View>
  );

  return (
    <View style={[styles.container, { paddingTop: top }]}>
      <HeaderPageBottomTab title={t("page_index_title")} rightComponent={renderRightComponent()} />
      <TopTabView routes={routes} index={index} setIndex={setIndex} onTabPress={onTabPress} renderScene={renderScene} />
    </View>
  );
};

export default ExpenseRequestListScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  rightButtonContainer: { flexDirection: "row" },
  rightButtonIcon: { padding: 10 },
});
