import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { AppText, ToastManager } from "app/components";
import { TouchableOpacity } from "react-native-gesture-handler";
import { WebView } from "components";
import { CloseIcon } from "assets/images/svg/icons";
import { Fonts } from "theme";
import * as authClient from "app/utils/authClient";
import config from "app/config";
import { WebViewProp } from "components/WebView";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { goBack } from "navigation/RootNavigation";
import webViewHelper, { DefinedMessageNames } from "app/utils/webViewHelper";

const UpdateAvatarScreen = () => {
  const { t } = useTranslation("app/screens/ProfileTab/UpdateAvatarScreen");
  const { user, updateUser } = useAuth();
  const [source, setSource] = useState<Partial<WebViewProp>>({});
  useEffect(() => {
    authClient.getTokenPair().then((tokenPair) => {
      setSource({
        headers: {
          Cookie: `${config.ACCOUNT_CONSTANT.TOKEN_KEY_NAME}=${tokenPair.token}`,
        },
        uri: `${config.ACCOUNT_ENDPOINT}/app-expense/personal-info/avatar`,
      });
    });
  }, []);

  const handleWebViewEvent = async (event) => {
    const message = webViewHelper.getMessageFromEvent(event);
    if (!message) {
      return;
    }

    if (message.name === DefinedMessageNames.AvatarUpload) {
      if (message.payload.status === "success") {
        updateUser({ ...user, avatar: message.payload.data.avatarUrl });
        goBack();
        ToastManager.success(t("avatar_uploaded"));
      } else {
        ToastManager.error(t("avatar_upload_failed"));
      }
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <AppText style={[Fonts.H300, styles.title]}>Tài khoản Bizzi</AppText>
        <TouchableOpacity onPress={() => goBack()}>
          <CloseIcon />
        </TouchableOpacity>
      </View>

      <WebView
        uri={source.uri}
        headers={source.headers}
        webViewProps={{
          incognito: true,
          onMessage: handleWebViewEvent,
        }}
      />
    </View>
  );
};

export default UpdateAvatarScreen;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  title: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
    flex: 1,
  },
});
