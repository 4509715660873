import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={20} height={20}>
        <Path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M4.424 17.085c-.42 0-.777-.146-1.069-.438a1.454 1.454 0 01-.437-1.069V4.425c0-.421.146-.778.437-1.07a1.454 1.454 0 011.07-.437h7.212l-1.25 1.25H4.424a.245.245 0 00-.176.08.245.245 0 00-.08.177v11.153c0 .064.027.123.08.177.053.053.112.08.176.08h11.154a.245.245 0 00.177-.08.245.245 0 00.08-.177V9.562l1.25-1.25v7.266c0 .421-.146.778-.438 1.07a1.454 1.454 0 01-1.069.437H4.424zm3.494-5.754V9.848c0-.201.039-.394.117-.58.078-.185.185-.346.322-.483l7.11-7.11c.13-.13.272-.224.427-.283a1.313 1.313 0 01.944 0c.15.059.288.15.412.275l1.046 1.043c.12.129.213.272.278.428.065.156.097.314.097.474 0 .16-.027.316-.082.466-.055.151-.148.29-.277.42l-7.135 7.135a1.585 1.585 0 01-.483.328 1.413 1.413 0 01-.579.124H8.671a.73.73 0 01-.537-.216.73.73 0 01-.216-.537zm1.25-.496h1.038L15.4 5.64l-.519-.52-.556-.535-5.157 5.157v1.092z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
