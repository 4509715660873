import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { MobileApprovalTypeQuery, MobileApprovalTypeQueryVariables } from "types";

const EXPENSE_APPROVAL_TYPE = gql`
  query MobileApprovalType($type: String) {
    expenseApprovalType: expApprovalType(type: $type) {
      approvalType
      withApprovalReason
    }
  }
`;

export const useExpApprovalTypeQuery = (
  options?: QueryHookOptions<MobileApprovalTypeQuery, MobileApprovalTypeQueryVariables>
) => {
  return useQuery(EXPENSE_APPROVAL_TYPE, { ...options, fetchPolicy: "cache-and-network" });
};
