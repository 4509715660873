import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

const NotFound = ({ route }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>Not Found</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 20,
  },
});

export default NotFound;
