import { StyleSheet } from "react-native";

const FontTypes = {
  regular: "Inter", //fontWeight: 400
  medium: "Inter-Medium", //fontWeight: 500
  semiBold: "Inter-SemiBold", //fontWeight: 600
  bold: "Inter-Bold", //fontWeight: 700
};

const Colors = {
  while: "#FFFFFF",
  black: "#000000",
  brand100: "#062D77",
  primary100: "#1E40AF",
  primary50: "#1F71F4",
  primary20: "#60A5FA",
  primary10: "#BFDBFE",
  primary05: "#DCEBFE",
  primary0: "#F0F6FF",
  purple05: "#F0EBFF",
  purple50: "#A98EF6",
  grayscale100: "#0F172A",
  grayscale80: "#475569",
  grayscale70: "#73788B",
  grayscale50: "#595959",
  grayscale60: "#94A3B8",
  grayscale40: "#CBD5E1",
  grayscale12: "#DFE1E6",
  grayscale10: "#E2E8F0",
  grayscale05: "#F1F5F9",
  grayscale0: "#F8FAFC",
  alert100: "#9F1239",
  alert50: "#E11934",
  alert20: "#FE4D65",
  alert10: "#F9D1D6",
  alert05: "#FFE4E6",
  alert0: "#FFE4E6",
  info100: "#1E40AF",
  info50: "#1F71F4",
  info05: "#60A5FA",
  info0: "#cce6ff",
  warning100: "#78350F",
  warning50: "#F59E0B",
  warning05: "#FCD34D",
  warning02: "#FDE68A",
  warning0: "#FEF3C7",
  success100: "#2B8A3E",
  success50: "#37B24D",
  success05: "#69DB7C",
  success02: "#D3F9D8",
  success0: "#E1F9E6",
  dataOrange: "#FFC078",
  dataYellow: "#F7CF5E",
  dataLime: "#B0D274",
  dataGreen: "#7DCF89",
  dataTeal: "#6EDDBB",
  dataCyan: "#94DEE8",
  dataBlue: "#66B9FA",
  dataIndigo: "#859EFE",
  dataViolet: "#A98EF6",
  dataGrape: "#D181E4",
  dataPink: "#FB83AE",
  dataRed: "#FF8585",
  dataGray: "#D1D5D9",
  brand10: "#9BABC9",
  white: "#FFFFFF",
  activeTintColor: "#0052CC",
  inactiveTintColor: "#42526E",
  ebony: "#0F172A",
  blackColor200: "rgba(0,0,0,0.2)",
};

const Fonts = StyleSheet.create({
  H900: {
    fontFamily: FontTypes.semiBold,
    fontSize: 72,
    lineHeight: 80,
  },
  H800: {
    fontFamily: FontTypes.semiBold,
    fontSize: 56,
    lineHeight: 64,
  },
  H700: {
    fontFamily: FontTypes.semiBold,
    fontSize: 40,
    lineHeight: 48,
  },
  H600: {
    fontFamily: FontTypes.semiBold,
    fontSize: 32,
    lineHeight: 40,
  },
  H550: {
    fontFamily: FontTypes.semiBold,
    fontSize: 30,
    lineHeight: 38,
  },
  H500: {
    fontFamily: FontTypes.bold,
    fontSize: 24,
    lineHeight: 32,
  },
  H400: {
    fontFamily: FontTypes.bold,
    fontSize: 20,
    lineHeight: 28,
  },
  H300: {
    fontFamily: FontTypes.bold,
    fontSize: 16,
    lineHeight: 24,
  },
  H200: {
    fontFamily: FontTypes.bold,
    fontSize: 14,
    lineHeight: 20,
  },
  H100: {
    fontFamily: FontTypes.bold,
    fontSize: 12,
    lineHeight: 16,
  },
  Subtitle: {
    fontFamily: FontTypes.medium,
    fontSize: 20,
    lineHeight: 32,
  },
  BodyLarge: {
    fontFamily: FontTypes.medium,
    fontSize: 16,
    lineHeight: 24,
  },
  BodyMedium: {
    fontFamily: FontTypes.medium,
    fontSize: 14,
    lineHeight: 20,
  },
  BodyRegular: {
    fontFamily: FontTypes.regular,
    fontSize: 14,
    lineHeight: 20,
  },
  BodySmall: {
    fontFamily: FontTypes.medium,
    fontSize: 12,
    lineHeight: 16,
  },
  BodyXSmall: {
    fontFamily: FontTypes.medium,
    fontSize: 10,
    lineHeight: 16,
  },
  NumericN100: {
    fontFamily: FontTypes.bold,
    fontSize: 12,
    lineHeight: 16,
  },
  NumericN200: {
    fontFamily: FontTypes.bold,
    fontSize: 14,
    lineHeight: 20,
  },
  NumericN300: {
    fontFamily: FontTypes.bold,
    fontSize: 16,
    lineHeight: 24,
  },
  NumericN500: {
    fontFamily: FontTypes.bold,
    fontSize: 24,
    lineHeight: 32,
  },
  Caption: {
    fontFamily: FontTypes.regular,
    fontSize: 12,
    lineHeight: 16,
  },
  NumericN400: {
    fontFamily: FontTypes.bold,
    fontSize: 20,
    lineHeight: 28,
  },
  SentenceSubtileXLarge: {
    fontFamily: FontTypes.semiBold,
    fontSize: 18,
    lineHeight: 26,
  },
  SentenceSubtitleXLarge: {
    fontFamily: FontTypes.semiBold,
    fontSize: 18,
    lineHeight: 26,
  },
  SentenceSubtitleLarge: {
    fontFamily: FontTypes.semiBold,
    fontSize: 16,
    lineHeight: 28,
  },
  SentenceBodyMedium: {
    fontFamily: FontTypes.medium,
    fontSize: 14,
    lineHeight: 20,
  },
  SentenceSubTitleMedium: {
    fontFamily: FontTypes.semiBold,
    fontSize: 14,
    lineHeight: 30,
  },
  SentenceSubTitleXLarge: {
    fontFamily: FontTypes.semiBold,
    fontSize: 18,
    lineHeight: 26,
  },
  SentenceBodyXLarge: {
    fontFamily: FontTypes.medium,
    fontSize: 18,
    lineHeight: 24,
  },
  SentenceCaptionMedium: {
    fontFamily: FontTypes.medium,
    fontSize: 10,
    lineHeight: 18,
  },
  SentenceCaptionLarge: {
    fontFamily: FontTypes.regular,
    fontSize: 12,
    lineHeight: 16,
  },
});
export { Colors, Fonts, FontTypes };
