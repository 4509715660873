import { useCallback, memo } from "react";
import { ImageBackground, TouchableHighlight, View, StyleSheet } from "react-native";
import { WIDTH_WINDOW } from "app/constants/Layout";

const ImageItem = ({ uri, index, selected, selectImage, selectedItemNumber, renderSelectedComponent }) => {
  const onPress = useCallback(() => {
    selectImage(index);
  }, [selectImage, index]);

  if (!uri) {
    return null;
  }

  return (
    <TouchableHighlight style={{ opacity: selected ? 0.5 : 1 }} underlayColor="transparent" onPress={onPress}>
      <View style={styles.wrapperImage}>
        <View style={styles.wrapperImage}>
          <ImageBackground style={styles.backgroundImageStyle} source={{ uri }}>
            {selected && renderSelectedComponent && renderSelectedComponent(selectedItemNumber)}
          </ImageBackground>
        </View>
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  wrapperImage: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  backgroundImageStyle: {
    width: WIDTH_WINDOW / 4,
    height: WIDTH_WINDOW / 4,
  },
});

export default memo(ImageItem);
