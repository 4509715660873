import { AppText, IconCustom } from "components/index";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface InvoiceStatusProps {
  isValid?: boolean;
}
const InvoiceStatus: FC<InvoiceStatusProps> = ({ isValid }) => {
  const { t } = useTranslation("app/screens/Inbox/InboxScreen");
  return (
    <View style={styles.container}>
      {isValid ? <IconCustom name="verify-user" /> : <IconCustom name="gpp-maybe" />}
      <AppText style={Fonts.BodySmall} color={isValid ? Colors.success100 : Colors.alert50}>
        {isValid ? t("valid_invoice") : t("invalid_invoice")}
      </AppText>
    </View>
  );
};
export default InvoiceStatus;
const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center", gap: 4 },
});
