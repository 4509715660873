// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { TRACKING } from "constants/Firebase";
import dayjs from "dayjs";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, RefreshControl, StatusBar, StyleSheet, View } from "react-native";
import Animated, { useAnimatedScrollHandler, useSharedValue } from "react-native-reanimated";
import { BizziBotWarning2 } from "assets/images/svg/icons";
import { AlertNotification, AlertNotificationHandle, useWindowDimensions } from "components";
import { Keys } from "constants/Keys";
import { useAuth } from "contexts/AuthContext";
import useUserSettingMutation from "hooks/useUserSettingMutation";
import { Colors } from "theme";
import { convertDateToISOFormat, isUserMod } from "utils";
import ExpenseList from "./components/ExpenseList";
import HeaderWelcome from "./components/HeaderWelcome";
import MenuSection from "./components/MenuSection";
import ReportCard from "./components/ReportCard";
import useLatestExpenseQuery from "./hooks/useLatestExpenseQuery";
import HeaderPage from "./components/HeaderPage";
import WelcomeModal from "./components/WelcomeModal";
import * as Analytics from "app/utils/analytics";
import CampaignList from "screens/Home/components/CampaignList";
import { CONSTANTS } from "constants/constants";
import useWaitingApprovalObjectsAggregate from "screens/WaitingApproval/hooks/useWaitingApprovalObjectsAggregate";
import * as Notifications from "expo-notifications";
import useUnSeenNotificationsQuery from "screens/Home/hooks/useUnSeenNotificationsQuery";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { navigationRef } from "navigation/RootNavigation";
import UserGuideVideo from "./components/UserGuideVideo/UserGuideVideo";
import ConfettiAnimation, {
  ConfettiAnimationMethods,
} from "screens/Card/CardListScreen/components/CardOpeningProcess/ConfettiAnimation";
import { useCopilot } from "react-native-copilot";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";

const DAYS_TO_QUERY_EXPENSES = 30;
const FROM_DATE = convertDateToISOFormat(dayjs().subtract(DAYS_TO_QUERY_EXPENSES, "day"));
const HomeScreen = ({ route }) => {
  const { t, i18n } = useTranslation("app/screens/Home/HomeScreen");
  const navigation = useNavigation();
  const confettiAnimation = useRef<ConfettiAnimationMethods>();

  const { height } = useWindowDimensions();
  const translationY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler((event) => {
    translationY.value = event.contentOffset.y;
  });

  const campaign = useRef();
  const campaignListRef = useRef();
  const reportCardRef = useRef();
  const reportCardForMobRef = useRef();
  const scrollViewRef = useRef();
  const verifyBottomSheetRef = useRef<AlertNotificationHandle>(null);
  const promotionTrackingHistory = useRef([]);
  const [visibleUserGuideModal, setVisibleUserGuideModal] = useState(false);
  const [isViewVideoGuide, setIsViewVideoGuide] = useState(false);
  const {
    user,
    company: { company_id: companyId },
  } = useAuth();
  const { updateMySetting } = useUserSettingMutation();
  const { fetchData: fetchTotalWaitingApproval, data: totalWaitingApproval } = useWaitingApprovalObjectsAggregate({
    condition: {
      companyId: companyId,
      employeeId: user?.employee_id,
    },
  });
  const { fetchData, data, loading } = useLatestExpenseQuery({
    companyId: companyId,
    userId: user?.id,
    employeeId: user?.employee_id,
    from: FROM_DATE,
  });
  const onRefreshData = () => {
    fetchData?.();
    fetchTotalWaitingApproval?.();
    refetchNotificationCount?.();
  };
  const { copilotEvents, currentStep, isLastStep: isLastStepCopilot, isFirstStep } = useCopilot();
  const { handleFinishGuideAction } = useWalkthroughGuide();

  /*-- start walkthrough flow --*/
  const { shouldShowWalkthrough = false, walkthroughName } = route?.params ?? {};
  const { start, steps, totalStep } = useCopilot();
  const { currentAction, currentChildrenActionCode } = useWalkthroughGuide();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) => steps[key].walkthroughName === walkthroughName && steps[key].order === 0
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          suppliedScrollView: scrollViewRef.current,
          step,
          walkthroughName,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [route?.params, stepNameToStartWalkthrough]);

  /*-- end --*/
  useEffect(() => {
    const listener = () => {
      // check and show face id & touch id authentication modal when finish walkthrough
      // only show confetti when it is onboarding walkthrough flow
      const isLastStep = isLastStepCopilot && !isFirstStep;
      if (
        isLastStep &&
        !currentStep?.singleStep &&
        currentStep?.walkthroughName !== CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME &&
        currentStep?.walkthroughName !== CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME &&
        currentStep?.walkthroughName !== CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME
      ) {
        handleFinishGuideAction();
        if (currentAction) {
          const currentActionChildren = currentAction.childrenActions.find(
            (item) => item.type === "walkthrough" && item.walkthroughName === currentChildrenActionCode
          );
          analyticService.logEvent({
            name: EVENT.WALKTHROUGH.FINISHED_ONBOARDING_COURSE_ACTION,
            properties: {
              course_name: currentAction.title,
              action_name: currentActionChildren?.title,
              total_step: currentStep?.totalStep ?? totalStep,
            },
          });
        }
      }
      // if (currentStep?.walkthroughName === CONSTANTS.WALK_THROUGH.OVERVIEW.NAME) {
      //   confettiAnimation?.current?.show();
      // }
    };
    copilotEvents.on("stop", listener);
    return () => {
      copilotEvents.off("stop", listener);
    };
  }, [currentStep, currentAction]);
  const { expenses } = {
    expenses: data?.expUnClaimedExpenses?.expenses ?? [],
  };
  const { data: notificationCountNovu, refetch: refetchNotificationCount } = useUnSeenNotificationsQuery();

  /*-- view user guide onboarding flow --*/
  useEffect(() => {
    if (route?.params?.shouldShowWalkthrough) {
      scrollViewRef?.current?.scrollTo({
        y: 0,
        animated: false,
      });
    }
  }, [route]);
  /*-- end --*/

  /*-- handle refresh data home screen when received notification --*/
  const notificationListener = useRef(null);
  useEffect(() => {
    /*-- handle refresh data home screen when received notification --*/
    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      if (notification?.request?.content?.data) {
        onRefreshData();
      }
    });
    /*-- end --*/
    const focusUnsubscribe = navigation.addListener("focus", () => {
      setTimeout(() => {
        StatusBar.setBarStyle("light-content");
      }, 250);
      onRefreshData();
      if (isUserMod(user)) {
        reportCardForMobRef?.current?.onRefresh();
      }
      reportCardRef?.current?.onRefresh();
    });
    const blurUnsubscribe = navigation.addListener("blur", () => {
      StatusBar.setBarStyle("dark-content");
    });

    return () => {
      focusUnsubscribe();
      blurUnsubscribe();
      Notifications.removeNotificationSubscription(notificationListener.current);
    };
  }, []);

  useEffect(() => {
    if (campaignListRef.current?.boundingRect) {
      return;
    }

    if (scrollViewRef.current && campaignListRef.current) {
      campaignListRef.current.measureLayout(scrollViewRef.current, (x, y, width, height) => {
        if (campaignListRef.current) {
          campaignListRef.current.boundingRect = { x, y, width, height };
        }
      });
    }
  });

  useLayoutEffect(() => {
    if (i18n) {
      try {
        updateMySetting({ language: i18n.language });
      } catch (error) {}
    }
  }, [i18n.language]);

  const onRefresh = () => {
    if (Platform.OS !== "web") {
      Analytics?.logEvent({ name: TRACKING.EVENTS.HOME_SCREEN.PULL_REFRESH });
    }
    onRefreshData();
    if (isUserMod(user)) {
      reportCardForMobRef?.current?.onRefresh();
    }
    reportCardRef?.current?.onRefresh();
  };
  const checkInformAboutLocalAuth = async () => {
    const isNoticed = await AsyncStorage.getItem(Keys.FLAG_FIRST_INFORM_ABOUT_LOCAL_AUTH);
    if (!isNoticed) {
      verifyBottomSheetRef.current?.confirm({
        icon: <BizziBotWarning2 />,
        title: t("dialog_title_local_authentication"),
        description: t("dialog_title_content_authentication"),
        cancelText: t("dialog_button_ignore_local_authentication"),
        confirmText: t("dialog_button_agree_local_authentication"),
        onConfirm: () => {
          verifyBottomSheetRef.current?.close();
          navigation.navigate(SCREEN_NAME.LocalAuthenticationScreen, {});
          AsyncStorage.setItem(Keys.FLAG_FIRST_INFORM_ABOUT_LOCAL_AUTH, "true");
        },
        onCancel: () => {
          verifyBottomSheetRef.current?.close();
          AsyncStorage.setItem(Keys.FLAG_FIRST_INFORM_ABOUT_LOCAL_AUTH, "true");
        },
      });
    }
  };
  const onSwitchCompany = useCallback(() => {
    navigation.navigate(SCREEN_NAME.SwitchCompanyScreen);
  }, [navigation]);

  const onSnapToItem = useCallback(
    (item) => {
      campaign.current = item;
      if (!isViewVideoGuide) {
        handleTrackingCampaign();
      }
    },
    [isViewVideoGuide]
  );

  const handleTrackingCampaign = () => {
    const currentCampaign = campaign.current;
    const boundingRect = campaignListRef.current?.boundingRect;
    const trackingHistory = promotionTrackingHistory.current;
    const screenName = navigationRef.current?.getCurrentRoute()?.name;
    if (screenName !== SCREEN_BOTTOM_TAB.Home) {
      return;
    }

    if ([currentCampaign, boundingRect, trackingHistory].some((i) => !i)) {
      return;
    }

    // 70 is the height of bottom menu
    const widowsHeight = height - 70;

    // skip if campaign is not visible or already tracked
    const isVisible = widowsHeight + translationY.value >= boundingRect.y + boundingRect.height;
    const isTracked = trackingHistory.some((id) => id === currentCampaign.id);
    if (isTracked || !isVisible) {
      return;
    }

    trackingHistory.push(currentCampaign.id);
    analyticService.logEvent({
      name: EVENT.HOME.VIEW_BANNER,
      properties: {
        campaign_title: currentCampaign.title,
        campaign_id: currentCampaign.id,
        promotion_url: currentCampaign.promotion_url,
      },
    });
  };

  return (
    <View style={styles.container}>
      <HeaderPage
        notificationCount={notificationCountNovu?.ntfUnseenNotificationsCount?.count}
        translationY={translationY}
      />
      <Animated.ScrollView
        scrollEventThrottle={16}
        onScroll={scrollHandler}
        onScrollEndDrag={handleTrackingCampaign}
        ref={scrollViewRef}
        refreshControl={<RefreshControl tintColor="#FFFFFF" refreshing={false} onRefresh={onRefresh} />}
        contentContainerStyle={styles.wrapper}
        showsVerticalScrollIndicator={false}
      >
        <HeaderWelcome translationY={translationY} onSwitchCompany={onSwitchCompany} />
        <View>
          <View style={styles.reportCardContainer}>
            <ReportCard ref={reportCardRef} />
          </View>
          <MenuSection totalWaitingApproval={totalWaitingApproval?.total} />
          <CampaignList ref={campaignListRef} onSnapToItem={onSnapToItem} />
          <UserGuideVideo setIsViewVideoGuide={setIsViewVideoGuide} />
          <ExpenseList onRefreshData={onRefreshData} loading={loading} data={expenses || []} />
        </View>
      </Animated.ScrollView>
      {Platform.OS !== "web" && (
        <WelcomeModal
          visible={visibleUserGuideModal}
          setVisible={setVisibleUserGuideModal}
          checkInformAboutLocalAuth={checkInformAboutLocalAuth}
        />
      )}
      <AlertNotification ref={verifyBottomSheetRef} />
      <ConfettiAnimation ref={confettiAnimation} />
    </View>
  );
};
export default HomeScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  wrapper: {
    paddingBottom: 80,
  },
  reportCardContainer: { marginTop: -80, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
});
