import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";

import { AppText } from "components";
import { EXPENSE_REPORT_STATUS } from "constants/constants";

const REPORT_STATUS = {
  [EXPENSE_REPORT_STATUS.DRAFT]: {
    text: "report_status_draft",
    backgroundColor: Colors.grayscale10,
  },
  [EXPENSE_REPORT_STATUS.SUBMITTED]: {
    text: "report_status_awaiting",
    backgroundColor: Colors.primary05,
  },
  [EXPENSE_REPORT_STATUS.REJECTED]: {
    text: "report_status_reject",
    backgroundColor: Colors.alert05,
  },
  [EXPENSE_REPORT_STATUS.AWAITING_ACCOUNTANT_APPROVAL]: {
    text: "report_status_awaiting",
    backgroundColor: Colors.warning0,
  },
  [EXPENSE_REPORT_STATUS.APPROVED]: {
    text: "report_status_approve",
    backgroundColor: Colors.success0,
  },
  [EXPENSE_REPORT_STATUS.CANCEL]: {
    text: "report_status_cancel",
    backgroundColor: Colors.grayscale05,
  },
};

interface ReportStatusProps {
  value: EXPENSE_REPORT_STATUS;
}

const ReportStatus: FC<ReportStatusProps> = ({ value }) => {
  const { t } = useTranslation("app/screens/Report/ReportListScreen");
  if (!REPORT_STATUS[value]) {
    return null;
  }
  return (
    <View style={[styles.container, { backgroundColor: REPORT_STATUS[value]?.backgroundColor }]}>
      <AppText style={Fonts.BodySmall}>{t(REPORT_STATUS[value]?.text)}</AppText>
    </View>
  );
};

export default memo(ReportStatus);
const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
  },
});
