import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 135, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 135 135" fill="none" {...props}>
      <Path fill="#fff" d="M0 0h135v135H0z" />
      <Path
        d="M113.906 128.697H21.094C9.463 128.697 0 119.235 0 107.604 0 95.972 9.463 86.51 21.094 86.51h92.812c11.631 0 21.094 9.462 21.094 21.094 0 11.631-9.463 21.093-21.094 21.093z"
        fill="#E6F4FF"
      />
      <Path
        d="M54.316 114.195a6.591 6.591 0 10.001-13.183 6.591 6.591 0 000 13.183zM27.95 114.195a6.591 6.591 0 100-13.183 6.591 6.591 0 000 13.183z"
        fill="#7F8A93"
      />
      <Path
        d="M113.906 86.51H67.5v42.187h46.406c11.631 0 21.094-9.462 21.094-21.093 0-11.632-9.463-21.094-21.094-21.094z"
        fill="#B3DCFF"
      />
      <Path
        d="M80.684 114.195a6.592 6.592 0 100-13.183 6.592 6.592 0 000 13.183zM107.051 114.195a6.592 6.592 0 100-13.184 6.592 6.592 0 000 13.184z"
        fill="#4D5C6A"
      />
      <Path
        d="M67.5 6.592H39.73a1.931 1.931 0 00-1.931 1.931v62.056c0 1.066.864 1.93 1.931 1.93H67.5a1.931 1.931 0 001.931-1.93V8.523a1.931 1.931 0 00-1.93-1.931z"
        fill="#33A1FF"
      />
      <Path
        d="M67.5 6.592H53.615V72.51H67.5a1.931 1.931 0 001.932-1.931V8.523A1.931 1.931 0 0067.5 6.592z"
        fill="#006DCC"
      />
      <Path
        d="M95.27 24.553H67.5a1.931 1.931 0 00-1.931 1.93v44.095c0 1.067.864 1.931 1.931 1.931h27.77a1.931 1.931 0 001.931-1.93V26.483a1.931 1.931 0 00-1.93-1.931z"
        fill="#33A1FF"
      />
      <Path
        d="M95.27 24.553H81.385V72.51H95.27a1.931 1.931 0 001.931-1.932V26.484a1.931 1.931 0 00-1.931-1.931z"
        fill="#006DCC"
      />
      <Path
        d="M58.655 15.129h-10.08a1.931 1.931 0 00-1.931 1.931v9.464c0 1.066.865 1.931 1.931 1.931h10.08a1.931 1.931 0 001.93-1.931V17.06a1.931 1.931 0 00-1.93-1.931zM58.655 33.107h-10.08a1.931 1.931 0 00-1.931 1.932v9.463c0 1.067.865 1.932 1.931 1.932h10.08a1.931 1.931 0 001.93-1.931v-9.464a1.931 1.931 0 00-1.93-1.932zM58.655 51.62h-10.08a1.931 1.931 0 00-1.931 1.93V70.58h13.942V53.55a1.931 1.931 0 00-1.931-1.931zM86.425 33.107h-10.08a1.931 1.931 0 00-1.93 1.932v9.463c0 1.067.864 1.932 1.93 1.932h10.08a1.931 1.931 0 001.931-1.931v-9.464a1.931 1.931 0 00-1.931-1.932zM86.425 51.62h-10.08a1.931 1.931 0 00-1.93 1.93V70.58h13.941V53.55a1.931 1.931 0 00-1.931-1.931z"
        fill="#E6F4FF"
      />
      <Path
        d="M58.655 51.62h-5.04v18.959h6.971V53.55a1.931 1.931 0 00-1.931-1.931zM58.655 33.107h-5.04v13.327h5.04a1.931 1.931 0 001.931-1.931v-9.464a1.931 1.931 0 00-1.931-1.932zM58.655 15.129h-5.04v13.326h5.04a1.931 1.931 0 001.931-1.931V17.06a1.931 1.931 0 00-1.931-1.931zM86.424 51.62h-5.04v18.959h6.972V53.55a1.931 1.931 0 00-1.932-1.931zM86.424 33.107h-5.04v13.327h5.04a1.931 1.931 0 001.932-1.931v-9.464a1.931 1.931 0 00-1.932-1.932z"
        fill="#B3DCFF"
      />
      <Path d="M98.528 68.647H36.472a1.931 1.931 0 000 3.863h62.056a1.931 1.931 0 000-3.863z" fill="#E6E8EA" />
      <Path d="M98.528 68.647H67.5v3.863h31.028a1.931 1.931 0 000-3.863z" fill="#B3BABF" />
    </Svg>
  );
}

export default SvgComponent;
