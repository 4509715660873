import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const MUTATION = gql`
  mutation MobileCrdAssignTransactionEmployee($companyId: UUID!, $cardTransactionId: UUID!, $expenseIds: [UUID!]!) {
    expAssignExpensesCardTransaction(
      input: { companyId: $companyId, cardTransactionId: $cardTransactionId, expenseIds: $expenseIds }
    ) {
      affectedRows
    }
  }
`;
const useAssignExpensesCardTransactionMutation = () => {
  return useMutation(MUTATION);
};

export default useAssignExpensesCardTransactionMutation;
