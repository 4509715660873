import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 24, color = "#7F8A93", ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path d="M7 10.5a1 1 0 100 2h5.5a1 1 0 100-2H7zM6 15.5a1 1 0 011-1h10a1 1 0 110 2H7a1 1 0 01-1-1z" fill={color} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2a1 1 0 011 1v1h8V3a1 1 0 112 0v1h3a2 2 0 012 2v13a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h3V3a1 1 0 011-1zm9 4v1a1 1 0 102 0V6h3v13H3V6h3v1a1 1 0 002 0V6h8z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
