import React, { FC, useState, useRef, useMemo } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { isNumber } from "lodash";

import { Colors, Fonts, FontTypes } from "theme";
import { LineChart } from "react-native-chart-kit";
import { formatMoney, formatMoneyString } from "utils";
import { AppText, useWindowDimensions } from "components";
import dayjs from "dayjs";
import { ExpMobileInsightsQuery } from "types";

type ExpenseChartProps = {
  data: ExpMobileInsightsQuery["expMobileInsights"]["monthlyApprovedExpenses"];
  selectedIndex: number | null;
  setSelectedIndex?: (index: number) => void;
};

const ExpenseChart: FC<ExpenseChartProps> = ({ data, selectedIndex, setSelectedIndex }) => {
  const chartData = useMemo(() => {
    if (!data) {
      return null;
    }

    const labels = [];
    const dataset = {
      data: [],
      strokeWidth: 2,
    };
    data.forEach((expense) => {
      dataset.data.push(expense.totalAmount);
      labels.push(dayjs(expense.month).startOf("month").format("MM/YY"));
    });
    return {
      labels,
      datasets: [dataset],
    };
  }, [data]);

  const [_, forceRender] = useState({});
  const { width } = useWindowDimensions();
  const layoutRect = useRef(null);

  const handleSelect = (i: number) => {
    setSelectedIndex(i);
  };

  const formatYLabel = (value) => {
    if (!Number(value)) {
      return "0";
    }
    return formatMoneyString(value).replace(/,\d+/, "");
  };

  const onDataPointClick = (data: {
    index: number;
    value: number;
    dataset: unknown;
    x: number;
    y: number;
    getColor: (opacity: number) => string;
  }) => {
    handleSelect(data.index);
  };

  const getDotProps = (dataPoint: any, index: number) => {
    return {
      r: 3,
      strokeWidth: 2,
      stroke: Colors.primary50,
      fill: index === selectedIndex ? Colors.primary50 : Colors.white,
    };
  };

  const renderTooltip = ({ paddingRight, horizontalOffset, data: chartData, width, height, paddingTop }) => {
    if (!isNumber(selectedIndex) || !chartData?.[0]) {
      return null;
    }
    const data = chartData[0].data;
    const text = `${formatMoney(Math.floor(data[selectedIndex]))} ₫`;

    const cx = paddingRight + horizontalOffset + selectedIndex * ((width - paddingRight - 2 * horizontalOffset) / 5);
    const calcScaler = Math.max(...data, 0) - Math.min(...data, 0) || 1;
    const calcHeight = height * (data[selectedIndex] / calcScaler);

    const cy = ((height - calcHeight) / 4) * 3 + paddingTop;

    const baseCharacterWidth = 6;
    const labelHorizontalPadding = 4;
    const labelWidth = text.length * baseCharacterWidth + 2 * labelHorizontalPadding;

    const labelVerticalPadding = 4;
    const labelHeight = Fonts.BodyXSmall.lineHeight + 2 * labelVerticalPadding;

    if (Platform.OS === "web") {
      const marginBottom = 4;
      return (
        <foreignObject
          x={selectedIndex === data.length - 1 ? cx - labelWidth : cx - labelWidth / 2}
          y={cy - labelHeight - marginBottom}
          width={labelWidth}
          height={labelHeight}
        >
          <div
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              alignItems: "center",
              textAlign: "center",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: Colors.grayscale10,
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 4,
              paddingRight: 4,
              backgroundColor: Colors.white,
              fontFamily: "Inter-Medium",
              fontSize: 10,
            }}
          >
            {text}
          </div>
        </foreignObject>
      );
    } else {
      const marginBottom = 8;

      return (
        <View
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            position: "absolute",
            top: cy - labelHeight - marginBottom,
            alignItems: "center",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: Colors.grayscale10,
            paddingVertical: 2,
            paddingHorizontal: 4,
            backgroundColor: Colors.white,
            ...(layoutRect.current
              ? {
                  opacity: 1,
                  left:
                    selectedIndex === data.length - 1
                      ? cx - layoutRect.current.width
                      : cx - layoutRect.current.width / 2,
                }
              : {
                  opacity: 0,
                  left: cx,
                }),
          }}
          onLayout={({ nativeEvent: { layout } }) => {
            layoutRect.current = layoutRect.current ? undefined : layout;
            if (layoutRect.current) {
              forceRender({});
            }
          }}
        >
          <AppText style={Fonts.BodyXSmall}>{text}</AppText>
        </View>
      );
    }
  };

  return (
    <View>
      {chartData && (
        <>
          <LineChart
            bezier
            key="expenseInsight:ExpenseChart"
            data={chartData}
            width={width - 20}
            height={220}
            horizontalOffset={24}
            xLabelsOffset={0}
            yLabelsOffset={8}
            fromZero={true}
            transparent={true}
            withVerticalLines={false}
            withOuterLines={true}
            getDotColor={() => Colors.white}
            onDataPointClick={onDataPointClick}
            getDotProps={getDotProps}
            formatYLabel={formatYLabel}
            getXLabelStyle={(index: number) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { fontFamily, ...styles } = Fonts.BodyXSmall;
              const isSelected = index === selectedIndex;
              if (!isSelected) {
                return Platform.OS === "web" ? Fonts.BodyXSmall : styles;
              }

              return Platform.OS === "web"
                ? { ...Fonts.BodyXSmall, fontFamily: FontTypes.bold }
                : { ...styles, fontWeight: "bold" };
            }}
            getYLabelStyle={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { fontFamily, ...styles } = Fonts.BodyXSmall;
              return Platform.OS === "web" ? Fonts.BodyXSmall : styles;
            }}
            decorator={renderTooltip}
            style={styles.svgContainer}
            chartConfig={{
              color: () => Colors.primary50,
              labelColor: () => Colors.grayscale80,
              useShadowColorFromDataset: false,
              fillShadowGradientFrom: Colors.primary50,
              fillShadowGradientTo: Colors.primary50,
              propsForBackgroundLines: {
                strokeDasharray: "0",
                stroke: Colors.grayscale05,
                strokeWidth: 1,
              },
            }}
          />
        </>
      )}
    </View>
  );
};

export default ExpenseChart;
const styles = StyleSheet.create({
  svgContainer: {
    paddingTop: 28,
    paddingRight: 40 + 20,
  },
});
