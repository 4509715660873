import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileExpSearchAirportsQuery, MobileExpSearchAirportsQueryVariables } from "types";

const QUERY = gql`
  query MobileExpSearchAirports($where: ExpSearchAirportsWhereInput!) {
    expSearchAirports(where: $where) {
      airports {
        airportId
        locationCode
        code
        name
        nameEn
        searchText
        location {
          name
          nameEn
          locationId
        }
      }
    }
  }
`;

const useExpSearchAirPorts = (variables?: MobileExpSearchAirportsQueryVariables) => {
  return useLazyQuery<MobileExpSearchAirportsQuery, MobileExpSearchAirportsQueryVariables>(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpSearchAirPorts;
