import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12 14.5c.699 0 1.29-.242 1.774-.726A2.413 2.413 0 0014.5 12c0-.699-.242-1.29-.726-1.774A2.413 2.413 0 0012 9.5c-.699 0-1.29.242-1.774.726A2.413 2.413 0 009.5 12c0 .699.242 1.29.726 1.774A2.413 2.413 0 0012 14.5zm.002 7a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
