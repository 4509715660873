import React, { useImperativeHandle, useMemo, useState } from "react";
import { ActivityIndicator, RefreshControl, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";

import { useAuth } from "contexts/AuthContext";
import { Colors } from "theme";
import { EmptyData, RadioSelectFilter, SkeletonListLoading, SortingLabel, AnimatedFlatList } from "components";
import { EmptyExpenseRequest } from "assets/images/svg/icons";
import ExpenseRequestItem from "components/ExpenseRequestItem";
import { REQUEST_SORT_OPTIONS, WaitingApprovalExpenseRequestTabMethods } from "../types";
import { CONSTANTS, KIND_OF_APPROVAL } from "constants/constants";
import useExpenseRequestWaitingApproval from "../hooks/useExpenseRequestWaitingApproval";
import { NetworkStatus } from "@apollo/client";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import Animated, {
  useAnimatedScrollHandler,
  useSharedValue,
  useAnimatedStyle,
  Extrapolation,
  interpolate,
} from "react-native-reanimated";

import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { clampReanimated } from "utils";
import { RequestListScreenProps } from "navigation/type";
import { useNavigation, useRoute } from "@react-navigation/native";

const FILTER_CONTAINER_HEIGHT = 36;
const PAGE_SIZE = 10;

const WaitingApprovalExpenseRequestTab = React.forwardRef<WaitingApprovalExpenseRequestTabMethods, unknown>(
  (_, ref) => {
    useImperativeHandle(ref, () => ({
      refetch: refetch,
    }));
    const {
      user: { employee_id: employeeId },
      company: { company_id: companyId },
    } = useAuth();
    const route = useRoute<RequestListScreenProps["route"]>();
    const navigation = useNavigation<RequestListScreenProps["navigation"]>();
    const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestListScreen");
    const sortOptions = useMemo(
      () =>
        [
          { label: t("requested_at_asc"), value: REQUEST_SORT_OPTIONS.REQUESTED_AT_ASC },
          { label: t("requested_at_desc"), value: REQUEST_SORT_OPTIONS.REQUESTED_AT_DESC },
          {
            label: t("expense_asc"),
            value: REQUEST_SORT_OPTIONS.AMOUNT_ASC,
          },
          {
            label: t("expense_desc"),
            value: REQUEST_SORT_OPTIONS.AMOUNT_DESC,
          },
        ].reduce((acc, option) => {
          acc[option.value] = option;
          return acc;
        }, {}),
      [t]
    );

    const [sortOption, setSortOption] = useState<SelectItem>(sortOptions[REQUEST_SORT_OPTIONS.REQUESTED_AT_ASC]);
    const { data, loading, fetchMore, refetch, networkStatus } = useExpenseRequestWaitingApproval({
      where: {
        companyId,
        employeeId,
        kindOfApproval: KIND_OF_APPROVAL.AWAITING_MY_APPROVAL,
      },
      limit: PAGE_SIZE,
      offset: 0,
      sortOption: sortOption.value,
    });

    const expenseRequestList = data?.expWaitingApprovalExpenseRequests?.requests ?? [];
    const total = data?.expWaitingApprovalExpenseRequests?.total ?? 0;
    const onRefresh = async () => {
      await refetch();
    };

    const handleLoadMore = async () => {
      if (loading || expenseRequestList.length >= total) {
        return;
      }

      await fetchMore({
        variables: { offset: expenseRequestList.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            expWaitingApprovalExpenseRequests: {
              requests: [
                ...prev?.expWaitingApprovalExpenseRequests?.requests,
                ...fetchMoreResult?.expWaitingApprovalExpenseRequests?.requests,
              ],
              total: fetchMoreResult?.expWaitingApprovalExpenseRequests?.total,
            },
          };
        },
      });
    };

    const renderFooter = () => {
      if (loading && expenseRequestList?.length > 0) {
        return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
      }
      return null;
    };

    const renderEmpty = () => {
      if (loading) {
        return null;
      }
      return (
        <EmptyData
          title={t("empty_title")}
          description={t("empty_description_approver")}
          icon={<EmptyExpenseRequest />}
        />
      );
    };
    const renderItem = ({ item, index }) => (
      <ExpenseRequestItem onRefreshData={onRefresh} item={item} isLast={index === total - 1} showCreator />
    );
    const translationY = useSharedValue(0);
    const animatedStyle = useAnimatedStyle(() => {
      const translateY = interpolate(
        translationY.value,
        [0, FILTER_CONTAINER_HEIGHT],
        [0, -FILTER_CONTAINER_HEIGHT],
        Extrapolation.CLAMP
      );
      return {
        transform: [{ translateY: translateY }],
      };
    });
    const scrollHandler = useAnimatedScrollHandler<{ prevY?: number }>({
      onScroll: (event, ctx) => {
        let { y } = event.contentOffset;
        if (y < 0) {
          y = 0;
        }
        const dy = y - (ctx?.prevY ?? 0);
        translationY.value = clampReanimated(translationY.value + dy, 0, FILTER_CONTAINER_HEIGHT);
        ctx.prevY = y;
      },
    });

    return (
      <View style={styles.container}>
        <Animated.View style={[styles.floatHeader, animatedStyle]}>
          <View style={styles.sortingContainer}>
            <SortingLabel
              sortingModalProps={{
                showClearButton: false,
                title: t("sort_by"),
                data: Object.values(sortOptions),
                value: sortOption,
                setValue: (option) => {
                  const params = route?.params ?? {};
                  navigation.setParams({ ...params, approvalTabSortOption: option.value });
                  setSortOption(option);
                },
                onPress: () =>
                  analyticService.logEvent({ name: EVENT.REQUEST.TAP_OPEN_SORT_MODAL, properties: { tab: "waiting" } }),
              }}
            />
          </View>
        </Animated.View>
        {(loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
          <SkeletonListLoading style={styles.loadingContainer} />
        ) : (
          <AnimatedFlatList
            onScroll={scrollHandler}
            data={expenseRequestList}
            keyExtractor={(item) => (item as any)?.expenseRequestId}
            renderItem={renderItem}
            estimatedItemSize={88}
            contentContainerStyle={styles.listContainer}
            refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefresh} />}
            onEndReached={handleLoadMore}
            showsVerticalScrollIndicator={false}
            onEndReachedThreshold={0.15}
            ListEmptyComponent={renderEmpty}
            ListFooterComponent={renderFooter}
          />
        )}
      </View>
    );
  }
);
export default WaitingApprovalExpenseRequestTab;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingBottom: 50,
    paddingTop: FILTER_CONTAINER_HEIGHT - 10,
    paddingHorizontal: 20,
  },
  loadingMore: { marginTop: 10 },
  floatHeader: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
  sortingContainer: {
    paddingVertical: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  loadingContainer: { marginTop: FILTER_CONTAINER_HEIGHT - 10 },
});
