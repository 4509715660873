import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F", width = 24, height = 24 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.037 21.09c-.295 0-.544-.1-.746-.3a1.004 1.004 0 01-.302-.742v-1.126a5.213 5.213 0 01-2.002-.896c-.572-.427-1.036-1.017-1.39-1.771a.93.93 0 01-.003-.81 1.11 1.11 0 01.644-.604.944.944 0 01.782.016c.268.118.48.314.638.588.279.476.63.836 1.05 1.08.422.245.931.367 1.529.367.671 0 1.232-.145 1.68-.435.45-.29.674-.743.674-1.357 0-.556-.185-1.003-.556-1.343-.37-.34-1.218-.72-2.544-1.141-1.417-.442-2.396-.985-2.938-1.628-.541-.643-.812-1.422-.812-2.338 0-1.067.345-1.911 1.035-2.531.69-.62 1.428-.983 2.213-1.09V3.951c0-.295.101-.543.302-.742.202-.2.45-.3.746-.3.3 0 .548.1.745.3.198.2.297.447.297.742v1.076a4.404 4.404 0 011.659.63c.472.31.863.692 1.17 1.15.167.237.198.501.094.794-.103.292-.31.503-.622.632-.241.104-.495.109-.761.015a2.182 2.182 0 01-.784-.514 2.145 2.145 0 00-.738-.502c-.284-.117-.63-.175-1.04-.175-.705 0-1.237.15-1.594.451-.358.301-.536.675-.536 1.123 0 .522.235.936.705 1.24.47.305 1.33.636 2.579.994 1.166.342 2.052.88 2.657 1.615.606.735.909 1.591.909 2.569 0 1.2-.354 2.113-1.062 2.742-.708.628-1.587 1.022-2.636 1.18v1.076c0 .295-.1.543-.3.742-.2.2-.447.3-.742.3z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
