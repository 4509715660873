import { ActivityIndicator, FlatListProps, Platform, SectionListProps, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import React, { ReactNode, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetModal,
  useBottomSheetDynamicSnapPoints,
} from "@gorhom/bottom-sheet";
import { BottomSheetFlatListCustom, useWindowDimensions } from "components";
import HeaderBottomSheet from "../BottomSheetModalCustom/HeaderBottomSheetModal";
import useBottomSheetBackHandler from "../BottomSheetModalCustom/useBottomSheetBackHandler";
import { Colors } from "theme";
import { useReducedMotion } from "react-native-reanimated";

export interface BottomSheetFlatListModalCustomProps {
  title?: string;
  description?: string;
  renderSearch?: () => ReactNode;
  renderFooter?: () => ReactNode;
  searchHeight?: number;
  snapPoints?: number[] | string[];
  loading?: boolean;
  onDismiss?: () => void;
  listProps: FlatListProps<any>;
}

export interface BottomSheetFlatListModalCustomMethods {
  present: () => void;
  close?: () => void;
}
const HEADER_HEIGHT = 40;
const BottomSheetFlatListModalCustom = React.forwardRef<
  BottomSheetFlatListModalCustomMethods,
  BottomSheetFlatListModalCustomProps
>(
  (
    {
      snapPoints,
      title,
      description,
      renderSearch,
      renderFooter,
      searchHeight = 0,
      loading = false,
      onDismiss,
      listProps,
    },
    ref
  ) => {
    // avoid issue: not showing modal when user enable 'Reduce Motion' on IOS, Android and Webapp
    const reducedMotion = useReducedMotion();

    useImperativeHandle(
      ref,
      () => ({
        present: () => {
          isOpened.current = true;
          bottomSheetRef?.current?.present?.();
        },
        close: () => {
          if (reducedMotion) {
            bottomSheetRef?.current?.snapToPosition(0);
          } else {
            bottomSheetRef?.current?.dismiss();
          }
        },
      }),
      [reducedMotion]
    );
    const { width, height } = useWindowDimensions();

    const { bottom, top } = useSafeAreaInsets();
    const bottomSheetRef = useRef<BottomSheetModal>(null);
    const isOpened = useRef<boolean>(false);
    const { handleSheetPositionChange } = useBottomSheetBackHandler(bottomSheetRef);
    const SCROLL_VIEW_MAX_HEIGHT = height - HEADER_HEIGHT - searchHeight;
    const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
      useBottomSheetDynamicSnapPoints(["CONTENT_HEIGHT"]);

    useEffect(() => {
      if (Platform.OS === "web") {
        const handleKeyDown = (event) => {
          if (event.key === "Escape" && isOpened.current) {
            bottomSheetRef?.current?.close();
          }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, []);

    const renderBackdrop = useCallback(
      (props) => (
        <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} opacity={0.3} pressBehavior="close" />
      ),
      []
    );
    const renderHeaderHandle = useCallback(
      (props) => {
        return (
          <HeaderBottomSheet
            title={title}
            description={description}
            onClose={() => bottomSheetRef?.current?.close()}
            {...props}
          />
        );
      },
      [title, description]
    );

    const bottomInset = bottom || 15;
    const topInset = top ? top + 5 : 30;
    const renderFooterComponent = useCallback(
      (props) => (
        <BottomSheetFooter style={[styles.footer, { paddingBottom: bottomInset }]} {...props} bottomInset={0}>
          {renderFooter?.()}
        </BottomSheetFooter>
      ),
      [renderFooter]
    );
    const handleOnDismiss = () => {
      isOpened.current = false;
      onDismiss?.();
    };
    return (
      <BottomSheetModal
        topInset={topInset}
        snapPoints={snapPoints ? snapPoints : animatedSnapPoints.value}
        {...(!snapPoints && { handleHeight: animatedHandleHeight, contentHeight: animatedContentHeight })}
        containerStyle={[styles.containerStyle, { maxWidth: width, overflow: "visible" }]}
        onChange={handleSheetPositionChange}
        handleIndicatorStyle={styles.handleIndicatorStyle}
        handleComponent={renderHeaderHandle}
        animateOnMount={!reducedMotion}
        enableHandlePanningGesture={false}
        backdropComponent={renderBackdrop}
        style={styles.modal}
        ref={bottomSheetRef}
        onDismiss={handleOnDismiss}
        {...(renderFooter && { footerComponent: renderFooterComponent })}
      >
        {renderSearch?.()}
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator color={Colors.primary50} />
          </View>
        ) : (
          <BottomSheetFlatListCustom
            keyboardShouldPersistTaps="always"
            keyboardDismissMode="on-drag"
            showsVerticalScrollIndicator={false}
            {...(!snapPoints && { style: { maxHeight: SCROLL_VIEW_MAX_HEIGHT, minHeight: 200 } })}
            contentContainerStyle={{ paddingBottom: searchHeight + (bottom || 20) }}
            onLayout={handleContentLayout}
            {...listProps}
          />
        )}
      </BottomSheetModal>
    );
  }
);
export default React.memo(BottomSheetFlatListModalCustom);

const styles = StyleSheet.create({
  containerStyle: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  handleIndicatorStyle: {
    display: "none",
  },
  modal: {
    borderWidth: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: "visible",
  },
  footer: { paddingHorizontal: 20, paddingTop: 8, backgroundColor: Colors.white },
  loadingContainer: { flex: 1, paddingTop: 30 },
});
