import {
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  IconCustom,
  RadioButton,
  SwitchButton,
} from "components";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import { COMPARE_TYPE, TIME_FILTER, TIME_FILTER_OPTIONS } from "../types";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import SelectTimeCustomizeModal from "screens/ExpenseOverview/components/SelectTimeCustomizeModal";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);

const TIMES: TIME_FILTER = [
  { title: "current_month", value: TIME_FILTER_OPTIONS.MONTH },
  { title: "current_quarter", value: TIME_FILTER_OPTIONS.QUARTER },
  { title: "fiscal_year", value: TIME_FILTER_OPTIONS.YEAR },
  { title: "year_to_date", value: TIME_FILTER_OPTIONS.YEAR_TO_DATE },
];
interface SelectTimeModalProps {
  value: TIME_FILTER_OPTIONS;
  setValue: (value: TIME_FILTER_OPTIONS) => void;
  isCompare: boolean;
  setIsCompare: (bool: boolean) => void;
  compareWith: COMPARE_TYPE;
  setCompareWith: (value: COMPARE_TYPE) => void;
  fromDateCustomize: Date;
  toDateCustomize: Date;
  setFromDateCustomize: (date: Date) => void;
  setToDateCustomize: (date: Date) => void;
}
const SelectTimeModal = forwardRef<BottomSheetScrollViewModalCustomMethods, SelectTimeModalProps>(
  (
    {
      value,
      setValue,
      isCompare,
      setIsCompare,
      compareWith,
      setCompareWith,
      fromDateCustomize,
      toDateCustomize,
      setFromDateCustomize,
      setToDateCustomize,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        setCurrentValue(value);
        setCurrentIsCompare(isCompare);
        setCurrentCompareWith(compareWith);
        bottomSheetRef?.current?.present?.();
        let newCompareSamePeriodBeforeDate, newCompareSamePeriodLastYearDate;
        if (value === TIME_FILTER_OPTIONS.CUSTOMIZE) {
          const newFromDateCustomize = dayjs(fromDateCustomize).startOf("month");
          const newToDateCustomize = dayjs(toDateCustomize).endOf("month");
          const betweenMonths = newToDateCustomize.diff(newFromDateCustomize, "month") + 1;
          newCompareSamePeriodBeforeDate = {
            fromDate: newFromDateCustomize.subtract(betweenMonths, "month").startOf("month"),
            toDate: newToDateCustomize.subtract(betweenMonths, "month").endOf("month"),
          };
          const betweenYears = newToDateCustomize.diff(newFromDateCustomize, "year") + 1;
          newCompareSamePeriodLastYearDate = {
            fromDate: newFromDateCustomize.subtract(betweenYears, "year"),
            toDate: newToDateCustomize.subtract(betweenYears, "year"),
          };
          setCompareSamePeriodBeforeDate(newCompareSamePeriodBeforeDate);
          setCompareSamePeriodLastYearDate(newCompareSamePeriodLastYearDate);
        }
      },
      close: () => {
        bottomSheetRef?.current?.close?.();
      },
    }));
    const { t } = useTranslation("app/screens/ExpenseOverview/ExpenseOverviewScreen");

    const [currentValue, setCurrentValue] = useState<TIME_FILTER_OPTIONS>();
    const [currentIsCompare, setCurrentIsCompare] = useState(true);
    const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
    const [currentCompareWith, setCurrentCompareWith] = useState<COMPARE_TYPE>(COMPARE_TYPE.SAME_PERIOD_BEFORE);
    const timeCustomizeModalRef = useRef<BottomSheetModalCustomMethods>();
    const [compareSamePeriodBeforeDate, setCompareSamePeriodBeforeDate] = useState({
      fromDate: dayjs().subtract(1, "month").startOf("month"),
      toDate: dayjs().subtract(1, "month").endOf("day"),
    });
    const [compareSamePeriodLastYearDate, setCompareSamePeriodLastYearDate] = useState({
      fromDate: dayjs().subtract(1, "year").startOf("month"),
      toDate: dayjs().subtract(1, "year").endOf("day"),
    });
    const handleSelectOption = (value) => () => {
      let newCompareSamePeriodBeforeDate, newCompareSamePeriodLastYearDate;
      switch (value) {
        case TIME_FILTER_OPTIONS.MONTH: {
          newCompareSamePeriodBeforeDate = {
            fromDate: dayjs().subtract(1, "month").startOf("month"),
            toDate: dayjs().subtract(1, "month").endOf("day"),
          };
          newCompareSamePeriodLastYearDate = {
            fromDate: newCompareSamePeriodBeforeDate.fromDate.subtract(1, "year"),
            toDate: newCompareSamePeriodBeforeDate.toDate.subtract(1, "year"),
          };
          break;
        }
        case TIME_FILTER_OPTIONS.QUARTER: {
          newCompareSamePeriodBeforeDate = {
            fromDate: dayjs().subtract(1, "quarter").startOf("quarter"),
            toDate: dayjs().subtract(1, "quarter").endOf("quarter"),
          };
          newCompareSamePeriodLastYearDate = {
            fromDate: dayjs().subtract(1, "year").startOf("quarter"),
            toDate: dayjs().subtract(1, "year").endOf("quarter"),
          };
          break;
        }
        case TIME_FILTER_OPTIONS.YEAR: {
          newCompareSamePeriodBeforeDate = {
            fromDate: dayjs().subtract(1, "year").startOf("year"),
            toDate: dayjs().subtract(1, "year").endOf("year"),
          };
          newCompareSamePeriodLastYearDate = newCompareSamePeriodBeforeDate;
          break;
        }
        case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
          newCompareSamePeriodBeforeDate = {
            fromDate: dayjs().subtract(1, "year").startOf("year"),
            toDate: dayjs().subtract(1, "year").endOf("day"),
          };
          newCompareSamePeriodLastYearDate = newCompareSamePeriodBeforeDate;
          break;
        }
        case TIME_FILTER_OPTIONS.CUSTOMIZE: {
          setCurrentValue(null);
          bottomSheetRef?.current?.close();
          timeCustomizeModalRef?.current?.present();
          break;
        }
      }
      setCompareSamePeriodBeforeDate(newCompareSamePeriodBeforeDate);
      setCompareSamePeriodLastYearDate(newCompareSamePeriodLastYearDate);
      setCurrentValue(value);
    };
    const handleApplyFilter = () => {
      bottomSheetRef?.current?.close();
      setIsCompare(currentIsCompare);
      setValue(currentValue);
      setCompareWith(currentCompareWith);
    };

    const renderSamePeriodBeforeTitle = () => {
      if (!compareSamePeriodBeforeDate) {
        return "";
      }
      switch (currentValue) {
        case TIME_FILTER_OPTIONS.MONTH: {
          return `${compareSamePeriodBeforeDate.fromDate.format(
            "DD/MM/YYYY"
          )} - ${compareSamePeriodBeforeDate.toDate.format("DD/MM/YYYY")}`;
        }
        case TIME_FILTER_OPTIONS.QUARTER:
        case TIME_FILTER_OPTIONS.YEAR:
        case TIME_FILTER_OPTIONS.CUSTOMIZE: {
          return `${compareSamePeriodBeforeDate.fromDate.format(
            "MM/YYYY"
          )} - ${compareSamePeriodBeforeDate.toDate.format("MM/YYYY")}`;
        }
        case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
          return `${compareSamePeriodBeforeDate.fromDate.format(
            "MM/YYYY"
          )} - ${compareSamePeriodBeforeDate.toDate.format("DD/MM/YYYY")}`;
        }
      }
    };
    const renderSamePeriodLatYearTitle = () => {
      if (!compareSamePeriodLastYearDate) {
        return "";
      }
      switch (currentValue) {
        case TIME_FILTER_OPTIONS.MONTH: {
          return `${compareSamePeriodLastYearDate.fromDate.format(
            "DD/MM/YYYY"
          )} - ${compareSamePeriodLastYearDate.toDate.format("DD/MM/YYYY")}`;
        }
        case TIME_FILTER_OPTIONS.QUARTER:
        case TIME_FILTER_OPTIONS.YEAR:
        case TIME_FILTER_OPTIONS.CUSTOMIZE: {
          return `${compareSamePeriodLastYearDate.fromDate.format(
            "MM/YYYY"
          )} - ${compareSamePeriodLastYearDate.toDate.format("MM/YYYY")}`;
        }
        case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
          return `${compareSamePeriodLastYearDate.fromDate.format(
            "MM/YYYY"
          )} - ${compareSamePeriodLastYearDate.toDate.format("DD/MM/YYYY")}`;
        }
      }
    };
    return (
      <>
        <BottomSheetScrollViewModalCustom
          ref={bottomSheetRef}
          containerStyle={{ paddingBottom: 100 }}
          snapPoints={[570]}
          title={t("data")}
          renderFooter={() => <Button onPress={handleApplyFilter}>{t("apply")}</Button>}
        >
          <View style={styles.body}>
            {TIMES.map((item) => {
              const isActive = currentValue === item.value;

              return (
                <TouchableOpacity key={item.value} style={styles.timeItem} onPress={handleSelectOption(item.value)}>
                  <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      t(item.title)
                    }
                  </AppText>
                  <RadioButton checked={isActive} />
                </TouchableOpacity>
              );
            })}
            <TouchableOpacity style={styles.timeItem} onPress={handleSelectOption(TIME_FILTER_OPTIONS.CUSTOMIZE)}>
              <AppText
                style={[Fonts.BodyMedium, styles.flex]}
                color={fromDateCustomize ? Colors.primary50 : Colors.grayscale100}
              >
                {t("customize")}
              </AppText>
              {Boolean(fromDateCustomize) && (
                <AppText style={[Fonts.BodyMedium, { marginRight: 16 }]} color={Colors.primary50}>{`${dayjs(
                  fromDateCustomize
                ).format("MM/YYYY")}-${dayjs(toDateCustomize).format("MM/YYYY")}`}</AppText>
              )}
              <IconCustom name="arrow-forward-ios" />
            </TouchableOpacity>
            <TouchableOpacity style={styles.timeItem}>
              <AppText style={[Fonts.BodyMedium, styles.flex]}>{t("compare")}</AppText>
              <SwitchButton onValueChange={setCurrentIsCompare} value={currentIsCompare} />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.timeItem, { paddingVertical: 8 }]}
              onPress={() => setCurrentCompareWith(COMPARE_TYPE.SAME_PERIOD_BEFORE)}
            >
              <View>
                <AppText style={Fonts.BodyMedium} color={currentIsCompare ? Colors.grayscale100 : Colors.grayscale60}>
                  {t("same_period_before")}
                </AppText>
                {currentIsCompare && (
                  <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                    {renderSamePeriodBeforeTitle()}
                  </AppText>
                )}
              </View>
              <RadioButton checked={currentIsCompare && currentCompareWith === COMPARE_TYPE.SAME_PERIOD_BEFORE} />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.timeItem, { paddingVertical: 8 }]}
              onPress={() => setCurrentCompareWith(COMPARE_TYPE.SAME_PERIOD_LAST_YEAR)}
            >
              <View>
                <AppText style={Fonts.BodyMedium} color={currentIsCompare ? Colors.grayscale100 : Colors.grayscale60}>
                  {t("same_period_year")}
                </AppText>
                {currentIsCompare && (
                  <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                    {renderSamePeriodLatYearTitle()}
                  </AppText>
                )}
              </View>
              <RadioButton checked={currentIsCompare && currentCompareWith === COMPARE_TYPE.SAME_PERIOD_LAST_YEAR} />
            </TouchableOpacity>
          </View>
        </BottomSheetScrollViewModalCustom>
        <SelectTimeCustomizeModal
          setFromDate={setFromDateCustomize}
          setToDate={setToDateCustomize}
          ref={timeCustomizeModalRef}
          handleApplyFilter={handleApplyFilter}
        />
      </>
    );
  }
);
export default SelectTimeModal;
const styles = StyleSheet.create({
  timeItem: {
    flexDirection: "row",
    paddingVertical: 13,
    gap: 8,
    alignItems: "center",
    justifyContent: "space-between",
  },
  body: { paddingHorizontal: 20 },
  flex: { flex: 1 },
});
