import { FC } from "react";
import { StyleSheet, View } from "react-native";
import { MobileExpenseReportDetailsQuery } from "types";
import { AppText, EmployeeTextView } from "components";
import { Colors, Fonts } from "theme";
import dayjs from "dayjs";
import { ALLOCATED_STATUS, APPROVAL_STATUS, CONSTANTS, EXPENSE_REPORT_STATUS } from "constants/constants";
import { useTranslation } from "react-i18next";

const TextWithBadged = ({ items }) => {
  return (
    <>
      {items.map((item) => {
        if (typeof item === "string") {
          return item.split(" ").map((str) => (
            <AppText style={Fonts.BodyRegular} color={Colors.grayscale70}>
              {`${str}`}
            </AppText>
          ));
        }

        return (
          <>
            {item}
            <AppText> </AppText>
          </>
        );
      })}
    </>
  );
};
interface SummaryMessageProps {
  expenseReport: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
  isApproverView?: boolean;
}
const SummaryMessage: FC<SummaryMessageProps> = ({ isApproverView, expenseReport }) => {
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
  const getActionTitle = () => {
    switch (expenseReport?.status) {
      case EXPENSE_REPORT_STATUS.DRAFT: {
        return t("is_create_at");
      }
      case EXPENSE_REPORT_STATUS.SUBMITTED: {
        return t("is_request_at");
      }
      case EXPENSE_REPORT_STATUS.APPROVED: {
        return t("is_approved_on");
      }
      case EXPENSE_REPORT_STATUS.REJECTED: {
        return t("rejected_on");
      }
      case EXPENSE_REPORT_STATUS.CANCEL: {
        return t("is_cancelled_on");
      }
    }
  };
  const expenseApprovalRules =
    expenseReport?.allocatedStatus === ALLOCATED_STATUS.IS_ALLOCATED
      ? expenseReport?.parent?.expenseApprovalRules
      : expenseReport?.expenseApprovalRules;
  const getTimeLabel = () => {
    switch (expenseReport?.status) {
      case EXPENSE_REPORT_STATUS.DRAFT: {
        return dayjs(expenseReport?.createdAt).format(CONSTANTS.FORMAT_DAY);
      }
      case EXPENSE_REPORT_STATUS.SUBMITTED: {
        return dayjs(expenseReport?.requestedAt).format(CONSTANTS.FORMAT_DAY);
      }
      case EXPENSE_REPORT_STATUS.APPROVED: {
        const levelApproved = expenseApprovalRules?.find((item) => item?.status === APPROVAL_STATUS.APPROVED);
        return levelApproved ? dayjs(levelApproved.updatedAt).format(CONSTANTS.FORMAT_DAY) : "";
      }
      case EXPENSE_REPORT_STATUS.REJECTED: {
        const levelRejected = expenseApprovalRules?.find((item) => item?.status === APPROVAL_STATUS.REJECTED);
        return levelRejected ? dayjs(levelRejected.updatedAt).format(CONSTANTS.FORMAT_DAY) : "";
      }
      case EXPENSE_REPORT_STATUS.CANCEL: {
        const levelRejected = expenseReport?.expenseApprovalLogs?.find(
          (item) => item?.status === APPROVAL_STATUS.CANCELLED
        );
        return levelRejected ? dayjs(levelRejected.createdAt).format(CONSTANTS.FORMAT_DAY) : "";
      }
    }
  };
  const reason = expenseReport?.expenseApprovalLogs
    ?.find((item) => {
      switch (expenseReport?.status) {
        case EXPENSE_REPORT_STATUS.CANCEL:
          return item.status === APPROVAL_STATUS.CANCELLED;
        case EXPENSE_REPORT_STATUS.REJECTED:
          return item.status === APPROVAL_STATUS.REJECTED;
        default:
          return false;
      }
    })
    ?.reason?.trim();
  const reasonTagBackgroundColor =
    expenseReport?.status === EXPENSE_REPORT_STATUS.REJECTED ? Colors.alert05 : Colors.grayscale05;
  const renderOwnerTag = () => {
    if (!isApproverView) {
      return null;
    }
    if (
      [EXPENSE_REPORT_STATUS.SUBMITTED, EXPENSE_REPORT_STATUS.APPROVED, EXPENSE_REPORT_STATUS.REJECTED].includes(
        expenseReport?.status as EXPENSE_REPORT_STATUS
      )
    ) {
      return (
        <>
          <AppText style={Fonts.BodyRegular} color={Colors.grayscale70}>
            {t("of")}
          </AppText>
          <EmployeeTextView
            type="Tag"
            employeeId={expenseReport?.companyEmployee?.employeeId}
            fullname={expenseReport?.companyEmployee?.fullname}
            avatar={expenseReport?.companyEmployee?.avatarUrl || expenseReport?.companyEmployee?.defaultAvatarUrl}
          />
        </>
      );
    }
  };

  const renderActor = () => {
    if (
      isApproverView &&
      expenseReport.status !== EXPENSE_REPORT_STATUS.REJECTED &&
      expenseReport.status !== EXPENSE_REPORT_STATUS.CANCEL
    ) {
      return null;
    }
    if (expenseReport?.status === EXPENSE_REPORT_STATUS.CANCEL) {
      const personCancelled = expenseReport?.expenseApprovalLogs?.find((e) => e.status === APPROVAL_STATUS.CANCELLED);
      return (
        <>
          <AppText style={Fonts.BodyRegular} color={Colors.grayscale70}>
            {t("is")}
          </AppText>
          <EmployeeTextView
            type="Tag"
            employeeId={personCancelled?.companyEmployeeId}
            fullname={personCancelled?.companyEmployee?.fullname}
            avatar={personCancelled?.companyEmployee?.avatarUrl || personCancelled?.companyEmployee?.defaultAvatarUrl}
          />
        </>
      );
    } else if (expenseReport?.status === EXPENSE_REPORT_STATUS.REJECTED) {
      const levelRejected = expenseApprovalRules?.find((item) => item?.status === APPROVAL_STATUS.REJECTED);
      const personReject = levelRejected?.expenseApprovalRuleEmployees?.find(
        (e) => e.status === APPROVAL_STATUS.REJECTED
      );
      return (
        <>
          <AppText style={Fonts.BodyRegular} color={Colors.grayscale70}>
            {t("is")}
          </AppText>
          <EmployeeTextView
            type="Tag"
            employeeId={personReject?.employeeId}
            fullname={personReject?.employee?.fullname}
            avatar={personReject?.employee?.avatarUrl || personReject?.employee?.defaultAvatarUrl}
          />
        </>
      );
    }
  };
  return (
    <>
      <View style={styles.container}>
        <View style={styles.tag}>
          <AppText style={Fonts.BodyMedium}>{t("expense_report")}</AppText>
        </View>
        {renderOwnerTag()}
        {renderActor()}
        <TextWithBadged items={[getActionTitle()]} />
        <View style={styles.tag}>
          <AppText style={Fonts.BodyMedium}>{getTimeLabel()}</AppText>
        </View>
        {Boolean(reason) && !reason?.includes("\n") && (
          <>
            <AppText style={Fonts.BodyRegular} color={Colors.grayscale70}>
              {t("because")}
            </AppText>
            <View style={[styles.tag, { backgroundColor: reasonTagBackgroundColor }]}>
              <AppText style={Fonts.BodyMedium}>{reason}</AppText>
            </View>
          </>
        )}
        {Boolean(reason) && reason?.includes("\n") && (
          <AppText style={Fonts.BodyRegular} color={Colors.grayscale70}>
            {t("because")}
          </AppText>
        )}
      </View>
      {Boolean(reason) && reason?.includes("\n") && (
        <>
          <View style={[styles.tag, styles.reasonTagFullWidth, { backgroundColor: reasonTagBackgroundColor }]}>
            <AppText style={Fonts.BodyRegular}>{reason}</AppText>
          </View>
        </>
      )}
    </>
  );
};
export default SummaryMessage;
const styles = StyleSheet.create({
  container: { flexDirection: "row", columnGap: 4, rowGap: 4, alignItems: "center", flexWrap: "wrap" },
  tag: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    backgroundColor: Colors.grayscale05,
    maxWidth: "100%",
  },
  reasonTagFullWidth: { marginTop: 8, alignItems: "flex-start", paddingVertical: 4 },
});
