import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { MobileEmailsQuery, MobileEmailsQueryVariables } from "types";

const QUERY_EMAILS = gql`
  query MobileEmails($userId: uuid!, $companyId: uuid!, $keyword: String, $offset: Int, $limit: Int) {
    emails(
      where: {
        deleted_at: { _is_null: true }
        user_id: { _eq: $userId }
        company_id: { _eq: $companyId }
        _or: [{ subject: { _ilike: $keyword } }, { text: { _ilike: $keyword } }]
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      to
      text
      subject
      status
      sender
      html
      from
      is_duplicated
      duplicated_invoice_id
      email_id
      created_at
      invoices {
        invoice_id
      }
      email_attachments {
        file_name
        file_type
        file_url
        status
        email_attachment_id
        file_content
        file_size
      }
    }
    emails_aggregate(
      where: {
        deleted_at: { _is_null: true }
        user_id: { _eq: $userId }
        _or: [{ subject: { _ilike: $keyword } }, { text: { _ilike: $keyword } }]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const useEmailsQuery = ({ variables, skip = false }) => {
  return useQuery<MobileEmailsQuery, MobileEmailsQueryVariables>(QUERY_EMAILS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip,
  });
};

export default useEmailsQuery;
