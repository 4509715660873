// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { request } from "app/utils/gql";
import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";

const USER_QUERY = /* GraphQL */ `
  query MobileUsers($userId: uuid!) {
    user: users(where: { user_id: { _eq: $userId } }) {
      fullname
      username
      email
      user_id
      groups: users_groups {
        role
        group_id
      }
    }
  }
`;

const useIsUserAdmin = ({ token }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<Users>(null);

  const checkUserIsAdmin = useCallback(async () => {
    setLoading(true);
    const payload = jwtDecode(token);
    const result = await request(
      USER_QUERY,
      {
        userId: payload.id,
      },
      token
    );

    if (result?.data?.user?.[0]?.groups?.[0]?.role === "mod") {
      setIsAdmin(true);
      setUserInfo(result?.data?.user[0]);
    }
    setLoading(false);
  }, [token]);

  useEffect(() => {
    if (token) {
      checkUserIsAdmin();
    }
  }, [token, checkUserIsAdmin]);

  return { isAdmin, loading, userInfo };
};

export default useIsUserAdmin;
