import { Platform } from "react-native";
import { perPlatformTypes } from "react-native-document-picker/src/fileTypes";
import { CONSTANTS } from "./constants";

const getUploadableTypes = () => {
  return CONSTANTS.COMMON.UPLOADABLE_TYPES.reduce((acc, type) => {
    const mimeType = perPlatformTypes.android;

    // override setting of perPlatformTypes
    const definedTypes = {
      ...perPlatformTypes,
      web: mimeType,
    }[Platform.OS];

    if (!definedTypes) {
      return acc;
    }

    acc.push(definedTypes[type]);
    return acc;
  }, []);
};
export const UPLOADABLE_TYPES = getUploadableTypes();
