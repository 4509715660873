import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#E11934", width = 24, height = 24 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={width} height={height}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.308 20.5a1.74 1.74 0 01-1.277-.531 1.74 1.74 0 01-.531-1.277V6h-.25a.725.725 0 01-.534-.216.726.726 0 01-.216-.535c0-.212.072-.39.216-.534A.726.726 0 015.25 4.5H9c0-.245.086-.454.259-.626a.853.853 0 01.626-.259h4.23c.245 0 .454.086.626.259A.852.852 0 0115 4.5h3.75c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.39-.216.534A.726.726 0 0118.75 6h-.25v12.692c0 .497-.177.923-.531 1.277a1.74 1.74 0 01-1.277.53H7.308zM17 6H7v12.692a.3.3 0 00.087.221.3.3 0 00.22.087h9.385a.3.3 0 00.221-.087.3.3 0 00.087-.22V6zm-6.846 11c.213 0 .39-.072.534-.216a.726.726 0 00.216-.534v-7.5a.726.726 0 00-.216-.535.726.726 0 00-.535-.215.725.725 0 00-.534.215.726.726 0 00-.215.535v7.5c0 .212.072.39.216.534a.726.726 0 00.534.216zm3.692 0c.213 0 .391-.072.535-.216a.726.726 0 00.215-.534v-7.5a.726.726 0 00-.216-.535.726.726 0 00-.534-.215.725.725 0 00-.534.215.726.726 0 00-.216.535v7.5c0 .212.072.39.216.534a.727.727 0 00.534.216z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
