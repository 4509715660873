// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { useCallback, useState, useEffect } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity, Linking } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTranslation } from "react-i18next";
import * as Location from "expo-location";
import { Text } from "react-native-paper";

import config from "app/config";
import { Loading, BizziButton, AppText, Line } from "components";
import { Colors, Fonts } from "theme";
import { Toast } from "@ant-design/react-native";

const NearByAddressScreen = ({ route, navigation }) => {
  const { t } = useTranslation("screens/SuggestionAddress/NearByAddressScreen");
  const [placePredictions, setPlacePredictions] = useState([]);
  const [location, setLocation] = useState<Location.LocationObject>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState("");
  const { bottom } = useSafeAreaInsets();

  const getListPlacesNearBy = useCallback(async (locationUser: Location.LocationObject) => {
    const uri = `https://rsapi.goong.io/Geocode?latlng=${locationUser?.coords?.latitude},${locationUser?.coords?.longitude}&api_key=${config.GOOGLE_PLACES_KEY}`;
    const resp = await fetch(uri);
    return resp.json();
  }, []);

  const getPlaceDetail = useCallback(async (placeId) => {
    const resp = await fetch(
      `https://rsapi.goong.io/Place/Detail?place_id=${placeId}&api_key=${config.GOOGLE_PLACES_KEY}`
    );
    return resp.json();
  }, []);

  useEffect(() => {
    if (selected) {
      getPlaceDetail(location);
    }
  }, [selected, location, getPlaceDetail]);

  const getCurrentPositionUser = useCallback(async () => {
    const locationUser: Location.LocationObject = await Location.getCurrentPositionAsync({});
    setLocation(locationUser);
    const locations = await getListPlacesNearBy(locationUser);

    setPlacePredictions(locations?.results ?? []);
    setIsLoading(false);
  }, [getListPlacesNearBy]);

  const checkPermissionLocation = useCallback(async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();

    // if (status !== "granted") {
    //   setErrorMsg(t("suggest_location_screens.error_not_provider_permission"));
    //   setIsLoading(false);
    //   return;
    // }

    getCurrentPositionUser();
  }, [getCurrentPositionUser, t]);

  useEffect(() => {
    checkPermissionLocation();
  }, [checkPermissionLocation]);

  const onPressItem = useCallback(
    async (item) => {
      Toast.loading("");
      const place = await getPlaceDetail(item.place_id);
      const data = {
        placeDetails: {
          ...place,
          location_name: place.result?.name,
          location_address: place.result?.formatted_address,
        },
      };

      const { callback } = route?.params ?? {};
      if (callback) {
        callback(data);
        navigation.goBack();
      }
      Toast.removeAll("");
    },
    [getPlaceDetail, route, navigation]
  );

  const renderItem = useCallback(
    (item, index) => {
      return (
        <TouchableOpacity key={item?.place_id} style={styles.itemStyle} onPress={() => onPressItem(item)}>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
            {item.formatted_address}
          </AppText>
          {placePredictions.length > 1 && index < placePredictions.length - 1 && <Line containerStyle={styles.line} />}
        </TouchableOpacity>
      );
    },
    [onPressItem, placePredictions?.length]
  );

  const renderContent = useCallback(() => {
    return <View style={{ flex: 1, paddingHorizontal: 15 }}>{placePredictions?.map(renderItem)}</View>;
  }, [placePredictions, renderItem]);

  const openSetting = useCallback(() => {
    Linking.openSettings();
  }, []);

  const renderError = useCallback(() => {
    return (
      <View style={styles.centerViewStyle}>
        <Text style={styles.errorText}>{errorMsg}</Text>
        <BizziButton
          color={Colors.errorColor}
          title={t("button_title_open_setting")}
          onPress={openSetting}
          style={styles.requestPermissionButton}
          titleStyle={{ fontSize: 14 }}
        />
      </View>
    );
  }, [errorMsg, openSetting, t]);

  return (
    <View style={styles.flex}>
      <View style={styles.wrapperChip}>
        {/* {LIST_FILTER.map((item) => {
          return (
            <Chip
              key={item.key}
              mode="outlined"
              style={{ marginRight: 4 }}
              selected={item.value === selected}
              onPress={() => {
                setSelected(item.value);
              }}
              disabled={!!errorMsg}
            >
              <Text>{item.title}</Text>
            </Chip>
          );
        })} */}
      </View>
      <ScrollView keyboardShouldPersistTaps="always">
        <View style={styles.flex}>
          {isLoading ? (
            <Loading size={60} />
          ) : (
            <View style={[styles.flex, { paddingBottom: bottom }]}>
              {!!errorMsg && renderError()}
              {!errorMsg && renderContent()}
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  wrapperChip: { flexDirection: "row", flexWrap: "wrap", paddingHorizontal: 15, paddingVertical: 10 },
  itemStyle: {
    padding: 4,
    backgroundColor: Colors.white,
    marginBottom: 10,
    alignItems: "flex-start",
  },
  centerViewStyle: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    color: Colors.alert50,
    marginTop: 50,
  },
  requestPermissionButton: {
    marginTop: 30,
    maxWidth: 150,
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  line: {
    marginTop: 15,
  },
});

export default NearByAddressScreen;
