import { gql, useLazyQuery } from "@apollo/client";
import { MobileExpensesQuery, MobileExpensesQueryVariables } from "types";

const QUERY_EXPENSES_BY_IDS = gql`
  query MobileExpenses(
    $expenseIds: [UUID!]
    $employeeId: UUID!
    $claimedStatus: ExpExpenseClaimedStatus
    $requestIds: [UUID!]
  ) {
    expExpenses(
      where: {
        expenseIds: $expenseIds
        employeeId: $employeeId
        statuses: [3]
        claimedStatus: $claimedStatus
        requestIds: $requestIds
      }
    ) {
      expenses {
        docSequence
        expenseId
        expenseDate
        employeeId
        note
        status
        totalAmountWithVat
        totalAmountWithoutVat
        companyTeamId
        expenseCategory {
          expenseCategoryId
          title
          titleEn
        }
        expenseRequestId
        expenseRequest {
          expenseRequestId
          docSequence
          title
          totalAmount
          cashAdvances {
            amount
          }
        }
        expenseCardTransactions {
          cardTransaction {
            amount
          }
        }
      }
    }
  }
`;

export const useExpExpensesQuery = (variables?: MobileExpensesQueryVariables) => {
  return useLazyQuery<MobileExpensesQuery, MobileExpensesQueryVariables>(QUERY_EXPENSES_BY_IDS, { variables });
};
