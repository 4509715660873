// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Platform, StatusBar } from "react-native";
import React, { useCallback, useRef } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import { enableScreens } from "react-native-screens";
import { useTranslation } from "react-i18next";

import { useAuth } from "contexts/AuthContext";
import { FullPageSpinner, useWindowDimensions } from "components";
import { linkingWeb } from "./LinkingConfiguration";
import { STACK_LOGIN, STACK_NOT_LOGIN, STACK_PUBLIC_WEB } from "./Flows";
import { navigationRef } from "./RootNavigation";
import { analyticService } from "services/AnalyticsService";
import { SCREEN_NAME_TRACKING } from "./ScreenName";

const Stack = createStackNavigator();

enableScreens(true);
const AppStack = () => {
  const { width } = useWindowDimensions();

  const { user } = useAuth();

  const routeNameRef = useRef<string | null>(null);
  const { t } = useTranslation("navigation/AppStack");

  const onStateChange = useCallback(() => {
    const previousRouteName = routeNameRef.current;
    const route = navigationRef.current?.getCurrentRoute();
    const routeName = route?.name;
    const routeParams = route?.params;

    if (routeParams?.resourceId === "widget") {
      analyticService.logEvent({
        name: `Tapped Widget Deep Link ${SCREEN_NAME_TRACKING[routeName]}`,
      });
    }

    if (previousRouteName !== routeName) {
      const params = navigationRef.current?.getCurrentRoute().params;
      analyticService.logEvent({
        name: `Viewed ${SCREEN_NAME_TRACKING[routeName] || routeName}`,
        properties: {
          route_params: params,
        },
      });
    }
    routeNameRef.current = routeName;
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linkingWeb}
      onStateChange={onStateChange}
      fallback={<FullPageSpinner />}
    >
      <StatusBar translucent {...(Platform.OS === "android" ? { backgroundColor: "transparent" } : {})} />
      <Stack.Navigator
        screenOptions={{
          cardStyle: {
            width: "100%",
            height: 1,
            alignSelf: "center",
          },
        }}
      >
        {user?.token ? (
          <Stack.Group navigationKey="user">
            {STACK_LOGIN({ t }).map((item) => {
              return <Stack.Screen {...item} key={item.name} />;
            })}
          </Stack.Group>
        ) : (
          <Stack.Group navigationKey="guest-web">
            {STACK_NOT_LOGIN(t).map((item) => {
              return <Stack.Screen {...item} key={item.name} />;
            })}
          </Stack.Group>
        )}
        {Platform.OS === "web" && (
          <Stack.Group navigationKey="public-web">
            {STACK_PUBLIC_WEB.map((item) => {
              return <Stack.Screen {...item} key={item.name} />;
            })}
          </Stack.Group>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AppStack;
