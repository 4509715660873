import { FC } from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";
import { analyticService } from "services/AnalyticsService";

interface TouchableOpacityCustomProps extends TouchableOpacityProps {
  eventName?: string;
}
const TouchableOpacityCustom: FC<TouchableOpacityCustomProps> = ({ eventName, onPress, children, ...props }) => {
  const handleClick = (e) => {
    if (eventName) {
      analyticService.logEvent({ name: eventName });
    }
    onPress?.(e);
  };
  return (
    <TouchableOpacity {...props} onPress={handleClick}>
      {children}
    </TouchableOpacity>
  );
};
export default TouchableOpacityCustom;
