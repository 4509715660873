import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  AppText,
  BackHeader,
  IconCustom,
  Line,
  ProgressBarCircle,
  AlertNotification,
  AlertNotificationHandle,
} from "components";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { CONSTANTS, REQUEST_TYPE } from "constants/constants";
import SCREEN_NAME from "navigation/ScreenName";
import { navigate } from "navigation/RootNavigation";
import { BaseActionItem, useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";
import { useMasterData } from "contexts/MasterDataContext";
import { BizziBotWarning2 } from "assets/images/svg/icons";
import { useIsExpenseRequestEnabled } from "contexts/FeatureManagementContext";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

export const ActionItem = ({ item, alertRef }: { item: BaseActionItem; alertRef: AlertNotificationHandle }) => {
  const { title, childrenActions } = item;
  const { setCurrentAction } = useWalkthroughGuide();
  const { requestTypes } = useMasterData();
  const { t } = useTranslation("app/screens/StartGuideScreen");

  const handleNavigateToActionItem = () => {
    if (item?.code === REQUEST_TYPE.travel && !requestTypes?.find((item) => item === REQUEST_TYPE.travel)) {
      alertRef.info({
        icon: <BizziBotWarning2 />,
        title: t("not_enable_travel_request_title"),
        description: t("not_enable_travel_request_description"),
        confirmText: t("understood"),
        onConfirm: () => {
          alertRef?.close();
        },
      });
      return;
    }
    analyticService.logEvent({
      name: EVENT.WALKTHROUGH.TAP_ONBOARDING_COURSE,
      properties: { course_name: item.title },
    });
    navigate(SCREEN_NAME.ActionItemDetailScreen);
    setCurrentAction(item);
  };
  const numberActionsIsCompleted = childrenActions?.filter((action) => action.isCompleted).length;
  return (
    <TouchableOpacity style={styles.actionItemContainer} onPress={handleNavigateToActionItem}>
      <AppText style={styles.actionTitle}>{title}</AppText>
      <View style={styles.progressBarContainer}>
        <ProgressBarCircle
          percent={numberActionsIsCompleted / childrenActions.length}
          size={36}
          strokeWidth={4}
          strokeColor={Colors.success50}
        >
          <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
            {`${numberActionsIsCompleted}/${childrenActions.length}`}
          </AppText>
        </ProgressBarCircle>
        <IconCustom name="keyboard-arrow-right" />
      </View>
    </TouchableOpacity>
  );
};
const StartGuideScreen = ({ navigation }) => {
  const { t } = useTranslation("app/screens/StartGuideScreen");
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("start_guide_screen_title")} />,
    } as any);
  }, [t]);
  const alertRef = useRef<AlertNotificationHandle>();

  const { getActionItemsData } = useWalkthroughGuide();
  const [actions, setActions] = useState<BaseActionItem[]>([]);
  const isExpenseRequestEnabled = useIsExpenseRequestEnabled();
  const initActionData = async () => {
    const actions = await getActionItemsData();
    const newActions = actions.filter(
      (item) => (item.code !== REQUEST_TYPE.travel && item.code !== REQUEST_TYPE.other) || isExpenseRequestEnabled
    );
    setActions(newActions);
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      initActionData();
    });
    return unsubscribe;
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <AppText style={styles.actionDescription} color={Colors.grayscale70}>
          {t("start_guide_description")}
        </AppText>
        {actions.map((item, index) => (
          <Fragment key={index}>
            <ActionItem item={item} alertRef={alertRef.current} />
            <Line />
          </Fragment>
        ))}
      </ScrollView>
      <AlertNotification ref={alertRef} />
    </View>
  );
};
export default StartGuideScreen;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingTop: 10,
  },
  actionItemContainer: {
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    gap: 8,
  },
  actionDescription: { ...Fonts.BodyMedium, marginBottom: 14, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  actionTitle: { ...Fonts.BodyMedium, flex: 1 },
  progressBarContainer: { flexDirection: "row", gap: 6, alignItems: "center" },
});
