import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#37B24D" }) {
  return (
    <Svg width={12} height={7} viewBox="0 0 12 7" fill="none">
      <Path
        d="M5.47 6.53a.75.75 0 001.06 0l4.773-4.773a.75.75 0 10-1.06-1.06L6 4.939 1.757.697a.75.75 0 00-1.06 1.06L5.47 6.53zm-.22-1.28a.75.75 0 001.5 0h-1.5zm1.5.5a.75.75 0 00-1.5 0h1.5zM5.25 5v.25h1.5V5h-1.5zm0 .75V6h1.5v-.25h-1.5z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
