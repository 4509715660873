import React, { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { AppText, CurrencyText, IconCustom } from "components";
import { Colors, Fonts } from "theme";
import StatusExpense from "components/ExpenseItem/StatusExpense";
import { CONSTANTS, EXPENSE_STATUS } from "constants/constants";
import { MobileExpExpenseRequestDetailQuery } from "types";
interface ExpenseSelectItemProps {
  item: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]["expenses"][0];
  isChecked?: boolean;
  handleSelectExpense?: (
    item: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]["expenses"][0]
  ) => () => void;
  handleNavigateDetail?: (id?: string) => () => void;
  isAdvanceRequest?: boolean;
}
const ExpenseSelectItem: FC<ExpenseSelectItemProps> = ({
  isChecked,
  item,
  handleSelectExpense,
  handleNavigateDetail,
  isAdvanceRequest,
}) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const disabled = isAdvanceRequest && item?.status !== EXPENSE_STATUS.READY;
  return (
    <TouchableOpacity style={styles.expenseItem} onPress={handleSelectExpense(item)}>
      <View style={styles.expenseBody}>
        {isChecked ? (
          <IconCustom name="selected-category" />
        ) : (
          <View style={styles.expenseIcon}>
            <IconCustom name="sell" />
          </View>
        )}
        <View style={styles.contentContainer}>
          {Boolean(item?.expenseCardTransactions?.[0]) && (
            <AppText style={[Fonts.BodySmall, { marginBottom: 3 }]} color={Colors.grayscale60}>
              {t("expense_from_transaction")}
            </AppText>
          )}
          <View style={styles.titleContainer}>
            <AppText
              style={[Fonts.BodyMedium, styles.flex, { paddingRight: 5 }]}
              color={disabled ? Colors.grayscale60 : Colors.grayscale100}
              numberOfLines={1}
            >
              {item?.note || t("need_explanation")}
            </AppText>
            <CurrencyText style={Fonts.NumericN200} color={disabled ? Colors.grayscale60 : Colors.grayscale100}>
              {item?.totalAmountWithVat}
            </CurrencyText>
          </View>
          <View style={styles.docSequenceContainer}>
            <AppText style={Fonts.BodySmall} color={disabled ? Colors.grayscale60 : Colors.grayscale100}>
              {item?.docSequence}
            </AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {dayjs(item?.expenseDate).format(CONSTANTS.FORMAT_DAY)}
            </AppText>
          </View>
          <View style={styles.statusContainer}>
            <StatusExpense textColor={disabled ? Colors.grayscale60 : Colors.grayscale100} value={item?.status} />
            <TouchableOpacity onPress={handleNavigateDetail(item?.expenseId)} style={styles.viewButton}>
              <AppText style={Fonts.BodySmall} color={Colors.primary50}>
                {t("view")}
              </AppText>
              <IconCustom name="arrow-right-alt" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};
export default ExpenseSelectItem;
const styles = StyleSheet.create({
  expenseItem: {
    paddingVertical: 0,
    paddingHorizontal: 15,
    borderRadius: 8,
    backgroundColor: Colors.white,
  },
  contentContainer: {
    flex: 1,
  },
  titleContainer: { flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", flex: 1 },
  flex: { flex: 1 },
  docSequenceContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginTop: 3,
  },
  expenseBody: { flexDirection: "row", alignItems: "center", paddingVertical: 12, gap: 16 },
  viewButton: {
    flexDirection: "row",
    gap: 4,
  },
  statusContainer: {
    marginTop: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  expenseIcon: {
    width: 48,
    height: 48,
    borderRadius: 48 / 2,
    backgroundColor: Colors.primary0,
    justifyContent: "center",
    alignItems: "center",
  },
});
