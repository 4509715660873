import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { AppText } from "components/index";
import { Colors, FontTypes } from "theme";
import { useFeatureManagement } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import dayjs from "dayjs";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";

interface NewFeatureTagProps {
  style?: StyleProp<ViewStyle>;
  featureName: string;
}
export interface NewFeatureTagMethods {
  markViewedFeature?: () => void;
}
const NewFeatureTag = forwardRef<NewFeatureTagMethods, NewFeatureTagProps>(({ style, featureName }, ref) => {
  useImperativeHandle(ref, () => ({
    markViewedFeature: async () => {
      const viewedFeatureIds = await AsyncStorage.getItem(Keys.VIEWED_NEW_FEATURE_IDS);
      const viewedFeatureIdsJson = viewedFeatureIds ? JSON.parse(viewedFeatureIds) : [];
      const isViewedFeature = viewedFeatureIdsJson.find((item) => item === featureName);
      if (!isViewedFeature) {
        await AsyncStorage.setItem(
          Keys.VIEWED_NEW_FEATURE_IDS,
          JSON.stringify([...viewedFeatureIdsJson, ...[featureName]])
        );
      }
      handleCheckIsNewFeature();
    },
  }));
  const { features } = useFeatureManagement();
  const [isNewFeature, setIsNewFeature] = useState(false);
  const handleCheckIsNewFeature = async () => {
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_PROD_HINT] ?? {};
    if (!enabled || !customFields?.productHint?.mobile) {
      setIsNewFeature(false);
      return;
    }
    const now = dayjs();
    const productHintFeature = customFields?.productHint?.mobile?.find(
      (item) => item?.feature === featureName && now.isAfter(item?.from_date) && now.isBefore(item?.to_date)
    );
    if (!productHintFeature) {
      setIsNewFeature(false);
      return;
    }
    if (productHintFeature.hidden_after_seen) {
      const viewedFeatureIds = await AsyncStorage.getItem(Keys.VIEWED_NEW_FEATURE_IDS);
      const isViewedFeature = viewedFeatureIds ? JSON.parse(viewedFeatureIds).includes(featureName) : false;
      setIsNewFeature(!isViewedFeature);
      return;
    }
    setIsNewFeature(true);
  };

  useEffect(() => {
    if (Object.keys(features)?.length > 0) {
      handleCheckIsNewFeature();
    }
  }, [features]);

  if (!isNewFeature) {
    return null;
  }

  return (
    <View style={[styles.newFeature, style]}>
      <AppText style={styles.newTitle} color={Colors.white}>
        New
      </AppText>
    </View>
  );
});
export default NewFeatureTag;

const styles = StyleSheet.create({
  newFeature: {
    minHeight: 16,
    alignItems: "center",
    backgroundColor: Colors.alert50,
    borderRadius: 8,
    justifyContent: "center",
    width: 26,
  },
  newTitle: {
    fontFamily: FontTypes.medium,
    fontSize: 8,
  },
});
