import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 18, color = "#389E0D", ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 18H2a2 2 0 01-2-2V2a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2zM7.736 8.647L6 13.5h2.138l.828-2.906.875 2.906H12l-1.747-4.787L11.902 4.5h-2.14l-.739 2.557L8.318 4.5H6.101l1.635 4.147z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
