import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { TFunction, useTranslation } from "react-i18next";

import {
  AlertNotification,
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetTextInputCustom,
  Button,
  ToastManager,
} from "components";
import { CancelReportWarningIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { CustomRecord, SERVER_ERROR_CODE } from "utils/serverCode";
import useCancelExpenseReport from "hooks/reports/useCancelExpenseReport";
import { goBack } from "navigation/RootNavigation";
import { EVENT } from "constants/Tracking";
import { analyticService } from "services/AnalyticsService";
import SCREEN_NAME from "navigation/ScreenName";
import { executeRouteFunction } from "utils/route";
import { useRoute } from "@react-navigation/native";

interface CancelExpenseReportModalProps {
  reportId: string;
  title?: string;
  onRefreshData?: () => void;
}

const SERVER_ERROR_CODE_TEXT: CustomRecord<SERVER_ERROR_CODE, Parameters<TFunction>> = {
  [SERVER_ERROR_CODE.UNKNOWN_ERROR]: ["unknown_error"],
  [SERVER_ERROR_CODE.EXPENSE_REPORT_NOT_FOUND]: ["cannot_cancel_report"],
  [SERVER_ERROR_CODE.CANCEL_EXPENSE_REPORT_FAILED]: ["cancel_expense_report_failed"],
  [SERVER_ERROR_CODE.UNSUPPORTED_CANCEL_OBJECT_TYPE]: ["unsupported_cancel_object_type"],
};

const CancelExpenseReportModal = forwardRef<Partial<BottomSheetModalCustomMethods>, CancelExpenseReportModalProps>(
  ({ title, reportId, onRefreshData }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetModalRef?.current?.present();
      },
    }));
    const route = useRoute();
    const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
    const alertNotificationRef = useRef(null);
    const bottomSheetModalRef = useRef<BottomSheetModalCustomMethods>(null);

    const { expenseCancelReport } = useCancelExpenseReport();
    const [reason, setReason] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const handleCancel = async () => {
      setDisabledButton(true);
      Keyboard.dismiss();
      bottomSheetModalRef?.current?.close();
      alertNotificationRef?.current?.loading();
      try {
        const results = await expenseCancelReport({
          report_id: reportId,
          reason,
        });
        if (!results.data?.expenseCancelReport?.success) {
          throw new Error(SERVER_ERROR_CODE.CANCEL_EXPENSE_REPORT_FAILED);
        }
        alertNotificationRef?.current?.close();
        analyticService.logEvent({
          sessionId: SCREEN_NAME.ReportDetailScreen,
          name: EVENT.REPORT.CANCELED_SUCCESS,
        });
        ToastManager.success(t("cancel_expense_report_success"));
        executeRouteFunction(route, "onRefreshData");
        goBack();
        setDisabledButton(false);
      } catch (errors) {
        setDisabledButton(false);
        const err = errors as { message: SERVER_ERROR_CODE };
        const message = SERVER_ERROR_CODE_TEXT[err?.message] || SERVER_ERROR_CODE_TEXT.UNKNOWN_ERROR;
        analyticService.logEvent({
          sessionId: SCREEN_NAME.ReportDetailScreen,
          name: EVENT.REPORT.CANCELED_FAILED,
          properties: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            message: t(message),
          },
        });
        alertNotificationRef.current?.error({
          onTry: handleCancel,
          title: t("cancel_report_failed"),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          description: t(message),
        });
      }
    };

    return (
      <>
        <BottomSheetScrollViewModalCustom ref={bottomSheetModalRef} wrapperByScrollView={false}>
          <TouchableWithoutFeedback onPress={Platform.OS !== "web" && Keyboard.dismiss}>
            <View style={styles.container}>
              <CancelReportWarningIcon />
              <View style={styles.labelContainer}>
                <AppText style={Fonts.H400}>{t("cancel_report")}</AppText>
                <AppText style={[Fonts.BodyMedium, styles.confirmText]} numberOfLines={2}>
                  {t("cancel_report_description")} <AppText style={Fonts.H200}>{` ${title}?`}</AppText>
                </AppText>
              </View>
              <BottomSheetTextInputCustom
                autoFocus
                value={reason}
                onChangeText={setReason}
                placeholder={t("reason_cancel")}
              />
            </View>
          </TouchableWithoutFeedback>

          <View style={styles.footerButton}>
            <Button type="white" style={styles.buttonClose} onPress={() => bottomSheetModalRef?.current?.close()}>
              {t("close")}
            </Button>
            <Button type="danger" disabled={disabledButton} style={styles.buttonCancel} onPress={handleCancel}>
              {t("cancel")}
            </Button>
          </View>
        </BottomSheetScrollViewModalCustom>
        <AlertNotification ref={alertNotificationRef} />
      </>
    );
  }
);
export default CancelExpenseReportModal;
const styles = StyleSheet.create({
  container: { paddingHorizontal: 20, paddingTop: 40, justifyContent: "center", alignItems: "center" },
  labelContainer: { alignItems: "center", marginTop: 20 },
  confirmText: { marginTop: 8, textAlign: "center" },
  textInput: {
    color: Colors.grayscale100,
    borderWidth: 1,
    //paddingTop: 15,

    padding: 10,
    borderColor: Colors.primary50,
    width: "100%",
    height: 120,
    borderRadius: 4,
    marginTop: 20,
  },
  footerButton: { flexDirection: "row", paddingHorizontal: 20, marginTop: 20, paddingBottom: 10 },
  buttonClose: { flex: 1, marginRight: 8 },
  buttonCancel: { flex: 1, marginLeft: 8 },
});
