// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, ReactNode, useEffect } from "react";
import { Image, StyleProp, StyleSheet, TextStyle, useWindowDimensions, ViewStyle } from "react-native";
import { NavigationState, SceneRendererProps, TabBar, TabView } from "react-native-tab-view";

import { AppText } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { useCopilot } from "react-native-copilot";
import * as React from "react";

type TopTabViewSize = "small" | "normal";
type WalkthroughConfig = {
  walkthroughName: string;
  title: string;
  text: string;
  order: number;
  image: any;
  onConfirm?: () => void;
  confirmText?: text;
  borderRadius?: number;
  position: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
};
type TopTabViewProps = {
  size?: TopTabViewSize;
  renderScene: (props: SceneRendererProps & { route: { key: string; title: string } }) => ReactNode;
  routes: { key: string; title: string }[];
  index: number;
  setIndex?: (value: number) => void;
  lazy?: boolean;
  tabBarStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  onTabPress?: (scene: { route: { key: string; title: string } }) => void;
  walkthroughConfig?: WalkthroughConfig[];
};

const TopTabView: FC<TopTabViewProps> = ({
  size = "normal",
  renderScene,
  routes,
  index,
  setIndex,
  lazy = true,
  tabBarStyle,
  labelStyle,
  style,
  onTabPress,
  walkthroughConfig,
}) => {
  const { registerStep } = useCopilot();
  useEffect(() => {
    if (walkthroughConfig) {
      walkthroughConfig.forEach((config) => {
        registerStep({
          walkthroughName: config.walkthroughName,
          name: `${config.order}_${config.walkthroughName}`,
          order: config.order,
          text: config.title ? `${config.title}${CONSTANTS.WALK_THROUGH.AddTitleKey}${config.text}` : config.text,
          borderRadius: config.borderRadius,
          image: <Image resizeMode="contain" source={config.image as any} style={styles.bizziBotImage} />,
          onConfirm: config.onConfirm,
          confirmText: config.confirmText,
          measure: async () => {
            return new Promise((resolve) => {
              resolve({
                x: config.position.x,
                y: config.position.y,
                width: config.position.width,
                height: config.position.height,
              });
            });
          },
        });
      });
    }
  }, []);
  const renderTabBar = (
    props: SceneRendererProps & {
      navigationState: NavigationState<{
        key: string;
        title: string;
      }>;
    }
  ) => (
    <TabBar
      {...props}
      onTabPress={onTabPress}
      pressColor={"transparent"}
      indicatorStyle={[styles.indicatorStyle, size === "small" && styles.indicatorStyleSmall]}
      style={[styles.tabBarStyle, size === "small" && styles.tabBarStyleSmall, tabBarStyle]}
      renderLabel={({ route: tabBarRoute, focused }) => (
        <AppText
          style={[Fonts.H200, size === "small" && styles.labelStyleSmall, labelStyle]}
          color={focused ? Colors.grayscale100 : Colors.grayscale80}
        >
          {tabBarRoute.title}
        </AppText>
      )}
    />
  );
  const { width } = useWindowDimensions();
  return (
    <TabView
      lazy={lazy}
      renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width }}
      style={style}
    />
  );
};
export default TopTabView;
const styles = StyleSheet.create({
  tabBarStyle: {
    elevation: 0,
    backgroundColor: Colors.grayscale05,
    height: 48,
    borderRadius: 40,
    marginBottom: 3,
    marginHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  indicatorStyle: {
    height: 40,
    marginBottom: 4,
    borderRadius: 40,
    backgroundColor: Colors.white,
    borderRightColor: Colors.grayscale05,
    borderRightWidth: 4,
    borderLeftColor: Colors.grayscale05,
    borderLeftWidth: 4,
  },
  tabBarStyleSmall: { height: 40 },
  indicatorStyleSmall: { height: 32 },
  labelStyleSmall: { marginTop: -7 },
  bizziBotImage: { width: 84, height: 91 },
});
