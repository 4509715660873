import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MobileNtfMarkAllNotificationsMutation, MobileNtfMarkAllNotificationsMutationVariables } from "types";

const MUTATION = gql`
  mutation MobileNtfMarkAllNotifications($input: NtfMarkAllNotificationsInput!) {
    ntfMarkAllNotifications(input: $input) {
      success
    }
  }
`;

const useMarkAllNotification = () => {
  return useMutation<MobileNtfMarkAllNotificationsMutation, MobileNtfMarkAllNotificationsMutationVariables>(MUTATION);
};

export default useMarkAllNotification;
