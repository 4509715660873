import { MobileOrgTeamsQuery } from "types";

export enum TIME_FILTER_OPTIONS {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
  YEAR_TO_DATE = "YEAR_TO_DATE",
  CUSTOMIZE = "CUSTOMIZE",
}
export type TIME_FILTER = {
  title: string;
  value: TIME_FILTER_OPTIONS;
}[];
export enum COMPARE_TYPE {
  SAME_PERIOD_BEFORE = "SAME_PERIOD_BEFORE",
  SAME_PERIOD_LAST_YEAR = "SAME_PERIOD_LAST_YEAR",
}
export type OrgTeam = {
  teamId: string;
  code?: string;
  name?: string;
  nameEn?: string | undefined;
  teamType?: { teamTypeId: string; name: string; nameEn?: string };
};
