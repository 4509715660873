import { StyleSheet, View } from "react-native";

import { AppText } from "app/components";
import { TouchableOpacity } from "react-native-gesture-handler";
import { WebView } from "components";
import { CloseIcon } from "assets/images/svg/icons";
import { Fonts } from "theme";
import { goBack } from "navigation/RootNavigation";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import { useAuth } from "contexts/AuthContext";
import webViewHelper, { DefinedMessageNames } from "utils/webViewHelper";
import config from "app/config";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";

const LoginWithSSOScreen = () => {
  const { refreshDataUser } = useAuth();
  const { t } = useTranslation("screens/Auth/Login");

  const handleWebViewEvent = async (event) => {
    const message = webViewHelper.getMessageFromEvent(event);
    if (!message) {
      return;
    }

    switch (message.name) {
      case DefinedMessageNames.BackToLogin:
        goBack();
        break;
      case DefinedMessageNames.ForwardToken:
        if (message.payload.status === "success" && message.payload.data.token) {
          await AsyncStorage.setItem(Keys.token, message.payload.data.token);
          await AsyncStorage.setItem(Keys.refreshToken, message.payload.data.refreshToken);
          await refreshDataUser();
        } else {
          goBack();
        }
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <AppText style={[Fonts.H300, styles.title]}>{t("login_with_sso")}</AppText>
        <TouchableOpacity onPress={() => goBack()}>
          <CloseIcon />
        </TouchableOpacity>
      </View>

      <WebView
        notRedirectUrlToBrowser={true}
        loadingAssertion={(url) => {
          const loadingWhiteList = [config.ACCOUNT_ENDPOINT];
          if (loadingWhiteList.some((item) => url.startsWith(item))) {
            return true;
          }
          return false;
        }}
        uri={`${config.ACCOUNT_ENDPOINT}/user/auth/sso`}
        webViewProps={{
          injectedJavaScript: `document.cookie='language=${i18n.language === LanguageStatus.VN ? "vi" : "en"}';`,
          onMessage: handleWebViewEvent,
          javaScriptEnabled: true,
          domStorageEnabled: true,
          incognito: true,
          bounces: false,
        }}
      />
    </View>
  );
};

export default LoginWithSSOScreen;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  title: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
    flex: 1,
  },
});
