import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, color = "#0F172A" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        d="M15 19.918l-6.52-6.52c-.77-.77-.77-2.03 0-2.8L15 4.078"
        stroke={color}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
