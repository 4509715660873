import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <G clipPath="url(#clip0_5284_330414)">
        <Path
          d="M8.577 2l6.351 11a.667.667 0 01-.577 1H1.649a.667.667 0 01-.577-1l6.35-11a.667.667 0 011.155 0zm-1.244 8.666V12h1.334v-1.334H7.333zm0-4.666v3.333h1.334V6H7.333z"
          fill="#F59E0B"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5284_330414">
          <Path fill="#fff" d="M0 0H16V16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
