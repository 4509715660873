import { StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, BarChartCompareHorizontal, LoadingView } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { MobileExpInsightsSpendingEmployeesQuery } from "types";
import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SCREEN_NAME from "navigation/ScreenName";
import { CHART_TYPE } from "screens/ExpenseOverview/DashboardDetailScreen/types";
import { useNavigation } from "@react-navigation/native";

interface EmployeeChartSectionProps {
  legend?: ReactElement;
  data: MobileExpInsightsSpendingEmployeesQuery["expInsights"]["topSpendingEmployees"];
  isCompare?: boolean;
  loading: boolean;
  fromDate: string;
  toDate: string;
  companyTeamId?: string;
}
const EmployeeChartSection: FC<EmployeeChartSectionProps> = ({
  legend,
  data,
  isCompare,
  loading,
  fromDate,
  toDate,
  companyTeamId,
}) => {
  const { t } = useTranslation("app/screens/ExpenseOverview/ExpenseOverviewScreen");
  const navigation = useNavigation<any>();

  const employees = useMemo(() => {
    if (!data?.employees) {
      return [];
    }
    const anotherEmployees = {
      employees: { fullname: t("other_employees"), totalAmount: data?.otherAmount, employeeId: "other" },
    } as unknown as MobileExpInsightsSpendingEmployeesQuery["expInsights"]["topSpendingEmployees"]["employees"][0];
    const newEmployees = data?.employees?.sort((a, b) => b.totalAmount - a.totalAmount) || [];
    if (data?.totalEmployees > newEmployees?.length) {
      return [...newEmployees, ...[anotherEmployees]];
    }
    return newEmployees as unknown as MobileExpInsightsSpendingEmployeesQuery["expInsights"]["topSpendingEmployees"]["employees"];
  }, [data]);

  const chartData = useMemo(() => {
    return employees.map((employee: any) => ({
      title: employee.fullname,
      thisMonthAmount: employee.totalAmount ?? 0,
      prevMonthAmount: employee.totalAmountPrevious ?? 0,
      diffPercentage: employee.totalAmountDiffPercentage ?? 0,
      metadata: employee,
    }));
  }, [employees]);

  const handleNavigate = (item) => () => {
    navigation.navigate(SCREEN_NAME.DashboardDetailScreen, {
      title: t("expenses_by_employee"),
      subTitle: item?.fullname,
      companyTeamId,
      employeeId: item?.employeeId,
      fromDate,
      toDate,
      type: item?.employeeId ? CHART_TYPE.CATEGORY : CHART_TYPE.EMPLOYEE,
    });
  };

  return (
    <View style={styles.container}>
      <AppText style={Fonts.H300} color={Colors.primary100}>
        {t("expense_by_employee")}
      </AppText>
      {loading ? (
        <LoadingView style={styles.loadingView} />
      ) : employees?.length ? (
        <>
          {legend}
          <BarChartCompareHorizontal
            data={chartData}
            showPercent={isCompare}
            onItemPress={(metadata) => {
              if (metadata?.employeeId === "other") {
                return;
              }
              const handler = handleNavigate(metadata);
              handler();
            }}
          />
          <TouchableOpacity style={styles.viewDetailButton} onPress={handleNavigate({ fullname: t("all_employee") })}>
            <AppText style={Fonts.BodySmall}>{t("view_detail_employees")}</AppText>
          </TouchableOpacity>
        </>
      ) : (
        <AppText style={styles.emptyText} color={Colors.grayscale60}>
          {t("no_data")}
        </AppText>
      )}
    </View>
  );
};
export default EmployeeChartSection;
const styles = StyleSheet.create({
  container: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingVertical: 20, gap: 16 },
  loadingView: { height: 250 },
  viewDetailButton: {
    marginTop: 15,
    height: 32,
    backgroundColor: Colors.primary0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  emptyText: { ...Fonts.BodyMedium, textAlign: "center" },
});
