import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 135, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 135 135" fill="none" {...props}>
      <Path fill="#fff" d="M0 0h135v135H0z" />
      <Path
        d="M113.906 127.93H21.094C9.463 127.93 0 118.467 0 106.836c0-11.631 9.463-21.094 21.094-21.094h92.812c11.631 0 21.094 9.463 21.094 21.094 0 11.631-9.463 21.094-21.094 21.094z"
        fill="#E6F4FF"
      />
      <Path
        d="M54.316 113.428a6.592 6.592 0 100-13.185 6.592 6.592 0 000 13.185zM27.95 113.428a6.592 6.592 0 10-.002-13.185 6.592 6.592 0 00.001 13.185z"
        fill="#7F8A93"
      />
      <Path
        d="M113.906 85.742H67.5v42.188h46.406c11.631 0 21.094-9.463 21.094-21.094 0-11.631-9.463-21.094-21.094-21.094z"
        fill="#B3DCFF"
      />
      <Path
        d="M80.684 113.428a6.592 6.592 0 10-.002-13.185 6.592 6.592 0 00.002 13.185zM107.051 113.428a6.593 6.593 0 10-.002-13.186 6.593 6.593 0 00.002 13.186z"
        fill="#4D5C6A"
      />
      <Path
        d="M83.32 40.918H51.68a3.955 3.955 0 01-3.955-3.955V25.889c0-10.904 8.87-19.776 19.775-19.776 10.904 0 19.775 8.872 19.775 19.776v11.074a3.955 3.955 0 01-3.955 3.955zm-27.685-7.91h23.73v-7.12c0-6.542-5.322-11.865-11.865-11.865-6.542 0-11.865 5.323-11.865 11.866v7.119z"
        fill="#7F8A93"
      />
      <Path
        d="M67.5 6.113v7.91c6.543 0 11.865 5.323 11.865 11.866v7.119H67.5v7.91h15.82a3.955 3.955 0 003.955-3.955V25.889c0-10.904-8.87-19.776-19.775-19.776z"
        fill="#4D5C6A"
      />
      <Path
        d="M92.549 72.295H42.45a3.955 3.955 0 01-3.955-3.955V36.963a3.955 3.955 0 013.955-3.955H92.55a3.955 3.955 0 013.955 3.955V68.34a3.955 3.955 0 01-3.955 3.955z"
        fill="#33A1FF"
      />
      <Path
        d="M92.549 33.008H67.5v39.287h25.049a3.955 3.955 0 003.955-3.955V36.963a3.955 3.955 0 00-3.955-3.955z"
        fill="#006DCC"
      />
      <Path
        d="M74.092 50.147a3.955 3.955 0 00-3.955-3.956h-5.274a3.953 3.953 0 00-1.318 7.68v1.549a3.955 3.955 0 007.91 0v-1.548a3.953 3.953 0 002.637-3.725z"
        fill="#006DCC"
      />
      <Path
        d="M70.137 46.191H67.5v13.184a3.955 3.955 0 003.955-3.955v-1.548a3.953 3.953 0 00-1.318-7.68z"
        fill="#33A1FF"
      />
    </Svg>
  );
}

export default SvgComponent;
