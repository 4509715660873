import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ stroke = "#1F71F4" }) {
  return (
    <Svg width={21} height={20} viewBox="0 0 21 20" fill="none">
      <Path
        d="M12.907 4.233a8.35 8.35 0 00-2.409-.358A7.223 7.223 0 003.273 11.1a7.225 7.225 0 1013.234-4.008M13.94 4.433L11.53 1.667M13.941 4.433l-2.808 2.05"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
