import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path transform="matrix(0 1 1 0 0 0)" fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M15.588 9.78V3.25a.726.726 0 00-.215-.535.726.726 0 00-.535-.215.726.726 0 00-.534.215.726.726 0 00-.216.535v6.53l-2.307-2.307a.715.715 0 00-.515-.212.748.748 0 00-.54.212.735.735 0 00-.232.535c0 .2.078.38.233.534l3.479 3.48a.83.83 0 00.632.255.83.83 0 00.633-.256l3.494-3.494a.705.705 0 00.22-.522.742.742 0 00-.235-.532.783.783 0 00-.527-.225.7.7 0 00-.527.225l-2.308 2.308zm-5.682 4.44l2.307 2.307c.139.138.31.21.515.212a.748.748 0 00.54-.212.735.735 0 00.232-.535.735.735 0 00-.233-.534l-3.479-3.48a.828.828 0 00-.632-.255.828.828 0 00-.633.256l-3.494 3.494a.705.705 0 00-.22.522c.002.2.08.377.235.532.155.145.331.22.527.225a.7.7 0 00.527-.225l2.308-2.308v6.531c0 .213.072.391.215.535a.726.726 0 00.535.215.726.726 0 00.534-.215.726.726 0 00.216-.535v-6.53z"
          fill="#94A3B8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
