import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={16} height={16}>
        <Path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M8 10.41c.153 0 .28-.052.384-.155a.521.521 0 00.154-.383.521.521 0 00-.154-.384A.521.521 0 008 9.333a.521.521 0 00-.384.155.521.521 0 00-.154.384c0 .152.051.28.154.383A.521.521 0 008 10.41zm0-2.27c.142 0 .26-.047.356-.143a.484.484 0 00.144-.356V5.423a.484.484 0 00-.144-.356A.484.484 0 008 4.923a.483.483 0 00-.356.144.484.484 0 00-.144.356V7.64c0 .141.048.26.144.356A.484.484 0 008 8.141zm0 6.107a1.24 1.24 0 01-.398-.066c-1.405-.5-2.524-1.387-3.355-2.66C3.416 10.248 3 8.874 3 7.4V4.397c0-.253.073-.48.218-.684.146-.202.334-.349.565-.44l3.795-1.417c.143-.051.284-.077.422-.077s.279.026.422.077l3.795 1.417c.23.091.419.238.564.44.146.203.219.431.219.684V7.4c0 1.474-.416 2.848-1.247 4.12-.831 1.274-1.95 2.16-3.355 2.66a1.124 1.124 0 01-.398.067z"
          fill="#E11934"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
