import { StyleSheet, KeyboardAvoidingView } from "react-native";

import Colors from "constants/Colors";

const BizziKeyboardAvoidingView = (props) => {
  const { children, style } = props;
  return (
    <KeyboardAvoidingView style={[styles.mainContainer, style]} enabled {...props}>
      {children}
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: Colors.whiteBackground,
    flex: 1,
  },
});

export default BizziKeyboardAvoidingView;
