import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileExpSearchLocationsQuery, MobileExpSearchLocationsQueryVariables } from "types";

const QUERY = gql`
  query MobileExpSearchLocations($where: ExpSearchLocationsWhereInput!) {
    expSearchLocations(where: $where) {
      locations {
        code
        countryCode
        createdAt
        locationId
        name
        parentCode
        nameEn
        parent {
          name
          nameEn
        }
      }
    }
  }
`;

const useExpSearchLocations = (variables?: MobileExpSearchLocationsQueryVariables) => {
  return useLazyQuery<MobileExpSearchLocationsQuery, MobileExpSearchLocationsQueryVariables>(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpSearchLocations;
