import { FileBase } from "components/UploadFiles/types";
import {
  ACCOUNT_TYPES,
  ALLOWANCE_TYPE,
  REQUEST_TYPE,
  RESTRICTION_BUDGET_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import { BankAccount } from "screens/Expense/components/ExpenseForm/types";
import RequestStatus from "constants/RequestStatus";
import { MobileExpExpenseRequestTravelUsageQuery } from "types";

enum ExpenseRequestListTabs {
  WAITING_FOR_APPROVAL = "waiting_for_approval",
  MINE = "mine",
  HISTORY = "history",
}

type ExpenseRequestCreateForm = ExpenseRequestCreateInput & {
  isSubmitAction?: boolean;
  attachments: FileBase[];
};

type ExpenseRequestUpdateForm = ExpenseRequestUpdateInput & {
  status?: string;
  isSubmitAction?: boolean;
  attachments: FileBase[];
  newAttachments?: FileBase[];
  removeAttachments?: FileBase[];
};

export enum HOTEL_BOOKING_TYPE {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

type ExpenseRequestFormValues = {
  expenseRequestType: REQUEST_TYPE;
  title?: string;
  companyTeam?: { companyTeamId: string; code: string; name?: string; nameEn?: string };
  budgetDate?: string;
  description?: string;
  note?: string;
  travelDate: {
    fromDate: Date;
    toDate: Date;
  };
  travel?: {
    fromCity: { label?: string; metadata: Location };
    toCity: { label?: string; metadata: Location };
    perDiem: number;
  };
  travelUsage?: MobileExpExpenseRequestTravelUsageQuery["expExpenseRequestTravelUsage"]["periods"];
  allowances?: {
    id?: string;
    foreignAmount?: number;
    amount: number;
    description?: string;
    expenseCategoryId: string;
    type?: ALLOWANCE_TYPE;
    expenseCategory?: {
      title?: string;
      titleEn?: string;
    };
    productName?: string;
    uom?: string; // đơn vị tính
    quantity?: number;
    unitPrice?: number;
    numberOfRooms?: number;
  }[];
  attachments?: any[];
  approverId?: string;
  overBudgets?: Record<string, unknown>;
  status?: string;
  hasCashAdvance?: boolean;
  cashAdvance?: {
    amount: number;
    foreignAmount?: number;
    description: string;
    paymentMethod: ExpPaymentMethod;
  };
  paymentInfo?: {
    type?: ACCOUNT_TYPES;
    bank?: { key?: string; label?: string; metadata?: BankAccount };
    accountNumber?: string;
    accountHolderName?: string;
    bankName?: string;
    bankShortName?: string;
    branchName?: string;
    description?: string;
  };
  customField?: {
    taxCode: string;
    invoiceCode: string;
    vatRate: string;
  };
  flightBooking?: {
    vendorName?: string;
    isEnabled?: boolean;
    isOn?: boolean;
    from?: { location: Location; code: string; name?: string; nameEn?: string };
    to?: { location: Location; code: string; name?: string; nameEn?: string };
    departureDate?: Date;
    departureNote?: string;
    isRoundTrip?: boolean;
    returnDate?: Date;
    returnNote?: string;
    expenseCategory?: {
      expenseCategoryId?: string;
      title?: string;
      titleEn?: string;
    };
  };
  transportationBooking?: {
    isEnabled?: boolean;
    isOn?: boolean;
    from?: { label: string; metadata?: Location };
    to?: { label: string; metadata?: Location };
    departureDate?: Date;
    departureNote?: string;
    isRoundTrip?: boolean;
    returnDate?: Date;
    returnNote?: string;
    expenseCategory?: {
      expenseCategoryId?: string;
      title?: string;
      titleEn?: string;
    };
  };
  hotelBooking?: {
    isEnabled?: boolean;
    isOn?: boolean;
    location?: { label: string; metadata?: Location };
    note?: string;
    expenseCategory?: {
      expenseCategoryId?: string;
      title?: string;
      titleEn?: string;
    };
    /*-- TMG group use case --*/
    hotelType?: HOTEL_BOOKING_TYPE;
    companyHotelCode?: string;
    hotelName?: string;
    numberOfRooms?: number;
    roomRate?: number;
    /*-- end --*/
  };
  hotelBookingDate: {
    fromDate?: Date;
    toDate?: Date;
  };
  currency?: { isOn: boolean; name: string; exchangeRate: number };
  foreignTotalAmountWithVat?: number;
  enablePassenger?: boolean;
  passengers?: { fullname: string }[];
};

enum ExpenseRequestFormAction {
  SAVE_DRAFT = "SAVE_DRAFT",
  SUBMIT = "SUBMIT",
}
type OverBudget = {
  restrictionType: RESTRICTION_BUDGET_TYPE;
  message: string;
  overBudgetAmount?: number;
  expenseCategoryId?: string;
};
type ExpenseRequest = {
  title: string;
  currency: string;
  updatedAt: string;
  totalAmount: number;
  docSequence: string;
  status: RequestStatus;
  expenseRequestId: string;
  expenseRequestType: string;
  companyTeamIds: string[];
  expenseTravels: { expenseTravelId: string }[];
  isLocked?: boolean;
  cashAdvances?: any[];
};

type DateModalInfoData = {
  field?: string | string[];
  visible?: boolean;
  date?: Date;
  minimumDate?: Date;
  maximumDate?: Date;
};
type Airport = {
  code?: string;
  name?: string;
  nameEn?: string;
  location?: Location;
};
type Location = {
  locationId?: string;
  code?: string;
  name?: string;
  nameEn?: string;
};
type FlightBookingSettings = {
  departureAirport: Airport;
  arrivalAirport: Airport;
  isRoundTrip?: boolean;
  departureDate: string;
  returnDate?: string;
  departureNote: string;
  returnNote?: string;
  vendorName?: string;
};

type BusBookingSettings = {
  pickupLocation: Location;
  dropOffLocation: Location;
  isRoundTrip?: boolean;
  departureDate: string;
  returnDate?: string;
  departureNote: string;
  returnNote?: string;
};

type HotelBookingSettings = {
  location: Location;
  checkInDate: string;
  checkOutDate: string;
  note?: string;
  companyHotelCode?: string;
  hotelName?: string;
  numberOfRooms?: number;
  roomRate?: number;
};
type AlertBudgetTracking = {
  object_id?: string;
  restriction_type: RESTRICTION_BUDGET_TYPE;
  message?: string;
  over_budgets?: { company_team_id: string; expense_category_id: string; over_amount: number; expense_date: string }[];
  over_requests?: { company_team_id: string; expense_category_id: string; over_amount: number }[];
  object_type?: TRACKING_OBJECT_TYPE;
};
export type {
  OverBudget,
  ExpenseRequestUpdateForm,
  ExpenseRequestCreateForm,
  ExpenseRequestFormValues,
  ExpenseRequest,
  DateModalInfoData,
  FlightBookingSettings,
  BusBookingSettings,
  HotelBookingSettings,
  Location,
  AlertBudgetTracking,
};
// enum
export { ExpenseRequestListTabs, ExpenseRequestFormAction };
