import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#94A3B8" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.002 21.5a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749zM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20zm-.025-1.192a.655.655 0 00.478-.205.655.655 0 00.205-.478v-.529a4.408 4.408 0 002.106-.874c.63-.478.944-1.202.944-2.172 0-.7-.207-1.32-.62-1.858-.412-.538-1.219-1.024-2.419-1.457-1.051-.36-1.75-.67-2.094-.933-.345-.263-.517-.63-.517-1.102 0-.472.176-.855.53-1.15.353-.295.84-.442 1.462-.442.372 0 .697.07.976.208.279.14.514.325.707.557a.985.985 0 00.423.312c.16.059.312.056.455-.008a.67.67 0 00.4-.394.52.52 0 00-.053-.512 3.502 3.502 0 00-.993-.941 2.695 2.695 0 00-1.257-.426v-.529a.655.655 0 00-.205-.478.655.655 0 00-.478-.205.655.655 0 00-.478.205.655.655 0 00-.205.478v.529c-.852.164-1.507.51-1.964 1.037-.457.528-.686 1.114-.686 1.759 0 .751.228 1.356.683 1.813.455.458 1.197.872 2.225 1.24 1.063.39 1.789.74 2.178 1.05.389.31.583.725.583 1.247 0 .627-.226 1.082-.678 1.366a2.823 2.823 0 01-1.533.426 2.4 2.4 0 01-1.288-.355c-.384-.238-.705-.58-.964-1.03a.76.76 0 00-.41-.354.698.698 0 00-.504.006.668.668 0 00-.39.369.584.584 0 00-.002.52c.28.56.645 1.008 1.095 1.344.45.336.985.57 1.605.704v.579c0 .182.069.341.205.478a.655.655 0 00.478.205z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
