import React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#42526E", size = 16, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.333 8a6.667 6.667 0 1013.334 0A6.667 6.667 0 001.333 8zm8.663-2.247L6.788 8.961l-1.39-1.39-.857.858 2.247 2.246 4.065-4.065-.857-.857z"
        fill={color}
        {...props}
      />
    </Svg>
  );
}

export default SvgComponent;
