import { ScrollView, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { MobileExpExpenseRequestDetailQuery } from "types";
import ExpenseListInfo from "./ExpenseListInfo";
import {
  ExpenseActualListByGroup,
  ExpenseListGroupByCategory,
} from "screens/ExpenseRequest/ExpenseRequestDetailScreen/types";
import AdvancePaymentInfo from "./AdvancePaymentInfo";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { REQUEST_STATUS, REQUEST_TYPE } from "constants/constants";
import ExpenseActualList from "./ExpenseActualList";
import { Colors } from "theme";

interface SpendingPlanningTabProps {
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
  isApproverView?: boolean;
  expenseListGroupByCategory?: ExpenseListGroupByCategory[];
  expenseActualListByGroup?: ExpenseActualListByGroup[];
  onRefreshDetail?: () => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
}
const SpendingPlanningTab = forwardRef<any, SpendingPlanningTabProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    scrollToExpenseActualList: () => {
      scrollViewRef?.current?.scrollTo({ y: positionExpenseActualList.current, animated: true });
    },
    scrollToExpenseSpendingList: () => {
      scrollViewRef?.current?.scrollTo({ y: positionExpenseSpendingList.current, animated: true });
    },
    scrollToCashAdvanceSection: () => {
      scrollViewRef?.current?.scrollTo({ y: positionCashAdvanceSection.current, animated: true });
    },
  }));
  const {
    expenseListGroupByCategory,
    expenseActualListByGroup,
    isApproverView,
    expenseRequest,
    onRefreshDetail,
    contentContainerStyle,
  } = props;
  const [FEATURE_EXP_CASH_ADVANCE] = useIsFeaturesEnabled([FEATURE_FLAGS.FEATURE_EXP_CASH_ADVANCE]);
  const scrollViewRef = useRef<ScrollView>();
  const positionExpenseActualList = useRef(0);
  const positionExpenseSpendingList = useRef(0);
  const positionCashAdvanceSection = useRef(0);
  const handleSetPositionExpenseActualList = (y) => {
    positionExpenseActualList.current = y;
  };
  const handleSetPositionPositionExpenseSpendingList = (y) => {
    positionExpenseSpendingList.current = y;
  };
  const handleSetPositionCashAdvanceSection = (y) => {
    positionCashAdvanceSection.current = y;
  };
  return (
    <View style={styles.container}>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={[styles.contentContainerStyle, contentContainerStyle]}
        showsVerticalScrollIndicator={false}
      >
        {expenseRequest.expenseRequestType !== REQUEST_TYPE.purchase && (
          <ExpenseListInfo
            setPosition={handleSetPositionPositionExpenseSpendingList}
            isApproverView={isApproverView}
            expenseRequest={expenseRequest}
            expenseListByGroup={expenseListGroupByCategory}
          />
        )}
        {expenseRequest?.status === REQUEST_STATUS.APPROVED && (
          <ExpenseActualList
            setPosition={handleSetPositionExpenseActualList}
            onRefreshDetail={onRefreshDetail}
            expenseRequest={expenseRequest}
            expenseActualList={expenseActualListByGroup}
            isLocked={expenseRequest?.isLocked}
          />
        )}
        {FEATURE_EXP_CASH_ADVANCE && (
          <AdvancePaymentInfo
            setPosition={handleSetPositionCashAdvanceSection}
            cashAdvance={expenseRequest?.cashAdvances?.[0]}
          />
        )}
      </ScrollView>
    </View>
  );
});
export default SpendingPlanningTab;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  contentContainerStyle: { gap: 8 },
});
