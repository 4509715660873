import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import React, { FC, useMemo } from "react";
import { MobileExpenseReportDetailsQuery } from "types";
import { useTranslation } from "react-i18next";
import { Colors } from "theme";
import { ApprovalFlowTooltip, ApprovalTimeLine, LoadingView, Section } from "components";
import GeneralInfo from "screens/Report/ReportDetailScreen/components/DetailTab/GeneralInfo";
import { ApprovalTimeLineItem } from "components/ApprovalTimeLine";
import { ALLOCATED_STATUS, APPROVAL_METHOD, EXPENSE_REPORT_STATUS, REQUEST_STATUS } from "constants/constants";
import { ScrollViewCustom } from "components/ScrollViewCustom/ScrollViewCustom";
import SupportingDocument from "screens/Report/ReportDetailScreen/components/DetailTab/SupportingDocument";
import DownloadAttachments from "screens/Report/ReportDetailScreen/components/DetailTab/DownloadAttachments";

interface DetailTabProps {
  expenseReport?: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
  isApproverView?: boolean;
  loading?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  refetch?: () => void;
}

const DetailTab: FC<DetailTabProps> = ({ contentContainerStyle, loading, expenseReport, isApproverView, refetch }) => {
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
  const canDownloadAttachment = [EXPENSE_REPORT_STATUS.SUBMITTED, REQUEST_STATUS.APPROVED].includes(
    expenseReport?.status
  );

  const expenseApprovalRules =
    expenseReport?.allocatedStatus === ALLOCATED_STATUS.IS_ALLOCATED
      ? expenseReport?.parent?.expenseApprovalRules
      : expenseReport?.expenseApprovalRules;

  const approvalTimeLineData: ApprovalTimeLineItem[] = useMemo(() => {
    if (!expenseApprovalRules) {
      return [];
    }
    return expenseApprovalRules?.map((item) => {
      const expenseApprovalLog = expenseReport?.expenseApprovalLogs?.find(
        (i) => i.expenseApprovalRuleId === item.expenseApprovalRuleId
      );
      const approvers = item?.expenseApprovalRuleEmployees?.map((approver) => ({
        isRequestor: approver?.employeeId === expenseReport?.companyEmployee?.employeeId,
        approvalMethod: (approver?.approvalMethod as APPROVAL_METHOD) ?? APPROVAL_METHOD.MANUAL,
        employeeId: approver?.employeeId as string,
        employee: {
          avatar: approver?.employee?.avatarUrl || approver?.employee?.defaultAvatarUrl,
          fullname: approver?.employee?.fullname,
        },
        status: approver.status as number,
      }));
      return {
        approvalFlowName: item?.settings?.approvalFlow?.title ?? "",
        name: item?.name,
        status: item?.status as number,
        updatedAt: item?.updatedAt,
        approvers,
        reason: expenseApprovalLog?.reason,
        rejectedExpenseReport: expenseApprovalLog?.rejectedExpenseReport,
        isPartialApproved: expenseApprovalLog?.isPartialApproved,
      };
    });
  }, [expenseApprovalRules, expenseReport?.companyEmployee?.employeeId]);

  return (
    <View style={styles.container}>
      {loading ? (
        <LoadingView style={styles.loadingView} />
      ) : (
        <ScrollViewCustom
          contentContainerStyle={contentContainerStyle}
          showsVerticalScrollIndicator={false}
          renderContent={({ setLayoutData }) => (
            <>
              <GeneralInfo
                isApproverView={isApproverView}
                expenseReport={expenseReport}
                expenseApprovalRules={expenseApprovalRules}
              />
              <SupportingDocument expenseReport={expenseReport} refetch={refetch} />
              {canDownloadAttachment && <DownloadAttachments expenseReport={expenseReport} />}
              {expenseReport?.status !== EXPENSE_REPORT_STATUS.DRAFT && (
                <Section
                  title={t("approval_flow")}
                  icon={<ApprovalFlowTooltip />}
                  onLayout={(event) => {
                    setLayoutData("approval-flow-section", event.nativeEvent.layout);
                  }}
                >
                  <ApprovalTimeLine data={approvalTimeLineData} />
                </Section>
              )}
            </>
          )}
        />
      )}
    </View>
  );
};

export default DetailTab;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  loadingView: { backgroundColor: Colors.white },
});
