import { useQuery } from "@apollo/client";

import gql from "graphql-tag";
import { useAuth } from "contexts/AuthContext";
import { MobileExpUploadConfigurationQuery, MobileExpUploadConfigurationQueryVariables } from "types";

export const QUERY_UPLOAD_CONFIGURATION = gql`
  query MobileExpUploadConfiguration($companyId: UUID!, $groupId: UUID!) {
    expUploadConfiguration(companyId: $companyId, groupId: $groupId) {
      maxFileSize
      maxFiles
      maxTotalSize
      supportedFileExtensions
    }
  }
`;
const useGetUploadConfiguration = () => {
  const { company, groupId } = useAuth();
  return useQuery<MobileExpUploadConfigurationQuery, MobileExpUploadConfigurationQueryVariables>(
    QUERY_UPLOAD_CONFIGURATION,
    {
      variables: { companyId: company.company_id, groupId },
      skip: !company?.company_id || !groupId,
      fetchPolicy: "cache-and-network",
    }
  );
};

export default useGetUploadConfiguration;
