import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from "react-native";

import { Toast } from "@ant-design/react-native";
import { BizziBotWarning, TrashIcon } from "assets/images/svg/icons";
import { AlertNotification, AlertNotificationHandle, BackHeader, ToastManager, useBackHandler } from "components";
import useInteractionManager from "components/hooks/useInteractionManager";
import { EXPENSE_REPORT_STATUS } from "constants/constants";
import useExpenseDeleteReportMutation from "hooks/reports/useExpenseDeleteReportMutation";
import { useExpExpenseReportDetailsQuery } from "hooks/reports/useExpExpenseReportDetailsQuery";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import CreateOrEditForm from "screens/Report/components/CreateOrEditForm/CreateOrEditForm";
import { Colors } from "theme";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { MobileExpenseReportDetailsQuery } from "types";
import { goBack } from "navigation/RootNavigation";
import { useAuth } from "contexts/AuthContext";
import { useExpExpensesQuery } from "../hooks/useExpExpensesQuery";
import { withTrackingSession } from "services/AnalyticsService";

const ReportEditScreen = ({ navigation, route }) => {
  const [isChangedFormInfo, setIsChangedFormInfo] = useState(false);
  const [formData, setFormData] = useState(null);
  const isReady = useInteractionManager();
  const { t } = useTranslation("app/screens/Report/ReportEditScreen");
  const { expenseReportId } = route?.params ?? {};
  const {
    user: { employee_id: employeeId },
  } = useAuth();
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);

  const { loading, onFetchReportDetail } = useExpExpenseReportDetailsQuery({
    variables: { expenseReportId },
    onCompleted: (data: MobileExpenseReportDetailsQuery) => {
      const detail = data?.expExpenseReportDetails;
      const expenseReportsTeams = detail?.expenseReportsTeams?.[0];
      const formData = {
        expenseReportId: detail.expenseReportId,
        title: detail?.title,
        companyTeamId: expenseReportsTeams?.companyTeamId,
        companyTeam: expenseReportsTeams?.companyTeam,
        paymentMethod: detail?.paymentMethod ?? null,
        approverId: null,
        expenseList: detail?.expenses,
        expenseApprovalLogs: detail?.expenseApprovalLogs,
        status: detail?.status,
      };
      setFormData(formData);
    },
  });
  const { deleteExpenseReport } = useExpenseDeleteReportMutation();

  useEffect(() => {
    onFetchReportDetail();
  }, []);

  const { onRefreshData } = route?.params ?? {};

  const [onFetchExpenseList] = useExpExpensesQuery();

  /*-- on refresh expense list when update expense --> filter by companyTeam --*/
  const onRefreshExpense = async (expenseIds) => {
    const rs = await onFetchExpenseList?.({
      variables: { expenseIds, employeeId },
    });
    setFormData((prevState) => ({ ...prevState, expenseList: rs?.data?.expExpenses?.expenses ?? [] }));
  };

  const onDeleteExpenseReport = useCallback(async () => {
    try {
      alertNotificationRef.current?.loading();
      await deleteExpenseReport({
        variables: { expenseReportId },
      });
      alertNotificationRef.current?.close();
      ToastManager.success(t("expense_report_delete_successfully"));
      navigation.navigate(SCREEN_BOTTOM_TAB.ReportsScreen);
      onRefreshData();
    } catch (error) {
      alertNotificationRef.current?.close();
      Toast.fail(t("an_error_occurred_during_processing"));
    }
  }, [navigation, t, expenseReportId, deleteExpenseReport]);

  const showAlert = useCallback(() => {
    alertNotificationRef.current?.warning({
      icon: <BizziBotWarning />,
      title: t("cancel_title"),
      description: t("cancel_confirm"),
      cancelText: t("close_alert"),
      confirmText: t("cancel"),
      onConfirm: () => {
        alertNotificationRef.current?.close();
        goBack();
      },
    });
  }, [navigation, t]);

  const handleGoBack = useCallback(() => {
    if (!isChangedFormInfo) {
      goBack();
      return;
    }
    showAlert();
  }, [isChangedFormInfo, navigation, showAlert]);

  useBackHandler(() => {
    if (isChangedFormInfo) {
      showAlert();
      return true;
    }
    return false;
  });

  return (
    <BottomSheetModalProvider>
      <BackHeader
        headerTitle={t("update_report")}
        headerRight={
          [EXPENSE_REPORT_STATUS.DRAFT, EXPENSE_REPORT_STATUS.CANCEL].includes(formData?.status) && (
            <TouchableOpacity
              onPress={() => {
                alertNotificationRef.current?.warning({
                  cancelText: t("close"),
                  confirmText: t("confirm"),
                  title: t("delete_expense_report"),
                  description: t("delete_expense_report_description"),
                  onConfirm: onDeleteExpenseReport,
                });
              }}
            >
              <TrashIcon />
            </TouchableOpacity>
          )
        }
        onPress={handleGoBack}
      />
      {loading || !isReady ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color={Colors.primary50} />
        </View>
      ) : (
        <View style={styles.container}>
          <CreateOrEditForm
            id={expenseReportId}
            formData={formData}
            onRefreshExpense={onRefreshExpense}
            onRefreshData={onRefreshData}
            onChangeFormValues={(value) => setIsChangedFormInfo(value)}
          />
          <AlertNotification ref={alertNotificationRef} />
        </View>
      )}
    </BottomSheetModalProvider>
  );
};
export default withTrackingSession(ReportEditScreen, SCREEN_NAME.EditReportScreen);

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  loadingContainer: {
    flex: 1,
    backgroundColor: Colors.grayscale05,
    justifyContent: "center",
    alignItems: "center",
  },
});
