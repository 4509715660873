import { Linking, StyleSheet, View } from "react-native";
import { AppText } from "components";
import { Fonts } from "theme";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "constants/constants";
import { useMemo } from "react";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useFeatureManagement } from "contexts/FeatureManagementContext";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import CarouselUserGuideVideo from "./CarouselUserGuideVideo";

const UserGuideVideo = () => {
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { features } = useFeatureManagement();

  const videos = useMemo(() => {
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_EXP_GET_STARTED] ?? {};
    if (!enabled) {
      return [];
    }
    return customFields?.videos ?? [];
  }, [features]);
  const handlePlayVideo = (id: string, video: string, thumbnail: string) => {
    analyticService.logEvent({ name: EVENT.HOME.TAP_VIEW_GUIDE_VIDEO, properties: { guide_video_id: id } });
    Linking.openURL(video).catch((error) => {
      console.log(error);
    });
  };
  if (!videos?.length) {
    return null;
  }
  return (
    <View style={styles.container}>
      <AppText style={styles.title}>{t("user_guide")}</AppText>
      <CarouselUserGuideVideo videos={videos} handlePlayVideo={handlePlayVideo} />
    </View>
  );
};
export default UserGuideVideo;
const styles = StyleSheet.create({
  container: { marginTop: 30 },
  title: { ...Fonts.H400, marginBottom: 14, marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING },
});
