import { Linking } from "react-native";
import { forwardRef, useImperativeHandle } from "react";

export interface VideoPlayerViewMethods {
  playVideo: (videoUrl: string) => void;
}
interface VideoPlayerViewProps {
  onClosed?: () => void;
}
const VideoPlayerView = forwardRef<VideoPlayerViewMethods, VideoPlayerViewProps>(({ onClosed }, ref) => {
  useImperativeHandle(ref, () => ({
    playVideo: (videoUrl) => {
      handlePlayVideo(videoUrl);
    },
  }));
  const handlePlayVideo = (url) => {
    onClosed?.();
    Linking.openURL(url).catch((error) => {
      console.log(error);
    });
  };

  return null;
});
export default VideoPlayerView;
