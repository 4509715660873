import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <Mask id="a" width={24} height={24} x={0} y={0} maskUnits="userSpaceOnUse">
      <Path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill="#0F172A"
        d="m12 10.438-4.073 4.073a.724.724 0 0 1-.522.213.707.707 0 0 1-.531-.213.717.717 0 0 1-.218-.527c0-.206.073-.382.218-.527l4.494-4.494A.83.83 0 0 1 12 8.708a.83.83 0 0 1 .633.256l4.493 4.493c.139.139.21.313.213.523a.707.707 0 0 1-.213.531.717.717 0 0 1-.526.218.717.717 0 0 1-.527-.218L12 10.438Z"
      />
    </G>
  </Svg>
);
export default SvgComponent;
