import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12 14.662a.828.828 0 01-.633-.256L6.873 9.912a.725.725 0 01-.213-.522.707.707 0 01.213-.532.718.718 0 01.527-.217c.206 0 .382.072.527.217L12 12.93l4.073-4.073a.724.724 0 01.522-.213.707.707 0 01.532.213c.144.145.217.32.217.527a.718.718 0 01-.217.527l-4.495 4.494a.827.827 0 01-.632.256z"
          fill="#94A3B8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
