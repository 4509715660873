import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AlertNotification,
  AlertNotificationHandle,
  BackHeader,
  Button,
  FloatFooterExpand,
  IconCustom,
  TopTabLineView,
  useBackHandler,
  useWindowDimensions,
} from "components";
import React, { FC, useMemo, useRef, useState } from "react";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { useTranslation } from "react-i18next";
import { Route } from "react-native-tab-view";
import { Colors, Fonts } from "theme";
import LuggageSelect from "./LuggageSelect";
import TicketOverview from "../components/TicketOverview";
import SCREEN_NAME from "navigation/ScreenName";
import { useFlightBooking, useFlightBookingApis } from "../context/FlightBookingProvider";
import useBokBookingFlight from "../hooks/useBokBookingFlight";
import { useAuth } from "contexts/AuthContext";
import { BokPassengerType, BookingInformation, FlightTicketType, BaggageBooking } from "../types";
import { BizziBotInfo } from "assets/images/svg/icons";
import i18n from "i18next";
import TicketDetailModal, { TicketDetailModalApi } from "../components/TicketDetailModal";
import FlightTicketAmountDetail from "screens/FlightBooking/components/FlightTicketAmountDetail";
import { goBack } from "navigation/RootNavigation";
import { LuggageScreenProps } from "navigation/type";

const mapToBaggage = (luggage?: BaggageBooking) => {
  if (!luggage?.code) {
    return {};
  }

  return {
    airline: luggage.airline,
    code: luggage.code,
    value: luggage.value,
    name: luggage.name,
    price: luggage.price,
    route: luggage.route,
    startPoint: luggage.startPoint,
    endPoint: luggage.endPoint,
  };
};

const LuggageScreen: FC<LuggageScreenProps> = ({ route, navigation }) => {
  const { t } = useTranslation("app/screens/FlightBooking/LuggageScreen/LuggageScreen");
  const {
    user: { employee_id: employeeId },
    company: { group_id: groupId, company_id: companyId },
  } = useAuth();
  const [reservation] = useBokBookingFlight();
  const { tickets, contact, passenger, baggages } = useFlightBooking();
  const { saveBaggageReturn, saveBaggageDeparture } = useFlightBookingApis();
  const { width } = useWindowDimensions();

  const [index, setIndex] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const alertNotificationRef = useRef<AlertNotificationHandle>();
  const ticketModalRef = useRef<TicketDetailModalApi>(null);

  const handleRePickTicket = (type: FlightTicketType) => () => {
    ticketModalRef.current.close();
    navigation.push(SCREEN_NAME.FlightSearchScreen, {
      ...route.params,
      type,
      fromScreen: SCREEN_NAME.LuggageScreen,
    });
  };
  /*-- reset baggage when user go back previous screen--*/
  useBackHandler(() => {
    handleGoBack();
    return false;
  });
  const handleGoBack = () => {
    saveBaggageReturn(null);
    saveBaggageDeparture(null);
    goBack();
  };
  /*-- end --*/
  const tapRoutes = [
    {
      key: "departure",
      title: `${route.params.fromCode} - ${route.params.toCode}`,
      icon: "flight-takeoff",
    },
    {
      key: "return",
      title: `${route.params.toCode} - ${route.params.fromCode}`,
      icon: "flight-land",
    },
  ];

  const calculatedTabWidth = useMemo(() => {
    return Math.floor(width / tapRoutes.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const departureTab = tickets?.departure && (
    <>
      <TouchableOpacity
        onPress={() => {
          ticketModalRef.current?.showTicket(
            {
              ticket: tickets?.departure,
            },
            {
              icon: <IconCustom name="flight-takeoff" />,
              footer: (
                <Button type="secondary" onPress={handleRePickTicket(FlightTicketType.Departure)}>
                  {t("re_pick_ticket")}
                </Button>
              ),
            }
          );
        }}
      >
        <TicketOverview
          containerStyle={styles.ticketOverview}
          icon={<IconCustom name="flight-takeoff" />}
          data={tickets?.departure}
        />
      </TouchableOpacity>
      <LuggageSelect
        ticket={tickets?.departure}
        value={baggages?.departure}
        onChange={(baggage) => {
          saveBaggageDeparture(baggage);
        }}
      />
    </>
  );

  const returnTab = tickets?.return && (
    <>
      <TouchableOpacity
        onPress={() => {
          ticketModalRef.current?.showTicket(
            {
              ticket: tickets?.return,
            },
            {
              icon: <IconCustom name="flight-takeoff" />,
              footer: (
                <Button type="secondary" onPress={handleRePickTicket(FlightTicketType.Return)}>
                  {t("re_pick_ticket")}
                </Button>
              ),
            }
          );
        }}
      >
        <TicketOverview
          containerStyle={styles.ticketOverview}
          icon={<IconCustom name="flight-land" />}
          data={tickets?.return}
        />
      </TouchableOpacity>
      <LuggageSelect
        ticket={tickets?.return}
        value={baggages?.return}
        onChange={(baggage) => {
          saveBaggageReturn(baggage);
        }}
      />
    </>
  );

  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case "departure":
        return (
          <ScrollView showsVerticalScrollIndicator={false} style={styles.tabContent}>
            {departureTab}
          </ScrollView>
        );
      case "return":
        return (
          <ScrollView showsVerticalScrollIndicator={false} style={styles.tabContent}>
            {returnTab}
          </ScrollView>
        );
      default:
        return null;
    }
  };

  const handleReservation = async () => {
    alertNotificationRef.current.confirm({
      icon: <BizziBotInfo />,
      title: t("process_to_hold_ticket"),
      description: t("process_to_hold_ticket_details"),
      confirmText: t("confirm"),
      cancelText: t("cancel"),
      onConfirm: async () => {
        const variables = {
          input: {
            groupId,
            companyId,
            employeeId,
            expenseBookingId: route.params?.expenseBookingId,
            flightInfo: {
              contact: {
                area: "+84",
                email: contact.email,
                firstName: contact.name,
                lastName: " ",
                phone: contact.phone,
              },
              passengers: [
                {
                  ...passenger,
                  type: BokPassengerType.Adult,
                  baggage:
                    tickets.departure && tickets.return
                      ? [mapToBaggage(baggages.departure), mapToBaggage(baggages.return)]
                      : mapToBaggage(baggages.departure),
                },
              ],
              fares: [tickets.departure, tickets.return].filter(Boolean).map((ticket) => ({
                fareId: ticket.fareId,
                flightValue: ticket.flights[0].flightValue,
                session: ticket.session,
              })),
            },
          },
        };
        alertNotificationRef?.current?.loading();
        try {
          const rs = await reservation({ variables });
          if (!rs?.data) {
            throw new Error(rs?.error?.message);
          }
          alertNotificationRef.current.close();
          navigation.navigate(SCREEN_NAME.ReservationOverviewScreen, route.params);
        } catch (error) {
          alertNotificationRef?.current?.error({
            title: t("booking_failed"),
            description: error?.message || i18n.t("common:internal_error").toString(),
          });
        }
      },
    });
  };

  const totalAmount = useMemo(() => {
    const ticketAmount = [tickets?.departure, tickets?.return].reduce((acc, ticket) => {
      acc += ticket ? ticket.totalPrice : 0;
      return acc;
    }, 0);
    const luggageAmount = [baggages?.departure, baggages?.return].reduce((acc, luggage) => {
      acc += luggage ? luggage.price : 0;
      return acc;
    }, 0);
    console.log("ticketAmount", ticketAmount);
    return ticketAmount + luggageAmount;
  }, [tickets, baggages]);
  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const renderFooter = () => {
    let fareList: BookingInformation["listBooking"][0]["fares"] = [
      {
        ...tickets.departure,
        bookingInfo: {
          baggage: [baggages?.departure] as BookingInformation["listBooking"][0]["fares"][0]["bookingInfo"]["baggage"],
          price: tickets?.departure?.totalPrice,
        },
      },
    ];
    if (tickets.return) {
      fareList = fareList.concat([
        {
          ...tickets.return,
          bookingInfo: {
            baggage: [baggages?.return] as BookingInformation["listBooking"][0]["fares"][0]["bookingInfo"]["baggage"],
            price: tickets?.return?.totalPrice,
          },
        },
      ]);
    }

    return (
      <FloatFooterExpand
        onLayout={onLayoutFooter}
        totalAmount={totalAmount}
        priceLabel={t("total_amount")}
        expandComponent={<FlightTicketAmountDetail fares={fareList} />}
        footerButton={
          <Button
            onPress={() => {
              if (!tickets?.return) {
                handleReservation();
                return;
              }

              const allStepsGoThrough = index === tapRoutes.length - 1;
              if (allStepsGoThrough) {
                handleReservation();
              }
              setIndex(Math.min(index + 1, tapRoutes.length - 1));
            }}
          >
            {t("continue")}
          </Button>
        }
      />
    );
  };
  return (
    <BottomSheetModalProvider>
      <View style={styles.container}>
        <BackHeader containerStyle={styles.pageHeader} headerTitle={t("page_title")} onPress={handleGoBack} />
        {tickets?.return ? (
          <TopTabLineView
            lazy={false}
            renderScene={renderScene}
            routes={tapRoutes}
            index={index}
            setIndex={setIndex}
            scrollEnabled={true}
            tabStyle={[{ width: calculatedTabWidth }, styles.tabStyle]}
            style={{ paddingBottom: footerHeight }}
            labelStyle={Fonts.H200}
          />
        ) : (
          <ScrollView showsVerticalScrollIndicator={false} style={[styles.tabContent, { paddingBottom: footerHeight }]}>
            {departureTab}
          </ScrollView>
        )}
      </View>
      {renderFooter()}
      <AlertNotification ref={alertNotificationRef} />
      <TicketDetailModal ref={ticketModalRef} />
    </BottomSheetModalProvider>
  );
};

export default LuggageScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  pageHeader: {
    paddingBottom: 5,
  },
  tabContent: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  ticketOverview: {
    paddingVertical: 16,
    backgroundColor: Colors.grayscale0,
  },
  tabStyle: {
    paddingHorizontal: 20,
  },
});
