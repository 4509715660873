import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={10} height={6} viewBox="0 0 10 6" fill="none">
      <Path
        d="M8.521.5H1.474c-.571 0-.857.759-.452 1.204l3.083 3.389a1.189 1.189 0 001.792 0l1.172-1.29 1.91-2.1C9.38 1.26 9.094.5 8.522.5z"
        fill="#0F172A"
      />
    </Svg>
  );
}

export default SvgComponent;
