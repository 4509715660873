import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M10.001 18.334a8.333 8.333 0 110-16.667 8.333 8.333 0 010 16.667zm0-1.667a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333zm-.83-3.333L5.634 9.798l1.178-1.179 2.357 2.358 4.714-4.714 1.179 1.178-5.893 5.893z"
        fill="#2B8A3E"
      />
    </Svg>
  );
}

export default SvgComponent;
