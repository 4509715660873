import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { MobileExpPartialApproveReportMutation, MobileExpPartialApproveReportMutationVariables } from "types";

const SUBMIT_EXPENSE_REPORT = gql`
  mutation MobileExpPartialApproveReport($input: ExpPartialApproveReportInput!) {
    expPartialApproveReport(input: $input) {
      message
      success
    }
  }
`;

export const useExpPartialApproveReport = (
  options?: MutationHookOptions<MobileExpPartialApproveReportMutation, MobileExpPartialApproveReportMutationVariables>
) => {
  return useMutation(SUBMIT_EXPENSE_REPORT, options);
};
