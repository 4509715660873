import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";

const EXPENSE_APPROVE_REQUEST = gql`
  mutation MobileApproveExpenseRequest($requestId: UUID!, $reason: String) {
    expenseApproveRequest(request_id: $requestId, reason: $reason) {
      message
      success
    }
  }
`;

const EXPENSE_ADD_APPROVAL = gql`
  mutation MobileAddExpenseRequestManualApprover($requestId: UUID!, $approver_id: UUID!) {
    expenseAddRequestManualApprover(request_id: $requestId, approver_employee_id: $approver_id) {
      success
      message
    }
  }
`;

const useExpenseApproveRequest = () => {
  const [approvalRequest] = useMutation(EXPENSE_APPROVE_REQUEST);
  const [approvalRequestAddApproval] = useMutation(EXPENSE_ADD_APPROVAL);

  const approvalRequestWithAddApproval = useCallback(
    async ({ requestId, approver_id }) => {
      try {
        if (approver_id) {
          await approvalRequestAddApproval({
            variables: {
              requestId,
              approver_id,
            },
          });
        }
        const results = await approvalRequest({
          variables: {
            requestId,
          },
        });

        return results;
      } catch (error) {
        return false;
      }
    },
    [approvalRequest, approvalRequestAddApproval]
  );

  return {
    approvalRequest,
    approvalRequestWithAddApproval,
  };
};

export default useExpenseApproveRequest;
