import React, { useRef } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Route } from "react-native-tab-view";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { HeaderPageBottomTab, IconCustom, SelectActionPicker, TopTabView } from "components";
import { Colors } from "theme";
import SCREEN_NAME from "navigation/ScreenName";
import { ReportSearchScreenNavigationProps } from "navigation/type";
import WaitingApproveTab from "screens/Report/ReportListScreen/components/WaitingApproveTab";
import MyReportTab from "screens/Report/ReportListScreen/components/MyReportTab";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useAuth } from "contexts/AuthContext";
import { BizziBotSticker02 } from "assets/images";
import { CopilotSingleStepCustom } from "components/Walkthrough";
import { useMasterData } from "contexts/MasterDataContext";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

export enum REPORT_LIST_TAB {
  WAITING_APPROVE = "WAITING_APPROVE",
  MY_REQUEST = "MY_REQUEST",
}

const TabIndex = {
  [REPORT_LIST_TAB.WAITING_APPROVE]: 0,
  [REPORT_LIST_TAB.MY_REQUEST]: 1,
};

const TabName = {
  [0]: REPORT_LIST_TAB.WAITING_APPROVE,
  [1]: REPORT_LIST_TAB.MY_REQUEST,
};

const MORE_ACTIONS = (t) => [
  { label: t("approve_all_report"), value: "approve", icon: <IconCustom name="data-check" /> },
  { label: t("history"), value: "history", icon: <IconCustom name="schedule" /> },
];
const ReportListScreen = ({ route: { params = {} as any } }) => {
  const { user } = useAuth();
  const {
    setting: { isBatchApproveEnabled },
  } = useMasterData();
  const { top } = useSafeAreaInsets();
  const navigation = useNavigation<ReportSearchScreenNavigationProps | any>();
  const { t } = useTranslation("app/screens/Report/ReportListScreen");

  const routes = [
    { key: REPORT_LIST_TAB.WAITING_APPROVE, title: t("waiting_approval_tab") },
    { key: REPORT_LIST_TAB.MY_REQUEST, title: t("my_report_tab") },
  ];
  const index = TabIndex[params?.tab] ?? 1;
  const onTabPress = (event) => {
    navigation.setParams({ ...params, tab: event.route.key });
  };
  const setIndex = (index) => {
    const nextTab = TabName[index];
    if (nextTab !== params.tab) {
      navigation.setParams({ ...params, tab: nextTab });
    }
  };

  const waitingApproveTabRef = useRef<any>();
  const myReportTabRef = useRef<any>();

  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case REPORT_LIST_TAB.WAITING_APPROVE:
        return <WaitingApproveTab ref={waitingApproveTabRef} />;
      case REPORT_LIST_TAB.MY_REQUEST:
        return <MyReportTab routeParams={params} ref={myReportTabRef} />;
      default:
        return null;
    }
  };

  const onClickAction = (action) => {
    if (action === "history") {
      navigation.navigate(SCREEN_NAME.HistoryReportScreen);
    } else {
      analyticService.logEvent({ name: EVENT.BATCH_APPROVAL.TAPPED });
      navigation.navigate(SCREEN_NAME.ReportBatchApprovalScreen, { sortOption: params?.approvalTabSortOption });
    }
  };
  const navigateToSearchReportScreen = () => {
    navigation.navigate(SCREEN_NAME.ReportSearchScreen, {
      awaitingMyApproval: index === 0,
      employee:
        index === 0
          ? null
          : {
              email: user?.email,
              employeeId: user?.employee_id,
              fullname: user?.employee?.fullname,
              avatarUrl: user?.avatar,
            },
      onRefreshData: () => {
        index === 0 ? waitingApproveTabRef?.current?.onRefresh?.() : myReportTabRef?.current?.onRefresh?.();
      },
    });
  };

  const renderRightComponent = () => {
    return (
      <View style={styles.rightButtonContainer}>
        <TouchableOpacity style={styles.rightButtonIcon} onPress={navigateToSearchReportScreen}>
          <IconCustom name="search" />
        </TouchableOpacity>
        {isBatchApproveEnabled ? (
          <CopilotSingleStepCustom
            featureName={FEATURE_FLAGS.RELEASE_SMP_9191}
            title={"mark_approve_title_feature"}
            text={"mark_approve_description_feature"}
            image={BizziBotSticker02}
            style={styles.rightButtonIcon}
          >
            <SelectActionPicker
              title={t("action")}
              icon={<IconCustom name="more-vert" />}
              options={MORE_ACTIONS(t)}
              onSelect={onClickAction}
            />
          </CopilotSingleStepCustom>
        ) : (
          <TouchableOpacity
            style={styles.rightButtonIcon}
            onPress={() => navigation.navigate(SCREEN_NAME.HistoryReportScreen)}
          >
            <IconCustom name="schedule" />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  return (
    <View style={[styles.container, { paddingTop: top }]}>
      <HeaderPageBottomTab title={t("expense_report")} rightComponent={renderRightComponent()} />
      <TopTabView routes={routes} index={index} setIndex={setIndex} onTabPress={onTabPress} renderScene={renderScene} />
    </View>
  );
};

export default ReportListScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  rightButtonContainer: { flexDirection: "row" },
  rightButtonIcon: { padding: 10 },
});
