import * as React from "react";
import Svg, { Rect, Path, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={40} height={40} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect x={0.5} y={0.5} width={79} height={79} rx={15.5} fill="#fff" stroke="#D9D9D9" />
      <Path
        d="M63.986 59.05a2.938 2.938 0 01-2.94 2.94H19.892a2.94 2.94 0 010-5.88h5.879V42.882a2.94 2.94 0 115.88 0V56.11h5.878V42.882a2.94 2.94 0 015.88 0V56.11h5.879V42.882a2.94 2.94 0 115.879 0V56.11h5.879a2.94 2.94 0 012.94 2.94zM17.094 33.49a2.937 2.937 0 011.093-3.287l20.577-14.698a2.94 2.94 0 013.43 0L62.77 30.203a2.939 2.939 0 01-1.724 5.33H19.892a2.937 2.937 0 01-2.798-2.043zm19.7-6.775a3.675 3.675 0 107.35 0 3.675 3.675 0 00-7.35 0z"
        fill="#9BABC9"
      />
      <Circle cx={40.5049} cy={27.6355} r={5.43524} fill="#9BABC9" />
      <Path
        d="M39.863 34.279v-2.031l.364.34a8.348 8.348 0 01-2.189-.34 5.724 5.724 0 01-1.76-.9l.618-1.565c.497.362 1.042.64 1.634.837a6.17 6.17 0 001.951.295c.793 0 1.37-.135 1.73-.403.37-.28.554-.626.554-1.039a.991.991 0 00-.396-.822c-.254-.206-.693-.377-1.317-.511l-1.681-.357c-.973-.206-1.698-.553-2.173-1.038-.466-.496-.698-1.142-.698-1.938 0-.61.153-1.152.46-1.628a3.298 3.298 0 011.285-1.162c.55-.29 1.195-.46 1.935-.512l-.317.233v-1.953h1.586v1.953l-.333-.248a6.24 6.24 0 011.776.356c.592.207 1.084.507 1.476.9l-.603 1.488a3.924 3.924 0 00-1.38-.807 4.99 4.99 0 00-1.698-.279c-.697 0-1.247.15-1.65.45-.401.29-.602.672-.602 1.147 0 .351.121.64.365.868.254.217.671.388 1.253.512l1.681.356c1.016.207 1.766.548 2.253 1.023.497.465.745 1.08.745 1.845 0 .6-.153 1.132-.46 1.597a3.35 3.35 0 01-1.269 1.1c-.528.269-1.147.44-1.856.512l.302-.326v2.047h-1.586z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
