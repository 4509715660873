import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path d="M24 0H0v24h24V0z" fill="#fff" />
      <Path
        d="M2.25 20.25h13.5M1.586 13.163l3.534 3.31a3 3 0 003.563.403l14.194-8.25-1.744-2.138a3 3 0 00-3.844-.684L13.127 8.25 7.502 6.376l-1.547.665a.75.75 0 00-.206 1.247l2.878 2.588-2.625 1.5-2.625-1.125-1.575.675a.751.751 0 00-.216 1.237v0z"
        stroke="#1F71F4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
