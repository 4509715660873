import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpenseCategoriesCompanyQuery, MobileExpenseCategoriesCompanyQueryVariables } from "types";

const QUERY = gql`
  query MobileExpenseCategoriesCompany($companyId: uuid!) {
    expenseCategories: expense_categories(
      limit: 1000
      offset: 0
      order_by: { title: asc }
      where: { company_id: { _eq: $companyId }, deleted_timestamp: { _eq: 0 } }
    ) {
      expenseCategoryId: expense_category_id
      code
      order
      titleEn: title_en
      title
    }
  }
`;

const useGetCategoriesCompany = (
  options?: QueryHookOptions<MobileExpenseCategoriesCompanyQuery, MobileExpenseCategoriesCompanyQueryVariables>
) => {
  return useQuery<MobileExpenseCategoriesCompanyQuery, MobileExpenseCategoriesCompanyQueryVariables>(QUERY, options);
};

export default useGetCategoriesCompany;
