import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1F71F4" }) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={20} height={20}>
        <Path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M10.031 16.25c-1.745 0-3.222-.605-4.433-1.816C4.387 13.224 3.78 11.746 3.78 10c0-1.744.606-3.222 1.817-4.434C6.808 4.356 8.286 3.75 10.03 3.75c.975 0 1.897.217 2.766.65a5.922 5.922 0 012.17 1.834V3.75h1.25v5.096h-5.096v-1.25h3.292a4.904 4.904 0 00-1.827-1.901A4.907 4.907 0 0010.03 5c-1.389 0-2.57.486-3.541 1.458C5.517 7.431 5.03 8.611 5.03 10c0 1.389.486 2.57 1.459 3.542C7.462 14.514 8.642 15 10.03 15c1.07 0 2.035-.306 2.896-.917a4.841 4.841 0 001.813-2.416h1.317a6.115 6.115 0 01-2.235 3.311c-1.112.848-2.375 1.272-3.79 1.272z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
