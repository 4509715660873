import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpActiveBudgetPlanQuery, MobileExpActiveBudgetPlanQueryVariables } from "types";

const QUERY = gql`
  query MobileExpActiveBudgetPlan($companyId: UUID!) {
    expActiveBudgetPlan(companyId: $companyId) {
      budgetPlanId
      fiscalYear
      fromDate
      toDate
    }
  }
`;

const useExpActiveBudgetPlanQuery = (variables: MobileExpActiveBudgetPlanQueryVariables, skip: boolean) => {
  return useQuery<MobileExpActiveBudgetPlanQuery, MobileExpActiveBudgetPlanQueryVariables>(QUERY, {
    variables,
    fetchPolicy: "network-only",
    skip,
  });
};

export default useExpActiveBudgetPlanQuery;
