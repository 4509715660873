import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "@apollo/client";
import { MobileExpDeleteExpenseMutation, MobileExpDeleteExpenseMutationVariables } from "types";

const DELETE_EXPENSE = gql`
  mutation MobileExpDeleteExpense($expenseId: UUID!, $employeeId: UUID!) {
    deleteExpense: expDeleteExpense(input: { expenseId: $expenseId, employeeId: $employeeId }) {
      message
      expenseId
    }
  }
`;

export const useDeleteExpense = (
  options?: MutationHookOptions<MobileExpDeleteExpenseMutation, MobileExpDeleteExpenseMutationVariables>
) => {
  return useMutation(DELETE_EXPENSE, { ...options });
};
