import { memo, FC } from "react";
import { StyleSheet, View } from "react-native";
import { Flex } from "@ant-design/react-native";
import { useTranslation } from "react-i18next";

import { AppText } from "components";
import { Colors, Fonts } from "theme";

type InvoiceStatusProps = {
  status: number;
  isDuplicated: boolean;
};

const InvoiceStatus: FC<InvoiceStatusProps> = memo(({ status, isDuplicated }) => {
  const { t } = useTranslation("app/screens/Inbox/components/InvoiceStatus");
  if (isDuplicated) {
    return (
      <View style={[styles.invoiceStatus, { backgroundColor: Colors.warning0 }]}>
        <View style={[styles.invoiceStatusIcon, { backgroundColor: Colors.warning50 }]} />
        <AppText style={[Fonts.BodySmall, styles.invoiceStatusTitle]}>{t("duplicated_invoice")}</AppText>
      </View>
    );
  }

  switch (status) {
    case 0:
      return (
        <View style={[styles.invoiceStatus, { backgroundColor: Colors.purple05 }]}>
          <View style={[styles.invoiceStatusIcon, { backgroundColor: Colors.purple50 }]} />
          <AppText style={[Fonts.BodySmall, styles.invoiceStatusTitle]}>{t("unsupported_status")}</AppText>
        </View>
      );
    case 1:
      return (
        <View style={[styles.invoiceStatus, { backgroundColor: Colors.success0 }]}>
          <View style={[styles.invoiceStatusIcon, { backgroundColor: Colors.success50 }]} />
          <AppText style={[Fonts.BodySmall, styles.invoiceStatusTitle]}>{t("done_status")}</AppText>
        </View>
      );
    case 2:
      return (
        <Flex style={[styles.invoiceStatus, { backgroundColor: Colors.primary05 }]}>
          <View style={[styles.invoiceStatusIcon, { backgroundColor: Colors.primary50 }]} />
          <AppText style={[Fonts.BodySmall, styles.invoiceStatusTitle]}>{t("processing_status")}</AppText>
        </Flex>
      );
    case 3:
      return (
        <View style={[styles.invoiceStatus, { backgroundColor: Colors.alert05 }]}>
          <View style={[styles.invoiceStatusIcon, { backgroundColor: Colors.alert50 }]} />
          <AppText style={[Fonts.BodySmall, styles.invoiceStatusTitle]}>{t("error_status")}</AppText>
        </View>
      );
    case 4:
      return (
        <View style={[styles.invoiceStatus, { backgroundColor: Colors.grayscale05 }]}>
          <View style={[styles.invoiceStatusIcon, { backgroundColor: Colors.grayscale50 }]} />
          <AppText style={[Fonts.BodySmall, styles.invoiceStatusTitle]}>{t("skip_status")}</AppText>
        </View>
      );
    case 5:
      return (
        <View style={[styles.invoiceStatus, { backgroundColor: Colors.success0 }]}>
          <View style={[styles.invoiceStatusIcon, { backgroundColor: Colors.success50 }]} />
          <AppText style={[Fonts.BodySmall, styles.invoiceStatusTitle]}>{t("tiki_status")}</AppText>
        </View>
      );
    default:
      return null;
  }
});

export default InvoiceStatus;

const styles = StyleSheet.create({
  invoiceStatus: {
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 20,
  },
  invoiceStatusIcon: {
    width: 12,
    height: 12,
    borderRadius: 12,
    marginTop: 2,
  },
  invoiceStatusTitle: {
    fontSize: 12,
    marginLeft: 5,
  },
});
