import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const SvgComponent = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <Rect width="24" height="24" rx="4" fill="#F8FAFC" />
    <G clipPath="url(#clip0_2017_3516)">
      <Path
        d="M7.33333 10.6665C6.6 10.6665 6 11.2665 6 11.9998C6 12.7332 6.6 13.3332 7.33333 13.3332C8.06667 13.3332 8.66667 12.7332 8.66667 11.9998C8.66667 11.2665 8.06667 10.6665 7.33333 10.6665ZM16.6667 10.6665C15.9333 10.6665 15.3333 11.2665 15.3333 11.9998C15.3333 12.7332 15.9333 13.3332 16.6667 13.3332C17.4 13.3332 18 12.7332 18 11.9998C18 11.2665 17.4 10.6665 16.6667 10.6665ZM12 10.6665C11.2667 10.6665 10.6667 11.2665 10.6667 11.9998C10.6667 12.7332 11.2667 13.3332 12 13.3332C12.7333 13.3332 13.3333 12.7332 13.3333 11.9998C13.3333 11.2665 12.7333 10.6665 12 10.6665Z"
        fill="#03053D"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_2017_3516">
        <Rect x="4" y="4" width="16" height="16" rx="4" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
