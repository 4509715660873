import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12 21.381a1.57 1.57 0 01-.306-.03c-.1-.02-.197-.046-.29-.08-2.116-.75-3.795-2.079-5.039-3.989-1.243-1.91-1.865-3.97-1.865-6.181V6.597c0-.38.11-.722.328-1.026.218-.304.5-.524.847-.66l5.692-2.126c.214-.077.425-.115.633-.115.208 0 .419.038.633.115l5.692 2.125c.346.137.629.357.847.661.219.304.328.646.328 1.026V11.1a11.058 11.058 0 01-1.998 6.34.596.596 0 01-.495.28.717.717 0 01-.551-.218l-2.571-2.572c-.275.19-.573.332-.896.427a3.486 3.486 0 01-.989.143c-.963 0-1.787-.343-2.472-1.028C8.843 13.787 8.5 12.963 8.5 12c0-.963.343-1.787 1.028-2.472C10.213 8.843 11.038 8.5 12 8.5c.963 0 1.786.342 2.472 1.028.685.685 1.028 1.51 1.028 2.472 0 .337-.046.663-.137.978a3.825 3.825 0 01-.394.899l1.866 1.865c.359-.703.642-1.44.851-2.213A9.265 9.265 0 0018 11.1V6.588a.299.299 0 00-.053-.173.32.32 0 00-.149-.115l-5.692-2.125a.282.282 0 00-.106-.02.283.283 0 00-.106.02L6.202 6.299a.319.319 0 00-.15.115.298.298 0 00-.052.173v4.514c0 2.016.567 3.85 1.7 5.5s2.567 2.75 4.3 3.3a7.23 7.23 0 001.752-.825.875.875 0 01.576-.125.701.701 0 01.503.298.691.691 0 01.117.562.718.718 0 01-.325.466c-.327.2-.654.384-.98.552a8.948 8.948 0 01-1.043.452 1.802 1.802 0 01-.6.1zm0-7.38c.55 0 1.02-.196 1.412-.588.392-.392.588-.862.588-1.412 0-.55-.196-1.021-.588-1.413A1.926 1.926 0 0012 10.001c-.55 0-1.02.196-1.412.587A1.926 1.926 0 0010 12.001c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
