import config from "app/config";
import padStart from "lodash/padStart";

enum QRTemplate {
  POS = "00",
}

enum QRDefinition {
  COMPANY_NAME = "00",
  TAX_CODE = "01",
  ADDRESS = "02",
  RRECEIVE_INVOICE_EMAIL = "03",
  PHONE = "04",
}

type GenerateQRInput = {
  companyName: string;
  taxCode: string | number;
  address: string;
  receiveInvoiceEmail?: string;
  phoneNumber?: string;
};

type QRCodeValue = {
  definition: QRDefinition;
  value: string;
};

type EmployeeQRInput = {
  employeeId: string;
  phoneNumber?: string;
};

const generateBizziQR = (input: GenerateQRInput) => {
  const template = QRTemplate.POS;
  const values: QRCodeValue[] = [
    {
      definition: QRDefinition.COMPANY_NAME,
      value: input.companyName,
    },
    {
      definition: QRDefinition.TAX_CODE,
      value: input.taxCode.toString(),
    },
    {
      definition: QRDefinition.ADDRESS,
      value: input.address,
    },
    {
      definition: QRDefinition.RRECEIVE_INVOICE_EMAIL,
      value: input.receiveInvoiceEmail,
    },
    {
      definition: QRDefinition.PHONE,
      value: input.phoneNumber,
    },
  ];

  const cleanValues = values.filter(({ value }) => Boolean(value));

  let code = `${template}`;
  cleanValues.forEach(({ definition, value }) => {
    const length = padStart(value.length.toString(), 3, "0");
    code += `${definition}${length}${value}`;
  });

  return code;
};

export const generateEmployeeVatQR = (input: EmployeeQRInput) => {
  const params = new URLSearchParams();
  params.append("e", input.employeeId);

  if (input.phoneNumber) {
    params.append("phone", input.phoneNumber);
  }

  return `${config.EXPENSE_APP_ENDPOINT}/vat-info?${params.toString()}`;
};

export default generateBizziQR;
