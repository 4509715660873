import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 16, height = 16, fill = "#37B24D" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 26 26">
      <Path
        d="M11.106 15.862L8.01 12.765a.966.966 0 00-.696-.284.942.942 0 00-.71.284.957.957 0 00-.289.702c0 .275.097.51.29.703l3.659 3.659c.24.24.522.361.843.361.322 0 .603-.12.844-.361l7.418-7.418a.966.966 0 00.283-.696.943.943 0 00-.283-.71.957.957 0 00-.703-.289.957.957 0 00-.702.29l-6.857 6.856zm1.895 9.805c-1.752 0-3.399-.332-4.94-.997a12.795 12.795 0 01-4.023-2.707 12.79 12.79 0 01-2.708-4.021c-.665-1.54-.998-3.187-.998-4.94 0-1.751.332-3.398.997-4.94A12.795 12.795 0 014.036 4.04a12.789 12.789 0 014.021-2.708c1.541-.665 3.188-.998 4.94-.998 1.751 0 3.398.332 4.94.997a12.796 12.796 0 014.022 2.707 12.788 12.788 0 012.709 4.021c.665 1.541.997 3.188.997 4.94 0 1.751-.332 3.398-.997 4.94a12.796 12.796 0 01-2.707 4.022 12.79 12.79 0 01-4.021 2.709c-1.54.665-3.187.997-4.94.997z"
        fill={fill}
      />
    </Svg>
  );
}

export default SvgComponent;
