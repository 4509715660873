// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
function trimString(s: string | string[]) {
  let l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] == " ") {
    l++;
  }
  while (r > l && s[r] == " ") {
    r -= 1;
  }
  return s.substring(l, r + 1);
}

function compareObjects(o1: { [x: string]: any }, o2: { [x: string]: any }) {
  let k = "";
  for (k in o1) {
    if (o1[k] != o2[k]) {
      return false;
    }
  }
  for (k in o2) {
    if (o1[k] != o2[k]) {
      return false;
    }
  }
  return true;
}

function itemExists(haystack: string | any[], needle: any) {
  for (let i = 0; i < haystack.length; i++) {
    if (compareObjects(haystack[i], needle)) {
      return true;
    }
  }
  return false;
}

export function removeAccents(str: string) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
}
export function searchForProvinceVn(objects: string | any[], toSearch: string) {
  const results = [];
  toSearch = trimString(toSearch); // trim it
  if (objects) {
    for (let i = 0; i < objects.length; i++) {
      for (const key in objects[i]) {
        if (
          key == "name" &&
          objects[i][key] != null &&
          (objects[i][key].toLowerCase().indexOf(toSearch.toLowerCase()) != -1 ||
            removeAccents(objects[i][key].toLowerCase()).indexOf(removeAccents(toSearch.toLowerCase())) != -1)
        ) {
          if (!itemExists(results, objects[i])) {
            results.push(objects[i]);
          }
        }
      }
    }
  }
  return results;
}
