import * as React from "react";
import Svg, { Path, Rect, Ellipse, G, Circle, Defs, LinearGradient, Stop } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={274} height={124} viewBox="0 0 274 124">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.388 13.59C90.388 6.483 96.151.72 103.26.72h157.156c7.11 0 12.873 5.763 12.873 12.872s-5.763 12.872-12.873 12.872h-58.167a12.848 12.848 0 014.781 10.012c0 7.053-5.673 12.78-12.704 12.87h36.945c7.109 0 12.872 5.764 12.872 12.873 0 7.109-5.763 12.872-12.872 12.872H77.694c7.035-.086 12.712-5.816 12.712-12.871 0-7.11-5.763-12.872-12.872-12.872H36.95c-7.085-.028-12.82-5.78-12.82-12.872 0-7.11 5.762-12.873 12.871-12.873H95.17a12.848 12.848 0 01-4.78-10.011zM.29 87.965c0-7.11 5.763-12.872 12.872-12.872h183.664c.633 0 1.255.045 1.864.134h-7.058c-6.31 0-11.425 5.114-11.425 11.424s5.115 11.424 11.425 11.424h22.701c6.349.793 11.262 6.209 11.262 12.773 0 7.109-5.763 12.872-12.872 12.872H74.121c-7.11 0-12.872-5.763-12.872-12.872 0-4.043 1.864-7.651 4.78-10.011H13.16C6.052 100.836.29 95.073.29 87.964z"
        fill="#F0F6FF"
      />
      <Rect x={193.273} y={102.238} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
      <Rect x={193.273} y={112.654} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={195.898}
        y={112.654}
        width={2.11731}
        height={7.84902}
        rx={1.05865}
        transform="rotate(-90 195.898 112.654)"
        fill="#75A4FE"
      />
      <Rect
        x={184.125}
        y={112.654}
        width={2.11731}
        height={7.84902}
        rx={1.05865}
        transform="rotate(-90 184.125 112.654)"
        fill="#75A4FE"
      />
      <Path
        d="M202.777 64.44c0 2.598-2.118 4.713-4.74 4.713-2.622 0-4.74-2.115-4.74-4.713 0-2.599 2.118-4.713 4.74-4.713 2.622 0 4.74 2.114 4.74 4.713z"
        fill="#fff"
        stroke="#1F71F4"
        strokeWidth={2.01559}
      />
      <Ellipse cx={83.0749} cy={37.7727} rx={4.25457} ry={4.23462} fill="#75A4FE" />
      <Rect x={96.8984} y={6.01172} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
      <Rect x={97.0312} y={15.9746} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={99.0234}
        y={15.54}
        width={2.11731}
        height={7.44551}
        rx={1.05865}
        transform="rotate(-90 99.023 15.54)"
        fill="#75A4FE"
      />
      <Path d="M90.512 15.975a1.059 1.059 0 110-2.118h5.328a1.059 1.059 0 010 2.118h-5.328z" fill="#75A4FE" />
      <G filter="url(#filter0_d_15082_241123)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.184 94.715h56.73a19.535 19.535 0 00.75 0h1.39l.086-.085c9.072-.875 16.149-8.113 16.149-16.911 0-8.884-7.215-16.176-16.412-16.935-1.59-14.1-13.75-25.065-28.515-25.065-14.082 0-25.794 9.974-28.226 23.129a21.359 21.359 0 00-2.347-.13c-10.77 0-19.5 8.06-19.5 18 0 9.588 8.12 17.424 18.357 17.97l.027.027h.721a21.673 21.673 0 00.79 0z"
          fill="url(#paint0_linear_15082_241123)"
        />
      </G>
      <G filter="url(#filter1_d_15082_241123)">
        <Path
          d="M170.465 98.727l.028-1.5h-.028v1.5zm-66.352 0l-1.073 1.048.442.451h.631v-1.5zm67.115 0v-1.5h-.027l.027 1.5zm1.316 0v1.499h.621l.439-.439-1.06-1.06zm.072-.073l-.129-1.494-.545.047-.387.387 1.061 1.06zm-.503-40.689l-1.48.242.193 1.182 1.196.074.091-1.498zm-63.485-3.222l-.218 1.484 1.319.194.347-1.287-1.448-.39zm-4.568 43.929l1.073-1.048-.405-.415-.579-.035-.089 1.498zm66.405-1.445h-66.352v2.999h66.352v-3zm.382.003l-.354-.003-.055 2.999c.136.003.272.004.409.004v-3zm.354-.003a18.99 18.99 0 01-.354.003v3c.136 0 .273-.001.409-.004l-.055-3zm1.343 0h-1.316v2.999h1.316v-3zm-.989.367l-.072.072 2.121 2.121.072-.072-2.121-2.121zm18.194-19.266c0 9.887-7.591 18.001-17.262 18.832l.257 2.989c11.208-.963 20.005-10.364 20.005-21.82h-3zm-17.727-18.865c9.891.605 17.727 8.82 17.727 18.865h3c0-11.64-9.08-21.158-20.544-21.86l-.183 2.995zm-31.999-27.244c15.417 0 28.209 11.25 30.61 25.988l2.961-.483c-2.634-16.166-16.66-28.505-33.571-28.505v3zm-29.947 22.915c3.562-13.202 15.622-22.915 29.947-22.915v-3c-15.715 0-28.938 10.655-32.844 25.133l2.897.782zm-4.685.874c1.026 0 2.034.075 3.019.22l.435-2.969a23.79 23.79 0 00-3.454-.251v3zM84.789 76.61c0-11.379 9.224-20.602 20.602-20.602v-3c-13.035 0-23.602 10.567-23.602 23.602h3zm19.36 20.564c-10.8-.64-19.36-9.604-19.36-20.564h-3c0 12.558 9.807 22.824 22.182 23.559l.178-2.995zm1.037.504l-.053-.054-2.146 2.096.053.055 2.146-2.097z"
          fill="#1F71F4"
        />
      </G>
      <Circle cx={125.114} cy={72.8459} r={3.05977} fill="#1F71F4" />
      <Circle cx={147.708} cy={72.8459} r={3.05977} fill="#1F71F4" />
      <Ellipse cx={136.18} cy={79.6662} rx={3.76587} ry={2.35367} fill="#1F71F4" />
      <Path transform="rotate(-30 118.289 66.72)" fill="#1F71F4" d="M118.289 66.7207H126.29147V68.60363H118.289z" />
      <Path
        transform="rotate(30 147.945 62.719)"
        fill="#1F71F4"
        d="M147.945 62.7188H155.94746999999998V64.60173H147.945z"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_15082_241123"
          x1={120.608}
          y1={110.22}
          x2={128.107}
          y2={40.8435}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#EFF1F6" />
          <Stop offset={1} stopColor="#fff" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
