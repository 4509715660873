export interface FilterData {
  employee?: {
    email: string;
    employeeId: string;
    fullname: string;
  };
  submittedDate?: {
    fromDate: string;
    toDate: string;
    labelDateValue?: string;
  };
  companyTeam?: {
    code: string;
    name: string;
    nameEn?: string;
    teamId: string;
  };
  sort?: { label: string; value: APPROVAL_OBJECT_SORT_OPTIONS };
}

export enum APPROVAL_OBJECT_SORT_OPTIONS {
  CREATED_AT_ASC = "created_date_asc",
  CREATED_AT_DESC = "created_date_desc",
  REQUESTED_AT_ASC = "submission_date_asc",
  REQUESTED_AT_DESC = "submission_date_desc",
  AMOUNT_ASC = "amount_asc",
  AMOUNT_DESC = "amount_desc",
}
