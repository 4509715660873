import { TouchableOpacity, View, ViewStyle, StyleSheet } from "react-native";
import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { ArrowDownSmallIcon, ArrowRightSmallIcon, ArrowUpSmallIcon } from "assets/images/svg/icons";
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { FC, useEffect } from "react";
import { formatMoneyString } from "utils";
const ProgressLine = ({ percent }) => {
  const position = useSharedValue(0);
  useEffect(() => {
    if (percent === 0) {
      position.value = 0;
    } else {
      position.value = withTiming(percent, {
        duration: 1000,
        easing: Easing.linear,
      });
    }
  }, [percent]);

  const style = useAnimatedStyle(() => {
    const width = interpolate(position.value, [0, 1], [0, 100], Extrapolate.CLAMP);
    return {
      width: `${width}%`,
    };
  });

  return (
    <View style={{ height: 1, backgroundColor: Colors.grayscale10 }}>
      <Animated.View
        style={[{ backgroundColor: Colors.primary50, height: 2, position: "absolute", bottom: 0 }, style]}
      />
    </View>
  );
};
interface LineHorizontalChartProps {
  style?: ViewStyle;
  title?: string;
  percent?: number;
  amount?: number;
  diffPercentage?: number;
  showPercent: boolean;
  onPress?: () => void;
}
const LineHorizontalChart: FC<LineHorizontalChartProps> = ({
  style,
  title,
  percent,
  amount,
  diffPercentage,
  showPercent,
  onPress,
}) => {
  return (
    <TouchableOpacity disabled={!onPress} onPress={onPress} style={style}>
      <View style={styles.body}>
        <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
          {title}
        </AppText>
        <View style={styles.lineContainer}>
          <AppText style={Fonts.NumericN200} color={Colors.grayscale80}>
            {formatMoneyString(amount)}
          </AppText>
          <View style={styles.percentRow}>
            {showPercent && typeof diffPercentage !== "undefined" && (
              <>
                {diffPercentage < 0 ? <ArrowDownSmallIcon /> : diffPercentage > 0 ? <ArrowUpSmallIcon /> : null}
                <AppText
                  style={Fonts.NumericN100}
                  color={
                    diffPercentage < 0 ? Colors.success50 : diffPercentage > 0 ? Colors.alert50 : Colors.grayscale100
                  }
                >
                  {`${Math.abs(Math.round(diffPercentage))}%`}
                </AppText>
              </>
            )}
            {onPress && <ArrowRightSmallIcon />}
          </View>
        </View>
      </View>
      <ProgressLine percent={percent} />
    </TouchableOpacity>
  );
};
export default LineHorizontalChart;
const styles = StyleSheet.create({
  body: { flexDirection: "row", gap: 8, marginBottom: 8 },
  lineContainer: { flexDirection: "row", alignItems: "center", gap: 8 },
  percentRow: {
    flexDirection: "row",
    alignItems: "center",
    minWidth: 65,
    justifyContent: "flex-end",
  },
  flex: { flex: 1 },
});
