// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import bizziIdClient from "./bizziIdClient";
import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import get from "lodash/get";

import { request } from "app/utils/gql";

import Config from "app/config";
import { Keys } from "app/constants/Keys";
import { isEmpty } from "lodash";
import { sentryService } from "services/SentryService";
import { widgetService } from "services/WidgetService";
import { removeCurrentCompanyId, getCurrentCompanyId, removeCurrentUser } from "utils/index";

const USER_QUERY = /* GraphQL */ `
  query MobileUsers($userId: uuid!) {
    me: idMe {
      user_id
      fullname
      phone
      email
      avatar_url
      default_avatar_url
    }
    user: users(where: { user_id: { _eq: $userId } }) {
      fullname
      username
      email
      user_id
      users_groups {
        role
        group_id
      }
      company_employees(where: { activated_at: { _is_null: false } }, order_by: { created_at: asc }) {
        employee_id
        company_id
        is_owner
        fullname
        phone
        expense_email
        custom_fields
        employee_code
        expense_policies_employees {
          expense_policies_employees_id
          expense_policy {
            expense_policy_id
            name
          }
        }
        employee_expense_approval_rules {
          expense_approval_rules_employees_id
        }
        department {
          department_id
          name
          company_branch {
            company_branch_id
            branch_name
            branch_tax_code
            branch_address
          }
          company {
            group_id
            group {
              roles {
                role_id
                name
              }
              group_id
            }
            name
            address
            tax_code
            company_id
            accountant_email
          }
        }
        company {
          group_id
          group {
            roles {
              role_id
              name
            }
            group_id
          }
          name
          address
          tax_code
          company_id
          accountant_email
        }
      }
      employee {
        employee_id
        company_id
        is_owner
        phone
        expense_email
        employee_expense_approval_rules {
          expense_approval_rules_employees_id
        }
        department {
          department_id
          name
          company_branch {
            company_branch_id
            branch_name
            branch_tax_code
            branch_address
          }
          company {
            group_id
            group {
              roles {
                role_id
                name
              }
              group_id
            }
            name
            address
            tax_code
            company_id
            accountant_email
          }
        }

        company {
          group_id
          group {
            roles {
              role_id
              name
            }
            group_id
          }
          name
          address
          tax_code
          company_id
          accountant_email
        }
      }
    }
  }
`;

export interface TUserInfo {
  id: string;
  avatar?: string;
  email: string;
  phone?: string;
  is_owner: boolean;
  group: {
    group_id: string;
    role: string | "user";
  };
  token: string;
  fullname: string;
  group_id: number;
  username: string;
  company: Companies;
  employee_id: string;
  invoice_email: string;
  expense_email: string;
  userGroups: Array<string>;
  employee: CompanyEmployees;
  employees: CompanyEmployees[];
  department?: CompanyDepartments;
  tokenRole?: "mod" | "user";
}

const getInfoFromPayload = (payload: any) => {
  return {
    id: payload.id,
    role: payload["https://hasura.io/jwt/claims"]["x-hasura-default-role"],
    allowedRoles: payload["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"],
  };
};

async function getUser(): Promise<TUserInfo | null> {
  const token = await getToken();
  if (!token) {
    return null;
  }
  const payload = jwtDecode(token);
  const { id, role: tokenRole } = getInfoFromPayload(payload);
  const { data, errors } = await request<{ user: Array<Users> }>(
    USER_QUERY,
    {
      userId: id,
    },
    token
  );

  if (errors) {
    return { tokenRole };
  }

  const user: Users | undefined = get(data, "user[0]");
  const avatar = get(data, "me")?.avatar_url || get(data, "me")?.default_avatar_url;

  if (!user) {
    console.error("User not found");
    return { token, tokenRole };
  }

  const groups: Array<UsersGroups> = get(user, "users_groups", []);
  const group: UsersGroups = groups[0] ?? {};
  const userGroups = groups.map((gr) => gr.role);
  const employees = user?.company_employees as CompanyEmployees[];

  if (isEmpty(employees)) {
    console.error("Employee not found");
    return {
      id,
      token,
      fullname: user.fullname,
      group: {
        role: group?.role,
      },
      tokenRole,
    };
  }

  let companyEmployee = user?.company_employees?.[0];
  let company: Companies = companyEmployee?.company || ({} as Companies);
  // If the user has multiple companies and has not selected a company,
  // Must redirect the user to the select company screen.
  const companyId = await getCurrentCompanyId();
  if (employees.length > 1) {
    companyEmployee = employees.find((e) => e.company_id === companyId);
    company = companyEmployee?.company || ({} as Companies);
  }

  const groupId = company?.group_id ?? group.group_id;
  const role = groups.find((gr) => gr.group_id === company?.group_id)?.role;

  return {
    id,
    avatar,
    token,
    company,
    group_id: groupId,
    fullname: user.fullname,
    username: user.username,
    email: user.email as string,
    employee_id: companyEmployee?.employee_id,
    is_owner: companyEmployee?.is_owner ?? false,
    employee: companyEmployee as CompanyEmployees,
    employees: user?.company_employees as CompanyEmployees[],
    phone: companyEmployee?.phone as string | undefined,
    department: companyEmployee?.department,
    group: {
      group_id: groupId,
      role: role || "user",
    },
    expense_email: companyEmployee?.expense_email ? companyEmployee?.expense_email : null,
    invoice_email: companyEmployee?.expense_email
      ? companyEmployee?.expense_email
      : companyEmployee?.phone
      ? `${companyEmployee?.phone}@${Config.EXPENSE_EMAIL_SUFFIX}`
      : null,
    userGroups,
    tokenRole,
  };
}

async function userRequestMFA(
  username: string
): Promise<{ success: boolean; challenge_id?: string; expires_at?: string; factor?: string }> {
  const payload = await bizziIdClient.request("users/mfa", {
    method: "POST",
    body: JSON.stringify({
      username,
    }),
  });
  return payload;
}

async function login({
  username,
  password,
  challengeId,
  answer,
}: {
  username: string;
  password: string;
  challengeId?: string;
  answer?: string;
}): Promise<{ token: string; refreshToken: string }> {
  const payload = await bizziIdClient.request("users/login", {
    method: "POST",
    body: JSON.stringify({
      username: username,
      password,
      challenge_id: challengeId,
      answer,
    }),
  });
  const token = payload.data?.token;
  const refreshToken = payload.data?.refreshToken;
  return { token, refreshToken };
}

async function checkPhoneExists(phone) {
  // TODO
}

/**
 * forgetPassword
 * @param {string} email - Email user input
 * @param {string} key - Key rececive from verify otp
 * @param {string} password - New password
 */
async function forgetPassword(email) {
  const forgetPasswordQuery = /* GraphQL */ `
    mutation MobileSendResetPasswordToken($email: String!) {
      sendResetPasswordToken(email: $email) {
        message
      }
    }
  `;
  const { data } = await request(forgetPasswordQuery, {
    email,
  });

  return data;
}

async function register(userInput) {
  const registerQuery = /* GraphQL */ `
    mutation MobileExpenseRegister($object: ExpenseRegisterInput!) {
      expenseRegister(object: $object) {
        success
        token
        message
      }
    }
  `;

  const { data, errors } = await request(registerQuery, {
    object: userInput,
  });

  if (errors && errors[0]) {
    console.error(errors);
    throw new Error(errors[0]?.message);
  }

  const { expenseRegister } = data;

  if (!expenseRegister?.success) {
    return Promise.reject(expenseRegister.message);
  }

  return Promise.resolve({
    id: expenseRegister.user_id,
    token: expenseRegister.token,
    group_id: expenseRegister.group_id,
    invoice_email: expenseRegister.invoice_email,
  });
}

async function registerWithCompanyCode({ email, password }, company_code) {
  const registerQuery = /* GraphQL */ `
    mutation MobileExpenseRegister($object: ExpenseRegisterWithCompanyCodeInput!) {
      expenseRegister: expenseRegisterWithCompanyCode(object: $object) {
        success
        token
        message
      }
    }
  `;

  const { data, errors } = await request(registerQuery, {
    object: {
      email,
      password,
      company_code,
    },
  });

  if (errors && errors[0]) {
    console.error(errors);
    throw new Error(errors[0]?.message);
  }

  const { expenseRegister } = data;

  if (!expenseRegister?.success) {
    return Promise.reject(expenseRegister.message);
  }

  return Promise.resolve({
    id: expenseRegister.user_id,
    token: expenseRegister.token,
    group_id: expenseRegister.group_id,
    invoice_email: expenseRegister.invoice_email,
  });
}

async function getCompanyByExpenseCode({ code, email }) {
  const SendEmailOtp = /* GraphQL */ `
    query MobileCompanyByCode($code: String!, $email: String!) {
      expenseGetCompanyByCode(code: $code, email: $email) {
        success
        message
        company {
          name
          address
          tax_code
          accountant_email
          department {
            name
            employee {
              phone
              fullname
            }
          }
        }
      }
    }
  `;

  const results = await request(SendEmailOtp, {
    code,
    email,
  });

  return results;
}

async function requestOTPFromEmail({ email, requiredExistence }) {
  const SendEmailOtp = /* GraphQL */ `
    mutation MobileSendEmailOtp($email: String!, $required_existence: Boolean!) {
      idSendEmailOtp(email: $email, required_existence: $required_existence) {
        success
        message
        otp
        expired_at
      }
    }
  `;

  const results = await request(SendEmailOtp, {
    required_existence: requiredExistence,
    email,
  });

  return results;
}

async function verifyOTP(variables) {
  const verifyOtp = /* GraphQL */ `
    mutation verifyOtp($email: String!, $otp: String!) {
      idVerifyOtp(email: $email, otp: $otp) {
        success
        message
        key
        token
      }
    }
  `;

  const results = await request(verifyOtp, variables);

  return results;
}

async function changePassword({ old_password, new_password, user_id }) {
  //Todo
}

async function getToken() {
  return AsyncStorage.getItem(Keys.token);
}

async function getTokenPair() {
  const token = await AsyncStorage.getItem(Keys.token);
  const refreshToken = await AsyncStorage.getItem(Keys.refreshToken);
  return { token, refreshToken };
}

async function isLoggedIn() {
  return Boolean(getToken());
}

async function logout() {
  sentryService.logout();
  await removeCurrentCompanyId();
  await AsyncStorage.removeItem(Keys.refreshToken);
  await AsyncStorage.removeItem(Keys.token);
  await removeCurrentUser();
  await widgetService.logout();
}

let refreshTokenRequest: any = null;
async function renewToken(): Promise<TokenPair> {
  const localRefreshToken = await AsyncStorage.getItem(Keys.refreshToken);
  if (!localRefreshToken) {
    throw new Error("local token not found");
  }

  const request = () =>
    bizziIdClient.request("users/refreshToken", {
      method: "POST",
      body: JSON.stringify({
        refreshToken: localRefreshToken,
      }),
    });

  refreshTokenRequest = refreshTokenRequest ?? request();

  const {
    data: { token, refreshToken },
  } = await refreshTokenRequest;
  refreshTokenRequest = null;

  await AsyncStorage.setItem(Keys.token, token);
  await AsyncStorage.setItem(Keys.refreshToken, refreshToken);
  return { token, refreshToken };
}

export {
  login,
  userRequestMFA,
  logout,
  register,
  registerWithCompanyCode,
  forgetPassword,
  getToken,
  getUser,
  isLoggedIn,
  checkPhoneExists,
  changePassword,
  getCompanyByExpenseCode,
  requestOTPFromEmail,
  verifyOTP,
  renewToken,
  getTokenPair,
};
