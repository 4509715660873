import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, fill = "#0F172A" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M15.774 12.973L14.65 11.85c.17-.89-.064-1.649-.702-2.277-.639-.628-1.405-.87-2.298-.723l-1.123-1.123c.232-.108.47-.185.716-.233.245-.047.498-.071.757-.071 1.134 0 2.097.396 2.889 1.187.792.792 1.188 1.755 1.188 2.89 0 .259-.023.511-.067.757a2.702 2.702 0 01-.237.716zm3.18 3.111l-1.103-1.034c.633-.483 1.2-1.02 1.7-1.613.5-.591.916-1.237 1.25-1.937a9.773 9.773 0 00-3.613-4.013A9.545 9.545 0 0012.001 6c-.484 0-.955.033-1.413.1-.458.067-.92.167-1.387.3L8.035 5.235a10.218 10.218 0 011.945-.556c.661-.12 1.335-.18 2.02-.18 2.15 0 4.123.56 5.922 1.676a11.208 11.208 0 014.15 4.533 1.617 1.617 0 01.19.792c0 .143-.012.28-.037.409-.025.128-.071.256-.138.383a9.557 9.557 0 01-1.333 2.085 10.915 10.915 0 01-1.8 1.707zM12.001 18.5c-2.106 0-4.035-.563-5.789-1.688a12.452 12.452 0 01-4.183-4.445 1.374 1.374 0 01-.182-.413 1.805 1.805 0 01-.058-.454c0-.154.017-.303.05-.446.033-.144.092-.284.175-.421.372-.68.793-1.327 1.264-1.942A9.45 9.45 0 014.9 7.038L2.643 4.765a.74.74 0 01-.205-.53.724.724 0 01.22-.524.718.718 0 01.527-.217c.207 0 .382.073.527.217L20.29 20.288c.138.139.212.31.22.515a.698.698 0 01-.22.54.718.718 0 01-.527.216.718.718 0 01-.527-.217l-3.519-3.488a9.052 9.052 0 01-1.818.494A11.79 11.79 0 0112 18.5zM5.954 8.092a9.68 9.68 0 00-1.58 1.536A8.838 8.838 0 003.202 11.5a9.773 9.773 0 003.612 4.012A9.544 9.544 0 0012.001 17c.43 0 .852-.029 1.268-.087.416-.057.829-.146 1.24-.267l-1.266-1.296a2.464 2.464 0 01-.607.18 4.133 4.133 0 01-.635.047c-1.135 0-2.098-.396-2.89-1.188-.792-.791-1.187-1.755-1.187-2.89 0-.22.017-.433.052-.635.034-.202.093-.404.175-.606L5.954 8.092z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
