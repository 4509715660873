import { MutationHookOptions, useMutation } from "@apollo/client";

import gql from "graphql-tag";

const DELETE_ACCOUNT = gql`
  mutation MobileDeleteAccount($userId: uuid!) {
    user: update_users_by_pk(pk_columns: { user_id: $userId }, _set: { is_active: false }) {
      userId: user_id
    }
  }
`;

interface IDeleteAccountVariables {
  userId: string;
}

interface IDeleteAccountDataResponse {
  user: {
    userId: string;
  };
}

const useDeleteAccount = (options?: MutationHookOptions<IDeleteAccountDataResponse, IDeleteAccountVariables>) => {
  return useMutation(DELETE_ACCOUNT, options);
};

export default useDeleteAccount;
