import React, { FC, ReactNode, useRef } from "react";

import { StyleProp, StyleSheet, TextInput as RNTextInput, View, ViewStyle, Platform } from "react-native";

import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Colors, Fonts, FontTypes } from "theme";
import { TextInput as PaperTextInput } from "react-native-paper";
import AppText from "components/AppText";
import { CONSTANTS } from "constants/constants";
import { convertStringToNumber, formatMoney } from "utils";
import { VNDIcon } from "assets/images/svg/icons";
import { BottomSheetTextInput } from "@gorhom/bottom-sheet";
import { KeyboardTypeOptions } from "react-native/Libraries/Components/TextInput/TextInput";
import { NumericFormat } from "react-number-format";
import { useSetRelativePosition } from "components";
import CurrencyInputRN from "react-native-currency-input";

interface CurrencyInputProps {
  style?: StyleProp<ViewStyle>;
  label?: string;
  name?: string;
  control: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  errorStyle?: "inline" | "summary";
  placeholder?: string;
  right?: ReactNode;
  autoFocus?: boolean;
  disabled?: boolean;
  bottomSheetMode?: boolean;
  setPosition?: (offsetY: number) => void;
  keyboardType?: KeyboardTypeOptions;
  containerRef?: any;
  onChange?: (value: number) => void;
  defaultValue?: string | number;
}
const CurrencyInput: FC<CurrencyInputProps> = ({
  autoFocus,
  style,
  label,
  name,
  control,
  rules,
  right,
  placeholder,
  error,
  disabled = false,
  bottomSheetMode = false,
  errorStyle = "inline",
  setPosition,
  keyboardType = "numeric",
  containerRef,
  onChange,
  defaultValue,
}) => {
  const textRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef: textRef, setPosition });
  const handleChange = (setValue) => (text) => {
    setValue(text);
    onChange?.(text);
    /*-- handle input negative number on some Android devices (samsung,...) --*/
    // if (Platform.OS === "android" && (text === "." || text === "-")) {
    //   setValue(text);
    //   return;
    // }
  };

  return (
    <View ref={textRef} style={style}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          if (disabled) {
            return (
              <View style={styles.disabledContainer}>
                <AppText style={[Fonts.Caption, styles.label]}>{label}</AppText>
                <AppText style={[Fonts.BodyLarge, styles.flex]} color={Colors.grayscale80}>
                  {formatMoney(value)}
                </AppText>
                <VNDIcon />
              </View>
            );
          }
          return (
            <CurrencyInputRN
              value={value}
              onChangeValue={handleChange(onChange)}
              delimiter="."
              separator=","
              precision={0}
              renderTextInput={(textInputProps) => {
                return (
                  <PaperTextInput
                    {...(textInputProps as any)}
                    disabled={disabled}
                    dense
                    ref={ref}
                    //defaultValue={3000000}
                    mode="outlined"
                    keyboardType={keyboardType}
                    //onChangeText={onChange}
                    label={label}
                    //value={value}
                    //defaultValue={value ? formatDefaultValue(value) : undefined}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    style={styles.textInput}
                    outlineColor={Colors.grayscale10}
                    activeOutlineColor={Colors.primary50}
                    //onChangeText={handleChange(onChange)}
                    theme={{
                      roundness: 8,
                      colors: {
                        error: Colors.alert50,
                        background: Colors.while,
                        text: CONSTANTS.COMMON.TEXT_INPUT_COLOR,
                        placeholder: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
                      },
                      fonts: {
                        regular: {
                          fontFamily: FontTypes.medium,
                        },
                      },
                    }}
                    error={Boolean(error?.message)}
                    right={
                      right ? (
                        <PaperTextInput.Icon
                          style={styles.rightIcon}
                          theme={{
                            colors: {
                              text: Colors.while,
                            },
                          }}
                          name={() => right}
                        />
                      ) : null
                    }
                  />
                );
              }}
            />
          );
        }}
      />
      {errorStyle === "inline" && Boolean(error?.message) && (
        <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
          {error?.message as string}
        </AppText>
      )}
    </View>
  );
};
export default CurrencyInput;
const styles = StyleSheet.create({
  errorText: { marginLeft: 0, marginTop: 4 },
  rightIcon: {
    paddingTop: 8,
  },
  textInput: {
    height: 48,
  },
  disabledContainer: {
    paddingVertical: 12,
    paddingLeft: 16,
    paddingRight: 12,
    minHeight: 48,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Colors.grayscale0,
    alignItems: "center",
    marginTop: 6,
  },
  flex: { flex: 1 },
  label: {
    position: "absolute",
    top: -8,
    left: 16,
    color: Colors.grayscale60,
  },
});
