import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path d="M12.946 12l-4.6-4.6L9.4 6.346l5.653 5.653L9.4 17.654 8.346 16.6l4.6-4.6z" fill="#94A3B8" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
