// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createNavigationContainerRef, ParamListBase, StackActions } from "@react-navigation/native";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import { CHAT_TYPE } from "constants/constants";

export enum PayloadType {
  NEW_INVOICE = 1,
  REPORT_APPROVED = 2,
  REPORT_REJECTED = 3,
  REPORT_APPROVED_V2 = 5,
  REPORT_REJECTED_V2 = 6,

  REQUEST_APPROVED = 7,
  REQUEST_REJECTED = 8,
  NEW_REQUEST = 9,
  REPORT_CANCEL = 10,
  REQUEST_CANCEL = 11,
  TRANSFER_INVOICE = 13,
  INVOICE_DELETED = 14,
  REQUEST_CLOSED = 15,
  REQUEST_REOPENED = 16,
  REPORT_PAID = 17,
  REPORT_UNPAID = 18,
  INVOICE_VALIDATED = 19,
  EXPENSE_CREATED = 20,
  CARD_TRANSACTION_CREATED = 21,
  NEW_CONVO_MENTION = 22,
  EXPENSE_CREATED_FROM_INVOICE_READY = 25,
  REPORT_PAYMENT_METHOD_UPDATED = 26,
  REPORT_EXPECTED_PAYMENT_DATE_UPDATED = 27,
}
interface NavigationPayload {
  type: PayloadType;
  notification_id: string;
  company_id?: string;
  invoice_id?: string;
  report_id?: string;
  request_id?: string;
  invoiceId?: string;
  expenseId?: string;
  objectId?: string;
  objectType?: string;
  convoObject?: {
    id?: string;
    docSequence?: string;
  };
}
export const navigationRef = createNavigationContainerRef();

let initialRoute = null;
/*-- use this function: in the case navigate when user click notification from app quit state --> react-navigation not ready --> cannot navigate --*/
const navigateFromNotification = (screenName: string, params?: ParamListBase) => {
  if (navigationRef?.isReady?.()) {
    navigationRef.navigate(screenName, params);
    return;
  }
  initialRoute = { screenName, params };
};

export function navigateBaseOnNotificationPayload({
  type,
  invoice_id,
  report_id,
  request_id,
  company_id,
  invoiceId,
  expenseId,
  cardTransactionId,
  expenseRequestId,
  expenseReportId,
  companyId,
  ...rest
}: NavigationPayload) {
  switch (type) {
    case PayloadType.NEW_INVOICE:
    case PayloadType.TRANSFER_INVOICE:
    case PayloadType.INVOICE_VALIDATED: {
      navigateFromNotification(SCREEN_NAME.InvoiceDetailScreen, { invoiceId: invoice_id || invoiceId });
      break;
    }
    case PayloadType.REPORT_APPROVED:
    case PayloadType.REPORT_APPROVED_V2:
    case PayloadType.REPORT_CANCEL:
    case PayloadType.REPORT_PAID:
    case PayloadType.REPORT_UNPAID:
    case PayloadType.REPORT_PAYMENT_METHOD_UPDATED:
    case PayloadType.REPORT_EXPECTED_PAYMENT_DATE_UPDATED:
    case PayloadType.REPORT_REJECTED:
    case PayloadType.REPORT_REJECTED_V2:
      if (report_id || expenseReportId) {
        navigateFromNotification(SCREEN_NAME.ReportDetailScreen, {
          expenseReportId: report_id || expenseReportId,
          companyId: company_id || companyId,
        });
      }
      break;
    case PayloadType.REQUEST_APPROVED:
    case PayloadType.REQUEST_REJECTED:
    case PayloadType.NEW_REQUEST:
    case PayloadType.REQUEST_CANCEL:
    case PayloadType.REQUEST_CLOSED:
    case PayloadType.REQUEST_REOPENED:
      if (request_id || expenseRequestId) {
        navigateFromNotification(SCREEN_NAME.DetailRequestScreen, {
          expenseRequestId: request_id || expenseRequestId,
          companyId: company_id || companyId,
        });
      }
      break;
    case PayloadType.EXPENSE_CREATED:
      navigateFromNotification(SCREEN_NAME.EditExpenseScreen, { expenseId });
      break;
    case PayloadType.CARD_TRANSACTION_CREATED:
      navigateFromNotification(SCREEN_NAME.TransactionDetailScreen, {
        cardTransactionId,
        companyId: company_id,
      });
      break;

    case PayloadType.NEW_CONVO_MENTION: {
      navigateFromNotification(SCREEN_NAME.CommentScreen, {
        objectId: rest?.convoObject?.id,
        type: rest?.objectType as CHAT_TYPE,
        docSequence: rest?.convoObject?.docSequence,
      });
      break;
    }
    case PayloadType.EXPENSE_CREATED_FROM_INVOICE_READY: {
      navigateFromNotification(SCREEN_NAME.ExpenseDetailScreen, { expenseId });
      break;
    }
    default:
      if (report_id) {
        navigateFromNotification(SCREEN_NAME.ReportDetailScreen, { expenseReportId: report_id, companyId: company_id });
      }
      break;
  }
}

export function navigate(name: string, params?: ParamListBase) {
  navigationRef.navigate(name, params);
}
export function replace(name: string, params?: ParamListBase) {
  navigationRef.dispatch(StackActions.replace(name, params));
}
export function goBack() {
  if (navigationRef.canGoBack()) {
    navigationRef.goBack();
    return;
  }
  navigationRef.dispatch(StackActions.replace(SCREEN_NAME.HomeBottomTabNavigator));
}
/*-- use this function: in the case navigate when user click notification from app quit state --> react-navigation not ready --> cannot navigate --*/
export function goToInitialRoute() {
  if (initialRoute) {
    navigationRef?.navigate(initialRoute.screenName, initialRoute.params);
  }
}
export function navigateNotification(playload, isDirected = true) {
  const { type } = playload || {};
  let screenName = "";

  switch (type) {
    case "1": {
      const { invoice_id, expense_id, tab } = playload || {};
      screenName = isDirected ? SCREEN_NAME.InvoiceDetailScreen : SCREEN_NAME.InboxTabScreen;
      if (invoice_id && !expense_id) {
        requestAnimationFrame(() => {
          navigationRef.current?.navigate(screenName, {
            tabId: "1",
            invoiceId: invoice_id,
            autofill: true,
          });
        });
      }

      if (invoice_id && expense_id) {
        requestAnimationFrame(() => {
          navigationRef.current?.navigate(screenName, {
            tabId: "1",
            invoiceId: invoice_id,
            expenseId: expense_id,
          });
        });
      }

      if (tab) {
        requestAnimationFrame(() => {
          navigationRef.current?.navigate(SCREEN_BOTTOM_TAB.ExpensesScreen, {
            tabId: "0",
          });
        });
      }

      break;
    }
    case "2": {
      const { report_id } = playload || {};
      screenName = isDirected ? SCREEN_NAME.ReportDetailScreen : SCREEN_BOTTOM_TAB.ReportsScreen;
      if (report_id) {
        requestAnimationFrame(() => {
          navigationRef.current?.navigate(screenName, {
            expenseReportId: report_id,
          });
        });
      }
      break;
    }
    default:
      break;
  }
}
