import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent({ width = 107, height = 106 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 107 106" fill="none">
      <G clipPath="url(#clip0_4418_140052)">
        <Path
          d="M44.156 98.25c2.546.81 5.446 1.268 8.532 1.268 3.087 0 5.986-.457 8.532-1.268-2.463-2.276-5.404-3.73-8.532-3.73s-6.038 1.434-8.532 3.73z"
          fill="#C0CAD8"
        />
        <Path
          d="M97.136 64.852c-1.32 20.701-21.324 30.033-45.185 28.506-23.86-1.518-42.494-13.334-41.174-34.014 1.32-20.68 21.99-40.374 45.85-38.846 23.85 1.517 41.83 23.652 40.51 44.354z"
          fill="#E4EAF1"
        />
        <Path
          d="M38.282 23.522L35.258 70.87c5.383 2.11 11.431 3.45 17.895 3.866 6.454.416 12.637-.145 18.25-1.558l3.023-47.348c-5.331-2.993-11.358-4.915-17.802-5.33-6.453-.416-12.678.727-18.342 3.023z"
          fill="#1F71F4"
        />
        <Path
          d="M52.75 40.908l-.903-.063.79-2.722 1.662-5.8.083-.311-4.593-.291 2.162-7.368-2.713-.177 6.61-3.73 4.084 4.416-2.1-.135-.852 2.94 6.038.385-10.267 12.856z"
          fill="#fff"
        />
        <Path
          d="M21.802 72c-5.145-7.263 2.359-29.867 10.184-34.086 10.517-5.664 40.28-2.972 49.29 4.843 6.527 5.674 9.925 28.64 4.23 34.253C74.137 88.213 30.978 84.96 21.802 72z"
          fill="#1B1B1D"
        />
        <Path
          d="M13.56 66.122l-4.884-1.58c-.894-.28-1.497-1.32-1.424-2.453l.53-8.313c.073-1.133.8-2.09 1.746-2.256l5.04-.945c1.258-.229 2.349.977 2.245 2.515l-.686 10.828c-.114 1.507-1.351 2.598-2.567 2.204z"
          fill="#C0CAD8"
        />
        <Path
          d="M10.786 65.28l-4.48-1.423c-.83-.26-1.371-1.206-1.309-2.266l.489-7.607c.062-1.05.727-1.902 1.58-2.058l4.624-.852c1.153-.218 2.14.904 2.057 2.286l-.634 9.915c-.083 1.403-1.226 2.37-2.327 2.005z"
          fill="#E4EAF1"
        />
        <Path
          d="M93.465 71.886l5.04-.945c.946-.166 1.674-1.123 1.746-2.255l.53-8.314c.073-1.133-.53-2.172-1.444-2.463l-4.885-1.58c-1.215-.384-2.452.676-2.546 2.203l-.686 10.84c-.093 1.527.988 2.764 2.245 2.514z"
          fill="#C0CAD8"
        />
        <Path
          d="M96.344 71.421l4.624-.852c.863-.156 1.518-1.029 1.58-2.057l.488-7.607c.063-1.05-.478-1.985-1.309-2.245l-4.48-1.424c-1.121-.364-2.244.624-2.327 2.006l-.634 9.904c-.083 1.371.904 2.494 2.058 2.275z"
          fill="#E4EAF1"
        />
        <Path
          d="M44.375 66.703a6.09 6.09 0 100-12.18 6.09 6.09 0 000 12.18zM66.322 65.956a3.357 3.357 0 100-6.714 3.357 3.357 0 000 6.714z"
          fill="#fff"
        />
        <Path
          d="M23.485 20.892c2.401-4.011 2.671-14.435-7.482-14.435S3.969 16.61 3.969 16.61l5.882 2.536s1.34-3.876 4.572-4.677c3.232-.8 5.519.936 2.526 7.005-1.705 3.45-.873 8.968.654 10.371l6.153-2.94c0 .01-2.682-4.002-.27-8.013zM23.58 40.024a4.084 4.084 0 100-8.169 4.084 4.084 0 000 8.169z"
          fill="#FF6D5A"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_4418_140052">
          <Path fill="#fff" transform="translate(3.969 6.457)" d="M0 0H99.0693V93.0626H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
