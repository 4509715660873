import Svg, { Circle, Path } from "react-native-svg";

const SvgComponent = () => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Circle cx={12} cy={12} r={11} fill="#37B24D" stroke="white" strokeWidth={2} />
      <Path
        d="M8 12L10.6635 14.5L16 9.5"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default SvgComponent;
