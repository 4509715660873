import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_WAITING_APPROVAL_OBJECTS_AGGREGATE = gql`
  query MobileExpWaitingApprovalObjectAggregate($condition: ExpWaitingApprovalObjectsCondition!) {
    expWaitingApprovalObjectsAggregate(condition: $condition) {
      total
    }
  }
`;

const useWaitingApprovalObjectsAggregate = ({ condition }) => {
  const [fetchData, result] = useLazyQuery(GET_WAITING_APPROVAL_OBJECTS_AGGREGATE, {
    variables: {
      condition,
    },
    fetchPolicy: "network-only",
  });

  return {
    ...result,
    data: result.data?.expWaitingApprovalObjectsAggregate ?? { total: 0 },
    fetchData,
  };
};

export default useWaitingApprovalObjectsAggregate;
