// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Toast } from "@ant-design/react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { BizziBotWarning, BizziBotWarning2, TrashIcon } from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  LoadingView,
  ToastManager,
  useBackHandler,
} from "components";
import useInteractionManager from "components/hooks/useInteractionManager";
import {
  ALLOWANCE_TYPE,
  PAYMENT_METHOD,
  REQUEST_STATUS,
  REQUEST_TYPE,
  RESTRICTION_BUDGET_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import useDeleteRequestMutation from "hooks/request/useDeleteItemRequest";
import { useSubmitExpenseRequest, useUpdateExpenseRequest } from "hooks/request/useLegacyExpenseRequests";
import differenceBy from "lodash/differenceBy";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import { RootStackParamList, TabParamList } from "navigation/type";
import React, { FC, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  BusBookingSettings,
  ExpenseRequestFormAction,
  ExpenseRequestFormValues,
  ExpenseRequestListTabs,
  FlightBookingSettings,
  HotelBookingSettings,
  OverBudget,
} from "screens/ExpenseRequest/types";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { isEmpty } from "lodash";
import { countNumberOfDays, removeDuplicateObjects, saveRecentCompanyTeamToStorage } from "utils";
import { useAuth } from "contexts/AuthContext";
import ExpenseRequestForm from "screens/ExpenseRequest/components/ExpenseRequestForm/ExpenseRequestForm";
import dayjs from "dayjs";
import useExpenseRequestDetailQuery from "screens/ExpenseRequest/hooks/useExpenseRequestDetailQuery";
import { Colors, Fonts } from "theme";
import { goBack } from "navigation/RootNavigation";
import { EVENT } from "constants/Tracking";
import { analyticService, withTrackingSession } from "services/AnalyticsService";
import { executeRouteFunction } from "utils/route";

type UpdateExpenseRequestScreenProps = NativeStackScreenProps<TabParamList & RootStackParamList, "EditRequestScreen">;

const UpdateExpenseRequestScreen: FC<UpdateExpenseRequestScreenProps> = ({ navigation, route }) => {
  const { expenseRequestId, onRefreshData } = route.params;
  const { user, company, groupId } = useAuth();

  const isReady = useInteractionManager();
  const { t } = useTranslation("app/screens/ExpenseRequest/UpdateExpenseRequestScreen");
  const { data: { status, ...data } = {} as ExpenseRequestFormValues, loading } =
    useExpenseRequestDetailQuery(expenseRequestId);
  const { deleteRequest } = useDeleteRequestMutation();
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const onDeleteExpenseRequest = useCallback(async () => {
    try {
      alertNotificationRef.current?.loading();
      await deleteRequest({
        variables: { expenseRequestId },
      });

      alertNotificationRef.current?.close();
      ToastManager.success(t("expense_request_delete_successfully"));
      navigation.navigate(SCREEN_BOTTOM_TAB.RequestScreen, { tab: ExpenseRequestListTabs.MINE });
      executeRouteFunction(route, "onRefreshData");
    } catch (error) {
      alertNotificationRef.current?.close();
      Toast.fail(t("an_error_occurred_during_processing"));
    }
  }, [deleteRequest, expenseRequestId, t, navigation, onRefreshData]);

  const showAlert = useCallback(() => {
    alertNotificationRef.current?.warning({
      icon: <BizziBotWarning />,
      title: t("cancel_title"),
      description: t("cancel_confirm"),
      cancelText: t("close"),
      confirmText: t("cancel_change"),
      onConfirm: () => {
        alertNotificationRef.current?.close();
        executeRouteFunction(route, "onRefreshData");
        goBack();
      },
    });
  }, [navigation, t]);

  const handleGoBack = useCallback(() => {
    if (!isDirty) {
      goBack();
      return;
    }
    showAlert();
  }, [isDirty, navigation, showAlert]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("update_request")}
          onPress={handleGoBack}
          headerRight={
            [REQUEST_STATUS.DRAFT, REQUEST_STATUS.CANCELLED, REQUEST_STATUS.REJECTED].includes(
              status as REQUEST_STATUS
            ) && (
              <TouchableOpacity
                onPress={() => {
                  alertNotificationRef.current?.warning({
                    cancelText: t("close"),
                    confirmText: t("delete_confirm"),
                    title: t("delete_expense_request"),
                    description: t("delete_expense_request_description"),
                    onConfirm: onDeleteExpenseRequest,
                  });
                }}
              >
                <TrashIcon />
              </TouchableOpacity>
            )
          }
        />
      ),
    });
  }, [navigation, t, data, onDeleteExpenseRequest, handleGoBack, status]);

  useBackHandler(() => {
    if (isDirty) {
      showAlert();
      return true;
    }
    return false;
  });
  const { updateExpenseRequest } = useUpdateExpenseRequest();
  const { submitExpenseRequest } = useSubmitExpenseRequest();
  const onUpdateRequest = async ({
    type,
    values,
    budgetValidate,
    message,
  }: {
    type: ExpenseRequestFormAction;
    values: ExpenseRequestFormValues;
    budgetValidate?: OverBudget[];
    message?: string;
  }) => {
    try {
      setDisabledButton(true);
      alertNotificationRef.current?.loading?.();
      const originalAttachments = data.attachments ?? [];
      const removeAttachments = differenceBy(originalAttachments, values.attachments, ({ id }) => id);
      const newAttachments = differenceBy(values.attachments, originalAttachments, ({ id }) => id);
      const body: any = {
        expenseRequestType: values?.expenseRequestType,
        title: values?.title,
        allowances:
          values?.allowances
            ?.filter((item) => item?.type === ALLOWANCE_TYPE.USER_REQUEST)
            ?.map((item) => ({
              amount: item.amount,
              foreignAmount: values?.currency?.isOn ? item.foreignAmount : null,
              description: item.description,
              expenseCategoryId: item.expenseCategoryId,
              productName: item.productName,
              quantity: item.quantity ? Number(item.quantity) : undefined,
              unitPrice: item.unitPrice ? Number(item.unitPrice) : undefined,
              uom: item.uom,
            })) ?? [],
        companyId: company.company_id,
        groupId: groupId,
        employeeId: user?.employee_id,
        budgetDate: dayjs(values?.budgetDate).toISOString(),
        /*travel: values?.travel
          ? {
              fromCityId: values?.travel?.fromCity.key,
              toCityId: values?.travel?.toCity.key,
              fromDate: dayjs(values?.travelDate?.fromDate).toISOString(),
              toDate: dayjs(values?.travelDate?.toDate).toISOString(),
              perDiem: values?.travel?.perDiem,
            }
          : null,*/
        description: values?.description,
        teamId: values?.companyTeam?.companyTeamId,
        attachments: values?.attachments,
        cashAdvances: null,
        currency: values?.currency?.isOn ? values?.currency?.name : "VND",
        exchangeRate: values?.currency?.isOn ? values?.currency?.exchangeRate : null,
        note: values?.note ?? null,
      };
      /*-- flow save travel info --*/
      if (values?.expenseRequestType === REQUEST_TYPE.travel) {
        const allowancePerDiems = values?.allowances?.filter((item) => item?.type === ALLOWANCE_TYPE.PERDIEM);
        const numOfDays = countNumberOfDays(values?.travelDate?.fromDate, values?.travelDate?.toDate);
        body.travel = {
          fromCityId: values?.travel?.fromCity?.metadata?.locationId,
          toCityId: values?.travel?.toCity?.metadata?.locationId,
          fromDate: dayjs(values?.travelDate?.fromDate).startOf("day").toISOString(),
          toDate: dayjs(values?.travelDate?.toDate).endOf("day").toISOString(),
          //perDiem: totalPerDiem,
          perDiems: allowancePerDiems?.map((item) => ({
            expenseCategoryId: item?.expenseCategoryId,
            amount: item?.amount / numOfDays,
          })),
          expenseBookings: [],
          passengers: null,
        };

        // save passenger for use case TMG company
        if (!isEmpty(values?.passengers) && values?.enablePassenger) {
          body.travel.passengers = values.passengers;
        }

        /*-- in the case company not setup category for perdiem --> use old work flow --*/
        if (values?.travel?.perDiem && !body?.travel?.perDiems?.length) {
          body.travel.perDiem = values.travel.perDiem;
          delete body.travel.perDiems;
        }
        if (values?.flightBooking?.isOn) {
          const { flightBooking } = values;
          const bookingSettings: FlightBookingSettings = {
            departureAirport: {
              code: flightBooking?.from?.code,
              name: flightBooking?.from?.name,
              nameEn: flightBooking?.from?.nameEn,
              location: {
                code: flightBooking?.from?.location?.code,
                name: flightBooking?.from?.location?.name,
                nameEn: flightBooking?.from?.location?.nameEn,
              },
            },
            arrivalAirport: {
              code: flightBooking?.to?.code,
              name: flightBooking?.to?.name,
              nameEn: flightBooking?.to?.nameEn,
              location: {
                code: flightBooking?.to?.location?.code,
                name: flightBooking?.to?.location?.name,
                nameEn: flightBooking?.to?.location?.nameEn,
              },
            },
            isRoundTrip: flightBooking?.isRoundTrip || false,
            departureDate: dayjs(flightBooking?.departureDate).toISOString(),
            departureNote: flightBooking?.departureNote,
            returnDate: flightBooking?.isRoundTrip ? dayjs(flightBooking?.returnDate).toISOString() : undefined,
            returnNote: flightBooking?.isRoundTrip ? flightBooking?.returnNote : undefined,
            vendorName: flightBooking?.vendorName ?? null,
          };

          const allowanceFlightBooking = values?.allowances?.find(
            (item) => item?.type === ALLOWANCE_TYPE.BOOKING_FLIGHT
          );
          if (allowanceFlightBooking) {
            body.travel.expenseBookings.push({
              expenseCategoryId: allowanceFlightBooking.expenseCategoryId,
              totalAmount: allowanceFlightBooking?.amount || 0,
              flightBooking: bookingSettings,
            });
          }
        }

        if (values?.transportationBooking?.isOn) {
          const { transportationBooking } = values;
          const bookingSettings: BusBookingSettings = {
            pickupLocation: {
              code: transportationBooking?.from?.metadata?.code,
              name: transportationBooking?.from?.metadata?.name,
              nameEn: transportationBooking?.from?.metadata?.nameEn,
            },
            dropOffLocation: {
              code: transportationBooking?.to?.metadata?.code,
              name: transportationBooking?.to?.metadata?.name,
              nameEn: transportationBooking?.to?.metadata?.nameEn,
            },
            departureDate: dayjs(transportationBooking?.departureDate).toISOString(),
            returnDate: transportationBooking?.isRoundTrip
              ? dayjs(transportationBooking?.returnDate).toISOString()
              : undefined,
            departureNote: transportationBooking?.departureNote,
            returnNote: transportationBooking?.returnNote,
            isRoundTrip: transportationBooking?.isRoundTrip || false,
          };
          const allowanceTransportationBooking = values?.allowances?.find(
            (item) => item?.type === ALLOWANCE_TYPE.BOOKING_BUS
          );
          if (allowanceTransportationBooking) {
            body.travel.expenseBookings.push({
              expenseCategoryId: allowanceTransportationBooking.expenseCategoryId,
              totalAmount: allowanceTransportationBooking?.amount || 0,
              transportationBooking: bookingSettings,
            });
          }
        }
        if (values?.hotelBooking?.isOn) {
          const { hotelBooking, hotelBookingDate } = values;
          const bookingSettings: HotelBookingSettings = {
            location: {
              code: hotelBooking?.location?.metadata?.code,
              name: hotelBooking?.location?.metadata?.name,
              nameEn: hotelBooking?.location?.metadata?.nameEn,
            },
            checkInDate: dayjs(hotelBookingDate?.fromDate).startOf("day").toISOString(),
            checkOutDate: dayjs(hotelBookingDate?.toDate).endOf("day").toISOString(),
            note: hotelBooking?.note,
            companyHotelCode: hotelBooking?.companyHotelCode,
            hotelName: hotelBooking?.hotelName,
            numberOfRooms: hotelBooking?.numberOfRooms,
            roomRate: hotelBooking?.roomRate,
          };
          const allowanceHotelBooking = values?.allowances?.find((item) => item?.type === ALLOWANCE_TYPE.BOOKING_HOTEL);
          if (allowanceHotelBooking) {
            body.travel.expenseBookings.push({
              expenseCategoryId: allowanceHotelBooking?.expenseCategoryId,
              totalAmount: allowanceHotelBooking?.amount || 0,
              hotelBooking: bookingSettings,
            });
          }
        }
      }
      /*--- end ---*/
      /*--- flow save cash advance ---*/
      if (values?.hasCashAdvance) {
        body.cashAdvances = [
          {
            amount: Number(values?.cashAdvance?.amount),
            foreignAmount: values?.currency?.isOn ? values?.cashAdvance?.foreignAmount : null,
            description: values?.cashAdvance?.description,
            paymentMethod: values?.cashAdvance?.paymentMethod,
            paymentInfo:
              values?.cashAdvance?.paymentMethod === PAYMENT_METHOD.BANK_TRANSFER
                ? {
                    accountHolderName: values?.paymentInfo?.accountHolderName,
                    accountNumber: values?.paymentInfo?.accountNumber,
                    bankCode: values?.paymentInfo?.bank?.metadata?.bankCode,
                    bankId: values?.paymentInfo?.bank?.metadata?.bankId,
                    bankName: values?.paymentInfo?.bank?.metadata?.bankName,
                    bankShortName: values?.paymentInfo?.bank?.metadata?.bankShortName,
                    branchName: values?.paymentInfo?.branchName,
                    description: values?.paymentInfo?.description,
                    logoUrl: values?.paymentInfo?.bank?.metadata?.logoUrl,
                    type: values?.paymentInfo?.type,
                  }
                : undefined,
          },
        ];
      }
      await updateExpenseRequest(expenseRequestId, user?.employee_id, { ...body, newAttachments, removeAttachments });

      if (type === ExpenseRequestFormAction.SUBMIT) {
        const { data: submitData } = await submitExpenseRequest(expenseRequestId, values?.approverId);
        const { success: isSuccess } = submitData?.expenseRequest ?? {};
        if (!isSuccess) {
          throw new Error("update_request_failed");
        }
        alertNotificationRef?.current?.close();
        analyticService.logEvent({
          sessionId: SCREEN_NAME.EditRequestScreen,
          name: EVENT.REQUEST.SUBMITTED_SUCCESS,
          properties: {
            message,
            object_id: expenseRequestId,
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            over_budgets: budgetValidate?.map((item) => ({
              expense_category_id: item.expenseCategoryId,
              company_team_id: values?.companyTeam?.companyTeamId,
              over_amount: item.overBudgetAmount,
              expense_date: values?.budgetDate,
            })),
          },
        });
        ToastManager.success(t("send_request_success"));
      } else {
        alertNotificationRef?.current?.close();
        analyticService.logEvent({ sessionId: SCREEN_NAME.EditRequestScreen, name: EVENT.REQUEST.UPDATED_SUCCESS });
        ToastManager.success(t("create_success"));
      }
      setDisabledButton(false);
      executeRouteFunction(route, "onRefreshData");
      goBack();
    } catch (error: any) {
      setDisabledButton(false);
      const title = type === ExpenseRequestFormAction.SUBMIT ? t("send_claim_failed") : t("save_failed");
      let message = "";
      switch (error?.message) {
        case SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW:
          message = t("no_approval_flow_error");
          break;
        case SERVER_ERROR_CODE.EXPENSE_UNSUPPORTED_APPROVAL_METHOD:
          message = t("no_approval_flow_error");
          break;
        case SERVER_ERROR_CODE.EXPENSE_TRAVEL_POLICY_MISMATCH:
          message =
            type === ExpenseRequestFormAction.SUBMIT
              ? t("submit_error_expense_travel_policy_mismatch")
              : t("save_error_expense_travel_policy_mismatch");
          break;
        default:
          message = t("update_request_internal_error");
          break;
      }

      const noApprovalFlowError = [
        SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW,
        SERVER_ERROR_CODE.EXPENSE_UNSUPPORTED_APPROVAL_METHOD,
      ].includes(error?.message);
      alertNotificationRef?.current?.error({
        title,
        description: message,
        confirmText: noApprovalFlowError ? t("view_approval_flow") : undefined,
        onConfirm: noApprovalFlowError
          ? () => {
              alertNotificationRef?.current?.close();
              (navigation as any).navigate(SCREEN_NAME.ApprovalFlowScreen, { index: 0 });
            }
          : undefined,
      });
      analyticService.logEvent({
        sessionId: SCREEN_NAME.EditRequestScreen,
        name: type === ExpenseRequestFormAction.SUBMIT ? EVENT.REQUEST.SUBMITTED_FAILED : EVENT.REQUEST.UPDATED_FAILED,
        properties: { message, error: JSON.stringify(error) },
      });
    }
  };
  const handleSubmit = (type: ExpenseRequestFormAction, values: ExpenseRequestFormValues) => {
    if (values?.companyTeam) {
      saveRecentCompanyTeamToStorage({
        companyId: company?.company_id,
        employeeId: user?.employee_id,
        companyTeam: values?.companyTeam,
      });
    }
    if (type === ExpenseRequestFormAction.SUBMIT) {
      const budgetValidate = Object.values(values?.overBudgets ?? {}) as OverBudget[];
      if (!isEmpty(budgetValidate)) {
        const isRestrict = budgetValidate.some((item) => item?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT);
        if (isRestrict) {
          analyticService.logEvent({
            name: EVENT.SYSTEM_ALERT.RESTRICT_REQUEST_BUDGET,
            properties: {
              object_id: expenseRequestId,
              object_type: TRACKING_OBJECT_TYPE.REQUEST,
              restriction_type: RESTRICTION_BUDGET_TYPE.RESTRICT,
              message: t("restrict_budget_request"),
              over_budgets: budgetValidate?.map((item) => ({
                expense_category_id: item?.expenseCategoryId,
                company_team_id: values?.companyTeam?.companyTeamId,
                over_amount: item?.overBudgetAmount,
                expense_date: values?.budgetDate,
              })),
            },
          });
          alertNotificationRef.current.info({
            title: t("budget_amount_warning_title"),
            icon: <BizziBotWarning2 />,
            description: t("restrict_budget_request"),
            confirmText: t("understood"),
            onConfirm: () => {
              alertNotificationRef?.current?.close();
            },
          });
          return;
        }
        analyticService.logEvent({
          name: EVENT.SYSTEM_ALERT.WARNING_REQUEST_BUDGET,
          properties: {
            object_id: expenseRequestId,
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            restriction_type: RESTRICTION_BUDGET_TYPE.WARNING,
            message: removeDuplicateObjects(budgetValidate, "message")
              ?.map((item) => item?.message)
              ?.join(".\n"),
            over_budgets: budgetValidate?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: values?.companyTeam?.companyTeamId,
              over_amount: item?.overBudgetAmount,
              expense_date: values?.budgetDate,
            })),
          },
        });
        const messageBudgets = removeDuplicateObjects(budgetValidate, "message").map((item) => item?.message);

        alertNotificationRef.current?.confirm({
          title: t("budget_amount_warning_title"),
          icon: <BizziBotWarning2 />,
          description: (
            <>
              {messageBudgets?.map((item, index) => (
                <AppText
                  key={index}
                  color={Colors.grayscale80}
                  style={[Fonts.BodyMedium, styles.textCenter]}
                >{`${item}.`}</AppText>
              ))}
              <AppText color={Colors.grayscale80} style={[Fonts.BodyMedium, styles.textCenter]}>
                {t("budget_amount_warning_context")}
              </AppText>
            </>
          ),
          cancelText: t("back"),
          confirmText: t("confirm"),
          onConfirm: () => {
            onUpdateRequest({
              type,
              values,
              budgetValidate,
              message: `${messageBudgets.join("\n")}\n${t("budget_amount_warning_context")}`,
            });
          },
        });
      } else {
        alertNotificationRef.current.confirm({
          title: t("submit"),
          description: t("submit_confirmation_text"),
          confirmText: t("continue"),
          cancelText: t("skip"),
          onConfirm: () => {
            onUpdateRequest({ type, values });
          },
        });
      }
      return;
    }
    onUpdateRequest({ type, values });
  };

  if (loading || !isReady) {
    return <LoadingView />;
  }
  return (
    <View style={styles.container}>
      <ExpenseRequestForm
        disabledButton={disabledButton}
        setIsDirty={setIsDirty}
        initialValues={data}
        onSubmit={handleSubmit}
      />
      <AlertNotification ref={alertNotificationRef} />
    </View>
  );
};

export default withTrackingSession(UpdateExpenseRequestScreen, SCREEN_NAME.EditRequestScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale05,
  },
  textCenter: {
    textAlign: "center",
  },
});
