// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useLayoutEffect, useCallback, useMemo, memo, useState, useEffect, useRef, useContext } from "react";
import { StyleSheet, View, FlatList, Share, TouchableOpacity } from "react-native";
import { useFocusEffect, useScrollToTop, useNavigation } from "@react-navigation/native";

import { WhiteSpace, Flex, Icon } from "@ant-design/react-native";
import { LocalizationContext } from "contexts/LocalizationContext";

import AntDesign from "react-native-vector-icons/AntDesign";

import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import Colors from "constants/Colors";
import { AppText, BizziButton, Line } from "app/components";
import { useAuth } from "app/contexts/AuthContext";
import { useTranslation } from "react-i18next";

const NUMBER_ROWS_PER_PAGE = 10;

const QUERY_COMPANIES = gql`
  query MobileCompanies($groupId: uuid!, $offset: Int) {
    companies(where: { users_companies: { group_id: { _eq: $groupId } } }
      order_by: { created_at: desc }
      limit: ${NUMBER_ROWS_PER_PAGE}
      offset: $offset
     ) {
      address
      company_id
      created_at
      tax_code
      name
      email
      accountant_email
      users_companies {
        staff_name
      }
    }
  }
`;

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: Colors.whiteBackground,
    borderRadius: 8,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  companyTitle: {
    flex: 5,
    // fontFamily: Fonts.fontFamilyBold,
    color: Colors.textColor2,
    fontSize: 18,
    fontWeight: "700",
  },
  labelContainer: {
    marginVertical: 8,
  },
  label: {
    fontSize: 12,
    color: Colors.grey4,
  },
  content: {
    fontSize: 16,
    flex: 1,
  },
  address: {
    fontSize: 16,
    flex: 0.8,
  },

  emptyContainer: {
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
});

function useCompanies(apiData, receiveInvoiceEmail) {
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    setCompanies(
      (apiData?.companies || []).map((comp) => ({
        ...comp,
        staffName: comp.users_companies[0].staff_name,
        receiveInvoiceEmail,
      }))
    );
  }, [apiData, receiveInvoiceEmail]);
  return companies;
}

const Empty = memo(({ t }) => {
  const navigation = useNavigation();
  return (
    <View style={styles.emptyContainer}>
      {/* <AddCompanyInfo /> */}
      {/* <WhiteSpace size="md" />
      <AppText style={styles.emptyText}>{t("empty_company_info")}</AppText>
      <WhiteSpace size="md" /> */}
      <BizziButton
        style={styles.button}
        mode="contained"
        color="#0086FF"
        onPress={() => navigation.navigate("CompanyFormScreen")}
        title={t("add_your_company")}
      />
    </View>
  );
});

const CompanyScreen = ({ navigation }) => {
  const { groupId, receiveInvoiceEmail } = useAuth();
  const [fetchCompanies, { data, loading, refetch, fetchMore, called }] = useLazyQuery(QUERY_COMPANIES, {
    variables: { groupId, offset: 0 },
  });
  const companies = useCompanies(data, receiveInvoiceEmail);
  const [blockedLoadMore, setBlockedLoadMore] = useState(false);
  const flatListRef = useRef<FlatList>(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { t } = useTranslation("screens/CompanyTab/CompanyScreen");
  const isLoading = useMemo(() => {
    return !called || loading;
  }, [called, loading]);

  useScrollToTop(flatListRef);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ paddingRight: 15 }} onPress={() => navigation.navigate("CompanyFormScreen")}>
          <AntDesign name="plus" size={24} color="white" />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  // const [primaryId, setPrimaryId] = useState(1);
  // const [visibleDialog, setVisibleDialog] = useState(false);
  // const [selectingCompany, setSelectingCompany] = useState(primaryId);
  const getCompanyById = useCallback(
    (company_id) => {
      return companies.find((item) => item.company_id === company_id);
    },
    [companies]
  );

  useEffect(() => {
    if (fetchCompanies) {
      fetchCompanies();
    }
  }, [fetchCompanies]);

  const refetchData = useCallback(() => {
    if (!called || !refetch) {
      return;
    }
    refetch();
  }, [refetch, called]);

  useFocusEffect(refetchData);

  const hasMore = useMemo(() => {
    const notiCount = companies?.length || 0;
    return called && notiCount % NUMBER_ROWS_PER_PAGE === 0;
  }, [companies, called]);

  const loadMore = useCallback(() => {
    if (blockedLoadMore && !hasMore) {
      return;
    }
    setBlockedLoadMore(true);
    setIsLoadingMore(true);
    fetchMore({
      variables: { offset: data?.companies?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        setIsLoadingMore(false);
        const isLastItems =
          fetchMoreResult?.companies?.length !== 0 && (fetchMoreResult?.companies?.length || 0) < NUMBER_ROWS_PER_PAGE;
        if (!isLastItems) {
          setBlockedLoadMore(false);
        }
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          companies: [...prev.companies, ...fetchMoreResult.companies],
        };
      },
    });
  }, [fetchMore, data, blockedLoadMore, hasMore]);

  const scrollToTop = useCallback(() => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
  });

  const onShare = useCallback(
    async (company) => {
      try {
        const result = await Share.share(
          {
            message: t("share_message", company),
            title: t("share_title", company),
          },
          {
            subject: t("share_title", company), // ios
            // url: 'http://nytimes.com',  // ios
            dialogTitle: t("share_title", company), // Android
          }
        );
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    [t]
  );

  // const showDialog = (id) => {
  //   if (id === primaryId) {
  //     return false;
  //   }
  //   setVisibleDialog(true);
  //   setSelectingCompany(id);
  // };
  // const hideDialog = () => {
  //   setVisibleDialog(false);
  // };

  const Company = ({ item }) => {
    const { company_id, name, tax_code, address, staffName } = item;
    return (
      <View style={styles.itemContainer}>
        <View style={{ padding: 15 }}>
          <Flex justify="between" style={{ width: "100%" }}>
            <AppText style={styles.companyTitle}>{name}</AppText>
            <TouchableOpacity
              style={{ flex: 2 }}
              onPress={() => {
                navigation.navigate("CompanyDetailScreen", item);
              }}
            >
              <Flex align="center" justify="between">
                <AppText style={{ marginLeft: "auto", color: "#0086FF" }}>{t("view")}</AppText>
              </Flex>
            </TouchableOpacity>
          </Flex>
          <Flex style={styles.labelContainer} direction="column" align="start">
            <AppText style={styles.label}>{t("tax_code")}</AppText>
            <AppText style={styles.content}>{tax_code}</AppText>
          </Flex>
          <Flex style={styles.labelContainer} direction="column" align="start">
            <AppText style={styles.label}>{t("address")}</AppText>
            <AppText style={styles.address}>{address}</AppText>
          </Flex>
          <Flex style={styles.labelContainer} direction="column" align="start">
            <AppText style={styles.label}>{t("buyer_name")}</AppText>
            <AppText style={styles.content}>{staffName}</AppText>
          </Flex>
          <Flex style={styles.labelContainer} direction="column" align="start">
            <AppText style={styles.label}>{t("email_to_receive_invoice")}</AppText>
            <AppText style={styles.content}>{receiveInvoiceEmail}</AppText>
          </Flex>
        </View>
        <Line />
        <Flex>
          {/* <Flex>
            <RadioButton.Android // works for iOS too
              value={company_id}
              status={company_id === primaryId ? "checked" : "unchecked"}
              color="#0086FF"
              onPress={() => showDialog(company_id)}
            />
            <TouchableOpacity onPress={() => showDialog(company_id)}>
              <AppText style={{ color: company_id === primaryId ? "#0086FF" : "#253858" }}>{t("primary")}</AppText>
            </TouchableOpacity>
          </Flex> */}
          <Flex style={{ marginLeft: "auto", padding: 5, paddingVertical: 10 }}>
            <TouchableOpacity onPress={() => onShare(getCompanyById(company_id))}>
              <AntDesign name="sharealt" size={24} color="#42526E" />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("CompanyFormScreen", {
                  company: getCompanyById(company_id),
                  type: "edit",
                })
              }
              style={{ marginHorizontal: 15 }}
            >
              <AntDesign name="edit" size={24} color="#42526E" />
            </TouchableOpacity>
          </Flex>
        </Flex>
      </View>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      {/* <HeaderNavigation
        title={t("invoice_issue_info")}
        right={
          <TouchableOpacity onPress={() => navigation.navigate("CompanyFormScreen")}>
            <AntDesign name="plus" size={24} color="white" />
          </TouchableOpacity>
        }
      /> */}
      {isLoading || companies?.length ? (
        <View style={{ flex: 1 }}>
          <WhiteSpace />
          <FlatList
            data={companies}
            ref={flatListRef}
            renderItem={Company}
            keyExtractor={(item) => item.company_id.toString()}
            refreshing={loading || isLoadingMore}
            onRefresh={refetchData}
            onEndReached={loadMore}
            onEndReachedThreshold={0.15}
            onScroll={(e) => {
              const newPosition = e.nativeEvent.contentOffset.y;
              newPosition <= currentPosition && newPosition !== 0 ? setShowScrollTop(true) : setShowScrollTop(false);
              setCurrentPosition(e.nativeEvent.contentOffset.y);
            }}
          />
          {/* {isLoadingMore && <ActivityIndicator size="large" />} */}
          {showScrollTop && (
            <TouchableOpacity
              style={{
                borderWidth: 1,
                borderColor: "rgba(0,0,0,0.2)",
                alignItems: "center",
                justifyContent: "center",
                width: 40,
                position: "absolute",
                bottom: 20,
                right: 20,
                height: 40,
                backgroundColor: "#fff",
                borderRadius: 100,
              }}
              onPress={scrollToTop}
            >
              <Icon name="up" size={15} color="#037ffc" />
            </TouchableOpacity>
          )}
          {/* <Portal>
          <Dialog visible={visibleDialog} onDismiss={hideDialog}>
            <Dialog.Title>
              {loading ? "loading" : t("change_primary_company", { company: getCompanyById(selectingCompany)?.name })}
            </Dialog.Title>
            <Dialog.Content>
              <Paragraph>{t("change_primary_company_confirm")}</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button color="#5E6C84" onPress={hideDialog}>
                {t("cancel")}
              </Button>
              <Button
                mode="contained"
                color="#0086FF"
                style={{ paddingHorizontal: 20 }}
                onPress={() => {
                  hideDialog();
                  setPrimaryId(getCompanyById(selectingCompany)?.company_id);
                }}
              >
                {t("ok")}
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal> */}
        </View>
      ) : (
        <Empty t={t} />
      )}
    </View>
  );
};

export default CompanyScreen;
