// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, ClassAttributes } from "react";
import {
  StyleSheet,
  View,
  TextInput,
  TouchableWithoutFeedback,
  Clipboard,
  Keyboard,
  Platform,
  I18nManager,
  ViewStyle,
  StyleProp,
  TextInputProps,
  EmitterSubscription,
  TextStyle,
  useWindowDimensions,
} from "react-native";

import Colors from "constants/Colors";
import Fonts from "constants/Fonts";

const majorVersionIOS = parseInt(String(Platform.Version), 10);
const isAutoFillSupported = Platform.OS === "ios" && majorVersionIOS >= 12;
const codeToArray = (code: number) => code?.split("") ?? [];

interface IProps {
  autoFocusOnLoad?: boolean;
  pinCount: number;
  autoComplete?: boolean;
  codeInputFieldStyle: StyleProp<ViewStyle>;
  codeInputHighlightStyle: StyleProp<ViewStyle>;
  onCodeFilled: (code: string) => Promise<void>;
  onCodeChanged: (code: string) => void;
  code?: number;
  clearInputs?: boolean;
  placeholderCharacter?: string;
  wrapperInput?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
}

interface IState {
  digits: string[];
  selectedIndex: number;
}

class OTPInputView extends Component<IProps & TextInputProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.fields = [];

    const { code } = props;
    this.state = {
      digits: codeToArray(code),
      selectedIndex: props.autoFocusOnLoad ? 0 : -1,
    };
  }

  fields: ClassAttributes<TextInput>[] = [];

  timer?: number;

  keyboardShow?: boolean;

  hasCheckedClipBoard?: boolean;

  clipBoardCode?: string;

  keyboardDidHideListener?: EmitterSubscription;

  keyboardDidShowListener?: EmitterSubscription;

  copyCodeFromClipBoardOnAndroid = () => {
    if (Platform.OS === "android") {
      this.checkPinCodeFromClipBoard();
      this.timer = setInterval(this.checkPinCodeFromClipBoard, 400);
    }
  };

  bringUpKeyBoardIfNeeded = () => {
    const { autoFocusOnLoad, pinCount } = this.props;
    const digits = this.getDigits();
    const focusIndex = digits.length ? digits.length - 1 : 0;
    if (focusIndex < pinCount && autoFocusOnLoad) {
      this.focusField(focusIndex);
    }
  };

  getDigits = () => {
    const { digits: innerDigits } = this.state;
    const { code } = this.props;
    return code === undefined ? innerDigits : code.split("");
  };

  handleKeyboardDidHide = () => {
    if (this.keyboardShow) {
      this.blurAllFields();
      this.keyboardShow = false;
    }
  };

  handleKeyboardDidShow = () => {
    this.blurAllFields();
  };

  notifyCodeChanged = () => {
    const { digits } = this.state;
    const code = digits.join("");
    const { onCodeChanged } = this.props;
    if (onCodeChanged) {
      onCodeChanged(code);
    }
  };

  checkPinCodeFromClipBoard = () => {
    const { pinCount, onCodeFilled } = this.props;
    const regexp = new RegExp(`^\\d{${pinCount}}$`);
    Clipboard.getString()
      .then((code) => {
        if (this.hasCheckedClipBoard && regexp.test(code) && this.clipBoardCode !== code) {
          this.setState(
            {
              digits: code.split(""),
            },
            () => {
              this.blurAllFields();
              this.notifyCodeChanged();
              onCodeFilled && onCodeFilled(code);
            }
          );
        }
        this.clipBoardCode = code;
        this.hasCheckedClipBoard = true;
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  handleChangeText = (index, text) => {
    const { onCodeFilled, pinCount } = this.props;
    const digits = this.getDigits();
    let newdigits = digits.slice();
    const oldTextLength = newdigits[index] ? newdigits[index].length : 0;
    const newTextLength = text.length;
    if (newTextLength - oldTextLength === pinCount) {
      // user pasted text in.
      newdigits = text.split("").slice(oldTextLength, newTextLength);
      this.setState({ digits: newdigits }, this.notifyCodeChanged);
    } else {
      if (text.length === 0) {
        if (newdigits.length > 0) {
          newdigits = newdigits.slice(0, newdigits.length - 1);
        }
      } else {
        text.split("").forEach((value) => {
          if (index < pinCount) {
            newdigits[index] = value;
            index += 1;
          }
        });
        index -= 1;
      }
      this.setState({ digits: newdigits }, this.notifyCodeChanged);
    }

    const result = newdigits.join("");
    if (result.length >= pinCount) {
      onCodeFilled && onCodeFilled(result);
      this.focusField(pinCount - 1);
      this.blurAllFields();
    } else {
      if (text.length > 0 && index < pinCount - 1) {
        this.focusField(index + 1);
      }
    }
  };

  handleKeyPressTextInput = (index, key) => {
    const digits = this.getDigits();
    if (key === "Backspace") {
      if (!digits[index] && index > 0) {
        this.handleChangeText(index - 1, "");
        this.focusField(index - 1);
      }
    }
  };

  focusField = (index) => {
    if (index < this.fields.length) {
      this.fields[index].focus();
      this.setState({
        selectedIndex: index,
      });
    }
  };

  blurAllFields = () => {
    this.fields.forEach((field) => field?.blur());
    this.setState({
      selectedIndex: -1,
    });
  };

  clearAllFields = () => {
    const { clearInputs, code } = this.props;
    if (clearInputs && code === "") {
      this.setState({ digits: [], selectedIndex: 0 });
    }
  };

  renderOneInputField = (_, index) => {
    const {
      codeInputFieldStyle,
      codeInputHighlightStyle,
      secureTextEntry,
      keyboardType,
      selectionColor,
      keyboardAppearance,
      inputStyle,
      isMobile,
      defaultTextFieldStyle,
      underLineInput,
    } = this.props;

    const { selectedIndex, digits } = this.state;
    const { clearInputs, placeholderCharacter, placeholderTextColor } = this.props;
    const { color: defaultPlaceholderTextColor } = { ...defaultTextFieldStyle, ...codeInputFieldStyle };
    return (
      <View pointerEvents="none" key={index + "view"} testID="inputSlotView" style={underLineInput}>
        <TextInput
          testID="textInput"
          underlineColorAndroid="rgba(0,0,0,0)"
          style={
            selectedIndex === index
              ? [
                  defaultTextFieldStyle,
                  inputStyle,

                  {
                    borderColor: selectedIndex === index ? "#0086FF" : "#C1C7D0",
                    borderBottomWidth: 2,
                    borderRadius: 0,
                  },
                ]
              : [
                  defaultTextFieldStyle,
                  inputStyle,

                  {
                    borderColor: "#C1C7D0",
                    borderBottomWidth: 2,
                    borderRadius: 0,
                  },
                ]
          }
          ref={(ref) => {
            this.fields[index] = ref;
          }}
          onChangeText={(text) => {
            this.handleChangeText(index, text);
          }}
          onKeyPress={({ nativeEvent: { key } }) => {
            this.handleKeyPressTextInput(index, key);
          }}
          value={!clearInputs ? digits[index] : ""}
          textAlign="center"
          keyboardAppearance={keyboardAppearance}
          keyboardType={keyboardType}
          textContentType={isAutoFillSupported ? "oneTimeCode" : "none"}
          key={index}
          selectionColor={selectionColor}
          secureTextEntry={secureTextEntry}
          placeholder={placeholderCharacter}
          placeholderTextColor={placeholderTextColor || defaultPlaceholderTextColor}
          autoCorrect={false}
        />
      </View>
    );
  };

  renderTextFields = () => {
    const { pinCount } = this.props;
    const array = new Array(pinCount).fill(0);
    return array.map(this.renderOneInputField);
  };

  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { code } = this.props;
    if (nextProps.code && nextProps.code !== code) {
      this.setState({ digits: codeToArray(nextProps.code) });
    }
  }

  componentDidMount() {
    this.copyCodeFromClipBoardOnAndroid();
    this.bringUpKeyBoardIfNeeded();
    //this.keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", this.handleKeyboardDidHide);
    //this.keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", this.handleKeyboardDidShow);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.keyboardDidHideListener) {
      this.keyboardDidHideListener?.remove();
    }
  }

  render() {
    const { pinCount, style, clearInputs, wrapperInput } = this.props;
    const digits = this.getDigits();
    return (
      <View testID="OTPInputView" style={[styles.container, style]}>
        <TouchableWithoutFeedback
          style={{ width: "100%", height: "100%" }}
          onPress={() => {
            if (!clearInputs) {
              const filledPinCount = digits.filter((digit) => {
                return digit !== null && digit !== undefined;
              }).length;
              this.focusField(Math.min(filledPinCount, pinCount - 1));
            } else {
              this.clearAllFields();
              this.focusField(0);
            }
          }}
        >
          <View style={[styles.wrapperInputs, wrapperInput]}>{this.renderTextFields()}</View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

OTPInputView.defaultProps = {
  pinCount: 6,
  autoFocusOnLoad: true,
  secureTextEntry: false,
  keyboardAppearance: "default",
  keyboardType: "number-pad",
  clearInputs: false,
  placeholderCharacter: "",
  selectionColor: "#000",
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  wrapperInputs: {
    flexDirection: I18nManager.isRTL ? "row-reverse" : "row",
    justifyContent: "center",

    flex: 1,
  },
});

const WrapperOTPInputView = (props) => {
  const { width: WWidth } = useWindowDimensions();

  return (
    <OTPInputView
      isMobile={WWidth < 768}
      {...props}
      defaultTextFieldStyle={{
        height: WWidth < 600 ? 45 : 90,
        textAlign: "center",
        color: Colors.black,
        fontFamily: Fonts.fontFamilyBold,
        fontSize: WWidth < 600 ? 20 : 40,
        width: "90%",
        fontWeight: "600",
      }}
      underLineInput={{
        width: "16%",
        alignItems: "center",
      }}
    />
  );
};

export default WrapperOTPInputView;
