import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M12.698 1.667h-5.4c-3.133 0-3.375 2.817-1.683 4.35l8.767 7.967c1.691 1.533 1.45 4.35-1.684 4.35h-5.4c-3.133 0-3.375-2.817-1.683-4.35l8.767-7.967c1.691-1.533 1.45-4.35-1.684-4.35z"
        stroke="#1F71F4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
