import { Platform, StyleSheet, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import React, { ReactNode, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetModal,
  BottomSheetModalProps,
  BottomSheetView,
  useBottomSheetDynamicSnapPoints,
} from "@gorhom/bottom-sheet";
import { BottomSheetScrollViewCustom, useWindowDimensions } from "components";
import HeaderBottomSheet from "../BottomSheetModalCustom/HeaderBottomSheetModal";
import useBottomSheetBackHandler from "../BottomSheetModalCustom/useBottomSheetBackHandler";
import { Colors } from "theme";
import { SharedValue, useReducedMotion } from "react-native-reanimated";

export interface BottomSheetScrollViewModalCustomProps extends Omit<BottomSheetModalProps, "snapPoints"> {
  title?: string;
  children: ReactNode;
  snapPoints?:
    | Array<string | number>
    | SharedValue<Array<string | number>>
    | Readonly<(string | number)[] | SharedValue<(string | number)[]>>;
  wrapperByScrollView?: boolean;
  containerStyle?: ViewStyle;
  renderFooter?: () => void;
  renderDescription?: () => ReactNode;
  minHeight?: number;
  enableTouchOutsideToClose?: boolean;
  footerStyle?: ViewStyle;
  renderHeaderList?: () => ReactNode;
}

export interface BottomSheetScrollViewModalCustomMethods {
  present: () => void;
  close?: () => void;
  expand?: () => void;
}
const HEADER_HEIGHT = 40;
const BottomSheetScrollViewModalCustom = React.forwardRef<
  BottomSheetScrollViewModalCustomMethods,
  BottomSheetScrollViewModalCustomProps
>(
  (
    {
      title,
      children,
      snapPoints,
      wrapperByScrollView = true,
      containerStyle,
      renderFooter,
      footerStyle,
      renderDescription,
      minHeight,
      enableTouchOutsideToClose = true,
      onDismiss,
      renderHeaderList,
      ...rest
    },
    ref
  ) => {
    // avoid issue: not showing modal when user enable 'Reduce Motion' on IOS, Android and Webapp
    const reducedMotion = useReducedMotion();

    useImperativeHandle(
      ref,
      () => ({
        present: () => {
          isOpened.current = true;
          bottomSheetRef?.current?.present?.();
        },
        expand: () => {
          bottomSheetRef?.current?.expand();
        },
        close: () => {
          if (reducedMotion) {
            bottomSheetRef?.current?.snapToPosition(0);
          } else {
            bottomSheetRef?.current?.dismiss();
          }
        },
      }),
      [reducedMotion]
    );
    const { width, height } = useWindowDimensions();

    const { bottom, top } = useSafeAreaInsets();
    const bottomSheetRef = useRef<BottomSheetModal>(null);
    const isOpened = useRef<boolean>(false);
    const { handleSheetPositionChange } = useBottomSheetBackHandler(bottomSheetRef);
    const SCROLL_VIEW_MAX_HEIGHT = height - HEADER_HEIGHT - top - 10;
    const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
      useBottomSheetDynamicSnapPoints(["CONTENT_HEIGHT"]);

    useEffect(() => {
      if (Platform.OS === "web") {
        const handleKeyDown = (event) => {
          if (event.key === "Escape" && isOpened.current) {
            bottomSheetRef?.current?.close();
          }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, []);

    const renderBackdrop = useCallback(
      (props) => (
        <BottomSheetBackdrop
          {...props}
          disappearsOnIndex={-1}
          appearsOnIndex={0}
          opacity={0.3}
          pressBehavior={enableTouchOutsideToClose ? "close" : "none"}
        />
      ),
      [enableTouchOutsideToClose]
    );
    const renderHeaderHandle = useCallback(
      (props) => {
        return (
          <HeaderBottomSheet
            renderDescription={renderDescription}
            title={title}
            onClose={() => bottomSheetRef?.current?.close()}
            {...props}
          />
        );
      },
      [title, renderDescription]
    );

    const bottomInset = bottom || 15;
    const topInset = top ? top + 5 : 30;
    const renderFooterComponent = useCallback(
      (props) => (
        <BottomSheetFooter
          style={[styles.footer, { paddingBottom: bottomInset }, footerStyle]}
          {...props}
          bottomInset={0}
        >
          {renderFooter?.()}
        </BottomSheetFooter>
      ),
      [footerStyle, renderFooter]
    );
    const handleOnDismiss = () => {
      isOpened.current = false;
      onDismiss?.();
    };
    return (
      <BottomSheetModal
        topInset={topInset}
        snapPoints={
          (snapPoints ? snapPoints : animatedSnapPoints) as Array<string | number> | SharedValue<Array<string | number>>
        }
        {...(!snapPoints && { handleHeight: animatedHandleHeight, contentHeight: animatedContentHeight })}
        containerStyle={[styles.containerStyle, { maxWidth: width }]}
        onChange={handleSheetPositionChange}
        handleIndicatorStyle={styles.handleIndicatorStyle}
        handleComponent={renderHeaderHandle}
        {...(renderFooter && { footerComponent: renderFooterComponent })}
        animateOnMount={!reducedMotion}
        enableHandlePanningGesture={true}
        backdropComponent={renderBackdrop}
        style={styles.modal}
        ref={bottomSheetRef}
        onDismiss={handleOnDismiss}
        {...rest}
      >
        {renderHeaderList?.()}
        {wrapperByScrollView ? (
          <BottomSheetScrollViewCustom
            keyboardShouldPersistTaps="always"
            keyboardDismissMode="on-drag"
            showsVerticalScrollIndicator={false}
            bounces={false}
            {...(!snapPoints && { style: { maxHeight: SCROLL_VIEW_MAX_HEIGHT } })}
          >
            <BottomSheetView
              onLayout={handleContentLayout}
              style={[styles.body, { paddingBottom: bottomInset }, minHeight && { minHeight }, containerStyle]}
            >
              {children}
            </BottomSheetView>
          </BottomSheetScrollViewCustom>
        ) : (
          <BottomSheetView
            onLayout={handleContentLayout}
            style={[styles.body, { paddingBottom: bottomInset }, minHeight && { minHeight }, containerStyle]}
          >
            {children}
          </BottomSheetView>
        )}
      </BottomSheetModal>
    );
  }
);
export default React.memo(BottomSheetScrollViewModalCustom);

const styles = StyleSheet.create({
  containerStyle: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  handleIndicatorStyle: {
    display: "none",
  },
  modal: {
    borderWidth: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  body: { minHeight: 180, paddingBottom: 40 },
  footer: { paddingHorizontal: 20, backgroundColor: Colors.white },
});
