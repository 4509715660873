import { ApolloLink, HttpLink } from "@apollo/client";
import config from "app/config";

export default function createHttpLink(): ApolloLink {
  const createLink = (uri: string) =>
    new HttpLink({
      uri,
    });
  const originalLink = createLink(config.HASURA_ENDPOINT) as ApolloLink;
  return originalLink;
}

/*-- this using for testing api local --*/
// import { ApolloLink, HttpLink, Operation, split } from "@apollo/client";
// import config from "app/config";
//
// const testOp = () => (op: Operation) => ["MobileExpenseRequestSearch"].includes(op.operationName);
//
// export default function createHttpLink(): ApolloLink {
//   const createLink = (uri: string) =>
//       new HttpLink({
//         uri,
//       });
//   const originalLink = createLink(config.HASURA_ENDPOINT) as ApolloLink;
//   const localLink = createLink("http://0.0.0.0:9998/dev/graphql") as ApolloLink;
//   const testLink = split(testOp(), localLink, originalLink);
//   return testLink;
// }
