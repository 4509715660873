import { StyleSheet, View } from "react-native";
import { countNumberOfDays, getTitleFromCategory } from "utils";
import { AppText, CurrencyText, DashedLine, Section } from "components";
import { Colors, Fonts } from "theme";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UseFormWatch } from "react-hook-form";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import { ALLOWANCE_TYPE } from "constants/constants";
import { useMasterData } from "contexts/MasterDataContext";

interface PerDiemInfoSectionProps {
  watch: UseFormWatch<ExpenseRequestFormValues>;
}
const PerDiemInfoSection: FC<PerDiemInfoSectionProps> = ({ watch }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const travelDate = watch("travelDate", null);
  const allowances = watch("allowances", []);
  const perDiemIfExist = watch("travel.perDiem");
  const numberOfDays =
    travelDate?.fromDate && travelDate?.toDate && countNumberOfDays(travelDate.fromDate, travelDate.toDate);
  const allowancesBudgetTravels = allowances?.filter((item) => item?.type === ALLOWANCE_TYPE.PERDIEM);
  const totalPerdiemForOneDay =
    allowancesBudgetTravels?.reduce((total, value) => total + value?.amount / numberOfDays, 0) || perDiemIfExist;
  const {
    setting: { request: requestConfig },
  } = useMasterData();

  return (
    <>
      <Section style={{ marginTop: 0 }} titleStyle={Fonts.SentenceSubTitleXLarge} title={t("per_diem_info_section")}>
        <View style={styles.rowItem}>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
            {t("travel_days")}
          </AppText>
          <AppText style={Fonts.NumericN200}>
            {numberOfDays ? t(numberOfDays > 1 ? "days" : "day", { value: numberOfDays }) : "-"}
          </AppText>
        </View>
        <DashedLine style={{ marginVertical: 2 }} />
        <View style={styles.rowItem}>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
            {requestConfig?.travel?.withPassengerInfo ? t("per_diem_person") : t("per_diem")}
          </AppText>
          <CurrencyText style={Fonts.NumericN200}>{totalPerdiemForOneDay}</CurrencyText>
        </View>
        <View style={{ marginLeft: 26 }}>
          {allowancesBudgetTravels?.map((item) => (
            <View
              key={item?.expenseCategoryId}
              style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 4, gap: 8 }}
            >
              <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale60} numberOfLines={2}>
                {getTitleFromCategory(item?.expenseCategory)}
              </AppText>
              <CurrencyText style={Fonts.NumericN200} color={Colors.grayscale60}>
                {item?.amount / numberOfDays}
              </CurrencyText>
            </View>
          ))}
        </View>
      </Section>
    </>
  );
};
export default PerDiemInfoSection;
const styles = StyleSheet.create({
  rowItem: { flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 20, paddingVertical: 8 },
  flex: { flex: 1 },
});
