import { FC } from "react";
import { View, StyleSheet } from "react-native";
import { ApprovalStage, IconCustom } from "components/index";
import { Colors } from "theme";
import { APPROVAL_EMPLOYEE_STATUS, APPROVAL_STATUS } from "constants/constants";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useScrollContextApis } from "./ScrollViewCustom/ScrollViewCustom";
import { ApprovalStageProps } from "./ApprovalStage";

const employeeToUser = (expenseApprovalRuleEmployee: ExpenseApprovalRuleEmployee): ApprovalStageProps["users"][0] => {
  return {
    userId: expenseApprovalRuleEmployee?.employee?.userId,
    avatarUrl: expenseApprovalRuleEmployee?.employee?.avatarUrl,
    defaultAvatarUrl: expenseApprovalRuleEmployee?.employee?.defaultAvatarUrl,
  };
};

/**
 * Retrieves a shortened timeline of approval stages based on the provided data.
 * @returns An array of approval stages with their corresponding stakeholders.
 */
const getShortenTimeline = (
  data: ExpenseApprovalRules[]
): Array<{ stage: Stage; stakeholder: ApprovalStageProps["users"] }> => {
  const stages: Record<Stage, ApprovalStageProps["users"]> = {
    rejected: [],
    approved: [],
    awaiting: [],
    pending: [],
  };
  data?.reduce((acc, step) => {
    switch (step.status) {
      case APPROVAL_STATUS.REJECTED:
        {
          const approver = step.expenseApprovalRuleEmployees.find(
            (e) => e.status === APPROVAL_EMPLOYEE_STATUS.REJECTED
          );
          acc.rejected.push(employeeToUser(approver));
        }
        break;
      case APPROVAL_STATUS.APPROVED:
        {
          const approver = step.expenseApprovalRuleEmployees.find(
            (e) => e.status === APPROVAL_EMPLOYEE_STATUS.APPROVED
          );
          acc.approved.push(employeeToUser(approver));
        }
        break;
      case APPROVAL_STATUS.AWAITING:
        acc.awaiting.push(...step.expenseApprovalRuleEmployees.map(employeeToUser));
        break;
      case APPROVAL_STATUS.PENDING:
        if (acc.rejected.length === 0) {
          acc.pending.unshift(...step.expenseApprovalRuleEmployees.map(employeeToUser));
        }
        break;
    }
    return acc;
  }, stages);

  if (stages.rejected.length > 0) {
    return [{ stage: Stage.rejected, stakeholder: stages.rejected }];
  }

  if (stages.pending.length === 0 && stages.awaiting.length === 0) {
    return [{ stage: Stage.approved, stakeholder: stages.approved }];
  }

  const timeline = [Stage.approved, Stage.awaiting, Stage.pending].reduce((acc, stage) => {
    if (stages[stage].length === 0) {
      return acc;
    }

    acc.push({ stage, stakeholder: stages[stage] });
    return acc;
  }, []);

  return timeline;
};

type ExpenseApprovalRuleEmployee = {
  status: number;
  employeeId: any;
  employee: {
    userId?: string;
    avatarUrl?: string | undefined;
    defaultAvatarUrl?: string | undefined;
  };
};

type ExpenseApprovalRules = {
  order?: number;
  status?: number | undefined;
  expenseApprovalRuleEmployees?: Array<ExpenseApprovalRuleEmployee> | undefined;
};

enum Stage {
  rejected = "rejected",
  approved = "approved",
  awaiting = "awaiting",
  pending = "pending",
}

type ApprovalFlowInlineProps = { data: ExpenseApprovalRules[] };
const ApprovalFlowInline: FC<ApprovalFlowInlineProps> = ({ data }) => {
  const { scrollForName } = useScrollContextApis();

  const handleStageClick = (name: Stage) => {
    const sectionName = {
      rejected: "stage-rejected",
      approved: "stage-approved",
      awaiting: "stage-awaiting",
      pending: "stage-pending",
    }[name];
    scrollForName(`approval-flow-section>approval-flow-section-content>${sectionName}`, 0);
  };

  const timelineEntries = getShortenTimeline(data);

  return (
    <View style={styles.flexRow}>
      {timelineEntries.map(({ stage, stakeholder }, index) => {
        const type = {
          [Stage.approved]: "success",
          [Stage.rejected]: "danger",
          [Stage.awaiting]: "primary",
          [Stage.pending]: "secondary",
        }[stage] as ApprovalStageProps["type"];

        const color = {
          [Stage.approved]: Colors.success50,
          [Stage.rejected]: Colors.alert50,
          [Stage.awaiting]: Colors.primary50,
          [Stage.pending]: Colors.grayscale40,
        }[stage];

        const limit = 2;
        if (stage === Stage.approved && stakeholder.length > limit) {
          stakeholder.reverse();
        }

        return (
          <TouchableOpacity style={styles.flexRow} key={stage} onPress={() => handleStageClick(stage)}>
            <ApprovalStage users={stakeholder} limit={limit} type={type} />
            {index < timelineEntries.length - 1 && (
              <IconCustom name="navigate-next" fill={color} width={16} height={16} />
            )}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};
export default ApprovalFlowInline;
const styles = StyleSheet.create({
  flexRow: { flexDirection: "row", alignItems: "center" },
});
