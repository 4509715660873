import * as FirebaseAnalytics from "expo-firebase-analytics";
import { Platform } from "react-native";
import Config from "app/config";
import { analyticService } from "services/AnalyticsService";

const setDebugModeEnabled = (isEnabled) => {
  if (Platform.OS !== "web") {
    FirebaseAnalytics.setDebugModeEnabled(isEnabled);
  }
};

const setUnavailabilityLogging = (isEnabled) => {
  if (Platform.OS !== "web") {
    FirebaseAnalytics.setUnavailabilityLogging(isEnabled);
  }
};

const logEvent = (name: string, properties?: Record<string, any>) => {
  if (Platform.OS === "web") {
    return;
  }
};

export { setDebugModeEnabled, setUnavailabilityLogging, logEvent };
