import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileQueryCrdEmployeeCardsQuery, MobileQueryCrdEmployeeCardsQueryVariables } from "types";

export const QUERY_EMPLOYEE_CARDS = gql`
  query MobileQueryCrdEmployeeCards($companyId: UUID!) {
    crdEmployeeCards(input: { companyId: $companyId }) {
      data {
        cardId
        activatedAt
        billingDay
        cardHolderEmployeeId
        cardNumber
        cardholderName
        cardholderPhoneNumber
        companyId
        createdAt
        expiredAt
        groupId
        maskedCardNumber
        prefixCardNumber
        status
        suffixCardNumber
        updatedAt
        cardholderEmployee {
          fullname
          title
        }
      }
      totalEntries
    }
  }
`;

const useEmployeeCardsQuery = (variables: MobileQueryCrdEmployeeCardsQueryVariables, onCompleted) => {
  return useQuery<MobileQueryCrdEmployeeCardsQuery, MobileQueryCrdEmployeeCardsQueryVariables>(QUERY_EMPLOYEE_CARDS, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted,
  });
};

export default useEmployeeCardsQuery;
