import React, { FC, memo, useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import Slider from "rn-range-slider";
import { AppText } from "components";
import { formatMoney } from "utils";

type RangeSliderProps = {
  label: string;
  min: number;
  max: number;
  step: number;
  defaultLow: number;
  defaultHigh: number;
  onValueChange?: (args: { min: number; max: number }) => void;
};
const RangeSlider: FC<RangeSliderProps> = ({ label, min, max, step, defaultLow, defaultHigh, onValueChange }) => {
  const [currentLow, setCurrentLow] = useState(defaultLow);
  const [currentHigh, setCurrentHigh] = useState(defaultHigh);
  const renderThumb = useCallback(() => <View style={styles.thumb} />, []);
  const renderRailSelected = useCallback(() => <View style={styles.railSelected} />, []);
  const renderRail = useCallback(() => <View style={styles.rail} />, []);

  const handleValueChange = useCallback((low, high) => {
    setCurrentLow(low);
    setCurrentHigh(high);
    onValueChange({ min: low, max: high });
  }, []);
  return (
    <View>
      <View style={styles.priceLabelRow}>
        <AppText style={[Fonts.H200, styles.flex]}>{label}</AppText>
        <AppText style={Fonts.BodySmall}>{`${formatMoney(currentLow)} ₫ - ${formatMoney(currentHigh)} ₫`}</AppText>
      </View>
      <Slider
        min={min}
        max={max}
        low={currentLow}
        high={currentHigh}
        step={step}
        floatingLabel
        renderThumb={renderThumb}
        renderRail={renderRail}
        renderRailSelected={renderRailSelected}
        onValueChanged={handleValueChange}
      />
    </View>
  );
};

export default memo(RangeSlider);

const styles = StyleSheet.create({
  thumb: {
    width: 16,
    height: 16,
    backgroundColor: Colors.white,
    borderColor: Colors.primary50,
    borderWidth: 1,
    borderRadius: 8,
  },
  railSelected: { height: 2, backgroundColor: Colors.primary50, borderRadius: 2 },
  rail: { flex: 1, height: 2, borderRadius: 2, backgroundColor: Colors.grayscale10 },
  priceLabelRow: { flexDirection: "row", marginBottom: 6, alignItems: "center" },
  flex: { flex: 1 },
});
