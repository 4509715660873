import { ScrollView, StyleSheet, View } from "react-native";

import { ProgressiveImage } from "app/components";
import { useRef, useState } from "react";
import { WIDTH_WINDOW } from "constants/Layout";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Colors from "constants/Colors";

const ImageGalleryScreen = ({ route }) => {
  const images = route?.params?.images;
  const selected = route?.params?.selected ?? 0;
  const [indexScroll, setIndexScroll] = useState(selected);
  const refScrollView = useRef(null);

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.wrapperSwiped}>
        <ScrollView
          ref={refScrollView}
          showsHorizontalScrollIndicator={false}
          horizontal
          pagingEnabled
          scrollEventThrottle={16}
          onScroll={({
            nativeEvent: {
              contentOffset: { x },
            },
          }) => {
            setIndexScroll((currenIndex) => {
              const newIndex = Math.abs(Math.round(x / WIDTH_WINDOW));
              if (newIndex !== currenIndex) {
                return newIndex;
              }

              return newIndex;
            });
          }}
        >
          {images.map((uri, index) => {
            return (
              <View style={styles.wrapperItemStyle} key={index}>
                <ProgressiveImage source={{ uri }} containerStyle={styles.progressiveImageContainer} />
              </View>
            );
          })}
        </ScrollView>
        <View style={styles.wrapperDotStyle}>
          {images.map((_, index) => {
            return (
              <TouchableWithoutFeedback
                key={index}
                onPress={() => refScrollView.current?.scrollTo({ x: index * WIDTH_WINDOW, y: 0 })}
              >
                <View
                  style={[
                    styles.dotStyle,
                    {
                      backgroundColor: indexScroll === index ? Colors.grey5 : Colors.grey3,
                    },
                  ]}
                />
              </TouchableWithoutFeedback>
            );
          })}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  progressiveImageContainer: { height: "100%", width: "100%", borderRadius: 6 },
  wrapperDotStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 10,
    bottom: 10,
    left: "48%",
  },
  dotStyle: {
    width: 8,
    height: 8,
    marginLeft: 10,
    borderRadius: 8,
  },
  wrapperItemStyle: { flex: 1, width: WIDTH_WINDOW },
  wrapperSwiped: { flex: 1 },
});

export default ImageGalleryScreen;
