import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={180} height={180} fill="none" {...props}>
    <G clipPath="url(#a)">
      <G clipPath="url(#b)">
        <Path
          fill="#C0CAD8"
          d="M84.223 173.959c4.323 1.377 9.247 2.153 14.488 2.153 5.242 0 10.165-.776 14.489-2.153-4.183-3.864-9.177-6.335-14.489-6.335-5.311 0-10.27 2.435-14.488 6.335Z"
        />
        <Path
          fill="#E4EAF1"
          d="M174.188 117.241c-2.241 35.154-36.212 51.001-76.73 48.424-40.518-2.594-72.177-22.659-69.936-57.777 2.241-35.118 37.342-68.56 77.86-65.965 40.518 2.594 71.047 40.183 68.806 75.318Z"
        />
        <Path
          fill="#1F71F4"
          d="M74.234 47.058 69.1 127.459c9.141 3.582 19.412 5.859 30.388 6.564 10.959.706 21.459-.247 30.989-2.647l5.135-80.4c-9.053-5.083-19.271-8.347-30.23-9.053-10.959-.689-21.511 1.235-31.147 5.135Z"
        />
        <Path
          fill="#fff"
          d="m98.817 76.6-1.536-.106 1.342-4.623 2.823-9.848.141-.53-7.8-.493 3.67-12.512-4.605-.3 11.224-6.336 6.935 7.5-3.565-.229-1.447 4.994 10.253.653L98.817 76.6Z"
        />
        <Path
          fill="#1B1B1D"
          d="M46.245 129.4c-8.735-12.335 4.006-50.718 17.295-57.883 17.859-9.617 68.4-5.047 83.701 8.224 11.082 9.635 16.853 48.636 7.182 58.165-19.288 19.024-92.577 13.5-108.178-8.506Z"
        />
        <Path
          fill="#C0CAD8"
          d="m32.251 119.412-8.294-2.683c-1.518-.476-2.541-2.241-2.418-4.165l.9-14.117c.124-1.924 1.36-3.547 2.965-3.83l8.56-1.606c2.135-.388 3.987 1.66 3.81 4.27l-1.164 18.407c-.194 2.541-2.276 4.376-4.359 3.724Z"
        />
        <Path
          fill="#E4EAF1"
          d="m27.557 117.983-7.606-2.418c-1.412-.441-2.33-2.047-2.224-3.847l.83-12.918c.106-1.782 1.235-3.23 2.682-3.494l7.853-1.447c1.96-.37 3.636 1.535 3.494 3.882L31.51 114.56c-.159 2.382-2.1 4.024-3.953 3.424Z"
        />
        <Path
          fill="#C0CAD8"
          d="m167.959 129.189 8.559-1.606c1.606-.282 2.841-1.906 2.964-3.829l.9-14.118c.124-1.924-.9-3.688-2.452-4.182l-8.295-2.683c-2.064-.653-4.164 1.147-4.323 3.741l-1.165 18.407c-.176 2.611 1.677 4.694 3.812 4.27Z"
        />
        <Path
          fill="#E4EAF1"
          d="m172.847 128.412 7.853-1.447c1.464-.265 2.576-1.747 2.682-3.494l.83-12.918c.105-1.783-.812-3.371-2.224-3.812l-7.606-2.418c-1.906-.617-3.812 1.059-3.953 3.406l-1.076 16.818c-.159 2.329 1.517 4.235 3.494 3.865Z"
        />
        <Path
          fill="#fff"
          stroke="#1E1E1E"
          strokeMiterlimit={10}
          strokeWidth={0.882}
          d="M71.719 40.29c0-13.323-13.935-24.118-31.113-24.118-17.179 0-31.114 10.795-31.114 24.118 0 12.596 12.48 22.926 28.379 24.001l7.593 6.829c1.338 1.206 3.476.494 3.825-1.264l1.368-6.77c12.247-3.255 21.062-12.22 21.062-22.796Z"
        />
        <Path
          fill="#1B1B1D"
          d="M27.85 37.484a3.3 3.3 0 0 0-3.476 3.124 3.3 3.3 0 0 0 3.124 3.476 3.3 3.3 0 0 0 3.476-3.123c.106-1.818-1.288-3.371-3.123-3.477ZM40.24 38.155a3.3 3.3 0 0 0-3.477 3.124 3.3 3.3 0 0 0 3.123 3.476 3.3 3.3 0 0 0 3.477-3.123 3.322 3.322 0 0 0-3.124-3.477ZM52.61 38.843a3.3 3.3 0 0 0-3.477 3.123 3.3 3.3 0 0 0 3.123 3.477 3.3 3.3 0 0 0 3.477-3.124 3.288 3.288 0 0 0-3.124-3.477Z"
        />
        <Path fill="#FE4D65" d="M100.461 116.486a2.524 2.524 0 1 0 .475-5.025 2.524 2.524 0 0 0-.475 5.025Z" />
        <Path
          fill="#FE4D65"
          d="m111.28 105.354.183-1.926c.394-4.16-.084-9.771-8.759-10.594-8.675-.822-10.2 4.599-10.594 8.759l-.182 1.926c-4.366.13-5.99 2.183-6.5 7.561l-.272 2.866c-.6 6.317 1.144 8.426 7.461 9.025l14.329 1.359c6.318.599 8.426-1.145 9.025-7.462l.272-2.866c.51-5.377-.7-7.7-4.963-8.648Zm-11.022 13.273a4.68 4.68 0 0 1-4.212-5.094 4.68 4.68 0 0 1 5.095-4.212c2.557.242 4.454 2.536 4.212 5.094a4.67 4.67 0 0 1-5.095 4.212Zm-5.806-15.009-.354-.033.17-1.788c.427-4.514 1.991-7.397 8.232-6.806 6.24.592 7.234 3.718 6.806 8.232l-.171 1.803-.355-.033-14.33-1.359.002-.016Z"
        />
      </G>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h180v180H0z" />
      </ClipPath>
      <ClipPath id="b">
        <Path fill="#fff" d="M-4.366 3.805h188.578v172.307H-4.366z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
