import { AppText } from "components";
import { FC, memo } from "react";
import { StyleSheet, View } from "react-native";

import { CheckedRadioIcon, RadioIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Colors, Fonts } from "theme";

interface CompanyListItemProps {
  isActive?: boolean;
  item: Partial<Companies>;
  onSelect: (companyId: string) => void;
}

const CompanyListItem: FC<CompanyListItemProps> = ({ item, onSelect, isActive = false }) => {
  const { t } = useTranslation("app/screens/Company/components/CompanyListItem");
  return (
    <>
      <TouchableOpacity
        disabled={isActive}
        onPress={() => onSelect(item.company_id)}
        style={[styles.container, isActive && styles.activeContainer]}
      >
        <View style={styles.contentContainer}>
          <View style={styles.titleContainer}>
            <AppText style={[Fonts.H300, styles.title]} color={Colors.grayscale100} numberOfLines={2}>
              {item.name}
            </AppText>
          </View>
          <View style={styles.taxCodeContainer}>
            <AppText color={Colors.grayscale100}>{t("tax_code")}: </AppText>
            <AppText color={Colors.grayscale100}>{item.tax_code}</AppText>
          </View>
          <AppText style={[Fonts.BodySmall, styles.address]} color={Colors.grayscale60} numberOfLines={2}>
            {item.address}
          </AppText>
        </View>
        <View style={styles.button}>{isActive ? <CheckedRadioIcon /> : <RadioIcon />}</View>
      </TouchableOpacity>
      <View style={styles.divider} />
    </>
  );
};

export default memo(CompanyListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  activeContainer: {
    backgroundColor: Colors.primary0,
  },
  contentContainer: {
    flex: 1,
  },
  button: {
    paddingLeft: 20,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  taxCodeContainer: { flexDirection: "row", marginTop: 2 },
  title: { flex: 1, paddingRight: 5 },
  address: {
    marginTop: 2,
  },
});
