import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M18.001 21.75a2.654 2.654 0 01-1.948-.802A2.65 2.65 0 0115.25 19c0-.125.01-.254.03-.388a2 2 0 01.09-.37l-7.397-4.33a2.952 2.952 0 01-.902.616A2.636 2.636 0 016 14.75a2.653 2.653 0 01-1.948-.802 2.65 2.65 0 01-.802-1.947c0-.763.267-1.413.802-1.948A2.65 2.65 0 016 9.25c.377 0 .734.074 1.071.222.337.148.638.354.902.617l7.396-4.331a2 2 0 01-.09-.37A2.635 2.635 0 0115.25 5c0-.764.267-1.413.802-1.948a2.65 2.65 0 011.947-.802c.763 0 1.413.267 1.948.802a2.65 2.65 0 01.803 1.947c0 .763-.267 1.413-.802 1.948A2.65 2.65 0 0118 7.75c-.377 0-.734-.074-1.071-.222a2.95 2.95 0 01-.902-.616l-7.396 4.33c.04.113.07.236.09.37a2.62 2.62 0 010 .774c-.02.135-.05.259-.09.372l7.396 4.33a2.95 2.95 0 01.902-.616A2.635 2.635 0 0118 16.25c.764 0 1.413.267 1.948.802a2.65 2.65 0 01.802 1.947c0 .763-.267 1.413-.802 1.948a2.65 2.65 0 01-1.947.803zM18 6.25c.347 0 .643-.122.886-.364.242-.243.364-.539.364-.886s-.122-.643-.364-.886A1.206 1.206 0 0018 3.75c-.348 0-.643.121-.886.364A1.205 1.205 0 0016.75 5c0 .347.121.643.364.886s.538.364.886.364zm-12 7c.347 0 .643-.121.886-.364s.364-.539.364-.886c0-.348-.121-.643-.364-.886A1.205 1.205 0 006 10.75c-.347 0-.643.121-.886.364A1.205 1.205 0 004.75 12c0 .347.121.643.364.886s.539.364.886.364zm12 7c.347 0 .643-.122.886-.364.242-.243.364-.539.364-.886 0-.348-.122-.643-.364-.886A1.206 1.206 0 0018 17.75c-.348 0-.643.121-.886.364a1.205 1.205 0 00-.364.886c0 .347.121.643.364.886.243.242.538.364.886.364z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
