import { AppText, Button, IconCustom, ImageViewCustom } from "components";
import { Colors, Fonts } from "theme";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { RetryIcon, SpinnerSmallIcon } from "assets/images/svg/icons";
import * as Notifications from "expo-notifications";

import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useTranslation } from "react-i18next";
import { INVOICE } from "constants/constants";
import dayjs from "dayjs";
import useRevalidateInvoiceMutation from "../hooks/useRevalidateInvoiceMutation";
import { useAuth } from "contexts/AuthContext";
import { MobileExpInvoiceDetailQuery } from "types";
import { PayloadType } from "navigation/RootNavigation";
import useInterval from "components/hooks/useInterval";

const VALIDATION_COLORS = {
  success: Colors.success50,
  warning: Colors.warning50,
  error: Colors.alert50,
};
const VALIDATION_ICONS = {
  error: <IconCustom name="cancel" width={16} height={16} fill={Colors.alert50} />,
  success: <IconCustom name="check-circle" />,
  warning: <IconCustom name="warning" />,
};

const ImagePreviewFooter = ({ imageIndex, imagesCount }) => (
  <View style={styles.imagePreviewFooter}>
    <View style={styles.imagePreviewCount}>
      <AppText style={Fonts.BodyMedium} color={Colors.white}>{`${imageIndex + 1} / ${imagesCount}`}</AppText>
    </View>
  </View>
);

interface InvoiceValidationTTCProps {
  invoice?: MobileExpInvoiceDetailQuery["expInvoiceDetail"];
  onRefreshDetail?: () => void;
}
const InvoiceValidationTTC: FC<InvoiceValidationTTCProps> = ({ invoice, onRefreshDetail }) => {
  const { t } = useTranslation("app/screens/Inbox/InvoiceDetailScreen");
  const [FEATURE_VALIDATE_TAXPAYER_NAME_AND_ADDRESS, FEATURE_EXP_MOBILE_VALIDATE_INVOICE] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_VALIDATE_TAXPAYER_NAME_AND_ADDRESS,
    FEATURE_FLAGS.FEATURE_EXP_MOBILE_VALIDATE_INVOICE,
  ]);

  const [visibleImagePreview, setVisibleImagePreview] = useState({ index: 0, visible: false });
  const notificationListener = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [isValidateTTC, setIsValidateTTC] = useState(false);
  const { user } = useAuth();

  const validations = (invoice?.invoiceValidations?.[0] as any) || {};
  const taxVerificationData = {
    gdtResults: validations.gdtResults,
    isLegalSeller: validations.isLegalSeller,
    isIssuedInvoice: validations.isIssued,
    screenshotAt: validations.tchdScreenshotAt,
    tchdScreenshot: validations.tchdScreenshot,
  };
  const { invoiceType } = invoice;
  const verifying = validations.isVerifying;

  useEffect(() => {
    /*--- refetch api detail when received notification validate TTC success --*/
    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      const data = notification?.request?.content?.data;
      if (data?.type === PayloadType.INVOICE_VALIDATED && data?.invoiceId === invoice?.invoiceId) {
        onRefreshDetail?.();
      }
    });
    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
    };
  }, []);

  /*-- handle re-fetch detail when invoice waiting response validate from GDT --*/
  const timerRef = useRef(50);
  const intervalId = useInterval(() => {
    timerRef.current -= 1;
    if (
      !invoice?.validationTask ||
      timerRef.current <= 0 ||
      taxVerificationData?.gdtResults ||
      (invoice?.validationTask?.canTriggerAfter && dayjs().isAfter(invoice.validationTask.canTriggerAfter))
    ) {
      clearInterval(intervalId);
      return;
    }
    onRefreshDetail?.();
  }, 3000);

  useEffect(() => {
    if (invoice?.validationTask?.canTriggerAfter && dayjs().isAfter(invoice.validationTask.canTriggerAfter)) {
      setLoading(false);
    } else {
      setLoading(invoice?.invoiceValidations?.[0]?.isVerifying);
    }
    /*-- handle re-fetch detail when invoice waiting response validate from GDT --*/
    if (
      invoice?.invoiceValidations?.[0] &&
      !taxVerificationData?.gdtResults &&
      dayjs().isBefore(invoice?.validationTask?.canTriggerAfter) &&
      !isLoading
    ) {
      setIsValidateTTC(true);
    } else {
      setIsValidateTTC(false);
      clearInterval(intervalId);
    }
  }, [invoice]);

  const evidenceScreenshots = useMemo(() => {
    return invoice?.invoiceValidations?.[0]?.evidences?.screenshots ?? [];
  }, [invoice]);
  const tchdScreenshotData = useMemo(() => {
    return (
      evidenceScreenshots.find((screenshot) => screenshot.type === "tchd") || {
        url: taxVerificationData?.tchdScreenshot,
        created_at: taxVerificationData?.screenshotAt,
      }
    );
  }, [evidenceScreenshots, taxVerificationData]);
  const tcnntScreenshotData = useMemo(() => {
    return evidenceScreenshots.find((screenshot) => screenshot.type === "tcnnt");
  }, [evidenceScreenshots]);
  const screenShotEvidence = invoiceType === INVOICE.INVOICE_32 ? tchdScreenshotData : tcnntScreenshotData;
  const sellerTaxValidations = useMemo(() => {
    if (!taxVerificationData?.gdtResults) {
      return [{ status: "error", message: t("validate_gdt_error") }];
    }
    return FEATURE_VALIDATE_TAXPAYER_NAME_AND_ADDRESS
      ? [
          {
            key: "legal_seller",
            status: taxVerificationData?.isLegalSeller ? "success" : "error",
            message: taxVerificationData?.gdtResults?.seller?.status || t("legal_seller_check_error"),
          },
          {
            key: "seller_name",
            status: taxVerificationData?.gdtResults?.seller?.isValidName ? "success" : "warning",
            message: taxVerificationData?.gdtResults?.seller?.isValidName
              ? t("seller_name_success")
              : t("seller_name_warning"),
          },
          {
            key: "seller_address",
            status: taxVerificationData?.gdtResults?.seller?.isValidAddress ? "success" : "warning",
            message: taxVerificationData?.gdtResults?.seller?.isValidAddress
              ? t("seller_address_success")
              : t("seller_address_warning"),
          },
        ]
      : [
          {
            key: "legal_seller",
            status: taxVerificationData?.isLegalSeller ? "success" : "error",
            message: taxVerificationData?.gdtResults?.seller?.status || t("legal_seller_check_error"),
          },
        ];
  }, [taxVerificationData]);

  let invoiceStatus = taxVerificationData?.gdtResults?.invoice?.status ?? [];
  if (!Array.isArray(invoiceStatus)) {
    invoiceStatus = [invoiceStatus];
  }

  const invoiceTaxValidations = invoiceStatus.map((status) => {
    const message = status.trim() || t("invoice_not_register_validation");
    const isError =
      message.toLowerCase().includes("không tồn tại hóa đơn") ||
      message.toLowerCase().includes("xoá bỏ") ||
      message.toLowerCase().includes("thông báo hủy") ||
      message.toLowerCase().includes("hết giá trị sử dụng") ||
      message.toLowerCase().includes("đăng ký phát hành") ||
      message.toLowerCase().includes("hoá đơn đã bị thay thế") ||
      message.toLowerCase().includes("tính chất huỷ") ||
      message.toLowerCase().includes("hoá đơn đã bị xoá") ||
      message.toLowerCase().includes("hóa đơn không đủ điều kiện cấp mã");
    return {
      key: message,
      status: isError ? "error" : "success",
      message,
    };
  });

  const imageFiles = useMemo(() => {
    const files = [];
    if (screenShotEvidence?.url) {
      files.push({ uri: screenShotEvidence?.url });
    }
    if (tchdScreenshotData?.url) {
      files.push({ uri: tchdScreenshotData?.url });
    }
    return files;
  }, [screenShotEvidence, tchdScreenshotData]);
  const handlePreviewImage = (url) => () => {
    if (Platform.OS === "web") {
      window.open(url, "_blank");
      return;
    }
    const foundIndex = imageFiles.findIndex((i) => i.uri === url);
    setVisibleImagePreview({ index: foundIndex, visible: true });
  };
  const [revalidateInvoice] = useRevalidateInvoiceMutation();
  const handleReValidateTTC = async () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    revalidateInvoice({
      variables: { invoiceId: invoice.invoiceId, companyId: user.company.company_id },
    });
  };
  const disabledValidate = dayjs().isBefore(invoice?.validationTask?.canTriggerAfter);
  return (
    <View style={styles.errorWrapper}>
      <AppText style={[Fonts.BodySmall, styles.errorInvoiceTitle]} color={Colors.grayscale60}>
        {t("invoice_checking_with_ttc")}
      </AppText>

      {isValidateTTC ? (
        <View style={styles.loadingSection}>
          <SpinnerSmallIcon />
          <AppText style={[Fonts.BodySmall, styles.loadingTitle]} color={Colors.grayscale80}>
            {t("wait_response_validate")}
          </AppText>
        </View>
      ) : (
        <View>
          {screenShotEvidence?.url && !verifying && (
            <TouchableOpacity style={styles.rowItem} onPress={handlePreviewImage(screenShotEvidence?.url)}>
              <AppText style={styles.imageLink} color={Colors.primary50}>
                {t("see_result", {
                  date: dayjs(screenShotEvidence?.created_at).format("DD/MM/YYYY"),
                  object: tcnntScreenshotData ? " NNT" : "",
                })}
              </AppText>
            </TouchableOpacity>
          )}
          {!verifying &&
            sellerTaxValidations.map((item, index) => {
              const { status, message } = item;
              const color = VALIDATION_COLORS[status];
              return (
                <View style={[styles.rowItem, !taxVerificationData.gdtResults && { marginTop: 5 }]} key={index}>
                  <View style={styles.statusIcon}>{VALIDATION_ICONS[status] ?? null}</View>
                  <AppText style={[Fonts.BodyMedium, styles.flex]} color={color}>
                    {message}
                  </AppText>
                </View>
              );
            })}
          {tcnntScreenshotData && !verifying && (
            <>
              {tchdScreenshotData ? (
                <TouchableOpacity style={styles.rowItem} onPress={handlePreviewImage(tchdScreenshotData?.url)}>
                  <AppText style={styles.imageLink} color={Colors.primary50}>
                    {t("see_result_invoice", {
                      date: dayjs(tchdScreenshotData?.created_at).format("DD/MM/YYYY"),
                    })}
                  </AppText>
                </TouchableOpacity>
              ) : (
                <AppText style={Fonts.BodyMedium} color={Colors.alert50}>
                  {t("no_image_invoice_check_tct")}
                </AppText>
              )}
            </>
          )}
          {invoiceTaxValidations?.map((item, index) => {
            const { status, message } = item || {};
            const color = VALIDATION_COLORS[status];
            return (
              <View style={styles.rowItem} key={index}>
                <View style={styles.statusIcon}>{VALIDATION_ICONS[status] ?? null}</View>
                <AppText style={[Fonts.BodyMedium, styles.flex]} color={color}>
                  {message}
                </AppText>
              </View>
            );
          })}
          {FEATURE_EXP_MOBILE_VALIDATE_INVOICE && (
            <>
              <Button
                style={[styles.reValidateButton, isLoading && styles.disableButton]}
                onPress={handleReValidateTTC}
                disabled={disabledValidate && !isLoading}
                type="secondary"
                labelStyle={{ color: isLoading ? Colors.grayscale80 : Colors.primary50 }}
                icon={
                  isLoading ? (
                    <SpinnerSmallIcon />
                  ) : (
                    <IconCustom name="refresh" fill={disabledValidate ? Colors.grayscale40 : Colors.primary50} />
                  )
                }
              >
                {isLoading ? t("is_verifying") : t("verify")}
              </Button>
              {isLoading ? (
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("is_verifying_description")}
                </AppText>
              ) : disabledValidate ? (
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("verify_finish")}
                  <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>{` ${dayjs(
                    invoice?.validationTask?.canTriggerAfter
                  ).format("HH:mm DD/MM/YYYY")}.`}</AppText>
                </AppText>
              ) : null}
            </>
          )}
        </View>
      )}
      <ImageViewCustom
        images={imageFiles ?? []}
        imageIndex={visibleImagePreview.index}
        visible={visibleImagePreview.visible}
        FooterComponent={({ imageIndex }) => (
          <ImagePreviewFooter imageIndex={imageIndex} imagesCount={imageFiles?.length} />
        )}
        onRequestClose={() => setVisibleImagePreview({ index: 0, visible: false })}
      />
    </View>
  );
};
export default InvoiceValidationTTC;
const styles = StyleSheet.create({
  errorWrapper: {
    flexDirection: "column",
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: Colors.white,
    marginBottom: 8,
  },
  errorInvoiceTitle: { marginBottom: 4 },
  rowItem: { flexDirection: "row", gap: 6, marginVertical: 2 },
  statusIcon: { marginTop: 2 },
  imageLink: { ...Fonts.BodyMedium, textDecorationLine: "underline" },
  imagePreviewFooter: {
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 30,
  },
  imagePreviewCount: {
    backgroundColor: "#00000077",
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 20,
  },
  flex: { flex: 1 },
  reValidateButton: { marginTop: 17, marginBottom: 4 },
  disableButton: {
    borderColor: Colors.grayscale05,
    backgroundColor: Colors.grayscale05,
  },
  loadingSection: { paddingBottom: 20, paddingTop: 30, alignItems: "center" },
  loadingTitle: { marginTop: 8 },
});
