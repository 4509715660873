import { FC } from "react";
import { View, StyleSheet } from "react-native";
import { AppText, AvatarUser, IconCustom } from "components/index";
import { Colors, Fonts } from "theme";

export type User = {
  userId: string;
  fullname?: string;
  avatarUrl?: string;
  defaultAvatarUrl?: string;
};

export type ApprovalStageProps = {
  type?: "success" | "primary" | "danger" | "secondary";
  limit?: number;
  users?: User[];
};

const ApprovalStage: FC<ApprovalStageProps> = ({ users, limit = 2, type = "secondary" }) => {
  const hasMore = users?.length > limit;
  const userToDisplay = users?.slice(0, hasMore ? limit - 1 : limit);

  const textColor = {
    success: Colors.white,
    primary: Colors.white,
    danger: Colors.white,
    secondary: Colors.grayscale100,
  }[type];

  const borderColor = {
    success: Colors.success50,
    primary: Colors.primary50,
    danger: Colors.alert50,
    secondary: Colors.grayscale40,
  }[type];

  const icon = {
    success: <IconCustom name="check-circle" fill={Colors.success50} width={12} height={12} />,
    primary: <IconCustom name="schedule-filled" />,
    danger: <IconCustom name="cancel" fill={Colors.alert50} width={12} height={12} />,
    secondary: null,
  }[type];

  return (
    <View style={[styles.container, { marginRight: icon ? 0 : -5 }]}>
      {userToDisplay?.map((user, index) => {
        const isLastItem = index === userToDisplay.length - 1;

        return (
          <View key={index} style={styles.approvalFlowItem}>
            <AvatarUser
              containerStyle={[styles.avatarContainer, index > 0 && { marginLeft: -5 }]}
              url={user.avatarUrl || user.defaultAvatarUrl}
              fullname={user.fullname}
              size={24}
              borderWidth={1.5}
              borderColor={borderColor}
              textStyle={Fonts.BodySmall}
            />
            {isLastItem && !hasMore && Boolean(icon) && <View style={styles.scheduleIcon}>{icon}</View>}
          </View>
        );
      })}

      {hasMore && (
        <View style={styles.approvalFlowItem}>
          <View style={[styles.avatarMoreContainer, { backgroundColor: borderColor }]}>
            <AppText style={Fonts.BodyXSmall} color={textColor}>{`+${users.length - limit + 1}`}</AppText>
          </View>
          {Boolean(icon) && <View style={styles.scheduleIcon}>{icon}</View>}
        </View>
      )}
    </View>
  );
};

export default ApprovalStage;
const styles = StyleSheet.create({
  container: { flexDirection: "row", paddingRight: 6 },
  approvalFlowItem: { flexDirection: "row" },
  avatarContainer: {
    width: 24,
    height: 24,
    borderRadius: 24,
    overflow: "hidden",
  },
  avatarMoreContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    borderRadius: 24,
    marginLeft: -5,
  },
  scheduleIcon: { position: "absolute", bottom: 0, right: -6 },
});
