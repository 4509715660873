import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Path
        d="M14.93 6.817H5.063c-.8 0-1.2.967-.633 1.533l4.316 4.317a1.775 1.775 0 002.509 0l1.641-1.642 2.675-2.675c.559-.566.159-1.533-.641-1.533z"
        fill="#0F172A"
      />
    </Svg>
  );
}

export default SvgComponent;
