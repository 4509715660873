import { WhiteSpace } from "@ant-design/react-native";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import filter from "lodash/filter";
import React, { ForwardedRef, forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, TextInput as RNTextInput, TouchableOpacity, View, ViewStyle } from "react-native";

import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetModalCustomMethods,
  IconCustom,
  Line,
  SearchInput,
  useSetRelativePosition,
} from "components";
import useExpEmployeeExpenseCategories from "hooks/useExpEmployeeExpenseCategories";
import { Colors, Fonts, FontTypes } from "theme";
import { removeAccents } from "utils/searchObject";
import { SearchInputProps } from "../SearchInput";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { CONSTANTS } from "constants/constants";
import { convertViToEn, getTitleFromCategory } from "utils";
import useVendorExpenseCategory from "hooks/useVendorExpenseCategory";
import { useAuth } from "contexts/AuthContext";
import { useMasterData } from "contexts/MasterDataContext";

export type CategoryOption = {
  expenseCategoryId: string;
  title: string;
  code: string;
  searchText: string;
  subAccounts?: { accountCode: string; title: string }[];
};

type CategorySection = {
  title: string;
  data: CategoryOption[];
};

interface ExpenseCategoryInfo {
  title: string;
  order: number;
  titleEn?: string | null;
  expenseCategoryId: string;
}

const ExpenseCategorySearchInput = forwardRef(
  ({ onFocus, onBlur, ...props }: SearchInputProps, ref: ForwardedRef<RNTextInput>) => {
    const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

    const handleOnFocus = useCallback(
      (args) => {
        shouldHandleKeyboardEvents.value = true;
        onFocus?.(args);
      },
      [onFocus, shouldHandleKeyboardEvents]
    );
    const handleOnBlur = useCallback(
      (args) => {
        shouldHandleKeyboardEvents.value = false;
        onBlur?.(args);
      },
      [onBlur, shouldHandleKeyboardEvents]
    );
    return <SearchInput ref={ref} {...props} onFocus={handleOnFocus} onBlur={handleOnBlur} />;
  }
);

export interface CategorySelectProps {
  style?: ViewStyle;
  selectedCategoryId?: string;
  touched?: boolean;
  onDismiss?: () => void;
  onSelect?: (item: ExpenseCategoryInfo) => void;
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  value?: string;
  setPosition?: (y: number) => void;
  partnerTaxCode?: string;
  containerRef?: any;
  onChange?: (category: CategoryOption) => void;
  defaultCategoryTitle?: string;
}

const CategorySelect: React.FC<CategorySelectProps> = ({
  selectedCategoryId,
  onDismiss,
  style,
  name,
  rules,
  control,
  error,
  setPosition,
  partnerTaxCode,
  containerRef,
  onChange,
  defaultCategoryTitle,
}) => {
  const textRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef: textRef, setPosition });

  const {
    company: { company_id: companyId },
  } = useAuth();
  const flatListRef = useRef<FlatList>(null);

  const categoryBottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

  const [keyword, setKeyword] = useState("");

  const setCategoryValue = useRef(null);

  const { categories: dataCategories } = useMasterData();
  const { data: dataVendorCategories, loading: loadingVendorCategories } = useVendorExpenseCategory({
    input: { companyId, partnerTaxCode },
  });

  const { t, i18n } = useTranslation("app/components/ExpenseCategoryChooser");

  const groupedOptions: CategorySection[] = useMemo(() => {
    const toCategoryOption = (category: any): CategoryOption => {
      return {
        ...category,
        searchText: `${category?.title} ${category?.titleEn} ${category.code}`,
      };
    };
    const sortCategoryOptions = (options: CategoryOption[]) => {
      return [...options].sort((a, b) => {
        if (a.title === "Other") {
          return 1;
        }

        const aTitle = convertViToEn(a.title);
        const bTitle = convertViToEn(b.title);
        return aTitle === bTitle ? 0 : aTitle < bTitle ? -1 : 1;
      });
    };

    const sections = [];
    const categoryLookup = new Set(dataVendorCategories.map((category) => category.expenseCategoryId));
    const { vendorSection, policySection } = dataCategories.reduce(
      (acc, category) => {
        if (categoryLookup.has(category.expenseCategoryId)) {
          acc.vendorSection.push(category);
          return acc;
        }

        acc.policySection.push(category);
        return acc;
      },
      { vendorSection: [], policySection: [] }
    );

    if (vendorSection.length > 0) {
      sections.push({
        title: t("section_categories_of_vendor"),
        data: sortCategoryOptions(vendorSection.map(toCategoryOption)),
      });
    }

    if (policySection.length > 0) {
      sections.push({
        title: t("section_categories_of_policy"),
        data: sortCategoryOptions(policySection.map(toCategoryOption)),
      });
    }
    return sections;
  }, [i18n.language, dataVendorCategories, t, dataCategories]);

  const groupedOptionsFiltered: CategorySection[] = useMemo(() => {
    if (!keyword) {
      return groupedOptions;
    }

    return groupedOptions.reduce((acc, item) => {
      const { title, data } = item;
      const filtered = filter(data, (category) => {
        const keywordUnicode = removeAccents(keyword).toLowerCase();
        const searchText = removeAccents(category.searchText ?? "").toLowerCase();

        return searchText.includes(keywordUnicode);
      });

      if (filtered.length > 0) {
        acc.push({ title, data: filtered });
      }
      return acc;
    }, []);
  }, [keyword, groupedOptions]);

  const categorySelected: CategoryOption | undefined = useMemo(() => {
    if (selectedCategoryId) {
      const policy = groupedOptionsFiltered
        .flatMap((section) => section.data)
        .find((category) => category.expenseCategoryId === selectedCategoryId);
      return policy;
    }
    return undefined;
  }, [groupedOptionsFiltered, selectedCategoryId]);

  const scrollToTop = useCallback(() => {
    if (flatListRef.current?.scrollToOffset) {
      flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
    }
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [groupedOptionsFiltered, scrollToTop]);

  const handleSelectCategory = (item: CategoryOption) => () => {
    categoryBottomSheetRef.current?.close();
    setCategoryValue?.current?.(item?.expenseCategoryId);
    onChange?.(item);
  };
  const renderItem = ({ item }: { item: CategorySection }) => {
    return (
      <View>
        {dataVendorCategories?.length > 0 && (
          <View style={styles.sectionTitle}>
            <AppText style={Fonts.H300}>{item.title}</AppText>
          </View>
        )}
        {item.data.map((category) => (
          <Fragment key={category.expenseCategoryId}>
            <TouchableOpacity
              onPress={handleSelectCategory(category)}
              style={[
                styles.wrapperItem,
                {
                  backgroundColor: categorySelected?.title === category.title ? Colors.primary0 : Colors.while,
                },
              ]}
            >
              <IconCustom name="sell-outline" />
              <AppText style={[Fonts.BodyLarge, styles.textItem]}>
                {category.title}
                {category.code && ` (${category.code})`}
              </AppText>
            </TouchableOpacity>
            <View style={styles.divider} />
          </Fragment>
        ))}
      </View>
    );
  };

  const handleChangeText = useCallback((text) => {
    setKeyword(text);
  }, []);

  const renderListEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <WhiteSpace size="md" />
        <IconCustom name="sell-outline" />
        <AppText style={styles.emptyText}>{t("no_result")}</AppText>
      </View>
    );
  };
  const handleOpenModal = () => {
    setKeyword("");
    categoryBottomSheetRef?.current?.present?.();
  };

  const getCategoryTitle = (categoryId) => {
    const category = dataCategories?.find((i) => i?.expenseCategoryId === categoryId);
    if (!category) {
      return defaultCategoryTitle;
    }
    return getTitleFromCategory({
      title: category?.title,
      titleEn: category?.titleEn,
    });
  };

  return (
    <View style={styles.container} ref={textRef}>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { onChange: onChangeValue, value } }) => (
          <View style={style}>
            <TouchableOpacity
              onPress={() => {
                setCategoryValue.current = onChangeValue;
                handleOpenModal();
              }}
              style={[styles.input, Boolean(error) && { borderColor: Colors.alert50 }]}
            >
              {Boolean(value) && <AppText style={styles.label}>{t("category")}</AppText>}
              <AppText
                style={styles.categoryValue}
                color={
                  error
                    ? Colors.alert50
                    : value
                    ? CONSTANTS.COMMON.TEXT_INPUT_COLOR
                    : CONSTANTS.COMMON.PLACEHOLDER_COLOR
                }
                numberOfLines={1}
              >
                {value ? getCategoryTitle(value) : t("category")}
              </AppText>
              {value ? (
                <TouchableOpacity
                  onPress={() => {
                    onChangeValue(null);
                    onChange?.(null);
                  }}
                >
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : (
                <IconCustom name="expand-more" />
              )}
            </TouchableOpacity>
            {Boolean(error?.message) && (
              <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                {error.message?.toString()}
              </AppText>
            )}
          </View>
        )}
      />
      <BottomSheetFlatListModalCustom
        loading={loadingVendorCategories}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={t("choose_category")}
        ref={categoryBottomSheetRef}
        searchHeight={55}
        renderSearch={() => (
          <>
            <ExpenseCategorySearchInput
              onChangeText={handleChangeText}
              style={styles.searchInput}
              placeholder={t("search")}
            />
            <Line hasBackground={false} size="xl" />
          </>
        )}
        listProps={{
          keyboardDismissMode: "on-drag",
          keyboardShouldPersistTaps: "always",
          showsVerticalScrollIndicator: false,
          data: groupedOptionsFiltered,
          keyExtractor: (item) => item.expenseCategoryId,
          ListEmptyComponent: renderListEmptyComponent,
          renderItem,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginTop: 6 },
  searchInput: {
    marginHorizontal: 20,
  },
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 13,
    flexDirection: "row",
  },
  emptyContainer: {
    flex: 1,
    minHeight: 250,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  emptyText: {
    marginLeft: 8,
    alignSelf: "center",
  },
  wrapperItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 16,
  },
  textItem: { marginLeft: 15, fontSize: 16, flex: 1 },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  categoryValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
  sectionTitle: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 16,
    paddingBottom: 8,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});

export default CategorySelect;
