import dayjs from "dayjs";
import { uniqBy } from "lodash";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Colors, Fonts } from "theme";
import { DeleteIcon } from "assets/images/svg/icons";
import { AppText, CurrencyText, IconCustom, InvoiceStatus } from "components";
import { CONSTANTS } from "constants/constants";

interface InvoiceItemProps {
  active?: boolean;
  item: ExpInvoice;
  handleViewDetail?: () => void;
  expenseView?: boolean;
  onClear?: () => void;
}

const InvoiceItem: FC<InvoiceItemProps> = ({ item, active, handleViewDetail, expenseView, onClear }) => {
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const { invoiceItems, invoiceValidations, sellerLegalName, totalAmountWithVat, invoiceNumber, issuedDate } = item;
  const info = uniqBy(invoiceItems, "itemName")
    .map(({ itemName }) => itemName)
    .join(", ");
  return (
    <View style={[styles.itemContainer, active && styles.activeContainer]}>
      <View style={styles.statusContainer}>
        <InvoiceStatus isValid={invoiceValidations?.[0]?.isExpenseInvoiceValid} />
        <View style={styles.totalAmountRow}>
          <CurrencyText style={Fonts.NumericN200}>{totalAmountWithVat}</CurrencyText>
          {onClear && (
            <TouchableOpacity style={styles.deleteButton} onPress={onClear}>
              <DeleteIcon />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <View style={styles.invoiceNumberContainer}>
        <AppText style={Fonts.BodySmall}>
          {t("electronic_invoice_number")}
          <AppText style={Fonts.BodySmall} color={Colors.success100}>
            {invoiceNumber}
            <AppText style={Fonts.BodySmall}>{` - ${dayjs(issuedDate).format(CONSTANTS.FORMAT_DAY)}`}</AppText>
          </AppText>
        </AppText>
      </View>
      <View>
        <AppText style={Fonts.BodyMedium} numberOfLines={1} color={Colors.grayscale80}>
          {sellerLegalName}
        </AppText>
        <AppText style={[Fonts.BodySmall, styles.itemName]} color={Colors.grayscale60} numberOfLines={1}>
          {info}
        </AppText>
      </View>
      {!expenseView && (
        <TouchableOpacity onPress={handleViewDetail} style={styles.viewButton}>
          <AppText style={Fonts.BodySmall} color={Colors.primary50}>
            {t("view")}
          </AppText>
          <IconCustom name="arrow-right-alt" />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activeContainer: {
    backgroundColor: Colors.primary0,
  },
  itemContainer: {
    backgroundColor: Colors.white,
  },
  viewButton: {
    paddingTop: 5,
    paddingBottom: 15,
    flexDirection: "row",
    gap: 4,
    alignSelf: "flex-end",
  },
  statusContainer: {
    flexDirection: "row",
    marginBottom: 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  invoiceNumberContainer: { marginBottom: 8 },
  itemName: { marginVertical: 4 },
  deleteButton: { paddingVertical: 3, paddingLeft: 8 },
  totalAmountRow: { flexDirection: "row", alignItems: "center" },
});

export default InvoiceItem;
