import { RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BackHeader,
  BottomSheetModalCustomMethods,
  IconCustom,
  Line,
  SelectCompanyTeam,
  useWindowDimensions,
} from "components";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import CategoryChartSection from "./components/CategoryChartSection";
import CompanyTeamChartSection from "./components/CompanyTeamChartSection";
import OverviewChartSection from "./components/OverviewChartSection";
import SelectTimeModal from "./components/SelectTimeModal";
import useExpenseOverviewQuery from "./hooks/useExpenseOverviewQuery";
import { useAuth } from "contexts/AuthContext";
import dayjs, { Dayjs } from "dayjs";
import { COMPARE_TYPE, OrgTeam, TIME_FILTER_OPTIONS } from "screens/ExpenseOverview/types";
import useExpActiveBudgetPlanQuery from "./hooks/useExpActiveBudgetPlanQuery";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import useSpendingEmployeesQuery from "screens/ExpenseOverview/hooks/useSpendingEmployeesQuery";
import EmployeeChartSection from "screens/ExpenseOverview/components/EmployeeChartSection";
import useSpendingTeamsQuery from "screens/ExpenseOverview/hooks/useSpendingTeamsQuery";
import { getTitleFromCompanyTeam } from "utils";

dayjs.extend(quarterOfYear);
const PADDING_TIME_SECTION = 20;
const GAP_BETWEEN_TAB = 18;
const ExpenseOverviewScreen = () => {
  const navigation = useNavigation();
  const {
    company: { company_id },
  } = useAuth();
  const { t } = useTranslation("app/screens/ExpenseOverview/ExpenseOverviewScreen");
  const timeRefModal = useRef<BottomSheetModalCustomMethods>();
  const { width } = useWindowDimensions();

  const [currentTimeFilter, setCurrentTimeFilter] = useState<TIME_FILTER_OPTIONS>(TIME_FILTER_OPTIONS.MONTH);
  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().startOf("month"));
  const [toDate, setToDate] = useState<Dayjs>(dayjs().endOf("day"));
  const [fromDateCompare, setFromDateCompare] = useState<Dayjs>(dayjs().subtract(1, "month").startOf("month"));
  const [toDateCompare, setToDateCompare] = useState<Dayjs>(dayjs().subtract(1, "month").endOf("day"));
  const [isCompare, setIsCompare] = useState(true);
  const [isShowWarning, setShowWarning] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [compareWith, setCompareWith] = useState<COMPARE_TYPE>(COMPARE_TYPE.SAME_PERIOD_BEFORE);

  const [fromDateCustomize, setFromDateCustomize] = useState<Date>();
  const [toDateCustomize, setToDateCustomize] = useState<Date>();
  const [companyTeam, setCompanyTeam] = useState<OrgTeam>(null);
  const scrollViewRef = useRef<ScrollView>();
  const TAB_TIME_WIDTH =
    currentTimeFilter === TIME_FILTER_OPTIONS.YEAR_TO_DATE
      ? 90
      : currentTimeFilter === TIME_FILTER_OPTIONS.YEAR
      ? 70
      : 82;

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("expense_overview")}
          description={
            isFilteringByCompanyTeam
              ? getTitleFromCompanyTeam({ name: companyTeam?.name, nameEn: companyTeam?.nameEn })
              : t("all_company")
          }
          headerRight={
            <SelectCompanyTeam
              type="filter"
              title={t("select_team")}
              setValue={(value) => {
                if (value?.teamId !== companyTeam?.teamId) {
                  scrollViewRef?.current?.scrollTo({ y: 0 });
                  setLoading(true);
                }
                setCompanyTeam(value);
              }}
              value={companyTeam}
            />
          }
        />
      ),
    } as any);
  }, [t, companyTeam]);

  const tabTimeRef = useRef<ScrollView>();
  const [loading, setLoading] = useState(true);
  const isFilteringByCompanyTeam = Boolean(companyTeam);
  const paramsInsights: ExpInsightsInput = {
    companyId: company_id,
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
    compareWith: fromDateCompare
      ? {
          fromDate: fromDateCompare.toISOString(),
          toDate: toDateCompare.toISOString(),
        }
      : undefined,
    companyTeamId: isFilteringByCompanyTeam ? companyTeam?.teamId : undefined,
  };
  const { data: expenseData, refetch } = useExpenseOverviewQuery(
    {
      input: paramsInsights,
    },
    () => {
      setRefreshing(false);
      setLoading(false);
    },
    () => {
      setRefreshing(false);
      setLoading(false);
    }
  );
  const { data: spendingEmployee, refetch: refetchSpendingEmployee } = useSpendingEmployeesQuery(
    {
      input: paramsInsights,
    },
    !isFilteringByCompanyTeam
  );
  const { data: spendingTeam, refetch: refetchSpendingTeam } = useSpendingTeamsQuery(
    {
      input: paramsInsights,
    },
    isFilteringByCompanyTeam
  );
  const isSetupBudget = Boolean(expenseData?.expInsights?.budget);
  const { data: activeBudgetPlanData } = useExpActiveBudgetPlanQuery(
    {
      companyId: company_id,
    },
    !isSetupBudget
  );
  const onRefresh = () => {
    setRefreshing(true);
    refetch();
    if (isFilteringByCompanyTeam) {
      refetchSpendingEmployee();
    } else {
      refetchSpendingTeam();
    }
  };
  useEffect(() => {
    if (currentTimeFilter) {
      setShowWarning(true);
      switch (currentTimeFilter) {
        case TIME_FILTER_OPTIONS.MONTH: {
          handleSelectTime(dayjs().startOf("month"), dayjs().endOf("day"))();
          break;
        }
        case TIME_FILTER_OPTIONS.QUARTER: {
          handleSelectTime(dayjs().startOf("quarter"), dayjs().endOf("quarter"))();
          break;
        }
        case TIME_FILTER_OPTIONS.YEAR: {
          handleSelectTime(dayjs().startOf("year"), dayjs().endOf("year"))();
          break;
        }
        case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
          handleSelectTime(dayjs().startOf("year"), dayjs().endOf("day"))();
          break;
        }
        case TIME_FILTER_OPTIONS.CUSTOMIZE: {
          handleSelectTime(dayjs(fromDateCustomize).startOf("month"), dayjs(toDateCustomize).endOf("month"))();
          break;
        }
      }
    }
  }, [currentTimeFilter, fromDateCustomize, compareWith]);

  /*-- automatically scroll to the center tab active upon entering this screen --*/
  const handleScrollToCenterTimeSection = () => {
    let findTabIndexActive = 0;
    if (currentTimeFilter === TIME_FILTER_OPTIONS.MONTH) {
      const startDateOfMonth = dayjs().startOf("month");
      const startOfYearDate = dayjs().subtract(1, "year").startOf("year");
      const monthTabs = new Array(24)
        .fill(null)
        .map((item, index) => startOfYearDate.add(index, "month").startOf("month"));
      findTabIndexActive = monthTabs.findIndex((item) => {
        item = dayjs(item).startOf("month");
        return item.isSame(startDateOfMonth, "month") && item.isSame(startDateOfMonth, "year");
      });
    } else if (currentTimeFilter === TIME_FILTER_OPTIONS.QUARTER) {
      const startDateOfQuarter = dayjs().startOf("quarter");
      const { fromDate: fromDateBudgetPlan, toDate: toDateBudgetPlan } = activeBudgetPlanData?.expActiveBudgetPlan ?? {
        fromDate: dayjs().startOf("year"),
        toDate: dayjs().endOf("year"),
      };
      const firstQuarterFromDate = dayjs(fromDateBudgetPlan).subtract(4, "quarter").startOf("quarter");
      const countBetweenQuarter = dayjs(toDateBudgetPlan).endOf("quarter").diff(firstQuarterFromDate, "quarter") + 1;
      const quarterTabs = new Array(countBetweenQuarter)
        .fill(null)
        .map((_, index) => firstQuarterFromDate.add(index, "quarter"));
      findTabIndexActive = quarterTabs.findIndex(
        (item) => dayjs(item).isSame(startDateOfQuarter, "quarter") && dayjs(item).isSame(startDateOfQuarter, "year")
      );
    }
    setTimeout(() => {
      tabTimeRef?.current?.scrollTo({
        x:
          findTabIndexActive * (TAB_TIME_WIDTH + GAP_BETWEEN_TAB) +
          PADDING_TIME_SECTION -
          width / 2 +
          TAB_TIME_WIDTH / 2,
        animated: false,
      });
    }, 100);
  };
  useEffect(() => {
    handleScrollToCenterTimeSection();
  }, [currentTimeFilter]);
  /*-- end --*/

  const handleSelectTime =
    (fromDate: Dayjs, toDate: Dayjs, disabledCompare = false) =>
    () => {
      scrollViewRef?.current?.scrollTo({ y: 0 });
      setFromDate(fromDate);
      setToDate(toDate);
      setShowWarning(true);
      setLoading(true);
      if (disabledCompare) {
        setFromDateCompare(undefined);
        setToDateCompare(undefined);
        return;
      }
      let newFromDateCompare, newToDateCompare;
      switch (currentTimeFilter) {
        case TIME_FILTER_OPTIONS.MONTH: {
          const unitSubtract = compareWith === COMPARE_TYPE.SAME_PERIOD_LAST_YEAR ? "year" : "month";
          newFromDateCompare = fromDate.subtract(1, unitSubtract).startOf("month");
          newToDateCompare =
            toDate.isSame(dayjs(), "month") && toDate.isSame(dayjs(), "year")
              ? dayjs().subtract(1, unitSubtract).endOf("day")
              : toDate.subtract(1, unitSubtract).endOf("day");
          break;
        }
        case TIME_FILTER_OPTIONS.QUARTER: {
          if (compareWith === COMPARE_TYPE.SAME_PERIOD_LAST_YEAR) {
            newFromDateCompare = fromDate.subtract(1, "year").startOf("month");
            newToDateCompare = toDate.subtract(1, "year").endOf("month");
          } else {
            newFromDateCompare = fromDate.subtract(3, "month").startOf("month");
            newToDateCompare = toDate.subtract(3, "month").endOf("month");
          }
          break;
        }
        case TIME_FILTER_OPTIONS.YEAR: {
          newFromDateCompare = fromDate.subtract(1, "year").startOf("year");
          newToDateCompare = toDate.subtract(1, "year").endOf("year");
          break;
        }
        case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
          newFromDateCompare = fromDate.subtract(1, "year").startOf("year");
          newToDateCompare = toDate.subtract(1, "year").endOf("year");
          break;
        }
        case TIME_FILTER_OPTIONS.CUSTOMIZE: {
          const unitSubtract = compareWith === COMPARE_TYPE.SAME_PERIOD_LAST_YEAR ? "year" : "month";
          const between = toDate.diff(fromDate, unitSubtract) + 1;
          newFromDateCompare = fromDate.subtract(between, unitSubtract).startOf("month");
          newToDateCompare = toDate.subtract(between, unitSubtract).endOf("month");
          break;
        }
      }
      setFromDateCompare(newFromDateCompare);
      setToDateCompare(newToDateCompare);
    };
  const renderListTabSection = () => {
    let content = null;
    switch (currentTimeFilter) {
      case TIME_FILTER_OPTIONS.MONTH: {
        const startOfYearDate = dayjs().subtract(1, "year").startOf("year");
        content = new Array(24).fill(null).map((item, index) => {
          const startDateOfMonth = startOfYearDate.add(index, "month").startOf("month");
          const isActive = dayjs(startDateOfMonth).isSame(fromDate);
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.tabButton,
                isActive && styles.tabButtonActive,
                {
                  width: TAB_TIME_WIDTH,
                },
              ]}
              onPress={handleSelectTime(
                startDateOfMonth,
                startDateOfMonth.isSame(dayjs(), "month") ? dayjs().endOf("day") : startDateOfMonth.endOf("month"),
                index === 0 && compareWith === COMPARE_TYPE.SAME_PERIOD_BEFORE
              )}
            >
              <AppText
                allowFontScaling={false}
                style={Fonts.BodySmall}
                color={isActive ? Colors.white : Colors.grayscale100}
              >
                {startDateOfMonth.format("MM/YYYY")}
              </AppText>
            </TouchableOpacity>
          );
        });
        break;
      }
      case TIME_FILTER_OPTIONS.QUARTER: {
        const { fromDate: fromDateBudgetPlan, toDate: toDateBudgetPlan } =
          activeBudgetPlanData?.expActiveBudgetPlan ?? {
            fromDate: dayjs().startOf("year"),
            toDate: dayjs().endOf("year"),
          };
        const firstQuarterFromDate = dayjs(fromDateBudgetPlan).subtract(4, "quarter").startOf("quarter");
        const countBetweenQuarter = dayjs(toDateBudgetPlan).endOf("quarter").diff(firstQuarterFromDate, "quarter") + 1;

        content = new Array(countBetweenQuarter).fill(null).map((_, index) => {
          const date = firstQuarterFromDate.add(index, "quarter");
          const isActive = dayjs(date).isSame(fromDate);
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.tabButton,
                isActive && styles.tabButtonActive,
                {
                  width: TAB_TIME_WIDTH,
                },
              ]}
              onPress={handleSelectTime(
                date,
                date.endOf("quarter"),
                index === 0 && compareWith === COMPARE_TYPE.SAME_PERIOD_BEFORE
              )}
            >
              <AppText
                allowFontScaling={false}
                style={Fonts.BodySmall}
                color={isActive ? Colors.white : Colors.grayscale100}
              >
                {`Q${dayjs(date).quarter()}/${dayjs(date).format("YYYY")}`}
              </AppText>
            </TouchableOpacity>
          );
        });
        break;
      }
      case TIME_FILTER_OPTIONS.YEAR: {
        const lastYearFromDate = dayjs().subtract(1, "year").startOf("year");
        const currentYearFromDate = dayjs().startOf("year");
        content = [lastYearFromDate, currentYearFromDate].map((item, index) => {
          const isActive = dayjs(item).isSame(fromDate);
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.tabButton,
                isActive && styles.tabButtonActive,
                {
                  width: TAB_TIME_WIDTH,
                },
              ]}
              onPress={handleSelectTime(
                item,
                item.endOf("year"),
                index === 0 && compareWith === COMPARE_TYPE.SAME_PERIOD_BEFORE
              )}
            >
              <AppText
                allowFontScaling={false}
                style={Fonts.BodySmall}
                color={isActive ? Colors.white : Colors.grayscale100}
              >
                {`${dayjs(item).format("YYYY")}`}
              </AppText>
            </TouchableOpacity>
          );
        });
        break;
      }
      case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
        const lastYearToMonthFromDate = dayjs().subtract(1, "year").startOf("year");
        const currentYearToMonthFromDate = dayjs().startOf("year");
        content = [lastYearToMonthFromDate, currentYearToMonthFromDate].map((item, index) => {
          const isActive = dayjs(item).isSame(fromDate);
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.tabButton,
                isActive && styles.tabButtonActive,
                {
                  width: TAB_TIME_WIDTH,
                },
              ]}
              onPress={handleSelectTime(
                item,
                dayjs()
                  .subtract(1 - index, "year")
                  .endOf("day"),
                index === 0 && compareWith === COMPARE_TYPE.SAME_PERIOD_BEFORE
              )}
            >
              <AppText
                allowFontScaling={false}
                style={Fonts.BodySmall}
                color={isActive ? Colors.white : Colors.grayscale100}
              >
                {`YTD-${dayjs(item).format("YYYY")}`}
              </AppText>
            </TouchableOpacity>
          );
        });
        break;
      }
    }
    if (content) {
      return (
        <ScrollView
          ref={tabTimeRef}
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={{ paddingHorizontal: PADDING_TIME_SECTION, marginTop: 8, gap: GAP_BETWEEN_TAB }}
        >
          {content}
        </ScrollView>
      );
    }
  };

  const getTimeTitle = () => {
    switch (currentTimeFilter) {
      case TIME_FILTER_OPTIONS.MONTH: {
        return t("by_month");
      }
      case TIME_FILTER_OPTIONS.QUARTER: {
        return t("by_quarter");
      }
      case TIME_FILTER_OPTIONS.YEAR: {
        return t("by_year");
      }
      case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
        return t("ytd");
      }
      default: {
        if (dayjs(fromDateCustomize).isSame(dayjs(toDateCustomize), "month")) {
          return `${dayjs(toDateCustomize).format("MM/YYYY")}`;
        } else if (dayjs(fromDateCustomize).isSame(dayjs(toDateCustomize), "year")) {
          return `${dayjs(fromDateCustomize).format("MM")}-${dayjs(toDateCustomize).format("MM/YYYY")}`;
        }
        return `${dayjs(fromDateCustomize).format("MM/YYYY")}-${dayjs(toDateCustomize).format("MM/YYYY")}`;
      }
    }
  };
  const renderCompareWithTitle = () => {
    if (!isCompare || !fromDateCompare) {
      return null;
    }
    switch (currentTimeFilter) {
      case TIME_FILTER_OPTIONS.MONTH: {
        return (
          <AppText style={styles.timeTitle} color={Colors.grayscale80} numberOfLines={1}>
            {`${t("compare_with")}: 01-${dayjs(toDateCompare).format("DD/MM/YYYY")}`}
          </AppText>
        );
      }
      case TIME_FILTER_OPTIONS.QUARTER: {
        const monthOfDateCompare = dayjs(fromDateCompare).format("MM");
        const yearOfDateCompare = dayjs(fromDateCompare).format("YYYY");
        return (
          <AppText style={styles.timeTitle} color={Colors.grayscale80} numberOfLines={1}>
            {monthOfDateCompare === "01" && `${t("compare_with")}: Q1/${yearOfDateCompare}`}
            {monthOfDateCompare === "04" && `${t("compare_with")}: Q2/${yearOfDateCompare}`}
            {monthOfDateCompare === "07" && `${t("compare_with")}: Q3/${yearOfDateCompare}`}
            {monthOfDateCompare === "10" && `${t("compare_with")}: Q4/${yearOfDateCompare}`}
          </AppText>
        );
      }
      case TIME_FILTER_OPTIONS.YEAR: {
        return (
          <AppText style={styles.timeTitle} color={Colors.grayscale80} numberOfLines={1}>
            {`${t("compare_with")}: ${dayjs(toDateCompare).format("YYYY")}`}
          </AppText>
        );
      }
      case TIME_FILTER_OPTIONS.YEAR_TO_DATE: {
        return (
          <AppText style={styles.timeTitle} color={Colors.grayscale80} numberOfLines={1}>
            {`${t("compare_with")} : YTD-${dayjs(toDateCompare).format("YYYY")}`}
          </AppText>
        );
      }
      default: {
        return (
          <AppText style={styles.timeTitle} color={Colors.grayscale80} numberOfLines={1}>
            {`${t("compare_with")} : ${dayjs(fromDateCompare).format("MM")}-${dayjs(toDateCompare).format("MM/YYYY")}`}
          </AppText>
        );
      }
    }
  };

  const legend = useMemo(() => {
    return (
      <>
        <View style={[styles.flexRow, styles.gap16]}>
          <View style={styles.dotRow}>
            <View style={[styles.dotIcon, { backgroundColor: Colors.primary50 }]} />

            <AppText style={Fonts.BodySmall}>
              {t("expenses")}{" "}
              {
                {
                  [TIME_FILTER_OPTIONS.MONTH]: fromDate.format("MM/YYYY"),
                  [TIME_FILTER_OPTIONS.QUARTER]: `Q${fromDate.quarter()}/${fromDate.format("YYYY")}`,
                  [TIME_FILTER_OPTIONS.YEAR]: fromDate.format("YYYY"),
                  [TIME_FILTER_OPTIONS.YEAR_TO_DATE]: `YTD-${fromDate.format("YYYY")}`,
                }[currentTimeFilter]
              }
            </AppText>
          </View>
          {isCompare && (
            <View style={styles.dotRow}>
              <View style={[styles.dotIcon, { backgroundColor: Colors.grayscale05 }]} />
              <AppText style={Fonts.BodySmall}>
                {compareWith === COMPARE_TYPE.SAME_PERIOD_LAST_YEAR
                  ? t("same_period_last_year_expenses")
                  : t("same_period_before_expenses")}
              </AppText>
            </View>
          )}
        </View>
        <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
          {t("touch_to_show_details")}
        </AppText>
      </>
    );
  }, [compareWith, currentTimeFilter, fromDate, isCompare, t]);

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.header}>
          <TouchableOpacity style={styles.timeSelect} onPress={() => timeRefModal?.current?.present()}>
            <AppText style={Fonts.BodyMedium}>{getTimeTitle()}</AppText>
            <IconCustom name="arrow-drop-down" />
          </TouchableOpacity>
          {renderCompareWithTitle()}
        </View>
        {renderListTabSection()}
      </View>
      <ScrollView
        ref={scrollViewRef}
        refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={refreshing} onRefresh={onRefresh} />}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      >
        <OverviewChartSection
          expenseData={expenseData?.expInsights}
          isCompare={isCompare}
          currentTimeFilter={currentTimeFilter}
          isShowWarning={isShowWarning}
          setShowWarning={setShowWarning}
          refreshing={refreshing}
          loading={loading}
        />
        <Line size="lg" />
        <CategoryChartSection
          loading={loading}
          isCompare={isCompare}
          legend={legend}
          data={expenseData?.expInsights?.topSpendingCategories}
          fromDate={fromDate.toISOString()}
          toDate={toDate.toISOString()}
          companyTeam={companyTeam}
        />
        <Line size="lg" />
        {isFilteringByCompanyTeam ? (
          <EmployeeChartSection
            legend={legend}
            loading={loading}
            isCompare={isCompare}
            data={spendingEmployee?.expInsights?.topSpendingEmployees}
            fromDate={fromDate.toISOString()}
            toDate={toDate.toISOString()}
            companyTeamId={companyTeam?.teamId}
          />
        ) : (
          <CompanyTeamChartSection
            loading={loading}
            isCompare={isCompare}
            legend={legend}
            data={spendingTeam?.expInsights?.topSpendingTeams}
            fromDate={fromDate.toISOString()}
            toDate={toDate.toISOString()}
          />
        )}
      </ScrollView>
      <SelectTimeModal
        isCompare={isCompare}
        setIsCompare={setIsCompare}
        value={currentTimeFilter}
        setValue={setCurrentTimeFilter}
        ref={timeRefModal}
        compareWith={compareWith}
        setCompareWith={setCompareWith}
        setFromDateCustomize={setFromDateCustomize}
        setToDateCustomize={setToDateCustomize}
        fromDateCustomize={fromDateCustomize}
        toDateCustomize={toDateCustomize}
      />
    </View>
  );
};
export default ExpenseOverviewScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  header: {
    paddingHorizontal: 20,
    paddingBottom: 4,
    flexDirection: "row",
    gap: 4,
    justifyContent: "space-between",
  },
  timeSelect: { flexDirection: "row", alignItems: "center" },
  timeTitle: { ...Fonts.BodySmall, flex: 1, textAlign: "right" },
  contentContainer: { paddingTop: 20, paddingBottom: 20 },
  tabButton: {
    height: 32,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  tabButtonActive: { backgroundColor: Colors.grayscale80, borderColor: Colors.grayscale80 },
  flexRow: { flexDirection: "row", alignItems: "center" },
  gap16: { gap: 16 },
  dotIcon: { height: 12, width: 12, borderRadius: 2 },
  dotRow: { flexDirection: "row", gap: 8, alignItems: "center" },
});
