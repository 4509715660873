import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M19.44 20.5c-1.884 0-3.778-.438-5.68-1.314-1.901-.877-3.649-2.113-5.242-3.71-1.593-1.595-2.827-3.343-3.704-5.241C3.938 8.335 3.5 6.445 3.5 4.56A1.032 1.032 0 014.55 3.5h3.261c.253 0 .476.082.669.247.193.165.315.368.368.61L9.421 7.3c.04.273.032.508-.025.704-.056.196-.158.36-.304.494l-2.31 2.248c.372.68.797 1.325 1.275 1.932.477.607.994 1.187 1.55 1.74a17.265 17.265 0 003.753 2.842l2.244-2.264c.156-.163.346-.277.568-.342.223-.066.454-.082.693-.048l2.777.565c.253.067.459.196.619.387.16.19.239.407.239.65v3.242a1.032 1.032 0 01-1.06 1.05zM6.073 9.327l1.785-1.708a.178.178 0 00.062-.106.234.234 0 00-.005-.125l-.434-2.234a.206.206 0 00-.068-.116A.194.194 0 007.288 5H5.15a.13.13 0 00-.096.038.13.13 0 00-.039.097 12.792 12.792 0 001.058 4.192zm8.7 8.642c.663.31 1.354.545 2.074.709.72.163 1.393.26 2.018.291a.13.13 0 00.135-.134V16.73a.194.194 0 00-.038-.125.206.206 0 00-.116-.068l-2.1-.427a.158.158 0 00-.1-.004.246.246 0 00-.092.062l-1.781 1.8z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
