import { FC, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";

interface HeaderPageBottomTabProps {
  title?: string;
  rightComponent?: ReactNode;
}

const HeaderPageBottomTab: FC<HeaderPageBottomTabProps> = ({ title, rightComponent }) => {
  return (
    <View style={styles.container}>
      <AppText style={Fonts.H400}>{title}</AppText>
      {rightComponent ?? null}
    </View>
  );
};

export default HeaderPageBottomTab;
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    marginBottom: 5,
  },
});
