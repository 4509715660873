import { SearchInputProps } from "components/SearchInput";
import { IconCustom, SearchInput } from "components";
import { forwardRef, useCallback, useRef } from "react";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { TextInput as RNTextInput, TouchableOpacity } from "react-native";

const TextInputFlatListModal = forwardRef(({ onFocus, onBlur, value, onChangeText, ...props }: SearchInputProps) => {
  const { shouldHandleKeyboardEvents } = useBottomSheetInternal();
  const inputRef = useRef<RNTextInput>();
  const handleOnFocus = useCallback(
    (args) => {
      shouldHandleKeyboardEvents.value = true;
      onFocus?.(args);
    },
    [onFocus, shouldHandleKeyboardEvents]
  );
  const handleOnBlur = useCallback(
    (args) => {
      shouldHandleKeyboardEvents.value = false;
      onBlur?.(args);
    },
    [onBlur, shouldHandleKeyboardEvents]
  );
  const handleChangeText = (text) => {
    onChangeText(text);
  };
  const handleClear = () => {
    onChangeText("");
    inputRef?.current?.setNativeProps({ text: "" });
  };

  return (
    <SearchInput
      right={
        value ? (
          <TouchableOpacity onPress={handleClear}>
            <IconCustom name="cancel" />
          </TouchableOpacity>
        ) : null
      }
      ref={inputRef}
      {...props}
      onChangeText={handleChangeText}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    />
  );
});
export default TextInputFlatListModal;
