import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 20, height = 20, fill = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={20} height={20}>
        <Path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M13.856 10.624H4.375a.605.605 0 01-.446-.18A.605.605 0 013.75 10c0-.178.06-.326.18-.446s.268-.18.445-.18h9.48L9.549 5.067a.587.587 0 01-.183-.435.619.619 0 01.196-.443A.653.653 0 0110 4a.583.583 0 01.44.188l5.283 5.283a.692.692 0 01.213.528.691.691 0 01-.213.527l-5.284 5.284a.596.596 0 01-.429.176.623.623 0 01-.45-.177.613.613 0 01-.193-.445c0-.168.065-.316.194-.446l4.295-4.294z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
