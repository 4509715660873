import LottieView from "lottie-react-native";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { WIDTH_WINDOW } from "constants/Layout";
import { Portal } from "react-native-paper";
export interface ConfettiAnimationMethods {
  show: () => void;
}
const ConfettiAnimation = forwardRef<ConfettiAnimationMethods>((_, ref) => {
  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    },
  }));
  const [visible, setVisible] = useState(false);

  if (!visible || Platform.OS === "web") {
    return null;
  }

  return (
    <Portal>
      <View style={styles.animationContainer}>
        <LottieView
          speed={1}
          autoPlay={true}
          style={{ width: WIDTH_WINDOW }}
          source={require("assets/lottie/confetti.json")}
        />
      </View>
    </Portal>
  );
});
export default ConfettiAnimation;

const styles = StyleSheet.create({
  animationContainer: {
    position: "absolute",
    top: 0,
    alignItems: "center",
    justifyContent: "flex-end",
    height: "80%",
  },
});
