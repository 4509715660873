import * as React from "react";
import Svg, { Defs, G, Path, Rect } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent({ width = 26, height = 26, color = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 26 26" fill="none">
      <G>
        <Rect x={1} y={1} width={24} height={24} rx={6} fill={color} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.91 15.496l-2.203-2.203a1 1 0 00-1.414 1.414l3 3a1 1 0 001.493-.09l7-8a1 1 0 10-1.572-1.235l-6.304 7.114z"
          fill="#fff"
        />
      </G>
      <Defs />
    </Svg>
  );
}

export default SvgComponent;
