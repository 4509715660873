import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileOrgCompanyCardStatusQuery, MobileOrgCompanyCardStatusQueryVariables } from "types";

const QUERY = gql`
  query MobileOrgCompanyCardStatus($companyId: UUID!) {
    orgCompanyCardStatus(companyId: $companyId) {
      status
    }
  }
`;

const useCardStatusQuery = (variables: MobileOrgCompanyCardStatusQueryVariables, skip) => {
  return useQuery<MobileOrgCompanyCardStatusQuery, MobileOrgCompanyCardStatusQueryVariables>(QUERY, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: skip,
  });
};

export default useCardStatusQuery;
