import * as React from "react";
import { Text, ButtonProps, StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { Button } from "@ant-design/react-native";
import Colors from "app/constants/Colors";

export interface IBizziButton {
  style?: StyleProp<ViewStyle>;
  text?: string;
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  color?: string;
  disabledColor?: string;
  disabled?: boolean;
  textColor?: string;
  mode?: string;
  loading?: boolean;
  disabledTextColor?: string;
}

const BizziButton: React.FC<IBizziButton & ButtonProps> = ({
  style,
  text,
  title,
  titleStyle,
  color,
  disabledColor,
  disabledTextColor,
  disabled,
  textColor,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      disabled={disabled}
      style={StyleSheet.flatten([style, { backgroundColor: disabled ? disabledColor : color }])}
    >
      <Text
        style={StyleSheet.flatten([
          titleStyle,
          { color: disabled ? disabledTextColor || Colors.disableColor500 : textColor },
        ])}
      >
        {text || title}
      </Text>
    </Button>
  );
};

BizziButton.defaultProps = {
  color: Colors.blue22,
  disabledColor: Colors.disableColor100,
  textColor: Colors.whiteText,
};

export default BizziButton;
