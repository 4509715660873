import * as React from "react";
import Svg, { Defs, G, Path, Rect } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent({ width = 26, height = 26, color = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 26 26" fill="none">
      <G>
        <Rect x={1} y={1} width={24} height={24} rx={12} fill="#fff" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M7 13a6 6 0 1112 0 6 6 0 01-12 0z" fill={color} />
        <Rect x={2} y={2} width={22} height={22} rx={11} stroke="#1F71F4" strokeWidth={2} />
      </G>
      <Defs />
    </Svg>
  );
}

export default SvgComponent;
