import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ size }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 0c-.825 0-1.5.675-1.5 1.5v21c0 .825.675 1.5 1.5 1.5h15c.825 0 1.5-.675 1.5-1.5V6l-6-6H6z"
        fill="#E2E5E7"
      />
      <Path d="M18 6h4.5l-6-6v4.5c0 .825.675 1.5 1.5 1.5z" fill="#B0B7BD" />
      <Path d="M22.5 10.5L18 6h4.5v4.5z" fill="#CAD1D8" />
      <Path
        d="M19.5 19.5c0 .413-.337.75-.75.75H2.25a.752.752 0 01-.75-.75V12c0-.412.337-.75.75-.75h16.5c.413 0 .75.338.75.75v7.5z"
        fill="#84BD5A"
      />
      <Path
        d="M6.767 15.292l1.043-1.307c.312-.408.918.113.576.503-.36.426-.737.888-1.097 1.361l1.223 1.524c.33.45-.33.88-.653.437l-1.104-1.415L5.67 17.84c-.306.437-.984-.054-.642-.462l1.205-1.53c-.378-.474-.744-.935-1.11-1.36-.377-.45.325-.912.6-.491l1.044 1.294zM9.254 14.21c0-.198.168-.366.378-.366.192 0 .354.168.354.366v3.004h1.631c.587 0 .6.786 0 .786H9.633c-.21 0-.378-.15-.378-.366V14.21h-.001zM12.753 14.75c.138-1.163 1.895-1.373 2.723-.737.408.33-.024.852-.384.588-.444-.282-1.452-.414-1.578.209-.162.984 2.447.42 2.405 2.016-.042 1.523-2.248 1.558-3.077.875a.443.443 0 01-.084-.588c.157-.155.33-.21.535-.041.49.335 1.757.587 1.846-.265-.077-.886-2.572-.353-2.386-2.056z"
        fill="#fff"
      />
      <Path d="M18.75 20.25H4.5V21h14.25c.413 0 .75-.337.75-.75v-.75c0 .413-.337.75-.75.75z" fill="#CAD1D8" />
    </Svg>
  );
}

export default SvgComponent;
