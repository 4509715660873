import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const EXPENSE_REJECT_REPORT = gql`
  mutation MobileRejectExpenseReport($reportId: UUID!, $reason: String) {
    expenseRejectReport(report_id: $reportId, reason: $reason) {
      message
      success
    }
  }
`;

const useExpenseRejectReport = () => {
  const [rejectReport] = useMutation(EXPENSE_REJECT_REPORT);

  return {
    rejectReport,
  };
};

export default useExpenseRejectReport;
