import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Timeline from "react-native-timeline-flatlist";

import { Colors, Fonts } from "theme";
import { AppText, EmptyData, Line } from "components";
import { useAuth } from "contexts/AuthContext";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { EmptyActivityIcon } from "assets/images/svg/icons";

const COLOR_STATUS = {
  1: Colors.success50,
  2: Colors.alert50,
  0: Colors.primary50,
  3: Colors.alert50,
};

const TEXT_STATUS = {
  0: "send_by",
  1: "approval_by",
  2: "rejected_by",
  3: "canceled_by",
};
interface ActivityLogTabProps {
  logs?: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]["expenseApprovalLogs"];
  contentContainerStyle?: StyleProp<ViewStyle>;
}
const ActivityLogTab: FC<ActivityLogTabProps> = ({ contentContainerStyle, logs }) => {
  const { user } = useAuth();
  const [timeLines, setTimeLines] = useState([]);

  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");

  useEffect(() => {
    if (logs) {
      const formatLogs = logs.map((item) => {
        return {
          ...item,
          circleColor: COLOR_STATUS[item.status],
          logOwner: item.status === 0,
        };
      });
      if (formatLogs.length > 0) {
        setTimeLines(formatLogs);
      }
    }
  }, [logs, user]);

  const renderDetail = useCallback(
    (rowData) => {
      return (
        <View style={styles.flex}>
          <View style={styles.row}>
            <AppText style={Fonts.BodyMedium}>
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                rowData.logOnwer ? t(TEXT_STATUS[0]) : t(TEXT_STATUS[rowData.status])
              }{" "}
              {rowData?.user?.fullname ?? rowData?.companyEmployee?.fullname ?? ""}
            </AppText>
          </View>
          <Line hasBackground={false} size="md" />
          <AppText style={Fonts.Caption} color={Colors.grayscale60}>
            {dayjs(rowData?.createdAt)?.format("HH:mm DD/MM/YYYY")}
          </AppText>
          <Line hasBackground={false} size="md" />
          {Boolean(rowData?.reason) && (
            <View style={styles.row}>
              <View>
                <AppText style={Fonts.Caption} color={Colors.grayscale60}>
                  {t("reason")}:
                </AppText>
              </View>
              <View style={styles.flex}>
                <AppText style={Fonts.Caption} color={Colors.grayscale60}>
                  {" "}
                  {rowData.reason}
                </AppText>
              </View>
            </View>
          )}
        </View>
      );
    },
    [TEXT_STATUS, t]
  );

  return (
    <View style={styles.container}>
      {timeLines?.length ? (
        <Timeline
          listViewContainerStyle={[{ paddingTop: 20 }, contentContainerStyle]}
          innerCircle={"dot"}
          circleSize={15}
          dotSize={7}
          data={timeLines}
          showTime={false}
          lineWidth={2}
          lineColor={Colors.grayscale10}
          renderFullLine
          rowContainerStyle={styles.rowContainerStyle}
          renderDetail={renderDetail}
        />
      ) : (
        <EmptyData icon={<EmptyActivityIcon />} title={t("activity_empty")} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  rowContainerStyle: { alignItems: "center", flex: 1, marginRight: 20 },
  flex: {
    flex: 1,
  },
});

export default ActivityLogTab;
