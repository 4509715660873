import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, ViewStyle } from "react-native";
import { AppText, CurrencyText, IconCustom, TouchableOpacityCustom } from "components";
import { CONSTANTS, EXPENSE_ALLOWANCE_TYPE, REQUEST_TYPE } from "constants/constants";
import LanguageStatus from "constants/LanguageStatus";
import i18n from "i18next";
import { ExpenseListGroupByCategory } from "screens/ExpenseRequest/ExpenseRequestDetailScreen/types";
import { Colors, Fonts } from "theme";
import { MobileExpExpenseRequestDetailQuery } from "types";
import Line from "components/Line";
import { getTitleI18n } from "utils";
import { useNavigation } from "@react-navigation/native";

import { generateAllowancePurchaseDescription } from "screens/ExpenseRequest/helper";
import SCREEN_NAME from "navigation/ScreenName";
import { StackNavigation } from "navigation/type";
import { useMasterData } from "contexts/MasterDataContext";

interface ExpenseListInfoProps {
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
  expenseListByGroup: ExpenseListGroupByCategory[];
  isApproverView?: boolean;
  setPosition?: (value: number) => void;
  style?: ViewStyle;
}
const ExpenseListInfo: FC<ExpenseListInfoProps> = ({ expenseRequest, expenseListByGroup, setPosition, style }) => {
  const navigation = useNavigation<StackNavigation>();
  const isTravelRequest = expenseRequest?.expenseRequestType === REQUEST_TYPE.travel;
  const isPurchaseRequest = expenseRequest?.expenseRequestType === REQUEST_TYPE.purchase;
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen/ExpenseRequestDetailScreen");
  const expenseTravel = expenseRequest?.expenseTravels?.[0];
  const {
    setting: { request: requestConfig },
  } = useMasterData();
  const allowancesPerDiem = useMemo(() => {
    return (expenseRequest?.expenseTravels?.[0]?.expenseAllowances ?? []).filter(
      (allowance) => allowance.allowanceType === EXPENSE_ALLOWANCE_TYPE.PER_DIEM
    );
  }, [expenseRequest]);
  console.log("allowancesPerDiem", allowancesPerDiem);
  const navigateToAllowanceDetailScreen = (item) => () => {
    navigation.navigate(SCREEN_NAME.DetailAllowanceScreen, {
      type: expenseRequest?.expenseRequestType as REQUEST_TYPE,
      item,
    });
  };
  const numberOfPassengers = expenseTravel?.passengers?.length || 1;

  return (
    <View
      style={[styles.container, style]}
      onLayout={(event) => {
        setPosition?.(event.nativeEvent.layout.y);
      }}
    >
      {isTravelRequest && (
        <View style={styles.budgetContainer}>
          <AppText style={[Fonts.SentenceSubtitleXLarge, styles.title]}>{t("perdiem")}</AppText>
          <View style={[styles.rowItem, styles.py8]}>
            <AppText style={[Fonts.BodyRegular, styles.flex]} color={Colors.grayscale80}>
              {t("day_to_business")}
            </AppText>
            <AppText style={Fonts.NumericN300}>{`${expenseTravel?.travelDuration ?? 0} ${
              expenseTravel?.travelDuration > 0 ? t("days") : t("day")
            }`}</AppText>
          </View>
          <Line backgroundColor={Colors.grayscale0} />
          <View style={[styles.rowItem, styles.py8]}>
            <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
              {requestConfig?.travel?.withPassengerInfo ? t("per_diem_rate_person") : t("per_diem_rate")}
            </AppText>
            <CurrencyText style={Fonts.NumericN300}>{expenseTravel?.perDiemAmount}</CurrencyText>
          </View>
          <View style={{ paddingLeft: 8 }}>
            {allowancesPerDiem
              ?.filter((item) => item?.expenseCategory)
              .map((allowance, index) => (
                <View key={index} style={[styles.rowItem, styles.py8, styles.gap8]}>
                  <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale60}>
                    {getTitleI18n({ vi: allowance.expenseCategory?.title, en: allowance.expenseCategory?.titleEn })}
                  </AppText>
                  {!!expenseTravel?.travelDuration && (
                    <CurrencyText style={Fonts.NumericN200} color={Colors.grayscale60}>
                      {allowance.amount / expenseTravel?.travelDuration / numberOfPassengers}
                    </CurrencyText>
                  )}
                </View>
              ))}
          </View>
        </View>
      )}
      <View style={isTravelRequest && { marginTop: 8 }}>
        <View style={styles.expenseListTitle}>
          <AppText style={Fonts.SentenceSubtitleXLarge}>
            {isPurchaseRequest ? t("product_service_list") : t("expense_list")}
          </AppText>
        </View>

        {expenseListByGroup?.map((expenseByGroup) => {
          const budgetInvalid = expenseByGroup?.budget && expenseByGroup?.budget?.isValid === false;
          return (
            <View key={expenseByGroup.categoryId}>
              <View style={styles.expenseGroupTitleContainer}>
                <View style={styles.rowCenter}>
                  <AppText style={[Fonts.SentenceSubtitleLarge, styles.expenseByGroupTitle]} numberOfLines={1}>
                    {i18n.language === LanguageStatus.VN ? expenseByGroup?.title : expenseByGroup?.titleEn}
                  </AppText>
                  <CurrencyText
                    foreignAmount={expenseByGroup?.foreignAmount}
                    foreignAmountStyle={Fonts.BodyLarge}
                    style={Fonts.NumericN300}
                    color={budgetInvalid ? Colors.alert50 : Colors.grayscale100}
                  >
                    {expenseByGroup?.totalAmount}
                  </CurrencyText>
                </View>
                {budgetInvalid && (
                  <View style={styles.budgetMessage}>
                    <AppText style={Fonts.Caption} color={Colors.alert50}>
                      {expenseByGroup?.budget?.message}
                      {expenseByGroup?.budget?.overBudgetAmount > 0 && (
                        <>
                          {" "}
                          <CurrencyText color={Colors.alert50}>{expenseByGroup?.budget?.overBudgetAmount}</CurrencyText>
                        </>
                      )}
                    </AppText>
                  </View>
                )}
              </View>
              {expenseByGroup?.expenseAllowances?.map((item, index) => {
                const isLastItem = index === expenseByGroup?.expenseAllowances?.length - 1;
                return (
                  <View style={styles.expenseListContainer} key={item?.expenseAllowanceId}>
                    <TouchableOpacityCustom style={styles.expenseItem} onPress={navigateToAllowanceDetailScreen(item)}>
                      <View style={styles.expenseIcon}>
                        <IconCustom name="sell" />
                      </View>
                      <View style={{ flex: 1 }}>
                        <View>
                          {
                            {
                              [EXPENSE_ALLOWANCE_TYPE.PER_DIEM]: (
                                <AppText style={[Fonts.BodySmall, styles.perdiemLabel]} color={Colors.grayscale60}>
                                  {t("perdiem_in_short")}
                                </AppText>
                              ),
                              [EXPENSE_ALLOWANCE_TYPE.BOOKING]: (
                                <AppText style={[Fonts.BodySmall, styles.perdiemLabel]} color={Colors.grayscale60}>
                                  {t("expense_remain")}
                                </AppText>
                              ),
                            }[item.allowanceType]
                          }
                        </View>
                        <View style={styles.row}>
                          <AppText style={[Fonts.BodyMedium, styles.expenseCategoryTitle]} numberOfLines={2}>
                            {item?.description || t("need_explanation")}
                          </AppText>
                          <CurrencyText
                            style={Fonts.NumericN200}
                            foreignAmountStyle={Fonts.SentenceBodyMedium}
                            foreignAmount={item?.foreignAmount}
                          >
                            {item?.amount}
                          </CurrencyText>
                        </View>
                        {isPurchaseRequest && (
                          <AppText style={Fonts.BodyMedium}>{generateAllowancePurchaseDescription(item)}</AppText>
                        )}
                      </View>
                    </TouchableOpacityCustom>
                    {!isLastItem && <View style={styles.divider} />}
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default ExpenseListInfo;

const styles = StyleSheet.create({
  gap8: {
    gap: 8,
  },
  py8: {
    paddingVertical: 8,
  },
  container: {},
  flex: { flex: 1 },
  rowItem: { flexDirection: "row", justifyContent: "space-between" },
  expenseCategoryTitle: { flex: 1, marginRight: 10 },
  expenseItem: { flexDirection: "row", paddingVertical: 17, alignItems: "center", gap: 9 },
  row: { flexDirection: "row", justifyContent: "space-between" },
  expenseListTitle: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    paddingTop: 16,
    paddingBottom: 8,
  },
  budgetContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    paddingTop: 12,
    paddingBottom: 20,
  },
  expenseListContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    paddingVertical: 5,
  },
  expenseGroupTitleContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    paddingTop: 15,
    paddingBottom: 11,
    marginBottom: 1,
  },
  expenseByGroupTitle: { flex: 1, marginRight: 10 },
  rowCenter: { flexDirection: "row", alignItems: "center" },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: Colors.grayscale05,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  budgetMessage: { alignItems: "flex-end" },
  perdiemLabel: {
    marginBottom: 6,
  },
  title: {
    paddingTop: 16,
    paddingBottom: 8,
  },
  expenseIcon: {
    width: 48,
    height: 48,
    borderRadius: 48 / 2,
    backgroundColor: Colors.primary0,
    justifyContent: "center",
    alignItems: "center",
  },
});
