import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  AlertNotification,
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetTextInputCustom,
  Button,
  ToastManager,
} from "components";
import { CancelReportWarningIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { goBack } from "navigation/RootNavigation";
import useExpenseRejectReport from "hooks/reports/useExpenseRejectReport";
import { EVENT } from "constants/Tracking";
import { analyticService } from "services/AnalyticsService";
import SCREEN_NAME, { SCREEN_NAME_TRACKING } from "navigation/ScreenName";
import { useValidateExpenseReportAmountMutation } from "hooks/useValidateExpenseReportAmount";
import { useAuth } from "contexts/AuthContext";
import { MobileExpenseReportDetailsQuery } from "types";
import { APPROVAL_STATUS, TRACKING_OBJECT_TYPE } from "constants/constants";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { executeRouteFunction } from "utils/route";
import { useRoute } from "@react-navigation/native";

interface RejectExpenseReportModalProps {
  reportId: string;
  title?: string;
  onRefreshData?: () => void;
  data: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
}
const RejectExpenseReportModal = forwardRef<Partial<BottomSheetModalCustomMethods>, RejectExpenseReportModalProps>(
  ({ title, reportId, onRefreshData, data }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetModalRef?.current?.present();
      },
    }));

    const route = useRoute();
    const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
    const alertNotificationRef = useRef(null);
    const bottomSheetModalRef = useRef<BottomSheetModalCustomMethods>(null);
    const { rejectReport } = useExpenseRejectReport();
    const [validateExpenseReportAmount] = useValidateExpenseReportAmountMutation();
    const { user } = useAuth();

    const [reason, setReason] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const handleReject = async () => {
      setDisabledButton(true);
      Keyboard.dismiss();
      bottomSheetModalRef?.current?.close();
      alertNotificationRef?.current?.loading();
      try {
        const results = await rejectReport({
          variables: {
            reportId: reportId,
            reason,
          },
        });

        setDisabledButton(false);
        if (results?.data?.expenseRejectReport?.message === "no_permission") {
          throw new Error("no_permission");
        }

        if (results?.data?.expenseRejectReport?.success) {
          alertNotificationRef?.current?.close();
          ToastManager.success(t("reject_expense_report_success"));
          executeRouteFunction(route, "onRefreshData");
          goBack();
          const expenseParamsValidate = data?.expenses
            ?.filter((item) => item?.totalAmountWithoutVat > 0)
            .map((item) => ({
              expenseDate: item?.expenseDate,
              spendingAmount: item?.totalAmountWithoutVat,
              spendingAmountWithVat: item?.totalAmountWithVat,
              spendingAmountWithoutVat: item?.totalAmountWithoutVat,
              expenseCategoryId: item?.expenseCategory?.expenseCategoryId,
              expenseRequestId: item?.expenseRequestId ?? undefined,
              companyTeamId: item?.companyTeamId ?? undefined,
            }));
          const rs = await validateExpenseReportAmount({
            variables: {
              input: {
                companyId: user?.company?.company_id,
                expenses: expenseParamsValidate,
              },
            },
          });
          const validateReport = rs?.data?.validateExpenseReportAmount;
          const companyTeam = data?.expenseReportsTeams?.[0]?.companyTeam;
          const expenseApprovalRules = data?.expenseApprovalRules ?? [];
          const approvalRulesWaitingIndex = expenseApprovalRules.findIndex(
            (item) =>
              item?.status === APPROVAL_STATUS.AWAITING &&
              item?.expenseApprovalRuleEmployees?.some((employee) => employee?.employeeId === user?.employee_id)
          );
          const isFinalApprovalRule = approvalRulesWaitingIndex === expenseApprovalRules.length - 1;
          analyticService.logEvent({
            sessionId: SCREEN_NAME.ReportDetailScreen,
            name: EVENT.REPORT.REJECTED_SUCCESS,
            properties: {
              current_screen: SCREEN_NAME_TRACKING.ReportDetailScreen,
              object_id: reportId,
              object_type: TRACKING_OBJECT_TYPE.REPORT,
              is_final: isFinalApprovalRule,
              over_budgets: validateReport?.budgets
                ?.filter((item) => !item?.isValid)
                ?.map((item) => ({
                  expense_category_id: item?.expenseCategoryId,
                  company_team_id: companyTeam?.companyTeamId,
                  over_amount: item?.overBudgetAmount,
                  expense_date: item?.expenseDate,
                })),
              over_requests: validateReport?.expenseRequests
                ?.filter((item) => !item?.isValid)
                ?.map((item) => ({
                  expense_category_id: item?.expenseCategoryId,
                  company_team_id: companyTeam?.companyTeamId,
                  over_amount: item?.overRequestAmount,
                })),
            },
          });
        }
      } catch (errors) {
        setDisabledButton(false);
        const errorTitle = t("reject_report_error");
        let errorMessage = t("internal_error");
        switch (errors?.message) {
          case SERVER_ERROR_CODE.NOT_OPEN_TO_REJECT: {
            errorMessage = t("not_open_to_reject");
            break;
          }
          case SERVER_ERROR_CODE.ITEM_CANCELLED: {
            errorMessage = t("report_is_cancelled");
            break;
          }
          case SERVER_ERROR_CODE.NO_PERMISSION: {
            errorMessage = t("no_permission_reject");
            break;
          }
        }
        analyticService.logEvent({
          sessionId: SCREEN_NAME.ReportDetailScreen,
          name: EVENT.REPORT.REJECTED_FAILED,
          properties: {
            message: errorMessage,
          },
        });
        alertNotificationRef.current?.error({
          title: errorTitle,
          description: errorMessage,
        });
      }
    };

    return (
      <>
        <BottomSheetScrollViewModalCustom ref={bottomSheetModalRef} wrapperByScrollView={false}>
          <TouchableWithoutFeedback onPress={Platform.OS !== "web" && Keyboard.dismiss}>
            <View style={styles.container}>
              <CancelReportWarningIcon />
              <View style={styles.labelContainer}>
                <AppText style={Fonts.H400}>{t("reject_report")}</AppText>
                <AppText style={[Fonts.BodyMedium, styles.confirmText]} numberOfLines={2}>
                  {t("reject_report_description")} <AppText style={Fonts.H200}>{` ${title}?`}</AppText>
                </AppText>
              </View>
              <BottomSheetTextInputCustom
                autoFocus
                value={reason}
                onChangeText={setReason}
                placeholder={t("reason_reject")}
              />
            </View>
          </TouchableWithoutFeedback>

          <View style={styles.footerButton}>
            <Button type="white" style={styles.buttonClose} onPress={() => bottomSheetModalRef?.current?.close()}>
              {t("close")}
            </Button>
            <Button type="danger" disabled={disabledButton} style={styles.buttonCancel} onPress={handleReject}>
              {t("reject")}
            </Button>
          </View>
        </BottomSheetScrollViewModalCustom>
        <AlertNotification ref={alertNotificationRef} />
      </>
    );
  }
);
export default RejectExpenseReportModal;

const styles = StyleSheet.create({
  container: { paddingHorizontal: 20, paddingTop: 40, justifyContent: "center", alignItems: "center" },
  labelContainer: { alignItems: "center", marginTop: 20 },
  confirmText: { marginTop: 8, textAlign: "center" },
  textInput: {
    color: Colors.grayscale100,
    borderWidth: 1,
    padding: 10,
    borderColor: Colors.primary50,
    width: "100%",
    height: 120,
    borderRadius: 4,
    marginTop: 20,
  },
  footerButton: { flexDirection: "row", paddingHorizontal: 20, marginTop: 20, paddingBottom: 10 },
  buttonClose: { flex: 1, marginRight: 8 },
  buttonCancel: { flex: 1, marginLeft: 8 },
});
