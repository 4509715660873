import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";

import { EmptyExpense } from "assets/images/svg/icons";
import { AppText, EmptyData, ExpenseItem } from "components";
import { navigate } from "navigation/RootNavigation";
import { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";

interface ExpenseListProps {
  data: {
    note?: string;
    provider?: string;
    category?: string;
    status: number;
    expenseId: string;
    invoiceId?: string;
    totalAmountWithVat: number;
    expenseDate: string;
    receivedInvoice?: boolean;
    expenseCategory?: { title: string; expenseCategoryId: string };
  }[];
  loading?: boolean;
  onRefreshData?: () => void;
}

const ExpenseList: FC<ExpenseListProps> = ({ data, loading = false, onRefreshData }) => {
  const { t } = useTranslation("app/screens/Home/HomeScreen");

  const handleViewAll = () => {
    navigate(SCREEN_BOTTOM_TAB.ExpensesScreen);
  };

  if (!loading && (!data || data?.length === 0)) {
    return (
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <AppText style={Fonts.H400}>{t("latest_expenses")}</AppText>
        </View>
        <EmptyData
          icon={<EmptyExpense />}
          title={t("empty_expense")}
          description={t("empty_expense_description")}
          style={{ marginTop: 40 }}
        />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <AppText style={Fonts.H400}>{t("latest_expenses")}</AppText>
        {data?.length > 0 && (
          <TouchableOpacity onPress={handleViewAll}>
            <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
              {t("view_all")}
            </AppText>
          </TouchableOpacity>
        )}
      </View>
      <View>
        {data?.map((item) => (
          <ExpenseItem onRefreshData={onRefreshData} key={item?.expenseId} item={item} />
        ))}
      </View>
    </View>
  );
};
export default memo(ExpenseList);
const styles = StyleSheet.create({
  container: { marginTop: 30, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  titleContainer: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 15 },
});
