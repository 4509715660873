import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 67, height = 66, color = "#E11934" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 67 66" fill="none">
      <Path
        d="M33.5 62.563C17.192 62.563 3.937 49.306 3.937 33c0-16.308 13.255-29.563 29.563-29.563 16.307 0 29.563 13.255 29.563 29.563 0 16.307-13.256 29.563-29.563 29.563zm0-55C19.475 7.563 8.062 18.974 8.062 33S19.476 58.438 33.5 58.438c14.025 0 25.438-11.413 25.438-25.438 0-14.025-11.413-25.438-25.438-25.438z"
        fill={color}
      />
      <Path
        d="M33.5 37.813a2.078 2.078 0 01-2.063-2.063V22c0-1.128.936-2.063 2.063-2.063 1.127 0 2.063.935 2.063 2.063v13.75a2.078 2.078 0 01-2.063 2.063zM33.5 46.752c-.358 0-.715-.083-1.045-.22a3.176 3.176 0 01-.907-.578 2.839 2.839 0 01-.578-.907 2.737 2.737 0 01-.22-1.045c0-.358.082-.715.22-1.045.138-.33.33-.633.578-.908.275-.247.577-.44.907-.577a2.75 2.75 0 012.09 0c.33.137.633.33.907.577.248.275.44.578.578.908.137.33.22.687.22 1.045 0 .357-.083.715-.22 1.045-.138.357-.33.632-.578.907-.274.248-.577.44-.907.578-.33.137-.688.22-1.045.22z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
