import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M1 22h22M2 22V6.022C2 4.01 2.902 3 4.696 3h5.617C12.107 3 13 4.01 13 6.022V22M13 22v-9.25c0-1.165.738-1.75 2.206-1.75h4.596C21.27 11 22 11.585 22 12.75V22M4 6h2M4 2h7M4 10h2M4 14h2M9 10h2M9 6h2M9 14h2M16 14h2M16 18h2"
        stroke="#1F71F4"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M6 18a1 1 0 011-1h2a1 1 0 011 1v4H6v-4z" fill="#1F71F4" />
    </Svg>
  );
}

export default SvgComponent;
