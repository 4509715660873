import { Colors, Fonts } from "theme";
import { View, StyleSheet, ViewStyle } from "react-native";
import { AppText } from "components";
import { REQUEST_PAYMENT_STATUS } from "screens/ExpenseRequest/constants";
import i18n from "i18next";
import { FC } from "react";

const BACKGROUND_COLOR = {
  [REQUEST_PAYMENT_STATUS.PAID]: Colors.success0,
  [REQUEST_PAYMENT_STATUS.PENDING]: Colors.primary05,
};
interface RequestPaymentStatusProps {
  status: REQUEST_PAYMENT_STATUS;
  style?: ViewStyle;
}
const RequestPaymentStatus: FC<RequestPaymentStatusProps> = ({ status, style }) => {
  return (
    <View style={[styles.container, { backgroundColor: BACKGROUND_COLOR[status] }, style]}>
      <AppText style={Fonts.BodySmall}>{i18n.t(`common:request_payment_status_${status}`) as string}</AppText>
    </View>
  );
};
export default RequestPaymentStatus;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
  },
});
