// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "./ScreenName";

const prefix = Linking.createURL("/");
const screenNamesConfigLinking = Object.keys(SCREEN_NAME)
  .filter(
    (item) =>
      ![
        SCREEN_NAME.HomeBottomTabNavigator,
        SCREEN_NAME.AddAllowanceScreen,
        SCREEN_NAME.AllocatedExpenseScreen,
      ].includes(item)
  )
  .map((item) => ({
    name: SCREEN_NAME[item],
    config: {
      initialRouteName: SCREEN_NAME[item],
      path: SCREEN_NAME[item],
    },
  }));
const screensLinking = Object.fromEntries(screenNamesConfigLinking.map((obj) => [obj.name, obj.config]));
export const linkingWeb: LinkingOptions = {
  prefixes: ["bizzi-expense://", prefix],
  config: {
    screens: {
      ...screensLinking,
      [SCREEN_NAME.LoginScreen]: {
        initialRouteName: SCREEN_NAME.LoginScreen,
        path: "login",
      },
      [SCREEN_NAME.RegisterOnboardingScreen]: {
        initialRouteName: SCREEN_NAME.RegisterOnboardingScreen,
        path: "register/:token",
        parse: {
          token: String,
        },
      },
      [SCREEN_NAME.RegisterOTPScreen]: {
        initialRouteName: SCREEN_NAME.RegisterOTPScreen,
        path: "register-otp",
      },
      [SCREEN_NAME.RegisterPasswordScreen]: {
        initialRouteName: SCREEN_NAME.RegisterPasswordScreen,
        path: "register-password",
      },
      [SCREEN_NAME.RegisterCompanyScreen]: {
        initialRouteName: "register-company",
        path: "register-company",
      },
      [SCREEN_NAME.RegisterInviteColleagueScreen]: {
        initialRouteName: "register-invite-colleague",
        path: "register-invite-colleague",
      },
      [SCREEN_NAME.RegisterSetupCompanyScreen]: {
        initialRouteName: "register-setup-company",
        path: "register-setup-company",
      },
      [SCREEN_NAME.RegisterInformScreen]: {
        initialRouteName: "register-inform",
        path: "register-inform",
      },
      [SCREEN_NAME.RegisterWarningEnabledExpenseScreen]: {
        initialRouteName: "register-warning",
        path: "register-warning",
      },
      [SCREEN_NAME.VatInfoScreen]: {
        initialRouteName: SCREEN_NAME.VatInfoScreen,
        path: "vat-info",
      },
      [SCREEN_NAME.LoginWithZaloScreen]: {
        initialRouteName: SCREEN_NAME.LoginWithZaloScreen,
        path: "login-with-zalo",
      },
      [SCREEN_NAME.HomeBottomTabNavigator]: {
        screens: {
          [SCREEN_BOTTOM_TAB.Home]: {
            initialRouteName: SCREEN_BOTTOM_TAB.Home,
            path: "home",
          },
          [SCREEN_BOTTOM_TAB.RequestScreen]: {
            initialRouteName: SCREEN_BOTTOM_TAB.RequestScreen,
            path: "expense-request",
          },
          [SCREEN_BOTTOM_TAB.ExpensesScreen]: {
            initialRouteName: SCREEN_BOTTOM_TAB.ExpensesScreen,
            path: "expenses",
          },
          [SCREEN_BOTTOM_TAB.ReportsScreen]: {
            initialRouteName: SCREEN_BOTTOM_TAB.ReportsScreen,
            path: "expense-report",
          },
          [SCREEN_BOTTOM_TAB.CardScreen]: {
            initialRouteName: SCREEN_BOTTOM_TAB.CardScreen,
            path: "card",
          },
        },
      },
    },
  },
};

const linking: LinkingOptions = {
  prefixes: ["bizzi-expense://", prefix],
  config: {
    screens: {
      path: "root",
      [SCREEN_NAME.HomeBottomTabNavigator]: {
        initialRouteName: SCREEN_BOTTOM_TAB.Home,
        path: "bottomBar",
        screens: {
          [SCREEN_BOTTOM_TAB.Home]: SCREEN_BOTTOM_TAB.Home,
          [SCREEN_BOTTOM_TAB.RequestScreen]: {
            path: "expenses/:tabId/:expenseId/:invoiceId",
          },

          [SCREEN_NAME.ReportsScreen]: {
            initialRouteName: "reportsscreen",
            path: "reportsscreen/:expenseReportId/:status",
          },
        },
      },
      [SCREEN_NAME.DetailRequestScreen]: {
        initialRouteName: SCREEN_NAME.DetailRequestScreen,
        path: `expenseRequest/:expenseRequestId`,
      },
      [SCREEN_NAME.ReportDetailScreen]: {
        initialRouteName: SCREEN_NAME.ReportDetailScreen,
        path: `expenseReport/:expenseReportId`,
      },
      [SCREEN_NAME.ExpenseOverviewScreen]: SCREEN_NAME.ExpenseOverviewScreen,
      [SCREEN_NAME.AboutScreen]: SCREEN_NAME.AboutScreen,
      [SCREEN_NAME.CompanyDetailScreen]: SCREEN_NAME.CompanyDetailScreen,
      [SCREEN_NAME.InboxScreen]: SCREEN_NAME.InboxScreen,
      [SCREEN_NAME.NewExpenseScreen]: SCREEN_NAME.NewExpenseScreen,
      [SCREEN_NAME.CameraScreen]: SCREEN_NAME.CameraScreen,
      [SCREEN_NAME.EditExpenseScreen]: SCREEN_NAME.EditExpenseScreen,
      [SCREEN_NAME.OTPScreen]: {
        initialRouteName: "otpscreen",
        path: "otpscreen/:id",
      },
      [SCREEN_NAME.LoginScreen]: {
        initialRouteName: "login",
        path: "login",
      },
      [SCREEN_NAME.LoginOTPScreen]: {
        initialRouteName: "login-otp",
        path: "login-otp",
      },
      [SCREEN_NAME.AccountDeletedScreen]: {
        initialRouteName: "AccountDeleted",
        path: "account-deleted",
      },
      [SCREEN_NAME.NewCompanyScreen]: {
        initialRouteName: "newcompany",
        path: "newcompany",
      },

      [SCREEN_NAME.SuggestionAddressScreen]: {
        initialRouteName: "SuggestionAddressScreen",
        path: "SuggestionAddressScreen",
      },
      [SCREEN_NAME.RegisterOnboardingScreen]: {
        initialRouteName: SCREEN_NAME.RegisterOnboardingScreen,
        path: "register/:token",
        parse: {
          token: String,
        },
      },
      [SCREEN_NAME.RegisterOTPScreen]: {
        initialRouteName: SCREEN_NAME.RegisterOTPScreen,
        path: "register-otp",
      },
      [SCREEN_NAME.RegisterPasswordScreen]: {
        initialRouteName: SCREEN_NAME.RegisterPasswordScreen,
        path: "register-password",
      },
      [SCREEN_NAME.RegisterCompanyScreen]: {
        initialRouteName: "register-company",
        path: "register-company",
      },
      [SCREEN_NAME.RegisterInviteColleagueScreen]: {
        initialRouteName: "register-invite-colleague",
        path: "register-invite-colleague",
      },
      [SCREEN_NAME.RegisterSetupCompanyScreen]: {
        initialRouteName: "register-setup-company",
        path: "register-setup-company",
      },
      [SCREEN_NAME.RegisterInformScreen]: {
        initialRouteName: "register-inform",
        path: "register-inform",
      },
    },
  },

  async getInitialURL() {
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();

    if (url != null) {
      return url;
    }

    // // Check if there is an initial firebase notification
    // const message = await messaging().getInitialNotification();

    // // Get the `url` property from the notification which corresponds to a screen
    // // This property needs to be set on the notification payload when sending it
    // return message?.notification.url;
  },
};

export default linking;
