import config from "app/config";
import * as authClient from "utils/authClient";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";

const originalFetch = fetch;

const fetchInstance = function (url, config) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  return originalFetch
    .apply(self, [url, config])
    .then((res) => res.json())
    .then(async function (data) {
      if (data?.errors?.[0]?.extensions?.code === "invalid-jwt") {
        try {
          const { token, refreshToken } = await authClient.renewToken();
          if (!token || !refreshToken) {
            return {};
          }
          await AsyncStorage.setItem(Keys.token, token);
          await AsyncStorage.setItem(Keys.refreshToken, refreshToken);
          return await fetch(url, {
            ...config,
            headers: { "content-type": "application/json", authorization: `Bearer ${token}` },
          }).then((res) => res.json());
        } catch (e) {
          return {};
        }
      }
      return data;
    });
};
export const request = <T = unknown, E = unknown>(
  query: string,
  variables: Record<string, unknown>,
  token?: string
): Promise<{
  data: T;
  errors: E;
}> => {
  const headers: HeadersInit_ = {
    "content-type": "application/json",
  };
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return fetchInstance(config.HASURA_ENDPOINT, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      query,
      variables,
    }),
    headers,
  });
};
