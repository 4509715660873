import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetTextInputCustom,
  Button,
  CheckBox,
  FloatFooter,
  IconCustom,
  KeyboardAwareScrollViewCustom,
  Line,
  useWindowDimensions,
} from "components";
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState, Fragment } from "react";
import { Keyboard, StyleSheet, TouchableOpacity, View, Platform } from "react-native";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { BizziBootInfoSuccess } from "assets/images/svg/icons";
import { useTranslation } from "react-i18next";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import dayjs from "dayjs";
import { FeedbackConfig } from "contexts/InAppFeedbackContext";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";

const STARS = [
  { value: 1, title: "1_star" },
  { value: 2, title: "2_star" },
  { value: 3, title: "3_star" },
  { value: 4, title: "4_star" },
  { value: 5, title: "5_star" },
];
const InAppFeedbackModal = forwardRef<BottomSheetModalCustomMethods>((_, ref) => {
  useImperativeHandle(ref, () => ({
    present: (config: FeedbackConfig) => {
      setContent("");
      setIsExpand(false);
      setRatingValue(undefined);
      setFeedbackConfig(config);
      setImproveDescriptionSelect([]);
      isSubmitRating.current = false;
      bottomSheetRef?.current?.present?.();
    },
  }));
  const { t } = useTranslation("app/components/InAppFeedbackModal");
  const [feedbackConfig, setFeedbackConfig] = useState<FeedbackConfig>(null);
  const [isExpand, setIsExpand] = useState(false);
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
  const alertRef = useRef<AlertNotificationHandle>();
  const [ratingValue, setRatingValue] = useState<(typeof STARS)[0]>();

  const [improveDescriptionSelect, setImproveDescriptionSelect] = useState([]);
  const [content, setContent] = useState("");
  const isSubmitRating = useRef(false);

  const handleSelectRating = (value) => () => {
    setImproveDescriptionSelect([]);
    setRatingValue(value);
    setIsExpand(true);
    bottomSheetRef?.current?.expand?.();
  };

  const handleSelectImproveDescription = (value) => () => {
    if (improveDescriptionSelect.includes(value)) {
      setImproveDescriptionSelect((prevState) => prevState.filter((item) => item !== value));
    } else {
      setImproveDescriptionSelect((prevState) => prevState.concat(value));
    }
  };
  const handleSubmitRating = (hiddenSuccessMessage) => {
    Keyboard.dismiss();
    isSubmitRating.current = true;
    analyticService.logEvent({
      name: EVENT.OTHER.RATING_INTERNAL_SUCCESS,
      properties: {
        rating: ratingValue?.value,
        content,
        need_improve_tags: improveDescriptionSelect?.map((item) => t(item)),
      },
    });
    bottomSheetRef?.current?.close();
    AsyncStorage.setItem(Keys.LAST_SUBMIT_DATE_RATING_INTERNAL, dayjs().toISOString());
    if (!hiddenSuccessMessage) {
      setTimeout(() => {
        alertRef.current.success({
          title: t("rating_title_success"),
          description: t("rating_description_success"),
          confirmText: t("close"),
          icon: <BizziBootInfoSuccess />,
        });
      }, 200);
    }
  };
  const { width } = useWindowDimensions();

  /*-- when user is selected star and not click submit button -> always send event --*/
  const onDismiss = () => {
    if (!isSubmitRating.current && ratingValue?.value) {
      handleSubmitRating(true);
    }
  };
  const renderFooterButton = () => {
    if (isExpand) {
      return (
        <FloatFooter enableShadow={false} style={[styles.footerButton, { width }]}>
          <Button onPress={() => handleSubmitRating(false)} disabled={!ratingValue?.value}>
            {t("send_feedback")}
          </Button>
        </FloatFooter>
      );
    }
    return null;
  };
  const configuration =
    i18n.language === LanguageStatus.EN ? feedbackConfig?.configurations?.en : feedbackConfig?.configurations?.vi;
  const feedback = useMemo(() => {
    return configuration?.feedbacks?.find((item) => item.stars.includes(ratingValue?.value));
  }, [ratingValue?.value]);
  const renderContent = () => (
    <>
      <View style={styles.starRatingContainer}>
        <AppText style={[Fonts.BodyMedium, styles.textCenter]}>{configuration?.message}</AppText>
        <View style={styles.starContainer}>
          {STARS.map((item) => {
            const isActive = item.value <= ratingValue?.value;
            return (
              <TouchableOpacity
                onPress={handleSelectRating(item)}
                style={[styles.starIcon, { backgroundColor: isActive ? Colors.warning0 : Colors.grayscale05 }]}
                key={item.value}
              >
                <IconCustom name="kid-star" fill={isActive ? Colors.warning50 : Colors.grayscale60} />
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
      {isExpand && (
        <View>
          <AppText style={styles.improveTitle} color={Colors.primary50}>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(ratingValue?.title)
            }
          </AppText>
          <Line containerStyle={[styles.divider, { width }]} />
          <AppText style={Fonts.H200}>{feedback?.title}</AppText>
          <View style={styles.improveDescriptionContainer}>
            {feedback?.options?.map((item, index) => {
              const isActive = improveDescriptionSelect.includes(item);
              const isLastItem = index === feedback?.options?.length - 1;
              return (
                <Fragment key={item}>
                  <TouchableOpacity
                    style={styles.improveDescriptionItem}
                    onPress={handleSelectImproveDescription(item)}
                  >
                    <AppText style={[Fonts.BodyMedium, styles.flex]}>{item}</AppText>
                    <CheckBox checked={Boolean(isActive)} />
                  </TouchableOpacity>
                  {!isLastItem && <View style={styles.dividerLine} />}
                </Fragment>
              );
            })}
          </View>
          <BottomSheetTextInputCustom
            isBottomSheetInput={false}
            style={{ marginTop: 10 }}
            placeholder={t("feedback_content")}
            value={content}
            maxLength={500}
            onChangeText={setContent}
          />
        </View>
      )}
    </>
  );
  return (
    <>
      <BottomSheetScrollViewModalCustom
        snapPoints={[226, CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={configuration?.title}
        ref={bottomSheetRef}
        renderFooter={renderFooterButton}
        wrapperByScrollView={Platform.OS === "web"}
        enableContentPanningGesture={false}
        onDismiss={onDismiss}
      >
        {Platform.OS === "web" ? (
          <View style={styles.body}>{renderContent()}</View>
        ) : (
          <KeyboardAwareScrollViewCustom
            extraHeight={130}
            enableResetScrollToCoords={true}
            bounces={false}
            contentContainerStyle={[styles.body]}
          >
            {renderContent()}
          </KeyboardAwareScrollViewCustom>
        )}
      </BottomSheetScrollViewModalCustom>
      <AlertNotification ref={alertRef} />
    </>
  );
});
export default InAppFeedbackModal;
const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 80,
  },
  improveTitle: {
    ...Fonts.BodyMedium,
    textAlign: "center",
    marginBottom: 5,
  },
  starIcon: {
    width: 44,
    height: 44,
    backgroundColor: Colors.warning0,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  starContainer: { flexDirection: "row", marginVertical: 10, justifyContent: "space-between" },
  divider: { marginVertical: 14, marginLeft: -CONSTANTS.COMMON.CONTAINER_PADDING },
  improveDescriptionContainer: { marginTop: 10 },
  improveDescriptionItem: {
    paddingVertical: 16,
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    justifyContent: "space-between",
  },
  footerButton: { marginTop: 10, position: "absolute", alignSelf: "center" },
  starRatingContainer: { width: 252, alignSelf: "center" },
  textCenter: { textAlign: "center" },
  flex: { flex: 1 },
  dividerLine: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
