import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 32, height = 32, color = "#FFFFFF" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <Path
        d="M16 2.668c-7.347 0-13.334 5.987-13.334 13.333 0 7.347 5.987 13.334 13.333 13.334 7.347 0 13.334-5.987 13.334-13.334 0-7.346-5.987-13.333-13.334-13.333zm5.333 14.333h-4.334v4.334c0 .546-.453 1-1 1-.546 0-1-.454-1-1V17h-4.333c-.547 0-1-.453-1-1 0-.546.453-1 1-1h4.333v-4.333c0-.547.454-1 1-1 .547 0 1 .453 1 1v4.333h4.334c.546 0 1 .454 1 1 0 .547-.454 1-1 1z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
