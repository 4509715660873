import { gql, useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { ExpMobileInsightsQuery, ExpMobileInsightsQueryVariables } from "types";

const PERSONAL_EXPENSE_INSIGHTS = gql`
  query ExpMobileInsights($companyId: UUID!, $employeeId: UUID!, $month: Int!) {
    expMobileInsights(input: { companyId: $companyId, employeeId: $employeeId }) {
      monthlyApprovedExpenses(months: $month) {
        month
        totalAmount
        diffPercentage
      }
      unclaimExpense {
        totalAmount
        totalExpenses
      }
      waitingApprovalReport {
        totalAmount
        totalReports
      }
      waitingApprovalRequest {
        totalAmount
        totalRequests
      }
    }
  }
`;

const usePersonalExpenseInsights = ({ month }: Pick<ExpMobileInsightsQueryVariables, "month">) => {
  const { company, user } = useAuth();

  const { data, ...rest } = useQuery<ExpMobileInsightsQuery, ExpMobileInsightsQueryVariables>(
    PERSONAL_EXPENSE_INSIGHTS,
    {
      variables: {
        companyId: company?.company_id,
        employeeId: user?.employee?.employee_id,
        month,
      },
      skip: !company?.company_id || !user?.employee?.employee_id,
    }
  );
  return {
    data: data?.expMobileInsights ?? ({} as ExpMobileInsightsQuery["expMobileInsights"]),
    ...rest,
  };
};

export default usePersonalExpenseInsights;
