import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileOrgTeamsQuery, MobileOrgTeamsQueryVariables } from "types";
import { useAuth } from "contexts/AuthContext";

const QUERY = gql`
  query MobileOrgTeams($where: OrgTeamWhere!) {
    orgTeams(where: $where, limit: 100) {
      data {
        teamId
        code
        name
        nameEn
        teamType {
          teamTypeId
          name
          nameEn
        }
      }
    }
  }
`;

const useOrgTeamsQuery = (options?: QueryHookOptions<MobileOrgTeamsQuery, MobileOrgTeamsQueryVariables>) => {
  const { company } = useAuth();

  return useQuery<MobileOrgTeamsQuery, MobileOrgTeamsQueryVariables>(QUERY, {
    variables: { where: { companyId: company.company_id } },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export default useOrgTeamsQuery;
