export default class ToastManager {
  static refToastMessage;
  static setRefToastMessage(ref) {
    this.refToastMessage = ref;
  }
  static success(title: string) {
    this.refToastMessage?.success?.(title);
  }
  static error(title: string) {
    this.refToastMessage?.error?.(title);
  }
}
