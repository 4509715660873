import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpCardEnabledQuery, MobileExpCardEnabledQueryVariables } from "types";
import { useAuth } from "contexts/AuthContext";

const QUERY = gql`
  query MobileExpCardEnabled($companyId: UUID!) {
    expCardEnabled(input: { companyId: $companyId }) {
      enabled
    }
  }
`;

const useExpCardEnabledQuery = () => {
  const {
    company: { company_id: companyId },
  } = useAuth();
  return useQuery<MobileExpCardEnabledQuery, MobileExpCardEnabledQueryVariables>(QUERY, {
    variables: { companyId },
    skip: !companyId,
    fetchPolicy: "cache-and-network",
  });
};

export default useExpCardEnabledQuery;
