import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { startLayoutAnimation } from "utils";

const CollapsibleViewMenu = ({ header, rightComponent, children, divider, isExpandedByDefault = false }) => {
  const [showContent, setShowContent] = useState(isExpandedByDefault);
  const animationController = useSharedValue(isExpandedByDefault ? 1 : 0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${interpolate(animationController?.value, [0, 1], [0, 90], Extrapolate.CLAMP)}deg`,
        },
      ],
    };
  }, [animationController.value]);
  const handleClick = () => {
    animationController.value = withTiming(showContent ? 0 : 1, {
      duration: 150,
      easing: Easing.linear,
    });
    startLayoutAnimation();
    setShowContent((prevState) => !prevState);
  };
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleClick} style={styles.header}>
        {header}
        <Animated.View style={animatedStyle}>{rightComponent}</Animated.View>
      </TouchableOpacity>
      {divider}
      {showContent && <View>{children}</View>}
    </View>
  );
};
export default CollapsibleViewMenu;
const styles = StyleSheet.create({
  container: { overflow: "hidden" },
  header: { flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
});
