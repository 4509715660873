// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useState } from "react";
import { Platform, Keyboard, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import { ActivityIndicator, Toast } from "@ant-design/react-native";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { AppText, Button, TextInput } from "components";
import { ForgotPasswordScreenNavigationProp } from "navigation/type";
import SCREEN_NAME from "navigation/ScreenName";
import { useAuth } from "contexts/AuthContext";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { BackButtonIcon, CircleLoginIcon } from "assets/images/svg/icons";
import { EVENT } from "constants/Tracking";
import { goBack } from "navigation/RootNavigation";

const ID_SEND_EMAIL_OTP = gql`
  mutation MobileSendEmailOtp($email: String!, $required_existence: Boolean!) {
    idSendEmailOtp(email: $email, required_existence: $required_existence) {
      success
      message
    }
  }
`;

const initialValues = { email: "", general: "" };

interface IProps {
  navigation: ForgotPasswordScreenNavigationProp;
}

const ForgotPasswordScreen: React.FC<IProps> = ({ navigation }) => {
  const { forgetPassword } = useAuth();
  const [idSendEmailOtp] = useMutation(ID_SEND_EMAIL_OTP);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("screens/Auth/ForgotPasswordScreen");
  const yupSchema = Yup.object().shape({
    email: Yup.string().email().required(t("required")),
  });
  const onRequestOTP = useCallback(
    async ({ email }) => {
      setLoading(true);
      try {
        const result = await idSendEmailOtp({ variables: { email, required_existence: true } });
        if (result?.data?.idSendEmailOtp?.success) {
          navigation.navigate(SCREEN_NAME.OTPScreen, { email, next: SCREEN_NAME.NewPasswordScreen });
        } else {
          const message = result?.data?.idSendEmailOtp?.message ?? t("server_error_description");

          Toast.fail(t(message));
        }
      } catch (error) {
        Toast.fail(error?.message ?? t("server_error_description"));
      }

      setLoading(false);
    },
    [idSendEmailOtp, navigation, t]
  );

  const onResetPassword = useCallback(
    async ({ email }) => {
      try {
        setIsLoading(true);
        const result: any = await forgetPassword(email);

        if (result?.sendResetPasswordToken?.message === "OK") {
          navigation.navigate(SCREEN_NAME.ForgotPasswordSuccessScreen, { email });
        } else {
          const message = result?.sendResetPasswordToken?.errors
            ? t(`${result?.sendResetPasswordToken?.errors}`)
            : t("server_error_description");

          Toast.fail(message);
        }
      } catch (error) {
        Toast.fail(t(`${error?.message}`) ?? t("server_error_description"));
      }
      setIsLoading(false);
    },
    [forgetPassword, t, navigation]
  );

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        onResetPassword(values);
      } catch (error) {
        actions.setStatus({ general: error.message });
      }

      actions.setSubmitting(false);
    },
    [onResetPassword]
  );

  const renderForm = () => {
    return (
      <View style={styles.container}>
        <View style={styles.circleImage}>
          <CircleLoginIcon />
        </View>
        <View style={styles.body}>
          <TouchableOpacity style={styles.buttonBack} onPress={goBack}>
            <BackButtonIcon />
          </TouchableOpacity>
          <AppText style={[Fonts.H400, styles.title]}>{t("description_email")}</AppText>
          <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>
            {t("forgot_password_description")}
          </AppText>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit} validationSchema={yupSchema}>
            {({ values, touched, isValid, dirty, status, handleChange, handleSubmit }) => {
              return (
                <View style={styles.formContainer}>
                  <TextInput
                    label={t("email")}
                    placeholder={t("email")}
                    keyboardType="email-address"
                    textContentType="emailAddress"
                    autoCapitalize="none"
                    autoCorrect={false}
                    touched={touched.email}
                    value={values.email}
                    autoFocus
                    onChangeText={handleChange("email")}
                    onSubmitEditing={handleSubmit}
                  />
                  <View style={styles.button}>
                    <AppText style={styles.error}>{status?.general}</AppText>
                    <Button
                      eventName={EVENT.AUTH.REQUEST_OTP_FORGOT_PASSWORD}
                      disabled={!(dirty && isValid)}
                      onPress={handleSubmit}
                    >
                      {t("send_code")}
                    </Button>
                  </View>
                </View>
              );
            }}
          </Formik>
        </View>
        <ActivityIndicator animating={isLoading} toast size="large" />
      </View>
    );
  };

  return (
    <TouchableWithoutFeedback onPress={() => Platform.OS !== "web" && Keyboard.dismiss()}>
      {renderForm()}
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  body: { marginTop: 80 },
  circleImage: { position: "absolute", top: 0, right: 0 },
  title: { marginBottom: 4 },
  buttonBack: { marginBottom: 20 },
  formContainer: { marginTop: 35 },

  button: { marginTop: 30 },
  error: {
    fontSize: 14,
    color: Colors.alert50,
  },
});

export default ForgotPasswordScreen;
