// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import dayjs, { Dayjs } from "dayjs";
import { InputField, PaperInput } from "app/components";

import NotesIcon from "app/assets/images/svg/icons/NotesIcon";
import { useTranslation } from "react-i18next";

interface IDatetimePickerField {
  style?: StyleProp<ViewStyle>;
  label?: string;
  value?: Dayjs;
  onChange: (date: any) => void;
  minimumDate: Date | undefined;
  maximumDate: Date | undefined;
  isCustomInput?: boolean;
  noUnderLine?: boolean;
}

const DatetimePickerField = (props: IDatetimePickerField) => {
  const { t } = useTranslation("layouts/DatetimePickerField");
  const { style, label, value, onChange, minimumDate, maximumDate, isCustomInput, noUnderLine } = props;
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState(value ? dayjs(value, "DD/MM/YYYY").toDate() : dayjs().toDate());

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  const show = useCallback(() => {
    setVisible(true);
  }, []);
  1;

  const CustomInput = isCustomInput ? PaperInput : InputField;

  const onChangeDate = useCallback(
    (event, selectedDate) => {
      if (Platform.OS === "ios") {
        onChange(selectedDate);
        setDate(selectedDate);
      } else {
        hide();
        onChange(selectedDate);
        setDate(selectedDate);
      }
    },
    [onChange, setDate, hide]
  );

  useEffect(() => {
    if (value) {
      setDate(dayjs(value, "DD/MM/YYYY").toDate());
    }
  }, [value]);

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity onPress={show}>
        <CustomInput
          pointerEvents="none"
          label={label}
          editable={false}
          placeholder={label}
          value={date ? dayjs(date).format("DD/MM/YYYY") : ""}
          leftImage={<NotesIcon />}
          onPress={show}
          noUnderLine={noUnderLine}
        />
      </TouchableOpacity>

      {/* {visible && Platform.OS === "web" && (
        <DatetimePicker
          value={date}
          minimumDate={minimumDate}
          mode="date"
          is24Hour
          onChange={(event, selectedDate) => {
            if (event.type === "dismissed") {
              hide();
            } else {
              onChangeDate(event, selectedDate);
            }
          }}
          pointerEvents="none"
        />
      )} */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default DatetimePickerField;
