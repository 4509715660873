import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={20} viewBox="0 0 16 16" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={16} height={16}>
        <Path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6.965 10.391l3.332-2.133A.295.295 0 0010.434 8a.295.295 0 00-.137-.258L6.965 5.61a.276.276 0 00-.31-.017.287.287 0 00-.156.268v4.28c0 .12.052.21.156.268a.276.276 0 00.31-.017zM2.87 13c-.337 0-.622-.117-.855-.35a1.163 1.163 0 01-.35-.855v-7.59c0-.337.117-.622.35-.855.233-.233.518-.35.855-.35h10.256c.337 0 .622.117.856.35.233.233.35.518.35.855v7.59c0 .337-.117.622-.35.855-.234.233-.519.35-.856.35H2.871zm0-1h10.256a.196.196 0 00.141-.064.195.195 0 00.065-.141v-7.59a.196.196 0 00-.065-.14.196.196 0 00-.14-.065H2.87a.196.196 0 00-.14.064.196.196 0 00-.065.141v7.59c0 .051.021.098.064.14.043.044.09.065.141.065z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
