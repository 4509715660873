import * as React from "react";
import Svg, { Path, Rect, Ellipse, G, Mask, Defs, LinearGradient, Stop } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent() {
  return (
    <Svg width={273} height={123} viewBox="0 0 273 123" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.1 12.872C90.1 5.763 95.861 0 102.97 0h157.156C267.236 0 273 5.763 273 12.872c0 7.11-5.763 12.872-12.873 12.872H201.96a12.849 12.849 0 014.781 10.012c0 7.053-5.673 12.78-12.704 12.871h36.945c7.109 0 12.872 5.763 12.872 12.872 0 7.11-5.763 12.872-12.872 12.872H77.405c7.035-.086 12.712-5.815 12.712-12.871 0-7.109-5.763-12.872-12.872-12.872H36.66c-7.085-.028-12.82-5.78-12.82-12.872 0-7.11 5.762-12.872 12.871-12.872H94.88a12.847 12.847 0 01-4.78-10.012zM0 87.245c0-7.11 5.763-12.872 12.872-12.872h183.664c.633 0 1.255.046 1.864.134h-7.059c-6.309 0-11.424 5.115-11.424 11.424 0 6.31 5.115 11.425 11.424 11.425h22.702c6.349.792 11.262 6.208 11.262 12.772 0 7.109-5.763 12.872-12.872 12.872H73.832c-7.11 0-12.873-5.763-12.873-12.872 0-4.043 1.865-7.651 4.78-10.011H12.872C5.763 100.117 0 94.354 0 87.245z"
        fill="#F0F6FF"
      />
      <Rect x={217.148} y={94} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
      <Rect x={217.148} y={104.416} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={219.773}
        y={104.416}
        width={2.11731}
        height={7.84902}
        rx={1.05865}
        transform="rotate(-90 219.773 104.416)"
        fill="#75A4FE"
      />
      <Rect
        x={208}
        y={104.416}
        width={2.11731}
        height={7.84902}
        rx={1.05865}
        transform="rotate(-90 208 104.416)"
        fill="#75A4FE"
      />
      <Path
        d="M218.488 50.72c0 2.6-2.118 4.714-4.74 4.714-2.622 0-4.74-2.114-4.74-4.713s2.118-4.713 4.74-4.713c2.622 0 4.74 2.114 4.74 4.713z"
        fill="#fff"
        stroke="#1F71F4"
        strokeWidth={2.01559}
      />
      <Ellipse cx={59.2546} cy={87.2346} rx={4.25457} ry={4.23462} fill="#75A4FE" />
      <Rect x={71.4453} y={2} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
      <Rect x={71.5781} y={11.9629} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={73.5742}
        y={11.5273}
        width={2.11731}
        height={7.44551}
        rx={1.05865}
        transform="rotate(-90 73.574 11.527)"
        fill="#75A4FE"
      />
      <Path d="M65.059 11.963a1.059 1.059 0 010-2.117h5.328a1.059 1.059 0 010 2.117h-5.328z" fill="#75A4FE" />
      <G filter="url(#filter0_d_6370_118343)">
        <Rect
          x={105.102}
          y={35.3125}
          width={53.3579}
          height={69.7757}
          rx={4.49826}
          fill="url(#paint0_linear_6370_118343)"
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.419 35.313h-9.339a3.08 3.08 0 00-3.08 3.08v63.615a3.08 3.08 0 003.08 3.08h47.198a3.08 3.08 0 003.08-3.08V82.222c-23.042-2.595-40.95-22.148-40.95-45.883 0-.343.003-.685.011-1.026z"
        fill="#DCEBFE"
        fillOpacity={0.5}
      />
      <Path
        d="M114.341 32.234h-8.209a4.105 4.105 0 00-4.105 4.105v67.723a4.105 4.105 0 004.105 4.105h52.332a4.105 4.105 0 004.104-4.105V36.339a4.105 4.105 0 00-4.104-4.105h-6.968"
        stroke="#1F71F4"
        strokeWidth={3}
        strokeLinecap="round"
      />
      <Mask id="a" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.414 29.157c0-3.4-2.953-6.157-6.596-6.157-3.643 0-6.597 2.756-6.597 6.157h-6.747a2.052 2.052 0 00-2.052 2.052v2.052a4.105 4.105 0 004.104 4.105h22.575a4.104 4.104 0 004.104-4.105V31.21a2.052 2.052 0 00-2.052-2.052h-6.739z"
        />
      </Mask>
      <Path
        d="M139.414 29.157h-3v3h3v-3zm-13.193 0v3h3v-3h-3zm10.193 0h6-6zM132.818 26c2.184 0 3.596 1.605 3.596 3.157h6c0-5.249-4.495-9.157-9.596-9.157v6zm-3.597 3.157c0-1.552 1.412-3.157 3.597-3.157v-6c-5.102 0-9.597 3.908-9.597 9.157h6zm0 0h-6 6zm-9.747 3h6.747v-6h-6.747v6zm.948-.948a.948.948 0 01-.948.948v-6a5.052 5.052 0 00-5.052 5.052h6zm0 2.052V31.21h-6v2.052h6zm1.104 1.105c-.61 0-1.104-.495-1.104-1.105h-6a7.105 7.105 0 007.104 7.105v-6zm22.575 0h-22.575v6h22.575v-6zm1.104-1.105c0 .61-.494 1.105-1.104 1.105v6a7.104 7.104 0 007.104-7.105h-6zm0-2.052v2.052h6V31.21h-6zm.948.948a.948.948 0 01-.948-.948h6a5.052 5.052 0 00-5.052-5.052v6zm-6.739 0h6.739v-6h-6.739v6z"
        fill="#1F71F4"
        mask="url(#a)"
      />
      <Rect
        x={112.28}
        y={46.6003}
        width={6.15668}
        height={6.15668}
        rx={1.02611}
        stroke="#60A5FA"
        strokeWidth={2.05223}
      />
      <Rect x={121.516} y={48.6523} width={28.7312} height={2.05223} rx={1.02611} fill="#60A5FA" />
      <Rect
        x={112.28}
        y={58.9128}
        width={6.15668}
        height={6.15668}
        rx={1.02611}
        stroke="#60A5FA"
        strokeWidth={2.05223}
      />
      <Rect x={121.516} y={60.9648} width={28.7312} height={2.05223} rx={1.02611} fill="#60A5FA" />
      <Rect
        x={112.28}
        y={71.2273}
        width={6.15668}
        height={6.15668}
        rx={1.02611}
        stroke="#60A5FA"
        strokeWidth={2.05223}
      />
      <Rect x={121.516} y={73.2793} width={28.7312} height={2.05223} rx={1.02611} fill="#60A5FA" />
      <Rect
        x={112.28}
        y={83.5398}
        width={6.15668}
        height={6.15668}
        rx={1.02611}
        stroke="#60A5FA"
        strokeWidth={2.05223}
      />
      <Rect x={121.516} y={85.5918} width={28.7312} height={2.05223} rx={1.02611} fill="#60A5FA" />
      <Defs>
        <LinearGradient
          id="paint0_linear_6370_118343"
          x1={132.369}
          y1={105.612}
          x2={133.61}
          y2={23.7945}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#fff" />
          <Stop offset={0.479167} stopColor="#fff" />
          <Stop offset={1} stopColor="#FCFDFF" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
