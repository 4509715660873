import React, { FC, useRef } from "react";
import { ActivityIndicator, Platform, SectionList, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";

import { AppText, CurrencyText, EmptyData, IconCustom, SearchInput, SkeletonListLoading } from "components";
import { Colors, Fonts } from "theme";
import { TransactionEmpty, TransactionIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { TimeFilterData, TYPE_TIME_FILTER } from "screens/Card/TransactionHistoryListScreen/types";
import { BottomSheetSectionList } from "@gorhom/bottom-sheet";
import TransactionStatus from "screens/Card/TransactionHistoryListScreen/components/TransactionStatus";
import { MobileCrdSuggestAssignableExpenseCardTransactionsQuery } from "types";

interface TransactionListViewProps {
  data: { title: string; data: any[] }[];
  suggestCardTransaction?: MobileCrdSuggestAssignableExpenseCardTransactionsQuery["crdSuggestAssignableExpenseCardTransactions"]["transactions"][0];
  startDate: Dayjs;
  endDate: Dayjs;
  handleClickFilter: () => void;
  textSearch: string;
  handleLoadMore: () => void;
  onChangeSearch: (text: string) => void;
  currentFilter: TimeFilterData;
  handleClearSearch: () => void;
  loading: boolean;
  loadingMore: boolean;
  handleSelectTransaction: (item) => void;
  currentValue: string;
  total?: number;
}
const TransactionListView: FC<TransactionListViewProps> = ({
  data,
  suggestCardTransaction,
  handleClickFilter,
  handleLoadMore,
  textSearch,
  startDate,
  endDate,
  onChangeSearch,
  handleClearSearch,
  currentFilter,
  loading,
  loadingMore,
  handleSelectTransaction,
  currentValue,
  total,
}) => {
  const { t } = useTranslation("app/screens/Expense/components/SelectTransactionModal");
  const textSearchRef = useRef<TextInput>();
  const onSelectTransaction = (item) => () => {
    handleSelectTransaction(item);
  };
  const renderItem = ({ item, isSuggest }) => {
    const isActive = item?.cardTransactionId === currentValue;
    if (!isSuggest && item?.cardTransactionId === suggestCardTransaction?.cardTransactionId) {
      return null;
    }
    return (
      <>
        <TouchableOpacity
          style={[
            styles.transactionItem,
            isActive && { backgroundColor: Colors.primary0 },
            isSuggest && { paddingTop: 0 },
          ]}
          onPress={onSelectTransaction(item)}
        >
          <View style={styles.leftIcon}>
            <TransactionIcon />
          </View>
          <View style={styles.flex}>
            {isSuggest && (
              <View style={styles.suggestContainer}>
                <IconCustom name="star-custom" />
                <AppText style={Fonts.BodySmall} color={Colors.primary50}>
                  {t("is_suggested")}
                </AppText>
              </View>
            )}
            <View style={styles.headerRow}>
              <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={3}>
                {item?.location}
              </AppText>
              <CurrencyText style={Fonts.H200}>{Math.abs(item?.amount)}</CurrencyText>
            </View>
            <View style={styles.transactionStatusContainer}>
              <TransactionStatus isCreatedExpense={item?.cardTransactionExpenses?.length} />
              {isSuggest && (
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {dayjs(item?.transactionDate).format("DD/MM/YYYY")}
                </AppText>
              )}
            </View>
          </View>
        </TouchableOpacity>
        {!isSuggest && <View style={[styles.divider, item?.isLastItem && { marginBottom: 20 }]} />}
      </>
    );
  };

  const renderEmpty = () => {
    if (loading) {
      return null;
    }
    return <EmptyData icon={<TransactionEmpty />} title={t("empty_transaction")} />;
  };

  const renderFooter = () => {
    if (loadingMore) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const renderSectionHeader = ({ section: { title, data } }) => {
    // in the case section only one item and this item is suggest item --> no render this item
    if (data?.length === 1 && data[0]?.cardTransactionId === suggestCardTransaction?.cardTransactionId) {
      return null;
    }
    return (
      <View style={styles.sectionHeader}>
        <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
          {title}
        </AppText>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <View style={styles.filterContainer}>
        <SearchInput
          ref={textSearchRef}
          placeholder={t("search_transaction")}
          onChangeText={onChangeSearch}
          right={
            textSearch ? (
              <TouchableOpacity
                onPress={() => {
                  textSearchRef?.current?.clear();
                  handleClearSearch();
                }}
              >
                <IconCustom name="cancel" />
              </TouchableOpacity>
            ) : null
          }
        />
        <View style={styles.filterHeader}>
          <AppText numberOfLines={1} style={Fonts.H200}>
            {total > 1 ? t("transactions", { value: total }) : t("transaction", { value: total })}
          </AppText>
          <View style={styles.flex}>
            <TouchableOpacity style={styles.buttonFilter} onPress={handleClickFilter}>
              <AppText style={Fonts.BodyMedium} color={Colors.grayscale80} numberOfLines={1}>
                {currentFilter?.value === TYPE_TIME_FILTER.CUSTOM
                  ? `${startDate.format(CONSTANTS.FORMAT_DAY)} - ${endDate.format(CONSTANTS.FORMAT_DAY)}`
                  : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    t(currentFilter?.title)}
              </AppText>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      {loading && !loadingMore ? (
        <SkeletonListLoading />
      ) : Platform.OS === "web" ? (
        <SectionList
          ListHeaderComponent={() =>
            suggestCardTransaction ? renderItem({ item: suggestCardTransaction, isSuggest: true }) : null
          }
          keyExtractor={(item, index) => item?.transactionId ?? index}
          sections={data}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
          renderSectionHeader={renderSectionHeader}
          stickySectionHeadersEnabled
        />
      ) : (
        <BottomSheetSectionList
          ListHeaderComponent={() =>
            suggestCardTransaction ? renderItem({ item: suggestCardTransaction, isSuggest: true }) : null
          }
          keyExtractor={(item, index) => item?.transactionId ?? index}
          sections={data}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
          renderSectionHeader={renderSectionHeader}
          stickySectionHeadersEnabled
        />
      )}
    </View>
  );
};
export default TransactionListView;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  filterHeader: { flexDirection: "row", marginTop: 12, alignItems: "center", paddingBottom: 7 },
  filterContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  buttonFilter: {
    paddingVertical: 6,
    alignSelf: "flex-start",
    paddingHorizontal: 12,
    borderRadius: 20,
    backgroundColor: Colors.grayscale05,
    marginLeft: 12,
  },
  listContainer: { paddingBottom: 80, paddingTop: 10 },
  loadingMore: { marginTop: 10 },
  flex: { flex: 1 },
  sectionHeader: {
    backgroundColor: Colors.white,
    marginTop: 0,
    paddingBottom: 3,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  transactionItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  leftIcon: { marginRight: 16 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 66,
  },
  headerRow: { flexDirection: "row", justifyContent: "space-between", gap: 10, marginBottom: 4 },
  transactionStatusContainer: { flexDirection: "row", justifyContent: "space-between" },
  suggestContainer: { flexDirection: "row", alignItems: "center", gap: 4, marginBottom: 4 },
});
