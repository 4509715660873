import { AppText, CopilotStepCustom, CurrencyText, LoadingView } from "components";
import { ScrollView, StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { FC, useMemo } from "react";
import { CONSTANTS } from "constants/constants";
import { EmptyPolicy } from "assets/images/svg/icons";
import { useTranslation } from "react-i18next";
import { getTitleI18n } from "utils";
import { CustomGroupedTable } from "components/Table/CustomTable";
import { MobileExpEmployeeExpensePolicyTravelQuery } from "types";
import { BizziBotSticker02 } from "assets/images";
import { useCopilot } from "react-native-copilot";
import { goBack } from "navigation/RootNavigation";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";

type PerdiemPolicyProps = {
  loading: boolean;
  perdiem: MobileExpEmployeeExpensePolicyTravelQuery["expEmployeeExpensePolicyTravel"]["perdiem"];
  shouldShowWalkthrough?: boolean;
};

const PerdiemPolicy: FC<PerdiemPolicyProps> = ({ perdiem, loading, shouldShowWalkthrough = false }) => {
  const { t } = useTranslation("app/screens/PolicyEmployee/PolicyEmployeeScreen");
  const { stop, start } = useCopilot();
  const { handleFinishGuideAction } = useWalkthroughGuide();

  const transformedData = useMemo(() => {
    const expensePolicyPerDiems = perdiem?.expensePolicyPerDiems;
    if (!expensePolicyPerDiems) {
      return { domestic: [], overseas: [], all: [] };
    }

    const data = expensePolicyPerDiems.reduce(
      (acc, perdiem, index, perdiems) => {
        const { scope, order, location, expenseCategory, amount } = perdiem;

        const key = !expenseCategory?.expenseCategoryId ? `${amount}` : `${order}`;
        if (!acc[scope][key]) {
          acc[scope][key] = {
            order: !location ? perdiems.length : order ?? index,
            titles: {},
            ts: {},
            categories: {},
          };
        }

        const title = location
          ? getTitleI18n({ vi: location.name, en: location.nameEn })
          : {
              domestic: t("other_domestic_locations"),
              overseas: t("other_international_locations"),
              all: t("all_locations"),
            }[scope];
        acc[scope][key].titles[title] = title;
        acc[scope][key].categories[expenseCategory?.expenseCategoryId ?? "no_category"] = {
          name: expenseCategory
            ? getTitleI18n({
                vi: expenseCategory?.title,
                en: expenseCategory?.titleEn,
              })
            : t("not_set_up_category"),
          amount,
        };

        return acc;
      },
      { domestic: {}, overseas: {}, all: {} }
    );

    const transform = (object) =>
      Object.values(object)
        .map((x: any) => ({
          order: x.order,
          title: Object.values(x.titles).join(", "),
          data: Object.values(x.categories),
        }))
        .sort((a, b) => a.order - b.order);

    const rs = {
      domestic: transform(data.domestic),
      overseas: transform(data.overseas),
      all: transform(data.all),
    };
    return rs;
  }, [perdiem, t]);
  if (loading && !shouldShowWalkthrough) {
    return <LoadingView style={styles.loadingView} />;
  }

  if ((!perdiem || !perdiem.isEnabled) && !shouldShowWalkthrough) {
    return (
      <View style={styles.emptyContainer}>
        <EmptyPolicy />
        <View style={[styles.emptyContent, styles.gap8]}>
          <AppText style={[Fonts.H400, styles.textCenter]} color={Colors.grayscale100}>
            {t("empty_perdiem_policy")}
          </AppText>
          <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale100}>
            {t("empty_perdiem_policy_description")}
          </AppText>
        </View>
      </View>
    );
  }
  return (
    <ScrollView contentContainerStyle={[styles.scrollView, styles.gap24]} showsVerticalScrollIndicator={false}>
      <AppText style={Fonts.BodyMedium}>{t("perdiem_description")}</AppText>
      <View style={styles.gap16}>
        <View style={styles.gap8}>
          <AppText style={Fonts.SentenceSubTitleXLarge}>{t("perdiem")}</AppText>
          <AppText style={Fonts.BodyMedium}>{t("perdiem_by_destinations_description")}</AppText>
        </View>
        <CopilotStepCustom
          order={CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.Step3}
          title={"walkthrough_travel_title_step3"}
          text={"walkthrough_travel_description_step3"}
          image={BizziBotSticker02}
          walkthroughName={CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME}
          activeStep={3}
          totalStep={3}
          onConfirm={handleFinishGuideAction}
          onBack={() => {
            stop();
            goBack();
            setTimeout(() => {
              start({
                fromStep: CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.Step2.toString(),
                walkthroughName: CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME,
              });
            }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
          }}
        >
          {transformedData.domestic.length > 0 && (
            <CustomGroupedTable
              columns={[
                {
                  key: "name",
                  title: (
                    <View>
                      <AppText style={Fonts.H200}>{t("domestic_locations")}</AppText>
                      <AppText style={Fonts.BodyMedium}>{t("expense_category")}</AppText>
                    </View>
                  ),
                },
                {
                  width: 100,
                  key: "amount",
                  title: (
                    <View style={styles.amountHeader}>
                      <AppText style={Fonts.H200}>{t("cost_per_day")}</AppText>
                    </View>
                  ),
                  render: (item) => (
                    <CurrencyText suffix="" style={[Fonts.NumericN200, styles.textRight]}>
                      {item.amount}
                    </CurrencyText>
                  ),
                },
              ]}
              renderGroupTitle={(group) => <AppText style={Fonts.H200}>{group.title}</AppText>}
              dataSource={transformedData.domestic}
            />
          )}
        </CopilotStepCustom>
        {transformedData.overseas.length > 0 && (
          <CustomGroupedTable
            columns={[
              {
                key: "name",
                title: (
                  <View>
                    <AppText style={Fonts.H200}>{t("international_locations")}</AppText>
                    <AppText style={Fonts.BodyMedium}>{t("expense_category")}</AppText>
                  </View>
                ),
              },
              {
                width: 100,
                key: "amount",
                title: (
                  <View style={styles.amountHeader}>
                    <AppText style={Fonts.H200}>{t("cost_per_day")}</AppText>
                  </View>
                ),
                render: (item) => (
                  <CurrencyText suffix="" style={[Fonts.NumericN200, styles.textRight]}>
                    {item.amount}
                  </CurrencyText>
                ),
              },
            ]}
            renderGroupTitle={(group) => <AppText style={Fonts.H200}>{group.title}</AppText>}
            dataSource={transformedData.overseas}
          />
        )}
        {transformedData.all.length > 0 && (
          <CustomGroupedTable
            columns={[
              {
                key: "name",
                title: (
                  <View>
                    <AppText style={Fonts.H200}>{t("locations")}</AppText>
                    <AppText style={Fonts.BodyMedium}>{t("expense_category")}</AppText>
                  </View>
                ),
              },
              {
                key: "amount",
                title: (
                  <View style={styles.amountHeader}>
                    <AppText style={Fonts.H200}>{t("cost_per_day")}</AppText>
                  </View>
                ),
                render: (item) => (
                  <CurrencyText suffix="" style={[Fonts.NumericN200, styles.textRight]}>
                    {item.amount}
                  </CurrencyText>
                ),
              },
            ]}
            renderGroupTitle={(group) => <AppText style={Fonts.H200}>{group.title}</AppText>}
            dataSource={transformedData.all}
          />
        )}
      </View>
    </ScrollView>
  );
};

export default PerdiemPolicy;

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  gap8: { gap: 8 },
  gap16: { gap: 16 },
  gap24: { gap: 24 },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  loadingView: {
    backgroundColor: Colors.white,
  },
  emptyContainer: {
    paddingVertical: 80,
    alignItems: "center",
  },
  emptyContent: {
    padding: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  amountHeader: { alignSelf: "stretch", justifyContent: "flex-end" },
});
