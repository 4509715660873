import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const QUERY_EMPLOYEE = gql`
  query MobileEmployeesQuery($where: OrgEmployeesCondition!, $limit: NonNegativeInt, $offset: NonNegativeInt) {
    orgEmployees(where: $where, limit: $limit, offset: $offset) {
      data {
        avatarUrl
        defaultAvatarUrl
        email
        employeeId
        fullname
      }
    }
  }
`;

interface UseEmployeesQueryArgs {
  where: OrgEmployeesCondition;
  limit?: number;
  offset?: number;
}

interface UseEmployeesQueryOutput {
  orgEmployees: {
    data: {
      employeeId: string;
      fullname: string;
      email: string;
    }[];
  };
}

const useEmployeesQuery = (variables: UseEmployeesQueryArgs) => {
  return useLazyQuery<UseEmployeesQueryOutput>(QUERY_EMPLOYEE, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables,
  });
};

export default useEmployeesQuery;
