import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

interface ExpDeleteExpenseInvoiceInput {
  object: {
    employeeId: string;
    invoiceId: string;
  };
}

const DELETE_INVOICE = gql`
  mutation MobileDeleteExpenseInvoice($object: ExpDeleteExpenseInvoiceInput!) {
    expDeleteExpenseInvoice(input: $object) {
      deletedInvoice {
        emailId
        invoiceId
        companyId
        groupId
        receivedAt
        buyerTaxCode
        totalVatAmount
        sellerTaxCode
      }
    }
  }
`;

const useDeleteExpenseInvoice = () => {
  const [deleteExpenseInvoice, { loading }] = useMutation(DELETE_INVOICE);
  const mutation = async ({ object }: ExpDeleteExpenseInvoiceInput) => {
    const { data } = await deleteExpenseInvoice({
      variables: {
        object,
      },
    });

    return data;
  };

  return { deleteExpenseInvoice: mutation, loading };
};

export default useDeleteExpenseInvoice;
