import * as React from "react";
import Svg, { Circle } from "react-native-svg";

function SvgComponent({ color = "#CBD5E1" }) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <Circle cx={8} cy={8} r={7} fill={color} stroke="white" strokeWidth={2} />
      <Circle cx={8} cy={8} r={3} fill="white" />
    </Svg>
  );
}

export default SvgComponent;
