import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  EmptyData,
  IconCustom,
  RadioButton,
  SearchInput,
} from "components";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Colors, Fonts } from "theme";
import { EmptyResultFound, SearchNotFoundIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import FilterInput from "./FilterInput";
import groupBy from "lodash/groupBy";
import { useTranslation } from "react-i18next";
import LanguageStatus from "constants/LanguageStatus";
import i18n from "i18next";
import { getTitleFromCompanyTeam } from "utils";
import filter from "lodash/filter";
import { removeAccents } from "utils/searchObject";
import { useAuth } from "contexts/AuthContext";
import useOrgTeamsQuery from "screens/ExpenseOverview/hooks/useOrgTeamsQuery";

export type CompanyTeam = {
  teamId: string;
  name?: string;
  code?: string;
  nameEn?: string;
};
interface SelectCompanyTeamProps {
  title?: string;
  value?: CompanyTeam;
  setValue?: (value: CompanyTeam) => void;
  type?: "filter" | "search";
}
const SelectCompanyTeam: FC<SelectCompanyTeamProps> = ({ title, value, setValue, type = "search" }) => {
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const searchRef = useRef<TextInput>();
  const [currentValue, setCurrentValue] = useState<CompanyTeam>(null);
  const [needFetchData, setNeedFetchData] = useState(false);
  const {
    company: { company_id: companyId },
  } = useAuth();

  /*-- only need call API when open this modal --*/
  const { data, loading } = useOrgTeamsQuery({ variables: { where: { companyId } }, skip: !needFetchData });
  const [teamOptions, setTeamOptions] = useState([]);
  const debounceSearchTimeoutId = useRef(null);
  const { t } = useTranslation("app/components/AdvanceSearch");

  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    setCurrentValue(value);
  }, [value?.teamId]);
  const teams = data?.orgTeams?.data ?? [];
  useEffect(() => {
    if (teams) {
      setTeamOptions(teams);
    }
  }, [teams?.length]);
  const companyTeams = useMemo(() => {
    if (!teamOptions) {
      return [];
    }
    const teams = groupBy(teamOptions, ({ teamType }) => teamType?.teamTypeId);
    const companyTeamsByGroup = Object.keys(teams).map((key) => {
      const newTeams = teams[key];
      return {
        teamType: teams[key][0].teamType,
        teams: newTeams,
      };
    });
    return companyTeamsByGroup;
  }, [teamOptions]);
  const onChangeSearch = (text) => {
    setKeyword(text);
    if (!text.trim()) {
      setTeamOptions(teams);
    }
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      const newTeamOptions = filter(teams, (team) => {
        const keywordUnicode = removeAccents(text).toLowerCase();
        const titleUnicode = removeAccents(team?.name ?? "").toLowerCase();
        const titleEnUnicode = removeAccents(team?.nameEn ?? "").toLowerCase();
        const code = (team?.code ?? "").toLowerCase();
        return (
          titleEnUnicode.includes(keywordUnicode) ||
          titleUnicode.includes(keywordUnicode) ||
          code.includes(keywordUnicode)
        );
      });
      setTeamOptions(newTeamOptions);
    }, 400);
  };
  const handleSelectCompanyTeam = (item) => () => {
    setCurrentValue(item);
  };
  const handleClearSearch = () => {
    searchRef?.current?.setNativeProps({ text: "" });
    setKeyword("");
    setTeamOptions(teams);
  };
  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    setTeamOptions(teams);
    if (currentValue) {
      setValue(null);
    }
  };
  const handleApplyFilter = () => {
    bottomSheetRef?.current?.close();
    setValue(currentValue);
  };
  const renderFooter = () => {
    return (
      <View style={styles.footerButton}>
        <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
          {t("clear_filter")}
        </Button>
        <Button style={styles.flex} onPress={handleApplyFilter} disabled={!currentValue}>
          {t("apply")}
        </Button>
      </View>
    );
  };
  const renderSearch = () => (
    <SearchInput
      style={styles.searchInput}
      placeholder={t("search_company_team_title")}
      onChangeText={onChangeSearch}
      ref={searchRef}
      autoCapitalize="none"
      enablesReturnKeyAutomatically
      returnKeyType="search"
      //value={keyword || ""}
      right={
        keyword ? (
          <TouchableOpacity onPress={handleClearSearch}>
            <IconCustom name="cancel" />
          </TouchableOpacity>
        ) : null
      }
    />
  );
  const renderItem = ({ item, index }) => {
    return (
      <View>
        <View style={[styles.title, index === 0 && { marginTop: 12 }]}>
          <AppText style={Fonts.H300}>
            {i18n.language === LanguageStatus.EN && item?.teamType?.nameEn
              ? item?.teamType?.nameEn
              : item?.teamType?.name}
          </AppText>
        </View>
        {item?.teams?.map((team) => {
          const isChecked = currentValue?.teamId === team?.teamId;
          return (
            <View key={team?.teamId}>
              <TouchableOpacity
                activeOpacity={0.9}
                style={styles.companyTeamItem}
                onPress={handleSelectCompanyTeam(team)}
              >
                <AppText style={[Fonts.BodyLarge, styles.flex]}>
                  {team.code} - {i18n.language === LanguageStatus.EN && team?.nameEn ? team.nameEn : team.name}
                </AppText>
                <RadioButton checked={isChecked} />
              </TouchableOpacity>
              <View style={styles.divider} />
            </View>
          );
        })}
      </View>
    );
  };
  const handleOpenModal = () => {
    handleClearSearch();
    setNeedFetchData(true);
    bottomSheetRef?.current?.present();
  };
  return (
    <>
      {type === "filter" ? (
        <TouchableOpacity onPress={handleOpenModal}>
          <IconCustom name="filter-alt" />
          {value && (
            <View style={styles.filterDot}>
              <AppText style={Fonts.BodyXSmall} color={Colors.white}>
                {1}
              </AppText>
            </View>
          )}
        </TouchableOpacity>
      ) : (
        <FilterInput
          title={title}
          value={value ? getTitleFromCompanyTeam({ code: value.code, name: value.name, nameEn: value.nameEn }) : ""}
          onPress={handleOpenModal}
          onClear={handleClearFilter}
        />
      )}
      <BottomSheetFlatListModalCustom
        loading={loading}
        renderSearch={renderSearch}
        renderFooter={renderFooter}
        ref={bottomSheetRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={title}
        listProps={{
          data: companyTeams,
          keyExtractor: (item) => item?.teamType?.teamTypeId,
          renderItem,
          ListEmptyComponent: (
            <EmptyData
              title={!keyword ? t("unit_empty_placeholder") : t("search_not_found")}
              icon={!keyword ? <EmptyResultFound /> : <SearchNotFoundIcon />}
            />
          ),
        }}
      />
    </>
  );
};
export default SelectCompanyTeam;
const styles = StyleSheet.create({
  flex: { flex: 1 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  title: {
    marginTop: 26,
    marginBottom: 0,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  companyTeamItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
  },
  searchInput: { marginHorizontal: 20, marginBottom: 5 },
  footerButton: { flexDirection: "row", gap: 8 },
  filterDot: {
    position: "absolute",
    right: -3,
    top: -5,
    backgroundColor: Colors.info50,
    borderRadius: 10,
    height: 16,
    width: 16,
    justifyContent: "center",
    alignItems: "center",
  },
});
