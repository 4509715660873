import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const EXPENSE_APPROVE_REPORT = gql`
  mutation MobileApproveReport($reportId: UUID!, $reason: String) {
    expenseApproveReport(report_id: $reportId, reason: $reason) {
      message
      success
    }
  }
`;

const EXPENSE_ADD_APPROVAL_REPORT = gql`
  mutation MobileAddReportManualApprover($reportId: UUID!, $approver_id: UUID!) {
    expenseAddReportManualApprover(report_id: $reportId, approver_employee_id: $approver_id) {
      success
      message
    }
  }
`;

const useExpenseApproveReport = () => {
  const [approvalReport] = useMutation(EXPENSE_APPROVE_REPORT);

  const [approvalReportAddApproval] = useMutation(EXPENSE_ADD_APPROVAL_REPORT);

  const approvalReportWithAddApproval = useCallback(
    async ({ reportId, approver_id }) => {
      try {
        if (approver_id) {
          await approvalReportAddApproval({
            variables: {
              reportId,
              approver_id,
            },
          });
        }
        const results = await approvalReport({
          variables: {
            reportId,
          },
        });

        return results;
      } catch (error) {
        return false;
      }
    },
    [approvalReport, approvalReportAddApproval]
  );

  return {
    approvalReport,
    approvalReportWithAddApproval,
  };
};

export default useExpenseApproveReport;
