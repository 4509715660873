import * as React from "react";
import Svg, { G, Rect, Path, Defs, ClipPath } from "react-native-svg";
const SVGComponent = () => (
  <Svg width={51} height={24} viewBox="0 0 51 24" fill="none">
    <G filter="url(#filter0_i_12588_254437)">
      <G filter="url(#filter1_i_12588_254437)">
        <Rect width={51} height={24} rx={12} transform="matrix(-1 0 0 1 51 0)" fill="#1F71F4" />
      </G>
      <Path
        d="M33.0142 7.23967L35.2941 14.135H35.3839L37.6595 7.23967H39.4048L36.3164 16H34.3573L31.2732 7.23967H33.0142ZM42.1456 7.23967V16H40.5586V7.23967H42.1456Z"
        fill="white"
      />
      <G clipPath="url(#clip0_12588_254437)">
        <G clipPath="url(#clip1_12588_254437)">
          <Path d="M-27 4H158V127H-27V4Z" fill="white" />
          <Path
            d="M-27 4H158V13.4588H-27V4ZM-27 22.9175H158V32.3763H-27V22.9175ZM-27 41.8351H158V51.2939H-27V41.8351ZM-27 60.7526H158V70.2114H-27V60.7526ZM-27 79.7061H158V89.1649H-27V79.7061ZM-27 98.6237H158V108.082H-27V98.6237ZM-27 117.541H158V127H-27V117.541Z"
            fill="#D80027"
          />
          <Path d="M-27 4H65.5V70.2114H-27V4Z" fill="#2E52B2" />
          <Path
            d="M-9.76521 53.9557L-11.2077 49.3522L-12.7945 53.9557H-17.5547L-13.696 56.725L-15.1385 61.3285L-11.2077 58.4873L-7.3851 61.3285L-8.86366 56.725L-4.93286 53.9557H-9.76521ZM10.5379 53.9557L9.05935 49.3522L7.54473 53.9557H2.78449L6.64317 56.725L5.20067 61.3285L9.05935 58.4873L12.9541 61.3285L11.5116 56.725L15.3703 53.9557H10.5379ZM30.9132 53.9557L29.3625 49.3522L27.92 53.9557H23.0516L27.0184 56.725L25.5038 61.3285L29.3625 58.4873L33.3293 61.3285L31.8147 56.725L35.6734 53.9557H30.9132ZM51.1802 53.9557L49.7377 49.3522L48.2231 53.9557H43.4268L47.3215 56.725L45.879 61.3285L49.7377 58.4873L53.6324 61.3285L52.0818 56.725L56.0486 53.9557H51.1802ZM9.05935 31.082L7.54473 35.6855H2.78449L6.64317 38.5268L5.20067 43.0584L9.05935 40.2531L12.9541 43.0584L11.5116 38.5268L15.3703 35.6855H10.5379L9.05935 31.082ZM-11.2077 31.082L-12.7945 35.6855H-17.5547L-13.696 38.5268L-15.1385 43.0584L-11.2077 40.2531L-7.3851 43.0584L-8.86366 38.5268L-4.93286 35.6855H-9.76521L-11.2077 31.082ZM29.3625 31.082L27.92 35.6855H23.0516L27.0184 38.5268L25.5038 43.0584L29.3625 40.2531L33.3293 43.0584L31.8147 38.5268L35.6734 35.6855H30.9132L29.3625 31.082ZM49.7377 31.082L48.2231 35.6855H43.4268L47.3215 38.5268L45.879 43.0584L49.7377 40.2531L53.6324 43.0584L52.0818 38.5268L56.0486 35.6855H51.1802L49.7377 31.082ZM-11.2077 12.8838L-12.7945 17.4154H-17.5547L-13.696 20.2566L-15.1385 24.8241L-11.2077 21.9829L-7.3851 24.8241L-8.86366 20.2566L-4.93286 17.4154H-9.76521L-11.2077 12.8838ZM9.05935 12.8838L7.54473 17.4154H2.78449L6.64317 20.2566L5.20067 24.8241L9.05935 21.9829L12.9541 24.8241L11.5116 20.2566L15.3703 17.4154H10.5379L9.05935 12.8838ZM29.3625 12.8838L27.92 17.4154H23.0516L27.0184 20.2566L25.5038 24.8241L29.3625 21.9829L33.3293 24.8241L31.8147 20.2566L35.6734 17.4154H30.9132L29.3625 12.8838ZM49.7377 12.8838L48.2231 17.4154H43.4268L47.3215 20.2566L45.879 24.8241L49.7377 21.9829L53.6324 24.8241L52.0818 20.2566L56.0486 17.4154H51.1802L49.7377 12.8838Z"
            fill="white"
          />
        </G>
        <G clipPath="url(#clip2_12588_254437)">
          <Path d="M9.21755 4H0V19.9997H24V4H9.21755Z" fill="#D80027" />
          <Path
            d="M12 7L13.1804 10.6327H17L13.9098 12.8778L15.0902 16.5105L12 14.2654L8.90979 16.5105L10.0902 12.8778L7 10.6327H10.8196L12 7Z"
            fill="#FFDA44"
          />
        </G>
      </G>
    </G>
    <Defs>
      <ClipPath id="clip0_12588_254437">
        <Rect x={4} y={4} width={16} height={16} rx={8} fill="white" />
      </ClipPath>
      <ClipPath id="clip1_12588_254437">
        <Rect width={185} height={123} fill="white" transform="translate(-27 4)" />
      </ClipPath>
      <ClipPath id="clip2_12588_254437">
        <Rect width={24} height={16} fill="white" transform="translate(0 4)" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SVGComponent;
