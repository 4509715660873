import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const DELETE_REQUEST = gql`
  mutation ExpDeleteExpenseRequest($expenseRequestId: UUID!) {
    expDeleteExpenseRequest(input: { expenseRequestId: $expenseRequestId }) {
      expenseRequestId
      message
    }
  }
`;
const useDeleteRequest = () => {
  const [deleteRequest, { loading, error }] = useMutation(DELETE_REQUEST);

  return {
    deleteRequest,
    loading,
    error,
  };
};
export default useDeleteRequest;
