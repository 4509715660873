import Svg, { Path, Rect } from "react-native-svg";
import React from "react";

function SvgComponent() {
  return (
    <Svg width={48} height={48} viewBox="0 0 48 48" fill="none">
      <Rect width={48} height={48} rx={24} fill="#DCEBFE" />
      <Path d="M15.11 28.44V33.44V28.44Z" fill="#1F71F4" />
      <Path
        d="M34 24C34 29.52 29.52 34 24 34C18.48 34 15.11 28.44 15.11 28.44M15.11 28.44H19.63M15.11 28.44V33.44M14 24C14 18.48 18.44 14 24 14C30.67 14 34 19.56 34 19.56M34 19.56V14.56M34 19.56H29.56"
        stroke="#1F71F4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M22 26.2386C22 27.2068 22.7486 27.9854 23.6669 27.9854H25.5435C26.342 27.9854 26.9908 27.3067 26.9908 26.4582C26.9908 25.5499 26.5916 25.2205 26.0026 25.0109L22.9982 23.9628C22.4092 23.7532 22.01 23.4338 22.01 22.5155C22.01 21.677 22.6588 20.9883 23.4573 20.9883H25.3339C26.2522 20.9883 27.0008 21.7668 27.0008 22.7351"
        stroke="#1F71F4"
        strokeWidth={1.2502}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24.4961 20V28.9835"
        stroke="#1F71F4"
        strokeWidth={1.2502}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
