import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props: any) {
  return (
    <Svg width={102} height={32} viewBox="0 0 102 32" fill="none" {...props}>
      <Path
        d="M44.376 27.673l9.677-10.312-.087-.249h-6.333l.543-6.348h18.207l-.758 4.238-9.547 10.312.086.249h7.85l-1.104 6.34H43.661l.715-4.23zM66.165 27.673l9.677-10.312-.087-.249h-6.333l.552-6.348H88.18l-.758 4.238-9.547 10.312.086.249h7.85l-1.103 6.34H65.45l.715-4.23zM36.878 5.46l-.922 5.303h6.47l-6.78 1.793-3.352 19.346h8.022l4.61-26.441h-8.048zM93.703 5.46l-.922 5.303h6.221l-6.531 1.793-3.36 19.346h8.03l4.61-26.441h-8.048zM31.941 8.296c.405-1.896.25-3.526-.482-4.882C30.244 1.201 27.58.07 23.479 0H7.144L5.661 6.632 0 32h15.234c1.723 0 3.343-.146 4.868-.438a12.04 12.04 0 004.162-1.604 12.768 12.768 0 002.973-2.522c1.335-1.51 2.223-3.2 2.645-5.105.405-1.836.319-3.405-.259-4.692-.585-1.287-1.663-2.231-3.248-2.814 1.25-.55 2.223-1.159 2.938-1.82 1.31-1.183 2.18-2.753 2.628-4.709zM13.141 32h-1.19l.801-3.569 1.698-7.627.086-.412h-6.04l2.223-9.685H7.152l8.34-5.362 5.722 5.362h-2.758l-.861 3.869h7.944L13.14 32z"
        fill="#146EFF"
      />
    </Svg>
  );
}

export default SvgComponent;
