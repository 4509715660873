// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Provider, Toast, WhiteSpace } from "@ant-design/react-native";
import { Button } from "react-native-paper";
import Svg, { Path } from "react-native-svg";
import * as Updates from "expo-updates";
import NetInfo from "@react-native-community/netinfo";
import { useTranslation } from "react-i18next";
import Colors from "app/constants/Colors";

function TowerIcon(props) {
  return (
    <Svg width={72} height={72} viewBox="0 0 72 72" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.156 2.985L21.144 0c-8.192 8.117-8.192 21.326 0 29.443l3.012-2.985c-3.163-3.135-4.905-7.303-4.905-11.736 0-4.434 1.742-8.602 4.905-11.737zm5.255 5.208L26.4 5.207c-5.295 5.247-5.295 13.783 0 19.03l3.012-2.986a9.185 9.185 0 010-13.058zM53.988 72l-8.584-31.048c2.36-.684 4.088-2.846 4.088-5.402 0-3.104-2.549-5.63-5.681-5.63h-5.68v-9.229c2.479-.871 4.26-3.217 4.26-5.97 0-3.492-2.867-6.333-6.391-6.333s-6.391 2.841-6.391 6.334c0 2.752 1.78 5.098 4.26 5.97v9.229h-5.68c-3.133 0-5.681 2.525-5.681 5.629 0 2.556 1.728 4.718 4.087 5.402L18.01 72h35.977zm-28.347-4.222L36 57.026l10.359 10.752H25.64zm4.344-23.108L36 50.912l6.014-6.242-.964-3.49h-10.1l-.965 3.49zm16.87 17.51l-7.91-8.21 4.383-4.55 3.528 12.76zM38.13 14.722A2.123 2.123 0 0036 12.61c-1.175 0-2.13.946-2.13 2.11 0 1.165.955 2.112 2.13 2.112 1.175 0 2.13-.947 2.13-2.111zm-9.941 19.42H43.81c.783 0 1.42.632 1.42 1.408 0 .776-.637 1.408-1.42 1.408H28.19c-.783 0-1.42-.632-1.42-1.408 0-.776.637-1.407 1.42-1.407zm4.866 19.827l-4.383-4.549-3.528 12.76 7.91-8.21zm9.534-45.776l3.013-2.986c5.294 5.246 5.294 13.783 0 19.029l-3.013-2.985a9.185 9.185 0 000-13.058zm5.255-5.208L50.856 0c8.192 8.117 8.192 21.326 0 29.443l-3.012-2.985c3.163-3.135 4.905-7.303 4.905-11.736 0-4.434-1.742-8.602-4.905-11.737z"
        fill="#42526E"
      />
    </Svg>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

function NetworkHandler() {
  const { t } = useTranslation("components/Lib/FullPageErrorFallback");
  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      if (state.isConnected) {
        Toast.loading("");
        Updates.reloadAsync();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <Provider>
      <View style={styles.container}>
        <TowerIcon />
        <WhiteSpace />
        <Text>{t("network_request_failed")}</Text>
        <WhiteSpace />
        <WhiteSpace />
        <Button
          mode="contained"
          color={Colors.blue22}
          style={{ width: 120 }}
          onPress={async () => {
            Toast.loading("");
            const state = await NetInfo.fetch();
            if (state.isConnected) {
              await Updates.reloadAsync();
            } else {
              Toast.removeAll();
            }
          }}
        >
          {t("retry")}
        </Button>
      </View>
    </Provider>
  );
}

function FullPageErrorFallback({ error }: { error: string }) {
  const { t } = useTranslation("components/Lib/FullPageErrorFallback");

  if (error === "Network request failed") {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text>{t("errors")}</Text>
      <Text>{JSON.stringify(error)}</Text>
    </View>
  );
}

export default FullPageErrorFallback;
