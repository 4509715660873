import { AppText, Button, IconCustom, SwitchButton, TextInputCustom, useSetRelativePosition } from "components";
import { Colors, Fonts } from "theme";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { FC, RefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Control, useFieldArray, UseFormSetValue } from "react-hook-form";
import { CONSTANTS } from "constants/constants";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import { useMasterData } from "contexts/MasterDataContext";
import { startLayoutAnimation } from "utils";

interface PassengerListSectionProps {
  control?: Control<ExpenseRequestFormValues>;
  errors: FieldErrors<ExpenseRequestFormValues>;
  setValue: UseFormSetValue<ExpenseRequestFormValues>;
  watch: UseFormWatch<ExpenseRequestFormValues>;
  setPosition: (key: string) => (y: number) => void;
  containerRef: RefObject<any>;
}

const PassengerListSection: FC<PassengerListSectionProps> = ({
  control,
  setValue,
  watch,
  errors,
  containerRef,
  setPosition,
}) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const {
    setting: { request: requestSetting },
  } = useMasterData();
  const enablePassenger = watch("enablePassenger");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "passengers",
  });
  const childRef = useRef(null);
  useSetRelativePosition({
    containerRef,
    childRef,
    setPosition: (y) => {
      setPosition("passengers")(y);
    },
  });

  const onToggle = (value) => {
    startLayoutAnimation();
    setValue("enablePassenger", value);
  };

  return (
    <View style={styles.container} ref={childRef}>
      <View style={styles.titleContainer}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("passenger_list")}</AppText>
        {!requestSetting?.travel?.requirePassengerInfo && (
          <SwitchButton onValueChange={onToggle} value={enablePassenger} />
        )}
      </View>
      {(enablePassenger || requestSetting?.travel?.requirePassengerInfo) && (
        <View style={styles.passengerList}>
          {fields.map((item, index) => (
            <View key={item.id} style={styles.passengerItem}>
              <TextInputCustom
                style={[styles.textInput, { marginTop: 2 }]}
                name={`passengers.${index}.fullname`}
                control={control}
                label={t("passenger_fullname")}
                placeholder={t("passenger_fullname")}
                rules={{ required: t("required") }}
                error={errors?.passengers?.[index]?.fullname}
              />
              {fields.length >= 2 && (
                <TouchableOpacity style={styles.removeIcon} onPress={() => remove(index)}>
                  <IconCustom name="minus" />
                </TouchableOpacity>
              )}
            </View>
          ))}
          {fields?.length <= 20 && (
            <Button
              type="secondary"
              style={styles.addPassengerButton}
              icon={() => <IconCustom name="add-circle-outline" />}
              onPress={() => append({ fullname: "" })}
            >
              {t("add_passenger")}
            </Button>
          )}
        </View>
      )}
    </View>
  );
};
export default PassengerListSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  passengerList: { overflow: "hidden", marginTop: 16, gap: 8 },
  addPassengerButton: {
    marginTop: 10,
  },
  textInput: {
    flex: 1,
  },
  removeIcon: { marginTop: 16, padding: 6 },
  passengerItem: { flexDirection: "row", gap: 8 },
});
