import { KIND_OF_APPROVAL } from "constants/constants";
import { FilterData } from "./types";
import { filter } from "utils";
import { MobileExpenseRequestSearchQueryVariables } from "types";

export const buildRequestSearchFilterCondition = ({
  companyId,
  employeeId,
  keyword,
  filters,
}: {
  companyId: string;
  employeeId: string;
  keyword?: string;
  filters?: FilterData;
}) => {
  const conditions: MobileExpenseRequestSearchQueryVariables["where"] = {
    companyId,
    employeeId,
    keyword,
  };
  if (!filters) {
    return conditions;
  }

  filter(filters?.iApprovedOrRejected, (value) => {
    if (value) {
      conditions.kindOfApproval = KIND_OF_APPROVAL.PARTICIPATED;
    }
  });

  filter(filters?.awaitingMyApproval, (value) => {
    if (value) {
      conditions.kindOfApproval = KIND_OF_APPROVAL.AWAITING_MY_APPROVAL;
    }
  });

  filter(filters?.employee, (value) => {
    conditions.senderId = value?.employeeId;
  });

  filter(filters?.status, (value) => {
    conditions.statuses = value.map((s) => s.value);
  });

  filter(filters?.createdDate, (value) => {
    conditions.createdDate = {
      fromDate: value,
      toDate: value.toDate,
    };
  });

  filter(filters?.fromDate, (value) => {
    conditions.submittedDate = {
      fromDate: value,
      toDate: filters.toDate,
    };
  });

  filter(filters?.companyTeam, (value) => {
    conditions.companyTeamId = value.teamId;
  });

  filter(filters?.expenseRequestType, ({ value }) => {
    conditions.expenseRequestType = value;
  });

  filter(filters?.paidStatus, ({ value }) => {
    conditions.paidStatus = value;
  });

  filter(filters?.type, ({ value }) => {
    conditions.hasCashAdvance = value === "ADVANCE";
  });

  filter(filters?.reportStatus, ({ value }) => {
    conditions.expenseReportStatus = value;
  });

  filter(filters?.totalAmount, ({ min, max }) => {
    conditions.totalAmount = { min, max };
  });

  filter(filters.paymentStatus, ({ value }) => {
    delete conditions.paymentStatus;
    conditions.cashAdvance = { paymentStatus: value };
  });

  filter(filters.cashAdvanceTotalAmount, ({ min, max }) => {
    delete conditions.cashAdvanceTotalAmount;
    conditions.cashAdvance = { ...conditions.cashAdvance, totalAmount: { min, max } };
  });

  filter(filters.priceComparison, ({ value }) => {
    conditions.priceComparison = {
      linked: value === "has_price_comparison",
    };
  });

  return conditions;
};
