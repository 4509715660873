import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={67} height={66} viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_5845_8468)">
        <Path
          d="M27.33 56.854c1.585.505 3.39.79 5.313.79 1.921 0 3.727-.285 5.312-.79-1.534-1.417-3.365-2.323-5.313-2.323-1.94 0-3.759.893-5.312 2.323z"
          fill="#C0CAD8"
        />
        <Path
          d="M60.317 36.057c-.822 12.89-13.278 18.7-28.134 17.749-14.857-.945-26.459-8.302-25.637-21.179C7.368 19.751 20.231 7.495 35.088 8.44c14.857.945 26.05 14.727 25.23 27.617z"
          fill="#E4EAF1"
        />
        <Path
          d="M23.674 10.323l-1.883 29.48c3.352 1.314 7.118 2.149 11.143 2.407 4.018.26 7.868-.09 11.362-.97l1.883-29.48c-3.32-1.864-7.073-3.061-11.084-3.32-4.019-.259-7.894.453-11.421 1.883z"
          fill="#1F71F4"
        />
        <Path
          d="M32.681 21.149l-.563-.039.492-1.695 1.035-3.61.052-.195-2.86-.181 1.346-4.588-1.689-.11 4.115-2.323 2.543 2.75-1.307-.084-.53 1.831 3.76.24-6.394 8.004z"
          fill="#fff"
        />
        <Path
          d="M13.411 40.508c-3.203-4.523 1.469-18.597 6.341-21.224 6.549-3.526 25.08-1.85 30.69 3.016 4.064 3.533 6.18 17.833 2.634 21.327-7.079 6.975-33.951 4.95-39.665-3.119z"
          fill="#1B1B1D"
        />
        <Path
          d="M8.28 36.846l-3.041-.984c-.557-.174-.932-.821-.887-1.527l.33-5.176c.046-.706.499-1.3 1.087-1.404l3.139-.59c.783-.142 1.462.61 1.397 1.567l-.427 6.749c-.07.938-.84 1.61-1.598 1.365z"
          fill="#C0CAD8"
        />
        <Path
          d="M6.553 36.322l-2.79-.886c-.517-.162-.853-.75-.815-1.41l.304-4.737c.04-.654.453-1.184.984-1.282l2.88-.53c.718-.136 1.332.563 1.28 1.424l-.394 6.166c-.052.88-.764 1.482-1.45 1.255z"
          fill="#E4EAF1"
        />
        <Path
          d="M58.04 40.437l3.138-.589c.589-.103 1.042-.699 1.087-1.404l.33-5.176c.045-.706-.33-1.353-.9-1.534l-3.04-.984c-.758-.239-1.528.421-1.586 1.372l-.427 6.75c-.065.957.608 1.72 1.398 1.565z"
          fill="#C0CAD8"
        />
        <Path
          d="M59.825 40.153l2.88-.53c.537-.097.944-.641.983-1.282l.304-4.736c.04-.654-.297-1.236-.815-1.398l-2.789-.886c-.699-.227-1.398.388-1.45 1.249l-.394 6.166c-.052.848.563 1.547 1.281 1.417z"
          fill="#E4EAF1"
        />
        <Path
          d="M29.406 33.436c.783-.362-.653-2.847-4.011-2.704-3.042.129-4.808 2.439-4.084 2.853.505.291 1.98-1.547 4.22-1.715 2.206-.168 3.351 1.806 3.875 1.566zM46.58 34.957c.828-.233-.208-2.918-3.547-3.3-3.021-.343-5.13 1.663-4.477 2.187.453.363 2.193-1.216 4.432-1.035 2.213.181 3.035 2.304 3.591 2.148z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5845_8468">
          <Path fill="#fff" transform="translate(2.948 8.389)" d="M0 0H61.0441V49.2546H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
