import { FC, ReactElement } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Colors, FontTypes, Fonts } from "theme";
import { AppText } from "components";

interface WeirdTextViewProps {
  label: string;
  primaryContent: string;
  secondaryContent?: string;
  style?: StyleProp<ViewStyle>;
  icon?: ReactElement;
}

const WeirdTextView: FC<WeirdTextViewProps> = (props) => {
  const { style, label, primaryContent, secondaryContent, icon } = props;

  return (
    <View style={[styles.container, style]}>
      {label && <AppText style={[Fonts.SentenceCaptionMedium, styles.label]}>{label}</AppText>}
      <View style={styles.body}>
        <View>{icon}</View>
        <View>
          <AppText style={[Fonts.BodyRegular, { fontFamily: FontTypes.medium }]} color={Colors.grayscale100}>
            {primaryContent}
          </AppText>
          {secondaryContent && (
            <AppText style={Fonts.BodyRegular} color={Colors.grayscale60}>
              {secondaryContent}
            </AppText>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    marginBottom: 2,
    color: Colors.grayscale60,
  },
  container: {
    borderBottomWidth: 1,
    borderColor: Colors.grayscale0,
    paddingBottom: 6,
  },
  body: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});

export default WeirdTextView;
