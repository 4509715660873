// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ActivityIndicator, Toast } from "@ant-design/react-native";
import BizziIcon from "assets/images/svg/icons/BizziIcon";
import { BizziButton } from "components";
import InputFieldFlat from "components/Input/PaperInputFlat";
import Colors from "constants/Colors";
import Layout, { SPACING_DEFAULT } from "constants/Layout";
import { useAuth } from "contexts/AuthContext";
import Constants from "expo-constants";
import { Formik } from "formik";
import useExpenseEmployeeRegister from "hooks/register/useEmployeeRegister";
import useExpenseIsEmailAvailable from "hooks/register/useExpenseIsEmailAvailable";

import { PasswordRules } from "layouts";
import SCREEN_NAME from "navigation/ScreenName";
import { RegisterPasswordScreenNavigationProp, RegisterPasswordScreenRouteProp } from "navigation/type";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { TextInput } from "react-native-paper";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import * as Yup from "yup";
import { stylesCommon } from "./commonStyle";
import RegisterStatus from "./RegisterSatus";
import { BackButtonIcon } from "assets/images/svg/icons";

interface IRegisterPasswordScreen {
  navigation: RegisterPasswordScreenNavigationProp;
  route: RegisterPasswordScreenRouteProp;
}

const RegisterPasswordScreen: React.FC<IRegisterPasswordScreen> = ({ navigation, route }) => {
  const { width } = useWindowDimensions();
  const IsMobile = width < 768;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { setUserData } = useAuth();
  const { t } = useTranslation("screens/RegisterOnboarding/RegisterPasswordScreen");
  const { email, fullname, phone, referralBy, employee_title } = route?.params ?? {};

  const { isAvailable, loading: loadingAvailable } = useExpenseIsEmailAvailable({ email });

  const { registerEmployee, loading: loadingRegister } = useExpenseEmployeeRegister();

  const initialValues = { password: "", confirm_password: "" };

  const yupSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t("password_rule_1"))
      .matches("[A-Z]+", t("password_rule_2"))
      .matches("[a-z]+", t("password_rule_3"))
      .required(t("required")),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], t("password_not_match"))
      .required(t("required")),
  });

  const onSubmit = useCallback(
    async (values) => {
      Keyboard.dismiss();

      if (!isAvailable) {
        const results = await registerEmployee({
          password: values.password,
          email,
          fullname,
          phone,
          title: employee_title,
        });

        if (results?.data?.expenseEmployeeRegister?.success) {
          if (Platform.OS === "web") {
            navigation.navigate(SCREEN_NAME.RegisterInformScreen);
          } else {
            setUserData({ token: results?.data?.expenseEmployeeRegister?.token });
          }
        } else {
          if (
            results?.data?.expenseEmployeeRegister?.message === SERVER_ERROR_CODE.EXPENSE_EMPLOYEE_PHONE_EXISTS ||
            results?.data?.expenseEmployeeRegister?.message === SERVER_ERROR_CODE.EMPLOYEE_PHONE_EXISTS
          ) {
            Toast.fail(t("phone_exists"));
            return;
          }
          Toast.fail(t("unknown_error"));
        }
      } else {
        requestAnimationFrame(() => {
          navigation.navigate(SCREEN_NAME.RegisterCompanyScreen, {
            password: values.password,
            email,
            fullname,
            phone,
            referralBy,
            employee_title,
          });
        });
      }
    },
    [navigation, fullname, phone, email, registerEmployee, t, isAvailable, setUserData, referralBy, employee_title]
  );

  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <ScrollView>
        <View style={stylesCommon.container}>
          <View style={IsMobile ? stylesCommon.contentMobile : stylesCommon.content}>
            <View style={stylesCommon.logoContainer}>
              <TouchableOpacity onPress={() => navigation.goBack()} style={stylesCommon.backButton}>
                <BackButtonIcon />
              </TouchableOpacity>
              <BizziIcon />
            </View>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnMount validationSchema={yupSchema}>
              {(props) => {
                const { values, errors, touched, handleChange, handleSubmit, handleBlur, setTouched, isValid } = props;
                return (
                  <ScrollView keyboardDismissMode="on-drag" keyboardShouldPersistTaps="always">
                    <View style={IsMobile ? stylesCommon.registerFormMobile : stylesCommon.registerForm}>
                      <Text style={stylesCommon.title}>{t("title")}</Text>
                      <InputFieldFlat
                        label={t("password")}
                        secureTextEntry={!showPassword}
                        onBlur={handleBlur("password")}
                        textContentType="newPassword"
                        onChangeText={handleChange("password")}
                        returnKeyType="go"
                        value={values.password}
                        placeholder=""
                        error={errors.password}
                        textAlign="center"
                        touched={touched.password}
                        iconRight={
                          <TextInput.Icon
                            onPress={() => setShowPassword(!showPassword)}
                            name={showPassword ? "eye-off-outline" : "eye-outline"}
                          />
                        }
                        inputStyle={styles.passwordInput}
                        onFocus={() =>
                          setTouched({
                            password: false,
                          })
                        }
                      />
                      <InputFieldFlat
                        label={t("confirm_password")}
                        secureTextEntry={!showConfirmPassword}
                        onBlur={handleBlur("confirm_password")}
                        textContentType="newPassword"
                        onChangeText={handleChange("confirm_password")}
                        touched={touched.confirm_password}
                        error={errors?.confirm_password}
                        iconRight={
                          <TextInput.Icon
                            onPress={() => setShowConfirmPassword(!showConfirmPassword)}
                            name={showConfirmPassword ? "eye-off-outline" : "eye-outline"}
                          />
                        }
                        onFocus={() =>
                          setTouched({
                            confirm_password: false,
                          })
                        }
                      />

                      <View style={styles.rules}>
                        <PasswordRules password={values.password} />
                      </View>

                      <BizziButton title={t("register_account")} onPress={handleSubmit} disabled={!isValid} />
                    </View>
                  </ScrollView>
                );
              }}
            </Formik>
          </View>
          {!IsMobile && (
            <View style={stylesCommon.status}>
              <RegisterStatus activeStep={1} />
            </View>
          )}
        </View>
      </ScrollView>

      {(loadingAvailable || loadingRegister) && <ActivityIndicator animating toast size="large" />}
    </KeyboardAvoidingView>
  );
};

export default RegisterPasswordScreen;

const styles = StyleSheet.create({
  passwordInput: {
    backgroundColor: Colors.whiteBackground,
    borderWidth: 1,
    borderColor: Colors.gray10,
    borderRadius: 8,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  rules: { marginTop: 34, marginBottom: 34 },
});
