import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Image, Platform, StatusBar, StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";

import { BackHeader, IconCustom, TopTabView, useWindowDimensions } from "components";
import { Colors } from "theme";
import EmailTab from "./components/EmailTab";
import InvoiceTab from "./components/InvoiceTab";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { CONSTANTS } from "constants/constants";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";
import { WINDOW_WIDTH } from "@gorhom/bottom-sheet";
import SCREEN_NAME from "navigation/ScreenName";

const InboxScreen = ({ navigation, route }) => {
  const { index: indexTab, shouldShowWalkthrough = false } = route?.params ?? {};
  const [index, setIndex] = useState(indexTab ? indexTab : 0);
  const { t } = useTranslation("app/screens/Inbox/InboxScreen");
  const { t: walkthroughGuideT } = useTranslation("app/contexts/WalkthroughGuideContext");

  const routes = useMemo(
    () => [
      { key: "1", title: "Email" },
      { key: "2", title: t("received_invoice_tab") },
    ],
    [t]
  );
  /*-- start walkthrough flow --*/
  const { start, steps, registerStep, stop } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) =>
      steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME &&
      steps[key].order === CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step5
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);
  /*-- end --*/

  useEffect(() => {
    if (index === 0) {
      analyticService.logEvent({ name: EVENT.EMAIL.TAP_VIEW_LIST });
    } else {
      analyticService.logEvent({ name: EVENT.INVOICE.TAP_VIEW_LIST });
    }
  }, [index]);

  const renderScene = ({ route: innerRoute }) => {
    switch (innerRoute.key) {
      case "1":
        return <EmailTab />;
      case "2":
        return <InvoiceTab navigation={navigation} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      const tabId = parseInt(route?.params?.tabId, 10);

      if (tabId > -1) {
        navigation.setParams({
          tabId: null,
          invoiceId: null,
        });
        setIndex(tabId);
      }
    });
  }, [route?.params?.expenseId, route?.params?.invoiceId, route?.params?.tabId, navigation, setIndex]);
  const { width } = useWindowDimensions();
  const { handleFinishGuideAction } = useWalkthroughGuide();
  const onLayoutChanged = useRef(false);
  const onLayoutHeaderChange = (event) => {
    const headerHeight = event.nativeEvent.layout.height;
    if (headerHeight === 0 || (!onLayoutChanged?.current && Platform.OS !== "web")) {
      onLayoutChanged.current = true;
      return;
    }
    [
      {
        walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
        title: "walkthrough_email_title_step5",
        text: "walkthrough_email_description_step5",
        order: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step5,
        image: BizziBotSticker01,
        onConfirm: () => {
          setIndex(1);
        },
        onBack: async () => {
          navigation.goBack();
          await stop();
          setTimeout(() => {
            start({
              fromStep: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step4.toString(),
              walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
            });
          }, 500);
        },
        borderRadius: 25,
        position: {
          x: Platform.OS === "web" ? (WINDOW_WIDTH - width) / 2 + 20 : 20,
          y: headerHeight,
          width: width / 2 - 20,
          height: 48,
        },
      },
      {
        walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
        title: "walkthrough_email_title_step6",
        text: "walkthrough_email_description_step6",
        order: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step6,
        image: BizziBotSticker01,
        borderRadius: 25,
        onConfirm: async () => {
          await stop();
          navigation.navigate(SCREEN_NAME.InvoiceDetailScreen, { shouldShowWalkthrough: true });
        },
        position: {
          x: Platform.OS === "web" ? (WINDOW_WIDTH - width) / 2 + width / 2 : width / 2,
          y: headerHeight,
          width: width / 2 - 20,
          height: 48,
        },
      },
    ].forEach((config, index) => {
      registerStep({
        walkthroughName: config.walkthroughName,
        name: `${config.order}_${config.walkthroughName}`,
        order: config.order,
        text: `${walkthroughGuideT(config.title as any)}${CONSTANTS.WALK_THROUGH.AddTitleKey}${walkthroughGuideT(
          config.text as any
        )}`,
        borderRadius: config.borderRadius,
        image: (<Image resizeMode="contain" source={config.image as any} style={styles.bizziBotImage} />) as any,
        onConfirm: config.onConfirm,
        onBack: config.onBack,
        onSkip: handleFinishGuideAction,
        activeStep: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step5 + index,
        totalStep: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.TotalStep,
        measure: async () => {
          return new Promise((resolve) => {
            resolve({
              x: config.position.x,
              y: config.position.y,
              width: config.position.width,
              height: config.position.height,
            });
          });
        },
      });
    });
  };
  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" />
      <BackHeader
        headerTitle={t("inbox")}
        onLayout={onLayoutHeaderChange}
        headerRight={
          <TouchableOpacity onPress={() => navigation.navigate(SCREEN_NAME.InvoiceSearchScreen)}>
            <IconCustom name="search" />
          </TouchableOpacity>
        }
      />
      <TopTabView routes={routes} index={index} setIndex={setIndex} renderScene={renderScene} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  bizziBotImage: { width: 84, height: 91 },
});

export default InboxScreen;
