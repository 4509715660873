import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 20, color = "#0089FF", ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.244 3.577a.833.833 0 01.589-.244h5v2.5A1.667 1.667 0 0012.5 7.5H15v5h1.666V6.667a.834.834 0 00-.244-.59l-4.166-4.166a.833.833 0 00-.59-.244H5.833a2.5 2.5 0 00-2.5 2.5V12.5H5V4.167c0-.221.087-.433.244-.59zm8.577 2.256L12.5 4.512v1.321h1.321z"
        fill={color}
      />
      <Path
        d="M7.5 5.833a.833.833 0 000 1.667h.833a.833.833 0 000-1.667H7.5zM7.5 9.167a.833.833 0 000 1.666h5a.833.833 0 000-1.666h-5z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 17.5v-5H5v3.443l1.204-.803a.833.833 0 011.052.104l1.077 1.078 1.077-1.078a.833.833 0 011.179 0l1.077 1.078 1.078-1.078a.833.833 0 011.051-.104l1.205.803V12.5h1.666v5a.833.833 0 01-1.295.694l-1.932-1.288-1.183 1.183a.833.833 0 01-1.179 0L10 17.012l-1.078 1.077a.833.833 0 01-1.178 0L6.56 16.906 4.63 18.194a.834.834 0 01-1.296-.694z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 13.333c0-.46.373-.833.833-.833H12.5a.833.833 0 010 1.667h-1.667a.833.833 0 01-.833-.834z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
