// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { useCallback, useState } from "react";
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Text,
} from "react-native";

import { Flex, Modal } from "@ant-design/react-native";
import Popover from "react-native-popover-view";

import Colors from "app/constants/Colors";
import Layout from "app/constants/Layout";
import { FormikErrors, FormikTouched } from "formik";
import { Icon } from "../Icon";
import Fonts from "constants/Fonts";

export interface IInputField extends TextInputProps {
  label?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | null;
  extra?: React.ReactElement | string;
  help?: React.ReactElement;
  leftImage?: React.ReactElement;
  tooltipText?: string;
  inputStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<TextStyle>;
  onBlur?: (e: any) => void;
  rightTitle?: string;
  rightTitleStyle?: StyleProp<TextStyle>;
  onPressRightTitle?: () => void;
  onFocus?: () => void;
  borderBottomWidth: number;
  styleExtra?: StyleProp<TextStyle>;
}

const InputField = React.forwardRef((props: IInputField, ref: React.ForwardedRef<TextInput>) => {
  const {
    label,
    error,
    touched,
    extra,
    help,
    leftImage,
    tooltipText,
    inputStyle,
    style,
    onBlur,
    rightTitle,
    rightTitleStyle,
    borderBottomWidth = 1,
    onPressRightTitle,
    onFocus,
    styleExtra = {
      marginRight: 30,
    },
    ...rest
  } = props;
  const [showHelp, setShowHelp] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  let finalExtra = extra;
  if (typeof extra === "string") {
    finalExtra = <Text>{extra}</Text>;
  } else if (!extra) {
    finalExtra = <></>;
  }
  const hasError = !!error && touched;

  const onFocusInput = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocused(true);
    },
    [onFocus]
  );

  const onBlurInput = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }

      setIsFocused(false);
    },
    [onBlur]
  );

  const onPressShowHelp = useCallback(() => {
    setShowHelp(true);
  }, []);

  const onPressCloseHelp = useCallback(() => {
    setShowHelp(false);
  }, []);

  return (
    <View style={StyleSheet.flatten([styles.marginBottomStyle, style])}>
      <Flex justify="between">
        {!!label && (
          <Flex>
            <Text style={[styles.label, isFocused && { color: hasError ? Colors.warningColor : Colors.blue22 }]}>
              {label}
            </Text>
            {!!tooltipText && (
              <View style={{ marginLeft: 5 }}>
                <Popover
                  placement="bottom"
                  popoverStyle={styles.popoverStyle}
                  from={
                    <TouchableOpacity>
                      <Icon name="question" size={14} color={Colors.black} />
                    </TouchableOpacity>
                  }
                >
                  <Text style={{ color: Colors.whiteBackground }}>{tooltipText}</Text>
                </Popover>
              </View>
            )}
          </Flex>
        )}
        {!!rightTitle && (
          <TouchableOpacity onPress={onPressRightTitle}>
            <Text style={[styles.label, rightTitleStyle]}>{rightTitle}</Text>
          </TouchableOpacity>
        )}
      </Flex>
      <View
        style={[
          {
            borderBottomWidth,
            borderBottomColor: isFocused ? (hasError ? Colors.warningColor : Colors.blue22) : Colors.grey1,
          },
          hasError && { backgroundColor: Colors.orange100 },
        ]}
      >
        <TextInput
          ref={ref}
          {...rest}
          clearButtonMode="while-editing"
          enablesReturnKeyAutomatically
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          placeholderTextColor={Colors.placeholderTextColor}
          style={StyleSheet.flatten([
            styles.input,
            !leftImage && { paddingLeft: 20 },
            extra && styleExtra,
            hasError && styles.error,
            inputStyle,
          ])}
        />
        <View style={styles.leftImage}>{leftImage}</View>
        {extra && (
          <TouchableOpacity onPress={onPressShowHelp} style={styles.finalExtraStyle}>
            {finalExtra}
          </TouchableOpacity>
        )}
      </View>
      {hasError && typeof error === "string" && <Text style={Layout.formError}>{error}</Text>}
      {help && (
        <Modal
          maskClosable
          closable
          transparent
          visible={showHelp}
          onClose={onPressCloseHelp}
          footer={[{ text: "OK", onPress: onPressCloseHelp }]}
        >
          <View style={styles.wrapperModalStyle}>{help}</View>
        </Modal>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  label: {
    fontFamily: Fonts.fontFamily,
    fontSize: 12,
    color: Colors.blue4,
  },
  input: {
    fontFamily: Fonts.fontFamily,
    // borderRadius: 15,
    backgroundColor: Colors.transparent,
    color: Colors.black,
    borderStyle: "solid",
    height: 40,
    paddingLeft: 40,
    marginLeft: 0,
  },
  error: {
    color: Colors.warningColor,
  },
  leftImage: {
    position: "absolute",
    left: 8,
    top: 8,
  },
  marginBottomStyle: {
    marginBottom: 20,
  },
  popoverStyle: { backgroundColor: Colors.black, padding: 10 },
  wrapperModalStyle: { paddingVertical: 20, paddingHorizontal: 20 },
  finalExtraStyle: { position: "absolute", right: 5, top: 10 },
});

export default InputField;
