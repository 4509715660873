import React, { forwardRef, memo, useImperativeHandle, useRef, useState } from "react";
import { ActivityIndicator, FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { Path, Svg } from "react-native-svg";
import { Toast } from "@ant-design/react-native";

import {
  AppText,
  AvatarUser,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  EmptyData,
  IconCustom,
  SearchInput,
  ToastManager,
} from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { EmptyExpense, SearchNotFoundIcon } from "assets/images/svg/icons";
import { useAuth } from "contexts/AuthContext";
import useEmployeesQuery from "../hooks/useEmployeesQuery";
import useAssignTransactionMutation from "../hooks/useAssignTransactionMutation";
import { MobileCrdCardTransactionQuery } from "types";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

interface AssignUserModalProps {
  onRefreshDetail?: () => void;
  transactionDetail: MobileCrdCardTransactionQuery["crdCardTransaction"];
}
export interface AssignUserModalMethods {
  present?: () => void;
}
const PAGE_SIZE = 20;
const SelectedIcon = () => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
    <Path
      d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829L4.76 9.757 9.003 14z"
      fill="#1F71F4"
    />
  </Svg>
);

const AssignUserModal = forwardRef<AssignUserModalMethods, AssignUserModalProps>(
  ({ onRefreshDetail, transactionDetail }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
        refetch?.();
      },
    }));
    const { t } = useTranslation("app/screens/Card/TransactionDetailScreen");
    const debounceSearchTimeoutId = useRef(null);

    const {
      company: { company_id: companyId },
    } = useAuth();
    const disableLoadMore = useRef<boolean>(false);
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
    const [textSearch, setTextSearch] = useState("");
    const searchInputRef = useRef(null);
    const [assignTransaction] = useAssignTransactionMutation();
    const [_, { data, loading, called, fetchMore, refetch }] = useEmployeesQuery({
      companyId,
      keyword: textSearch,
      limit: PAGE_SIZE,
      offset: 0,
    });
    const employees = data?.orgEmployees?.data ?? [];
    const total = data?.orgEmployees?.totalEntries ?? 0;
    const handleChoose = async (employeeId) => {
      bottomSheetRef?.current?.close();
      try {
        Toast.loading("");
        analyticService.logEvent({ name: EVENT.CARD_TRANSACTION.ASSIGN_TRANSACTION });
        const result = await assignTransaction({
          variables: {
            companyId: transactionDetail?.companyId,
            companyEmployeeId: employeeId,
            cardTransactionId: transactionDetail?.cardTransactionId,
          },
        });
        analyticService.logEvent({ name: EVENT.CARD_TRANSACTION.ASSIGN_TRANSACTION_SUCCESS });
        if (result?.data?.crdAssignTransactionEmployee) {
          await onRefreshDetail?.();
          ToastManager.success(t("assign_user_success"));
        }
        Toast.removeAll();
      } catch (e) {
        Toast.removeAll();
      }
    };
    const onChangeSearch = (text) => {
      if (debounceSearchTimeoutId.current) {
        clearTimeout(debounceSearchTimeoutId.current);
      }
      debounceSearchTimeoutId.current = setTimeout(() => {
        setTextSearch(text);
      }, 500);
    };
    const renderItem = ({ item }) => {
      return (
        <TouchableOpacity style={styles.employeeItem} onPress={() => handleChoose(item?.employeeId)}>
          <AvatarUser url={item?.avatarUrl || item?.defaultAvatarUrl} />
          <View style={styles.employeeInfo}>
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
              {item?.fullname}
            </AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {item?.email}
            </AppText>
          </View>
          {item?.employeeId === transactionDetail?.employee?.employeeId && <SelectedIcon />}
        </TouchableOpacity>
      );
    };
    const handleLoadMore = () => {
      console.log("load", disableLoadMore.current, loading);
      if (disableLoadMore.current || loading || employees.length >= total) {
        return;
      }
      fetchMore?.({
        variables: { offset: employees?.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            orgEmployees: {
              totalEntries: fetchMoreResult.orgEmployees.totalEntries,
              data: [...prev.orgEmployees.data, ...fetchMoreResult.orgEmployees.data],
            },
          };
        },
      });
    };

    const renderListFooterComponent = () => {
      if (loading && employees?.length > 0) {
        return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
      }
      return null;
    };

    const renderListEmptyComponent = () => {
      if (loading && !called) {
        return null;
      }
      if (textSearch) {
        return <EmptyData icon={<SearchNotFoundIcon />} title={t("search_not_found")} />;
      }
      return <EmptyData title={t("empty_user")} icon={<EmptyExpense />} />;
    };
    const handleClearSearch = () => {
      setTextSearch("");
      searchInputRef?.current?.clear();
    };
    const onDismiss = () => {
      setTextSearch("");
    };
    return (
      <BottomSheetModalCustom
        title={t("choose_user")}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        ref={bottomSheetRef}
        onDismiss={onDismiss}
      >
        <View style={styles.expenseListContainer}>
          <SearchInput
            style={styles.searchInput}
            ref={searchInputRef}
            onChangeText={onChangeSearch}
            placeholder={t("find_a_user")}
            right={
              textSearch ? (
                <TouchableOpacity onPress={handleClearSearch}>
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : null
            }
          />
          {loading && employees?.length === 0 ? (
            <ActivityIndicator style={styles.loading} size="small" color={Colors.primary50} />
          ) : (
            <FlatList
              showsVerticalScrollIndicator={false}
              contentContainerStyle={styles.expenseListContent}
              data={employees}
              renderItem={renderItem}
              keyExtractor={(item, index) => item?.expense_id ?? index}
              keyboardDismissMode="on-drag"
              keyboardShouldPersistTaps="always"
              ListEmptyComponent={renderListEmptyComponent}
              onEndReached={handleLoadMore}
              onEndReachedThreshold={0.15}
              ItemSeparatorComponent={() => <View style={styles.divider} />}
              ListFooterComponent={renderListFooterComponent}
            />
          )}
        </View>
      </BottomSheetModalCustom>
    );
  }
);
export default memo(AssignUserModal);

const styles = StyleSheet.create({
  expenseListContent: {
    paddingTop: 15,
    paddingBottom: 100,
  },
  expenseListContainer: {
    flex: 1,
    paddingTop: 5,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  loading: { marginTop: 50 },
  loadingMore: { marginTop: 10 },
  divider: { height: 1, backgroundColor: Colors.grayscale05 },
  employeeItem: {
    paddingVertical: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    rowGap: 10,
    alignItems: "center",
    gap: 8,
  },
  employeeInfo: { flex: 1 },
  searchInput: { marginBottom: 10 },
});
