import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGComponent = ({ width = 25, height = 24, color = "#1F71F4" }) => (
  <Svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <Path
      d="M22.5 7.55039C22.5 8.21039 21.96 8.75039 21.3 8.75039H3.7C3.04 8.75039 2.5 8.21039 2.5 7.55039V7.54039C2.5 5.25039 4.35 3.40039 6.64 3.40039H18.35C20.64 3.40039 22.5 5.26039 22.5 7.55039Z"
      fill={color}
    />
    <Path
      d="M2.5 11.45V16.46C2.5 18.75 4.35 20.6 6.64 20.6H18.35C20.64 20.6 22.5 18.74 22.5 16.45V11.45C22.5 10.79 21.96 10.25 21.3 10.25H3.7C3.04 10.25 2.5 10.79 2.5 11.45ZM8.5 17.25H6.5C6.09 17.25 5.75 16.91 5.75 16.5C5.75 16.09 6.09 15.75 6.5 15.75H8.5C8.91 15.75 9.25 16.09 9.25 16.5C9.25 16.91 8.91 17.25 8.5 17.25ZM15 17.25H11C10.59 17.25 10.25 16.91 10.25 16.5C10.25 16.09 10.59 15.75 11 15.75H15C15.41 15.75 15.75 16.09 15.75 16.5C15.75 16.91 15.41 17.25 15 17.25Z"
      fill={color}
    />
  </Svg>
);
export default SVGComponent;
