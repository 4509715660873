import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = ({ width = 20, height = 20, color = "#94A3B8" }) => (
  <Svg width={width} height={height} viewBox="0 0 18 18" fill="none">
    <Path
      d="M8.99935 0.666016C4.40768 0.666016 0.666016 4.40768 0.666016 8.99935C0.666016 13.591 4.40768 17.3327 8.99935 17.3327C13.591 17.3327 17.3327 13.591 17.3327 8.99935C17.3327 4.40768 13.591 0.666016 8.99935 0.666016ZM11.7993 10.916C12.041 11.1577 12.041 11.5577 11.7993 11.7993C11.6743 11.9243 11.516 11.9827 11.3577 11.9827C11.1993 11.9827 11.041 11.9243 10.916 11.7993L8.99935 9.88268L7.08268 11.7993C6.95768 11.9243 6.79935 11.9827 6.64102 11.9827C6.48268 11.9827 6.32435 11.9243 6.19935 11.7993C5.95768 11.5577 5.95768 11.1577 6.19935 10.916L8.11602 8.99935L6.19935 7.08268C5.95768 6.84102 5.95768 6.44102 6.19935 6.19935C6.44102 5.95768 6.84102 5.95768 7.08268 6.19935L8.99935 8.11602L10.916 6.19935C11.1577 5.95768 11.5577 5.95768 11.7993 6.19935C12.041 6.44102 12.041 6.84102 11.7993 7.08268L9.88268 8.99935L11.7993 10.916Z"
      fill={color}
    />
  </Svg>
);
export default SVGComponent;
