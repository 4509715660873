import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6 19h3.346v-5.038c0-.256.087-.47.26-.644a.875.875 0 01.644-.26h3.5c.256 0 .47.087.644.26.173.174.26.388.26.644v5.039H18v-8.846a.289.289 0 00-.034-.14.375.375 0 00-.091-.11l-5.692-4.28A.268.268 0 0012 5.56a.268.268 0 00-.183.067L6.125 9.905a.375.375 0 00-.091.11.29.29 0 00-.034.14V19zm-1.5 0v-8.845a1.795 1.795 0 01.723-1.446l5.692-4.289a1.738 1.738 0 011.083-.361c.407 0 .769.12 1.087.361l5.692 4.289c.226.165.403.376.53.632.129.256.193.527.193.814V19c0 .409-.148.761-.443 1.057A1.443 1.443 0 0118 20.5h-3.942a.875.875 0 01-.644-.26.874.874 0 01-.26-.644v-5.039h-2.308v5.039c0 .256-.087.47-.26.644a.875.875 0 01-.644.26H6c-.409 0-.761-.148-1.057-.443A1.443 1.443 0 014.5 19z"
          fill="#94A3B8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
