/* eslint-disable react-native/no-inline-styles */
import { StyleSheet, View, ViewStyle } from "react-native";
import { AppText, Line, PercentLabel, useWindowDimensions } from "components";
import { Colors, Fonts } from "theme";
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { FC, useEffect, Fragment } from "react";
import { formatMoneyString } from "utils";
import { CONSTANTS } from "constants/constants";
import { TouchableOpacity } from "react-native-gesture-handler";

const AMOUNT_LABEL_MAX_WIDTH = 60;

const BAR_MIN_WIDTH = 2;
const ProgressLine = ({ amount, showPercent, percent, title, diffPercentage, prevAmount, percentPrevMonth }) => {
  const progress = useSharedValue(0);

  useEffect(() => {
    progress.value = withTiming(1, {
      duration: 500,
      easing: Easing.linear,
    });
  }, []);

  const { width } = useWindowDimensions();
  const maxWidthLineChart = width - CONSTANTS.COMMON.CONTAINER_PADDING * 2 - AMOUNT_LABEL_MAX_WIDTH;
  const style = useAnimatedStyle(() => {
    const width = interpolate(progress.value, [0, 1], [0, maxWidthLineChart * (percent / 100)], Extrapolate.CLAMP);
    return {
      width: Math.floor(width) ? width : BAR_MIN_WIDTH,
    };
  });
  const stylePrevMonth = useAnimatedStyle(() => {
    const width = interpolate(
      progress.value,
      [0, 1],
      [0, maxWidthLineChart * (percentPrevMonth / 100)],
      Extrapolate.CLAMP
    );
    return {
      width: Math.floor(width) ? width : BAR_MIN_WIDTH,
    };
  });

  return (
    <View style={{ gap: 10 }}>
      <View style={styles.titleContainer}>
        <AppText
          style={[
            Fonts.BodyMedium,
            {
              maxWidth: width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING - AMOUNT_LABEL_MAX_WIDTH,
            },
          ]}
          numberOfLines={1}
        >
          {title}
        </AppText>
        {showPercent && typeof diffPercentage === "number" && <PercentLabel value={diffPercentage} />}
      </View>
      <View style={{ gap: 4 }}>
        <View
          style={[
            styles.lineChartContainer,
            {
              maxWidth: width - CONSTANTS.COMMON.CONTAINER_PADDING * 2,
            },
          ]}
        >
          <Animated.View style={[styles.lineChart, style]} />
          <AppText style={Fonts.NumericN100} color={Colors.grayscale100}>
            {formatMoneyString(amount)}
          </AppText>
        </View>
        {showPercent && typeof prevAmount === "number" && (
          <View
            style={[
              styles.lineChartContainer,
              {
                maxWidth: width - CONSTANTS.COMMON.CONTAINER_PADDING * 2,
              },
            ]}
          >
            <Animated.View style={[styles.lineChart, styles.lineChartPrevMonth, stylePrevMonth]} />
            <AppText style={Fonts.NumericN100} color={Colors.grayscale70}>
              {formatMoneyString(prevAmount)}
            </AppText>
          </View>
        )}
      </View>
    </View>
  );
};

export type BarChartCompareDataItem = {
  title: string;
  thisMonthAmount: number;
  prevMonthAmount: number;
  diffPercentage: number;
};
interface BarChartCompareHorizontalProps {
  style?: ViewStyle;
  data: (BarChartCompareDataItem & { metadata: any })[];
  showPercent?: boolean;
  onItemPress?: (metadata: any) => void;
}
const BarChartCompareHorizontal: FC<BarChartCompareHorizontalProps> = ({ style, data, showPercent, onItemPress }) => {
  const maxAmountValue = Math.max(
    ...[...data?.map((i) => i.thisMonthAmount), ...data?.map((i) => i?.prevMonthAmount || 0)]
  );
  return (
    <View style={style}>
      {data?.map((item, index) => {
        const percent = (item?.thisMonthAmount / maxAmountValue) * 100;
        const percentPrevMonth = (item?.prevMonthAmount / maxAmountValue) * 100;
        const progressLine = [
          index === 0 ? null : <Line size="sm" containerStyle={{ marginVertical: 10 }} />,
          <ProgressLine
            showPercent={showPercent}
            amount={item?.thisMonthAmount}
            prevAmount={item?.prevMonthAmount}
            key={index}
            title={item?.title}
            diffPercentage={item?.diffPercentage}
            percent={percent}
            percentPrevMonth={percentPrevMonth}
          />,
        ];
        return onItemPress ? (
          <TouchableOpacity key={index} onPress={() => onItemPress(item.metadata)}>
            {progressLine}
          </TouchableOpacity>
        ) : (
          <Fragment key={index}>{progressLine}</Fragment>
        );
      })}
    </View>
  );
};
export default BarChartCompareHorizontal;
const styles = StyleSheet.create({
  lineChartContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  lineChart: {
    backgroundColor: Colors.primary50,
    height: 12,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
  },
  lineChartPrevMonth: {
    backgroundColor: Colors.grayscale05,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
});
