import React, { ReactElement, ReactNode, RefObject, useRef } from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { AppText, IconCustom, useSetRelativePosition } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts, FontTypes } from "theme";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

export interface ISelectCustomItem {
  key: string;
  label?: string;
  metadata?: any;
}

interface ISelectCustomProps {
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  label?: string;
  onPress: () => void;
  style?: ViewStyle;
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  onSelect?: () => void;
  setPosition?: (y: number) => void;
  handleClear?: () => void;
  warningMessage?: string;
  children?: ReactNode;
  containerRef?: RefObject<any>;
}

const SelectCustom: React.FC<ISelectCustomProps> = (props) => {
  const {
    name,
    control,
    rules,
    error,
    label,
    onPress,
    rightIcon,
    style,
    leftIcon,
    setPosition,
    children,
    handleClear,
    warningMessage,
    containerRef,
  } = props;
  const childRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef, setPosition });
  const renderRightIcon = (value, onChange) => {
    if (handleClear && value) {
      return (
        <TouchableOpacity
          onPress={() => {
            onChange(null);
            handleClear?.();
          }}
        >
          <IconCustom name="cancel" />
        </TouchableOpacity>
      );
    }
    return rightIcon ? rightIcon : <IconCustom name="expand-more" />;
  };
  return (
    <View ref={childRef}>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          const option = value as ISelectCustomItem;
          return (
            <View style={style}>
              <TouchableOpacity onPress={onPress} style={[styles.input, Boolean(error) && styles.inputError]}>
                {leftIcon && <View style={styles.leftIcon}>{leftIcon}</View>}
                {!!value && <AppText style={styles.label}>{label}</AppText>}
                {value && children ? (
                  children
                ) : (
                  <AppText
                    style={styles.value}
                    color={
                      error
                        ? Colors.alert50
                        : value
                        ? CONSTANTS.COMMON.TEXT_INPUT_COLOR
                        : CONSTANTS.COMMON.PLACEHOLDER_COLOR
                    }
                    numberOfLines={1}
                  >
                    {value ? option.label : label}
                  </AppText>
                )}

                {renderRightIcon(value, onChange)}
              </TouchableOpacity>
              {error?.message ? (
                <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                  {error?.message as string}
                </AppText>
              ) : warningMessage ? (
                <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                  {warningMessage}
                </AppText>
              ) : null}
            </View>
          );
        }}
      />
    </View>
  );
};

export default SelectCustom;

const styles = StyleSheet.create({
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    flexDirection: "row",
  },
  inputError: { borderColor: Colors.alert50 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  value: { ...Fonts.BodyLarge, flex: 1 },
  errorText: { marginLeft: 0, marginTop: 4 },
  leftIcon: { marginRight: 10, marginLeft: -5 },
});
