// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useState, FC } from "react";

import { StyleSheet, View, KeyboardAvoidingView, Keyboard, TouchableWithoutFeedback } from "react-native";
import { WhiteSpace, ActivityIndicator } from "@ant-design/react-native";

import Constants from "expo-constants";
import { Formik } from "formik";
import * as Yup from "yup";
import Colors from "app/constants/Colors";
import Layout, { SPACING_DEFAULT, isIphoneX } from "app/constants/Layout";
import { BizziButton } from "app/components";
import { PasswordInputField, PasswordRules } from "app/layouts";
import { passwordComplex } from "app/validations";
import { useAuth } from "app/contexts/AuthContext";
import { NewPasswordScreenRouteProp } from "navigation/type";
import { useTranslation } from "react-i18next";
import { AppText } from "components";

const yupSchema = Yup.object().shape({
  password: passwordComplex,
});

interface INewPasswordScreen {
  route: NewPasswordScreenRouteProp;
}

const NewPasswordScreen: FC<INewPasswordScreen> = ({ route }) => {
  const { forgetPassword } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("screens/Auth/NewPasswordScreen");
  const { email, key } = route?.params;

  const onSubmit = useCallback(
    (values, actions) => {
      setIsLoading(true);
      forgetPassword(email, key, values.password)
        .catch((error) => actions.setStatus({ general: error?.message }))
        .finally(() => {
          setIsLoading(false);
          actions.setSubmitting(false);
        });
    },
    [email, key, setIsLoading, forgetPassword]
  );

  return (
    <Formik
      initialValues={{ phone: "", password: "" }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={yupSchema}
    >
      {(props) => {
        const { values, errors, isValid, dirty, status, handleChange, handleSubmit, validateForm } = props;
        return (
          <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <View style={styles.container}>
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <WhiteSpace size="xl" />
                <PasswordInputField
                  returnKeyType="go"
                  error={errors.password}
                  value={values.password}
                  textContentType="newPassword"
                  label={t("new_password")}
                  onChangeText={handleChange("password")}
                  onSubmitEditing={handleSubmit}
                  clearButtonMode="never"
                />

                <PasswordRules password={values.password} />
                <View style={styles.button}>
                  <AppText style={styles.error}>{status?.general}</AppText>
                  <BizziButton
                    style={{ marginHorizontal: SPACING_DEFAULT }}
                    mode="contained"
                    title={t("reset_your_password")}
                    disabled={!(dirty && isValid)}
                    onPress={handleSubmit}
                  />
                  {isIphoneX() ? (
                    <>
                      <WhiteSpace size="xl" />
                      <WhiteSpace size="md" />
                    </>
                  ) : (
                    <WhiteSpace size="xl" />
                  )}
                </View>
              </View>
            </TouchableWithoutFeedback>
            <ActivityIndicator animating={isLoading} toast size="large" />
          </KeyboardAvoidingView>
        );
      }}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 15,
  },
  button: {
    flex: 1,
    justifyContent: "flex-end",
  },
  error: {
    fontSize: 14,
    color: Colors.warningColor,
  },
});

export default NewPasswordScreen;
