import { AppText } from "components";
import { CONSTANTS } from "constants/constants";
import { FC, ReactElement } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { Fonts } from "theme";
import PlaneTicket from "../components/PlaneTicket";
import { BaggageBooking, FlightTicket } from "../types";
import { getTitleFromLocation, getTitleI18n } from "utils";

type TicketOverviewProps = {
  icon?: ReactElement;
  data: FlightTicket;
  containerStyle?: ViewStyle;
  baggageInfo?: BaggageBooking[]; //baggage info after booked ticket
};

const TicketOverview: FC<TicketOverviewProps> = ({ icon, data, containerStyle, baggageInfo }) => {
  const flight = data?.flights?.[0];
  return flight ? (
    <View style={[styles.overviewSection, containerStyle]}>
      <View style={styles.ticketContainer}>
        <View style={styles.flexRow}>
          {icon}
          <AppText style={[Fonts.H100, styles.flex]}>
            {getTitleI18n({
              en: `${flight?.departure?.location?.nameEn} - ${flight?.arrival?.location?.nameEn}`,
              vi: `${flight?.departure?.location?.name} - ${flight?.arrival?.location?.name}`,
            })}
          </AppText>
        </View>
        <PlaneTicket baggageInfo={baggageInfo} item={data} />
      </View>
    </View>
  ) : null;
};

export default TicketOverview;

const styles = StyleSheet.create({
  overviewSection: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    gap: 16,
  },
  ticketContainer: {
    gap: 8,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  flex: { flex: 1 },
});
