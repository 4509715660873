import { FC, memo } from "react";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";

import { AppText } from "components";
import { ALLOCATED_STATUS } from "constants/constants";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";

const STATUS = {
  [ALLOCATED_STATUS.IS_ALLOCATED]: {
    color: Colors.success0,
    text: "is_allocated",
  },
};

interface StatusExpenseProps {
  value: ALLOCATED_STATUS;
}

const AllocatedStatus: FC<StatusExpenseProps> = ({ value }) => {
  const { t } = useTranslation("app/components/ExpenseItem");
  const [FEATURE_EXP_EXPENSE_ALLOCATION] = useIsFeaturesEnabled([FEATURE_FLAGS.FEATURE_EXP_EXPENSE_ALLOCATION]);
  if (!STATUS[value]?.color || !FEATURE_EXP_EXPENSE_ALLOCATION) {
    return null;
  }
  return (
    <View style={[styles.container, { backgroundColor: STATUS[value].color }]}>
      <AppText style={Fonts.BodySmall}>{t(STATUS[value]?.text)}</AppText>
    </View>
  );
};

export default memo(AllocatedStatus);
const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
  },
});
