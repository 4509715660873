import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={12} height={6} viewBox="0 0 12 6" fill="none">
      <Path d="M12 0H0l4.586 4.586a2 2 0 002.828 0L12 0z" fill="#fff" />
    </Svg>
  );
}

export default SvgComponent;
