import { createContext, useContext } from "react";
import { EmployeeOption } from "./EmployeeOption";

interface IEmployeeChooserContext {
  chosen: EmployeeOption;
  setChosen: (option: EmployeeOption) => void;
  recentChosen: EmployeeOption[];
  setRecentChosen: (options: EmployeeOption[]) => void;
}

export const EmployeeChooserContext = createContext<IEmployeeChooserContext>({} as IEmployeeChooserContext);
export const useEmployeeChooserContext = () => useContext(EmployeeChooserContext);
