import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 67, height = 66, color = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 67 66" fill="none">
      <Path
        d="M39.607 59.484c-3.245 0-7.838-2.283-11.468-13.2l-1.98-5.94-5.94-1.98C9.33 34.734 7.047 30.14 7.047 26.896c0-3.217 2.282-7.837 13.172-11.495L43.567 7.62c5.83-1.953 10.697-1.375 13.695 1.595 2.997 2.97 3.575 7.865 1.622 13.695l-7.782 23.347c-3.658 10.945-8.25 13.228-11.495 13.228zm-18.095-40.15c-7.645 2.557-10.368 5.582-10.368 7.562 0 1.98 2.723 5.005 10.368 7.535l6.93 2.31c.605.193 1.1.688 1.292 1.293l2.31 6.93c2.53 7.645 5.583 10.367 7.563 10.367 1.98 0 5.005-2.722 7.562-10.367l7.783-23.348c1.402-4.235 1.155-7.7-.633-9.487-1.787-1.788-5.252-2.008-9.46-.605l-23.347 7.81z"
        fill={color}
      />
      <Path
        d="M28.306 39.6a2.04 2.04 0 01-1.458-.605 2.075 2.075 0 010-2.915l9.845-9.872a2.075 2.075 0 012.915 0 2.075 2.075 0 010 2.915l-9.845 9.872c-.385.413-.935.605-1.457.605z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
