import { ComponentProps, forwardRef, ForwardedRef } from "react";
import { TextInput as PaperTextInput } from "react-native-paper";
import { StyleSheet, TextInput as RNTextInput } from "react-native";

import { Colors, FontTypes } from "theme";
import { Location } from "assets/images/svg/icons";
import { IconCustom } from "components/index";

export type SearchInputProps = ComponentProps<typeof PaperTextInput>;

const SearchInput = forwardRef(
  ({ theme, left = null, right = null, style, ...props }: SearchInputProps, ref: ForwardedRef<RNTextInput>) => {
    return (
      <PaperTextInput
        dense
        ref={ref}
        mode="outlined"
        style={StyleSheet.flatten([styles.container, style])}
        outlineColor={Colors.grayscale10}
        activeOutlineColor={Colors.primary50}
        theme={{
          roundness: 54,
          colors: {
            text: Colors.grayscale100,
          },
          fonts: {
            regular: {
              fontFamily: FontTypes.regular,
            },
          },
          ...theme,
        }}
        left={
          <PaperTextInput.Icon name={() => (left ? left : <IconCustom name="search" fill={Colors.grayscale60} />)} />
        }
        right={right ? <PaperTextInput.Icon name={() => right} /> : null}
        {...props}
      />
    );
  }
);
//<IconCustom name="search" fill={Colors.grayscale60} />
const styles = StyleSheet.create({
  container: {
    borderColor: Colors.grayscale10,
    backgroundColor: Colors.grayscale0,
  },
});

export default SearchInput;
