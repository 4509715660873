import { Image, Linking, RefreshControl, ScrollView, StyleSheet, View } from "react-native";
import { AppText, Button } from "components";
import { Colors, Fonts } from "theme";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { CONSTANTS } from "constants/constants";
import { CardEmptyState } from "assets/images";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { isUserMod } from "utils";
import { FC } from "react";

const DESCRIPTIONS = ["card_description01", "card_description02", "card_description03"];
interface EmptyStateProps {
  onRefreshData: () => void;
}
const EmptyState: FC<EmptyStateProps> = ({ onRefreshData }) => {
  const { top } = useSafeAreaInsets();
  const { t } = useTranslation("app/screens/Card/CardListScreen");
  const { user } = useAuth();
  const handleRegisterCard = () => {
    Linking.openURL("https://bizzi.vn/the-tin-dung/");
  };
  return (
    <View style={[styles.container, { paddingTop: top + 12 }]}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
        refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefreshData} />}
      >
        <AppText style={Fonts.H500}>{t("card_business_title")}</AppText>
        <AppText style={[Fonts.BodyMedium, styles.description]} color={Colors.grayscale80}>
          {t("card_business_description")}
        </AppText>
        <View>
          <Image source={CardEmptyState} style={styles.emptyImage} />
        </View>
        <View style={styles.descriptionContainer}>
          {DESCRIPTIONS.map((item, index) => (
            <View key={index} style={styles.descriptionItem}>
              <View style={styles.descriptionNumber}>
                <AppText style={Fonts.NumericN300} color={Colors.white}>
                  {index + 1}
                </AppText>
              </View>
              <AppText style={Fonts.H200}>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  t(item)
                }
              </AppText>
            </View>
          ))}
        </View>
        {isUserMod(user) && <Button onPress={handleRegisterCard}>{t("register_card")}</Button>}
      </ScrollView>
    </View>
  );
};
export default EmptyState;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  description: { marginTop: 8 },
  emptyImage: { width: 290, height: 222, marginTop: 32, alignSelf: "center" },
  descriptionContainer: { marginTop: 33, marginBottom: 8, paddingHorizontal: 4 },
  descriptionItem: { flexDirection: "row", alignItems: "center", marginBottom: 24 },
  descriptionNumber: {
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
    backgroundColor: Colors.primary50,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
  contentContainer: { paddingBottom: 20 },
});
