import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MobileBatchRejectReportsMutation, MobileBatchRejectReportsMutationVariables } from "types";

const BATCH_REJECT_REPORTS = gql`
  mutation MobileBatchRejectReports($reportIds: [UUID!]!, $reason: String) {
    expenseRejectReports(report_ids: $reportIds, reason: $reason) {
      reportId
      success
      message
      status
    }
  }
`;
const useBatchRejectReports = () =>
  useMutation<MobileBatchRejectReportsMutation, MobileBatchRejectReportsMutationVariables>(BATCH_REJECT_REPORTS);

export default useBatchRejectReports;
