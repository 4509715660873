// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as auth from "app/utils/authClient";
import createApolloClient from "app/apollo/clientConfig";

async function bootstrapAppData() {
  let appData = { user: null };
  const token = await auth.getToken();
  if (token) {
    const user = await auth.getUser();
    /*-- in the case getUser need refresh token --> get new access token --*/
    const newTokenIfExist = await auth.getToken();
    appData = { user, apolloClient: createApolloClient(newTokenIfExist) };
  }
  return appData;
}

export { bootstrapAppData };
