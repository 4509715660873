import {
  AppText,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  MonthPickerCustom,
} from "components";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { TIME_FILTER_OPTIONS } from "../types";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import dayjs from "dayjs";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";

interface SelectTimeCustomizeModalProps {
  value?: TIME_FILTER_OPTIONS;
  setFromDate?: (date: Date) => void;
  setToDate?: (date: Date) => void;
  handleApplyFilter: () => void;
}
const SelectTimeCustomizeModal = forwardRef<BottomSheetScrollViewModalCustomMethods, SelectTimeCustomizeModalProps>(
  ({ handleApplyFilter, setFromDate, setToDate }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
      },
      close: () => {
        bottomSheetRef?.current?.close?.();
      },
    }));
    const { t } = useTranslation("app/screens/ExpenseOverview/ExpenseOverviewScreen");
    const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
    const [currentFromDate, setCurrentFromDate] = useState(dayjs().subtract(2, "month").toDate());
    const [currentToDate, setCurrentToDate] = useState(dayjs().toDate());
    const [visibleFromDate, setVisibleFromDate] = useState(false);
    const [visibleToDate, setVisibleToDate] = useState(false);

    const handleApply = () => {
      bottomSheetRef?.current?.close();
      setFromDate(currentFromDate);
      setToDate(currentToDate);
      handleApplyFilter();
    };
    return (
      <>
        <BottomSheetScrollViewModalCustom
          ref={bottomSheetRef}
          containerStyle={{ paddingBottom: 100, paddingTop: 10 }}
          title={t("customize")}
          wrapperByScrollView={false}
          renderFooter={() => <Button onPress={handleApply}>{t("apply")}</Button>}
        >
          <View style={styles.body}>
            <View style={styles.rowItem}>
              <AppText style={Fonts.BodyLarge}>{t("from_month")}</AppText>
              <TouchableOpacity style={styles.selectTimeButton} onPress={() => setVisibleFromDate(true)}>
                <AppText style={Fonts.BodyLarge} color={Colors.primary50}>
                  {dayjs(currentFromDate).format("MM/YYYY")}
                </AppText>
              </TouchableOpacity>
            </View>
            <View style={[styles.rowItem, { marginBottom: 20 }]}>
              <AppText style={Fonts.BodyLarge}>{t("to_month")}</AppText>
              <TouchableOpacity style={styles.selectTimeButton} onPress={() => setVisibleToDate(true)}>
                <AppText style={Fonts.BodyLarge} color={Colors.primary50}>
                  {dayjs(currentToDate).format("MM/YYYY")}
                </AppText>
              </TouchableOpacity>
            </View>
          </View>
        </BottomSheetScrollViewModalCustom>
        <MonthPickerCustom
          visible={visibleFromDate}
          setVisible={setVisibleFromDate}
          maximumDate={currentToDate}
          okButton={t("confirm")}
          cancelButton={t("cancel")}
          value={dayjs(currentFromDate).toDate()}
          locale={i18n.language === LanguageStatus.EN ? "en" : "vi"}
          onChange={(event: string, date: Date) => {
            setVisibleFromDate(false);
            setCurrentFromDate(date);
          }}
          setValue={setCurrentFromDate}
        />
        <MonthPickerCustom
          visible={visibleToDate}
          setVisible={setVisibleToDate}
          minimumDate={currentFromDate}
          okButton={t("confirm")}
          cancelButton={t("cancel")}
          value={dayjs(currentToDate).toDate()}
          locale={i18n.language === LanguageStatus.EN ? "en" : "vi"}
          onChange={(event: string, date: Date) => {
            setVisibleToDate(false);
            setCurrentToDate(date);
          }}
          setValue={setCurrentToDate}
        />
      </>
    );
  }
);
export default SelectTimeCustomizeModal;
const styles = StyleSheet.create({
  rowItem: {
    flexDirection: "row",
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectTimeButton: {
    width: 115,
    borderRadius: 4,
    paddingVertical: 4,
    backgroundColor: Colors.grayscale05,
    justifyContent: "center",
    alignItems: "center",
  },
  body: { paddingHorizontal: 20 },
});
