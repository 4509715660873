import * as React from "react";
import Svg, { Ellipse, Path, Rect } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg width={273} height={123} viewBox="0 0 273 123" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0991 12.8721C90.0991 5.76304 95.8621 0 102.971 0H260.127C267.236 0 273 5.76304 273 12.8721C273 19.9812 267.237 25.7442 260.127 25.7442H201.96C204.876 28.1041 206.741 31.7122 206.741 35.7559C206.741 42.8089 201.068 48.5369 194.037 48.627H230.982C238.091 48.627 243.854 54.39 243.854 61.4991C243.854 68.6082 238.091 74.3712 230.982 74.3712H77.4051C84.4404 74.2854 90.1171 68.5556 90.1171 61.5C90.1171 54.391 84.354 48.6279 77.2449 48.6279H36.6612C29.5756 48.6004 23.8401 42.848 23.8401 35.7559C23.8401 28.6468 29.6032 22.8838 36.7122 22.8838H94.8799C91.9636 20.524 90.0991 16.9158 90.0991 12.8721ZM0 87.245C0 80.1359 5.76305 74.3729 12.8721 74.3729H196.536C197.169 74.3729 197.791 74.4186 198.4 74.5068H182.359C176.049 74.5068 170.934 79.6217 170.934 85.9312C170.934 92.2407 176.049 97.3556 182.359 97.3556H214.043C220.392 98.1478 225.305 103.564 225.305 110.128C225.305 117.237 219.542 123 212.433 123H73.8316C66.7225 123 60.9594 117.237 60.9594 110.128C60.9594 106.085 62.8236 102.477 65.7393 100.117H12.8721C5.76306 100.117 0 94.3541 0 87.245Z"
      fill="#F0F6FF"
    />
    <Rect x={192.984} y={101.52} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
    <Rect x={192.984} y={111.936} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={195.61}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05865}
      transform="rotate(-90 195.61 111.936)"
      fill="#75A4FE"
    />
    <Rect
      x={183.836}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05865}
      transform="rotate(-90 183.836 111.936)"
      fill="#75A4FE"
    />
    <Path
      d="M188.632 78.9775C188.632 81.434 186.63 83.4335 184.151 83.4335C181.671 83.4335 179.669 81.434 179.669 78.9775C179.669 76.521 181.671 74.5215 184.151 74.5215C186.63 74.5215 188.632 76.521 188.632 78.9775Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.01559}
    />
    <Path
      d="M114.957 84.6527V37.2246C114.957 34.0441 117.535 31.4658 120.716 31.4658H153.744C156.925 31.4658 159.503 34.0441 159.503 37.2246V85.0792C159.503 88.9848 155.093 91.2607 151.91 88.9976C150.403 87.9264 148.415 87.8141 146.797 88.7087L139.47 92.7604C137.643 93.7704 135.414 93.7131 133.641 92.6107L127.591 88.8471C126.013 87.8653 124.015 87.8598 122.432 88.8328C119.936 90.3661 116.895 89.3582 115.595 87.0887C115.193 86.3852 114.957 85.5605 114.957 84.6527Z"
      fill="#F0F6FF"
    />
    <Ellipse cx={73.3825} cy={31.0569} rx={4.25457} ry={4.23462} fill="#75A4FE" />
    <Rect x={96.6133} y={5.29297} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
    <Rect x={96.7451} y={15.2559} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={98.7393}
      y={14.8213}
      width={2.11731}
      height={7.44551}
      rx={1.05865}
      transform="rotate(-90 98.7393 14.8213)"
      fill="#75A4FE"
    />
    <Path
      d="M90.2256 15.2559C89.641 15.2559 89.167 14.7819 89.167 14.1972V14.1972C89.167 13.6125 89.641 13.1385 90.2256 13.1385L95.5538 13.1385C96.1385 13.1385 96.6125 13.6125 96.6125 14.1972V14.1972C96.6125 14.7819 96.1385 15.2559 95.5538 15.2559L90.2256 15.2559Z"
      fill="#75A4FE"
    />
    <Ellipse cx={128.657} cy={67.1718} rx={31.2235} ry={31.0771} fill="white" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.049 58.6247C151.836 60.9954 150.233 63.2194 148.24 65.2026C138.046 75.3494 121.517 75.3494 111.322 65.2026C108.397 62.2911 106.311 58.8606 105.065 55.2088C100.069 64.9676 101.672 77.2121 109.874 85.3757C120.068 95.5225 136.597 95.5225 146.792 85.3757C154.061 78.1404 156.147 67.6996 153.049 58.6247Z"
      fill="#F0F6FF"
    />
    <Path
      d="M120.468 73.0307C120.468 75.9958 122.771 78.38 125.597 78.38H131.37C133.827 78.38 135.824 76.3014 135.824 73.7032C135.824 70.9215 134.595 69.9128 132.783 69.2709L123.539 66.0613C121.727 65.4194 120.499 64.4412 120.499 61.629C120.499 59.0613 122.495 56.9521 124.952 56.9521H130.725C133.551 56.9521 135.854 59.3364 135.854 62.3015"
      stroke="#1F71F4"
      strokeWidth={2.69326}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M128.146 53.9258V81.4366"
      stroke="#1F71F4"
      strokeWidth={2.69326}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M158.857 67.6814C158.857 84.5547 145.098 98.249 128.145 98.249C111.192 98.249 97.4336 84.5547 97.4336 67.6814C97.4336 50.8081 109.718 42.2084 111.765 41.1895"
      stroke="#1F71F4"
      strokeWidth={2.69326}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M158.857 49.3403V37.1133H146.572"
      stroke="#1F71F4"
      strokeWidth={2.69326}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M143.501 52.3971L158.857 37.1133"
      stroke="#1F71F4"
      strokeWidth={2.69326}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M115.907 38.6923C118.3 37.6667 124.318 35.7179 129.241 36.1282"
      stroke="#1F71F4"
      strokeWidth={2.69326}
      strokeLinecap="round"
    />
  </Svg>
);
export default SVGComponent;
