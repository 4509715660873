import { useNavigation } from "@react-navigation/native";
import { FlashList } from "@shopify/flash-list";
import { memo, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RefreshControl, StyleSheet, TouchableOpacity, View } from "react-native";

import { EmptyExpenseReport } from "assets/images/svg/icons";
import { BackHeader, EmptyData, FlatListCustom, IconCustom, SkeletonListLoading } from "components";
import { CONSTANTS, KIND_OF_APPROVAL } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import { NewReportScreenNavigationProp } from "navigation/type";
import { Colors } from "theme";
import ReportItem from "../ReportListScreen/components/ReportItem";
import useExpenseReportsHistory from "screens/Report/HistoryReportScreen/hooks/useExpenseReportsHistory";
import { EVENT } from "constants/Tracking";

const HistoryReportScreen = () => {
  const navigation = useNavigation<NewReportScreenNavigationProp | any>();
  const flashListRef = useRef<FlashList<any>>(null);

  const {
    user: {
      employee_id: employeeId,
      company: { company_id: companyId },
    },
  } = useAuth();
  const { t } = useTranslation("app/screens/Report/HistoryReportScreen");
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("title")}
          headerRight={
            <TouchableOpacity
              onPress={() =>
                navigation.navigate(SCREEN_NAME.ReportSearchScreen, {
                  fromScreen: SCREEN_NAME.HistoryReportScreen,
                  iApprovedOrRejected: true,
                })
              }
            >
              <IconCustom name="search" />
            </TouchableOpacity>
          }
        />
      ),
    } as any);
  }, []);
  const { data, loading, refetch, fetchMore, called } = useExpenseReportsHistory({
    where: {
      companyId,
      companyEmployeeId: employeeId,
      kindOfApproval: KIND_OF_APPROVAL.PARTICIPATED,
    },
    limit: 10,
    offset: 0,
  });
  const reportList = data?.expExpenseReportsHistory?.reports ?? [];
  const total = data?.expExpenseReportsHistory?.total ?? 0;
  const onRefresh = () => {
    refetch?.();
  };
  const handleLoadMore = () => {
    if (loading || reportList?.length === 0 || reportList?.length >= total) {
      return;
    }
    fetchMore?.({
      variables: { offset: reportList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expExpenseReportsHistory: {
            reports: [
              ...prev?.expExpenseReportsHistory?.reports,
              ...fetchMoreResult?.expExpenseReportsHistory?.reports,
            ],
            total: fetchMoreResult?.expExpenseReportsHistory?.total,
          },
        };
      },
    });
  };

  const handleClickItem = (item) => () => {
    const { expenseReportId } = item;
    navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
      expenseReportId: expenseReportId,
      onRefreshData: () => {
        flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
        refetch?.();
      },
    });
  };

  const renderItem = ({ item }) => (
    <ReportItem eventName={EVENT.REPORT.TAP_VIEW_DETAIL} showCreator onPress={handleClickItem(item)} item={item} />
  );

  const renderEmpty = () => {
    if (loading || !called) {
      return null;
    }
    return (
      <EmptyData
        icon={<EmptyExpenseReport />}
        title={t("empty_expense_report")}
        description={t("empty_expense_report_description")}
      />
    );
  };

  const renderFooter = () => {
    if (loading && reportList?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };

  return (
    <View style={styles.container}>
      {loading && !data ? (
        <SkeletonListLoading />
      ) : (
        <FlatListCustom
          ref={flashListRef}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefresh} />}
          keyExtractor={(item, index) => item?.expenseReportId ?? index}
          data={reportList}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          estimatedItemSize={88}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
    </View>
  );
};
export default memo(HistoryReportScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 50,
  },
  loadingMore: { marginTop: 10 },
});
