import { Section, ToastManager, UploadFileView } from "components";
import { useTranslation } from "react-i18next";
import { UploadFileItem } from "components/UploadFiles/types";
import UploadFiles from "components/InputCustom/UploadFiles/UploadFiles";
import { COMPANY_ATTACHMENT_OBJECT_TYPES, COMPANY_ATTACHMENT_TYPES, EXPENSE_REPORT_STATUS } from "constants/constants";
import { useForm } from "react-hook-form";
import { Toast } from "@ant-design/react-native";
import { FC, useEffect, useMemo } from "react";
import AttachmentType from "constants/AttachmentType";
import { useInsertCompanyAttachments } from "screens/Expense/hooks/useInsertCompanyAttachments";
import useUploadFileSignedURL from "hooks/useUploadFileSignedURL";
import { useAuth } from "contexts/AuthContext";
import { useDeleteCompanyAttachments } from "screens/Expense/hooks/useDeleteCompanyAttachments";
import { MobileExpenseReportDetailsQuery } from "types";
import { isUserMod } from "utils";
import { ImageInfo } from "screens/Expense/components/ExpenseForm/types";
import useExpGenerateReportDownloadFile from "screens/Report/hooks/useExpGenerateReportDownloadFile";

interface SupportingDocumentProps {
  expenseReport?: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
  refetch?: () => void;
}
const SupportingDocument: FC<SupportingDocumentProps> = ({ expenseReport, refetch }) => {
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
  const [insertCompanyAttachments] = useInsertCompanyAttachments();
  const [deleteCompanyAttachments] = useDeleteCompanyAttachments();
  const { generateExpReportDownloadFile } = useExpGenerateReportDownloadFile();

  const { uploadImage } = useUploadFileSignedURL();
  const { company, user } = useAuth();

  const supportingDocuments = useMemo<UploadFileItem[]>(() => {
    if (!expenseReport) {
      return [];
    }
    return (
      expenseReport.companyAttachments?.map((att) => ({
        id: att.companyAttachmentId,
        uri: att.fileUrl,
        name: att.fileName,
        size: att.fileSize,
        fileType: att.mimeType,
        createdAt: att.createdAt,
      })) ?? []
    );
  }, [expenseReport]);

  const { control, setValue } = useForm<{ supportingDocuments: ImageInfo[] }>({
    defaultValues: { supportingDocuments: [] },
  });

  useEffect(() => {
    if (supportingDocuments?.length) {
      setValue("supportingDocuments", supportingDocuments);
    }
  }, [supportingDocuments?.length, setValue]);
  const handleGenerateReportAttachments = async () => {
    await generateExpReportDownloadFile({
      input: {
        companyId: company.company_id,
        expenseReportId: expenseReport.expenseReportId,
        state: "GENERATE_ZIP",
      },
    });
    refetch();
  };
  const onChangeFile = async (file) => {
    try {
      Toast.loading("");
      const data = await uploadImage({
        objectId: expenseReport?.expenseReportId,
        images: [file],
        companyId: company.company_id,
        subAttachmentType: AttachmentType.TYPE_REQUEST,
      });
      const { file_name: fileName, file_url: fileUrl, file_type: filetype, file_size: fileSize } = data[0];
      await insertCompanyAttachments({
        variables: {
          input: {
            companyId: company.company_id,
            objectId: expenseReport?.expenseReportId,
            objectType: COMPANY_ATTACHMENT_OBJECT_TYPES.EXPENSE_REPORT,
            attachments: [
              {
                attachmentType: COMPANY_ATTACHMENT_TYPES.SUPPORT_DOCUMENT,
                fileUrl,
                thumbnailUrl: fileUrl,
                fileName,
                fileSize,
                mimeType: filetype,
              },
            ],
          },
        },
      });
      await handleGenerateReportAttachments();
      ToastManager.success(t("update_supporting_documents_success"));
    } catch (e) {
    } finally {
      Toast.removeAll();
    }
  };

  const onRemoveFile = async (index) => {
    try {
      Toast.loading("");
      await deleteCompanyAttachments({
        variables: {
          input: {
            attachmentIds: [supportingDocuments[index].id],
            companyId: company.company_id,
            objectId: expenseReport?.expenseReportId,
            objectType: COMPANY_ATTACHMENT_OBJECT_TYPES.EXPENSE_REPORT,
          },
        },
      });
      await handleGenerateReportAttachments();
      ToastManager.success(t("update_supporting_documents_success"));
    } catch (e) {
    } finally {
      Toast.removeAll();
    }
  };

  const statusCanUploadSupportingDocuments =
    expenseReport?.status === EXPENSE_REPORT_STATUS.SUBMITTED ||
    expenseReport?.status === EXPENSE_REPORT_STATUS.APPROVED;
  const isApproverForRequest = useMemo(() => {
    return expenseReport?.expenseApprovalRules?.some((item) => {
      return item?.expenseApprovalRuleEmployees?.some((i) => i?.employeeId === user?.employee_id);
    });
  }, [expenseReport]);
  const personCanUploadSupportingDocuments =
    isUserMod(user) || expenseReport?.companyEmployee?.employeeId === user?.employee_id || isApproverForRequest;
  const isCanUploadSupportingDocuments = statusCanUploadSupportingDocuments && personCanUploadSupportingDocuments;

  if (!supportingDocuments?.length && !isCanUploadSupportingDocuments) {
    return null;
  }

  if (isCanUploadSupportingDocuments) {
    return (
      <Section
        title={
          supportingDocuments.length
            ? `${t("supporting_documents")} (${supportingDocuments.length})`
            : t("supporting_documents")
        }
      >
        <UploadFiles
          onChangeFile={onChangeFile}
          onRemoveFile={onRemoveFile}
          name="supportingDocuments"
          title={t("supporting_documents")}
          control={control}
        />
      </Section>
    );
  }

  return (
    <Section title={`${t("supporting_documents")} (${supportingDocuments.length})`}>
      <UploadFileView
        maxFile={6}
        files={supportingDocuments}
        title={`${t("supporting_documents")} (${supportingDocuments.length})`}
      />
    </Section>
  );
};
export default SupportingDocument;
