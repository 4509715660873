import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ size }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 0c-.825 0-1.5.675-1.5 1.5v21c0 .825.675 1.5 1.5 1.5h15c.825 0 1.5-.675 1.5-1.5V6l-6-6H6z"
        fill="#E2E5E7"
      />
      <Path d="M18 6h4.5l-6-6v4.5c0 .825.675 1.5 1.5 1.5z" fill="#B0B7BD" />
      <Path d="M22.5 10.5L18 6h4.5v4.5z" fill="#CAD1D8" />
      <Path
        d="M19.5 19.5c0 .413-.337.75-.75.75H2.25a.752.752 0 01-.75-.75V12c0-.412.337-.75.75-.75h16.5c.413 0 .75.338.75.75v7.5z"
        fill="#50BEE8"
      />
      <Path
        d="M6.152 15.292l1.044-1.308c.312-.407.918.114.576.504-.359.426-.737.888-1.098 1.36l1.224 1.524c.33.45-.33.882-.654.438l-1.103-1.415-1.085 1.445c-.306.437-.984-.054-.641-.462l1.205-1.53c-.378-.472-.744-.934-1.11-1.36-.377-.45.325-.91.6-.49l1.042 1.294zM9.432 15.369v2.218c0 .265-.216.414-.431.414-.192 0-.36-.149-.36-.414v-3.375a.4.4 0 01.36-.415c.173 0 .275.103.377.217l1.32 1.78 1.366-1.847c.2-.245.684-.15.684.265v3.375c0 .265-.168.414-.359.414-.216 0-.384-.149-.384-.414V15.37l-.996 1.26c-.215.264-.485.264-.683 0l-.894-1.26zM13.793 14.214c0-.198.168-.366.378-.366.192 0 .354.168.354.366v3.004h1.631c.588 0 .6.785 0 .785h-1.984c-.21 0-.378-.149-.378-.366v-3.423h-.001z"
        fill="#fff"
      />
      <Path d="M18.75 20.25H4.5V21h14.25c.413 0 .75-.337.75-.75v-.75c0 .413-.337.75-.75.75z" fill="#CAD1D8" />
    </Svg>
  );
}

export default SvgComponent;
