import React, { ChangeEvent, ReactNode, useEffect, useRef } from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { ArrowDownIcon, CloseSearchIcon } from "assets/images/svg/icons";
import {
  AppText,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  IconCustom,
} from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts, FontTypes } from "theme";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface PickerItem {
  label: string;
  value: string | number | ChangeEvent<any>;
  icon?: ReactNode;
}

interface PickerCustomProps {
  style?: ViewStyle;
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  label?: string;
  title?: string;
  options?: PickerItem[];
  renderPickerItem?: (item: PickerItem, onSelect: () => void) => ReactNode;
  autoOpen?: boolean;
  snapPoints?: number[] | string[];
  required?: boolean;
}

const PickerCustom: React.FC<PickerCustomProps> = ({
  style,
  name,
  rules,
  control,
  error,
  label,
  options,
  renderPickerItem,
  title,
  autoOpen = false,
  required = false,
}) => {
  const onChangeCompanyTeam = useRef(null);

  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>(null);
  useEffect(() => {
    if (autoOpen) {
      handleOpenModal();
    }
  }, [autoOpen]);
  const handleOpenModal = () => {
    bottomSheetRef.current?.present();
  };
  const getLabelByValue = (value) => {
    return options?.find((item) => item?.value === value)?.label ?? "";
  };
  return (
    <View style={style}>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          const pickerItem = value as PickerItem;
          return (
            <>
              <View style={style}>
                <TouchableOpacity
                  onPress={() => {
                    onChangeCompanyTeam.current = onChange;
                    handleOpenModal();
                  }}
                  style={[styles.input, error?.message && { borderColor: Colors.alert50 }]}
                >
                  {Boolean(value) && <AppText style={styles.label}>{label}</AppText>}
                  <AppText
                    style={styles.companyTeamValue}
                    color={
                      error?.message
                        ? Colors.alert50
                        : value
                        ? CONSTANTS.COMMON.TEXT_INPUT_COLOR
                        : CONSTANTS.COMMON.PLACEHOLDER_COLOR
                    }
                    numberOfLines={1}
                  >
                    {pickerItem ? getLabelByValue(pickerItem) : label}
                  </AppText>
                  {!value || required ? (
                    <IconCustom name="expand-more" />
                  ) : (
                    <TouchableOpacity onPress={() => onChange(null)}>
                      <IconCustom name="cancel" />
                    </TouchableOpacity>
                  )}
                </TouchableOpacity>
                {Boolean(error?.message) && (
                  <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                    {error?.message as string}
                  </AppText>
                )}
              </View>
              <BottomSheetScrollViewModalCustom ref={bottomSheetRef} title={title}>
                {options?.map((item) => {
                  return renderPickerItem(item, () => {
                    bottomSheetRef?.current?.close();
                    onChange(item?.value as any);
                  });
                })}
              </BottomSheetScrollViewModalCustom>
            </>
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 13,
    flexDirection: "row",
  },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  companyTeamValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
});

export default PickerCustom;
