import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#7F8A93", size = 16, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.195 2.862a.667.667 0 01.471-.195h4v2A1.333 1.333 0 0010 6h2v4h1.333V5.333a.667.667 0 00-.195-.471L9.805 1.529a.667.667 0 00-.472-.196H4.666a2 2 0 00-2 2V10H4V3.333c0-.177.07-.346.195-.471zm6.862 1.805L10 3.609v1.058h1.057z"
        fill={color}
      />
      <Path
        d="M6 4.667A.667.667 0 106 6h.667a.667.667 0 000-1.333H6zM6 7.333a.667.667 0 000 1.334h4a.667.667 0 000-1.334H6z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.667 14v-4H4v2.754l.963-.642a.667.667 0 01.842.083l.862.862.861-.862c.26-.26.683-.26.943 0l.862.862.862-.862a.667.667 0 01.841-.083l.964.642V10h1.333v4a.667.667 0 01-1.036.555l-1.546-1.03-.946.946a.667.667 0 01-.943 0L8 13.61l-.862.861a.667.667 0 01-.943 0l-.947-.947-1.545 1.03A.667.667 0 012.667 14z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10.667c0-.368.298-.667.666-.667H10a.667.667 0 010 1.333H8.666A.667.667 0 018 10.667z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
