import { useQuery } from "@apollo/client";

import gql from "graphql-tag";
import { useCallback, useEffect } from "react";
import useGetEmployeePolicies from "./request/useGetEmployeePolicies";

export const QUERY_EXPENSE_CATEGORIES = gql`
  query MobileExpenseCategories($expense_policy_id: uuid!) {
    expense_policies_categories(
      where: {
        is_active: { _eq: true }
        expense_policy_id: { _eq: $expense_policy_id }
        expense_category: { deleted_timestamp: { _eq: 0 } }
      }
      order_by: { order: asc }
    ) {
      order
      expense_policies_categories_id
      expense_category_id
      description_hint
      currency
      account_code
      max_amount

      require_description
      expense_category {
        title
        title_en
        account_code
        expense_category_id
        code
      }
    }
    expense_policies_categories_aggregate(
      where: {
        is_active: { _eq: true }
        expense_policy_id: { _eq: $expense_policy_id }
        expense_category: { deleted_by: { _is_null: true } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
const useQueryExpenseCategory = (skip = false) => {
  const { data: policyId, loading: loadingPolicy } = useGetEmployeePolicies(skip);
  const { data, loading, refetch } = useQuery<{
    expense_policies_categories: ExpensePoliciesCategories[];
    expense_policies_categories_aggregate: ExpensePoliciesAggregate;
  }>(QUERY_EXPENSE_CATEGORIES, {
    variables: { expense_policy_id: policyId },
    skip: !policyId || skip,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (policyId) {
      refetch();
    }
  }, [policyId, refetch]);

  const getExpenseCategory = useCallback(
    (expenseCategoryId: string) => {
      return data?.expense_policies_categories?.find((cat) => cat.expense_category_id === expenseCategoryId);
    },
    [data?.expense_policies_categories]
  );

  return {
    data: data?.expense_policies_categories ?? [],
    totalCategory: data?.expense_policies_categories_aggregate?.aggregate?.count ?? 0,
    loading: loading || loadingPolicy,
    getExpenseCategory,
  };
};

export default useQueryExpenseCategory;
