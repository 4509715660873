import { FC, ReactNode } from "react";
import { LayoutChangeEvent, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Colors } from "theme";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface FloatFooterProps {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  onLayout?: (e: LayoutChangeEvent) => void;
  enableShadow?: boolean;
}
const FloatFooter: FC<FloatFooterProps> = ({ children, style, onLayout, enableShadow = true }) => {
  const { bottom } = useSafeAreaInsets();
  return (
    <View
      style={[styles.container, enableShadow && styles.shadow, { paddingBottom: bottom || 10 }, style]}
      onLayout={onLayout}
    >
      {children}
    </View>
  );
};
export default FloatFooter;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    backgroundColor: Colors.white,
    width: "100%",
    paddingTop: 8,
    paddingHorizontal: 20,
  },
  shadow: {
    shadowColor: Colors.grayscale100,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 4,
  },
});
