import * as React from "react";
import Svg, { Ellipse, Path, Rect } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg width={273} height={123} viewBox="0 0 273 123" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0991 12.8721C90.0991 5.76304 95.8621 0 102.971 0H260.127C267.236 0 273 5.76304 273 12.8721C273 19.9812 267.237 25.7442 260.127 25.7442H201.96C204.876 28.1041 206.741 31.7122 206.741 35.7559C206.741 42.8089 201.068 48.5369 194.037 48.627H230.982C238.091 48.627 243.854 54.39 243.854 61.4991C243.854 68.6082 238.091 74.3712 230.982 74.3712H77.4051C84.4404 74.2854 90.1171 68.5556 90.1171 61.5C90.1171 54.391 84.354 48.6279 77.2449 48.6279H36.6612C29.5756 48.6004 23.8401 42.848 23.8401 35.7559C23.8401 28.6468 29.6032 22.8838 36.7122 22.8838H94.8799C91.9636 20.524 90.0991 16.9158 90.0991 12.8721ZM0 87.245C0 80.1359 5.76305 74.3729 12.8721 74.3729H196.536C197.169 74.3729 197.791 74.4186 198.4 74.5068H191.341C185.032 74.5068 179.917 79.6217 179.917 85.9312C179.917 92.2407 185.032 97.3556 191.341 97.3556H214.043C220.392 98.1478 225.305 103.564 225.305 110.128C225.305 117.237 219.542 123 212.433 123H73.8316C66.7225 123 60.9594 117.237 60.9594 110.128C60.9594 106.085 62.8236 102.477 65.7393 100.117H12.8721C5.76306 100.117 0 94.3541 0 87.245Z"
      fill="#F0F6FF"
    />
    <Rect x={192.984} y={101.52} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
    <Rect x={192.984} y={111.936} width={2.12729} height={7.8122} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={195.61}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05865}
      transform="rotate(-90 195.61 111.936)"
      fill="#75A4FE"
    />
    <Rect
      x={183.836}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05865}
      transform="rotate(-90 183.836 111.936)"
      fill="#75A4FE"
    />
    <Path
      d="M188.674 74.3723C188.674 76.9708 186.556 79.0855 183.933 79.0855C181.311 79.0855 179.193 76.9708 179.193 74.3723C179.193 71.7738 181.311 69.6592 183.933 69.6592C186.556 69.6592 188.674 71.7738 188.674 74.3723Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.01559}
    />
    <Ellipse cx={82.7849} cy={37.054} rx={4.25457} ry={4.23462} fill="#75A4FE" />
    <Rect x={96.6133} y={5.29297} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
    <Rect x={96.7451} y={15.2559} width={2.12729} height={7.41058} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={98.7393}
      y={14.8213}
      width={2.11731}
      height={7.44551}
      rx={1.05865}
      transform="rotate(-90 98.7393 14.8213)"
      fill="#75A4FE"
    />
    <Path
      d="M90.2256 15.2559C89.641 15.2559 89.167 14.7819 89.167 14.1972V14.1972C89.167 13.6125 89.641 13.1385 90.2256 13.1385L95.5538 13.1385C96.1385 13.1385 96.6125 13.6125 96.6125 14.1972V14.1972C96.6125 14.7819 96.1385 15.2559 95.5538 15.2559L90.2256 15.2559Z"
      fill="#75A4FE"
    />
    <Rect x={101} y={39} width={55} height={47} rx={11.6006} fill="white" />
    <Path
      d="M94.9336 49.8038C94.9336 37.8867 101.775 32.7793 112.038 32.7793H146.248C156.511 32.7793 163.353 37.8867 163.353 49.8038V73.6382C163.353 85.5554 156.511 90.6627 146.248 90.6627H112.038"
      stroke="#1F71F4"
      strokeWidth={3.1638}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M146.248 51.5068L135.54 60.0191C132.016 62.8111 126.235 62.8111 122.711 60.0191L112.038 51.5068"
      stroke="#1F71F4"
      strokeWidth={3.1638}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M94.9336 77.043H115.459"
      stroke="#1F71F4"
      strokeWidth={3.1638}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M94.9336 63.4238H105.196"
      stroke="#1F71F4"
      strokeWidth={3.1638}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SVGComponent;
