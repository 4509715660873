import Svg, { Rect, Path, SvgProps } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, color = "#94A3B8" }: SvgProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Rect x={15.2695} y={2} width={2} height={18} rx={0.5} fill={color} />
      <Rect x={12} y={4} width={7} height={2} rx={0.5} fill={color} />
      <Rect x={6.26953} y={21} width={11} height={2} rx={0.5} fill={color} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.38 20c2.97 0 5.379-2.91 5.379-6.5S14.35 7 11.379 7C8.41 7 6 9.91 6 13.5S8.408 20 11.38 20zm.12-2c1.933 0 3.5-2.015 3.5-4.5S13.433 9 11.5 9 8 11.015 8 13.5 9.567 18 11.5 18z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
