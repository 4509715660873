import * as Sentry from "@sentry/react-native";

import Config from "app/config";
import { Platform } from "react-native";
import * as Updates from "expo-updates";
import app from "../../app.json";

type CategoryEvent = "screen_view";
const isEnableSentry = !__DEV__ && Platform.OS !== "web";

class SentryService {
  init() {
    if (isEnableSentry) {
      const appVersion = app.expo.version;
      const updateId = Updates.updateId;
      let releaseName = "";
      if (Platform.OS === "android") {
        const androidVersionCode = app.expo.android?.versionCode;
        const androidPackageName = Config.APP_PACKAGE_NAME;
        releaseName = `${androidPackageName}@${appVersion}+${androidVersionCode}`;
      } else {
        const iosBuildNumber = app.expo.ios?.buildNumber;
        const iosBundleID = Config.APP_PACKAGE_NAME;
        releaseName = `${iosBundleID}@${appVersion}+${iosBuildNumber}`;
      }
      Sentry.init({
        dsn: "https://09efe81095614d26983654d7817673b2@o403930.ingest.sentry.io/5640889",
        debug: !Config.IS_PRODUCTION || __DEV__,
        release: releaseName,
        dist: updateId,
        tracesSampleRate: 1.0,
      });
    }
  }
  setUser({ id, username, email }) {
    if (isEnableSentry) {
      Sentry.setUser({ id, username, email });
    }
  }
  logout() {
    if (isEnableSentry) {
      Sentry.setUser(null);
    }
  }
  logEvent(category: CategoryEvent, message: string) {
    if (isEnableSentry) {
      Sentry.addBreadcrumb({
        category,
        message,
        level: "info",
      });
    }
  }
  captureException(exception) {
    if (isEnableSentry) {
      Sentry.withScope(function (scope) {
        scope.setLevel("info");
        Sentry.captureException(exception);
      });
    }
  }
}

export const sentryService = new SentryService();
