import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = ({ width = 18, height = 9, color = "#1F71F4" }) => (
  <Svg width={width} height={height} viewBox="0 0 18 9" fill="none">
    <Path
      d="M16.9181 8.05063L10.3981 1.53062C9.62812 0.760625 8.36813 0.760625 7.59813 1.53062L1.07812 8.05063"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SVGComponent;
