import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

export const EXPENSE_REQUEST_OTP = gql`
  mutation MobileRequestOTP($objects: ExpenseRequestOtpInput!) {
    expenseRequestOTP(objects: $objects) {
      challenge_id
      expires_at
    }
  }
`;

const useExpenseRequestOTP = () => {
  const [mutation, results] = useMutation(EXPENSE_REQUEST_OTP);

  const onRequestOTP = useCallback(
    async (objects) => {
      return await mutation({
        variables: {
          objects,
        },
      });
    },
    [mutation]
  );

  return {
    onRequestOTP,
    ...results,
  };
};

export default useExpenseRequestOTP;
