import { AppText, ImageCustom } from "components";
import { ApprovalFlowManualState } from "assets/images";
import { StyleSheet, View } from "react-native";
import { Fonts } from "theme";

const ManualState = ({ title }) => {
  return (
    <View style={styles.container}>
      <ImageCustom
        style={{
          width: 350,
          height: 350 / 1.4,
          alignSelf: "center",
        }}
        source={ApprovalFlowManualState}
      />
      <AppText style={styles.description}>{title}</AppText>
    </View>
  );
};
export default ManualState;
const styles = StyleSheet.create({
  container: { marginTop: 20 },
  description: { ...Fonts.BodyMedium, marginTop: 20, textAlign: "center" },
});
