import { FC, useMemo } from "react";
import { StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import ContentLoader, { Rect } from "react-content-loader/native";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { AppText, CurrencyText, IconCustom } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { ArrowTimeIcon } from "assets/images/svg/icons";
import { MobileQueryCrdCardQuery } from "types";

const ProgressBarCard = ({ value, total }) => {
  const percent = (value / total) * 100;
  return (
    <View style={styles.progressBarContainer}>
      <View
        style={{ width: `${percent}%`, height: 6, backgroundColor: percent > 20 ? Colors.success50 : Colors.warning50 }}
      />
    </View>
  );
};

const CardLoading = () => {
  const { width } = useWindowDimensions();
  return (
    <View>
      <View style={styles.cardItem}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: 22 }}>
          <ContentLoader animate={false} width={110} height={30}>
            <Rect x="0" y="0" rx="3" ry="3" width={110} height={30} />
          </ContentLoader>
          <ContentLoader animate={false} width={110} height={30}>
            <Rect x="0" y="0" rx="3" ry="3" width={110} height={30} />
          </ContentLoader>
        </View>
        <ContentLoader animate={false} width={"100%"} height={25}>
          <Rect x="0" y="0" rx="3" ry="3" width={"100%"} height={25} />
        </ContentLoader>
      </View>

      <View style={styles.cardItem}>
        <ContentLoader animate={false} width={width - CONSTANTS.COMMON.CONTAINER_PADDING} height={20}>
          <Rect x="0" y="0" rx="3" ry="3" width={width - CONSTANTS.COMMON.CONTAINER_PADDING - 60} height={20} />
        </ContentLoader>
        <View style={styles.divider} />
        <ContentLoader animate={false} width={width - CONSTANTS.COMMON.CONTAINER_PADDING} height={20}>
          <Rect x="0" y="0" rx="3" ry="3" width={width - CONSTANTS.COMMON.CONTAINER_PADDING - 60} height={20} />
        </ContentLoader>
        <View style={styles.divider} />
        <ContentLoader animate={false} width={width - CONSTANTS.COMMON.CONTAINER_PADDING} height={21}>
          <Rect x="0" y="0" rx="3" ry="3" width={width - CONSTANTS.COMMON.CONTAINER_PADDING - 60} height={20} />
        </ContentLoader>
      </View>
    </View>
  );
};
interface CardInfoModProps {
  loading?: boolean;
  currentCard: MobileQueryCrdCardQuery["crdCard"]["card"];
  handleViewInfoLastUpdate: () => void;
  handleViewRewardPoints: () => void;
}
const CardInfoMod: FC<CardInfoModProps> = ({
  loading,
  currentCard,
  handleViewInfoLastUpdate,
  handleViewRewardPoints,
}) => {
  const { t } = useTranslation("app/screens/Card/CardListScreen");
  const totalAvailablePoint = currentCard?.totalAvailablePoint ?? 0;
  const totalAmountUsed = currentCard?.currentUsageSchedule?.cardTransactions?.reduce((total, currentValue) => {
    return Math.abs(currentValue?.amount) + total;
  }, 0);
  const { billingDayText, paymentDueDayText } = useMemo(() => {
    if (!currentCard) {
      return { billingDayText: "", paymentDueDayText: "" };
    }
    const { paymentDueDay, billingDay } = currentCard ?? {};
    const currentDay = dayjs().get("date");
    const currentMonth = dayjs().get("month") + 1;
    const currentYear = dayjs().get("year");
    const isPast = currentDay > paymentDueDay;
    const currentBillingDay = dayjs(`${billingDay}/${currentMonth}/${currentYear}`, "D/M/YYYY");
    const currentPaymentDueDay = dayjs(`${paymentDueDay}/${currentMonth}/${currentYear}`, "D/M/YYYY");

    if (isPast) {
      const nextPaymentDueDay = currentPaymentDueDay.add(1, "month");
      const remainDay = nextPaymentDueDay.diff(dayjs(), "day");
      return {
        billingDayText: currentBillingDay.add(1, "month").format(CONSTANTS.FORMAT_DAY),
        paymentDueDayText: `${nextPaymentDueDay.format("DD/MM")} (${
          remainDay > 1
            ? t("remain_days", {
                day: remainDay,
              })
            : t("remain_day", {
                day: remainDay,
              })
        })`,
      };
    }

    const remainDay = paymentDueDay - currentDay;
    return {
      billingDayText: currentBillingDay.format(CONSTANTS.FORMAT_DAY),
      paymentDueDayText: `${currentPaymentDueDay.format("DD/MM")} (${
        remainDay > 1
          ? t("remain_days", {
              day: remainDay,
            })
          : t("remain_day", {
              day: remainDay,
            })
      })`,
    };
  }, [currentCard, t]);
  if (loading) {
    return <CardLoading />;
  }
  return (
    <>
      <View style={styles.cardItem}>
        <View style={styles.rowItem}>
          <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
            {t("card_limit")}
          </AppText>
          <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
            {t("remain_money")}
          </AppText>
        </View>
        <View style={styles.rowItem}>
          <CurrencyText style={[Fonts.H200, styles.flex]}>{currentCard?.creditLimit}</CurrencyText>
          <CurrencyText style={[Fonts.H200, styles.flex, styles.textAlignRight]}>{currentCard?.balance}</CurrencyText>
        </View>
        <ProgressBarCard value={currentCard?.balance} total={currentCard?.creditLimit} />
        <View style={styles.rowItemCenter}>
          <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
            {t("last_update")}
          </AppText>
          <View style={styles.lastUpdateContainer}>
            <AppText style={Fonts.BodyMedium}>
              {`${dayjs(currentCard?.lastSyncedAt).format("HH:mm")} - ${dayjs(currentCard?.lastSyncedAt).format(
                CONSTANTS.FORMAT_DAY
              )}`}
            </AppText>
            <TouchableOpacity style={styles.viewInfoButton} onPress={handleViewInfoLastUpdate}>
              <IconCustom name="info" />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.rowItemCenter}>
          <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
            {t("closing_date")}
          </AppText>
          <AppText style={Fonts.BodyMedium}>{billingDayText}</AppText>
        </View>
        <View style={styles.divider} />
        <View style={styles.rowItemCenter}>
          <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
            {t("time_for_payment")}
          </AppText>
          <AppText style={Fonts.BodyMedium}>{paymentDueDayText}</AppText>
        </View>
        <View style={styles.divider} />
        <View style={styles.rowItemCenter}>
          <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
            {t("reward_points")}
          </AppText>
          <CurrencyText style={Fonts.BodyMedium} suffix={t("point")}>
            {totalAvailablePoint}
          </CurrencyText>
          <TouchableOpacity style={styles.viewInfoButton} onPress={handleViewRewardPoints}>
            <IconCustom name="info" />
          </TouchableOpacity>
        </View>
      </View>
      {Boolean(currentCard?.assignee) && (
        <View style={styles.cardItem}>
          <View style={styles.rowItemCenter}>
            <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
              {t("assignee")}
            </AppText>
            <AppText style={Fonts.BodyMedium}>{currentCard?.assignee?.fullname}</AppText>
          </View>
          <View style={styles.divider} />
          <View style={styles.rowItemCenter}>
            <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
              {t("total_amount_used")}
            </AppText>
            <CurrencyText style={Fonts.BodyMedium}>{totalAmountUsed}</CurrencyText>
          </View>
          <View style={styles.divider} />
          <View>
            <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
              {t("time")}
            </AppText>
            <View style={styles.usedTimeInfo}>
              <AppText style={Fonts.BodyMedium}>{`${dayjs(currentCard?.currentUsageSchedule?.fromDate).format(
                "HH:mm - DD/MM/YYYY"
              )}`}</AppText>
              <ArrowTimeIcon />
              <AppText style={Fonts.BodyMedium}>{`${dayjs(currentCard?.currentUsageSchedule?.toDate).format(
                "HH:mm - DD/MM/YYYY"
              )}`}</AppText>
            </View>
          </View>
        </View>
      )}
    </>
  );
};
export default CardInfoMod;

const styles = StyleSheet.create({
  cardItem: {
    padding: 20,
    marginTop: 20,
    borderRadius: 16,
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 4,
  },
  rowItem: { flexDirection: "row", justifyContent: "space-between" },
  rowItemCenter: { flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
  progressBarContainer: {
    marginTop: 8,
    marginBottom: 12,
    width: "100%",
    height: 6,
    backgroundColor: Colors.grayscale05,
    borderRadius: 8,
    overflow: "hidden",
  },
  divider: { height: 1, backgroundColor: Colors.grayscale0, marginVertical: 10 },
  lastUpdateContainer: { flexDirection: "row", alignItems: "center" },
  viewInfoButton: { marginLeft: 5 },
  flex: { flex: 1 },
  textAlignRight: { textAlign: "right" },
  usedTimeInfo: { flexDirection: "row", gap: 6, alignItems: "center", marginTop: 8, flexWrap: "wrap" },
});
