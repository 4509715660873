import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#94A3B8", width = 16, height = 16 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 16 16">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={16} height={16}>
        <Path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.998 11.168c.141 0 .26-.048.356-.144a.484.484 0 00.143-.356V7.835a.484.484 0 00-.143-.357.484.484 0 00-.357-.143.483.483 0 00-.356.143.484.484 0 00-.144.357v2.833c0 .142.048.26.144.356a.484.484 0 00.357.144zm0-4.974c.152 0 .28-.052.383-.155a.521.521 0 00.155-.384.521.521 0 00-.155-.384.521.521 0 00-.384-.154.521.521 0 00-.383.154.521.521 0 00-.155.384c0 .153.052.28.155.384a.521.521 0 00.383.155zm0 8.14c-.875 0-1.699-.166-2.47-.498a6.397 6.397 0 01-2.01-1.354 6.394 6.394 0 01-1.355-2.01 6.164 6.164 0 01-.499-2.47c0-.876.166-1.699.499-2.47a6.397 6.397 0 011.353-2.011 6.394 6.394 0 012.01-1.354 6.164 6.164 0 012.47-.499c.876 0 1.7.166 2.47.499.771.332 1.442.783 2.012 1.353.57.57 1.021 1.24 1.354 2.01.332.771.499 1.594.499 2.47 0 .876-.166 1.7-.499 2.47a6.396 6.396 0 01-1.354 2.012 6.393 6.393 0 01-2.01 1.354 6.163 6.163 0 01-2.47.499z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
