import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  Button,
  CurrencyText,
  DashedLine,
  IconCustom,
  Line,
} from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";

interface TotalAmountInfoProps {
  totalAmount?: number;
  cashAdvanceAmount?: number;
  cardTransactionAmount?: number;

  setPosition?: (value: number) => void;
}
const TotalAmountInfo: FC<TotalAmountInfoProps> = ({
  cashAdvanceAmount = 0,
  totalAmount = 0,
  cardTransactionAmount = 0,
  setPosition,
}) => {
  const { t } = useTranslation("app/screens/Report/components/CreateOrEditForm");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
  const paidAmount = cashAdvanceAmount + cardTransactionAmount;
  const [FEATURE_CARD, FEATURE_EXP_CASH_ADVANCE] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_CARD,
    FEATURE_FLAGS.FEATURE_EXP_CASH_ADVANCE,
  ]);
  return (
    <>
      <Line size="lg" />
      <View
        style={styles.container}
        onLayout={(event) => {
          setPosition?.(event.nativeEvent.layout.y);
        }}
      >
        <AppText style={Fonts.SentenceSubtitleXLarge}>{t("expense_overview")}</AppText>
        <View style={[styles.amountRow, { marginTop: 15, marginBottom: 8 }]}>
          <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
            {t("total_amount")}
          </AppText>
          <CurrencyText style={Fonts.NumericN400} color={Colors.success100}>
            {totalAmount}
          </CurrencyText>
        </View>
        <DashedLine />
        <View
          style={{
            paddingVertical: 8,
          }}
        >
          <View style={styles.amountRow}>
            <View style={[styles.amountLabelIcon, styles.flex]}>
              <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
                {t("paid_amount")}
              </AppText>
              <TouchableOpacity onPress={() => bottomSheetRef?.current?.present()}>
                <IconCustom name="info" />
              </TouchableOpacity>
            </View>
            <CurrencyText style={Fonts.NumericN300}>{paidAmount}</CurrencyText>
          </View>
          {FEATURE_EXP_CASH_ADVANCE && (
            <View style={[styles.amountRow, { marginVertical: 15 }]}>
              <AppText style={[Fonts.BodyMedium, styles.flex, { paddingLeft: 20 }]} color={Colors.grayscale60}>
                {t("advance")}
              </AppText>
              <CurrencyText style={Fonts.NumericN300} color={Colors.grayscale60}>
                {cashAdvanceAmount}
              </CurrencyText>
            </View>
          )}
          {FEATURE_CARD && (
            <View style={styles.amountRow}>
              <AppText style={[Fonts.BodyMedium, styles.flex, { paddingLeft: 20 }]} color={Colors.grayscale60}>
                {t("card_transaction")}
              </AppText>
              <CurrencyText style={Fonts.NumericN300} color={Colors.grayscale60}>
                {cardTransactionAmount}
              </CurrencyText>
            </View>
          )}
        </View>
        <>
          <DashedLine />
          <View style={[styles.amountRow, { marginTop: 8 }]}>
            <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
              {totalAmount - paidAmount >= 0 ? t("company_must_pay") : t("employee_must_pay")}
            </AppText>
            <CurrencyText style={Fonts.NumericN300}>{Math.abs(totalAmount - paidAmount)}</CurrencyText>
          </View>
        </>
      </View>
      <BottomSheetScrollViewModalCustom
        wrapperByScrollView={false}
        ref={bottomSheetRef}
        title={t("paid_amount")}
        containerStyle={styles.modalStyle}
        renderFooter={() => (
          <Button type="primary" onPress={() => bottomSheetRef.current?.close()}>
            {t("understood")}
          </Button>
        )}
      >
        <AppText style={Fonts.BodyLarge}>{t("expense_advance_description")}</AppText>
        <View style={styles.typeRow}>
          <View style={styles.dotIcon} />
          <AppText style={Fonts.BodyLarge}>{t("advance_request")}</AppText>
        </View>
        <View style={styles.typeRow}>
          <View style={styles.dotIcon} />
          <AppText style={Fonts.BodyLarge}>{t("business_card")}</AppText>
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default TotalAmountInfo;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
  },
  amountRow: { flexDirection: "row", alignItems: "center" },
  flex: { flex: 1 },
  amountLabelIcon: { flexDirection: "row", gap: 4, alignItems: "center" },
  modalStyle: { paddingHorizontal: 20, paddingTop: 16, paddingBottom: 120 },
  typeRow: { flexDirection: "row", alignItems: "center", gap: 6 },
  dotIcon: { width: 5, height: 5, borderRadius: 3, backgroundColor: Colors.grayscale100 },
});
