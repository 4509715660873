import { FC, memo, useEffect, useRef, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { AppText, CopilotStepCustom, TouchableOpacityCustom, useWindowDimensions } from "components";
import { Colors, Fonts } from "theme";
import SCREEN_NAME from "navigation/ScreenName";
import { useAuth } from "contexts/AuthContext";
import { CONSTANTS } from "constants/constants";
import IconCustom from "components/IconCustom";
import { EVENT } from "constants/Tracking";
import { BizziBotSticker01, BizziBotSticker02 } from "assets/images";
import { useCopilot } from "react-native-copilot";

const scrollBarWidth = 40;
const scrollBarChildWidth = 18;
interface MenuSectionProps {
  totalWaitingApproval?: number;
}

const MenuSection: FC<MenuSectionProps> = ({ totalWaitingApproval = 0 }) => {
  const navigation = useNavigation<any>();
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { width, isTablet } = useWindowDimensions();

  const translateX = useSharedValue(0);
  const [completeScrollBarWidth, setCompleteScrollBarWidth] = useState(0);
  const [visibleScrollBarWidth, setVisibleScrollBarWidth] = useState(0);

  const scrollHandler = useAnimatedScrollHandler((event) => {
    translateX.value = event.contentOffset.x;
  });

  const indicatorStyle = useAnimatedStyle(() => {
    const value = interpolate(
      translateX?.value,
      [0, completeScrollBarWidth - visibleScrollBarWidth],
      [0, scrollBarWidth - scrollBarChildWidth],
      Extrapolate.CLAMP
    );
    return {
      transform: [
        {
          translateX: value,
        },
      ],
    };
  });
  const buttonWidth = isTablet && Platform.OS !== "web" ? (width - 12) / 5 : (width - 12) / 4;
  const { stop } = useCopilot();

  return (
    <View>
      <CopilotStepCustom
        style={styles.menuListContainer}
        title={"walkthrough_overview_step5_title"}
        text={"walkthrough_overview_step5_description"}
        order={CONSTANTS.WALK_THROUGH.OVERVIEW.Step5}
        image={BizziBotSticker02}
        walkthroughName={CONSTANTS.WALK_THROUGH.OVERVIEW.NAME}
        offsetX={10}
      >
        <>
          <Animated.ScrollView
            scrollEventThrottle={16}
            onScroll={scrollHandler}
            horizontal
            onContentSizeChange={(e) => {
              setCompleteScrollBarWidth(e);
            }}
            onLayout={({
              nativeEvent: {
                layout: { width },
              },
            }) => {
              setVisibleScrollBarWidth(width);
            }}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingLeft: 7 }}
          >
            <View style={styles.menuList}>
              <TouchableOpacityCustom
                eventName={EVENT.HOME.VIEW_SUBMITTED_TO_ME}
                style={[styles.button, { width: buttonWidth }]}
                onPress={() => navigation.navigate(SCREEN_NAME.WaitingApprovalScreen)}
              >
                <View>
                  <View style={styles.iconContainer}>
                    <IconCustom name="check-circle" width={26} height={26} fill={Colors.white} />
                  </View>
                  {totalWaitingApproval > 0 && (
                    <View style={styles.waitingApprovalCountContainer}>
                      <AppText style={[Fonts.H100, styles.waitingApprovalCount]} color={Colors.white}>
                        {totalWaitingApproval > 99 ? "99+" : totalWaitingApproval}
                      </AppText>
                    </View>
                  )}
                </View>
                <AppText style={[Fonts.BodySmall, styles.titleButton]}>{t("waiting_approval")}</AppText>
              </TouchableOpacityCustom>
              <CopilotStepCustom
                title={"walkthrough_email_title_step1"}
                text={"walkthrough_email_description_step1"}
                order={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step1}
                image={BizziBotSticker01}
                walkthroughName={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME}
                onConfirm={() =>
                  navigation.navigate(SCREEN_NAME.CompanyDetailScreen, {
                    shouldShowWalkthrough: true,
                  })
                }
                singleStep={true}
                confirmText={"continue"}
              >
                <TouchableOpacityCustom
                  eventName={EVENT.HOME.VIEW_INVOICE_INFORMATION}
                  style={[styles.button, { width: buttonWidth }]}
                  onPress={() => navigation.navigate(SCREEN_NAME.CompanyDetailScreen)}
                >
                  <View style={[styles.iconContainer, { backgroundColor: Colors.success50 }]}>
                    <IconCustom name="description" />
                  </View>
                  <AppText style={[Fonts.BodySmall, styles.titleButton]}>{t("company_info_icon_title")}</AppText>
                </TouchableOpacityCustom>
              </CopilotStepCustom>
              <TouchableOpacityCustom
                eventName={EVENT.EXPENSE.TAP_CREATE}
                style={[styles.button, { width: buttonWidth }]}
                onPress={() => navigation.navigate(SCREEN_NAME.NewExpenseScreen)}
              >
                <View style={[styles.iconContainer, { backgroundColor: Colors.primary50 }]}>
                  <IconCustom name="add-circle" />
                </View>
                <AppText style={[Fonts.BodySmall, styles.titleButton]}>{t("create_expense")}</AppText>
              </TouchableOpacityCustom>
              <CopilotStepCustom
                title={"walkthrough_email_title_step4"}
                text={"walkthrough_email_description_step4"}
                order={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step4}
                image={BizziBotSticker01}
                walkthroughName={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME}
                onConfirm={() => {
                  stop();
                  navigation.navigate(SCREEN_NAME.InboxScreen, { shouldShowWalkthrough: true });
                }}
                onBack={() => {
                  stop();
                  navigation.navigate(SCREEN_NAME.CompanyDetailScreen, {
                    shouldShowWalkthrough: true,
                    orderStep: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step3,
                  });
                }}
                confirmText={"continue"}
                activeStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step4}
                totalStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.TotalStep}
              >
                <TouchableOpacityCustom
                  eventName={EVENT.HOME.VIEW_INBOX}
                  style={[styles.button, { width: buttonWidth }]}
                  onPress={() => navigation.navigate(SCREEN_NAME.InboxScreen)}
                >
                  <View style={[styles.iconContainer, { backgroundColor: Colors.dataGrape }]}>
                    <IconCustom name="mail" />
                  </View>
                  <AppText style={[Fonts.BodySmall, styles.titleButton]}>{t("inbox")}</AppText>
                </TouchableOpacityCustom>
              </CopilotStepCustom>
              {Platform.OS !== "web" && (
                <TouchableOpacityCustom
                  eventName={EVENT.HOME.OPEN_CAMERA}
                  style={[styles.button, { width: buttonWidth }]}
                  onPress={() => navigation.navigate(SCREEN_NAME.CameraScreen)}
                >
                  <View style={[styles.iconContainer, { backgroundColor: Colors.dataPink }]}>
                    <IconCustom name="add-a-photo" />
                  </View>
                  <AppText style={[Fonts.BodySmall, styles.titleButton]}>{t("camera_icon_title")}</AppText>
                </TouchableOpacityCustom>
              )}
            </View>
          </Animated.ScrollView>
          {Platform.OS !== "web" && !isTablet && (
            <View style={styles.indicatorContainer}>
              <Animated.View style={[styles.indicator, indicatorStyle]} />
            </View>
          )}
        </>
      </CopilotStepCustom>
    </View>
  );
};

export default memo(MenuSection);
const styles = StyleSheet.create({
  menuListContainer: { marginTop: 20, paddingHorizontal: 0 },
  menuList: { flexDirection: "row", paddingTop: 10 },
  button: { alignItems: "center" },
  titleButton: { marginTop: 6, textAlign: "center" },
  indicatorContainer: {
    marginTop: 16,
    alignSelf: "center",
    width: scrollBarWidth,
    height: 4,
    borderRadius: 20,
    backgroundColor: Colors.primary05,
  },
  indicator: {
    width: scrollBarChildWidth,
    height: 4,
    borderRadius: 20,
    backgroundColor: Colors.primary50,
  },
  // eslint-disable-next-line react-native/no-color-literals
  waitingApprovalCountContainer: {
    justifyContent: "center",
    height: 20,
    backgroundColor: "red",
    position: "absolute",
    left: 34,
    top: -5,
    paddingHorizontal: 6,
    borderRadius: 10,
  },
  waitingApprovalCount: {
    minWidth: 8,
    textAlign: "center",
  },
  iconContainer: {
    backgroundColor: Colors.dataOrange,
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    justifyContent: "center",
    alignItems: "center",
  },
});
