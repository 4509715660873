import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";

import { AppText } from "components";
import { EXPENSE_REPORT_PAYMENT_STATUS } from "constants/constants";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";

const REPORT_STATUS = {
  [EXPENSE_REPORT_PAYMENT_STATUS.PENDING]: {
    text: "payment_status_pending",
    backgroundColor: Colors.primary05,
  },
  [EXPENSE_REPORT_PAYMENT_STATUS.PAID]: {
    text: "payment_status_paid",
    backgroundColor: Colors.success0,
  },
};

interface ReportPaymentStatusProps {
  value: EXPENSE_REPORT_PAYMENT_STATUS;
}

const ReportPaymentStatus: FC<ReportPaymentStatusProps> = ({ value }) => {
  const { t } = useTranslation("app/screens/Report/ReportListScreen");
  const [FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS,
  ]);

  if (!FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS) {
    return null;
  }

  return (
    <View style={[styles.container, { backgroundColor: REPORT_STATUS[value]?.backgroundColor ?? Colors.grayscale05 }]}>
      <AppText style={Fonts.BodySmall}>
        {REPORT_STATUS[value] ? t(REPORT_STATUS[value].text as any) : t("payment_status_not_recorded")}
      </AppText>
    </View>
  );
};

export default memo(ReportPaymentStatus);
const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
  },
});
