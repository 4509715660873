import { gql, useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { ExpMobileSpendingTrendQuery, ExpMobileSpendingTrendQueryVariables } from "types";

const PERSONAL_EXPENSE_SPENDING_TREND = gql`
  query ExpMobileSpendingTrend(
    $companyId: UUID!
    $employeeId: UUID!
    $fromDate: DateTime!
    $toDate: DateTime!
    $compareWith: ExpMobileSpendingTrendCompareWith!
  ) {
    expMobileSpendingTrend(
      input: {
        companyId: $companyId
        employeeId: $employeeId
        fromDate: $fromDate
        toDate: $toDate
        compareWith: $compareWith
      }
    ) {
      categoryId
      title
      titleEn
      spendingPlanAmount
      spendingAmount
      spendingAmountToCompare
      diffPercentage
    }
  }
`;

const usePersonalExpenseSpendingTrend = ({
  fromDate,
  toDate,
  compareWith,
}: Pick<ExpMobileSpendingTrendQueryVariables, "fromDate" | "toDate" | "compareWith">) => {
  const { company, user } = useAuth();

  const { data, ...rest } = useQuery<ExpMobileSpendingTrendQuery, ExpMobileSpendingTrendQueryVariables>(
    PERSONAL_EXPENSE_SPENDING_TREND,
    {
      variables: {
        companyId: company.company_id,
        employeeId: user.employee.employee_id,
        fromDate,
        toDate,
        compareWith,
      },
      skip: !company.company_id || !user.employee.employee_id,
    }
  );

  return {
    data: data?.expMobileSpendingTrend ?? ([] as ExpMobileSpendingTrendQuery["expMobileSpendingTrend"]),
    ...rest,
  };
};

export default usePersonalExpenseSpendingTrend;
