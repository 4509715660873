import { AppText, BottomSheetModalCustom, BottomSheetModalCustomMethods, IconCustom } from "components";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { CONSTANTS } from "constants/constants";
import { Colors, Fonts, FontTypes } from "theme";
import { ArrowDownIcon } from "assets/images/svg/icons";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

const VAT_RATES = [
  {
    title: "no_vat",
    value: "-1",
  },

  {
    title: "mixed_tariff",
    value: "-1000",
  },
  {
    title: "0%",
    value: "0",
  },
  {
    title: "3.5%",
    value: "3.5",
  },
  {
    title: "5%",
    value: "5",
  },
  {
    title: "7%",
    value: "7",
  },
  {
    title: "8%",
    value: "8",
  },
  {
    title: "10%",
    value: "10",
  },
];

interface VatRateSelectModalProps {
  style?: StyleProp<ViewStyle>;
  name?: string;
  control: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
}

const VatRateSelectModal = forwardRef<Partial<BottomSheetModalCustomMethods>, VatRateSelectModalProps>(
  ({ name, control, rules, error, style }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
      },
    }));
    const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

    /*useEffect(() => {
      if (value) {
        const vatRate = VAT_RATES.find((i) => i.value === value);
        setCurrentVatRate(vatRate ?? null);
      }
    }, [value]);*/
    const onChoosePaymentMethod = (item) => () => {
      bottomSheetRef?.current?.close();
      //onSelect(item);
    };
    const getVatRateTitle = (value) => {
      const title = VAT_RATES?.find((item) => item?.value === value)?.title ?? "";
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t(title);
    };
    return (
      <>
        <Controller
          name={name as never}
          control={control}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <>
              <View style={style}>
                <TouchableOpacity
                  onPress={() => {
                    bottomSheetRef?.current?.present();
                    //setCategoryValue.current = onChange;
                    //handleOpenModal();
                  }}
                  style={styles.input}
                >
                  {Boolean(value) && <AppText style={styles.label}>{t("vat_rate")}</AppText>}
                  <AppText style={styles.categoryValue} color={CONSTANTS.COMMON.TEXT_INPUT_COLOR} numberOfLines={1}>
                    {value ? getVatRateTitle(value) : t("no_vat_declaration")}
                  </AppText>
                  <IconCustom name="expand-more" />
                </TouchableOpacity>
                {Boolean(error?.message) && (
                  <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                    {error?.message}
                  </AppText>
                )}
              </View>
              <BottomSheetModalCustom title={t("vat_rate")} snapPoints={[600]} ref={bottomSheetRef}>
                {VAT_RATES?.map((item) => {
                  const isActive = item?.value === value;
                  return (
                    <TouchableOpacity
                      style={[styles.paymentItem, isActive && { backgroundColor: Colors.primary0 }]}
                      onPress={() => {
                        onChange(item?.value as any);
                        bottomSheetRef?.current?.close();
                      }}
                    >
                      <AppText style={[Fonts.BodyLarge, { alignItems: "center" }]}>
                        {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          t(item?.title)
                        }
                      </AppText>
                    </TouchableOpacity>
                  );
                })}
              </BottomSheetModalCustom>
            </>
          )}
        />
      </>
    );
  }
);
export default VatRateSelectModal;
const styles = StyleSheet.create({
  paymentItem: { flexDirection: "row", paddingVertical: 17, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    flexDirection: "row",
  },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: Colors.grayscale80,
  },
  categoryValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
});
