import * as React from "react";
import Svg, { G, Rect, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={51} height={24} viewBox="0 0 51 24" fill="none">
      <G filter="url(#filter0_i_12588_254438)">
        <Rect width={51} height={24} rx={12} transform="matrix(-1 0 0 1 51 0)" fill="#1F71F4" />
      </G>
      <G clipPath="url(#clip0_12588_254438)">
        <G clipPath="url(#clip1_12588_254438)">
          <Path d="M26.867 4h24.67v16.402h-24.67V4z" fill="#fff" />
          <Path
            d="M26.867 4h24.67v1.261h-24.67V4zm0 2.523h24.67v1.261h-24.67V6.523zm0 2.522h24.67v1.262h-24.67V9.045zm0 2.523h24.67v1.261h-24.67v-1.26zm0 2.528h24.67v1.26h-24.67v-1.26zm0 2.522h24.67v1.262h-24.67v-1.262zm0 2.523h24.67v1.261h-24.67v-1.261z"
            fill="#D80027"
          />
          <Path d="M26.867 4h12.335v8.83H26.867V4z" fill="#2E52B2" />
          <Path
            d="M29.164 10.663l-.193-.614-.211.614h-.635l.515.369-.193.614.524-.38.51.38-.197-.614.524-.37h-.644zm2.707 0l-.197-.614-.202.614h-.635l.515.369-.192.614.514-.38.52.38-.193-.614.515-.37h-.645zm2.717 0l-.206-.614-.193.614h-.65l.53.369-.202.614.515-.38.529.38-.203-.614.515-.37h-.635zm2.703 0l-.192-.614-.202.614h-.64l.52.369-.193.614.515-.38.519.38-.207-.614.53-.37h-.65zm-5.617-3.05l-.202.613h-.635l.515.38-.192.603.514-.374.52.374L32 8.605l.515-.379h-.645l-.197-.614zm-2.703 0l-.211.613h-.635l.515.38-.193.603.524-.374.51.374-.197-.604.524-.379h-.644l-.193-.614zm5.41 0l-.192.613h-.65l.53.38-.202.603.515-.374.529.374-.203-.604.515-.379h-.635l-.206-.614zm2.718 0l-.202.613h-.64l.52.38-.193.603.515-.374.519.374-.207-.604.53-.379h-.65l-.192-.614zM28.97 5.185l-.211.604h-.635l.515.379-.193.609.524-.38.51.38-.197-.61.524-.378h-.644l-.193-.604zm2.703 0l-.202.604h-.635l.515.379-.192.609.514-.38.52.38-.193-.61.515-.378h-.645l-.197-.604zm2.708 0l-.193.604h-.65l.53.379-.202.609.515-.38.529.38-.203-.61.515-.378h-.635l-.206-.604zm2.717 0l-.202.604h-.64l.52.379-.193.609.515-.38.519.38-.207-.61.53-.378h-.65l-.192-.604z"
            fill="#fff"
          />
        </G>
      </G>
      <Path
        d="M6.864 16V7.24h5.698v1.33H8.45v2.378h3.816v1.33H8.45v2.392h4.145V16H6.864zM21.38 7.24V16h-1.412l-4.128-5.967h-.072V16H14.18V7.24h1.42l4.123 5.971h.077V7.24h1.579z"
        fill="#fff"
      />
      <Defs>
        <ClipPath id="clip0_12588_254438">
          <Rect x={31} y={4} width={16} height={16} rx={8} fill="#fff" />
        </ClipPath>
        <ClipPath id="clip1_12588_254438">
          <Path fill="#fff" transform="translate(26.867 4)" d="M0 0H24.6702V16.4023H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
