import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={101} height={94} viewBox="0 0 101 94" fill="none" {...props}>
      <G clipPath="url(#clip0_5166_52734)">
        <Path
          d="M41.154 92.252c2.546.81 5.446 1.268 8.532 1.268 3.087 0 5.986-.457 8.532-1.268-2.463-2.276-5.404-3.73-8.532-3.73s-6.038 1.434-8.532 3.73z"
          fill="#C0CAD8"
        />
        <Path
          d="M94.133 58.85c-1.32 20.701-21.325 30.033-45.186 28.506-23.86-1.518-42.494-13.334-41.174-34.014 1.32-20.68 21.99-40.374 45.85-38.846 23.85 1.517 41.83 23.652 40.51 44.354z"
          fill="#E4EAF1"
        />
        <Path
          d="M35.282 17.52l-3.024 47.348c5.383 2.11 11.431 3.45 17.895 3.865 6.454.416 12.637-.145 18.25-1.559l3.023-47.347c-5.331-2.993-11.358-4.915-17.802-5.33-6.453-.416-12.678.727-18.342 3.023z"
          fill="#1F71F4"
        />
        <Path
          d="M49.747 34.908l-.904-.063.79-2.722 1.662-5.8.084-.311-4.594-.291 2.162-7.368-2.713-.177 6.61-3.73 4.084 4.416-2.1-.135-.851 2.94 6.038.385-10.268 12.856z"
          fill="#fff"
        />
        <Path
          d="M18.8 66c-5.144-7.264 2.359-29.867 10.184-34.086 10.517-5.664 40.28-2.972 49.29 4.843 6.527 5.674 9.925 28.64 4.23 34.253C71.135 82.213 27.976 78.96 18.8 66z"
          fill="#1B1B1D"
        />
        <Path
          d="M10.558 60.118l-4.884-1.58c-.894-.28-1.497-1.32-1.424-2.452l.53-8.314c.073-1.133.8-2.09 1.746-2.255l5.04-.946c1.258-.229 2.349.977 2.245 2.515l-.686 10.828c-.114 1.507-1.35 2.599-2.567 2.204z"
          fill="#C0CAD8"
        />
        <Path
          d="M7.784 59.276l-4.48-1.423c-.83-.26-1.371-1.206-1.309-2.266l.489-7.607c.062-1.05.727-1.902 1.58-2.058l4.624-.852c1.153-.218 2.14.904 2.057 2.286l-.633 9.915c-.084 1.403-1.227 2.37-2.328 2.005z"
          fill="#E4EAF1"
        />
        <Path
          d="M90.465 65.886l5.04-.945c.946-.166 1.674-1.123 1.746-2.255l.53-8.314c.073-1.133-.53-2.172-1.444-2.463l-4.885-1.58c-1.215-.384-2.452.676-2.546 2.203l-.686 10.84c-.093 1.527.988 2.764 2.245 2.514z"
          fill="#C0CAD8"
        />
        <Path
          d="M93.342 65.418l4.624-.853c.863-.155 1.518-1.028 1.58-2.057l.488-7.607c.063-1.05-.478-1.985-1.309-2.245l-4.479-1.424c-1.122-.364-2.245.624-2.328 2.006l-.634 9.904c-.083 1.371.904 2.494 2.058 2.276z"
          fill="#E4EAF1"
        />
        <Path
          d="M41.371 60.701a6.09 6.09 0 100-12.18 6.09 6.09 0 000 12.18zM63.32 59.952a3.357 3.357 0 100-6.714 3.357 3.357 0 000 6.714z"
          fill="#fff"
        />
        <Path
          d="M20.17 33.957a4.037 4.037 0 100-8.074 4.037 4.037 0 000 8.074zM13.68 1.31L9.361 2.376a2 2 0 00-1.409 2.6l6.615 18.973a2 2 0 002.591 1.214l2.48-.93a2 2 0 001.237-2.358L16.099 2.767a2 2 0 00-2.42-1.457z"
          fill="#F59E0B"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5166_52734">
          <Path fill="#fff" transform="translate(.967 .457)" d="M0 0H99.0693V93.0626H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
