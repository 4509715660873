import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  Badge,
  Button,
  CurrencyText,
  DashedLine,
  FloatFooter,
  IconCustom,
  Line,
  LottieLoading,
  RadioButton,
  ToastManager,
} from "components";
import React, { FC, ReactElement, useMemo, useRef, useState } from "react";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { BizziBotInfo } from "assets/images/svg/icons";
import { useNavigation } from "@react-navigation/native";
import useBokIssueTicket from "screens/FlightBooking/hooks/useBokIssueTicket";
import { CheckoutScreenProps, StackNavigation } from "navigation/type";
import useFlightBookingInfoQuery from "screens/FlightBooking/hooks/useFlightBookingInfoQuery";
import SCREEN_NAME from "navigation/ScreenName";
import { BaggageType, BookingInformation } from "screens/FlightBooking/types";
import { getListFaresFromBookingInfo } from "screens/FlightBooking/helpers";

type PaymentMethodOption = {
  value: number;
  label?: string;
  description?: string;
  disable?: boolean;
  badge?: ReactElement;
};

const PaymentMethodSelect = () => {
  const { t } = useTranslation("app/screens/FlightBooking/CheckoutScreen/CheckoutScreen");
  const [value, setValue] = useState(0);
  const options: PaymentMethodOption[] = [
    { value: 0, label: t("accounts_payable"), description: t("accounts_payable_descriptions") },
    {
      value: 1,
      label: t("personal_wallet"),
      description: t("personal_wallet_descriptions"),
      disable: true,
      badge: <Badge color={Colors.warning0} text={<AppText>{t("coming_soon")}</AppText>} />,
    },
  ];
  const handleSelect = (option) => () => setValue(option.value);

  return (
    <View>
      {options.map((option, index) => {
        const isChecked = value === option.value;

        const content = (
          <View key={index}>
            {index !== 0 && <Line size="sm" />}
            <View style={styles.paymentItemContainer}>
              <View style={styles.allSpaceLeft}>
                <AppText style={Fonts.BodyMedium} color={option.disable ? Colors.grayscale60 : Colors.grayscale100}>
                  {option.label}
                </AppText>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
                  {option.description}
                </AppText>
              </View>
              <RadioButton checked={isChecked} disabled={option.disable} />
            </View>
            {option.badge && <View style={styles.badgeContainer}>{option.badge}</View>}
          </View>
        );
        return option.disable ? (
          content
        ) : (
          <TouchableOpacity key={index} onPress={handleSelect(option)}>
            {content}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const CheckoutScreen: FC<CheckoutScreenProps> = ({ route }) => {
  const { t } = useTranslation("app/screens/FlightBooking/CheckoutScreen/CheckoutScreen");
  const { expenseRequestId } = route?.params;
  const navigation = useNavigation<StackNavigation>();
  const [footerHeight, setFooterHeight] = useState(0);
  const alertRef = useRef<AlertNotificationHandle>();
  const [bookIssueTicket] = useBokIssueTicket();
  const { data, loading } = useFlightBookingInfoQuery({ expenseRequestId });
  const bookingInformation = data?.expExpenseRequestDetail?.expenseTravels?.[0]?.expenseBookings?.find(
    (item) => item?.bookingType === BOOKING_TYPE.FLIGHT
  )?.expenseTravelBookings?.[0]?.bookingInformation;

  const faresList = useMemo(() => {
    const booking = data?.expExpenseRequestDetail?.expenseTravels?.[0]?.expenseBookings?.find(
      (booking) => booking.bookingType === BOOKING_TYPE.FLIGHT
    )?.expenseTravelBookings?.[0];

    if (!booking) {
      return null;
    }
    const bookingInformation = booking.bookingInformation as BookingInformation;
    return getListFaresFromBookingInfo(bookingInformation, booking?.status);
  }, [data]);
  const onPayFlightTicket = async () => {
    alertRef?.current?.loading();
    const rs = await bookIssueTicket({
      variables: {
        input: {
          orderCode: bookingInformation?.orderCode,
          tickets: bookingInformation?.listBooking?.map((item) => ({
            airline: item?.airline?.code,
            bookingCode: item?.bookingCode,
          })),
        },
      },
    });
    if (rs?.data?.bokIssueTicket?.success) {
      ToastManager.success(t("pay_success"));
      alertRef?.current?.close();
      navigation.navigate(SCREEN_NAME.DetailRequestScreen, { expenseRequestId });
      return;
    }
    alertRef?.current?.error({ title: t("pay_failed"), description: t("pay_failed_description") });
  };
  const handlePay = () => {
    alertRef?.current?.confirm({
      icon: <BizziBotInfo />,
      title: t("confirm_to_pay"),
      description: t("confirm_to_pay_description"),
      onConfirm: onPayFlightTicket,
      confirmText: t("pay"),
      cancelText: t("cancel"),
    });
  };

  return (
    <BottomSheetModalProvider>
      <View style={styles.container}>
        <BackHeader containerStyle={styles.pageHeader} headerTitle={t("page_title")} />
        {loading ? (
          <LottieLoading style={styles.loadingView} />
        ) : (
          <>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.contentContainer}>
              <View style={styles.section}>
                <AppText style={Fonts.SentenceSubtitleLarge}>{t("summary")}</AppText>
                <View>
                  {faresList?.map((item, index) => {
                    const flight = item?.flights?.[0];
                    return (
                      <View key={index}>
                        <View style={styles.summaryItem}>
                          <View style={[styles.flexRow, styles.allSpaceLeft]}>
                            <IconCustom name={index === 0 ? "flight-takeoff" : "flight-land"} />
                            <AppText
                              style={Fonts.BodyMedium}
                            >{`${flight?.departure?.code} - ${flight?.arrival?.code}`}</AppText>
                          </View>
                        </View>
                        <View style={styles.summaryDetailRow}>
                          <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>
                            {t("number_of_passenger", {
                              numberOfPassenger: 1,
                            })}
                          </AppText>
                          <CurrencyText style={Fonts.NumericN200}>{item?.bookingInfo?.price}</CurrencyText>
                        </View>
                        {item?.bookingInfo?.baggage
                          ?.filter((item) => item.type !== BaggageType.HandBaggage && item?.weight && item?.price)
                          ?.map((item) => (
                            <View style={styles.summaryDetailRow}>
                              <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{`${t(
                                "number_of_extra_luggage",
                                {
                                  numberOfExtraLuggage: 1,
                                }
                              )} ${item?.weight}${item?.weightUnit?.toLowerCase()}`}</AppText>
                              <CurrencyText style={Fonts.NumericN200}>{item?.price}</CurrencyText>
                            </View>
                          ))}

                        <DashedLine />
                      </View>
                    );
                  })}
                  <View style={styles.totalAmountLine}>
                    <AppText style={Fonts.BodyMedium}>{t("total_amount")}</AppText>
                    <CurrencyText style={Fonts.NumericN300} color={Colors.success50}>
                      {bookingInformation?.price}
                    </CurrencyText>
                  </View>
                </View>
              </View>
              <View style={[styles.section, { paddingBottom: footerHeight + 8 }]}>
                <AppText style={Fonts.SentenceSubtitleLarge}>{t("choose_payment_method")}</AppText>
                <PaymentMethodSelect />
              </View>
            </ScrollView>
            <FloatFooter onLayout={(event) => setFooterHeight(event.nativeEvent.layout.height)}>
              <View style={styles.footerDescription}>
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale100}>
                  {t("total_amount")}
                </AppText>
                <CurrencyText style={Fonts.NumericN400} color={Colors.success50}>
                  {bookingInformation?.price}
                </CurrencyText>
              </View>
              <View style={styles.footerButtonContainer}>
                <Button style={styles.footerButton} onPress={handlePay}>
                  {t("pay")}
                </Button>
              </View>
            </FloatFooter>
          </>
        )}
      </View>
      <AlertNotification ref={alertRef} />
    </BottomSheetModalProvider>
  );
};

export default CheckoutScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale0,
  },
  loadingView: {
    backgroundColor: Colors.white,
  },
  pageHeader: {
    paddingBottom: 5,
  },
  contentContainer: {
    gap: 8,
  },
  section: {
    gap: 8,
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
  },
  footerDescription: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  footerButtonContainer: {
    flexDirection: "row",
    gap: 8,
  },
  footerButton: {
    flex: 1,
  },
  summaryItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 8,
    paddingVertical: 8,
  },
  totalAmountLine: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 8,
    paddingVertical: 8,
    marginTop: 4,
  },
  summaryDetailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 8,
    paddingBottom: 8,
  },
  paymentItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 24,
    paddingVertical: 8,
  },
  badgeContainer: {
    alignSelf: "flex-start",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  allSpaceLeft: {
    flex: 1,
  },
});
