const tintColor = "#2f95dc";

export default {
  primary0: "#F0F6FF",
  primary50: "#1F71F4",
  appBg: "#ffffff",
  tintColor,
  black: "#000000",
  blackColor1: "rgba(0, 0, 0, 0.65)",
  tabIconDefault: "#ccc",
  tabIconSelected: tintColor,
  tabBar: "#fefefe",
  errorBackground: "red",
  errorText: "#fff",
  warningBackground: "#EAEB5E",
  warningText: "#666804",
  noticeBackground: tintColor,
  noticeText: "#fff",
  text: "#4b4955",
  fieldLabel: "#C1C7D0",
  whiteText: "#FFFFFF",
  blackText: "#000000",
  blackText2: "#7E7E7E",
  errorColor: "#ef5350",
  redColor1: "#DE350B",
  redColor2: "#FF5630",
  redColor3: "#dd2c00",
  blue: "#388DED",
  blue2: "#0089FF",
  blue3: "#0E4DFB",
  blue22: "#0086FF",
  blue4: "#091E42",
  blue5: "#294063",
  blue6: "#172B4D",
  blue7: "#344563",
  green: "#389E0D",
  blueBackground: "#1890FF",
  whiteBackground: "#FFFFFF",
  blackBackground: "#000000",
  darkmodeDatepickerBackground: "rgba(34,34,34, 1)",
  mainBackground: "#FFFFFF",
  backgroundColor: "#D9D9D9",
  backgroundColor1: "#F6FFED",
  border: "#389E0D",
  grey: "grey",
  dividerColor: "#E0E0E0",
  disableColor100: "#E0E0E0",
  disableColor500: "#A6A6A6",
  warning50: "#F59E0B",
  warningColor: "#FF5630",
  grey1: "#F2F3F4",
  grey2: "#c1c7d0",
  grey3: "#EAEFFF",
  grey4: "#7A869A",
  grey5: "#42526E",
  grey6: "#5E6C84",
  grey7: "#f4f5f7",
  grey8: "#B3D4FF",
  grey9: "#E8E8E8",
  grey10: "#777777",
  grey11: "#5D5C5D",
  grey12: "#DFE1E6",
  grey13: "#6F787E",
  grey14: "#7F8A93",
  gray0: "#F8FAFC",
  gray10: "#E2E8F0",
  gray60: "#94A3B8",
  gray40: "#CBD5E1",
  gray80: "#475569",
  gray100: "#0F172A",
  grey200: "#DEDEDE",
  orange100: "#FFEBE5",
  orange: "#F56700",
  orange1: "#FFAB00",
  activeTintColor: "#0052CC",
  inactiveTintColor: "#42526E",
  inactiveBackgroundColor: "#B3BAC5",
  activeBackgroundColor: "#B2D4FF",
  lineColor: "#c1c7d0",
  textColor2: "#253858",
  transparent: "transparent",
  green1: "#0A9320",
  placeholderTextColor: "#5E6C84",
  blackColor200: "rgba(0,0,0,0.2)",
  yellow100: "#FBBC05",
  backgroundGray: "#F4F7FA",
  backgroundWhite: "#FFFFFF",

  success0: "#E1F9E6",
  success05: "#69DB7C",
  success50: "#37B24D",

  alert0: "#FFE4E6",
  alert50: "#E11934",
  Ink500: "#001629",
};

export const Colors = {
  blueRibbon: "#0B60EA",
  mischka: "#D1D5DB",
  california: "#d46b08",
  porcelain: "#E6E8EA",
  persianBlue: "#1E40AF",
  dodgerBlue: "#1F71F4",
  zircon: "#F0F6FF",
  torchRed: "#F5222D",
  whiteText: "#FFFFFF",
  orange1: "#FFAB00",
  wildWatermelon: "#FE4D65",
  catskillWhite: "#F1F5F9",
  ebony: "#0F172A",
  textColor: "#253858",
  black: "#000000",
  blackCustom: "#0D0D0D",
  transparent: "transparent",
  dividerColor: "#E0E0E0",
  blackColor200: "rgba(0,0,0,0.2)",
  blueCustom1: "rgba(0, 134, 255, 0.3)",
  orangeCustom1: "#FFAF15",
  orangeCustom2: "#FCA18E",
  blueCustom2: "#132437",
  blueCustom3: "#EBF3FF",

  greyCustom1: "#E6E6E6",
  redCustom1: "#EF4D2A",

  blue100: "#E6F4FF",
  blue200: "#B3DCFF",
  blue300: "#7FC3FF",
  blue400: "#33A1FF",
  blue500: "#0089FF",
  blue600: "#006DCC",
  blue700: "#004C8F",
  blue800: "#002C52",

  green100: "#ECFAEE",
  green200: "#C6F0CC",
  green300: "#9EE4A9",
  green400: "#79DA88",
  green500: "#3FCA54",
  green600: "#2CA03D",
  green700: "#1F702D",
  green800: "#124019",

  red100: "#FEE6E6",
  red200: "#FCB3B3",
  red300: "#F97F7F",
  red400: "#F84D4D",
  red500: "#F50000",
  red600: "#B80000",
  red700: "#7A0000",
  red800: "#3D0000",

  orange100: "#FFF0E7",
  orange200: "#FED2B7",
  orange300: "#FCB386",
  orange400: "#FD9657",
  orange500: "#FB690E",
  orange600: "#C94F03",
  orange700: "#8D3702",
  orange800: "#502001",

  yellow100: "#FFF9E7",
  yellow200: "#FFEBB5",
  yellow300: "#FFDC83",
  yellow400: "#FFCF53",
  yellow500: "#FFBA08",
  yellow600: "#CC9200",
  yellow700: "#8F6600",
  yellow800: "#523A00",

  grey100: "#F4F4F4",
  grey200: "#DEDEDE",
  grey300: "#C6C6C6",
  grey400: "#B1B1B1",
  grey500: "#8F8F8F",
  grey600: "#707070",
  grey700: "#525252",
  grey800: "#333333",
  grey900: "#141414",

  grey60: "#A1A1AA",

  white100: "rgba(255, 255, 255, 0.1)",
  white200: "rgba(255, 255, 255, 0.3)",
  white300: "rgba(255, 255, 255, 0.5)",
  white400: "rgba(255, 255, 255, 0.7)",
  white500: "#FFFFFF",

  Ink100: "#E6E8EA",
  Ink200: "#B3BABF",
  Ink300: "#7F8A93",
  Ink400: "#4D5C6A",
  Ink500: "#001629",

  gray0: "#F8FAFC",
  gray10: "#E2E8F0",
  gray40: "#CBD5E1",
  gray80: "#475569",
  gray100: "#0F172A",

  alert0: "#FFE4E6",
  alert50: "#E11934",

  primary0: "#F0F6FF",
  primary20: "#60A5FA",
  primary50: "#146EFF",
  backgroundWhite: "#FFFFFF",
};
