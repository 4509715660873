import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGComponent = ({ width = 16, height = 16, color = "#94A3B8" }) => (
  <Svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <Path
      d="M8.00065 14.6673C11.674 14.6673 14.6673 11.674 14.6673 8.00065C14.6673 4.32732 11.674 1.33398 8.00065 1.33398C4.32732 1.33398 1.33398 4.32732 1.33398 8.00065C1.33398 11.674 4.32732 14.6673 8.00065 14.6673ZM8.50065 10.6673C8.50065 10.9407 8.27398 11.1673 8.00065 11.1673C7.72732 11.1673 7.50065 10.9407 7.50065 10.6673V7.33398C7.50065 7.06065 7.72732 6.83398 8.00065 6.83398C8.27398 6.83398 8.50065 7.06065 8.50065 7.33398V10.6673ZM7.38732 5.08065C7.42065 4.99398 7.46732 4.92732 7.52732 4.86065C7.59398 4.80065 7.66732 4.75398 7.74732 4.72065C7.82732 4.68732 7.91398 4.66732 8.00065 4.66732C8.08732 4.66732 8.17398 4.68732 8.25398 4.72065C8.33398 4.75398 8.40732 4.80065 8.47398 4.86065C8.53398 4.92732 8.58065 4.99398 8.61398 5.08065C8.64732 5.16065 8.66732 5.24732 8.66732 5.33398C8.66732 5.42065 8.64732 5.50732 8.61398 5.58732C8.58065 5.66732 8.53398 5.74065 8.47398 5.80732C8.40732 5.86732 8.33398 5.91398 8.25398 5.94732C8.09398 6.01398 7.90732 6.01398 7.74732 5.94732C7.66732 5.91398 7.59398 5.86732 7.52732 5.80732C7.46732 5.74065 7.42065 5.66732 7.38732 5.58732C7.35398 5.50732 7.33398 5.42065 7.33398 5.33398C7.33398 5.24732 7.35398 5.16065 7.38732 5.08065Z"
      fill={color}
    />
  </Svg>
);
export default SVGComponent;
