import dayjs, { Dayjs } from "dayjs";
import i18n from "i18next";
import React, { useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { DatePickerModal } from "react-native-paper-dates";
import { CalendarDate } from "react-native-paper-dates/lib/typescript/Date/Calendar";
import { RangeChange } from "react-native-paper-dates/src/Date/Calendar";

import { ArrowRightIcon } from "assets/images/svg/icons";
import { AppText, BottomSheetModalCustomMethods } from "components";
import Button from "components/Button";
import RadioButton from "components/RadioButton";
import LanguageStatus from "constants/LanguageStatus";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts, FontTypes } from "theme";
import { TYPE_TIME_FILTER } from "screens/Card/TransactionHistoryListScreen/types";
import { TIME_FILTER_DATA } from "screens/Card/TransactionHistoryListScreen/constants";

interface FilterViewProps {
  handleApplyFilter: (value: TYPE_TIME_FILTER, startDate: Dayjs, endDate: Dayjs) => void;
}

const FilterView = React.forwardRef<BottomSheetModalCustomMethods, FilterViewProps>(({ handleApplyFilter }, ref) => {
  useImperativeHandle(ref, () => ({
    present: () => {
      bottomSheetRef?.current?.present?.();
    },
  }));
  const { t } = useTranslation("app/screens/Card/TransactionHistoryListScreen");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
  const [visibleModalSelectDay, setVisibleModalSelectDay] = useState(false);
  const [startDateCustom, setStartDateCustom] = useState<CalendarDate>();
  const [endDateCustom, setEndDateCustom] = useState<CalendarDate>();
  const [currentTypeTime, setCurrentTypeTime] = useState<TYPE_TIME_FILTER | null>(
    TIME_FILTER_DATA[0].value as TYPE_TIME_FILTER
  );

  const onConfirmSelectDate: RangeChange = (date) => {
    const { startDate, endDate } = date;
    setStartDateCustom(startDate);
    setEndDateCustom(endDate);
    setVisibleModalSelectDay(false);
  };

  const handleSelectTypeTime = (value: TYPE_TIME_FILTER) => () => {
    setCurrentTypeTime(value);
  };

  const onResetFilter = () => {
    setCurrentTypeTime(TYPE_TIME_FILTER.ONE_LAST_MONTH);
    setStartDateCustom(undefined);
    setEndDateCustom(undefined);
  };

  const onApplyFilter = () => {
    let startDate, endDate;
    switch (currentTypeTime) {
      case TYPE_TIME_FILTER.SIX_LAST_MONTHS: {
        startDate = dayjs().subtract(5, "month").startOf("month");
        endDate = dayjs().endOf("month");
        break;
      }
      case TYPE_TIME_FILTER.NINE_LAST_MONTHS: {
        startDate = dayjs().subtract(8, "month").startOf("month");
        endDate = dayjs().endOf("month");
        break;
      }
      case TYPE_TIME_FILTER.ONE_LAST_YEAR: {
        startDate = dayjs().startOf("year");
        endDate = dayjs().endOf("year");
        break;
      }
      case TYPE_TIME_FILTER.CUSTOM: {
        startDate = startDateCustom ? dayjs(startDateCustom) : undefined;
        endDate = endDateCustom ? dayjs(endDateCustom) : undefined;
        break;
      }
      default: {
        startDate = dayjs().subtract(30, "day").startOf("day");
        endDate = dayjs().endOf("day");
      }
    }
    handleApplyFilter(currentTypeTime, startDate, endDate);
    bottomSheetRef?.current?.close();
  };

  return (
    <View style={styles.container}>
      <View style={styles.flex}>
        <View style={styles.divider} />
        <View style={styles.timeRow}>
          <AppText style={Fonts.H300}>{t("time")}</AppText>
          <View style={{ marginTop: 16 }}>
            {TIME_FILTER_DATA.map((item) => (
              <TouchableOpacity
                key={item.value}
                style={styles.timeRowItem}
                onPress={handleSelectTypeTime(item?.value as TYPE_TIME_FILTER)}
              >
                <AppText style={Fonts.BodyMedium}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    t(item?.title)
                  }
                </AppText>
                <RadioButton
                  onPress={handleSelectTypeTime(item?.value as TYPE_TIME_FILTER)}
                  checked={currentTypeTime === item?.value}
                />
              </TouchableOpacity>
            ))}
            {currentTypeTime === TYPE_TIME_FILTER.CUSTOM && (
              <TouchableOpacity style={styles.timeRowItem} onPress={() => setVisibleModalSelectDay(true)}>
                <AppText style={Fonts.BodyMedium}>{t("specific_day")}</AppText>
                <View style={styles.selectDay}>
                  {startDateCustom && endDateCustom ? (
                    <AppText style={Fonts.BodySmall}>
                      {`${dayjs(startDateCustom).format(CONSTANTS.FORMAT_DAY)} - ${dayjs(endDateCustom).format(
                        CONSTANTS.FORMAT_DAY
                      )}`}
                    </AppText>
                  ) : (
                    <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                      {t("select_day")}
                    </AppText>
                  )}
                  <View style={styles.arrowIcon}>
                    <ArrowRightIcon />
                  </View>
                </View>
              </TouchableOpacity>
            )}
          </View>
        </View>
        <View style={styles.divider} />
      </View>
      <TouchableOpacity style={styles.buttonReset} onPress={onResetFilter}>
        <AppText style={styles.resetTitle} color={Colors.primary50}>
          {t("reset")}
        </AppText>
      </TouchableOpacity>

      <DatePickerModal
        locale={i18n.language === LanguageStatus.VN ? "vi" : "en"}
        mode="range"
        visible={visibleModalSelectDay}
        onDismiss={() => setVisibleModalSelectDay(false)}
        startDate={startDateCustom ?? new Date()}
        endDate={endDateCustom ?? new Date()}
        onConfirm={onConfirmSelectDate}
        validRange={{
          endDate: new Date(),
        }}
        saveLabel={t("save")}
        label={t("selected_range")}
        startLabel={t("from_date")}
        endLabel={t("to_date")}
      />
      <View style={styles.footer}>
        <Button
          disabled={currentTypeTime === TYPE_TIME_FILTER.CUSTOM && !startDateCustom && !endDateCustom}
          type="primary"
          onPress={onApplyFilter}
        >
          {t("apply")}
        </Button>
      </View>
    </View>
  );
});
export default React.memo(FilterView);

const styles = StyleSheet.create({
  container: { flex: 1 },
  divider: { height: 1, backgroundColor: Colors.grayscale10 },
  timeRow: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingVertical: 16 },
  timeRowItem: { paddingVertical: 18, flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
  buttonReset: { alignSelf: "center", marginTop: 18, marginBottom: 25 },
  resetTitle: { ...Fonts.BodyMedium, fontFamily: FontTypes.semiBold },
  arrowIcon: { marginLeft: 8 },
  footer: {
    justifyContent: "flex-end",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 20,
    paddingLeft: 20,
    paddingRight: 15,
  },
  buttonClose: { padding: 5 },
  selectDay: { flexDirection: "row", alignItems: "center" },
  flex: { flex: 1 },
});
