import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <Path
      stroke="#94A3B8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8 2v3M16 2v3M21 13.63V8.5c0-3-1.5-5-5-5H8c-3.5 0-5 2-5 5V17c0 3 1.5 5 5 5h6.68M7 11h6M7 16h2.62"
    />
    <Path
      stroke="#94A3B8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M18.06 18.39a.481.481 0 0 0-.12 0 1.638 1.638 0 0 1 .06-3.275 1.638 1.638 0 0 1 .06 3.275ZM21.37 21.69A4.967 4.967 0 0 1 18 23c-1.3 0-2.48-.495-3.37-1.31.05-.47.35-.93.886-1.29 1.37-.91 3.61-.91 4.97 0 .535.36.835.82.885 1.29Z"
    />
    <Path
      stroke="#94A3B8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18 23a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
    />
  </Svg>
);
export default SvgComponent;
