import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  MobileQueryExpenseRequestWaitingApprovalQuery,
  MobileQueryExpenseRequestWaitingApprovalQueryVariables,
} from "types";

export const QUERY_EXPENSE_REQUEST = gql`
  query MobileQueryExpenseRequestWaitingApproval(
    $where: ExpMobileExpenseRequestCondition!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
    $sortOption: ExpMobileExpenseRequestsSortOptions
  ) {
    expWaitingApprovalExpenseRequests: expMobileExpenseRequests(
      where: $where
      limit: $limit
      offset: $offset
      sortOption: $sortOption
    ) {
      total
      requests {
        createdAt
        closedAt
        closedBy
        docSequence
        expenseRequestId
        expenseRequestType
        isClosed
        status
        title
        totalAmount
        updatedAt
        requestedAt
        companyEmployee {
          fullname
        }
      }
    }
  }
`;

const useExpenseRequestWaitingApproval = (variables: MobileQueryExpenseRequestWaitingApprovalQueryVariables) => {
  return useQuery<
    MobileQueryExpenseRequestWaitingApprovalQuery,
    MobileQueryExpenseRequestWaitingApprovalQueryVariables
  >(QUERY_EXPENSE_REQUEST, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !variables.where.companyId || !variables.where.employeeId,
  });
};

export default useExpenseRequestWaitingApproval;
