import { ActivityIndicator, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import {
  AppText,
  AvatarUser,
  BottomSheetFlatListModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  EmptyData,
  IconCustom,
  RadioButton,
  SearchInput,
} from "components";
import React, { FC, useRef, useState } from "react";
import { Colors, Fonts } from "theme";
import { SearchNotFoundIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";
import { EmployeeItem } from "../types";
import useEmployeesQuery from "components/AdvanceSearch/EmployeeChooser/useEmployeesQuery";
import { useAuth } from "contexts/AuthContext";
import { NetworkStatus } from "@apollo/client";

interface SelectExpenseCategoryProps {
  title?: string;
  value?: EmployeeItem;
  setValue?: (value: EmployeeItem) => void;
  isFiltering?: boolean;
}
const PAGE_SIZE = CONSTANTS.COMMON.PAGE_SIZE;
const FilterByEmployeeButton: FC<SelectExpenseCategoryProps> = ({ title, value, setValue, isFiltering }) => {
  const { t } = useTranslation("app/screens/ExpenseOverview/DashboardDetailScreen");
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const searchRef = useRef<TextInput>();
  const disableLoadMore = useRef<boolean>(true);

  const [currentValue, setCurrentValue] = useState<EmployeeItem>(null);

  const {
    company: { company_id: companyId },
  } = useAuth();
  const [keyword, setKeyword] = useState("");

  const [onFetchEmployee, { loading, data, fetchMore, networkStatus }] = useEmployeesQuery({
    where: { companyId, keyword: keyword?.trim() },
    limit: PAGE_SIZE,
    offset: 0,
  });

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const employees = data?.orgEmployees?.data ?? [];
  const debounceSearchTimeoutId = useRef(null);
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      disableLoadMore.current = true;
      setKeyword(text);
    }, 400);
  };

  const handleSelectEmployee = (item) => () => {
    setCurrentValue(item);
  };

  const handleClearSearch = () => {
    searchRef?.current?.setNativeProps({ text: "" });
    setKeyword("");
  };

  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    if (currentValue) {
      setValue(null);
    }
  };

  const handleApplyFilter = () => {
    bottomSheetRef?.current?.close();
    setValue(currentValue);
  };
  const handleOpenModal = () => {
    onFetchEmployee?.();
    handleClearSearch();
    bottomSheetRef?.current?.present();
    setCurrentValue(value);
    setTimeout(() => {
      disableLoadMore.current = false;
    }, 1000);
  };
  const renderLoadingMore = () => {
    if (isLoadingMore) {
      return <ActivityIndicator size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const handleLoadMore = () => {
    if (disableLoadMore.current || isLoadingMore || loading || employees?.length === 0) {
      return;
    }
    disableLoadMore.current = true;
    fetchMore({
      variables: {
        offset: employees.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.orgEmployees?.data?.length) {
          return prev;
        }
        if (fetchMoreResult.orgEmployees.data.length >= PAGE_SIZE) {
          setTimeout(() => {
            disableLoadMore.current = false;
          }, 1000);
        }
        return {
          orgEmployees: {
            data: [...prev.orgEmployees.data, ...fetchMoreResult.orgEmployees.data],
          },
        };
      },
    });
  };

  const renderFooter = () => {
    return (
      <View style={styles.footerButton}>
        <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
          {t("clear_filter")}
        </Button>
        <Button style={styles.flex} onPress={handleApplyFilter} disabled={!currentValue}>
          {t("apply")}
        </Button>
      </View>
    );
  };

  const renderSearch = () => (
    <SearchInput
      style={styles.searchInput}
      placeholder={t("search_employee")}
      onChangeText={onChangeSearch}
      autoCapitalize="none"
      enablesReturnKeyAutomatically
      returnKeyType="search"
      ref={searchRef}
      //value={keyword || ""}
      right={
        keyword ? (
          <TouchableOpacity onPress={handleClearSearch}>
            <IconCustom name="cancel" />
          </TouchableOpacity>
        ) : null
      }
    />
  );

  const renderItem = ({ item }) => {
    const isChecked = currentValue?.employeeId === item?.employeeId;
    return (
      <TouchableOpacity activeOpacity={0.9} style={styles.employeeItem} onPress={handleSelectEmployee(item)}>
        <AvatarUser url={item?.avatarUrl || item?.defaultAvatarUrl} style={styles.avatar} />
        <View style={styles.flex}>
          <AppText style={Fonts.H200} numberOfLines={1}>
            {item?.fullname}
          </AppText>
          <AppText style={[Fonts.BodySmall, { marginTop: 2 }]} color={Colors.grayscale60} numberOfLines={1}>
            {item?.email}
          </AppText>
        </View>
        <RadioButton checked={isChecked} />
      </TouchableOpacity>
    );
  };

  return (
    <>
      <TouchableOpacity onPress={handleOpenModal}>
        <IconCustom name="filter-alt" />
        {isFiltering && (
          <View style={styles.filterDot}>
            <AppText style={Fonts.BodyXSmall} color={Colors.white}>
              {1}
            </AppText>
          </View>
        )}
      </TouchableOpacity>

      <BottomSheetFlatListModalCustom
        loading={loading && !isLoadingMore}
        renderSearch={renderSearch}
        renderFooter={renderFooter}
        ref={bottomSheetRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={title}
        listProps={{
          contentContainerStyle: { paddingBottom: 90 },
          data: data?.orgEmployees?.data ?? [],
          keyExtractor: (item) => item?.employeeId,
          renderItem,
          ItemSeparatorComponent: () => <View style={styles.divider} />,
          ListEmptyComponent: <EmptyData title={t("search_not_found")} icon={<SearchNotFoundIcon />} />,
          ListFooterComponent: renderLoadingMore,
          onEndReached: handleLoadMore,
          onEndReachedThreshold: 0.5,
        }}
      />
    </>
  );
};
export default FilterByEmployeeButton;
const styles = StyleSheet.create({
  flex: { flex: 1 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  employeeItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  searchInput: { marginHorizontal: 20, marginBottom: 5 },
  footerButton: { flexDirection: "row", gap: 8 },
  filterDot: {
    position: "absolute",
    right: -3,
    top: -5,
    backgroundColor: Colors.info50,
    borderRadius: 10,
    height: 16,
    width: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: { width: 48, height: 48, borderRadius: 48 / 2 },
});
