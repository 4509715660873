import RadioSelectFilter, { RadioSelectFilterProps } from "components/AdvanceSearch/RadioSelectFilter";
import AppText from "components/AppText";
import IconCustom from "components/IconCustom";
import React, { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";

type SortingLabelProps = {
  sortingModalProps?: Omit<RadioSelectFilterProps, "renderIconButton">;
};

const SortingLabel: FC<SortingLabelProps> = ({ sortingModalProps }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
        {t("common:order_by")}:
      </AppText>
      <RadioSelectFilter
        {...sortingModalProps}
        renderIconButton={() => (
          <View style={styles.sortingLabel}>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
              {sortingModalProps?.value?.label}
            </AppText>
            <IconCustom name="swap-vert" fill={Colors.grayscale70} />
          </View>
        )}
      />
    </View>
  );
};

export default SortingLabel;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  sortingLabel: {
    alignSelf: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    paddingHorizontal: 4,
  },
});
