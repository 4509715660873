import React, { FC, memo } from "react";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";

import { MobileCrdCardTransactionQuery } from "types";
import { EmptyData, ExpenseItem } from "components";
import { EmptyExpense } from "assets/images/svg/icons";
import { EXPENSE_STATUS } from "constants/constants";
import SCREEN_NAME from "navigation/ScreenName";
import { useAuth } from "contexts/AuthContext";
import ReportStatus from "constants/ReportStatus";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

interface ExpenseTransactionListProps {
  data: MobileCrdCardTransactionQuery["crdCardTransaction"]["cardTransactionExpenses"][0][];
}

const ExpenseTransactionList: FC<ExpenseTransactionListProps> = ({ data }) => {
  const { t } = useTranslation("app/screens/Card/TransactionDetailScreen");
  const {
    user: { employee_id },
  } = useAuth();
  const navigation = useNavigation<any>();
  const handleNavigateExpense = (item) => () => {
    const isOwnerExpense = item?.employeeId === employee_id;
    if (!isOwnerExpense) {
      analyticService.logEvent({ name: EVENT.EXPENSE.TAP_VIEW_DETAIL });
      navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, { expenseId: item?.expenseId });
      return;
    }
    const isCanEdit =
      (!item.expenseReport && item?.status !== EXPENSE_STATUS.READY) ||
      item.expenseReport?.status === ReportStatus.CANCEL ||
      item.expenseReport?.status === ReportStatus.DRAFT ||
      item.expenseReport?.status === ReportStatus.REJECTED;
    if (isCanEdit) {
      analyticService.logEvent({ name: EVENT.EXPENSE.TAP_UPDATE });
      navigation.navigate(SCREEN_NAME.EditExpenseScreen, {
        invoiceId: item?.invoiceId,
        expenseId: item?.expenseId,
      });
    } else {
      analyticService.logEvent({ name: EVENT.EXPENSE.TAP_VIEW_DETAIL });
      navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, { expenseId: item?.expenseId });
    }
  };
  if (data?.length === 0) {
    return <EmptyData icon={<EmptyExpense />} title={t("empty_expense_assign")} />;
  }
  return (
    <View style={styles.container}>
      {data?.map((item) => {
        return (
          <ExpenseItem
            isExpenseTransaction={true}
            onPress={handleNavigateExpense(item?.expense)}
            item={item?.expense}
          />
        );
      })}
    </View>
  );
};
export default memo(ExpenseTransactionList);
const styles = StyleSheet.create({
  container: {
    marginTop: 14,
  },
});
