import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { VNDIcon } from "assets/images/svg/icons";
import {
  BackHeader,
  Button,
  CategorySelect,
  CurrencyInput,
  FloatFooter,
  IconCustom,
  TextInputCustom,
} from "components";
import { ALLOWANCE_TYPE, CONSTANTS, REQUEST_TYPE } from "constants/constants";
import { RootStackParamList, TabParamList } from "navigation/type";
import React, { useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Colors } from "theme";
import { v4 } from "uuid";
import { goBack } from "navigation/RootNavigation";
import { useController, useForm } from "react-hook-form";
import { AllowanceFormValues, UnitPrice } from "./types";
import ProductNameSelectInput from "screens/ExpenseRequest/AddAllowanceScreen/components/ProductNameSelectInput";
import UnitSelectInput from "screens/ExpenseRequest/AddAllowanceScreen/components/UnitSelectInput";

type AddAllowanceScreenProps = NativeStackScreenProps<TabParamList & RootStackParamList, "AddAllowanceScreen">;

const AddAllowanceScreen = ({ navigation, route }: AddAllowanceScreenProps) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/AddAllowanceScreen");
  const isEdit = Boolean(route.params?.edit);
  const { append, update, callback, type, control: controlExpenseRequestForm } = route?.params;
  const [disabledButton, setDisabledButton] = useState(false);
  const [unitPrices, setUnitPrices] = useState<UnitPrice[]>([]);
  const isPurchaseRequest = type === REQUEST_TYPE.purchase;

  const { field: currency } = useController({
    name: "currency",
    control: controlExpenseRequestForm,
  });
  const { isOn: isEnableCurrency, exchangeRate } = currency?.value ?? {};

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AllowanceFormValues>({
    shouldFocusError: false,
    defaultValues: route.params?.edit?.allowance ?? undefined,
    mode: CONSTANTS.COMMON.VALIDATE_RULE,
  });
  const renderTitlePage = () => {
    if (isEdit) {
      return type === REQUEST_TYPE.purchase ? t("update_product_service") : t("update_budget");
    }
    return type === REQUEST_TYPE.purchase ? t("add_product_service") : t("add_budget");
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={renderTitlePage()} />,
    });
  }, [isEdit, navigation, t]);

  const onSubmitForm = (values: AllowanceFormValues) => {
    setDisabledButton(true);
    goBack();
    if (!route.params.edit) {
      append({ ...values, type: ALLOWANCE_TYPE.USER_REQUEST, id: v4() });
    } else {
      const { index } = route.params.edit;
      update(index, values);
    }
    callback?.();
  };

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView contentContainerStyle={styles.contentContainer} showsVerticalScrollIndicator={false}>
        {isEnableCurrency && (
          <CurrencyInput
            name="foreignAmount"
            placeholder="0"
            right={<IconCustom name="attach-money" width={26} height={26} fill="#94A3B8" />}
            control={control}
            rules={{
              required: t("required"),
            }}
            error={errors.foreignAmount}
            label={t("foreign_amount")}
            onChange={(amount) => {
              setValue("amount", amount * exchangeRate);
            }}
          />
        )}
        <CurrencyInput
          disabled={isEnableCurrency || isPurchaseRequest}
          name="amount"
          placeholder="0"
          right={<VNDIcon />}
          control={control}
          rules={
            !isEnableCurrency
              ? {
                  required: t("required"),
                }
              : undefined
          }
          error={errors.amount}
          label={t("amount")}
        />
        <CategorySelect
          error={errors.expenseCategoryId}
          rules={{ required: t("required") }}
          name="expenseCategoryId"
          control={control}
        />
        <TextInputCustom
          name="description"
          control={control}
          label={t("description")}
          placeholder={t("description")}
          multiline
          autoHeight
          rules={{ maxLength: { value: 1000 } }}
        />
        {isPurchaseRequest && (
          <>
            <ProductNameSelectInput
              control={control}
              rules={{ required: t("required") }}
              error={errors?.productName}
              setValue={({ value, unitPrices }) => {
                setValue("productName", value, { shouldValidate: true });
                setUnitPrices(unitPrices);
              }}
              getValues={getValues}
              unitPrices={unitPrices}
              setUnitPrices={setUnitPrices}
            />
            <UnitSelectInput
              rules={{ required: t("required") }}
              error={errors?.uom}
              setValue={setValue}
              unitPrices={unitPrices}
              control={control}
              getValues={getValues}
            />
            <CurrencyInput
              rules={{
                required: t("required"),
              }}
              error={errors?.quantity}
              name="quantity"
              control={control}
              label={t("quantity")}
              placeholder={t("quantity")}
              onChange={(value) => {
                setValue("amount", getValues("unitPrice") * value, { shouldValidate: true });
              }}
            />
            <CurrencyInput
              rules={{ required: t("required") }}
              name="unitPrice"
              error={errors?.unitPrice}
              control={control}
              label={t("unit_price")}
              placeholder={t("unit_price")}
              right={<VNDIcon />}
              onChange={(value) => {
                setValue("amount", getValues("quantity") * value, { shouldValidate: true });
              }}
            />
          </>
        )}
      </KeyboardAwareScrollView>
      <FloatFooter>
        <Button disabled={disabledButton} type="primary" onPress={handleSubmit(onSubmitForm)}>
          {isEdit ? t("update") : t("add")}
        </Button>
      </FloatFooter>
    </View>
  );
};

export default AddAllowanceScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, gap: 6, paddingBottom: 80 },
});
