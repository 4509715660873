import { FC } from "react";
import { View, ViewStyle } from "react-native";
import { Image, ImageContentFit, ImageSource, ImageStyle, ImageProps } from "expo-image";

interface ImageCustomProps extends ImageProps {
  containerStyle?: ViewStyle | ViewStyle[];
  style?: ImageStyle | ImageStyle[];
  source?: ImageSource;
  contentFit?: ImageContentFit;
}
const ImageCustom: FC<ImageCustomProps> = ({ containerStyle, style, source, contentFit = "cover", ...rest }) => {
  /*-- handle case remote url is empty or undefine - avoid crash app on android --*/
  if (Object.keys(source)[0] === "uri" && !source?.uri) {
    return (
      <View style={containerStyle}>
        <View style={style} />
      </View>
    );
  }
  return (
    <View style={containerStyle}>
      <Image style={style} source={source} contentFit={contentFit} {...rest} />
    </View>
  );
};
export default ImageCustom;
