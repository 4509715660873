import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const EXPENSE_CANCEL_REPORT = gql`
  mutation MobileCancelExpenseReport($reason: String, $report_id: UUID!) {
    expenseCancelReport(reason: $reason, report_id: $report_id) {
      message
      success
    }
  }
`;

const useCancelExpenseReport = () => {
  const [mutation, results] = useMutation(EXPENSE_CANCEL_REPORT);

  const expenseCancelReport = useCallback(
    async (variables) => {
      return await mutation({ variables });
    },
    [mutation]
  );

  return {
    expenseCancelReport,
    ...results,
  };
};

export default useCancelExpenseReport;
