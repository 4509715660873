import { FC, memo, useEffect, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";

import { AppText, TextView } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { formatTime, getTitleFromLocation, getWarningMessageTravelDateIfExist } from "utils";
import { MobileExpExpenseRequestDetailQuery } from "types";
import useExpenseRequestTravelUsage from "screens/ExpenseRequest/hooks/useExpenseRequestTravelUsage";
import useExpEmployeeExpensePolicyTravel from "screens/ExpenseRequest/hooks/useExpEmployeeExpensePolicyTravel";

interface BudgetDetailProp {
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
}

const TravelRequestInfo: FC<BudgetDetailProp> = ({ expenseRequest }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const [fetchTravelUsage, { data: travelUsage }] = useExpenseRequestTravelUsage({
    input: {
      employeeId: expenseRequest?.companyEmployee?.employeeId,
      travelDateRange: {
        fromDate: expenseRequest?.expenseTravels?.[0].fromDate,
        toDate: expenseRequest?.expenseTravels?.[0].toDate,
      },
    },
  });

  const [fetchExpensePolicyTravel, { data: expensePolicyTravel }] = useExpEmployeeExpensePolicyTravel({
    employeeId: expenseRequest?.companyEmployee?.employeeId,
  });

  const isValidated = useRef(false);
  useEffect(() => {
    if (isValidated.current || !expenseRequest) {
      return;
    }

    isValidated.current = true;
    fetchExpensePolicyTravel().then(({ data }) => {
      if (data?.expEmployeeExpensePolicyTravel?.limitation?.isEnabled) {
        fetchTravelUsage();
      }
    });
  });

  const expenseTravel = useMemo(() => {
    return expenseRequest?.expenseTravels?.[0];
  }, [expenseRequest]);

  const warningMessage = useMemo(() => {
    const limitationEnable = expensePolicyTravel?.expEmployeeExpensePolicyTravel?.limitation?.isEnabled;
    const fromDate = expenseRequest?.expenseTravels?.[0].fromDate;
    const toDate = expenseRequest?.expenseTravels?.[0].toDate;

    if ([!!travelUsage, !!fromDate, !!toDate, limitationEnable].some((x) => !x)) {
      return "";
    }

    return getWarningMessageTravelDateIfExist(travelUsage?.expExpenseRequestTravelUsage?.periods, fromDate, toDate);
  }, [travelUsage, expensePolicyTravel, expenseRequest]);

  return (
    <View style={styles.container}>
      {/*--- traver request info ---*/}
      <View style={styles.travelRequestContainer}>
        <AppText style={[Fonts.SentenceSubtitleLarge, { marginBottom: 16 }]}>{t("detail_request_info")}</AppText>
        <TextView
          label={
            expenseTravel?.travelDuration > 1
              ? t("times", { day: expenseTravel?.travelDuration })
              : t("time", { day: expenseTravel?.travelDuration })
          }
          style={styles.textView}
          value={
            expenseTravel?.travelDuration > 1
              ? `${formatTime(expenseTravel?.fromDate, "ddd, DD/MM/YYYY")} - ${formatTime(
                  expenseTravel?.toDate,
                  "ddd, DD/MM/YYYY"
                )}`
              : formatTime(expenseTravel?.fromDate, "ddd, DD/MM/YYYY")
          }
          footer={
            warningMessage ? (
              <AppText color={Colors.alert50} style={Fonts.SentenceCaptionLarge}>
                {warningMessage}
              </AppText>
            ) : null
          }
        />
        <TextView
          label={t("location_from")}
          type="location_from"
          style={styles.textView}
          value={getTitleFromLocation(expenseTravel?.fromLocation)}
        />
        <TextView
          label={t("location_to")}
          type="location_to"
          style={styles.textView}
          value={getTitleFromLocation(expenseTravel?.toLocation)}
        />
      </View>
    </View>
  );
};
export default memo(TravelRequestInfo);
const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 16,
    paddingBottom: 4,
  },
  travelRequestContainer: {},
  textView: { marginBottom: 8 },
});
