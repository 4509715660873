import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MobileBatchApproveRequestsMutation, MobileBatchApproveRequestsMutationVariables } from "types";

const BATCH_APPROVE_REQUEST = gql`
  mutation MobileBatchApproveRequests($requestIds: [UUID!]!) {
    expenseApproveRequests(request_ids: $requestIds) {
      requestId
      success
      message
      status
    }
  }
`;
const useBatchApproveRequests = () =>
  useMutation<MobileBatchApproveRequestsMutation, MobileBatchApproveRequestsMutationVariables>(BATCH_APPROVE_REQUEST);

export default useBatchApproveRequests;
