import React, { FC, ReactElement, useEffect, useRef } from "react";
import { Keyboard, StyleSheet, View } from "react-native";

import { BottomSheetFlatListCustom, EmptyData, SkeletonListLoading } from "components";
import { Colors } from "theme";
import { CONSTANTS } from "constants/constants";
import EmployeeItem, { EmployeeOption } from "./EmployeeOption";
import { EmptyResultFound } from "assets/images/svg/icons";
import { useTranslation } from "react-i18next";
import { useEmployeeChooserContext } from "./EmployeeChooserContext";
import { useAuth } from "contexts/AuthContext";
import useEmployeesQuery from "./useEmployeesQuery";

interface EmployeeListProps {
  textSearch?: string;
  headerComponent?: ReactElement;
}

const EmployeeList: FC<EmployeeListProps> = ({ textSearch, headerComponent }) => {
  const { t } = useTranslation("app/components/AdvanceSearch/EmployeeChooser");
  const { chosen, setChosen } = useEmployeeChooserContext();
  const lastItemReached = useRef(false);
  useEffect(() => {
    lastItemReached.current = false;
    textSearch && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearch]);

  const {
    company: { company_id: companyId },
  } = useAuth();
  const [, { loading, networkStatus, data, refetch, fetchMore }] = useEmployeesQuery({
    where: { companyId, keyword: textSearch },
    limit: CONSTANTS.COMMON.PAGE_SIZE,
    offset: 0,
  });
  const isLoadingMore = networkStatus === 3;
  const employees = (data?.orgEmployees?.data ?? []) as OrgEmployee[];

  const handleLoadMore = () => {
    if (lastItemReached.current || loading || employees?.length === 0) {
      return;
    }
    fetchMore({
      variables: {
        offset: employees.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.orgEmployees?.data?.length) {
          lastItemReached.current = true;
          return prev;
        }
        const nextCache = {
          orgEmployees: {
            data: [...prev.orgEmployees.data, ...fetchMoreResult.orgEmployees.data],
          },
        };
        return nextCache;
      },
    });
  };

  const handleItemPress = async (employee: EmployeeOption) => {
    if (employee.employeeId === chosen?.employeeId) {
      return;
    }
    Keyboard.dismiss();
    setChosen(employee);
  };

  const renderItem = ({ item }) => {
    const employee = item as EmployeeOption;
    return (
      <EmployeeItem
        employee={employee}
        checked={employee.employeeId === chosen?.employeeId}
        onPress={handleItemPress}
      />
    );
  };

  const renderEmpty = () => {
    if (loading) {
      return null;
    }
    return <EmptyData icon={<EmptyResultFound />} title={t("empty_bank")} />;
  };

  return loading && !isLoadingMore ? (
    <SkeletonListLoading />
  ) : (
    <BottomSheetFlatListCustom
      ListHeaderComponent={headerComponent}
      keyboardShouldPersistTaps="always"
      keyExtractor={(item, index) => item?.bankId ?? index}
      data={employees}
      renderItem={renderItem}
      showsVerticalScrollIndicator={false}
      ListEmptyComponent={renderEmpty}
      ItemSeparatorComponent={() => <View style={styles.divider} />}
      onEndReached={handleLoadMore}
      onEndReachedThreshold={0.5}
      contentContainerStyle={styles.contentContainer}
    />
  );
};

export default EmployeeList;

const styles = StyleSheet.create({
  contentContainer: { paddingBottom: 100 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
