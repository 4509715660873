import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M11.989 17.615a.983.983 0 00.723-.295.985.985 0 00.296-.724.983.983 0 00-.296-.724.985.985 0 00-.724-.295.983.983 0 00-.724.296.986.986 0 00-.295.724c0 .285.099.526.296.723a.986.986 0 00.724.295zm.013 3.885a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749zM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20zm.081-12.28c.461 0 .86.144 1.198.435.337.29.506.654.506 1.09 0 .366-.108.694-.324.984-.215.29-.461.56-.738.81a6.367 6.367 0 00-.959 1.062 2.339 2.339 0 00-.443 1.291.566.566 0 00.193.458.676.676 0 00.47.185.679.679 0 00.49-.193.876.876 0 00.255-.47c.067-.345.209-.65.426-.92a8.14 8.14 0 01.706-.765c.364-.356.682-.743.954-1.162.272-.42.408-.888.408-1.404 0-.792-.312-1.443-.937-1.953-.624-.51-1.353-.764-2.186-.764-.595 0-1.156.132-1.683.395a2.885 2.885 0 00-1.23 1.153.686.686 0 00-.088.49c.032.171.12.301.264.39.182.102.37.13.562.087a.846.846 0 00.49-.329 2.36 2.36 0 01.736-.632c.288-.16.598-.239.93-.239z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
