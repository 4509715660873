import { AppText } from "components";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import TextView from "components/TextView";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { ExpenseDetailsView } from "screens/Expense/ExpenseDetailsScreen/types";
import { useMasterData } from "contexts/MasterDataContext";

interface LocationInfoSectionProps {
  expenseDetail?: Partial<ExpenseDetailsView>;
}
const LocationInfoSection: FC<LocationInfoSectionProps> = ({ expenseDetail }) => {
  const { t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");
  const { setting } = useMasterData();
  const isRequiredLocation = setting?.requiredLocation;

  const haveLocationInfo = expenseDetail?.placeDetails?.location_name && expenseDetail?.placeDetails?.location_address;
  return (
    <View style={StyleSheet.flatten([styles.section])}>
      <AppText style={StyleSheet.flatten([Fonts.SentenceSubtitleLarge, styles.titleSection])}>
        {t("location_info")}
      </AppText>
      {haveLocationInfo || isRequiredLocation ? (
        <View style={{ gap: 6 }}>
          <TextView
            error={
              isRequiredLocation && !expenseDetail.placeDetails?.location_name
                ? t("missing_value", { name: t("location").toLowerCase() })
                : ""
            }
            value={expenseDetail.placeDetails?.location_name}
            label={t("location")}
            emptyText={t("no_value", { label: t("location").toLowerCase() })}
          />
          <TextView
            error={
              isRequiredLocation && !expenseDetail.placeDetails?.location_name
                ? t("missing_value", { name: t("address").toLowerCase() })
                : ""
            }
            value={expenseDetail.placeDetails?.location_address}
            label={t("address")}
            emptyText={t("no_value", { label: t("address").toLowerCase() })}
          />
        </View>
      ) : (
        <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale70}>
          {t("no_value", { label: (t("location_info") ?? "").toLocaleLowerCase() })}
        </AppText>
      )}
    </View>
  );
};
export default LocationInfoSection;

const styles = StyleSheet.create({
  textCenter: { textAlign: "center" },
  titleSection: {
    paddingBottom: 16,
  },
  section: {
    backgroundColor: Colors.while,
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
});
