import React, { memo, useLayoutEffect } from "react";
import { ActivityIndicator, RefreshControl, StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";

import { Colors } from "theme";
import { BackHeader, EmptyData, FlatListCustom, IconCustom, SkeletonListLoading } from "components";
import { NewReportScreenNavigationProp } from "navigation/type";
import ExpenseRequestItem from "components/ExpenseRequestItem";
import useExpenseRequestsHistory from "./hooks/useExpenseRequestsHistory";
import { useAuth } from "contexts/AuthContext";
import { EmptyExpenseRequest } from "assets/images/svg/icons";
import SCREEN_NAME from "navigation/ScreenName";
import { KIND_OF_APPROVAL } from "constants/constants";
import { NetworkStatus } from "@apollo/client";

const PAGE_SIZE = 10;
const HistoryRequestScreen = () => {
  const navigation = useNavigation<NewReportScreenNavigationProp>();
  const { t } = useTranslation("app/screens/ExpenseRequest/HistoryRequestScreen");

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("title")}
          headerRight={
            <TouchableOpacity
              onPress={() =>
                navigation.navigate(SCREEN_NAME.RequestSearchScreen, {
                  fromScreen: SCREEN_NAME.HistoryRequestScreen,
                  iApprovedOrRejected: true,
                })
              }
            >
              <IconCustom name="search" />
            </TouchableOpacity>
          }
        />
      ),
    } as any);
  }, []);
  const {
    user: {
      employee_id: employeeId,
      company: { company_id: companyId },
    },
  } = useAuth();
  const { data, loading, fetchMore, refetch, networkStatus } = useExpenseRequestsHistory({
    where: {
      companyId,
      employeeId,
      kindOfApproval: KIND_OF_APPROVAL.PARTICIPATED,
    },
    limit: 10,
    offset: 0,
  });

  const expenseRequestList = data?.expExpenseRequestsHistory?.requests ?? [];
  const total = data?.expExpenseRequestsHistory?.total ?? 0;

  const onRefresh = async () => {
    await refetch();
  };
  const handleLoadMore = async () => {
    if (networkStatus !== NetworkStatus.ready) {
      return;
    }

    if (expenseRequestList?.length < PAGE_SIZE || expenseRequestList?.length === total) {
      return;
    }

    await fetchMore({
      variables: { offset: expenseRequestList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expExpenseRequestsHistory: {
            requests: [
              ...prev?.expExpenseRequestsHistory?.requests,
              ...fetchMoreResult?.expExpenseRequestsHistory?.requests,
            ],
            total: fetchMoreResult?.expExpenseRequestsHistory?.total,
          },
        };
      },
    });
  };

  const renderFooter = () => {
    if (loading && expenseRequestList?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const renderEmpty = () => {
    if (loading) {
      return null;
    }
    return (
      <EmptyData
        title={t("empty_history")}
        description={t("empty_history_description")}
        icon={<EmptyExpenseRequest />}
      />
    );
  };
  const renderItem = ({ item, index }) => <ExpenseRequestItem item={item} isLast={index === total - 1} showCreator />;

  return (
    <View style={styles.container}>
      {loading && !data ? (
        <SkeletonListLoading />
      ) : (
        <FlatListCustom
          data={expenseRequestList}
          keyExtractor={(item) => (item as any)?.expenseRequestId}
          renderItem={renderItem}
          estimatedItemSize={88}
          contentContainerStyle={styles.listContainer}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefresh} />}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
    </View>
  );
};
export default memo(HistoryRequestScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  loadingMore: { marginTop: 10 },
  listContainer: {
    paddingBottom: 50,
    paddingHorizontal: 20,
  },
});
