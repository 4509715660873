import { NativeModules, Platform } from "react-native";

enum ResponseStatus {
  Success = 1,
  Failed = 0,
}

const setItemAsync = async (key: string, stringifiedData: string): Promise<void> =>
  new Promise((resolve) => {
    NativeModules.RNSharedWidget.setItem(key, stringifiedData, (status) => {
      if (status === ResponseStatus.Failed) {
        console.error("set data failed for key: ", key);
      }
      resolve();
    });
  });

const removeItemAsync = async (key: string): Promise<void> =>
  new Promise((resolve) => {
    NativeModules.RNSharedWidget.removeItem(key, (status) => {
      if (status === ResponseStatus.Failed) {
        console.error("remove data failed for key: ", key);
      }
      resolve();
    });
  });

const reloadWidget = (kind?: string) => {
  if (kind) {
    console.log("todo: implemented reloadTimelines(ofKind:)");
    return;
  }
  try {
    if (Platform.OS === "ios") {
      NativeModules.RNSharedWidget.reloadAllTimelines();
    } else {
      NativeModules.RNSharedWidget.reloadBudgetWidget();
    }
  } catch (e) {}
};

const widgetHelper = { setItemAsync, removeItemAsync, reloadWidget };
export default widgetHelper;
