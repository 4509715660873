// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { forwardRef, useCallback, useState } from "react";
import { Platform, StyleProp, StyleSheet, TextInput as RNTextInput, TextStyle, View, ViewStyle } from "react-native";
import { Modal } from "@ant-design/react-native";
import { Text, TextInput } from "react-native-paper";
import { Colors } from "app/constants/Colors";
import Fonts from "constants/Fonts";
import { TextInputProps } from "react-native-paper/lib/typescript/components/TextInput/TextInput";

export type PaperInputProps = TextInputProps & {
  label: string;
  error?: string;
  touched?: boolean;
  extra?: React.ReactElement;
  help?: React.ReactElement;
  leftImage?: React.ReactElement;
  tooltipText?: string;
  inputStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  rightTitle?: string;
  rightTitleStyle?: StyleProp<ViewStyle>;
  onPressRightTitle?: string;
  noLine?: boolean;
  noUnderLine?: boolean;
  right?: any;
};

const PaperInput = forwardRef((props: PaperInputProps, ref: React.ForwardedRef<RNTextInput>) => {
  const {
    error,
    touched,
    extra,
    help,
    leftImage,
    tooltipText,
    inputStyle,
    style,
    rightTitle,
    rightTitleStyle,
    onPressRightTitle,
    noUnderLine,
    onFocus,
    noLine,
    pointerEvents,
    // omit
    theme,
    ...rest
  } = props;
  const [showHelp, setShowHelp] = useState(false);

  const hasError = !!error && touched;

  const onPressCloseHelp = useCallback(() => {
    setShowHelp(false);
  }, []);

  return (
    <View pointerEvents={pointerEvents}>
      <View style={styles.containerInput} pointerEvents={pointerEvents}>
        {leftImage}
        <View style={styles.wrapperInput} pointerEvents={pointerEvents}>
          <TextInput
            ref={ref}
            underlineColor={noLine ? "transparent" : Colors.Ink100}
            style={StyleSheet.flatten([styles.input, inputStyle])}
            theme={{
              colors: { text: (inputStyle as TextStyle)?.color?.toString() ?? Colors.Ink500, background: "white" },
              fonts: {
                regular: {
                  fontFamily: (inputStyle as TextStyle)?.fontFamily ?? Fonts.fontFamily,
                },
              },
            }}
            {...(Platform.OS === "android" && {
              underlineColorAndroid: "transparent",
              textAlignVertical: "center",
            })}
            pointerEvents={pointerEvents}
            error={hasError}
            clearButtonMode="while-editing"
            placeholderTextColor={Colors.Ink300}
            autoCorrect={false}
            allowFontScaling
            spellCheck={false}
            {...rest}
            render={({ style: innerStyle, ...innerProps }) => (
              <RNTextInput
                style={[
                  innerStyle,
                  rest.multiline
                    ? {
                        paddingTop: 8,
                      }
                    : null,
                ]}
                {...innerProps}
              />
            )}
          />
          {hasError && <Text style={styles.errorStyle}>{error}</Text>}
        </View>
      </View>

      {help && (
        <Modal
          maskClosable
          closable
          transparent
          visible={showHelp}
          onClose={onPressCloseHelp}
          footer={[{ text: "OK", onPress: onPressCloseHelp }]}
        >
          <View style={styles.wrapperModalStyle}>{help}</View>
        </Modal>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  input: {
    backgroundColor: Colors.white500,
    color: Colors.Ink500,
    fontSize: 14,
    paddingHorizontal: 0,
    borderBottomColor: "transparent",

    ...(Platform.OS === "android" && { includeFontPadding: false }),
  },
  wrapperModalStyle: { paddingVertical: 20, paddingHorizontal: 20 },
  errorStyle: { marginTop: 5, fontSize: 12, color: Colors.red500 },
  wrapperInput: {
    flex: 1,
    marginLeft: 10,
  },
  containerInput: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default PaperInput;
