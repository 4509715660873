import { StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, BarChartCompareHorizontal, LoadingView } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { MobileExpInsightsSpendingTeamsQuery } from "types";
import { FC, memo, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTitleFromCompanyTeam } from "utils";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";
import { CHART_TYPE } from "screens/ExpenseOverview/DashboardDetailScreen/types";

interface CompanyTeamChartSectionProps {
  legend?: ReactElement;
  data: MobileExpInsightsSpendingTeamsQuery["expInsights"]["topSpendingTeams"];
  isCompare?: boolean;
  loading: boolean;
  fromDate: string;
  toDate: string;
}

const CompanyTeamChartSection: FC<CompanyTeamChartSectionProps> = ({
  legend,
  data,
  isCompare,
  loading,
  fromDate,
  toDate,
}) => {
  const { t } = useTranslation("app/screens/ExpenseOverview/ExpenseOverviewScreen");
  const navigation = useNavigation<any>();
  const teams = useMemo(() => {
    if (!data?.teams) {
      return [];
    }
    const anotherTeam = {
      code: "other",
      totalAmount: data?.otherAmount,
      name: t("other_teams"),
      nameEn: t("other_teams"),
    };
    const newTeams = data?.teams?.sort((a, b) => b.totalAmount - a.totalAmount) || [];
    if (data?.totalTeams > newTeams?.length) {
      return [...newTeams, ...[anotherTeam]];
    }
    return newTeams;
  }, [data]);

  const chartData = useMemo(() => {
    return teams.map((team: any) => ({
      title:
        team?.name || team?.nameEn
          ? getTitleFromCompanyTeam({
              name: team.name,
              nameEn: team.nameEn,
            })
          : t("expense_not_unit"),
      thisMonthAmount: team.totalAmount,
      prevMonthAmount: team.totalAmountPrevious,
      diffPercentage: team.totalAmountDiffPercentage,
      metadata: team,
    }));
  }, [t, teams]);

  const handleNavigate = (item) => () => {
    navigation.navigate(SCREEN_NAME.DashboardDetailScreen, {
      title: t("expenses_by_team"),
      subTitle: item
        ? getTitleFromCompanyTeam({ code: item?.code, name: item?.name || t("expense_not_unit"), nameEn: item?.nameEn })
        : "",
      categoryId: item?.categoryId,
      companyTeamId: item?.teamId,
      fromDate,
      toDate,
      type: item?.teamId || item?.teamId === null ? CHART_TYPE.CATEGORY : CHART_TYPE.TEAM,
    });
  };

  return (
    <View style={styles.container}>
      <AppText style={Fonts.H300} color={Colors.primary100}>
        {t("expense_by_team")}
      </AppText>

      {loading ? (
        <LoadingView style={styles.loadingView} />
      ) : teams?.length ? (
        <>
          {legend}
          <BarChartCompareHorizontal
            data={chartData}
            showPercent={isCompare}
            onItemPress={(metadata) => {
              if (metadata?.code === "other") {
                return;
              }
              const handler = handleNavigate(metadata);
              handler();
            }}
          />
          <TouchableOpacity style={styles.viewDetailButton} onPress={handleNavigate({ name: t("all_team") })}>
            <AppText style={Fonts.BodySmall}>{t("view_detail_teams")}</AppText>
          </TouchableOpacity>
        </>
      ) : (
        <AppText style={styles.emptyText} color={Colors.grayscale60}>
          {t("no_data")}
        </AppText>
      )}
    </View>
  );
};
export default memo(CompanyTeamChartSection);
const styles = StyleSheet.create({
  container: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingVertical: 20, gap: 16 },
  loadingView: { height: 250 },
  viewDetailButton: {
    marginTop: 15,
    height: 32,
    backgroundColor: Colors.primary0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  emptyText: { ...Fonts.BodyMedium, textAlign: "center" },
});
