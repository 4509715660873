import { BackHeader, TextView } from "components";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { Colors } from "theme";
import { CONSTANTS, REQUEST_TYPE } from "constants/constants";
import { getTitleI18n } from "utils";
import { RootStackParamList, TabParamList } from "navigation/type";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import SCREEN_NAME from "navigation/ScreenName";

type DetailAllowanceScreenProps = NativeStackScreenProps<
  TabParamList & RootStackParamList,
  typeof SCREEN_NAME.DetailAllowanceScreen
>;

const DetailAllowanceScreen = ({ navigation, route }: DetailAllowanceScreenProps) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/DetailAllowanceScreen");
  const { item, type } = route?.params;

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("detail_allowance_title")} />,
    });
  }, [navigation, t]);

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.contentContainer}>
        <View style={styles.infoContainer}>
          {Boolean(item?.foreignAmount) && (
            <TextView isMoney label={t("foreign_amount")} prefix="$" value={item?.foreignAmount} />
          )}
          <TextView isMoney label={t("amount")} value={item?.amount} />
          <TextView
            label={t("category")}
            value={getTitleI18n({ vi: item?.expenseCategory?.title, en: item?.expenseCategory?.titleEn })}
          />
          <TextView label={t("description")} value={item?.description} />
          {type === REQUEST_TYPE.purchase && (
            <>
              <TextView label={t("product_name")} value={item?.productName} />
              <TextView label={t("uom")} value={item?.uom} />
              <TextView label={t("quantity")} value={item?.quantity} />
              <TextView isMoney label={t("unit_price")} value={item?.unitPrice} />
            </>
          )}
        </View>
      </ScrollView>
    </View>
  );
};
export default DetailAllowanceScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingBottom: 30 },
  infoContainer: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 16,
    gap: 8,
  },
});
