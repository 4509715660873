import { StyleSheet, View } from "react-native";
import { AlertNotificationHandle, AppText } from "components";
import { Colors, Fonts } from "theme";
import { FC, ReactNode, useEffect, useRef } from "react";
import ImageCustom from "components/ImageCustom";
import { LoginWithZalo } from "assets/images";
import { ErrorIcon, SpinnerLoadingIcon } from "assets/images/svg/icons";
import Config from "app/config";
import AlertNotification from "components/AlertNotification/AlertNotification";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import { useAuth } from "contexts/AuthContext";
import i18n from "i18next";

interface LoginWithZaloScreenProps {
  route: { params: { idToken: string; redirect: string } };
}

enum ZALO_REDIRECT_SCREEN {
  EMAIL_SCREEN = "email_screen",
  INVOICE_SCREEN = "invoice_screen",
}

const LoginWithZaloScreen: FC<LoginWithZaloScreenProps> = ({ route }) => {
  const { idToken, redirect } = route?.params ?? {};

  const { refreshDataUser, logout } = useAuth();
  const alertRef = useRef<AlertNotificationHandle>();
  const handleFetchData = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: idToken }),
    };
    fetch(`${Config.EXPENSE_MINI_SERVICE_ENDPOINT}/auth/zalo-token`, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .then(async (rs) => {
        const accessToken = (rs as any)?.data?.accessToken;
        if (accessToken) {
          await AsyncStorage.setItem(Keys.token, accessToken);
          await refreshDataUser();
          if (redirect === ZALO_REDIRECT_SCREEN.EMAIL_SCREEN) {
            window.location.href = `${Config.EXPENSE_APP_ENDPOINT}/InboxScreen`;
          } else if (redirect === ZALO_REDIRECT_SCREEN.INVOICE_SCREEN) {
            window.location.href = `${Config.EXPENSE_APP_ENDPOINT}/InboxScreen?index=1`;
          }
          return;
        }
        throw new Error();
      })
      .catch((error) => {
        logout();
        alertRef.current.info({
          icon: <ErrorIcon />,
          title: i18n.t("common:error"),
          description: i18n.t("common:internal_error") as ReactNode,
          onConfirm: () => {
            window.location.href = `${Config.EXPENSE_APP_ENDPOINT}/LoginScreen`;
          },
        });
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <View style={styles.container}>
      <ImageCustom style={styles.logo} source={LoginWithZalo} />
      <View>
        <AppText style={Fonts.BodyLarge}>{i18n.t("common:verifying") as string}</AppText>
        <View style={styles.loadingIcon}>
          <SpinnerLoadingIcon />
        </View>
      </View>
      <AlertNotification ref={alertRef} />
    </View>
  );
};
export default LoginWithZaloScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white, justifyContent: "center", alignItems: "center", gap: 70 },
  logo: { width: 189, height: 65 },
  loadingIcon: { alignItems: "center", marginTop: 10 },
});
