import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { Colors } from "theme";

interface ILine {
  size?: "sm" | "md" | "lg" | "xl";
  containerStyle?: StyleProp<ViewStyle>;
  backgroundColor?: string;
  hasBackground?: boolean;
  color?: string;
}

const getHeight = (size: string | undefined) => {
  switch (size) {
    case "md":
      return 5;
    case "lg":
      return 8;
    case "xl":
      return 15;
    default:
      return 1;
  }
};

const Line: React.FC<ILine> = ({ containerStyle, size, backgroundColor, hasBackground }) => {
  const height = getHeight(size);
  const backgroundColorDefault = hasBackground ? backgroundColor : undefined;
  return (
    <View
      style={StyleSheet.flatten([
        styles.container,
        { height, backgroundColor: backgroundColorDefault },
        containerStyle,
      ])}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 1,
  },
});

Line.defaultProps = {
  size: "sm",
  hasBackground: true,
  backgroundColor: Colors.grayscale05,
};

export default Line;
