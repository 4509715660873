import TextView from "components/TextView";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { CONSTANTS, PAYMENT_METHOD } from "constants/constants";
import { Colors, Fonts } from "theme";
import { AppText, ImageCustom } from "components";
import { FC } from "react";
import { MobileExpExpenseRequestDetailQuery } from "types";
import RequestPaymentStatus from "screens/ExpenseRequest/components/RequestPaymentStatus";
import { REQUEST_PAYMENT_STATUS } from "screens/ExpenseRequest/constants";

const REQUEST_PAYMENT_METHODS = {
  [PAYMENT_METHOD.CASH]: "cash",
  [PAYMENT_METHOD.BANK_TRANSFER]: "bank_transfer",
};
interface AdvancePaymentInfoProps {
  cashAdvance?: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]["cashAdvances"][0];
  setPosition?: (value: number) => void;
}
const AdvancePaymentInfo: FC<AdvancePaymentInfoProps> = ({ cashAdvance, setPosition }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const paymentInfo = cashAdvance?.paymentInfo;
  return (
    <View
      style={styles.container}
      onLayout={(event) => {
        setPosition?.(event.nativeEvent.layout.y);
      }}
    >
      <View style={styles.advanceTitleRow}>
        <AppText style={[Fonts.SentenceSubtileXLarge, styles.titleSection]}>{t("advance_request")}</AppText>
        {cashAdvance && (
          <RequestPaymentStatus
            style={{ marginTop: 3 }}
            status={cashAdvance?.paymentStatus as REQUEST_PAYMENT_STATUS}
          />
        )}
      </View>
      {cashAdvance ? (
        <View style={styles.body}>
          {Boolean(cashAdvance?.foreignAmount) && (
            <TextView
              style={styles.textView}
              isMoney
              value={cashAdvance.foreignAmount}
              prefix="$"
              label={t("cash_advance_foreign_amount")}
            />
          )}
          <TextView style={styles.textView} isMoney value={cashAdvance?.amount} label={t("advance_amount")} />
          <TextView style={styles.textView} value={cashAdvance?.description} label={t("advance_purpose")} />
          <TextView
            style={styles.textView}
            value={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              t(REQUEST_PAYMENT_METHODS[cashAdvance?.paymentMethod])
            }
            label={t("payment_method")}
          />
          {cashAdvance?.paymentMethod === PAYMENT_METHOD.BANK_TRANSFER && (
            <>
              <TextView
                style={styles.textView}
                value={paymentInfo?.description}
                emptyText={t("no_value", { label: t("payment_description").toLowerCase() })}
                label={t("payment_description")}
              />
              <View style={styles.paymentInfo}>
                <ImageCustom contentFit="contain" style={styles.bankAvatar} source={{ uri: paymentInfo?.logoUrl }} />
                <View style={styles.flex}>
                  <AppText style={Fonts.NumericN200}>{paymentInfo?.accountHolderName}</AppText>
                  <AppText style={Fonts.Caption} color={Colors.grayscale80} numberOfLines={1} ellipsizeMode="head">
                    {paymentInfo?.bankName}
                  </AppText>
                  <AppText style={Fonts.Caption} color={Colors.grayscale80}>
                    {paymentInfo?.accountNumber}
                  </AppText>
                  <AppText style={Fonts.Caption} color={Colors.grayscale80} numberOfLines={1} ellipsizeMode="head">
                    {paymentInfo?.branchName}
                  </AppText>
                </View>
              </View>
            </>
          )}
        </View>
      ) : (
        <View>
          <AppText style={[Fonts.BodyMedium, styles.emptyText]} color={Colors.grayscale60}>
            {t("request_not_advance")}
          </AppText>
        </View>
      )}
    </View>
  );
};
export default AdvancePaymentInfo;
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingVertical: 16,
  },
  body: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  textView: {
    marginTop: 8,
  },
  titleSection: {
    marginBottom: 10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  emptyText: { textAlign: "center", marginBottom: 8 },
  paymentInfo: {
    backgroundColor: Colors.grayscale05,
    paddingVertical: 8,
    paddingLeft: 8,
    paddingRight: 12,
    borderRadius: 8,
    marginTop: 14,
    flexDirection: "row",
    gap: 8,
  },
  bankAvatar: { width: 70, height: 70, borderRadius: 8, backgroundColor: Colors.white },
  flex: { flex: 1 },
  advanceTitleRow: { flexDirection: "row", alignItems: "center", gap: 8 },
});
