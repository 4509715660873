import { ActivityIndicator, FlatList, Keyboard, Platform, RefreshControl, StyleSheet, View } from "react-native";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "contexts/AuthContext";
import { Colors, Fonts } from "theme";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  BottomSheetTextInputCustom,
  CurrencyText,
  EmptyData,
  SkeletonListLoading,
  SortingLabel,
  ToastManager,
} from "components";
import {
  BizziBotEyesTwinkle,
  BizziBotWarning2,
  CancelReportWarningIcon,
  EmptyWaitingApproval,
} from "assets/images/svg/icons";
import ExpenseRequestItem from "components/ExpenseRequestItem";
import { CONSTANTS, KIND_OF_APPROVAL, TRACKING_ACTION_TYPE, TRACKING_OBJECT_TYPE } from "constants/constants";
import useExpenseRequestWaitingApproval from "./hooks/useExpenseRequestWaitingApproval";
import { NetworkStatus } from "@apollo/client";
import BatchApprovalLayout from "components/BatchApprovalLayout/BatchApprovalLayout";
import useSelectList from "hooks/useSelectList";
import { RequestItemDisplayMode } from "components/ExpenseRequestItem/ExpenseRequestItem";
import { ApprovalModal, ApprovalModalApi, ApprovalModalSummary } from "components/BatchApprovalLayout/ApprovalModal";
import useBatchRejectRequests from "hooks/request/useBatchRejectRequests";
import useBatchApproveRequests from "hooks/request/useBatchApproveRequests";
import { REQUEST_SORT_OPTIONS } from "./types";
import { EVENT } from "constants/Tracking";
import { analyticService } from "services/AnalyticsService";
import SCREEN_NAME from "navigation/ScreenName";
import RequestCategoryStatus from "constants/RequestCategoryStatus";
import { FeedbackObjectType, useInAppFeedback } from "contexts/InAppFeedbackContext";
import ExpenseRequestItemAlt from "components/ExpenseRequestItem/ExpenseRequestItemAlt";
import { FlashList } from "@shopify/flash-list";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { RequestBatchApprovalScreenProps } from "navigation/type";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";

const PAGE_SIZE = 10;

const RequestBatchApprovalScreen: FC<RequestBatchApprovalScreenProps> = ({ route, navigation }) => {
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const { i18n, t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestListScreen");
  const sortOptions = useMemo(
    () =>
      [
        { label: t("requested_at_asc"), value: REQUEST_SORT_OPTIONS.REQUESTED_AT_ASC },
        { label: t("requested_at_desc"), value: REQUEST_SORT_OPTIONS.REQUESTED_AT_DESC },
        {
          label: t("expense_asc"),
          value: REQUEST_SORT_OPTIONS.AMOUNT_ASC,
        },
        {
          label: t("expense_desc"),
          value: REQUEST_SORT_OPTIONS.AMOUNT_DESC,
        },
      ].reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [t]
  );
  const { requestInAppFeedback } = useInAppFeedback();

  const [sortOption, setSortOption] = useState<SelectItem>(
    sortOptions[route?.params?.sortOption ?? REQUEST_SORT_OPTIONS.REQUESTED_AT_ASC]
  );
  const { data, loading, called, fetchMore, refetch, networkStatus } = useExpenseRequestWaitingApproval({
    where: {
      companyId,
      employeeId,
      kindOfApproval: KIND_OF_APPROVAL.AWAITING_MY_APPROVAL,
    },
    sortOption: sortOption.value,
    limit: PAGE_SIZE,
    offset: 0,
  });
  const flatListRef = useRef<FlatList>(null);

  const requestList = data?.expWaitingApprovalExpenseRequests?.requests ?? [];
  const total = data?.expWaitingApprovalExpenseRequests?.total ?? 0;
  const keyExtractor = useCallback((item) => item.expenseRequestId, []);
  const { selected, selectStatus, handleClickItem, handleSelectAll, onClear } = useSelectList(
    requestList,
    keyExtractor
  );
  const selectedAmount = [...selected].reduce((acc, key) => {
    acc += requestList.find((request) => request.expenseRequestId === key)?.totalAmount ?? 0;
    return acc;
  }, 0);

  const handleLoadMore = async () => {
    if (loading || requestList.length >= total) {
      return;
    }

    await fetchMore({
      variables: { offset: requestList.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expWaitingApprovalExpenseRequests: {
            requests: [
              ...prev?.expWaitingApprovalExpenseRequests?.requests,
              ...fetchMoreResult?.expWaitingApprovalExpenseRequests?.requests,
            ],
            total: fetchMoreResult?.expWaitingApprovalExpenseRequests?.total,
          },
        };
      },
    });
  };

  const renderItem = ({ item, index }) => (
    <ExpenseRequestItem
      onRefreshData={refetch}
      item={item}
      isLast={index === total - 1}
      showCreator
      mode={RequestItemDisplayMode.Selectable}
      selected={selected.has(item.expenseRequestId)}
      onPress={({ target }) => {
        if (target === "view-detail") {
          analyticService.logEvent({ name: EVENT.REQUEST.TAP_VIEW_DETAIL });
          navigation.navigate(SCREEN_NAME.DetailRequestScreen, {
            expenseRequestId: item.expenseRequestId,
            isTravelRequest: item.expenseRequestType === RequestCategoryStatus.TRAVEL_REQUEST,
            onRefreshData: () => refetch(),
          });
        } else {
          handleClickItem(item.expenseRequestId);
        }
      }}
    />
  );

  const approvalModalRef = useRef<ApprovalModalApi>(null);
  const notificationRef = useRef<AlertNotificationHandle>();
  const rejectModalRef = useRef<AlertNotificationHandle>();

  const [rejectReason, setRejectReason] = useState("");
  const [batchApprove] = useBatchApproveRequests();
  const [batchReject] = useBatchRejectRequests();

  const handleApproveSelected = async () => {
    analyticService.logEvent({ name: EVENT.BATCH_APPROVAL.SUBMITTED });
    notificationRef.current.loading(t("batch_approve_processing"));
    const requestIdsToApprove = [...selected].slice(0, CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL);
    try {
      const response = await batchApprove({ variables: { requestIds: requestIdsToApprove } });
      const failedRequests = response.data.expenseApproveRequests.reduce((acc, res) => {
        if (!res.success) {
          const request = requestList.find((request) => request.expenseRequestId === res.requestId);
          acc.push({ error: res, obj: { ...request, status: res.status } });
        }
        return acc;
      }, []);
      notificationRef.current.close();
      if (failedRequests.length > 0) {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS_PARTIAL,
          properties: {
            succeeded: requestIdsToApprove.length - failedRequests.length,
            failed: failedRequests.length,
            messages: failedRequests.map((res) => res?.error?.message),
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            action: TRACKING_ACTION_TYPE.APPROVED,
          },
        });
        approvalModalRef.current.show({
          summaryComponent: (
            <ApprovalModalSummary
              entry="request"
              successCount={requestIdsToApprove.length - failedRequests.length}
              errorCount={failedRequests.length}
            />
          ),
          data: failedRequests,
          onConfirm: () => {
            approvalModalRef.current.close();
          },
        });
      } else {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS,
          properties: {
            succeeded: requestIdsToApprove.length - failedRequests.length,
            failed: failedRequests.length,
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            action: TRACKING_ACTION_TYPE.APPROVED,
          },
        });
        requestInAppFeedback(FeedbackObjectType.ApprovalObject);
        ToastManager.success(
          t("batch_approved", {
            numberOfEntry: requestIdsToApprove.length,
          })
        );
      }
      onClearSelected();
    } catch (error) {
      analyticService.logEvent({
        name: EVENT.BATCH_APPROVAL.SUBMIT_FAILED,
        properties: {
          message: error?.message || i18n.t("common:internal_error").toString(),
          object_type: TRACKING_OBJECT_TYPE.REQUEST,
          action: TRACKING_ACTION_TYPE.APPROVED,
        },
      });
      notificationRef?.current?.error({
        title: t("approve_failed"),
        description: error?.message || i18n.t("common:internal_error").toString(),
      });
    }
  };
  const onClearSelected = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
    onClear();
    refetch();
  };
  const handleRejectSelected = async () => {
    Keyboard.dismiss();
    analyticService.logEvent({ name: EVENT.BATCH_APPROVAL.SUBMITTED });
    rejectModalRef.current.close();
    notificationRef.current.loading(t("batch_reject_processing"));
    const requestIdsToReject = [...selected].slice(0, CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL);
    try {
      const response = await batchReject({ variables: { requestIds: requestIdsToReject, reason: rejectReason } });
      const failedRequests = response.data.expenseRejectRequests.reduce((acc, res) => {
        if (!res.success) {
          const request = requestList.find((request) => request.expenseRequestId === res.requestId);
          acc.push({ error: res, obj: { ...request, status: res.status } });
        }
        return acc;
      }, []);
      notificationRef.current.close();
      if (failedRequests.length > 0) {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS_PARTIAL,
          properties: {
            succeeded: requestIdsToReject.length - failedRequests.length,
            failed: failedRequests.length,
            messages: failedRequests.map((res) => res?.error?.message),
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            action: TRACKING_ACTION_TYPE.REJECTED,
          },
        });
        approvalModalRef.current.show({
          summaryComponent: (
            <ApprovalModalSummary
              entry="request"
              successCount={requestIdsToReject.length - failedRequests.length}
              errorCount={failedRequests.length}
            />
          ),
          data: failedRequests,
          onConfirm: () => {
            notificationRef?.current?.close();
            approvalModalRef.current.close();
          },
        });
      } else {
        analyticService.logEvent({
          name: EVENT.BATCH_APPROVAL.SUBMIT_SUCCESS,
          properties: {
            succeeded: requestIdsToReject.length - failedRequests.length,
            failed: failedRequests.length,
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            action: TRACKING_ACTION_TYPE.REJECTED,
          },
        });
        ToastManager.success(
          t("batch_rejected", {
            numberOfEntry: requestIdsToReject.length,
          })
        );
      }
      onClearSelected();
    } catch (error) {
      analyticService.logEvent({
        name: EVENT.BATCH_APPROVAL.SUBMIT_FAILED,
        properties: {
          message: error?.message || i18n.t("common:internal_error").toString(),
          object_type: TRACKING_OBJECT_TYPE.REQUEST,
          action: TRACKING_ACTION_TYPE.REJECTED,
        },
      });
      notificationRef?.current?.error({
        title: t("reject_failed"),
        description: error?.message || i18n.t("common:internal_error").toString(),
      });
    } finally {
      setRejectReason("");
    }
  };

  const showConfirmPopup =
    ({ action }: { action: "approve" | "reject" }) =>
    () => {
      switch (action) {
        case "approve":
          notificationRef.current?.confirm({
            disableClose: true,
            icon: selected.size > 20 ? <BizziBotWarning2 width={80} height={80} /> : <BizziBotEyesTwinkle />,
            title:
              selected.size > 20
                ? t("approve_confirm_title_over")
                : t("approve_confirm_title", {
                    numberOfEntry: selected.size,
                  }),
            description:
              selected.size > 20
                ? t("approve_confirm_with_warning_subtitle", {
                    numberOfEntry: CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL,
                  })
                : t("approve_confirm_subtitle"),
            cancelText: t("cancel"),
            confirmText: t("confirm"),
            onCancel: () => {
              notificationRef.current.close();
            },
            onConfirm: handleApproveSelected,
          });
          break;
        case "reject":
          rejectModalRef.current?.confirm({
            icon: <CancelReportWarningIcon />,
            title:
              selected.size > 20
                ? t("reject_confirm_title_over")
                : t("reject_confirm_title", {
                    numberOfEntry: selected.size,
                  }),
            description: (
              <View style={styles.rejectModalContent}>
                <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale80}>
                  {selected.size > 20
                    ? t("reject_confirm_with_warning_subtitle", {
                        numberOfEntry: CONSTANTS.COMMON.MAXIMUM_BATCH_APPROVAL,
                      })
                    : t("reject_confirm_subtitle")}
                </AppText>
                <BottomSheetTextInputCustom
                  autoFocus={Platform.OS !== "web"}
                  value={rejectReason}
                  onChangeText={setRejectReason}
                  placeholder={t("please_enter_reason")}
                />
              </View>
            ),
            cancelText: t("cancel"),
            confirmText: t("confirm"),
            onCancel: () => {
              setRejectReason("");
              notificationRef.current.close();
            },
            onConfirm: handleRejectSelected,
          });
          break;
      }
    };

  const [footerHeight, setFooterHeight] = useState(0);
  const onFooterLayout = (event) => {
    if (event.nativeEvent.layout.height) {
      setFooterHeight(event.nativeEvent.layout.height);
    }
  };

  return (
    <BottomSheetModalProvider>
      <View style={styles.container}>
        <BackHeader headerTitle={t("batch_approval")} />
        <BatchApprovalLayout
          onFooterLayout={onFooterLayout}
          selected={selected}
          selectStatus={selectStatus}
          onAllButtonPress={handleSelectAll}
          onApprove={showConfirmPopup({ action: "approve" })}
          onReject={showConfirmPopup({ action: "reject" })}
          summaryComponent={
            <View style={styles.summaryContainer}>
              <View style={styles.summaryRow}>
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
                  {t("has_selected_count_maximum", {
                    count: selected.size,
                  })}
                </AppText>
              </View>
              <View style={styles.summaryRow}>
                <AppText>{t("total_amount")}</AppText>
                <CurrencyText style={Fonts.NumericN400} color={Colors.success50}>
                  {selectedAmount}
                </CurrencyText>
              </View>
            </View>
          }
          sortingComponent={
            <SortingLabel
              sortingModalProps={{
                showClearButton: false,
                title: t("sort_by"),
                data: Object.values(sortOptions),
                value: sortOption,
                setValue: setSortOption,
                onPress: () => {
                  analyticService.logEvent({ name: EVENT.REQUEST.TAP_OPEN_SORT_MODAL, properties: { tab: "waiting" } });
                },
              }}
            />
          }
        >
          {(loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
            <SkeletonListLoading />
          ) : (
            <FlashList
              ref={flatListRef}
              data={requestList}
              extraData={selected}
              keyExtractor={keyExtractor}
              renderItem={renderItem}
              estimatedItemSize={88}
              contentContainerStyle={{ ...styles.listContainer, paddingBottom: footerHeight }}
              refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
              onEndReached={handleLoadMore}
              showsVerticalScrollIndicator={false}
              onEndReachedThreshold={0.15}
              ListEmptyComponent={
                !loading &&
                called && (
                  <EmptyData
                    icon={<EmptyWaitingApproval />}
                    title={t("empty_approval_title")}
                    description={t("empty_approval_description")}
                  />
                )
              }
              ListFooterComponent={
                loading &&
                requestList?.length > 0 && (
                  <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />
                )
              }
            />
          )}
        </BatchApprovalLayout>

        <ApprovalModal
          ref={approvalModalRef}
          keyExtractor={keyExtractor}
          renderItem={({ item }) => (
            <ExpenseRequestItemAlt
              isLast={true}
              item={item}
              showCreator
              onPress={(item) => {
                analyticService.logEvent({ name: EVENT.REQUEST.TAP_VIEW_DETAIL });
                navigation.navigate(SCREEN_NAME.DetailRequestScreen, {
                  expenseRequestId: item.expenseRequestId,
                  isTravelRequest: item.expenseRequestType === RequestCategoryStatus.TRAVEL_REQUEST,
                  onRefreshData: (id) => {
                    id && approvalModalRef.current.removeItem(id);
                  },
                });
              }}
            />
          )}
        />
      </View>
      <AlertNotification ref={notificationRef} />
      <AlertNotification ref={rejectModalRef} />
    </BottomSheetModalProvider>
  );
};
export default RequestBatchApprovalScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  loadingMore: { marginTop: 10 },
  summaryContainer: {
    gap: 4,
    paddingVertical: 4,
  },
  summaryRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
  },
  rejectModalContent: {
    alignItems: "center",
  },
  textCenter: { textAlign: "center" },
});
