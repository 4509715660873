import { StyleSheet, View } from "react-native";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  Button,
  EmptyData,
  IconCustom,
} from "components";
import React, { FC, useRef, useState } from "react";
import { CONSTANTS } from "constants/constants";
import PlaneTicket from "../components/PlaneTicket";
import { Colors, Fonts } from "theme";
import TicketFilterModal from "./components/TicketFilterModal";
import TicketDetailModal, { TicketDetailModalApi } from "../components/TicketDetailModal";
import { BottomSheetModalProvider, TouchableOpacity } from "@gorhom/bottom-sheet";
import SCREEN_NAME from "navigation/ScreenName";
import TicketSortingModal from "./components/TicketSortingModal";
import useBokSearchFlight from "screens/FlightBooking/hooks/useBokSearchFlight";
import { FlightTicket, FlightTicketType, PlaneTicketSortOptions } from "screens/FlightBooking/types";
import SummarySection from "screens/FlightBooking/FlightSearchScreen/components/SummarySection";
import { FlightSearchScreenProps } from "navigation/type";
import { useFlightBooking, useFlightBookingApis } from "screens/FlightBooking/context/FlightBookingProvider";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import LottieLoading from "components/LottieLoading";
import { FlashList } from "@shopify/flash-list";
import { BizziBotWarning, EmptyResultFound } from "assets/images/svg/icons";

const FlightSearchScreen: FC<FlightSearchScreenProps> = ({ route, navigation }) => {
  const [minPriceTicket, setMinPriceTicket] = useState(0);
  const [maxPriceTicket, setMaxPriceTicket] = useState(0);
  const {
    fromScreen,
    type,
    expenseBookingId,
    expenseRequestId,
    fromCode,
    fromMaxAmount,
    toCode,
    toMaxAmount,
    departureDate,
    returnDate,
    cabinClass,
  } = route.params;
  const { t } = useTranslation("app/screens/FlightBooking/FlightSearchScreen/FlightSearchScreen");
  const bookingCtxApis = useFlightBookingApis();
  const { tickets: chooseTickets } = useFlightBooking();
  const [flightTickets, setFlightTickets] = useState<FlightTicket[]>([]);
  const flatListRef = useRef<any>();

  const { loading } = useBokSearchFlight(
    {
      input: {
        departure: type === FlightTicketType.Departure ? fromCode : toCode,
        destination: type === FlightTicketType.Departure ? toCode : fromCode,
        departureDate: type === FlightTicketType.Departure ? departureDate : returnDate,
        isRoundTrip: false,
        passengerInfo: {
          adult: 1,
          children: 0,
          infant: 0,
        },
        travelLimit:
          type === FlightTicketType.Departure
            ? toMaxAmount
              ? Number(toMaxAmount)
              : undefined
            : fromMaxAmount
            ? Number(fromMaxAmount)
            : undefined,
        cabinClass,
      },
    },
    (data) => {
      const tickets = data?.bokSearchFlight?.bokFares?.sort((a, b) => a.totalPrice - b.totalPrice) ?? [];
      const prices = tickets.map((item) => item.totalPrice);
      if (prices.length === 0) {
        prices.push(0);
        prices.push(type === FlightTicketType.Departure ? fromMaxAmount ?? 0 : toMaxAmount ?? 0);
      }

      setMinPriceTicket(Math.min(...prices));
      setMaxPriceTicket(Math.max(...prices));
      setFlightTickets(tickets);
    }
  );

  const ticketModalRef = useRef<TicketDetailModalApi>(null);
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);

  const handleSelectTicket = (ticket: FlightTicket) => () => {
    const forwardParams = {
      expenseRequestId,
      expenseBookingId,
      fromCode,
      fromMaxAmount,
      toCode,
      toMaxAmount,
      departureDate,
      returnDate,
      cabinClass,
    };
    switch (type) {
      case FlightTicketType.Departure:
        bookingCtxApis.chooseDeparture(ticket);
        break;
      case FlightTicketType.Return:
        bookingCtxApis.chooseReturn(ticket);
    }

    switch (fromScreen) {
      case SCREEN_NAME.DetailRequestScreen:
        if (returnDate) {
          navigation.push(SCREEN_NAME.FlightSearchScreen, {
            ...forwardParams,
            fromScreen: SCREEN_NAME.FlightSearchScreen,
            type: FlightTicketType.Return,
          });
        } else {
          navigation.navigate(SCREEN_NAME.PassengerInfoScreen, forwardParams);
        }
        break;
      case SCREEN_NAME.FlightSearchScreen:
        navigation.navigate(SCREEN_NAME.PassengerInfoScreen, forwardParams);
        break;
      case SCREEN_NAME.PassengerInfoScreen:
      case SCREEN_NAME.LuggageScreen:
        navigation.goBack();
        break;
    }
    setTimeout(() => {
      ticketModalRef.current?.close();
    }, 1000);
  };
  const handleApplySort = (value: PlaneTicketSortOptions) => {
    flatListRef?.current?.scrollToOffset?.({ animated: true, offset: 0 });
    switch (value) {
      case PlaneTicketSortOptions.PriceAsc: {
        setFlightTickets((prevState) => [...prevState.sort((a, b) => a.totalPrice - b.totalPrice)]);
        break;
      }
      case PlaneTicketSortOptions.DepartureAsc: {
        setFlightTickets((prevState) => [
          ...prevState.sort(
            (a, b) => new Date(a.flights?.[0].startDate).getTime() - new Date(b.flights?.[0].startDate).getTime()
          ),
        ]);
        break;
      }
      case PlaneTicketSortOptions.DepartureDesc: {
        setFlightTickets((prevState) => [
          ...prevState.sort(
            (a, b) => new Date(b.flights?.[0].startDate).getTime() - new Date(a.flights?.[0].startDate).getTime()
          ),
        ]);
        break;
      }
      case PlaneTicketSortOptions.ArrivalAsc: {
        setFlightTickets((prevState) => [
          ...prevState.sort(
            (a, b) => new Date(a.flights?.[0].endDate).getTime() - new Date(b.flights?.[0].endDate).getTime()
          ),
        ]);
        break;
      }
      case PlaneTicketSortOptions.ArrivalDesc: {
        setFlightTickets((prevState) => [
          ...prevState.sort(
            (a, b) => new Date(b.flights?.[0].endDate).getTime() - new Date(a.flights?.[0].endDate).getTime()
          ),
        ]);
        break;
      }
      case PlaneTicketSortOptions.DurationAsc: {
        setFlightTickets((prevState) => [
          ...prevState.sort((a, b) => a.flights?.[0]?.duration - b.flights?.[0]?.duration),
        ]);
        break;
      }
    }
  };
  const handleItemPress = (ticket) => () => {
    const departureAt = dayjs(ticket.flights?.[0]?.startDate);
    const arrivalAt = dayjs(ticket.flights?.[0]?.endDate);

    if (
      type === FlightTicketType.Departure &&
      chooseTickets?.return &&
      dayjs(chooseTickets?.return?.flights?.[0]?.startDate).diff(arrivalAt, "hour") < 1
    ) {
      alertNotificationRef.current.info({
        icon: <BizziBotWarning />,
        title: t("choose_another_flight"),
        description: t("choose_another_departure_flight", {
          time: dayjs(chooseTickets?.return?.flights?.[0]?.startDate).subtract(1, "hour").format("HH:mm DD/MM/YYYY"),
        }),
        confirmText: t("understood"),
        onConfirm: () => {
          alertNotificationRef?.current?.close();
        },
      });
      return;
    }

    if (
      type === FlightTicketType.Return &&
      departureAt.diff(dayjs(chooseTickets?.departure?.flights?.[0]?.endDate), "hour") < 1
    ) {
      alertNotificationRef.current.info({
        icon: <BizziBotWarning />,
        title: t("choose_another_flight"),
        description: t("choose_another_departure_flight", {
          time: dayjs(chooseTickets?.departure?.flights?.[0]?.endDate).add(1, "hour").format("HH:mm DD/MM/YYYY"),
        }),
        confirmText: t("understood"),
        onConfirm: () => {
          alertNotificationRef?.current?.close();
        },
      });
      return;
    }

    ticketModalRef.current?.showTicket(
      { ticket },
      {
        icon:
          type === FlightTicketType.Departure ? (
            <IconCustom name="flight-takeoff" />
          ) : (
            <IconCustom name="flight-land" />
          ),
        footer: <Button onPress={handleSelectTicket(ticket)}>{t("choose_ticket")}</Button>,
      }
    );
  };

  const renderItem = ({ item: ticket, index }) => {
    const extraStyle = index === 0 ? {} : { marginTop: 12 };
    return (
      <TouchableOpacity style={[styles.listItemContainer, extraStyle]} onPress={handleItemPress(ticket)}>
        <PlaneTicket mode="listItem" item={ticket} />
      </TouchableOpacity>
    );
  };

  return (
    <BottomSheetModalProvider>
      <View style={styles.container}>
        <BackHeader
          containerStyle={styles.pageHeader}
          headerTitle={type === "departure" ? t("choose_departure_ticket") : t("choose_return_ticket")}
          headerRight={
            !loading && (
              <TicketFilterModal
                tickets={flightTickets}
                minAmount={minPriceTicket}
                maxAmount={maxPriceTicket}
                button={<IconCustom name="filter-alt" />}
                setTickets={setFlightTickets}
                departureDate={departureDate}
                scrollToTop={() => flatListRef?.current?.scrollToOffset?.({ animated: true, offset: 0 })}
              />
            )
          }
        />
        <SummarySection data={route.params} type={type} />
        {loading ? (
          <LottieLoading title={t("loading_ticket_title")} />
        ) : (
          <>
            {Boolean(flightTickets?.length) && (
              <View style={styles.listHeader}>
                <AppText style={[Fonts.H200, styles.allSpaceLeft]} color={Colors.grayscale100}>
                  {t("found_flight", {
                    numberOfFlight: flightTickets?.length ?? 0,
                  })}
                </AppText>
                <TicketSortingModal handleApplySort={handleApplySort} button={<IconCustom name="sort" />} />
              </View>
            )}
            <FlashList
              estimatedItemSize={123}
              ref={flatListRef}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item, index) => index.toString()}
              data={flightTickets}
              renderItem={renderItem}
              ListEmptyComponent={
                <EmptyData
                  title={t("no_tickets_found")}
                  icon={<EmptyResultFound />}
                  description={t("no_tickets_found_description")}
                />
              }
              contentContainerStyle={styles.ticketListContainer}
            />
          </>
        )}
      </View>
      <TicketDetailModal ref={ticketModalRef} />
      <AlertNotification ref={alertNotificationRef} />
    </BottomSheetModalProvider>
  );
};

export default FlightSearchScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale0 },
  listHeader: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginTop: 16,
    marginBottom: 12,
  },
  ticketListContainer: { paddingBottom: 30 },
  listItemContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  pageHeader: {
    paddingBottom: 5,
  },
  allSpaceLeft: {
    flex: 1,
  },
});
