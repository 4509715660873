import { Dimensions, PixelRatio, Platform } from "react-native";
import Colors from "app/constants/Colors";
import DeviceInfo from "react-native-device-info";
const windowsDimensions = Dimensions.get("window");
const dimensions = Platform.OS === "web" ? { ...windowsDimensions, width: 800 } : windowsDimensions;
const { width, height } = dimensions;

export function isIphoneX() {
  return Platform.OS === "ios" && (DeviceInfo.hasNotch() || DeviceInfo.hasDynamicIsland());
}

export const HEADER_BAR_HEIGHT = Platform.OS === "ios" ? (isIphoneX() ? 96 : 64) : 64;

export function getBottomSpace() {
  return isIphoneX() ? 34 : 0;
}

export const BOTTOM_BAR_HEIGHT = 64;

export const SPACING_DEFAULT = 15;

export const WIDTH_WINDOW = width;

export const HEIGHT_WINDOW = height;

export const FONT_SIZE_DEFAULT = 16;

// based on iPhone 8's scale
const wscale = WIDTH_WINDOW / 375;
const hscale = HEIGHT_WINDOW / 667;

export const SPACING_X_LARGE = 25;

export const SPACING_LARGE = 20;

export const SPACING_SMALL = 10;

export const SPACING_X_SMALL = 5;

export function normalize(size, based: "width" | "height" = "width") {
  const newSize = based === "height" ? size * hscale : size * wscale;
  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 1;
  }
}

export default {
  window: {
    width,
    height,
  },
  whiteLayout: {
    backgroundColor: Colors.warningColor,
    flex: 1,
  },
  main: {
    backgroundColor: Colors.mainBackground,
    flex: 1,
    position: "relative",
  },
  container: {
    marginHorizontal: 40,
  },
  formError: {
    color: Colors.errorColor,
    marginTop: 10,
    fontSize: 12,
  },
  isSmallDevice: width < 375,
};
