import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 18, color = "#828282", ...props }) {
  return (
    <Svg width={size - 2} height={size} viewBox="0 0 16 18" fill="none" {...props}>
      <Path d="M2 0a2 2 0 00-2 2v14a2 2 0 002 2h12a2 2 0 002-2V6l-6-6H2zm8 7H9V2l5 5h-4z" fill={color} />
    </Svg>
  );
}

export default SvgComponent;
