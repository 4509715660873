import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h7.194c.24 0 .473.047.695.14.223.094.416.223.58.387l4.196 4.196c.164.164.293.357.387.58.093.222.14.454.14.695v11.194c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.308zM13.5 7.596V4H6.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v15.384c0 .077.032.148.096.212a.294.294 0 00.212.096h11.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V8.5h-3.596a.875.875 0 01-.645-.259.875.875 0 01-.259-.645z"
          fill="#1C1B1F"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
