import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { ActivityIndicator, StyleSheet, View, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetModalCustomMethods,
  Button,
  CurrencyText,
  EmptyData,
  Line,
} from "components";
import { CONSTANTS, EXPENSE_STATUS } from "constants/constants";
import { Colors, Fonts } from "theme";
import ExpenseSelectItem from "./ExpenseSelectItem";
import { BizziBotWarningCircle, EmptyExpense } from "assets/images/svg/icons";
import useExpenseUnClaimed from "hooks/expense/useExpenseUnClaimed";
import useAddRequestToExpense from "hooks/request/useAddRequestToExpense";
import { useAuth } from "contexts/AuthContext";
import { Toast } from "@ant-design/react-native";
import { NetworkStatus } from "@apollo/client";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";
import { MobileExpExpenseRequestDetailQuery } from "types";

interface ChooseExpenseListModalProps {
  expenseList?: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]["expenses"];
  setExpenseList?: (expenseList) => void;
  companyTeamIds?: string[];
  requestId?: string;
  onRefreshDetail?: () => void;
  isAdvanceRequest?: boolean;
}
export interface ChooseExpenseListModalMethods {
  present?: () => void;
}
const PAGE_SIZE = 10;

const ChooseExpenseListModal = forwardRef<ChooseExpenseListModalMethods, ChooseExpenseListModalProps>(
  ({ onRefreshDetail, requestId, companyTeamIds, expenseList, isAdvanceRequest = false }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
        refetch?.();
        const expensesNoDocSequence =
          expenseList?.filter((expense) => {
            return !expense?.expenseReport?.docSequence;
          }) ?? [];
        setExpenseListSelect([...expensesNoDocSequence]);
        setTimeout(() => {
          disableLoadMore.current = false;
        }, 2000);
      },
    }));
    const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
    const { expenseAddExpenseToRequest } = useAddRequestToExpense();
    const navigation = useNavigation<any>();
    const { data, loading, called, fetchMore, refetch, networkStatus } = useExpenseUnClaimed(companyTeamIds);

    const disableLoadMore = useRef<boolean>(true);
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
    const alertNotificationRef = useRef<AlertNotificationHandle>();
    const [expenseListSelect, setExpenseListSelect] = useState([]);
    const { user } = useAuth();
    const handleSelectExpense = (item) => async () => {
      if (isAdvanceRequest && item?.status !== EXPENSE_STATUS.READY) {
        alertNotificationRef.current.info({
          icon: <BizziBotWarningCircle />,
          title: t("cannot_select_expense_title"),
          description: t("cannot_select_expense_description"),
          confirmText: t("understood"),
          onConfirm: () => {
            alertNotificationRef?.current?.close();
          },
        });
        return;
      }
      const isChecked = expenseListSelect.find((i) => i?.expenseId === item?.expenseId);
      let newExpenseListSelect = [...expenseListSelect];
      if (!isChecked) {
        newExpenseListSelect.push(item);
      } else {
        newExpenseListSelect = expenseListSelect.filter((i) => i?.expenseId !== item?.expenseId);
      }
      setExpenseListSelect(newExpenseListSelect);
    };
    const handleChoose = async () => {
      bottomSheetRef?.current?.close();
      try {
        Toast.loading("");
        await expenseAddExpenseToRequest({
          variables: {
            object: {
              employee_id: user.employee_id,
              expense_ids: expenseListSelect.map((item) => item.expenseId),
              request_id: requestId,
            },
          },
        });
        await onRefreshDetail?.();
        Toast.removeAll();
      } catch (e) {
        Toast.removeAll();
      }
    };
    const handleNavigateDetail = (expenseId: string) => () => {
      navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, { expenseId });
    };
    const renderItem = ({ item }) => {
      const isChecked = expenseListSelect.find((i) => i?.expenseId === item?.expenseId);
      return (
        <ExpenseSelectItem
          isAdvanceRequest={isAdvanceRequest}
          isChecked={isChecked}
          handleSelectExpense={handleSelectExpense}
          handleNavigateDetail={handleNavigateDetail}
          item={item}
        />
      );
    };

    const totalAmountWithVat = useMemo(() => {
      return expenseListSelect.reduce((total: number, item) => total + item?.totalAmountWithVat, 0);
    }, [expenseListSelect]);

    const handleLoadMore = () => {
      if (
        disableLoadMore.current ||
        loading ||
        !data?.expenses ||
        !data?.expenses_aggregate?.aggregate?.count ||
        data?.expenses?.length === 0 ||
        data?.expenses?.length >= data?.expenses_aggregate?.aggregate?.count
      ) {
        return;
      }
      disableLoadMore.current = true;
      fetchMore?.({
        variables: { offset: data?.expenses?.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          if (prev?.expenses?.length >= PAGE_SIZE && fetchMoreResult?.expenses?.length > 0) {
            disableLoadMore.current = false;
          }
          return {
            ...prev,
            expenses: [...prev.expenses, ...fetchMoreResult.expenses],
          };
        },
      });
    };

    const renderListFooterComponent = () => {
      if (loading && data?.expenses?.length && data?.expenses?.length > 0) {
        return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
      }
      return null;
    };

    const renderFooter = () => {
      return (
        <View style={styles.footer}>
          <View>
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
              {t("choose_expense_count", { num: expenseListSelect?.length })}
            </AppText>
            <CurrencyText style={Fonts.SentenceSubTitleMedium} color={Colors.primary50}>
              {totalAmountWithVat}
            </CurrencyText>
          </View>
          <Button style={styles.chooseButton} onPress={handleChoose} disabled={expenseListSelect?.length === 0}>
            {t("choose", { num: expenseListSelect?.length ?? 0 })}
          </Button>
        </View>
      );
    };
    const renderListEmptyComponent = () => {
      if (loading && !called) {
        return null;
      }
      return <EmptyData title={t("empty_expense")} icon={<EmptyExpense />} />;
    };

    /*useEffect(() => {
      const expensesNoDocSequence =
        expenseList?.filter((expense) => {
          return !expense?.expenseReport?.docSequence;
        }) ?? [];
      setExpenseListSelect([...expensesNoDocSequence]);
    }, [expenseList]);*/

    const expensesUniqBy = useMemo(() => {
      const expensesNoDocSequence =
        expenseList?.filter((expense) => {
          return !expense?.expenseReport?.docSequence;
        }) ?? [];
      return [...expensesNoDocSequence, ...(data?.expenses ?? [])];
    }, [expenseList, data?.expenses]);

    return (
      <>
        <BottomSheetFlatListModalCustom
          title={t("choose_expense")}
          snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
          ref={bottomSheetRef}
          loading={loading && networkStatus !== NetworkStatus.fetchMore}
          renderFooter={renderFooter}
          listProps={{
            keyboardDismissMode: "on-drag",
            keyboardShouldPersistTaps: "always",
            showsVerticalScrollIndicator: false,
            contentContainerStyle: styles.expenseListContent,
            data: expensesUniqBy,
            keyExtractor: (item, index) => item?.expenseId ?? index,
            renderItem,
            ListEmptyComponent: renderListEmptyComponent,
            onEndReached: handleLoadMore,
            onEndReachedThreshold: 0.15,
            ListFooterComponent: renderListFooterComponent,
            ItemSeparatorComponent: () => <Line hasBackground={true} size="sm" containerStyle={styles.divider} />,
          }}
        />
        <AlertNotification ref={alertNotificationRef} />
      </>
    );
  }
);
export default memo(ChooseExpenseListModal);

const styles = StyleSheet.create({
  expenseListContent: {
    paddingBottom: Platform.OS === "web" ? 150 : 100,
  },
  footer: {
    flexDirection: "row",
    backgroundColor: Colors.white,
    justifyContent: "space-between",
  },
  chooseButton: { minWidth: 121 },
  loadingMore: { marginTop: 10 },
  divider: {
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 45,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
