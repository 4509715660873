import { gql, useLazyQuery } from "@apollo/client";
import { MobileExpBankQrParserQuery, MobileExpBankQrParserQueryVariables } from "types";

const QUERY = gql`
  query MobileExpBankQRParser($content: String!) {
    expBankQRParser(content: $content) {
      isValid
      paymentInfo {
        type
        bankId
        bankName
        bankCode
        bankShortName
        logoUrl
        branchName
        accountNumber
        accountHolderName
        description
      }
    }
  }
`;

export const useExpBankQRParser = (variables?: MobileExpBankQrParserQueryVariables) => {
  return useLazyQuery<MobileExpBankQrParserQuery, MobileExpBankQrParserQueryVariables>(QUERY, {
    variables,
  });
};
