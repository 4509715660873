// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { memo, useCallback, FC, useMemo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import dayjs from "dayjs";
import uniqBy from "lodash/uniqBy";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";

import { AppText, CurrencyText } from "components";
import SCREEN_NAME from "navigation/ScreenName";
import { Flex, WhiteSpace } from "@ant-design/react-native";
import { PlusOutlinedIcon } from "assets/images/svg/icons";
import isEmpty from "lodash/isEmpty";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { COMPANIES } from "constants/Companies";

interface IInvoiceItemInList {
  item: {
    invoice_number: number;
    expense_date: string;
    total_amount_with_vat: number;
    seller_legal_name: string;
    invoice_id: number;
    expense: any;
    invoice_items: [
      {
        item_name: string;
      }
    ];
  };
  index: number;
  showCheckbox?: boolean;
  onChange?: () => void;
  checked?: boolean;
  navigation: any;
  onLongPress?: () => void;
  onPress?: () => void;
}

interface IInvoiceStatusProps {
  t: any;
  styles: any;
  isCreatedExpense: boolean;
}

interface IExpenseButton {
  t: any;
  onPress: () => void;
  isCreatedExpense: boolean;
}

const InvoiceStatus = memo(({ t, styles, isCreatedExpense }: IInvoiceStatusProps) => {
  const title = isCreatedExpense ? t("option_1") : t("option_2");
  return (
    <>
      <Flex style={[styles.invoiceStatus, { backgroundColor: isCreatedExpense ? Colors.success0 : Colors.warning0 }]}>
        <View
          style={[
            styles.invoiceStatusIcon,
            { backgroundColor: isCreatedExpense ? Colors.success50 : Colors.warning50 },
          ]}
        />
        <AppText style={[Fonts.Caption, styles.textTitle]}>{title}</AppText>
      </Flex>
    </>
  );
});

const ExpenseButton = memo(({ t, isCreatedExpense, onPress }: IExpenseButton) => {
  return (
    <>
      {isCreatedExpense ? null : (
        <TouchableOpacity onPress={onPress}>
          <Flex>
            <PlusOutlinedIcon />
            <AppText style={[Fonts.BodySmall, styles.textMarginLeft]} color={Colors.primary50}>
              {t("add_expense")}
            </AppText>
          </Flex>
        </TouchableOpacity>
      )}
    </>
  );
});

const InvoiceItemInList: FC<IInvoiceItemInList> = ({
  item,
  showCheckbox,
  checked,
  onChange,
  navigation,
  onLongPress,
  onPress,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { t } = useTranslation("layouts/InvoiceItemInList");
  const { company } = useAuth();
  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );
  const { total_amount_with_vat, seller_legal_name, invoice_id, invoice_items, expense_date, expense, invoice_number } =
    item;
  const date = dayjs(expense_date).format("DD/MM/YYYY");
  const name = uniqBy(invoice_items, "item_name")
    .slice(0, 2)
    .map(({ item_name }) => item_name)
    .join(", ");

  const isCreatedExpense = !isEmpty(expense?.expense_id);

  const onPressExpenseButton = useCallback(() => {
    if (isCreatedExpense) {
      navigation.navigate(SCREEN_NAME.EditExpenseScreen, {
        expenseId: expense?.expense_id,
        isForceFullInfo: true,
        invoiceId: invoice_id,
        autofill: true,
      });
    } else {
      navigation.navigate(SCREEN_NAME.NewExpenseScreen, {
        invoiceId: invoice_id,
        autofill: true,
      });
    }
  }, [isCreatedExpense, navigation, expense?.expense_id, invoice_id]);

  return (
    <>
      <TouchableOpacity activeOpacity={1} onLongPress={onLongPress && (() => onLongPress())} onPress={onPress}>
        <View style={styles.container}>
          <View style={styles.middleContainer}>
            <Flex justify="between">
              <InvoiceStatus t={t} styles={styles} isCreatedExpense={isCreatedExpense} />
              <CurrencyText style={Fonts.NumericN200}>{total_amount_with_vat}</CurrencyText>
            </Flex>
            <WhiteSpace size="sm" />
            <Flex>
              <Flex>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale100}>
                  {t("invoice_number")}
                </AppText>
                <AppText style={Fonts.BodySmall} color={Colors.success100}>
                  {invoice_number}
                </AppText>
                <AppText>{" - "}</AppText>
              </Flex>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale100}>
                {date}
              </AppText>
            </Flex>
            <View style={{ paddingVertical: 8 }}>
              <AppText style={Fonts.Caption} color={Colors.grayscale100} numberOfLines={1}>
                {seller_legal_name}
              </AppText>
              <AppText style={Fonts.Caption} color={Colors.grayscale80} numberOfLines={1}>
                {name}
              </AppText>
              <WhiteSpace size="sm" />
            </View>
            {!isTLNExpenseCompany && (
              <Flex>
                <ExpenseButton t={t} isCreatedExpense={isCreatedExpense} onPress={onPressExpenseButton} />
              </Flex>
            )}
          </View>
        </View>
      </TouchableOpacity>
      <View style={styles.divider} />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: Colors.white,
    paddingVertical: 16,
    borderRadius: 8,
    marginHorizontal: 10,
  },
  middleContainer: {
    paddingLeft: 10,
    paddingRight: 15,
    flex: 1,
  },
  invoiceStatus: {
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 20,
  },
  invoiceStatusIcon: {
    width: 12,
    height: 12,
    borderRadius: 12,
  },
  textMarginLeft: {
    marginLeft: 3,
  },
  textTitle: { marginLeft: 5, fontSize: 12 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});

const areEqual = (prevProps: IInvoiceItemInList, nextProps: IInvoiceItemInList): boolean => {
  return (
    isEqual(prevProps?.item, nextProps?.item) &&
    prevProps.autofillOnNewExpense === nextProps.autofillOnNewExpense &&
    prevProps.index === nextProps.index &&
    prevProps.showCheckbox === nextProps.showCheckbox &&
    prevProps.checked === nextProps.checked
  );
};

export default memo(InvoiceItemInList, areEqual);
