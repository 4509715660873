// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import useExpenseRequestApprovalTemplateQuery from "hooks/request/useExpenseRequestApprovalTemplateQuery";
import { createContext, ReactElement, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthContext";

const defaultFeatures = {
  FEATURE_SHOW_REPORT_BOTTOM_TAB: true,
  FEATURE_SHOW_REQUEST_BOTTOM_TAB: false,
  FEATURE_SHOW_EXPENSE_TLN_TAB: false,
  FEATURE_SHOW_SELECT_REQUEST_IN_CREATE_EXPENSE: true,
  FEATURE_SHOW_BUTTON_CLAIM_EXPENSE: false,
  FEATURE_LIMIT_ONLY_1_UPLOAD_PAPER_INVOICE: false,
  FEATURE_SHOW_TLN_UPDATE_EXPENSE_SCREEN: false,
  FEATURE_SHOW_EXPENSE_ITEM_TLN: false,
  FEATURE_SHOW_REJECT_REPORT_IN_EXPENSE_EDIT_VIEW: false,
  FEATURE_SHOW_CATEGORY_FREE_USER: true,
  FEATURE_COMBINE_REPORT_REQUEST: false,
  FEATURE_TURN_OFF_CREATE_EXPENSE_BUTTON_WHEN_INVOICE_ERROR: false,
  FEATURE_SHOW_CUSTOM_FIELD_IN_DETAIL_EXPENSE: false,
  FEATURE_SHOW_FULL_VERIFIED_INVOICE: false,
  FEATURE_TRANSFER_EXPENSE_INVOICE: false,
};

export const FeatureFlagContext = createContext<typeof defaultFeatures>(defaultFeatures);

const { Provider, Consumer } = FeatureFlagContext;

const TLN_EXPENSE_COMPANY = "32360905-2f83-4afd-a51c-985a95ed3219";
const LSP_EXPENSE_COMPANY = "3086adda-b6b8-4a57-bd01-ed2c2f714752";
const TLN_EXPENSE_COMPANY_PRODUCTION = "ba260e95-7cd1-4387-bf8f-8f2bba4e5e2b";

const BIZZI_INTERNAL = "e287453a-8ad6-4a12-831b-87fd7619fc45";

const BIZZI_DEMO_1 = "6b208045-1b79-4631-a9bf-542eb8f4c9af";

const BIZZI_DEMO_2 = "dc8c14df-d967-431d-99d5-8e43fc7a4034";

const BIZZI_DEMO_3 = "56d553ed-150f-43b4-91e0-1c8ff7d88f51";

const BIZZI_TEST_1 = "3be670f7-437c-4abd-a144-0c9202f9ba22";

const FeatureFlagProvider = ({ children }: { children: ReactElement }) => {
  const [features, setFeatures] = useState(defaultFeatures);
  const { company } = useAuth();
  const { groupSetting } = useExpenseRequestApprovalTemplateQuery();
  const isShowApprovalBottomTab = useMemo(() => {
    return (
      !!groupSetting.group_setting_id &&
      [BIZZI_INTERNAL, BIZZI_DEMO_1, BIZZI_DEMO_2, BIZZI_DEMO_3, BIZZI_TEST_1].includes(company?.company_id)
    );
  }, [groupSetting, company]);

  useEffect(() => {
    if (TLN_EXPENSE_COMPANY === company?.company_id || TLN_EXPENSE_COMPANY_PRODUCTION === company?.company_id) {
      setFeatures({
        ...defaultFeatures,
        // FEATURE_SHOW_REPORT_BOTTOM_TAB: false,
        // FEATURE_SHOW_EXPENSE_TLN_TAB: true,
        // FEATURE_SHOW_SELECT_REQUEST_IN_CREATE_EXPENSE: false,
        FEATURE_SHOW_BUTTON_CLAIM_EXPENSE: true,
        FEATURE_LIMIT_ONLY_1_UPLOAD_PAPER_INVOICE: true,
        // FEATURE_SHOW_TLN_UPDATE_EXPENSE_SCREEN: true,
        // FEATURE_SHOW_EXPENSE_ITEM_TLN: true,
        FEATURE_SHOW_REJECT_REPORT_IN_EXPENSE_EDIT_VIEW: true,
        FEATURE_TURN_OFF_CREATE_EXPENSE_BUTTON_WHEN_INVOICE_ERROR: true,
        // FEATURE_SHOW_CUSTOM_FIELD_IN_DETAIL_EXPENSE: true,
        FEATURE_SHOW_FULL_VERIFIED_INVOICE: true,
      });
      return;
    }

    if (LSP_EXPENSE_COMPANY === company?.company_id) {
      setFeatures({
        ...defaultFeatures,
        FEATURE_SHOW_REPORT_BOTTOM_TAB: false,
        FEATURE_COMBINE_REPORT_REQUEST: true,
      });

      return;
    }

    setFeatures({
      ...defaultFeatures,
      FEATURE_SHOW_REQUEST_BOTTOM_TAB: isShowApprovalBottomTab,
      FEATURE_SHOW_CATEGORY_FREE_USER: !isShowApprovalBottomTab,
    });
  }, [company, isShowApprovalBottomTab]);

  return <Provider value={features}>{children}</Provider>;
};

FeatureFlagProvider.Consumer = Consumer;

export default FeatureFlagProvider;
