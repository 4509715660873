import * as React from "react";
import Svg, { Circle, Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1F71F4", width = 12, height = 12 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" fill="none">
      <Circle cx={6} cy={6} r={6} fill="#fff" />
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={12} height={12}>
        <Path fill="#D9D9D9" d="M0 0H12V12H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6.375 5.848V3.875a.363.363 0 00-.108-.267A.363.363 0 006 3.5a.363.363 0 00-.267.108.363.363 0 00-.108.267v2.088a.462.462 0 00.136.324L7.473 8c.07.07.156.105.261.106A.353.353 0 008 8a.359.359 0 00.109-.263A.359.359 0 008 7.473L6.375 5.848zm-.374 4.902a4.628 4.628 0 01-1.853-.374A4.8 4.8 0 012.64 9.361a4.796 4.796 0 01-1.016-1.508 4.623 4.623 0 01-.374-1.852c0-.657.125-1.275.374-1.853.25-.578.588-1.08 1.015-1.508.428-.428.93-.766 1.508-1.016a4.623 4.623 0 011.852-.374c.657 0 1.275.125 1.853.374.578.25 1.08.588 1.508 1.015.428.428.766.93 1.016 1.508s.374 1.195.374 1.852-.125 1.275-.374 1.853A4.8 4.8 0 019.361 9.36c-.428.428-.93.766-1.508 1.016a4.622 4.622 0 01-1.852.374z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
