import BizziIcon from "assets/images/svg/icons/BizziIcon";
import Layout from "constants/Layout";
import Constants from "expo-constants";
import { KeyboardAvoidingView } from "layouts";
import { ScrollView, useWindowDimensions, View } from "react-native";
import { stylesCommon } from "./commonStyle";
import RegisterStatus from "./RegisterSatus";

const RegisterContainer = ({ children, activeStep = 1 }) => {
  const { width } = useWindowDimensions();
  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <ScrollView>
        <View style={stylesCommon.container}>
          <View
            style={[
              stylesCommon.content,
              {
                width: width > 1024 ? (width > 1280 ? "75%" : "70%") : width < 768 ? "100%" : "70%",
                paddingHorizontal: width > 1024 ? (width > 1280 ? 80 : 40) : width < 768 ? 0 : 15,
              },
            ]}
          >
            <View style={stylesCommon.ph20}>
              <BizziIcon />
            </View>
            <ScrollView keyboardDismissMode="on-drag" keyboardShouldPersistTaps="always">
              {children}
            </ScrollView>
          </View>
          {width >= 768 && (
            <View style={[stylesCommon.status, { width: width > 1024 ? (width > 1280 ? "25%" : "30%") : "30%" }]}>
              <RegisterStatus activeStep={activeStep} />
            </View>
          )}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default RegisterContainer;
