import React, { FC, useMemo, useRef } from "react";
import { ScrollView, StyleSheet, View, ViewStyle } from "react-native";
import { useTranslation } from "react-i18next";
import { Button } from "react-native-paper";
import { Control, Controller, UseFormWatch } from "react-hook-form";

import { Colors, Fonts } from "theme";
import { InvoiceType } from "constants/ExpenseApproval";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import UploadFiles from "components/InputCustom/UploadFiles/UploadFiles";
import { COMPANIES } from "constants/Companies";
import { useAuth } from "contexts/AuthContext";
import NoInvoiceTab from "./NoInvoiceTab";
import ElectronicInvoiceTab from "./ElectronicInvoiceTab";
import { ExpenseFormValues } from "screens/Expense/components/ExpenseForm/types";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { AppText, SwitchButton } from "components";
import { startLayoutAnimation } from "utils";
import isEmpty from "lodash/isEmpty";

interface InvoiceSectionProps {
  isExpenseFromCardTransaction?: boolean;
  style?: ViewStyle;
  name?: string;
  control?: Control<ExpenseFormValues>;
  rules?: Record<string, unknown>;
  errors?: FieldErrors<ExpenseFormValues>;
  setValue?: (key?: string, value?: string | Record<string, unknown>) => void;
  watch?: UseFormWatch<ExpenseFormValues>;
  expenseReportId?: string;
}
const InvoiceSection: FC<InvoiceSectionProps> = ({
  isExpenseFromCardTransaction,
  setValue,
  watch,
  style,
  name,
  control,
  errors,
  expenseReportId,
}) => {
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const [FEATURE_PAPER_INVOICE] = useIsFeaturesEnabled([FEATURE_FLAGS.FEATURE_PAPER_INVOICE]);
  const { company } = useAuth();
  const amount = watch("amount");
  const expenseDate = watch("expenseDate");
  const invoiceType = watch("invoice.invoiceType");
  const previousType = useRef<InvoiceType>(null);

  const isTLNExpenseCompany = [
    COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
    COMPANIES.TLN_EXPENSE_COMPANY_DEV,
    COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
  ].includes(company?.company_id);

  const invoiceTabs = useMemo(() => {
    const invoiceTabs = [];
    invoiceTabs.push({
      name: t("e_invoice"),
      type: InvoiceType.ELECTRONIC,
    });
    if (FEATURE_PAPER_INVOICE) {
      invoiceTabs.push({
        name: t("paper_invoice"),
        type: InvoiceType.PAPER,
      });
    }
    return invoiceTabs;
  }, [FEATURE_PAPER_INVOICE, t]);
  const renderTLNInvoice = (invoice) => {
    if (invoice?.invoiceType === InvoiceType.NO_INVOICE) {
      return (
        <View>
          <NoInvoiceTab control={control} errors={errors} />
        </View>
      );
    }
    return null;
  };
  const renderContent = (invoice, onChange) => {
    if (invoice?.invoiceType === InvoiceType.ELECTRONIC) {
      return (
        <View style={{ paddingTop: 20 }}>
          <ElectronicInvoiceTab
            invoice={invoice}
            prefer={{ amount, expenseDate }}
            error={errors?.invoice}
            onSelect={(value) => {
              onChange({ ...invoice, invoiceElectronic: value });
              if (!isExpenseFromCardTransaction) {
                setValue("amount", value?.totalAmountWithVat ? value.totalAmountWithVat.toString() : "");
                setValue("expenseDate", value?.issuedDate);
              }
              if (isTLNExpenseCompany) {
                setValue("note", value?.sellerLegalName);
              }
            }}
          />
        </View>
      );
    } else if (invoice?.invoiceType === InvoiceType.PAPER) {
      return (
        <View style={styles.invoiceContent}>
          <UploadFiles<ExpenseFormValues, "invoiceFiles">
            name="invoiceFiles"
            title={t("take_a_photo")}
            control={control}
            error={errors?.invoiceFiles}
            rules={needValidatedRequiredInvoice ? { required: t("required") } : undefined}
          />
        </View>
      );
    }
    return null;
  };

  const handleToggleSection = (isOn: boolean) => {
    startLayoutAnimation();
    if (isOn) {
      setValue?.("invoice.invoiceType", (previousType?.current ?? InvoiceType.ELECTRONIC) as unknown as string);
    } else {
      previousType.current = invoiceType;
      setValue?.("invoice.invoiceType", InvoiceType.NO_INVOICE as unknown as string);
    }
  };
  const isExpand = [InvoiceType.ELECTRONIC, InvoiceType.PAPER].includes(invoiceType);
  const needValidatedRequiredInvoice = expenseReportId && isExpand;

  const validateInvoiceElectronic = (value: ExpenseFormValues["invoice"]) => {
    if (
      needValidatedRequiredInvoice &&
      value?.invoiceType === InvoiceType.ELECTRONIC &&
      isEmpty(value?.invoiceElectronic)
    ) {
      return t("required");
    }
    return true;
  };

  return (
    <Controller
      name={name as never}
      control={control}
      rules={{
        validate: validateInvoiceElectronic,
      }}
      render={({ field: { onChange, value } }) => {
        const invoiceInfo = value as any;
        return (
          <View style={styles.container}>
            <View style={styles.header}>
              <View>
                <AppText style={Fonts.SentenceSubtileXLarge}>{t("invoice_info")}</AppText>
              </View>
              <SwitchButton value={isExpand} onValueChange={handleToggleSection} />
            </View>
            {isExpand && (
              <View>
                <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                  {invoiceTabs.map(({ name, type }) => {
                    const isSelected = invoiceInfo?.invoiceType === type;
                    return (
                      <Button
                        key={type}
                        uppercase={false}
                        color={Colors.grayscale100}
                        style={StyleSheet.flatten([
                          styles.buttonFilterStyle,
                          isSelected && styles.buttonFilterActiveStyle,
                          style,
                        ])}
                        labelStyle={StyleSheet.flatten([Fonts.BodyMedium, isSelected && { color: Colors.black }])}
                        onPress={() => {
                          previousType.current = invoiceInfo?.invoiceType;
                          onChange?.({ ...invoiceInfo, invoiceType: type });
                        }}
                      >
                        {name}
                      </Button>
                    );
                  })}
                </ScrollView>
                {renderContent(value, onChange)}
              </View>
            )}
            {isTLNExpenseCompany && renderTLNInvoice(value)}
          </View>
        );
      }}
    />
  );
};
export default InvoiceSection;

const styles = StyleSheet.create({
  container: {
    gap: 20,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonFilterStyle: {
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: Colors.while,
    borderColor: Colors.grayscale10,
    marginRight: 8,
  },
  buttonFilterActiveStyle: {
    borderColor: Colors.primary50,
    shadowColor: Colors.primary50,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  invoiceContent: { paddingTop: 20 },
});
