/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import * as Yup from "yup";
// import { LocalizationContext } from "contexts/LocalizationContext";
import i18n from "lang";

const { t } = i18n;

Yup.addMethod(Yup.string, "integer", function () {
  return this.matches(/^\d+$/, t("validations.number_only"));
});

const phone = Yup.string().integer().min(10, t("validations.number_length")).required(t("validations.required"));
const email = Yup.string().email(t("please_input_correct_email")).required(t("validations.required"));
const password = Yup.string().min(8, t("validations.password_length")).required(t("validations.password_required"));

const passwordComplex = Yup.string()
  .min(8, t("validations.password_length"))
  .matches("[A-Z]+", t("validations.password_validation"))
  .matches("[A-z]+", t("validations.password_validation"))
  .required(t("validations.required"));

const fullNameSchema = Yup.string().min(3, t("validations.full_name_length")).required(t("validations.required"));

const taxCode = Yup.string()
  .trim()
  .matches(/^\d{10}(-\d{3})?$/, t("validations.invalid_tax_code"))
  .required(t("validations.required"));

const companyForm = Yup.object().shape({
  tax_code: taxCode,
  name: Yup.string().min(5, t("validations.string_min")).required(t("validations.required")),
  address: Yup.string().min(10, t("validations.string_min")).required(t("validations.required")),
  staff_name: Yup.string().min(5, t("validations.string_min")).required(t("validations.required")),
  phone,
  department: Yup.string().min(5, t("validations.string_min")).required(t("validations.required")),
  accountant_email: email,
  invoice_email: email,
});

const searchInvoicesForm = Yup.object().shape({
  tax_code: taxCode,
  // fromDate: Yup.date()
  //   .min(5, t("validations.number_length"))
  //   .typeError(t("validations.required"))
  //   .required(t("validations.required")),
  // toDate: Yup.date()
  //   .min(5, t("validations.number_length"))
  //   .typeError(t("validations.required"))
  //   .required(t("validations.required")),
  category: Yup.array(Yup.number().min(1).required(t("validations.required"))),
  status: Yup.array(Yup.number()).min(1).required(t("validations.required")),
});

const newExpenseForm = Yup.object().shape({
  expense: Yup.string()
    .trim()
    .test({
      name: "currency",
      message: t("validations.number_min"),
      test: (value) => {
        const n = +value?.trim().replace(/\./g, "");
        return n > 1000;
      },
      params: { min: 1000 },
    }),
  provider: Yup.string().typeError(t("validations.required")).required(t("validations.required")),
  address: Yup.string().min(5, t("validations.string_min")).typeError(t("validations.required")),
  category: Yup.string().min(1, t("validations.required")).required(t("validations.required")),
  // date: Yup.date().min(5, t("validations.number_length")).required(t("validations.required")),
  explanation: Yup.string().min(5, t("validations.string_min")).required(t("validations.required")),
});

const languageForm = Yup.object().shape({});

export {
  taxCode,
  email,
  phone,
  password,
  passwordComplex,
  fullNameSchema,
  companyForm,
  searchInvoicesForm,
  newExpenseForm,
  languageForm,
};
