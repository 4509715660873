import Svg, { Circle } from "react-native-svg";

function SvgComponent({ width = 197, height = 180, color = "#DCEBFE" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 197 180" fill="none">
      <Circle cx={200} cy={-20} r={189.5} stroke={color} />
      <Circle cx={200} cy={-20} r={179.5} stroke={color} />
      <Circle cx={200} cy={-20} r={169.5} stroke={color} />
      <Circle cx={200} cy={-20} r={159.5} stroke={color} />
      <Circle cx={200} cy={-20} r={149.5} stroke={color} />
      <Circle cx={200} cy={-20} r={139.5} stroke={color} />
      <Circle cx={200} cy={-20} r={129.5} stroke={color} />
      <Circle cx={200} cy={-20} r={119.5} stroke={color} />
      <Circle cx={200} cy={-20} r={109.5} stroke={color} />
      <Circle cx={200} cy={-20} r={99.5} stroke={color} />
      <Circle cx={200} cy={-20} r={89.5} stroke={color} />
      <Circle cx={200} cy={-20} r={79.5} stroke={color} />
      <Circle cx={200} cy={-20} r={69.5} stroke={color} />
      <Circle cx={200} cy={-20} r={59.5} stroke={color} />
      <Circle cx={200} cy={-20} r={49.5} stroke={color} />
      <Circle cx={200} cy={-20} r={39.5} stroke={color} />
      <Circle cx={200} cy={-20} r={29.5} stroke={color} />
      <Circle cx={200} cy={-20} r={199.5} stroke={color} />
    </Svg>
  );
}

export default SvgComponent;
