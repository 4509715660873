import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 25, height = 24, color = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 25 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.53 1.3c.19.08.33.23.41.41.04.1.06.19.06.29v4c0 .41-.34.75-.75.75s-.75-.34-.75-.75V3.81l-1.67 1.67c-.33-.38-.68-.73-1.06-1.06l1.67-1.67h-2.19c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.1 0 .19.02.28.05zm-3.76 3.12l-2.05 2.05c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l2.05-2.05c1.5 1.76 2.42 4.03 2.42 6.52 0 5.52-4.48 10-10 10s-10-4.48-10-10 4.48-10 10-10c2.49 0 4.76.92 6.52 2.42zM14 11.82c.64.23 1.5.69 1.5 2.16 0 1.25-.99 2.28-2.2 2.28H13v.25c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.25h-.08c-1.33 0-2.42-1.12-2.42-2.5 0-.42.34-.76.75-.76s.75.34.75.75c0 .55.41 1 .92 1h.08v-2.22l-1-.35c-.64-.23-1.5-.69-1.5-2.16 0-1.25.99-2.28 2.2-2.28h.3V7.5c0-.41.34-.75.75-.75s.75.34.75.75v.25h.08c1.33 0 2.42 1.12 2.42 2.5 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.55-.41-1-.92-1H13v2.22l1 .35zm-3.01-1.05c-.34-.12-.49-.2-.49-.74 0-.43.32-.78.7-.78h.3v1.7l-.51-.18zm2.31 3.98H13v-1.7l.51.18c.34.12.49.2.49.74 0 .43-.31.78-.7.78z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
