import React, { useEffect, useRef, useState } from "react";
import { RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";

import { AlertNotification, AlertNotificationHandle, AppText, HeaderPageBottomTab, IconCustom } from "components";
import { Colors, Fonts } from "theme";
import SCREEN_NAME from "navigation/ScreenName";
import { useAuth } from "contexts/AuthContext";
import { ArrowRightIcon, BizziBootInfoSuccess } from "assets/images/svg/icons";
import { CARD_REGISTRATION_STATUS, CARD_STATUS, CONSTANTS } from "constants/constants";
import { TransactionHistoryListScreenNavigationProps } from "navigation/type";
import EmptyState from "./components/EmptyState";
import CardCarouselList from "./components/CardCarouselList";
import useEmployeeCardDetailQuery from "./hooks/useEmployeeCardDetailQuery";
import useEmployeeCardsQuery from "./hooks/useEmployeeCardsQuery";
import CardInfoMod from "./components/CardInfoMod";
import CardInfoAssignee from "./components/CardInfoAssignee";
import CardOpeningProcess from "./components/CardOpeningProcess/CardOpeningProcess";
import useCardStatusQuery from "screens/Card/CardListScreen/hooks/useCardStatusQuery";
import { isUserMod } from "utils";
import Asyncstorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import ConfettiAnimation, {
  ConfettiAnimationMethods,
} from "screens/Card/CardListScreen/components/CardOpeningProcess/ConfettiAnimation";
import * as WebBrowser from "expo-web-browser";

const CardListScreen = () => {
  const { top } = useSafeAreaInsets();
  const alertNotificationRef = useRef<AlertNotificationHandle>();
  const alertProcessNotificationRef = useRef<AlertNotificationHandle>();
  const confettiAnimation = useRef<ConfettiAnimationMethods>();
  const confettiProcessAnimation = useRef<ConfettiAnimationMethods>();
  const navigation = useNavigation<TransactionHistoryListScreenNavigationProps>();
  const [cardId, setCardId] = useState<string>(null);
  const { t } = useTranslation("app/screens/Card/CardListScreen");
  const {
    user,
    company: { company_id: companyId },
  } = useAuth();
  const { data, loading, refetch } = useEmployeeCardsQuery({ companyId }, (data) => {
    if (data?.crdEmployeeCards?.data?.[0]) {
      setCardId(data?.crdEmployeeCards?.data[0]?.cardId);
    }
  });
  const {
    data: processData,
    loading: loadingProcessData,
    refetch: refetchCardStatus,
  } = useCardStatusQuery({ companyId }, user.group.role !== "mod");
  const { data: cardDetail, loading: loadingDetail } = useEmployeeCardDetailQuery(
    { companyId, cardId },
    { skip: !cardId }
  );
  const currentCard = cardDetail?.crdCard?.card;

  const handleChangeCard = (cardId: string) => {
    setCardId(cardId);
  };
  const handleViewInfoLastUpdate = () => {
    alertNotificationRef?.current?.info({
      title: t("last_update_title"),
      description: t("last_update_description"),
      confirmText: t("understand"),
      onConfirm: () => alertNotificationRef?.current?.close(),
    });
  };
  const handleViewRewardPoints = () => {
    alertNotificationRef?.current?.confirm({
      icon: <BizziBootInfoSuccess />,
      title: t("reward_points"),
      description: (
        <AppText style={[Fonts.SentenceBodyMedium, styles.rewardPointsDescription]}>
          {t("reward_points_description")}
        </AppText>
      ),
      confirmText: t("understand"),
      cancelText: t("learn_more"),
      onConfirm: () => alertNotificationRef?.current?.close(),
      onCancel: () => {
        WebBrowser.openBrowserAsync("https://bizzi.app.link/e/shinhan-point");
      },
    });
  };

  const isShowCardInfo =
    currentCard?.status === CARD_STATUS.ACTIVATED || currentCard?.status === CARD_STATUS.BLOCKED || !currentCard;
  const isModOrHolderCard = user?.group?.role === "mod" || currentCard?.cardHolderEmployeeId === user?.employee_id;
  const onRefresh = async () => {
    setCardId(null);
    await refetch();
  };
  /*-- flow: show modal register card success and card is ready to use ---*/
  const showModalRegisterCardSuccess = async () => {
    const isViewAlertRegisterCardSuccess = await Asyncstorage.getItem(Keys.IS_VIEW_ALERT_REGISTER_CARD_SUCCESS);
    if (!isViewAlertRegisterCardSuccess) {
      Asyncstorage.setItem(Keys.IS_VIEW_ALERT_REGISTER_CARD_SUCCESS, "true");
      alertProcessNotificationRef?.current?.info({
        icon: <BizziBootInfoSuccess />,
        title: t("register_card_success_title"),
        description: t("register_card_success_description"),
        confirmText: t("got_it"),
        onConfirm: () => alertProcessNotificationRef?.current?.close(),
      });
      confettiProcessAnimation?.current?.show();
    }
  };
  const showModalAlertCardIsReady = async () => {
    const isViewAlertCardReady = await Asyncstorage.getItem(Keys.IS_VIEW_ALERT_CARD_READY);
    if (!isViewAlertCardReady) {
      Asyncstorage.setItem(Keys.IS_VIEW_ALERT_CARD_READY, "true");
      alertNotificationRef?.current?.info({
        icon: <BizziBootInfoSuccess />,
        title: t("card_is_ready_title"),
        description: t("card_is_ready_description"),
        confirmText: t("got_it"),
        onConfirm: () => alertNotificationRef?.current?.close(),
      });
      confettiAnimation?.current?.show();
    }
  };
  useEffect(() => {
    if (!data?.crdEmployeeCards || !processData?.orgCompanyCardStatus) {
      return;
    }
    if (
      isUserMod(user) &&
      [
        CARD_REGISTRATION_STATUS.REGISTERED,
        CARD_REGISTRATION_STATUS.BIZZI_VERIFIED,
        CARD_REGISTRATION_STATUS.BANK_VERIFIED,
      ].includes(processData?.orgCompanyCardStatus?.status as CARD_REGISTRATION_STATUS)
    ) {
      showModalRegisterCardSuccess();
      return;
    }
    if (data?.crdEmployeeCards?.data?.length > 0) {
      showModalAlertCardIsReady();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processData?.orgCompanyCardStatus?.status, data]);
  /*--- end ----*/

  if (
    (!data?.crdEmployeeCards && !loading) ||
    (!isUserMod(user) && !loading && data?.crdEmployeeCards?.data?.length === 0) ||
    (isUserMod(user) &&
      !loadingProcessData &&
      processData?.orgCompanyCardStatus?.status === CARD_REGISTRATION_STATUS.UNREGISTERED &&
      data?.crdEmployeeCards?.data?.length === 0)
  ) {
    return <EmptyState onRefreshData={onRefresh} />;
  } else if (
    isUserMod(user) &&
    !loading &&
    !loadingProcessData &&
    (!data?.crdEmployeeCards?.data || data?.crdEmployeeCards?.data?.length === 0)
  ) {
    return (
      <>
        <CardOpeningProcess
          status={processData?.orgCompanyCardStatus?.status as CARD_REGISTRATION_STATUS}
          onRefreshData={() => {
            onRefresh();
            refetchCardStatus();
          }}
        />
        <AlertNotification ref={alertProcessNotificationRef} />
        <ConfettiAnimation ref={confettiProcessAnimation} />
      </>
    );
  }

  const isLoading = loading || loadingDetail || loadingProcessData;
  return (
    <View style={[styles.container, { paddingTop: top + 10 }]}>
      <HeaderPageBottomTab title={t("card_list")} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
        refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefresh} />}
      >
        <CardCarouselList
          loading={loading || loadingProcessData}
          list={data?.crdEmployeeCards?.data ?? []}
          handleChangeCard={handleChangeCard}
        />
        <View style={styles.body}>
          {isShowCardInfo && isModOrHolderCard && (
            <CardInfoMod
              loading={isLoading}
              currentCard={currentCard}
              handleViewInfoLastUpdate={handleViewInfoLastUpdate}
              handleViewRewardPoints={handleViewRewardPoints}
            />
          )}
          {isShowCardInfo && !isModOrHolderCard && <CardInfoAssignee loading={isLoading} currentCard={currentCard} />}

          {!isLoading && currentCard?.status !== CARD_STATUS.INACTIVE && (
            <TouchableOpacity
              style={styles.cardItem}
              onPress={() =>
                navigation.navigate(SCREEN_NAME.TransactionHistoryListScreen, { cardId: currentCard?.cardId })
              }
            >
              <View style={styles.rowItem}>
                <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                  {t("transaction_history")}
                </AppText>
                <IconCustom name="arrow-forward-ios" fill={Colors.primary50} />
              </View>
            </TouchableOpacity>
          )}
        </View>
      </ScrollView>
      <AlertNotification ref={alertNotificationRef} />
      <ConfettiAnimation ref={confettiAnimation} />
    </View>
  );
};
export default CardListScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainerStyle: { paddingBottom: 50 },
  body: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  cardItem: {
    padding: 20,
    marginTop: 20,
    borderRadius: 16,
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 4,
  },
  rewardPointsDescription: {
    marginTop: 8,
    marginBottom: 20,
  },
  rowItem: { flexDirection: "row", justifyContent: "space-between" },
});
