import * as React from "react";
import Svg, { Ellipse, Path, Rect } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg width={273} height={123} viewBox="0 0 273 123" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0991 12.8721C90.0991 5.76305 95.8621 0 102.971 0H260.127C267.236 0 273 5.76305 273 12.8721C273 19.9812 267.236 25.7443 260.127 25.7443H201.96C204.876 28.1041 206.741 31.7123 206.741 35.7559C206.741 42.8082 201.069 48.5358 194.039 48.627H230.982C238.091 48.627 243.854 54.39 243.854 61.4991C243.854 68.6082 238.091 74.3712 230.982 74.3712H77.2449C84.354 74.3712 90.1171 68.6082 90.1171 61.4991C90.1171 54.39 84.354 48.627 77.2449 48.627H36.542C29.5114 48.5358 23.8401 42.8082 23.8401 35.7559C23.8401 28.6468 29.6032 22.8838 36.7122 22.8838H94.8799C91.9636 20.524 90.0991 16.9158 90.0991 12.8721ZM0 87.2451C0 80.136 5.76305 74.3729 12.8721 74.3729H196.536C197.169 74.3729 197.791 74.4186 198.399 74.5068H193.338C187.028 74.5068 181.913 79.6217 181.913 85.9312C181.913 92.2407 187.028 97.3556 193.338 97.3556H214.043C220.392 98.1479 225.305 103.564 225.305 110.128C225.305 117.237 219.542 123 212.433 123H73.8316C66.7225 123 60.9594 117.237 60.9594 110.128C60.9594 106.085 62.8235 102.477 65.7392 100.117H12.8721C5.76306 100.117 0 94.3541 0 87.2451Z"
      fill="#F0F6FF"
    />
    <Rect x={192.984} y={101.52} width={2.12729} height={7.81221} rx={1.06364} fill="#75A4FE" />
    <Rect x={192.984} y={111.936} width={2.12729} height={7.81221} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={195.61}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05866}
      transform="rotate(-90 195.61 111.936)"
      fill="#75A4FE"
    />
    <Rect
      x={183.836}
      y={111.936}
      width={2.11731}
      height={7.84902}
      rx={1.05866}
      transform="rotate(-90 183.836 111.936)"
      fill="#75A4FE"
    />
    <Path
      d="M188.674 74.3723C188.674 76.9709 186.556 79.0855 183.933 79.0855C181.311 79.0855 179.193 76.9709 179.193 74.3723C179.193 71.7738 181.311 69.6592 183.933 69.6592C186.556 69.6592 188.674 71.7738 188.674 74.3723Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.01559}
    />
    <Ellipse cx={82.7849} cy={37.053} rx={4.25457} ry={4.23462} fill="#75A4FE" />
    <Rect x={96.6133} y={5.29297} width={2.12729} height={7.41059} rx={1.06364} fill="#75A4FE" />
    <Rect x={96.7451} y={15.2559} width={2.12729} height={7.41059} rx={1.06364} fill="#75A4FE" />
    <Rect
      x={98.7393}
      y={14.8213}
      width={2.11731}
      height={7.44551}
      rx={1.05866}
      transform="rotate(-90 98.7393 14.8213)"
      fill="#75A4FE"
    />
    <Path
      d="M90.2256 15.2559C89.641 15.2559 89.167 14.7819 89.167 14.1972V14.1972C89.167 13.6125 89.641 13.1385 90.2256 13.1385L95.5538 13.1385C96.1385 13.1385 96.6125 13.6125 96.6125 14.1972V14.1972C96.6125 14.7819 96.1385 15.2559 95.5538 15.2559L90.2256 15.2559Z"
      fill="#75A4FE"
    />
    <Path
      d="M117.346 91.099C120.131 88.1242 124.377 88.3608 126.822 91.606L130.252 96.1696C133.003 99.7867 137.453 99.7867 140.204 96.1696L143.634 91.606C146.079 88.3608 150.325 88.1242 153.11 91.099C159.155 97.5218 164.08 95.3921 164.08 86.4002V48.3029C164.114 34.6798 160.921 31.2656 148.083 31.2656H122.407C109.569 31.2656 106.376 34.6798 106.376 48.3029V86.3664C106.376 95.3921 111.335 97.488 117.346 91.099Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.63442}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M122.135 82.8564C124.212 80.6157 127.378 80.7939 129.201 83.2383L131.759 86.6758C133.811 89.4004 137.128 89.4004 139.18 86.6758L141.738 83.2383C143.561 80.7939 146.727 80.6157 148.803 82.8564C153.311 87.6943 156.984 86.0902 156.984 79.3171V50.6204C157.009 40.3589 154.628 37.7871 145.055 37.7871H125.909C116.336 37.7871 113.955 40.3589 113.955 50.6204V79.2916C113.955 86.0902 117.653 87.6689 122.135 82.8564Z"
      fill="#F0F6FF"
    />
    <Path
      d="M121.659 48.168H148.83"
      stroke="#1F71F4"
      strokeWidth={2.63442}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M125.055 61.6895H145.433"
      stroke="#1F71F4"
      strokeWidth={2.63442}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SVGComponent;
