import { FC, memo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import ReportStatus from "constants/ReportStatus";

import { AppText } from "components";

const STATUS = {
  [ReportStatus.DRAFT]: {
    color: Colors.grayscale05,
    text: "report_draft_status",
  },
  [ReportStatus.SUBMITTED]: {
    color: Colors.primary05,
    text: "report_submitted_status",
  },
  [ReportStatus.APPROVED]: {
    color: Colors.success0,
    text: "report_success_status",
  },
  [ReportStatus.REJECTED]: {
    color: Colors.alert0,
    text: "report_rejected_status",
  },
  [ReportStatus.CANCEL]: {
    color: Colors.grayscale05,
    text: "report_cancel_status",
  },
};

interface StatusExpenseReportProps {
  value: number;
  style?: ViewStyle;
  textColor?: string;
}

const StatusExpenseReport: FC<StatusExpenseReportProps> = ({ value, style, textColor = Colors.grayscale100 }) => {
  const { t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");
  if (!STATUS[value]?.color) {
    return null;
  }
  return (
    <View style={[styles.container, style, { backgroundColor: STATUS[value]?.color ?? Colors.info50 }]}>
      <AppText style={Fonts.BodySmall} color={textColor}>
        {t(STATUS[value]?.text ?? "")}
      </AppText>
    </View>
  );
};

export default memo(StatusExpenseReport);
const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
  },
});
