import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <G clipPath="url(#clip0_5284_330408)">
        <Path
          d="M8 14.667A6.667 6.667 0 118 1.333a6.667 6.667 0 010 13.334zm-.665-4l4.714-4.714-.943-.943-3.77 3.771-1.887-1.886-.942.943 2.828 2.829z"
          fill="#37B24D"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5284_330408">
          <Path fill="#fff" d="M0 0H16V16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
