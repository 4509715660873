import { FC } from "react";
import BizziIcon, { IconName } from "assets/icons";

interface IconCustomProps {
  name: IconName;
  fill?: string;
  width?: number;
  height?: number;
  stroke?: string;
}
const IconCustom: FC<IconCustomProps> = ({ name, ...props }) => {
  const Icon = BizziIcon[name];
  if (!Icon) {
    console.error(`icon ${name} not found`);
    return null;
  }
  return <Icon {...props} />;
};
export default IconCustom;
