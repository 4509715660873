import { TimeFilterData, TYPE_TIME_FILTER } from "./types";

export const TIME_FILTER_DATA: TimeFilterData[] = [
  {
    title: "30_last_days",
    value: TYPE_TIME_FILTER.ONE_LAST_MONTH,
  },
  {
    title: "6_last_months",
    value: TYPE_TIME_FILTER.SIX_LAST_MONTHS,
  },
  {
    title: "9_last_months",
    value: TYPE_TIME_FILTER.NINE_LAST_MONTHS,
  },
  {
    title: "1_last_year",
    value: TYPE_TIME_FILTER.ONE_LAST_YEAR,
  },
];
