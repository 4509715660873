import { TouchableOpacity, TouchableOpacityProps, View, ViewProps } from "react-native";

import { CheckedIcon, CheckIcon } from "assets/images/svg/icons";

interface CheckBoxProps extends TouchableOpacityProps {
  checked: boolean;
}

const CheckBox = ({ checked, ...props }: CheckBoxProps) => {
  if (props?.onPress) {
    return <TouchableOpacity {...props}>{checked ? <CheckedIcon /> : <CheckIcon />}</TouchableOpacity>;
  }
  return <View {...(props as ViewProps)}>{checked ? <CheckedIcon /> : <CheckIcon />}</View>;
};

CheckBox.defaultProps = {
  checked: false,
};

export default CheckBox;
