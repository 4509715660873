import { AppText, IconCustom, ImageViewCustom, SwitchButton, useWindowDimensions } from "components";
import React, { FC, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Linking, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { ExpenseFormValues } from "screens/Expense/components/ExpenseForm/types";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { getFileExtension, startLayoutAnimation } from "utils";
import { SPACING_LARGE } from "constants/Layout";
import * as DocumentPicker from "components/InputCustom/UploadFiles/DocumentPicker";
import { useMasterData } from "contexts/MasterDataContext";
import * as ImagePicker from "expo-image-picker";
import { UPLOADABLE_TYPES } from "constants/UploadableType";
import { Control, Controller, FieldError, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { v1 } from "uuid";
import FileItem from "components/UploadFiles/FileItem";
import { ImagePreviewFooter } from "components/UploadFiles/UploadFileView";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";
import * as XLSX from "xlsx";
import Config from "app/config";

interface AllocatedUploadFileProps {
  control: Control<ExpenseFormValues>;
  name: string;
  setValue: UseFormSetValue<ExpenseFormValues>;
  error?: FieldError;
  rules?: Record<string, any>;
  setPosition?: (offsetY: number) => void;
  watch?: UseFormWatch<ExpenseFormValues>;
}
const numberOfRows = Platform.OS === "web" ? 6 : 4;
const COLUMN_GAP = 14;

const getLibraryPermission = async () => {
  const { status: cameraRollStatus } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  return cameraRollStatus === "granted";
};
const readExcelFile = async (file, t) => {
  try {
    const data = await file.arrayBuffer();
    const xlsxData = new Uint8Array(data);
    const workbook = XLSX.read(xlsxData, { type: "array" });
    const { SheetNames, Sheets } = workbook;
    const [sheetname] = SheetNames;
    const headerRows = [
      "description",
      "uom",
      "amount",
      "customer",
      "cost_center",
      "bu_code",
      "invoice_number",
      "task_code",
    ];
    const rows = XLSX.utils.sheet_to_json(Sheets[sheetname], {
      header: headerRows,
      raw: false,
      defval: "",
    });
    rows.splice(0, 3);
    return rows;
  } catch (error) {
    alert(t("invalid_file"));
    console.error("Error reading Excel file:", error);
    return [];
  }
};
const AllocatedUploadFile: FC<AllocatedUploadFileProps> = ({ control, setPosition, error, watch, setValue }) => {
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  const { width, isTablet } = useWindowDimensions();
  const IMAGE_HEIGHT = (width - SPACING_LARGE * 2) / numberOfRows;
  const { uploadConfiguration } = useMasterData();
  const onChangeFiles = useRef(null);
  const currentFiles = useRef([]);
  const numberItemPerRow = isTablet ? 6 : 3;
  const [visibleImagePreview, setVisibleImagePreview] = useState(false);
  const navigation = useNavigation<any>();
  const allocatedExpenses = watch("allocatedExpense.allocatedExpenses");
  const isEnable = watch("allocatedExpense.isOn");
  const [loadingFile, setLoadingFile] = useState(false);
  const handlePreviewImage = (uri) => () => {
    if (Platform.OS === "web") {
      window.open(uri, "_blank", "noopener noreferrer");
      return;
    }
    setVisibleImagePreview(true);
  };
  const handleToggle = () => {
    setValue("allocatedExpense.isOn", !isEnable);
    startLayoutAnimation();
  };

  const openSetting = useCallback(() => {
    Alert.alert(
      "",
      t("description_require_permission"),
      [
        {
          text: t("button_title_open_setting"),
          onPress: () => {
            Linking.openSettings();
          },
        },
        {
          text: t("cancel"),
          style: "cancel",
        },
      ],
      { cancelable: false }
    );
  }, [t]);
  const handleChooseFile = async () => {
    const status = await getLibraryPermission();
    if (status) {
      const document = await DocumentPicker.getDocumentAsync({
        multiple: false,
        copyToCacheDirectory: true,
        type: UPLOADABLE_TYPES,
      });
      openDocumentPicker(document);
    } else {
      openSetting();
    }
  };
  const openDocumentPicker = useCallback(
    async (document: DocumentPicker.GetDocumentAsyncResult) => {
      if (document.type === "cancel") {
        return;
      }
      setLoadingFile(true);
      const file = document.output[0];
      const fileExtension = getFileExtension(file.name);
      if (!["xlsx", "xls"].includes(fileExtension)) {
        alert(t("invalid_file"));
        return;
      }
      if ((file.size ?? 0) > uploadConfiguration?.maxFileSize) {
        alert(t("descriptions_max_size_upload", { value: uploadConfiguration?.maxFileSize / 1024 / 1024 }));
        return;
      }
      const fileUrl = URL.createObjectURL(file);
      const fileObject = {
        id: v1(),
        name: file.name,
        size: file.size,
        fileType: file.type,
        uri: fileUrl,
      };
      onChangeFiles?.current?.([fileObject]);
      const rows = await readExcelFile(file, t);
      setLoadingFile(false);
      setValue("allocatedExpense.allocatedExpenses", rows);
    },
    [t, uploadConfiguration?.maxFileSize]
  );
  const handleRemoveFile = () => {
    onChangeFiles?.current?.(null);
    setValue("allocatedExpense.allocatedExpenses", null);
  };
  const handleViewDetail = () => {
    navigation.navigate(SCREEN_NAME.AllocatedExpenseScreen, { expenses: allocatedExpenses });
  };
  const handleDownloadTemplateFile = () => {
    window.open(Config.ALLOCATED_FILE_TEMPLATE, "_blank", "noopener noreferrer");
  };

  const WIDTH_FILE = Math.floor(
    (width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING - COLUMN_GAP * (numberItemPerRow - 1)) / numberItemPerRow
  );

  return (
    <View
      style={styles.container}
      onLayout={(event) => {
        setPosition?.(event.nativeEvent.layout.y);
      }}
    >
      <View style={styles.header}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("allocated_expenses_title")}</AppText>
        <SwitchButton value={isEnable} onValueChange={handleToggle} />
      </View>
      {isEnable && (
        <Controller
          name="allocatedExpense.allocatedDocuments"
          control={control}
          rules={{ required: t("required") }}
          render={({ field: { onChange, value } }) => {
            const files = value as ExpenseFormValues["allocatedExpense"]["allocatedDocuments"];
            currentFiles.current = files;
            onChangeFiles.current = onChange;
            return (
              <>
                <View>
                  <View style={styles.content}>
                    {files?.map((file) => {
                      return (
                        <View key={file.id}>
                          <FileItem
                            loading={loadingFile}
                            handlePreviewImage={handlePreviewImage(file.uri)}
                            width={WIDTH_FILE}
                            height={WIDTH_FILE}
                            key={file.id}
                            file={file}
                            onRemove={handleRemoveFile}
                          />
                        </View>
                      );
                    })}
                    {!files?.length && (
                      <TouchableOpacity
                        onPress={handleChooseFile}
                        style={[styles.btnAddImage, { height: IMAGE_HEIGHT, width: "100%" }]}
                      >
                        <IconCustom name="add-circle-outline" width={32} height={32} />
                      </TouchableOpacity>
                    )}
                    {files?.length && !loadingFile && (
                      <TouchableOpacity onPress={handleViewDetail} style={styles.seeMoreButton}>
                        <AppText style={Fonts.SentenceSubtitleXLarge} color={Colors.primary50}>
                          {t("allocated_num_expense", { num: allocatedExpenses?.length })}
                        </AppText>
                        <IconCustom name="arrow-right" width={30} height={30} fill={Colors.primary50} />
                      </TouchableOpacity>
                    )}
                  </View>
                  {Boolean(error?.message) && (
                    <AppText style={[Fonts.BodySmall, { marginTop: 10 }]} color={Colors.alert50}>
                      {error.message}
                    </AppText>
                  )}
                  <TouchableOpacity style={{ marginTop: 10 }} onPress={handleDownloadTemplateFile}>
                    <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                      {t("download_allocated_file_template")}
                    </AppText>
                  </TouchableOpacity>
                </View>
                <ImageViewCustom
                  images={files?.[0] ? [{ uri: files[0].uri }] : []}
                  imageIndex={0}
                  visible={visibleImagePreview}
                  FooterComponent={({ imageIndex }) => <ImagePreviewFooter imageIndex={imageIndex} imagesCount={1} />}
                  onRequestClose={() => setVisibleImagePreview(false)}
                />
              </>
            );
          }}
        />
      )}
    </View>
  );
};
export default AllocatedUploadFile;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.while,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
    gap: 16,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    gap: 16,
    flexDirection: "row",
  },
  btnAddImage: {
    height: 100,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: "dashed",
    alignItems: "center",
    justifyContent: "center",
    borderColor: Colors.primary20,
    backgroundColor: Colors.primary0,
  },
  seeMoreButton: {
    paddingTop: 45,
    flexDirection: "row",
  },
});
