import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

export const EXPENSE_VERIFY_OTP = gql`
  mutation MobileVerifyOTP($objects: ExpenseVerifyOtpInput!) {
    expenseVerifyOTP(objects: $objects) {
      challenge_id
      success
    }
  }
`;

const useExpenseVerifyRequestOTP = () => {
  const [mutation, results] = useMutation(EXPENSE_VERIFY_OTP);

  const onVerifyRequestOTP = useCallback(
    async (objects) => {
      return await mutation({
        variables: {
          objects,
        },
      });
    },
    [mutation]
  );

  return {
    onVerifyRequestOTP,
    ...results,
  };
};

export default useExpenseVerifyRequestOTP;
