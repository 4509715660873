import { FC, ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import AppText from "./AppText";
import { Colors, Fonts } from "theme";
import { IconCustom } from "components/index";

enum LabelType {
  Equivalent = "equivalent",
  Increase = "increase",
  Decrease = "decrease",
}

type PercentLabelProps = {
  style?: StyleProp<ViewStyle>;
  value: number;
  children?: ReactNode;
};

const PercentLabel: FC<PercentLabelProps> = ({ value, style, children }) => {
  const labelType = value === 0 ? LabelType.Equivalent : value > 0 ? LabelType.Increase : LabelType.Decrease;
  const displayValue = Math.floor(Math.abs(value));

  return (
    <View style={[styles.summary, style]}>
      {
        {
          [LabelType.Equivalent]: (
            <AppText style={Fonts.NumericN100} color={Colors.grayscale80}>
              {displayValue}%{children}
            </AppText>
          ),
          [LabelType.Increase]: (
            <>
              <View style={styles.icon}>
                <IconCustom name="arrow-upward-alt" />
              </View>
              <AppText style={Fonts.NumericN100} color={Colors.alert50}>
                {displayValue}%{children}
              </AppText>
            </>
          ),
          [LabelType.Decrease]: (
            <>
              <View style={styles.icon}>
                <IconCustom name="arrow-downward-alt" />
              </View>
              <AppText style={Fonts.NumericN100} color={Colors.success50}>
                {displayValue}%{children}
              </AppText>
            </>
          ),
        }[labelType]
      }
    </View>
  );
};

export default PercentLabel;

const styles = StyleSheet.create({
  summary: {
    flexDirection: "row",
    gap: 2,
  },
  icon: { marginTop: 3.3 },
});
