// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "react-native-paper";
import { View, StyleSheet, useWindowDimensions } from "react-native";

import { Colors } from "constants/Colors";
import SCREEN_NAME from "navigation/ScreenName";
import DeleteAccountIcon from "assets/images/svg/icons/DeleteAccountIcon";
import { AccountDeletedScreenNavigationProp, AccountDeletedScreenRouteProp } from "navigation/type";

interface AccountDeletedScreenProps {
  route: AccountDeletedScreenRouteProp;
  navigation: AccountDeletedScreenNavigationProp;
}

const AccountDeletedScreen: FC<AccountDeletedScreenProps> = ({ navigation }) => {
  const { t } = useTranslation("screens/Auth/AccountDeletedScreen");
  const { width } = useWindowDimensions();

  return (
    <View style={styles.container}>
      <View style={styles.center}>
        <DeleteAccountIcon />
        <Text style={[styles.title, { fontSize: width > 1024 ? 28 : 20, marginTop: 16, maxWidth: 800 }]}>
          {t("account_deleted_title")}
        </Text>
        <Text style={[styles.content, { fontSize: width > 1024 ? 22 : 14, marginTop: 16, maxWidth: 800 }]}>
          {t("account_deleted_content")}
        </Text>
        <Button
          labelStyle={{ fontWeight: "600" }}
          style={{ marginTop: 32 }}
          onPress={async () => {
            navigation.navigate(SCREEN_NAME.LoginScreen);
          }}
        >
          {t("back")}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    paddingHorizontal: 15,
  },
  title: {
    fontWeight: "400",

    textAlign: "center",
    color: Colors.ebony,
  },
  content: {
    fontWeight: "400",

    textAlign: "center",
    color: Colors.ebony,
  },
});

export default AccountDeletedScreen;
