import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, BackHeader, Button, FloatFooterExpand, IconCustom, LottieLoading, TextView } from "components";
import React, { FC, useMemo, useRef, useState } from "react";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import BizziBotHappy from "assets/images/svg/BizziBotHappy";
import dayjs from "dayjs";
import { BOOKING_TYPE, CONSTANTS } from "constants/constants";
import SCREEN_NAME from "navigation/ScreenName";
import useFlightBookingInfoQuery from "../hooks/useFlightBookingInfoQuery";
import TicketOverview from "../components/TicketOverview";
import { getMinDate } from "utils";
import { BookingInformation, FlightTicket } from "../types";
import TicketDetailModal, { TicketDetailModalApi, TicketDetailModalData } from "../components/TicketDetailModal";
import { getListFaresFromBookingInfo } from "screens/FlightBooking/helpers";
import ReservationStatusBadge from "../components/ReservationStatusBadge";
import FlightTicketAmountDetail from "screens/FlightBooking/components/FlightTicketAmountDetail";
import { ReservationOverviewScreenProps } from "navigation/type";

const ReservationOverviewScreen: FC<ReservationOverviewScreenProps> = ({ route, navigation }) => {
  const { t } = useTranslation("app/screens/FlightBooking/ReservationOverviewScreen/ReservationOverviewScreen");
  const expenseRequestId = route?.params?.expenseRequestId;
  const { data, loading } = useFlightBookingInfoQuery({ expenseRequestId });

  const ticketModalRef = useRef<TicketDetailModalApi>(null);
  const [footerHeight, setFooterHeight] = useState(0);

  const handlePay = () => {
    navigation.navigate(SCREEN_NAME.CheckoutScreen, { expenseRequestId });
  };

  const handlePayLater = () => {
    navigation.navigate(SCREEN_NAME.DetailRequestScreen, { expenseRequestId });
  };

  const reservation = useMemo(() => {
    const booking = data?.expExpenseRequestDetail?.expenseTravels?.[0]?.expenseBookings?.find(
      (booking) => booking.bookingType === BOOKING_TYPE.FLIGHT
    )?.expenseTravelBookings?.[0];

    if (!booking) {
      return null;
    }

    const bookingInformation = booking.bookingInformation as BookingInformation;
    const listBooking = bookingInformation.listBooking ?? [];
    const bookingDeparture = listBooking?.[0];
    const bookingReturn = listBooking?.[1];
    const fares = getListFaresFromBookingInfo(bookingInformation, booking?.status);

    return {
      tickets: {
        departure: fares?.[0],
        return: fares?.[1],
      },
      orderCode: booking.orderCode,
      status: booking.status,
      totalAmount: booking.totalAmount,
      expiredAt: getMinDate(listBooking.map((booking) => dayjs(booking.expiredAt))),

      bookingCodes: {
        departure: bookingDeparture?.bookingCode,
        return: bookingReturn?.bookingCode,
      },
      contact: bookingInformation.contact,
      passenger: listBooking?.[0]?.passengers?.[0],
    };
  }, [data]);

  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const renderFooter = () => {
    if (loading) {
      return null;
    }
    const booking = data?.expExpenseRequestDetail?.expenseTravels?.[0]?.expenseBookings?.find(
      (booking) => booking.bookingType === BOOKING_TYPE.FLIGHT
    )?.expenseTravelBookings?.[0];
    const fares = getListFaresFromBookingInfo(booking?.bookingInformation, booking?.status);

    return (
      <FloatFooterExpand
        onLayout={onLayoutFooter}
        totalAmount={reservation?.totalAmount}
        priceLabel={t("total_amount")}
        expandComponent={<FlightTicketAmountDetail fares={fares} />}
        footerButton={
          <View style={styles.footerButtonContainer}>
            <Button type="secondary" style={styles.footerButton} onPress={handlePayLater}>
              {t("checkout_later")}
            </Button>
            <Button style={styles.footerButton} onPress={handlePay}>
              {t("checkout_now")}
            </Button>
          </View>
        }
      />
    );
  };

  return (
    <BottomSheetModalProvider>
      <View style={styles.contentContainer}>
        <BackHeader
          onPress={() => navigation.navigate(SCREEN_NAME.DetailRequestScreen, { expenseRequestId })}
          containerStyle={styles.pageHeader}
          headerTitle={t("page_title")}
        />

        {loading ? (
          <LottieLoading style={styles.loadingView} />
        ) : (
          <ScrollView
            scrollEnabled={true}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: footerHeight }}
          >
            <View style={styles.notificationSection}>
              <View style={styles.notificationEmotion}>
                <BizziBotHappy width={80} height={80} />
              </View>
              <View style={styles.notificationContent}>
                <AppText style={[Fonts.H300, styles.textCenter]}>{t("reservation_success")}</AppText>
                <ReservationStatusBadge status={reservation.status} />
                <AppText style={[Fonts.BodyMedium, styles.textCenter]}>
                  {t("prefix_checkout_noti")}{" "}
                  <AppText style={Fonts.H200}>{reservation.expiredAt?.format("HH:mm DD/MM/YYYY")}</AppText>{" "}
                  {t("suffix_checkout_noti")}
                </AppText>
              </View>
            </View>

            <View style={styles.ticketSection}>
              {reservation.tickets?.departure && (
                <TouchableOpacity
                  onPress={() => {
                    ticketModalRef.current?.showTicket(
                      {
                        ticket: reservation.tickets.departure as TicketDetailModalData["ticket"],
                        reservation: { status: reservation.status, bookingCode: reservation.bookingCodes.departure },
                      },
                      {
                        icon: <IconCustom name="flight-takeoff" />,
                      }
                    );
                  }}
                >
                  <TicketOverview
                    containerStyle={styles.ticketContainer}
                    icon={<IconCustom name="flight-takeoff" />}
                    data={reservation.tickets.departure as FlightTicket}
                    baggageInfo={reservation?.tickets?.departure?.bookingInfo?.baggage}
                  />
                </TouchableOpacity>
              )}
              {reservation.tickets?.return && (
                <TouchableOpacity
                  onPress={() => {
                    ticketModalRef.current?.showTicket(
                      {
                        ticket: reservation.tickets.return as TicketDetailModalData["ticket"],
                        reservation: { status: reservation.status, bookingCode: reservation.bookingCodes.return },
                      },
                      {
                        icon: <IconCustom name="flight-land" />,
                      }
                    );
                  }}
                >
                  <TicketOverview
                    containerStyle={styles.ticketContainer}
                    icon={<IconCustom name="flight-land" />}
                    data={reservation.tickets.return as FlightTicket}
                    baggageInfo={reservation?.tickets?.return?.bookingInfo?.baggage}
                  />
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.section}>
              <AppText style={[Fonts.SentenceSubtitleLarge, styles.sectionHeader]}>{t("passenger_info")}</AppText>
              <View style={styles.sectionBody}>
                <TextView label={t("last_name")} value={reservation.passenger.lastName} />
                <TextView label={t("first_name")} value={reservation.passenger.firstName} />
                <TextView
                  label={t("gender")}
                  value={{ male: t("male"), female: t("female") }[reservation.passenger?.gender?.toLocaleLowerCase()]}
                />
                <TextView label={t("birth_day")} value={dayjs(reservation.passenger.birthday).format("DD/MM/YYYY")} />
              </View>
            </View>
            <View style={styles.section}>
              <AppText style={[Fonts.SentenceSubtitleLarge, styles.sectionHeader]}>{t("contact_info")}</AppText>
              <View style={styles.sectionBody}>
                <TextView label={t("full_name")} value={reservation.contact.firstName} />
                <TextView label={t("phone")} value={reservation.contact.phone} />
                <TextView label={t("email")} value={reservation.contact.email} />
              </View>
            </View>
          </ScrollView>
        )}
      </View>
      {renderFooter()}
      <TicketDetailModal ref={ticketModalRef} />
    </BottomSheetModalProvider>
  );
};

export default ReservationOverviewScreen;

const styles = StyleSheet.create({
  pageHeader: {
    paddingBottom: 5,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.grayscale0,
  },
  notificationSection: {
    backgroundColor: Colors.white,
    paddingVertical: 12,
  },
  notificationEmotion: {
    paddingTop: 12,
    alignItems: "center",
  },
  notificationContent: {
    alignItems: "center",
    gap: 8,
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  ticketSection: {
    marginTop: 16,
  },
  section: {
    marginBottom: 8,
    paddingBottom: 16,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  sectionHeader: {
    paddingVertical: 16,
  },
  sectionBody: {
    gap: 8,
  },
  footerButtonContainer: {
    flexDirection: "row",
    gap: 8,
  },
  footerButton: {
    flex: 1,
  },
  textCenter: {
    textAlign: "center",
  },
  ticketContainer: {
    marginBottom: 16,
  },
  loadingView: {
    flex: 1,
    backgroundColor: Colors.white,
  },
});
