import { useLayoutEffect, useRef } from "react";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  AvatarUser,
  BackHeader,
  FloatFooter,
  IconCustom,
  Line,
  ToastManager,
} from "components";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import { useAuth } from "contexts/AuthContext";
import { COMPANIES } from "constants/Companies";
import SCREEN_NAME from "navigation/ScreenName";
import { UpdateAvatarScreenNavigationProp } from "navigation/type";
import { CameraIcon2 } from "assets/images/svg/icons";
import config from "app/config";
import { goBack } from "navigation/RootNavigation";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import useDeleteAccount from "hooks/useDeleteAccount";

const ProfileDetailScreen = () => {
  const navigation = useNavigation<UpdateAvatarScreenNavigationProp>();
  const { t } = useTranslation("app/screens/ProfileTab/ProfileDetailScreen/ProfileDetailScreen");
  const {
    user,
    logout,
    company: { company_id: companyId },
  } = useAuth();
  const [deleteAccount] = useDeleteAccount();
  const alertRef = useRef<AlertNotificationHandle>();
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("profile_info")} onPress={goBack} />,
    } as any);
  }, [navigation, t]);

  const handleAvatarClick = async () => {
    if (Platform.OS === "web") {
      window.open(`${config.ACCOUNT_ENDPOINT}/personal-info/avatar`);
      return;
    }

    navigation.navigate(SCREEN_NAME.UpdateAvatarScreen);
  };

  const handleDeleteAccount = () => {
    alertRef?.current?.warning({
      title: t("title_delete"),
      description: t("confirm_delete"),
      confirmText: t("confirm"),
      cancelText: t("cancel"),
      countDownConfirm: 10,
      onConfirm: async () => {
        alertRef?.current?.loading();
        return deleteAccount({
          variables: { userId: user?.id },
          onCompleted: async () => {
            analyticService.logEvent({ name: EVENT.PROFILE.DELETED_ACCOUNT_SUCCESS });
            await logout();
            alertRef?.current?.close();
            ToastManager.success(t("success_delete"));
          },
          onError: () => {
            alertRef?.current?.close();
            analyticService.logEvent({ name: EVENT.PROFILE.DELETE_ACCOUNT_FAILED });
          },
        });
      },
    });
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer} showsVerticalScrollIndicator={false}>
        <View style={styles.avatarContainer}>
          <TouchableOpacity style={styles.avatar} onPress={handleAvatarClick}>
            <AvatarUser size={100} url={user?.avatar} />
            <View style={styles.cameraIconContainer}>
              <CameraIcon2 />
            </View>
          </TouchableOpacity>
        </View>
        <View style={[styles.infoRow, { marginTop: 10 }]}>
          <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
            {t("fullname")}
          </AppText>
          <AppText style={[Fonts.SentenceSubTitleMedium, styles.rightTitle]}>{user?.fullname}</AppText>
        </View>
        <Line />
        <View style={styles.infoRow}>
          <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
            Email
          </AppText>
          <AppText style={[Fonts.SentenceSubTitleMedium, styles.rightTitle]}>{user?.email}</AppText>
        </View>
        <Line />
        <View style={styles.infoRow}>
          <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
            {t("phone_number")}
          </AppText>
          <AppText
            style={[Fonts.SentenceSubTitleMedium, styles.rightTitle, !user?.phone && Fonts.SentenceBodyMedium]}
            color={user?.phone ? Colors.grayscale100 : Colors.grayscale60}
          >
            {user?.phone || t("no_value", { label: t("phone_number").toLowerCase() })}
          </AppText>
        </View>
        {[
          COMPANIES.MDLZ_NORTH_EXPENSE_COMPANY_DEV,
          COMPANIES.MDLZ_NORTH_EXPENSE_COMPANY_PRODUCTION,
          COMPANIES.MDLZ_NORTH_EXPENSE_COMPANY_UAT,
          COMPANIES.MDLZ_SOUTH_EXPENSE_COMPANY_DEV,
          COMPANIES.MDLZ_SOUTH_EXPENSE_COMPANY_PRODUCTION,
          COMPANIES.MDLZ_SOUTH_EXPENSE_COMPANY_UAT,
          COMPANIES.MDLZ_TRADE_EXPENSE_COMPANY_PRODUCTION,
        ].includes(companyId) && (
          <>
            <Line />
            <View style={styles.infoRow}>
              <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
                {t("employee_code")}
              </AppText>
              <AppText
                style={[
                  Fonts.SentenceSubTitleMedium,
                  styles.rightTitle,
                  !user?.employee?.employee_code && Fonts.SentenceBodyMedium,
                ]}
                color={user?.employee?.employee_code ? Colors.grayscale100 : Colors.grayscale60}
              >
                {user?.employee?.employee_code || t("no_value", { label: t("employee_code").toLowerCase() })}
              </AppText>
            </View>
            <Line />
            <View style={styles.infoRow}>
              <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
                {t("cost_center")}
              </AppText>
              <AppText
                style={[
                  Fonts.SentenceSubTitleMedium,
                  styles.rightTitle,
                  !user?.employee?.custom_fields?.cost_center && Fonts.SentenceBodyMedium,
                ]}
                color={user?.employee?.custom_fields?.cost_center ? Colors.grayscale100 : Colors.grayscale60}
              >
                {user?.employee?.custom_fields?.cost_center || t("no_value", { label: t("cost_center").toLowerCase() })}
              </AppText>
            </View>
            <Line />
            <View style={styles.infoRow}>
              <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale80}>
                {t("sg")}
              </AppText>
              <AppText
                style={[
                  Fonts.SentenceSubTitleMedium,
                  styles.rightTitle,
                  !user.employee?.expense_policies_employees[0]?.expense_policy.name && Fonts.SentenceBodyMedium,
                ]}
                color={
                  user.employee?.expense_policies_employees[0]?.expense_policy.name
                    ? Colors.grayscale100
                    : Colors.grayscale60
                }
              >
                {user.employee?.expense_policies_employees[0]?.expense_policy.name ||
                  t("no_value", { label: t("sg").toLowerCase() })}
              </AppText>
            </View>
            <Line />
          </>
        )}
      </ScrollView>
      <FloatFooter enableShadow={false}>
        <TouchableOpacity style={styles.deleteAccountButton} onPress={handleDeleteAccount}>
          <IconCustom name="delete" width={22} height={22} fill={Colors.grayscale70} />
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>
            {t("delete_account")}
          </AppText>
        </TouchableOpacity>
      </FloatFooter>
      <AlertNotification ref={alertRef} />
    </View>
  );
};
export default ProfileDetailScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingTop: 15, paddingBottom: 70, paddingHorizontal: 20 },
  infoRow: { flexDirection: "row", justifyContent: "space-between", paddingVertical: 12, gap: 8 },
  rightTitle: { flex: 1, textAlign: "right", lineHeight: 20 },
  avatarContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 12,
  },
  avatar: {
    borderRadius: 999,
    overflow: "hidden",
  },
  // eslint-disable-next-line react-native/no-color-literals
  cameraIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: 30,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#111827",
  },
  deleteAccountButton: { flexDirection: "row", alignItems: "center", gap: 4, alignSelf: "center" },
});
