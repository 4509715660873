import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={8} height={9} viewBox="0 0 8 9">
      <Path
        d="M3.335 6.465V1c0-.19.064-.348.192-.475a.645.645 0 01.475-.192c.189 0 .347.064.475.192a.645.645 0 01.191.475v5.466l1.934-1.933a.632.632 0 01.466-.183c.19 0 .345.06.467.183a.632.632 0 01.183.467.632.632 0 01-.183.466L4.468 8.532a.64.64 0 01-.466.2.64.64 0 01-.467-.2L.468 5.465A.632.632 0 01.285 5c0-.19.061-.345.183-.467a.632.632 0 01.467-.183c.189 0 .345.06.467.183l1.933 1.933z"
        fill="#37B24D"
      />
    </Svg>
  );
}

export default SvgComponent;
