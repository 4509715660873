import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { Platform, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { CARD_REGISTRATION_STATUS } from "constants/constants";
import { FC } from "react";
import { TimeLineAwaitingIcon, TimeLineRejectIcon, TimeLineSuccessIcon } from "assets/images/svg/icons";

const AvatarStep = ({ step, backgroundColor }) => {
  return (
    <View style={[styles.avatar, { backgroundColor }]}>
      <AppText style={Fonts.H200} color={Colors.white}>
        {step}
      </AppText>
    </View>
  );
};
const PROCESS = [
  { title: "process_step_1", code: CARD_REGISTRATION_STATUS.REGISTERED },
  { title: "process_step_2", code: CARD_REGISTRATION_STATUS.BIZZI_VERIFIED },
  { title: "process_step_3", code: CARD_REGISTRATION_STATUS.BANK_VERIFIED },
  { title: "process_step_4", code: CARD_REGISTRATION_STATUS.CARD_ISSUED },
];
interface ProcessStatusTimeLineProps {
  status: CARD_REGISTRATION_STATUS;
}
const ProcessStatusTimeLine: FC<ProcessStatusTimeLineProps> = ({ status }) => {
  const { t } = useTranslation("app/screens/Card/CardListScreen");
  const getThemeByStatus = (status) => {
    if (status === "success") {
      return { icon: <TimeLineSuccessIcon />, lineColor: Colors.success05, backgroundColor: Colors.success50 };
    }
    if (status === "waiting") {
      return {
        icon: <TimeLineAwaitingIcon color={Colors.primary50} />,
        lineColor: Colors.primary20,
        backgroundColor: Colors.primary50,
      };
    }
    if (status === "error") {
      return {
        icon: <TimeLineRejectIcon />,
        lineColor: Colors.alert10,
        backgroundColor: Colors.alert20,
      };
    }
    return { icon: <TimeLineAwaitingIcon />, lineColor: Colors.grayscale10, backgroundColor: Colors.grayscale40 };
  };
  const renderFirstLine = () => {
    return <View style={styles.dividerFirstLine} />;
  };
  return (
    <View style={styles.processContainer}>
      <AppText style={Fonts.SentenceSubTitleXLarge}>{t("process_opening")}</AppText>
      <View style={styles.timeLineContainer}>
        {renderFirstLine()}
        {PROCESS?.map((item, index) => {
          let statusOfStep = "";
          switch (status) {
            case CARD_REGISTRATION_STATUS.REGISTERED: {
              if (index === 0) {
                statusOfStep = "success";
              } else if (index === 1) {
                statusOfStep = "waiting";
              }
              break;
            }
            case CARD_REGISTRATION_STATUS.BIZZI_VERIFIED: {
              if (index === 0 || index === 1) {
                statusOfStep = "success";
              } else if (index === 2) {
                statusOfStep = "waiting";
              }
              break;
            }
            case CARD_REGISTRATION_STATUS.BANK_VERIFIED:
            case CARD_REGISTRATION_STATUS.CARD_ISSUED: {
              if (index === 0 || index === 1 || index === 2) {
                statusOfStep = "success";
              } else if (index === 3) {
                statusOfStep = "waiting";
              }
              break;
            }
            case CARD_REGISTRATION_STATUS.BIZZI_REJECTED: {
              if (index === 0) {
                statusOfStep = "success";
              } else if (index === 1) {
                statusOfStep = "error";
              }
              break;
            }
            case CARD_REGISTRATION_STATUS.BANK_REJECTED: {
              if (index === 0 || index === 1) {
                statusOfStep = "success";
              } else if (index === 2) {
                statusOfStep = "error";
              }
              break;
            }
          }
          const theme = getThemeByStatus(statusOfStep);
          const icon = theme.icon;
          const lineColor = theme.lineColor;
          const backgroundColor = theme.backgroundColor;
          const isLastDivider = PROCESS.length - 1 === index;
          return (
            <View key={index}>
              <View
                style={[
                  styles.divider,
                  Platform.OS === "web"
                    ? { backgroundColor: lineColor, top: isLastDivider ? 52 : 55 }
                    : { backgroundColor: lineColor },
                ]}
              />
              <View style={styles.content}>
                <View
                  style={[styles.debugDot, statusOfStep !== "success" && statusOfStep !== "error" && { left: -40 }]}
                >
                  {icon}
                </View>
                <View
                  style={[
                    styles.personContainer,
                    statusOfStep !== "success" && statusOfStep !== "error" && { marginLeft: -19 },
                  ]}
                >
                  <AvatarStep backgroundColor={backgroundColor} step={index + 1} />
                  <View style={styles.flex}>
                    <AppText style={Fonts.H200}>
                      {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-nocheck
                        t(item?.title)
                      }
                    </AppText>
                  </View>
                </View>
              </View>
            </View>
          );
        })}
      </View>
      <View style={styles.footer} />
    </View>
  );
};
export default ProcessStatusTimeLine;
const styles = StyleSheet.create({
  processContainer: { marginTop: 35 },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 48 / 2,
    backgroundColor: Colors.dataTeal,
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    width: 2,
    position: "absolute",
    height: "100%",
    top: "50%",
    left: 11,
  },
  dividerFirstLine: {
    width: 2,
    position: "absolute",
    left: 11,
    height: 50,
    top: 0,
    backgroundColor: Colors.success05,
  },
  content: {
    paddingVertical: 20,
    marginLeft: 44,
    flexDirection: "row",
  },
  personContainer: {
    marginLeft: -23,
    paddingVertical: 0,
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    gap: 16,
  },
  debugDot: {
    // borderRadius: 999,
    alignSelf: "center",
    paddingVertical: 0,
    left: -44,
  },
  flex: { flex: 1 },
  timeLineContainer: { marginTop: 20 },
  footer: { backgroundColor: Colors.white, height: 50 },
});
