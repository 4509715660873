import { StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, CheckBox, Line } from "components";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { Colors, Fonts } from "theme";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";

interface SelectListProps {
  title?: string;
  options?: SelectItem[];
  value?: SelectItem["value"];
  emptyState?: ReactElement;
  onChange?: (value: SelectItem["value"]) => void;
}
const SelectList: FC<SelectListProps> = ({ options, title, value, emptyState, onChange }) => {
  const [currentValue, setCurrentValue] = useState<SelectItem["value"][]>([]);
  useEffect(() => {
    setCurrentValue(value ?? []);
  }, [value]);
  const handleCheckboxSelect = (value) => () => {
    const statusIsExist = currentValue?.find((item) => item === value);
    if (statusIsExist) {
      onChange(currentValue.filter((item) => item !== value));
      //setCurrentValue((prevState) => prevState.filter((i) => i?.value !== item.value));
    } else {
      console.log("currentValue11", currentValue.concat([value]));
      onChange(currentValue.concat([value]));
      //setCurrentValue((prevState) => prevState.concat([item]));
    }
  };
  return (
    <View style={styles.container}>
      <AppText style={Fonts.H200} color={Colors.grayscale100}>
        {title}
      </AppText>
      <View>
        {options?.map((item, index) => {
          const isLastItem = index === options.length - 1;
          const isChecked = currentValue?.findIndex((i) => i === item?.value) !== -1;
          return (
            <View key={item?.value}>
              <TouchableOpacity style={styles.selectItem} onPress={handleCheckboxSelect(item?.value)}>
                <View style={styles.labelContainer}>
                  {item?.leftIcon ? item?.leftIcon : null}
                  <AppText style={Fonts.BodyMedium}>{item?.label}</AppText>
                </View>
                <CheckBox checked={Boolean(isChecked)} />
              </TouchableOpacity>
              {!isLastItem && (
                <View style={styles.dividerContainer}>
                  <Line size="sm" />
                </View>
              )}
            </View>
          );
        })}
      </View>
      {!options?.length && emptyState}
    </View>
  );
};
export default SelectList;
const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  selectItem: {
    paddingVertical: 16,
    flexDirection: "row",
  },
  dividerContainer: {
    marginRight: -20,
  },
  labelContainer: { flexDirection: "row", flex: 1, alignItems: "center", gap: 16 },
});
