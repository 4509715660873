import React, { FC, memo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { BottomSheetHandle, BottomSheetHandleProps } from "@gorhom/bottom-sheet";

import { AppText } from "components";
import { CloseIcon } from "assets/images/svg/icons";
import { Fonts } from "theme";

interface HeaderBottomSheetProps extends BottomSheetHandleProps {
  title?: string;
  onClose?: () => void;
}

const HeaderBottomSheet: FC<HeaderBottomSheetProps> = ({ title, onClose, ...rest }) => {
  return (
    <BottomSheetHandle {...rest}>
      <View style={styles.header}>
        <AppText style={Fonts.H400}>{title}</AppText>
        <TouchableOpacity onPress={onClose}>
          <CloseIcon />
        </TouchableOpacity>
      </View>
    </BottomSheetHandle>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    padding: 0,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 5,
  },
});

export default memo(HeaderBottomSheet);
