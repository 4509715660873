import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={121} height={120} viewBox="0 0 121 120" fill="none">
      <G clipPath="url(#clip0_5299_18237)">
        <Path
          d="M51.365 103.54c2.882.918 6.165 1.435 9.659 1.435 3.494 0 6.776-.517 9.659-1.435-2.788-2.577-6.118-4.224-9.66-4.224-3.54 0-6.846 1.624-9.658 4.224z"
          fill="#C0CAD8"
        />
        <Path
          d="M109.413 62.4c0 23.482-21.93 35.459-49 35.459-27.072 0-48.978-12-48.978-35.46 0-23.447 21.93-47.188 49-47.188 27.072 0 48.978 23.718 48.978 47.189z"
          fill="#E4EAF1"
        />
        <Path
          d="M39.941 19.952V73.67c6.236 1.989 13.153 3.07 20.495 3.07 7.317 0 14.259-1.081 20.494-3.07V19.952c-6.235-3-13.177-4.741-20.494-4.741-7.318 0-14.26 1.741-20.495 4.741z"
          fill="#1F71F4"
        />
        <Path
          d="M57.542 38.552h-1.024l.694-3.13 1.46-6.67.07-.364H53.53l1.917-8.483h-3.082l7.2-4.694 4.941 4.694H62.13l-.741 3.388h6.859l-10.706 15.26z"
          fill="#fff"
        />
        <Path
          d="M24.812 75.917c-6.33-7.835.518-33.918 9.059-39.235 11.47-7.153 45.294-6.26 56.036 1.917 7.788 5.93 13.27 31.636 7.247 38.4-12.047 13.46-61.036 12.895-72.342-1.082z"
          fill="#1B1B1D"
        />
        <Path
          d="M15.07 69.858l-5.623-1.436c-1.035-.258-1.788-1.388-1.788-2.67v-9.424c0-1.294.753-2.412 1.812-2.67l5.623-1.436c1.412-.353 2.718.942 2.718 2.671v12.294c-.024 1.706-1.33 3.024-2.741 2.67z"
          fill="#C0CAD8"
        />
        <Path
          d="M11.882 69.106l-5.164-1.294c-.953-.224-1.636-1.259-1.636-2.459v-8.635c0-1.2.683-2.2 1.647-2.436l5.165-1.294c1.294-.33 2.482.871 2.482 2.436v11.235c0 1.612-1.223 2.777-2.494 2.447z"
          fill="#E4EAF1"
        />
        <Path
          d="M105.777 70.612l5.624-1.436c1.058-.258 1.811-1.388 1.811-2.67v-9.424c0-1.294-.753-2.412-1.811-2.67l-5.624-1.436c-1.412-.353-2.718.941-2.718 2.67v12.295c0 1.73 1.306 3.047 2.718 2.67z"
          fill="#C0CAD8"
        />
        <Path
          d="M108.989 69.883l5.165-1.294c.965-.235 1.647-1.27 1.647-2.435v-8.635c0-1.2-.682-2.2-1.647-2.436l-5.165-1.294c-1.294-.33-2.482.87-2.482 2.435V67.46c0 1.565 1.188 2.753 2.482 2.423z"
          fill="#E4EAF1"
        />
        <Path
          d="M27.506 74.625l.788 7.33a4.85 4.85 0 01-3.341 5.129c-2.553.812-5.282-.588-6.094-3.13a4.838 4.838 0 012.235-5.694l6.412-3.635zM92.554 74.613l7.33-.788a4.85 4.85 0 015.129 3.341c.811 2.553-.589 5.283-3.13 6.094a4.837 4.837 0 01-5.694-2.235l-3.635-6.412zM86.777 63.047l4.153.847a2.797 2.797 0 012.235 2.73 2.794 2.794 0 01-2.776 2.8 2.796 2.796 0 01-2.741-2.212l-.87-4.165zM35.118 64l-.33 4.235a2.794 2.794 0 01-2.423 2.553c-1.53.2-2.93-.882-3.117-2.412a2.784 2.784 0 011.858-2.988L35.118 64z"
          fill="#66B9F9"
        />
        <Path
          d="M35.353 57.188s11.236.811 15.836 5.552c0 0-11 .46-15.836 3.812 0 0 6.118-3.73 9.8-4.682 0 0-7.823-3.753-9.8-4.682zM86.224 57.188s-11.235.811-15.835 5.552c0 0 11 .46 15.835 3.812 0 0-6.118-3.73-9.8-4.682 0 0 7.824-3.753 9.8-4.682z"
          fill="#fff"
        />
        <Path
          d="M53.542 75.387s2.364-7.977 6.753-7.977c4.388 0 6.659 7.577 6.659 7.577s-3.212-6.66-6.53-6.365c-3.294.294-6.882 6.765-6.882 6.765z"
          fill="#636368"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5299_18237">
          <Path fill="#fff" transform="translate(5.082 15.21)" d="M0 0H110.707V89.7655H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
