import React, { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { AppText, BottomSheetFlatListCustom, EmptyData, ImageCustom } from "components";
import { Colors, Fonts } from "theme";
import { DefaultBank, SearchNotFoundIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";

interface AccountListViewProps {
  dataSource: {
    headerSection?: {
      title: string;
      data: any[];
    };
    mainSection: {
      title: string;
      data: any[];
    };
  };
  handleSelect: (item) => void;
}

const AccountListView: FC<AccountListViewProps> = ({ dataSource: { headerSection, mainSection }, handleSelect }) => {
  const { t } = useTranslation("app/screens/Expense/components/AccountListView");

  const renderItem = (item) => (
    <TouchableOpacity style={styles.transactionItem} onPress={() => handleSelect(item)}>
      <View style={[styles.leftIcon, {}]}>
        {item.logoUrl ? (
          <ImageCustom
            contentFit="contain"
            source={{ uri: item.logoUrl }}
            containerStyle={styles.imageContainer}
            style={styles.avatar}
          />
        ) : (
          <View style={styles.imageContainer}>
            <DefaultBank style={styles.avatar} />
          </View>
        )}
      </View>
      <View style={styles.flex}>
        <View style={styles.row}>
          <AppText ellipsizeMode="head" numberOfLines={1} style={Fonts.BodyMedium}>
            {item?.aliasName || item?.accountHolderName}
          </AppText>
        </View>
        <View style={styles.rowItem}>
          <AppText style={[Fonts.BodySmall, styles.title]} color={Colors.grayscale60} numberOfLines={1}>
            {`${item?.bankShortName || ""} - ${item.accountNumber || ""}`}
          </AppText>
        </View>
      </View>
    </TouchableOpacity>
  );

  const renderHeaderComponent = () => {
    const headers = [];

    if (headerSection && headerSection.data.length) {
      headers.push(
        <>
          <View style={{ paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING }}>
            <AppText style={Fonts.H300}>{headerSection.title}</AppText>
          </View>
          {headerSection.data.map((item) => renderItem(item))}
        </>
      );
    }

    if (mainSection && mainSection.data.length) {
      headers.push(
        <View style={{ paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING }}>
          <AppText style={Fonts.H300}>{mainSection.title}</AppText>
        </View>
      );
    }

    return <>{headers}</>;
  };

  if (!headerSection?.data?.length && !mainSection?.data?.length) {
    return <EmptyData style={styles.emptyImage} icon={<SearchNotFoundIcon />} title={t("search_not_found")} />;
  }

  return (
    <BottomSheetFlatListCustom
      ListHeaderComponent={renderHeaderComponent()}
      keyExtractor={(item) => `${item?.bankId}${item?.accountNumber}${item?.isMine}`}
      data={mainSection.data}
      showsVerticalScrollIndicator={false}
      renderItem={({ item, index }) => {
        const isLastItem = index === mainSection.data.length - 1;
        return (
          <>
            {renderItem(item)}
            <View style={[styles.divider, isLastItem && styles.lastDivider]} />
          </>
        );
      }}
    />
  );
};

export default AccountListView;
const styles = StyleSheet.create({
  emptyImage: {
    marginTop: 120,
  },
  lastDivider: {
    marginBottom: 20,
  },
  flex: { flex: 1 },
  transactionItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  leftIcon: { marginRight: 16 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  title: { flex: 1, paddingRight: 5 },
  // eslint-disable-next-line react-native/no-color-literals
  imageContainer: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    shadowColor: "rgba(0, 0, 0, 0.05)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
    elevation: 4,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 4,
  },
  rowItem: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10, marginTop: 4 },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
});
