import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M9.212 19.5H5.308c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h13.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525h-3.904l-2.155 2.156a.867.867 0 01-.633.27.867.867 0 01-.633-.27L9.212 19.5zM5.308 18h4.511L12 20.18 14.18 18h4.512a.3.3 0 00.221-.087.3.3 0 00.087-.22V4.307a.3.3 0 00-.087-.221.3.3 0 00-.22-.087H5.307a.3.3 0 00-.221.087.3.3 0 00-.087.22v13.385a.3.3 0 00.087.221.3.3 0 00.22.087zm6.64-1.394a.984.984 0 00.724-.296.984.984 0 00.295-.723.984.984 0 00-.295-.724.984.984 0 00-.724-.296.985.985 0 00-.724.296.984.984 0 00-.295.723c0 .286.098.528.295.725a.985.985 0 00.724.295zm1.817-8.452c0 .302-.087.613-.26.932-.174.318-.458.653-.853 1.004-.27.244-.493.475-.668.694a3.727 3.727 0 00-.428.658c-.073.14-.126.278-.16.415-.033.136-.067.288-.1.455a.59.59 0 00.152.5.639.639 0 00.496.213.703.703 0 00.486-.192.946.946 0 00.283-.512c.05-.246.146-.476.288-.688.142-.213.374-.481.697-.804.538-.539.92-1.005 1.146-1.398.226-.394.339-.82.339-1.277 0-.823-.28-1.489-.84-1.997-.561-.509-1.312-.763-2.253-.763-.653 0-1.24.146-1.757.437A3.357 3.357 0 009.085 7.05a.501.501 0 00-.018.49c.08.172.21.29.393.354a.689.689 0 00.505 0 .905.905 0 00.42-.313c.209-.278.454-.493.737-.644a2.02 2.02 0 01.968-.227c.491 0 .893.13 1.206.391.313.26.47.612.47 1.053z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
