import React, { FC, useRef } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { AppText, BottomSheetModalCustomMethods, BottomSheetScrollViewModalCustom, Button } from "components";
import { Colors, Fonts } from "theme";
import { InfoCircleSolidIcon } from "assets/images/svg/icons";
import { getTitleFromCompanyTeam } from "utils";
import { MobileExpenseReportDetailsQuery } from "types";
import TextView from "components/TextView";
import { useTranslation } from "react-i18next";
import Line from "components/Line";

interface AllocatedStatusInfoProps {
  parent?: MobileExpenseReportDetailsQuery["expExpenseReportDetails"]["parent"];
}
const AllocatedStatusInfo: FC<AllocatedStatusInfoProps> = ({ parent }) => {
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
  if (!parent) {
    return null;
  }
  return (
    <View style={styles.container}>
      <TextView
        numberOfLines={1}
        value={parent?.title ? `${parent?.docSequence} - ${parent?.title}` : parent?.docSequence}
        label={t("allocated_from")}
        icon={
          <TouchableOpacity onPress={() => bottomSheetRef.current?.present()}>
            <InfoCircleSolidIcon />
          </TouchableOpacity>
        }
      />
      <BottomSheetScrollViewModalCustom
        title={t("allocated_from")}
        ref={bottomSheetRef}
        containerStyle={styles.modalStyle}
        renderFooter={() => (
          <Button style={styles.footer} type="secondary" onPress={() => bottomSheetRef.current?.close()}>
            {t("close")}
          </Button>
        )}
      >
        <View style={styles.rowItem}>
          <AppText style={styles.title} color={Colors.grayscale60}>
            {t("report")}
          </AppText>
          <AppText style={Fonts.BodyLarge}>
            {parent?.title ? `${parent?.docSequence} - ${parent.title}` : parent?.docSequence}
          </AppText>
        </View>
        <Line />
        <View style={styles.rowItem}>
          <AppText style={styles.title} color={Colors.grayscale60}>
            {t("unit")}
          </AppText>
          <AppText style={Fonts.BodyLarge}>
            {parent?.expenseReportsTeams?.[0]?.companyTeam
              ? getTitleFromCompanyTeam({
                  code: parent.expenseReportsTeams[0].companyTeam.code,
                  name: parent.expenseReportsTeams[0].companyTeam.name,
                  nameEn: parent.expenseReportsTeams[0].companyTeam.nameEn,
                })
              : t("no_value", { label: t("unit").toLowerCase() })}
          </AppText>
        </View>
        <Line />
        <View style={styles.rowItem}>
          <AppText style={styles.title} color={Colors.grayscale60}>
            {t("employee")}
          </AppText>
          <AppText style={Fonts.BodyLarge}>{parent?.companyEmployee?.fullname}</AppText>
        </View>
      </BottomSheetScrollViewModalCustom>
    </View>
  );
};
export default AllocatedStatusInfo;

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
  },
  title: { ...Fonts.Caption, marginBottom: 8 },
  rowItem: { paddingVertical: 8 },
  footer: {
    marginTop: 8,
  },
  modalStyle: { paddingHorizontal: 20, paddingBottom: 90 },
});
