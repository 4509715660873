/* eslint-disable react-native/no-unused-styles */
/* eslint-disable react-native/no-color-literals */

/* eslint-disable react-native/no-inline-styles */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Flex, Toast } from "@ant-design/react-native";
import { useNavigation } from "@react-navigation/native";
import ScreenName from "navigation/ScreenName";
import SCREEN_NAME from "navigation/ScreenName";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Dimensions, Keyboard, Platform, Share, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import QRCode from "react-native-qrcode-svg";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import AntDesign from "react-native-vector-icons/AntDesign";

import {
  AppText,
  BackHeader,
  Button,
  FloatFooter,
  IconCustom,
  Line,
  ToastManager,
  CopilotStepCustom,
} from "app/components";
import { useAuth } from "contexts/AuthContext";
import * as Clipboard from "expo-clipboard";
import { useTranslation } from "react-i18next";

import QRLogo from "assets/images/QRLogo.png";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useIsFeatureEnabled } from "contexts/FeatureManagementContext";
import { CompanyDetailScreenNavigationProp } from "navigation/type";
import { Colors, Fonts, FontTypes } from "theme";
import generateBizziQR, { generateEmployeeVatQR } from "utils/generateBizziQR";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import config from "app/config";
import useUpdateEmployeeProfile from "hooks/ussUpdateProfile";
import { ApolloError } from "@apollo/client";
import { goBack } from "navigation/RootNavigation";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { StatusBarCustom } from "components";
import { CONSTANTS } from "constants/constants";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";

enum PHONE_NUMBER_TYPE {
  HIDE = "hide",
  SHOW = "show",
}

const PHONE_MASK = "• • • • • • • • • •";

const wWidth = Dimensions.get("window").width;
const AnimatedKeyboardAwareScrollView = Animated.createAnimatedComponent(KeyboardAwareScrollView);
const CompanyDetailScreen = ({ route }) => {
  const { shouldShowWalkthrough = false, orderStep = CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step2 } =
    route?.params || {};
  const FEATURE_QR_VAT = useIsFeatureEnabled(FEATURE_FLAGS.FEATURE_QR_VAT);
  const { user, updateUser: updateUserContext } = useAuth();
  const receiveInvoiceEmail = user.expense_email;
  const hasMultipleCompanies = useMemo(() => user?.employees?.length > 1, [user?.employees]);

  const navigation = useNavigation<CompanyDetailScreenNavigationProp>();

  const { t } = useTranslation("screens/CompanyTab/CompanyDetailScreen");
  const translationY = useSharedValue(0);

  const animatedBackgroundStyle = useAnimatedStyle(() => {
    const scale = interpolate(translationY.value, [0, -350], [1, 3], Extrapolation.CLAMP);
    const translateY = interpolate(translationY.value, [0, 400], [0, -400], Extrapolation.CLAMP);
    return {
      transform: [
        {
          scale: scale,
        },
        { translateY },
      ],
    };
  });

  const [inputType, setInputType] = useState({
    name: "visibility-off",
    type: PHONE_NUMBER_TYPE.HIDE,
    color: Colors.grey,
  });
  const [editMail, setEditMail] = useState(false);
  const scrollViewRef = useRef();

  const onnPressIcon = useCallback(() => {
    if (inputType.type === "hide") {
      setInputType({ name: "visibility", type: PHONE_NUMBER_TYPE.SHOW });
    } else {
      setInputType({ name: "visibility-off", type: PHONE_NUMBER_TYPE.HIDE });
    }
  }, [inputType]);

  const onShare = useCallback(
    async (data) => {
      const formatMessage = t("share_message", {
        name: data.name,
        phone: data.phone,
        address: data.address,
        tax_code: data.taxCode,
        staffName: data.staffName,
        receive_invoice_email: data.receiveInvoiceEmail,
      });
      try {
        const result = await Share.share(
          {
            message: formatMessage,
            title: formatMessage,
          },
          {
            subject: formatMessage, // ios
            // url: 'http://nytimes.com',  // ios
            dialogTitle: formatMessage, // Android
          }
        );
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        console.error((error as Error).message);
      }
    },
    [t]
  );

  const { name, taxCode, isOwner, staffName, address, phone, employeeId } = useMemo(() => {
    const {
      is_owner,
      company: { name: companyName, tax_code, address: addressCompany },
    } = user ?? { company: {} };

    return {
      name: companyName,
      taxCode: tax_code,
      isOwner: is_owner,
      address: addressCompany,
      staffName: user?.fullname,
      phone: user?.phone,
      employeeId: user?.employee_id,
    };
  }, [user]);

  const shareData = useMemo(() => {
    return {
      name: name,
      taxCode,
      phone: user.phone,
      address: address,
      staffName: staffName,
      receiveInvoiceEmail,
    };
  }, [name, user.phone, address, taxCode, staffName, receiveInvoiceEmail]);

  const copyText = useCallback(
    async (text) => {
      Clipboard.setString(text);
      Toast.success(t("copied"), 1);
    },
    [t]
  );

  const switchCompany = useCallback(() => {
    navigation.navigate(ScreenName.SwitchCompanyScreen, { shouldBackToTop: false });
  }, [navigation]);
  const { updateEmployeeProfile } = useUpdateEmployeeProfile();
  const [errorEmail, setErrorEmail] = useState("");
  const handleUpdateEmail = async () => {
    try {
      if (!/^[&a-zA-Z0-9+._-]*$/.test(expenseEmail)) {
        setErrorEmail(t("invalid_email"));
        return;
      }
      setErrorEmail("");
      Toast.loading("");
      const object = {
        employeeId: user.employee_id,
        input: {
          expenseEmail: `${expenseEmail.toLowerCase()}@${config.EXPENSE_EMAIL_SUFFIX}`,
        },
      };
      await updateEmployeeProfile(object);
      analyticService.logEvent({ name: EVENT.EMAIL.UPDATED_SUCCESS });
      Toast.removeAll();
      updateUserContext({
        ...user,
        expense_email: `${expenseEmail.toLowerCase()}@${config.EXPENSE_EMAIL_SUFFIX}`,
      });
      setEditMail(false);
      Keyboard.dismiss();
      ToastManager.success(t("update_email_success"));
    } catch (error) {
      analyticService.logEvent({ name: EVENT.EMAIL.UPDATED_FAILED });
      Toast.removeAll();
      if (error instanceof ApolloError) {
        Toast.fail({
          content: error.message,
        });
      }
    }
  };

  const qrCode = useMemo(() => {
    const phoneNumber = inputType.type === PHONE_NUMBER_TYPE.SHOW ? phone : null;

    if (employeeId) {
      return generateEmployeeVatQR({ employeeId, phoneNumber });
    }

    return generateBizziQR({ companyName: name, taxCode, address, receiveInvoiceEmail, phoneNumber });
  }, [name, taxCode, address, receiveInvoiceEmail, phone, inputType.type, employeeId]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          color={FEATURE_QR_VAT ? Colors.white : undefined}
          containerStyle={FEATURE_QR_VAT ? styles.headerStyle : {}}
          headerTitle={t("buyer_company_info")}
          backIcon={<IconCustom name="west" fill={FEATURE_QR_VAT ? Colors.white : undefined} />}
          headerRight={
            Platform.OS !== "web" ? (
              <Flex justify="end">
                <TouchableOpacity
                  onPress={() => {
                    onShare(shareData);
                  }}
                >
                  <IconCustom name="share" fill={FEATURE_QR_VAT ? Colors.white : undefined} />
                </TouchableOpacity>
                {isOwner && (
                  <TouchableOpacity
                    onPress={() => navigation.navigate(SCREEN_NAME.CompanyFormScreen, { type: "edit" })}
                    style={{ marginLeft: 15 }}
                  >
                    <AntDesign name="edit" size={24} color={Colors.whiteBackground} />
                  </TouchableOpacity>
                )}
              </Flex>
            ) : null
          }
        />
      ),
    } as any);
  }, [isOwner, navigation, onShare, shareData, t, FEATURE_QR_VAT]);

  const scrollHandler = useAnimatedScrollHandler((event) => {
    translationY.value = event.contentOffset.y;
  });

  // Expect QR size is 200, but it should be less than or equal to window width
  // Improve to perfect on larger window size
  const qrSize = Math.min(wWidth, 200);

  const hasReceiveInvoiceEmail = Boolean(receiveInvoiceEmail);
  const [expenseEmail, setExpenseEmail] = useState(user?.expense_email?.replace(/(@.+)$/, ""));
  const emailInputRef = useRef();
  const handleOpenEditEmail = () => {
    setEditMail(true);
    setTimeout(() => {
      emailInputRef?.current?.focus();
    }, 100);
  };
  const onConfirmWalkthrough = () => {
    goBack();
  };
  /*-- start walkthrough flow --*/
  const { start, steps, goToNth } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) =>
      steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME && steps[key].order === orderStep
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);
  /*-- end --*/
  const onLayoutContainerChange = () => {
    if (shouldShowWalkthrough) {
      scrollViewRef.current?.scrollToEnd?.(false);
    }
  };

  return (
    <View style={styles.screenContainer} onLayout={onLayoutContainerChange}>
      <StatusBarCustom barStyle={FEATURE_QR_VAT ? "light-content" : "dark-content"} />
      {FEATURE_QR_VAT && <Animated.View style={[styles.background, animatedBackgroundStyle]} />}
      <AnimatedKeyboardAwareScrollView
        contentContainerStyle={styles.scrollContainer}
        scrollEventThrottle={16}
        extraScrollHeight={10}
        onScroll={scrollHandler}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
      >
        {FEATURE_QR_VAT && (
          <View style={styles.qrContainer}>
            <View style={styles.qrBackground} />
            <TouchableOpacity
              style={styles.qrContent}
              onPress={() => navigation.navigate(SCREEN_NAME.BizziQRScreen, { qrCode })}
            >
              <QRCode value={qrCode} size={qrSize} logo={QRLogo} logoSize={32} />
            </TouchableOpacity>
            <AppText style={[Fonts.BodySmall, styles.qrDescription]}>{t("scan_qr_instruction")}</AppText>
          </View>
        )}
        <View style={[styles.ph16, styles.container]}>
          <View style={[styles.section, styles.boxShadow]}>
            <View style={styles.row}>
              <View style={styles.content}>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("company")}
                </AppText>
                <AppText style={Fonts.H300} color={Colors.grayscale100} numberOfLines={2}>
                  {name}
                </AppText>
              </View>
              {hasMultipleCompanies && (
                <TouchableOpacity style={styles.btnSwitchCompany} onPress={switchCompany}>
                  <IconCustom name="autorenew" fill={Colors.grayscale60} />
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={styles.btnCopy}
                onPress={() => {
                  copyText(name);
                }}
              >
                <IconCustom name="content-copy" fill={Colors.grayscale60} />
              </TouchableOpacity>
            </View>
            <View style={styles.separator} />
            <View style={styles.row}>
              <View style={styles.content}>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("tax_code")}
                </AppText>
                <AppText style={Fonts.H300} color={Colors.grayscale100}>
                  {taxCode}
                </AppText>
              </View>
              <TouchableOpacity
                style={styles.btnCopy}
                onPress={() => {
                  copyText(taxCode);
                }}
              >
                <IconCustom name="content-copy" fill={Colors.grayscale60} />
              </TouchableOpacity>
            </View>
            <View style={styles.separator} />
            <View style={styles.row}>
              <View style={styles.content}>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("address")}
                </AppText>
                <AppText style={Fonts.H300} color={Colors.grayscale100}>
                  {address}
                </AppText>
              </View>
              <TouchableOpacity
                style={styles.btnCopy}
                onPress={() => {
                  copyText(address);
                }}
              >
                <IconCustom name="content-copy" fill={Colors.grayscale60} />
              </TouchableOpacity>
            </View>
            <Line />
            <CopilotStepCustom
              title={"walkthrough_email_title_step2"}
              text={"walkthrough_email_description_step2"}
              order={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step2}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME}
              style={styles.row}
              activeStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step2}
              totalStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.TotalStep}
              autoScroll={true}
              onBack={() => {
                goBack();
                setTimeout(() => {
                  goToNth(1);
                }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
              }}
            >
              <View style={styles.content}>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("email_to_receive_invoice")}
                </AppText>
                {editMail ? (
                  <>
                    <View style={styles.emailInputContainer}>
                      <TextInput
                        ref={emailInputRef}
                        textAlignVertical="top"
                        onChangeText={setExpenseEmail}
                        style={{ height: 24, fontFamily: FontTypes.bold, fontSize: 16, flex: 1 }}
                        value={expenseEmail}
                      />
                      <AppText
                        style={{ fontFamily: FontTypes.bold, fontSize: 16 }}
                        color={Colors.grayscale60}
                      >{`@${config.EXPENSE_EMAIL_SUFFIX}`}</AppText>
                    </View>
                    {Boolean(errorEmail) && (
                      <AppText style={Fonts.BodySmall} color={Colors.alert50}>
                        {errorEmail}
                      </AppText>
                    )}
                  </>
                ) : hasReceiveInvoiceEmail ? (
                  <AppText style={Fonts.H300} color={Colors.grayscale100}>
                    {receiveInvoiceEmail}
                  </AppText>
                ) : (
                  <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
                    {t("no_receive_invoice_email")}
                  </AppText>
                )}
              </View>
              {editMail ? (
                <Button
                  eventName={EVENT.EMAIL.UPDATED}
                  style={{ paddingHorizontal: 10 }}
                  size="small"
                  onPress={handleUpdateEmail}
                >
                  {t("save")}
                </Button>
              ) : (
                <CopilotStepCustom
                  title={"walkthrough_email_title_step3"}
                  text={"walkthrough_email_description_step3"}
                  order={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step3}
                  image={BizziBotSticker01}
                  walkthroughName={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME}
                  borderRadius={3}
                  confirmText={t("continue")}
                  onConfirm={onConfirmWalkthrough}
                  activeStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step3}
                  totalStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.TotalStep}
                >
                  <TouchableOpacity onPress={handleOpenEditEmail}>
                    <IconCustom name="edit-square" fill={Colors.grayscale60} />
                  </TouchableOpacity>
                </CopilotStepCustom>
              )}

              {hasReceiveInvoiceEmail && !editMail && (
                <TouchableOpacity
                  style={styles.btnCopy}
                  onPress={() => {
                    copyText(receiveInvoiceEmail);
                  }}
                >
                  <IconCustom name="content-copy" fill={Colors.grayscale60} />
                </TouchableOpacity>
              )}
            </CopilotStepCustom>
          </View>

          <View style={[styles.section, styles.boxShadow, { marginTop: 8 }]}>
            <View style={styles.row}>
              <View style={styles.content}>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("full_name")}
                </AppText>
                <AppText style={Fonts.H300} color={Colors.grayscale100}>
                  {staffName}
                </AppText>
              </View>
            </View>
            <Line />
            <View style={styles.row}>
              <View style={styles.content}>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                  {t("phone_number")}
                </AppText>
                {user?.phone ? (
                  <AppText style={Fonts.H300} color={Colors.grayscale100}>
                    {inputType.type === PHONE_NUMBER_TYPE.SHOW ? user.phone : PHONE_MASK}
                  </AppText>
                ) : (
                  <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale60}>
                    {t("no_value", { label: t("phone_number").toLowerCase() })}
                  </AppText>
                )}
              </View>
              <View>
                {Boolean(user?.phone) && (
                  <TouchableOpacity onPress={onnPressIcon}>
                    <IconCustom name={inputType.name} fill={Colors.grayscale60} width={20} height={20} />
                  </TouchableOpacity>
                )}
                {/*Boolean(user?.phone) && (
                  <Icon
                    containerStyle={styles.icon}
                    size={18}
                    type="FontAwesome"
                    name={inputType.name}
                    color={Colors.grayscale60}
                    onPress={onnPressIcon}
                  />
                )*/}
              </View>
            </View>
          </View>
        </View>
      </AnimatedKeyboardAwareScrollView>
      <FloatFooter>
        <Button
          eventName={EVENT.EXPENSE.TAP_CREATE}
          onPress={() => {
            navigation.navigate(SCREEN_NAME.NewExpenseScreen);
          }}
          uppercase={false}
          mode="contained"
        >
          <AppText style={Fonts.BodyLarge} color={Colors.white}>
            {t("create_expense")}
          </AppText>
        </Button>
      </FloatFooter>
    </View>
  );
};

const styles = StyleSheet.create({
  headerStyle: {
    backgroundColor: Colors.brand100,
  },
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  scrollContainer: {
    paddingBottom: 100,
  },
  p10: {
    padding: 10,
  },
  container: {
    paddingTop: 16,
  },
  icon: {
    marginLeft: 10,
  },
  label: {
    color: "#475569",

    fontSize: 14,
    lineHeight: 20,
  },
  header: {
    height: 80,
    backgroundColor: "#0089FF",
  },
  boxShadow: {
    backgroundColor: Colors.white,
    borderRadius: 16,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
  },
  section: {
    backgroundColor: "white",
    borderRadius: 16,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  content: { flex: 1 },
  ph16: { paddingHorizontal: 16 },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#E2E8F0",
  },
  wrapperCompanyName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 0,
  },
  qrContainer: {
    width: "100%",
    alignItems: "center",
    backgroundColor: Colors.white,
    paddingTop: 16,
  },
  qrContent: {
    padding: 8,
    borderColor: Colors.grayscale05,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderRadius: 4,
  },
  qrDescription: {
    marginTop: 6,
    color: Colors.grayscale60,
  },
  qrBackground: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: 100,
    backgroundColor: Colors.brand100,
  },
  background: {
    backgroundColor: Colors.brand100,
    width: "100%",
    position: "absolute",
    height: 180,
  },
  btnCopy: {
    marginLeft: 12,
  },
  btnSwitchCompany: {
    marginLeft: 10,
  },
  btnUpdate: {
    paddingHorizontal: 14,
  },
  emailInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    marginRight: 8,
  },
});

export default CompanyDetailScreen;
