import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileCrdCardTransactionQuery, MobileCrdCardTransactionQueryVariables } from "types";

export const QUERY_TRANSACTION_DETAIL = gql`
  query MobileCrdCardTransaction($cardTransactionId: UUID!, $companyId: UUID!) {
    crdCardTransaction(input: { cardTransactionId: $cardTransactionId, companyId: $companyId }) {
      assignable
      amount
      updatedAt
      transactionType
      transactionId
      transactionDate
      status
      cardId
      cardTransactionId
      companyId
      createdAt
      groupId
      location
      mcc
      refCode
      employee {
        fullname
        employeeId
      }
      card {
        cardholderName
        prefixCardNumber
        suffixCardNumber
      }
      unreconciledAmount
      cardTransactionExpenses {
        expense {
          docSequence
          status
          expenseDate
          expenseReport {
            status
          }
          companyId
          employeeId
          expenseId
          groupId
          note
          totalAmountWithVat
          totalAmountWithoutVat
        }
      }
    }
  }
`;
const useTransactionDetailQuery = (variables: MobileCrdCardTransactionQueryVariables) => {
  return useQuery<MobileCrdCardTransactionQuery, MobileCrdCardTransactionQueryVariables>(QUERY_TRANSACTION_DETAIL, {
    variables,
    fetchPolicy: "network-only",
  });
};

export default useTransactionDetailQuery;
