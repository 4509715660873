import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.5 19.538c-.412 0-.766-.146-1.06-.44a1.445 1.445 0 01-.44-1.06c0-.412.147-.765.44-1.059.294-.294.648-.44 1.06-.44.412 0 .766.146 1.06.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.059-.294.294-.648.44-1.06.44zm0-5.769c-.412 0-.766-.147-1.06-.44a1.444 1.444 0 01-.44-1.06c0-.412.147-.765.44-1.06.294-.293.648-.44 1.06-.44.412 0 .766.147 1.06.44.293.295.44.648.44 1.06 0 .413-.147.766-.44 1.06-.294.293-.648.44-1.06.44zm0-5.77c-.412 0-.766-.146-1.06-.44A1.444 1.444 0 0111 6.5c0-.413.147-.766.44-1.06.294-.293.648-.44 1.06-.44.412 0 .766.147 1.06.44.293.294.44.647.44 1.06 0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44z"
          fill="#0F172A"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
