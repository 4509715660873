import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, fill = "#0F172A" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.003 15.577c1.133 0 2.095-.397 2.887-1.19.792-.793 1.188-1.756 1.188-2.89 0-1.132-.397-2.095-1.19-2.886-.793-.792-1.756-1.188-2.89-1.188-1.132 0-2.095.397-2.887 1.19-.791.793-1.187 1.756-1.187 2.89 0 1.132.396 2.095 1.19 2.886.793.792 1.756 1.188 2.89 1.188zm-.002-1.377c-.75 0-1.388-.262-1.913-.787a2.604 2.604 0 01-.787-1.913c0-.75.262-1.387.787-1.913a2.604 2.604 0 011.913-.787c.75 0 1.387.262 1.912.787.525.526.788 1.163.788 1.913s-.263 1.387-.788 1.912a2.604 2.604 0 01-1.912.788zm0 4.3c-2.105 0-4.03-.562-5.776-1.685a12.573 12.573 0 01-4.18-4.432 1.757 1.757 0 01-.183-.434 1.753 1.753 0 01.183-1.332 12.573 12.573 0 014.18-4.432C7.97 5.062 9.895 4.5 12 4.5c2.105 0 4.03.562 5.776 1.685a12.572 12.572 0 014.18 4.432 1.753 1.753 0 010 1.765 12.572 12.572 0 01-4.18 4.433C16.03 17.938 14.106 18.5 12 18.5zm0-1.5a9.545 9.545 0 005.187-1.488 9.773 9.773 0 003.613-4.012 9.772 9.772 0 00-3.613-4.013A9.545 9.545 0 0012.001 6a9.545 9.545 0 00-5.188 1.487A9.773 9.773 0 003.201 11.5a9.773 9.773 0 003.612 4.012A9.545 9.545 0 0012.001 17z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
