import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#D9D9D9" d="M0.784567 0.623432H24.784567V24.623432H0.784567z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.015 14.123c-.412 0-.765-.146-1.059-.44a1.444 1.444 0 01-.44-1.06c0-.412.146-.765.44-1.059.294-.294.647-.44 1.06-.44.412 0 .765.146 1.059.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.059-.294.293-.647.44-1.06.44zm5.77 0c-.413 0-.766-.146-1.06-.44a1.444 1.444 0 01-.44-1.06c0-.412.146-.765.44-1.059.294-.294.647-.44 1.06-.44.412 0 .765.146 1.059.44.294.294.44.647.44 1.06 0 .412-.146.765-.44 1.059-.294.293-.647.44-1.06.44zm5.769 0c-.413 0-.766-.146-1.06-.44a1.445 1.445 0 01-.44-1.06c0-.412.147-.765.44-1.059.294-.294.647-.44 1.06-.44.412 0 .765.146 1.06.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.059-.295.293-.648.44-1.06.44z"
          fill="#1C1B1F"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
