import React, { FC, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { Circle, G, Svg, Text as TextSVG } from "./SvgWeb";
import Animated, { useSharedValue, withTiming } from "react-native-reanimated";
import { AppText } from "components";
import { Colors, Fonts } from "theme";

export type PieChartDataItem = {
  color: string;
  textColor: string;
  percent: number;
  showPercent?: boolean;
};

export type PieChartData = PieChartDataItem[];
const degreesToRadians = (angle) => {
  return angle * (Math.PI / 180);
};
const calculateTextCoords = (percent, radius, cx, cy, angleOffset) => {
  const angle = (percent * 360) / 2 + angleOffset;
  const radians = degreesToRadians(angle);
  const textCoords = {
    x: radius * Math.cos(radians) + cx,
    y: radius * Math.sin(radians) + cy,
  };
  return textCoords;
};
export const PieChartSegment: FC<{
  center: number;
  radius: number;
  strokeWidth: number;
  color: string;
  textColor: string;
  circumference: number;
  angle: number;
  percent: number;
  progress: Animated.SharedValue<number>;
  index: number;
  showPercent: boolean;
}> = ({ center, radius, strokeWidth, circumference, color, textColor, angle, percent, showPercent = true }) => {
  const { x, y } = calculateTextCoords(percent, radius, center, center, angle);
  return (
    <Svg>
      <G transform={`rotate(${angle}, 90, 90)`}>
        <Circle
          fill="transparent"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          stroke={color}
          strokeDasharray={`${circumference}`}
          strokeDashoffset={`${circumference * (1 - percent)}`}
          originX={center}
          originY={center}
          textAnchor="middle"
        />
      </G>
      {percent > 0.04 && showPercent && (
        <G>
          <TextSVG
            fontSize="12"
            x={x}
            y={y}
            dy="3"
            textAnchor="middle"
            transform={`rotate(${90}, ${x}, ${y})`}
            fill={textColor}
            fontFamily="Inter-Bold"
          >
            {`${Math.round(percent * 100)}%`}
          </TextSVG>
        </G>
      )}
    </Svg>
  );
};

type PieChartProps = {
  totalTitle?: string;
  total?: string;
  size?: number;
  strokeWidth?: number;
  data?: PieChartDataItem[];
  refreshing?: boolean;
};
const PieChart: FC<PieChartProps> = ({ totalTitle, total, size = 180, strokeWidth = 35, data, refreshing }) => {
  const progress = useSharedValue(0);
  const [chartData, setChartData] = React.useState<PieChartData>([]);
  const [startAngles, setStartAngles] = React.useState<number[]>([]);
  const center = size / 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  const refresh = () => {
    const generatedData = [...data];

    let angle = 0;
    const angles: number[] = [];
    generatedData.forEach((item) => {
      angles.push(angle);
      angle += item.percent * 360;
    });

    setChartData(generatedData);
    setStartAngles(angles);

    progress.value = 0;
    progress.value = withTiming(1, {
      duration: 1000,
    });
  };

  useEffect(() => {
    if (data && (!chartData?.length || !refreshing)) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refreshing]);

  return (
    <View style={[{ width: size, height: size }, styles.rotate]}>
      <View
        style={{
          position: "absolute",
          width: size,
          height: size,
          justifyContent: "center",
          alignItems: "center",
          transform: [{ rotateZ: "90deg" }],
        }}
      >
        <AppText style={Fonts.H200} color={Colors.primary100}>
          {totalTitle}
        </AppText>
        <AppText style={Fonts.NumericN200}>{total}</AppText>
      </View>
      <Svg viewBox={`0 0 ${size} ${size}`}>
        {chartData.map((item, index) => (
          <PieChartSegment
            showPercent={item?.showPercent}
            index={index}
            key={`${item.color}-${index}`}
            center={center}
            radius={radius}
            circumference={circumference}
            angle={startAngles[index]}
            color={item.color}
            textColor={item.textColor}
            percent={item.percent}
            strokeWidth={strokeWidth}
            progress={progress}
          />
        ))}
      </Svg>
    </View>
  );
};
export default PieChart;
const styles = StyleSheet.create({
  rotate: {
    transform: [{ rotateZ: "-90deg" }],
  },
});
