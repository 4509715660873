import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

function SvgComponent({ width = 56, height = 56, color = "#D181E4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 56 56" fill="none">
      <Rect width={width} height={height} rx={28} fill={color} />
      <Path
        d="M34.667 16.668H21.334c-4 0-6.667 2-6.667 6.667v9.333c0 4.667 2.667 6.667 6.667 6.667h13.333c4 0 6.667-2 6.667-6.667v-9.333c0-4.667-2.667-6.667-6.667-6.667zm.627 8.12l-4.174 3.333c-.88.707-2 1.054-3.12 1.054s-2.253-.347-3.12-1.054l-4.173-3.333a1.026 1.026 0 01-.16-1.413.997.997 0 011.4-.16l4.173 3.333c1.014.813 2.734.813 3.747 0l4.173-3.333a.984.984 0 011.4.16c.347.426.28 1.066-.146 1.413z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
