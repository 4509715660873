import React, { FC } from "react";
import { Platform } from "react-native";
import { DatePickerModal } from "react-native-paper-dates";
import dayjs from "dayjs";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";
import { Portal } from "@ant-design/react-native";
import MonthPicker from "react-native-month-year-picker";

const MonthPickerCustom: FC<any> = ({
  okButton,
  cancelButton,
  maximumDate,
  minimumDate,
  label,
  locale,
  visible,
  value,
  setVisible,
  setValue,
}) => {
  return (
    <DatePickerModal
      locale={locale}
      mode="single"
      visible={visible}
      date={value ? dayjs(value).toDate() : dayjs().toDate()}
      onDismiss={() => setVisible(false)}
      onConfirm={(date) => {
        setVisible(false);
        setValue(date?.date);
      }}
      label={label}
    />
  );
};
export default MonthPickerCustom;
