import * as React from "react";
import Svg, { Circle, Mask, Path, Rect } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg width={273} height={123} viewBox="0 0 273 123" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0991 12.8721C90.0991 5.76304 95.8621 0 102.971 0H260.127C267.236 0 273 5.76304 273 12.8721C273 19.9812 267.237 25.7442 260.127 25.7442H201.96C204.876 28.1041 206.741 31.7122 206.741 35.7559C206.741 42.8089 201.068 48.5369 194.037 48.627H230.982C238.091 48.627 243.854 54.39 243.854 61.4991C243.854 68.6082 238.091 74.3712 230.982 74.3712H77.4051C84.4404 74.2854 90.1171 68.5556 90.1171 61.5C90.1171 54.391 84.354 48.6279 77.2449 48.6279H36.6612C29.5756 48.6004 23.8401 42.848 23.8401 35.7559C23.8401 28.6468 29.6032 22.8838 36.7122 22.8838H94.8799C91.9636 20.524 90.0991 16.9158 90.0991 12.8721ZM0 87.245C0 80.1359 5.76305 74.3729 12.8721 74.3729H196.536C197.169 74.3729 197.791 74.4186 198.4 74.5068H191.341C185.032 74.5068 179.917 79.6217 179.917 85.9312C179.917 92.2407 185.032 97.3556 191.341 97.3556H214.043C220.392 98.1478 225.305 103.564 225.305 110.128C225.305 117.237 219.542 123 212.433 123H73.8316C66.7225 123 60.9594 117.237 60.9594 110.128C60.9594 106.085 62.8236 102.477 65.7393 100.117H12.8721C5.76306 100.117 0 94.3541 0 87.245Z"
      fill="#F0F6FF"
    />
    <Circle cx={138} cy={52} r={32} fill="#F0F6FF" />
    <Circle cx={138} cy={52} r={27} fill="white" />
    <Path
      d="M170.625 51.7712C170.625 69.9274 155.927 84.6253 137.771 84.6253C119.615 84.6253 104.917 69.9274 104.917 51.7712C104.917 33.6149 119.615 18.917 137.771 18.917"
      stroke="#1F71F4"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Mask id="path-5-inside-1_6274_394974" fill="white">
      <Path d="M156.699 23.8325C157.252 22.9762 158.399 22.7264 159.222 23.3269C165.037 27.5644 169.3 33.5963 171.355 40.4915C171.646 41.4685 171.028 42.4658 170.037 42.7017V42.7017C169.045 42.9375 168.055 42.3223 167.757 41.3473C165.931 35.3761 162.235 30.1467 157.215 26.4333C156.395 25.8269 156.146 24.6888 156.699 23.8325V23.8325Z" />
    </Mask>
    <Path
      d="M156.699 23.8325C157.252 22.9762 158.399 22.7264 159.222 23.3269C165.037 27.5644 169.3 33.5963 171.355 40.4915C171.646 41.4685 171.028 42.4658 170.037 42.7017V42.7017C169.045 42.9375 168.055 42.3223 167.757 41.3473C165.931 35.3761 162.235 30.1467 157.215 26.4333C156.395 25.8269 156.146 24.6888 156.699 23.8325V23.8325Z"
      stroke="#1F71F4"
      strokeWidth={6}
      strokeLinejoin="round"
      mask="url(#path-5-inside-1_6274_394974)"
    />
    <Path d="M162.5 77L167 81.5" stroke="#1F71F4" strokeWidth={3} strokeLinecap="round" />
    <Rect
      x={165.448}
      y={85.5723}
      width={7.91665}
      height={19.9685}
      rx={3.95833}
      transform="rotate(-45 165.448 85.5723)"
      stroke="#1F71F4"
      strokeWidth={3}
    />
    <Circle opacity={0.3} cx={138} cy={52} r={26.5} stroke="#75A4FE" strokeWidth={3} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M151.449 72.2611C148.982 71.7316 146.553 70.8096 144.248 69.4721C132.459 62.6286 128.405 47.4978 135.193 35.6765C137.141 32.2845 139.769 29.5336 142.807 27.4968C132.648 25.3169 121.832 29.7878 116.37 39.2988C109.582 51.1201 113.637 66.2509 125.426 73.0944C133.832 77.9743 143.902 77.3228 151.449 72.2611Z"
      fill="#F0F6FF"
    />
    <Path d="M91 90H106" stroke="#75A4FE" strokeWidth={3} strokeLinecap="round" />
    <Path d="M80 82L106 82" stroke="#75A4FE" strokeWidth={3} strokeLinecap="round" />
    <Path d="M67 82H73.5" stroke="#75A4FE" strokeWidth={3} strokeLinecap="round" />
    <Path d="M192 29.5H181" stroke="#75A4FE" strokeWidth={3} strokeLinecap="round" />
    <Path d="M204 21H178.5" stroke="#75A4FE" strokeWidth={3} strokeLinecap="round" />
    <Path d="M216 21H209.5" stroke="#75A4FE" strokeWidth={3} strokeLinecap="round" />
  </Svg>
);
export default SVGComponent;
