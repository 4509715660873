import { checkForUpdateAsync, fetchUpdateAsync } from "expo-updates";
import { NewManifest } from "expo-manifests";
import { some } from "lodash";
import app from "../../app.json";
import Config from "app/config";
import { checkFirstLaunch } from "utils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";

type AppVersionJson = {
  major: number;
  minor: number;
  patch: number;
};
const getVersionInJsonFormat = (version: string): AppVersionJson | null => {
  if (!version) {
    return null;
  }
  const parts = version.split(".");
  if (some(parts, (part) => isNaN(+part))) {
    return null;
  }

  const [major, minor, patch] = parts;
  return { major: +major, minor: +minor, patch: +patch };
};

export enum UpdateStatusCode {
  UpdatedSuccess = "UpdatedSuccess",
  NotAvailable = "NotAvailable",
  Error = "Error",
}

type UpdateAppResult =
  | {
      status: UpdateStatusCode.UpdatedSuccess;
      shouldNotifyChange: boolean;
    }
  | { status: UpdateStatusCode.NotAvailable }
  | { status: UpdateStatusCode.Error };

const OtaService = () => {
  const checkForNotifyChange = async (inComingVersion?: string): Promise<boolean> => {
    // in the case UAT app: always show notify update modal
    if (!Config.IS_PRODUCTION) {
      return true;
    }
    const isFirstLaunch = await checkFirstLaunch();
    /*
     Notify user to update app if the difference in minor version between the current app version and OTA app version is detected,
     and if it's not the first installation of the app.
    */
    const currentMinorVersion = getVersionInJsonFormat(app.expo.version)?.minor;
    const otaMinorVersion = getVersionInJsonFormat(inComingVersion)?.minor;
    if (currentMinorVersion !== otaMinorVersion && !isFirstLaunch) {
      return true;
    }
    return false;
  };

  const updateApp = async (): Promise<UpdateAppResult> => {
    try {
      const result = await checkForUpdateAsync();
      if (result.isAvailable) {
        await fetchUpdateAsync();
        const inComingVersion = (result.manifest as NewManifest).extra?.expoClient?.version;
        const shouldNotifyChange = await checkForNotifyChange(inComingVersion);
        AsyncStorage.setItem(Keys.IS_FIRST_LAUNCH, "false");
        return { status: UpdateStatusCode.UpdatedSuccess, shouldNotifyChange };
      }
      AsyncStorage.setItem(Keys.IS_FIRST_LAUNCH, "false");
      return { status: UpdateStatusCode.NotAvailable };
    } catch (error) {
      console.error("OTA app failed:", error);
      return { status: UpdateStatusCode.Error };
    }
  };

  return { updateApp };
};

export default OtaService();
