import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M2.25 18.5a.725.725 0 01-.535-.216.726.726 0 01-.215-.534V5.25c0-.213.072-.39.216-.534A.726.726 0 012.25 4.5c.213 0 .391.072.535.216A.726.726 0 013 5.25V14h8.25V8c0-.409.148-.761.443-1.057A1.443 1.443 0 0112.75 6.5H19c.962 0 1.786.343 2.472 1.028.685.686 1.028 1.51 1.028 2.472v7.75c0 .212-.072.39-.216.534a.726.726 0 01-.534.216.725.725 0 01-.535-.216.726.726 0 01-.215-.534V15.5H3v2.25c0 .212-.072.39-.216.534a.726.726 0 01-.534.216zm4.749-5.942c-.71 0-1.315-.25-1.811-.747A2.47 2.47 0 014.442 10c0-.71.25-1.315.747-1.812A2.47 2.47 0 017 7.442c.71 0 1.315.25 1.811.747A2.47 2.47 0 019.558 10c0 .71-.25 1.315-.747 1.812A2.47 2.47 0 017 12.557zM12.75 14H21v-4c0-.55-.196-1.02-.587-1.413A1.926 1.926 0 0019 8h-6.25v6zM7 11.058c.296 0 .546-.103.751-.307.204-.204.307-.455.307-.751s-.103-.546-.307-.751A1.021 1.021 0 007 8.942c-.296 0-.546.103-.751.307a1.021 1.021 0 00-.307.751c0 .296.103.546.307.751.205.204.455.307.751.307z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
