import { BookingInformation } from "screens/FlightBooking/types";

const getListFaresFromBookingInfo = (
  bookingInfo: BookingInformation,
  statusBooking
): BookingInformation["listBooking"][0]["fares"] => {
  const fares: BookingInformation["listBooking"][0]["fares"] = [];
  bookingInfo?.listBooking?.forEach((item) => {
    item?.fares?.forEach((fare) => {
      const baggage = item?.passengers?.[0]?.baggage?.filter(
        (i) => i?.startPoint === fare?.flights?.[0]?.departure?.code
      );
      fare.bookingInfo = {
        bookingCode: item.bookingCode,
        baggage,
        status: statusBooking,
        price: fare.totalPrice,
      };
    });
    fares.push(...item?.fares);
  });
  return fares;
};

export { getListFaresFromBookingInfo };
