import { AppText, IconCustom, TextView } from "components";
import { CONSTANTS } from "constants/constants";
import { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import WeirdTextView from "./WeirdTextView";
import { calculateDurationInDays, formatTime, getTitleFromLocation } from "utils";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { getTransformedData, HotelReservationData } from "./mappingUtils";
import PolicyNote, { FlightBookingPolicy, HotelPolicy, TransportationPolicy } from "./PolicyNote";
import RequestStatus from "constants/RequestStatus";
import { useMasterData } from "contexts/MasterDataContext";

export enum ReservationTypes {
  Hotel = "ExpHotelBooking",
}

type HotelReservationSectionProps = {
  title: string;
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
};

const HotelReservationSection: FC<HotelReservationSectionProps> = ({ title, expenseRequest }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const {
    setting: { request: requestSetting },
  } = useMasterData();
  const bookingInfo = useMemo<ReturnType<typeof getTransformedData>>(() => {
    const expenseTravels = expenseRequest?.expenseTravels ?? [];
    const expenseBookings = expenseTravels.flatMap((expenseTravel) => expenseTravel.expenseBookings);

    const bookingMatchType = expenseBookings.find(
      (booking) => booking.bookingSettings.__typename === ReservationTypes.Hotel
    );
    if (!bookingMatchType) {
      return null;
    }

    return getTransformedData(bookingMatchType);
  }, [expenseRequest]);

  if (!bookingInfo) {
    return null;
  }

  const bookingSettings = bookingInfo?.bookingSettings as HotelReservationData;
  const bookingPolicies = bookingInfo?.bookingPolicies;
  const durationOfStay = calculateDurationInDays(bookingSettings.checkInDate, bookingSettings.checkOutDate);
  return (
    <View style={styles.container}>
      <AppText style={[Fonts.SentenceSubtitleLarge, styles.mb16]}>{title}</AppText>
      <View style={styles.body}>
        <WeirdTextView
          label={t("location_to")}
          primaryContent={getTitleFromLocation(bookingSettings?.location)}
          icon={<IconCustom name="hotel" />}
        />
        {requestSetting?.hotelBooking?.withHotelInfo && (
          <>
            <TextView
              label={t("hotel_type")}
              value={bookingSettings?.companyHotelCode ? t("hotel_internal") : t("hotel_external")}
            />
            <TextView label={t("hotel_name")} value={bookingSettings?.hotelName} />
            <TextView isMoney suffix="" label={t("number_of_rooms")} value={bookingSettings?.numberOfRooms} />
            <TextView isMoney label={t("room_rate")} value={bookingSettings?.roomRate} />
          </>
        )}
        <TextView
          label={
            durationOfStay > 1
              ? t("duration_of_stays", { days: durationOfStay })
              : t("duration_of_stay", { days: durationOfStay })
          }
          value={`${formatTime(bookingSettings.checkInDate, "ddd, DD/MM/YYYY")} - ${formatTime(
            bookingSettings.checkOutDate,
            "ddd, DD/MM/YYYY"
          )}`}
        />
        {Boolean(bookingSettings.note) && <TextView label={t("note")} value={bookingSettings.note} />}
      </View>
      {expenseRequest.status === RequestStatus.APPROVED && (
        <PolicyNote
          title={t("employee_policy")}
          data={bookingPolicies as FlightBookingPolicy | TransportationPolicy | HotelPolicy}
        />
      )}
    </View>
  );
};

export default memo(HotelReservationSection);

const styles = StyleSheet.create({
  mb16: {
    marginBottom: 16,
  },
  container: {
    marginTop: 8,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  body: {
    gap: 8,
  },
});
