/* eslint-disable react-native/no-inline-styles */
import { StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import {
  AppText,
  BackHeader,
  BarChartCompareHorizontal,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  CurrencyText,
  Line,
  LoadingView,
  PercentLabel,
} from "components";
import React, { FC, useLayoutEffect, useState, useRef, useEffect, useMemo, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import ExpenseChart from "./ExpenseChart";
import usePersonalExpenseInsights from "screens/Home/hooks/usePersonalExpenseInsights";
import usePersonalExpenseSpendingTrend from "screens/Home/hooks/usePersonalExpenseSpendingTrend";
import { ExpMobileSpendingTrendQuery } from "types";
import { formatMoney, getTitleFromCategory } from "utils";

const NUMBER_OF_MONTH = 6;

type SpendingCategorySectionProps = {
  selectedIndex: number;
};

const SpendingCategorySection: FC<SpendingCategorySectionProps> = memo(({ selectedIndex }) => {
  const { t } = useTranslation("app/screens/ExpenseInsight/ExpenseInsightScreen");
  const filter = useMemo(() => {
    const selectedMonth = dayjs().subtract(NUMBER_OF_MONTH - (selectedIndex + 1), "month");
    return {
      fromDate: selectedMonth.startOf("month").toISOString(),
      toDate: selectedMonth.endOf("month").toISOString(),
      compareWith: {
        fromDate: selectedMonth.subtract(1, "month").startOf("month").toISOString(),
        toDate: selectedMonth.subtract(1, "month").endOf("months").toISOString(),
      },
    };
  }, [selectedIndex]);
  const { loading, data } = usePersonalExpenseSpendingTrend(filter);
  const [selectedCategory, setSelectedCategory] =
    useState<ExpMobileSpendingTrendQuery["expMobileSpendingTrend"][0]>(null);
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

  const chartData = useMemo(() => {
    return data.reduce((acc, category) => {
      if (category.spendingAmount === 0) {
        return acc;
      }
      acc.push({
        title: getTitleFromCategory({ title: category.title, titleEn: category.titleEn }),
        thisMonthAmount: category.spendingAmount,
        prevMonthAmount: category.spendingAmountToCompare,
        diffPercentage: category.diffPercentage,
        metadata: category,
      });
      return acc;
    }, []);
  }, [data]);

  const handleChartItemPress = ({ categoryId }) => {
    bottomSheetRef.current?.present();
    const category = data.find((x) => x.categoryId === categoryId);
    setSelectedCategory(category);
  };

  const renderModalContent = () => {
    if (!selectedCategory) {
      return null;
    }

    const { spendingPlanAmount, spendingAmount } = selectedCategory;
    const isOverSpendingPlan = spendingAmount > spendingPlanAmount;
    return (
      <View style={styles.chartModalBody}>
        <View style={[styles.flexRow, styles.spaceBetween]}>
          <AppText style={Fonts.BodyMedium}>{t("planned_spend")}</AppText>
          <CurrencyText style={Fonts.NumericN200}>{spendingPlanAmount}</CurrencyText>
        </View>
        <View>
          <View style={[styles.flexRow, styles.spaceBetween]}>
            <AppText style={Fonts.BodyMedium}>{t("approved_expense")}</AppText>
            <CurrencyText style={Fonts.NumericN200} color={isOverSpendingPlan ? Colors.alert50 : Colors.grayscale100}>
              {spendingAmount}
            </CurrencyText>
          </View>

          {isOverSpendingPlan && (
            <View style={styles.overSpendingRow}>
              <AppText style={Fonts.BodyXSmall} color={Colors.alert50}>
                {t("over_spending", {
                  amount: formatMoney(spendingAmount - spendingPlanAmount),
                })}
              </AppText>
            </View>
          )}
        </View>
      </View>
    );
  };

  const renderChart = () => {
    if (loading) {
      return <LoadingView style={{ height: 100, backgroundColor: Colors.white }} />;
    }

    if (!chartData.length) {
      return (
        <View style={[styles.flexRow, { justifyContent: "center" }]}>
          <AppText style={Fonts.SentenceBodyMedium} color={Colors.grayscale60}>
            {t("no_data")}
          </AppText>
        </View>
      );
    }

    return (
      <>
        <View style={styles.gap16}>
          <View style={[styles.flexRow, styles.gap16]}>
            <View style={styles.dotRow}>
              <View style={[styles.dotIcon, { backgroundColor: Colors.primary50 }]} />
              <AppText style={Fonts.BodySmall}>
                {`${t("expenses")} ${filter?.fromDate ? dayjs(filter.fromDate).format("MM/YYYY") : ""}`}
              </AppText>
            </View>
            <View style={styles.dotRow}>
              <View style={[styles.dotIcon, { backgroundColor: Colors.grayscale05 }]} />
              <AppText style={Fonts.BodySmall}>{`${t("expenses")} ${
                filter?.compareWith.fromDate ? dayjs(filter.compareWith.fromDate).format("MM/YYYY") : ""
              }`}</AppText>
            </View>
          </View>
          <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
            {t("touch_to_show_details")}
          </AppText>
        </View>
        <BarChartCompareHorizontal showPercent={true} onItemPress={handleChartItemPress} data={chartData} />
      </>
    );
  };

  return (
    <>
      <View style={styles.section}>
        <View style={styles.gap16}>
          <AppText style={Fonts.H300} color={Colors.primary100}>
            {t("expense_by_category")}
          </AppText>
          {renderChart()}
        </View>
      </View>
      <BottomSheetScrollViewModalCustom
        wrapperByScrollView={false}
        title={getTitleFromCategory({
          title: selectedCategory?.title,
          titleEn: selectedCategory?.titleEn,
        })}
        ref={bottomSheetRef}
      >
        {renderModalContent()}
      </BottomSheetScrollViewModalCustom>
    </>
  );
});

type SummarySectionProps = {
  selectedIndex: number;
  handleSelectIndex: (index: number) => void;
};

const SummarySection: FC<SummarySectionProps> = memo(({ selectedIndex, handleSelectIndex }) => {
  const { t } = useTranslation("app/screens/ExpenseInsight/ExpenseInsightScreen");
  const { loading, data } = usePersonalExpenseInsights({ month: NUMBER_OF_MONTH });

  const renderSummary = () => {
    const selected = data?.monthlyApprovedExpenses?.[selectedIndex];
    const nextLeft = data?.monthlyApprovedExpenses?.[selectedIndex - 1];
    const selectedAmount = selected?.totalAmount ?? 0;
    return (
      <>
        <View style={[styles.horizontalSpaceBetween, styles.py8]}>
          <AppText>
            {t("expenses")} {selected && dayjs(selected.month).format("MM/YYYY")}
          </AppText>
          <CurrencyText style={Fonts.NumericN200}>{selected && selectedAmount}</CurrencyText>
        </View>
        {selected && nextLeft && (
          <View style={[styles.horizontalSpaceBetween, styles.py8]}>
            <AppText>
              {t("compare_to")}
              {` ${dayjs(nextLeft.month).format("MM/YYYY")}`}
            </AppText>
            <PercentLabel value={selected.diffPercentage} />
          </View>
        )}
      </>
    );
  };

  return (
    <View style={styles.section}>
      <AppText style={Fonts.H300} color={Colors.primary100}>
        {t("spending_trend")}
      </AppText>
      <AppText style={Fonts.BodySmall}>{t("the_last_n_months", { numberOfMonth: 6 })}</AppText>
      {loading ? (
        <LoadingView style={{ height: 344, backgroundColor: Colors.white }} />
      ) : (
        <>
          <View style={{ marginLeft: -20 }}>
            <ExpenseChart
              data={data?.monthlyApprovedExpenses}
              selectedIndex={selectedIndex}
              setSelectedIndex={handleSelectIndex}
            />
          </View>
          <View style={{ marginTop: 24 }}>{renderSummary()}</View>
        </>
      )}
    </View>
  );
});

const ExpenseInsightScreen = ({ navigation }) => {
  const { t } = useTranslation("app/screens/ExpenseInsight/ExpenseInsightScreen");
  const [selectedIndex, setSelectedIndex] = useState<number>(null);

  const scrollViewRef = useRef(null);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("title")} description={t("your_expenses")} />,
    } as any);
  }, []);

  useEffect(() => {
    if (!selectedIndex) {
      handleSelectIndex(NUMBER_OF_MONTH - 1);
      setTimeout(() => scrollViewRef?.current?.scrollToEnd({ animated: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const monthLabels = useMemo(
    () =>
      Array(NUMBER_OF_MONTH)
        .fill(0)
        .map((_, index) => {
          const day = dayjs()
            .startOf("month")
            .subtract(NUMBER_OF_MONTH - (index + 1), "month");
          return day.format("MM/YYYY");
        }),
    []
  );

  const handleSelectIndex = useCallback((index: number) => {
    const itemWidth = 120;
    scrollViewRef?.current?.scrollTo({ x: index * itemWidth - (CONSTANTS.COMMON.CONTAINER_PADDING + 16) });
    setSelectedIndex(index);
  }, []);

  return (
    <View style={styles.container}>
      <View>
        <ScrollView
          ref={scrollViewRef}
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={styles.buttonContainer}
        >
          {monthLabels.map((monthLabel, index) => {
            const isActive = index === selectedIndex;
            return (
              <TouchableOpacity
                key={index}
                style={[styles.tabButton, isActive && styles.tabButtonActive]}
                onPress={() => handleSelectIndex(index)}
              >
                <AppText style={Fonts.BodySmall} color={isActive ? Colors.white : Colors.grayscale100}>
                  {monthLabel}
                </AppText>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollViewContainer}>
        <SummarySection selectedIndex={selectedIndex} handleSelectIndex={handleSelectIndex} />
        <Line size="lg" />
        <SpendingCategorySection selectedIndex={selectedIndex} />
      </ScrollView>
    </View>
  );
};

export default ExpenseInsightScreen;

const styles = StyleSheet.create({
  scrollViewContainer: {
    paddingBottom: 15,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  buttonContainer: {
    paddingHorizontal: 20,
    marginTop: 8,
  },
  tabButton: {
    height: 32,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    borderRadius: 2,
    paddingHorizontal: 12,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 20,
  },
  tabButtonActive: { backgroundColor: Colors.grayscale80, borderColor: Colors.grayscale80 },
  section: {
    paddingVertical: 20,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  horizontalSpaceBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  py8: {
    paddingVertical: 8,
  },

  flexRow: { flexDirection: "row", alignItems: "center" },
  spaceBetween: { justifyContent: "space-between" },
  overSpendingRow: { flexDirection: "row", justifyContent: "flex-end", marginTop: 8 },
  gap16: { gap: 16 },
  chartModalBody: {
    gap: 20,
    paddingVertical: 10,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  dotIcon: { height: 12, width: 12, borderRadius: 2 },
  dotRow: { flexDirection: "row", gap: 4, alignItems: "center" },
});
