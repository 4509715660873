import { Dayjs } from "dayjs";

export interface ExpenseFilterData {
  categories?: string[];
  startDate?: Dayjs;
  endDate?: Dayjs;
}

export interface NewExpenseTabMethods {
  refreshData: () => void;
}

export interface NewExpenseTabProps {
  routeParams?: Record<string, any>;
}
export enum EXPENSE_SORT_OPTIONS {
  CREATED_AT_ASC = "created_date_asc",
  CREATED_AT_DESC = "created_date_desc",
  REQUESTED_AT_ASC = "submission_date_asc",
  REQUESTED_AT_DESC = "submission_date_desc",
  AMOUNT_ASC = "amount_asc",
  AMOUNT_DESC = "amount_desc",
}
