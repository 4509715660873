import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, color = "#94A3B8" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 12c0 5.93 4.82 10.75 10.75 10.75S22.75 17.93 22.75 12c0-.41-.34-.75-.75-.75s-.75.34-.75.75c0 5.1-4.15 9.25-9.25 9.25S2.75 17.1 2.75 12 6.9 2.75 12 2.75c.41 0 .75-.34.75-.75s-.34-.75-.75-.75C6.07 1.25 1.25 6.07 1.25 12zM12 17.25c-.41 0-.75-.34-.75-.75v-.25h-.08c-1.33 0-2.42-1.12-2.42-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.41 1 .92 1h.08v-2.221l-1-.349c-.64-.23-1.5-.69-1.5-2.16 0-1.25.99-2.28 2.2-2.28h.3V7.5c0-.41.34-.75.75-.75s.75.34.75.75v.24h.08c1.33 0 2.42 1.12 2.42 2.5 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.55-.41-1-.92-1h-.08v2.221l1 .349c.64.23 1.5.69 1.5 2.16 0 1.26-.99 2.28-2.2 2.28h-.3v.25c0 .41-.34.75-.75.75zm-.75-8.01v1.698l-.51-.178c-.34-.12-.49-.2-.49-.74 0-.43.31-.78.7-.78h.3zm1.5 5.51v-1.698l.51.178c.34.12.49.2.49.74 0 .43-.31.78-.7.78h-.3zm9.25-8c-.41 0-.75-.34-.75-.75V3.81l-3.72 3.72c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 010-1.06l3.72-3.72H18c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.11 0 .217.025.312.07a.736.736 0 01.366.363.738.738 0 01.072.317v4c0 .41-.34.75-.75.75z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
