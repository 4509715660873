import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={26} height={26} viewBox="0 0 26 26">
      <Path
        d="M11.999 14v4.334c0 .283.096.52.287.712.192.192.43.288.713.288.284 0 .521-.096.712-.288a.968.968 0 00.288-.712v-4.333h4.333c.283 0 .52-.096.712-.288a.969.969 0 00.288-.713.967.967 0 00-.288-.712.968.968 0 00-.712-.287h-4.333V7.667a.967.967 0 00-.288-.712.968.968 0 00-.713-.288.967.967 0 00-.712.288.968.968 0 00-.287.712v4.334H7.665a.967.967 0 00-.712.287.968.968 0 00-.288.713c0 .284.096.521.288.712.192.192.429.288.712.288H12zM13 25.668c-1.752 0-3.399-.332-4.94-.997a12.795 12.795 0 01-4.023-2.707 12.79 12.79 0 01-2.708-4.021c-.665-1.54-.998-3.187-.998-4.94 0-1.751.332-3.398.997-4.94A12.795 12.795 0 014.036 4.04a12.789 12.789 0 014.021-2.708c1.541-.665 3.188-.998 4.94-.998 1.751 0 3.398.332 4.94.997a12.796 12.796 0 014.022 2.707 12.788 12.788 0 012.709 4.021c.665 1.541.997 3.188.997 4.94 0 1.751-.332 3.398-.997 4.94a12.796 12.796 0 01-2.707 4.022 12.79 12.79 0 01-4.021 2.709c-1.54.665-3.187.997-4.94.997z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
