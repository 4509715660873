import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

interface TransferExpenseInvoiceInput {
  object: {
    employeeId: string;
    invoiceId: string;
    destinationEmployeeIds: string[];
  };
}

const TRANSFER_INVOICE = gql`
  mutation MobileTransferExpenseInvoice($object: ExpTransferInvoiceInput!) {
    expTransferExpenseInvoice(input: $object) {
      transferredInvoice {
        emailId
        invoiceId
        companyId
        groupId
        receivedAt
        buyerTaxCode
        totalVatAmount
        sellerTaxCode
      }
    }
  }
`;

const useTransferExpenseInvoice = () => {
  const [transferExpenseInvoice, { loading }] = useMutation(TRANSFER_INVOICE);
  const mutation = async ({ object }: TransferExpenseInvoiceInput) => {
    const { data } = await transferExpenseInvoice({
      variables: {
        object,
      },
    });

    return data;
  };

  return { transferExpenseInvoice: mutation, loading };
};

export default useTransferExpenseInvoice;
