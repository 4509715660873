import { AppText, Badge, CurrencyText, IconCustom, ImageCustom } from "components";
import React, { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import dayjs from "dayjs";
import { BaggageType, BaggageBooking, BokCabinClass, FlightTicket } from "screens/FlightBooking/types";
import { useTranslation } from "react-i18next";
import { formatDuration } from "utils";

export type PlaneTicketProps = {
  mode?: "detail" | "listItem";
  item: FlightTicket;
  baggageInfo?: BaggageBooking[]; //baggage info after booked ticket
};
const FlightTicketCabinClass = {
  [BokCabinClass.Economy]: "cabin_class_economy",
  [BokCabinClass.DeluxeEconomy]: "cabin_class_deluxe_economy",
  [BokCabinClass.Business]: "cabin_class_business",
  [BokCabinClass.First]: "cabin_class_first",
  [BokCabinClass.Suites]: "cabin_class_suites",
};

const PlaneTicket: FC<PlaneTicketProps> = ({ mode = "detail", item, baggageInfo }) => {
  const departureFlight = item.flights?.[0];
  const segments = departureFlight?.segments;
  const { t } = useTranslation("app/screens/FlightBooking/components/FlightBookingComponent");
  const renderHeader = () => {
    switch (mode) {
      case "listItem":
        return (
          <>
            <View style={[styles.boardingStage, styles.boardingStageListItem]}>
              <View style={styles.timeBoard}>
                <AppText style={Fonts.BodyMedium}>{dayjs(departureFlight?.startDate).format("HH:mm")}</AppText>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
                  {departureFlight?.departure?.code}
                </AppText>
              </View>

              <View style={styles.arrowContainer}>
                <AppText style={Fonts.BodyXSmall} color={Colors.grayscale70}>
                  {formatDuration(departureFlight?.duration)}
                </AppText>
                <IconCustom name="vector-right" />
                <AppText style={Fonts.BodyXSmall} color={Colors.grayscale70}>
                  {segments?.length > 1
                    ? t("number_of_stations", { numberOfStation: segments.length - 1 })
                    : t("direct_flight")}
                </AppText>
              </View>

              <View style={styles.timeBoard}>
                <AppText style={Fonts.BodyMedium}>{dayjs(departureFlight?.endDate).format("HH:mm")}</AppText>
                <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
                  {departureFlight?.arrival?.code}
                </AppText>
              </View>
            </View>
            <View style={styles.priceContainer}>
              <CurrencyText color={Colors.success50} style={Fonts.NumericN200}>
                {item?.totalPrice}
              </CurrencyText>
            </View>
          </>
        );
      case "detail":
        return (
          <View style={[styles.boardingStage, styles.boardingStageDetail]}>
            <View style={styles.timeBoard}>
              <AppText style={Fonts.BodyMedium}>{departureFlight?.departure?.code}</AppText>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
                {dayjs(departureFlight?.startDate).format("HH:mm")}
              </AppText>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
                {dayjs(departureFlight?.startDate).format("DD/MM/YYYY")}
              </AppText>
            </View>

            <View style={styles.arrowContainer}>
              <AppText style={Fonts.BodyXSmall} color={Colors.grayscale70}>
                {formatDuration(departureFlight?.duration)}
              </AppText>
              <IconCustom name="vector-right" />
              <AppText style={Fonts.BodyXSmall} color={Colors.grayscale70}>
                {segments?.length > 1
                  ? t("number_of_stations", { numberOfStation: segments.length - 1 })
                  : t("direct_flight")}
              </AppText>
            </View>

            <View style={styles.timeBoard}>
              <AppText style={Fonts.BodyMedium}>{departureFlight?.arrival?.code}</AppText>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
                {dayjs(departureFlight?.endDate).format("HH:mm")}
              </AppText>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
                {dayjs(departureFlight?.endDate).format("DD/MM/YYYY")}
              </AppText>
            </View>
          </View>
        );
    }
  };

  return (
    <View style={styles.ticketCard}>
      <View style={styles.cardHeader}>{renderHeader()}</View>
      <View style={styles.cardFooter}>
        <View style={styles.vendorContainer}>
          <ImageCustom
            contentFit="contain"
            source={{ uri: departureFlight?.airline?.logoUrl }}
            style={styles.vendorAvatar}
          />
          <View style={styles.flightNumberAirline}>
            <AppText style={Fonts.BodySmall}>{item?.airline?.name}</AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
              {[departureFlight.flightNumber, t(FlightTicketCabinClass[departureFlight.cabinClass])]
                .filter(Boolean)
                .join(" | ")}{" "}
            </AppText>
          </View>
        </View>
        <View style={styles.otherInfo}>
          {!baggageInfo && Boolean(departureFlight.segments?.[0].allowanceBaggage?.weight) && (
            <Badge
              color={Colors.primary05}
              text={
                <AppText style={Fonts.BodySmall} color={Colors.grayscale100}>
                  {`${
                    departureFlight.segments?.[0].allowanceBaggage?.weight
                  }${departureFlight.segments?.[0].allowanceBaggage?.weightUnit?.toLowerCase()}`}
                </AppText>
              }
              icon={<IconCustom name="travel-luggage-and-bags" />}
            />
          )}
          {baggageInfo
            ?.filter((i) => i.type !== BaggageType.HandBaggage && i?.weight)
            ?.map((item) => (
              <Badge
                color={Colors.primary05}
                text={
                  <AppText style={Fonts.BodySmall} color={Colors.grayscale100}>
                    {`${item?.weight}${item?.weightUnit?.toLowerCase()}`}
                  </AppText>
                }
                icon={<IconCustom name="travel-luggage-and-bags" />}
              />
            ))}
        </View>
      </View>
    </View>
  );
};

export default PlaneTicket;

const styles = StyleSheet.create({
  ticketCard: { backgroundColor: Colors.white, paddingHorizontal: 12, paddingVertical: 8, borderRadius: 8 },
  cardHeader: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingVertical: 8 },
  cardFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 4,
  },
  /** header */
  boardingStage: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  boardingStageDetail: {
    justifyContent: "space-between",
  },
  boardingStageListItem: {
    gap: 12,
  },
  arrowContainer: {
    alignItems: "center",
    width: 60,
  },
  timeBoard: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  /** footer*/
  vendorContainer: {
    flex: 1,

    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  },
  flightNumberAirline: {
    flex: 1,
    gap: 2,
  },
  otherInfo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 4,
  },
  vendorAvatar: {
    width: 24,
    height: 24,
  },
});
