import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12 14.711a.784.784 0 00.808-.807.784.784 0 00-.808-.808.783.783 0 00-.808.808.784.784 0 00.808.807zm0-3.48a.726.726 0 00.535-.216.726.726 0 00.215-.534V5.942a.726.726 0 00-.215-.534.726.726 0 00-.535-.216.726.726 0 00-.535.216.726.726 0 00-.215.534v4.539c0 .213.072.39.215.534a.726.726 0 00.535.216zM6.038 17.5l-2.001 2.002c-.285.285-.612.349-.982.193-.37-.156-.555-.436-.555-.841V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.038zM5.4 16h14.292a.294.294 0 00.212-.096.294.294 0 00.096-.212V4.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H4.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v13.077L5.4 16z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
