import Svg, { Circle } from "react-native-svg";
import { Colors } from "theme";

const SvgComponent = ({ stroke = Colors.primary50 }) => (
  <Svg width={24} height={24} viewBox="0 0 16 16" fill="none">
    <Circle cx={8} cy={8} r={7} fill={stroke} stroke="white" strokeWidth={2} />
    <Circle cx={8} cy={8} r={3} fill="white" />
  </Svg>
);

export default SvgComponent;
