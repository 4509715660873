import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { StyleSheet, View } from "react-native";
import React, { FC } from "react";
import { MobileExpApplicableApprovalFlowsQuery } from "types";
import { formatMoneyString, getTitleFromCompanyTeam, getTitleI18n } from "utils";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";
interface ConditionHeaderProps {
  conditions: MobileExpApplicableApprovalFlowsQuery["expApplicableApprovalFlows"]["approvalFlows"][0]["conditions"];
  noConditionTitle?: string;
}
const ConditionHeader: FC<ConditionHeaderProps> = ({ conditions, noConditionTitle }) => {
  const { t } = useTranslation("app/screens/ApprovalFlowScreen");

  const renderConditionItem = (item) => {
    let operator = "";
    let conditionName = "";
    let value = typeof item?.value === "number" ? formatMoneyString(item?.value, true) : item?.value;
    if (value === "travel") {
      value = t("travel_request");
    } else if (value === "other") {
      value = t("expense_request");
    } else if (value === "purchase") {
      value = t("purchase");
    }
    switch (item.operator) {
      case "greaterThan": {
        operator = t("greaterThan");
        break;
      }
      case "greaterThanInclusive": {
        operator = t("greaterThanInclusive");
        break;
      }
      case "equal": {
        operator = t("equal");
        break;
      }
      case "notEqual": {
        operator = t("notEqual");
        break;
      }
      case "in": {
        operator = t("in");
        break;
      }
      case "lessThan": {
        operator = t("lessThan");
        break;
      }
      case "lessThanInclusive": {
        operator = t("lessThanInclusive");
        break;
      }
      case "contains": {
        operator = t("contains");
        break;
      }
      case "doesNotContain": {
        operator = t("doesNotContain");
        break;
      }
    }
    switch (item.fact) {
      case "total_cash_advance_amount": {
        conditionName = t("total_cash_advance_amount");
        break;
      }
      case "policy": {
        conditionName = t("policy");
        value = item?.value?.policyName;
        break;
      }
      case "total_requested_allowance": {
        conditionName = t("total_requested_allowance");
        break;
      }
      case "team": {
        conditionName = "team";
        break;
      }
      case "total_reimbursement": {
        conditionName = t("total_reimbursement");
        break;
      }
      case "expense_request_type": {
        conditionName = t("expense_request_type");
        break;
      }
      case "total_over_budget_amount": {
        conditionName = t("over_budget_amount");
        break;
      }
      case "total_over_spending_plan_amount": {
        conditionName = t("total_over_spending_plan_amount");
        break;
      }
    }
    let title = (
      <AppText style={[Fonts.BodyMedium, styles.flex]}>
        {`${t("when")}`}
        <AppText style={Fonts.NumericN200}>{` ${conditionName} `}</AppText>
        {`${operator} ${value}`}
      </AppText>
    );
    if (item.fact === "expense_request_existed") {
      title = (
        <AppText style={[Fonts.BodyMedium, styles.flex]}>
          {item.value ? t("is_assigned") : t("not_assigned")}
          <AppText style={Fonts.NumericN200}>{` ${t("expense_request")} `}</AppText>
        </AppText>
      );
    }
    if (item.fact === "team") {
      const isVi = i18n.language === LanguageStatus.VN;
      const conditionTeamName = isVi ? item?.value.teamType?.name : item?.value.teamType?.nameEn;
      if (item?.value?.teams?.length) {
        const conditionTeamValue =
          item?.operator === "in"
            ? `${t("is")}: ${item?.value?.teams
                ?.map((item) => getTitleFromCompanyTeam({ code: item?.code, name: item.name, nameEn: item?.nameEn }))
                .join(", ")}`
            : `${t("is_not", {
                label: isVi ? item?.value.teamType?.name : item?.value.teamType?.nameEn,
              })}: ${item?.value?.teams
                ?.map((item) => getTitleFromCompanyTeam({ code: item?.code, name: item.name, nameEn: item?.nameEn }))
                .join(", ")}`;
        title = (
          <AppText style={[Fonts.BodyMedium, styles.flex]}>
            <AppText style={Fonts.NumericN200}>{`${conditionTeamName} `}</AppText>
            {`${conditionTeamValue}`}
          </AppText>
        );
      } else {
        title = (
          <AppText style={[Fonts.BodyMedium, styles.flex]}>
            {item?.operator === "in"
              ? `${t("all_team")} ${(isVi ? item?.value.teamType?.name : item?.value.teamType?.nameEn)?.toLowerCase()}`
              : `${t("unit_is_not")} ${(isVi
                  ? item?.value.teamType?.name
                  : item?.value.teamType?.nameEn
                )?.toLowerCase()}`}
          </AppText>
        );
      }
    }

    if (item.fact === "expense_categories") {
      const conditionCategoryValue = `${item?.value
        ?.map((item) => getTitleI18n({ vi: item?.title, en: item.titleEn }))
        .join(", ")}`;
      title = (
        <AppText style={[Fonts.BodyMedium, styles.flex]}>
          <AppText style={Fonts.NumericN200}>{t("expense_categories_condition")}</AppText>
          {` ${t(item?.value?.length ? "are" : "is")}: ${conditionCategoryValue}`}
        </AppText>
      );
    }

    return (
      <View style={styles.conditionItem}>
        {conditions?.length > 1 && <View style={styles.dotItem} />}
        {/*<AppText style={[Fonts.BodyMedium, styles.flex]}>{title}</AppText>*/}
        {title}
      </View>
    );
  };
  return (
    <View style={styles.container}>
      {conditions?.length > 0 ? (
        conditions?.map((item) => renderConditionItem(item))
      ) : (
        <AppText style={Fonts.BodyMedium}>{noConditionTitle}</AppText>
      )}
      <View style={styles.divider} />
    </View>
  );
};
export default ConditionHeader;

const styles = StyleSheet.create({
  container: { paddingHorizontal: 5 },
  divider: { height: 1, backgroundColor: Colors.grayscale05, marginVertical: 8 },
  dotItem: { marginTop: 8, width: 5, height: 5, borderRadius: 3, backgroundColor: Colors.grayscale100 },
  conditionItem: { flexDirection: "row", gap: 10, marginBottom: 4 },
  flex: { flex: 1 },
});
