// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScrollToTop } from "@react-navigation/native";
import dayjs from "dayjs";
import { ActivityIndicator, FlatList, RefreshControl, StyleSheet, TouchableOpacity, View } from "react-native";
import "dayjs/locale/vi";
import relativeTime from "dayjs/plugin/relativeTime";

import { EmptyNotification } from "app/assets/images/svg/icons";
import { AppText, EmptyData, Icon, Line, SkeletonListLoading } from "app/components";
import { COMPANIES } from "constants/Companies";
import LanguageStatus from "constants/LanguageStatus";
import { BOTTOM_BAR_HEIGHT, HEIGHT_WINDOW, SPACING_DEFAULT } from "constants/Layout";
import { useAuth } from "contexts/AuthContext";
import useMarkNotification from "../hooks/useMarkNotification";
import { navigateBaseOnNotificationPayload } from "navigation/RootNavigation";
import { Colors, Fonts } from "theme";
import { fromNow } from "utils";
import useNotificationsQuery from "../hooks/useNotificationsQuery";
import { MobileNtfNotificationsQuery } from "types";
import useMarkAllNotification from "../hooks/useMarkAllNotification";
import { CustomMenu, IconCustom } from "components";
import useDeleteNotification from "screens/Notification/hooks/useDeleteNotification";
import uniqBy from "lodash/uniqBy";

dayjs.extend(relativeTime);

export interface NotificationListTabViewMethods {
  onMarkReadNotificationAll: () => void;
}
type NotificationItem = MobileNtfNotificationsQuery.ntfNotifications.notifications[0];
const Empty = memo(({ t }) => (
  <EmptyData icon={<EmptyNotification />} title={t("empty_notification")} description={""} />
));
type NotificationListTabViewProps = {
  notificationUnseenCount: number;
};

const NotificationListTabView = forwardRef<NotificationListTabViewMethods, NotificationListTabViewProps>(
  ({ notificationUnseenCount }, ref) => {
    useImperativeHandle(
      ref,
      () => ({
        onMarkReadNotificationAll,
      }),
      []
    );
    const [isReadAll, setIsReadAll] = useState(false);
    const {
      switchCompany,
      company: { company_id: companyId },
    } = useAuth();

    const isTLNExpenseCompany = useMemo(
      () =>
        [
          COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
          COMPANIES.TLN_EXPENSE_COMPANY_DEV,
          COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
        ].includes(companyId),
      [companyId]
    );
    const { t, i18n } = useTranslation("app/screens/Notification/NotificationScreen");
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const [readNotificationIds, setReadNotificationIds] = useState([]);
    const [notificationList, setNotificationList] = useState([]);
    const { loading, refetch } = useNotificationsQuery({ page: currentPage }, (data) => {
      if (!data?.ntfNotifications) {
        return;
      }
      const { notifications = [], hasMore } = data.ntfNotifications;
      setNotificationList((prevState) =>
        currentPage === 0 ? notifications : uniqBy([...prevState, ...notifications], "messageId")
      );
      setHasMore(hasMore);
    });

    const [markReadAllNotification] = useMarkAllNotification();
    const [markReadNotification] = useMarkNotification();
    const [deleteNotification] = useDeleteNotification();

    const [showScrollTop, setShowScrollTop] = useState(false);
    const flatListRef = useRef<FlatList>(null);
    const isCurrentLoadingMore = useRef(false);

    useScrollToTop(flatListRef);
    useEffect(() => {
      if (notificationUnseenCount) {
        markReadAllNotification({ variables: { input: { value: "SEEN" } } });
      }
    }, [notificationUnseenCount]);
    const onMarkReadNotificationAll = () => {
      try {
        setIsReadAll(true);
        scrollToTop();
        setReadNotificationIds(notificationList?.map((item) => item?.messageId));
        markReadAllNotification({ variables: { input: { value: "READ" } } });
        markReadAllNotification({ variables: { input: { value: "SEEN" } } });
      } catch (error) {}
    };

    useEffect(() => {
      if (i18n.language === LanguageStatus.VN) {
        dayjs.locale("vi");
      } else {
        dayjs.locale("en");
      }
    }, [i18n.language]);

    const renderFooter = () => {
      if (loading && notificationList?.length > 0) {
        return <ActivityIndicator size="small" color={Colors.primary50} />;
      }
      return null;
    };

    const handleLoadMore = () => {
      if (isCurrentLoadingMore.current || !hasMore) {
        return;
      }
      isCurrentLoadingMore.current = true;
      setCurrentPage((prevState) => prevState + 1);
      setTimeout(() => {
        isCurrentLoadingMore.current = false;
      }, 500);
    };

    const onPressNotification = (payload, messageId, read, index) => async () => {
      if (isTLNExpenseCompany) {
        return;
      }
      if (payload?.companyId) {
        switchCompany(payload.companyId);
      }
      navigateBaseOnNotificationPayload(payload);
      if (!read) {
        setReadNotificationIds((prevState) => [...prevState, ...[messageId]]);
        markReadNotification({ variables: { input: { messageId, read: true, seen: true } } });
      }
    };

    const handleDeleteNotification = async (messageId, notificationId) => {
      setNotificationList((prevState) => prevState.filter((item) => item.messageId !== messageId));
      await deleteNotification({ variables: { input: { messageId, notificationId } } });
      refetch({ page: currentPage });
    };

    const renderItem = ({ item, index }: { item: NotificationItem; index: number }) => {
      const { messageId, title, content, createdAt, payload, read } = item;
      const isRead = read || readNotificationIds?.includes(messageId) || isReadAll;
      return (
        <TouchableOpacity onPress={onPressNotification(payload, messageId, isRead, index)}>
          <View style={[styles.itemContainer, { backgroundColor: !isRead ? Colors.primary0 : Colors.white }]}>
            <View style={styles.headerRow}>
              <View style={styles.notificationDateRow}>
                {!isRead && <View style={styles.iconRead} />}
                <AppText style={Fonts.Caption}>{fromNow(createdAt)}</AppText>
              </View>
              <CustomMenu
                menuMaxWidth={110}
                options={[
                  {
                    title: t("hide_notification"),
                    onSelect: () => handleDeleteNotification(messageId, payload.notification_id),
                  },
                ]}
              >
                <IconCustom name="more-horiz" />
              </CustomMenu>
            </View>
            <View style={styles.boxContent}>
              <AppText style={Fonts.H300} color={!isRead ? Colors.grayscale100 : Colors.grayscale80} numberOfLines={3}>
                {title}
              </AppText>
              <AppText
                style={Fonts.BodyMedium}
                color={!isRead ? Colors.grayscale100 : Colors.grayscale60}
                numberOfLines={3}
              >
                {content}
              </AppText>
            </View>
          </View>
        </TouchableOpacity>
      );
    };

    const scrollToTop = useCallback(() => {
      if (flatListRef.current?.scrollToOffset) {
        flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
      }
    }, []);
    const currentPositionRef = useRef(0);

    const onScroll = useCallback(
      (e) => {
        const newPosition = e.nativeEvent.contentOffset.y;

        if (newPosition > HEIGHT_WINDOW) {
          if (!showScrollTop) {
            setShowScrollTop(true);
          }
        } else if (showScrollTop) {
          setShowScrollTop(false);
        }
        currentPositionRef.current = newPosition;
      },
      [showScrollTop]
    );

    const onRefresh = () => {
      refetch?.({ page: 0 });
      setCurrentPage(0);
      setIsReadAll(false);
    };
    if (loading && notificationList?.length === 0) {
      return (
        <View style={styles.container}>
          <SkeletonListLoading />
        </View>
      );
    }
    return (
      <View style={styles.container}>
        <FlatList
          ref={flatListRef}
          data={notificationList ?? []}
          renderItem={renderItem}
          keyExtractor={(item) => item?.messageId}
          showsVerticalScrollIndicator={false}
          ItemSeparatorComponent={Line}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefresh} />}
          onEndReached={handleLoadMore}
          contentContainerStyle={styles.contentContainerStyle}
          onEndReachedThreshold={0.2}
          ListFooterComponent={renderFooter}
          ListEmptyComponent={() => <Empty t={t} />}
          onScroll={onScroll}
        />

        {showScrollTop && (
          <TouchableOpacity style={styles.floatButton} onPress={scrollToTop}>
            <Icon type="Antdesign" name="up" size={15} color="#037ffc" />
          </TouchableOpacity>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.while,
    paddingTop: 10,
    flex: 1,
  },
  contentContainerStyle: { paddingBottom: 50 },
  iconRead: {
    marginRight: 4,
    backgroundColor: Colors.primary50,
    borderRadius: 4,
    width: 8,
    height: 8,
  },
  notificationDateRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  itemContainer: {
    padding: SPACING_DEFAULT,
  },
  boxContent: {
    marginVertical: 8,
  },
  floatButton: {
    borderWidth: 1,
    borderColor: Colors.blackColor200,
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    position: "absolute",
    bottom: 20 + BOTTOM_BAR_HEIGHT,
    right: 20,
    height: 40,
    backgroundColor: Colors.white,
    borderRadius: 100,
  },
  headerRow: { flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
});
export default NotificationListTabView;
