import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpSpendingTrendCategoryQuery, MobileExpSpendingTrendCategoryQueryVariables } from "types";

const QUERY = gql`
  query MobileExpSpendingTrendCategory($input: ExpSpendingTrendInput!) {
    expSpendingTrend(input: $input) {
      spendingCategory {
        actualSpendingAmount
        budgetAmount
        categoryId
        spendingPlanAmount
        title
        titleEn
      }
      months {
        actualSpendingAmount
        budgetAmount
        month
        spendingPlanAmount
      }
      budget {
        budgetId
        fromDate
        toDate
      }
      spendingTeam {
        actualSpendingAmount
        budgetAmount
        code
        companyTeamId
        name
        nameEn
        spendingPlanAmount
      }
      spendingEmployee {
        actualSpendingAmount
        email
        employeeId
        fullname
        spendingPlanAmount
      }
    }
  }
`;

const useSpendingTrendCategoryQuery = (variables: MobileExpSpendingTrendCategoryQueryVariables) => {
  return useQuery<MobileExpSpendingTrendCategoryQuery, MobileExpSpendingTrendCategoryQueryVariables>(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useSpendingTrendCategoryQuery;
