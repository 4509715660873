import Svg, { Path, SvgProps } from "react-native-svg";

function SvgComponent({ width = 121, height = 117, color = "#1F71F4" }: SvgProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 121 117" fill="none">
      <Path
        d="M14.14 24.188c6.68 0 12.094-5.415 12.094-12.094C26.234 5.414 20.82 0 14.141 0 7.46 0 2.047 5.415 2.047 12.094c0 6.679 5.414 12.094 12.094 12.094zM70.578 24.188c6.68 0 12.094-5.415 12.094-12.094C82.672 5.414 77.257 0 70.578 0c-6.68 0-12.094 5.415-12.094 12.094 0 6.679 5.415 12.094 12.094 12.094z"
        fill={color}
      />
      <Path
        d="M24.219 10.078h30.234v4.031H24.22v-4.03zM60.5 102.797h30.234v4.031H60.5v-4.031zM95.773 60.469H58.484v-4.032h37.29c11.67 0 21.164-9.494 21.164-21.164 0-11.67-9.494-21.164-21.165-21.164H80.656v-4.03h15.117c13.893 0 25.196 11.302 25.196 25.194 0 13.893-11.303 25.196-25.196 25.196zM34.297 106.828h-9.07C11.334 106.828.03 95.526.03 81.633s11.303-25.195 25.196-25.195h7.054v4.03h-7.054c-11.671 0-21.165 9.495-21.165 21.165s9.494 21.164 21.165 21.164h9.07v4.031z"
        fill="#475569"
      />
      <Path
        d="M50.422 116.906c6.68 0 12.094-5.414 12.094-12.094 0-6.679-5.415-12.093-12.094-12.093-6.68 0-12.094 5.414-12.094 12.093 0 6.68 5.415 12.094 12.094 12.094z"
        fill={color}
      />
      <Path
        d="M106.859 116.906c6.68 0 12.094-5.414 12.094-12.094 0-6.679-5.414-12.093-12.094-12.093-6.679 0-12.093 5.414-12.093 12.093 0 6.68 5.414 12.094 12.093 12.094z"
        fill="#37B24D"
      />
      <Path
        d="M42.36 70.547c6.679 0 12.093-5.415 12.093-12.094 0-6.68-5.414-12.094-12.094-12.094-6.679 0-12.093 5.415-12.093 12.094 0 6.68 5.414 12.094 12.093 12.094z"
        fill={color}
      />
      <Path
        d="M20.426 1.777a12.018 12.018 0 011.776 6.286c0 6.68-5.415 12.094-12.094 12.094-2.305 0-4.452-.657-6.286-1.776 2.124 3.478 5.944 5.807 10.317 5.807 6.68 0 12.094-5.414 12.094-12.093 0-4.374-2.33-8.194-5.807-10.318zM76.863 1.777a12.018 12.018 0 011.776 6.286c0 6.68-5.414 12.094-12.093 12.094-2.306 0-4.453-.657-6.286-1.776 2.124 3.478 5.943 5.807 10.317 5.807 6.68 0 12.094-5.414 12.094-12.093 0-4.374-2.33-8.194-5.808-10.318zM56.707 94.496a12.017 12.017 0 011.776 6.286c0 6.679-5.414 12.094-12.093 12.094-2.306 0-4.453-.657-6.287-1.777 2.124 3.479 5.944 5.808 10.318 5.808 6.679 0 12.093-5.414 12.093-12.094 0-4.373-2.329-8.193-5.807-10.317z"
        fill="#1E40AF"
      />
      <Path
        d="M113.144 94.496a12.015 12.015 0 011.777 6.286c0 6.679-5.415 12.094-12.094 12.094-2.306 0-4.453-.657-6.286-1.777 2.124 3.479 5.944 5.808 10.317 5.808 6.68 0 12.094-5.414 12.094-12.094 0-4.373-2.33-8.193-5.808-10.317z"
        fill="#2B8A3E"
      />
      <Path
        d="M48.644 48.137a12.018 12.018 0 011.777 6.286c0 6.679-5.415 12.093-12.094 12.093-2.305 0-4.453-.656-6.286-1.776 2.124 3.478 5.944 5.808 10.317 5.808 6.68 0 12.094-5.415 12.094-12.094 0-4.374-2.33-8.193-5.808-10.317z"
        fill="#1E40AF"
      />
      <Path
        d="M49.83 19.565l-2.85-2.85 4.622-4.622-4.621-4.622 2.85-2.85 7.472 7.472-7.472 7.472zM63.107 65.924l-7.472-7.472 7.472-7.471 2.85 2.85-4.622 4.621 4.622 4.622-2.85 2.85zM86.112 112.284l-2.85-2.85 4.621-4.622-4.621-4.622 2.85-2.85 7.472 7.472-7.472 7.472zM29.674 112.284l-2.85-2.85 4.622-4.622-4.622-4.622 2.85-2.85 7.472 7.472-7.472 7.472z"
        fill="#475569"
      />
    </Svg>
  );
}

export default SvgComponent;
