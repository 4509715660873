// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Flex } from "@ant-design/react-native";
import { LanguageEnIcon, LanguageViIcon } from "app/assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  AvatarUser,
  BackHeader,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  CollapsibleViewMenu,
  CopilotStepCustom,
  IconCustom,
  Line,
  StatusBarCustom,
  TouchableOpacityCustom,
} from "components";
import { useAuth } from "contexts/AuthContext";
import * as WebBrowser from "expo-web-browser";
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
// eslint-disable-next-line react-native/split-platform-components
import { ActionSheetIOS, Linking, Platform, ScrollView, StyleSheet, View } from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAppVersion } from "app/config";
import ArrowRight from "assets/images/svg/icons/ArrowRight";
import LanguageStatus from "constants/LanguageStatus";
import NotificationProvider from "contexts/NotificationContext";
import ScreenName from "navigation/ScreenName";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import { ProfileScreenNavigationProp } from "navigation/type";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { useNavigation } from "@react-navigation/native";
import { POLICY_TYPE } from "screens/PolicyEmployee/types";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { useInAppFeedback } from "contexts/InAppFeedbackContext";
import { useCopilot } from "react-native-copilot";
import { CONSTANTS } from "constants/constants";
import { BizziBotSticker01 } from "assets/images";
import { goBack } from "navigation/RootNavigation";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";

interface IProfileScreen {
  navigation: ProfileScreenNavigationProp;
}

interface NotificationProviderProps {
  removeTokenNotification: () => Promise<any>;
}
const phoneSupport = "02836222368";
const emailSupport = "cskh@bizzi.vn";

const ProfileScreen: FC<IProfileScreen & NotificationProviderProps> = ({ removeTokenNotification, route }) => {
  const { user, logout } = useAuth();
  const hasMultipleCompanies = useMemo(() => user?.employees?.length > 1, [user?.employees]);
  const { shouldShowWalkthrough = false, walkthroughName } = route.params ?? {};
  const { t, i18n } = useTranslation("app/screens/ProfileTab/ProfileScreen");
  const alertRef = useRef<AlertNotificationHandle>();
  const { openFeedbackModal } = useInAppFeedback();
  const { setCurrentAction, setCurrentChildrenActionCode } = useWalkthroughGuide();
  const selectContactRef = useRef<BottomSheetModalCustomMethods>();

  /*-- start walkthrough flow --*/
  const { start, steps, stop, currentStep, goToNth } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) => steps[key].walkthroughName === currentStep?.walkthroughName && steps[key].order === 1 // 0 is first step
  )?.[0];
  const isShownWalkthrough = useRef(false);
  useEffect(() => {
    if (
      shouldShowWalkthrough &&
      shouldShowWalkthrough !== "false" &&
      stepNameToStartWalkthrough &&
      !isShownWalkthrough.current
    ) {
      isShownWalkthrough.current = true;
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);
  /*-- end --*/

  const appVersion = useMemo(() => {
    const version = getAppVersion();
    if (version) {
      return t("version", { version: getAppVersion() });
    }
    return "";
  }, [t]);
  const handleLogout = async () => {
    alertRef?.current?.warning({
      title: t("logout_title"),
      description: t("logout_description"),
      confirmText: t("confirm"),
      cancelText: t("cancel"),
      onConfirm: async () => {
        alertRef?.current?.loading();
        await removeTokenNotification();
        await logout();
        alertRef?.current?.close();
      },
    });
  };
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("profile")} />,
    } as any);
  }, [t]);

  const navigation = useNavigation<any>();
  const [language, setLanguage] = useState();
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const handleChangeLanguage = async () => {
    setLanguage((prevState) => (prevState === LanguageStatus.VN ? LanguageStatus.EN : LanguageStatus.VN));
    await AsyncStorage.setItem("locale", language === LanguageStatus.EN ? LanguageStatus.VN : LanguageStatus.EN);
    await i18n.changeLanguage(language === LanguageStatus.EN ? LanguageStatus.VN : LanguageStatus.EN);
  };

  const openMailClient = () => {
    analyticService.logEvent({ name: EVENT.OTHER.OPEN_MAIL_CONTACT });
    Linking.canOpenURL(`mailto:${emailSupport}`)
      .then((supported) => {
        if (!supported) {
          console.log("Cant handle url");
        } else {
          return Linking.openURL(`mailto:${emailSupport}`).catch((error) => {
            console.log(error);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const callSupportPhoneNumber = () => {
    analyticService.logEvent({ name: EVENT.OTHER.CALL_PHONE_CONTACT });
    let phoneNumber;
    if (Platform.OS !== "android") {
      phoneNumber = `telprompt:${phoneSupport}`;
    } else {
      phoneNumber = `tel:${phoneSupport}`;
    }
    Linking.canOpenURL(phoneNumber)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(phoneNumber);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClickMenu = (type) => () => {
    switch (type) {
      case "company_info": {
        navigation.navigate(ScreenName.CompanyDetailScreen);
        break;
      }
      case "policy_spend": {
        navigation.navigate(ScreenName.PolicyEmployeeScreen, { type: POLICY_TYPE.SPEND });
        break;
      }
      case "policy_travel": {
        navigation.navigate(ScreenName.PolicyEmployeeScreen, { type: POLICY_TYPE.REQUEST_TRAVEL });
        break;
      }
      case "switch_company": {
        navigation.navigate(ScreenName.SwitchCompanyScreen);
        break;
      }
      case "authentication": {
        navigation.navigate(ScreenName.LocalAuthenticationScreen);
        break;
      }
      case "term_of_condition": {
        WebBrowser.openBrowserAsync(
          i18n.language === LanguageStatus.EN
            ? "https://bizzi.vn/en/terms-and-conditions/"
            : "https://bizzi.vn/terms-and-conditions/"
        );
        break;
      }
      case "about": {
        WebBrowser.openBrowserAsync(i18n.language === LanguageStatus.EN ? "https://bizzi.vn/en/" : "https://bizzi.vn/");
        break;
      }
      case "rating": {
        if (Platform.OS === "web") {
          const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
          Linking.openURL(
            isMac
              ? "https://apps.apple.com/vn/app/bizzi-expense/id1552135499"
              : "https://play.google.com/store/apps/details?id=vn.bizzi.expense"
          );
          return;
        }
        const APP_STORE_LINK = "https://apps.apple.com/vn/app/bizzi-expense/id1552135499?action=write-review";
        const PLAY_STORE_LINK = `market://details?id=vn.bizzi.expense`;
        Linking.openURL(Platform.OS === "ios" ? APP_STORE_LINK : PLAY_STORE_LINK);
        break;
      }
      case "approval_flow": {
        navigation.navigate(SCREEN_NAME.ApprovalFlowScreen);
        break;
      }
      case "contact": {
        if (Platform.OS !== "ios") {
          selectContactRef?.current?.present();
          return;
        }
        ActionSheetIOS.showActionSheetWithOptions(
          {
            options: [t("cancel"), t("phone_number"), "Email"],
            cancelButtonIndex: 0,
          },
          (buttonIndex) => {
            if (buttonIndex === 0) {
              // cancel action
            } else if (buttonIndex === 1) {
              callSupportPhoneNumber();
            } else if (buttonIndex === 2) {
              openMailClient();
            }
          }
        );
        break;
      }
      case "view_guide": {
        setCurrentAction(null);
        setCurrentChildrenActionCode("");
        navigation.navigate(SCREEN_BOTTOM_TAB.Home, {
          shouldShowWalkthrough: true,
          walkthroughName: CONSTANTS.WALK_THROUGH.OVERVIEW.NAME,
        });
        break;
      }
      case "faq": {
        WebBrowser.openBrowserAsync(
          "https://bizzi.zendesk.com/hc/vi/categories/4490625723289-Bizzi-Expense-Vai-tr%C3%B2-Nh%C3%A2n-vi%C3%AAn"
        );
        break;
      }
    }
  };

  return (
    <View style={styles.container}>
      <StatusBarCustom barStyle="dark-content" />
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: 20 }}>
        <TouchableOpacityCustom
          eventName={EVENT.PROFILE.TAP_VIEW_DETAIL}
          style={styles.userInfoContainer}
          onPress={() => navigation.navigate(ScreenName.ProfileDetailScreen)}
        >
          <AvatarUser size={40} url={user?.avatar} />
          <Flex direction="column" style={styles.nameContainer}>
            <AppText style={Fonts.H300}>{user.fullname}</AppText>
            <AppText style={[Fonts.SentenceBodyMedium, styles.expense_email]} color={Colors.grayscale100}>
              {user.email}
            </AppText>
          </Flex>
          <View style={styles.iconForward}>
            <ArrowRight />
          </View>
        </TouchableOpacityCustom>
        <View style={styles.menuSection}>
          <AppText style={[Fonts.H300, styles.menuSectionTitle]}>{t("company")}</AppText>
          <TouchableOpacityCustom
            eventName={EVENT.OTHER.VIEW_INVOICE_INFORMATION}
            style={styles.menuItem}
            onPress={handleClickMenu("company_info")}
          >
            <View style={styles.menuTitle}>
              <IconCustom name="qr-code" fill={Colors.grayscale60} />
              <AppText style={Fonts.BodyLarge}>{t("company_info")}</AppText>
            </View>
            <ArrowRight />
          </TouchableOpacityCustom>
          <Line backgroundColor={Colors.grayscale0} />
          <CollapsibleViewMenu
            isExpandedByDefault={shouldShowWalkthrough}
            rightComponent={<ArrowRight />}
            divider={<Line backgroundColor={Colors.grayscale0} />}
            header={
              <>
                <View style={styles.menuItem}>
                  <View style={styles.menuTitle}>
                    <IconCustom name="policy" fill={Colors.grayscale60} />
                    <AppText style={Fonts.BodyLarge}>{t("employee_policy")}</AppText>
                  </View>
                </View>
              </>
            }
          >
            <View style={{ paddingLeft: 40 }}>
              <CopilotStepCustom
                order={CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.Step2}
                title={"walkthrough_category_title_step2"}
                text={"walkthrough_category_description_step2"}
                image={BizziBotSticker01}
                walkthroughName={CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME}
                onConfirm={() => {
                  stop();
                  navigation.navigate(SCREEN_NAME.PolicyEmployeeScreen, {
                    type: POLICY_TYPE.SPEND,
                    shouldShowWalkthrough: true,
                    walkthroughName: CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME,
                  });
                }}
                activeStep={2}
                totalStep={3}
                borderRadius={10}
                onBack={() => {
                  goBack();
                  setTimeout(() => {
                    goToNth(1);
                  }, 250);
                }}
              >
                <TouchableOpacityCustom
                  eventName={EVENT.OTHER.VIEW_CATEGORY_POLICY}
                  style={styles.menuChild}
                  onPress={handleClickMenu("policy_spend")}
                >
                  <View style={styles.menuTitle}>
                    <AppText style={Fonts.BodyLarge}>{t("policy_spend")}</AppText>
                  </View>
                  <ArrowRight />
                </TouchableOpacityCustom>
              </CopilotStepCustom>
              <Line backgroundColor={Colors.grayscale0} />
              <CopilotStepCustom
                order={CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.Step2}
                title={"walkthrough_travel_title_step2"}
                text={"walkthrough_travel_description_step2"}
                image={BizziBotSticker01}
                walkthroughName={CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME}
                onConfirm={() => {
                  stop();
                  navigation.navigate(ScreenName.PolicyEmployeeScreen, {
                    type: POLICY_TYPE.REQUEST_TRAVEL,
                    shouldShowWalkthrough: true,
                    walkthroughName: CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME,
                  });
                }}
                onBack={() => {
                  goBack();
                  setTimeout(() => {
                    goToNth(1);
                  }, 250);
                }}
                activeStep={2}
                totalStep={3}
                borderRadius={10}
              >
                <TouchableOpacityCustom
                  eventName={EVENT.OTHER.VIEW_TRAVEL_REQUEST_POLICY}
                  style={styles.menuChild}
                  onPress={handleClickMenu("policy_travel")}
                >
                  <View style={styles.menuTitle}>
                    <AppText style={Fonts.BodyLarge}>{t("policy_travel")}</AppText>
                  </View>
                  <ArrowRight />
                </TouchableOpacityCustom>
              </CopilotStepCustom>
              <Line backgroundColor={Colors.grayscale0} />
              <TouchableOpacityCustom
                eventName={EVENT.OTHER.VIEW_APPROVAL_FLOW}
                style={styles.menuChild}
                onPress={handleClickMenu("approval_flow")}
              >
                <View style={styles.menuTitle}>
                  <AppText style={Fonts.BodyLarge}>{t("approval_flow")}</AppText>
                </View>
                <ArrowRight />
              </TouchableOpacityCustom>
              <Line backgroundColor={Colors.grayscale0} />
            </View>
          </CollapsibleViewMenu>
          {hasMultipleCompanies && (
            <>
              <TouchableOpacityCustom
                eventName={EVENT.OTHER.SWITCH_COMPANY}
                style={styles.menuItem}
                onPress={handleClickMenu("switch_company")}
              >
                <View style={styles.menuTitle}>
                  <IconCustom name="cached" fill={Colors.grayscale60} />
                  <AppText style={Fonts.BodyLarge}>{t("switch_company")}</AppText>
                </View>
                <ArrowRight />
              </TouchableOpacityCustom>
              <Line backgroundColor={Colors.grayscale0} />
            </>
          )}
        </View>
        <View style={styles.menuSection}>
          <AppText style={[Fonts.H300, styles.menuSectionTitle]}>{t("setting")}</AppText>
          <View style={styles.menuItem}>
            <View style={styles.menuTitle}>
              <IconCustom name="g-translate" fill={Colors.grayscale60} />
              <AppText style={Fonts.BodyLarge}>{t("change_language")}</AppText>
            </View>
            <TouchableOpacityCustom
              eventName={EVENT.OTHER.CHANGE_LANGUAGE}
              activeOpacity={0.8}
              onPress={handleChangeLanguage}
            >
              {language === LanguageStatus.EN ? <LanguageEnIcon /> : <LanguageViIcon />}
            </TouchableOpacityCustom>
          </View>
          <Line backgroundColor={Colors.grayscale0} />
          <TouchableOpacityCustom
            eventName={EVENT.OTHER.OPEN_AUTHENTICATION}
            style={styles.menuItem}
            onPress={handleClickMenu("authentication")}
          >
            <View style={styles.menuTitle}>
              <IconCustom name="shield-person" fill={Colors.grayscale60} />
              <AppText style={Fonts.BodyLarge}>{t("verify_way")}</AppText>
            </View>
            <ArrowRight />
          </TouchableOpacityCustom>
          <Line backgroundColor={Colors.grayscale0} />
        </View>
        <View style={styles.menuSection}>
          <AppText style={[Fonts.H300, styles.menuSectionTitle]}>{t("support")}</AppText>
          <TouchableOpacityCustom
            eventName={EVENT.OTHER.VIEW_TERM_OF_CONDITION}
            style={styles.menuItem}
            onPress={handleClickMenu("term_of_condition")}
          >
            <View style={styles.menuTitle}>
              <IconCustom name="dictionary" fill={Colors.grayscale60} />
              <AppText style={Fonts.BodyLarge}>{t("term_of_condition")}</AppText>
            </View>
            <ArrowRight />
          </TouchableOpacityCustom>
          <Line backgroundColor={Colors.grayscale0} />
          <TouchableOpacityCustom
            eventName={EVENT.OTHER.VIEW_ABOUT_BIZZI}
            style={styles.menuItem}
            onPress={handleClickMenu("about")}
          >
            <View style={styles.menuTitle}>
              <IconCustom name="info-outline" fill={Colors.grayscale60} />
              <AppText style={Fonts.BodyLarge}>{t("about_us")}</AppText>
            </View>
            <ArrowRight />
          </TouchableOpacityCustom>
          <Line backgroundColor={Colors.grayscale0} />
          <TouchableOpacityCustom
            eventName={EVENT.OTHER.VIEW_CONTACT}
            style={styles.menuItem}
            onPress={handleClickMenu("contact")}
          >
            <View style={styles.menuTitle}>
              <IconCustom name="call" fill={Colors.grayscale60} />
              <AppText style={Fonts.BodyLarge}>{t("contact")}</AppText>
            </View>
            <ArrowRight />
          </TouchableOpacityCustom>
          {Platform.OS !== "web" && (
            <>
              <Line backgroundColor={Colors.grayscale0} />
              <CollapsibleViewMenu
                rightComponent={<ArrowRight />}
                divider={<Line backgroundColor={Colors.grayscale0} />}
                header={
                  <>
                    <View style={styles.menuItem}>
                      <View style={styles.menuTitle}>
                        <IconCustom name="help" fill={Colors.grayscale60} />
                        <AppText style={Fonts.BodyLarge}>{t("view_guide")}</AppText>
                      </View>
                    </View>
                  </>
                }
              >
                <View style={{ paddingLeft: 40 }}>
                  <TouchableOpacityCustom style={styles.menuChild} onPress={handleClickMenu("view_guide")}>
                    <View style={styles.menuTitle}>
                      <AppText style={Fonts.BodyLarge}>{t("overview_introduction")}</AppText>
                    </View>
                    <ArrowRight />
                  </TouchableOpacityCustom>

                  <Line backgroundColor={Colors.grayscale0} />
                  <TouchableOpacityCustom
                    eventName={EVENT.OTHER.VIEW_TRAVEL_REQUEST_POLICY}
                    style={styles.menuChild}
                    onPress={handleClickMenu("faq")}
                  >
                    <View style={styles.menuTitle}>
                      <AppText style={Fonts.BodyLarge}>{t("faq")}</AppText>
                    </View>
                    <ArrowRight />
                  </TouchableOpacityCustom>
                  <Line backgroundColor={Colors.grayscale0} />
                </View>
              </CollapsibleViewMenu>
              <Line backgroundColor={Colors.grayscale0} />
            </>
          )}
          <CollapsibleViewMenu
            rightComponent={<ArrowRight />}
            divider={<Line backgroundColor={Colors.grayscale0} />}
            header={
              <>
                <View style={styles.menuItem}>
                  <View style={styles.menuTitle}>
                    <IconCustom name="star-outline" fill={Colors.grayscale60} />
                    <AppText style={Fonts.BodyLarge}>{t("rating")}</AppText>
                  </View>
                </View>
              </>
            }
          >
            <View style={{ paddingLeft: 40 }}>
              <TouchableOpacityCustom style={styles.menuChild} onPress={handleClickMenu("rating")}>
                <View style={styles.menuTitle}>
                  <AppText style={Fonts.BodyLarge}>
                    {Platform.OS === "ios" ? t("rating_app_store") : t("rating_google_play")}
                  </AppText>
                </View>
                <ArrowRight />
              </TouchableOpacityCustom>
              <Line backgroundColor={Colors.grayscale0} />
              <TouchableOpacityCustom
                eventName={EVENT.OTHER.TAP_OPEN_RATING_INTERNAL}
                style={styles.menuChild}
                onPress={openFeedbackModal}
              >
                <View style={styles.menuTitle}>
                  <AppText style={Fonts.BodyLarge}>{t("feedback")}</AppText>
                </View>
                <ArrowRight />
              </TouchableOpacityCustom>
              <Line backgroundColor={Colors.grayscale0} />
            </View>
          </CollapsibleViewMenu>
          <Line backgroundColor={Colors.grayscale0} />
          <TouchableOpacityCustom eventName={EVENT.OTHER.TAP_LOGOUT} style={styles.menuItem} onPress={handleLogout}>
            <View style={styles.menuTitle}>
              <IconCustom name="logout" fill={Colors.alert50} />
              <AppText style={Fonts.BodyLarge} color={Colors.alert50}>
                {t("sign_out")}
              </AppText>
            </View>
          </TouchableOpacityCustom>
        </View>
        <View style={{ paddingBottom: 20, marginTop: 10, alignItems: "center" }}>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale100}>
            {appVersion}
          </AppText>
        </View>
      </ScrollView>

      <BottomSheetScrollViewModalCustom wrapperByScrollView={false} ref={selectContactRef}>
        <View style={{ paddingHorizontal: 20, paddingTop: 15 }}>
          <TouchableOpacityCustom
            style={styles.contactItem}
            onPress={() => {
              selectContactRef?.current?.close();
              if (Platform.OS !== "web") {
                callSupportPhoneNumber();
              }
            }}
          >
            <AppText style={Fonts.BodyMedium}>
              {Platform.OS !== "web" ? t("phone_number") : `${t("phone_number")}: ${phoneSupport}`}
            </AppText>
          </TouchableOpacityCustom>
          <Line />
          <TouchableOpacityCustom
            style={styles.contactItem}
            onPress={() => {
              selectContactRef?.current?.close();
              if (Platform.OS !== "web") {
                openMailClient();
              }
            }}
          >
            <AppText style={Fonts.BodyMedium}>{Platform.OS !== "web" ? "Email" : `Email: ${emailSupport}`}</AppText>
          </TouchableOpacityCustom>
        </View>
      </BottomSheetScrollViewModalCustom>
      <AlertNotification ref={alertRef} />
    </View>
  );
};

const WrapperNotificationContext = (props: ProfileScreenNavigationProp) => {
  return (
    <NotificationProvider.Consumer>
      {({ removeTokenNotification }) => {
        return <ProfileScreen removeTokenNotification={removeTokenNotification} {...props} />;
      }}
    </NotificationProvider.Consumer>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  userInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    paddingLeft: 14,
    paddingRight: 20,
    marginBottom: 10,
  },
  menuSection: { paddingHorizontal: 20, marginTop: 25 },
  menuSectionTitle: { marginBottom: 4 },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 12,
  },
  menuChild: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingLeft: 4,
  },
  menuTitle: { flexDirection: "row", gap: 16, alignItems: "center" },
  nameContainer: {
    marginLeft: 15,
    alignItems: "baseline",
  },
  expense_email: {
    textAlign: "left",
    marginTop: 5,
  },
  iconForward: {
    marginLeft: "auto",
  },
  contactItem: { paddingVertical: 13 },
});

export default WrapperNotificationContext;
