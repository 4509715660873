import Svg, { Path } from "react-native-svg";

const SVGComponent = ({ width = 20, height = 20, color = "#2B8A3E" }) => (
  <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <Path
      d="M10.0013 18.3337C5.3988 18.3337 1.66797 14.6028 1.66797 10.0003C1.66797 5.39783 5.3988 1.66699 10.0013 1.66699C14.6038 1.66699 18.3346 5.39783 18.3346 10.0003C18.3346 14.6028 14.6038 18.3337 10.0013 18.3337ZM10.0013 16.667C11.7694 16.667 13.4651 15.9646 14.7153 14.7144C15.9656 13.4641 16.668 11.7684 16.668 10.0003C16.668 8.23222 15.9656 6.53652 14.7153 5.28628C13.4651 4.03604 11.7694 3.33366 10.0013 3.33366C8.23319 3.33366 6.5375 4.03604 5.28726 5.28628C4.03701 6.53652 3.33464 8.23222 3.33464 10.0003C3.33464 11.7684 4.03701 13.4641 5.28726 14.7144C6.5375 15.9646 8.23319 16.667 10.0013 16.667ZM9.17047 13.3337L5.63464 9.79783L6.81297 8.61949L9.17047 10.977L13.8838 6.26283L15.063 7.44116L9.17047 13.3337Z"
      fill={color}
    />
  </Svg>
);

export default SVGComponent;
