import { useMutation, MutationHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpRevalidateInvoiceMutation, MobileExpRevalidateInvoiceMutationVariables } from "types";

const REVALIDATE_INVOICE = gql`
  mutation MobileExpRevalidateInvoice($companyId: UUID!, $invoiceId: UUID!) {
    expRevalidateInvoice(input: { companyId: $companyId, invoiceId: $invoiceId }) {
      success
    }
  }
`;
const useRevalidateInvoiceMutation = (
  options?: MutationHookOptions<MobileExpRevalidateInvoiceMutation, MobileExpRevalidateInvoiceMutationVariables>
) => {
  return useMutation(REVALIDATE_INVOICE, { ...options });
};
export default useRevalidateInvoiceMutation;
