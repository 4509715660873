import dayjs from "dayjs";
import { i18n } from "i18next";
import isEmpty from "lodash/isEmpty";
import { TFunction } from "react-i18next";

import LanguageStatus from "constants/LanguageStatus";
import { MobileExpenseDetailsByPkQuery } from "types";
import { formatMoney, getTitleFromCategory } from "utils";

import { formatDataImages } from "../components/ExpenseForm/helpers";

import { ExpenseDetailsView } from "./types";
import { CONSTANTS } from "constants/constants";

export const parseExpense = (
  i18n: i18n,
  t: TFunction<"app/screens/Expense/ExpenseDetailsScreen", undefined>,
  expense?: MobileExpenseDetailsByPkQuery["expExpenseDetails"]
): Partial<ExpenseDetailsView> => {
  if (!expense) {
    return {
      fileAttachments: [],
      invoiceImages: [],
    };
  }
  const {
    note,
    address,
    provider,
    expenseId,
    invoice,
    invoiceType,
    companyTeam,
    expenseRequest,
    expenseCategory,
    expenseAttachments,
    expenseDate: originalExpenseDate,
    totalAmountWithVat: originalTotalAmountWithVat,
    customFields,
    placeDetails,
    expenseReport,
    expenseCardTransactions,
    status,
    paymentInfo,
    docSequence,
    allocatedStatus,
    parent,
    subAccount,
  } = expense;
  const totalAmountWithVat = formatMoney(String(originalTotalAmountWithVat ?? ""));
  const { companyTeamId, name, code: companyTeamCode, nameEn } = companyTeam ?? {};
  const expenseDate = dayjs(originalExpenseDate).format(CONSTANTS.FORMAT_DAY);
  const {
    expenseRequestId,
    docSequence: requestDocSequence,
    title: originalExpenseRequestTitle,
  } = expenseRequest ?? {};
  const { expenseCategoryId } = expenseCategory ?? {};

  const invoiceImages = formatDataImages(1, expenseAttachments) || [];
  const fileAttachments = formatDataImages(2, expenseAttachments) || [];

  const expenseCategoryTitle = getTitleFromCategory(expenseCategory);

  const companyTeamName =
    companyTeamId && `${companyTeamCode}-${i18n.language === LanguageStatus.EN && nameEn ? nameEn : name}`;

  const expenseRequestTitle = requestDocSequence && `${requestDocSequence} - ${originalExpenseRequestTitle}`;
  return {
    note,
    invoice,
    expenseId,
    expenseDate,
    invoiceType,
    placeDetails,
    companyTeamId,
    invoiceImages,
    fileAttachments,
    expenseRequestId,
    expenseCategoryId,
    totalAmountWithVat,
    expenseCategoryTitle,
    address: isEmpty(address) ? t("no_value", { label: t("address").toLowerCase() }) : address,
    provider: isEmpty(provider) ? t("no_value", { label: t("location_info").toLowerCase() }) : provider,
    companyTeamName,
    expenseRequestTitle,
    customFields,
    expenseReport,
    expenseCardTransactions,
    status,
    paymentInfo,
    companyTeam,
    docSequence,
    parent,
    allocatedStatus,
    subAccount,
  };
};
