import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ size }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 62 62" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={62} height={62}>
        <Path d="M.163 0h61.655v61.655H.163V0z" fill="#fff" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M15.577 0a3.862 3.862 0 00-3.85 3.853v53.952a3.864 3.864 0 003.853 3.85h38.535a3.863 3.863 0 003.852-3.853V15.414L42.558 0h-26.98z"
          fill="#E2E5E7"
        />
        <Path d="M46.404 15.414h11.561L42.551 0v11.562a3.865 3.865 0 003.853 3.852z" fill="#B0B7BD" />
        <Path d="M57.961 26.973L46.399 15.41h11.562v11.565-.003z" fill="#CAD1D8" />
        <Path
          d="M50.256 50.093a1.933 1.933 0 01-1.93 1.926H5.94a1.933 1.933 0 01-1.926-1.926V30.829c0-1.063.864-1.93 1.926-1.93h42.387c1.06 0 1.927.867 1.927 1.927l.003 19.267z"
          fill="#F15642"
        />
        <Path
          d="M48.333 52.02H11.725v1.926h36.608a1.93 1.93 0 001.926-1.927v-1.926a1.928 1.928 0 01-1.926 1.926z"
          fill="#CAD1D8"
        />
        <Path
          d="M16.063 46c-.391 0-.586-.17-.586-.508v-.64c0-.36.132-.722.398-1.086l5.078-7.016v-.055c-.312.026-.625.04-.937.04h-3.641c-.39 0-.586-.17-.586-.508v-.922c0-.339.195-.508.586-.508H23.5c.39 0 .586.17.586.508v.523c0 .276-.091.54-.273.79l-5.391 7.429v.055c.312-.026.625-.04.937-.04h4.36c.39 0 .586.17.586.508v.922c0 .339-.196.508-.586.508h-7.657zm10.515 0c-.364 0-.547-.195-.547-.586V35.383c0-.39.183-.586.547-.586h1.188c.364 0 .547.195.547.586v10.031c0 .39-.183.586-.547.586h-1.188zm4.781 0c-.364 0-.547-.195-.547-.586V35.383c0-.39.183-.586.547-.586h2.922c1.875 0 3.175.305 3.899.914.729.61 1.093 1.474 1.093 2.594 0 1.12-.364 1.984-1.093 2.593-.724.61-2.024.914-3.899.914h-1.187v3.602c0 .39-.183.586-.547.586h-1.188zm1.735-6.094h1.18c1.041 0 1.75-.122 2.124-.367.375-.25.563-.661.563-1.234s-.188-.982-.563-1.227c-.375-.25-1.083-.375-2.125-.375h-1.18v3.203z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
