import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpenseRequestSearchQuery, MobileExpenseRequestSearchQueryVariables } from "types";

const QUERY_REQUESTS = gql`
  query MobileExpenseRequestSearch(
    $where: ExpMobileExpenseRequestCondition!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
    $sortOption: ExpMobileExpenseRequestsSortOptions
  ) {
    expMobileExpenseRequests(where: $where, limit: $limit, offset: $offset, sortOption: $sortOption) {
      total
      requests {
        createdAt
        closedAt
        closedBy
        docSequence
        expenseRequestId
        expenseRequestType
        isClosed
        status
        title
        totalAmount
        updatedAt
        requestedAt
        companyEmployee {
          fullname
        }
        expenseApprovalRules {
          status
          expenseApprovalRuleEmployees {
            employee {
              employeeId
              user {
                avatarUrl
                userId
                defaultAvatarUrl
                fullname
              }
            }
          }
        }
      }
    }
  }
`;

const useExpenseRequestSearchQuery = (variables: MobileExpenseRequestSearchQueryVariables) => {
  return useLazyQuery<MobileExpenseRequestSearchQuery, MobileExpenseRequestSearchQueryVariables>(QUERY_REQUESTS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpenseRequestSearchQuery;
