import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Path
        d="M16 17.5h.5v1h-1v-1h.5zm.5-1.5v-.5h1v1h-1V16zm-1-.5h-2v-2h2v2zm3-1v-1h2v1h-2zm-4 4.5v1.5h-1v-2h1v.5zm3 .5h2v-2h1v3h-3v-1zM5 4.5h-.5v5h5v-5H5zm10 0h-.5v5h5v-5H15zm-10 10h-.5v5h5v-5H5zm-1.5-11h7v7h-7v-7zm10 0h7v7h-7v-7zm-10 10h7v7h-7v-7zm3-7h1v1h-1v-1zm0 10h1v1h-1v-1zm10-10h1v1h-1v-1z"
        stroke="#94A3B8"
      />
    </Svg>
  );
}

export default SvgComponent;
