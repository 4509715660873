import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M3.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V7.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h3.054l1.307-1.417c.166-.18.365-.322.598-.426A1.77 1.77 0 019 3.5h4.058c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.391-.216.535a.726.726 0 01-.534.215H8.865L7.031 7H3.308a.3.3 0 00-.221.087.3.3 0 00-.087.22v11.385a.3.3 0 00.087.221.3.3 0 00.22.087h15.385a.3.3 0 00.221-.087.3.3 0 00.087-.22v-7.75c0-.213.072-.391.216-.535a.726.726 0 01.534-.216c.213 0 .391.072.535.216a.726.726 0 01.215.534v7.75c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H3.308zM19 5h-1.25a.726.726 0 01-.534-.216A.726.726 0 0117 4.25c0-.213.072-.391.216-.535a.726.726 0 01.534-.215H19V2.25c0-.212.072-.39.216-.534a.726.726 0 01.534-.216c.213 0 .391.072.535.216a.726.726 0 01.215.534V3.5h1.25c.212 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.391-.216.535A.726.726 0 0121.75 5H20.5v1.25c0 .212-.072.39-.216.534A.726.726 0 0119.75 7a.725.725 0 01-.535-.216A.726.726 0 0119 6.25V5zm-8 12.115c1.147 0 2.12-.399 2.918-1.197s1.197-1.77 1.197-2.918c0-1.148-.399-2.12-1.197-2.918S12.148 8.885 11 8.885c-1.147 0-2.12.399-2.918 1.197S6.885 11.852 6.885 13c0 1.147.399 2.12 1.197 2.918s1.77 1.197 2.918 1.197zm0-1.5c-.739 0-1.359-.25-1.862-.754-.502-.502-.753-1.123-.753-1.861 0-.739.25-1.359.753-1.862.503-.502 1.123-.753 1.862-.753.738 0 1.359.25 1.861.753.503.503.754 1.123.754 1.862 0 .738-.25 1.359-.754 1.861-.502.503-1.123.754-1.861.754z"
          fill="#1C1B1F"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
