import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileExpenseReportsHistoryQuery, MobileExpenseReportsHistoryQueryVariables } from "types";

const QUERY_REPORTS_HISTORY = gql`
  query MobileExpenseReportsHistory(
    $where: ExpMobileExpenseReportCondition!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
  ) {
    expExpenseReportsHistory: expMobileExpenseReports(where: $where, limit: $limit, offset: $offset) {
      total
      reports {
        allocatedStatus
        expenseReportId
        title
        docSequence
        expenses {
          expenseId
          totalAmountWithVat
        }
        createdAt
        status
        companyEmployeeId
        companyEmployee {
          employeeId
          fullname
          email
        }
        user {
          userId
        }
      }
    }
  }
`;
const useExpenseReportsHistory = (variables: MobileExpenseReportsHistoryQueryVariables) => {
  return useQuery<MobileExpenseReportsHistoryQuery, MobileExpenseReportsHistoryQueryVariables>(QUERY_REPORTS_HISTORY, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
};
export default useExpenseReportsHistory;
