import React, { FC, forwardRef, memo, useImperativeHandle, useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useCopilot } from "react-native-copilot";

import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import { HomeNavigationProp } from "navigation/type";
import { AppText, CopilotStepCustom, CurrencyText, IconCustom, PercentLabel, TouchableOpacityCustom } from "components";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import ReportCardLoading from "./ReportCardLoading";
import { EXPENSE_LIST_TAB } from "screens/Expense/ExpenseListScreen/ExpenseListScreen";
import { REPORT_LIST_TAB } from "screens/Report/ReportListScreen/ReportListScreen";
import { CONSTANTS } from "constants/constants";
import ThumbnailLineChart from "./ThumbnailLineChart";
import ReportStatus from "constants/ReportStatus";
import { ExpenseRequestListTabs } from "screens/ExpenseRequest/types";
import RequestStatus from "constants/RequestStatus";
import dayjs from "dayjs";
import usePersonalExpenseInsights from "../hooks/usePersonalExpenseInsights";
import { EVENT } from "constants/Tracking";
import { BizziBotSticker01 } from "assets/images";

const NUMBER_OF_MONTH = 6;

interface ReportCardData {
  summary: {
    amount: number;
    diffPercentage: number;
    chartData: number[];
  };
  totalNonClaim: {
    count: number;
    amount: number;
  };
  waitingRequestApproveClaim: {
    count: number;
    amount: number;
  };
  waitingReportApproveClaim: {
    count: number;
    amount: number;
  };
  loading?: boolean;
}

const DUMMY_DATA: ReportCardData = {
  summary: {
    amount: 80000000,
    diffPercentage: 20,
    chartData: [13000000, 23000000, 12000000, 12100000, 18000000, 28000000],
  },
  totalNonClaim: {
    count: 5,
    amount: 10000000,
  },
  waitingRequestApproveClaim: {
    count: 2,
    amount: 50000000,
  },
  waitingReportApproveClaim: {
    count: 1,
    amount: 30000000,
  },
};

const ReportCard = forwardRef((_, ref) => {
  useImperativeHandle(
    ref,
    () => ({
      onRefresh: () => refetch(),
    }),
    []
  );
  const { refetch, loading, data } = usePersonalExpenseInsights({ month: 6 });
  const totalNonClaim = {
    count: data.unclaimExpense?.totalExpenses ?? 0,
    amount: data.unclaimExpense?.totalAmount ?? 0,
  };
  const waitingReportApproveClaim = {
    count: data.waitingApprovalReport?.totalReports ?? 0,
    amount: data.waitingApprovalReport?.totalAmount ?? 0,
  };
  const waitingRequestApproveClaim = {
    count: data.waitingApprovalRequest?.totalRequests ?? 0,
    amount: data.waitingApprovalRequest?.totalAmount ?? 0,
  };

  const summary: ReportCardData["summary"] = useMemo(() => {
    const currentMonth = data.monthlyApprovedExpenses?.[NUMBER_OF_MONTH - 1];
    const chartData =
      data.monthlyApprovedExpenses?.map((exp) => exp.totalAmount) ??
      Array(6)
        .fill(0)
        .map(() => 0);

    return {
      amount: currentMonth?.totalAmount ?? 0,
      diffPercentage: currentMonth?.diffPercentage ?? 0,
      chartData,
    };
  }, [data]);

  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const navigation = useNavigation<HomeNavigationProp>();
  const { visible: walkthroughVisible } = useCopilot();
  const handleViewNonClaim = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate(SCREEN_BOTTOM_TAB.ExpensesScreen, { tab: EXPENSE_LIST_TAB.NEW });
  };

  const handleViewReportWaitingApprove = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate(SCREEN_BOTTOM_TAB.ReportsScreen, {
      tab: REPORT_LIST_TAB.MY_REQUEST,
      status: ReportStatus.SUBMITTED,
    });
  };

  const handleViewRequestWaitingApprove = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate(SCREEN_BOTTOM_TAB.RequestScreen, {
      tab: ExpenseRequestListTabs.MINE,
      status: RequestStatus.SUBMITTED,
    });
  };

  const handleViewInsight = () => {
    (navigation as any).navigate(SCREEN_NAME.ExpenseInsightScreen);
  };

  if (loading) {
    return <ReportCardLoading />;
  }

  return (
    <CopilotStepCustom
      title={"walkthrough_overview_step4_title"}
      text={"walkthrough_overview_step4_description"}
      order={CONSTANTS.WALK_THROUGH.OVERVIEW.Step4}
      style={styles.card}
      image={BizziBotSticker01}
      walkthroughName={CONSTANTS.WALK_THROUGH.OVERVIEW.NAME}
      autoScroll={true}
    >
      <>
        <TouchableOpacityCustom eventName={EVENT.DASHBOARD.VIEW_PERSONAL_DASHBOARD} onPress={handleViewInsight}>
          <View style={styles.cardHeader}>
            <AppText style={[styles.flex, Fonts.BodySmall]} color={Colors.grayscale80} numberOfLines={2}>
              {t("total_amount_of_approved_expenses")}
            </AppText>
            <View style={styles.cardHeaderLeft}>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>
                {dayjs().format("MM/YYYY")}
              </AppText>
              <IconCustom width={20} height={20} name="arrow-right" fill={Colors.grayscale60} />
            </View>
          </View>

          <View style={styles.cardItem}>
            <View style={styles.flex}>
              <CurrencyText style={Fonts.NumericN300}>
                {!walkthroughVisible ? summary.amount : DUMMY_DATA.summary.amount}
              </CurrencyText>
              <View style={styles.flex}>
                <PercentLabel value={walkthroughVisible ? DUMMY_DATA.summary.diffPercentage : summary.diffPercentage}>
                  <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>
                    {" "}
                    {t("compare_to_previous_month")}
                  </AppText>
                </PercentLabel>
              </View>
            </View>
            <ThumbnailLineChart
              width={97}
              height={46}
              data={!walkthroughVisible ? summary.chartData : DUMMY_DATA.summary.chartData}
            />
          </View>
        </TouchableOpacityCustom>

        <CardItem
          eventName={EVENT.HOME.VIEW_NEW_EXPENSE_LIST}
          title={`${t("not_reported_expense")} ${
            walkthroughVisible
              ? `(${DUMMY_DATA.totalNonClaim.count})`
              : totalNonClaim.count
              ? `(${totalNonClaim.count})`
              : ""
          }`}
          amount={!walkthroughVisible ? totalNonClaim.amount : DUMMY_DATA.totalNonClaim.amount}
          onPress={handleViewNonClaim}
        />
        <CardItem
          eventName={EVENT.HOME.VIEW_REQUEST_LIST_NEED_TO_BE_APPROVED}
          title={`${t("requests_need_to_be_approved")} ${
            walkthroughVisible
              ? `(${DUMMY_DATA.waitingRequestApproveClaim.count})`
              : waitingRequestApproveClaim.count
              ? `(${waitingRequestApproveClaim.count})`
              : ""
          }`}
          amount={
            !walkthroughVisible ? waitingRequestApproveClaim?.amount : DUMMY_DATA.waitingRequestApproveClaim.amount
          }
          onPress={handleViewRequestWaitingApprove}
        />
        <CardItem
          eventName={EVENT.HOME.VIEW_REPORT_LIST_NEED_TO_BE_APPROVED}
          title={`${t("reports_need_to_be_approved")} ${
            walkthroughVisible
              ? `(${DUMMY_DATA.waitingReportApproveClaim.count})`
              : waitingReportApproveClaim.count
              ? `(${waitingReportApproveClaim.count})`
              : ""
          }`}
          amount={!walkthroughVisible ? waitingReportApproveClaim.amount : DUMMY_DATA?.waitingReportApproveClaim.amount}
          style={styles.cardLastItem}
          onPress={handleViewReportWaitingApprove}
        />
      </>
    </CopilotStepCustom>
  );
});

type CardItemProps = {
  style?: ViewStyle;
  amount: number;
  title: string;
  onPress?: () => void;
  eventName?: string;
};

const CardItem: FC<CardItemProps> = ({ eventName, style, title, amount, onPress }) => {
  return (
    <TouchableOpacityCustom eventName={eventName} style={[styles.cardItem, style]} onPress={onPress}>
      <View>
        <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>
          {title}
        </AppText>
        <CurrencyText style={Fonts.NumericN200}>{amount}</CurrencyText>
      </View>
      <IconCustom width={22} height={22} name="arrow-right" fill={Colors.grayscale60} />
    </TouchableOpacityCustom>
  );
};

export default memo(ReportCard);
const styles = StyleSheet.create({
  cardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 4,
  },
  cardHeaderLeft: {
    flexDirection: "row",
    alignSelf: "flex-start",
    alignItems: "center",
  },
  cardItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayscale05,
    gap: 4,
  },
  cardLastItem: {
    borderBottomWidth: 0,
  },
  card: {
    backgroundColor: Colors.white,
    borderRadius: 16,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
    paddingVertical: 4,
  },
  flex: { flex: 1 },
});
