import { useQuery, gql } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";

const QUERY_EXPENSE_REQUEST_APPROVAL_TEMPLATE = gql`
  query MobileRequestApprovalTemplate($groupId: uuid!, $companyId: uuid!) {
    groupSettings: group_settings(
      where: {
        company_id: { _eq: $companyId }
        group_id: { _eq: $groupId }
        type: { _eq: "expense:request_approval_templates" }
      }
    ) {
      group_setting_id
      setting
    }
  }
`;

const useExpenseRequestApprovalTemplateQuery = () => {
  const { company, groupId } = useAuth();
  const results = useQuery<{ groupSettings: GroupSettings[] }>(QUERY_EXPENSE_REQUEST_APPROVAL_TEMPLATE, {
    variables: {
      groupId,
      companyId: company.company_id,
    },
    fetchPolicy: "network-only",
    skip: !groupId || !company?.company_id,
  });

  return {
    ...results,
    groupSetting: results.data?.groupSettings[0] ?? {},
  };
};

export default useExpenseRequestApprovalTemplateQuery;
