import * as React from "react";
import Svg, { G, Rect, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <G clipPath="url(#clip0_6281_73237)">
        <Rect width={24} height={24} rx={4} fill="#F0F6FF" />
        <Path d="M12 16l-6-6h12l-6 6z" fill="#1F71F4" />
      </G>
      <Defs>
        <ClipPath id="clip0_6281_73237">
          <Rect width={24} height={24} rx={4} fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
