import Svg, { Circle, Path } from "react-native-svg";

const SvgComponent = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" style={{ backgroundColor: "white" }}>
    <Circle cx={12} cy={12} r={11} fill="#E11934" stroke="white" strokeWidth={2} />
    <Path d="M9 15L15 9" stroke="white" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    <Path d="M15 15L9 9" stroke="white" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);

export default SvgComponent;
