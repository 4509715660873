import { FC, memo, useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { Colors, Fonts } from "theme";
import { AppText } from "components";
import { InfoCircleIcon } from "assets/images/svg/icons";
import { formatMoney, getTitleI18n } from "utils";
import dayjs from "dayjs";
import { BOOKING_TYPE } from "constants/constants";
import { useTranslation } from "react-i18next";

export type FlightBookingPolicy = {
  type: BOOKING_TYPE.FLIGHT;
  travelDay: string;
  maxSeatLevel: number;
  maxAdvanceBookingDays: number;
  minAdvanceBookingDays: number;
  locationPolicies: {
    location: {
      code: string;
      name: string;
      nameEn: string;
    };
    amount: number;
  }[];
};

export type TransportationPolicy = {
  type: BOOKING_TYPE.TRANSPORTATION;
  travelDay: string;
  maxAdvanceBookingDays: number;
  minAdvanceBookingDays: number;
  locationPolicies: {
    location: {
      code: string;
      name: string;
      nameEn: string;
    };
    amount: number;
  }[];
};

export type HotelPolicy = {
  type: BOOKING_TYPE.HOTEL;
  travelDay: string;
  maxHotelLevel: number;
  maxAdvanceBookingDays: number;
  minAdvanceBookingDays: number;
  locationPolicies: {
    location: {
      code: string;
      name: string;
      nameEn: string;
    };
    amount: number;
  }[];
};

type PolicyNoteProps = {
  title: string;
  data: FlightBookingPolicy | TransportationPolicy | HotelPolicy;
};

const PolicyNote: FC<PolicyNoteProps> = ({ title, data }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const contents = useMemo(() => {
    if (!data) {
      return [];
    }

    const policies = [];
    const locationPolicies = (data as any).locationPolicies ?? (data as any).locationPolices;
    if (locationPolicies) {
      // Hạn mức đặt phòng hoặc vé
      const unit = {
        [BOOKING_TYPE.HOTEL]: t("night"),
        [BOOKING_TYPE.FLIGHT]: t("trip"),
        [BOOKING_TYPE.TRANSPORTATION]: t("trip"),
      }[data.type];
      locationPolicies.forEach((p) => {
        policies.push(
          <AppText style={Fonts.BodySmall}>
            {t("limited_amount")}
            {": "}
            {`${formatMoney(p.amount)} (₫) / ${unit} ${t("for_location")} ${getTitleI18n({
              vi: p.location.name,
              en: p.location.nameEn,
            })}`}
          </AppText>
        );
      });
    }

    // Thời gian đặt phòng hoặc vé
    const entry = {
      [BOOKING_TYPE.HOTEL]: t("room"),
      [BOOKING_TYPE.FLIGHT]: t("ticket"),
      [BOOKING_TYPE.TRANSPORTATION]: t("ticket"),
    }[data.type];

    if (!!data.minAdvanceBookingDays && !!data.maxAdvanceBookingDays) {
      policies.push(
        <AppText style={Fonts.BodySmall}>
          {t("booking_interval", { entry })}
          {": "}
          {dayjs(data.travelDay).subtract(data.maxAdvanceBookingDays, "day").format("DD/MM/YYYY")}
          {" - "}
          {dayjs(data.travelDay).subtract(data.minAdvanceBookingDays, "day").format("DD/MM/YYYY")}
        </AppText>
      );
    }

    if (!!data.minAdvanceBookingDays && !data.maxAdvanceBookingDays) {
      policies.push(
        <AppText style={Fonts.BodySmall}>
          {t("booking_interval", { entry })} {t("booking_interval_before")}
          {": "}
          {dayjs(data.travelDay).subtract(data.minAdvanceBookingDays, "day").format("DD/MM/YYYY")}
        </AppText>
      );
    }

    if (!data.minAdvanceBookingDays && !!data.maxAdvanceBookingDays) {
      policies.push(
        <AppText style={Fonts.BodySmall}>
          {t("booking_interval", { entry })} {t("booking_interval_after")}
          {": "}
          {dayjs(data.travelDay).subtract(data.maxAdvanceBookingDays, "day").format("DD/MM/YYYY")}
        </AppText>
      );
    }

    // Loại phòng hoặc hạng ghế
    switch (data.type) {
      case BOOKING_TYPE.FLIGHT:
        data.maxSeatLevel &&
          policies.push(
            <AppText style={Fonts.BodySmall}>
              {t("max_seat_level")}
              {": "}
              {
                {
                  1: t("economy"),
                  2: t("deluxe_economy"),
                  3: t("business"),
                  4: t("first"),
                  5: t("suite"),
                }[data.maxSeatLevel]
              }
            </AppText>
          );
        break;
      case BOOKING_TYPE.HOTEL:
        data.maxHotelLevel &&
          policies.push(
            <AppText style={Fonts.BodySmall}>
              {t("max_hotel_level")}
              {": "}
              {
                {
                  1: t("1_star"),
                  2: t("2_star"),
                  3: t("3_star"),
                  4: t("4_star"),
                  5: t("5_star"),
                }[data.maxHotelLevel]
              }
            </AppText>
          );

        break;
    }
    return policies;
  }, [data, t]);

  if (!contents.length) {
    return null;
  }

  return (
    Boolean(data) && (
      <View style={styles.policyNote}>
        <View style={[styles.flexRow, styles.mb4]}>
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <InfoCircleIcon size={16} color={Colors.grayscale60} style={{ marginRight: 4 }} />
          <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
            {title}
          </AppText>
        </View>
        <View>
          {contents.map((item, index) => (
            <View key={index} style={styles.policyItem}>
              <View style={styles.dotContainer}>
                <View style={styles.policyItemDot} />
              </View>
              <View style={styles.grow}>{item}</View>
            </View>
          ))}
        </View>
      </View>
    )
  );
};

export default memo(PolicyNote);

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  mb4: {
    marginBottom: 4,
  },
  policyNote: {
    padding: 8,
    borderRadius: 4,
    backgroundColor: Colors.grayscale05,
  },
  policyItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2,
  },
  policyItemDot: {
    backgroundColor: Colors.grayscale100,
    width: 4,
    height: 4,
    marginVertical: 6,
    borderRadius: 99,
    marginHorizontal: 6,
  },
  dotContainer: {
    alignSelf: "stretch",
  },
  grow: {
    flex: 1,
  },
});
