import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M8.577 20.224c.437-.546 1.703-1.571 3.274-1.31 1.964.328 4.582 0 5.237-.654.524-.524 3.71-3.492 5.237-4.91.218-.327.393-1.047-.654-1.31-.328-.108-1.244-.065-2.292.983l-2.291 1.964"
        stroke="#94A3B8"
        strokeWidth={1.3}
        strokeLinecap="round"
      />
      <Rect
        x={1.91924}
        y={17.7184}
        width={2.62795}
        height={7.29078}
        rx={0.960144}
        transform="rotate(-45.023 1.92 17.718)"
        stroke="#94A3B8"
        strokeWidth={1.3}
      />
      <Path
        d="M4.977 16.296l2.946-2.291c.545-.436 2.03-1.113 3.6-.327.219.109.851.327 1.637.327h2.619c.654.11 1.833.655 1.309 1.964 0 .109-.393.327-1.964.327h-4.91"
        stroke="#94A3B8"
        strokeWidth={1.3}
        strokeLinecap="round"
      />
      <Path
        d="M16.09 8.09h-3v1.092h1.364v1.09h1.091v-1.09h.546a1.363 1.363 0 100-2.727h-2.182a.273.273 0 010-.546h3V4.82h-1.363V3.726h-1.091v1.091h-.546a1.363 1.363 0 100 2.727h2.182a.273.273 0 010 .546z"
        fill="#94A3B8"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7A6 6 0 119 7a6 6 0 0112 0zm-1.09 0a4.91 4.91 0 11-9.82 0 4.91 4.91 0 019.82 0z"
        fill="#94A3B8"
      />
    </Svg>
  );
}

export default SvgComponent;
