import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileExpEmployeeExpensePolicyTravelQuery, MobileExpEmployeeExpensePolicyTravelQueryVariables } from "types";

const QUERY = gql`
  query MobileExpEmployeeExpensePolicyTravel($employeeId: UUID!) {
    expEmployeeExpensePolicyTravel(employeeId: $employeeId) {
      perdiem {
        isEnabled
        expensePolicyPerDiems {
          expensePoliciesPerDiemId
          expensePolicyId
          expenseCategoryId
          locationId
          amount
          currency
          location {
            locationId
            code
            name
            createdAt
            updatedAt
            countryCode
            parentCode
            nameEn
          }
          expenseCategory {
            order
            title
            titleEn
            code
            expenseCategoryId
          }
          order
          scope
        }
      }
      limitation {
        isEnabled
        maxTravelDays
        period
      }
      hotelBooking {
        expenseCategory {
          code
          expenseCategoryId
          order
          title
          titleEn
        }
        isEnabled
        maxAdvanceBookingDays
        maxHotelLevel
        minAdvanceBookingDays
        policies {
          amount
          expensePoliciesBookingId
          location {
            name
            nameEn
            locationId
          }
          order
          scope
          locationId
        }
      }
      transportationBooking {
        expenseCategory {
          code
          expenseCategoryId
          order
          title
          titleEn
        }
        isEnabled
        maxAdvanceBookingDays
        minAdvanceBookingDays
        policies {
          amount
          expensePoliciesBookingId
          location {
            name
            nameEn
            locationId
          }
          order
          scope
          locationId
        }
      }
      flightBooking {
        isEnabled
        expenseCategory {
          order
          title
          titleEn
          code
          expenseCategoryId
        }
        maxSeatLevel
        minAdvanceBookingDays
        maxAdvanceBookingDays
        policies {
          amount
          expensePoliciesBookingId
          location {
            name
            nameEn
            locationId
          }
          order
          scope
          locationId
        }
      }
    }
  }
`;

const useExpEmployeeExpensePolicyTravel = (variables?: MobileExpEmployeeExpensePolicyTravelQueryVariables) => {
  return useLazyQuery<MobileExpEmployeeExpensePolicyTravelQuery, MobileExpEmployeeExpensePolicyTravelQueryVariables>(
    QUERY,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    }
  );
};

export default useExpEmployeeExpensePolicyTravel;
