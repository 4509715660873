export const EXPENSE_REQUEST_TYPE_LABEL = (t) => ({
  purchase: t("purchase_request"),
  other: t("expense_request"),
  travel: t("travel_request"),
  price_comparison: t("price_comparison"),
});
export enum REQUEST_PAYMENT_STATUS {
  PENDING = "pending",
  PAID = "paid",
}
