const EditExpenseScreen = "EditExpenseScreen";
const ExpenseDetailScreen = "ExpenseDetailScreen";
const NewPaymentRequestScreen = "NewPaymentRequestScreen";
const NotificationScreen = "NotificationScreen";
const NotificationDetailScreen = "NotificationDetailScreen";
const InboxScreen = "InboxScreen";
const InboxItemDetailScreen = "InboxItemDetailScreen";
const AuthStack = "AuthStack";
const OTPScreen = "OTPScreen";
const LoginOTPScreen = "LoginOTPScreen";
const RegisterScreen = "RegisterScreen";
const ForgotPasswordScreen = "ForgotPasswordScreen";
const NewPasswordScreen = "NewPasswordScreen";
const SelectLanguageScreen = "SelectLanguageScreen";
const AboutScreen = "AboutScreen";
const ChangePasswordScreen = "ChangePasswordScreen";
const ExpenseSearchScreen = "ExpenseSearchScreen";
const CompanyDetailScreen = "CompanyDetailScreen";
const BizziQRScreen = "BizziQRScreen";
const NewExpenseScreen = "NewExpenseScreen";
const ReportDetailScreen = "ReportDetailScreen";
const OnboardingScreen = "OnboardingScreen";
const Home = "Home";
const PhoneInputScreen = "PhoneInputScreen";
const PasswordInputScreen = "PasswordInputScreen";
const LoginScreen = "LoginScreen";
const LoginWithSSOScreen = "LoginWithSSOScreen";
const LoginWithZaloScreen = "LoginWithZaloScreen";
const AccountDeletedScreen = "AccountDeletedScreen";
const SendPaymentRequestScreen = "SendPaymentRequestScreen";
const UpdateProfileScreen = "UpdateProfileScreen";
const ProfileDetailScreen = "ProfileDetailScreen";
const AddInvoiceInfoScreen = "AddInvoiceInfoScreen";
const CompanyFormScreen = "CompanyFormScreen";
const NewCompanyScreen = "NewCompanyScreen";
const ReportsScreen = "ReportsScreen";
const NewReportScreen = "NewReportScreen";
const ViewReportScreen = "ViewReportScreen";
const EditReportScreen = "EditReportScreen";
const ExpensesScreen = "ExpensesScreen";
const CompanyScreen = "CompanyScreen";
const ProfileScreen = "ProfileScreen";
const ImageBrowserScreen = "ImageBrowserScreen";
const HomeBottomTabNavigator = "HomeBottomTabNavigator";
const ExpensesStackScreen = "ExpensesStackScreen";
const VerifyCompanyScreen = "VerifyCompanyScreen";
const CameraScreen = "CameraScreen";
const DebugScreen = "DebugScreen";
const InvoiceDetailScreen = "InvoiceDetailScreen";
const CenterScreen = "CenterScreen";
const InboxTabScreen = "InboxTabScreen";
const ImageGalleryScreen = "ImageGalleryScreen";
const EmailDetailScreen = "EmailDetailScreen";
const SuggestionAddressScreen = "SuggestionAddressScreen";
const NewEmployeeInfoScreen = "NewEmployeeInfoScreen";
const HistoryReportScreen = "HistoryReportScreen";
const ActivityReportScreen = "ActivityReportScreen";
const ApprovedReportScreen = "ApprovedReportScreen";
const RequestScreen = "RequestScreen";
const NewRequestScreen = "NewRequestScreen";
const EditRequestScreen = "EditRequestScreen";
const DetailRequestScreen = "DetailRequestScreen";
const AddExpenseToRequestScreen = "AddExpenseToRequestScreen";
const HistoryRequestScreen = "HistoryRequestScreen";
const LocalAuthenticationScreen = "LocalAuthenticationScreen";
const SendClaimedExpenseReportSuccess = "SendClaimedExpenseReportSuccess";
const DetailDashboardScreen = "DetailDashboardScreen";
const RegisterOnboardingScreen = "RegisterOnboardingScreen";
const RegisterOTPScreen = "RegisterOTPScreen";
const RegisterPasswordScreen = "RegisterPasswordScreen";
const RegisterCompanyScreen = "RegisterCompanyScreen";
const RegisterInviteColleagueScreen = "RegisterInviteColleagueScreen";
const RegisterSetupCompanyScreen = "RegisterSetupCompanyScreen";
const RegisterInformScreen = "RegisterInformScreen";
const ForgotPasswordSuccessScreen = "ForgotPasswordSuccessScreen";
const NotFound = "NotFound";
const RegisterWarningEnabledExpenseScreen = "RegisterWarningEnabledExpenseScreen";
const AddAllowanceScreen = "AddAllowanceScreen";
const DetailAllowanceScreen = "DetailAllowanceScreen";
const SelectCompanyScreen = "SelectCompanyScreen";
const SwitchCompanyScreen = "SwitchCompanyScreen";
const CardScreen = "CardScreen";
const TransactionHistoryListScreen = "TransactionHistoryListScreen";
const TransactionDetailScreen = "TransactionDetailScreen";
const VatInfoScreen = "VatInfoScreen";
const ReportSearchScreen = "ReportSearchScreen";
const WaitingApprovalScreen = "WaitingApprovalScreen";
const WaitingApprovalSearchScreen = "WaitingApprovalSearchScreen";
const RequestSearchScreen = "RequestSearchScreen";
const PolicyEmployeeScreen = "PolicyEmployeeScreen";
const ExpenseOverviewScreen = "ExpenseOverviewScreen";
const DashboardDetailScreen = "DashboardDetailScreen";
const ApprovalFlowScreen = "ApprovalFlowScreen";
const AttachmentFileScreen = "AttachmentFileScreen";
const UpdateAvatarScreen = "UpdateAvatarScreen";
const ExpenseInsightScreen = "ExpenseInsightScreen";
const CommentScreen = "CommentScreen";
const ScanQrCodeScreen = "ScanQrCodeScreen";
const FlightSearchScreen = "FlightSearchScreen";
const PassengerInfoScreen = "PassengerInfoScreen";
const LuggageScreen = "LuggageScreen";
const ReservationOverviewScreen = "ReservationOverviewScreen";
const CheckoutScreen = "CheckoutScreen";
const FlightTicketDetailScreen = "FlightTicketDetailScreen";
const ReportBatchApprovalScreen = "ReportBatchApprovalScreen";
const RequestBatchApprovalScreen = "RequestBatchApprovalScreen";
const StartGuideScreen = "StartGuideScreen";
const ActionItemDetailScreen = "ActionItemDetailScreen";
const AllocatedExpenseScreen = "AllocatedExpenseScreen";
const InvoiceSearchScreen = "InvoiceSearchScreen";

/**
 * @todo: remove when SMP-4685 had done
 */
const FilterPopupsScreen = "FilterPopupsScreen";

export type TStackScreen =
  | typeof CheckoutScreen
  | typeof ReservationOverviewScreen
  | typeof LuggageScreen
  | typeof PassengerInfoScreen
  | typeof FlightSearchScreen
  | typeof EditExpenseScreen
  | typeof ExpenseDetailScreen
  | typeof NewPaymentRequestScreen
  | typeof NotificationScreen
  | typeof NotificationDetailScreen
  | typeof InboxItemDetailScreen
  | typeof InboxScreen
  | typeof AuthStack
  | typeof OTPScreen
  | typeof LoginOTPScreen
  | typeof RegisterScreen
  | typeof ForgotPasswordScreen
  | typeof NewPasswordScreen
  | typeof SelectLanguageScreen
  | typeof AboutScreen
  | typeof ChangePasswordScreen
  | typeof ExpenseSearchScreen
  | typeof CompanyDetailScreen
  | typeof BizziQRScreen
  | typeof NewExpenseScreen
  | typeof ReportDetailScreen
  | typeof OnboardingScreen
  | typeof PasswordInputScreen
  | typeof LoginScreen
  | typeof AccountDeletedScreen
  | typeof PhoneInputScreen
  | typeof SendPaymentRequestScreen
  | typeof UpdateProfileScreen
  | typeof ProfileDetailScreen
  | typeof AddInvoiceInfoScreen
  | typeof CompanyFormScreen
  | typeof NewCompanyScreen
  | typeof CompanyScreen
  | typeof ImageBrowserScreen
  | typeof HomeBottomTabNavigator
  | typeof ExpensesStackScreen
  | typeof NewReportScreen
  | typeof ViewReportScreen
  | typeof EditReportScreen
  | typeof VerifyCompanyScreen
  | typeof CameraScreen
  | typeof InvoiceDetailScreen
  | typeof DebugScreen
  | typeof ReportsScreen
  | typeof ProfileScreen
  | typeof ImageGalleryScreen
  | typeof EmailDetailScreen
  | typeof SuggestionAddressScreen
  | typeof NewEmployeeInfoScreen
  | typeof HistoryReportScreen
  | typeof ApprovedReportScreen
  | typeof InboxTabScreen
  | typeof NewRequestScreen
  | typeof DetailRequestScreen
  | typeof AddExpenseToRequestScreen
  | typeof ActivityReportScreen
  | typeof EditRequestScreen
  | typeof HistoryRequestScreen
  | typeof LocalAuthenticationScreen
  | typeof SendClaimedExpenseReportSuccess
  | typeof DetailDashboardScreen
  | typeof RegisterOnboardingScreen
  | typeof RegisterOTPScreen
  | typeof RegisterPasswordScreen
  | typeof RegisterCompanyScreen
  | typeof RegisterInviteColleagueScreen
  | typeof RegisterSetupCompanyScreen
  | typeof RegisterInformScreen
  | typeof ForgotPasswordSuccessScreen
  | typeof NotFound
  | typeof RegisterWarningEnabledExpenseScreen
  | typeof AddAllowanceScreen
  | typeof DetailAllowanceScreen
  | typeof SelectCompanyScreen
  | typeof SwitchCompanyScreen
  | typeof TransactionHistoryListScreen
  | typeof TransactionDetailScreen
  | typeof VatInfoScreen
  | typeof ReportSearchScreen
  | typeof WaitingApprovalScreen
  | typeof WaitingApprovalSearchScreen
  | typeof RequestSearchScreen
  | typeof FilterPopupsScreen
  | typeof PolicyEmployeeScreen
  | typeof ExpenseOverviewScreen
  | typeof DashboardDetailScreen
  | typeof ApprovalFlowScreen
  | typeof AttachmentFileScreen
  | typeof UpdateAvatarScreen
  | typeof ExpenseInsightScreen
  | typeof CommentScreen
  | typeof ScanQrCodeScreen
  | typeof FlightTicketDetailScreen
  | typeof ReportBatchApprovalScreen
  | typeof RequestBatchApprovalScreen
  | typeof StartGuideScreen
  | typeof ActionItemDetailScreen
  | typeof LoginWithSSOScreen
  | typeof LoginWithZaloScreen
  | typeof AllocatedExpenseScreen
  | typeof InvoiceSearchScreen;

type TBottomTabScreen =
  | typeof Home
  | typeof CenterScreen
  | typeof ExpensesScreen
  | typeof ReportsScreen
  | typeof RequestScreen
  | typeof CardScreen;

export type TListStackScreen = {
  [K in TStackScreen]: K;
};

export type TListBottomTabScreen = {
  [K in TBottomTabScreen]: K;
};

const SCREEN_NAME: TListStackScreen = {
  CheckoutScreen,
  ReservationOverviewScreen,
  LuggageScreen,
  PassengerInfoScreen,
  FlightSearchScreen,
  EditExpenseScreen,
  ExpenseDetailScreen,
  NewPaymentRequestScreen,
  NotificationScreen,
  NotificationDetailScreen,
  InboxItemDetailScreen,
  InboxScreen,
  AuthStack,
  OTPScreen,
  LoginOTPScreen,
  RegisterScreen,
  ForgotPasswordScreen,
  NewPasswordScreen,
  SelectLanguageScreen,
  AboutScreen,
  ChangePasswordScreen,
  ExpenseSearchScreen,
  CompanyDetailScreen,
  BizziQRScreen,
  NewExpenseScreen,
  ReportDetailScreen,
  OnboardingScreen,
  PasswordInputScreen,
  LoginScreen,
  AccountDeletedScreen,
  PhoneInputScreen,
  SendPaymentRequestScreen,
  UpdateProfileScreen,
  AddInvoiceInfoScreen,
  CompanyFormScreen,
  NewCompanyScreen,
  CompanyScreen,
  ImageBrowserScreen,
  HomeBottomTabNavigator,
  ExpensesStackScreen,
  NewReportScreen,
  ViewReportScreen,
  EditReportScreen,
  VerifyCompanyScreen,
  CameraScreen,
  DebugScreen,
  InvoiceDetailScreen,
  ReportsScreen,
  ProfileScreen,
  ImageGalleryScreen,
  EmailDetailScreen,
  SuggestionAddressScreen,
  NewEmployeeInfoScreen,
  HistoryReportScreen,
  ActivityReportScreen,
  ApprovedReportScreen,
  InboxTabScreen,
  NewRequestScreen,
  DetailRequestScreen,
  AddExpenseToRequestScreen,
  EditRequestScreen,
  HistoryRequestScreen,
  LocalAuthenticationScreen,
  SendClaimedExpenseReportSuccess,
  DetailDashboardScreen,
  RegisterOnboardingScreen,
  RegisterOTPScreen,
  RegisterPasswordScreen,
  RegisterCompanyScreen,
  RegisterInviteColleagueScreen,
  RegisterSetupCompanyScreen,
  RegisterInformScreen,
  ForgotPasswordSuccessScreen,
  NotFound,
  RegisterWarningEnabledExpenseScreen,
  AddAllowanceScreen,
  DetailAllowanceScreen,
  SelectCompanyScreen,
  SwitchCompanyScreen,
  TransactionHistoryListScreen,
  TransactionDetailScreen,
  VatInfoScreen,
  ReportSearchScreen,
  RequestSearchScreen,
  FilterPopupsScreen,
  WaitingApprovalScreen,
  WaitingApprovalSearchScreen,
  PolicyEmployeeScreen,
  ProfileDetailScreen,
  ExpenseOverviewScreen,
  DashboardDetailScreen,
  ApprovalFlowScreen,
  AttachmentFileScreen,
  UpdateAvatarScreen,
  ExpenseInsightScreen,
  CommentScreen,
  ScanQrCodeScreen,
  FlightTicketDetailScreen,
  ReportBatchApprovalScreen,
  RequestBatchApprovalScreen,
  StartGuideScreen,
  ActionItemDetailScreen,
  LoginWithSSOScreen,
  AllocatedExpenseScreen,
  LoginWithZaloScreen,
  InvoiceSearchScreen,
};

const SCREEN_BOTTOM_TAB: TListBottomTabScreen = {
  Home,
  ExpensesScreen,
  CenterScreen,
  ReportsScreen,
  RequestScreen,
  CardScreen,
};

export default SCREEN_NAME;
const SCREEN_NAME_TRACKING = {
  [SCREEN_NAME.EditExpenseScreen]: "Expense Update Screen",
  [SCREEN_NAME.ExpenseDetailScreen]: "Expense Detail Screen",
  [SCREEN_NAME.NotificationScreen]: "Notification List Screen",
  [SCREEN_NAME.InboxScreen]: "Inbox Screen",
  [SCREEN_NAME.OTPScreen]: "OTP Screen",
  [SCREEN_NAME.LoginOTPScreen]: "Login OTP Screen",
  [SCREEN_NAME.RegisterScreen]: "Register Screen",
  [SCREEN_NAME.ForgotPasswordScreen]: "Forgot Password Screen",
  [SCREEN_NAME.CompanyDetailScreen]: "Invoice Information Screen",
  [SCREEN_NAME.BizziQRScreen]: "QR Code Screen",
  [SCREEN_NAME.NewExpenseScreen]: "Expense Create Screen",
  [SCREEN_NAME.ReportDetailScreen]: "Report Detail Screen",
  [SCREEN_NAME.OnboardingScreen]: "Onboarding Screen",
  [SCREEN_NAME.LoginScreen]: "Login Screen",
  [SCREEN_NAME.LoginWithSSOScreen]: "Login With SSO Screen",
  [SCREEN_NAME.LoginWithZaloScreen]: "Login With Zalo Screen",
  [SCREEN_NAME.AccountDeletedScreen]: "Account Deleted Screen",
  [SCREEN_NAME.CompanyFormScreen]: "Company Update Screen",
  [SCREEN_NAME.NewReportScreen]: "Report Create Screen",
  [SCREEN_NAME.EditReportScreen]: "Report Update Screen",
  [SCREEN_NAME.CameraScreen]: "Camera Screen",
  [SCREEN_NAME.InvoiceDetailScreen]: "Invoice Detail Screen",
  [SCREEN_BOTTOM_TAB.Home]: "Home Screen",
  [SCREEN_BOTTOM_TAB.ReportsScreen]: "Report List Screen",
  [SCREEN_BOTTOM_TAB.RequestScreen]: "Request List Screen",
  [SCREEN_BOTTOM_TAB.ExpensesScreen]: "Expense List Screen",
  [SCREEN_BOTTOM_TAB.CardScreen]: "Card Screen",
  [SCREEN_NAME.ProfileScreen]: "Profile Screen",
  [SCREEN_NAME.EmailDetailScreen]: "Email Detail Screen",
  [SCREEN_NAME.SuggestionAddressScreen]: "Address Search Screen",
  [SCREEN_NAME.HistoryReportScreen]: "Report History Screen",
  [SCREEN_NAME.NewRequestScreen]: "Request Create Screen",
  [SCREEN_NAME.DetailRequestScreen]: "Request Detail Screen",
  [SCREEN_NAME.EditRequestScreen]: "Request Update Screen",
  [SCREEN_NAME.HistoryRequestScreen]: "Request History Screen",
  [SCREEN_NAME.LocalAuthenticationScreen]: "Authentication Screen",
  [SCREEN_NAME.AddAllowanceScreen]: "Add Allowance Screen",
  [SCREEN_NAME.DetailAllowanceScreen]: "Detail Allowance Screen",
  [SCREEN_NAME.SelectCompanyScreen]: "Select Company Screen",
  [SCREEN_NAME.SwitchCompanyScreen]: "Switch Company Screen",
  [SCREEN_NAME.TransactionHistoryListScreen]: "Transaction List Screen",
  [SCREEN_NAME.TransactionDetailScreen]: "Transaction Detail Screen",
  [SCREEN_NAME.VatInfoScreen]: "Vat Info Screen",
  [SCREEN_NAME.ReportSearchScreen]: "Report Search Screen",
  [SCREEN_NAME.RequestSearchScreen]: "Request Search Screen",
  [SCREEN_NAME.WaitingApprovalScreen]: "Waiting Approval Screen",
  [SCREEN_NAME.WaitingApprovalSearchScreen]: "Waiting Approval Search Screen",
  [SCREEN_NAME.PolicyEmployeeScreen]: "Policy Employee Screen",
  [SCREEN_NAME.ExpenseOverviewScreen]: "Admin Dashboard Screen",
  [SCREEN_NAME.DashboardDetailScreen]: "Admin dashboard Detail Screen",
  [SCREEN_NAME.ApprovalFlowScreen]: "Approval Flow Screen",
  [SCREEN_NAME.AttachmentFileScreen]: "Attachment File Screen",
  [SCREEN_NAME.UpdateAvatarScreen]: "Avatar Update Screen",
  [SCREEN_NAME.ExpenseInsightScreen]: "Personal Dashboard Screen",
  [SCREEN_NAME.CommentScreen]: "Comment Screen",
  [SCREEN_NAME.ProfileDetailScreen]: "Profile Detail Screen",
  [SCREEN_NAME.ExpenseSearchScreen]: "Expense Search Screen",
  [SCREEN_NAME.ScanQrCodeScreen]: "Scan QR Code Screen",
  [SCREEN_NAME.RequestBatchApprovalScreen]: "Batch Approval Request Screen",
  [SCREEN_NAME.ReportBatchApprovalScreen]: "Batch Approval Report Screen",
  [SCREEN_NAME.StartGuideScreen]: "Start Guide Screen",
  [SCREEN_NAME.ActionItemDetailScreen]: "Action Item Detail Screen",
  [SCREEN_NAME.AllocatedExpenseScreen]: "Allocated Expense Screen",
  [SCREEN_NAME.InvoiceSearchScreen]: "Invoice Search Screen",
};
export { SCREEN_BOTTOM_TAB, SCREEN_NAME_TRACKING };
export type { TBottomTabScreen };
