import { useTranslation } from "react-i18next";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";

import { CheckBoxMinus } from "assets/images/svg/icons";
import { AppText, CheckBox, FloatFooter, Button } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { TouchableOpacity } from "react-native-gesture-handler";
import { SelectAllStatus } from "hooks/useSelectList";
import { FC, ReactElement, ReactNode } from "react";

type BatchApprovalLayoutProps = {
  selectStatus: SelectAllStatus;
  selected: Set<string>;
  onApprove: () => void;
  onReject: () => void;
  onAllButtonPress: () => void;
  onFooterLayout?: (event: LayoutChangeEvent) => void;
  summaryComponent: ReactElement;
  children: ReactNode;
  sortingComponent?: ReactElement;
};

const BatchApprovalLayout: FC<BatchApprovalLayoutProps> = ({
  selected,
  selectStatus,
  children,
  onApprove,
  onReject,
  onAllButtonPress,
  onFooterLayout,
  summaryComponent,
  sortingComponent,
}) => {
  const { t } = useTranslation("app/components/BatchApprovalLayout/BatchApprovalLayout");

  return (
    <View style={styles.container}>
      <View style={styles.floatHeaderContainer}>
        <TouchableOpacity style={styles.selectAllContainer} onPress={onAllButtonPress}>
          {
            {
              [SelectAllStatus.ALL]: <CheckBox checked />,
              [SelectAllStatus.SOME]: <CheckBoxMinus width={26} height={26} />,
              [SelectAllStatus.NONE]: <CheckBox />,
            }[selectStatus]
          }
          <AppText style={Fonts.BodyMedium}>{t("select_all")}</AppText>
        </TouchableOpacity>
        {sortingComponent && <View style={styles.sortingContainer}>{sortingComponent}</View>}
      </View>

      {children}

      <FloatFooter onLayout={onFooterLayout}>
        {summaryComponent}
        <View style={styles.wrapperButtonContainer}>
          <Button type="danger" onPress={onReject} style={styles.footerButton} disabled={!selected.size}>
            {t("reject")}
          </Button>
          <Button type="success" onPress={onApprove} style={styles.footerButton} disabled={!selected.size}>
            {t("approve")}
          </Button>
        </View>
      </FloatFooter>
    </View>
  );
};

export default BatchApprovalLayout;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  floatHeaderContainer: {
    paddingVertical: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    gap: 8,
  },
  selectAllContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  wrapperButtonContainer: {
    marginTop: 3,
    backgroundColor: Colors.while,
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "center",
    gap: 8,
  },
  footerButton: {
    flex: 1,
  },
  sortingContainer: {
    paddingVertical: 4,
  },
});
