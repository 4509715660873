import gql from "graphql-tag";
import { QueryHookOptions, useLazyQuery } from "@apollo/client";
import { MobileExpenseReportDetailsQuery, MobileExpenseReportDetailsQueryVariables } from "types";

const QUERY_EXPENSE_REPORT = gql`
  query MobileExpenseReportDetails($expenseReportId: UUID!) {
    expExpenseReportDetails(expenseReportId: $expenseReportId) {
      title
      paymentStatus
      docSequence
      allocatedStatus
      expectedPaymentDate
      parent {
        docSequence
        title
        companyEmployee {
          fullname
        }
        expenseReportsTeams {
          companyTeam {
            code
            name
            nameEn
          }
        }
        expenseApprovalRules {
          settings {
            approvalFlow {
              title
            }
          }
          order
          status
          name
          condition
          expenseApprovalRuleId
          approvalType
          createdAt
          updatedAt
          expenseApprovalRuleEmployees {
            approvalMethod
            status
            employeeId
            employee {
              employeeId
              userId
              email
              phone
              fullname
              avatarUrl
              defaultAvatarUrl
            }
          }
        }
      }
      paymentMethod
      expenseReportId
      status
      paidAt
      paymentMethod
      approvedAt
      rejectedAt
      createdAt
      requestedAt
      user {
        userId
        email
        fullname
      }
      companyEmployee {
        employeeId
        fullname
        email
        avatarUrl
        defaultAvatarUrl
      }
      company {
        name
        accountantEmail
        address
        taxCode
        companyId
      }
      expenseReportsTeams {
        companyTeamId
        expenseReportId
        expenseReportTeamId
        companyTeam {
          companyTeamId
          code
          name
          status
          nameEn
          deletedAt
        }
      }
      expenseApprovalLogs {
        isPartialApproved
        rejectedExpenseReport {
          expenseReportId
          docSequence
        }
        status
        userId
        reason
        createdAt
        expenseApprovalLogId
        companyEmployeeId
        expenseApprovalRuleId
        user {
          userId
          email
          fullname
        }
        companyEmployee {
          employeeId
          fullname
          avatarUrl
          defaultAvatarUrl
        }
      }
      expenseApprovalRules {
        settings {
          approvalFlow {
            title
          }
        }
        order
        status
        name
        condition
        expenseApprovalRuleId
        approvalType
        createdAt
        updatedAt
        expenseApprovalRuleEmployees {
          approvalMethod
          status
          employeeId
          employee {
            employeeId
            userId
            email
            phone
            fullname
            avatarUrl
            defaultAvatarUrl
          }
        }
      }
      expenses {
        currency
        exchangeRate
        foreignTotalAmountWithVat
        allocatedStatus
        docSequence
        expenseCardTransactions {
          cardTransaction {
            cardTransactionId
            amount
          }
        }
        expenseRequestId
        status
        note
        address
        expenseId
        provider
        category
        invoiceType
        companyTeamId
        invoice {
          emailId
          invoiceId
          invoiceNumber
          receivedAt
          buyerDisplayName
          buyerLegalName
          buyerTaxCode
          sellerAddressLine
          sellerLegalName
          sellerTaxCode
          totalAmountWithVat
          totalVatAmount
          invoiceItems {
            invoiceItemId
            itemName
          }
        }
        expenseDate
        expenseTravelId
        expenseReportId
        expenseInfo
        receivedInvoice
        totalAmountWithVat
        totalAmountWithoutVat
        customFields
        expenseTravel {
          expenseTravelId
        }
        expenseReport {
          status
          title
          companyId
          docSequence
          rejectedReason
          expenseReportId
        }
        expenseRequest {
          title
          docSequence
          expenseRequestId
          expenseRequestType
          totalAmount
          cashAdvances {
            amount
          }
        }
        expenseCategory {
          order
          title
          titleEn
          expenseCategoryId
        }
        expenseAttachments {
          type
          fileName
          fileUrl
          expenseAttachmentId
        }
      }
      companyAttachments {
        companyAttachmentId
        objectId
        objectType
        attachmentType
        fileName
        fileUrl
        thumbnailUrl
        mimeType
        fileSize
        fileMetadata
        customFields
        fileObject
        createdAt
        createdBy
      }
      xlsx: generatedDocument(documementType: xlsx) {
        status
        url
      }
      pdf: generatedDocument(documementType: pdf) {
        status
        url
      }
      zip: generatedDocument(documementType: zip) {
        status
        url
      }
    }
  }
`;

export const useExpExpenseReportDetailsQuery = (
  options?: QueryHookOptions<MobileExpenseReportDetailsQuery, MobileExpenseReportDetailsQueryVariables>
) => {
  const [onFetchReportDetail, { data, ...rest }] = useLazyQuery(QUERY_EXPENSE_REPORT, {
    ...options,
    fetchPolicy: "network-only",
  });

  return { ...rest, data, onFetchReportDetail };
};
