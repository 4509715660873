import * as React from "react";
import Svg, { G, Rect, Path, Defs, ClipPath } from "react-native-svg";
import { Colors } from "theme";

function SvgComponent({ size = 24, fill = Colors.primary50, radius = 4, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <G clipPath="url(#clip0_6790_440619)">
        <Rect width={size} height={size} rx={radius} fill={fill} />
        <Path
          d="M12.707 15.293a1 1 0 01-1.414 0l-3.586-3.586c-.63-.63-.184-1.707.707-1.707h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586z"
          fill={Colors.grayscale0}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_6790_440619">
          <Rect width={size} height={size} rx={radius} fill={Colors.white} />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
