import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={26} height={20} viewBox="0 0 26 20">
      <Path
        d="M2.742 20a2.326 2.326 0 01-1.71-.7 2.326 2.326 0 01-.7-1.71V2.41c0-.673.233-1.243.7-1.71a2.327 2.327 0 011.71-.7h20.513c.674 0 1.244.233 1.71.7.467.467.7 1.037.7 1.71v15.18c0 .673-.233 1.243-.7 1.71-.466.467-1.037.7-1.71.7H2.742zM13 10.523c.11 0 .221-.017.33-.05.11-.033.216-.08.318-.137l9.536-6.105a.786.786 0 00.323-.366.857.857 0 00.057-.473c-.017-.307-.168-.536-.451-.686-.284-.15-.57-.136-.86.04L13 8.666l-9.254-5.92c-.29-.176-.573-.19-.853-.042a.803.803 0 00-.457.675.942.942 0 00.056.5.68.68 0 00.323.352l9.536 6.105c.103.058.209.104.318.137.11.033.22.05.33.05z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
