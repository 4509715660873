import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#D9D9D9" d="M0.399414 0.281738H24.399414V24.281738H0.399414z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M5.707 21.782c-.498 0-.925-.177-1.278-.53a1.742 1.742 0 01-.53-1.278V8.776a1.906 1.906 0 01-.72-.645c-.186-.28-.28-.6-.28-.965V4.59c0-.498.177-.924.53-1.278.354-.353.78-.53 1.278-.53h15.385c.498 0 .924.177 1.278.53.353.354.53.78.53 1.278v2.577c0 .364-.094.686-.28.965a1.906 1.906 0 01-.72.645v11.198c0 .499-.177.925-.53 1.278-.354.353-.78.53-1.278.53H5.707zM5.4 8.974v10.952a.33.33 0 00.11.255.392.392 0 00.275.1h13.308a.3.3 0 00.22-.086.3.3 0 00.087-.221v-11h-14zm-.692-1.5h15.385a.3.3 0 00.22-.087.3.3 0 00.087-.22V4.588a.3.3 0 00-.086-.22.3.3 0 00-.221-.087H4.707a.3.3 0 00-.221.086.3.3 0 00-.087.221v2.577a.3.3 0 00.087.221.3.3 0 00.221.087zm5.606 6.24h4.183a.683.683 0 00.51-.205.708.708 0 00.201-.516.692.692 0 00-.2-.51.692.692 0 00-.511-.201h-4.193a.692.692 0 00-.51.2.692.692 0 00-.201.511.7.7 0 00.72.721z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
