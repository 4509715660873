import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { AppText, useWindowDimensions } from "components";
import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { isEqual } from "lodash";

type BadgeSelectProps = {
  layoutCols?: number;
  label: string;
  value?: SelectItem["value"][];
  options: SelectItem[];
  onChange?: (item: number[]) => void;
};

const BadgeSelect: FC<BadgeSelectProps> = ({ layoutCols, label, value, options, onChange }) => {
  const { width } = useWindowDimensions();
  const [selected, setSelected] = useState<SelectItem["value"][]>([]);
  useEffect(() => {
    setSelected(value ? value : []);
  }, [value]);
  const handleSelect = (value: SelectItem["value"]) => () => {
    const isSelected = selected?.findIndex((item) => item === value) !== -1;
    let newSelected = [...selected];
    if (isSelected) {
      newSelected = newSelected.filter((item) => item !== value);
    } else {
      newSelected = [...newSelected, ...[value]];
    }
    onChange(newSelected);
  };

  const calculateItemWidth = useMemo(() => {
    if (!layoutCols) {
      return "auto";
    }
    const containerWidth = width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING;
    const availableWidth = containerWidth - (layoutCols - 1) * styles.itemContainer.gap;
    return availableWidth / layoutCols;
  }, [width, layoutCols]);

  return (
    <View style={styles.container}>
      <AppText style={Fonts.H200} color={Colors.grayscale100}>
        {label}
      </AppText>
      <View style={styles.itemContainer}>
        {options.map((item, index) => {
          const isSelected = selected?.findIndex((i) => i === item?.value) !== -1;
          const isDisabled = item?.disabled;
          return (
            <TouchableOpacity
              disabled={isDisabled}
              key={index}
              style={[
                styles.item,
                { width: calculateItemWidth },
                isSelected && styles.selectedItem,
                isDisabled && { backgroundColor: Colors.grayscale05, borderWidth: 0 },
              ]}
              onPress={handleSelect(item?.value)}
            >
              <AppText
                style={[Fonts.BodySmall, styles.itemText]}
                color={isSelected ? Colors.primary50 : isDisabled ? Colors.grayscale40 : Colors.grayscale100}
              >
                {item.label}
              </AppText>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

export default memo(BadgeSelect);

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  itemContainer: {
    alignContent: "stretch",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
  },
  item: {
    width: "auto",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    borderRadius: 20,
    justifyContent: "center",
  },
  itemText: {
    textAlign: "center",
  },
  selectedItem: {
    borderColor: Colors.primary50,
  },
});
