import {
  AppText,
  BadgeSelect,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  IconCustom,
  ImageCustom,
  RangeSlider,
} from "components";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { CONSTANTS } from "constants/constants";
import React, { FC, ReactElement, useMemo, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import SelectList from "../../components/SelectList";
import { BokCabinClass, FlightTicket } from "screens/FlightBooking/types";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";
import {
  FilterFlightTicket,
  FilterFlightTicketStringKeys,
  FlightTicketFilter,
} from "screens/FlightBooking/FlightSearchScreen/types";
import {
  filterByAmount,
  filterByCabinClass,
  filterByLandingTime,
  filterByTakeOffTime,
  filterByVendor,
  filterTicketByNumOfStop,
  formatTicketListToFilter,
  getLandingTimeOptions,
  getNumberStopOptions,
  getTakeOffTimeOptions,
} from "screens/FlightBooking/FlightSearchScreen/helpers";
import { Colors, Fonts } from "theme";

type TicketFilterModalProps = {
  button: ReactElement;
  minAmount: number;
  maxAmount: number;
  tickets: FlightTicket[];
  setTickets?: (tickets: FlightTicket[]) => void;
  departureDate?: string;
  scrollToTop?: () => void;
};

const FlightTicketCabinClass = {
  [BokCabinClass.Economy]: "cabin_class_economy",
  [BokCabinClass.DeluxeEconomy]: "cabin_class_deluxe_economy",
  [BokCabinClass.Business]: "cabin_class_business",
  [BokCabinClass.First]: "cabin_class_first",
  [BokCabinClass.Suites]: "cabin_class_suites",
};

const TicketFilterModal: FC<TicketFilterModalProps> = ({
  departureDate,
  button,
  minAmount,
  maxAmount,
  tickets,
  setTickets,
  scrollToTop,
}) => {
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const { t } = useTranslation("app/screens/FlightBooking/FlightSearchScreen");
  const ticketListNotFilter = useRef<FlightTicketFilter[]>([]);
  const [currentFilter, setCurrentFilter] = useState<FilterFlightTicket>({ minAmount, maxAmount });
  const [currentTickets, setCurrentTickets] = useState<FlightTicket[]>([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const handleOpenModal = () => {
    setCurrentTickets(tickets);
    setCurrentFilter((prevState) => ({ minAmount, maxAmount, ...prevState }));
    bottomSheetRef?.current?.present();
    //set ticket list not filter to reset ticket list when clear filter
    if (!ticketListNotFilter?.current?.length) {
      ticketListNotFilter.current = formatTicketListToFilter(tickets, departureDate);
    }
  };

  const vendorOptions = useMemo<SelectItem[]>(() => {
    if (!ticketListNotFilter?.current?.length) {
      return [];
    }
    return uniqBy(ticketListNotFilter?.current, "airline.code").map((item) => ({
      label: item?.airline?.name,
      value: item?.airline?.code,
      leftIcon: (
        <ImageCustom contentFit="contain" source={{ uri: item?.airline?.logoUrl }} style={styles.selectItemIcon} />
      ),
    }));
  }, [ticketListNotFilter?.current]);
  const seatClassOptions = useMemo<SelectItem[]>(() => {
    if (!ticketListNotFilter?.current?.length) {
      return [];
    }
    return uniqBy(ticketListNotFilter?.current, "flights.cabinClass").map((item) => ({
      label: t(FlightTicketCabinClass[item?.flights?.[0]?.cabinClass]),
      value: item?.flights?.[0]?.cabinClass,
      leftIcon: <IconCustom name="chair-alt" />,
    }));
  }, [ticketListNotFilter?.current]);

  const onClearFilter = () => {
    bottomSheetRef?.current?.close();
    setTickets(ticketListNotFilter.current);
    setCurrentFilter({ minAmount: minAmount, maxAmount: maxAmount });
    setIsFiltering(false);
  };
  const onApplyFilter = () => {
    bottomSheetRef?.current?.close();
    setIsFiltering(true);
    setTickets(currentTickets);
  };
  const onChangeFilter = (key: FilterFlightTicketStringKeys, value) => {
    const { minAmount, maxAmount } = currentFilter;
    let newTicketsFilter = filterByAmount(ticketListNotFilter.current, { min: minAmount, max: maxAmount });
    /*-- first: filter ticket list by current filter --*/
    if (key !== "numberOfStop" && typeof currentFilter.numberOfStop !== "undefined") {
      newTicketsFilter = filterTicketByNumOfStop(newTicketsFilter, currentFilter?.numberOfStop);
    }
    if (key !== "takeOffTimeGroups" && currentFilter?.takeOffTimeGroups?.length) {
      newTicketsFilter = filterByTakeOffTime(newTicketsFilter, currentFilter.takeOffTimeGroups);
    }
    if (key !== "landingTimeGroups" && currentFilter?.landingTimeGroups?.length) {
      newTicketsFilter = filterByLandingTime(newTicketsFilter, currentFilter.landingTimeGroups);
    }
    if (key !== "vendors" && currentFilter?.vendors?.length) {
      newTicketsFilter = filterByVendor(newTicketsFilter, currentFilter.vendors);
    }
    if (key !== "cabinClass" && currentFilter?.cabinClass?.length) {
      newTicketsFilter = filterByCabinClass(newTicketsFilter, currentFilter.cabinClass);
    }
    /*--- end ---*/

    switch (key) {
      case "maxAmount": {
        setCurrentFilter((prevState) => ({ ...prevState, minAmount: value.min, maxAmount: value.max }));
        newTicketsFilter = filterByAmount(newTicketsFilter, value);
        break;
      }
      case "numberOfStop": {
        setCurrentFilter((prevState) => ({ ...prevState, numberOfStop: value }));
        newTicketsFilter = filterTicketByNumOfStop(newTicketsFilter, value);
        break;
      }
      case "takeOffTimeGroups": {
        setCurrentFilter((prevState) => ({
          ...prevState,
          takeOffTimeGroups: value,
        }));
        newTicketsFilter = filterByTakeOffTime(newTicketsFilter, value);
        break;
      }
      case "landingTimeGroups": {
        setCurrentFilter((prevState) => ({
          ...prevState,
          landingTimeGroups: value,
        }));
        newTicketsFilter = filterByLandingTime(newTicketsFilter, value);
        break;
      }
      case "vendors": {
        setCurrentFilter((prevState) => ({ ...prevState, vendors: value }));
        newTicketsFilter = filterByVendor(newTicketsFilter, value);
        break;
      }
      case "cabinClass": {
        setCurrentFilter((prevState) => ({ ...prevState, cabinClass: value }));
        newTicketsFilter = filterByCabinClass(newTicketsFilter, value);
        break;
      }
    }
    setCurrentTickets(newTicketsFilter);
    scrollToTop();
  };
  const countFilter = useMemo(() => {
    if (!isFiltering) {
      return 0;
    }
    let totalFilter = 0;
    if (
      (typeof currentFilter?.minAmount !== "undefined" && currentFilter.minAmount !== minAmount) ||
      (typeof currentFilter?.maxAmount !== "undefined" && currentFilter.maxAmount !== maxAmount)
    ) {
      totalFilter += 1;
    }
    if (currentFilter?.numberOfStop?.length) {
      totalFilter += 1;
    }
    if (currentFilter?.landingTimeGroups?.length) {
      totalFilter += 1;
    }
    if (currentFilter?.takeOffTimeGroups?.length) {
      totalFilter += 1;
    }
    if (currentFilter?.cabinClass?.length) {
      totalFilter += 1;
    }
    if (currentFilter?.vendors?.length) {
      totalFilter += 1;
    }
    return totalFilter;
  }, [currentFilter, isFiltering]);
  return (
    <>
      <TouchableOpacity onPress={handleOpenModal}>
        {button}
        {countFilter > 0 && (
          <View style={styles.filterDot}>
            <AppText style={Fonts.BodyXSmall} color={Colors.white}>
              {countFilter}
            </AppText>
          </View>
        )}
      </TouchableOpacity>

      <BottomSheetScrollViewModalCustom
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title="Lọc kết quả"
        wrapperByScrollView={true}
        ref={bottomSheetRef}
        renderFooter={() => (
          <View style={styles.footer}>
            <AppText>{`Xem kết quả ${currentTickets.length} chuyến bay`}</AppText>
            <View style={styles.footerButton}>
              <Button style={styles.flex} type="secondary" onPress={onClearFilter}>
                {t("clear_filter")}
              </Button>
              <Button style={styles.flex} onPress={onApplyFilter} disabled={!currentTickets?.length}>
                {t("apply")}
              </Button>
            </View>
          </View>
        )}
      >
        <View style={styles.contentContainer}>
          <RangeSlider
            label={t("price_range")}
            min={minAmount}
            max={maxAmount}
            defaultLow={currentFilter?.minAmount || minAmount}
            defaultHigh={currentFilter?.maxAmount || maxAmount}
            step={Math.min(10000, maxAmount)}
            onValueChange={({ min, max }) => onChangeFilter("maxAmount", { min, max })}
          />
          <BadgeSelect
            label={t("number_of_transit")}
            value={currentFilter?.numberOfStop}
            options={getNumberStopOptions(ticketListNotFilter.current)}
            onChange={(value) => onChangeFilter("numberOfStop", value)}
          />
          <BadgeSelect
            label={t("takeoff_time")}
            value={currentFilter?.takeOffTimeGroups}
            options={getTakeOffTimeOptions(ticketListNotFilter.current)}
            layoutCols={2}
            onChange={(value) => onChangeFilter("takeOffTimeGroups", value)}
          />
          <BadgeSelect
            label={t("landing_time")}
            value={currentFilter?.landingTimeGroups}
            options={getLandingTimeOptions(ticketListNotFilter.current)}
            layoutCols={2}
            onChange={(value) => onChangeFilter("landingTimeGroups", value)}
          />
          <SelectList
            title={t("airlines")}
            value={currentFilter?.vendors}
            options={vendorOptions}
            emptyState={
              <AppText color={Colors.grayscale60} style={{ textAlign: "center" }}>
                {t("empty_airlines")}
              </AppText>
            }
            onChange={(value) => onChangeFilter("vendors", value)}
          />
          <SelectList
            title={t("cabin_class")}
            value={currentFilter?.cabinClass}
            options={seatClassOptions}
            emptyState={
              <AppText color={Colors.grayscale60} style={{ textAlign: "center" }}>
                {t("empty_cabin_class")}
              </AppText>
            }
            onChange={(value) => onChangeFilter("cabinClass", value)}
          />
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};

export default TicketFilterModal;

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 16,
    paddingBottom: 100,
    gap: 20,
  },
  selectItemIcon: {
    width: 32,
    height: 32,
  },
  footer: {
    marginTop: 10,
  },
  footerButton: {
    flexDirection: "row",
    gap: 8,
    marginTop: 14,
  },
  flex: { flex: 1 },

  filterDot: {
    position: "absolute",
    right: -3,
    top: -5,
    backgroundColor: Colors.info50,
    borderRadius: 10,
    height: 16,
    width: 16,
    justifyContent: "center",
    alignItems: "center",
  },
});
