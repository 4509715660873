import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const QUERY_VENDOR_EXPENSE_CATEGORY = gql`
  query MobilePartnerExpenseCategoriesByTaxCode($input: ExpPartnerExpenseCategoriesByTaxCodeInput!) {
    expPartnerExpenseCategoriesByTaxCode(input: $input) {
      partnerExpenseCategories {
        isActive
        expenseCategoryId
        expenseCategory {
          code
          title
          titleEn
        }
      }
    }
  }
`;

type QueryMobilePartnerExpenseCategoriesByTaxCodeVariables = {
  input: {
    companyId: string;
    partnerTaxCode: string;
  };
};

const useVendorExpenseCategory = (variables: QueryMobilePartnerExpenseCategoriesByTaxCodeVariables) => {
  const { data, ...rest } = useQuery(QUERY_VENDOR_EXPENSE_CATEGORY, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !variables.input.companyId || !variables.input.partnerTaxCode,
  });
  return {
    ...rest,
    data:
      data?.expPartnerExpenseCategoriesByTaxCode?.partnerExpenseCategories.filter((category) =>
        Boolean(category.isActive)
      ) ?? [],
  };
};

export default useVendorExpenseCategory;
