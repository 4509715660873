import { BokCabinClass, FlightTicket } from "screens/FlightBooking/types";
import dayjs from "dayjs";
import { FlightTicketFilter, FlightTicketTime } from "screens/FlightBooking/FlightSearchScreen/types";

const filterByAmount = (tickets: FlightTicketFilter[], { min, max }) => {
  if (!max) {
    return [...tickets];
  }
  return tickets.filter((item) => item.totalPrice >= min && item.totalPrice <= max);
};
const filterTicketByNumOfStop = (tickets: FlightTicketFilter[], numOfStops?: number[]) => {
  if (!numOfStops?.length || numOfStops?.length === 3) {
    return [...tickets];
  }
  return tickets.filter((item) =>
    numOfStops?.some((numOfStop) => {
      if (numOfStop === 0) {
        return item.flights?.[0]?.segments?.length === 1;
      } else if (numOfStop === 1) {
        return item.flights?.[0]?.segments?.length === 2;
      }
      return item.flights?.[0]?.segments?.length >= 3;
    })
  );
};
const filterByTakeOffTime = (tickets: FlightTicketFilter[], value: FlightTicketTime[]) => {
  if (!value?.length || value?.length === 4) {
    return [...tickets];
  }
  return tickets.filter((item) => value.some((i) => item?.takeOffTimeGroupType?.includes(i)));
};
const filterByLandingTime = (tickets: FlightTicketFilter[], value: FlightTicketTime[]) => {
  if (!value?.length || value?.length === 4) {
    return [...tickets];
  }
  return tickets.filter((item) => value.some((i) => item?.landingTimeGroupType?.includes(i)));
};
const filterByVendor = (tickets: FlightTicketFilter[], vendors: string[]) => {
  if (!vendors?.length) {
    return [...tickets];
  }
  return tickets.filter((item) => vendors.includes(item?.flights?.[0]?.airline?.code));
};
const filterByCabinClass = (tickets: FlightTicketFilter[], cabinClass: BokCabinClass[]) => {
  if (!cabinClass?.length) {
    return [...tickets];
  }
  return tickets.filter((item) => cabinClass.includes(item.flights?.[0]?.cabinClass as BokCabinClass));
};
const formatTicketListToFilter = (tickets: FlightTicket[], departureDate: string): FlightTicketFilter[] => {
  return tickets.map((item: FlightTicketFilter) => {
    const { startDate, endDate } = item?.flights?.[0];
    let takeOffTimeGroupType = "";
    let landingTimeGroupType = "";
    const startOfDayDepartureDate = dayjs(departureDate).startOf("day");

    // set value takeOffTimeGroupType
    if (dayjs(startDate).isBetween(startOfDayDepartureDate.hour(0), startOfDayDepartureDate.hour(6), "hour", "[]")) {
      takeOffTimeGroupType += FlightTicketTime.Morning1;
    }
    if (dayjs(startDate).isBetween(startOfDayDepartureDate.hour(6), startOfDayDepartureDate.hour(12), "hour", "[]")) {
      takeOffTimeGroupType += FlightTicketTime.Morning2;
    }
    if (dayjs(startDate).isBetween(startOfDayDepartureDate.hour(12), startOfDayDepartureDate.hour(18), "hour", "[]")) {
      takeOffTimeGroupType += FlightTicketTime.Afternoon;
    }
    if (
      dayjs(startDate).isBetween(
        startOfDayDepartureDate.hour(18),
        startOfDayDepartureDate.hour(23).minute(59).second(59),
        "hour",
        "[]"
      )
    ) {
      takeOffTimeGroupType += FlightTicketTime.Night;
    }
    // set value landingTimeGroupType
    if (dayjs(endDate).isBetween(startOfDayDepartureDate.hour(0), startOfDayDepartureDate.hour(6), "hour", "[]")) {
      landingTimeGroupType += FlightTicketTime.Morning1;
    }
    if (dayjs(endDate).isBetween(startOfDayDepartureDate.hour(6), startOfDayDepartureDate.hour(12), "hour", "[]")) {
      landingTimeGroupType += FlightTicketTime.Morning2;
    }
    if (dayjs(endDate).isBetween(startOfDayDepartureDate.hour(12), startOfDayDepartureDate.hour(18), "hour", "[]")) {
      landingTimeGroupType += FlightTicketTime.Afternoon;
    }
    if (
      dayjs(endDate).isBetween(
        startOfDayDepartureDate.hour(18),
        startOfDayDepartureDate.hour(23).minute(59).second(59),
        "hour",
        "[]"
      )
    ) {
      landingTimeGroupType += FlightTicketTime.Night;
    }
    return {
      ...item,
      takeOffTimeGroupType,
      landingTimeGroupType,
    };
  });
};
const getTakeOffTimeOptions = (tickets: FlightTicketFilter[]) => {
  return [
    {
      label: "Sáng sớm 00:00-06:00",
      value: FlightTicketTime.Morning1,
      disabled: !tickets?.some((item) => item?.takeOffTimeGroupType?.includes(FlightTicketTime.Morning1)),
    },
    {
      label: "Sáng 06:00-12:00",
      value: FlightTicketTime.Morning2,
      disabled: !tickets?.some((item) => item?.takeOffTimeGroupType?.includes(FlightTicketTime.Morning2)),
    },
    {
      label: "Chiều 12:00-18:00",
      value: FlightTicketTime.Afternoon,
      disabled: !tickets?.some((item) => item?.takeOffTimeGroupType?.includes(FlightTicketTime.Afternoon)),
    },
    {
      label: "Tối 18:00-24:00",
      value: FlightTicketTime.Night,
      disabled: !tickets?.some((item) => item?.takeOffTimeGroupType?.includes(FlightTicketTime.Night)),
    },
  ];
};

const getLandingTimeOptions = (tickets: FlightTicketFilter[]) => {
  return [
    {
      label: "Sáng sớm 00:00-06:00",
      value: FlightTicketTime.Morning1,
      disabled: !tickets?.some((item) => item?.landingTimeGroupType?.includes(FlightTicketTime.Morning1)),
    },
    {
      label: "Sáng 06:00-12:00",
      value: FlightTicketTime.Morning2,
      disabled: !tickets?.some((item) => item?.landingTimeGroupType?.includes(FlightTicketTime.Morning2)),
    },
    {
      label: "Chiều 12:00-18:00",
      value: FlightTicketTime.Afternoon,
      disabled: !tickets?.some((item) => item?.landingTimeGroupType?.includes(FlightTicketTime.Afternoon)),
    },
    {
      label: "Tối 18:00-24:00",
      value: FlightTicketTime.Night,
      disabled: !tickets?.some((item) => item?.landingTimeGroupType?.includes(FlightTicketTime.Night)),
    },
  ];
};

const getNumberStopOptions = (tickets: FlightTicketFilter[]) => {
  /*
  * numOfStops?.some((numOfStop) => {
      if (numOfStop === 0) {
        return item.flights?.[0]?.segments?.length === 1;
      } else if (numOfStop === 1) {
        return item.flights?.[0]?.segments?.length === 2;
      }
      return item.flights?.[0]?.segments?.length >= 3;
    })
  * */
  return [
    {
      label: "Bay thẳng",
      value: 0,
      disabled: !tickets?.some((item) => item?.flights?.[0]?.segments?.length === 1),
    },
    {
      label: "1 Điểm dừng",
      value: 1,
      disabled: !tickets?.some((item) => item?.flights?.[0]?.segments?.length === 2),
    },
    {
      label: "+2 Điểm dừng",
      value: 2,
      disabled: !tickets?.some((item) => item?.flights?.[0]?.segments?.length >= 3),
    },
  ];
};
export {
  filterByAmount,
  filterTicketByNumOfStop,
  filterByTakeOffTime,
  filterByLandingTime,
  filterByVendor,
  filterByCabinClass,
  formatTicketListToFilter,
  getTakeOffTimeOptions,
  getLandingTimeOptions,
  getNumberStopOptions,
};
