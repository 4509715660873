import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={54} height={6} fill="none" {...props}>
    <Path
      fill="#94A3B8"
      d="m53.75 3-5-2.887v5.774l5-2.887Zm-53.5.5h1.029v-1H.25v1Zm3.087 0h2.057v-1H3.337v1Zm4.115 0H9.51v-1H7.452v1Zm4.115 0h2.058v-1h-2.058v1Zm4.116 0h2.057v-1h-2.057v1Zm4.115 0h2.058v-1h-2.058v1Zm4.116 0h2.057v-1h-2.058v1Zm4.115 0h2.058v-1h-2.058v1Zm4.115 0h2.058v-1h-2.058v1Zm4.116 0h2.057v-1H36.26v1Zm4.115 0h2.058v-1h-2.058v1Zm4.115 0h2.058v-1H44.49v1Zm4.116 0h2.057v-1h-2.057v1Z"
    />
  </Svg>
);
export default SvgComponent;
