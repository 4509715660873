import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { ActivityIndicator, FlatList, Platform, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";
import { Toast } from "@ant-design/react-native";

import {
  AppText,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  Button,
  CurrencyText,
  EmptyData,
} from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { isIphoneX } from "constants/Layout";
import { EmptyExpense } from "assets/images/svg/icons";
import { useAuth } from "contexts/AuthContext";

import ExpenseSelectItem from "./ExpenseSelectItem";
import useAssignableCardTransactionExpenses from "../../hooks/useAssignableCardTransactionExpenses";
import useAssignExpensesCardTransactionMutation from "../../hooks/useAssignExpensesCardTransactionMutation";
import { MobileCrdCardTransactionQuery } from "types";

interface ChooseExpenseListModalProps {
  expenseList?: MobileCrdCardTransactionQuery["crdCardTransaction"]["cardTransactionExpenses"][0][];
  totalAmountTransaction?: number;
  onRefreshDetail?: () => void;
  cardTransactionId?: number;
}
export interface ChooseExpenseListModalMethods {
  present?: () => void;
}
const PAGE_SIZE = 15;

const ChooseExpenseListModal = forwardRef<ChooseExpenseListModalMethods, ChooseExpenseListModalProps>(
  ({ expenseList, onRefreshDetail, totalAmountTransaction, cardTransactionId }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
        refetch?.();
      },
    }));
    const { t } = useTranslation("app/screens/Card/TransactionDetailScreen/TransactionDetailScreen");
    const {
      company: { company_id: companyId },
    } = useAuth();
    const [_, { data, loading, called, fetchMore, refetch }] = useAssignableCardTransactionExpenses({
      cardTransactionId,
      companyId,
      limit: PAGE_SIZE,
      offset: 0,
    });
    const expenses = data?.expAssignableCardTransactionExpenses?.expenses ?? [];
    const total = data?.expAssignableCardTransactionExpenses?.total ?? 0;

    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
    const [expenseListSelect, setExpenseListSelect] = useState([]);
    const [assignExpenseForTransaction] = useAssignExpensesCardTransactionMutation();

    useEffect(() => {
      setExpenseListSelect(expenseList?.map((item) => ({ ...item?.expense })));
    }, [expenseList]);

    const handleSelectExpense = (item) => async () => {
      const isChecked = expenseListSelect?.find((i) => i?.expenseId === item?.expenseId);
      let newExpenseListSelect = [...expenseListSelect];
      if (!isChecked) {
        newExpenseListSelect.push(item);
      } else {
        newExpenseListSelect = expenseListSelect?.filter((i) => i?.expenseId !== item?.expenseId);
      }
      setExpenseListSelect(newExpenseListSelect);
    };
    const handleChoose = async () => {
      bottomSheetRef?.current?.close();
      try {
        Toast.loading("");
        // expense list need assign to transaction
        const newExpenseIdsSelect = expenseListSelect?.map((i) => i.expenseId);
        // expense list is assigned to transaction
        const expenseListIds = expenseList?.map((i) => i?.expense?.expenseId);
        /*--- if expense is assign to transaction --> remove this expense before send to API ---*/
        const expenseIds = newExpenseIdsSelect?.filter((item) => !expenseListIds?.includes(item));
        if (expenseIds?.length) {
          await assignExpenseForTransaction({
            variables: {
              companyId,
              cardTransactionId,
              expenseIds: expenseIds,
            },
          });
          await onRefreshDetail?.();
        }
        Toast.removeAll();
      } catch (e) {
        Toast.removeAll();
      }
    };
    const renderItem = ({ item }) => {
      const isChecked = expenseListSelect.find((i) => i?.expenseId === item?.expenseId);
      return <ExpenseSelectItem isChecked={isChecked} handleSelectExpense={handleSelectExpense} item={item} />;
    };

    const totalAmountWithVat = useMemo(() => {
      return expenseListSelect?.reduce((total: number, item) => total + item?.totalAmountWithVat, 0);
    }, [expenseListSelect]);

    const handleLoadMore = () => {
      if (loading || expenses?.length >= total) {
        return;
      }
      fetchMore?.({
        variables: { offset: expenses?.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            expAssignableCardTransactionExpenses: {
              expenses: [
                ...prev.expAssignableCardTransactionExpenses.expenses,
                ...fetchMoreResult.expAssignableCardTransactionExpenses.expenses,
              ],
              total: fetchMoreResult.expAssignableCardTransactionExpenses.total,
            },
          };
        },
      });
    };

    const renderListFooterComponent = () => {
      if (loading && expenses?.length > 0) {
        return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
      }
      return null;
    };

    const renderFooter = () => {
      const overAmountTransaction = totalAmountWithVat > totalAmountTransaction;
      return (
        <View style={styles.footer}>
          <View style={styles.flex}>
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
              {t("choose_expense_count", { num: expenseListSelect?.length })}
            </AppText>
            <CurrencyText
              style={[Fonts.SentenceSubTitleXLarge, { marginVertical: 4 }]}
              color={overAmountTransaction ? Colors.alert50 : Colors.success50}
            >
              {totalAmountWithVat}
            </CurrencyText>
            {overAmountTransaction && (
              <AppText style={Fonts.Caption} color={Colors.alert50}>
                {t("over_transaction_amount")}
              </AppText>
            )}
          </View>
          <Button
            style={styles.chooseButton}
            onPress={handleChoose}
            disabled={expenseListSelect?.length === 0 || overAmountTransaction}
          >
            {t("choose", { num: expenseListSelect?.length ?? 0 })}
          </Button>
        </View>
      );
    };

    const renderListEmptyComponent = () => {
      if (loading && !called) {
        return null;
      }
      return <EmptyData title={t("empty_expense")} icon={<EmptyExpense />} />;
    };
    const onDismiss = () => {
      // reset expense list selected to default value when close modal
      setExpenseListSelect(expenseList?.map((item) => ({ ...item.expense })));
    };
    return (
      <BottomSheetModalCustom
        title={t("choose_expense")}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        ref={bottomSheetRef}
        onDismiss={onDismiss}
      >
        <View style={styles.expenseListContainer}>
          {loading && expenses?.length === 0 ? (
            <ActivityIndicator style={styles.loading} size="small" color={Colors.primary50} />
          ) : (
            <FlatList
              showsVerticalScrollIndicator={false}
              contentContainerStyle={styles.expenseListContent}
              data={expenses}
              renderItem={renderItem}
              keyExtractor={(item, index) => item?.expenseId ?? index}
              keyboardDismissMode="on-drag"
              keyboardShouldPersistTaps="always"
              ListEmptyComponent={renderListEmptyComponent}
              onEndReached={handleLoadMore}
              onEndReachedThreshold={0.15}
              ListFooterComponent={renderListFooterComponent}
            />
          )}
        </View>
        {renderFooter()}
      </BottomSheetModalCustom>
    );
  }
);
export default memo(ChooseExpenseListModal);

const styles = StyleSheet.create({
  expenseListContent: {
    paddingBottom: 100,
  },
  expenseListContainer: { flex: 1, backgroundColor: Colors.grayscale0 },
  footer: {
    flexDirection: "row",
    position: "absolute",
    bottom: Platform.OS === "web" ? 34 : 0,
    backgroundColor: Colors.white,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 8,
    paddingBottom: isIphoneX() ? 42 : 30,
    gap: 10,
  },
  chooseButton: { minWidth: 121 },
  loading: { marginTop: 50 },
  loadingMore: { marginTop: 10 },
  flex: { flex: 1 },
});
