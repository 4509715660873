import { gql, QueryHookOptions, useLazyQuery } from "@apollo/client";
import { MobileApprovalTypeQuery, MobileApprovalTypeQueryVariables } from "types";

const EXPENSE_APPROVAL_TYPE = gql`
  query MobileApprovalType($type: String) {
    expenseApprovalType: expApprovalType(type: $type) {
      approvalType
    }
  }
`;

export const useApprovalTypeQuery = (
  options?: QueryHookOptions<MobileApprovalTypeQuery, MobileApprovalTypeQueryVariables>
) => {
  return useLazyQuery(EXPENSE_APPROVAL_TYPE, { ...options, fetchPolicy: "network-only" });
};
