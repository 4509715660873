import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileExpEmployeeBookingLocationsQuery, MobileExpEmployeeBookingLocationsQueryVariables } from "types";

const QUERY = gql`
  query MobileExpEmployeeBookingLocations($input: ExpEmployeeBookingLocationsInput!) {
    expEmployeeBookingLocations(input: $input) {
      locations {
        code
        countryCode
        createdAt
        locationId
        name
        parentCode
        nameEn
        parent {
          name
          nameEn
        }
      }
    }
  }
`;

const useExpEmployeeBookingLocations = (variables?: MobileExpEmployeeBookingLocationsQueryVariables) => {
  return useLazyQuery<MobileExpEmployeeBookingLocationsQuery, MobileExpEmployeeBookingLocationsQueryVariables>(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpEmployeeBookingLocations;
