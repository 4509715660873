import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 18, color = "#42526E", ...rest }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.328 1.5c.491 0 .962.196 1.306.543l2.325 2.325a1.847 1.847 0 010 2.611l-8.49 8.489a3.037 3.037 0 01-2.118 1.034H1.5v-.75l.002-3.162c.064-.79.432-1.526.993-2.02l8.526-8.527a1.842 1.842 0 011.307-.543zm-7.03 13.504c.4-.029.774-.215 1.073-.558l5.671-5.671-2.815-2.816-5.705 5.703a1.553 1.553 0 00-.522.989v2.351l2.298.002zm4.99-10.106l2.815 2.816L14.9 5.92a.347.347 0 000-.49L12.57 3.1a.342.342 0 00-.486 0l-1.798 1.797z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
