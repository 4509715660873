import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgComponent({ width = 121, height = 120, color = "#CBD5E1" }: SvgProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 121 120" fill="none">
      <Path
        d="M107 61.148H89.6c-4.9 0-9.25 2.7-11.45 7.1l-4.2 8.3c-1 2-3 3.25-5.2 3.25h-16.4c-1.55 0-3.75-.35-5.2-3.25l-4.2-8.25c-2.2-4.35-6.6-7.1-11.45-7.1H14c-1.95 0-3.5 1.55-3.5 3.5v16.3c0 18.15 10.9 29 29.1 29h41.9c17.15 0 27.7-9.4 29-26.1v-19.25c0-1.9-1.55-3.5-3.5-3.5zM64.25 10c0-2.05-1.7-3.75-3.75-3.75s-3.75 1.7-3.75 3.75v10h7.5V10z"
        fill={color}
      />
      <Path
        d="M110.5 49.05v5.2c-1.1-.4-2.3-.6-3.5-.6H89.6c-7.75 0-14.7 4.3-18.15 11.2l-3.75 7.4H53.4l-3.75-7.35c-3.45-6.95-10.4-11.25-18.15-11.25H14c-1.2 0-2.4.2-3.5.6v-5.2C10.5 30.85 21.35 20 39.55 20h17.2v15.95l-3.6-3.6c-1.45-1.45-3.85-1.45-5.3 0-1.45 1.45-1.45 3.85 0 5.3l10 10c.05.05.1.05.1.1.35.3.7.55 1.1.7.5.2.95.3 1.45.3s.95-.1 1.4-.3c.45-.15.9-.45 1.25-.8l10-10c1.45-1.45 1.45-3.85 0-5.3-1.45-1.45-3.85-1.45-5.3 0l-3.6 3.6V20h17.2c18.2 0 29.05 10.85 29.05 29.05z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
