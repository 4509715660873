import * as React from "react";
import Svg, { G, Rect, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent({ width = 28, height = 28 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <G>
        <Rect x={2} y={2} width={28} height={28} rx={7} fill="#1F71F4" />
        <Rect x={9} y={14.8335} width={14} height={2.33333} rx={1.16667} fill="#fff" />
      </G>
      <Defs />
    </Svg>
  );
}

export default SvgComponent;
