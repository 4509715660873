// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import debounce from "lodash/debounce";
import trim from "lodash/trim";
import * as Location from "expo-location";
import { ActivityIndicator, Searchbar } from "react-native-paper";
import { useTranslation } from "react-i18next";
import useExpPredictLocations from "hooks/useExpPredictLocations";
import { SPACING_DEFAULT } from "constants/Layout";
import { LocationIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { AppText, IconCustom, Line } from "components";
import { MobileExpPredictLocationsNearbyQuery } from "types";

const PlacePredictions = ({ route, navigation, extraItem }) => {
  const { t } = useTranslation("screens/SuggestionAddress/History");
  const [placePredictions, setPlacePredictions] = useState([]);
  const [location, setLocation] = useState<Location.LocationObject>(null);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [onFetchLocations] = useExpPredictLocations();

  const getCurrentPositionUser = useCallback(async () => {
    try {
      await Location.requestForegroundPermissionsAsync();
      const locationUser = await Location.getCurrentPositionAsync({});
      if (locationUser?.coords) {
        setLocation(locationUser);
      }
    } catch (error) {
      // console.log(error);
    }
    setIsLoading(false);
  }, []);
  useEffect(() => {
    getCurrentPositionUser();
  }, [getCurrentPositionUser]);

  const predictPlaces = useCallback(
    debounce(async (query) => {
      setIsLoading(true);
      if (!trim(query)) {
        setPlacePredictions([]);
        return;
      }
      const rs = await onFetchLocations({
        variables: {
          input: {
            query,
            location: location?.coords?.longitude
              ? { longitude: location?.coords?.longitude, latitude: location?.coords?.latitude }
              : undefined,
          },
        },
      });
      setPlacePredictions(rs?.data?.expPredictLocationsNearby?.predictedLocations ?? []);
      setIsLoading(false);
    }, 500),
    [location]
  );

  useEffect(() => {
    if (keyword) {
      predictPlaces(keyword);
    }
  }, [keyword, predictPlaces]);

  const handleGoBack = (data) => {
    const { callback } = route?.params ?? {};
    navigation.goBack();
    callback?.(data);
  };

  const onPressItem =
    (item: MobileExpPredictLocationsNearbyQuery["expPredictLocationsNearby"]["predictedLocations"][0]) => () => {
      const data = {
        placeDetails: {
          location_name: item?.mainText,
          location_address: item?.secondaryText,
          longitude: item?.longitude,
          latitude: item?.latitude,
        },
      };
      handleGoBack(data);
    };

  const renderItem = useCallback(
    (item, index) => {
      return (
        <TouchableOpacity
          key={item?.mainText + item?.secondaryText}
          style={styles.itemStyle}
          onPress={onPressItem(item)}
        >
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
            {item?.mainText}
          </AppText>
          <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
            {item?.secondaryText}
          </AppText>
          {placePredictions.length > 1 && index < placePredictions.length - 1 && <Line containerStyle={styles.line} />}
        </TouchableOpacity>
      );
    },
    [onPressItem, placePredictions?.length]
  );

  const renderExtraItem = useCallback(() => {
    if (!extraItem) {
      return null;
    }
    return (
      <TouchableOpacity
        style={styles.itemStyle}
        onPress={() =>
          handleGoBack({
            placeDetails: { location_address: extraItem.location_address, location_name: extraItem.location_name },
          })
        }
      >
        <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
          {extraItem?.location_name}
        </AppText>
        <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
          {extraItem?.location_address}
        </AppText>
      </TouchableOpacity>
    );
  }, [extraItem, handleGoBack]);

  return (
    <View style={styles.flex}>
      <View style={styles.wrapper}>
        <Searchbar
          icon={() => <IconCustom name="location-on-outline" fill={Colors.grayscale60} />}
          placeholder={t("search")}
          onChangeText={(text) => setKeyword(text)}
          autoCapitalize="none"
          returnKeyLabel={t("next")}
          selectionColor={Colors.blue500}
          inputStyle={[styles.inputStyle]}
          style={styles.styleSearch}
          enablesReturnKeyAutomatically
          returnKeyType="search"
          clearButtonMode="never"
          value={keyword}
        />
      </View>
      <KeyboardAvoidingView
        style={styles.main}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={Platform.OS === "ios" ? 60 : 20}
        enabled
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <ScrollView keyboardShouldPersistTaps="always" style={[styles.flex, styles.wrapper]}>
            {renderExtraItem()}
            {isLoading ? <ActivityIndicator /> : placePredictions?.map(renderItem)}
          </ScrollView>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </View>
  );
};

export default PlacePredictions;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  inputStyle: {
    fontSize: 14,
  },
  styleSearch: {
    borderRadius: 20,
    borderColor: Colors.grayscale12,
    borderWidth: 1,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    height: 40,
  },
  wrapper: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: Colors.white,
  },
  itemStyle: {
    padding: 4,
    backgroundColor: Colors.white,
    marginBottom: SPACING_DEFAULT - 5,
  },
  line: {
    marginTop: 15,
  },
  main: {
    backgroundColor: Colors.mainBackground,
    flex: 1,
    position: "relative",
  },
});
