import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import {
  ApprovalFlowInline,
  CollapsibleView,
  EmployeeTextView,
  IconCustom,
  Line,
  StatusDetail,
  TextView,
} from "components";
import { APPROVAL_STATUS, CONSTANTS, REQUEST_STATUS, REQUEST_TYPE } from "constants/constants";
import { Colors, Fonts } from "theme";
import { formatTime, getTitleFromCompanyTeam, isForeignCurrency } from "utils";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { ClosedStatusIcon } from "assets/images/svg/icons";
import SummaryMessage from "./SummaryMessage";
import { EXPENSE_REQUEST_TYPE_LABEL } from "screens/ExpenseRequest/constants";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import { StackNavigation } from "navigation/type";
import { useMasterData } from "contexts/MasterDataContext";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";

interface GeneralInfoProps {
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
  isApproverView?: boolean;
}

const STATUS = {
  [REQUEST_STATUS.APPROVED]: {
    title: "approved",
    backgroundColor: Colors.success0,
  },
  [REQUEST_STATUS.SUBMITTED]: {
    title: "waiting",
    backgroundColor: Colors.primary05,
  },
  [REQUEST_STATUS.CANCELLED]: {
    title: "cancelled",
    backgroundColor: Colors.grayscale05,
  },
  [REQUEST_STATUS.REJECTED]: {
    title: "rejected",
    backgroundColor: Colors.alert05,
  },
  [REQUEST_STATUS.DRAFT]: {
    title: "draft",
    backgroundColor: Colors.grayscale05,
  },
};

const GeneralInfo: FC<GeneralInfoProps> = ({ isApproverView, expenseRequest }) => {
  const routes = useNavigationState((state) => state.routes);
  const { setting } = useMasterData();
  const [FEATURE_EXP_EXPENSE_REQUEST_NOTE] = useIsFeaturesEnabled([FEATURE_FLAGS.FEATURE_EXP_EXPENSE_REQUEST_NOTE]);
  const companyTeam = expenseRequest?.expenseRequestsTeams?.[0]?.companyTeam;
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen/ExpenseRequestDetailScreen");
  const navigation = useNavigation<StackNavigation>();
  const purchaseRequest = expenseRequest?.linkedPurchaseRequests?.[0]?.purchaseRequest ?? null;
  const handleNavigateToPurchaseRequest = (purchaseRequestId) => () => {
    const isExistPreviousRequestDetail = routes && routes[routes.length - 2]?.name === SCREEN_NAME.DetailRequestScreen;
    if (isExistPreviousRequestDetail) {
      navigation.goBack();
    } else {
      navigation.push(SCREEN_NAME.DetailRequestScreen, { expenseRequestId: purchaseRequestId });
    }
  };

  const renderPersonRejectedOrCancelled = () => {
    if (expenseRequest?.status === REQUEST_STATUS.REJECTED) {
      const levelRejected = expenseRequest?.expenseApprovalRules?.find(
        (item) => item?.status === APPROVAL_STATUS.REJECTED
      );
      const personReject = levelRejected?.expenseApprovalRuleEmployees?.find(
        (e) => e.status === APPROVAL_STATUS.REJECTED
      );
      return (
        <EmployeeTextView
          style={styles.textView}
          label={t("rejected_by_person")}
          fullname={personReject?.employee?.fullname}
          employeeId={personReject?.employeeId}
          avatar={personReject?.employee?.avatarUrl || personReject?.employee?.defaultAvatarUrl}
        />
      );
    } else if (expenseRequest?.status === REQUEST_STATUS.CANCELLED) {
      const personCancelled = expenseRequest?.expenseApprovalLogs?.find((e) => e.status === APPROVAL_STATUS.CANCELLED);
      return (
        <EmployeeTextView
          style={styles.textView}
          label={t("cancelled_by_person")}
          fullname={personCancelled?.companyEmployee?.fullname}
          employeeId={personCancelled?.companyEmployee?.employeeId}
          avatar={personCancelled?.companyEmployee?.avatarUrl || personCancelled?.companyEmployee?.defaultAvatarUrl}
        />
      );
    }
    return null;
  };

  return (
    <View style={styles.container}>
      <View style={styles.statusRow}>
        <StatusDetail
          backgroundColor={STATUS[expenseRequest?.status]?.backgroundColor}
          title={t(STATUS[expenseRequest?.status]?.title)}
        />
        {Boolean(expenseRequest?.isClosed) && (
          <View style={styles.closeStatusIcon}>
            <ClosedStatusIcon size={16} />
          </View>
        )}
        <View style={styles.quickLookApprovalFlowContainer}>
          <ApprovalFlowInline data={expenseRequest?.expenseApprovalRules} />
        </View>
      </View>
      <CollapsibleView
        numberOfLineTitle={2}
        rotate={-180}
        rightComponentStyle={styles.rightComponentStyle}
        rightComponent={
          <View style={styles.dropDownIcon}>
            <IconCustom name="arrow-drop-down" fill={Colors.primary50} />
          </View>
        }
        divider={<Line backgroundColor={Colors.grayscale0} />}
        summaryComponent={<SummaryMessage isApproverView={isApproverView} expenseRequest={expenseRequest} />}
        title={`[${expenseRequest?.docSequence}] ${expenseRequest?.title}`}
        titleStyle={Fonts.SentenceSubtitleXLarge}
      >
        {setting?.isSupportCurrency && (
          <>
            <View>
              <TextView
                value={isForeignCurrency(expenseRequest?.currency) ? expenseRequest?.currency : null}
                label={t("currency")}
              />
              <TextView value={expenseRequest?.exchangeRate} isMoney currency="" label={t("exchange_rate")} />
            </View>
          </>
        )}
        <EmployeeTextView
          style={styles.textView}
          label={t("employee")}
          fullname={expenseRequest?.companyEmployee?.fullname}
          employeeId={expenseRequest?.companyEmployee?.employeeId}
          avatar={expenseRequest?.companyEmployee?.avatarUrl || expenseRequest?.companyEmployee?.defaultAvatarUrl}
        />
        {renderPersonRejectedOrCancelled()}
        <TextView
          style={styles.textView}
          label={t("type_request")}
          value={EXPENSE_REQUEST_TYPE_LABEL(t)[expenseRequest?.expenseRequestType]}
        />
        {expenseRequest?.expenseRequestType === REQUEST_TYPE.price_comparison && purchaseRequest && (
          <TextView
            onPress={handleNavigateToPurchaseRequest(purchaseRequest.purchaseRequestId)}
            hyperlink
            value={`${purchaseRequest.docSequence} - ${purchaseRequest.title}`}
            label={t("purchase_request")}
          />
        )}
        {expenseRequest?.expenseRequestType !== REQUEST_TYPE.price_comparison && (
          <TextView
            style={styles.textView}
            label={t("budget_of_month")}
            value={expenseRequest?.budgetDate ? formatTime(expenseRequest?.budgetDate, "MM/YYYY") : ""}
            emptyText={t("no_value", { label: t("budget_of_month").toLowerCase() })}
          />
        )}
        <TextView
          style={styles.textView}
          label={t("unit")}
          value={companyTeam ? getTitleFromCompanyTeam(companyTeam) : ""}
          emptyText={t("no_value", { label: t("unit").toLowerCase() })}
        />
        <TextView style={styles.textView} label={t("created_date")} value={formatTime(expenseRequest?.createdAt)} />
        <TextView
          style={styles.textView}
          label={t("day_send_request")}
          value={formatTime(expenseRequest?.requestedAt)}
        />
        {Boolean(expenseRequest?.closedAt) && (
          <TextView style={styles.textView} label={t("closed_at")} value={formatTime(expenseRequest?.closedAt)} />
        )}
        <TextView style={styles.textView} label={t("explanation")} value={expenseRequest?.description} />
        {FEATURE_EXP_EXPENSE_REQUEST_NOTE && (
          <TextView style={styles.textView} label={t("note")} value={expenseRequest?.note} />
        )}
      </CollapsibleView>
    </View>
  );
};
export default memo(GeneralInfo);
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 16,
    paddingBottom: 8,
    backgroundColor: Colors.white,
  },
  textView: { marginBottom: 6 },
  statusRow: { flexDirection: "row", alignItems: "center", gap: 8, marginBottom: 8 },
  closeStatusIcon: {
    height: 24,
    backgroundColor: Colors.alert05,
    width: 24,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  dropDownIcon: { backgroundColor: Colors.primary0, borderRadius: 4 },
  quickLookApprovalFlowContainer: { flex: 1, alignItems: "flex-end" },
  rightComponentStyle: { marginTop: 5 },
});
