import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  IconCustom,
  Line,
  PickerCustom,
  RadioButton,
  TextInputCustom,
} from "components";
import { CONSTANTS } from "constants/constants";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import { BizziBotInfo } from "assets/images/svg/icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import { useAuth } from "contexts/AuthContext";
import { useFlightBookingApis } from "../context/FlightBookingProvider";
import { convertNameEnToVi } from "utils";
import { PassengerInfoRouteProps, StackNavigation } from "navigation/type";
import { BirthDayInput } from "components/InputCustom";
import dayjs from "dayjs";

type PassengerInfoFormValues = {
  contact: {
    name: string;
    phone: string;
    email: string;
  };
  passenger: {
    lastName: string;
    firstName: string;
    gender: string;
    birthday: Date;
  };
};

export type PassengerInfoFormApi = {
  triggerSubmit: () => void;
};
const birthDateFormat = "DD/MM/YYYY";
const PassengerInfoForm = forwardRef<PassengerInfoFormApi, any>((_, ref) => {
  useImperativeHandle(ref, () => ({
    triggerSubmit: () => {
      handleSubmit(onSubmit)();
    },
  }));
  const route = useRoute<PassengerInfoRouteProps>();
  const navigation = useNavigation<StackNavigation>();
  const { user } = useAuth();
  const bookingCtxApis = useFlightBookingApis();
  const { t } = useTranslation("app/screens/FlightBooking/PassengerInfoScreen/PassengerInfoScreen");
  const alertNotificationRef = useRef<AlertNotificationHandle>();
  const genderOptions = [
    {
      label: t("male"),
      value: "Male",
    },
    {
      label: t("female"),
      value: "Female",
    },
  ];

  const form = useForm<PassengerInfoFormValues>({
    defaultValues: {
      contact: {
        name: convertNameEnToVi(user.fullname).toUpperCase(),
        phone: user.phone,
        email: user.email,
      },
      passenger: {
        lastName: "",
        firstName: "",
        gender: null,
        birthday: null,
      },
    },
    shouldFocusError: false,
    mode: CONSTANTS.COMMON.VALIDATE_RULE,
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = (values: PassengerInfoFormValues) => {
    bookingCtxApis.saveContactPassenger({
      contact: values.contact,
      passenger: {
        ...values.passenger,
        birthday: dayjs(values.passenger.birthday, birthDateFormat).toISOString(),
      },
    });
    navigation.navigate(SCREEN_NAME.LuggageScreen, route.params);
  };
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={styles.contactSectionHeader}>
          <AppText style={Fonts.SentenceSubtitleLarge}>{t("contact_info")}</AppText>
          <TouchableOpacity
            onPress={() => {
              alertNotificationRef.current?.info({
                icon: <BizziBotInfo />,
                title: t("contact_info"),
                description: t("contact_info_explanation"),
                confirmText: t("understood"),
                onConfirm: () => {
                  alertNotificationRef.current.close();
                },
              });
            }}
          >
            <IconCustom name="info" />
          </TouchableOpacity>
        </View>
        <TextInputCustom
          autoCapitalize="characters"
          control={control}
          label={t("full_name")}
          name="contact.name"
          error={errors?.contact?.name}
          rules={{ required: t("required") }}
        />
        <TextInputCustom
          keyboardType="numeric"
          control={control}
          label={t("phone")}
          name="contact.phone"
          error={errors?.contact?.phone}
          rules={{ required: t("required") }}
        />
        <TextInputCustom
          control={control}
          label={t("email")}
          name="contact.email"
          error={errors?.contact?.email}
          rules={{ required: t("required") }}
        />
      </View>
      <View style={styles.section}>
        <View>
          <AppText style={Fonts.SentenceSubtitleLarge}>{t("passenger_info")}</AppText>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>
            {t("description_contact_form")}
          </AppText>
        </View>
        <TextInputCustom
          autoCapitalize="characters"
          control={control}
          label={t("last_name")}
          name="passenger.lastName"
          error={errors?.passenger?.lastName}
          rules={{ required: t("required") }}
        />
        <TextInputCustom
          autoCapitalize="characters"
          control={control}
          label={t("first_name")}
          name="passenger.firstName"
          error={errors?.passenger?.firstName}
          rules={{ required: t("required") }}
        />
        <PickerCustom
          style={styles.pickerCustom}
          control={control}
          name="passenger.gender"
          label={t("gender")}
          title={t("gender")}
          options={genderOptions}
          error={errors?.passenger?.gender}
          rules={{ required: t("required") }}
          renderPickerItem={(item, onSelect) => {
            const isSelected = item.value === getValues("passenger.gender");
            return (
              <TouchableOpacity
                key={item?.value as string}
                onPress={() => {
                  onSelect();
                  setValue("passenger.gender", item?.value as string);
                }}
              >
                <View style={styles.listItem}>
                  <AppText>{item?.label}</AppText>
                  <RadioButton checked={isSelected} />
                </View>
                <Line size="sm" />
              </TouchableOpacity>
            );
          }}
        />
        <BirthDayInput
          label={t("date_of_birth")}
          name="passenger.birthday"
          requiredMessage={t("required")}
          invalidMessage={t("invalid_birth_day")}
          error={errors?.passenger?.birthday}
          control={control}
          dateFormat={birthDateFormat}
        />
      </View>
      <AlertNotification goBackIsDismiss ref={alertNotificationRef} />
    </View>
  );
});

export default PassengerInfoForm;

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  section: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    gap: 10,
  },
  contactSectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  pickerCustom: {
    paddingTop: 3,
  },
});
