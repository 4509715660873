import React, { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Keyboard,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";

import {
  AppText,
  EmptyData,
  ExpenseItem,
  ExpenseReportItem,
  FlatListCustom,
  IconCustom,
  RadioSelectFilter,
  SearchInput,
  SelectCompanyTeam,
  SelectDate,
  SelectExpenseCategory,
  SkeletonListLoading,
} from "components";
import { BackButtonIcon, EmptyResultFound, SearchNotFoundIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { FilterData } from "./types";
import {
  ALLOCATED_STATUS,
  ALLOCATED_STATUS_ALLOCATION_LEVEL_CONSTRAINTS,
  CONSTANTS,
  EXPENSE_CLAIMED_STATUS,
  EXPENSE_REPORT_STATUS,
  EXPENSE_STATUS,
  INVOICE_TYPE,
} from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { goBack } from "navigation/RootNavigation";
import useExpenseSearchQuery from "./hooks/useExpenseSearchQuery";
import { NetworkStatus } from "@apollo/client";
import SelectTotalAmountWithVat from "components/AdvanceSearch/SelectTotalAmountWithVat";
import { isNil } from "lodash";

const getStatusOptions = (t): SelectItem[] => [
  { label: t("awaiting_invoice"), value: EXPENSE_STATUS.AWAITING_INVOICE },
  { label: t("need_detail"), value: EXPENSE_STATUS.NEED_DETAIL },
  { label: t("ready"), value: EXPENSE_STATUS.READY },
];

const getInvoiceTypeOptions = (t): SelectItem[] => [
  { label: t("no_invoice"), value: INVOICE_TYPE.NO_INVOICE },
  { label: t("e_invoice"), value: INVOICE_TYPE.E_INVOICE },
  {
    label: t("paper_invoice"),
    value: INVOICE_TYPE.PAPER_INVOICE,
  },
];

const getAllocateStatusList = (t): SelectItem[] => [
  {
    label: t("unallocated"),
    value: ALLOCATED_STATUS.UNALLOCATED,
  },
  { label: t("allocated"), value: ALLOCATED_STATUS.ALLOCATED },
  { label: t("is_allocated"), value: ALLOCATED_STATUS.IS_ALLOCATED },
];

const getClaimedStatusOptions = (t): SelectItem[] => [
  {
    label: t("report_created"),
    value: EXPENSE_CLAIMED_STATUS.CLAIMED,
  },
  {
    label: t("report_not_created"),
    value: EXPENSE_CLAIMED_STATUS.UNCLAIMED,
  },
];

const getHasCardTransactionOptions = (t): SelectItem[] => [
  {
    label: t("had_transaction"),
    value: true,
  },
  {
    label: t("had_no_transaction"),
    value: false,
  },
];

const getReportStatusOptions = (t): SelectItem[] => [
  {
    label: t("report_draft"),
    value: EXPENSE_REPORT_STATUS.DRAFT,
  },
  {
    label: t("report_submitted"),
    value: EXPENSE_REPORT_STATUS.SUBMITTED,
  },
  {
    label: t("report_approved"),
    value: EXPENSE_REPORT_STATUS.APPROVED,
  },
  {
    label: t("report_rejected"),
    value: EXPENSE_REPORT_STATUS.REJECTED,
  },
  {
    label: t("report_cancelled"),
    value: EXPENSE_REPORT_STATUS.CANCEL,
  },
];

const SearchBox = ({ keyword, setKeyword }) => {
  const { t } = useTranslation("app/screens/Expense/ExpenseSearchScreen/ExpenseSearchScreen");
  const searchInputRef = useRef<TextInput>();

  const debounceSearchTimeoutId = useRef(null);
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      setKeyword(text);
    }, 400);
  };

  const onResetSearch = () => {
    setKeyword("");
    searchInputRef?.current?.setNativeProps({ text: "" });
  };

  return (
    <View style={styles.searchContainer}>
      <TouchableOpacity style={{ marginTop: 6 }} onPress={() => goBack()}>
        <BackButtonIcon />
      </TouchableOpacity>
      <SearchInput
        numberOfLines={1}
        ref={searchInputRef}
        style={styles.searchInput}
        placeholder={t("search")}
        onChangeText={onChangeSearch}
        autoCapitalize="none"
        placeholderTextColor={Colors.grayscale60}
        enablesReturnKeyAutomatically
        returnKeyType="search"
        right={
          keyword ? (
            <TouchableOpacity onPress={onResetSearch}>
              <IconCustom name="cancel" />
            </TouchableOpacity>
          ) : null
        }
      />
    </View>
  );
};

const ExpenseSearchScreen = ({ route }) => {
  const { t } = useTranslation("app/screens/Expense/ExpenseSearchScreen/ExpenseSearchScreen");
  const { top } = useSafeAreaInsets();
  const {
    user: { employee_id: employeeId },
  } = useAuth();

  // const navigation = useNavigation<any>();
  const flashListRef = useRef<FlashList<any>>(null);

  const claimedStatusOptions = getClaimedStatusOptions(t);
  const [filter, setFilter] = useState<FilterData>({
    claimedStatus: {
      claimed: claimedStatusOptions[0],
      new: claimedStatusOptions[1],
    }[route.params?.previousScreen ?? ""],
  });

  const [keyword, setKeyword] = useState("");
  const [_, { data, networkStatus, loading, refetch, fetchMore }] = useExpenseSearchQuery({
    limit: 10,
    offset: 0,
    where: {
      employeeId,
      keyword: keyword ? keyword : undefined,
      claimedStatus: filter.claimedStatus ? filter.claimedStatus.value : undefined,
      categoryIds: filter.expenseCategory ? [filter.expenseCategory?.expenseCategoryId] : undefined,
      companyTeamIds: filter.companyTeam ? [filter.companyTeam.teamId] : undefined,
      hasCardTransaction: filter.hasCardTransaction ? filter.hasCardTransaction.value : undefined,
      invoiceTypes: filter.invoiceType ? [filter.invoiceType.value] : undefined,
      expenseDate:
        filter.expenseDate?.fromDate && filter.expenseDate?.toDate
          ? { fromDate: filter.expenseDate.fromDate, toDate: filter.expenseDate.toDate }
          : undefined,
      statuses: filter.status ? [filter.status.value] : undefined,
      totalAmountWithVat: filter.totalAmountWithVat
        ? { min: filter.totalAmountWithVat.min, max: filter.totalAmountWithVat.max }
        : undefined,

      expenseReportStatuses: filter.reportStatus ? [filter.reportStatus.value] : undefined,
      levels: filter.allocatedStatus
        ? [ALLOCATED_STATUS_ALLOCATION_LEVEL_CONSTRAINTS[filter.allocatedStatus.value]]
        : undefined,
    },
  });
  const expenseList = data?.expMobileExpenses?.expenses ?? [];
  const total = data?.expMobileExpenses?.total;

  const isFiltering =
    keyword.trim() ||
    [
      filter.hasCardTransaction,
      filter.claimedStatus,
      filter.companyTeam,
      filter.invoiceType,
      filter.allocatedStatus,
      filter.status,
      filter.expenseDate?.fromDate && filter.expenseDate?.toDate,
      filter.companyTeam,
      filter.reportStatus,
      filter.expenseCategory,
      !isNil(filter.totalAmountWithVat?.min) || !isNil(filter.totalAmountWithVat?.max),
    ].some(Boolean);

  useEffect(() => {
    if (isFiltering) {
      refetch();
    }
  }, [filter, isFiltering, refetch]);

  const onChangeFilter = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };

  const onClearFilter = () => {
    setKeyword("");
    setFilter({});
  };

  const onRefreshAfterUpdate = () => {
    flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
    refetch();
  };

  const renderItem = ({ item }) => {
    if (filter?.claimedStatus?.value === EXPENSE_CLAIMED_STATUS.UNCLAIMED) {
      return <ExpenseItem item={item} onRefreshData={onRefreshAfterUpdate} />;
    }
    return <ExpenseReportItem item={item} onRefreshData={onRefreshAfterUpdate} />;
  };

  const handleLoadMore = () => {
    const lastItemLoaded = expenseList.length === total;
    if (lastItemLoaded || loading || networkStatus !== NetworkStatus.ready) {
      return;
    }

    const newOffset = expenseList.length;
    fetchMore({
      variables: {
        offset: newOffset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          expMobileExpenses: {
            total: prev.expMobileExpenses.total,
            expenses: [...prev.expMobileExpenses.expenses, ...fetchMoreResult.expMobileExpenses.expenses],
          },
        };
      },
    });
  };

  const renderContent = () => {
    if (!isFiltering) {
      return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.emptyContainer}>
            <EmptyData icon={<EmptyResultFound />} title={t("search_title")} description={t("search_description")} />
          </View>
        </TouchableWithoutFeedback>
      );
    }

    if (loading && networkStatus !== NetworkStatus.fetchMore) {
      return <SkeletonListLoading style={{ marginTop: 5 }} />;
    }

    return (
      <FlatListCustom
        ref={flashListRef}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
        data={expenseList}
        renderItem={renderItem}
        refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
        ListEmptyComponent={<EmptyData icon={<SearchNotFoundIcon />} title={t("search_not_found")} />}
        keyExtractor={(item, index) => {
          return item?.expenseId ?? index;
        }}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.2}
        ListFooterComponent={
          networkStatus === NetworkStatus.fetchMore ? (
            <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />
          ) : null
        }
        estimatedItemSize={88}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View style={[styles.headerContainer, { paddingTop: top || 20 }]}>
        <SearchBox keyword={keyword} setKeyword={setKeyword} />

        <ScrollView keyboardShouldPersistTaps="always" horizontal showsHorizontalScrollIndicator={false}>
          <View style={styles.searchItemContainer}>
            <RadioSelectFilter
              minWidth={112}
              title={t("is_report_created")}
              data={claimedStatusOptions}
              value={filter?.claimedStatus as SelectItem}
              setValue={(value) => onChangeFilter("claimedStatus", value)}
            />
            <RadioSelectFilter
              minWidth={130}
              title={t("report_status")}
              data={getReportStatusOptions(t)}
              value={filter?.reportStatus}
              setValue={(value) => onChangeFilter("reportStatus", value)}
            />
            <SelectExpenseCategory
              minWidth={130}
              title={t("expense_category")}
              value={filter?.expenseCategory}
              setValue={(value) => onChangeFilter("expenseCategory", value)}
            />
            <SelectCompanyTeam
              title={t("unit")}
              value={filter?.companyTeam}
              setValue={(value) => onChangeFilter("companyTeam", value)}
            />
            <RadioSelectFilter
              title={t("card_transaction")}
              data={getHasCardTransactionOptions(t)}
              value={filter?.hasCardTransaction as SelectItem}
              setValue={(value) => onChangeFilter("hasCardTransaction", value)}
            />
            <RadioSelectFilter
              title={t("invoice_type")}
              data={getInvoiceTypeOptions(t)}
              value={filter?.invoiceType as SelectItem}
              setValue={(value) => onChangeFilter("invoiceType", value)}
            />
            <SelectDate
              title={t("created_date")}
              labelDateValue={filter?.expenseDate?.labelDateValue}
              setValue={(label, from, to) => {
                onChangeFilter("expenseDate", { fromDate: from, toDate: to, labelDateValue: label });
              }}
            />
            <RadioSelectFilter
              title={t("status")}
              data={getStatusOptions(t)}
              value={filter?.status as SelectItem}
              setValue={(value) => onChangeFilter("status", value)}
            />
            <SelectTotalAmountWithVat
              minWidth={130}
              title={t("total_amount_with_vat")}
              value={filter.totalAmountWithVat}
              setValue={(value) => onChangeFilter("totalAmountWithVat", value)}
            />
            <RadioSelectFilter
              minWidth={130}
              title={t("allocated_status")}
              data={getAllocateStatusList(t)}
              value={filter?.allocatedStatus}
              setValue={(value) => onChangeFilter("allocatedStatus", value)}
            />
            <TouchableOpacity onPress={onClearFilter}>
              <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                {t("clear_filter")}
              </AppText>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>

      {renderContent()}
    </View>
  );
};

export default ExpenseSearchScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingTop: 10, paddingBottom: 20, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  headerContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 4,
  },
  searchContainer: {
    paddingRight: 20,
    paddingLeft: 18,
    justifyContent: "space-between",
    gap: 8,
    alignItems: "center",
    flexDirection: "row",
  },
  searchInput: { ...Fonts.BodySmall, flex: 1, color: Colors.grayscale100, backgroundColor: Colors.white },
  searchItemContainer: {
    flexDirection: "row",
    gap: 8,
    marginTop: 16,
    marginHorizontal: 20,
    alignItems: "center",
    paddingBottom: 16,
  },
  loadingMore: { marginTop: 10 },
  emptyContainer: { flex: 1 },
});
