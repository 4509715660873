import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={26} viewBox="0 0 20 26">
      <Path
        d="M5 20.667h10v-2H5v2zm0-5.333h10v-2H5v2zM2.41 25.667a2.327 2.327 0 01-1.71-.7 2.327 2.327 0 01-.7-1.71V2.744c0-.673.233-1.243.7-1.71a2.327 2.327 0 011.71-.7H13l7 7v15.923c0 .673-.233 1.244-.7 1.71-.467.467-1.037.7-1.71.7H2.41zM12 8.334h6l-6-6v6z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
