import { FlashList } from "@shopify/flash-list";
import { forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RefreshControl, StyleSheet, View } from "react-native";
import { NetworkStatus } from "@apollo/client";

import { useNavigation, useRoute } from "@react-navigation/native";
import { EmptyExpenseReport } from "assets/images/svg/icons";
import { EmptyData, AnimatedFlatList, RadioSelectFilter, SkeletonListLoading, SortingLabel } from "components";
import { CONSTANTS, KIND_OF_APPROVAL } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import { Colors } from "theme";
import useExpenseReportApprovalQuery from "../hooks/useExpenseReportApprovalQuery";
import ReportItem from "./ReportItem";
import FlashListItem from "components/FlashListItem";
import { EVENT } from "constants/Tracking";
import { REPORT_SORT_OPTIONS, WaitingApprovalExpenseReportTabMethods } from "../types";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import Animated, {
  useAnimatedScrollHandler,
  useSharedValue,
  useAnimatedStyle,
  Extrapolation,
  interpolate,
} from "react-native-reanimated";
import { clampReanimated } from "utils";
import { analyticService } from "services/AnalyticsService";
import { ReportListScreenProps } from "navigation/type";

const FILTER_CONTAINER_HEIGHT = 36;
const PAGE_SIZE = 10;

const WaitingApproveTab = forwardRef<WaitingApprovalExpenseReportTabMethods, unknown>((_, ref) => {
  useImperativeHandle(ref, () => ({
    /* scroll to top before refetch */
    onRefresh: () => {
      flashListRef.current?.scrollToOffset({ animated: false, offset: 0 });
      refetch();
    },
  }));
  const route = useRoute<ReportListScreenProps["route"]>();
  const navigation = useNavigation<ReportListScreenProps["navigation"]>();
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const { t } = useTranslation("app/screens/Report/ReportListScreen");
  const sortOptions = useMemo(
    () =>
      [
        { label: t("requested_at_asc"), value: REPORT_SORT_OPTIONS.REQUESTED_AT_ASC },
        { label: t("requested_at_desc"), value: REPORT_SORT_OPTIONS.REQUESTED_AT_DESC },
        {
          label: t("expense_asc"),
          value: REPORT_SORT_OPTIONS.AMOUNT_ASC,
        },
        {
          label: t("expense_desc"),
          value: REPORT_SORT_OPTIONS.AMOUNT_DESC,
        },
      ].reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [t]
  );
  const [sortOption, setSortOption] = useState<SelectItem>(sortOptions[REPORT_SORT_OPTIONS.REQUESTED_AT_ASC]);

  const flashListRef = useRef<FlashList<any>>(null);
  const { data, loading, called, refetch, fetchMore, networkStatus } = useExpenseReportApprovalQuery({
    where: {
      companyId,
      companyEmployeeId: employeeId,
      kindOfApproval: KIND_OF_APPROVAL.AWAITING_MY_APPROVAL,
    },
    limit: PAGE_SIZE,
    offset: 0,
    sortOption: sortOption.value,
  });

  const reportList = data?.expWaitingApprovalExpenseReports?.reports ?? [];
  const total = data?.expWaitingApprovalExpenseReports?.total ?? 0;

  const handleLoadMore = () => {
    if (loading || reportList.length >= total) {
      return;
    }
    fetchMore?.({
      variables: { offset: reportList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expWaitingApprovalExpenseReports: {
            reports: [
              ...prev?.expWaitingApprovalExpenseReports?.reports,
              ...fetchMoreResult?.expWaitingApprovalExpenseReports?.reports,
            ],
            total: fetchMoreResult?.expWaitingApprovalExpenseReports?.total,
          },
        };
      },
    });
  };
  const handleClickItem = (expenseReportId) => () => {
    navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
      expenseReportId,
      onRefreshData: () => {
        flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
        refetch?.();
      },
    });
  };

  const renderItem = ({ item }) => (
    <FlashListItem>
      <ReportItem
        showCreator
        eventName={EVENT.REPORT.TAP_VIEW_DETAIL}
        onPress={handleClickItem(item?.expenseReportId)}
        item={item}
      />
    </FlashListItem>
  );

  const renderEmpty = () => {
    if (loading || !called) {
      return null;
    }
    return (
      <EmptyData
        icon={<EmptyExpenseReport />}
        title={t("empty_expense_report_approve")}
        description={t("empty_expense_report_approve_description")}
      />
    );
  };

  const renderFooter = () => {
    if (loading && reportList?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };

  const translationY = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      translationY.value,
      [0, FILTER_CONTAINER_HEIGHT],
      [0, -FILTER_CONTAINER_HEIGHT],
      Extrapolation.CLAMP
    );
    return {
      transform: [{ translateY: translateY }],
    };
  });
  const scrollHandler = useAnimatedScrollHandler<{ prevY?: number }>({
    onScroll: (event, ctx) => {
      let { y } = event.contentOffset;
      if (y < 0) {
        y = 0;
      }
      const dy = y - (ctx?.prevY ?? 0);
      translationY.value = clampReanimated(translationY.value + dy, 0, FILTER_CONTAINER_HEIGHT);
      ctx.prevY = y;
    },
  });

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.floatHeader, animatedStyle]}>
        <View style={styles.sortingContainer}>
          <SortingLabel
            sortingModalProps={{
              showClearButton: false,
              title: t("sort_by"),
              data: Object.values(sortOptions),
              value: sortOption,
              setValue: (option) => {
                const params = route.params ?? {};
                navigation.setParams({ ...params, approvalTabSortOption: option.value });
                setSortOption(option);
              },
              onPress: () =>
                analyticService.logEvent({ name: EVENT.REPORT.TAP_OPEN_SORT_MODAL, properties: { tab: "waiting" } }),
            }}
          />
        </View>
      </Animated.View>
      {(loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={styles.loadingContainer} />
      ) : (
        <AnimatedFlatList
          onScroll={scrollHandler}
          ref={flashListRef}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
          keyExtractor={(item, index) => (item as any)?.expenseReportId ?? index}
          data={reportList}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          estimatedItemSize={88}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
    </View>
  );
});
export default memo(WaitingApproveTab);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingTop: FILTER_CONTAINER_HEIGHT - 10,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 50,
  },
  loadingContainer: { marginTop: FILTER_CONTAINER_HEIGHT - 10 },
  loadingMore: { marginTop: 10 },
  floatHeader: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
  sortingContainer: {
    paddingVertical: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
