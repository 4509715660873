import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileExpEmployeeBookingAirportsQuery, MobileExpEmployeeBookingAirportsQueryVariables } from "types";

const QUERY = gql`
  query MobileExpEmployeeBookingAirports($input: ExpEmployeeBookingAirportsInput!) {
    expEmployeeBookingAirports(input: $input) {
      airports {
        airportId
        locationCode
        code
        name
        nameEn
        searchText
        location {
          name
          nameEn
          locationId
        }
      }
    }
  }
`;

const useExpensePoliciesBookingAirports = (variables?: MobileExpEmployeeBookingAirportsQueryVariables) => {
  return useLazyQuery<MobileExpEmployeeBookingAirportsQuery, MobileExpEmployeeBookingAirportsQueryVariables>(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpensePoliciesBookingAirports;
