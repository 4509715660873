import { RefObject, useEffect } from "react";
import { View } from "react-native";

const useSetRelativePosition = ({
  containerRef,
  childRef,
  setPosition,
}: {
  containerRef: RefObject<View>;
  childRef: RefObject<View>;
  setPosition: (y: number) => void;
}): void => {
  useEffect(() => {
    if (containerRef?.current && childRef?.current && setPosition) {
      measureRelativePosition();
    }
  }, [containerRef?.current, childRef?.current]);
  const measureRelativePosition = () => {
    containerRef.current?.measure?.((x, y, width, height, pageX, pageY) => {
      const containerY = pageY;
      childRef.current?.measure?.((x, y, width, height, pageX, pageY) => {
        setPosition(pageY - containerY);
      });
    });
  };
};

export default useSetRelativePosition;
