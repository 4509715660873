import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={23} viewBox="0 0 30 34" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.093 22.007h-.007.014c10.347.946 11.288 9.79 11.288 11.295 0-3.45 2.257-10.538 11.286-11.294h.002l.007-.001h-.015c-10.346-.945-11.287-9.789-11.287-11.295 0 3.45-2.258 10.538-11.286 11.295h-.003.001zM7.082 5.064c0 2.157-1.412 6.589-7.06 7.06h14.12c-6.472-.589-7.06-6.119-7.06-7.06zm-.007 14.12c0-2.157 1.412-6.589 7.06-7.06H.015c6.472.589 7.06 6.119 7.06 7.06zM15.564.828c0 1.294-.847 3.953-4.236 4.236h.012c3.88.355 4.232 3.67 4.232 4.235 0-1.294.848-3.953 4.236-4.236h-.012c-3.88-.354-4.232-3.67-4.232-4.235z"
        fill="#1F71F4"
      />
    </Svg>
  );
}

export default SvgComponent;
