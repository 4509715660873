import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Path d="M10.977 10L6.852 5.875 8.03 4.697 13.333 10 8.03 15.303l-1.178-1.178L10.977 10z" fill="#E2E8F0" />
    </Svg>
  );
}

export default SvgComponent;
