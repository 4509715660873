import { FC } from "react";
import { Button } from "react-native-paper";
import { ScrollView, StyleSheet, ViewStyle } from "react-native";

import { Colors, Fonts } from "theme";

interface Tab {
  name: string;
  value: string | number;
}
interface TabsProps {
  tabs: Tab[];
  style?: ViewStyle;
  containerStyle?: ViewStyle;
  disable?: boolean;
  value?: Tab["value"];
  error?: boolean;
  onChange?: (value: Tab["value"]) => void;
}

const Tabs: FC<TabsProps> = ({ tabs, value: valueProp, error, style, containerStyle, onChange, disable = false }) => {
  return (
    <ScrollView horizontal contentContainerStyle={containerStyle} showsHorizontalScrollIndicator={false}>
      {tabs.map(({ name, value }, index) => {
        const isSelected = valueProp === value;
        return (
          <Button
            key={value}
            disabled={disable}
            uppercase={false}
            color={Colors.grayscale100}
            style={[
              styles.buttonFilterStyle,
              index !== 0 && styles.marginLeft,
              isSelected && styles.buttonFilterActiveStyle,
              isSelected && error && styles.buttonFilterErrorStyle,
              style,
            ]}
            labelStyle={[Fonts.BodyMedium, isSelected && { color: Colors.black }]}
            onPress={() => {
              onChange?.(value);
            }}
          >
            {name}
          </Button>
        );
      })}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  marginLeft: {
    marginLeft: 8,
  },
  buttonFilterActiveStyle: {
    borderColor: Colors.primary50,
    shadowColor: Colors.primary50,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  buttonFilterErrorStyle: {
    borderColor: Colors.alert50,
    shadowColor: Colors.alert50,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  buttonFilterStyle: {
    borderWidth: 1,
    borderRadius: 50,
    backgroundColor: Colors.while,
    borderColor: Colors.grayscale10,
  },
});

export default Tabs;
