import { useEffect, useLayoutEffect, useState } from "react";
import { Keyboard, KeyboardAvoidingView, Platform, StatusBar, StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, BackHeader, LoadingView, WebView } from "components";
import { useTranslation } from "react-i18next";
import { useExpConvoChatUrl } from "hooks/useExpConvoChatUrl";
import { useAuth } from "contexts/AuthContext";
import { SafeAreaView } from "react-native-safe-area-context";
import { Colors, Fonts } from "theme";
import SCREEN_NAME from "navigation/ScreenName";
import { CHAT_TYPE } from "constants/constants";

const isAndroid = Platform.OS === "android";
const INJECTED_JAVASCRIPT_HIDE_KEYBOARD = `(function() {
    setTimeout(()=>{
      /*--- auto scroll view to previous position when open keyboard on android ---*/
      if(${isAndroid}){
        let windowHeight = window.innerHeight;
        let prevCurrentScrollTop = 0;
        window.onresize = function(event) {
          const keyboardHeight = windowHeight-window.innerHeight;
          const height = (keyboardHeight ? windowHeight - keyboardHeight : windowHeight)-71;
          document.getElementById('root').querySelector('.overflow-auto').style.marginBottom= keyboardHeight ? "236px" : "0px";
          setTimeout(()=>{
            if(keyboardHeight){
              const currentScrollTop = document.getElementById('root').querySelector('.overflow-auto').scrollTop;
              prevCurrentScrollTop = currentScrollTop;
              document.getElementById('root').querySelector('.overflow-auto').scrollTo({top: keyboardHeight+currentScrollTop, behavior: "smooth"})
            }else{
              const currentScrollTop = document.getElementById('root').querySelector('.overflow-auto').scrollTop;
              document.getElementById('root').querySelector('.overflow-auto').scrollTo({top: prevCurrentScrollTop, behavior: "smooth"})
            }
          },100)
        };
      }
      /*---- end -----*/
      
      /*--- auto dismiss keyboard when scroll down ---*/
      var initTop=document.body.scrollTop;
      const inputElement = document.getElementById('composer-content-editable')
      document.getElementById('root').querySelector('.overflow-auto').addEventListener('scroll',function(){
        let currTop = this.scrollTop;
        let dist = currTop-initTop;
        if(dist === 0){
          return;
        }
        // dist<=0 is scroll up, else scroll down --> if scroll up -> unFocus input to hide keyboard
        if(dist<=0){
          inputElement.blur();
        }
        initTop = currTop;
    },false)
    /*---- end -----*/
  },1000)
})();`;
const runFirst = `
      console.log('firstRun')
      window.isNativeApp = true;
      true; // note: this is required, or you'll sometimes get silent failures
    `;

const CommentScreen = ({ route, navigation }) => {
  const { t } = useTranslation("app/screens/CommentScreen");
  const { type, objectId, docSequence } = route?.params;
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerComponent={
            <View style={styles.headerRow}>
              <AppText style={Fonts.H400}>{t("comment")}</AppText>
              <TouchableOpacity style={styles.flex} onPress={handleNavigateToScreen}>
                <AppText style={Fonts.H400} color={Colors.primary50} numberOfLines={1}>
                  {`[${docSequence}]`}
                </AppText>
              </TouchableOpacity>
            </View>
          }
        />
      ),
    } as any);
  }, []);

  const {
    company: { company_id },
  } = useAuth();
  const { data, loading } = useExpConvoChatUrl({ input: { companyId: company_id, objectId, type } });
  const [webWiewScrollEnabled, setWebWiewScrollEnabled] = useState(true);

  const handleNavigateToScreen = () => {
    if (type === CHAT_TYPE.REQUEST) {
      navigation.navigate(SCREEN_NAME.DetailRequestScreen, { expenseRequestId: objectId });
      return;
    }
    navigation.navigate(SCREEN_NAME.ReportDetailScreen, { expenseReportId: objectId });
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", (e) => {
      setWebWiewScrollEnabled(false);
    });
    const hideSubscription = Keyboard.addListener("keyboardWillHide", (e) => {
      setWebWiewScrollEnabled(true);
    });
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  if (Platform.OS === "web") {
    if (loading) {
      return <LoadingView style={styles.loadingView} />;
    }
    return (
      <iframe
        src={data?.expConvoUrl?.url}
        width="100%"
        height="100%"
        style={{ borderWidth: 0, background: Colors.white }}
      />
    );
  }
  return (
    <SafeAreaView edges={["bottom"]} style={styles.container}>
      <StatusBar barStyle="dark-content" />
      {Platform.OS === "android" ? (
        <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={80} style={styles.container}>
          <WebView
            loading={loading}
            uri={data?.expConvoUrl?.url}
            webViewProps={{
              bounces: false,
              hideKeyboardAccessoryView: true,
              injectedJavaScript: INJECTED_JAVASCRIPT_HIDE_KEYBOARD,
              injectedJavaScriptBeforeContentLoaded: runFirst,
              javaScriptEnabled: true,
              scrollEnabled: false,
            }}
          />
        </KeyboardAvoidingView>
      ) : (
        <WebView
          loading={loading}
          uri={data?.expConvoUrl?.url}
          webViewProps={{
            bounces: false,
            hideKeyboardAccessoryView: true,
            injectedJavaScript: INJECTED_JAVASCRIPT_HIDE_KEYBOARD,
            injectedJavaScriptBeforeContentLoaded: runFirst,
            javaScriptEnabled: true,
            scrollEnabled: webWiewScrollEnabled,
          }}
        />
      )}
    </SafeAreaView>
  );
};
export default CommentScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  loadingView: { backgroundColor: Colors.white },
  headerRow: { flexDirection: "row", gap: 6, flex: 1 },
  flex: { flex: 1 },
});
