import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 18, color = "#6554C0", ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M2 18h14a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v14a2 2 0 002 2zm3-7l2.363 2.363L11 8l5 7H2l3-4z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
