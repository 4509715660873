import { gql, useMutation } from "@apollo/client";
import {
  MobileGenerateExpReportDownloadFileMutation,
  MobileGenerateExpReportDownloadFileMutationVariables,
} from "types";

const GENERATE_EXP_REPORT_DOWNLOAD_FILE = gql`
  mutation MobileGenerateExpReportDownloadFile($input: ExpGenerateExpenseReportDownloadFileInput!) {
    expGenerateExpenseReportDownloadFile(input: $input) {
      success
    }
  }
`;

const useExpGenerateReportDownloadFile = () => {
  const [generateReportDownloadFile, { loading }] = useMutation<
    MobileGenerateExpReportDownloadFileMutation,
    MobileGenerateExpReportDownloadFileMutationVariables
  >(GENERATE_EXP_REPORT_DOWNLOAD_FILE);
  const mutation = async ({ input }) => {
    const { data } = await generateReportDownloadFile({
      variables: { input },
    });
    return data;
  };
  return { generateExpReportDownloadFile: mutation, loading };
};

export default useExpGenerateReportDownloadFile;
