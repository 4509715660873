import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { useAuth } from "./AuthContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";

export type IFeatures = Record<string, any>;

export interface IFeatureManagementContext {
  servicePlan: string;
  features: IFeatures;
  waitingUpgrade: boolean;
}

export const FEATURES = {
  EXPENSE_REQUEST: "bizzi-expense-request",
  EXPENSE_POLICY: "bizzi-expense-policy",
  EXPENSE_APPROVAL_FLOW: "bizzi-expense-approval-flow",
  TRAVEL_REQUEST: "bizzi-travel-request",
  EXPENSE_USERS_LIMIT: "bizzi-expense-users-limit",
  "BIZZI_VERSION_2.0": "bizzi-enable-new-ui-v.2.0",
  EXPENSE_INVOICE_PER_MONTH_LIMIT: "bizzi-expense-invoices-per-user-per-month-limit",
  FEATURE_TRANSFER_EXPENSE_INVOICE: "FEATURE_TRANSFER_EXPENSE_INVOICE",
};

const FEATURE_FLAGS_QUERY = gql`
  query MobileFeatureFlags($groupId: UUID, $companyId: UUID) {
    featureFlags: fmFeatureFlags(where: { context: { groupId: $groupId, companyId: $companyId } }) {
      name
      enabled
      customFields
    }
  }
`;

export const useFeatureFlags = () => {
  const { groupId, company } = useAuth();
  const companyId = company?.company_id;
  const result = useQuery<{ featureFlags: FmFeatureFlag[] }, { groupId: string; companyId: string }>(
    FEATURE_FLAGS_QUERY,
    {
      variables: { groupId, companyId },
      skip: !groupId || !companyId,
      nextFetchPolicy: "cache-and-network",
    }
  );
  const featureFlags = result.data?.featureFlags ?? [];
  const features = featureFlags.reduce((featureMap, feature) => {
    featureMap[feature.name] = feature;
    return featureMap;
  }, {} as Record<string, FmFeatureFlag>);

  return {
    ...result,
    features,
  };
};

const FeatureManagementContext = createContext<IFeatureManagementContext>({
  servicePlan: "",
  features: {},
  waitingUpgrade: false,
});

export const useFeatureManagement = () => {
  const { features, servicePlan, waitingUpgrade } = useContext(FeatureManagementContext);
  const isFeatureEnabled = useCallback(
    (featureCode) => {
      // avoid issue: auto redirect to home screen when refresh page on web app
      if (
        !Object.keys(features)?.length &&
        (featureCode === FEATURES.EXPENSE_REQUEST || featureCode === FEATURE_FLAGS.FEATURE_CARD)
      ) {
        return true;
      }
      // If the feature flag is cleared in Unleash, return true to avoid issues caused by users using an outdated app version.
      if (!features?.[featureCode]) {
        return true;
      }
      return features?.[featureCode]?.enabled ?? false;
    },
    [features]
  );
  return { isFeatureEnabled, features, servicePlan, waitingUpgrade };
};

export const useIsFeatureEnabled = (featureCode: string): boolean => {
  const { isFeatureEnabled } = useFeatureManagement();
  return isFeatureEnabled(featureCode);
};

export const useIsExpenseRequestEnabled = () => {
  const { isFeatureEnabled } = useFeatureManagement();

  return isFeatureEnabled(FEATURES.EXPENSE_REQUEST);
};

export const useIsFeaturesEnabled = (featureCodes: Array<string>) => {
  const { isFeatureEnabled } = useFeatureManagement();
  return featureCodes.map(isFeatureEnabled);
};

export const useIsCategoryDefault = () => {
  const { features, servicePlan, waitingUpgrade } = useContext(FeatureManagementContext);
  const [isEnabled, setIsEnabled] = useState(false);
  const selectCategoryDefault = useCallback(
    (company_id: string) => {
      if (
        isEnabled &&
        features?.[FEATURES["BIZZI_VERSION_2.0"]]?.customFields?.groups?.[company_id]?.expense_category
      ) {
        return features?.[FEATURES["BIZZI_VERSION_2.0"]]?.customFields?.groups?.[company_id]?.expense_category;
      }
      return;
    },
    [features, isEnabled]
  );

  useEffect(() => {
    setIsEnabled(features?.[FEATURES["BIZZI_VERSION_2.0"]]?.enabled ?? false);
  }, [features]);
  return { isEnabled, features, servicePlan, waitingUpgrade, selectCategoryDefault };
};

export default FeatureManagementContext;
