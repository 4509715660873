// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState, useCallback, forwardRef } from "react";
import * as React from "react";
import {
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
  TextInputProps,
  Platform,
  TextStyle,
  TextInput as RNTextInput,
} from "react-native";
import { Modal } from "@ant-design/react-native";
import { TextInput } from "react-native-paper";
import { Colors } from "app/constants/Colors";
import Fonts from "constants/Fonts";
import TextInputError from "screens/RegisterOnboarding/TextInputError";

export interface IInputFieldFlat {
  label: string;
  error?: string;
  touched?: boolean;
  extra?: React.ReactElement;
  help?: React.ReactElement;
  leftImage?: React.ReactElement;
  tooltipText?: string;
  inputStyle?: StyleProp<TextStyle>;
  widthInput?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  rightTitle?: string;
  rightTitleStyle?: StyleProp<ViewStyle>;
  onPressRightTitle?: string;
  noLine?: boolean;
  noUnderLine?: boolean;
  iconRight?: React.ReactElement;
  disabled?: boolean;
}

const InputFieldFlat = forwardRef((props: IInputFieldFlat & TextInputProps, ref: React.ForwardedRef<RNTextInput>) => {
  const {
    error,
    touched,
    extra,
    help,
    leftImage,
    tooltipText,
    inputStyle,
    widthInput,
    style,
    rightTitle,
    rightTitleStyle,
    onPressRightTitle,
    noUnderLine,

    noLine,
    iconRight,
    disabled,
    ...rest
  } = props;
  const [showHelp, setShowHelp] = useState(false);

  const hasError = !!error && touched;

  const onPressShowHelp = useCallback(() => {
    setShowHelp(true);
  }, []);

  const onPressCloseHelp = useCallback(() => {
    setShowHelp(false);
  }, []);

  return (
    <View style={widthInput}>
      <View style={styles.containerInput}>
        {leftImage}
        <View style={styles.wrapperInput}>
          <TextInput
            ref={ref}
            style={StyleSheet.flatten([styles.input, inputStyle, hasError && styles.inputError])}
            underlineColor="transparent"
            // activeUnderlineColor="transparent"
            disabled={disabled}
            theme={{
              colors: { text: inputStyle?.color ?? hasError ? Colors.alert50 : Colors.Ink500, background: "white" },
              fonts: {
                regular: {
                  fontFamily: inputStyle?.fontFamily ?? Fonts.fontFamily,
                },
              },
            }}
            placeholderTextColor={hasError ? Colors.alert50 : Colors.gray10}
            {...(Platform.OS === "android" && {
              underlineColorAndroid: "transparent",
              textAlignVertical: "center",
            })}
            allowFontScaling
            right={iconRight}
            {...rest}
          />
          {hasError && <TextInputError>{error}</TextInputError>}
        </View>
      </View>

      {help && (
        <Modal
          maskClosable
          closable
          transparent
          visible={showHelp}
          onClose={onPressCloseHelp}
          footer={[{ text: "OK", onPress: onPressCloseHelp }]}
        >
          <View style={styles.wrapperModalStyle}>{help}</View>
        </Modal>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  label: {
    fontSize: 12,
    color: Colors.blue400,
  },
  input: {
    borderWidth: 1,
    borderRadius: 8,
    marginTop: 28,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    color: "#0F172A",

    backgroundColor: Colors.white500,
    borderColor: "#E2E8F0",
  },
  error: {
    color: Colors.alert50,
  },
  leftImage: {
    position: "absolute",
    left: 8,
    top: 8,
  },

  popoverStyle: { backgroundColor: Colors.black, padding: 10 },
  wrapperModalStyle: { paddingVertical: 20, paddingHorizontal: 20 },
  wrapperExtraStyle: { justifyContent: "center", alignItems: "center", paddingBottom: 8 },
  errorStyle: { marginTop: 5, fontSize: 12, color: Colors.alert50 },
  wrapperInput: {
    flex: 1,
  },
  inputError: {
    backgroundColor: Colors.alert0,
    borderColor: Colors.alert50,
  },
  containerInput: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default InputFieldFlat;
