enum ReportStatus {
  DRAFT = 0,
  SUBMITTED = 1,
  AWAITING_MANAGER_APPROVAL = 1,
  REJECTED = 2,
  AWAITING_ACCOUNTANT_APPROVAL = 3,
  APPROVED = 4,
  CANCEL = 5,
}

export const NO_FLOW_REGISTERED = "no_flow_registered";

export default ReportStatus;
