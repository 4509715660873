/* eslint-disable react-native/no-inline-styles */
import { AppText, AvatarUser, DashedLine, EmployeeModalView, IconCustom } from "components";
import { APPROVAL_METHOD, APPROVAL_STATUS, CONSTANTS } from "constants/constants";
import dayjs from "dayjs";
import { FC, useRef } from "react";
import { Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { ArrowApprovalIcon } from "assets/images/svg/icons";
import { EmployeeModalViewMethods } from "components/EmployeeInfoView/EmployeeModalView";
import { useScrollContextApis } from "./ScrollViewCustom/ScrollViewCustom";
import { useTranslation } from "react-i18next";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";
import { StackNavigation } from "navigation/type";

export type ApprovalTimeLineItem = {
  approvalFlowName?: string;
  name?: string;
  status: APPROVAL_STATUS;
  updatedAt?: string;
  approvers: {
    isRequestor?: boolean;
    approvalMethod?: APPROVAL_METHOD;
    employeeId: string;
    employee: {
      title?: string;
      fullname?: string;
      avatar?: string;
    };
    status: APPROVAL_STATUS;
  }[];
  isPartialApproved?: boolean;
  reason?: string;
  rejectedExpenseReport?: { expenseReportId?: string; docSequence?: string };
};
export interface ApprovalTimeLineProp {
  data: ApprovalTimeLineItem[];
  style?: StyleProp<ViewStyle>;
  lineColor?: string;
}
const ApprovalTimeLine: FC<ApprovalTimeLineProp> = (props) => {
  const { t } = useTranslation("app/components/ApprovalTimeLine");
  const navigation = useNavigation<StackNavigation>();
  const { setLayoutData } = useScrollContextApis();
  const { data: stages, lineColor, style } = props;
  const employeeModalViewRef = useRef<EmployeeModalViewMethods>();
  const handleViewUserProfile =
    ({ employeeId, avatar }) =>
    () => {
      employeeModalViewRef?.current?.present({ employeeId, avatar });
    };

  const onStageLayout = (event, stage) => {
    switch (stage.status) {
      case APPROVAL_STATUS.APPROVED:
        setLayoutData("stage-approved", event.nativeEvent.layout);
        break;
      case APPROVAL_STATUS.AWAITING:
        setLayoutData("stage-awaiting", event.nativeEvent.layout);
        break;
      case APPROVAL_STATUS.PENDING:
        setLayoutData("stage-pending", event.nativeEvent.layout, "cache");
        break;
      case APPROVAL_STATUS.REJECTED:
        setLayoutData("stage-rejected", event.nativeEvent.layout);
        break;
    }
  };

  const renderActionLabel = (stage, employee) => {
    if (![APPROVAL_STATUS.APPROVED, APPROVAL_STATUS.REJECTED].includes(stage.status)) {
      return null;
    }

    if (employee?.approvalMethod === APPROVAL_METHOD.AUTO) {
      return (
        <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
          {t("auto_approved_at", { date: dayjs(stage.updatedAt).format(CONSTANTS.FORMAT_DAY) })}
        </AppText>
      );
    }

    if (stage.status === APPROVAL_STATUS.APPROVED) {
      return (
        <>
          {stage.isPartialApproved ? (
            <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
              {t("partial_approved_at", { date: dayjs(stage.updatedAt).format(CONSTANTS.FORMAT_DAY) })}
            </AppText>
          ) : (
            <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
              {t("approved_at", { date: dayjs(stage.updatedAt).format(CONSTANTS.FORMAT_DAY) })}
            </AppText>
          )}
          {Boolean(stage.reason) && (
            <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
              {t("partial_approved_reason", { reason: stage.reason })}
            </AppText>
          )}
          {Boolean(stage?.rejectedExpenseReport?.expenseReportId) && (
            <AppText
              style={Fonts.BodySmall}
              color={Colors.grayscale70}
              onPress={() =>
                navigation.push(SCREEN_NAME.EditReportScreen, {
                  expenseReportId: stage.rejectedExpenseReport.expenseReportId,
                })
              }
            >
              {t("rejected_report")}
              <AppText color={Colors.primary50}>{stage.rejectedExpenseReport.docSequence}</AppText>
            </AppText>
          )}
        </>
      );
    }

    if (stage.status === APPROVAL_STATUS.REJECTED) {
      return (
        <AppText style={Fonts.BodySmall} color={Colors.grayscale70}>
          {t("rejected_at", { date: dayjs(stage.updatedAt).format(CONSTANTS.FORMAT_DAY) })}
        </AppText>
      );
    }

    return "";
  };

  return (
    <View
      style={[style, styles.timeLineContainer]}
      onLayout={(event) => {
        setLayoutData("approval-flow-section-content", event.nativeEvent.layout);
      }}
    >
      {Boolean(stages?.[0]?.approvalFlowName) && (
        <AppText style={styles.approvalFlowName} color={Colors.grayscale70}>
          {stages[0].approvalFlowName}
        </AppText>
      )}
      {stages?.map((stage, stageIndex) => {
        const prev = stages[stageIndex - 1];
        let icon, currentLineColor;
        switch (stage.status) {
          case APPROVAL_STATUS.REJECTED:
            icon = <IconCustom name="timeline-times" />;
            currentLineColor = Colors.alert10;
            break;
          case APPROVAL_STATUS.APPROVED:
            icon = <IconCustom name="timeline-check-circle" />;
            currentLineColor = Colors.success50;
            break;
          default:
            currentLineColor = lineColor ? lineColor : Colors.grayscale40;
            icon = <IconCustom name="timeline-circle" stroke={currentLineColor} />;
            if (!prev || prev.status === APPROVAL_STATUS.APPROVED) {
              icon = <IconCustom name="timeline-circle" stroke={Colors.primary50} />;
              currentLineColor = Colors.primary50;
            }
            break;
        }
        const personApproveOrReject = stage?.approvers?.find((e) =>
          [APPROVAL_STATUS.APPROVED, APPROVAL_STATUS.REJECTED].includes(e?.status)
        );
        const stakeholders = personApproveOrReject ? [personApproveOrReject] : stage?.approvers;
        const isLastItem = stageIndex === stages?.length - 1;
        return (
          <View
            key={stageIndex}
            style={{
              zIndex: stages.length - stageIndex,
              marginTop: Platform.OS === "web" ? -14 : 0,
              overflow: "hidden",
            }}
            onLayout={(event) => onStageLayout(event, stage)}
          >
            {stages.length === 1 && (
              <DashedLine
                dashColor={currentLineColor}
                dashGap={3}
                style={[
                  styles.divider,
                  Platform.OS === "web"
                    ? { borderColor: currentLineColor, top: 14 }
                    : { borderColor: currentLineColor },
                ]}
                isVertical
              />
            )}
            {!isLastItem && (
              <>
                <DashedLine
                  dashColor={currentLineColor}
                  dashGap={3}
                  style={[
                    styles.divider,
                    Platform.OS === "web"
                      ? { borderColor: currentLineColor, top: 14 }
                      : { borderColor: currentLineColor },
                  ]}
                  isVertical
                />
                <View style={[styles.arrowDownIcon, Platform.OS === "web" && { bottom: 0 }]}>
                  <ArrowApprovalIcon color={currentLineColor} />
                </View>
              </>
            )}
            <View style={[styles.content, stages.length === 1 ? { paddingBottom: 0 } : {}]}>
              <View style={styles.debugDot}>{icon}</View>
              <View style={styles.approvalRuleNameContainer}>
                <AppText style={Fonts.BodyMedium} color={Colors.brand100}>
                  {stage.name}
                </AppText>
              </View>
              {stakeholders
                ?.sort((a, b) => (Number(b?.isRequestor) ?? 0) - (Number(a?.isRequestor) ?? 0))
                .map((employee, index) => (
                  <View
                    key={employee.employeeId}
                    style={[styles.personContainer, Platform.OS === "web" && { marginTop: index === 0 ? 30 : 15 }]}
                  >
                    <View style={styles.flex}>
                      <TouchableOpacity
                        onPress={handleViewUserProfile({
                          employeeId: employee?.employeeId,
                          avatar: employee?.employee?.avatar,
                        })}
                      >
                        <View style={styles.employeeContainer}>
                          <AvatarUser
                            fullname={employee?.employee?.fullname}
                            url={employee?.employee?.avatar}
                            textStyle={Fonts.BodySmall}
                            size={24}
                          />
                          <View style={styles.approverContainer}>
                            <AppText>
                              <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                                {[employee?.employee?.fullname, employee?.employee?.title]
                                  .filter((t) => !!t)
                                  .join(" - ")}
                              </AppText>
                              {employee?.isRequestor && (
                                <AppText style={Fonts.BodySmall}>{` (${t("requestor")})`}</AppText>
                              )}
                              {employee?.approvalMethod === APPROVAL_METHOD.AUTO &&
                                APPROVAL_STATUS.APPROVED !== stage.status && (
                                  <View style={{ transform: [{ translateY: 4 }, { translateX: 4 }] }}>
                                    <View>
                                      <IconCustom name="check-circle-outline" />
                                      <View style={styles.scheduleIcon}>
                                        <IconCustom name="schedule-filled" fill={Colors.success50} />
                                      </View>
                                    </View>
                                  </View>
                                )}
                            </AppText>
                          </View>
                        </View>
                        <View style={styles.approverBottom}>{renderActionLabel(stage, employee)}</View>
                      </TouchableOpacity>
                    </View>
                  </View>
                ))}
            </View>
          </View>
        );
      })}
      <EmployeeModalView ref={employeeModalViewRef} />
    </View>
  );
};
export default ApprovalTimeLine;

const styles = StyleSheet.create({
  timeLineContainer: {
    paddingVertical: 0,
  },
  divider: {
    width: 2,
    position: "absolute",
    height: "100%",
    left: 11,
  },
  flex: { flex: 1 },
  debugDot: {
    borderRadius: 999,
    position: "absolute",
    left: -44,
  },
  content: {
    paddingVertical: 14,
    marginLeft: 44,
  },
  approvalRuleNameContainer: {
    position: "absolute",
    alignItems: "center",
  },
  personContainer: {
    marginTop: 14,
    flexDirection: "row",
    gap: 8,
  },
  approverContainer: {
    flex: 1,
  },
  approverBottom: { marginLeft: 32 },
  arrowDownIcon: { position: "absolute", bottom: 0, left: 6 },
  employeeContainer: {
    alignSelf: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    gap: 8,
  },
  scheduleIcon: {
    position: "absolute",
    bottom: 0,
    right: -4,
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 6,
  },
  approvalFlowName: {
    ...Fonts.BodyRegular,
    marginBottom: 13,
    marginTop: -8,
  },
});
