import { AlertNotification, AlertNotificationHandle, AppText, CurrencyText, IconCustom, LoadingView } from "components";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import { FC, useMemo, useRef } from "react";
import { getTitleI18n } from "utils";
import { CONSTANTS } from "constants/constants";
import { EmptyPolicy } from "assets/images/svg/icons";
import { useTranslation } from "react-i18next";
import { CustomTable } from "components/Table";
import { MobileExpEmployeeExpensePolicyTravelQuery } from "types";

type HotelPolicyProps = {
  loading: boolean;
  hotelBooking: MobileExpEmployeeExpensePolicyTravelQuery["expEmployeeExpensePolicyTravel"]["hotelBooking"];
};
const HotelPolicy: FC<HotelPolicyProps> = ({ hotelBooking, loading }) => {
  const { t } = useTranslation("app/screens/PolicyEmployee/PolicyEmployeeScreen");

  const transformedData = useMemo(() => {
    if (!hotelBooking?.policies) {
      return { domestic: [], overseas: [], all: [] };
    }

    const data = hotelBooking.policies.reduce(
      (acc, policy) => {
        const { scope, order, location, amount } = policy;
        const key = `${order}_${amount}_${location ? "specific" : "common"}`;
        if (!acc[scope][key]) {
          acc[scope][key] = { order, names: [], amount };
        }
        const name = location
          ? getTitleI18n({ vi: location.name, en: location.nameEn })
          : {
              domestic: t("other_domestic_locations"),
              overseas: t("other_international_locations"),
              all: t("all_locations"),
            }[scope];
        acc[scope][key].names.push(name);
        return acc;
      },
      { domestic: {}, overseas: {}, all: {} }
    );

    const transform = (object) => {
      return Object.values(object)
        .map((x: any) => ({
          ...x,
          names: x.names.join(", "),
        }))
        .sort((a, b) => a.order - b.order);
    };

    const rs = {
      domestic: transform(data.domestic),
      overseas: transform(data.overseas),
      all: transform(data.all),
    };

    return rs;
  }, [hotelBooking, t]);

  const alertNotification = useRef<AlertNotificationHandle>();

  if (loading) {
    return <LoadingView style={styles.loadingView} />;
  }

  if (!hotelBooking || !hotelBooking.isEnabled) {
    return (
      <View style={styles.emptyContainer}>
        <EmptyPolicy />
        <View style={[styles.emptyContent, styles.gap8]}>
          <AppText style={[Fonts.H400, styles.textCenter]} color={Colors.grayscale100}>
            {t("empty_hotel_policy")}
          </AppText>
          <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale100}>
            {t("empty_hotel_policy_description")}
          </AppText>
        </View>
      </View>
    );
  }

  const renderHighestHotelStar = () => {
    if (!hotelBooking.maxHotelLevel) {
      return null;
    }

    return (
      <View style={styles.gap8}>
        <View style={styles.inline}>
          <AppText style={Fonts.SentenceSubTitleXLarge} individual>
            {t("highest_star")}
          </AppText>
          <TouchableOpacity
            onPress={() => {
              alertNotification.current.info({
                title: t("highest_star"),
                description: t("highest_star_tooltip"),
                confirmText: t("understood"),
                onConfirm: () => alertNotification.current.close(),
              });
            }}
          >
            <IconCustom name="info" height={20} width={20} />
          </TouchableOpacity>
        </View>
        <AppText style={Fonts.BodyMedium}>
          {t("hotel_highest_star")}
          <AppText style={Fonts.H200}>
            {
              {
                1: t("1_start"),
                2: t("2_start"),
                3: t("3_start"),
                4: t("4_start"),
                5: t("5_start"),
              }[hotelBooking.maxHotelLevel]
            }
          </AppText>
        </AppText>
      </View>
    );
  };

  const renderTimeToBooking = () => {
    if (!hotelBooking.maxAdvanceBookingDays && !hotelBooking.minAdvanceBookingDays) {
      return null;
    }

    return (
      <View style={styles.gap8}>
        <View style={styles.inline}>
          <AppText style={Fonts.SentenceSubTitleXLarge} individual>
            {t("time_to_reservation")}
          </AppText>
          <TouchableOpacity
            onPress={() => {
              alertNotification.current.info({
                title: t("time_to_reservation"),
                description: t("time_to_reservation_tooltip"),
                confirmText: t("understood"),
                onConfirm: () => alertNotification.current.close(),
              });
            }}
          >
            <IconCustom name="info" height={20} width={20} />
          </TouchableOpacity>
        </View>
        <View style={[styles.inline, styles.gap16]}>
          {!!hotelBooking.maxAdvanceBookingDays && (
            <AppText style={Fonts.BodyMedium}>
              {t("max")}:{" "}
              <AppText style={Fonts.H200}>
                {hotelBooking.maxAdvanceBookingDays} {t("day")}
              </AppText>
            </AppText>
          )}
          {!!hotelBooking.minAdvanceBookingDays && (
            <AppText style={Fonts.BodyMedium}>
              {t("min")}:{" "}
              <AppText style={Fonts.H200}>
                {hotelBooking.minAdvanceBookingDays} {t("day")}
              </AppText>
            </AppText>
          )}
        </View>
      </View>
    );
  };

  return (
    <ScrollView contentContainerStyle={[styles.scrollView, styles.gap24]} showsVerticalScrollIndicator={false}>
      <View style={styles.gap16}>
        <View style={styles.gap8}>
          <View style={styles.inline}>
            <AppText style={Fonts.SentenceSubTitleXLarge} individual>
              {t("limit_by_destinations")}
            </AppText>
            <TouchableOpacity
              onPress={() => {
                alertNotification.current.info({
                  title: t("limit_by_destinations"),
                  description: t("limit_by_destinations_tooltip"),
                  confirmText: t("understood"),
                  onConfirm: () => alertNotification.current.close(),
                });
              }}
            >
              <IconCustom name="info" height={20} width={20} />
            </TouchableOpacity>
          </View>
          <AppText style={Fonts.BodyMedium}>{t("hotel_limit_by_destinations_description")}</AppText>
        </View>
        {transformedData.domestic.length > 0 && (
          <CustomTable
            columns={[
              {
                key: "names",
                title: t("domestic_locations"),
                render: (item) => <AppText style={Fonts.BodyMedium}>{item.names}</AppText>,
              },
              {
                width: 100,
                key: "amount",
                title: t("cost_per_night"),
                render: (item) => (
                  <CurrencyText suffix="" style={[Fonts.NumericN200, styles.textRight]} color={Colors.grayscale100}>
                    {item.amount}
                  </CurrencyText>
                ),
              },
            ]}
            dataSource={transformedData.domestic}
          />
        )}
        {transformedData.overseas.length > 0 && (
          <CustomTable
            columns={[
              {
                key: "names",
                title: t("international_locations"),
                render: (item) => <AppText style={Fonts.BodyMedium}>{item.names}</AppText>,
              },
              {
                width: 100,
                key: "amount",
                title: t("cost_per_night"),
                render: (item) => (
                  <CurrencyText suffix="" style={[Fonts.NumericN200, styles.textRight]} color={Colors.grayscale100}>
                    {item.amount}
                  </CurrencyText>
                ),
              },
            ]}
            dataSource={transformedData.overseas}
          />
        )}
        {transformedData.all.length > 0 && (
          <CustomTable
            columns={[
              {
                key: "names",
                title: t("locations"),
                render: (item) => <AppText style={Fonts.BodyMedium}>{item.names}</AppText>,
              },
              {
                width: 100,
                key: "amount",
                title: t("cost_per_night"),
                render: (item) => (
                  <CurrencyText suffix="" style={[Fonts.NumericN200, styles.textRight]} color={Colors.grayscale100}>
                    {item.amount}
                  </CurrencyText>
                ),
              },
            ]}
            dataSource={transformedData.all}
          />
        )}
        <AppText style={Fonts.BodyMedium}>
          {t("hotel_cost_to_category")}
          <AppText style={Fonts.H200}>
            {hotelBooking?.expenseCategory
              ? getTitleI18n({
                  vi: hotelBooking.expenseCategory.title,
                  en: hotelBooking.expenseCategory.titleEn,
                })
              : t("not_set_up_category")}
          </AppText>
        </AppText>
      </View>
      {renderHighestHotelStar()}
      {renderTimeToBooking()}
      <AlertNotification ref={alertNotification} />
    </ScrollView>
  );
};

export default HotelPolicy;

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  gap8: { gap: 8 },
  gap16: { gap: 16 },
  gap24: { gap: 24 },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  inline: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  loadingView: {
    backgroundColor: Colors.white,
  },
  emptyContainer: {
    paddingVertical: 80,
    alignItems: "center",
  },
  emptyContent: {
    padding: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
