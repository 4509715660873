import { AppText } from "components/index";
import { useAuth } from "contexts/AuthContext";
import { FC } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { removeAccents } from "utils/searchObject";

interface UserAvatarDefaultProps {
  style?: ViewStyle;
}

const UserAvatarDefault: FC<UserAvatarDefaultProps> = ({ style }) => {
  const { user } = useAuth();
  const lastname = user?.fullname?.trim()?.split(" ").pop();
  const firstChar = removeAccents(lastname?.charAt(0)?.toUpperCase() ?? "B");

  return (
    <View style={[styles.avatar, style]}>
      <AppText style={Fonts.H200} color={Colors.white}>
        {firstChar}
      </AppText>
    </View>
  );
};
export default UserAvatarDefault;

const styles = StyleSheet.create({
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 40 / 2,
    backgroundColor: Colors.primary20,
    justifyContent: "center",
    alignItems: "center",
  },
});
