import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { MobileInsertExpenseMutation, MobileInsertExpenseMutationVariables } from "types";

const INSERT_EXPENSE = gql`
  mutation MobileInsertExpense($expense: ExpInsertExpenseInput!) {
    expInsertExpense(input: $expense) {
      message
      expenseId
      status
      expenseReport {
        docSequence
        expenseReportId
        title
      }
    }
  }
`;

export const useExpInsertExpenseMutation = (
  options?: MutationHookOptions<MobileInsertExpenseMutation, MobileInsertExpenseMutationVariables>
) => {
  return useMutation(INSERT_EXPENSE, { ...options });
};
