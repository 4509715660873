import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpEmployeeExpenseCategoriesQuery, MobileExpEmployeeExpenseCategoriesQueryVariables } from "types";
import { useAuth } from "contexts/AuthContext";

const QUERY = gql`
  query MobileExpEmployeeExpenseCategories($where: ExpEmployeeExpenseCategoriesInput!) {
    expEmployeeExpenseCategories(where: $where) {
      expenseCategories {
        expenseCategory {
          code
          expenseCategoryId
          title
          titleEn
          subAccounts {
            accountCode
            title
            titleEn
          }
        }
      }
    }
  }
`;

const useExpEmployeeExpenseCategories = () => {
  const { user: { employee_id: employeeId, company } = {}, groupId } = useAuth();

  const { data, loading } = useQuery<
    MobileExpEmployeeExpenseCategoriesQuery,
    MobileExpEmployeeExpenseCategoriesQueryVariables
  >(QUERY, {
    variables: { where: { companyId: company?.company_id, groupId, employeeId: employeeId } },
    skip: !company?.company_id || !groupId || !employeeId,
    fetchPolicy: "cache-and-network",
  });
  return {
    data: data?.expEmployeeExpenseCategories?.expenseCategories?.map((item) => item?.expenseCategory) ?? [],
    loading,
  };
};

export default useExpEmployeeExpenseCategories;
