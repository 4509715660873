import { Image, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { AppText, Line } from "components";
import { styles } from "./commonStyle";
import { Colors, Fonts } from "theme";
import { Onboarding01 } from "assets/images";

const Step1 = () => {
  const { t } = useTranslation("app/screens/Onboarding/OnboardingScreen");

  const { top } = useSafeAreaInsets();
  return (
    <View style={[styles.container, { marginTop: top }]}>
      <Image resizeMode="contain" source={Onboarding01} style={styles.image} />
      <Line hasBackground={false} size="xl" />
      <AppText style={[Fonts.H400, styles.textCenter]}>{t("titleStep01")}</AppText>
      <Line hasBackground={false} size="xl" />
      <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale80}>
        {t("descriptionStep01")}
      </AppText>
    </View>
  );
};

export default Step1;
