import dayjs from "dayjs";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { AppText, CurrencyText, IconCustom } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { CircleCheckedBox, ExpenseItemIcon } from "assets/images/svg/icons";
import StatusExpense from "components/ExpenseItem/StatusExpense";
import { MobileExpAssignableExpenseReportExpensesQuery } from "types";

type ExpenseItem = MobileExpAssignableExpenseReportExpensesQuery["expAssignableExpenseReportExpenses"]["expenses"][0];
interface ExpenseSelectItemProps {
  item: ExpenseItem;
  isChecked?: boolean;
  handleSelectExpense?: (item: ExpenseItem) => () => void;
  handleNavigateDetail?: (id: string) => () => void;
  disabled?: boolean;
}
const ExpenseSelectItem: FC<ExpenseSelectItemProps> = ({
  isChecked,
  item,
  handleSelectExpense,
  handleNavigateDetail,
  disabled = false,
}) => {
  const { t } = useTranslation("app/screens/Report/components/CreateOrEditForm");
  return (
    <>
      <TouchableOpacity
        disabled={disabled}
        style={[styles.expenseItem, { backgroundColor: isChecked ? Colors.primary0 : Colors.white }]}
        onPress={handleSelectExpense(item)}
      >
        {isChecked ? (
          <CircleCheckedBox width={48} height={48} />
        ) : (
          <ExpenseItemIcon
            backgroundColor={disabled ? Colors.grayscale05 : undefined}
            iconColor={disabled ? Colors.grayscale60 : undefined}
            width={48}
            height={48}
          />
        )}
        <View style={styles.flex}>
          {Boolean(item?.expenseCardTransactions?.[0]) && (
            <AppText style={[Fonts.BodySmall, { marginBottom: 4 }]} color={Colors.grayscale60}>
              {t("expense_from_transaction")}
            </AppText>
          )}
          <View style={styles.rowItemCenter}>
            <AppText style={styles.expenseTitle} numberOfLines={1}>
              {item?.note?.trim() || t("need_explanation")}
            </AppText>
            <CurrencyText style={Fonts.NumericN200}>{item?.totalAmountWithVat}</CurrencyText>
          </View>
          <View style={[styles.rowItemCenter, { marginTop: 3 }]}>
            <AppText style={Fonts.BodySmall}>{item?.docSequence}</AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {dayjs(item.expenseDate).format(CONSTANTS.FORMAT_DAY)}
            </AppText>
          </View>
          <View style={styles.statusContainer}>
            <StatusExpense value={item?.status} />
            <TouchableOpacity style={styles.viewButton} onPress={handleNavigateDetail(item?.expenseId)}>
              <AppText style={Fonts.BodySmall} color={Colors.primary50}>
                {t("view")}
              </AppText>
              <IconCustom name="arrow-right-alt" />
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};
export default ExpenseSelectItem;
const styles = StyleSheet.create({
  expenseItem: {
    paddingVertical: 18,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  rowItemCenter: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8 },
  flex: { flex: 1 },
  expenseTitle: { ...Fonts.BodyMedium, flex: 1 },
  viewButton: {
    flexDirection: "row",
    gap: 4,
  },
  statusContainer: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 4 },
});
