import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6.308 20h11.384a.3.3 0 00.221-.087.3.3 0 00.087-.22v-9.385a.3.3 0 00-.087-.222.3.3 0 00-.22-.086H6.307a.3.3 0 00-.221.086.3.3 0 00-.087.222v9.384a.3.3 0 00.087.221.3.3 0 00.22.087zM12 16.75c.486 0 .899-.17 1.24-.51.34-.341.51-.754.51-1.24s-.17-.9-.51-1.24c-.341-.34-.754-.51-1.24-.51s-.9.17-1.24.51c-.34.34-.51.754-.51 1.24s.17.899.51 1.24c.34.34.754.51 1.24.51zM6.308 21.5c-.499 0-.925-.177-1.278-.53a1.742 1.742 0 01-.53-1.278v-9.384c0-.5.177-.925.53-1.278.353-.353.779-.53 1.278-.53H13.5v-2c0-1.249.438-2.31 1.313-3.187C15.69 2.438 16.751 2 18 2c1.106 0 2.064.346 2.874 1.037a4.457 4.457 0 011.524 2.575.668.668 0 01-.129.603c-.143.19-.316.285-.519.285a.894.894 0 01-.527-.146.79.79 0 01-.294-.45 3.04 3.04 0 00-1.024-1.71A2.829 2.829 0 0018 3.5c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0015 6.5v2h2.692c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v9.384c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53H6.308z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
