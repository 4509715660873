import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6.25 13.75h7.5v-1.5h-7.5v1.5zm0-3h11.5v-1.5H6.25v1.5zm0-3h11.5v-1.5H6.25v1.5zM2.5 21.038V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.038L2.5 21.038zM5.4 16h14.292a.294.294 0 00.212-.096.294.294 0 00.096-.212V4.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H4.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v13.077L5.4 16z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
