import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path d="M24 0H0v24h24V0z" fill="#fff" />
      <Path
        d="M9 20.25h13.5M2.25 9.722v-4.93a.75.75 0 01.984-.713L4.5 4.5 6 7.313l3 .937V4.791a.75.75 0 01.984-.712l1.266.421 3 5.25 4.556 1.266A2.99 2.99 0 0121 13.904v3.346L4.444 12.61A2.99 2.99 0 012.25 9.722v0z"
        stroke="#1F71F4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
