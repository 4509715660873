import { gql, LazyQueryHookOptions, useLazyQuery } from "@apollo/client";
import { MobileExpenseDetailsByPkQuery, MobileExpenseDetailsByPkQueryVariables } from "types/generated";

const EXPENSE_BY_PK = gql`
  query MobileExpenseDetailsByPk($expenseId: UUID!) {
    expExpenseDetails(expenseId: $expenseId) {
      exchangeRate
      foreignTotalAmountWithVat
      currency
      allocatedStatus
      parent {
        docSequence
        note
        companyEmployee {
          fullname
        }
        companyTeam {
          code
          name
          nameEn
        }
      }
      employeeId
      docSequence
      status
      note
      address
      expenseId
      provider
      category
      placeDetails
      companyTeamId
      invoiceType
      companyTeam {
        companyTeamId
        code
        name
        status
        nameEn
        deletedAt
      }
      invoice {
        emailId
        invoiceId
        receivedAt
        buyerTaxCode
        invoiceNumber
        sellerTaxCode
        totalVatAmount
        buyerLegalName
        sellerLegalName
        buyerDisplayName
        sellerAddressLine
        totalAmountWithVat
        totalAmountWithoutVat
        invoiceItems {
          invoiceItemId
          itemName
        }
        invoiceValidations {
          isExpenseInvoiceValid
          expenseValidations {
            status
            results {
              key
              status
            }
          }
          evidences
          isLegalSeller
          isCorrectCompany
          validationAggregation
          invoiceValidationId
          gdtResults
          isSignatureValid
          isDigestValid
          isIssued
          companySnapshot
          tchdScreenshot
          tchdScreenshotAt
          manualValidations
          signatureErrors
          certValidations
          isVerifying
        }
      }
      expenseDate
      expenseInfo
      expenseTravelId
      expenseReportId
      receivedInvoice
      totalAmountWithVat
      customFields
      expenseTravel {
        expenseTravelId
      }
      expenseReport {
        status
        title
        companyId
        rejectedReason
        expenseReportId
        docSequence
      }
      expenseRequest {
        cashAdvances {
          amount
        }
        title
        docSequence
        expenseRequestId
        expenseRequestType
      }
      expenseCategory {
        order
        title
        titleEn
        expenseCategoryId
        subAccounts {
          accountCode
          title
        }
      }
      expenseAttachments {
        type
        fileName
        fileUrl
        fileType
        expenseAttachmentId
        createdAt
        fileSize
      }
      expenseCardTransactions {
        cardTransaction {
          status
          transactionDate
          companyId
          cardId
          card {
            cardId
            maskedCardNumber
            suffixCardNumber
            prefixCardNumber
          }
          totalReconciledExpenses
          amount
          cardTransactionId
          location
          unreconciledAmount
        }
      }
      paymentInfo {
        accountHolderName
        accountNumber
        bankCode
        bankId
        bankName
        branchName
        bankShortName
        description
        logoUrl
        type
      }
      companyAttachments {
        companyAttachmentId
        fileUrl
        fileName
        fileSize
        mimeType
        createdAt
        attachmentType
        customFields
      }
      subAccount {
        title
        accountCode
      }
    }
  }
`;

export const useExpExpenseDetailsQuery = (
  options?: LazyQueryHookOptions<MobileExpenseDetailsByPkQuery, MobileExpenseDetailsByPkQueryVariables>
) => {
  const [expenseQuery, { data, ...rest }] = useLazyQuery(EXPENSE_BY_PK, {
    ...options,
    fetchPolicy: "network-only",
  });
  return {
    ...rest,
    data,
    expenseQuery,
    expenseData: data?.expExpenseDetails,
  };
};
