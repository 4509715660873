// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { gql, MutationHookOptions, useMutation } from "@apollo/client";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ExpSubmitExpenseReportMutation, ExpSubmitExpenseReportMutationVariables } from "types";

const SUBMIT_EXPENSE_REPORT = gql`
  mutation MobileSubmitExpenseReport($expenseReportId: UUID!, $approverEmployeeId: UUID) {
    expSubmitExpenseReport(expenseReportId: $expenseReportId, approverEmployeeId: $approverEmployeeId) {
      message
      success
    }
  }
`;

export const useExpSubmitExpenseReportMutation = (
  options?: MutationHookOptions<ExpSubmitExpenseReportMutation, ExpSubmitExpenseReportMutationVariables>
) => {
  return useMutation(SUBMIT_EXPENSE_REPORT, options);
};
