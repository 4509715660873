import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useRoute } from "@react-navigation/native";

import {
  AlertNotification,
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetTextInputCustom,
  Button,
  ToastManager,
} from "components";
import { CancelReportWarningIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { goBack } from "navigation/RootNavigation";
import useExpenseRejectRequest from "hooks/request/useExpenseRejectRequest";
import i18n from "i18next";
import { EVENT } from "constants/Tracking";
import { analyticService } from "services/AnalyticsService";
import SCREEN_NAME from "navigation/ScreenName";
import { APPROVAL_STATUS, TRACKING_OBJECT_TYPE } from "constants/constants";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { ExpenseListGroupByCategory } from "screens/ExpenseRequest/ExpenseRequestDetailScreen/types";
import { useAuth } from "contexts/AuthContext";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { executeRouteFunction } from "utils/route";

interface RejectExpenseRequestModalProps {
  requestId: string;
  title?: string;
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
  expenseListGroupByCategory: ExpenseListGroupByCategory[];
  onRefreshData?: () => void;
}

const RejectExpenseRequestModal = forwardRef<Partial<BottomSheetModalCustomMethods>, RejectExpenseRequestModalProps>(
  ({ title, requestId, expenseRequest, expenseListGroupByCategory, onRefreshData }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetModalRef?.current?.present();
      },
    }));
    const route = useRoute();
    const { user } = useAuth();
    const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen/ExpenseRequestDetailScreen");
    const alertNotificationRef = useRef(null);
    const bottomSheetModalRef = useRef<BottomSheetModalCustomMethods>(null);
    const { rejectRequest } = useExpenseRejectRequest();

    const [reason, setReason] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);
    const handleReject = async () => {
      setDisabledButton(true);
      Keyboard.dismiss();
      bottomSheetModalRef?.current?.close();
      alertNotificationRef?.current?.loading();
      try {
        const results = await rejectRequest({
          variables: {
            requestId: requestId,
            reason,
          },
        });
        if (!results?.data?.expenseRejectRequest?.success) {
          throw new Error();
        }
        const expenseApprovalRules = expenseRequest?.expenseApprovalRules ?? [];
        const approvalRulesWaitingIndex = expenseApprovalRules.findIndex(
          (item) =>
            item?.status === APPROVAL_STATUS.AWAITING &&
            item?.expenseApprovalRuleEmployees?.some((employee) => employee?.employeeId === user?.employee_id)
        );
        const isFinalApprovalRule = approvalRulesWaitingIndex === expenseApprovalRules.length - 1;
        const companyTeam = expenseRequest?.expenseRequestsTeams?.[0]?.companyTeam;
        analyticService.logEvent({
          sessionId: SCREEN_NAME.DetailRequestScreen,
          name: EVENT.REQUEST.REJECTED_SUCCESS,
          properties: {
            object_id: requestId,
            object_type: TRACKING_OBJECT_TYPE.REQUEST,
            is_final: isFinalApprovalRule,
            over_budgets: expenseListGroupByCategory
              ?.filter((item) => !item?.budget?.isValid)
              ?.map((item) => ({
                expense_category_id: item?.budget?.expenseCategoryId,
                company_team_id: companyTeam?.companyTeamId,
                over_amount: item?.budget?.overBudgetAmount,
                expense_date: expenseRequest?.budgetDate,
              })),
          },
        });
        alertNotificationRef?.current?.close();
        ToastManager.success(t("description_rejected_success"));
        executeRouteFunction(route, "onRefreshData");
        goBack();
        setDisabledButton(false);
      } catch (errors) {
        setDisabledButton(false);
        const errorTitle = t("reject_error");
        let errorMessage = i18n.t("common:internal_error");
        switch (errors?.message) {
          case SERVER_ERROR_CODE.NOT_OPEN_TO_REJECT: {
            errorMessage = t("not_open_to_reject");
            break;
          }
          case SERVER_ERROR_CODE.ITEM_CANCELLED: {
            errorMessage = t("request_is_cancelled");
            break;
          }
          case SERVER_ERROR_CODE.NO_PERMISSION: {
            errorMessage = t("no_permission_reject");
            break;
          }
        }
        analyticService.logEvent({
          sessionId: SCREEN_NAME.DetailRequestScreen,
          name: EVENT.REQUEST.REJECTED_FAILED,
          properties: {
            message: errorMessage,
          },
        });
        alertNotificationRef.current?.error({
          title: errorTitle,
          description: errorMessage,
        });
      }
    };

    return (
      <>
        <BottomSheetScrollViewModalCustom ref={bottomSheetModalRef} wrapperByScrollView={false}>
          <TouchableWithoutFeedback onPress={Platform.OS !== "web" && Keyboard.dismiss}>
            <View style={styles.container}>
              <CancelReportWarningIcon />
              <View style={styles.labelContainer}>
                <AppText style={Fonts.H400}>{t("reject_request")}</AppText>
                <AppText style={[Fonts.BodyMedium, styles.confirmText]} numberOfLines={2}>
                  {t("reject_request_description")} <AppText style={Fonts.H200}>{` ${title}?`}</AppText>
                </AppText>
              </View>
              <BottomSheetTextInputCustom
                autoFocus
                placeholder={t("reason_reject")}
                value={reason}
                onChangeText={setReason}
              />
            </View>
          </TouchableWithoutFeedback>

          <View style={styles.footerButton}>
            <Button type="white" style={styles.buttonClose} onPress={() => bottomSheetModalRef?.current?.close()}>
              {t("close")}
            </Button>
            <Button type="danger" disabled={disabledButton} style={styles.buttonCancel} onPress={handleReject}>
              {t("reject")}
            </Button>
          </View>
        </BottomSheetScrollViewModalCustom>
        <AlertNotification ref={alertNotificationRef} />
      </>
    );
  }
);
export default RejectExpenseRequestModal;

const styles = StyleSheet.create({
  container: { paddingHorizontal: 20, paddingTop: 40, justifyContent: "center", alignItems: "center" },
  labelContainer: { alignItems: "center", marginTop: 20 },
  confirmText: { marginTop: 8, textAlign: "center" },
  textInput: {
    color: Colors.grayscale100,
    borderWidth: 1,
    padding: 10,
    borderColor: Colors.primary50,
    width: "100%",
    height: 120,
    borderRadius: 4,
    marginTop: 20,
  },
  footerButton: { flexDirection: "row", paddingHorizontal: 20, marginTop: 20, paddingBottom: 10 },
  buttonClose: { flex: 1, marginRight: 8 },
  buttonCancel: { flex: 1, marginLeft: 8 },
});
