import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12 12.75c-.913 0-1.683-.313-2.31-.94s-.94-1.396-.94-2.31c0-.912.313-1.682.94-2.309.627-.627 1.397-.94 2.31-.94s1.683.313 2.31.94.94 1.397.94 2.31-.313 1.682-.94 2.31c-.627.626-1.397.94-2.31.94zm0-1.5c.497 0 .913-.167 1.248-.501.335-.335.502-.75.502-1.248s-.167-.914-.502-1.248c-.335-.335-.75-.502-1.248-.502s-.914.167-1.248.502c-.335.334-.502.75-.502 1.248 0 .497.167.913.502 1.248.334.334.75.502 1.248.502zm0-7.115l-6 2.24v4.726c0 .95.136 1.87.409 2.76.272.888.65 1.717 1.135 2.486a10.55 10.55 0 012.118-.806 9.18 9.18 0 012.338-.29c.82 0 1.6.097 2.338.29.737.194 1.443.462 2.118.806.484-.77.863-1.598 1.135-2.487A9.359 9.359 0 0018 11.1V6.377l-6-2.24zm0 12.616c-.645 0-1.265.07-1.86.21a8.325 8.325 0 00-1.688.588 8.889 8.889 0 001.637 1.43A7.31 7.31 0 0012 19.9a7.233 7.233 0 001.906-.922 8.968 8.968 0 001.633-1.43 8.35 8.35 0 00-1.683-.589A8.06 8.06 0 0012 16.75zm0 4.62a1.864 1.864 0 01-.596-.1c-2.11-.75-3.787-2.079-5.034-3.989-1.247-1.91-1.87-3.97-1.87-6.181V6.597c0-.378.11-.72.329-1.024a1.88 1.88 0 01.846-.663l5.692-2.125c.214-.077.425-.115.633-.115.208 0 .419.038.633.115l5.692 2.125c.345.137.627.358.846.663.22.304.329.646.329 1.024V11.1c0 2.211-.623 4.272-1.87 6.181-1.247 1.91-2.925 3.24-5.034 3.99a1.7 1.7 0 01-.596.1z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
