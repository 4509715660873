import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 135, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 135 135" fill="none" {...props}>
      <Path fill="#fff" d="M0 0h135v135H0z" />
      <Path
        d="M113.906 128.408H21.094C9.463 128.408 0 118.946 0 107.314c0-11.63 9.463-21.093 21.094-21.093h92.812c11.631 0 21.094 9.462 21.094 21.093 0 11.632-9.463 21.094-21.094 21.094z"
        fill="#E6F4FF"
      />
      <Path
        d="M54.316 113.906a6.592 6.592 0 100-13.183 6.592 6.592 0 000 13.183zM27.95 113.906a6.592 6.592 0 100-13.183 6.592 6.592 0 000 13.183z"
        fill="#7F8A93"
      />
      <Path
        d="M113.906 86.22H67.5v42.188h46.406c11.631 0 21.094-9.462 21.094-21.094 0-11.63-9.463-21.093-21.094-21.093z"
        fill="#B3DCFF"
      />
      <Path
        d="M80.684 113.906a6.592 6.592 0 100-13.183 6.592 6.592 0 000 13.183zM107.051 113.906a6.592 6.592 0 100-13.184 6.592 6.592 0 000 13.184z"
        fill="#4D5C6A"
      />
      <Path
        d="M67.49 72.552c18.208 0 32.969-14.766 32.969-32.98 0-18.215-14.76-32.98-32.97-32.98-18.208 0-32.969 14.765-32.969 32.98 0 18.214 14.761 32.98 32.97 32.98z"
        fill="#33A1FF"
      />
      <Path
        d="M67.49 6.592h-.045v65.959h.045c18.208 0 32.969-14.765 32.969-32.98 0-18.214-14.76-32.98-32.969-32.98z"
        fill="#006DCC"
      />
      <Path
        d="M48.85 40.227l13.335 13.335a1.49 1.49 0 002.107 0l21.892-21.893a1.49 1.49 0 000-2.106l-3.985-3.985a1.49 1.49 0 00-2.106 0L64.17 41.501a1.49 1.49 0 01-2.107 0l-7.135-7.136a1.49 1.49 0 00-2.077-.029l-3.97 3.755a1.49 1.49 0 00-.03 2.136z"
        fill="#E6F4FF"
      />
      <Path
        d="M82.2 25.578a1.49 1.49 0 00-2.107 0L67.445 38.226v12.182l18.739-18.739a1.49 1.49 0 000-2.106l-3.985-3.985z"
        fill="#B3DCFF"
      />
    </Svg>
  );
}

export default SvgComponent;
