import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";
import { FC, memo } from "react";
import { Colors, Fonts } from "theme";
import AppText from "components/AppText";

interface ToggleInputViewProps {
  data?: { title: string; value: string | number }[];
  value?: string | number;
  style?: StyleProp<ViewStyle>;
}

const ToggleInputView: FC<ToggleInputViewProps> = ({ data, value, style }) => {
  return (
    <View style={[styles.tabs, style]}>
      {data?.map((item) => {
        const isSelected = item?.value === value;
        return (
          <View
            key={item.value}
            //onPress={() => setSelectedTab("left")}
            style={[styles.wrapperItem, { backgroundColor: isSelected ? Colors.while : "transparent" }]}
          >
            <AppText style={Fonts.H200} color={Colors.grayscale80}>
              {item?.title}
            </AppText>
          </View>
        );
      })}
    </View>
  );
};
export default memo(ToggleInputView);

const styles = StyleSheet.create({
  tabs: {
    height: 40,
    padding: 4,
    borderRadius: 60,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.grayscale05,
  },
  wrapperItem: {
    width: "50%",
    height: "100%",
    borderRadius: 60,
    alignItems: "center",
    justifyContent: "center",
  },
});
