import { FlightBookingContextData } from "../types";

const Key = "bookingContext";

const getStorage = () => {
  try {
    const jsonData = sessionStorage.getItem(Key);
    const storage = jsonData ? JSON.parse(jsonData) : {};
    return storage;
  } catch (error) {
    console.error(error);
  }

  return {};
};

const setStorage = (data: FlightBookingContextData) => {
  sessionStorage.setItem(Key, JSON.stringify(data));
};

export default { getStorage, setStorage };
