import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.942 12.808l-1.207-1.5a.442.442 0 00-.357-.171.433.433 0 00-.357.18l-1.377 1.806c-.12.154-.14.313-.058.477a.424.424 0 00.41.246h7.508a.424.424 0 00.41-.246.427.427 0 00-.049-.477l-2.184-2.886a.438.438 0 00-.362-.181.438.438 0 00-.361.18l-2.016 2.572zM8.058 17.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h11.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H8.058zm0-1.5h11.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V4.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H8.058a.294.294 0 00-.212.096.294.294 0 00-.096.212v11.384c0 .077.032.148.096.212a.294.294 0 00.212.096zm-3.5 5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V7.058c0-.213.072-.391.215-.535a.726.726 0 01.535-.215c.213 0 .391.071.535.215a.726.726 0 01.215.535v12.134c0 .077.032.148.096.212a.294.294 0 00.212.096h12.134c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.535.726.726 0 01-.535.215H4.558z"
          fill="#1C1B1F"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
