import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";

const SVGComponent = () => (
  <Svg width={40} height={31} viewBox="0 0 40 31" fill="none">
    <Path
      d="M30.7815 17.729C31.0621 16.2051 31.0621 14.6431 30.7815 13.1191C30.7237 12.933 30.5993 12.7745 30.4319 12.6736C30.2644 12.5727 30.0656 12.5365 29.8731 12.5718C29.6806 12.6071 29.5078 12.7115 29.3875 12.8651C29.2672 13.0188 29.2077 13.211 29.2204 13.4054C29.4629 14.7404 29.4629 16.1078 29.2204 17.4427C29.2077 17.6371 29.2672 17.8294 29.3875 17.983C29.5078 18.1367 29.6806 18.2411 29.8731 18.2764C30.0656 18.3117 30.2644 18.2755 30.4319 18.1746C30.5993 18.0737 30.7237 17.9152 30.7815 17.729Z"
      fill="white"
    />
    <Path
      d="M33.4551 19.0791C34.0304 16.6864 34.0304 14.1924 33.4551 11.7997C33.4429 11.6874 33.4068 11.5791 33.3493 11.4818C33.2917 11.3845 33.214 11.3005 33.1214 11.2354C33.0287 11.1703 32.9231 11.1256 32.8117 11.1042C32.7003 11.0828 32.5856 11.0853 32.4752 11.1115C32.3649 11.1377 32.2614 11.1869 32.1716 11.256C32.0819 11.3251 32.0079 11.4124 31.9547 11.5121C31.9015 11.6118 31.8702 11.7216 31.863 11.8343C31.8557 11.9469 31.8726 12.0599 31.9126 12.1655C32.4268 14.3172 32.4268 16.5589 31.9126 18.7106C31.8729 18.8161 31.8561 18.9288 31.8634 19.0413C31.8707 19.1537 31.902 19.2634 31.9551 19.3629C32.0082 19.4624 32.082 19.5496 32.1715 19.6186C32.261 19.6876 32.3643 19.7369 32.4744 19.7632C32.5845 19.7895 32.699 19.7922 32.8102 19.7712C32.9215 19.7501 33.0269 19.7058 33.1197 19.6411C33.2124 19.5764 33.2903 19.4929 33.3481 19.396C33.4059 19.2991 33.4424 19.1911 33.4551 19.0791Z"
      fill="white"
    />
    <Path
      d="M36.0967 20.3799C36.9988 17.1415 36.9988 13.7198 36.0967 10.4814C36.0793 10.3704 36.0385 10.2644 35.977 10.1703C35.9155 10.0762 35.8346 9.99605 35.7397 9.93526C35.6449 9.87446 35.5381 9.83434 35.4266 9.81754C35.315 9.80075 35.2011 9.80765 35.0924 9.8378C34.9837 9.86795 34.8827 9.92066 34.7959 9.99247C34.7092 10.0643 34.6387 10.1536 34.5891 10.2544C34.5395 10.3553 34.512 10.4654 34.5082 10.5777C34.5045 10.6899 34.5247 10.8016 34.5675 10.9055C35.388 13.867 35.388 16.9942 34.5675 19.9558C34.5374 20.1482 34.5793 20.3451 34.6853 20.5088C34.7913 20.6726 34.954 20.792 35.1426 20.8443C35.3312 20.8966 35.5325 20.8782 35.7084 20.7926C35.8842 20.707 36.0224 20.5602 36.0967 20.3799Z"
      fill="white"
    />
    <Path
      d="M37.7395 8.67644C37.5379 8.73772 37.3689 8.87572 37.2691 9.0604C37.1693 9.24508 37.1469 9.46151 37.2067 9.66258C38.3568 13.4302 38.3568 17.4529 37.2067 21.2206C37.1703 21.4128 37.2064 21.6115 37.308 21.7789C37.4096 21.9463 37.5696 22.0706 37.7575 22.128C37.9454 22.1854 38.1479 22.1719 38.3264 22.0901C38.5049 22.0083 38.6469 21.8639 38.7251 21.6845C39.9754 17.6155 39.9754 13.2676 38.7251 9.19867C38.6619 9.00081 38.5233 8.83541 38.3392 8.73783C38.155 8.64025 37.9397 8.61822 37.7395 8.67644Z"
      fill="white"
    />
    <Path
      d="M19.9774 1.16797L3.38078 1.16797C1.9095 1.16797 0.716797 2.35482 0.716797 3.81888L0.716797 27.0568C0.716797 28.5209 1.9095 29.7077 3.38078 29.7077L19.9774 29.7077C21.4487 29.7077 22.6414 28.5209 22.6414 27.0568L22.6414 3.81888C22.6414 2.35482 21.4487 1.16797 19.9774 1.16797Z"
      fill="url(#paint0_linear_7246_88789)"
      stroke="url(#paint1_linear_7246_88789)"
      strokeWidth={1.18532}
      strokeMiterlimit={10}
    />
    <Path
      d="M8.29049 1.17187L8.29049 10.185C8.29049 10.3825 8.21162 10.572 8.07124 10.7117C7.93085 10.8514 7.74045 10.9299 7.54191 10.9299L0.716797 10.9299"
      stroke="#2A2A2A"
      strokeWidth={0.551582}
      strokeMiterlimit={10}
    />
    <Path
      d="M15.4219 1.17188L15.4219 10.3997C15.4219 10.5973 15.5007 10.7867 15.6411 10.9264C15.7815 11.0661 15.9719 11.1446 16.1705 11.1446L22.6546 11.1446"
      stroke="#2A2A2A"
      strokeWidth={0.551582}
      strokeMiterlimit={10}
    />
    <Path
      d="M0.716797 19.8945L7.54191 19.8945C7.74045 19.8945 7.93085 19.973 8.07124 20.1127C8.21162 20.2524 8.29049 20.4419 8.29049 20.6394L8.29049 29.7082"
      stroke="#2A2A2A"
      strokeWidth={0.551582}
      strokeMiterlimit={10}
    />
    <Path
      d="M15.4219 29.7159L15.4219 20.6551C15.4219 20.4575 15.5007 20.268 15.6411 20.1283C15.7815 19.9886 15.9719 19.9102 16.1705 19.9102L22.6546 19.9102"
      stroke="#2A2A2A"
      strokeWidth={0.551582}
      strokeMiterlimit={10}
    />
    <Path d="M8.29492 10.1973L15.4131 10.1973" stroke="#2A2A2A" strokeWidth={0.551582} strokeMiterlimit={10} />
    <Path d="M15.4131 21.1152L8.29492 21.1152" stroke="#2A2A2A" strokeWidth={0.551582} strokeMiterlimit={10} />
    <Defs>
      <LinearGradient
        id="paint0_linear_7246_88789"
        x1={2.6029}
        y1={2.20713}
        x2={21.2053}
        y2={29.5914}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#444444" />
        <Stop offset={0.17} stopColor="white" />
        <Stop offset={0.2} stopColor="#E6E6E6" />
        <Stop offset={0.26} stopColor="#BCBCBC" />
        <Stop offset={0.31} stopColor="#9B9B9B" />
        <Stop offset={0.37} stopColor="#838383" />
        <Stop offset={0.42} stopColor="#757575" />
        <Stop offset={0.46} stopColor="#707070" />
        <Stop offset={0.81} stopColor="white" />
        <Stop offset={1} stopColor="#828282" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_7246_88789"
        x1={0.450399}
        y1={15.4378}
        x2={22.9078}
        y2={15.4378}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#828282" />
        <Stop offset={0.19} stopColor="white" />
        <Stop offset={0.54} stopColor="#707070" />
        <Stop offset={0.58} stopColor="#757575" />
        <Stop offset={0.63} stopColor="#838383" />
        <Stop offset={0.69} stopColor="#9B9B9B" />
        <Stop offset={0.74} stopColor="#BCBCBC" />
        <Stop offset={0.8} stopColor="#E6E6E6" />
        <Stop offset={0.83} stopColor="white" />
        <Stop offset={1} stopColor="#444444" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default SVGComponent;
