export enum CLIENT_ERROR_CODE {
  CREATE_EXPENSE_REPORT_FAIL = "CREATE_EXPENSE_REPORT_FAIL",
  CREATE_EXPENSE_REQUEST_FAIL = "CREATE_EXPENSE_REQUEST_FAIL",
  CREATE_EXPENSE_FAIL = "CREATE_EXPENSE_FAIL",
}

export enum SERVER_ERROR_CODE {
  COMPANY_ENABLED_EXPENSE = "COMPANY_ENABLED_EXPENSE",
  NOT_SUPPORT_ENABLED_MULTI_COMPANY_EXPENSE = "NOT_SUPPORT_ENABLED_MULTI_COMPANY_EXPENSE",
  USER_EXISTS = "USER_EXISTS",
  EXPENSE_NOT_ACTIVATED = "EXPENSE_NOT_ACTIVATED",
  EXPENSE_EMPLOYEE_PHONE_EXISTS = "EMPLOYEE_PHONE_EXISTS",
  EXPENSE_EMPLOYEE_EMAIL_EXISTS = "EMPLOYEE_EMAIL_EXISTS",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  EXPENSE_REPORT_NOT_FOUND = "EXPENSE_REPORT_NOT_FOUND",
  EXPENSE_REQUEST_NOT_FOUND = "EXPENSE_REQUEST_NOT_FOUND",
  CANCEL_EXPENSE_REPORT_FAILED = "CANCEL_EXPENSE_REPORT_FAILED",
  CANCEL_EXPENSE_REQUEST_FAILED = "CANCEL_EXPENSE_REQUEST_FAILED",
  UNSUPPORTED_CANCEL_OBJECT_TYPE = "UNSUPPORTED_CANCEL_OBJECT_TYPE",
  CANNOT_CANCEL_EXPENSE_REQUEST = "CANNOT_CANCEL_EXPENSE_REQUEST",
  CANNOT_CANCEL_EXPENSE_REPORT = "CANNOT_CANCEL_EXPENSE_REPORT",

  EXPENSE_NO_APPROVAL_FLOW = "NO_APPROVAL_FLOW",
  EXPENSE_UNSUPPORTED_APPROVAL_METHOD = "UNSUPPORTED_APPROVAL_METHOD",
  EXPENSE_NOT_FOUND = "EXPENSE_NOT_FOUND",
  EMPLOYEE_PHONE_EXISTS = "EMPLOYEE_PHONE_EXISTS",
  INVALID_COMPANY_TEAM_ID = "INVALID_COMPANY_TEAM_ID",
  BAD_USER_INPUT = "BAD_USER_INPUT",
  EXPENSE_TRAVEL_POLICY_MISMATCH = "EXPENSE_TRAVEL_POLICY_MISMATCH",
  NOT_OPEN_TO_APPROVE = "NOT_OPEN_TO_APPROVE",
  NOT_OPEN_TO_REJECT = "NOT_OPEN_TO_REJECT",
  ITEM_CANCELLED = "ITEM_CANCELLED",
  NO_PERMISSION = "no_permission",
}

export enum SERVER_SUCCESS_CODE {
  EXPENSE_CANCEL_SUCCESS = "EXPENSE_CANCEL_SUCCESS",
}

export type CustomRecord<K extends keyof any, T> = {
  [P in K]?: T;
} & { UNKNOWN_ERROR: T };
