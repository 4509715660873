// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ActivityIndicator, Toast } from "@ant-design/react-native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Asyncstorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import BizziIcon from "assets/images/svg/icons/BizziIcon";
import InputFieldFlat from "components/Input/PaperInputFlat";
import Colors from "constants/Colors";
import LanguageStatus from "constants/LanguageStatus";
import Layout from "constants/Layout";
import Constants from "expo-constants";
import { Formik } from "formik";
import CustomDropdownPicker from "layouts/CustomDropdownPicker";
import useExpenseRequestOTP from "hooks/useExpenseRequestOTP";
import * as WebBrowser from "expo-web-browser";
import SCREEN_NAME from "navigation/ScreenName";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyboardAvoidingView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import trim from "lodash/trim";
import { Button, Menu } from "react-native-paper";
import * as Yup from "yup";
import ToastRN from "react-native-toast-message";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import { stylesCommon } from "./commonStyle";
import jwtDecode from "jwt-decode";
import { BackButtonIcon } from "assets/images/svg/icons";

const RegisterOnboardingScreen: React.FC = ({ route }) => {
  const { params } = route?.params ?? {};

  const navigation = useNavigation();
  const { t, i18n } = useTranslation("screens/RegisterOnboarding/RegisterOnboardingScreen");
  const { onRequestOTP } = useExpenseRequestOTP();
  const [visible, setVisible] = useState(false);
  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);
  const yupSchema = Yup.object().shape({
    email: Yup.string().email(t("please_input_correct_email")).required(t("please_input_email_company")),
    phone: Yup.string()
      .matches(/^\d+$/, t("phone_is_not_valid"))
      .min(10, t("phone_is_not_valid"))
      .max(12, t("phone_is_not_valid"))
      .required(t("required")),
    fullname: Yup.string().min(5, t("string_min")).required(t("required")),
  });

  const initialValues = useMemo(() => {
    return params?.token ? jwtDecode(params.token) : { email: "", phone: "", fullname: "", employee_title: "" };
  }, [params?.token]);

  useEffect(() => {
    const loadingLanguage = async () => {
      const locateStore = await Asyncstorage.getItem("locale");

      if (locateStore) {
        i18n.changeLanguage(locateStore);
      }
    };
    loadingLanguage();
  }, [i18n]);

  const dropdownSelect = [
    {
      id: 1,
      label: t("business_owner"),
      value: "business_owner",
    },
    {
      id: 2,
      label: t("accountant"),
      value: "accountant",
    },
    {
      id: 3,
      label: t("administration"),
      value: "administration",
    },
    {
      id: 4,
      label: t("accounting_agency"),
      value: "accounting_agency",
    },
    {
      id: 5,
      label: t("other"),
      value: "other",
    },
  ];

  const onSubmit = useCallback(
    async (values) => {
      try {
        const results = await onRequestOTP({
          email: trim(values.email),
          requiredExistence: false,
          type: "EXPENSE_REGISTER",
        });

        if (results?.data?.expenseRequestOTP?.challenge_id) {
          navigation.navigate(SCREEN_NAME.RegisterOTPScreen, {
            email: trim(values.email),
            challenge_id: results?.data?.expenseRequestOTP?.challenge_id,
            phone: trim(values?.phone),
            fullname: trim(values?.fullname),
            employee_title: values.employee_title,
          });
        } else {
          Toast.fail(`${t(`unknown`)}`);
        }
      } catch (errors) {
        if (errors?.message === "EXPENSE_EXPENSE_USER_EXISTS" || errors?.message === SERVER_ERROR_CODE.USER_EXISTS) {
          ToastRN.show({
            type: "error",
            text1: t("email_exists_in_system"),
            text2: t("please_login"),
            onPress: () => {
              navigation.navigate(SCREEN_NAME.LoginScreen);
            },
          });
          return;
        }

        if (errors?.message === SERVER_ERROR_CODE.EXPENSE_NOT_ACTIVATED) {
          ToastRN.show({
            type: "error",
            text1: t("email_exists_in_system"),
            text2: t("please_login"),
            onPress: () => {
              navigation.navigate(SCREEN_NAME.LoginScreen);
            },
          });
          return;
        }

        Toast.fail(`${t(`unknown`)}`);
      }
    },
    [onRequestOTP, navigation, t]
  );

  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <ScrollView contentContainerStyle={styles.pb80}>
        <View style={styles.flex1}>
          <View style={styles.flex1}>
            <View style={styles.wrapperMenu}>
              <Menu
                visible={visible}
                onDismiss={closeMenu}
                anchor={
                  <Button icon={() => <MaterialIcons name="language" size={24} color="black" />} onPress={openMenu} />
                }
              >
                <Menu.Item
                  style={{ backgroundColor: i18n.language === LanguageStatus.VN ? Colors.grey7 : "transparent" }}
                  onPress={async () => {
                    i18n.changeLanguage(LanguageStatus.VN);
                    await Asyncstorage.setItem("locale", LanguageStatus.VN);
                    closeMenu();
                  }}
                  title={t("vn")}
                />
                <Menu.Item
                  style={{ backgroundColor: i18n.language !== LanguageStatus.VN ? Colors.grey7 : "transparent" }}
                  onPress={async () => {
                    i18n.changeLanguage(LanguageStatus.EN);
                    await Asyncstorage.setItem("locale", LanguageStatus.EN);
                    closeMenu();
                  }}
                  title={t("en")}
                />
              </Menu>
            </View>
          </View>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnMount
            validationSchema={yupSchema}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setTouched,
                isSubmitting,
                setFieldValue,
              } = props;

              return (
                <View style={stylesCommon.container}>
                  <View style={stylesCommon.contentMobile}>
                    <View style={stylesCommon.logoContainer}>
                      <TouchableOpacity onPress={() => navigation.goBack()} style={stylesCommon.backButton}>
                        <BackButtonIcon />
                      </TouchableOpacity>
                      <BizziIcon />
                    </View>
                    <View style={stylesCommon.registerFormMobile}>
                      <Text style={stylesCommon.title}>{t("title")}</Text>
                      <Text style={stylesCommon.description}>{t("description")}</Text>

                      <InputFieldFlat
                        importantForAutofill="no"
                        autoComplete="off"
                        label={t("fullname")}
                        value={values.fullname}
                        autoCorrect={false}
                        placeholder={t("fullname")}
                        autoCapitalize="none"
                        touched={touched?.fullname}
                        error={errors?.fullname}
                        onChangeText={handleChange("fullname")}
                        onBlur={handleBlur("fullname")}
                        onFocus={() => setTouched({ ...touched, fullname: false })}
                      />

                      <InputFieldFlat
                        importantForAutofill="no"
                        autoComplete="off"
                        label={t("phone")}
                        value={values.phone}
                        keyboardType="numeric"
                        autoCorrect={false}
                        placeholder={t("phone")}
                        autoCapitalize="none"
                        touched={touched?.phone}
                        error={errors?.phone}
                        onChangeText={handleChange("phone")}
                        onBlur={handleBlur("phone")}
                        onFocus={() => setTouched({ ...touched, phone: false })}
                      />

                      <InputFieldFlat
                        importantForAutofill="no"
                        autoComplete="off"
                        label={t("email_company")}
                        value={values.email}
                        keyboardType="email-address"
                        textContentType="emailAddress"
                        autoCorrect={false}
                        placeholder={t("email_company")}
                        autoCapitalize="none"
                        touched={touched.email}
                        error={errors.email}
                        onChangeText={handleChange("email")}
                        onBlur={handleBlur("email")}
                        onSubmitEditing={handleSubmit}
                        onFocus={() => setTouched({ ...touched, email: false })}
                      />

                      <View style={styles.mt25}>
                        <CustomDropdownPicker
                          placeholder={t("employee_title")}
                          value={values.employee_title}
                          onSelectItem={(item) => {
                            setFieldValue("employee_title", item);
                          }}
                          items={dropdownSelect}
                        />
                      </View>

                      <Button
                        uppercase={false}
                        disabled={isSubmitting}
                        onPress={handleSubmit}
                        style={styles.mt25}
                        mode="contained"
                        contentStyle={{ paddingVertical: 3 }}
                        labelStyle={{ fontSize: 16, fontWeight: "600" }}
                      >
                        {t("register")}
                      </Button>
                      {isSubmitting && <ActivityIndicator animating toast size="large" />}

                      <Text style={styles.agreePolicyAndTears}>
                        {t("by_clicking_register_you_agree_to")}{" "}
                        <Text
                          style={{ color: Colors.blue22 }}
                          onPress={() => {
                            WebBrowser.openBrowserAsync(
                              i18n.language === LanguageStatus.EN
                                ? "https://bizzi.vn/terms-and-conditions"
                                : "https://bizzi.vn/dieu-khoan-su-dung"
                            );
                          }}
                        >
                          {t("policy")}
                        </Text>{" "}
                        {t("and")}{" "}
                        <Text
                          style={{ color: Colors.blue22 }}
                          onPress={() => {
                            WebBrowser.openBrowserAsync(
                              i18n.language === LanguageStatus.EN
                                ? "https://bizzi.vn/terms-and-conditions"
                                : "https://bizzi.vn/dieu-khoan-su-dung"
                            );
                          }}
                        >
                          {t("terms")}
                        </Text>{" "}
                        {t("our")}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            }}
          </Formik>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  agreePolicyAndTears: {
    marginTop: 32,
    fontSize: 14,
    lineHeight: 20,
    color: Colors.gray80,
  },
  mt25: {
    marginTop: 25,
  },
  pb80: { paddingBottom: 80 },
  wrapperMenu: { position: "absolute", right: 0, top: 30, zIndex: 10 },
  flex1: {
    flex: 1,
    position: "relative",
  },
});

export default RegisterOnboardingScreen;
