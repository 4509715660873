import { createContext, useContext, useState } from "react";

interface IReportManageContext {
  setApprovedExpenseIds: (ids: string[]) => void;
  approvedExpenseIds?: string[];
}

const ReportManageContext = createContext<IReportManageContext>({} as IReportManageContext);

ReportManageContext.displayName = "ReportManageContext";

function ReportManageProvider({ children }) {
  const [approvedExpenseIds, setApprovedExpenseIds] = useState<string[]>([]);

  return (
    <ReportManageContext.Provider
      value={{
        approvedExpenseIds,
        setApprovedExpenseIds,
      }}
    >
      {children}
    </ReportManageContext.Provider>
  );
}

function useReportManage(): IReportManageContext {
  const context = useContext(ReportManageContext);
  if (context === undefined) {
    throw new Error(`useReportManage must be used within a ReportManageProvider`);
  }
  return context;
}

export { useReportManage, ReportManageProvider, ReportManageContext };
