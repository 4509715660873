import { createContext, useContext, useMemo } from "react";
import useGetUploadConfiguration from "hooks/useUploadConfigurationQuery";
import { useExpenseRequestTypes } from "hooks/useExpExpenseRequestTypes";
import { EXPENSE_REPORT_PAYMENT_METHOD, REQUEST_TYPE, SETTING_TYPES } from "constants/constants";
import useGetCompanyConfigurations from "hooks/useGetCompanyConfigurations";
import useExpCardEnabledQuery from "hooks/useExpCardEnabledQuery";
import useExpEmployeeExpenseCategories from "hooks/useExpEmployeeExpenseCategories";
import { MobileExpEmployeeExpenseCategoriesQuery } from "types";
import { useExpApprovalTypeQuery } from "hooks/useExpApprovalTypeQuery";
import { EXPENSE_APPROVAL_TYPE } from "constants/ExpenseApproval";

interface IMasterDataContext {
  uploadConfiguration: {
    maxFileSize: number;
    maxFiles: number;
    maxTotalSize: number;
    supportedFileExtensions: string[];
  };
  requestTypes: REQUEST_TYPE[];
  categories?: MobileExpEmployeeExpenseCategoriesQuery["expEmployeeExpenseCategories"]["expenseCategories"][0]["expenseCategory"][];
  setting: {
    requiredLocation?: boolean;
    requiredPaymentInfo?: boolean;
    isCardEnabled?: boolean;
    isBatchApproveEnabled?: boolean;
    allowPastTimeOnExpenseRequestCreation?: boolean;
    allowEditAmountExpenseWithInvoice?: boolean;
    supportedCurrencies: string[];
    isSupportCurrency?: boolean;
    request: {
      flightBooking?: {
        defaultVendor: string;
        requireVendor: boolean;
        withVendor: boolean;
      };
      general?: {
        requireDescription: boolean;
      };
      hotelBooking?: {
        requireHotelInfo: boolean;
        withHotelInfo: boolean;
      };
      travel?: {
        requirePassengerInfo: boolean;
        withPassengerInfo: boolean;
      };
      hotels?: {
        code: string;
        name: string;
        roomRate: number;
        taxCode: string;
        location: { code: string; name: string; nameEn: string; parentCode: string; countryCode: string };
      }[];
    };
  };
  approvalTypeRequest?: ApprovalType;
  approvalTypeReport?: ApprovalType;
  withApprovalReason?: boolean;
  paymentMethods: EXPENSE_REPORT_PAYMENT_METHOD[];
}

const MasterDataContext = createContext<IMasterDataContext>({} as IMasterDataContext);

MasterDataContext.displayName = "MasterDataContext";

function MasterDataProvider({ children }) {
  const { data } = useGetUploadConfiguration();
  const { data: expenseRequestType } = useExpenseRequestTypes();
  const { data: companySetting } = useGetCompanyConfigurations();
  const { data: cardEnable } = useExpCardEnabledQuery();
  const { data: dataCategories } = useExpEmployeeExpenseCategories();
  const { data: approvalTypeReportData } = useExpApprovalTypeQuery({
    variables: { type: EXPENSE_APPROVAL_TYPE.REPORT },
  });
  const { data: approvalTypeRequestData } = useExpApprovalTypeQuery({
    variables: { type: EXPENSE_APPROVAL_TYPE.REQUEST },
  });

  const uploadConfiguration = useMemo(() => {
    return {
      maxFileSize: data?.expUploadConfiguration?.maxFileSize ?? 0,
      maxFiles: data?.expUploadConfiguration?.maxFiles ?? 0,
      maxTotalSize: data?.expUploadConfiguration?.maxTotalSize ?? 0,
      supportedFileExtensions: data?.expUploadConfiguration?.supportedFileExtensions?.map((item) =>
        item.replace(".", "")
      ),
    };
  }, [data]);

  const setting: IMasterDataContext["setting"] = useMemo(() => {
    const requestSetting = companySetting[SETTING_TYPES.EXPENSE_REQUEST_FORM];
    return {
      requiredLocation: companySetting[SETTING_TYPES.EXPENSE_FORM]?.requiredLocation ?? false,
      requiredPaymentInfo: companySetting[SETTING_TYPES.EXPENSE_FORM]?.requiredPaymentInfo ?? false,
      allowPastTimeOnExpenseRequestCreation:
        companySetting[SETTING_TYPES.EXPENSE_FORM]?.allowPastTimeOnExpenseRequestCreation ?? false,
      allowEditAmountExpenseWithInvoice:
        companySetting[SETTING_TYPES.EXPENSE_FORM]?.allowEditAmountExpenseWithInvoice ?? true,
      isCardEnabled: cardEnable?.expCardEnabled?.enabled ?? false,
      isBatchApproveEnabled: companySetting[SETTING_TYPES.BATCH_APPROVAL]?.batchApproval ?? false,
      supportedCurrencies: companySetting[SETTING_TYPES.FOREIGN_CURRENCY]?.supported_currencies ?? [],
      isSupportCurrency: companySetting[SETTING_TYPES.FOREIGN_CURRENCY]?.supported_currencies?.length > 0,
      request: (requestSetting
        ? {
            flightBooking: requestSetting.flightBooking,
            general: requestSetting.general,
            hotelBooking: requestSetting.hotelBooking,
            travel: requestSetting.travel,
            hotels: companySetting[SETTING_TYPES.COMPANY_HOTELS]
              ? Object.values(companySetting[SETTING_TYPES.COMPANY_HOTELS]).map((hotel: Record<string, any>) => ({
                  ...hotel,
                  roomRate: Number(hotel.roomRate),
                }))
              : [],
          }
        : {}) as IMasterDataContext["setting"]["request"],
      paymentMethods: companySetting[SETTING_TYPES.EXPENSE_REPORT_FORM]?.payment_methods ?? [
        EXPENSE_REPORT_PAYMENT_METHOD.CASH,
        EXPENSE_REPORT_PAYMENT_METHOD.BANK_TRANSFERS,
        EXPENSE_REPORT_PAYMENT_METHOD.BANK_CARD,
      ],
    };
  }, [companySetting]);

  const expenseRequestTypes = useMemo(() => {
    return expenseRequestType?.expExpenseRequestTypes?.expenseRequestTypes ?? [];
  }, [expenseRequestType]);

  return (
    <MasterDataContext.Provider
      value={{
        uploadConfiguration,
        requestTypes: expenseRequestTypes as REQUEST_TYPE[],
        setting,
        categories: dataCategories,
        approvalTypeRequest: (approvalTypeRequestData?.expenseApprovalType?.approvalType ?? "AUTO") as ApprovalType,
        approvalTypeReport: (approvalTypeReportData?.expenseApprovalType?.approvalType ?? "AUTO") as ApprovalType,
        withApprovalReason: approvalTypeReportData?.expenseApprovalType?.withApprovalReason ?? false,
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
}

function useMasterData(): IMasterDataContext {
  const context = useContext(MasterDataContext);
  if (context === undefined) {
    throw new Error(`useMasterData must be used within a MasterDataProvider`);
  }
  return context;
}

export { MasterDataProvider, useMasterData };
