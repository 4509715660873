import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileBokBaggageQuery, MobileBokBaggageQueryVariables } from "types";

const QUERY = gql`
  query MobileBokBaggage($input: BokBaggageInput!) {
    bokBaggage(input: $input) {
      baggage {
        airline
        code
        value
        name
        price
        route
        startPoint
        endPoint
        weight
        weightUnit
      }
    }
  }
`;

const useBokBaggage = (options: {
  variables?: MobileBokBaggageQueryVariables;
  onCompleted: (data: MobileBokBaggageQuery) => void;
}) => {
  return useQuery<MobileBokBaggageQuery, MobileBokBaggageQueryVariables>(QUERY, options);
};

export default useBokBaggage;
