import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.23 20.5a1.74 1.74 0 01-1.276-.531 1.74 1.74 0 01-.531-1.277V8c0-.497.177-.923.531-1.277a1.74 1.74 0 011.277-.53h2.077V4.307c0-.497.177-.923.53-1.277a1.74 1.74 0 011.278-.531h1.768c.498 0 .923.177 1.277.531.354.354.531.78.531 1.277v1.884h2.077c.497 0 .923.177 1.277.531.354.354.531.78.531 1.277v10.692c0 .497-.177.923-.531 1.277a1.74 1.74 0 01-1.277.531v.115a.853.853 0 01-.258.626.852.852 0 01-.627.259.852.852 0 01-.625-.259.852.852 0 01-.259-.626V20.5H9v.115a.853.853 0 01-.259.626.852.852 0 01-.625.259.852.852 0 01-.627-.259.853.853 0 01-.258-.626V20.5zm0-1.5h9.54a.3.3 0 00.22-.087.3.3 0 00.087-.22V8a.3.3 0 00-.087-.221.3.3 0 00-.22-.087H7.23a.3.3 0 00-.22.087.3.3 0 00-.087.221v10.692a.3.3 0 00.087.221.3.3 0 00.22.087zm2.827-9.923a.726.726 0 00-.534.216.727.727 0 00-.215.534v7.038c0 .213.072.39.216.535a.726.726 0 00.534.215c.213 0 .39-.072.534-.215a.726.726 0 00.216-.535V9.827a.726.726 0 00-.216-.534.727.727 0 00-.535-.216zm3.885 0a.725.725 0 00-.534.216.726.726 0 00-.216.534v7.038c0 .213.072.39.216.535a.727.727 0 00.535.215c.212 0 .39-.072.534-.215a.727.727 0 00.215-.535V9.827a.726.726 0 00-.216-.534.726.726 0 00-.534-.216zm-3.134-2.885h2.384V4.308a.3.3 0 00-.086-.221.3.3 0 00-.222-.087h-1.768a.3.3 0 00-.222.087.3.3 0 00-.086.22v1.885z"
          fill="#1C1B1F"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
