import React, { useEffect, useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  ActivityIndicator,
  Keyboard,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";

import {
  AppText,
  EmptyData,
  FlatListCustom,
  IconCustom,
  RadioSelectFilter,
  SearchInput,
  SelectDate,
  SkeletonListLoading,
} from "components";
import { BackButtonIcon, EmptyResultFound, SearchNotFoundIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { INVOICE_EXPENSE_STATUS, InvoiceFilterData } from "./types";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import { goBack } from "navigation/RootNavigation";
import { NetworkStatus } from "@apollo/client";
import { useReceivedInvoicesQuery } from "hooks/useReceivedInvoicesQuery";
import InvoiceItem from "screens/Inbox/InboxScreen/components/InvoiceItem";

const getStatusList = (t): SelectItem[] => [
  { label: t("created_expense"), value: INVOICE_EXPENSE_STATUS.CREATED },
  {
    label: t("no_create_expense"),
    value: INVOICE_EXPENSE_STATUS.NOT_CREATED,
  },
];

const ReportSearchScreen = () => {
  const { t } = useTranslation("app/screens/Inbox/InvoiceSearchScreen");
  const [keyword, setKeyword] = useState("");
  const navigation = useNavigation<any>();
  const { top } = useSafeAreaInsets();
  const searchInputRef = useRef<TextInput>();
  const flashListRef = useRef<FlashList<any>>(null);
  const disableLoadMore = useRef(true);

  const [filter, setFilter] = useState<InvoiceFilterData>({});
  const debounceSearchTimeoutId = useRef(null);
  const {
    user: { id: userId },
    company: { company_id: companyId },
  } = useAuth();

  const [_, { data, refetch, loading, fetchMore, networkStatus }] = useReceivedInvoicesQuery({
    variables: {
      companyId,
      userId,
      limit: 10,
      offset: 0,
      invoiceFilters: {
        createdExpenseStatus: filter?.createdExpenseStatus?.value ?? INVOICE_EXPENSE_STATUS.ALL,
        invoiceNumber: keyword ? keyword.trim() : undefined,
        fromDate: filter?.invoiceDate?.fromDate ?? undefined,
        toDate: filter?.invoiceDate?.toDate ?? undefined,
      } as ExpInvoicesFilters,
    },
  });
  const invoiceList = data?.expReceivedInvoices?.invoices ?? [];
  const total = data?.expReceivedInvoices?.total ?? 0;

  const isFiltering =
    keyword.trim() ||
    [filter?.createdExpenseStatus?.value, filter?.invoiceDate?.fromDate && filter?.invoiceDate?.toDate].some(Boolean);

  useEffect(() => {
    if (isFiltering) {
      disableLoadMore.current = true;
      refetch?.();
      setTimeout(() => {
        disableLoadMore.current = false;
      }, 1000);
    }
  }, [isFiltering]);

  const onChangeFilter = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      setKeyword(text);
    }, 400);
  };
  const onResetSearch = () => {
    setKeyword("");
    searchInputRef?.current?.setNativeProps({ text: "" });
  };
  const onClearFilter = () => {
    setKeyword("");
    setFilter(null);
  };
  const renderEmpty = () => {
    return <EmptyData icon={<SearchNotFoundIcon />} title={t("invoice_search_not_found")} />;
  };
  const handleClickItem = (item) => () => {
    navigation.navigate(SCREEN_NAME.InvoiceDetailScreen, {
      invoiceId: item?.invoiceId,
      autofill: true,
      onRefreshData: refetch,
    });
  };
  const renderItem = ({ item }) => {
    return <InvoiceItem onRefreshData={refetch} item={item} onPress={handleClickItem(item)} />;
  };

  const renderFooter = () => {
    if (loading) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const handleLoadMore = () => {
    if (loading || disableLoadMore.current || invoiceList?.length === 0 || invoiceList?.length >= total) {
      return;
    }

    disableLoadMore.current = true;

    fetchMore?.({
      variables: { offset: invoiceList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        setTimeout(() => {
          disableLoadMore.current = false;
        }, 500);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expReceivedInvoices: {
            total: fetchMoreResult.expReceivedInvoices.total,
            invoices: [...prev.expReceivedInvoices.invoices, ...fetchMoreResult.expReceivedInvoices.invoices],
          },
        };
      },
    });
  };

  return (
    <View style={styles.container}>
      <View style={[styles.headerContainer, { paddingTop: top || 20 }]}>
        <View style={styles.searchContainer}>
          <TouchableOpacity style={{ marginTop: 6 }} onPress={goBack}>
            <BackButtonIcon />
          </TouchableOpacity>
          <SearchInput
            numberOfLines={1}
            ref={searchInputRef}
            style={styles.searchInput}
            placeholder={t("search")}
            onChangeText={onChangeSearch}
            autoCapitalize="none"
            placeholderTextColor={Colors.grayscale60}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            right={
              keyword ? (
                <TouchableOpacity onPress={onResetSearch}>
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : null
            }
          />
        </View>
        <ScrollView keyboardShouldPersistTaps="always" horizontal showsHorizontalScrollIndicator={false}>
          <View style={styles.searchItemContainer}>
            <RadioSelectFilter
              minWidth={130}
              title={t("expense_status")}
              data={getStatusList(t)}
              value={filter?.createdExpenseStatus}
              setValue={(value) => onChangeFilter("createdExpenseStatus", value)}
            />
            <SelectDate
              title={t("invoice_date")}
              labelDateValue={filter?.invoiceDate?.labelDateValue}
              setValue={(label, from, to) => {
                onChangeFilter("invoiceDate", { fromDate: from, toDate: to, labelDateValue: label });
              }}
            />
            <TouchableOpacity onPress={onClearFilter}>
              <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                {t("clear_filter")}
              </AppText>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
      {!isFiltering ? (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.emptyContainer}>
            <EmptyData
              icon={<EmptyResultFound />}
              title={t("search_invoice_title")}
              description={t("search_invoice_description")}
            />
          </View>
        </TouchableWithoutFeedback>
      ) : (loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={styles.loading} />
      ) : (
        <FlatListCustom
          ref={flashListRef}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.contentContainer}
          data={invoiceList}
          renderItem={renderItem}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
          ListEmptyComponent={renderEmpty}
          keyExtractor={(item, index) => item?.expenseReportId ?? index}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.2}
          ListFooterComponent={renderFooter}
          estimatedItemSize={88}
        />
      )}
    </View>
  );
};
export default ReportSearchScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingTop: 10, paddingBottom: 20 },
  headerContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 4,
  },
  searchContainer: {
    paddingRight: 20,
    paddingLeft: 18,
    justifyContent: "space-between",
    gap: 8,
    alignItems: "center",
    flexDirection: "row",
  },
  searchInput: { ...Fonts.BodySmall, flex: 1, color: Colors.grayscale100, backgroundColor: Colors.white },
  searchItemContainer: {
    flexDirection: "row",
    gap: 8,
    marginTop: 16,
    marginHorizontal: 20,
    alignItems: "center",
    paddingBottom: 16,
  },
  loadingMore: { marginTop: 10 },
  emptyContainer: { flex: 1 },
  loading: { marginTop: 5 },
});
