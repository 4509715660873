// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import QRLogo from "assets/images/QRLogo.png";
import { CloseIcon } from "assets/images/svg/icons";
import Colors from "constants/Colors";
import { BizziQRScreenRouteProp } from "navigation/type";
import { FC } from "react";
import { StyleSheet, TouchableWithoutFeedback, View, useWindowDimensions } from "react-native";
import QRCode from "react-native-qrcode-svg";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface BizziQRScreenProps {
  route: BizziQRScreenRouteProp;
  navigation: BizziQRScreenRouteProp;
}

const BizziQRScreen: FC<BizziQRScreenProps> = ({ navigation, route }) => {
  const { width: wWidth, height: wHeight } = useWindowDimensions();
  const { bottom } = useSafeAreaInsets();

  const code = route?.params?.qrCode;

  const qrSize = Math.min(wWidth - 30, wHeight / 2);
  const logoSize = Math.min(48, qrSize * 0.2);

  return (
    <View style={styles.container}>
      <View style={styles.qrContainer}>
        <QRCode value={code} size={qrSize} logo={QRLogo} logoSize={logoSize} />
      </View>
      <TouchableWithoutFeedback>
        <View style={[styles.btnClose, { bottom: bottom + 30 }]}>
          <CloseIcon color={Colors.whiteText} />
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback onPress={() => navigation.goBack()}>
        <View style={[styles.backdrop, { width: wWidth, height: wHeight }]} />
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  btnClose: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: Colors.primary50,
  },
  qrContainer: {
    padding: 8,
    borderWidth: 1,
    borderColor: Colors.gray10,
    backgroundColor: Colors.whiteBackground,
    borderRadius: 8,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
  },
});
export default BizziQRScreen;
