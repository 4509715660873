import { AppText, Line } from "components";
import { ScrollView, StyleSheet, View, Platform, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { FC, ReactElement } from "react";

type CustomTableProps = {
  columns: {
    key: string;
    title: string | ReactElement;
    width?: number;
    textAlign?: "left" | "center" | "right";
    render?: (data: any, rowIndex?: number) => ReactElement;
  }[];
  dataSource: Array<{ [key: string]: any }>;
  isScrollTable?: boolean;
  bordered?: boolean;
  footerData?: (string | ReactElement)[];
};

const CustomTable: FC<CustomTableProps> = ({
  columns,
  dataSource,
  isScrollTable = false,
  bordered = false,
  footerData,
}) => {
  const renderHeaderTable = () => {
    return (
      <>
        {columns.map((c, index) => {
          return (
            <View
              key={index}
              style={[
                styles.headerRowItem,
                c.width && { width: c.width },
                bordered && { borderLeftWidth: 1 },
                bordered && index === columns.length - 1 && { borderRightWidth: 1 },
              ]}
            >
              {typeof c.title === "string" ? (
                <AppText style={[Fonts.NumericN200, c.textAlign && { textAlign: c.textAlign }]}>{c.title}</AppText>
              ) : (
                c.title
              )}
            </View>
          );
        })}
      </>
    );
  };

  const renderFooterTable = () => {
    return (
      <>
        {footerData.map((item, index) => {
          const col = columns[index];
          return (
            <View
              key={index}
              style={[
                styles.headerRowItem,
                col.width && { width: col.width },
                bordered && { borderLeftWidth: 1 },
                bordered && index === columns.length - 1 && { borderRightWidth: 1 },
              ]}
            >
              {typeof item === "string" ? (
                <AppText style={[Fonts.NumericN200, col.textAlign && { textAlign: col.textAlign }]}>{item}</AppText>
              ) : (
                item
              )}
            </View>
          );
        })}
      </>
    );
  };

  const renderRowTable = () => {
    return (
      <>
        {dataSource.map((item, rowIndex) => {
          return [
            <View key={rowIndex} style={styles.tableRow}>
              {columns.map(({ key, width, render, textAlign }, index) => {
                const cellElem = render ? (
                  render(item, rowIndex)
                ) : (
                  <AppText style={[Fonts.BodyMedium, textAlign && { textAlign }]} color={Colors.grayscale100}>
                    {item[key] || "--"}
                  </AppText>
                );

                return (
                  <View
                    key={index}
                    style={[
                      width ? { width } : styles.grow,
                      styles.tableRowItem,
                      bordered && { borderLeftWidth: 1 },
                      bordered && index === columns.length - 1 && { borderRightWidth: 1 },
                    ]}
                  >
                    <View style={{ flex: 1, justifyContent: "center" }}>{cellElem}</View>
                  </View>
                );
              })}
            </View>,
          ];
        })}
      </>
    );
  };

  if (isScrollTable) {
    return (
      <ScrollView horizontal={true} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <View>
          <View style={styles.tableHeader}>{renderHeaderTable()}</View>
          <ScrollView contentContainerStyle={{ paddingBottom: 40 }} style={Platform.OS === "web" && styles.webTable}>
            {renderRowTable()}
          </ScrollView>
          {footerData ? <View style={styles.tableHeader}>{renderFooterTable()}</View> : null}
        </View>
      </ScrollView>
    );
  }

  return (
    <View>
      <View style={styles.tableHeader}>{renderHeaderTable()}</View>
      {renderRowTable()}
      {footerData ? <View style={styles.tableHeader}>{renderFooterTable()}</View> : null}
    </View>
  );
};

type CustomGroupedTableProps = {
  columns: { key: string; title: string | ReactElement; width?: number; render?: (data: any) => ReactElement }[];
  dataSource: { title: string | ReactElement; data: { [key: string]: any }[] }[];
  renderGroupTitle?: (data: any) => ReactElement;
};

export const CustomGroupedTable: FC<CustomGroupedTableProps> = ({ columns, dataSource, renderGroupTitle }) => {
  return (
    <View>
      <View style={styles.tableHeader}>
        {columns.map((c) => {
          if (typeof c.title === "string") {
            return <AppText style={[Fonts.NumericN200, styles.grow]}>{c.title}</AppText>;
          }
          return c.title;
        })}
      </View>
      {dataSource.map((group) => {
        const { title, data } = group;
        return [
          <View style={styles.tableRowGroup}>
            {renderGroupTitle ? renderGroupTitle(group) : <AppText>{title}</AppText>}
            {data.map((item) => (
              <View style={styles.groupedRow}>
                {columns.map(({ key, width, render }) => {
                  const cellElem = render ? (
                    render(item)
                  ) : (
                    <AppText style={Fonts.BodyMedium} color={Colors.grayscale100}>
                      {item[key]}
                    </AppText>
                  );

                  return <View style={width ? { width } : styles.grow}>{cellElem}</View>;
                })}
              </View>
            ))}
          </View>,
          <Line size="sm" />,
        ];
      })}
    </View>
  );
};

export default CustomTable;

const styles = StyleSheet.create({
  tableHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.primary0,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.grayscale10,
  },
  headerRowItem: {
    padding: 8,
    borderColor: Colors.grayscale10,
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderColor: Colors.grayscale10,
  },
  tableRowItem: {
    paddingVertical: 12,
    paddingHorizontal: 8,
    height: "100%",
    borderColor: Colors.grayscale10,
  },
  tableRowGroup: {
    gap: 8,
    marginVertical: 16,
    paddingHorizontal: 8,
  },
  groupedRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  grow: {
    flex: 1,
  },
  webTable: { scrollbarWidth: "none" } as ViewStyle,
});
