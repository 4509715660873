import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const SVGComponent = (props) => (
  <Svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
    <Rect width={48} height={48} rx={24} fill="#1F71F4" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.0946 17.9744C33.661 18.5789 33.6301 19.5282 33.0256 20.0946L22.3527 30.0946C21.7754 30.6355 20.8773 30.6351 20.3005 30.0937L14.9735 25.0937C14.3694 24.5268 14.3394 23.5775 14.9063 22.9735C15.4733 22.3694 16.4225 22.3394 17.0266 22.9063L21.328 26.9436L30.9744 17.9054C31.5789 17.339 32.5282 17.3699 33.0946 17.9744Z"
      fill="white"
    />
  </Svg>
);
export default SVGComponent;
