import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M13.904 21.054c-.288.287-.645.43-1.07.43-.425 0-.781-.143-1.069-.43l-8.8-8.8a1.623 1.623 0 01-.34-.5 1.487 1.487 0 01-.125-.604V4c0-.409.148-.761.443-1.057A1.443 1.443 0 014 2.5h7.15a1.572 1.572 0 011.085.446l8.8 8.816c.293.293.442.652.445 1.075.003.424-.139.78-.426 1.067l-7.15 7.15zM12.83 20l7.155-7.15L11.155 4H4v7.15L12.83 20zM6.5 7.75c.347 0 .642-.121.886-.364.243-.243.365-.538.365-.885s-.121-.642-.364-.886A1.202 1.202 0 006.5 5.25c-.347 0-.642.121-.886.364a1.202 1.202 0 00-.365.885c0 .347.121.642.364.886.243.243.538.365.885.365z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
