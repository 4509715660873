import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#E11934" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path transform="matrix(-1 0 0 1 24 0)" fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M18.692 20.5c.505 0 .933-.175 1.283-.525.35-.35.525-.778.525-1.283V5.308c0-.505-.175-.933-.525-1.283a1.745 1.745 0 00-1.283-.525H12.74a.726.726 0 00-.534.215.726.726 0 00-.216.535c0 .213.072.391.216.535A.726.726 0 0012.74 5h5.952c.077 0 .148.032.212.096a.294.294 0 01.096.212v13.384a.294.294 0 01-.096.212.294.294 0 01-.212.096H12.74a.726.726 0 00-.534.215.726.726 0 00-.216.535c0 .213.072.391.216.535a.726.726 0 00.534.215h5.952zm-12.31-7.75h7.772a.726.726 0 00.534-.215.726.726 0 00.216-.535.726.726 0 00-.216-.535.726.726 0 00-.534-.215H6.383l1.923-1.923a.707.707 0 00.212-.507.739.739 0 00-.212-.532.72.72 0 00-.527-.24.717.717 0 00-.542.225l-3.095 3.094a.867.867 0 00-.27.633c0 .241.09.452.27.633l3.095 3.094a.714.714 0 00.53.22.751.751 0 00.539-.236.732.732 0 00.21-.534.737.737 0 00-.226-.52L6.383 12.75z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
