// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Flex, WhiteSpace } from "@ant-design/react-native";
import { ProgressBar, Text } from "react-native-paper";
import { v4 } from "uuid";
import FileViewer from "react-native-file-viewer";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";

import XmlIcon from "assets/images/svg/icons/XmlIcon";
import PdfIcon from "assets/images/svg/icons/PdfIcon";
import PngIcon from "assets/images/svg/icons/PngIcon";
import XslIcon from "assets/images/svg/icons/XIcon";
import FileIcon from "assets/images/svg/icons/FileIcon";
import { SPACING_DEFAULT, WIDTH_WINDOW } from "constants/Layout";
import NotificationContext from "contexts/NotificationContext";
import { AppText, BackHeader, HtmlView, Line, LoadingView } from "components";
import { Colors, Fonts } from "theme";
import InvoiceStatus from "../components/InvoiceStatus";
import { useAuth } from "contexts/AuthContext";
import { MobileEmailQuery } from "types";
import useEmailDetailQuery from "screens/Inbox/hooks/useEmailDetailQuery";

dayjs.extend(relativeTime);

const ITEM_WIDTH = 80;

const ITEM_HEIGHT = ITEM_WIDTH * 1.1;

const NUMBER_ITEM_PER_LINE: number = parseInt((WIDTH_WINDOW - SPACING_DEFAULT * 2) / ITEM_WIDTH, 10);

const PADDING_EACH_ITEM: number = parseInt(
  (WIDTH_WINDOW - 30 - NUMBER_ITEM_PER_LINE * ITEM_WIDTH) / (NUMBER_ITEM_PER_LINE - 1),
  10
);

const LIST_ICON = {
  xml: XmlIcon,
  pdf: PdfIcon,
  png: PngIcon,
  xsl: XslIcon,
  xslt: XslIcon,
};

const EmailDetailScreen = ({ route, schedulePushNotification, navigation }) => {
  const { t } = useTranslation("app/screens/Inbox/EmailDetailScreen");
  // cover in the case reload on web app --> call API to fetch detail
  const [data, setData] = useState(route?.params);
  const { subject, html, created_at, sender, from, to, email_attachments, status, is_duplicated, email_id } = data;
  const {
    user,
    company: { company_id: companyId },
  } = useAuth();
  const { loading } = useEmailDetailQuery({
    variables: {
      userId: user.id,
      companyId,
      emailId: email_id,
    },
    skip: typeof email_attachments !== "string",
    onCompleted: (data: MobileEmailQuery) => {
      setData(data?.emails?.[0]);
    },
  });

  const [downloadProgrerss, setDownloadProgrerss] = useState(0);
  const [indexShowProgress, setIndexShowProgress] = useState<number[]>([]);
  const isCurrentDowload = useRef(false);
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("content")} />,
    } as unknown);
  }, [navigation, t]);

  const callback = useCallback((downloadProgress) => {
    const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    setDownloadProgrerss(progress);
  }, []);

  const getExtensionFile = useCallback((filename) => {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
  }, []);

  const saveFile = useCallback(async (fileUri: string) => {
    const { status: statusMediaLibrary } = await MediaLibrary.requestPermissionsAsync();
    if (statusMediaLibrary === "granted") {
      const asset = await MediaLibrary.createAssetAsync(fileUri);
      await MediaLibrary.createAlbumAsync("Download", asset, false);
    }
  }, []);

  const handleDownloadFile = useCallback(
    (index: number, fileUri, fileName, type) => async () => {
      if (isCurrentDowload.current) {
        return;
      }

      isCurrentDowload.current = true;
      let newArray = [...indexShowProgress, index];
      setIndexShowProgress(newArray);

      const ext = getExtensionFile(fileName)?.[0];

      const name = `bizzi_${v4()}.${ext}`;

      const downloadResumable = FileSystem.createDownloadResumable(
        fileUri,
        FileSystem.documentDirectory + `bizzi-${name}`,
        {},
        callback
      );

      try {
        const { uri } = await downloadResumable.downloadAsync();
        newArray = newArray.filter((item) => item !== index);
        setIndexShowProgress(newArray);
        FileViewer.open(uri);
      } catch (e) {
        newArray = newArray.filter((item) => item !== index);
        setIndexShowProgress(newArray);
        console.error(e);
      }
      newArray = newArray.filter((item) => item !== index);
      setIndexShowProgress(newArray);
      isCurrentDowload.current = false;
    },
    [indexShowProgress, schedulePushNotification, saveFile, callback, getExtensionFile, t]
  );

  const handelDownloadFileOnWeb = (fileUri, fileType) => async () => {
    if (["application/octet-stream"].includes(fileType)) {
      const response = await fetch(fileUri);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `bizzi_${v4()}`;
      link.click();
      URL.revokeObjectURL(url);
      return;
    }

    window.open(fileUri, "_blank", "noopener noreferrer");
  };
  if (loading) {
    return <LoadingView />;
  }
  return (
    <ScrollView nestedScrollEnabled showsVerticalScrollIndicator={false}>
      <View style={styles.container}>
        <View>
          <View style={styles.flex}>
            <View style={styles.row}>
              <View style={[styles.flex, styles.mediumMarginRight]}>
                <AppText style={Fonts.BodyLarge} color={Colors.grayscale100} numberOfLines={2}>
                  {sender || from}
                </AppText>
              </View>

              <Text style={styles.date}>{dayjs(created_at).format("DD/MM/YYYY HH:mm:ss")}</Text>
            </View>

            <WhiteSpace size="sm" />
            <View>
              <AppText style={Fonts.BodyMedium}>
                {t("to")}: {to}
              </AppText>
            </View>
            <WhiteSpace size="sm" />
            <View style={[styles.row, styles.centerStyle]}>
              <InvoiceStatus status={status} isDuplicated={is_duplicated} />
            </View>
          </View>
        </View>

        <WhiteSpace size="sm" />
        <Line containerStyle={styles.marginVertical} />
        <WhiteSpace size="sm" />

        {email_attachments?.length > 0 && (
          <Flex wrap="wrap" justify="start">
            {email_attachments?.map?.((attachment, index) => {
              const ext = attachment.file_name.substr(attachment.file_name.lastIndexOf(".") + 1);
              const IconCustom = LIST_ICON[ext] ?? FileIcon;

              return (
                <View
                  key={attachment.file_name}
                  style={[
                    styles.wrapperAttachment,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {
                      marginLeft: index % NUMBER_ITEM_PER_LINE === 0 ? 0 : PADDING_EACH_ITEM,
                    },
                  ]}
                >
                  {!!indexShowProgress.includes(index) && (
                    <>
                      <ProgressBar progress={downloadProgrerss} color={Colors.blue22} />
                      <WhiteSpace size="sm" />
                    </>
                  )}

                  <TouchableOpacity
                    disabled={!!indexShowProgress.includes(index)}
                    onPress={
                      Platform.OS === "web"
                        ? handelDownloadFileOnWeb(attachment.file_url, attachment.file_type)
                        : handleDownloadFile(index, attachment.file_url, attachment.file_name, attachment.file_type)
                    }
                  >
                    <Flex justify="start" style={styles.iconAttachmentStyle}>
                      <IconCustom size={28} />
                    </Flex>
                    <WhiteSpace size="sm" />
                    <Flex>
                      <Text style={styles.textAttachmentStyle} numberOfLines={2}>
                        {attachment.file_name?.slice(0, 10)}...{ext}
                      </Text>
                    </Flex>
                  </TouchableOpacity>
                </View>
              );
            })}
            <WhiteSpace size="sm" />
          </Flex>
        )}

        <AppText style={Fonts.BodyLarge}>{subject}</AppText>
        <WhiteSpace size="sm" />
        <View style={styles.flex}>
          {Platform.OS === "web" ? (
            <div dangerouslySetInnerHTML={{ __html: html }} />
          ) : (
            <HtmlView style={{ flex: 1, height: 600 }}>{html}</HtmlView>
          )}
        </View>
      </View>
      <WhiteSpace size="xl" />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: Colors.white,
    paddingHorizontal: SPACING_DEFAULT,
  },

  date: {
    fontSize: 12,
    lineHeight: 16,

    color: Colors.grey6,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  flex: {
    flex: 1,
  },
  mediumMarginRight: {
    marginRight: SPACING_DEFAULT,
  },

  centerStyle: { alignItems: "center" },
  wrapperAttachment: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    backgroundColor: Colors.grayscale05,
    borderColor: Colors.grayscale12,
    borderRadius: 8,
    padding: 8,
    marginBottom: PADDING_EACH_ITEM,
  },
  textAttachmentStyle: { fontSize: 12, lineHeight: 16 },
  iconAttachmentStyle: { marginRight: "auto" },
  marginVertical: { marginVertical: 5 },
});

const WrapperInboxDetail = (props) => {
  return (
    <NotificationContext.Consumer>
      {({ schedulePushNotification }) => {
        return <EmailDetailScreen {...props} schedulePushNotification={schedulePushNotification} />;
      }}
    </NotificationContext.Consumer>
  );
};

export default WrapperInboxDetail;
