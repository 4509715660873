// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useRef, useState } from "react";
import { Platform, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTranslation } from "react-i18next";
import Asyncstorage from "@react-native-async-storage/async-storage";
import { StatusBar } from "expo-status-bar";
import { Menu } from "react-native-paper";

import { Button, Line } from "components";
import { Colors } from "theme";
import { SPACING_DEFAULT, WIDTH_WINDOW } from "constants/Layout";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step1 from "./Step1";
import SCREEN_NAME from "navigation/ScreenName";
import { OnboardingScreenNavigationProp } from "navigation/type";
import LanguageStatus from "constants/LanguageStatus";
import ZendeskChat from "layouts/ZendeskChat";

interface IOnboardingScreen {
  navigation: OnboardingScreenNavigationProp;
}

const OnboardingScreen: React.FC<IOnboardingScreen> = ({ navigation }) => {
  const { t, i18n } = useTranslation("screens/Onboarding/OnboardingScreen");
  const { bottom } = useSafeAreaInsets();
  const onPressLogin = useCallback(() => {
    navigation.navigate(SCREEN_NAME.LoginScreen, { tab: 0 });
  }, [navigation]);
  const refScrollView = useRef(null);

  const [indexScroll, setIndexScroll] = useState(0);

  useEffect(() => {
    const loadingLanguage = async () => {
      const locateStore = await Asyncstorage.getItem("locale");

      if (locateStore) {
        i18n.changeLanguage(locateStore);
      }
    };
    loadingLanguage();
  }, [i18n]);

  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  const onPressRegister = useCallback(() => {
    navigation.navigate(SCREEN_NAME.RegisterOnboardingScreen);
  }, [navigation]);
  const { top } = useSafeAreaInsets();

  return (
    <View style={styles.container}>
      <StatusBar style="dark" />

      <View style={{ position: "absolute", right: 20, top: top + 5, zIndex: 10 }}>
        <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <TouchableOpacity onPress={openMenu}>
              <MaterialIcons name="language" size={24} color="black" />
            </TouchableOpacity>
          }
        >
          <Menu.Item
            style={{ backgroundColor: i18n.language === LanguageStatus.VN ? Colors.grayscale05 : "transparent" }}
            onPress={async () => {
              i18n.changeLanguage(LanguageStatus.VN);
              await Asyncstorage.setItem("locale", LanguageStatus.VN);
              closeMenu();
            }}
            title={t("vn")}
          />
          <Menu.Item
            style={{ backgroundColor: i18n.language !== LanguageStatus.VN ? Colors.grayscale05 : "transparent" }}
            onPress={async () => {
              i18n.changeLanguage(LanguageStatus.EN);
              await Asyncstorage.setItem("locale", LanguageStatus.EN);
              closeMenu();
            }}
            title={t("en")}
          />
        </Menu>
      </View>

      <View style={styles.wrapperSwiped}>
        <ScrollView
          ref={refScrollView}
          showsHorizontalScrollIndicator={false}
          horizontal
          pagingEnabled
          scrollEventThrottle={16}
          onScroll={({
            nativeEvent: {
              contentOffset: { x },
            },
          }) => {
            setIndexScroll((currenIndex) => {
              const newIndex = Math.abs(Math.round(x / WIDTH_WINDOW));
              if (newIndex !== currenIndex) {
                return newIndex;
              }

              return newIndex;
            });
          }}
        >
          <View style={styles.wrapperItemStyle}>
            <Step1 />
          </View>
          <View style={styles.wrapperItemStyle}>
            <Step2 />
          </View>
          <View style={styles.wrapperItemStyle}>
            <Step3 />
          </View>
        </ScrollView>
        <View style={styles.wrapperDotStyle}>
          {[1, 2, 3].map((_, index) => {
            return (
              <TouchableWithoutFeedback
                key={index}
                onPress={() => refScrollView.current?.scrollTo({ x: index * WIDTH_WINDOW, y: 0 })}
              >
                <View
                  style={[
                    styles.dotStyle,
                    {
                      backgroundColor: indexScroll === index ? Colors.primary50 : Colors.grayscale10,
                    },
                  ]}
                />
              </TouchableWithoutFeedback>
            );
          })}
        </View>
      </View>

      <View style={[styles.buttonContainer, { paddingBottom: bottom + SPACING_DEFAULT }]}>
        <Button onPress={onPressLogin}>{t("login")}</Button>
        <Line hasBackground={false} size="xl" />
        <Button
          //title={t("sign_up")}
          //color={Colors.grayscale05}
          type="white"
          textColor={Colors.grayscale100}
          onPress={onPressRegister}
          style={{ borderColor: Colors.grayscale05 }}
        >
          {t("sign_up")}
        </Button>
      </View>

      {Platform.OS === "web" && <ZendeskChat />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  buttonContainer: {
    paddingHorizontal: SPACING_DEFAULT,
  },
  wrapperDotStyle: { flexDirection: "row", alignItems: "center", justifyContent: "center" },
  dotStyle: {
    width: 8,
    height: 8,
    marginLeft: 10,
    borderRadius: 8,
  },
  wrapperItemStyle: { flex: 1, width: WIDTH_WINDOW },
  wrapperSwiped: { flex: 1, marginBottom: 15 },
});

export default OnboardingScreen;
