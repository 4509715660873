import { TextInputCustom } from "components";
import React, { FC } from "react";
import { StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import VatRateSelectModal from "screens/Expense/components/ExpenseForm/VatRateSelectModal";
import { Control } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { ExpenseFormValues } from "screens/Expense/components/ExpenseForm/types";

interface NoInvoiceTabProps {
  control: Control<ExpenseFormValues>;
  errors?: FieldErrors<ExpenseFormValues>;
}
const NoInvoiceTab: FC<NoInvoiceTabProps> = ({ control, errors }) => {
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");
  return (
    <>
      <TextInputCustom
        name="taxCode"
        control={control}
        rules={{ required: t("tax_code_required") }}
        label={t("tax_code_placeholder")}
        error={errors.taxCode}
        placeholder={t("tax_code_placeholder")}
      />
      <TextInputCustom
        name="invoiceCode"
        control={control}
        rules={{ required: t("invoice_code_required") }}
        style={styles.input}
        label={t("invoice_code_placeholder")}
        placeholder={t("invoice_code_placeholder")}
        error={errors?.invoiceCode}
      />
      <VatRateSelectModal style={styles.selectInput} name="vatRate" control={control} />
    </>
  );
};
export default NoInvoiceTab;

const styles = StyleSheet.create({
  input: { marginTop: 9 },
  selectInput: { marginTop: 16 },
});
