import { useAuth } from "contexts/AuthContext";
import { useCallback, useState } from "react";
import { request } from "utils/gql";

export const EXPENSE_REGISTER = /* GraphQL */ `
  mutation MobileExpenseRegister($object: ExpenseRegisterInput!) {
    expenseRegister(object: $object) {
      group_id
      invoice_email
      message
      success
      token
      company_id
      user_id
    }
  }
`;

const useExpenseRegisterCustomer = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const registerNewCustomer = useCallback(
    async (object) => {
      setLoading(true);

      try {
        const result = await request(
          EXPENSE_REGISTER,
          {
            object,
          },
          user?.token
        );

        setLoading(false);
        return result;
      } catch (error) {
        setLoading(false);
        return error;
      }
    },
    [user]
  );

  return {
    registerNewCustomer,
    loading,
  };
};

export default useExpenseRegisterCustomer;
