import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";

const QUERY_COMPANY_EMPLOYEES = gql`
  query MobileCompanyEmployees($companyId: uuid!) {
    company_employees(
      where: { company_id: { _eq: $companyId }, deleted_at: { _is_null: true }, user: { is_active: { _eq: true } } }
    ) {
      employee_id
      fullname
      email
      title
      expense_policies_employees {
        expense_policies_employees_id
        expense_policy {
          expense_policy_id
          name
        }
      }
    }
  }
`;
const useCompanyEmployeesQuery = () => {
  const { company } = useAuth();

  const results = useQuery<{ company_employees: CompanyEmployees[] }>(QUERY_COMPANY_EMPLOYEES, {
    variables: {
      companyId: company?.company_id,
    },
    skip: !company?.company_id,
  });

  return {
    ...results,
    employees: results.data?.company_employees ?? [],
  };
};
export default useCompanyEmployeesQuery;
