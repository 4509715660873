import { FC, memo, useRef } from "react";
import { FlashList } from "@shopify/flash-list";
import { CONSTANTS } from "constants/constants";

import { StyleSheet, View } from "react-native";
import { Colors } from "theme";
import CompanyListItem from "./CompanyListItem";

interface CompanyListProps {
  activeCompanyId?: string;
  companies: Partial<Companies>[];
  onSelect: (companyId: string) => void;
}
const CompanyList: FC<CompanyListProps> = ({ activeCompanyId, companies, onSelect }) => {
  const flashListRef = useRef<FlashList<any>>(null);
  const renderItem = ({ item }) => (
    <CompanyListItem isActive={item.company_id === activeCompanyId} item={item} onSelect={onSelect} />
  );

  return (
    <View style={styles.container}>
      <FlashList
        ref={flashListRef}
        keyExtractor={(item, index) => item?.companyId ?? index}
        data={companies ?? []}
        contentContainerStyle={styles.listContainer}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

export default memo(CompanyList);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    // paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 50,
  },
});
