import { RadioSelectFilter } from "components";
import React, { FC, ReactElement, useMemo, useState } from "react";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { PlaneTicketSortOptions } from "screens/FlightBooking/types";
import { useTranslation } from "react-i18next";

type TicketSortingModalProps = {
  button: ReactElement;
  handleApplySort: (value: PlaneTicketSortOptions) => void;
};
const TicketSortingModal: FC<TicketSortingModalProps> = ({ button, handleApplySort }) => {
  const { t } = useTranslation("app/screens/FlightBooking/FlightSearchScreen/FlightSearchScreen");
  const sortOptions: SelectItem[] = useMemo(() => {
    return [
      { label: t("lowest_cost"), value: PlaneTicketSortOptions.PriceAsc },
      {
        label: t("early_takeoff"),
        value: PlaneTicketSortOptions.DepartureAsc,
      },
      {
        label: t("late_takeoff"),
        value: PlaneTicketSortOptions.DepartureDesc,
      },
      {
        label: t("early_landing"),
        value: PlaneTicketSortOptions.ArrivalAsc,
      },
      {
        label: t("late_landing"),
        value: PlaneTicketSortOptions.ArrivalDesc,
      },
      {
        label: t("shortest_flight_duration"),
        value: PlaneTicketSortOptions.DurationAsc,
      },
    ];
  }, [t]);
  const [currentValue, setCurrentValue] = useState<SelectItem>(sortOptions[0]);
  const onApplySort = (sortValue) => {
    setCurrentValue(sortValue);
    handleApplySort(sortValue.value);
  };
  return (
    <RadioSelectFilter
      showClearButton={false}
      title="Sắp xếp theo"
      renderIconButton={() => button}
      data={sortOptions}
      value={currentValue}
      setValue={onApplySort}
    />
  );
};

export default TicketSortingModal;
