import { FlashList } from "@shopify/flash-list";
import { memo, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RefreshControl, StyleSheet, View } from "react-native";
import Animated, {
  useAnimatedScrollHandler,
  useSharedValue,
  useAnimatedStyle,
  Extrapolation,
  interpolate,
  withTiming,
} from "react-native-reanimated";

import { EmptyExpense } from "assets/images/svg/icons";
import {
  AnimatedFlatList,
  EmptyData,
  ExpenseReportItem,
  RadioSelectFilter,
  SkeletonListLoading,
  SortingLabel,
} from "components";
import { CONSTANTS, EXPENSE_REPORT_STATUS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { Colors } from "theme";
import { clampReanimated } from "utils";
import useExpenseClaimedQuery from "../hooks/useExpenseClaimedQuery";
import StatusList from "./StatusList";
import FlashListItem from "components/FlashListItem";
import { EXPENSE_SORT_OPTIONS } from "../types";
import { EVENT } from "constants/Tracking";
import { analyticService } from "services/AnalyticsService";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { NetworkStatus } from "@apollo/client";
import { isNil } from "lodash";

const FILTER_CONTAINER_HEIGHT = 82;

const LIST_STATUS = [
  {
    title: "draft",
    value: EXPENSE_REPORT_STATUS.DRAFT,
  },
  {
    title: "waiting",
    value: `${EXPENSE_REPORT_STATUS.AWAITING_ACCOUNTANT_APPROVAL};${EXPENSE_REPORT_STATUS.SUBMITTED}`,
  },
  {
    title: "approved",
    value: EXPENSE_REPORT_STATUS.APPROVED,
  },
  {
    title: "rejected",
    value: EXPENSE_REPORT_STATUS.REJECTED,
  },
  {
    title: "canceled",
    value: EXPENSE_REPORT_STATUS.CANCEL,
  },
];
const STATUS_DEFAULT = [
  EXPENSE_REPORT_STATUS.DRAFT,
  EXPENSE_REPORT_STATUS.SUBMITTED,
  EXPENSE_REPORT_STATUS.REJECTED,
  EXPENSE_REPORT_STATUS.AWAITING_ACCOUNTANT_APPROVAL,
  EXPENSE_REPORT_STATUS.APPROVED,
  EXPENSE_REPORT_STATUS.CANCEL,
];
const ClaimedExpenseTab = () => {
  const {
    user: { employee_id: employeeId },
  } = useAuth();
  const { t } = useTranslation("app/screens/Expense/ExpenseListScreen");
  const sortOptions = useMemo(
    () =>
      [
        { label: t("requested_at_desc"), value: EXPENSE_SORT_OPTIONS.REQUESTED_AT_DESC },
        { label: t("requested_at_asc"), value: EXPENSE_SORT_OPTIONS.REQUESTED_AT_ASC },
        {
          label: t("expense_asc"),
          value: EXPENSE_SORT_OPTIONS.AMOUNT_ASC,
        },
        {
          label: t("expense_desc"),
          value: EXPENSE_SORT_OPTIONS.AMOUNT_DESC,
        },
      ].reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [t]
  );
  const [currentStatus, setCurrentStatus] = useState<number>();
  const [sortOption, setSortOption] = useState<SelectItem>(sortOptions[EXPENSE_SORT_OPTIONS.REQUESTED_AT_DESC]);
  const flashListRef = useRef<FlashList<any>>(null);

  const variables = useMemo(() => {
    return {
      where: {
        employeeId: employeeId,
        statuses: isNil(currentStatus)
          ? STATUS_DEFAULT
          : typeof currentStatus === "string"
          ? (currentStatus as string).split(";").map((status) => parseInt(status, 10))
          : [currentStatus],
      },
      sortOption: sortOption.value,
      offset: 0,
    };
  }, [employeeId, currentStatus, sortOption]);

  const { data, loading, called, refetch, fetchMore, networkStatus } = useExpenseClaimedQuery(variables);
  const expenseList = data?.expClaimedExpenses?.expenses ?? [];
  const total = data?.expClaimedExpenses?.total ?? 0;

  const handleChangeStatus = (status: number) => {
    translationY.value = withTiming(0);
    setCurrentStatus(currentStatus !== status ? status : undefined);
  };

  const handleLoadMore = () => {
    if (loading || expenseList?.length === 0 || expenseList?.length >= total) {
      return;
    }
    fetchMore?.({
      variables: { offset: expenseList.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expClaimedExpenses: {
            expenses: [...prev?.expClaimedExpenses?.expenses, ...fetchMoreResult?.expClaimedExpenses?.expenses],
            total: fetchMoreResult?.expClaimedExpenses?.total,
          },
        };
      },
    });
  };
  const onRefreshData = () => {
    translationY.value = 0;
    refetch();
  };
  const renderItem = ({ item }) => (
    <FlashListItem>
      <ExpenseReportItem item={item} onRefreshData={onRefreshData} />
    </FlashListItem>
  );

  const renderEmpty = () => {
    if (loading || !called) {
      return null;
    }
    return (
      <EmptyData icon={<EmptyExpense />} title={t("empty_expense")} description={t("empty_expense_description")} />
    );
  };

  const renderFooter = () => {
    if (loading && expenseList.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };

  const translationY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event, ctx) => {
      let { y } = event.contentOffset;
      if (y < 0) {
        y = 0;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const dy = y - (ctx?.prevY ?? 0);
      translationY.value = clampReanimated(
        translationY.value + dy,
        0,
        FILTER_CONTAINER_HEIGHT
        // CONSTANTS.EXPENSE_LIST.HEADER_STATUS_HEIGHT + 50
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ctx.prevY = y;
    },
  });
  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      translationY.value,
      [0, FILTER_CONTAINER_HEIGHT],
      [0, -FILTER_CONTAINER_HEIGHT],
      Extrapolation.CLAMP
    );
    return {
      transform: [{ translateY: translateY }],
    };
  });

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.floatHeader, animatedStyle]}>
        <StatusList data={LIST_STATUS} currentStatus={currentStatus} handleChangeStatus={handleChangeStatus} />
        <View style={styles.sortingContainer}>
          <SortingLabel
            sortingModalProps={{
              showClearButton: false,
              title: t("sort_by"),
              data: Object.values(sortOptions),
              value: sortOption,
              setValue: setSortOption,
              onPress: () =>
                analyticService.logEvent({ name: EVENT.REQUEST.TAP_OPEN_SORT_MODAL, properties: { tab: "reported" } }),
            }}
          />
        </View>
      </Animated.View>
      {(loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={styles.loadingContainer} />
      ) : (
        <AnimatedFlatList
          refreshControl={
            <RefreshControl
              progressViewOffset={CONSTANTS.EXPENSE_LIST.HEADER_STATUS_HEIGHT + 10}
              tintColor={Colors.primary50}
              refreshing={false}
              onRefresh={refetch}
            />
          }
          onScroll={scrollHandler}
          ref={flashListRef}
          keyExtractor={({ expenseId }, index) => expenseId ?? index}
          data={expenseList}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          estimatedItemSize={88}
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
    </View>
  );
};
export default memo(ClaimedExpenseTab);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: FILTER_CONTAINER_HEIGHT - 10,
    paddingBottom: 50,
  },
  loadingMore: { marginTop: FILTER_CONTAINER_HEIGHT - 10 },
  loadingContainer: { marginTop: FILTER_CONTAINER_HEIGHT - 10 },
  floatHeader: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1,
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
  sortingContainer: {
    paddingTop: 6,
    paddingBottom: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
