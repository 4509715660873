import * as React from "react";
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={187} height={187} viewBox="0 0 187 187" fill="none" {...props}>
      <G clipPath="url(#clip0_767_12759)">
        <Path
          d="M76.02 161.058c4.492 1.43 9.607 2.237 15.052 2.237s10.56-.807 15.052-2.237c-4.345-4.015-9.534-6.581-15.052-6.581-5.5 0-10.652 2.53-15.052 6.581z"
          fill="#C0CAD8"
        />
        <Path
          d="M169.483 102.135c-2.328 36.52-37.62 52.984-79.714 50.289-42.093-2.677-74.965-23.522-72.637-60.006 2.328-36.484 38.775-71.207 80.87-68.53 42.093 2.676 73.81 41.727 71.481 78.247z"
          fill="#E4EAF1"
        />
        <Path
          d="M65.661 29.222l-5.335 83.527c9.497 3.722 20.167 6.087 31.57 6.82 11.386.734 22.294-.256 32.194-2.75l5.335-83.527c-9.405-5.28-20.038-8.672-31.405-9.405-11.385-.734-22.367 1.283-32.359 5.335z"
          fill="#1F71F4"
        />
        <Path
          d="M91.182 59.895l-1.595-.11 1.393-4.803 2.933-10.23.147-.55-8.103-.513L89.77 30.69l-4.785-.311 11.66-6.582 7.205 7.792-3.703-.239-1.504 5.189 10.652.678-18.113 22.678z"
          fill="#fff"
        />
        <Path
          d="M36.585 114.75c-9.075-12.815 4.161-52.69 17.966-60.134 18.554-9.992 71.061-5.243 86.956 8.543 11.514 10.01 17.509 50.528 7.462 60.428-20.057 19.763-96.196 14.025-112.384-8.837z"
          fill="#1B1B1D"
        />
        <Path
          d="M22.046 104.371l-8.616-2.787c-1.577-.495-2.64-2.328-2.512-4.327l.935-14.666c.128-1.999 1.412-3.685 3.08-3.979l8.892-1.668c2.218-.404 4.143 1.723 3.96 4.436l-1.21 19.122c-.202 2.659-2.384 4.565-4.529 3.869z"
          fill="#C0CAD8"
        />
        <Path
          d="M17.151 102.883l-7.901-2.511c-1.467-.459-2.42-2.127-2.31-3.997l.861-13.42c.11-1.852 1.284-3.355 2.787-3.63l8.158-1.504c2.035-.385 3.777 1.596 3.63 4.034l-1.118 17.472c-.147 2.493-2.163 4.198-4.107 3.556z"
          fill="#E4EAF1"
        />
        <Path
          d="M163.031 114.546l8.891-1.668c1.669-.294 2.952-1.98 3.08-3.979l.935-14.666c.129-1.999-.935-3.832-2.548-4.346l-8.617-2.786c-2.145-.679-4.326 1.191-4.491 3.886l-1.21 19.122c-.184 2.714 1.723 4.877 3.96 4.437z"
          fill="#C0CAD8"
        />
        <Path
          d="M168.091 113.741l8.158-1.503c1.522-.275 2.677-1.815 2.787-3.63l.861-13.42c.11-1.852-.843-3.502-2.31-3.96l-7.901-2.512c-1.98-.642-3.96 1.1-4.107 3.538l-1.118 17.472c-.147 2.402 1.595 4.382 3.63 4.015z"
          fill="#E4EAF1"
        />
        <Path
          d="M79.137 113.594s3.832 4.601 13.402 5.811 14.025-2.97 14.025-2.97-.825 7.352-14.429 5.941c-13.603-1.412-12.998-8.782-12.998-8.782z"
          fill="#474749"
        />
        <Path
          d="M152.122 94.967l-5.922-.586c-.128-.019-.238.018-.366.036-.789-10.303-8.709-18.846-19.434-19.891-10.541-1.027-20.057 5.518-23.063 15.18-2.42-2.53-5.042-3.979-7.847-4.272h-.073c-2.842-.275-5.775.642-8.745 2.695-1.009-10.083-8.855-18.352-19.415-19.397-11.386-1.118-21.58 6.637-23.67 17.6-.036 0-.054-.018-.09-.036l-5.922-.587c-1.082-.11-2.054.697-2.164 1.778-.11 1.082.697 2.054 1.779 2.164l5.921.586c.037 0 .074-.018.092-.018-.092 11.165 8.397 20.754 19.782 21.872 11.788 1.155 22-7.224 23.558-18.774 3.044-2.86 5.904-4.198 8.47-3.941h.055c2.53.275 5.005 2.126 7.334 5.481-.715 11.661 7.938 21.891 19.727 23.046 12.063 1.191 22.495-7.59 23.668-19.562l5.94.568c1.082.11 2.054-.697 2.164-1.778.11-1.082-.697-2.054-1.779-2.164z"
          fill="#fff"
        />
        <Path
          d="M35.173 66.074c0-9.332 8.433-16.775 16.775-16.775-12.925 0-16.775-6.307-16.775-16.776 0 10.469-7.15 16.776-16.775 16.776 9.625 0 16.775 8.36 16.775 16.775zM154.524 84.242c0-7.649 6.912-13.75 13.75-13.75-10.594 0-13.75-5.17-13.75-13.75 0 8.58-5.861 13.75-13.75 13.75 7.889 0 13.75 6.853 13.75 13.75zM161.858 60.407c0-10.71 9.677-19.25 19.25-19.25-14.832 0-19.25-7.238-19.25-19.25 0 12.012-8.205 19.25-19.251 19.25 11.046 0 19.251 9.593 19.251 19.25z"
          fill="#FFC261"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_767_12759">
          <Path fill="#fff" transform="translate(6.94 23.742)" d="M0 0H172.958V139.555H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
