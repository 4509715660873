import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileExpExpenseRequestTravelUsageQuery, MobileExpExpenseRequestTravelUsageQueryVariables } from "types";

const QUERY = gql`
  query MobileExpExpenseRequestTravelUsage($input: ExpExpenseRequestTravelUsageInput!) {
    expExpenseRequestTravelUsage(input: $input) {
      periods {
        period
        periodValue
        maxTravelDays
        totalTravelDays
      }
    }
  }
`;

const useExpenseRequestTravelUsage = (variables?: MobileExpExpenseRequestTravelUsageQueryVariables) => {
  return useLazyQuery<MobileExpExpenseRequestTravelUsageQuery, MobileExpExpenseRequestTravelUsageQueryVariables>(
    QUERY,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    }
  );
};

export default useExpenseRequestTravelUsage;
