import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#EA4335", size = 100, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2zM6.723 9.745v1.734h.43c.25 0 .421-.061.514-.184.083-.14.124-.373.124-.701 0-.385-.073-.633-.217-.744-.093-.07-.311-.105-.654-.105h-.197zM5 15.5v-7h1.845c.992 0 1.672.125 2.04.376.418.293.627.877.627 1.754 0 .24-.003.403-.01.49a5.075 5.075 0 01-.075.402 1.4 1.4 0 01-.242.535c-.294.444-.79.667-1.488.667h-.974V15.5H5zm6.926-5.809v4.618h.27c.481 0 .722-.2.722-.597v-3.424c0-.398-.241-.597-.723-.597h-.27zM10.202 15.5v-7h2.565c.693 0 1.19.178 1.49.535.255.303.382.75.382 1.34v3.329c0 .584-.197 1.042-.59 1.375-.337.28-.764.421-1.282.421h-2.565zm5.203-7v7h1.723v-2.909h1.675v-1.35h-1.675V9.85H19V8.5h-3.595z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
