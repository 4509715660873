import { FC, ReactNode } from "react";
import { StyleProp, StyleSheet, TextStyle, useWindowDimensions, View, ViewStyle } from "react-native";
import { NavigationState, SceneRendererProps, TabBar, TabView } from "react-native-tab-view";

import { AppText, IconCustom } from "components";
import { Colors, Fonts } from "theme";
import { IconName } from "assets/icons";

type TopTabLineViewProps = {
  renderScene: (props: SceneRendererProps & { route: { key: string; title: string; icon?: string } }) => ReactNode;
  routes: { key: string; title: string; icon?: string }[];
  index: number;
  setIndex: (value: number) => void;
  lazy?: boolean;
  tabBarContainerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  scrollEnabled?: boolean;
  tabStyle?: StyleProp<TextStyle>;
};

const TopTabLineView: FC<TopTabLineViewProps> = ({
  renderScene,
  routes,
  index,
  setIndex,
  lazy = true,
  tabBarContainerStyle,
  labelStyle,
  style,
  scrollEnabled,
  tabStyle,
}) => {
  const renderTabBar = (
    props: SceneRendererProps & {
      navigationState: NavigationState<{
        key: string;
        title: string;
        icon?: IconName;
      }>;
    }
  ) => (
    <TabBar
      {...props}
      pressColor={"transparent"}
      indicatorStyle={[styles.indicatorStyle]}
      tabStyle={tabStyle}
      style={[styles.tabBarStyle, tabBarContainerStyle]}
      renderLabel={({ route: tabBarRoute, focused }) => {
        return (
          <View style={styles.tabBarLabel}>
            {tabBarRoute?.icon && <IconCustom name={tabBarRoute?.icon} />}
            <AppText
              style={[Fonts.BodyMedium, labelStyle]}
              color={focused ? Colors.primary50 : Colors.grayscale100}
              numberOfLines={1}
            >
              {tabBarRoute.title}
            </AppText>
          </View>
        );
      }}
      scrollEnabled={scrollEnabled}
    />
  );
  const { width } = useWindowDimensions();
  return (
    <TabView
      lazy={lazy}
      renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width }}
      style={style}
    />
  );
};
export default TopTabLineView;
const styles = StyleSheet.create({
  tabBarStyle: {
    elevation: 0,
    backgroundColor: Colors.white,
    height: 42,
  },
  indicatorStyle: {
    backgroundColor: Colors.primary50,
  },
  tabBarLabel: {
    flexDirection: "row",
    gap: 8,
  },
});
