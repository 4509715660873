import * as React from "react";
import Svg, { Ellipse, Path, Rect } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg width={273} height={123} viewBox="0 0 273 123" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.0989 12.8721C90.0989 5.76305 95.8619 0 102.971 0H260.127C267.237 0 273 5.76305 273 12.8721C273 19.9812 267.237 25.7443 260.127 25.7443H201.96C204.876 28.1041 206.741 31.7122 206.741 35.7558C206.741 42.8225 201.046 48.5591 193.995 48.6273H230.982C238.091 48.6273 243.854 54.3904 243.854 61.4995C243.854 68.6085 238.091 74.3716 230.982 74.3716H77.2449C84.354 74.3716 90.1171 68.6085 90.1171 61.4995C90.1171 54.3904 84.354 48.6273 77.245 48.6273H36.5849C29.5343 48.5591 23.8398 42.8225 23.8398 35.7558C23.8398 28.6467 29.6029 22.8837 36.712 22.8837H94.8795C91.9634 20.5238 90.0989 16.9157 90.0989 12.8721ZM0 87.2451C0 80.136 5.76305 74.3729 12.8721 74.3729H196.536C197.169 74.3729 197.791 74.4186 198.399 74.5068H189.345C183.036 74.5068 177.921 79.6217 177.921 85.9312C177.921 92.2407 183.036 97.3556 189.345 97.3556H214.045C220.394 98.1486 225.306 103.565 225.306 110.128C225.306 117.237 219.542 123 212.433 123H73.8321C66.723 123 60.96 117.237 60.96 110.128C60.96 106.085 62.8239 102.477 65.7394 100.117H12.8721C5.76304 100.117 0 94.3541 0 87.2451Z"
      fill="#F0F6FF"
    />
    <Rect x={192.985} y={101.52} width={2.12729} height={7.81221} rx={1.06365} fill="#75A4FE" />
    <Rect x={192.985} y={111.936} width={2.12729} height={7.81221} rx={1.06365} fill="#75A4FE" />
    <Rect
      x={195.611}
      y={111.936}
      width={2.11731}
      height={7.84903}
      rx={1.05866}
      transform="rotate(-90 195.611 111.936)"
      fill="#75A4FE"
    />
    <Rect
      x={183.837}
      y={111.936}
      width={2.11731}
      height={7.84903}
      rx={1.05866}
      transform="rotate(-90 183.837 111.936)"
      fill="#75A4FE"
    />
    <Path
      d="M188.674 74.3723C188.674 76.9709 186.556 79.0855 183.933 79.0855C181.311 79.0855 179.193 76.9709 179.193 74.3723C179.193 71.7738 181.311 69.6592 183.933 69.6592C186.556 69.6592 188.674 71.7738 188.674 74.3723Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.01559}
    />
    <Ellipse cx={82.7849} cy={37.053} rx={4.25458} ry={4.23462} fill="#75A4FE" />
    <Path
      d="M179 43.6646V51.9588C179 57.374 175.556 60.8014 170.116 60.8014H158.339V36.8441C158.339 33.0397 161.473 29.9551 165.295 29.9551C169.048 29.9894 172.492 31.4974 174.971 33.9651C177.45 36.4671 179 39.8944 179 43.6646Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.76627}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M110.124 47.4395V96.396C110.124 99.2984 113.361 100.942 115.634 99.1935L121.522 94.7175C122.899 93.6684 124.828 93.8083 126.068 95.0672L131.784 100.907C133.127 102.271 135.331 102.271 136.674 100.907L142.459 95.0322C143.664 93.8083 145.592 93.6684 146.935 94.7175L152.824 99.1935C155.096 100.907 158.333 99.2634 158.333 96.396V36.9489C158.333 33.1023 161.432 29.9551 165.22 29.9551H127.342H123.898C113.568 29.9551 110.124 36.2145 110.124 43.9426V47.4395Z"
      fill="white"
      stroke="#1F71F4"
      strokeWidth={2.76627}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M116.381 86.3165V42.4068C116.381 39.6472 118.618 37.4102 121.377 37.4102H148.268C151.027 37.4102 153.264 39.6472 153.264 42.4068V86.6783C153.264 89.8909 149.567 91.6959 147.034 89.72C145.771 88.7347 144.03 88.6326 142.661 89.4636L136.979 92.9108C135.303 93.9272 133.189 93.8705 131.57 92.7659L126.909 89.5845C125.581 88.6781 123.834 88.673 122.501 89.5718C120.485 90.931 117.928 90.1222 116.871 88.2317C116.562 87.6772 116.381 87.0297 116.381 86.3165Z"
      fill="#F0F6FF"
    />
    <Path d="M116.355 57.2559V74.6291" stroke="#1F71F4" strokeWidth={2.49832} strokeLinecap="round" />
    <Path d="M116.355 79.5928V87.0384" stroke="#1F71F4" strokeWidth={2.49832} strokeLinecap="round" />
    <Rect x={96.6123} y={5.29297} width={2.12729} height={7.41059} rx={1.06365} fill="#75A4FE" />
    <Rect x={96.7451} y={15.2559} width={2.12729} height={7.41059} rx={1.06365} fill="#75A4FE" />
    <Rect
      x={98.7393}
      y={14.8213}
      width={2.11731}
      height={7.44552}
      rx={1.05866}
      transform="rotate(-90 98.7393 14.8213)"
      fill="#75A4FE"
    />
    <Path
      d="M90.2256 15.2559C89.641 15.2559 89.167 14.7819 89.167 14.1972V14.1972C89.167 13.6125 89.641 13.1385 90.2256 13.1385L95.5539 13.1385C96.1385 13.1385 96.6125 13.6125 96.6125 14.1972V14.1972C96.6125 14.7819 96.1385 15.2559 95.5539 15.2559L90.2256 15.2559Z"
      fill="#75A4FE"
    />
  </Svg>
);
export default SVGComponent;
