import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#1F71F4", height = 40, width = 40 }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 125 126" fill="none">
      <Path
        d="M2.5 31.493V11.767A8.767 8.767 0 0111.267 3H36.88M3.49 94.507v19.726A8.767 8.767 0 0012.257 123H37.87M122.501 31.493V11.767A8.767 8.767 0 00113.734 3H88.121M122.501 94.507v19.726a8.767 8.767 0 01-8.767 8.767H88.121"
        stroke={color}
        strokeWidth={4.38356}
      />
      <Path
        d="M53.414 20.635c-9.45 1.918-24.346 10.092-24.346 13.422 0 2.926 2.26 2.825 6.78-.303 2.466-1.816 7.088-4.44 10.375-5.954 5.24-2.422 7.191-2.724 16.231-2.724 11.505.1 17.463 2.018 25.99 8.375 4.622 3.431 7.806 3.633 7.19.505-.41-2.523-11.71-9.688-18.285-11.706-7.293-2.12-17.669-2.926-23.935-1.615z"
        fill={color}
      />
      <Path
        d="M49.1 32.341c-7.808 2.826-13.971 6.66-19.313 12.312-5.136 5.449-6.266 9.183-2.773 9.183 1.13 0 2.054-.404 2.054-.908 0-.404 2.363-3.129 5.24-5.954 16.127-15.844 40.267-15.743 56.395.1 2.876 2.826 5.136 5.45 5.136 5.854 0 .504.925.908 2.055.908 6.883 0-4.315-13.119-16.128-18.87-6.78-3.23-8.526-3.634-17.566-4.037-7.704-.303-11.094 0-15.1 1.412z"
        fill={color}
      />
      <Path
        d="M51.976 42.533C36.259 48.083 27.63 60.395 27.63 77.046c0 6.054.617 8.779 2.876 13.825 3.082 6.66 5.959 9.99 7.705 8.88 1.746-1.01 1.335-3.431-.925-6.257-2.773-3.43-5.136-11.605-5.136-17.558 0-11.706 9.245-24.926 19.929-28.458 12.43-4.137 25.886-.202 33.59 9.789 7.089 9.284 9.246 21.696 4.52 26.338-5.341 5.348-12.018 1.917-13.25-6.862-1.13-9.284-5.24-13.724-13.252-14.532-12.533-1.21-19.313 12.715-12.533 25.935 1.438 2.825 4.52 6.963 6.78 9.183 4.417 4.339 12.532 8.577 14.279 7.467 2.157-1.311.822-3.431-3.493-5.55-8.012-3.733-15.922-14.43-16.025-21.494 0-3.532 2.465-8.376 5.034-9.789 6.677-3.43 14.484 1.514 14.484 9.284 0 11.908 13.457 18.064 21.675 9.99 7.293-7.265 3.184-26.136-8.116-37.034-8.423-8.174-23.01-11.504-33.796-7.67z"
        fill={color}
      />
      <Path
        d="M58.14 51.918c-5.856 1.01-9.554 3.129-13.868 7.77-7.602 8.275-8.424 18.064-2.569 29.669 4.315 8.577 10.992 16.65 12.841 15.54 2.055-1.211 1.336-3.632-2.157-7.77-5.136-5.853-8.937-13.32-9.656-18.971-1.336-11.202 8.731-22.302 20.237-22.302 1.438 0 5.136 1.21 8.012 2.624 6.164 3.027 10.067 8.476 11.3 15.641.822 4.642 2.67 5.752 4.623 2.826.616-1.01.41-3.33-.72-7.367-3.698-12.412-15.408-19.779-28.044-17.66z"
        fill={color}
      />
      <Path
        d="M60.605 72.706c-2.157 2.12.103 10.697 4.622 17.155 3.904 5.651 8.218 8.578 13.868 9.183 4.007.404 4.623.202 4.315-1.311-.206-.909-2.157-2.321-4.417-3.028-7.088-2.422-13.868-11-13.971-17.76 0-3.431-2.67-6.055-4.417-4.239z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
