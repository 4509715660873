import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import {
  ApprovalFlowInline,
  CollapsibleView,
  EmployeeTextView,
  IconCustom,
  Line,
  StatusDetail,
  TextView,
} from "components";
import { APPROVAL_STATUS, CONSTANTS, EXPENSE_REPORT_PAYMENT_STATUS, EXPENSE_REPORT_STATUS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { MobileExpenseReportDetailsQuery } from "types";
import SummaryMessage from "./SummaryMessage";
import dayjs from "dayjs";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";
import AllocatedStatusInfo from "./AllocatedStatusInfo";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import ReportPaymentStatus from "screens/Report/ReportListScreen/components/ReportItem/ReportPaymentStatus";
import { PAYMENT_METHOD_TEXT } from "screens/Report/constants";

interface GeneralInfoProps {
  expenseApprovalRules: any[];
  expenseReport: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
  isApproverView?: boolean;
}

const STATUS = {
  [EXPENSE_REPORT_STATUS.APPROVED]: {
    title: "approved",
    backgroundColor: Colors.success0,
  },
  [EXPENSE_REPORT_STATUS.SUBMITTED]: {
    title: "waiting",
    backgroundColor: Colors.primary05,
  },
  [EXPENSE_REPORT_STATUS.CANCEL]: {
    title: "cancelled",
    backgroundColor: Colors.grayscale05,
  },
  [EXPENSE_REPORT_STATUS.REJECTED]: {
    title: "rejected",
    backgroundColor: Colors.alert05,
  },
  [EXPENSE_REPORT_STATUS.DRAFT]: {
    title: "draft",
    backgroundColor: Colors.grayscale05,
  },
};

const GeneralInfo: FC<GeneralInfoProps> = ({ isApproverView, expenseReport, expenseApprovalRules }) => {
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");

  const [
    FEATURE_EXP_EXPENSE_ALLOCATION,
    FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE,
    FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS,
  ] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_EXP_EXPENSE_ALLOCATION,
    FEATURE_FLAGS.FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE,
    FEATURE_FLAGS.FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS,
  ]);
  const companyTeam = expenseReport?.expenseReportsTeams?.[0]?.companyTeam;
  const renderPersonRejectedOrCancelled = () => {
    if (expenseReport?.status === EXPENSE_REPORT_STATUS.REJECTED) {
      const levelRejected = expenseReport?.expenseApprovalRules?.find(
        (item) => item?.status === APPROVAL_STATUS.REJECTED
      );
      const personReject = levelRejected?.expenseApprovalRuleEmployees?.find(
        (e) => e.status === APPROVAL_STATUS.REJECTED
      );
      return (
        <EmployeeTextView
          style={styles.textView}
          label={t("rejected_by_person")}
          fullname={personReject?.employee?.fullname}
          employeeId={personReject?.employeeId}
          avatar={personReject?.employee?.avatarUrl || personReject?.employee?.defaultAvatarUrl}
        />
      );
    } else if (expenseReport?.status === EXPENSE_REPORT_STATUS.CANCEL) {
      const personCancelled = expenseReport?.expenseApprovalLogs?.find((e) => e.status === APPROVAL_STATUS.CANCELLED);
      return (
        <EmployeeTextView
          style={styles.textView}
          label={t("cancelled_by_person")}
          fullname={personCancelled?.companyEmployee?.fullname}
          employeeId={personCancelled?.companyEmployee?.employeeId}
          avatar={personCancelled?.companyEmployee?.avatarUrl || personCancelled?.companyEmployee?.defaultAvatarUrl}
        />
      );
    }
    return null;
  };
  return (
    <View style={styles.container}>
      <View style={styles.statusRow}>
        <StatusDetail
          backgroundColor={STATUS[expenseReport?.status]?.backgroundColor}
          title={t(STATUS[expenseReport?.status]?.title)}
        />
        <ReportPaymentStatus value={expenseReport?.paymentStatus as EXPENSE_REPORT_PAYMENT_STATUS} />
        <View style={styles.quickLookApprovalFlowContainer}>
          <ApprovalFlowInline data={expenseApprovalRules} />
        </View>
      </View>

      <CollapsibleView
        rotate={-180}
        rightComponentStyle={styles.rightComponentStyle}
        rightComponent={
          <View style={styles.dropDownIcon}>
            <IconCustom name="arrow-drop-down" fill={Colors.primary50} />
          </View>
        }
        divider={<Line backgroundColor={Colors.grayscale0} />}
        summaryComponent={<SummaryMessage isApproverView={isApproverView} expenseReport={expenseReport} />}
        title={`[${expenseReport?.docSequence}] ${expenseReport?.title}`}
        titleStyle={Fonts.SentenceSubtitleXLarge}
        numberOfLineTitle={2}
      >
        <EmployeeTextView
          style={styles.textView}
          label={t("employee")}
          fullname={expenseReport?.companyEmployee?.fullname}
          employeeId={expenseReport?.companyEmployee?.employeeId}
          avatar={expenseReport?.companyEmployee?.avatarUrl || expenseReport?.companyEmployee?.defaultAvatarUrl}
        />
        {renderPersonRejectedOrCancelled()}
        {expenseReport?.status !== EXPENSE_REPORT_STATUS.DRAFT && (
          <TextView
            style={styles.textView}
            value={dayjs(expenseReport?.requestedAt).format(CONSTANTS.FORMAT_DAY)}
            label={t("requested_at")}
          />
        )}
        <TextView
          style={styles.textView}
          value={dayjs(expenseReport?.createdAt).format(CONSTANTS.FORMAT_DAY)}
          label={t("created_date")}
        />
        <TextView
          style={styles.textView}
          value={expenseReport?.paymentMethod ? PAYMENT_METHOD_TEXT[expenseReport?.paymentMethod] : ""}
          emptyText={t("no_payment_method")}
          label={t("payment_method")}
        />
        <TextView
          style={styles.textView}
          value={
            companyTeam?.companyTeamId
              ? `${companyTeam?.code}-${
                  i18n.language === LanguageStatus.EN && companyTeam?.nameEn ? companyTeam?.nameEn : companyTeam?.name
                }`
              : ""
          }
          label={t("unit")}
          emptyText={t("empty_unit")}
        />
        {FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE && (
          <TextView
            style={styles.textView}
            value={
              expenseReport?.expectedPaymentDate ? dayjs(expenseReport.expectedPaymentDate).format("DD/MM/YYYY") : ""
            }
            label={t("expected_payment_date")}
            emptyText={t("empty_expected_payment_date")}
          />
        )}
        {FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS && (
          <TextView
            style={styles.textView}
            value={
              expenseReport?.paymentStatus === EXPENSE_REPORT_PAYMENT_STATUS.PAID && expenseReport?.paidAt
                ? dayjs(expenseReport.paidAt).format("DD/MM/YYYY")
                : ""
            }
            label={t("paid_at")}
          />
        )}
        {FEATURE_EXP_EXPENSE_ALLOCATION && <AllocatedStatusInfo parent={expenseReport?.parent} />}
      </CollapsibleView>
    </View>
  );
};
export default memo(GeneralInfo);
const styles = StyleSheet.create({
  statusRow: { flexDirection: "row", alignItems: "center", marginBottom: 8, gap: 6 },
  container: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 16,
    paddingBottom: 8,
    backgroundColor: Colors.white,
  },
  textView: { marginBottom: 6 },
  dropDownIcon: { backgroundColor: Colors.primary0, borderRadius: 4 },
  quickLookApprovalFlowContainer: { flex: 1, alignItems: "flex-end" },
  rightComponentStyle: { marginTop: 5 },
});
