import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileOrgEmployeesQuery, MobileOrgEmployeesQueryVariables } from "types";

export const QUERY_EMPLOYEE_LIST = gql`
  query MobileOrgEmployees($companyId: UUID!, $keyword: String, $limit: NonNegativeInt, $offset: NonNegativeInt) {
    orgEmployees(where: { companyId: $companyId, keyword: $keyword }, limit: $limit, offset: $offset) {
      totalEntries
      data {
        avatarUrl
        defaultAvatarUrl
        email
        employeeId
        employeeCode
        fullname
      }
    }
  }
`;
const useEmployeesQuery = (variables: MobileOrgEmployeesQueryVariables) => {
  return useLazyQuery<MobileOrgEmployeesQuery, MobileOrgEmployeesQueryVariables>(QUERY_EMPLOYEE_LIST, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
};

export default useEmployeesQuery;
