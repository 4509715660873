// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Keyboard, Platform, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  AppText,
  BackHeader,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  Button,
  IconCustom,
  TextInput,
} from "components";

import NearByAddressScreen from "./NearByAddressScreen";
import History from "./History";
import { Colors, Fonts } from "theme";
import { WhiteSpace } from "@ant-design/react-native";
import { Formik } from "formik";

const SuggestionAddressScreen = ({ route, navigation }) => {
  const { t } = useTranslation("screens/SuggestionAddress/SuggestionAddressScreen");
  const [index, setIndex] = useState(0);
  const [location_name, setLocationName] = useState(route?.params?.location_name ?? "");
  const [location_address, setLocationAddress] = useState(route?.params?.location_address ?? "");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

  const routes = useMemo(
    () => [
      { key: "0", title: t("searched") },
      { key: "1", title: t("nearby") },
    ],
    [t]
  );
  const renderScene = ({ route: innerRoute }) => {
    switch (innerRoute.key) {
      case "0":
        return (
          <History
            route={route}
            navigation={navigation}
            extraItem={location_name ? { location_name, location_address } : null}
          />
        );
      case "1":
        return <NearByAddressScreen route={route} navigation={navigation} />;
      default:
        return null;
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("select_location")}
          headerRight={
            <TouchableOpacity
              onPress={() => {
                bottomSheetRef?.current?.present();
              }}
            >
              <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                {t("add_address")}
              </AppText>
            </TouchableOpacity>
          }
        />
      ),
    });
  }, [navigation, t]);

  const onSubmit = ({ location_name, location_address }) => {
    setLocationName(location_name);
    setLocationAddress(location_address);
    bottomSheetRef?.current?.close();
  };

  return (
    <View style={styles.container}>
      <History
        route={route}
        navigation={navigation}
        extraItem={location_name ? { location_name, location_address } : null}
      />
      <BottomSheetModalCustom title={t("add_address")} ref={bottomSheetRef} snapPoints={["80%"]}>
        <Formik initialValues={{ location_name, location_address }} enableReinitialize onSubmit={onSubmit}>
          {(props) => {
            const { values, dirty, handleChange, handleSubmit } = props;

            return (
              <TouchableWithoutFeedback onPress={() => Platform.OS !== "web" && Keyboard.dismiss()}>
                <View style={styles.bottomSheetContainer}>
                  <View style={styles.bottomSheetContent}>
                    <TextInput
                      label={t("location_name_placeholder")}
                      placeholder={t("location_name_placeholder")}
                      value={values.location_name}
                      onChangeText={handleChange("location_name")}
                      clearButtonMode="while-editing"
                    />
                    <WhiteSpace size="lg" />
                    <TextInput
                      multiline
                      label={t("location_address_placeholder")}
                      placeholder={t("location_address_placeholder")}
                      leftImage={<IconCustom name="location-on-outline" fill={Colors.grayscale60} />}
                      value={values.location_address}
                      onChangeText={handleChange("location_address")}
                      clearButtonMode="while-editing"
                    />
                    <WhiteSpace size="xl" />

                    <Button
                      disabled={!dirty || !values.location_name || !values.location_address}
                      onPress={() => {
                        handleSubmit({ location_name, location_address });
                      }}
                    >
                      <AppText
                        style={Fonts.BodyLarge}
                        color={
                          !dirty || !values.location_name || !values.location_address
                            ? Colors.grayscale60
                            : Colors.white
                        }
                      >
                        {t("add")}
                      </AppText>
                    </Button>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            );
          }}
        </Formik>
      </BottomSheetModalCustom>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  bottomSheetContainer: {
    alignItems: "center",
    flex: 1,
    position: "relative",
  },
  bottomSheetContent: {
    flex: 1,
    width: "90%",
    marginTop: 10,
  },
});

export default SuggestionAddressScreen;
