import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpApplicableApprovalFlowsQuery, MobileExpApplicableApprovalFlowsQueryVariables } from "types";

const QUERY = gql`
  query MobileExpApplicableApprovalFlows($input: ExpApplicableApprovalFlowsInput!) {
    expApplicableApprovalFlows(input: $input) {
      approvalType
      approvalFlows {
        title
        type
        conditions {
          fact
          operator
          value
        }
        approvalTemplates {
          name
          level
          approvers {
            employee {
              fullname
              avatarUrl
              defaultAvatarUrl
            }
            name
            email
            employeeId
            isLineManager
            isTeamManager
            teamType
          }
        }
      }
    }
  }
`;

const useExpApplicableApprovalFlowsQuery = (variables: MobileExpApplicableApprovalFlowsQueryVariables) => {
  return useQuery<MobileExpApplicableApprovalFlowsQuery, MobileExpApplicableApprovalFlowsQueryVariables>(QUERY, {
    variables,
  });
};

export default useExpApplicableApprovalFlowsQuery;
