import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M5.75 12.05c0 .293.02.584.06.874.04.29.107.577.203.86a.901.901 0 01-.02.61.745.745 0 01-.395.418.69.69 0 01-.586.023.65.65 0 01-.387-.438 6.157 6.157 0 01-.287-1.161 8.14 8.14 0 01-.088-1.186c0-2.162.753-4.003 2.258-5.522C8.013 5.01 9.844 4.251 12 4.251h.78l-1.322-1.324a.724.724 0 01-.213-.522.707.707 0 01.213-.531.717.717 0 01.527-.218c.206 0 .382.073.527.218l2.494 2.494c.18.18.27.391.27.632s-.09.452-.27.633L12.51 8.127a.724.724 0 01-.522.213.707.707 0 01-.531-.213.717.717 0 01-.218-.527c0-.206.073-.382.218-.526L12.78 5.75H12c-1.737 0-3.213.612-4.428 1.835C6.357 8.808 5.75 10.297 5.75 12.05zm12.5-.1c0-.292-.02-.583-.06-.874a4.58 4.58 0 00-.204-.858.902.902 0 01.02-.61.746.746 0 01.396-.42.68.68 0 01.58-.022.65.65 0 01.383.438c.134.377.231.764.293 1.16.061.398.092.793.092 1.187 0 2.162-.753 4.003-2.258 5.522C15.987 18.99 14.156 19.75 12 19.75h-.78l1.322 1.324c.139.138.21.312.213.522a.707.707 0 01-.213.531.717.717 0 01-.527.218.717.717 0 01-.527-.218l-2.494-2.494a.867.867 0 01-.27-.633c0-.24.09-.451.27-.632l2.495-2.494a.724.724 0 01.522-.213.707.707 0 01.531.213c.145.144.218.32.218.527a.718.718 0 01-.218.526L11.22 18.25H12c1.737 0 3.213-.611 4.428-1.834 1.215-1.223 1.822-2.712 1.822-4.466z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
