import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetModalCustomMethods,
  EmptyData,
  IconCustom,
  Line,
  SearchInput,
  SelectCustom,
  useDebounce,
  useSetRelativePosition,
} from "components";
import React, { FC, ReactElement, useEffect, useRef, useState, RefObject } from "react";
import { Platform, StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "constants/constants";
import { AirlineLocationEmptyIcon, SearchPageIcon } from "assets/images/svg/icons";
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import { MobileExpSearchAirportsQuery } from "types";
import { getTitleFromAirport, getTitleFromLocation } from "utils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useExpensePoliciesBookingAirports from "screens/ExpenseRequest/hooks/useExpensePoliciesBookingAirports";
import { useAuth } from "contexts/AuthContext";
import useExpSearchAirports from "screens/ExpenseRequest/hooks/useExpSearchAirports";

interface SelectAirportInputProps {
  label?: string;
  style?: ViewStyle;
  name: string;
  control?: Control<ExpenseRequestFormValues>;
  rules?: Record<string, unknown>;
  error?: Merge<FieldError, FieldErrorsImpl<ExpenseRequestFormValues>>;
  setValue?: UseFormSetValue<ExpenseRequestFormValues>;
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
  watch?: UseFormWatch<ExpenseRequestFormValues>;
  leftIcon?: ReactElement;
  value?: ExpenseRequestFormValues["flightBooking"]["from"];
  recentAirportKey?: string;
  setPosition?: (y: number) => void;
  handleLoadPoliciesPerdiem?: () => void;
  isSearchAllWithoutPolicy?: boolean;
  containerRef?: RefObject<any>;
}
type AirportItem = MobileExpSearchAirportsQuery["expSearchAirports"]["airports"][0];
const PAGE_SIZE = 10;
const SelectAirportInput: FC<SelectAirportInputProps> = ({
  name,
  control,
  style,
  label,
  setValue,
  error,
  rules,
  leftIcon,
  value,
  getValues,
  recentAirportKey,
  setPosition,
  handleLoadPoliciesPerdiem,
  isSearchAllWithoutPolicy,
  containerRef,
}) => {
  const childRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef, setPosition });
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
  const [keyword, setKeyword] = useState("");
  const {
    user: { employee_id },
  } = useAuth();
  const debounceSearchValue = useDebounce<string>(keyword);
  const [onFetchAirports, { loading, data }] = useExpSearchAirports();

  const [onFetchPoliciesBookingAirports, { loading: loadingBookingAirPorts, data: bookingAirports }] =
    useExpensePoliciesBookingAirports();
  const [recentAirports, setRecentAirports] = useState<AirportItem[]>([]);
  const textSearchRef = useRef<TextInput>();

  useEffect(() => {
    handleLoadRecentAirportsFromStorage();
  }, []);
  useEffect(() => {
    if (debounceSearchValue?.length >= 2) {
      if (isSearchAllWithoutPolicy) {
        onFetchAirports({
          variables: { where: { limit: PAGE_SIZE, keyword: debounceSearchValue, preferCountryCode: "vn" } },
        });
      } else {
        onFetchPoliciesBookingAirports({
          variables: {
            input: { employeeId: employee_id, limit: PAGE_SIZE, keyword: debounceSearchValue },
          },
        });
      }
    }
  }, [debounceSearchValue]);
  const handleClearSearch = () => {
    setKeyword("");
    textSearchRef?.current?.setNativeProps({ text: "" });
    textSearchRef?.current?.clear();
  };
  const onChangeSearch = (text) => {
    setKeyword(text);
  };
  const handleLoadRecentAirportsFromStorage = async () => {
    const data = await AsyncStorage.getItem(recentAirportKey);
    if (data) {
      setRecentAirports(JSON.parse(data) as AirportItem[]);
    }
  };
  const handleOpenModal = () => {
    bottomSheetRef?.current?.present();
    handleLoadRecentAirportsFromStorage();
    // auto focus open modal
    if (Platform.OS !== "web") {
      setTimeout(() => {
        textSearchRef?.current?.focus();
      }, 100);
    }
  };
  const handleSetRecentAirport = async (item: AirportItem) => {
    const recents = await AsyncStorage.getItem(recentAirportKey);
    let airports = [item];
    if (recents) {
      airports = airports.concat(JSON.parse(recents)?.filter((i) => i?.airportId !== item?.airportId));
    }
    AsyncStorage.setItem(recentAirportKey, JSON.stringify(airports.slice(0, 5)));
  };
  const renderItem = ({ item }) => {
    const isDisabled =
      name === "flightBooking.from"
        ? item?.code === getValues("flightBooking.to")?.code
        : item?.code === getValues("flightBooking.from")?.code;
    return (
      <TouchableOpacity
        disabled={isDisabled}
        onPress={() => {
          bottomSheetRef.current?.close();
          setValue(
            name as keyof ExpenseRequestFormValues,
            {
              code: item?.code,
              name: item?.name,
              nameEn: item?.nameEn,
              location: {
                code: item?.locationCode,
                name: item?.location?.name,
                nameEn: item?.location?.nameEn,
              },
            },
            { shouldValidate: true }
          );
          handleSetRecentAirport(item);
          handleLoadPoliciesPerdiem?.();
        }}
        style={[styles.airPotItem, isDisabled && { opacity: 0.4 }]}
      >
        <IconCustom name="location-on-outline" fill={Colors.grayscale60} />
        <View>
          <AppText style={Fonts.SentenceBodyMedium}>
            {getTitleFromLocation(item?.location)}
            {isDisabled ? ` (${name === "flightBooking.to" ? t("location_from") : t("location_to")})` : null}
          </AppText>
          <AppText style={Fonts.SentenceCaptionLarge} color={Colors.grayscale60}>
            {getTitleFromAirport({ code: item?.code, name: item?.name, nameEn: item?.nameEn })}
          </AppText>
        </View>
      </TouchableOpacity>
    );
  };

  const renderHeaderComponent = () => {
    if (debounceSearchValue || !recentAirports?.length) {
      return null;
    }
    return (
      <View style={styles.headerComponent}>
        <AppText style={Fonts.H300}>{t("recent")}</AppText>
      </View>
    );
  };
  const renderSearch = () => (
    <SearchInput
      ref={textSearchRef}
      style={styles.searchInput}
      placeholder={t("search_location_airline")}
      onChangeText={onChangeSearch}
      autoCapitalize="none"
      enablesReturnKeyAutomatically
      returnKeyType="search"
      //value={keyword}
      right={
        keyword ? (
          <TouchableOpacity onPress={handleClearSearch}>
            <IconCustom name="cancel" />
          </TouchableOpacity>
        ) : null
      }
    />
  );
  const title = getTitleFromLocation(value?.location);
  const subTitle = getTitleFromAirport({ code: value?.code, name: value?.name, nameEn: value?.nameEn });

  return (
    <View style={style} ref={childRef}>
      <SelectCustom
        name={name}
        control={control}
        label={label}
        onPress={handleOpenModal}
        rightIcon={<View />}
        leftIcon={leftIcon}
        error={error}
        rules={rules}
      >
        <View style={{ flex: 1 }}>
          <AppText style={Fonts.BodyLarge} color={Colors.grayscale80} numberOfLines={1}>
            {title}
          </AppText>
          <AppText style={Fonts.SentenceCaptionLarge} color={Colors.grayscale60} numberOfLines={1}>
            {subTitle}
          </AppText>
        </View>
      </SelectCustom>
      <BottomSheetFlatListModalCustom
        onDismiss={() => setKeyword("")}
        loading={loading || loadingBookingAirPorts}
        renderSearch={renderSearch}
        ref={bottomSheetRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={label}
        listProps={{
          data: debounceSearchValue
            ? (isSearchAllWithoutPolicy
                ? data?.expSearchAirports?.airports
                : bookingAirports?.expEmployeeBookingAirports?.airports) || []
            : recentAirports,
          renderItem,
          keyExtractor: (item) => item?.airportId,
          ItemSeparatorComponent: () => <Line backgroundColor={Colors.grayscale0} />,
          ListEmptyComponent: (
            <EmptyData
              title={debounceSearchValue ? t("search_airline_not_found") : t("search")}
              description={
                debounceSearchValue
                  ? t("search_airline_not_found_description")
                  : name === "flightBooking.from"
                  ? t("search_airline_description")
                  : t("search_airpot_to_description")
              }
              icon={debounceSearchValue ? <AirlineLocationEmptyIcon /> : <SearchPageIcon />}
            />
          ),
          ListHeaderComponent: renderHeaderComponent,
        }}
      />
    </View>
  );
};
export default SelectAirportInput;

const styles = StyleSheet.create({
  airPotItem: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
    paddingVertical: 11,
  },
  searchInput: { marginHorizontal: 20, marginBottom: 5 },
  headerComponent: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, marginTop: 6 },
});
