import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileQueryClaimedExpenseQuery, MobileQueryClaimedExpenseQueryVariables } from "types";

export const QUERY_EXPENSE_UNCLAIMED = gql`
  query MobileQueryClaimedExpense(
    $where: ExpClaimedExpensesWhereInput!
    $sortOption: ExpClaimedExpensesSortOptions
    $offset: NonNegativeInt!
  ) {
    expClaimedExpenses(where: $where, sortOption: $sortOption, limit: 10, offset: $offset) {
      total
      expenses {
        allocatedStatus
        docSequence
        expenseCardTransactions {
          cardTransaction {
            cardTransactionId
          }
        }
        note
        expenseId
        invoiceId
        provider
        category
        totalAmountWithVat
        expenseDate
        receivedInvoice
        status
        invoice {
          invoiceId
          invoiceValidations {
            invoiceValidationId
            isLegalSeller
            isCorrectCompany
            validationAggregation
          }
        }
        receivedInvoice
        status
        expenseCategory {
          expenseCategoryId
          title
        }
        expenseReport {
          expenseReportId
          status
          docSequence
        }
      }
    }
  }
`;

const useExpenseUnClaimedQuery = (variables: MobileQueryClaimedExpenseQueryVariables) => {
  return useQuery<MobileQueryClaimedExpenseQuery, MobileQueryClaimedExpenseQueryVariables>(QUERY_EXPENSE_UNCLAIMED, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !variables?.where?.employeeId,
  });
};

export default useExpenseUnClaimedQuery;
