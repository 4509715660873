import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { AppText, BackHeader, IconCustom, Line, VideoPlayerView, VideoPlayerViewMethods } from "components";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import {
  ChildrenActionVideo,
  ChildrenActionWalkthrough,
  useWalkthroughGuide,
} from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";
import Animated, { BounceIn } from "react-native-reanimated";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

export const ActionItem = ({
  item,
  index,
  handleClickItem,
  disabled = false,
  handlePlayVideo,
}: {
  item: ChildrenActionVideo | ChildrenActionWalkthrough;
  index: number;
  handleClickItem?: (item: ChildrenActionVideo | ChildrenActionWalkthrough) => void;
  handlePlayVideo?: (url) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation("app/screens/StartGuideScreen/ActionItemDetailScreen");

  const { startWalkthrough } = useWalkthroughGuide();
  const { title, type, isCompleted } = item;
  const [isCompletedFinal, setIsCompletedFinal] = useState(false);
  useEffect(() => {
    if (isCompleted) {
      setTimeout(() => {
        setIsCompletedFinal(true);
      }, 500);
    }
  }, [isCompleted]);

  const onPressItem = () => {
    handleClickItem?.(item);
    if (item.type === "walkthrough") {
      startWalkthrough(item.walkthroughName);
    } else {
      handlePlayVideo(item.videoUrl);
    }
  };
  return (
    <TouchableOpacity disabled={disabled} style={styles.actionItemContainer} onPress={onPressItem}>
      {isCompletedFinal ? (
        <Animated.View entering={BounceIn.duration(500)}>
          <IconCustom name="check-circle" width={24} height={24} />
        </Animated.View>
      ) : (
        <View style={styles.orderActionContainer}>
          <AppText style={Fonts.BodySmall}>{index + 1}</AppText>
        </View>
      )}
      <AppText style={styles.actionTitle}>{title}</AppText>
      {!disabled && (
        <View style={[styles.rightButtonContainer, type === "video" && { flexDirection: "column", gap: 0 }]}>
          {type === "video" && <IconCustom name="smart-display" />}
          <AppText style={Fonts.BodySmall} color={Colors.primary50}>
            {type === "video" ? t("watch_video") : isCompleted ? t("view") : t("start")}
          </AppText>
          {type !== "video" && <IconCustom name={isCompleted ? "refresh" : "arrow-right-alt"} />}
        </View>
      )}
    </TouchableOpacity>
  );
};
const ActionItemDetailScreen = ({ navigation }) => {
  const { t } = useTranslation("app/screens/StartGuideScreen/ActionItemDetailScreen");
  const videoPlayerRef = useRef<VideoPlayerViewMethods>();
  const { setCurrentChildrenActionCode, markCompletedGuideAction, currentAction } = useWalkthroughGuide();
  const actions = currentAction?.childrenActions ?? [];
  const title = currentAction?.title ?? "";
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("start_guide_screen_title")} description={title} />,
    } as any);
  }, [t]);

  const handleClickItem = (item) => {
    analyticService.logEvent({
      name: EVENT.WALKTHROUGH.TAP_ONBOARDING_COURSE_ACTION,
      properties: { course_name: title, action_name: item.title },
    });
    setCurrentChildrenActionCode(item?.type === "video" ? item?.code : item?.walkthroughName);
  };
  const onClosedVideo = () => {
    markCompletedGuideAction();
  };
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <AppText style={styles.actionDescription} color={Colors.grayscale70}>
          {t("start_action_description")}
        </AppText>
        {actions.map((item, index) => (
          <Fragment key={index}>
            <ActionItem
              index={index}
              item={item}
              handleClickItem={handleClickItem}
              handlePlayVideo={(url) => videoPlayerRef?.current?.playVideo(url)}
            />
            <Line />
          </Fragment>
        ))}
      </ScrollView>
      <VideoPlayerView ref={videoPlayerRef} onClosed={onClosedVideo} />
    </View>
  );
};
export default ActionItemDetailScreen;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingTop: 10,
  },
  actionItemContainer: {
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    gap: 8,
  },
  actionDescription: { ...Fonts.BodyMedium, marginBottom: 14, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  actionTitle: { ...Fonts.BodyMedium, flex: 1 },
  rightButtonContainer: { flexDirection: "row", gap: 6, alignItems: "center" },
  orderActionContainer: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.grayscale05,
  },
});
