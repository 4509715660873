import Svg, { ClipPath, Defs, G, Path } from "react-native-svg";

function SvgComponent({ height = 48, width = 48, color = "#0086FF" }) {
  return (
    <Svg width={width} height={height} fill="none" viewBox="0 0 48 48">
      <G clipPath="url(#prefix__clip0_5177_44023)" fill={color}>
        <Path d="M40.961 9.632c-.362 0-.722-.128-1.01-.392C35.537 5.216 29.873 3 24.001 3S12.465 5.216 8.051 9.242A1.501 1.501 0 016.03 7.024C11 2.494 17.381 0 24.001 0c6.62 0 13.002 2.494 17.972 7.024a1.501 1.501 0 01-1.012 2.608z" />
        <Path d="M43.5 19.434c-.481 0-.957-.232-1.245-.664C38.09 12.57 31.267 8.868 24 8.868c-7.266 0-14.09 3.702-18.254 9.902a1.5 1.5 0 11-2.492-1.672C7.977 10.068 15.733 5.87 24 5.87c8.268 0 16.024 4.198 20.746 11.23a1.499 1.499 0 01-1.246 2.334z" />
        <Path d="M19.264 48c-.386 0-.772-.148-1.066-.444-.328-.33-8.014-8.176-8.014-15.73 0-7.84 6.198-14.218 13.818-14.218 7.62 0 13.818 6.378 13.818 14.218a1.5 1.5 0 01-3 0c0-6.186-4.852-11.218-10.818-11.218-5.966 0-10.818 5.032-10.818 11.218 0 6.334 7.074 13.546 7.146 13.618A1.499 1.499 0 0119.264 48z" />
        <Path d="M28.739 48c-3.608 0-11.972-6.864-12.782-14.508-.286-2.702.574-5.466 2.298-7.394 1.5-1.676 3.526-2.606 5.704-2.618h.042c2.124 0 4.132.834 5.66 2.352 1.596 1.586 2.474 3.716 2.474 5.996v.978c0 2.41 1.876 4.37 4.184 4.37 2.308 0 4.186-1.96 4.186-4.37v-.342c0-9.34-6.766-17.11-15.404-17.686-4.502-.306-8.824 1.25-12.158 4.366-3.46 3.236-5.444 7.86-5.444 12.684 0 3.968 1.344 7.834 3.784 10.886a1.5 1.5 0 01-2.344 1.872c-2.862-3.582-4.44-8.114-4.44-12.76 0-5.652 2.332-11.074 6.394-14.874 3.946-3.692 9.064-5.53 14.406-5.17 10.208.682 18.206 9.766 18.206 20.68v.342c0 4.064-3.224 7.37-7.186 7.37-3.962 0-7.184-3.306-7.184-7.37v-.978a5.411 5.411 0 00-1.588-3.868 4.997 4.997 0 00-3.544-1.48h-.028c-1.69.008-2.828.886-3.484 1.62-1.17 1.306-1.75 3.206-1.552 5.078.662 6.236 7.828 11.826 9.8 11.826a1.499 1.499 0 110 2.998z" />
        <Path d="M34.305 45.067c-5.368 0-11.922-6.56-11.922-14.218a1.5 1.5 0 013 0c0 5.936 4.99 11.218 8.922 11.218a1.5 1.5 0 010 3z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_5177_44023">
          <Path fill="#fff" d="M0 0h48v48H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
