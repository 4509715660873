export const FEATURE_FLAGS = {
  FEATURE_TEAM_MGMT: "FEATURE_TEAM_MGMT",
  FEATURE_BUDGET_MGMT: "FEATURE_BUDGET_MGMT",
  FEATURE_PAPER_INVOICE: "FEATURE_PAPER_INVOICE",
  FEATURE_TRANSFER_EXPENSE_INVOICE: "FEATURE_TRANSFER_EXPENSE_INVOICE",
  FEATURE_DELETE_EXPENSE_INVOICE: "FEATURE_DELETE_EXPENSE_INVOICE",
  FEATURE_APP_WALKTHROUGH: "FEATURE_APP_WALKTHROUGH",
  FEATURE_OPTIONAL_NOTE: "FEATURE_OPTIONAL_NOTE",
  FEATURE_OPTIONAL_CATEGORY: "FEATURE_OPTIONAL_CATEGORY",
  FEATURE_QR_VAT: "FEATURE_QR_VAT",
  FEATURE_CARD: "FEATURE_CARD",
  FEATURE_EXPENSE_PAYMENT_INFO: "FEATURE_EXPENSE_PAYMENT_INFO",
  FEATURE_IN_APP_PROMOTIONS: "FEATURE_IN_APP_PROMOTIONS",
  FEATURE_VALIDATE_TAXPAYER_NAME_AND_ADDRESS: "FEATURE_VALIDATE_TAXPAYER_NAME_AND_ADDRESS",
  FEATURE_EXP_MOBILE_VALIDATE_INVOICE: "FEATURE_EXP_MOBILE_VALIDATE_INVOICE",
  FEATURE_EXP_REIMBURSABLE_EXPENSE: "FEATURE_EXP_REIMBURSABLE_EXPENSE",
  FEATURE_EXP_EXPENSE_ALLOCATION: "FEATURE_EXP_EXPENSE_ALLOCATION",
  FEATURE_EXP_CASH_ADVANCE: "FEATURE_EXP_CASH_ADVANCE",
  FEATURE_EXP_APP_THEME: "FEATURE_EXP_APP_THEME",
  FEATURE_EXP_GET_STARTED: "FEATURE_EXP_GET_STARTED",
  FEATURE_PROD_HINT: "FEATURE_PROD_HINT",
  RELEASE_SMP_9010: "RELEASE_SMP_9010",
  RELEASE_SMP_8722: "RELEASE_SMP_8722",
  FEATURE_EXP_INAPP_FEEDBACK: "FEATURE_EXP_INAPP_FEEDBACK",
  RELEASE_SMP_9191: "RELEASE_SMP_9191",
  FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE: "FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE",
  FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE: "FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE",
  FEATURE_PROCUREMENT: "FEATURE_PROCUREMENT",
  FEATURE_EXP_PARTIAL_APPROVAL: "FEATURE_EXP_PARTIAL_APPROVAL",
  FEATURE_EXP_EXPENSE_REQUEST_NOTE: "FEATURE_EXP_EXPENSE_REQUEST_NOTE",
  FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS: "FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS",
} as const;
