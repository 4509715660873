import * as LocalAuthentication from "expo-local-authentication";
import { useCallback, useEffect, useState } from "react";

const useSupportedAuthenticationTypes = () => {
  const [supportedAuthenticationTypes, setSupportedAuthenticationTypes] = useState<
    LocalAuthentication.AuthenticationType[]
  >([]);

  const checkSupportedAuthnTypes = useCallback(async () => {
    const results = await LocalAuthentication.supportedAuthenticationTypesAsync();
    setSupportedAuthenticationTypes(results);
  }, []);

  useEffect(() => {
    checkSupportedAuthnTypes();
  }, []);

  return {
    supportedAuthenticationTypes,
    isIrisAvailable: supportedAuthenticationTypes.includes(LocalAuthentication.AuthenticationType.IRIS),
    isFaceIdAvailable: supportedAuthenticationTypes.includes(LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION),
    isFingerprintAvailable: supportedAuthenticationTypes.includes(LocalAuthentication.AuthenticationType.FINGERPRINT),
    isMultipleSupport:
      [
        supportedAuthenticationTypes.includes(LocalAuthentication.AuthenticationType.IRIS),
        supportedAuthenticationTypes.includes(LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION),
        supportedAuthenticationTypes.includes(LocalAuthentication.AuthenticationType.FINGERPRINT),
      ].filter((item) => item)?.length > 1,
  };
};

export default useSupportedAuthenticationTypes;
