import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";

import { AppText } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";

interface StatusListProps {
  data?: { title: string; value: number | string }[];
  currentStatus?: string | number;
  handleChangeStatus(value: string | number): void;
}
const ReportStatusList: FC<StatusListProps> = ({ data, currentStatus, handleChangeStatus }) => {
  const { t } = useTranslation("app/screens/Report/ReportListScreen");

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      horizontal
      automaticallyAdjustContentInsets={true}
      contentContainerStyle={styles.listContainer}
    >
      {data?.map((item) => {
        const isActive = item?.value === currentStatus;

        return (
          <TouchableOpacity
            key={item?.value}
            style={[styles.statusButton, isActive && { borderColor: Colors.primary50 }]}
            onPress={() => handleChangeStatus?.(item?.value)}
          >
            <AppText style={Fonts.BodyMedium}>
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                t(item?.title)
              }
            </AppText>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );
};

export default ReportStatusList;

const styles = StyleSheet.create({
  statusButton: {
    paddingHorizontal: 16,
    marginRight: 8,
    height: 40,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    alignItems: "center",
    justifyContent: "center",
  },
  listContainer: {
    height: 52,
    alignItems: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
