import { useState, useMemo, FC } from "react";
import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";
import { Colors } from "theme";
interface DashedLineProps {
  style?: StyleProp<ViewStyle>;
  dashGap?: number;
  dashLength?: number;
  dashColor?: string;
  isVertical?: boolean;
}
const DashedLine: FC<DashedLineProps> = ({
  style,
  dashGap = 2,
  dashLength = 3,
  dashColor = Colors.grayscale40,
  isVertical = false,
}) => {
  const [lineLength, setLineLength] = useState(0);
  const numOfDashes = Math.ceil(lineLength / (dashGap + dashLength));
  const dashStyles = useMemo(
    () => ({
      width: 1.5,
      height: dashLength,
      marginRight: 0,
      marginBottom: dashGap,
      backgroundColor: dashColor,
    }),
    [dashColor, dashGap, dashLength]
  );
  if (!isVertical) {
    return (
      <View style={[styles.verticalContainer, style]}>
        <View style={[styles.verticalLine, { borderColor: dashColor }]} />
      </View>
    );
  }
  return (
    <View
      onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        setLineLength(height);
      }}
      style={style}
    >
      {[...Array(numOfDashes)].map((_, i) => {
        return <View key={i} style={dashStyles} />;
      })}
    </View>
  );
};
export default DashedLine;
const styles = StyleSheet.create({
  verticalContainer: { height: 1, overflow: "hidden" },
  verticalLine: { height: 2, borderWidth: 1, borderStyle: "dashed" },
});
