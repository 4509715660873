// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useRef } from "react";
import { Platform, ScrollView, StyleSheet, TextInput, View } from "react-native";
import { Toast } from "@ant-design/react-native";
import Constants from "expo-constants";
import { Formik } from "formik";
import toLower from "lodash/toLower";
import * as Yup from "yup";
import { BizziButton, PaperInput } from "app/components";
import { KeyboardAvoidingView } from "app/layouts";
import { request } from "app/utils/gql";
import { useAuth } from "app/contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import Config from "app/config";
import { Colors } from "constants/Colors";
import { DepartmentIcon, EmailIcon, PhoneAltIcon, Profile } from "assets/images/svg/icons";
import { HEADER_BAR_HEIGHT, SPACING_DEFAULT } from "constants/Layout";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Appbar } from "react-native-paper";
import Fonts from "constants/Fonts";
import { NewEmployeeInfoScreenNavigationProp, NewEmployeeInfoScreenRouteProp } from "navigation/type";
import { useTranslation } from "react-i18next";
import { AppText } from "components";

interface INewEmployeeInfoScreen {
  route: NewEmployeeInfoScreenRouteProp;
  navigation: NewEmployeeInfoScreenNavigationProp;
}

const NewEmployeeInfoScreen: React.FC<INewEmployeeInfoScreen> = ({ route, navigation }) => {
  const { email, password, ...company } = route?.params ?? {};
  const { register } = useAuth();
  const { t } = useTranslation("screens/Auth/NewEmployeeInfo");
  const { bottom, top } = useSafeAreaInsets();

  const yupSchema = Yup.object().shape({
    fullname: Yup.string().min(5, t("string_min")).required(t("required")),
    phone: Yup.string()
      .length(10, t("string_length"))
      .required(t("required"))
      .test("phone", t("phone_exists"), async (value) => {
        if (company.phone) {
          return true;
        }
        if (!value) {
          return false;
        }
        const { data, errors } = await request(
          `query MobileExpenseVerifyPhone($phone: String!){
            expenseVerifyPhone(phone: $phone){
              success
              message
            }
          }`,
          {
            phone: value,
          },
          null
        );
        if (errors) {
          console.error(errors);
        }
        return data.expenseVerifyPhone.success;
      }),
    department: Yup.string().min(5, t("string_min")).required(t("required")),
  });

  const employeeNameRef = useRef<TextInput>(null);
  const employeePhoneRef = useRef<TextInput>(null);
  const departmentRef = useRef<TextInput>(null);

  const initialValues: {
    fullname: string;
    phone: string;
    department: string;
    invoice_email: string;
  } = {
    ...company,
  };

  const onSubmit = useCallback(
    async (values, actions) => {
      const { invoice_email, ...rest } = values;
      actions.setSubmitting(true);
      Toast.loading();

      register({ ...company, ...rest, email, password })
        .then(() => {
          Toast.success(t("register_success"));
          requestAnimationFrame(() => {
            navigation.replace(SCREEN_NAME.HomeBottomTabNavigator);
            actions.setSubmitting(false);
          });
        })
        .catch((error) => {
          actions.setStatus({ general: t(`${toLower(error)}`, { defaultValue: t("unexpected_error") }) });
          actions.setSubmitting(false);
          Toast.removeAll();
        });
    },
    [email, password, navigation, register, company, t]
  );

  useEffect(() => {
    return () => {
      Toast.removeAll();
    };
  }, []);

  return (
    <KeyboardAvoidingView
      style={styles.mainContainer}
      enabled
      behavior={Constants?.platform?.ios ? "padding" : "height"}
      keyboardVerticalOffset={Constants?.platform?.ios ? 0 : 0}
    >
      <Appbar
        style={[
          styles.navbarStyle,
          { height: Platform.OS === "ios" ? HEADER_BAR_HEIGHT : HEADER_BAR_HEIGHT + top, paddingTop: top },
        ]}
      >
        <Appbar.Action
          icon="keyboard-backspace"
          onPress={() => {
            navigation.goBack();
          }}
        />
        <Appbar.Content
          title={<AppText style={styles.headerTitle}>{t("header_title")}</AppText>}
          style={{ alignItems: "center" }}
        />
        <Appbar.Action icon="keyboard-backspace" style={{ opacity: 0 }} />
      </Appbar>
      <Formik
        initialValues={initialValues}
        validateOnMount
        enableReinitialize
        validationSchema={yupSchema}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { values, errors, touched, isValid, isSubmitting, status, handleChange, handleBlur, handleSubmit } =
            props;
          values.invoice_email = `${values?.phone ?? ""}@${Config.EXPENSE_EMAIL_SUFFIX}`;
          return (
            <>
              <ScrollView keyboardDismissMode="on-drag" showsVerticalScrollIndicator={false}>
                <View style={styles.container}>
                  <View style={styles.header}>
                    <AppText style={styles.description}>{t("description")}</AppText>
                  </View>

                  <View style={styles.body}>
                    <PaperInput
                      ref={employeeNameRef}
                      label={t("employee_name")}
                      placeholder={t("employee_name_placeholder")}
                      value={values.fullname}
                      error={errors.fullname}
                      touched={touched.fullname}
                      onBlur={handleBlur("fullname")}
                      onChangeText={handleChange("fullname")}
                      onSubmitEditing={() => employeePhoneRef.current?.focus()}
                      leftImage={<Profile focused size={24} customBorder="transparent" color={Colors.Ink400} />}
                      returnKeyLabel={t("next")}
                      autoCorrect={false}
                    />
                    <PaperInput
                      ref={employeePhoneRef}
                      label={t("phone_number")}
                      placeholder={t("phone_number_placeholder")}
                      keyboardType="phone-pad"
                      textContentType="telephoneNumber"
                      value={values.phone}
                      error={errors.phone}
                      touched={touched.phone}
                      onBlur={handleBlur("phone")}
                      onChangeText={handleChange("phone")}
                      onSubmitEditing={() => departmentRef.current?.focus()}
                      leftImage={<PhoneAltIcon />}
                      returnKeyLabel={t("next")}
                    />
                    <PaperInput
                      ref={departmentRef}
                      label={t("department")}
                      placeholder={t("department_placeholder")}
                      value={values.department}
                      error={errors.department}
                      touched={touched.department}
                      onBlur={handleBlur("department")}
                      onChangeText={handleChange("department")}
                      onSubmitEditing={handleSubmit}
                      leftImage={<DepartmentIcon />}
                      returnKeyLabel={t("next")}
                    />

                    <PaperInput
                      editable={false}
                      label={t("email_to_receive_invoice")}
                      placeholder={t("email_to_receive_invoice_placeholder")}
                      value={values?.invoice_email ?? ""}
                      leftImage={<EmailIcon />}
                      tooltipText={t("invoice_email_automatically_generated")}
                      noUnderLine
                    />
                    <AppText style={styles.meta}>{t("describe_email_received_invoice")}</AppText>
                  </View>

                  <AppText style={styles.error}>{status?.general}</AppText>
                </View>
              </ScrollView>
              <View style={[styles.wrapperInput, { paddingBottom: SPACING_DEFAULT + bottom }]}>
                <BizziButton
                  disabled={!isValid || isSubmitting}
                  onPress={handleSubmit}
                  loading={isSubmitting}
                  title={t("button_finish")}
                />
              </View>
            </>
          );
        }}
      </Formik>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.white500,
  },
  container: {
    flex: 1,
  },
  header: {
    marginTop: SPACING_DEFAULT,
    paddingHorizontal: SPACING_DEFAULT,
  },
  headerTitle: {
    color: Colors.Ink500,
    fontFamily: Fonts.fontFamilyBold,
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "600",
  },
  body: {
    marginTop: SPACING_DEFAULT,

    backgroundColor: Colors.white500,
    paddingHorizontal: SPACING_DEFAULT,
  },
  wrapperInput: {
    paddingHorizontal: SPACING_DEFAULT,
    paddingTop: 10,
    backgroundColor: Colors.white500,
  },
  navbarStyle: { shadowOpacity: 0, elevation: 0, backgroundColor: "transparent" },
  description: {
    color: Colors.Ink300,
    fontSize: 14,
    lineHeight: 18,
    textAlign: "center",
  },
  meta: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: 16,
    color: Colors.Ink300,
  },
  error: {
    color: Colors.red500,
    fontSize: 12,
    lineHeight: 18,
    textAlign: "center",
    marginTop: 10,
  },
});

export default NewEmployeeInfoScreen;
