import { useAuth } from "contexts/AuthContext";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const SERVICE_PLAN_QUERY = gql`
  query MobileCompanyQuery($company_id: uuid!) {
    company: companies_by_pk(company_id: $company_id) {
      company_id
      group {
        group_id
        service_plan
        custom_fields
      }
    }
  }
`;

const useServicePlanInfo = () => {
  const { company } = useAuth();
  const results = useQuery(SERVICE_PLAN_QUERY, {
    variables: {
      company_id: company.company_id,
    },
    skip: !company?.company_id,
  });
  return {
    ...results,
    servicePlan: results?.data?.company?.group?.service_plan,
    waitingUpgrade: Boolean(results?.data?.company?.group?.custom_fields?.upgrade_request?.service_plan),
  };
};

export default useServicePlanInfo;
