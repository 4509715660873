import Svg, { Path } from "react-native-svg";

function SvgComponent({ size, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 48 48" fill="none" {...props}>
      <Path
        d="M42.782 21.913h-9.39c-.865 0-1.566-.7-1.566-1.565V7.826c0-4.315 3.51-7.826 7.826-7.826a7.819 7.819 0 017.826 7.826v9.391a4.7 4.7 0 01-4.696 4.696z"
        fill="#006DCC"
      />
      <Path
        d="M.522 7.826v38.608a1.564 1.564 0 002.543 1.223L6 45.308l2.935 2.349a1.566 1.566 0 001.956 0l2.935-2.349 2.935 2.349a1.566 1.566 0 001.956 0l2.935-2.349 2.935 2.349a1.566 1.566 0 001.956 0l2.935-2.349 2.935 2.349a1.566 1.566 0 002.543-1.223V4.696A4.7 4.7 0 0139.652 0H8.348C4.033 0 .522 3.51.522 7.826z"
        fill="#7FC3FF"
      />
      <Path
        d="M18.26 47.908c.162-.057.317-.14.457-.251l2.935-2.349 2.935 2.349a1.566 1.566 0 001.956 0l2.935-2.349 2.935 2.349a1.566 1.566 0 002.543-1.223V4.696A4.7 4.7 0 0139.652 0H18.26v47.908z"
        fill="#0089FF"
      />
      <Path
        d="M18.26 26.608c-.417 0-.81-.163-1.106-.459a1.555 1.555 0 01-.459-1.106c0-.418.163-.81.459-1.106.59-.591 1.622-.592 2.213 0 .296.296.459.689.459 1.106a1.565 1.565 0 003.13 0c0-1.255-.489-2.433-1.375-3.32a4.658 4.658 0 00-1.755-1.09v-1.329a1.565 1.565 0 00-3.13 0v1.328c-.651.23-1.253.589-1.755 1.09a4.665 4.665 0 00-1.376 3.321c0 1.255.49 2.434 1.376 3.32a4.663 4.663 0 003.32 1.376c.417 0 .81.163 1.106.459.296.295.459.688.459 1.106 0 .418-.163.81-.459 1.107-.59.59-1.622.591-2.213-.001a1.555 1.555 0 01-.459-1.106 1.565 1.565 0 00-3.13 0c0 1.255.49 2.434 1.376 3.32.502.502 1.104.86 1.754 1.09v1.33a1.565 1.565 0 003.13 0v-1.33a4.66 4.66 0 001.755-1.09 4.665 4.665 0 001.376-3.32c0-1.255-.49-2.434-1.376-3.32a4.662 4.662 0 00-3.32-1.376zM27.13 9.391H8.348a1.565 1.565 0 010-3.13H27.13a1.565 1.565 0 010 3.13z"
        fill="#E6F4FF"
      />
      <Path d="M27.13 6.26h-8.87v3.131h8.87a1.565 1.565 0 000-3.13z" fill="#B3DCFF" />
      <Path d="M27.13 15.652H8.348a1.565 1.565 0 010-3.13H27.13a1.565 1.565 0 010 3.13z" fill="#E6F4FF" />
      <Path
        d="M27.13 12.522h-8.87v3.13h8.87a1.565 1.565 0 000-3.13zM19.367 23.937c.296.296.459.688.459 1.106a1.565 1.565 0 003.13 0c0-1.255-.489-2.434-1.375-3.32a4.656 4.656 0 00-1.755-1.09v-1.329c0-.864-.701-1.565-1.565-1.565v5.754c.405 0 .81.148 1.106.444zM19.826 37.043v-1.328a4.66 4.66 0 001.755-1.09 4.665 4.665 0 001.375-3.321c0-1.255-.489-2.434-1.375-3.32a4.662 4.662 0 00-3.32-1.376v3.13c.418 0 .81.164 1.106.46.296.295.459.688.459 1.106 0 .418-.163.81-.459 1.107a1.562 1.562 0 01-1.106.443v5.754c.864 0 1.565-.7 1.565-1.565z"
        fill="#B3DCFF"
      />
    </Svg>
  );
}

export default SvgComponent;
