import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, color = "#0F172A" }: SvgProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <Path
        d="M2 7.828A2.828 2.828 0 014.828 5c.75 0 1.47-.298 2-.828L7.5 3.5l.07-.07A5 5 0 0111.022 2H12.977A5 5 0 0116.5 3.5l.672.672c.53.53 1.25.828 2 .828A2.828 2.828 0 0122 7.828v5.822c0 3.131 0 4.697-.676 5.861a5 5 0 01-1.813 1.813C18.347 22 16.781 22 13.65 22h-3.3c-3.131 0-4.697 0-5.861-.676a5 5 0 01-1.813-1.813C2 18.347 2 16.781 2 13.65V7.828z"
        stroke={color}
        strokeWidth={1.5}
      />
      <Path
        d="M10.5 7h3M12 18c2.203 0 4-1.797 4-4 0-2.203-1.797-4-4-4-2.203 0-4 1.797-4 4 0 2.203 1.797 4 4 4z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
