import {
  AppText,
  Badge,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  CopyContainer,
  CurrencyText,
  IconCustom,
} from "components";
import { CONSTANTS } from "constants/constants";
import { forwardRef, memo, ReactElement, useImperativeHandle, useMemo, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import TicketOverview from "./TicketOverview";
import { BaggageType, BookingInformation, FlightTicket } from "screens/FlightBooking/types";
import { useTranslation } from "react-i18next";
import { formatMoney } from "utils";

export type TicketDetailModalData = {
  ticket: FlightTicket & { bookingInfo?: BookingInformation["listBooking"][0]["fares"][0]["bookingInfo"] };
  reservation?: {
    status: string;
    bookingCode: string;
  };
};

export type TicketDetailModalApi = {
  showTicket: (data: TicketDetailModalData, options?: { icon?: ReactElement; footer?: ReactElement }) => void;
  close: () => void;
};

type TicketDetailModalProps = {
  renderButton?: (ticket: FlightTicket) => ReactElement;
};

const TicketDetailModal = forwardRef<TicketDetailModalApi, TicketDetailModalProps>((props, ref) => {
  const { t } = useTranslation("app/screens/FlightBooking/components/FlightBookingComponent");
  useImperativeHandle(ref, () => ({
    showTicket: (data, options) => {
      bottomSheetRef.current?.present();
      setData(data);
      footerRef.current = options?.footer;
      iconRef.current = options?.icon;
    },
    close: () => bottomSheetRef.current?.close(),
  }));

  const [paddingBottom, setPaddingBottom] = useState<number>(0);
  const [data, setData] = useState<TicketDetailModalData>(null);
  const iconRef = useRef<ReactElement | null>(null);
  const footerRef = useRef<ReactElement | null>(null);

  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();

  const renderFooter = () => {
    return (
      <View onLayout={(e) => setPaddingBottom(e.nativeEvent.layout.height)}>
        <View style={styles.totalAmountContainer}>
          <AppText style={[Fonts.BodyMedium, styles.allSpaceLeft]} color={Colors.grayscale100}>
            {t("total_amount")}
          </AppText>
          <CurrencyText style={Fonts.NumericN400} color={Colors.success50}>
            {data?.ticket?.totalPrice}
          </CurrencyText>
        </View>
        {footerRef.current ? <View style={styles.actionButtonsContainer}>{footerRef.current}</View> : null}
      </View>
    );
  };

  const flight = data?.ticket?.flights?.[0];
  const segment = flight?.segments?.[0];
  const baggageInfo = useMemo(() => {
    const baggage = {
      handBaggage: { title: t("hand_baggage"), weight: "0kg" },
      allowanceBaggage: { title: t("bought_baggage_allowance"), weight: "0kg" },
      boughtBaggage: null,
    };
    if (segment?.handBaggage?.weight) {
      baggage.handBaggage.weight = `${segment?.handBaggage?.weight}${segment?.handBaggage?.weightUnit?.toLowerCase()}`;
    }
    if (segment?.allowanceBaggage?.weight) {
      baggage.allowanceBaggage.weight = `${
        segment?.allowanceBaggage?.weight
      }${segment?.allowanceBaggage?.weightUnit?.toLowerCase()}`;
    }
    const boughBaggage = data?.ticket?.bookingInfo?.baggage?.find((item) => item?.type === BaggageType.BoughtBaggage);
    if (boughBaggage) {
      delete baggage.allowanceBaggage;
      const weightLabel = `${boughBaggage?.weight}${boughBaggage?.weightUnit}`;
      baggage.boughtBaggage = {
        title: t("bought_baggage", {
          weightLabel: weightLabel.toLocaleLowerCase(),
          amount: `${formatMoney(boughBaggage?.price)} ₫`,
        }),
        highlight: true,
      };
    }
    return baggage;
  }, [segment]);
  const ticketPolicies = [
    {
      title: t("allowance_refund"),
      content: flight?.noRefund ? t("un_supported") : t("allowance_refund_details"),
    },
    {
      title: t("allowance_change_airport"),
      content: segment?.changeAirport ? t("allowance_change_airport_details") : t("un_supported"),
    },
    {
      title: t("allowance_change_station"),
      content: segment?.changeStation ? t("allowance_change_station_details") : t("un_supported"),
    },
    {
      title: t("allowance_day_change"),
      content: segment?.dayChange ? t("allowance_day_change_details") : t("un_supported"),
    },
  ];

  return (
    <BottomSheetScrollViewModalCustom
      snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
      ref={bottomSheetRef}
      title={t("ticket_detail")}
      wrapperByScrollView={true}
      renderFooter={renderFooter}
    >
      {data?.ticket?.bookingInfo && (
        <View style={styles.reservationContainer}>
          <View style={styles.reservationCodeContainer}>
            <AppText style={Fonts.H200}>{t("reservation_code")}</AppText>
            {
              {
                issued: <Badge text={<AppText style={Fonts.BodySmall}>{t("paid")}</AppText>} color={Colors.success0} />,
                hold: (
                  <Badge text={<AppText style={Fonts.BodySmall}>{t("not_paid")}</AppText>} color={Colors.warning0} />
                ),
                expired: (
                  <Badge text={<AppText style={Fonts.BodySmall}>{t("expired")}</AppText>} color={Colors.alert0} />
                ),
              }[data?.ticket?.bookingInfo?.status]
            }
          </View>
          <CopyContainer
            value={data?.reservation?.bookingCode}
            icon={<IconCustom fill={Colors.primary50} name="content-copy" />}
            message={t("booking_code_copied_to_clipboard")}
          >
            <AppText color={Colors.primary50} style={Fonts.SentenceSubtitleLarge}>
              {data?.ticket?.bookingInfo?.bookingCode}
            </AppText>
          </CopyContainer>
        </View>
      )}
      <TicketOverview
        containerStyle={styles.ticketOverview}
        icon={iconRef.current}
        data={data?.ticket}
        baggageInfo={data?.ticket?.bookingInfo?.baggage}
      />

      <View style={{ paddingBottom }}>
        <View style={styles.policySection}>
          <View style={styles.policyItem}>
            <AppText style={Fonts.H200} color={Colors.grayscale100}>
              {t("allowance_baggage")}
            </AppText>
            {Object.keys(baggageInfo)?.map((key) => {
              const item = baggageInfo[key];
              if (!item) {
                return null;
              }
              return (
                <View style={styles.baggageItem}>
                  <View style={styles.dotIcon} />
                  <AppText style={Fonts.BodySmall} color={item.highlight ? Colors.primary50 : Colors.grayscale100}>
                    {`${item?.title}`}
                    {Boolean(item?.weight) && `: ${item?.weight}`}
                  </AppText>
                </View>
              );
            })}
          </View>
          {ticketPolicies.map((policy) => (
            <View style={styles.policyItem}>
              <AppText style={Fonts.H200}>{policy.title}</AppText>
              <AppText style={Fonts.BodySmall}>{policy.content}</AppText>
            </View>
          ))}
        </View>
      </View>
    </BottomSheetScrollViewModalCustom>
  );
});

export default memo(TicketDetailModal);

const styles = StyleSheet.create({
  policySection: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    gap: 16,
  },
  policyItem: {
    gap: 8,
  },
  totalAmountContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  actionButtonsContainer: {
    marginTop: 8,
  },
  allSpaceLeft: {
    flex: 1,
  },
  ticketOverview: {
    padding: 16,
    backgroundColor: Colors.grayscale0,
  },
  reservationCodeContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  reservationContainer: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
    gap: 8,
  },
  baggageItem: { flexDirection: "row", alignItems: "center", gap: 4 },
  dotIcon: { width: 4, height: 4, backgroundColor: Colors.grayscale100, borderRadius: 4 / 2 },
});
