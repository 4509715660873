import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.001 20.94a1.78 1.78 0 01-.584-.1 1.543 1.543 0 01-.53-.313 36.753 36.753 0 01-2.518-2.555 22.895 22.895 0 01-2.04-2.628 14.03 14.03 0 01-1.371-2.604c-.337-.86-.505-1.69-.505-2.492 0-2.308.747-4.176 2.24-5.605C8.185 3.214 9.955 2.5 12 2.5s3.816.714 5.309 2.143c1.493 1.43 2.24 3.297 2.24 5.605 0 .801-.17 1.63-.506 2.488a14.401 14.401 0 01-1.366 2.603 21.96 21.96 0 01-2.035 2.628 37.402 37.402 0 01-2.519 2.55 1.56 1.56 0 01-.53.318c-.197.07-.395.105-.593.105zm.002-9.075c.498 0 .923-.177 1.276-.531.353-.354.53-.78.53-1.278s-.177-.923-.532-1.276A1.745 1.745 0 0012 8.25c-.498 0-.924.177-1.277.531-.353.355-.53.78-.53 1.278s.178.923.532 1.277c.354.353.78.53 1.278.53z"
          fill="#E11934"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
