import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 67, height = 66, color = "#37B24D" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 67 66" fill="none">
      <Path
        d="M33.5 62.563C17.192 62.563 3.937 49.306 3.937 33c0-16.308 13.255-29.563 29.563-29.563 16.307 0 29.563 13.255 29.563 29.563 0 16.307-13.256 29.563-29.563 29.563zm0-55C19.475 7.563 8.062 18.974 8.062 33S19.476 58.438 33.5 58.438c14.025 0 25.438-11.413 25.438-25.438 0-14.025-11.413-25.438-25.438-25.438z"
        fill={color}
      />
      <Path
        d="M29.596 42.843a2.06 2.06 0 01-1.458-.605l-7.782-7.782a2.075 2.075 0 010-2.915 2.075 2.075 0 012.915 0l6.325 6.325L43.73 23.73a2.075 2.075 0 012.915 0 2.075 2.075 0 010 2.915L31.053 42.238a2.06 2.06 0 01-1.457.605z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
