import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, EmptyData, ImageCustom, useWindowDimensions } from "components";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useFeatureManagement } from "contexts/FeatureManagementContext";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import dayjs from "dayjs";
import Asyncstorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { EmptyNotification } from "assets/images/svg/icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import relativeTime from "dayjs/plugin/relativeTime";
import * as WebBrowser from "expo-web-browser";
import { fromNow, measureLayoutSync } from "utils";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

dayjs.extend(relativeTime);
const PromotionListTabView = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation("app/screens/Notification/NotificationScreen");
  const containerRef = useRef<View>();
  const bannerRefs = useRef<ReactNode[]>([]);
  const [viewingBanners, setViewingBanners] = useState<boolean[]>([]);

  const [bannerPositions, setBannerPositions] = useState<number[]>([]);
  const [readPromotionIds, setReadPromotionIds] = useState<string[]>([]);
  const viewedPromotionIds = useRef<string[]>([]);
  const { features } = useFeatureManagement();
  const { user } = useAuth();

  const campaignList = useMemo(() => {
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_IN_APP_PROMOTIONS] ?? {};
    if (!enabled || !customFields?.promotions?.length) {
      return [];
    }
    const now = dayjs();
    return customFields?.promotions?.filter((promotion) => {
      const { roles, from_date: fromDate } = promotion;
      return roles.includes(user?.group?.role) && now.isAfter(fromDate);
    });
  }, [features]);

  /*-- log event for each banner is viewing --*/
  useEffect(() => {
    viewingBanners.forEach((item, index) => {
      const campaign = campaignList?.[index];
      if (item && !viewedPromotionIds.current.includes(campaign?.id)) {
        viewedPromotionIds.current.push(campaign?.id);
        analyticService.logEvent({
          name: EVENT.NOTIFICATION.VIEW_PROMOTION_BANNER,
          properties: {
            campaign_title: campaign?.title,
            campaign_id: campaign?.id,
            promotion_url: campaign?.promotion_url,
          },
        });
      }
    });
  }, [viewingBanners]);

  /*-- log viewed banner event when entering the screen --*/
  useEffect(() => {
    if (bannerPositions?.[0]) {
      handleCheckIsViewedBanner();
    }
  }, [bannerPositions]);

  /*-- save position for each banner --*/
  useEffect(() => {
    if (campaignList?.length) {
      setTimeout(async () => {
        const positions = [];
        for (const ref of bannerRefs.current) {
          if (ref) {
            const { y, height } = await measureLayoutSync(ref, containerRef.current);
            positions.push(y + height);
          }
        }
        setBannerPositions(positions);
      }, 250);
    }
  }, [campaignList?.length]);

  useEffect(() => {
    Asyncstorage.getItem(Keys.READ_PROMOTION_IDS).then((values) => {
      if (values) {
        setReadPromotionIds(JSON.parse(values));
      }
    });
  }, []);
  const handleNavigateDetail = (item) => async () => {
    WebBrowser.openBrowserAsync(item?.promotion_url);
    if (!readPromotionIds?.includes(item?.id)) {
      setReadPromotionIds((prevState) => prevState.concat([item?.id]));
      await Asyncstorage.setItem(
        Keys.READ_PROMOTION_IDS,
        JSON.stringify(readPromotionIds?.length > 0 ? readPromotionIds.concat([item?.id]) : [item?.id])
      );
    }
    analyticService.logEvent({
      name: EVENT.NOTIFICATION.TAP_PROMOTION_BANNER,
      properties: {
        campaign_title: item?.title,
        campaign_id: item?.id,
        promotion_url: item?.promotion_url,
      },
    });
  };
  const THUMBNAIL_WIDTH = width - CONSTANTS.COMMON.CONTAINER_PADDING * 2;
  const renderEmpty = () => <EmptyData icon={<EmptyNotification />} title={t("empty_promotion")} />;
  const handleSetBannerRef = (index) => (ref) => {
    if (bannerRefs.current[index]) {
      bannerRefs.current[index] = ref;
    } else {
      bannerRefs.current.push(ref);
    }
  };
  const renderItem = ({ item, index }) => {
    const isRead = readPromotionIds?.includes(item?.id);

    return (
      <TouchableOpacity
        ref={handleSetBannerRef(index)}
        style={styles.cardContainer}
        activeOpacity={0.9}
        onPress={handleNavigateDetail(item)}
      >
        <ImageCustom
          source={{ uri: item?.thumbnail?.["1x"] }}
          style={[
            styles.thumbnail,
            {
              width: THUMBNAIL_WIDTH,
              height: THUMBNAIL_WIDTH / 1.93,
            },
          ]}
        />
        <View style={styles.cardContent}>
          <View style={styles.fromDate}>
            {!isRead && <View style={styles.dotItem} />}
            <AppText style={Fonts.Caption} color={Colors.grayscale80}>
              {fromNow(item?.from_date)}
            </AppText>
          </View>
          <AppText style={Fonts.H300}>{item?.title}</AppText>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
            {item?.description}
          </AppText>
        </View>
      </TouchableOpacity>
    );
  };
  const [containerHeight, setContainerHeight] = useState(0);
  const handleCheckIsViewedBanner = () => {
    const scrollOffset = 0;
    const componentVisibilities = bannerPositions.map((position) => {
      return position !== null && position < scrollOffset + containerHeight && position > scrollOffset;
    });
    setViewingBanners(componentVisibilities);
  };
  const handleScroll = (event) => {
    const scrollViewHeight = event.nativeEvent.layoutMeasurement.height;
    const scrollOffset = event.nativeEvent.contentOffset.y;
    const componentVisibilities = bannerPositions.map((position) => {
      return position !== null && position < scrollOffset + scrollViewHeight && position > scrollOffset;
    });
    setViewingBanners(componentVisibilities);
  };
  return (
    <View
      style={styles.container}
      ref={containerRef}
      onLayout={(event) => setContainerHeight(event.nativeEvent.layout.height)}
    >
      <FlatList
        onScroll={handleScroll}
        scrollEventThrottle={16}
        contentContainerStyle={styles.contentContainer}
        data={campaignList}
        renderItem={renderItem}
        keyExtractor={(item) => item?.id}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={renderEmpty}
      />
    </View>
  );
};
export default PromotionListTabView;
const styles = StyleSheet.create({
  container: { flex: 1 },
  contentContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingTop: 25, paddingBottom: 30 },
  cardContainer: {
    borderRadius: 16,
    marginBottom: 20,
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 4,
    elevation: 5,
  },
  thumbnail: {
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
  },
  cardContent: { padding: 15 },
  dotItem: { width: 8, height: 8, borderRadius: 4, backgroundColor: Colors.primary50 },
  fromDate: { flexDirection: "row", alignItems: "center", gap: 5, marginBottom: 2 },
});
