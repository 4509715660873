import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none">
      <Path
        d="M21 21.25H4A3.251 3.251 0 01.75 18V1C.75.866.866.75 1 .75s.25.116.25.25v17A2.753 2.753 0 004 20.75h17c.134 0 .25.116.25.25s-.116.25-.25.25z"
        stroke="#FFF"
      />
      <Path
        d="M13.93 11.81h.014a1.722 1.722 0 001.266-.615s0 0 0 0l4.587-5.357s0 0 0 0a.256.256 0 01.357-.028.256.256 0 01.025.357h-.001l-4.587 5.357s0 0 0 0c-.41.475-1 .761-1.618.786a2.296 2.296 0 01-1.679-.653s0 0 0 0l-.939-.949-.002-.002a1.751 1.751 0 00-1.297-.516 1.722 1.722 0 00-1.266.615s0 0 0 0l-4.58 5.35a.298.298 0 01-.21.095.262.262 0 01-.163-.058.256.256 0 01-.026-.358l.001-.002 4.587-5.356s0 0 0 0c.41-.475.999-.76 1.617-.786a2.296 2.296 0 011.68.654l.95.95.354-.354-.354.354a1.79 1.79 0 001.284.516z"
        fill="#FFF"
        stroke="#FFF"
      />
    </Svg>
  );
}

export default SvgComponent;
