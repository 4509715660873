import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.75 11.696V7.75a.726.726 0 00-.216-.534A.726.726 0 0012 7a.725.725 0 00-.535.216.726.726 0 00-.215.534v4.177a.9.9 0 00.271.648L14.946 16c.139.138.313.21.522.212A.707.707 0 0016 16a.718.718 0 00.217-.527.718.718 0 00-.217-.527l-3.25-3.25zm-.748 9.804a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749zM12 20c2.217 0 4.104-.78 5.663-2.337C19.22 16.104 20 14.217 20 12s-.78-4.104-2.337-5.663C16.104 4.78 14.217 4 12 4s-4.104.78-5.663 2.337C4.78 7.896 4 9.783 4 12s.78 4.104 2.337 5.663C7.896 19.22 9.783 20 12 20z"
          fill="#0F172A"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
