import React, { FC, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import dayjs from "dayjs";
import uniqBy from "lodash/uniqBy";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";

import { AppText, CurrencyText, IconCustom, InvoiceStatus, TouchableOpacityCustom } from "components";
import SCREEN_NAME from "navigation/ScreenName";
import { Flex } from "@ant-design/react-native";
import { PlusOutlinedIcon } from "assets/images/svg/icons";
import isEmpty from "lodash/isEmpty";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { COMPANIES } from "constants/Companies";
import { useNavigation } from "@react-navigation/native";
import { EVENT } from "constants/Tracking";

interface InvoiceItemProps {
  item: ExpInvoice;
  onPress?: () => void;
  onRefreshData?: () => void;
}

interface InvoiceExpenseStatusProps {
  t: any;
  styles?: any;
  isCreatedExpense: boolean;
}

const InvoiceExpenseStatus: FC<InvoiceExpenseStatusProps> = ({ t, styles, isCreatedExpense }) => {
  const title = isCreatedExpense ? t("option_1") : t("option_2");
  return (
    <>
      <Flex style={[styles.invoiceStatus, { backgroundColor: isCreatedExpense ? Colors.success0 : Colors.warning0 }]}>
        <View
          style={[
            styles.invoiceStatusIcon,
            { backgroundColor: isCreatedExpense ? Colors.success50 : Colors.warning50 },
          ]}
        />
        <AppText style={[Fonts.Caption, styles.textTitle]}>{title}</AppText>
      </Flex>
    </>
  );
};

const InvoiceItem: FC<InvoiceItemProps> = ({ onRefreshData, item, onPress }) => {
  const { t } = useTranslation("app/screens/Inbox/InboxScreen");
  const navigation = useNavigation<any>();
  const { company } = useAuth();
  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );
  const { totalAmountWithVat, sellerLegalName, invoiceId, invoiceItems, receivedAt, expense, invoiceNumber } = item;
  const name = uniqBy(invoiceItems, "item_name")
    .slice(0, 2)
    .map(({ itemName }) => itemName)
    .join(", ");

  const isCreatedExpense = !isEmpty(expense?.expenseId);
  return (
    <>
      <TouchableOpacityCustom eventName={EVENT.OTHER.VIEW_INVOICE_DETAIL} onPress={onPress} style={styles.container}>
        <View style={styles.statusContainer}>
          <InvoiceStatus isValid={item?.invoiceValidations?.[0]?.isExpenseInvoiceValid} />
          <CurrencyText style={Fonts.NumericN200}>{totalAmountWithVat}</CurrencyText>
        </View>
        <View style={styles.invoiceNumberContainer}>
          <View>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale100}>
              {t("invoice_number")}
              <AppText color={Colors.success100}>{invoiceNumber}</AppText>
              <AppText color={Colors.grayscale100}>{` - ${dayjs(receivedAt).format(CONSTANTS.FORMAT_DAY)}`}</AppText>
            </AppText>
          </View>
        </View>
        <View>
          <AppText style={[Fonts.Caption, styles.legalName]} color={Colors.grayscale100} numberOfLines={1}>
            {sellerLegalName}
          </AppText>
          <AppText style={Fonts.Caption} color={Colors.grayscale80} numberOfLines={1}>
            {name}
          </AppText>
        </View>
        <View style={{ flexDirection: "row", marginTop: 8, justifyContent: "space-between" }}>
          <InvoiceExpenseStatus t={t} styles={styles} isCreatedExpense={isCreatedExpense} />
          {!isTLNExpenseCompany && !isCreatedExpense && (
            <TouchableOpacityCustom
              eventName={EVENT.EXPENSE.TAP_CREATE}
              onPress={() =>
                navigation.navigate(SCREEN_NAME.NewExpenseScreen, {
                  invoiceId: invoiceId,
                  autofill: true,
                  onRefreshData,
                })
              }
            >
              <View style={styles.rowCenter}>
                <PlusOutlinedIcon />
                <AppText style={[Fonts.BodySmall, styles.textMarginLeft]} color={Colors.primary50}>
                  {t("add_expense")}
                </AppText>
              </View>
            </TouchableOpacityCustom>
          )}
          {!isTLNExpenseCompany && isCreatedExpense && (
            <TouchableOpacityCustom
              style={styles.viewExpenseButton}
              onPress={() =>
                navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, {
                  expenseId: expense?.expenseId,
                })
              }
            >
              <AppText style={[Fonts.BodySmall, styles.textMarginLeft]} color={Colors.primary50}>
                {t("view_expense")}
              </AppText>
              <IconCustom name="arrow-right-alt" />
            </TouchableOpacityCustom>
          )}
        </View>
      </TouchableOpacityCustom>
      <View style={styles.divider} />
    </>
  );
};

const styles = StyleSheet.create({
  container: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingBottom: 16, paddingTop: 10 },
  invoiceStatus: {
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 20,
  },
  invoiceStatusIcon: {
    width: 12,
    height: 12,
    borderRadius: 12,
  },
  textMarginLeft: {
    marginLeft: 3,
  },
  textTitle: { marginLeft: 5, fontSize: 12 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  statusContainer: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 6 },
  legalName: { marginBottom: 4 },
  invoiceNumberContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    justifyContent: "space-between",
  },
  rowCenter: { flexDirection: "row", alignItems: "center" },
  viewExpenseButton: {
    flexDirection: "row",
    gap: 4,
  },
});

export default InvoiceItem;
