import * as React from "react";
import Svg, { Path, Circle, Mask, Rect } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={273} height={123} fill="none" {...props}>
    <Path
      fill="#F0F6FF"
      fillRule="evenodd"
      d="M90.1 12.872C90.1 5.763 95.861 0 102.97 0h157.156C267.236 0 273 5.763 273 12.872c0 7.11-5.763 12.872-12.873 12.872H201.96a12.849 12.849 0 0 1 4.781 10.012c0 7.053-5.673 12.78-12.704 12.871h36.945c7.109 0 12.872 5.763 12.872 12.872 0 7.11-5.763 12.872-12.872 12.872H77.405c7.035-.086 12.712-5.815 12.712-12.871 0-7.109-5.763-12.872-12.872-12.872H36.66c-7.085-.028-12.82-5.78-12.82-12.872 0-7.11 5.762-12.872 12.871-12.872H94.88a12.847 12.847 0 0 1-4.78-10.012ZM0 87.245c0-7.11 5.763-12.872 12.872-12.872h183.664c.633 0 1.255.046 1.864.134h-7.059c-6.309 0-11.424 5.115-11.424 11.424 0 6.31 5.115 11.425 11.424 11.425h22.702c6.349.792 11.262 6.208 11.262 12.772 0 7.109-5.763 12.872-12.872 12.872H73.832c-7.11 0-12.873-5.763-12.873-12.872 0-4.043 1.865-7.651 4.78-10.011H12.872C5.763 100.117 0 94.354 0 87.245Z"
      clipRule="evenodd"
    />
    <Circle cx={138} cy={52} r={32} fill="#F0F6FF" />
    <Circle cx={138} cy={52} r={27} fill="#fff" />
    <Path
      stroke="#1F71F4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M170.622 51.771c0 18.156-14.698 32.854-32.854 32.854s-32.854-14.698-32.854-32.854 14.698-32.854 32.854-32.854"
    />
    <Mask id="a" fill="#fff">
      <Path d="M156.7 23.832c.553-.856 1.699-1.106 2.523-.505a32.85 32.85 0 0 1 12.133 17.165c.291.977-.327 1.974-1.318 2.21-.992.236-1.982-.38-2.28-1.355a29.16 29.16 0 0 0-10.542-14.914c-.82-.606-1.069-1.744-.516-2.6Z" />
    </Mask>
    <Path
      stroke="#1F71F4"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M156.7 23.832c.553-.856 1.699-1.106 2.523-.505a32.85 32.85 0 0 1 12.133 17.165c.291.977-.327 1.974-1.318 2.21-.992.236-1.982-.38-2.28-1.355a29.16 29.16 0 0 0-10.542-14.914c-.82-.606-1.069-1.744-.516-2.6Z"
      mask="url(#a)"
    />
    <Path stroke="#1F71F4" strokeLinecap="round" strokeWidth={3} d="m162.5 77 4.5 4.5" />
    <Rect
      width={7.917}
      height={19.968}
      x={165.449}
      y={85.572}
      stroke="#1F71F4"
      strokeWidth={3}
      rx={3.958}
      transform="rotate(-45 165.449 85.572)"
    />
    <Circle cx={138} cy={52} r={26.5} stroke="#75A4FE" strokeWidth={3} opacity={0.3} />
    <Path
      fill="#F0F6FF"
      fillRule="evenodd"
      d="M151.453 72.261a24.618 24.618 0 0 1-7.201-2.789c-11.789-6.843-15.843-21.974-9.055-33.795a24.5 24.5 0 0 1 7.614-8.18c-10.159-2.18-20.975 2.29-26.437 11.802-6.788 11.821-2.733 26.952 9.056 33.795 8.406 4.88 18.476 4.229 26.023-.833Z"
      clipRule="evenodd"
    />
    <Path
      stroke="#75A4FE"
      strokeLinecap="round"
      strokeWidth={3}
      d="M91 90h15M80 82h26M67 82h6.5M192 29.5h-11M204 21h-25.5M216 21h-6.5"
    />
  </Svg>
);
export default SvgComponent;
