/* eslint-disable react-native/no-inline-styles */
import React, { FC, memo, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionSheetIOS, Linking, Platform, StyleSheet, Text, View } from "react-native";
import { Image } from "expo-image";
import i18n from "i18next";

import Animated, {
  cancelAnimation,
  Extrapolation,
  interpolate,
  ReduceMotion,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";

import { BizziBotSticker01 } from "assets/images";
import { CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { Colors, Fonts } from "theme";
import { ArrowMessageIcon, SwitchCompanyHome } from "assets/images/svg/icons";
import {
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  CopilotStepCustom,
  IconCustom,
  ImageCustom,
  Line,
  SelectActionPicker,
  TouchableOpacityCustom,
  useWindowDimensions,
} from "components";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import dayjs from "dayjs";
import { useFeatureManagement } from "contexts/FeatureManagementContext";
import { StackNavigation } from "navigation/type";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";
import LottieView from "lottie-react-native";
import { useInAppFeedback } from "contexts/InAppFeedbackContext";
import * as WebBrowser from "expo-web-browser";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import LanguageStatus from "constants/LanguageStatus";
import { COMPANIES } from "constants/Companies";

const AnimatedImage = Animated.createAnimatedComponent(Image);

interface HeaderWelcomeProps {
  translationY: Animated.SharedValue<number>;
  onSwitchCompany?: () => Promise<void>;
}

const formatFullName = (fullName: string, numberOfWords: number) => {
  if (!fullName) {
    return "";
  }

  const words = fullName?.trim()?.split(" ");
  return words.slice(-numberOfWords).join(" ");
};
const BIZZI_BOT_WIDTH = 85;
enum BIZZI_BOT_ACTION {
  OVERVIEW = "overview",
  START_GUIDE = "start_guide",
  FAQ = "faq",
  CONTACT = "contact",
  FEED_BACK = "feedback",
}

const BIZZI_BOT_ACTIONS = (t) => [
  { label: t("start_guide"), value: BIZZI_BOT_ACTION.START_GUIDE, icon: <IconCustom name="menu-book" /> },
  { label: t("faq"), value: BIZZI_BOT_ACTION.FAQ, icon: <IconCustom name="help" /> },
  { label: t("contact"), value: BIZZI_BOT_ACTION.CONTACT, icon: <IconCustom name="call" /> },
  { label: t("feedback"), value: BIZZI_BOT_ACTION.FEED_BACK, icon: <IconCustom name="feedback" /> },
];

const BIZZI_BOT_ACTIONS_TLN_COMPANY = (t) => [
  { label: t("faq"), value: BIZZI_BOT_ACTION.FAQ, icon: <IconCustom name="help" /> },
  { label: t("contact"), value: BIZZI_BOT_ACTION.CONTACT, icon: <IconCustom name="call" /> },
  { label: t("feedback"), value: BIZZI_BOT_ACTION.FEED_BACK, icon: <IconCustom name="feedback" /> },
];
const MessageBoxAnimation = () => {
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const opacity = useSharedValue(1);
  const scale = useSharedValue(1);

  useEffect(() => {
    opacity.value = withRepeat(
      withSequence(
        withDelay(2000, withTiming(1, { duration: 250, reduceMotion: ReduceMotion.Never })),
        withDelay(5000, withTiming(0, { duration: 250, reduceMotion: ReduceMotion.Never }))
      ),
      -1,
      true
    );
    scale.value = withRepeat(
      withSequence(
        withDelay(2000, withTiming(1, { duration: 250, reduceMotion: ReduceMotion.Never })),
        withDelay(5000, withTiming(0, { duration: 250, reduceMotion: ReduceMotion.Never }))
      ),
      -1,
      true
    );
    return () => {
      cancelAnimation(opacity);
      cancelAnimation(scale);
    };
  }, []);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: scale.value,
        },
      ],
      opacity: opacity.value,
    };
  });
  return (
    <Animated.View
      style={[
        {
          flexDirection: "row",
          position: "absolute",
          backgroundColor: Colors.white,
          borderRadius: 4,
          paddingVertical: 4,
          paddingHorizontal: 8,
        },
        Platform.OS === "web" && {
          width: i18n.language === LanguageStatus.EN ? 166 : 155,
        },
        animatedStyle,
      ]}
    >
      <AppText style={Fonts.BodyXSmall}>{t("bizzi_bot_support_title")}</AppText>
      <View style={{ position: "absolute", bottom: -5, right: 6 }}>
        <ArrowMessageIcon />
      </View>
    </Animated.View>
  );
};
const BizziBotAnimation = () => {
  return (
    <>
      <View style={{ position: "absolute", left: -120, top: -18 }}>
        <MessageBoxAnimation />
      </View>
      {Platform.OS !== "web" ? (
        <LottieView
          speed={1}
          autoPlay={true}
          style={styles.bizziBotImage}
          source={require("assets/lottie/bizzi-bot.json")}
        />
      ) : (
        <ImageCustom style={styles.bizziBotImage} source={require("assets/images/BizziBotWelcomeAnimation.gif")} />
      )}
    </>
  );
};

const phoneSupport = "02836222368";
const emailSupport = "cskh@bizzi.vn";
const HeaderWelcome: FC<HeaderWelcomeProps> = ({ translationY, onSwitchCompany }) => {
  const navigation = useNavigation<StackNavigation>();
  const { user, company } = useAuth();
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { width, isTablet } = useWindowDimensions();
  const { top } = useSafeAreaInsets();
  const hasMultipleCompanies = useMemo(() => user?.employees?.length > 1, [user?.employees]);
  const { features } = useFeatureManagement();
  const { openFeedbackModal } = useInAppFeedback();
  const selectContactRef = useRef<BottomSheetModalCustomMethods>();
  const selectActionRef = useRef<BottomSheetModalCustomMethods>();
  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );
  const callSupportPhoneNumber = () => {
    analyticService.logEvent({ name: EVENT.OTHER.CALL_PHONE_CONTACT });
    let phoneNumber;
    if (Platform.OS !== "android") {
      phoneNumber = `telprompt:${phoneSupport}`;
    } else {
      phoneNumber = `tel:${phoneSupport}`;
    }
    Linking.canOpenURL(phoneNumber)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(phoneNumber);
        }
      })
      .catch((err) => console.log(err));
  };
  const openMailClient = () => {
    analyticService.logEvent({ name: EVENT.OTHER.OPEN_MAIL_CONTACT });
    Linking.canOpenURL(`mailto:${emailSupport}`)
      .then((supported) => {
        if (!supported) {
          console.log("Cant handle url");
        } else {
          return Linking.openURL(`mailto:${emailSupport}`).catch((error) => {
            console.log(error);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { banner, botIcon } = useMemo(() => {
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_EXP_APP_THEME] ?? {};
    if (!enabled) {
      return {};
    }
    const now = dayjs();
    const themeActive = customFields.config.themes.find((theme) => {
      const { from_date: fromDate, to_date: toDate } = theme;
      return now.isAfter(fromDate) && (!toDate || now.isBefore(toDate));
    });
    if (!themeActive) {
      return {};
    }

    return {
      banner: themeActive.banner_url,
      botIcon: themeActive.bot_url["1x"],
    };
  }, [features]);
  const onClickAction = (action) => {
    switch (action) {
      case BIZZI_BOT_ACTION.START_GUIDE: {
        navigation.navigate(SCREEN_NAME.StartGuideScreen as any);
        break;
      }
      case BIZZI_BOT_ACTION.FEED_BACK: {
        openFeedbackModal();
        break;
      }
      case BIZZI_BOT_ACTION.FAQ: {
        WebBrowser.openBrowserAsync(
          "https://bizzi.zendesk.com/hc/vi/categories/4490625723289-Bizzi-Expense-Vai-tr%C3%B2-Nh%C3%A2n-vi%C3%AAn"
        );
        break;
      }
      case BIZZI_BOT_ACTION.OVERVIEW: {
        break;
      }
      case BIZZI_BOT_ACTION.CONTACT: {
        if (Platform.OS !== "ios") {
          selectContactRef?.current?.present();
          return;
        }
        ActionSheetIOS.showActionSheetWithOptions(
          {
            options: [t("cancel"), t("phone_number"), "Email"],
            cancelButtonIndex: 0,
          },
          (buttonIndex) => {
            if (buttonIndex === 0) {
              // cancel action
            } else if (buttonIndex === 1) {
              callSupportPhoneNumber();
            } else if (buttonIndex === 2) {
              openMailClient();
            }
          }
        );
        break;
      }
    }
  };
  const bizziBotAnimatedStyle = useAnimatedStyle(() => {
    const scale = interpolate(translationY.value, [0, 350], [1, 0], Extrapolation.CLAMP);
    const translateY = interpolate(translationY.value, [0, 400], [0, 100], Extrapolation.CLAMP);
    const opacity = interpolate(translationY.value, [0, 100], [1, 0], Extrapolation.CLAMP);

    return {
      transform: [
        {
          scale: scale,
        },
        { translateY: translateY },
      ],
      opacity,
    };
  });

  const textAnimatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(translationY.value, [0, 400], [0, 100], Extrapolation.CLAMP);
    const opacity = interpolate(translationY.value, [0, 50, 100], [1, 1, 0], Extrapolation.CLAMP);
    return {
      transform: [{ translateY: translateY }],
      opacity,
    };
  });

  const imageBackgroundStyle = useAnimatedStyle(() => {
    const scale = interpolate(translationY.value, [0, -100], [1, 2.2], Extrapolation.CLAMP);
    //const translateY = interpolate(translationY.value, [0, -100], [0, -100], Extrapolation.CLAMP);
    return {
      transform: [
        {
          scale: scale,
        },
      ],
    };
  });
  return (
    <View
      style={[
        styles.container,
        {
          height: CONSTANTS.HOME.HEADER_WELCOME_HEIGHT + top,
          width: Platform.OS === "web" ? "auto" : width,
        },
      ]}
    >
      {banner?.["1x"] || banner?.["2x"] ? (
        <AnimatedImage
          contentFit="cover"
          style={[
            styles.backgroundImage,
            {
              width: width,
            },
            imageBackgroundStyle,
          ]}
          source={{ uri: isTablet ? banner["2x"] || banner["1x"] : banner["1x"] }}
        />
      ) : (
        <Animated.View
          style={[
            styles.backgroundImage,
            {
              width: width,
            },
            imageBackgroundStyle,
          ]}
        />
      )}

      <Animated.View style={[textAnimatedStyle, styles.headerWelcome, { maxWidth: width - BIZZI_BOT_WIDTH - 40 }]}>
        <CopilotStepCustom
          title={"walkthrough_overview_step1_title"}
          text={"walkthrough_overview_step1_description"}
          order={CONSTANTS.WALK_THROUGH.OVERVIEW.Step1}
          image={BizziBotSticker01}
          walkthroughName={CONSTANTS.WALK_THROUGH.OVERVIEW.NAME}
        >
          <>
            <View style={styles.textWelcome}>
              <Text style={[Fonts.BodyLarge, styles.welcome]} numberOfLines={1}>
                {t("welcome")}
              </Text>
              <Text style={[Fonts.H400, styles.fullName]} numberOfLines={1}>
                &nbsp;{`${formatFullName(user?.fullname, 1)}`}
              </Text>
            </View>
            <View
              style={[
                styles.wrapperCompany,
                onSwitchCompany && hasMultipleCompanies ? { marginRight: 32 } : { marginBottom: 10 },
              ]}
            >
              <Text style={[Fonts.BodySmall, styles.company]} ellipsizeMode="head" numberOfLines={1}>
                {user?.company?.name}
              </Text>
              {onSwitchCompany && hasMultipleCompanies && (
                <TouchableOpacityCustom style={styles.switchCompanyButton} onPress={onSwitchCompany}>
                  <SwitchCompanyHome />
                </TouchableOpacityCustom>
              )}
            </View>
          </>
        </CopilotStepCustom>
      </Animated.View>
      <Animated.View style={[styles.bizziBotContainer, bizziBotAnimatedStyle]}>
        <CopilotStepCustom
          title={"walkthrough_welcome_step1_title"}
          text={"walkthrough_welcome_step1_description"}
          order={CONSTANTS.WALK_THROUGH.FIRST_LAUNCH.Step1}
          image={BizziBotSticker01}
          walkthroughName={CONSTANTS.WALK_THROUGH.FIRST_LAUNCH.NAME}
          singleStep={true}
          confirmText={"understood"}
          onConfirm={() => selectActionRef?.current?.present()}
        >
          <SelectActionPicker
            ref={selectActionRef}
            eventName={EVENT.WALKTHROUGH.TAP_BIZZI_BOT}
            title={t("bizzi_bot_action_title")}
            icon={botIcon ? <Image style={styles.bizziBotImage} source={{ uri: botIcon }} /> : <BizziBotAnimation />}
            options={isTLNExpenseCompany ? BIZZI_BOT_ACTIONS_TLN_COMPANY(t) : BIZZI_BOT_ACTIONS(t)}
            onSelect={onClickAction}
          />
        </CopilotStepCustom>
      </Animated.View>
      <BottomSheetScrollViewModalCustom wrapperByScrollView={false} ref={selectContactRef}>
        <View style={{ paddingHorizontal: 20, paddingTop: 15 }}>
          <TouchableOpacityCustom
            style={styles.contactItem}
            onPress={() => {
              selectContactRef?.current?.close();
              if (Platform.OS !== "web") {
                callSupportPhoneNumber();
              }
            }}
          >
            <AppText style={Fonts.BodyMedium}>
              {Platform.OS !== "web" ? t("phone_number") : `${t("phone_number")}: ${phoneSupport}`}
            </AppText>
          </TouchableOpacityCustom>
          <Line />
          <TouchableOpacityCustom
            style={styles.contactItem}
            onPress={() => {
              selectContactRef?.current?.close();
              if (Platform.OS !== "web") {
                openMailClient();
              }
            }}
          >
            <AppText style={Fonts.BodyMedium}>{Platform.OS !== "web" ? "Email" : `Email: ${emailSupport}`}</AppText>
          </TouchableOpacityCustom>
        </View>
      </BottomSheetScrollViewModalCustom>
    </View>
  );
};

export default memo(HeaderWelcome);
const styles = StyleSheet.create({
  container: {
    //height: CONSTANTS.HOME.HEADER_WELCOME_HEIGHT + 150,
    //flexDirection: "row",
    justifyContent: "space-between",
  },
  welcome: { color: Colors.white },
  headerWelcome: {
    position: "absolute",
    paddingLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    bottom: 100,
  },
  fullName: { color: Colors.white },
  company: { color: Colors.white },
  wrapperCompany: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  textWelcome: { paddingTop: 10, flexDirection: "row", alignItems: "baseline" },
  bizziBotContainer: { paddingTop: 5, position: "absolute", bottom: 53, right: 20 },
  bizziBotImage: { width: 72, height: 98 },
  switchCompanyButton: {},
  backgroundImage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "100%",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: Colors.brand100,
  },
  contactItem: { paddingVertical: 13 },
});
