import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ size }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 0c-.825 0-1.5.675-1.5 1.5v21c0 .825.675 1.5 1.5 1.5h15c.825 0 1.5-.675 1.5-1.5V6l-6-6H6z"
        fill="#E2E5E7"
      />
      <Path d="M18 6h4.5l-6-6v4.5c0 .825.675 1.5 1.5 1.5z" fill="#B0B7BD" />
      <Path d="M22.5 10.5L18 6h4.5v4.5z" fill="#CAD1D8" />
      <Path
        d="M19.5 19.5c0 .413-.337.75-.75.75H2.25a.752.752 0 01-.75-.75V12c0-.412.337-.75.75-.75h16.5c.413 0 .75.338.75.75v7.5z"
        fill="#50BEE8"
      />
      <Path
        d="M6.656 14.21c0-.504.792-.504.792 0v2.368c0 .942-.45 1.512-1.487 1.512-.51 0-.936-.138-1.308-.617-.306-.366.27-.894.582-.51.252.311.522.383.786.371.335-.011.63-.162.636-.756V14.21h-.001zM8.5 14.207c0-.198.156-.414.408-.414h1.385c.78 0 1.482.522 1.482 1.523 0 .947-.702 1.475-1.482 1.475H9.292v.793c0 .264-.168.413-.384.413a.405.405 0 01-.408-.413v-3.377zm.792.341v1.494h1.001c.402 0 .72-.355.72-.727 0-.419-.318-.767-.72-.767H9.292zM16.058 17.537c-.426.354-.948.504-1.475.504-1.26 0-2.153-.72-2.153-2.147 0-1.21.942-2.152 2.206-2.152.474 0 .995.161 1.367.527.366.36-.162.894-.52.576-.223-.216-.535-.378-.847-.378-.725 0-1.426.582-1.426 1.427 0 .888.587 1.428 1.373 1.428.365 0 .677-.109.9-.27v-.737h-.9c-.534 0-.48-.732 0-.732h1.2c.221 0 .424.168.424.354v1.277c0 .108-.047.208-.149.323z"
        fill="#fff"
      />
      <Path d="M18.75 20.25H4.5V21h14.25c.413 0 .75-.337.75-.75v-.75c0 .413-.337.75-.75.75z" fill="#CAD1D8" />
    </Svg>
  );
}

export default SvgComponent;
