import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={37} height={20} viewBox="0 0 37 20" fill="none">
      <Rect width={36.6667} height={20} rx={10} fill="#1F71F4" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.477 4.477 0 10 0h16.667c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10z"
        fill="#1F71F4"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.334 10a8.333 8.333 0 1116.667 0 8.333 8.333 0 01-16.667 0z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
