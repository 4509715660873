import { FC, useMemo } from "react";
import { AppText, CurrencyText, DashedLine, Section } from "components";
import { StyleSheet, View } from "react-native";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";

interface AmountInfoProps {
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
}
const AmountInfo: FC<AmountInfoProps> = ({ expenseRequest }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const totalAmountExpense = useMemo(() => {
    return expenseRequest?.expenses?.reduce((currentValue, expense) => {
      return currentValue + expense.totalAmountWithVat;
    }, 0);
  }, [expenseRequest?.expenses]);
  const remainingExpense = expenseRequest?.totalAmount - totalAmountExpense;
  const totalForeignAmount = expenseRequest?.exchangeRate
    ? expenseRequest?.totalAmount / expenseRequest?.exchangeRate
    : 0;
  return (
    <Section title={t("request_overview")}>
      <View style={styles.rowItem}>
        <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
          {t("spending_plan")}
        </AppText>
        <CurrencyText style={styles.valueText} foreignAmountStyle={Fonts.BodyLarge} foreignAmount={totalForeignAmount}>
          {expenseRequest?.totalAmount}
        </CurrencyText>
      </View>
      {Boolean(expenseRequest?.cashAdvances?.[0]?.amount) && (
        <>
          <View style={[styles.rowItem, { paddingLeft: 12 }]}>
            <AppText style={Fonts.BodyMedium} color={Colors.grayscale60}>
              {t("advance")}
            </AppText>
            <CurrencyText
              style={[styles.valueText, Fonts.BodyMedium]}
              color={Colors.grayscale60}
              foreignAmount={expenseRequest?.cashAdvances?.[0]?.foreignAmount}
            >
              {expenseRequest?.cashAdvances?.[0]?.amount}
            </CurrencyText>
          </View>
        </>
      )}
      <DashedLine />
      <View style={styles.rowItem}>
        <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
          {t("expense")}
        </AppText>
        <CurrencyText style={styles.valueText}>{totalAmountExpense}</CurrencyText>
      </View>
      <DashedLine />
      <View style={styles.rowItem}>
        <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
          {remainingExpense < 0 ? t("over_spending_plan") : t("remain")}
        </AppText>
        <CurrencyText style={styles.valueText} color={remainingExpense < 0 ? Colors.alert50 : Colors.grayscale100}>
          {Math.abs(remainingExpense)}
        </CurrencyText>
      </View>
    </Section>
  );
};
export default AmountInfo;
const styles = StyleSheet.create({
  rowItem: { flexDirection: "row", alignItems: "center", paddingVertical: 8 },
  valueText: { ...Fonts.NumericN300, flex: 1, textAlign: "right" },
});
