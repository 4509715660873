import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent({ size = 64 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <G clipPath="url(#clip0_6418_7254)">
        <Path
          d="M5.493 0C4.831 0 4.29.542 4.29 1.204v16.86c0 .661.542 1.203 1.204 1.203h12.042c.663 0 1.204-.542 1.204-1.204V4.817L13.924 0h-8.43z"
          fill="#E2E5E7"
        />
        <Path d="M15.126 4.817h3.613L13.922 0v3.613c0 .662.542 1.204 1.204 1.204z" fill="#B0B7BD" />
        <Path d="M18.738 8.429l-3.613-3.613h3.613V8.43z" fill="#CAD1D8" />
        <Path
          d="M16.33 15.654a.604.604 0 01-.603.602H2.481a.604.604 0 01-.602-.602v-6.02c0-.332.27-.603.602-.603h13.246c.331 0 .602.271.602.602v6.021z"
          fill="#F15642"
        />
        <Path
          d="M4.504 11.407c0-.16.125-.333.327-.333h1.112c.626 0 1.19.42 1.19 1.223 0 .76-.564 1.184-1.19 1.184h-.804v.636c0 .212-.135.332-.308.332a.324.324 0 01-.327-.332v-2.71zm.635.274v1.199h.804a.594.594 0 00.578-.584c0-.336-.255-.615-.578-.615h-.804zM8.078 14.449c-.158 0-.332-.087-.332-.298v-2.735c0-.173.173-.299.332-.299h1.103c2.2 0 2.152 3.332.043 3.332H8.078zm.304-2.744v2.157h.799c1.3 0 1.358-2.157 0-2.157h-.8zM12.11 11.744v.765h1.228c.173 0 .347.174.347.342 0 .159-.174.289-.347.289H12.11v1.01c0 .17-.12.299-.288.299-.212 0-.341-.13-.341-.298v-2.735c0-.173.13-.299.34-.299h1.691c.212 0 .337.126.337.299a.333.333 0 01-.337.327H12.11v.001z"
          fill="#fff"
        />
        <Path
          d="M15.729 16.256H4.289v.602h11.44c.331 0 .602-.27.602-.602v-.602c0 .332-.27.602-.602.602z"
          fill="#CAD1D8"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_6418_7254">
          <Path fill="#fff" transform="translate(.676)" d="M0 0H19.2673V19.2673H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
