import Colors from "constants/Colors";
import Fonts from "constants/Fonts";
import { SPACING_DEFAULT } from "constants/Layout";

import { StyleSheet } from "react-native";
import { CONSTANTS } from "constants/constants";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.whiteBackground,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  title: {
    marginTop: SPACING_DEFAULT * 2,
    fontFamily: Fonts.fontFamilyBold,
    fontSize: 18,
    textAlign: "center",
    paddingHorizontal: SPACING_DEFAULT,
    fontWeight: "600",
  },
  description: {
    color: Colors.grey6,
    textAlign: "center",
    fontSize: 14,
    paddingHorizontal: SPACING_DEFAULT,
  },
  textCenter: { textAlign: "center" },
  image: { width: 260, height: 266 },
});
