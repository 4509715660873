import { gql, useMutation } from "@apollo/client";

const EXPENSE_ADD_EXPENSE_TO_REQUEST = gql`
  mutation MobileAddExpenseToRequest($object: ExpenseAddExpenseToRequestInput!) {
    expenseAddExpenseToRequest(object: $object) {
      message
    }
  }
`;
const useAddRequestToExpense = () => {
  const [expenseAddExpenseToRequest, { data, loading }] = useMutation(EXPENSE_ADD_EXPENSE_TO_REQUEST);
  return { expenseAddExpenseToRequest };
};

export default useAddRequestToExpense;
