import { ImageCustom, TouchableOpacityCustom, useWindowDimensions } from "components";
import { ForwardedRef, forwardRef, memo, useMemo, useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useFeatureManagement } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import Carousel from "react-native-reanimated-carousel";
import PaginationDot from "screens/Card/CardListScreen/components/PaginationDot";
import { useSharedValue } from "react-native-reanimated";
import { useAuth } from "contexts/AuthContext";
import dayjs from "dayjs";
import Asyncstorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import * as WebBrowser from "expo-web-browser";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { twoDimensionalDistance } from "utils";

const modeConfig = { parallaxScrollingScale: 1, parallaxScrollingOffset: 32 };

type Promotion = {
  title: string;
  id: string;
  promotion_url: string;
};

type CampaignListProps = {
  onSnapToItem?: (item: Promotion) => void;
};

const CampaignList = forwardRef(({ onSnapToItem }: CampaignListProps, ref: ForwardedRef<View>) => {
  const { features } = useFeatureManagement();
  const { user } = useAuth();
  const campaignList = useMemo(() => {
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_IN_APP_PROMOTIONS] ?? {};
    if (!enabled) {
      return [];
    }
    const promotions = customFields.promotions ?? [];
    const now = dayjs();
    return promotions.filter((promotion) => {
      const { roles, from_date: fromDate, to_date: toDate } = promotion;
      return roles.includes(user?.group?.role) && now.isAfter(fromDate) && (!toDate || now.isBefore(toDate));
    });
  }, [features]);

  const { width } = useWindowDimensions();

  const CARD_WIDTH = width - 40;
  const CARD_HEIGHT = CARD_WIDTH / 2.7;
  const progressValue = useSharedValue<number>(0);
  const mouseLogs = useRef([]);

  const handleNavigateDetail = (item) => async () => {
    analyticService.logEvent({
      name: EVENT.HOME.TAP_BANNER,
      properties: {
        campaign_title: item?.title,
        campaign_id: item?.id,
        promotion_url: item?.promotion_url,
      },
    });
    WebBrowser.openBrowserAsync(item?.promotion_url);
    let readPromotionIds = await Asyncstorage.getItem(Keys.READ_PROMOTION_IDS);
    readPromotionIds = JSON.parse(readPromotionIds);
    if (!readPromotionIds?.includes(item?.id)) {
      await Asyncstorage.setItem(
        Keys.READ_PROMOTION_IDS,
        JSON.stringify(readPromotionIds?.length > 0 ? [...readPromotionIds, ...[item?.id]] : [item?.id])
      );
    }
  };

  const handleSnapToItem = (index: number) => {
    const item = campaignList?.[index];
    if (item) {
      onSnapToItem?.(item);
    }
  };

  const renderItem = ({ item }) => {
    const image = (
      <ImageCustom
        source={{ uri: item?.banner_url?.["1x"] }}
        style={[styles.image, { width: CARD_WIDTH, height: CARD_HEIGHT }]}
      />
    );

    return Platform.OS === "web" ? (
      <div
        className="prevent-select-img"
        onMouseDown={({ clientX: x, clientY: y }) => mouseLogs.current.push({ x, y })}
        onMouseUp={({ clientX: x, clientY: y }) => mouseLogs.current.push({ x, y })}
        onClick={() => {
          const acceptedRadius = 10;
          const distance = twoDimensionalDistance(mouseLogs.current[0], mouseLogs.current[1]);
          if (distance < acceptedRadius) {
            handleNavigateDetail(item)();
          }
          mouseLogs.current = [];
        }}
      >
        {image}
      </div>
    ) : (
      <TouchableOpacityCustom activeOpacity={0.9} style={styles.cardContainer} onPress={handleNavigateDetail(item)}>
        {image}
      </TouchableOpacityCustom>
    );
  };
  if (campaignList?.length === 0) {
    return null;
  }
  return (
    <View style={styles.container} ref={ref}>
      <Carousel
        style={{
          width: width,
        }}
        snapEnabled={false}
        vertical={false}
        overscrollEnabled={false}
        loop={campaignList?.length > 1}
        autoPlay={campaignList?.length > 1}
        autoPlayInterval={4000}
        width={width}
        height={CARD_HEIGHT}
        mode="parallax"
        modeConfig={modeConfig}
        data={campaignList}
        onProgressChange={(_, absoluteProgress) => (progressValue.value = absoluteProgress)}
        scrollAnimationDuration={500}
        renderItem={renderItem}
        onConfigurePanGesture={(gestureChain) => {
          gestureChain.activeOffsetX([-10, 10]);
        }}
        onSnapToItem={handleSnapToItem}
      />
      {campaignList?.length > 1 && (
        <View style={styles.paginationContainer}>
          {campaignList?.map((item, index) => {
            return (
              <PaginationDot
                key={index}
                size={6}
                length={campaignList.length}
                animValue={progressValue}
                index={index}
              />
            );
          })}
        </View>
      )}
    </View>
  );
});
export default memo(CampaignList);
const styles = StyleSheet.create({
  container: { marginTop: 25 },
  paginationContainer: { flexDirection: "row", alignSelf: "center", marginTop: 7 },
  cardContainer: { alignItems: "center" },
  image: { borderRadius: 16 },
});
