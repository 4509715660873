import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M7.501 18.334H5.835c-3.334 0-4.167-.834-4.167-4.167V5.834c0-3.334.833-4.167 4.167-4.167h1.25c1.25 0 1.525.367 2 1l1.25 1.667c.316.416.5.666 1.333.666h2.5c3.333 0 4.167.834 4.167 4.167v1.667"
        stroke="#475569"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.465 15.267c-1.958.142-1.958 2.975 0 3.117h4.633c.559 0 1.108-.209 1.517-.584 1.375-1.2.642-3.6-1.167-3.825-.65-3.908-6.3-2.425-4.966 1.3"
        stroke="#475569"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
