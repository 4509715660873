import { FC } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import Colors from "constants/Colors";

interface IContainer {
  style?: StyleProp<ViewStyle>;
}

const Container: FC<IContainer> = ({ children, style }) => {
  return <View style={StyleSheet.flatten([styles.container, style])}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.whiteBackground,
    padding: 15,
    paddingVertical: 20,
  },
});

export default Container;
