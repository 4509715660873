import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 20, height = 20, fill = "#94A3B8" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={20} height={20}>
        <Path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M10.79 10L7.396 6.606a.604.604 0 01-.177-.435.589.589 0 01.177-.443.598.598 0 01.439-.181c.172 0 .318.06.44.181l3.744 3.745a.691.691 0 01.213.527.691.691 0 01-.213.527l-3.745 3.746a.604.604 0 01-.435.177.589.589 0 01-.443-.177.598.598 0 01-.181-.44c0-.171.06-.318.181-.438L10.79 10z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
