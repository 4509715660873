import { gql, useQuery } from "@apollo/client";
import { MobileExpExpenseRequestTypesQuery, MobileExpExpenseRequestTypesQueryVariables } from "types";
import { useAuth } from "contexts/AuthContext";

const EXPENSE_REQUEST_TYPES_QUERY = gql`
  query MobileExpExpenseRequestTypes($input: expExpenseRequestTypesInput!) {
    expExpenseRequestTypes(input: $input) {
      expenseRequestTypes
    }
  }
`;
export const useExpenseRequestTypes = () => {
  const { user, groupId } = useAuth();
  return useQuery<MobileExpExpenseRequestTypesQuery, MobileExpExpenseRequestTypesQueryVariables>(
    EXPENSE_REQUEST_TYPES_QUERY,
    {
      variables: { input: { groupId, companyEmployeeId: user?.employee_id } },
      skip: !user?.employee_id || !groupId,
      fetchPolicy: "cache-and-network",
    }
  );
};
