import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={16} height={16}>
        <Path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.3 8.71L6.238 7.65a.489.489 0 00-.348-.152.476.476 0 00-.354.152.49.49 0 00-.155.356.49.49 0 00.155.356l1.342 1.353c.12.12.261.18.422.18.16 0 .301-.06.422-.18l2.763-2.763a.494.494 0 000-.713.49.49 0 00-.357-.155.49.49 0 00-.356.155L7.3 8.71zm.7 5.537a1.24 1.24 0 01-.397-.066c-1.406-.5-2.525-1.387-3.356-2.66C3.416 10.248 3 8.874 3 7.4V4.397c0-.252.073-.48.22-.682.145-.203.333-.35.563-.442l3.795-1.417c.143-.051.284-.077.422-.077s.279.026.422.077l3.795 1.417c.23.091.418.239.564.442.146.203.219.43.219.682V7.4c0 1.474-.416 2.848-1.247 4.12-.831 1.274-1.95 2.16-3.356 2.66a1.12 1.12 0 01-.397.067z"
          fill="#2B8A3E"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
