// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import jwtDecode from "jwt-decode";
import { request } from "app/utils/gql";
import { useCallback, useEffect, useState } from "react";
import map from "lodash/map";
import flatten from "lodash/flatten";

const GET_COMPANY_IN_GROUP = /* GraphQL */ `
  query MobileUserGroups($userId: uuid!) {
    users_groups(where: { role: { _eq: "mod" }, user_id: { _eq: $userId } }) {
      group {
        group_id
        companies {
          company_id
          name
          tax_code
          expense_code
          users_roles {
            users_roles_id

            user {
              fullname
              user_id
            }
          }
        }
      }
    }
  }
`;

const useGetCompanyInGroup = ({ token }) => {
  const [companies, setCompanies] = useState<Companies[]>([]);
  const [loading, setLoading] = useState(false);
  const getCompanyInGroup = useCallback(async () => {
    setLoading(true);
    const payload = jwtDecode(token);
    const result: {
      data: {
        users_groups: UsersGroups[];
      };
    } = await request(GET_COMPANY_IN_GROUP, { userId: payload?.id }, token);

    if (result?.data?.users_groups?.length > 0) {
      const listCompany = map(result?.data?.users_groups, (userGroup) => userGroup.group.companies);
      setCompanies(flatten(listCompany));
    }
    setLoading(false);
  }, [token]);

  useEffect(() => {
    if (token) {
      getCompanyInGroup();
    }
  }, [token, getCompanyInGroup]);

  return {
    loading,
    companies,
  };
};

export default useGetCompanyInGroup;
