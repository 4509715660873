import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#C0CAD8"
        d="M33.447 74.154c1.922.611 4.11.957 6.44.957 2.329 0 4.517-.346 6.439-.957-1.859-1.718-4.079-2.816-6.44-2.816-2.36 0-4.556 1.082-6.439 2.816Z"
      />
      <Path
        fill="#E4EAF1"
        d="M73.432 48.946C72.436 64.57 57.338 71.613 39.33 70.46 21.322 69.315 7.259 60.397 8.255 44.79 9.25 29.18 24.85 14.317 42.859 15.47c18 1.145 31.569 17.851 30.573 33.475Z"
      />
      <Path
        fill="#1F71F4"
        d="m29.017 17.753-2.283 35.734c4.063 1.592 8.628 2.604 13.506 2.917 4.871.314 9.538-.11 13.773-1.176l2.282-35.734c-4.023-2.259-8.572-3.71-13.435-4.023-4.87-.314-9.569.549-13.843 2.282Z"
      />
      <Path
        fill="#fff"
        d="m39.934 30.875-.682-.047.596-2.055 1.255-4.377.063-.235-3.467-.22 1.631-5.56-2.047-.134 4.989-2.815 3.082 3.333-1.584-.102-.644 2.22 4.557.29-7.749 9.702Z"
      />
      <Path
        fill="#0F172A"
        d="M16.576 54.342c-3.882-5.483 1.78-22.542 7.686-25.726 7.938-4.275 30.4-2.243 37.2 3.655 4.926 4.282 7.491 21.616 3.193 25.851-8.58 8.455-41.153 6-48.079-3.78Z"
      />
      <Path
        fill="#C0CAD8"
        d="M10.357 49.902 6.671 48.71c-.675-.212-1.13-.996-1.075-1.851l.4-6.275c.055-.855.604-1.576 1.318-1.702l3.804-.713c.949-.173 1.772.737 1.694 1.898l-.518 8.172c-.086 1.138-1.02 1.961-1.937 1.663Z"
      />
      <Path
        fill="#E4EAF1"
        d="m8.263 49.267-3.38-1.075c-.628-.196-1.036-.91-.989-1.71l.369-5.74c.047-.793.549-1.436 1.192-1.554l3.49-.643c.87-.165 1.616.682 1.553 1.725l-.478 7.483c-.063 1.059-.926 1.788-1.757 1.514Z"
      />
      <Path
        fill="#C0CAD8"
        d="m70.663 54.255 3.804-.714c.714-.125 1.263-.847 1.318-1.701l.4-6.275c.055-.855-.4-1.64-1.09-1.859l-3.687-1.192c-.917-.29-1.85.51-1.921 1.663l-.518 8.18c-.07 1.153.745 2.086 1.694 1.898Z"
      />
      <Path
        fill="#E4EAF1"
        d="m72.836 53.903 3.49-.643c.651-.118 1.145-.777 1.192-1.553l.369-5.741c.047-.793-.36-1.498-.988-1.694l-3.38-1.075c-.848-.275-1.695.47-1.758 1.514l-.478 7.474c-.063 1.036.682 1.883 1.553 1.718Z"
      />
      <Path
        fill="#37B24D"
        d="m10.675 19.24-5.372 7.53s2.82-.367 7.727 1.258c4.908 1.625 7.41 3.13 7.41 3.13s.139-3.16 2.641-11.385c2.502-8.226 5.421-10.464 5.421-10.464s-1.958-1.612-2.897-4.248c-.94-2.637-1.654-5.183-1.654-5.183S20.3 3.668 18.554 11.85c-1.746 8.182-1.458 12.708-1.458 12.708l-6.421-5.318Z"
      />
      <Path
        fill="#0F172A"
        d="M16.3 54.718c-3.896-5.583 1.915-22.888 7.902-26.093 8.044-4.305 30.748-2.148 37.595 3.877 4.962 4.367 7.466 21.986 3.1 26.272-8.714 8.542-41.64 5.908-48.596-4.056Z"
      />
      <Path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.4}
        d="m36.596 55.32.292.186a8.133 8.133 0 0 0 8.071.385v0"
      />
      <Path
        fill="#fff"
        d="M33.407 28.97c.05.432-1.344.655-3.089.902-1.744.247-3.11.536-3.215-.028-.067-.403 1.345-.657 3.089-.904 1.743-.23 3.14-.485 3.215.03ZM36.588 28.565c.034.432-.398.498-.957.556-.559.057-.98.188-1.036-.373-.036-.401.398-.499.957-.556.543-.058.993-.155 1.036.373ZM36.371 46.902c-3.479-.238-6.973 2.499-7.233 6.307.246-3.597-1.958-6.936-6.308-7.233 4.35.297 6.973-2.5 7.234-6.308-.275 4.02 2.405 6.967 6.307 7.234ZM59.47 48.482c-3.478-.238-6.972 2.499-7.233 6.307.246-3.596-1.958-6.936-6.307-7.233 4.35.297 6.973-2.5 7.233-6.308-.275 4.02 2.406 6.967 6.308 7.234Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.5 0h80v80H.5z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgComponent;
