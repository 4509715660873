import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGComponent = ({ width = 25, height = 24, color = "#94A3B8" }) => (
  <Svg width={width} height={height} viewBox="0 0 25 24" fill="none">
    <Path
      d="M2.5 8.50586H22.5"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.5 16.5059H8.5"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11 16.5059H15"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.94 3.50586H18.05C21.61 3.50586 22.5 4.38586 22.5 7.89586V16.1059C22.5 19.6159 21.61 20.4959 18.06 20.4959H6.94C3.39 20.5059 2.5 19.6259 2.5 16.1159V7.89586C2.5 4.38586 3.39 3.50586 6.94 3.50586Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default SVGComponent;
