import { gql, useMutation } from "@apollo/client";

const EXPENSE_REMOVE_EXPENSE_FROM_REQUEST = gql`
  mutation MobileUnlinkExpensesFromRequest($expenseRequestId: UUID!, $expenseIds: [UUID!]!) {
    expUnlinkExpensesFromRequest(input: { expenseIds: $expenseIds, expenseRequestId: $expenseRequestId }) {
      message
    }
  }
`;
const useUnlinkExpensesFromRequest = () => {
  const [unlinkExpensesFromRequest] = useMutation(EXPENSE_REMOVE_EXPENSE_FROM_REQUEST);
  return { unlinkExpensesFromRequest };
};

export default useUnlinkExpensesFromRequest;
