import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const UPDATE_MY_SETTING = gql`
  mutation MobileUpdateMySetting($setting: [IdMySettingsUpsertInput!]!) {
    idUpdateMySettings(userSettings: $setting) {
      message
    }
  }
`;
const useUserUpdateMySetting = () => {
  const [mutation, options] = useMutation(UPDATE_MY_SETTING);

  const updateMySetting = async ({ language }: { language: string }) => {
    const {
      data: { idUpdateMySettings },
    } = await mutation({
      variables: {
        setting: [{ setting: { LC_ALL: language }, type: "locale" }],
      },
    });
    return idUpdateMySettings;
  };

  return { updateMySetting, ...options };
};

export default useUserUpdateMySetting;
