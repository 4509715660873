import LottieView from "lottie-react-native";
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { AppText, LoadingView, useWindowDimensions } from "components/index";
import { Fonts } from "theme";
import { FC } from "react";

interface LottieLoadingProps {
  title?: string;
  style?: StyleProp<ViewStyle>;
}
const LottieLoading: FC<LottieLoadingProps> = ({ title, style }) => {
  const { width } = useWindowDimensions();
  if (Platform.OS === "web") {
    return <LoadingView style={style} />;
  }
  return (
    <View style={[styles.container, style]}>
      <View style={styles.loadingContainer}>
        <LottieView style={{ width }} speed={1} autoPlay={true} source={require("assets/lottie/flight-loading.json")} />
        {Boolean(title) && <AppText style={Fonts.BodyLarge}>{title}</AppText>}
      </View>
    </View>
  );
};
export default LottieLoading;

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: "center", alignItems: "center" },
  loadingContainer: { marginTop: -120, alignItems: "center" },
});
