import { CONSTANTS } from "constants/constants";
import { FC, Fragment, ReactElement, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import {
  AppText,
  BottomSheetModalCustom,
  BottomSheetScrollViewCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  CollapsibleView,
  IconCustom,
  Line,
} from "components";
import { Colors, Fonts } from "theme";
import { BizziBotSticker01 } from "assets/images";
import { isIphoneX } from "constants/Layout";
import { useTranslation } from "react-i18next";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";

type ApprovalModalSummaryProps = {
  successCount: number;
  errorCount: number;
  entry: "report" | "request";
};
const ApprovalModalSummary: FC<ApprovalModalSummaryProps> = ({ successCount, errorCount, entry }) => {
  const { t } = useTranslation("app/components/BatchApprovalLayout/BatchApprovalLayout");

  return (
    <View style={styles.summarySection}>
      <View style={styles.summaryLine}>
        <View style={{ marginHorizontal: 2 }}>
          <IconCustom name="check-circle" fill={Colors.success50} width={19} height={19} />
        </View>
        <AppText style={Fonts.BodyMedium} color={Colors.success50}>
          {entry === "report"
            ? t("reports_success_count", {
                count: successCount,
              })
            : t("requests_success_count", {
                count: successCount,
              })}
        </AppText>
      </View>
      <View style={styles.summaryLine}>
        <IconCustom name="cancel" fill={Colors.alert50} />
        <AppText style={Fonts.BodyMedium} color={Colors.alert50}>
          {entry === "report"
            ? t("reports_failed_count", {
                count: errorCount,
              })
            : t("requests_failed_count", {
                count: errorCount,
              })}
        </AppText>
      </View>
    </View>
  );
};

type ApprovalModalData = {
  summaryComponent?: ReactElement;
  data: {
    error: {
      objectId: string;
      message: string;
      success: boolean;
    };
    obj: any;
  }[];
  onConfirm?: () => void;
};
type ApprovalModalProps = {
  keyExtractor: (item: any) => string;
  renderItem: ({ item }: any) => ReactElement;
};

type ApprovalModalApi = {
  removeItem: (key) => void;
  show: (args: ApprovalModalData) => void;
  close: () => void;
};

const ApprovalModal = forwardRef<ApprovalModalApi, ApprovalModalProps>(({ renderItem, keyExtractor }, ref) => {
  const { t } = useTranslation("app/components/BatchApprovalLayout/BatchApprovalLayout");
  useImperativeHandle(ref, () => ({
    removeItem: (key) => {
      setModal((prev) => ({ ...prev, data: prev.data.filter((x) => key !== keyExtractor(x.obj)) }));
    },
    show: (modal: ApprovalModalData) => {
      setModal(modal);
      bottomSheetRef.current?.present();
    },
    close: () => bottomSheetRef.current?.close(),
  }));

  const [modal, setModal] = useState<ApprovalModalData>(null);

  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();

  const renderFooter = () => {
    return (
      <View style={styles.footer}>
        <Button
          style={styles.underStoodButton}
          type="primary"
          onPress={modal?.onConfirm ?? bottomSheetRef.current?.close}
        >
          {t("understood")}
        </Button>
      </View>
    );
  };

  const groups = modal?.data?.reduce?.((acc, { error, obj }) => {
    const msg = error.message;
    if (!acc[msg]) {
      acc[msg] = [];
    }

    acc[msg].push(obj);
    return acc;
  }, {});

  return (
    <BottomSheetModalCustom
      showHeader={false}
      snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
      ref={bottomSheetRef}
      footerComponent={renderFooter}
      enableTouchOutsideToClose={false}
      enablePanDownToClose={false}
      enableOverDrag={false}
    >
      <BottomSheetScrollViewCustom showsVerticalScrollIndicator={false}>
        <View style={styles.listHeader}>
          <View style={styles.headerRowCenter}>
            <Image resizeMode="contain" source={BizziBotSticker01} style={styles.bizziBotImage} />
          </View>
          <View style={styles.headerRowCenter}>
            <AppText style={Fonts.H400}>{t("approval_result")}</AppText>
          </View>
          <Line />
          {modal?.summaryComponent}
        </View>
        {groups
          ? Object.entries(groups).map(([key, value]) => {
              const entityList = value as any[];
              return (
                <Fragment key={key}>
                  <CollapsibleView
                    rotate={-180}
                    headerStyle={styles.headerStyle}
                    showContent={true}
                    headerComponent={
                      <View style={styles.errorSectionTitle}>
                        <IconCustom name="cancel" fill={Colors.alert50} />
                        <AppText style={[Fonts.BodyMedium, styles.allSpaceLeft]}>{key}</AppText>
                      </View>
                    }
                    rightComponent={<IconCustom name="expand-more" />}
                  >
                    <View style={styles.listContainer}>
                      {entityList.map((item, index) => {
                        const isLast = index === entityList.length - 1;
                        return (
                          <Fragment key={keyExtractor(item)}>
                            {renderItem({ item })}
                            {!isLast && <View style={styles.divider} />}
                          </Fragment>
                        );
                      })}
                    </View>
                  </CollapsibleView>
                </Fragment>
              );
            })
          : null}
      </BottomSheetScrollViewCustom>
    </BottomSheetModalCustom>
  );
});

export { ApprovalModal, ApprovalModalSummary };
export type { ApprovalModalApi };

const styles = StyleSheet.create({
  listHeader: {
    paddingTop: 20,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    gap: 12,
  },
  headerRowCenter: {
    alignItems: "center",
  },
  listContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Colors.white,
    paddingVertical: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: isIphoneX() ? 34 : 20,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  underStoodButton: {
    flex: 1,
  },
  bizziBotImage: { width: 84, height: 91 },
  errorSectionTitle: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  allSpaceLeft: {
    flex: 1,
  },
  summarySection: {
    paddingVertical: 8,
    gap: 8,
  },
  headerStyle: {
    paddingVertical: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.grayscale0,
  },
  summaryLine: {
    flexDirection: "row",
    gap: 8,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 45,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
