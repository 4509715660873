import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const EXPENSE_CANCEL_REQUEST = gql`
  mutation MobileCancelExpenseRequest($reason: String, $request_id: UUID!) {
    expenseCancelRequest(reason: $reason, request_id: $request_id) {
      message
      success
    }
  }
`;

const useCancelExpenseRequest = () => {
  const [mutation, results] = useMutation(EXPENSE_CANCEL_REQUEST);

  const expenseCancelRequest = useCallback(
    async (variables) => {
      return await mutation({ variables });
    },
    [mutation]
  );

  return {
    expenseCancelRequest,
    ...results,
  };
};

export default useCancelExpenseRequest;
