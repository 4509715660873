import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, fill = "#0F172A" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M9.058 17.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h8.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H9.058zm0-1.5h8.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V4.308a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H9.058a.294.294 0 00-.212.096.294.294 0 00-.096.212v11.384c0 .077.032.148.096.212a.294.294 0 00.212.096zm-3.5 5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V7.058c0-.213.072-.391.215-.535a.726.726 0 01.535-.215c.213 0 .391.071.535.215a.726.726 0 01.215.535v12.134c0 .077.032.148.096.212a.294.294 0 00.212.096h9.134c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.535.726.726 0 01-.535.215H5.558z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
