// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import NotificationProvider from "contexts/NotificationContext";
import { useAuth } from "contexts/AuthContext";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  IconCustom,
  StatusBarCustom,
} from "components";
import { Linking, Platform, StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";

import CompanyList from "../components/CompanyList";
import SCREEN_NAME from "navigation/ScreenName";
import BizziBotPermissionDenied from "assets/images/svg/icons/BizziBotPermissionDenied";
import { CONSTANTS } from "constants/constants";
import { TouchableOpacity } from "react-native-gesture-handler";
import config from "app/config";

interface SelectCompanyScreenProps {
  navigation: any;
}

interface NotificationProviderProps {
  removeTokenNotification: () => Promise<any>;
}

const SelectCompanyScreen: FC<SelectCompanyScreenProps & NotificationProviderProps> = ({
  navigation,
  removeTokenNotification,
}) => {
  const { user, logout, selectCompany } = useAuth();
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const companies = useMemo(() => user?.employees?.map((emp) => emp.company)?.filter(Boolean) ?? [], [user?.employees]);
  const { t } = useTranslation("app/screens/Company/SelectCompanyScreen/SelectCompanyScreen");
  const onPressLogout = useCallback(async () => {
    await removeTokenNotification();
    await logout();
  }, [removeTokenNotification, logout]);

  const onSelect = useCallback(
    async (company_id) => {
      alertNotificationRef.current?.loading();
      await selectCompany(company_id);
      alertNotificationRef.current?.close();
      setTimeout(() => {
        navigation.navigate(SCREEN_NAME.HomeBottomTabNavigator);
      }, 400);
    },
    [navigation, selectCompany]
  );

  useLayoutEffect(() => {
    if (user?.employee_id) {
      navigation.replace(SCREEN_NAME.HomeBottomTabNavigator);
    }
  }, [navigation, user?.employee_id]);

  const handleSpendLink = () => {
    if (Platform.OS === "web") {
      window.open(`${config.SPEND_ENDPOINT}`);
      return;
    }

    Linking.canOpenURL(config.SPEND_ENDPOINT).then((supported) => {
      if (supported) {
        Linking.openURL(config.SPEND_ENDPOINT);
      }
    });
  };

  return (
    <>
      <View style={StyleSheet.flatten([styles.container])}>
        <StatusBarCustom barStyle="dark-content" />
        {companies?.length ? (
          <>
            <BackHeader
              headerTitle={companies.length ? t("select_company_header") : t("add_company_header")}
              onPress={onPressLogout}
              backIcon={<IconCustom name="logout" fill={Colors.grayscale100} />}
            />
            <CompanyList companies={companies} onSelect={onSelect} />
          </>
        ) : (
          <View style={styles.permissionDeniedContainer}>
            <View style={styles.contentContainer}>
              <AppText style={[Fonts.H400, styles.title]}>
                {user?.group?.role === "mod" ? t("registration_not_completed") : t("expense_feature_not_enabled")}
              </AppText>
              <BizziBotPermissionDenied />
              <View style={styles.footerContainer}>
                {user?.group?.role === "mod" ? (
                  <AppText style={styles.textCenter}>
                    <AppText style={Fonts.BodyMedium}>{t("registration_not_completed_guide_prefix")}</AppText>
                    <AppText style={Fonts.BodyMedium} color={Colors.primary50} onPress={handleSpendLink}>
                      {config.SPEND_ENDPOINT.replace("https://", "")}
                    </AppText>
                    <AppText style={Fonts.BodyMedium}>{t("registration_not_completed_guide_suffix")}</AppText>
                  </AppText>
                ) : (
                  <AppText style={styles.textCenter}>{t("expense_feature_not_enabled_guide")}</AppText>
                )}

                <TouchableOpacity onPress={onPressLogout}>
                  <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.primary50}>
                    {t("logout")}
                  </AppText>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </View>
      <AlertNotification ref={alertNotificationRef} />
    </>
  );
};

const WrapperNotificationContext = (props: SelectCompanyScreenProps) => {
  return (
    <NotificationProvider.Consumer>
      {({ removeTokenNotification }) => {
        return <SelectCompanyScreen removeTokenNotification={removeTokenNotification} {...props} />;
      }}
    </NotificationProvider.Consumer>
  );
};

export default WrapperNotificationContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale05,
    flexDirection: "column",
  },
  permissionDeniedContainer: {
    flex: 1,
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
  },
  contentContainer: {
    gap: 24,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    padding: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  footerContainer: {
    gap: 8,
  },
  title: {
    textAlign: "center",
    marginBottom: 0,
  },
  textCenter: {
    textAlign: "center",
  },
});
