import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";

import { Colors, Fonts } from "theme";
import {
  AppText,
  BackHeader,
  BottomSheetModalCustomMethods,
  Button,
  FloatFooter,
  LoadingView,
  TextView,
} from "components";
import { CONSTANTS, TRANSACTION_CARD_STATUS } from "constants/constants";
import { formatCardNumber } from "utils";
import AssignUserModal from "./components/AssignUserModal";
import ExpenseTransactionList from "./components/ExpenseTransactionList";
import ChooseExpenseListModal from "./components/ChooseExpenseListModal/ChooseExpenseListModal";
import useTransactionDetailQuery from "./hooks/useTransactionDetailQuery";
import dayjs from "dayjs";
import { AssignUserIcon } from "app/assets/images/svg/icons";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";

const TransactionDetailScreen = ({ navigation, route }) => {
  const { t } = useTranslation("app/screens/Card/TransactionDetailScreen");
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("transaction_detail")} />,
    } as any);
  }, []);
  const {
    user: { employee_id: employeeId },
  } = useAuth();
  const { cardTransactionId, companyId } = route?.params;
  const chooseExpenseListModalRef = useRef<BottomSheetModalCustomMethods>();
  const { data, loading, refetch, called } = useTransactionDetailQuery({ cardTransactionId, companyId });
  const [footerHeight, setFooterHeight] = useState(0);

  const transactionDetail = data?.crdCardTransaction;
  const assignUserModalRef = useRef<BottomSheetModalCustomMethods>(null);
  const isRefundTransaction = transactionDetail?.amount > 0;

  useEffect(() => {
    /*--reload data when focus screen--*/
    const unsubscribe = navigation.addListener("focus", () => {
      if (called) {
        refetch?.();
      }
    });
    return unsubscribe;
  }, []);

  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const onRefreshData = () => {
    refetch?.();
    route?.params?.onRefreshData?.();
  };
  const handleCreateExpense = () => {
    navigation.navigate(SCREEN_NAME.NewExpenseScreen, {
      amount: Math.abs(transactionDetail?.amount),
      cardTransaction: transactionDetail,
    });
  };
  const isCanAssignUser = transactionDetail?.assignable;

  const isCanCreateExpense =
    !isRefundTransaction &&
    transactionDetail?.employee?.employeeId === employeeId &&
    !transactionDetail?.cardTransactionExpenses?.length;
  if (loading && !data) {
    return <LoadingView style={styles.loadingContainer} />;
  }
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: footerHeight }}>
        <View style={styles.infoContainer}>
          <TextView
            isMoney
            style={styles.textView}
            label={t("amount")}
            value={transactionDetail?.amount}
            textColor={isRefundTransaction ? Colors.success100 : Colors.grayscale100}
            labelLeft={isRefundTransaction ? "+" : ""}
          />
          <TextView
            style={styles.textView}
            label={t("card_number")}
            value={formatCardNumber(
              transactionDetail?.card?.prefixCardNumber,
              transactionDetail?.card?.suffixCardNumber
            )}
          />
          <TextView style={styles.textView} label={t("transaction_code")} value={transactionDetail?.transactionId} />
          <TextView
            style={styles.textView}
            label={t("transaction_date")}
            value={`${dayjs(transactionDetail?.transactionDate).format("HH:mm")} - ${dayjs(
              transactionDetail?.transactionDate
            ).format(CONSTANTS.FORMAT_DAY)}`}
          />
          <TextView style={styles.textView} label={t("location")} value={transactionDetail?.location} />
          <TextView
            style={styles.textView}
            label={t("user")}
            value={transactionDetail?.employee?.fullname ?? "--"}
            icon={
              isCanAssignUser && (
                <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => assignUserModalRef?.current?.present?.()}>
                  <AssignUserIcon />
                </TouchableOpacity>
              )
            }
          />
        </View>
        {!isRefundTransaction && transactionDetail?.status === TRANSACTION_CARD_STATUS.COMPLETED && (
          <>
            <View style={styles.divider} />
            <View style={styles.expenseListContainer}>
              <AppText style={Fonts.SentenceSubTitleXLarge}>{t("expense_list")}</AppText>
              <ExpenseTransactionList data={transactionDetail?.cardTransactionExpenses} />
            </View>
          </>
        )}
      </ScrollView>
      {isCanCreateExpense && (
        <FloatFooter onLayout={onLayoutFooter}>
          <Button onPress={handleCreateExpense}>{t("create_expense")}</Button>
        </FloatFooter>
      )}
      <ChooseExpenseListModal
        totalAmountTransaction={Math.abs(transactionDetail?.amount)}
        onRefreshDetail={refetch}
        ref={chooseExpenseListModalRef}
        cardTransactionId={transactionDetail?.cardTransactionId}
        expenseList={transactionDetail?.cardTransactionExpenses}
      />
      <AssignUserModal transactionDetail={transactionDetail} ref={assignUserModalRef} onRefreshDetail={onRefreshData} />
    </View>
  );
};
export default TransactionDetailScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  loadingContainer: { backgroundColor: Colors.white },
  infoContainer: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 16,
  },
  divider: { backgroundColor: Colors.grayscale05, height: 8 },
  expenseListContainer: {
    marginTop: 20,
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  textView: { marginBottom: 6 },
});
