import { useEffect, useState, useCallback, useRef, lazy, Suspense, useLayoutEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Alert, Linking, Platform, StatusBar } from "react-native";
import { Camera, CameraCapturedPicture } from "expo-camera";
import * as ImageManipulator from "expo-image-manipulator";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native-paper";
import { v1 } from "uuid";
import { useIsFocused } from "@react-navigation/native";

import { Loading, BackHeader, Button, Tabs, AppText } from "components";
import SCREEN_NAME from "navigation/ScreenName";
import { WIDTH_WINDOW } from "constants/Layout";
import { CameraScreenNavigationProp } from "navigation/type";
import PencilIcon from "assets/images/svg/icons/PencilIcon";
import { Colors, Fonts } from "theme";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { InvoiceType } from "constants/ExpenseApproval";
import { CloseSquareIcon } from "assets/images/svg/icons";
import { useFocusEffect } from "@react-navigation/native";
import { useIsFeatureEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { CONSTANTS } from "constants/constants";

type TCameraScreen = {
  navigation: CameraScreenNavigationProp;
};

let CameraPreview = null;

if (Platform.OS === "web") {
  CameraPreview = lazy(() => import("./ReviewImage.web"));
} else {
  CameraPreview = lazy(() => import("./ReviewImage"));
}

enum PaperType {
  INVOICE_PAPER = "invoice_paper",
  ANOTHER_PAPER = "another_paper",
}
const CameraScreen: React.FC<TCameraScreen> = ({ navigation }) => {
  const { bottom } = useSafeAreaInsets();
  const FEATURE_PAPER_INVOICE = useIsFeatureEnabled(FEATURE_FLAGS.FEATURE_PAPER_INVOICE);

  const [startCamera, setStartCamera] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [capturedImage, setCapturedImage] = useState<
    (ImageManipulator.ImageResult & { id?: string; fileType: string; name?: string }) | null
  >(null);
  const [typeOfInvoice, setTypeOfInvoice] = useState<PaperType>(
    FEATURE_PAPER_INVOICE ? PaperType.INVOICE_PAPER : PaperType.ANOTHER_PAPER
  );
  const height = Math.round((WIDTH_WINDOW * 16) / 9);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenEditImage, setIsOpenEditImage] = useState(false);
  const [isShowEditImageIcon, setIsShowEditImageIcon] = useState(false);
  const { t } = useTranslation("app/screens/CameraScreen/CameraScreen");
  const cameraRef = useRef<Camera>(null);
  const [ratio, setRatio] = useState("4:3");

  const LIST_CHOICES = [
    {
      name: t("invoice_paper"),
      value: PaperType.INVOICE_PAPER,
    },
    {
      value: PaperType.ANOTHER_PAPER,
      name: t("another_paper"),
    },
  ];

  const getPermissionsAsync = useCallback(async () => {
    const { status: cameraStatus } = await Camera.requestCameraPermissionsAsync();
    return cameraStatus === "granted";
  }, []);

  const onPressSetting = () => {
    Linking.openSettings();
  };

  const initCamera = useCallback(async () => {
    const status = await getPermissionsAsync();

    if (status) {
      if (Platform.OS === "android") {
        setStartCamera(true);
      } else {
        setStartCamera(true);
      }
    } else {
      Alert.alert(
        "",
        t("description_require_permission"),
        [
          {
            text: t("button_title_open_setting"),
            onPress: onPressSetting,
          },
          {
            text: t("button_cancel"),
            style: "cancel",
          },
        ],
        { cancelable: false }
      );
    }
  }, [getPermissionsAsync, t]);

  useEffect(() => {
    initCamera();
  }, []);

  const processImageAsync = useCallback(async (uri, height, width) => {
    const file: ImageManipulator.ImageResult = await ImageManipulator.manipulateAsync(
      uri,
      [
        {
          resize: {
            width,
            height,
          },
        },
      ],
      {
        compress: 1,
        format: ImageManipulator.SaveFormat.JPEG,
      }
    );
    return file;
  }, []);

  const openEditImage = useCallback(() => {
    setIsOpenEditImage(true);
  }, []);

  const closeEditImage = useCallback(() => {
    setIsOpenEditImage(false);
  }, []);

  const takePicture = useCallback(async () => {
    try {
      setIsLoading(true);

      const photo: CameraCapturedPicture | undefined = await cameraRef.current?.takePictureAsync({
        skipProcessing: true,
        quality: CONSTANTS.COMMON.QUALITY_RESIZE_IMAGE,
      });

      const formatPhoto =
        Platform.OS === "android" ? photo : await processImageAsync(photo?.uri, photo?.height, photo?.width);
      setPreviewVisible(true);
      setCapturedImage({ ...formatPhoto, fileType: "image/jpeg", name: `${v1()}.jpeg` });
      setIsShowEditImageIcon(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [setIsLoading, setCapturedImage, setPreviewVisible, processImageAsync]);

  const retakePicture = useCallback(async () => {
    await cameraRef.current?.resumePreview();
    setCapturedImage(null);
    setIsShowEditImageIcon(false);
    setPreviewVisible(false);
    initCamera();
  }, [setCapturedImage, setPreviewVisible, initCamera]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          headerTitle={t("title")}
          color={Colors.white}
          statusBarProps={{ barStyle: "light-content" }}
          containerStyle={styles.backHeader}
          headerRight={
            isShowEditImageIcon && (
              <TouchableOpacity style={{ paddingRight: 15 }} onPress={openEditImage}>
                <PencilIcon color="white" />
              </TouchableOpacity>
            )
          }
        />
      ),
    } as any);
  }, [isShowEditImageIcon, navigation, openEditImage, t]);

  useFocusEffect(() => {
    StatusBar.setBarStyle("light-content");
    return () => {
      StatusBar.setBarStyle("dark-content");
    };
  });
  const isFocused = useIsFocused();

  return (
    <View style={styles.container}>
      {startCamera && isFocused && (
        <View style={styles.content}>
          {previewVisible && capturedImage ? (
            <>
              <Suspense fallback={<ActivityIndicator size={10} />}>
                <CameraPreview
                  photo={capturedImage}
                  retakePicture={retakePicture}
                  openEditImage={openEditImage}
                  closeEditImage={closeEditImage}
                  setCapturedImage={setCapturedImage}
                  isOpenEditImage={isOpenEditImage}
                />
              </Suspense>
              <View style={styles.actions}>
                {FEATURE_PAPER_INVOICE ? (
                  <>
                    <View style={styles.title}>
                      <AppText style={Fonts.BodyMedium}>{t("type_of_invoice")}</AppText>
                      <TouchableOpacity onPress={retakePicture}>
                        <CloseSquareIcon />
                      </TouchableOpacity>
                    </View>
                    <View>
                      <Tabs
                        style={styles.tabs}
                        tabs={LIST_CHOICES}
                        value={typeOfInvoice}
                        onChange={(invoiceType) => {
                          setTypeOfInvoice(invoiceType as PaperType);
                        }}
                      />
                    </View>
                  </>
                ) : (
                  <View style={styles.title} />
                )}

                <View style={StyleSheet.flatten([styles.wrapperContentChoice, { paddingBottom: bottom }])}>
                  <Button
                    type="primary"
                    style={styles.createExpenseButton}
                    onPress={() => {
                      requestAnimationFrame(() => {
                        return navigation.replace(SCREEN_NAME.NewExpenseScreen, {
                          invoiceType:
                            typeOfInvoice === PaperType.INVOICE_PAPER ? InvoiceType.PAPER : InvoiceType.NO_INVOICE,
                          photos:
                            typeOfInvoice === PaperType.INVOICE_PAPER && capturedImage
                              ? [{ ...capturedImage, id: v1() }]
                              : [],
                          attachments:
                            typeOfInvoice === PaperType.ANOTHER_PAPER && capturedImage
                              ? [{ ...capturedImage, id: v1() }]
                              : [],
                        });
                      });
                    }}
                  >
                    <AppText style={Fonts.BodyLarge} color={Colors.white}>
                      {t("title_create_expense")}
                    </AppText>
                  </Button>
                  <Button type="secondary" style={styles.retakePictureBtn} onPress={retakePicture}>
                    <AppText style={Fonts.BodyLarge} color={Colors.primary50}>
                      {t("re_take")}
                    </AppText>
                  </Button>
                </View>
              </View>
            </>
          ) : (
            <Camera
              {...(Platform.OS === "android" && { ratio: "16:9" })}
              style={[styles.cameraStyle, { height }]}
              ref={cameraRef}
            >
              <View style={styles.wrapperCamera}>
                <View style={styles.wrapperButtonSnap}>
                  <View style={styles.snapButton}>
                    <View style={styles.wrapperSnapButton}>
                      <TouchableOpacity disabled={isLoading} onPress={takePicture} style={styles.snapButtonInnerStyle}>
                        {isLoading ? <Loading /> : null}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </Camera>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  actions: {
    backgroundColor: Colors.while,
    paddingHorizontal: 20,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  tabs: {
    marginVertical: 20,
  },
  createExpenseButton: {},
  retakePictureBtn: {
    color: Colors.white,
    backgroundColor: Colors.white,
    marginTop: 10,
  },
  backHeader: {
    backgroundColor: Colors.grayscale100,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale100,
    alignItems: "center",
    justifyContent: "center",
  },

  content: {
    flex: 1,
    width: "100%",
  },
  wrapperCamera: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
  },
  wrapperButtonSnap: {
    position: "absolute",
    bottom: 0,
    flexDirection: "row",
    flex: 1,
    width: "100%",
    padding: 20,
    justifyContent: "space-between",
    borderColor: Colors.white,
  },
  snapButton: {
    alignSelf: "center",
    flex: 1,
    alignItems: "center",
  },
  wrapperSnapButton: {
    width: 70,
    height: 70,
    bottom: 0,
    borderRadius: 50,
    borderWidth: 4,
    justifyContent: "center",
    alignItems: "center",
    borderColor: Colors.white,
  },
  snapButtonInnerStyle: {
    width: 50,
    height: 50,
    bottom: 0,
    borderRadius: 50,
    backgroundColor: Colors.white,
  },
  cameraStyle: {
    flex: 1,
    width: "100%",
  },
  wrapperContentChoice: {
    flexDirection: "column",
  },
});

export default CameraScreen;
