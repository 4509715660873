import { View, ViewStyle } from "react-native";
import { FC, ReactElement } from "react";
import { StyleSheet } from "react-native";
import React from "react";
import { Colors } from "theme";

const Badge: FC<{
  color: (typeof Colors)[keyof typeof Colors];
  text: ReactElement;
  icon?: ReactElement;
  style?: ViewStyle;
}> = ({ text, icon, color, style }) => {
  const backgroundColor = color ?? Colors.white;

  return (
    <View style={[styles.badge, { backgroundColor }, style]}>
      {icon}
      {text}
    </View>
  );
};

export default Badge;

const styles = StyleSheet.create({
  badge: {
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
  },
});
