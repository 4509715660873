/* eslint-disable @typescript-eslint/no-explicit-any */
import { NetworkStatus } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";
import { FlashList } from "@shopify/flash-list";
import React, { FC, memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";

import { EmptyWaitingApproval } from "assets/images/svg/icons";
import {
  AnimatedFlatList,
  AppText,
  BackHeader,
  EmptyData,
  IconCustom,
  RadioSelectFilter,
  SelectActionPicker,
  SkeletonListLoading,
  SortingLabel,
} from "components";
import { APPROVAL_OBJECT_SORT_OPTIONS, CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import { NewReportScreenNavigationProp, WaitingApprovalScreenProps } from "navigation/type";
import { Colors, Fonts } from "theme";
import Animated, {
  Easing,
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { clampReanimated } from "utils";
import ExpenseRequestItem from "components/ExpenseRequestItem";
import ReportItem from "screens/Report/ReportListScreen/components/ReportItem";
import useWaitingApprovalObjects from "screens/WaitingApproval/hooks/useWaitingApprovalObjects";
import { EVENT } from "constants/Tracking";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { analyticService } from "services/AnalyticsService";
import { BizziBotSticker02 } from "assets/images";
import { isIphoneX } from "constants/Layout";
import { useCopilot } from "react-native-copilot";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { CopilotSingleStepCustom } from "components/Walkthrough";
import { useMasterData } from "contexts/MasterDataContext";

const FILTER_CONTAINER_HEIGHT = 82;
const RIGHT_ICON_PADDING = 10;
enum DocumentTypes {
  ALL = "ALL",
  REQUEST = "REQUEST",
  REPORT = "REPORT",
}

const DOCUMENT_TYPES = [
  {
    value: DocumentTypes.REQUEST,
    title: "document_type_request",
  },
  {
    value: DocumentTypes.REPORT,
    title: "document_type_report",
  },
];
const sortOptions = (t) => [
  { label: t("submitted_at_asc"), value: APPROVAL_OBJECT_SORT_OPTIONS.SUBMISSION_DATE_ASC },
  { label: t("submitted_at_desc"), value: APPROVAL_OBJECT_SORT_OPTIONS.SUBMISSION_DATE_DESC },
  {
    label: t("amount_asc"),
    value: APPROVAL_OBJECT_SORT_OPTIONS.AMOUNT_ASC,
  },
  {
    label: t("amount_desc"),
    value: APPROVAL_OBJECT_SORT_OPTIONS.AMOUNT_DESC,
  },
];

const MORE_ACTIONS = (t) => [
  { label: t("approve_all_request"), value: "request", icon: <IconCustom name="money-send" /> },
  { label: t("approve_all_report"), value: "report", icon: <IconCustom name="receipt-custom" /> },
];

const WaitingApprovalScreen: FC<WaitingApprovalScreenProps> = ({ route, navigation }) => {
  const {
    user,
    company: { company_id: companyId },
  } = useAuth();
  const {
    setting: { isBatchApproveEnabled },
  } = useMasterData();
  const { t } = useTranslation("app/screens/WaitingApproval/WaitingApprovalList/WaitingApprovalScreen");
  const flashListRef = useRef<FlashList<any>>(null);
  const lastItemLoaded = useRef(false);
  const [queryParams, setQueryParams] = useState({
    condition: {
      companyId: companyId,
      employeeId: user.employee_id,
      type: DocumentTypes.ALL,
    },
    sortOption: APPROVAL_OBJECT_SORT_OPTIONS.SUBMISSION_DATE_ASC,
    limit: CONSTANTS.COMMON.PAGE_SIZE,
    offset: 0,
  });
  useEffect(() => {
    fetchData();
  }, []);
  const [currentSort, setCurrentSort] = useState<SelectItem>(sortOptions(t)[0]);
  const onApplySort = (sortValue: SelectItem) => {
    setQueryParams({
      ...queryParams,
      sortOption: sortValue.value,
      limit: CONSTANTS.COMMON.PAGE_SIZE,
      offset: 0,
    });
    setCurrentSort(sortValue);

    /*-- scroll header to top when apply sort --*/
    translationY.value = withTiming(0, {
      duration: 150,
      easing: Easing.linear,
    });
    analyticService.logEvent({ name: EVENT.OTHER.SORT_WAITING_APPROVAL_LIST, properties: { sort: sortValue?.value } });
  };
  const handleTypeChange = (type: DocumentTypes) => {
    lastItemLoaded.current = false;
    setQueryParams({
      ...queryParams,
      condition: { ...queryParams.condition, type },
      limit: CONSTANTS.COMMON.PAGE_SIZE,
      offset: 0,
    });
  };
  const onClickAction = (action) => {
    analyticService.logEvent({ name: EVENT.BATCH_APPROVAL.TAPPED });
    if (action === "report") {
      navigation.navigate(SCREEN_NAME.ReportBatchApprovalScreen, { sortOption: currentSort.value });
    } else {
      navigation.navigate(SCREEN_NAME.RequestBatchApprovalScreen, { sortOption: currentSort.value });
    }
  };
  const onRefreshDataWhenGoBack = () => {
    flashListRef.current?.scrollToOffset({ animated: false, offset: 0 });
    fetchData();
  };
  const renderHeaderRightButton = () => {
    return (
      <View style={styles.headerRightContainer}>
        <TouchableOpacity
          style={styles.rightButtonIcon}
          onPress={() =>
            navigation.navigate(SCREEN_NAME.WaitingApprovalSearchScreen, { onRefreshData: onRefreshDataWhenGoBack })
          }
        >
          <IconCustom name="search" />
        </TouchableOpacity>
        {isBatchApproveEnabled && (
          <CopilotSingleStepCustom
            featureName={FEATURE_FLAGS.RELEASE_SMP_9191}
            title={"mark_approve_title_feature"}
            text={"mark_approve_description_feature"}
            image={BizziBotSticker02}
            style={styles.rightButtonIcon}
            delay={500}
          >
            <SelectActionPicker
              title={t("action")}
              icon={<IconCustom name="more-vert" />}
              options={MORE_ACTIONS(t)}
              onSelect={onClickAction}
            />
          </CopilotSingleStepCustom>
        )}
      </View>
    );
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <BackHeader
          containerStyle={styles.backHeader}
          headerTitle={t("title")}
          headerRight={renderHeaderRightButton()}
        />
      ),
    } as any);
  }, [currentSort, renderHeaderRightButton]);

  const { fetchData, fetchMore, data, loading, networkStatus } = useWaitingApprovalObjects(queryParams);

  const handleLoadMore = () => {
    if (data.length < CONSTANTS.COMMON.PAGE_SIZE || lastItemLoaded.current || networkStatus !== NetworkStatus.ready) {
      return;
    }

    fetchMore({
      variables: { offset: data?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        const lastPatchSize = fetchMoreResult.expWaitingApprovalObjects.length;
        if (lastPatchSize === 0) {
          lastItemLoaded.current = true;
          return prev;
        }

        if (lastPatchSize < CONSTANTS.COMMON.PAGE_SIZE) {
          lastItemLoaded.current = true;
        }

        return {
          expWaitingApprovalObjects: [...prev.expWaitingApprovalObjects, ...fetchMoreResult.expWaitingApprovalObjects],
        };
      },
    });
  };

  const handleReload = () => {
    lastItemLoaded.current = false;
    fetchData();
  };

  const renderItem = ({ item }) => {
    const { request, report } = item;
    if (request) {
      return <ExpenseRequestItem item={request} showCreator onRefreshData={onRefreshDataWhenGoBack} />;
    }

    if (report) {
      return (
        <ReportItem
          eventName={EVENT.REPORT.TAP_VIEW_DETAIL}
          item={report}
          onPress={() => {
            const { expenseReportId } = report;
            navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
              expenseReportId: expenseReportId,
              onRefreshData: onRefreshDataWhenGoBack,
            });
          }}
          showCreator
        />
      );
    }

    return null;
  };

  const renderEmpty = () => {
    if (loading) {
      return null;
    }

    return (
      <EmptyData icon={<EmptyWaitingApproval />} title={t("empty_data")} description={t("empty_data_description")} />
    );
  };

  const renderFooter = () => {
    if (loading && data?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };

  const translationY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event, ctx) => {
      let { y } = event.contentOffset;
      if (y < 0) {
        y = 0;
      }
      const dy = y - ((ctx as any)?.prevY ?? 0);
      translationY.value = clampReanimated(translationY.value + dy, 0, FILTER_CONTAINER_HEIGHT);
      (ctx as any).prevY = y;
    },
  });
  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      translationY.value,
      [0, FILTER_CONTAINER_HEIGHT],
      [0, -FILTER_CONTAINER_HEIGHT],
      Extrapolation.CLAMP
    );
    return {
      transform: [{ translateY: translateY }],
    };
  });
  return (
    <View style={styles.container}>
      {/* Quick filter */}
      <Animated.View style={[styles.filterContainer, animatedStyle]}>
        <ScrollView
          horizontal
          scrollEnabled={false}
          automaticallyAdjustContentInsets={true}
          contentContainerStyle={styles.filterListContainer}
        >
          {DOCUMENT_TYPES.map((documentType, index) => {
            const isActive = documentType?.value === queryParams.condition.type;

            return (
              <TouchableOpacity
                key={`${documentType?.value}.${index}`}
                style={[styles.statusButton, isActive && { borderColor: Colors.primary50 }]}
                onPress={() => handleTypeChange(isActive ? DocumentTypes.ALL : documentType?.value)}
              >
                <AppText style={Fonts.BodyMedium}>{t(documentType.title as any)}</AppText>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
        <View style={styles.sortingContainer}>
          <SortingLabel
            sortingModalProps={{
              showClearButton: false,
              title: t("sort_by"),
              data: sortOptions(t),
              value: currentSort,
              setValue: onApplySort,
              onPress: () => () => analyticService.logEvent({ name: EVENT.OTHER.TAP_OPEN_SORT_WAITING_APPROVAL }),
            }}
          />
        </View>
      </Animated.View>
      {loading && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={styles.loadingContainer} />
      ) : (
        /** Waiting Approval Objects List */
        <AnimatedFlatList
          onScroll={scrollHandler}
          keyExtractor={(item: any, index) => item.request?.expenseRequestId ?? item.report?.expenseReportId ?? index}
          ref={flashListRef}
          data={data}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          estimatedItemSize={88}
          refreshControl={
            <RefreshControl
              progressViewOffset={CONSTANTS.EXPENSE_LIST.HEADER_STATUS_HEIGHT + 10}
              tintColor={Colors.primary50}
              refreshing={false}
              onRefresh={handleReload}
            />
          }
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
    </View>
  );
};
export default memo(WaitingApprovalScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  filterContainer: {
    position: "absolute",
    top: 0,
    zIndex: 1,
    width: "100%",
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
  filterListContainer: {
    paddingTop: 6,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  sortingContainer: {
    paddingVertical: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  listContainer: {
    paddingTop: FILTER_CONTAINER_HEIGHT - 10,
    paddingBottom: 50,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  loadingContainer: { marginTop: 60 },
  loadingMore: { marginTop: 10 },
  statusButton: {
    paddingHorizontal: 16,
    marginRight: 8,
    height: 40,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    alignItems: "center",
    justifyContent: "center",
  },
  headerRightContainer: { flexDirection: "row" },
  rightButtonIcon: { padding: RIGHT_ICON_PADDING },
  backHeader: {
    paddingBottom: 12 - RIGHT_ICON_PADDING,
    paddingTop: isIphoneX() ? 5 - RIGHT_ICON_PADDING : 18 - RIGHT_ICON_PADDING,
  },
});
