import { StyleSheet, View } from "react-native";
import { AppText, CurrencyText } from "components";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import ContentLoader, { Rect } from "react-content-loader/native";
import { FC } from "react";
import { MobileQueryCrdCardQuery } from "types";
import dayjs from "dayjs";
import { ArrowTimeIcon } from "assets/images/svg/icons";

const CardLoading = () => {
  return (
    <View>
      <View style={styles.cardItem}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: 22 }}>
          <ContentLoader animate={false} width={110} height={30}>
            <Rect x="0" y="0" rx="3" ry="3" width={110} height={30} />
          </ContentLoader>
          <ContentLoader animate={false} width={110} height={30}>
            <Rect x="0" y="0" rx="3" ry="3" width={110} height={30} />
          </ContentLoader>
        </View>
        <ContentLoader animate={false} width={"100%"} height={33}>
          <Rect x="0" y="0" rx="3" ry="3" width={"100%"} height={30} />
        </ContentLoader>
      </View>
    </View>
  );
};
interface CardInfoAssigneeProps {
  currentCard: MobileQueryCrdCardQuery["crdCard"]["card"];
  loading?: boolean;
}
const CardInfoAssignee: FC<CardInfoAssigneeProps> = ({ currentCard, loading }) => {
  const { t } = useTranslation("app/screens/Card/CardListScreen");
  if (loading) {
    return <CardLoading />;
  }

  const totalAmountUsed = currentCard?.currentUsageSchedule?.cardTransactions?.reduce((total, currentValue) => {
    return Math.abs(currentValue?.amount) + total;
  }, 0);

  return (
    <>
      <View style={styles.cardItem}>
        <View style={styles.rowItemCenter}>
          <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
            {t("total_amount_used")}
          </AppText>
          <CurrencyText style={Fonts.BodyMedium}>{totalAmountUsed}</CurrencyText>
        </View>
        <View style={styles.divider} />
        <View>
          <AppText style={[Fonts.BodySmall, styles.flex]} color={Colors.grayscale60}>
            {t("time")}
          </AppText>
          <View style={styles.usedTimeInfo}>
            <AppText style={Fonts.BodyMedium}>{`${dayjs(currentCard?.currentUsageSchedule?.fromDate).format(
              "HH:mm - DD/MM/YYYY"
            )}`}</AppText>
            <ArrowTimeIcon />
            <AppText style={Fonts.BodyMedium}>{`${dayjs(currentCard?.currentUsageSchedule?.toDate).format(
              "HH:mm - DD/MM/YYYY"
            )}`}</AppText>
          </View>
        </View>
      </View>
    </>
  );
};
export default CardInfoAssignee;

const styles = StyleSheet.create({
  cardItem: {
    padding: 20,
    marginTop: 20,
    borderRadius: 16,
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 4,
  },
  rowItemCenter: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 },
  divider: { height: 1, backgroundColor: Colors.grayscale0, marginVertical: 10 },
  flex: { flex: 1 },
  usedTimeInfo: { flexDirection: "row", gap: 6, alignItems: "center", marginTop: 8, flexWrap: "wrap" },
});
