import { FC, memo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";

import { AppText } from "components";

const STATUS = {
  1: {
    color: Colors.alert05,
    text: "awaiting_invoice",
  },
  2: {
    color: Colors.warning0,
    text: "need_detail",
  },
  3: {
    color: Colors.primary05,
    text: "expense_status_ready",
  },
  4: {
    color: Colors.info05,
    text: "requested_payment",
  },
};

interface StatusExpenseProps {
  value: number;
  style?: ViewStyle;
  textColor?: string;
}

const StatusExpense: FC<StatusExpenseProps> = ({ value, style, textColor = Colors.grayscale100 }) => {
  const { t } = useTranslation("app/components/ExpenseItem");
  if (!STATUS[value]?.color) {
    return null;
  }
  return (
    <View style={[styles.container, style, { backgroundColor: STATUS[value]?.color ?? Colors.info50 }]}>
      <AppText style={Fonts.BodySmall} color={textColor}>
        {t(STATUS[value]?.text ?? "")}
      </AppText>
    </View>
  );
};

export default memo(StatusExpense);
const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
  },
});
