import * as React from "react";
import Svg, { Circle, Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, fill = "#94A3B8" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" fill="none">
      <Circle cx={6} cy={6} r={6} fill="#fff" />
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={12} height={12}>
        <Path fill="#D9D9D9" d="M0 0H12V12H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M6 6.527l1.537 1.536c.069.07.156.105.26.107a.353.353 0 00.266-.107.359.359 0 00.11-.263.359.359 0 00-.11-.263L6.527 6l1.536-1.537a.362.362 0 00.107-.26.353.353 0 00-.107-.266.359.359 0 00-.263-.11.359.359 0 00-.263.11L6 5.473 4.463 3.937a.362.362 0 00-.26-.107.353.353 0 00-.266.107.359.359 0 00-.11.263c0 .103.037.191.11.263L5.473 6 3.937 7.537a.362.362 0 00-.107.26.353.353 0 00.107.266c.072.073.16.11.263.11a.359.359 0 00.263-.11L6 6.527zm0 4.223a4.628 4.628 0 01-1.852-.374A4.8 4.8 0 012.64 9.361a4.796 4.796 0 01-1.016-1.508 4.623 4.623 0 01-.374-1.852c0-.657.125-1.275.374-1.853.25-.578.588-1.08 1.015-1.508.428-.428.93-.766 1.508-1.016a4.623 4.623 0 011.852-.374c.657 0 1.275.125 1.853.374.578.25 1.08.588 1.508 1.015.428.428.766.93 1.016 1.508s.374 1.195.374 1.852-.125 1.275-.374 1.853A4.8 4.8 0 019.361 9.36c-.428.428-.93.766-1.508 1.016a4.622 4.622 0 01-1.852.374z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
