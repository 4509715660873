import { ImageSourcePropType, ViewProps } from "react-native";

export interface FileBase {
  id: string;
  uri: string;
  name?: string;
  type?: string;
  size?: number;
  fileType?: string;
}

export interface UploadFileItem {
  id: string;
  uri: string;
  name?: string;
  type?: string;
  size?: number;
  fileType?: string;
  createdAt?: Date;
  customFields?: Record<string, any>;
}
export type ImageFileBase = FileBase &
  Pick<ViewProps, "style"> & {
    index: number;
    name?: string;
  };

export type ImageFileProps = Omit<ImageFileBase, "id"> & {
  icon: ImageSourcePropType;
};

export interface ImageItemProps extends ImageFileBase {
  onRemove?: (id: string) => void;
}
/*
*
  xml: XmlIcon,
  pdf: PdfFileIcon,
  xsl: XslIcon,
  xls: XslIcon,
  xslt: XslIcon,
  xlsx: XslIcon,
  doc: DocxFileIcon,
  docx: DocxFileIcon,
  *
      ext?.toLowerCase() === "png" ||
      ext?.toLowerCase() === "jpg" ||
      ext?.toLowerCase() === "jpeg"
* */
export enum EXTENSION_FILE {
  XML = "xml",
  PDF = "pdf",
  XSL = "xsl",
  XLS = "xls",
  XSLT = "xslt",
  XLSX = "xlsx",
  DOC = "doc",
  DOCX = "docx",
  PNG = "png",
  JPG = "jpg",
  IMAGE_JPG = "image/jpg",
  JPEG = "jpeg",
  ZIP = "zip",
  EML = "eml",
  CSV = "csv",
  MSG = "msg",
}
