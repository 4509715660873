import { RefreshControl, ScrollView, StyleSheet, View } from "react-native";
import { AlertNotification, AlertNotificationHandle, AppText, ImageCustom, useWindowDimensions } from "components";
import { Colors, Fonts, FontTypes } from "theme";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { CARD_REGISTRATION_STATUS, CONSTANTS } from "constants/constants";
import { BizziCard } from "assets/images";
import { useTranslation } from "react-i18next";
import React, { FC, useRef } from "react";
import {
  BizziBotCry,
  BizziTextCardIcon,
  CardLogoIcon,
  ShinhanBankCardText,
  VisaCardText,
} from "assets/images/svg/icons";
import ProcessStatusTimeLine from "./ProcessStatusTimeLine";

interface CardStatusProcessSectionProps {
  onRefreshData: () => void;
  status: CARD_REGISTRATION_STATUS;
}
const CardOpeningProcess: FC<CardStatusProcessSectionProps> = ({ onRefreshData, status }) => {
  const { top } = useSafeAreaInsets();
  const { t } = useTranslation("app/screens/Card/CardListScreen");
  const { width } = useWindowDimensions();

  const CARD_WIDTH = width - 40;
  const CARD_HEIGHT = CARD_WIDTH / 1.58;
  const alertNotification = useRef<AlertNotificationHandle>();

  return (
    <>
      <View style={[styles.container, { paddingTop: top + 12 }]}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.contentContainer}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={onRefreshData} />}
        >
          <AppText style={Fonts.H500}>{t("process_card_opening")}</AppText>
          {/*-- Card Item ---*/}
          {status === CARD_REGISTRATION_STATUS.BANK_REJECTED || status === CARD_REGISTRATION_STATUS.BIZZI_REJECTED ? (
            <View style={styles.rejectContainer}>
              <BizziBotCry />
              <AppText style={[Fonts.H400, styles.rejectTitle]}>
                {status === CARD_REGISTRATION_STATUS.BANK_REJECTED
                  ? t("bank_rejected_title")
                  : t("bizzi_rejected_title")}
              </AppText>
              <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale80}>
                {t("bank_rejected_description")}
              </AppText>
            </View>
          ) : (
            <>
              <AppText style={[Fonts.BodyMedium, styles.description]} color={Colors.grayscale80}>
                {t("registration_card_success")}
              </AppText>
              <View style={styles.cardItem}>
                <ImageCustom
                  source={BizziCard}
                  containerStyle={[styles.cardImage, { width: CARD_WIDTH, height: CARD_HEIGHT }]}
                  style={[styles.cardImage, { width: CARD_WIDTH, height: CARD_HEIGHT }]}
                />
                <View style={styles.leftHeaderCard}>
                  <BizziTextCardIcon />
                </View>
                <View style={styles.rightHeaderCard}>
                  <ShinhanBankCardText />
                </View>
                <View style={styles.cardRightLogo}>
                  <CardLogoIcon />
                </View>
                <View style={[styles.visaCardText, { top: CARD_HEIGHT - 40 }]}>
                  <VisaCardText />
                </View>
                <View style={styles.accountHolderInfo}>
                  <AppText style={styles.cardNumberText} color={Colors.white}>
                    {"1234 56** **** 1234"}
                  </AppText>
                  <AppText style={styles.fullname} color={Colors.white}>
                    BIZZI BOT
                  </AppText>
                </View>
              </View>
            </>
          )}
          {/*-- end ---*/}
          <ProcessStatusTimeLine status={status} />
        </ScrollView>
      </View>
      <AlertNotification ref={alertNotification} />
    </>
  );
};
export default CardOpeningProcess;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  description: { marginTop: 8 },
  contentContainer: { paddingBottom: 20 },
  cardItem: {
    marginTop: 20,
    shadowColor: "rgba(68, 120, 255, 0.8)",
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  leftHeaderCard: { left: 12, top: 12, position: "absolute" },
  accountHolderInfo: { left: 12, top: 38, position: "absolute" },
  rightHeaderCard: { right: 12, top: 12, position: "absolute" },
  cardRightLogo: { right: 30, top: 40, position: "absolute" },
  visaCardText: { right: 13, position: "absolute" },

  cardNumberText: { fontSize: 17, fontFamily: FontTypes.medium },
  fullname: { fontSize: 17, fontFamily: FontTypes.semiBold, marginTop: 7 },
  cardImage: { borderRadius: 16 },
  rejectContainer: { justifyContent: "center", alignItems: "center", paddingTop: 35, paddingBottom: 10 },
  rejectTitle: { textAlign: "center", marginBottom: 8, marginTop: 20 },
  textCenter: { textAlign: "center" },
});
