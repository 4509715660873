import * as React from "react";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import CopilotStepCustom from "./CopilotStepCustom";
import { useCopilot } from "react-native-copilot";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Keys } from "constants/Keys";
import { useFeatureManagement } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import dayjs from "dayjs";
import { CONSTANTS } from "constants/constants";

interface CopilotSingleStepCustomProps {
  name?: string;
  title?: string;
  text: string;
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
  image: ReactNode;
  featureName?: string;
  delay?: number;
  isProductHint?: boolean;
}
const CopilotSingleStepCustom: FC<CopilotSingleStepCustomProps> = ({
  featureName,
  title,
  text,
  style,
  children,
  image,
  delay = 0,
}) => {
  const { start, visible } = useCopilot();
  const [shouldShowProductHint, setShouldShowProductHint] = useState(false);
  const isShowedProductHint = useRef(false);

  const { features } = useFeatureManagement();

  useEffect(() => {
    if (Object.keys(features)?.length > 0) {
      handleCheckShowProductHint();
    }
  }, [features]);

  useEffect(() => {
    if (!isShowedProductHint.current && shouldShowProductHint) {
      isShowedProductHint.current = visible;
      setTimeout(() => {
        start({ fromStep: "0", walkthroughName: CONSTANTS.WALK_THROUGH.SINGLE_STEP });
      }, delay);
      markViewedProductHintFeature();
    }
  }, [start, shouldShowProductHint]);

  const handleCheckShowProductHint = async () => {
    if (!featureName) {
      const viewedFeatureIds = await AsyncStorage.getItem(Keys.VIEWED_NEW_FEATURE_IDS);
      const isViewedFeature = viewedFeatureIds ? JSON.parse(viewedFeatureIds).includes(featureName) : false;
      setShouldShowProductHint(!isViewedFeature);
      return;
    }
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_PROD_HINT] ?? {};
    if (!enabled || !customFields?.productHint?.mobile) {
      setShouldShowProductHint(false);
      return;
    }
    const now = dayjs();
    const productHintFeature = customFields?.productHint?.mobile?.find(
      (item) => item?.feature === featureName && now.isAfter(item?.from_date) && now.isBefore(item?.to_date)
    );
    if (!productHintFeature) {
      setShouldShowProductHint(false);
      return;
    }
    if (productHintFeature.hidden_after_seen) {
      const viewedFeatureIds = await AsyncStorage.getItem(Keys.VIEWED_NEW_FEATURE_IDS);
      const isViewedFeature = viewedFeatureIds ? JSON.parse(viewedFeatureIds).includes(featureName) : false;
      setShouldShowProductHint(!isViewedFeature);
      return;
    }
    setShouldShowProductHint(true);
  };
  const markViewedProductHintFeature = async () => {
    const viewedFeatureIds = await AsyncStorage.getItem(Keys.VIEWED_NEW_FEATURE_IDS);
    const viewedFeatureIdsJson = viewedFeatureIds ? JSON.parse(viewedFeatureIds) : [];
    const isViewedFeature = viewedFeatureIdsJson.find((item) => item === featureName);
    if (!isViewedFeature) {
      await AsyncStorage.setItem(
        Keys.VIEWED_NEW_FEATURE_IDS,
        JSON.stringify([...viewedFeatureIdsJson, ...[featureName]])
      );
    }
  };

  return (
    <CopilotStepCustom
      walkthroughName={CONSTANTS.WALK_THROUGH.SINGLE_STEP}
      title={title}
      text={text}
      order={0}
      image={image}
      singleStep={true}
      style={style}
    >
      {children}
    </CopilotStepCustom>
  );
};
export default CopilotSingleStepCustom;
