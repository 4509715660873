import { StyleSheet, View } from "react-native";
import React, { FC } from "react";
import { AppText, CurrencyText, DashedLine, IconCustom } from "components";
import { Colors, Fonts } from "theme";
import { BookingInformation } from "screens/FlightBooking/types";
import { useTranslation } from "react-i18next";

interface FlightTicketAmountDetailProps {
  fares?: BookingInformation["listBooking"][0]["fares"];
}
const FlightTicketAmountDetail: FC<FlightTicketAmountDetailProps> = ({ fares }) => {
  const { t } = useTranslation("app/screens/FlightBooking/components/FlightBookingComponent");

  return (
    <View style={styles.container}>
      {fares?.map((item, index) => {
        const flight = item?.flights?.[0];
        return (
          <View key={index}>
            <DashedLine />
            <View style={styles.summaryItem}>
              <View style={[styles.flexRow, styles.flex]}>
                <IconCustom name={index === 0 ? "flight-takeoff" : "flight-land"} />
                <AppText style={Fonts.BodyMedium}>{`${flight?.departure?.code} - ${flight?.arrival?.code}`}</AppText>
              </View>
            </View>
            <View style={styles.summaryDetailRow}>
              <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>
                {t("number_of_passenger", {
                  numberOfPassenger: 1,
                })}
              </AppText>
              <CurrencyText style={Fonts.NumericN200}>{item?.bookingInfo?.price}</CurrencyText>
            </View>
            {item?.bookingInfo?.baggage
              ?.filter((item) => item?.price)
              ?.map((item, index) => (
                <View key={index} style={styles.summaryDetailRow}>
                  <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>{`${t("number_of_extra_luggage", {
                    numberOfExtraLuggage: 1,
                  })} ${item?.weight}${item?.weightUnit?.toLowerCase()}`}</AppText>
                  <CurrencyText style={Fonts.NumericN200}>{item?.price}</CurrencyText>
                </View>
              ))}
          </View>
        );
      })}
    </View>
  );
};
export default FlightTicketAmountDetail;

const styles = StyleSheet.create({
  container: { paddingVertical: 2 },
  summaryItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 8,
    paddingVertical: 8,
  },
  summaryDetailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 8,
    paddingBottom: 8,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  flex: {
    flex: 1,
  },
});
