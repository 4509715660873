import * as authClient from "utils/authClient";

/*
 * Singleton to handle logout when refresh token expired.
 */
class AuthHelper {
  constructor() {
    if (AuthHelper.instance) {
      return AuthHelper.instance;
    }
    AuthHelper.instance = this;
  }

  private static instance: AuthHelper;
  public static getInstance(): AuthHelper {
    if (!AuthHelper.instance) {
      AuthHelper.instance = new AuthHelper();
    }
    return AuthHelper.instance;
  }
  private setUserData: (user?: Record<string, string>) => void;

  public configure({ setUserData }) {
    this.setUserData = setUserData;
  }

  public async logout() {
    await authClient.logout();
    this.setUserData({});
  }
}
export default AuthHelper;
