import AntIcon from "react-native-vector-icons/AntDesign";

import ZocialIcon from "react-native-vector-icons/Zocial";
import SimpleLineIcon from "react-native-vector-icons/SimpleLineIcons";
import Entypo from "react-native-vector-icons/Entypo";
import Ionicons from "react-native-vector-icons/Ionicons";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import Octicons from "react-native-vector-icons/Octicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import Feather from "react-native-vector-icons/Feather";

export default (type) => {
  switch (type) {
    case "Zocial":
      return ZocialIcon;
    case "SimpleLineIcon":
      return SimpleLineIcon;
    case "Feather":
      return Feather;
    case "Antdesign":
      return AntIcon;
    case "Entypo":
      return Entypo;
    case "Ionicons":
      return Ionicons;
    case "FontAwesome":
      return FontAwesome;
    case "Octicons":
      return Octicons;
    case "MaterialCommunityIcons":
      return MaterialCommunityIcons;
    case "MaterialIcons":
      return MaterialIcons;
    case "FontAwesome5":
      return FontAwesome5;
    default:
      return SimpleLineIcon;
  }
};
