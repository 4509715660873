import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 14, color = "#E11934" }) {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      <Path
        d="M7.003 13.667a6.667 6.667 0 110-13.333 6.667 6.667 0 010 13.333zm0-7.61L5.117 4.171l-.943.944L6.06 7 4.174 8.885l.943.944 1.886-1.886 1.885 1.886.943-.944L7.945 7l1.886-1.885-.943-.944-1.885 1.886z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
