import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#fff" d="M0.625732 0.0793457H24.625732V24.0793457H0.625732z" />
      </Mask>
      <G mask="url(#a)">
        <Path d="M12.626 15.68l3.2-4.6h-2.85l2-7h-5.35v8h3v3.6zm-2 6.4v-8h-3v-12h10l-2 7h4l-9 13z" fill="#fff" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
