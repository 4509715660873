import Svg, { Path, SvgProps } from "react-native-svg";

function SvgComponent({ width = 20, height = 20, stroke = "#94A3B8", fill = "none" }: SvgProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill={fill}>
      <Path
        d="M9.583 17.5a7.917 7.917 0 100-15.833 7.917 7.917 0 000 15.833zM18.333 18.333l-1.666-1.666"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
