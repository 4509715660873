import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 64 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 0c-.825 0-1.5.675-1.5 1.5v21c0 .825.675 1.5 1.5 1.5h15c.825 0 1.5-.675 1.5-1.5V6l-6-6H6z"
        fill="#E2E5E7"
      />
      <Path d="M18 6h4.5l-6-6v4.5c0 .825.675 1.5 1.5 1.5z" fill="#B0B7BD" />
      <Path d="M22.5 10.5L18 6h4.5v4.5z" fill="#CAD1D8" />
      <Path
        d="M19.5 19.5c0 .413-.337.75-.75.75H2.25a.752.752 0 01-.75-.75V12c0-.412.337-.75.75-.75h16.5c.413 0 .75.338.75.75v7.5z"
        fill="#A066AA"
      />
      <Path
        d="M4.352 14.208c0-.198.155-.415.407-.415h1.386c.78 0 1.481.522 1.481 1.523 0 .948-.702 1.475-1.481 1.475H5.142v.793c0 .264-.166.413-.383.413a.404.404 0 01-.407-.413v-3.376zm.79.341v1.493h1.002c.401 0 .72-.354.72-.726 0-.42-.319-.767-.72-.767H5.142zM8.391 14.268c0-.217.048-.426.36-.426.216 0 .264.054.426.209l1.984 2.483v-2.326c0-.198.173-.415.378-.415.216 0 .425.217.425.415v3.376c0 .264-.162.365-.318.413-.209 0-.312-.047-.485-.209L9.177 15.27v2.315c0 .265-.162.414-.378.414a.395.395 0 01-.408-.414v-3.316zM16.476 17.538c-.426.353-.948.504-1.475.504-1.26 0-2.153-.72-2.153-2.147 0-1.212.942-2.153 2.206-2.153.474 0 .995.162 1.367.528.366.36-.162.893-.52.576-.223-.217-.535-.378-.847-.378-.725 0-1.426.581-1.426 1.427 0 .888.587 1.427 1.373 1.427.365 0 .677-.108.9-.27v-.737H15c-.534 0-.48-.732 0-.732h1.2c.221 0 .424.168.424.354v1.278c0 .107-.047.208-.149.323z"
        fill="#fff"
      />
      <Path d="M18.75 20.25H4.5V21h14.25c.413 0 .75-.337.75-.75v-.75c0 .413-.337.75-.75.75z" fill="#CAD1D8" />
    </Svg>
  );
}

export default SvgComponent;
