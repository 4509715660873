import { AppText, BottomSheetModalCustomMethods, IconCustom, TextInputCustom } from "components";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import BottomSheetScrollViewModalCustom from "components/BottomSheetScrollViewModalCustom/BottomSheetScrollViewModalCustom";

const UnitSelectInput = ({ control, setValue, getValues, unitPrices, rules, error }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/AddAllowanceScreen/AddAllowanceScreen");
  const modalRef = useRef<BottomSheetModalCustomMethods>();

  const handleOpenModal = () => {
    modalRef.current.present();
  };

  const handleSelectItem = (item) => () => {
    setValue("uom", item.unit, { shouldValidate: true });
    setValue("unitPrice", item.unitPrice, { shouldValidate: true });
    setValue("amount", item.unitPrice * (getValues("quantity") ?? 0));
    modalRef?.current?.close();
  };

  const renderItem = ({ item }) => (
    <>
      <TouchableOpacity style={styles.productItem} onPress={handleSelectItem(item)}>
        <IconCustom name="inventory-2" />
        <AppText style={[Fonts.BodyLarge, styles.flex]}>{item?.unit}</AppText>
      </TouchableOpacity>
      <View style={styles.divider} />
    </>
  );

  return (
    <>
      <TextInputCustom
        name="uom"
        control={control}
        rules={rules}
        error={error}
        label={t("uom")}
        placeholder={t("uom")}
        absoluteRight={
          unitPrices?.length > 0 ? (
            <TouchableOpacity activeOpacity={1} style={styles.rightButton} onPress={handleOpenModal}>
              <IconCustom name="inventory-2" fill={Colors.grayscale60} />
            </TouchableOpacity>
          ) : null
        }
      />
      <BottomSheetScrollViewModalCustom title={t("select_uom")} ref={modalRef}>
        {unitPrices?.map((item) => renderItem({ item }))}
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default UnitSelectInput;

const styles = StyleSheet.create({
  rightButton: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  productItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  flex: { flex: 1 },

  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
