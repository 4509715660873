import * as React from "react";
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg width={120} height={120} viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <G clipPath="url(#clip0_5840_48187)">
      <Path
        d="M35.0938 74.3493C37.1197 74.9669 39.4256 75.3046 41.8715 75.2799C44.3174 75.2552 46.615 74.8681 48.6245 74.2093C46.6562 72.4222 44.3091 71.2939 41.8303 71.3269C39.3514 71.3516 37.0455 72.5128 35.0938 74.3493Z"
        fill="#C0CAD8"
      />
      <Path
        d="M13.1957 10.2453C13.9534 9.60293 18.8205 15.2853 17.7663 16.3312C16.7204 17.3771 12.1251 11.1512 13.1957 10.2453Z"
        fill="#1B1B1D"
      />
      <Path
        d="M20.7395 8.80434C21.3242 8.60669 22.8231 12.9879 21.9748 13.3503C21.1266 13.7126 19.916 9.08434 20.7395 8.80434Z"
        fill="#1B1B1D"
      />
      <Path
        d="M9.47249 16.5618C9.7772 15.9277 14.5619 18.1841 14.1914 19.1642C13.8125 20.1442 9.04425 17.4594 9.47249 16.5618Z"
        fill="#1B1B1D"
      />
      <Path
        d="M76.5165 48.9516C74.9188 65.3071 58.8187 72.1671 39.9679 70.3224C21.1171 68.4777 6.65584 58.62 8.2535 42.281C9.85116 25.9338 26.7501 10.896 45.6091 12.7407C64.4681 14.5772 78.1141 32.5962 76.5165 48.9516Z"
        fill="#E4EAF1"
      />
      <Path
        d="M31.0003 14.6431L27.3438 52.0646C31.552 53.8764 36.3038 55.1035 41.418 55.5976C46.5157 56.1 51.424 55.82 55.9122 54.8564L59.5687 17.4349C55.4264 14.9149 50.7075 13.2349 45.6098 12.7408C40.5121 12.2384 35.5544 12.9714 31.0003 14.6431Z"
        fill="#1F71F4"
      />
      <Path
        d="M41.9951 28.7997L41.2786 28.7338L41.9704 26.6008L43.4445 22.0467L43.5186 21.7996L39.8868 21.4455L41.7974 15.6642L39.6562 15.4584L44.9845 12.6748L48.1057 16.2819L46.4504 16.1172L45.701 18.4313L50.4775 18.9007L41.9951 28.7997Z"
        fill="white"
      />
      <Path
        d="M16.6554 52.6C12.7765 46.7117 19.3236 29.0057 25.6319 25.8762C34.1061 21.6762 57.6098 24.5997 64.5358 31.0316C69.5594 35.6928 71.6264 53.9835 66.9734 58.2741C57.6757 66.8389 23.5731 63.1083 16.6554 52.6Z"
        fill="#1B1B1D"
      />
      <Path
        d="M37.2847 42.7833C33.9247 43.7221 34.9953 42.2398 31.8988 42.6598C28.8023 43.088 29.0823 43.7633 26.7435 43.4421C23.2928 42.9645 26.5787 36.5903 31.4953 36.4173C37.5318 36.2032 39.8706 42.0586 37.2847 42.7833Z"
        fill="white"
      />
      <Path
        d="M54.9067 38.7315C59.6996 39.868 61.6761 46.761 58.1926 46.5551C55.829 46.4151 56.2408 45.8057 53.2843 44.7763C50.3278 43.7551 51.0855 45.4187 47.9807 43.8375C45.5925 42.6269 49.0349 37.3398 54.9067 38.7315Z"
        fill="white"
      />
      <Path
        d="M10.2801 47.7248L6.45887 46.3412C5.75886 46.0942 5.31415 45.2542 5.3965 44.3565L6.03886 37.7847C6.12945 36.887 6.7224 36.1541 7.48005 36.047L11.4989 35.4376C12.5036 35.2894 13.3272 36.2776 13.2119 37.4882L12.3719 46.053C12.2401 47.2389 11.2354 48.0624 10.2801 47.7248Z"
        fill="#C0CAD8"
      />
      <Path
        d="M8.1142 46.9836L4.60593 45.7319C3.95534 45.5013 3.55181 44.7354 3.62592 43.9036L4.21064 37.8918C4.29299 37.06 4.83652 36.4012 5.52006 36.3024L9.20127 35.7588C10.1236 35.6188 10.873 36.533 10.766 37.6283L10.0001 45.4519C9.90127 46.5636 8.97068 47.2966 8.1142 46.9836Z"
        fill="#E4EAF1"
      />
      <Path
        d="M73.4191 54.4201L77.4379 53.8107C78.1873 53.7036 78.7885 52.9707 78.8791 52.073L79.5215 45.5012C79.6121 44.6035 79.1591 43.7635 78.4426 43.5165L74.6214 42.1329C73.6661 41.7953 72.6614 42.6023 72.5461 43.8129L71.7061 52.3777C71.5908 53.5801 72.4144 54.5848 73.4191 54.4201Z"
        fill="#C0CAD8"
      />
      <Path
        d="M75.7097 54.1315L79.3909 53.588C80.0745 53.4892 80.618 52.8139 80.7003 51.9986L81.2851 45.9867C81.3674 45.155 80.9639 44.4055 80.3051 44.175L76.7968 42.9232C75.9238 42.6102 75.0015 43.3597 74.8944 44.455L74.1285 52.2786C74.0379 53.3574 74.7874 54.2715 75.7097 54.1315Z"
        fill="#E4EAF1"
      />
      <Path
        d="M20.8223 54.7577C30.7624 61.2389 52.1579 63.4707 62.9297 59.1059C64.4203 58.5048 65.3921 57.0389 65.3345 55.433C65.2357 52.3859 64.6262 49.0753 62.5509 48.367C59.6438 47.3788 54.892 52.5423 42.4978 52.2047C30.1036 51.867 27.9953 46.6046 23.3753 45.4435C20.7729 44.7846 19.6611 48.0541 19.1835 51.027C18.9529 52.4765 19.587 53.9506 20.8223 54.7577Z"
        fill="#6B3C41"
      />
      <Path
        d="M52.7766 59.7811C53.0895 57.4587 48.5189 54.9222 42.5729 54.1234C36.627 53.3245 31.554 54.5598 31.2411 56.8822C31.1258 57.7634 31.7023 58.6693 32.7893 59.5011C38.8506 60.967 45.6612 61.6093 51.7471 61.2717C52.3319 60.8517 52.6942 60.3493 52.7766 59.7811Z"
        fill="#F9595F"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5840_48187">
        <Rect width={77.6842} height={66.4924} fill="white" transform="translate(3.61719 8.7959)" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SVGComponent;
