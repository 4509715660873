import { FC, ReactElement, useEffect, useRef } from "react";
import { Platform, ScrollView, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { AppText, useWindowDimensions } from "components/index";
import { Colors, Fonts } from "theme";

interface TabViewInsideScrollViewProps {
  tabBarStyle?: StyleProp<ViewStyle>;
  routes?: { key: string; title: string; component: ReactElement }[];
  index?: number;
  setIndex?: (value: number) => void;
}
const TabViewInsideScrollView: FC<TabViewInsideScrollViewProps> = ({
  tabBarStyle,
  routes,
  index: currentIndex,
  setIndex,
}) => {
  const { width } = useWindowDimensions();

  const scrollViewRef = useRef<ScrollView>(null);

  const onMomentumScrollEnd = (event) => {
    setIndex(Math.round(event.nativeEvent.contentOffset.x / width));
  };

  useEffect(() => {
    setTimeout(() => {
      scrollViewRef?.current?.scrollTo({
        x: currentIndex * width,
        animated: true,
      });
    }, 0);
  }, [currentIndex]);

  return (
    <View>
      <View style={[styles.tabViewButtonContainer, tabBarStyle]}>
        {routes?.map((item, index) => {
          const isActive = currentIndex === index;
          return (
            <TouchableOpacity
              style={[styles.tabBarStyle, isActive && { backgroundColor: Colors.white }]}
              onPress={() => setIndex(index)}
            >
              <AppText style={Fonts.H200} color={isActive ? Colors.grayscale100 : Colors.grayscale80}>
                {item?.title}
              </AppText>
            </TouchableOpacity>
          );
        })}
      </View>
      <ScrollView
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onMomentumScrollEnd={onMomentumScrollEnd}
        ref={scrollViewRef}
        scrollEnabled={false}
      >
        {routes?.map((item, index) => (
          <View key={index} style={{ width: width }}>
            {Platform.OS === "web" ? item?.component : currentIndex === index ? item?.component : null}
          </View>
        ))}
      </ScrollView>
    </View>
  );
};
export default TabViewInsideScrollView;
const styles = StyleSheet.create({
  tabViewButtonContainer: {
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    elevation: 0,
    backgroundColor: Colors.grayscale05,
    height: 48,
    borderRadius: 40,
    marginBottom: 3,
    paddingVertical: 4,
    paddingHorizontal: 4,
  },
  tabBarStyle: { flex: 1, justifyContent: "center", alignItems: "center", borderRadius: 40 },
});
