import React, { useEffect, useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  ActivityIndicator,
  Keyboard,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";

import {
  AppText,
  EmptyData,
  FlatListCustom,
  IconCustom,
  SearchInput,
  SelectCompanyTeam,
  SelectDate,
  SkeletonListLoading,
} from "components";
import { BackButtonIcon, EmptyResultFound, SearchNotFoundIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { RadioSelectFilter, SelectEmployee } from "components/AdvanceSearch";

import { APPROVAL_OBJECT_SORT_OPTIONS, FilterData } from "./types";
import { CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import ReportItem from "screens/Report/ReportListScreen/components/ReportItem";
import SCREEN_NAME from "navigation/ScreenName";
import useWaitingApprovalObjects from "screens/WaitingApproval/hooks/useWaitingApprovalObjects";
import ExpenseRequestItem from "components/ExpenseRequestItem";
import { NetworkStatus } from "@apollo/client";
import { EVENT } from "constants/Tracking";
import { goBack } from "navigation/RootNavigation";
import { executeRouteFunction } from "utils/route";

const sortOptions = (t) => [
  { label: t("requested_at_asc"), value: APPROVAL_OBJECT_SORT_OPTIONS.REQUESTED_AT_ASC },
  { label: t("requested_at_desc"), value: APPROVAL_OBJECT_SORT_OPTIONS.REQUESTED_AT_DESC },
  {
    label: t("expense_asc"),
    value: APPROVAL_OBJECT_SORT_OPTIONS.AMOUNT_ASC,
  },
  {
    label: t("expense_desc"),
    value: APPROVAL_OBJECT_SORT_OPTIONS.AMOUNT_DESC,
  },
];

const WaitingApprovalSearchScreen = ({ route }) => {
  const { t } = useTranslation("app/screens/WaitingApproval/WaitingApprovalSearchScreen");
  const [keyword, setKeyword] = useState("");
  const navigation = useNavigation<any>();
  const { top } = useSafeAreaInsets();
  const searchInputRef = useRef<TextInput>();
  const disabledLoadMore = useRef(false);
  const flashListRef = useRef<FlashList<any>>(null);

  const [filter, setFilter] = useState<FilterData>({
    sort: sortOptions(t)[0],
  });
  const debounceSearchTimeoutId = useRef(null);
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const { data, loading, fetchMore, refetch, networkStatus, previousData } = useWaitingApprovalObjects({
    condition: {
      companyId,
      employeeId,
      keyword: keyword,
      senderId: filter?.employee?.employeeId ? filter?.employee?.employeeId : undefined,
      submittedDate: filter?.submittedDate?.fromDate
        ? {
            fromDate: filter?.submittedDate?.fromDate,
            toDate: filter?.submittedDate?.toDate,
          }
        : undefined,
      companyTeamId: filter?.companyTeam?.teamId ? filter?.companyTeam?.teamId : undefined,
      type: "ALL",
    },
    sortOption: filter?.sort?.value,
    limit: CONSTANTS.COMMON.PAGE_SIZE,
    offset: 0,
  });
  useEffect(() => {
    setTimeout(() => {
      searchInputRef?.current?.focus();
    }, 150);
  }, []);

  const isFiltering =
    keyword.trim() ||
    filter?.employee ||
    filter?.companyTeam ||
    (filter?.submittedDate?.fromDate && filter?.submittedDate?.toDate);

  useEffect(() => {
    if (isFiltering) {
      refetch?.();
    }
  }, [filter, keyword]);
  const onChangeFilter = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      setKeyword(text);
    }, 400);
  };
  const onResetSearch = () => {
    setKeyword("");
    searchInputRef?.current?.setNativeProps({ text: "" });
  };
  const onClearFilter = () => {
    setKeyword("");
    setFilter(null);
  };
  const renderEmpty = () => {
    return <EmptyData icon={<SearchNotFoundIcon />} title={t("search_not_found")} />;
  };
  const onRefreshDataWhenGoBack = () => {
    flashListRef.current?.scrollToOffset({ animated: false, offset: 0 });
    refetch?.();
    executeRouteFunction(route, "onRefreshData");
  };
  const handleClickReportItem = (item) => () => {
    navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
      expenseReportId: item?.expenseReportId,
      onRefreshData: onRefreshDataWhenGoBack,
    });
  };
  const renderItem = ({ item }) => {
    const { request, report } = item;
    if (report) {
      return (
        <ReportItem
          showCreator
          eventName={EVENT.REPORT.TAP_VIEW_DETAIL}
          onPress={handleClickReportItem(report)}
          item={report}
        />
      );
    }
    return <ExpenseRequestItem onRefreshData={onRefreshDataWhenGoBack} showCreator item={request} />;
  };
  const renderFooter = () => {
    if (loading && data?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const handleLoadMore = () => {
    if (loading || !previousData || disabledLoadMore?.current || data?.length < CONSTANTS.COMMON.PAGE_SIZE) {
      return;
    }
    fetchMore?.({
      variables: { offset: data?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || fetchMoreResult?.expWaitingApprovalObjects?.length === 0) {
          disabledLoadMore.current = true;
          return prev;
        }
        return {
          ...prev,
          expWaitingApprovalObjects: [...prev.expWaitingApprovalObjects, ...fetchMoreResult.expWaitingApprovalObjects],
        };
      },
    });
  };
  return (
    <View style={styles.container}>
      <View style={[styles.headerContainer, { paddingTop: top || 20 }]}>
        <View style={styles.searchContainer}>
          <TouchableOpacity style={{ marginTop: 6 }} onPress={goBack}>
            <BackButtonIcon />
          </TouchableOpacity>
          <SearchInput
            numberOfLines={1}
            ref={searchInputRef}
            style={styles.searchInput}
            placeholder={t("search")}
            onChangeText={onChangeSearch}
            autoCapitalize="none"
            placeholderTextColor={Colors.grayscale60}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            right={
              keyword ? (
                <TouchableOpacity onPress={onResetSearch}>
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : null
            }
          />
        </View>
        <ScrollView keyboardShouldPersistTaps="always" horizontal showsHorizontalScrollIndicator={false}>
          <View style={styles.searchItemContainer}>
            <SelectEmployee
              value={filter?.employee}
              setValue={(value) => onChangeFilter("employee", value)}
              title={t("employee")}
            />
            <SelectDate
              title={t("submitted_date")}
              labelDateValue={filter?.submittedDate?.labelDateValue}
              setValue={(label, from, to) => {
                onChangeFilter("submittedDate", { fromDate: from, toDate: to, labelDateValue: label });
              }}
            />
            <SelectCompanyTeam
              title={t("unit")}
              value={filter?.companyTeam}
              setValue={(value) => onChangeFilter("companyTeam", value)}
            />
            <RadioSelectFilter
              showClearFilter={false}
              showClearButton={false}
              title={t("sort_by")}
              data={sortOptions(t)}
              minWidth={100}
              value={filter?.sort}
              setValue={(value) => onChangeFilter("sort", value)}
            />
            <TouchableOpacity onPress={onClearFilter}>
              <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                {t("clear_filter")}
              </AppText>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
      {!isFiltering ? (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.emptyContainer}>
            <EmptyData icon={<EmptyResultFound />} title={t("search_title")} description={t("search_description")} />
          </View>
        </TouchableWithoutFeedback>
      ) : loading && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={{ marginTop: 5 }} />
      ) : (
        <FlatListCustom
          ref={flashListRef}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.contentContainer}
          data={data}
          renderItem={renderItem}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
          ListEmptyComponent={renderEmpty}
          keyExtractor={(item, index) => item?.expenseReportId ?? index}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.2}
          ListFooterComponent={renderFooter}
          estimatedItemSize={88}
        />
      )}
    </View>
  );
};
export default WaitingApprovalSearchScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingTop: 10, paddingBottom: 20, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  headerContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 4,
  },
  searchContainer: {
    paddingRight: 20,
    paddingLeft: 18,
    justifyContent: "space-between",
    gap: 8,
    alignItems: "center",
    flexDirection: "row",
  },
  searchInput: { ...Fonts.BodySmall, flex: 1, color: Colors.grayscale100, backgroundColor: Colors.white },
  searchItemContainer: {
    flexDirection: "row",
    gap: 8,
    marginTop: 16,
    marginHorizontal: 20,
    alignItems: "center",
    paddingBottom: 16,
  },
  loadingMore: { marginTop: 10 },
  emptyContainer: { flex: 1 },
});
