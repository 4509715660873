import { FC } from "react";
import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import uniqBy from "lodash/uniqBy";

import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { useExpenseRequestStatusList } from "components/ExpenseRequestItem";
import { AppText } from "components";

type FiltersProps = {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

const Filters: FC<FiltersProps> = ({ value, onChange }) => {
  const statuses = useExpenseRequestStatusList();

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      horizontal
      automaticallyAdjustContentInsets={true}
      contentContainerStyle={styles.listContainer}
    >
      {uniqBy(Object.entries(statuses), (el) => el[1].uniqueKey).map(([code, { text, uniqueKey }]) => {
        return (
          <TouchableOpacity
            key={code}
            style={[styles.statusButton, value === uniqueKey && { borderColor: Colors.primary50 }]}
            onPress={() => onChange(uniqueKey)}
          >
            <AppText style={Fonts.BodyMedium}>{text}</AppText>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );
};

export default Filters;

const styles = StyleSheet.create({
  listContainer: {
    alignItems: "center",
    height: 52,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  statusButton: {
    paddingHorizontal: 16,
    marginRight: 8,
    height: 40,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    alignItems: "center",
    justifyContent: "center",
  },
});
