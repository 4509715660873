import BPromise from "bluebird";
import config from "app/config";
import widgetHelper from "utils/widgetHelper";
import { Platform } from "react-native";

enum SharedKeys {
  env = "env",
  token = "token",
  refreshToken = "refreshToken",
  user = "user",
  companyInfo = "companyInfo",
  expInsights = "expInsights",
}

type WidgetConfig = {
  token: string;
  refreshToken: string;
};

type User = {
  companyInfo: {
    companyId: string;
    qrCode: string;
    companyName: string;
    email: string;
    taxCode: string;
    address: string;
  };
  role: string;
};

const widgetConfig = {
  HASURA_ENDPOINT: config.HASURA_ENDPOINT,
  BIZZI_ID_ENDPOINT: config.BIZZI_ID_ENDPOINT,
  RELOAD_INTERVAL: 30, // 30 mins
};

/*-- widget service not use on web platform --*/
class WidgetService {
  async init(data: WidgetConfig) {
    if (Platform.OS === "web") {
      return;
    }
    await widgetHelper.setItemAsync(SharedKeys.env, JSON.stringify(widgetConfig));
    const { token, refreshToken } = data;
    await widgetHelper.setItemAsync(SharedKeys.token, token);
    await widgetHelper.setItemAsync(SharedKeys.refreshToken, refreshToken);
  }
  async setUser(user: User) {
    if (Platform.OS === "web") {
      return;
    }
    const { companyInfo, role } = user;
    await widgetHelper.setItemAsync(SharedKeys.user, JSON.stringify({ role, companyId: companyInfo.companyId }));
    await widgetHelper.setItemAsync(SharedKeys.companyInfo, JSON.stringify(companyInfo));
  }

  reloadContent = (kind?: string) => {
    if (Platform.OS === "web") {
      return;
    }
    widgetHelper.reloadWidget(kind);
  };

  logout = async () => {
    if (Platform.OS === "web") {
      return;
    }
    BPromise.each(
      [
        SharedKeys.token,
        SharedKeys.refreshToken,
        SharedKeys.user,
        SharedKeys.companyInfo,
        SharedKeys.expInsights,
        "convertorBizziExpense",
        "quickActionExpense",
        "statisticalBizziExpense",
        "user",
      ],
      async (key) => {
        await widgetHelper.removeItemAsync(key);
      }
    );
    widgetHelper.reloadWidget();
  };
}

export const widgetService = new WidgetService();
