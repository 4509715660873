import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, BackHeader, Badge, CopyContainer, IconCustom, LottieLoading, TextView } from "components";
import React, { FC, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { FlightTicketDetailScreenProps } from "navigation/type";
import useFlightBookingInfoQuery from "screens/FlightBooking/hooks/useFlightBookingInfoQuery";
import TicketDetailModal, { TicketDetailModalApi } from "screens/FlightBooking/components/TicketDetailModal";
import { getMinDate } from "utils";
import dayjs from "dayjs";
import { BookingInformation, FlightTicket } from "screens/FlightBooking/types";
import TicketOverview from "screens/FlightBooking/components/TicketOverview";
import { getListFaresFromBookingInfo } from "screens/FlightBooking/helpers";

const FlightTicketDetailScreen: FC<FlightTicketDetailScreenProps> = ({ route }) => {
  const { t } = useTranslation("app/screens/FlightBooking/FlightTicketDetailScreen");
  const expenseRequestId = route?.params?.expenseRequestId;
  const { data, loading } = useFlightBookingInfoQuery({ expenseRequestId });
  const bookingInformation = data?.expExpenseRequestDetail?.expenseTravels?.[0]?.expenseBookings?.find(
    (item) => item?.bookingType === BOOKING_TYPE.FLIGHT
  )?.expenseTravelBookings?.[0]?.bookingInformation;
  const ticketModalRef = useRef<TicketDetailModalApi>(null);

  const reservation = useMemo(() => {
    const booking = data?.expExpenseRequestDetail?.expenseTravels?.[0]?.expenseBookings?.find(
      (booking) => booking.bookingType === BOOKING_TYPE.FLIGHT
    )?.expenseTravelBookings?.[0];

    if (!booking) {
      return null;
    }

    const bookingInformation = booking.bookingInformation as BookingInformation;
    const listBooking = bookingInformation.listBooking ?? [];
    const fares = getListFaresFromBookingInfo(bookingInformation, booking?.status);
    return {
      tickets: {
        departure: fares?.[0] as FlightTicket,
        return: fares?.[1] as FlightTicket,
      },
      baggages: {
        departure: fares?.[0]?.bookingInfo?.baggage,
        return: fares?.[1]?.bookingInfo?.baggage,
      },
      orderCode: booking.orderCode,
      status: booking.status,
      totalAmount: booking.totalAmount,
      expiredAt: getMinDate(listBooking.map((booking) => dayjs(booking.expiredAt))),
      contact: bookingInformation.contact,
      passenger: listBooking?.[0]?.passengers?.[0],
      bookingCode: listBooking?.[0]?.bookingCode,
    };
  }, [data]);
  return (
    <View style={styles.container}>
      <BackHeader containerStyle={styles.pageHeader} headerTitle={t("flight_ticket_detail_title")} />
      {loading ? (
        <LottieLoading style={styles.loadingView} />
      ) : (
        <>
          <ScrollView contentContainerStyle={[styles.contentContainer]} showsVerticalScrollIndicator={false}>
            <View style={[styles.section, styles.bookingCodeContainer]}>
              <View style={styles.statusRow}>
                <AppText style={Fonts.SentenceSubtitleLarge}>{t("reservation_code")}</AppText>
                <Badge color={Colors.success0} text={<AppText>{t("paid")}</AppText>} />
              </View>
              <View style={styles.bookingCodeRow}>
                {bookingInformation?.listBooking?.map((item) => {
                  const flight = item?.fares?.[0]?.flights?.[0];
                  return (
                    <View style={styles.flex}>
                      <AppText style={Fonts.BodyMedium}>
                        {`${flight?.departure?.code} - ${flight?.arrival?.code}: `}
                      </AppText>
                      <CopyContainer
                        value={item?.bookingCode}
                        icon={<IconCustom fill={Colors.primary50} name="content-copy" />}
                        message={t("booking_code_copied_to_clipboard")}
                      >
                        <AppText color={Colors.primary50} style={Fonts.SentenceSubtitleLarge}>
                          {item?.bookingCode}
                        </AppText>
                      </CopyContainer>
                    </View>
                  );
                })}
              </View>
            </View>
            {reservation.tickets?.departure && (
              <TouchableOpacity
                onPress={() => {
                  ticketModalRef.current?.showTicket(
                    {
                      ticket: reservation.tickets.departure,
                    },
                    {
                      icon: <IconCustom name="flight-takeoff" />,
                    }
                  );
                }}
              >
                <TicketOverview
                  containerStyle={styles.ticketContainer}
                  icon={<IconCustom name="flight-takeoff" />}
                  data={reservation.tickets.departure}
                  baggageInfo={reservation.baggages.departure}
                />
              </TouchableOpacity>
            )}
            {reservation.tickets?.return && (
              <TouchableOpacity
                onPress={() => {
                  ticketModalRef.current?.showTicket(
                    {
                      ticket: reservation.tickets.return,
                    },
                    {
                      icon: <IconCustom name="flight-land" />,
                    }
                  );
                }}
              >
                <TicketOverview
                  containerStyle={styles.ticketContainer}
                  icon={<IconCustom name="flight-land" />}
                  data={reservation.tickets.return}
                  baggageInfo={reservation.baggages.return}
                />
              </TouchableOpacity>
            )}
            <View style={styles.section}>
              <AppText style={[Fonts.SentenceSubtitleLarge, styles.sectionHeader]}>{t("passenger_info")}</AppText>
              <View style={styles.sectionBody}>
                <TextView label={t("last_name")} value={reservation.passenger.lastName} />
                <TextView label={t("first_name")} value={reservation.passenger.firstName} />
                <TextView
                  label={t("gender")}
                  value={{ male: t("male"), female: t("female") }[reservation.passenger?.gender?.toLocaleLowerCase()]}
                />
                <TextView label={t("birth_day")} value={dayjs(reservation?.passenger?.birthday).format("DD/MM/YYYY")} />
              </View>
            </View>
            <View style={styles.section}>
              <AppText style={[Fonts.SentenceSubtitleLarge, styles.sectionHeader]}>{t("contact_info")}</AppText>
              <View style={styles.sectionBody}>
                <TextView label={t("full_name")} value={reservation.contact.firstName} />
                <TextView label={t("phone")} value={reservation.contact.phone} />
                <TextView label={t("email")} value={reservation.contact.email} />
              </View>
            </View>
          </ScrollView>
        </>
      )}
      <TicketDetailModal ref={ticketModalRef} />
    </View>
  );
};

export default FlightTicketDetailScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale0,
  },
  loadingView: { backgroundColor: Colors.white },
  pageHeader: {
    paddingBottom: 5,
  },
  contentContainer: {
    gap: 8,
    paddingBottom: 10,
  },
  section: {
    paddingVertical: 12,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    backgroundColor: Colors.white,
  },
  sectionHeader: { marginBottom: 12 },
  sectionBody: {
    gap: 8,
  },
  statusRow: { flexDirection: "row", gap: 8, backgroundColor: Colors.white },
  ticketContainer: {
    gap: 8,
  },
  bookingCodeContainer: { paddingBottom: 4 },
  bookingCodeRow: { flexDirection: "row", marginTop: 15 },
  flex: { flex: 1 },
});
