import React, { FC } from "react";
import { Colors, Fonts } from "theme";
import { AppText } from "components";
import { Keyboard, StyleSheet, TouchableOpacity, View } from "react-native";
import { CloseFilterIcon, DropDownIcon } from "assets/images/svg/icons";

interface FilterInputProps {
  title?: string;
  value?: string;
  onPress?: () => void;
  onClear?: () => void;
  minWidth?: number;
}
const FilterInput: FC<FilterInputProps> = ({ title, value, onPress, onClear, minWidth }) => {
  const handleClick = () => {
    onPress();
    Keyboard.dismiss();
  };
  return (
    <TouchableOpacity
      style={[styles.container, value && { borderColor: Colors.primary50 }, minWidth && { minWidth }]}
      onPress={handleClick}
    >
      {value ? (
        <>
          <View>
            <AppText style={styles.label}>{title}</AppText>
            <View style={styles.content}>
              <AppText style={styles.valueTitle} color={Colors.primary50} numberOfLines={1}>
                {value}
              </AppText>
              {Boolean(onClear) && (
                <TouchableOpacity onPress={onClear}>
                  <CloseFilterIcon />
                </TouchableOpacity>
              )}
            </View>
          </View>
        </>
      ) : (
        <View style={styles.content}>
          <AppText style={Fonts.BodySmall}>{title}</AppText>
          <DropDownIcon />
        </View>
      )}
    </TouchableOpacity>
  );
};
export default FilterInput;

const styles = StyleSheet.create({
  container: {
    borderRadius: 999,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    height: 36,
    justifyContent: "center",
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 12,
    gap: 8,
  },
  label: {
    ...Fonts.BodyXSmall,
    position: "absolute",
    top: -18,
    left: 20,
    backgroundColor: Colors.white,
    color: Colors.primary50,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  valueTitle: { ...Fonts.BodySmall, maxWidth: 166, minWidth: 60 },
});
