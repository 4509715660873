import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileQueryExpenseUnClaimedQuery, MobileQueryExpenseUnClaimedQueryVariables } from "types";

export const QUERY_EXPENSE_UNCLAIMED = gql`
  query MobileQueryExpenseUnClaimed(
    $where: ExpUnClaimedExpensesWhereInput!
    $sortOption: ExpUnClaimedExpensesSortOptions
    $offset: NonNegativeInt!
  ) {
    expUnClaimedExpenses(where: $where, limit: 10, offset: $offset, sortOption: $sortOption) {
      total
      expenses {
        allocatedStatus
        docSequence
        expenseCardTransactions {
          cardTransaction {
            cardTransactionId
          }
        }
        note
        expenseId
        invoiceId
        provider
        category
        totalAmountWithVat
        expenseDate
        receivedInvoice
        status
        expenseCategory {
          expenseCategoryId
          title
        }
        expenseReport {
          expenseReportId
          status
          docSequence
        }
      }
    }
  }
`;

const useExpenseUnClaimedQuery = (variables: MobileQueryExpenseUnClaimedQueryVariables) => {
  return useQuery<MobileQueryExpenseUnClaimedQuery, MobileQueryExpenseUnClaimedQueryVariables>(
    QUERY_EXPENSE_UNCLAIMED,
    {
      variables,
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      skip: !variables?.where?.employeeId,
    }
  );
};

export default useExpenseUnClaimedQuery;
