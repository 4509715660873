import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={8} height={9} viewBox="0 0 8 9">
      <Path
        d="M3.335 2.535V8c0 .19.064.348.192.475a.645.645 0 00.475.192.645.645 0 00.475-.192.645.645 0 00.191-.475V2.535l1.934 1.933a.632.632 0 00.466.183.632.632 0 00.467-.183.632.632 0 00.183-.467.632.632 0 00-.183-.466L4.468.468a.64.64 0 00-.466-.2.64.64 0 00-.467.2L.468 3.535A.632.632 0 00.285 4c0 .19.061.345.183.467a.632.632 0 00.467.183.632.632 0 00.467-.183l1.933-1.933z"
        fill="#E11934"
      />
    </Svg>
  );
}

export default SvgComponent;
