import { useNavigation } from "@react-navigation/native";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Linking, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { v1 } from "uuid";
import { BottomSheetModalCustomMethods } from "components/BottomSheetModalCustom/BottomSheetModalCustom";
import UploadType from "constants/UploadType";
import ScreenName from "navigation/ScreenName";
import { Colors, Fonts } from "theme";
import { getFileExtension } from "utils";
import { FileBase } from "./types";
import { Control, Controller, FieldError, FieldErrors } from "react-hook-form";
import {
  AppText,
  BottomSheetScrollViewModalCustom,
  IconCustom,
  ImageViewCustom,
  useWindowDimensions,
} from "components";
import { useMasterData } from "contexts/MasterDataContext";
import { CONSTANTS } from "constants/constants";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import FileItem from "components/UploadFiles/FileItem";
import { ImagePreviewFooter } from "components/UploadFiles/UploadFileView";
import { EXTENSION_FILE } from "components/UploadFiles/types";
import * as mime from "react-native-mime-types";
import { StackNavigation } from "navigation/type";
import * as DocumentPicker from "expo-document-picker";

const { PICK_FROM_FOLDER, PICK_FROM_GALLERY, TAKE_PHOTO } = UploadType;

const getLibraryPermission = async () => {
  const { status: cameraRollStatus } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  return cameraRollStatus === "granted";
};

const getCameraPermission = async () => {
  const { status: cameraStatus } = await ImagePicker.requestCameraPermissionsAsync();
  return cameraStatus === "granted";
};

interface UploadFilesProps<T, K extends keyof T> {
  limit?: number;
  title?: string;
  numberOfRows?: number;
  viewOnly?: boolean;
  name?: K | string;
  control?: Control<T>;
  rules?: Record<string, unknown>;
  error?: FieldErrors<T>[K];
  onChangeFile?: (file: any) => void;
  onRemoveFile?: (fileIndex: number) => void;
}
const COLUMN_GAP = 14;

const UploadFiles = <T, K extends keyof T>({
  title,
  limit,
  error,
  viewOnly = false,
  control,
  rules,
  name,
  onChangeFile,
  onRemoveFile,
}: UploadFilesProps<T, K>) => {
  const navigation = useNavigation<StackNavigation>();
  const { t } = useTranslation("app/components/InputCustom/UploadFiles");
  const { uploadConfiguration } = useMasterData();
  const maxFiles = limit || uploadConfiguration?.maxFiles;
  const bottomSheetModalRef = useRef<BottomSheetModalCustomMethods>(null);
  const { width, isTablet } = useWindowDimensions();
  const isAcceptAllExtension = uploadConfiguration?.supportedFileExtensions?.[0] === "*";

  const onChangeFiles = useRef(null);
  const currentFiles = useRef([]);
  const [visibleImagePreview, setVisibleImagePreview] = useState({ index: 0, visible: false });

  const numberItemPerRow = isTablet ? 6 : 3;
  const WIDTH_FILE = Math.floor(
    (width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING - COLUMN_GAP * (numberItemPerRow - 1)) / numberItemPerRow
  );
  const imageFiles = currentFiles.current
    ?.filter((file) => {
      const ext = mime.extension(file.fileType);
      return [EXTENSION_FILE.PNG, EXTENSION_FILE.JPG, EXTENSION_FILE.JPEG].includes(ext as EXTENSION_FILE);
    })
    .map((file) => ({
      uri: file?.uri,
    }));

  const handlePreviewImage = (uri) => () => {
    if (Platform.OS === "web") {
      window.open(uri, "_blank", "noopener noreferrer");
      return;
    }
    const foundImageIndex = imageFiles?.findIndex((file) => file.uri === uri);
    setVisibleImagePreview({ visible: true, index: foundImageIndex });
  };

  const onPressSetting = useCallback(() => {
    return Linking.openSettings();
  }, []);

  const onHandleImage = useCallback(
    (newFiles: FileBase[]) => {
      if (!newFiles?.length) {
        return;
      }

      const files = currentFiles.current ?? [];
      const estimatedFileLength = files.length + newFiles.length;
      if (uploadConfiguration?.maxFiles && estimatedFileLength > uploadConfiguration.maxFiles) {
        const newFileValues = [...files, ...newFiles.slice(0, uploadConfiguration.maxFiles - files.length)];
        onChangeFiles.current?.(newFileValues);
        onChangeFile?.(newFileValues[newFileValues.length - 1]);
        return;
      }
      const newFileValues = [...files, ...newFiles];
      onChangeFiles?.current?.(newFileValues);
      onChangeFile?.(newFileValues[newFileValues.length - 1]);
    },
    [uploadConfiguration]
  );

  const openDocumentPicker = useCallback(
    (document: DocumentPicker.DocumentPickerResult) => {
      if (document.canceled) {
        return;
      }

      const filesSuccess: FileBase[] = [];
      const files = document.assets;

      for (const file of files) {
        const fileExtension = mime.extension(file.mimeType) || getFileExtension(file.name);
        if (
          uploadConfiguration?.supportedFileExtensions &&
          !uploadConfiguration.supportedFileExtensions.includes(fileExtension as string) &&
          !isAcceptAllExtension
        ) {
          Platform.OS !== "web" ? Alert.alert("", t("invalid_file")) : alert(t("invalid_file"));
          return;
        }
        if ((file.size ?? 0) > uploadConfiguration?.maxFileSize) {
          Platform.OS !== "web"
            ? Alert.alert(
                "",
                t("descriptions_max_size_upload", { value: uploadConfiguration?.maxFileSize / 1024 / 1024 })
              )
            : alert(t("descriptions_max_size_upload", { value: uploadConfiguration?.maxFileSize / 1024 / 1024 }));
          return;
        }

        filesSuccess.push({
          id: v1(),
          name: file.name,
          size: file.size,
          fileType: file.mimeType,
          uri: file.uri,
        });
      }

      onHandleImage(filesSuccess);
    },
    [t, onHandleImage, uploadConfiguration?.maxFileSize, isAcceptAllExtension]
  );

  const openSetting = useCallback(() => {
    Alert.alert(
      "",
      t("description_require_permission"),
      [
        {
          text: t("button_title_open_setting"),
          onPress: onPressSetting,
        },
        {
          text: t("button_cancel"),
          style: "cancel",
        },
      ],
      { cancelable: false }
    );
  }, [onPressSetting, t]);

  const handleSelectUploadType = useCallback(
    (uploadType: UploadType) => async () => {
      bottomSheetModalRef.current?.close();
      switch (uploadType) {
        case PICK_FROM_GALLERY: {
          const status = await getLibraryPermission();
          if (status) {
            navigation.navigate(ScreenName.ImageBrowserScreen, {
              callBack: onHandleImage,
              max: maxFiles - currentFiles?.current?.length,
              startNumber: 0,
            });
          } else {
            openSetting();
          }
          break;
        }
        case PICK_FROM_FOLDER: {
          const status = await getLibraryPermission();
          if (status) {
            const document = await DocumentPicker.getDocumentAsync({
              multiple: true,
              copyToCacheDirectory: true,
            });
            openDocumentPicker(document);
          } else {
            openSetting();
          }

          break;
        }
        case TAKE_PHOTO:
          {
            const status = await getCameraPermission();
            if (status) {
              analyticService.logEvent({ name: EVENT.EXPENSE.TAP_CREATE });
              const results = await ImagePicker.launchCameraAsync({
                quality: CONSTANTS.COMMON.QUALITY_RESIZE_IMAGE,
                allowsEditing: false,
              });
              const image = results?.assets?.[0];
              if (image?.uri && !results.canceled) {
                const resizedImage = await ImageManipulator.manipulateAsync(
                  image.uri,
                  [{ resize: { width: image.width, height: image.height } }],
                  {
                    compress: 0.5,
                    format: ImageManipulator.SaveFormat.JPEG,
                  }
                );

                onHandleImage([
                  { ...resizedImage, id: v1(), fileType: "image/jpeg", name: image.fileName || `${v1()}.jpeg` },
                ]);
              }
            } else {
              openSetting();
            }
          }
          break;
        default:
          break;
      }
    },
    [onHandleImage, openSetting, openDocumentPicker, navigation, maxFiles, uploadConfiguration]
  );

  const openBottomSheetModalCustom = useCallback(async () => {
    bottomSheetModalRef.current?.present();
  }, []);
  const handleRemoveFile = (files, index) => async () => {
    if (onRemoveFile) {
      await onRemoveFile(index);
    }
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChangeFiles?.current?.(newFiles);
  };
  return (
    <>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          const files = value as any;
          currentFiles.current = files;
          onChangeFiles.current = onChange;
          return (
            <>
              <View style={styles.imagesContainer}>
                {files?.map((file, index) => {
                  return (
                    <View key={file.id}>
                      <FileItem
                        handlePreviewImage={handlePreviewImage(file.uri)}
                        width={WIDTH_FILE}
                        height={WIDTH_FILE}
                        file={file}
                        onRemove={handleRemoveFile(files, index)}
                      />
                    </View>
                  );
                })}
                {!viewOnly && maxFiles && files?.length < maxFiles ? (
                  <TouchableOpacity
                    onPress={openBottomSheetModalCustom}
                    style={[styles.btnAddImage, { width: files?.length ? WIDTH_FILE : "100%", height: WIDTH_FILE }]}
                  >
                    <IconCustom name="add-circle-outline" width={32} height={32} />
                  </TouchableOpacity>
                ) : null}
              </View>
              {Boolean((error as FieldError)?.message) && (
                <AppText style={[Fonts.BodySmall, styles.errorMessage]} color={Colors.alert50}>
                  {(error as FieldError).message}
                </AppText>
              )}
              <BottomSheetScrollViewModalCustom wrapperByScrollView={false} ref={bottomSheetModalRef} title={title}>
                <View style={styles.boxDescriptionsUploadFile}>
                  {!isAcceptAllExtension && (
                    <Text style={styles.descriptionsUploadFile}>
                      {t("descriptions_upload_file", {
                        extensions: uploadConfiguration?.supportedFileExtensions
                          ?.map((item) => item.toUpperCase())
                          .join(", "),
                      })}
                    </Text>
                  )}
                  <Text style={styles.descriptionsUploadFile}>
                    {t("descriptions_max_size_upload", { value: uploadConfiguration?.maxFileSize / 1024 / 1024 })}
                  </Text>
                </View>
                <View style={styles.content}>
                  {Platform.OS !== "web" && (
                    <>
                      <TouchableOpacity onPress={handleSelectUploadType(TAKE_PHOTO)}>
                        <View style={styles.itemStyle}>
                          <IconCustom name="add-a-photo-outline" />
                          <Text style={styles.itemTextStyle}>{t("take_photo")}</Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={handleSelectUploadType(PICK_FROM_GALLERY)}>
                        <View style={styles.itemStyle}>
                          <IconCustom name="photo-library" />
                          <Text style={styles.itemTextStyle}>{t("pick_from_gallery")}</Text>
                        </View>
                      </TouchableOpacity>
                    </>
                  )}
                  <TouchableOpacity onPress={handleSelectUploadType(PICK_FROM_FOLDER)}>
                    <View style={styles.itemStyle}>
                      <IconCustom name="note" />
                      <Text style={styles.itemTextStyle}>{t("pick_from_folder")}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </BottomSheetScrollViewModalCustom>
              <ImageViewCustom
                images={imageFiles ?? []}
                imageIndex={visibleImagePreview.index}
                visible={visibleImagePreview.visible}
                FooterComponent={({ imageIndex }) => (
                  <ImagePreviewFooter imageIndex={imageIndex} imagesCount={imageFiles?.length} />
                )}
                onRequestClose={() => setVisibleImagePreview({ index: 0, visible: false })}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default UploadFiles;
export const styles = StyleSheet.create({
  imagesContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignSelf: "flex-start",
    margin: -2,
    width: "100%",
    rowGap: 16,
    columnGap: COLUMN_GAP,
  },
  btnAddImage: {
    height: "100%",
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: "dashed",
    alignItems: "center",
    justifyContent: "center",
    borderColor: Colors.primary20,
    backgroundColor: Colors.primary0,
  },
  content: {
    marginBottom: 15,
    backgroundColor: Colors.white,
  },
  itemStyle: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  itemTextStyle: {
    fontSize: 14,
    lineHeight: 20,
    marginLeft: 16,
    color: Colors.grayscale100,
  },
  boxDescriptionsUploadFile: {
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  descriptionsUploadFile: {
    fontSize: 12,
    color: Colors.grayscale80,
  },
  errorMessage: { marginTop: 10 },
});
