import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ size = 24 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path d="M8.002 8.953a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16z" stroke="#94A3B8" strokeWidth={1.5} />
      <Path
        d="M2.412 5.659c1.313-5.774 9.867-5.767 11.173.006.767 3.387-1.34 6.254-3.187 8.027a3.462 3.462 0 01-4.806 0c-1.84-1.773-3.947-4.647-3.18-8.033z"
        stroke="#94A3B8"
        strokeWidth={1.5}
      />
    </Svg>
  );
}

export default SvgComponent;
