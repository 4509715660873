import { FC, ReactElement, ReactNode } from "react";
import { Platform, StyleSheet } from "react-native";

import { TouchableOpacity } from "react-native-gesture-handler";
import * as Clipboard from "expo-clipboard";
import { ToastManager } from "./Toast";

type CopyContainerProps = {
  children: ReactNode;
  value;
  message?: string;
  icon?: ReactElement;
};
const CopyContainer: FC<CopyContainerProps> = ({ children, value, message, icon }) => {
  const content = (
    <TouchableOpacity
      style={styles.copyIconContainer}
      onPress={() => {
        Clipboard.setStringAsync(value);
        ToastManager.success(message ?? "Copied to clipboard");
      }}
    >
      {children}
      {icon}
    </TouchableOpacity>
  );
  // eslint-disable-next-line react-native/no-inline-styles
  return Platform.OS === "web" ? <div style={{ cursor: "pointer" }}>{content}</div> : content;
};

export default CopyContainer;
const styles = StyleSheet.create({
  copyIconContainer: {
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
  },
});
