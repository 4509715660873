import { StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, BackHeader, Button, FloatFooterExpand, IconCustom, KeyboardAwareScrollViewCustom } from "components";
import React, { FC, useRef, useState } from "react";
import TicketDetailModal, { TicketDetailModalApi } from "../components/TicketDetailModal";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { Colors, Fonts } from "theme";
import PlaneTicket from "../components/PlaneTicket";
import { CONSTANTS } from "constants/constants";
import PassengerInfoForm, { PassengerInfoFormApi } from "./PassengerInfoForm";
import { useTranslation } from "react-i18next";
import SCREEN_NAME from "navigation/ScreenName";
import { useFlightBooking } from "../context/FlightBookingProvider";
import { BookingInformation, FlightTicketType } from "../types";
import FlightTicketAmountDetail from "screens/FlightBooking/components/FlightTicketAmountDetail";
import { PassengerInfoScreenProps } from "navigation/type";
import { getTitleI18n } from "utils";

const PassengerInfoScreen: FC<PassengerInfoScreenProps> = ({ route, navigation }) => {
  const { t } = useTranslation("app/screens/FlightBooking/PassengerInfoScreen/PassengerInfoScreen");
  const { tickets } = useFlightBooking();
  const passengerInfoFormRef = useRef<PassengerInfoFormApi>(null);
  const ticketModalRef = useRef<TicketDetailModalApi>(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const handleRePickTicket = (type: FlightTicketType) => () => {
    ticketModalRef.current.close();
    navigation.push(SCREEN_NAME.FlightSearchScreen, {
      ...route.params,
      type,
      fromScreen: SCREEN_NAME.PassengerInfoScreen,
    });
  };

  const handleContinue = () => {
    passengerInfoFormRef.current.triggerSubmit();
  };
  const renderFooter = () => {
    const totalAmount =
      [tickets?.departure, tickets?.return].reduce((acc, ticket) => {
        acc += ticket ? ticket?.totalPrice : 0;
        return acc;
      }, 0) || 0;
    let fareList: BookingInformation["listBooking"][0]["fares"] = [
      {
        ...tickets.departure,
        bookingInfo: {
          price: tickets?.departure?.totalPrice,
        },
      },
    ];
    if (tickets.return) {
      fareList = fareList.concat([
        {
          ...tickets.return,
          bookingInfo: {
            price: tickets?.return?.totalPrice,
          },
        },
      ]);
    }
    return (
      <FloatFooterExpand
        onLayout={onLayoutFooter}
        totalAmount={totalAmount}
        priceLabel={t("total_amount")}
        expandComponent={<FlightTicketAmountDetail fares={fareList} />}
        footerButton={
          <Button style={styles.footerButton} onPress={handleContinue}>
            {t("continue")}
          </Button>
        }
      />
    );
  };

  return (
    <BottomSheetModalProvider>
      <View style={styles.container}>
        <BackHeader containerStyle={styles.pageHeader} headerTitle={t("page_title")} />
        <KeyboardAwareScrollViewCustom
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
        >
          {/* tickets */}
          <View style={styles.overviewSection}>
            {[tickets?.departure, tickets?.return].map((ticket, index) => {
              const type = index === 0 ? FlightTicketType.Departure : FlightTicketType.Return;
              const flight = ticket?.flights?.[0];

              return flight ? (
                <TouchableOpacity
                  style={styles.ticketContainer}
                  onPress={() =>
                    ticketModalRef.current.showTicket(
                      { ticket },
                      {
                        icon:
                          type === FlightTicketType.Departure ? (
                            <IconCustom name="flight-takeoff" />
                          ) : (
                            <IconCustom name="flight-land" />
                          ),
                        footer: (
                          <Button type="secondary" onPress={handleRePickTicket(type)}>
                            {t("re_pick_ticket")}
                          </Button>
                        ),
                      }
                    )
                  }
                >
                  <View style={styles.flexRow}>
                    <IconCustom name={type === FlightTicketType.Departure ? "flight-takeoff" : "flight-land"} />
                    <AppText style={[Fonts.H100, styles.flex]}>
                      {getTitleI18n({
                        en: `${flight?.departure?.location?.nameEn} - ${flight?.arrival?.location?.nameEn}`,
                        vi: `${flight?.departure?.location?.name} - ${flight?.arrival?.location?.name}`,
                      })}
                    </AppText>
                  </View>
                  <PlaneTicket item={ticket} />
                </TouchableOpacity>
              ) : null;
            })}
          </View>

          {/* passenger info form*/}
          <PassengerInfoForm ref={passengerInfoFormRef} />
        </KeyboardAwareScrollViewCustom>
        {renderFooter()}
      </View>
      <TicketDetailModal ref={ticketModalRef} />
    </BottomSheetModalProvider>
  );
};

export default PassengerInfoScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale0 },
  pageHeader: {
    paddingBottom: 5,
  },
  overviewSection: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    gap: 16,
  },
  ticketContainer: {
    gap: 8,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  footerButton: {
    paddingVertical: 8,
  },
  flex: { flex: 1 },
});
