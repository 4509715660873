import React, { FC } from "react";
import { Keyboard, StyleSheet, View } from "react-native";

import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import EmployeeItem, { EmployeeOption } from "./EmployeeOption";
import { useEmployeeChooserContext } from "./EmployeeChooserContext";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";

interface RecentListProps {
  initialValue?: EmployeeOption;
}

const RecentList: FC<RecentListProps> = ({ initialValue }) => {
  const { t } = useTranslation("app/components/AdvanceSearch/EmployeeChooser");
  const { user: me } = useAuth();
  const { chosen, setChosen, recentChosen } = useEmployeeChooserContext();

  const renderItem = ({ item, index }) => {
    const employee = item as EmployeeOption;
    const isLastItem = index === recentChosen.length - 1;
    return (
      <View key={item?.employeeId}>
        <EmployeeItem
          employee={employee}
          checked={chosen?.region === "recent" && chosen?.employeeId === employee.employeeId}
          onPress={(item) => {
            setChosen({ ...item, region: "recent" });
            Keyboard.dismiss();
          }}
        />
        {!isLastItem && <View style={styles.divider} />}
      </View>
    );
  };

  return (
    <>
      {initialValue && (
        <EmployeeItem
          employee={initialValue}
          checked={[undefined, "active"].includes(chosen.region) && chosen?.employeeId === initialValue.employeeId}
          onPress={(item) => {
            setChosen({ ...item, region: "active" });
            Keyboard.dismiss();
          }}
        />
      )}
      <AppText style={[styles.sectionTitle, Fonts.H300]}>{t("me")}</AppText>
      {me && (
        <EmployeeItem
          employee={{
            region: "me",
            employeeId: me.employee_id,
            fullname: me.employee.fullname,
            email: me.email,
            avatarUrl: me?.avatar,
            defaultAvatarUrl: me?.avatar,
          }}
          checked={chosen?.region === "me" && chosen?.employeeId === me.employee_id}
          onPress={(item) => {
            setChosen(item);
            Keyboard.dismiss();
          }}
        />
      )}
      {recentChosen?.length > 0 && <AppText style={[styles.sectionTitle, Fonts.H300]}>{t("recent")}</AppText>}
      {recentChosen?.map((item, index) => renderItem({ item, index }))}
    </>
  );
};

export default RecentList;

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  sectionTitle: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: 10,
  },
});
