// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useRef, useCallback, useLayoutEffect, useMemo } from "react";
import { KeyboardAvoidingView, StyleSheet, View, TextInput, ScrollView } from "react-native";
import * as Yup from "yup";
import Constants from "expo-constants";
import { Formik } from "formik";

import Fontisto from "react-native-vector-icons/Fontisto";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { BizziButton, InputField } from "app/components";

import { useAuth } from "app/contexts/AuthContext";
import Colors from "constants/Colors";
import { useTranslation } from "react-i18next";
import { AppText } from "components";

const ADD_COMPANY = gql`
  mutation MobileCreateCompanies($company: companies_insert_input!) {
    company: insert_companies_one(object: $company) {
      address
      company_id
      created_at
      tax_code
      name
      email
      accountant_email
      users_companies {
        staff_name
        job_title
        department
      }
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation MobileUpdateCompanies($companyId: uuid!, $company: companies_set_input!, $staffName: String) {
    company: update_companies_by_pk(pk_columns: { company_id: $companyId }, _set: $company) {
      address
      company_id
      created_at
      tax_code
      name
      email
      accountant_email
      users_companies {
        staff_name
      }
    }
    update_users_companies(where: { company_id: { _eq: $companyId } }, _set: { staff_name: $staffName }) {
      returning {
        user_company_id
      }
    }
  }
`;

const CompanyFormScreen = ({ navigation, route }) => {
  const { user, groupId, receiveInvoiceEmail } = useAuth();
  const { t } = useTranslation("screens/CompanyTab/CompanyFormScreen");
  const { params: { type = "add" } = {} } = route;
  const [addCompany] = useMutation(ADD_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const { company } = user;

  const companyNameRef = useRef<TextInput>(null);
  const addressRef = useRef<TextInput>(null);
  const financeEmailRef = useRef<TextInput>(null);

  const companyForm = Yup.object().shape({
    tax_code: Yup.string()
      .trim()
      .matches(/^\d{10}(-\d{3})?$/, t("invalid_tax_code"))
      .required(t("required")),
    name: Yup.string().min(5, t("string_min")).required(t("required")),
    address: Yup.string().min(10, t("string_min")).required(t("required")),
    staff_name: Yup.string().min(5, t("string_min")).required(t("required")),
    // phone: Yup.string().min(10, t("number_length")).required(t("required")),
    department: Yup.string().min(5, t("string_min")).required(t("required")),
    accountant_email: Yup.string().email(t("please_input_correct_email")).required(t("required")),
    // invoice_email: Yup.string().email(t("please_input_correct_email")).required(t("required")),
  });

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: type === "add" ? t("AddCompanyScreen") : t("EditCompanyScreen"),
    });
  }, [type, navigation, t]);

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        actions.setSubmitting(true);
        const { accountant_email, address, tax_code, name, staff_name, job_title, department } = values;
        let company = {};
        if (type === "add") {
          company = await addCompany({
            variables: {
              company: {
                group_id: groupId,
                accountant_email,
                address,
                tax_code,
                name,
                users_companies: {
                  data: [{ user_id: user.id, group_id: groupId, staff_name, job_title, department }],
                },
              },
            },
          }).then((result) => result?.data?.company);
        } else {
          company = await updateCompany({
            variables: {
              company: {
                accountant_email,
                address,
                tax_code,
                name,
              },
              companyId: values.company_id,
              staffName: staff_name,
            },
          }).then((result) => result?.data?.company);
        }
        navigation.replace("CompanyDetailScreen", { ...company, receiveInvoiceEmail });
      } catch (error) {
        console.error(error);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [addCompany, groupId, navigation, receiveInvoiceEmail, type, updateCompany, user]
  );

  const initialValues = useMemo(
    () => ({
      staff_name: user.fullname,
      ...company,
      accountant_email: company?.accountant_email ?? "",
      department: user.department.name,
    }),
    [company, user]
  );

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit} validationSchema={companyForm}>
      {(props) => {
        const { values, errors, touched, isValid, dirty, handleSubmit, handleChange, handleBlur, setFieldValue } =
          props;

        return (
          <KeyboardAvoidingView
            style={styles.mainContainer}
            behavior={Constants?.platform?.ios ? "padding" : "height"}
            keyboardVerticalOffset={Constants?.platform?.ios ? 80 : 100}
            enabled
          >
            <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }} keyboardDismissMode="on-drag">
              <View style={styles.outerContainer}>
                <View style={styles.sectionHeader}>
                  <AppText style={styles.sectionHeaderLabel}>{t("buyer_company_info").toUpperCase()}</AppText>
                </View>
                <View style={styles.container}>
                  <InputField
                    label={t("tax_code_full")}
                    placeholder={t("tax_code_placeholder")}
                    keyboardType="number-pad"
                    value={values.tax_code}
                    error={errors.tax_code}
                    touched={touched.tax_code}
                    onBlur={handleBlur("tax_code")}
                    onChangeText={(value) => handleChange("tax_code")(value.trim())}
                    onSubmitEditing={() => companyNameRef.current?.focus()}
                    leftImage={<Fontisto name="hashtag" size={20} color={Colors.blue7} />}
                    returnKeyLabel={t("next")}
                    extra={
                      <MaterialIcons
                        name="search"
                        size={25}
                        color={Colors.blue7}
                        onPress={async () => {
                          if (errors.tax_code) {
                            return;
                          }

                          const resp = await fetch(`https://thongtindoanhnghiep.co/api/company/${values.tax_code}`);
                          const data = await resp.json();

                          if (!data.Title || !data.DiaChiCongTy) {
                            return;
                          }
                          setFieldValue("name", data.Title, false);
                          setFieldValue("address", data.DiaChiCongTy, false);
                        }}
                      />
                    }
                  />

                  <InputField
                    ref={companyNameRef}
                    label={t("company_name")}
                    textContentType="organizationName"
                    value={values.name}
                    error={errors.name}
                    touched={touched.name}
                    onBlur={handleBlur("name")}
                    onChangeText={handleChange("name")}
                    onSubmitEditing={() => addressRef.current?.focus()}
                    leftImage={<MaterialIcons name="business-center" size={24} color={Colors.blue7} />}
                    returnKeyLabel={t("next")}
                  />

                  <InputField
                    ref={addressRef}
                    label={t("address")}
                    placeholder={t("address_placeholder")}
                    textContentType="fullStreetAddress"
                    value={values.address}
                    error={errors.address}
                    touched={touched.address}
                    onBlur={handleBlur("address")}
                    onChangeText={handleChange("address")}
                    onSubmitEditing={() => financeEmailRef.current?.focus()}
                    leftImage={<Fontisto name="map-marker-alt" size={24} color={Colors.blue7} />}
                    returnKeyLabel={t("next")}
                  />

                  <InputField
                    ref={financeEmailRef}
                    label={t("finance_email")}
                    placeholder={t("finance_email_placeholder")}
                    textContentType="emailAddress"
                    keyboardType="email-address"
                    autoCorrect={false}
                    value={values.accountant_email}
                    error={errors.accountant_email}
                    touched={touched.accountant_email}
                    onBlur={handleBlur("accountant_email")}
                    onChangeText={handleChange("accountant_email")}
                    onSubmitEditing={handleSubmit}
                    returnKeyLabel={t("complete")}
                    leftImage={<MaterialCommunityIcons name="email" size={24} color={Colors.blue7} />}
                    autoCapitalize="none"
                  />
                  {/*
                  <Flex style={{ marginTop: 15 }}>
                    <Checkbox.Android
                      color="#0052cc"
                      status={values.isPrimary ? "checked" : "unchecked"}
                      onPress={() => setFieldValue("isPrimary", !values.isPrimary)}
                    />
                    <Text>{t("set_primary")}</Text>
                  </Flex> */}
                </View>
                <View style={styles.sectionHeader}>
                  <AppText style={styles.sectionHeaderLabel}>{t("proposer").toUpperCase()}</AppText>
                </View>
                <View style={styles.container}>
                  <InputField
                    label={t("buyer_name")}
                    placeholder={t("name_placeholder")}
                    textContentType="name"
                    value={values.staff_name}
                    error={errors.staff_name}
                    touched={touched.staff_name}
                    onBlur={handleBlur("staff_name")}
                    onChangeText={handleChange("staff_name")}
                    leftImage={<MaterialIcons name="person-pin" size={24} color={Colors.blue7} />}
                    returnKeyLabel={t("next")}
                  />

                  {/* <InputField
                    label={t("job_title")}
                    // placeholder={t("job_title_placeholder")}
                    textContentType="jobTitle"
                    value={values.job_title}
                    error={errors.job_title}
                    touched={touched.job_title}
                    onBlur={handleBlur("job_title")}
                    onChangeText={handleChange("job_title")}
                    leftImage={<FontAwesome name="black-tie" size={24} color={Colors.blue7} />}
                    returnKeyLabel={t("next")}
                  /> */}

                  <InputField
                    label={t("department")}
                    // placeholder={t("department_placeholder")}
                    textContentType="organizationName"
                    value={values.department}
                    error={errors.department}
                    touched={touched.department}
                    onBlur={handleBlur("department")}
                    onChangeText={handleChange("department")}
                    leftImage={<MaterialCommunityIcons name="account-group" size={24} color={Colors.blue7} />}
                    returnKeyLabel={t("next")}
                  />
                  <BizziButton
                    style={{ marginHorizontal: 10, marginBottom: 20 }}
                    mode="contained"
                    onPress={handleSubmit}
                    title={t("complete")}
                  />
                </View>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
        );
      }}
    </Formik>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: Colors.whiteBackground,
    flex: 1,
  },

  outerContainer: {
    backgroundColor: Colors.grey7,
    flex: 1,
  },
  container: {
    backgroundColor: Colors.whiteBackground,
    flex: 1,
    padding: 20,
  },
  sectionHeader: {
    backgroundColor: Colors.grey7,
    padding: 20,
  },
  sectionHeaderLabel: {
    color: Colors.grey6,
  },
});

export default CompanyFormScreen;
