import { forwardRef, memo, useImperativeHandle, useRef, useState } from "react";
import { StyleSheet, Platform } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  useAnimatedGestureHandler,
  ReduceMotion,
} from "react-native-reanimated";
import { PanGestureHandler } from "react-native-gesture-handler";

import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { SuccessCircleIcon } from "assets/images/svg/icons";
import { FailureCircleIcon } from "assets/images/svg/icons";
import { isIphoneX, WIDTH_WINDOW } from "constants/Layout";

export interface ToastMessageMethods {
  success: (title: string) => void;
  error: (title: string) => void;
}

enum ToastTypes {
  Success = "success",
  Error = "error",
}

const TOP_POSITION_MESSAGE = isIphoneX() ? 115 : 96;
const ToastMessage = forwardRef<ToastMessageMethods>((_, ref) => {
  useImperativeHandle(ref, () => ({
    success: (title: string) => showMessage({ type: ToastTypes.Success, title }),
    error: (title: string) => showMessage({ type: ToastTypes.Error, title }),
  }));

  const showMessage = (option: { type: ToastTypes; title: string }) => {
    setVisible(true);
    translationY.value = withSpring(TOP_POSITION_MESSAGE, {
      damping: 10,
      mass: 0.1,
      stiffness: 100,
      reduceMotion: ReduceMotion.Never,
    });
    setTitle(option.title);
    setToastType(option.type);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      clearTimeout(timeoutCloseId.current);
    }
    timeoutId.current = setTimeout(() => {
      translationY.value = withSpring(0, { damping: 10, mass: 0.1, stiffness: 10, reduceMotion: ReduceMotion.Never });
    }, 2500);
    timeoutCloseId.current = setTimeout(() => {
      setVisible(false);
      setTitle("");
      setToastType(undefined);
    }, 3500);
  };

  const timeoutId = useRef(null);
  const timeoutCloseId = useRef(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [toastType, setToastType] = useState<ToastTypes>();
  const translationY = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translationY.value }],
    };
  });
  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ctx.startY = translationY.value;
    },
    onActive: (event, ctx) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ctx.startY + event.translationY > TOP_POSITION_MESSAGE) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      translationY.value = ctx.startY + event.translationY;
    },
    onEnd: (_) => {
      translationY.value = withSpring(0, {
        reduceMotion: ReduceMotion.Never,
      });
    },
  });
  if (!visible) {
    return null;
  }
  return (
    <PanGestureHandler onGestureEvent={gestureHandler}>
      <Animated.View
        style={[
          styles.container,
          animatedStyle,
          Platform.OS === "web" && {
            maxWidth: "96%",
          },
        ]}
      >
        {toastType === ToastTypes.Success && <SuccessCircleIcon />}
        {toastType === ToastTypes.Error && <FailureCircleIcon />}
        <AppText style={[Fonts.BodyMedium, styles.title]} numberOfLines={2}>
          {title}
        </AppText>
      </Animated.View>
    </PanGestureHandler>
  );
});

export default memo(ToastMessage);

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.grayscale0,
    backgroundColor: Colors.white,
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    top: -60,
    width: WIDTH_WINDOW - 40,
    alignSelf: "center",
    shadowColor: Colors.grayscale100,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    elevation: 3,
  },
  title: { flex: 1, marginLeft: 14 },
});
