import { LazyQueryResultTuple, OperationVariables, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { ACCOUNT_TYPES } from "constants/constants";

const QUERY_ACCOUNT_BOOK = gql`
  query MobileAccountBook($employeeIds: [UUID!]!) {
    expenseEmployeesInformation(employeeIds: $employeeIds) {
      customFields
      bankAccounts
    }
  }
`;

interface IVariables {
  employeeIds: string[];
  accountType: string;
}

const useBankAccountBookQuery = (variables?: IVariables) => {
  const { employeeIds, accountType } = variables;
  const [fetch, { data, ...rest }] = useLazyQuery<any>(QUERY_ACCOUNT_BOOK, {
    variables: {
      employeeIds,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const expenseEmployeesInformation = data?.expenseEmployeesInformation?.[0];
  const bankAccounts =
    expenseEmployeesInformation?.bankAccounts.map((item) => ({
      ...item,
      type: ACCOUNT_TYPES.PERSONAL,
      isMine: true,
    })) ?? [];
  const bankAccountBooks = expenseEmployeesInformation?.customFields?.bankAccountBooks ?? [];

  return [
    fetch,
    {
      data: [...bankAccounts, ...bankAccountBooks]?.filter((i) => i?.type === accountType),
      ...rest,
    },
  ] as LazyQueryResultTuple<any, OperationVariables>;
};

export default useBankAccountBookQuery;
