import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ height = 120, width = 121, color = "#0086FF" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 121 120" fill="none">
      <Path
        d="M60.502 81.65c-11.95 0-21.65-9.7-21.65-21.65s9.7-21.65 21.65-21.65 21.65 9.7 21.65 21.65-9.7 21.65-21.65 21.65zm0-35.8c-7.8 0-14.15 6.35-14.15 14.15 0 7.8 6.35 14.15 14.15 14.15 7.8 0 14.15-6.35 14.15-14.15 0-7.8-6.35-14.15-14.15-14.15z"
        fill={color}
      />
      <Path
        d="M60.498 105.1c-18.8 0-36.55-11-48.75-30.1-5.3-8.25-5.3-21.7 0-30 12.25-19.1 30-30.1 48.75-30.1s36.5 11 48.7 30.1c5.3 8.25 5.3 21.7 0 30-12.2 19.1-29.95 30.1-48.7 30.1zm0-82.7c-16.15 0-31.6 9.7-42.4 26.65-3.75 5.85-3.75 16.05 0 21.9 10.8 16.95 26.25 26.65 42.4 26.65s31.6-9.7 42.4-26.65c3.75-5.85 3.75-16.05 0-21.9-10.8-16.95-26.25-26.65-42.4-26.65z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
