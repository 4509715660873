import { Colors, Fonts } from "theme";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import useExpenseRequestStatusList from "components/ExpenseRequestItem/useExpenseRequestStatusList";
import { REQUEST_STATUS } from "constants/constants";

const StatusExpenseRequest = ({
  status,
  textColor = Colors.grayscale100,
  style,
}: {
  status: REQUEST_STATUS | string;
  textColor?: string;
  style?: ViewStyle;
}) => {
  const STATUS = useExpenseRequestStatusList();
  if (!STATUS[status]?.bgcolor) {
    return null;
  }
  return (
    <View style={[styles.container, { backgroundColor: STATUS[status]?.bgcolor }, style]}>
      <Text style={[Fonts.BodySmall, { color: textColor }]}>{STATUS[status]?.text}</Text>
    </View>
  );
};

export default StatusExpenseRequest;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
  },
});
