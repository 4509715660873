import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { FC, ReactNode, useState } from "react";
import { StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import { AppText } from "components/index";
import { startLayoutAnimation } from "utils";

interface CollapsibleViewProps {
  style?: ViewStyle;
  headerStyle?: ViewStyle;
  headerComponent?: ReactNode;
  rightComponentStyle?: ViewStyle;
  rightComponent?: ReactNode;
  divider?: ReactNode;
  rotate?: number;
  dividerStyle?: ViewStyle;
  summaryComponent?: ReactNode;
  numberOfLineTitle?: number;
  title?: string;
  titleStyle?: TextStyle;
  children?: ReactNode;
  showContent?: boolean;
}
const CollapsibleView: FC<CollapsibleViewProps> = ({
  style,
  headerStyle,
  headerComponent,
  rightComponentStyle,
  rightComponent,
  divider,
  rotate = 90,
  summaryComponent,
  dividerStyle,
  children,
  numberOfLineTitle,
  title,
  titleStyle,
  showContent: showContentProp,
}) => {
  const [showContent, setShowContent] = useState(showContentProp ?? false);
  const animationController = useSharedValue(showContentProp ? 1 : 0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${interpolate(animationController?.value, [0, 1], [0, rotate], Extrapolate.CLAMP)}deg`,
        },
      ],
    };
  }, [animationController.value]);
  const handleClick = () => {
    animationController.value = withTiming(showContent ? 0 : 1, {
      duration: 150,
      easing: Easing.linear,
    });
    startLayoutAnimation();
    setShowContent((prevState) => !prevState);
  };
  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity onPress={handleClick} style={[styles.header, headerStyle]} activeOpacity={0.7}>
        <View style={styles.flex}>
          {headerComponent ? (
            headerComponent
          ) : (
            <AppText
              style={titleStyle}
              {...(numberOfLineTitle && !showContent && { numberOfLines: numberOfLineTitle })}
            >
              {title}
            </AppText>
          )}
        </View>
        <View>
          <Animated.View style={[animatedStyle, rightComponentStyle]}>{rightComponent}</Animated.View>
        </View>
      </TouchableOpacity>
      {Boolean(divider) && <View style={[{ marginVertical: 8 }, dividerStyle]}>{divider}</View>}
      {!showContent && summaryComponent}
      {showContent && <View>{children}</View>}
    </View>
  );
};
export default CollapsibleView;
const styles = StyleSheet.create({
  container: { overflow: "hidden" },
  header: { flexDirection: "row", justifyContent: "space-between", gap: 8 },
  flex: { flex: 1 },
  // rightComponent: { marginTop: 5 },
});
