import React, { FC, useMemo, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";

import { AppText, TabViewInsideScrollView } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import ExpenseGroupedByCategoryTabView from "./ExpenseGroupedByCategoryTabView";
import ExpenseGroupedByRequestTabView from "./ExpenseGroupedByRequestTabView";
import { MobileExpenseReportDetailsQuery } from "types";

interface ExpenseListSectionProps {
  data: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
  showTitle?: boolean;
  handleRemoveExpense?: (item: any) => void;
  isValidateBudget: boolean;
  handleRemoveRequest?: (expenseRequestId: string) => void;
  onRefreshData?: () => void;
  enablePartialApproval?: boolean;
}

const ExpenseListSection: FC<ExpenseListSectionProps> = ({
  isValidateBudget,
  data,
  showTitle = true,
  handleRemoveExpense,
  handleRemoveRequest,
  onRefreshData,
  enablePartialApproval = false,
}) => {
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");
  const [validateByBudget, setValidateByBudget] = useState([]);
  const [validateByCategory, setValidateByCategory] = useState([]);
  const [index, setIndex] = useState(0);

  const routes = useMemo(() => {
    return [
      {
        key: "expense",
        title: t("expense_request_tab"),
        component: (
          <ExpenseGroupedByRequestTabView
            onRefreshData={onRefreshData}
            validateAmount={validateByCategory}
            setValidateAmount={setValidateByCategory}
            isValidateBudget={isValidateBudget}
            expenseList={data?.expenses}
            handleRemoveExpense={handleRemoveExpense}
            handleRemoveRequest={handleRemoveRequest}
            enablePartialApproval={enablePartialApproval}
          />
        ),
      },
      {
        key: "budget",
        title: t("budget_tab"),
        component: (
          <ExpenseGroupedByCategoryTabView
            onRefreshData={onRefreshData}
            validateAmount={validateByBudget}
            setValidateAmount={setValidateByBudget}
            isValidateBudget={isValidateBudget}
            expenseList={data?.expenses}
            handleRemoveExpense={handleRemoveExpense}
            handleRemoveRequest={handleRemoveRequest}
            enablePartialApproval={enablePartialApproval}
          />
        ),
      },
    ];
  }, [data?.expenses, validateByBudget, validateByCategory, handleRemoveRequest]);

  return (
    <View style={styles.container}>
      {showTitle && (
        <View style={styles.title}>
          <AppText
            style={[Fonts.SentenceSubTitleXLarge, { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING }]}
            color={Colors.grayscale100}
          >
            {t("expense_list")}
          </AppText>
        </View>
      )}
      <TabViewInsideScrollView index={index} setIndex={setIndex} routes={routes} />
    </View>
  );
};
export default ExpenseListSection;
const styles = StyleSheet.create({
  container:
    Platform.OS === "web"
      ? {
          paddingTop: 8,
          backgroundColor: Colors.white,
          flex: 1,
          minHeight: "unset" as any,
        }
      : {
          paddingTop: 8,
          backgroundColor: Colors.white,
          flex: 1,
        },
  title: {
    paddingVertical: 14,
  },
});
