import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";

import { AppText } from "components";

interface TransactionStatusProps {
  isCreatedExpense: boolean;
  style?: StyleProp<ViewStyle>;
}

const TransactionStatus: FC<TransactionStatusProps> = ({ style, isCreatedExpense }) => {
  const { t } = useTranslation("app/screens/Card/TransactionHistoryListScreen");
  return (
    <View style={[styles.container, style, { backgroundColor: isCreatedExpense ? Colors.success0 : Colors.warning0 }]}>
      <AppText style={Fonts.BodySmall}>{isCreatedExpense ? t("is_created_expense") : t("no_create_expense")}</AppText>
    </View>
  );
};

export default memo(TransactionStatus);
const styles = StyleSheet.create({
  container: {
    marginTop: 2,
    flexDirection: "row",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    alignItems: "center",
  },
});
