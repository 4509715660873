import * as React from "react";
import Svg, { Rect, G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={56} height={56} viewBox="0 0 56 57" fill="none">
      <Rect y={0.5} width={56} height={56} rx={28} fill="#FFC078" />
      <G clipPath="url(#clip0_5649_67005)">
        <Path
          d="M28 41.333c-7.364 0-13.334-5.97-13.334-13.333 0-7.364 5.97-13.334 13.334-13.334S41.333 20.636 41.333 28 35.363 41.333 28 41.333zm-1.33-8l9.427-9.428-1.885-1.885-7.542 7.543-3.772-3.772-1.885 1.885 5.657 5.657z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5649_67005">
          <Path fill="#fff" transform="translate(12 12)" d="M0 0H32V32H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
