import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const ASSIGN_TRANSACTION_EMPLOYEE_MUTATION = gql`
  mutation MobileCrdAssignTransactionEmployee($companyId: UUID!, $companyEmployeeId: UUID!, $cardTransactionId: UUID!) {
    crdAssignTransactionEmployee(
      input: { companyId: $companyId, companyEmployeeId: $companyEmployeeId, cardTransactionId: $cardTransactionId }
    )
  }
`;
const useAssignTransactionMutation = () => {
  return useMutation(ASSIGN_TRANSACTION_EMPLOYEE_MUTATION);
};

export default useAssignTransactionMutation;
