import React, { FC, useRef } from "react";
import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";

import {
  AppText,
  BottomSheetFlatListCustom,
  EmptyData,
  IconCustom,
  ImageCustom,
  SearchInput,
  SkeletonListLoading,
} from "components";
import { Colors, Fonts } from "theme";
import { CloseSearchIcon, EmptySearchIcon, SearchIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { useTranslation } from "react-i18next";

interface BankListViewProps {
  data: any[];
  textSearch: string;
  onChangeSearch: (text: string) => void;
  handleClearSearch: () => void;
  loading: boolean;
  handleSelectBank: (item) => void;
}

const BankListView: FC<BankListViewProps> = ({
  data,
  textSearch,
  onChangeSearch,
  handleClearSearch,
  loading,
  handleSelectBank,
}) => {
  const { t } = useTranslation("app/screens/Expense/components/BankListView");
  const textSearchRef = useRef<TextInput>();
  const onSelectBank = (item) => () => {
    handleSelectBank(item);
  };

  const renderItem = ({ item, index }) => {
    const isLastItem = index === data.length - 1;
    return (
      <>
        <TouchableOpacity style={styles.transactionItem} onPress={onSelectBank(item)}>
          <View style={[styles.leftIcon, {}]}>
            <ImageCustom
              contentFit="contain"
              source={{ uri: item.logoUrl }}
              containerStyle={styles.imageContainer}
              style={styles.avatar}
            />
          </View>
          <View style={styles.flex}>
            <AppText style={Fonts.BodyMedium}>{`${item.bankShortName} (${item.bankCode})`}</AppText>
            <View style={styles.rowItem}>
              <AppText style={[Fonts.BodySmall, styles.title]} color={Colors.grayscale60} numberOfLines={1}>
                {item.bankName}
              </AppText>
            </View>
          </View>
        </TouchableOpacity>
        <View style={[styles.divider, isLastItem && { marginBottom: 20 }]} />
      </>
    );
  };

  const renderEmpty = () => {
    if (loading) {
      return null;
    }
    return <EmptyData icon={<EmptySearchIcon />} title={t("empty_bank")} />;
  };

  return (
    <View style={styles.container}>
      <View style={styles.filterContainer}>
        <SearchInput
          ref={textSearchRef}
          placeholder={t("search")}
          onChangeText={onChangeSearch}
          right={
            textSearch ? (
              <TouchableOpacity
                onPress={() => {
                  textSearchRef?.current?.clear();
                  handleClearSearch();
                }}
              >
                <IconCustom name="cancel" />
              </TouchableOpacity>
            ) : null
          }
        />
      </View>

      {loading ? (
        <SkeletonListLoading />
      ) : (
        <BottomSheetFlatListCustom
          keyExtractor={(item, index) => item?.bankId ?? index}
          data={data}
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
          ListEmptyComponent={renderEmpty}
        />
      )}
    </View>
  );
};
export default BankListView;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  filterContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  flex: { flex: 1 },
  transactionItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  leftIcon: { marginRight: 16 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  title: { flex: 1, paddingRight: 5 },
  // eslint-disable-next-line react-native/no-color-literals
  imageContainer: {
    backgroundColor: Colors.white,
    shadowColor: "rgba(0, 0, 0, 0.05)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
    elevation: 4,
  },
  avatar: {
    borderRadius: 4,
    width: 40,
    height: 40,
  },
  rowItem: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10, marginTop: 4 },
});
