// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import BizziButton from "./ButtonOutdate/BizziButton";
import Line from "./Line";
import { FullPageErrorFallback, FullPageSpinner } from "./Lib";
import { IInputField, Input, PaperInput } from "./Input";
import { BizziCircle } from "./Circle/BizziCircle";
import { Icon } from "./Icon";
import AppText from "./AppText";
import CopyContainer from "./CopyContainer";
import WebView from "./WebView";
import { ProgressiveImage } from "./Image";
import HtmlView from "./HtmlView";
import { Loading } from "./loading";
import TextInput from "./TextInput";
import SearchInput from "./SearchInput";
import CheckBox from "./CheckBox";
import RadioButton from "./RadioButton";
import Button from "./Button";
import BottomSheetCustom, { BottomSheetCustomMethods } from "./BottomSheetCustom/BottomSheetCustom";
import AlertNotification, { AlertNotificationHandle } from "./AlertNotification/AlertNotification";
import ExpenseItem from "./ExpenseItem/ExpenseItem";
import AllocatedStatus from "./ExpenseItem/AllocatedStatus";
import BackHeader from "./BackHeader";
import TopTabView from "./TopTabView";
import TopTabLineView from "./TopTabLineView";
import UserAvatarDefault from "./UserAvatarDefault";
import CreateCircleButton from "./CreateCircleButton";
import ExpenseReportItem from "./ExpenseReportItem/ExpenseReportItem";
import BottomSheetModalCustom, { BottomSheetModalCustomMethods } from "./BottomSheetModalCustom/BottomSheetModalCustom";
import Tabs from "./Tabs";
import DatePickerField from "./DatePickerField";
import KeyboardAwareScrollViewCustom from "./KeyboardAwareScrollViewCustom";
import HeaderPageBottomTab from "./HeaderPageBottomTab";
import EmptyData from "./EmptyData";
import FloatFooter from "./FloatFooter";
import FloatFooterExpand from "./FloatFooterExpand";
import ApproverChooserModal, { ApproverChooserModalMethods } from "./ApproverChooserModal";
import CurrencyText from "./CurrencyText";
import { ToastManager, ToastMessage } from "./Toast";
import TextView from "./TextView";
import StatusDetail from "./StatusDetail";
import LoadingView from "./LoadingView";
import ToggleInputView from "./ToggleInputView";
import ApprovalTimeLine from "./ApprovalTimeLine";
import UploadFileView from "./UploadFiles/UploadFileView";
import BottomSheetList from "components/BottomSheetList";
import useBackHandler from "./hooks/useBackHandler";
import useSetRelativePosition from "./hooks/useSetRelativePosition";
import useWindowDimensions from "./hooks/useWindowDimensions";
import useInteractionManager from "./hooks/useInteractionManager";

import useDebounce from "./hooks/useDebounce";
import { TooltipComponent, CopilotStepCustom, CopilotProviderCustom } from "./Walkthrough";
import TabViewInsideScrollView from "./TabViewInsideScrollView";
import {
  TextInputCustom,
  CurrencyInput,
  CategorySelect,
  CompanyTeamSelect,
  DatePickerCustom,
  ButtonInput,
  SelectCustom,
  RadioSelect,
  PickerCustom,
} from "./InputCustom";
import BottomSheetFlatListCustom from "./BottomSheetFlatListCustom";
import BottomSheetScrollViewCustom from "./BottomSheetScrollViewCustom";
import SlideView from "./SlideView";
import ImageCustom from "./ImageCustom";
import Section from "./Section";
import ImageViewCustom from "./ImageViewCustom/ImageViewCustom";
import BottomSheetScrollViewModalCustom, {
  BottomSheetScrollViewModalCustomMethods,
} from "./BottomSheetScrollViewModalCustom/BottomSheetScrollViewModalCustom";
import BottomSheetFlatListModalCustom from "./BottomSheetFlatListModalCustom/BottomSheetFlatListModalCustom";
import TextInputFlatListModal from "./BottomSheetFlatListModalCustom/TextInputFlatListModal";
import CustomMenu from "./CustomMenu/CustomMenu";

import {
  ToggleCheck,
  CheckboxSelect,
  SelectDate,
  RadioSelectFilter,
  SelectCompanyTeam,
  SelectExpenseCategory,
  SelectEmployee,
} from "./AdvanceSearch";
import SkeletonListLoading from "./SkeletonListLoading";
import EmployeeChooser from "./AdvanceSearch/EmployeeChooser/EmployeeChooser";
import ActionIconButtonMenu from "./ActionIconButtonMenu";
import InvoiceStatus from "./InvoiceStatus";
import BottomSheetTextInputCustom from "./BottomSheetTextInputCustom";
import FlatListCustom from "./FlatListCustom";
import AnimatedFlatList from "./AnimatedFlatList";
import DashedLine from "./DashedLine";
import SwitchButton from "./SwitchButton";
import CollapsibleView from "./CollapsibleView";
import { PieChart, LineHorizontalChart, HorizontalBarChart, BarChartCompareHorizontal } from "./Chart";
import MonthPickerCustom from "./MonthPickerCustom/MonthPickerCustom";
import EmployeeTextView from "./EmployeeInfoView/EmployeeTextView";
import EmployeeModalView from "./EmployeeInfoView/EmployeeModalView";
import AvatarUser from "./AvatarUser";
import CollapsibleViewMenu from "./CollapsibleViewMenu";
import NewFeatureTag from "./NewFeatureTag";
import PercentLabel from "./PercentLabel";
import IconCustom from "./IconCustom";
import TouchableOpacityCustom from "./TouchableOpacityCustom";
import ApprovalStage from "./ApprovalStage";
import ApprovalFlowInline from "./ApprovalFlowInline";
import InAppFeedbackModal from "./InAppFeedbackModal/InAppFeedbackModal";
import RangeSlider from "./RangeSlider/RangeSlider";
import BadgeSelect from "./BadgeSelect/BadgeSelect";
import Badge from "./Badge/Badge";
import LottieLoading from "./LottieLoading";
import FeatureWrapper from "./FeatureWrapper/FeatureWrapper";
import ApprovalFlowTooltip from "./HowTo/ApprovalFlowTooltip";
import StatusBarCustom from "./StatusBarCustom";
import SortingLabel from "./SortingLabel/SortingLabel";
import SelectActionPicker from "./SelectActionPicker";
import ProgressBarCircle from "./ProgressBarCircle/ProgressBarCircle";
import VideoPlayerView, { VideoPlayerViewMethods } from "./VideoPlayerView/VideoPlayerView";
import SelectBottomCustom from "./InputCustom/SelectBottomCustom";

export {
  Button,
  Input as InputField,
  BizziButton,
  BizziCircle,
  FullPageSpinner,
  FullPageErrorFallback,
  Line,
  Icon,
  ProgressiveImage,
  HtmlView,
  CheckBox,
  Loading,
  PaperInput,
  /*-- custom hook --*/
  useBackHandler,
  useSetRelativePosition,
  useWindowDimensions,
  useInteractionManager,
  useDebounce,
  /*-- end custom hook --*/

  /*--- new component ----*/
  AppText,
  WebView,
  Tabs,
  BottomSheetCustom,
  BottomSheetModalCustom,
  TextInput,
  SearchInput,
  RadioButton,
  AlertNotification,
  BackHeader,
  ExpenseItem,
  TopTabView,
  TopTabLineView,
  UserAvatarDefault,
  CreateCircleButton,
  ExpenseReportItem,
  DatePickerField,
  KeyboardAwareScrollViewCustom,
  HeaderPageBottomTab,
  EmptyData,
  FloatFooter,
  FloatFooterExpand,
  ApproverChooserModal,
  CurrencyText,
  ToastMessage,
  ToastManager,
  TextView,
  StatusDetail,
  LoadingView,
  ToggleInputView,
  ApprovalTimeLine,
  UploadFileView,
  BottomSheetList,
  TooltipComponent,
  CopilotStepCustom,
  CopilotProviderCustom,
  TabViewInsideScrollView,
  TextInputCustom,
  CurrencyInput,
  CategorySelect,
  CompanyTeamSelect,
  DatePickerCustom,
  BottomSheetFlatListCustom,
  ButtonInput,
  BottomSheetScrollViewCustom,
  SelectCustom,
  SlideView,
  PickerCustom,
  RadioSelect,
  ImageCustom,
  ImageViewCustom,
  Section,
  BottomSheetScrollViewModalCustom,
  BottomSheetFlatListModalCustom,
  TextInputFlatListModal,
  ToggleCheck,
  CheckboxSelect,
  SelectDate,
  RadioSelectFilter,
  SelectCompanyTeam,
  SelectExpenseCategory,
  EmployeeChooser,
  SelectEmployee,
  SkeletonListLoading,
  ActionIconButtonMenu,
  InvoiceStatus,
  BottomSheetTextInputCustom,
  AllocatedStatus,
  FlatListCustom,
  AnimatedFlatList,
  DashedLine,
  SwitchButton,
  CollapsibleView,
  PieChart,
  LineHorizontalChart,
  MonthPickerCustom,
  HorizontalBarChart,
  EmployeeTextView,
  EmployeeModalView,
  AvatarUser,
  CollapsibleViewMenu,
  NewFeatureTag,
  BarChartCompareHorizontal,
  PercentLabel,
  IconCustom,
  TouchableOpacityCustom,
  ApprovalStage,
  ApprovalFlowInline,
  RangeSlider,
  InAppFeedbackModal,
  Badge,
  BadgeSelect,
  CopyContainer,
  LottieLoading,
  FeatureWrapper,
  ApprovalFlowTooltip,
  StatusBarCustom,
  SortingLabel,
  SelectActionPicker,
  ProgressBarCircle,
  VideoPlayerView,
  CustomMenu,
  SelectBottomCustom,
};

export type {
  IInputField,
  AlertNotificationHandle,
  BottomSheetModalCustomMethods,
  BottomSheetCustomMethods,
  ApproverChooserModalMethods,
  BottomSheetScrollViewModalCustomMethods,
  VideoPlayerViewMethods,
};
