import { StyleSheet, TouchableOpacity, View } from "react-native";
import { AppText, BarChartCompareHorizontal, LoadingView } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { FC, ReactElement, useMemo } from "react";
import { MobileExpInsightsExpenseOverviewQuery } from "types";
import { useTranslation } from "react-i18next";
import { getTitleFromCategory } from "utils";
import { useNavigation } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import { OrgTeam } from "screens/ExpenseOverview/types";
import { CHART_TYPE } from "screens/ExpenseOverview/DashboardDetailScreen/types";

interface CategoryChartSectionProps {
  legend?: ReactElement;
  data: MobileExpInsightsExpenseOverviewQuery["expInsights"]["topSpendingCategories"];
  isCompare?: boolean;
  loading: boolean;
  fromDate: string;
  toDate: string;
  companyTeam?: OrgTeam;
}
const CategoryChartSection: FC<CategoryChartSectionProps> = ({
  legend,
  data,
  isCompare,
  loading,
  fromDate,
  toDate,
  companyTeam,
}) => {
  const { t } = useTranslation("app/screens/ExpenseOverview/ExpenseOverviewScreen");
  const navigation = useNavigation<any>();
  const categories = useMemo(() => {
    if (!data?.categories) {
      return [];
    }
    const anotherCategory = {
      totalAmount: data?.otherAmount,
      title: t("other_categories"),
      titleEn: t("other_categories"),
      code: "other",
    };
    const newCategories = data?.categories?.sort((a, b) => b.totalAmount - a.totalAmount) || [];
    if (data?.totalCategories > newCategories?.length) {
      return [...newCategories, ...[anotherCategory]];
    }
    return newCategories;
  }, [data]);

  const chartData = useMemo(() => {
    return categories.map((category: any) => ({
      key: category.categoryId,
      title: getTitleFromCategory({ title: category?.title, titleEn: category?.titleEn }),
      thisMonthAmount: category.totalAmount,
      prevMonthAmount: category.totalAmountPrevious,
      diffPercentage: category.totalAmountDiffPercentage,
      metadata: category,
    }));
  }, [categories]);

  const handleNavigate = (item) => () => {
    navigation.navigate(SCREEN_NAME.DashboardDetailScreen, {
      title: t("expenses_by_category"),
      subTitle: item ? getTitleFromCategory({ title: item?.title, titleEn: item?.titleEn }) : "",
      categoryId: item?.categoryId,
      companyTeamId: companyTeam?.teamId,
      fromDate,
      toDate,
      type: item?.categoryId ? (companyTeam?.teamId ? CHART_TYPE.EMPLOYEE : CHART_TYPE.TEAM) : CHART_TYPE.CATEGORY,
    });
  };

  return (
    <View style={styles.container}>
      <AppText style={Fonts.H300} color={Colors.primary100}>
        {t("expense_category_chart")}
      </AppText>
      {loading ? (
        <LoadingView style={styles.loadingView} />
      ) : categories?.length ? (
        <>
          {legend}
          <BarChartCompareHorizontal
            data={chartData}
            showPercent={isCompare}
            onItemPress={(metadata) => {
              if (metadata?.code === "other") {
                return;
              }
              const handler = handleNavigate(metadata);
              handler();
            }}
          />
          <TouchableOpacity style={styles.viewDetailButton} onPress={handleNavigate({ title: t("all_category") })}>
            <AppText style={Fonts.BodySmall}>{t("view_detail_categories")}</AppText>
          </TouchableOpacity>
        </>
      ) : (
        <AppText style={styles.emptyText} color={Colors.grayscale60}>
          {t("no_data")}
        </AppText>
      )}
    </View>
  );
};
export default CategoryChartSection;
const styles = StyleSheet.create({
  container: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingVertical: 20, gap: 16 },
  loadingView: { height: 250 },
  viewDetailButton: {
    marginTop: 15,
    height: 32,
    backgroundColor: Colors.primary0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  emptyText: { ...Fonts.BodyMedium, textAlign: "center" },
});
