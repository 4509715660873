// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BottomSheetFlatList, useBottomSheetInternal } from "@gorhom/bottom-sheet";

import { Platform, StyleSheet, Text, TextInput as RNTextInput, TouchableOpacity, View, FlatList } from "react-native";
import { Checkbox } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useCompanyEmployeesQuery from "hooks/useCompanyEmployeesQuery";
import isEmpty from "lodash/isEmpty";
import { useAuth } from "contexts/AuthContext";
import { removeAccents } from "utils/searchObject";
import { BottomSheetModalCustom, BottomSheetModalCustomMethods, Button, SearchInput, IconCustom } from "components";
import { SearchInputProps } from "components/SearchInput";
import { SearchIcon } from "assets/images/svg/icons";
import { Colors } from "theme";

type Props = {
  setSelectedEmployee: any;
  selectedEmployee: {
    id: string;
    label: string;
  };
  onTransfer?: () => void;
};

const styles = StyleSheet.create({
  wrapperItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
  },
  fSize14: {
    fontSize: 14,
  },
  fBold: {
    fontWeight: "bold",
  },
  boxEmployee: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  searchInput: { marginHorizontal: 20, marginBottom: 10 },
  footer: { paddingHorizontal: 20, paddingTop: 10 },
});

const SearchInputModal = forwardRef(
  ({ onFocus, onBlur, ...props }: SearchInputProps, ref: ForwardedRef<RNTextInput>) => {
    const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

    const handleOnFocus = useCallback(
      (args) => {
        shouldHandleKeyboardEvents.value = true;
        onFocus?.(args);
      },
      [onFocus, shouldHandleKeyboardEvents]
    );
    const handleOnBlur = useCallback(
      (args) => {
        shouldHandleKeyboardEvents.value = false;
        onBlur?.(args);
      },
      [onBlur, shouldHandleKeyboardEvents]
    );
    return <SearchInput ref={ref} {...props} onFocus={handleOnFocus} onBlur={handleOnBlur} />;
  }
);
const ModalTransferInvoiceEmployee = React.forwardRef<BottomSheetModalCustomMethods, Props>(
  ({ setSelectedEmployee, selectedEmployee, onTransfer }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
      },
      close: () => {
        bottomSheetRef?.current?.close?.();
      },
    }));
    const { t } = useTranslation("app/screens/Inbox/InvoiceDetailScreen");
    const [keyword, setKeyword] = useState("");
    const { user } = useAuth();
    const { top, bottom } = useSafeAreaInsets();
    const { employees } = useCompanyEmployeesQuery();

    const employeesListTransfer = useMemo(() => {
      if (isEmpty(employees)) {
        return [];
      }
      return employees.filter((employee) => {
        const keywordUnicode = removeAccents(keyword).toLowerCase();
        const emailUnicode = removeAccents(employee?.email ?? "").toLowerCase();
        const fullNameEnUnicode = removeAccents(employee?.fullname ?? "").toLowerCase();
        return (
          (emailUnicode.includes(keywordUnicode) || fullNameEnUnicode.includes(keywordUnicode)) &&
          employee.employee_id !== user.employee_id
        );
      });
    }, [employees, user, keyword]);

    const handleChangeText = useCallback((text) => {
      setKeyword(text);
    }, []);

    const onCheckedEmployee = useCallback(
      (employee) =>
        setSelectedEmployee({
          id: employee.employee_id,
          label: employee.title ? `${employee.fullname} - ${employee.title}` : `${employee.fullname}`,
        }),
      [setSelectedEmployee]
    );

    const renderItem = ({ item }) => {
      const active = selectedEmployee?.id === item?.employee_id;
      return (
        <TouchableOpacity
          onPress={() => onCheckedEmployee(item)}
          activeOpacity={0.9}
          style={StyleSheet.flatten([styles.wrapperItem])}
        >
          <View style={styles.boxEmployee}>
            <View>
              <Text style={[styles.fSize14, styles.fBold]}>
                {item.title ? `${item.fullname} - ${item.title}` : `${item.fullname}`}
              </Text>
              <Text>{item.email}</Text>
            </View>
            {active && <Checkbox.Item label="" status="checked" />}
          </View>
        </TouchableOpacity>
      );
    };

    const onSend = () => {
      onTransfer();
      setKeyword("");
    };
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
    const renderFooter = () => (
      <View
        style={[
          styles.footer,
          { paddingBottom: bottom || 10 },
          Platform.OS === "web" && { position: "absolute", bottom: 55, width: "100%", backgroundColor: Colors.white },
        ]}
      >
        <Button onPress={onSend} disabled={!selectedEmployee.id}>
          {t("send")}
        </Button>
      </View>
    );
    return (
      <BottomSheetModalCustom
        keyboardBehavior="fillParent"
        title={t("transfer")}
        ref={bottomSheetRef}
        snapPoints={["80%"]}
        keyboardBlurBehavior="restore"
        footerComponent={renderFooter}
        topInset={top}
        onDismiss={() => setKeyword("")}
      >
        <SearchInputModal
          style={styles.searchInput}
          placeholder={t("placeholder_receiver")}
          autoCapitalize="none"
          returnKeyLabel={t("next")}
          onChangeText={handleChangeText}
          enablesReturnKeyAutomatically
          returnKeyType="search"
          value={keyword}
        />
        {Platform.OS === "web" ? (
          <FlatList
            data={employeesListTransfer}
            renderItem={renderItem}
            contentContainerStyle={{ paddingHorizontal: 20, paddingTop: 0, paddingBottom: 140 }}
            keyExtractor={(item) => item?.employee_id}
          />
        ) : (
          <BottomSheetFlatList
            data={employeesListTransfer}
            renderItem={renderItem}
            contentContainerStyle={{ paddingHorizontal: 20, paddingTop: 0 }}
            keyExtractor={(item) => item?.employee_id}
          />
        )}
      </BottomSheetModalCustom>
    );
  }
);

export default React.memo(ModalTransferInvoiceEmployee);
