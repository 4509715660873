import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  CheckBox,
} from "components";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import { isIphoneX } from "constants/Layout";
import FilterInput from "./FilterInput";
import { useTranslation } from "react-i18next";

export type SelectItem = {
  label: string;
  value: string | number | any;
  leftIcon?: ReactNode;
  disabled?: boolean;
};
interface CheckboxSelectProps {
  title?: string;
  data?: SelectItem[];
  value?: SelectItem[];
  setValue?: (value: SelectItem[]) => void;
}
const CheckboxSelect: FC<CheckboxSelectProps> = ({ data, title, value, setValue }) => {
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const [currentValue, setCurrentValue] = useState<SelectItem[]>([]);
  const { t } = useTranslation("app/components/AdvanceSearch");
  useEffect(() => {
    setCurrentValue(value || []);
  }, [value]);

  const handleCheckboxSelect = (item) => () => {
    const statusIsExist = currentValue?.find((i) => i?.value === item.value);
    if (statusIsExist) {
      setCurrentValue((prevState) => prevState.filter((i) => i?.value !== item.value));
    } else {
      setCurrentValue((prevState) => prevState.concat([item]));
    }
  };
  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    if (currentValue?.length > 0) {
      setValue([]);
    }
  };
  const handleApplyFilter = () => {
    bottomSheetRef?.current?.close();
    setValue(currentValue);
  };
  return (
    <>
      <FilterInput
        title={title}
        value={value ? value.map((i) => i.label).join(", ") : ""}
        onPress={() => bottomSheetRef?.current?.present()}
        onClear={handleClearFilter}
      />
      <BottomSheetScrollViewModalCustom wrapperByScrollView={false} ref={bottomSheetRef} title={title}>
        {data?.map((item, index) => {
          const isLastItem = index === data.length - 1;
          const isChecked = currentValue?.find((i) => i?.value === item?.value);
          return (
            <View key={item?.value}>
              <TouchableOpacity style={styles.selectItem} onPress={handleCheckboxSelect(item)}>
                <View style={styles.labelContainer}>
                  {item?.leftIcon ? item?.leftIcon : null}
                  <AppText style={Fonts.BodyLarge}>{item?.label}</AppText>
                </View>
                <CheckBox checked={Boolean(isChecked)} />
              </TouchableOpacity>
              {!isLastItem && <View style={styles.divider} />}
            </View>
          );
        })}
        <View style={styles.footer}>
          <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
            {t("clear_filter")}
          </Button>
          <Button style={styles.flex} onPress={handleApplyFilter} disabled={currentValue?.length === 0}>
            {!currentValue || currentValue?.length <= 0 ? t("apply") : `${t("apply")} (${currentValue.length})`}
          </Button>
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default CheckboxSelect;
const styles = StyleSheet.create({
  selectItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
  },
  flex: { flex: 1 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  footer: {
    flexDirection: "row",
    gap: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginTop: 20,
  },
  labelContainer: { flexDirection: "row", flex: 1, alignItems: "center", gap: 16 },
});
