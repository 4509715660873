// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import trim from "lodash/trim";

const computeTaxValidations = (validations, t) => {
  const taxVerificationData = {
    isVerifying: validations.is_verifying,
    gdtResults: validations.gdt_results,
    isLegalSeller: validations.is_legal_seller,
    isIssuedInvoice: validations.is_issued,
    screenshotAt: validations.tchd_screenshot_at,
    tchdScreenshot: validations.tchd_screenshot,
  };

  const isVerifying = taxVerificationData.isVerifying || taxVerificationData.isLegalSeller === 2;

  if (isVerifying) {
    return {
      isVerifying,
      taxVerificationData,
      computedTaxValidations: [
        {
          key: "verifying_invoice",
          status: "loading",
          message: "Đang kiểm tra thông tin với TCT",
          popoverInfo: null,
        },
      ],
    };
  }

  let invoiceStatus = taxVerificationData?.gdtResults?.invoice?.status ?? [];
  if (!Array.isArray(invoiceStatus)) {
    invoiceStatus = [invoiceStatus];
  }

  const sellerTaxValidations = [
    {
      key: "legal_seller",
      status: taxVerificationData?.isLegalSeller ? "success" : "error",
      message: taxVerificationData?.gdtResults?.seller?.status || "Không tìm thấy thông tin người bán",
    },
  ];

  const invoiceTaxValidations = invoiceStatus.map((status) => {
    const message = trim(status) || t("invoice_not_register_validation");
    const errorVnMessage =
      message.includes("Không tồn tại hóa đơn") ||
      message.includes("xoá bỏ") ||
      message.includes("xóa bỏ") ||
      message.includes("thông báo hủy") ||
      message.includes("hết giá trị sử dụng") ||
      message.includes("đăng ký phát hành") ||
      message.includes("Hoá đơn đã bị thay thế") ||
      message.toLowerCase().includes("tính chất huỷ") ||
      message.includes("Hoá đơn đã bị xoá") ||
      message.includes("Hóa đơn không đủ điều kiện cấp mã");

    const errorEngMessage =
      message.includes("no invoice that corresponds") ||
      message.includes("been registered") ||
      message.includes("has been replaced") ||
      message.includes("has been deleted");
    const isError = errorVnMessage || errorEngMessage;

    return {
      key: message,
      status: isError ? "error" : "success",
      message,
    };
  });

  const computedTaxValidations = [...sellerTaxValidations, ...invoiceTaxValidations].map((item) => {
    return {
      ...item,
      message: item.message ?? "",
      popoverInfo: null,
    };
  });

  return {
    isVerifying,
    taxVerificationData,
    computedTaxValidations,
    invoiceTaxValidations,
    sellerTaxValidations,
  };
};

const getInvoiceValidationResult = (t, invoice) => {
  const INVOICE_VALIDATION_MESSAGES = {
    buyer_tax_code_error: t("buyer_tax_code_error"),
    buyer_tax_code_success: t("buyer_tax_code_success"),
    seller_tax_code_error: t("seller_tax_code_error"),
    seller_tax_code_success: t("seller_tax_code_success"),
    buyer_legal_name_error: t("buyer_legal_name_error"),
    buyer_legal_name_success: t("buyer_legal_name_success"),
    seller_legal_name_error: t("seller_legal_name_error"),
    seller_legal_name_success: t("seller_legal_name_success"),
    buyer_address_line_error: t("buyer_address_line_error"),
    buyer_address_line_success: t("buyer_address_line_success"),
    seller_address_line_error: t("seller_address_line_error"),
    seller_address_line_success: t("seller_address_line_success"),
    has_xml_warning: t("has_xml_warning"),
    certificate_error: t("certificate_error"),
    certificate_success: t("certificate_success"),
    has_certificate_error: t("has_certificate_error"),
    has_signature_error: t("has_signature_error"),
    has_signature_success: t("has_signature_success"),
    valid_signature_error: t("valid_signature_error"),
    valid_signature_success: t("valid_signature_success"),
    digest_error: t("digest_error"),
    digest_success: t("digest_success"),
    sign_date_error: t("sign_date_error"),
    sign_date_success: t("sign_date_success"),
    total_amount_with_vat_error: t("total_amount_with_vat_error"),
    total_amount_with_vat_success: t("total_amount_with_vat_success"),
    tax_verification_code_success: t("tax_verification_code_success"),
    currency_success: t("currency_success"),
    required_fields_success: t("required_fields_success"),
    currency_error: t("currency_error"),
    required_fields_error: t("required_fields_error"),
    header_and_items_total_amount_without_vat_error: t("header_and_items_total_amount_without_vat_error"),
    header_and_items_total_amount_without_vat_success: t("header_and_items_total_amount_without_vat_success"),
    header_and_items_total_vat_amount_error: t("header_and_items_total_vat_amount_error"),
    header_and_items_total_vat_amount_success: t("header_and_items_total_vat_amount_success"),
    header_and_items_total_amount_with_vat_error: t("header_and_items_total_amount_with_vat_error"),
    header_and_items_total_amount_with_vat_success: t("header_and_items_total_amount_with_vat_success"),
  };

  const VALIDATION_DETAIL_ITEMS = [
    {
      key: "buyer_tax_code_error",
      title: t("buyer_tax_code_error_validation"),
    },
    {
      key: "buyer_legal_name_error",
      title: t("buyer_legal_name_error_validation"),
    },
    {
      key: "buyer_address_line_error",
      title: t("buyer_address_line_error_validation"),
    },
    {
      key: "sign_date_error",
      title: t("sign_date_error_validation"),
    },
    {
      key: "certificate_success",
      title: t("certificate_success_validation"),
    },
    {
      key: "certificate_error",
      title: t("certificate_error_validation"),
    },
    {
      key: "valid_signature_error",
      title: t("valid_signature_error_validation"),
    },
    {
      key: "valid_signature_success",
      title: t("valid_signature_success_validation"),
    },
    {
      key: "total_amount_with_vat_error",
      title: t("total_amount_with_vat_error_validation"),
    },
    {
      key: "currency_error",
      title: t("currency_error_validation"),
    },
    {
      key: "required_fields_error",
      title: t("required_fields_error_validation"),
    },
  ];
  const validations = invoice?.invoiceValidations?.[0] || {};
  const invoiceValidationResult = validations?.validationAggregation?.results || [];

  const computedInvoiceValidations = invoiceValidationResult
    .map((item) => {
      const validationKey = `${item?.key}_${item?.status}`;
      return {
        ...item,
        message: INVOICE_VALIDATION_MESSAGES[validationKey] || "",
        popoverInfo: VALIDATION_DETAIL_ITEMS.find((detailItem) => detailItem.key.includes(validationKey)),
      };
    })
    .filter((validation) => validation.message);

  const { isVerifying, taxVerificationData, computedTaxValidations, invoiceTaxValidations, sellerTaxValidations } =
    computeTaxValidations(validations, t);

  const allValidations = [...computedInvoiceValidations, ...computedTaxValidations];
  const isValidInvoice =
    allValidations.length &&
    allValidations.every((validation) => validation.status === "success" || validation.status === "warning");

  return {
    isVerifying,
    computedInvoiceValidations,
    computedTaxValidations,
    taxVerificationData,
    isValidInvoice,
    invoiceTaxValidations,
    sellerTaxValidations,
  };
};

const useInvoiceValidationResult = () => {
  const { t } = useTranslation("hooks/useInvoiceValidationResult");
  const fn = useCallback((invoice) => getInvoiceValidationResult(t, invoice), [t]);
  return [fn];
};

export default useInvoiceValidationResult;
