import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  EmptyData,
  IconCustom,
  RadioButton,
  SearchInput,
} from "components";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Colors, Fonts } from "theme";
import { EmptyResultFound, SearchNotFoundIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import FilterInput from "./FilterInput";
import { useTranslation } from "react-i18next";
import { getTitleI18n } from "utils";
import useQueryExpenseCategory from "hooks/useQueryExpenseCategory";
import { removeAccents } from "utils/searchObject";
import { filter } from "lodash";

export type ExpenseCategory = {
  order: number;
  code?: string;
  title: string;
  titleEn?: string | null;
  expenseCategoryId: string;
};

interface SelectExpenseCategoryProps {
  minWidth?: number;
  title?: string;
  value?: ExpenseCategory;
  setValue?: (value: ExpenseCategory) => void;
}

const SelectExpenseCategory: FC<SelectExpenseCategoryProps> = ({ title, value, setValue, minWidth }) => {
  const { t } = useTranslation("app/components/AdvanceSearch");
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const searchRef = useRef<TextInput>();
  const [currentValue, setCurrentValue] = useState<ExpenseCategory>(null);

  const { data } = useQueryExpenseCategory();
  const dataCategories: ExpenseCategory[] = useMemo(() => {
    return data.map((category) => ({
      order: category?.order,
      code: category?.expense_category?.code,
      title: category?.expense_category?.title,
      titleEn: category?.expense_category?.title_en,
      expenseCategoryId: category.expense_category_id,
    }));
  }, [data]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (dataCategories.length > 0) {
      setCategoryOptions(dataCategories);
    }
  }, [dataCategories]);

  const [keyword, setKeyword] = useState("");
  const debounceSearchTimeoutId = useRef(null);
  const onChangeSearch = (text) => {
    setKeyword(text);
    if (!text) {
      setCategoryOptions(dataCategories);
      return;
    }
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      const newTeamOptions = filter(categoryOptions, (category) => {
        const keywordUnicode = removeAccents(text).toLowerCase();
        const titleUnicode = removeAccents(category?.title ?? "").toLowerCase();
        const titleEnUnicode = removeAccents(category?.titleEn ?? "").toLowerCase();
        const codeUnicode = removeAccents(category?.code ?? "").toLocaleLowerCase();
        return (
          titleEnUnicode.includes(keywordUnicode) ||
          titleUnicode.includes(keywordUnicode) ||
          codeUnicode.includes(keywordUnicode)
        );
      });
      setCategoryOptions(newTeamOptions);
    }, 400);
  };

  const handleSelectExpenseCategory = (item) => () => {
    setCurrentValue(item);
  };

  const handleClearSearch = () => {
    searchRef?.current?.setNativeProps({ text: "" });
    setKeyword("");
    setCategoryOptions(dataCategories);
  };

  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    setCategoryOptions(dataCategories);
    if (currentValue) {
      setValue(null);
    }
  };

  const handleApplyFilter = () => {
    bottomSheetRef?.current?.close();
    setValue(currentValue);
  };

  const renderFooter = useCallback(() => {
    return (
      <View style={styles.footerButton}>
        <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
          {t("clear_filter")}
        </Button>
        <Button style={styles.flex} onPress={handleApplyFilter} disabled={!currentValue}>
          {t("apply")}
        </Button>
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!currentValue, data, t]);

  const renderSearch = () => (
    <SearchInput
      style={styles.searchInput}
      placeholder={t("search_category_title")}
      onChangeText={onChangeSearch}
      autoCapitalize="none"
      enablesReturnKeyAutomatically
      returnKeyType="search"
      ref={searchRef}
      //value={keyword || ""}
      right={
        keyword ? (
          <TouchableOpacity onPress={handleClearSearch}>
            <IconCustom name="cancel" />
          </TouchableOpacity>
        ) : null
      }
    />
  );

  const renderItem = ({ item: category }) => {
    const isChecked = currentValue?.expenseCategoryId === category?.expenseCategoryId;
    return (
      <View key={category.expenseCategoryId}>
        <TouchableOpacity
          activeOpacity={0.9}
          style={styles.companyTeamItem}
          onPress={handleSelectExpenseCategory(category)}
        >
          <AppText style={[Fonts.BodyLarge, styles.flex]}>
            {getTitleI18n({ vi: category.title, en: category.titleEn })}
            {category.code ? ` (${category.code})` : ""}
          </AppText>
          <RadioButton checked={isChecked} />
        </TouchableOpacity>
        <View style={styles.divider} />
      </View>
    );
  };

  return (
    <>
      <FilterInput
        title={title}
        value={value ? getTitleI18n({ vi: value.title, en: value.titleEn }) : ""}
        onPress={() => bottomSheetRef?.current?.present()}
        onClear={handleClearFilter}
        minWidth={minWidth}
      />
      <BottomSheetFlatListModalCustom
        renderSearch={renderSearch}
        renderFooter={renderFooter}
        ref={bottomSheetRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={title}
        listProps={{
          data: categoryOptions,
          renderItem: renderItem,
          keyExtractor: (item) => item?.teamType?.teamTypeId,
          ListEmptyComponent: (
            <EmptyData
              title={!keyword ? t("category_empty_placeholder") : t("search_not_found")}
              icon={!keyword ? <EmptyResultFound /> : <SearchNotFoundIcon />}
            />
          ),
        }}
      />
    </>
  );
};
export default SelectExpenseCategory;
const styles = StyleSheet.create({
  flex: { flex: 1 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  companyTeamItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
  },
  searchInput: { marginHorizontal: 20, marginBottom: 5 },
  footerButton: { flexDirection: "row", gap: 8 },
});
