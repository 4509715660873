import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";
import { BackHandler } from "react-native";

const useBackHandler = (handler: () => boolean) => {
  useFocusEffect(
    useCallback(() => {
      BackHandler.addEventListener("hardwareBackPress", handler);
      return () => BackHandler.removeEventListener("hardwareBackPress", handler);
    }, [handler])
  );
};
export default useBackHandler;
