import { useAuth } from "contexts/AuthContext";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import { MobileAssignableExpenseRequestQuery, MobileAssignableExpenseRequestQueryVariables } from "types";

const NUMBER_ROWS_PER_PAGE = 10;
const ASSIGNABLE_EXPENSE_REQUEST = gql`
  query MobileAssignableExpenseRequest($input: ExpAssignableExpenseRequestInput!) {
    assignableExpenseRequest: expAssignableExpenseRequest(input: $input) {
      total
      assignableExpenseRequests {
        cashAdvances {
          amount
        }
        isLocked
        expenseRequestId
        docSequence
        expenseTravelId
        expenseRequestType
        title
        status
        currency
        updatedAt
        totalAmount
        companyTeams {
          companyTeamId
        }
        expenseTravels {
          expenseTravelId
        }
      }
    }
  }
`;
export const useAssignableExpenseRequest = () => {
  const { user } = useAuth();
  return useLazyQuery<MobileAssignableExpenseRequestQuery, MobileAssignableExpenseRequestQueryVariables>(
    ASSIGNABLE_EXPENSE_REQUEST,
    {
      variables: {
        input: { limit: NUMBER_ROWS_PER_PAGE * 100, offset: 0, employeeId: user.employee_id },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );
};
