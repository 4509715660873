import * as React from "react";
import { FC, ReactNode } from "react";
import { Image, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { CopilotStep, useCopilot } from "react-native-copilot";
import { CONSTANTS } from "constants/constants";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";
import { useTranslation } from "react-i18next";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

interface CopilotStepCustomProps {
  title?: string;
  text: string;
  style?: StyleProp<ViewStyle>;
  order?: number;
  maskOffset?: number;
  children: ReactNode;
  onNext?: () => void;
  image: ReactNode;
  singleStep?: boolean;
  walkthroughName: string;
  borderRadius?: number;
  autoScroll?: boolean;
  onBack?: () => void;
  onConfirm?: () => void;
  onSkip?: () => void;
  confirmText?: string;
  activeStep?: number;
  totalStep?: number;
  offsetX?: number;
  offsetY?: number;
}

const CustomComponent = ({ copilot, style, children }) => (
  <View style={style} {...copilot}>
    {children}
  </View>
);

const CopilotStepCustom: FC<CopilotStepCustomProps> = ({
  title,
  text,
  style,
  order,
  children,
  image,
  singleStep = false,
  walkthroughName,
  borderRadius = 16,
  autoScroll = false,
  onBack,
  onConfirm,
  onSkip,
  confirmText,
  activeStep,
  totalStep,
  offsetX = 0,
  offsetY = 0,
}) => {
  const { handleSkipGuideAction } = useWalkthroughGuide();
  const { t } = useTranslation("app/contexts/WalkthroughGuideContext");
  const { totalStep: totalStepCopilot } = useCopilot();
  const handleSkip = () => {
    onSkip?.();
    handleSkipGuideAction({ stepNumber: order + 1, stepTitle: title, totalStep: totalStep ?? totalStepCopilot });
  };
  return (
    <CopilotStep
      walkthroughName={walkthroughName}
      text={title ? `${t(title as any)}${CONSTANTS.WALK_THROUGH.AddTitleKey}${t(text as any)}` : t(text as any)}
      order={order}
      name={`${order}_${walkthroughName}`}
      image={<Image resizeMode="contain" source={image as any} style={styles.bizziBotImage} />}
      singleStep={singleStep}
      borderRadius={borderRadius}
      autoScroll={autoScroll}
      onBack={onBack}
      onConfirm={onConfirm}
      onSkip={handleSkip}
      confirmText={confirmText ? t(confirmText as any) : ""}
      activeStep={activeStep}
      totalStep={totalStep}
      offsetX={offsetX}
      offsetY={offsetY}
    >
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CustomComponent style={[styles.container, style]}>{children}</CustomComponent>
      }
    </CopilotStep>
  );
};
export default CopilotStepCustom;
const styles = StyleSheet.create({
  container: {},
  bizziBotImage: { width: 84, height: 91 },
});
