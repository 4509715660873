import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 25, height = 24, color = "#94A3B8" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 25 24" fill="none">
      <Path
        d="M12.5 22.75c-.78 0-1.54-.4-2.06-1.1L9.43 20.3c-.21-.28-.49-.44-.79-.46-.3-.01-.6.12-.84.37l-.57-.51.55.51c-1.44 1.54-2.55 1.42-3.08 1.21-.54-.21-1.45-.9-1.45-3.12V7.04c0-4.44 1.28-5.79 5.47-5.79h7.56c4.19 0 5.47 1.35 5.47 5.79V18.3c0 2.21-.91 2.9-1.45 3.12-.53.21-1.63.33-3.08-1.21-.24-.26-.53-.4-.85-.37-.3.02-.59.18-.8.46l-1.01 1.35c-.52.7-1.28 1.1-2.06 1.1zm-3.92-4.42h.13c.74.04 1.44.43 1.91 1.06l1.01 1.35c.49.65 1.24.65 1.73 0l1.01-1.35c.48-.63 1.17-1.02 1.92-1.06.74-.04 1.48.27 2.02.85.76.81 1.26.91 1.43.84.24-.1.5-.68.5-1.72V7.04c0-3.61-.63-4.29-3.97-4.29H8.72c-3.34 0-3.97.68-3.97 4.29V18.3c0 1.05.26 1.63.5 1.72.17.07.67-.03 1.43-.84.54-.55 1.21-.85 1.9-.85z"
        fill={color}
      />
      <Path
        d="M16.5 7.75h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM15.5 11.75h-6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
