import * as React from "react";
import Svg, { Path, Ellipse } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M1.914 7h13.388M1.914 11h13.388M15.303 18H1.914V6.21c-.001-.29.047-.578.14-.847.094-.269.231-.513.405-.718.174-.206.38-.369.607-.48.227-.11.47-.166.716-.165h9.652c.246-.001.49.055.716.166.228.11.434.273.608.479.173.205.31.45.404.718.094.269.141.557.14.848V18zM15.359 18v2.25c0 .199-.07.39-.196.53a.635.635 0 01-.474.22h-2.008a.635.635 0 01-.473-.22.798.798 0 01-.196-.53V18M5.206 18v2.25c0 .199-.07.39-.196.53a.635.635 0 01-.473.22H2.53a.635.635 0 01-.474-.22.798.798 0 01-.196-.53V18"
        stroke="#1F71F4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4.48 15.75c.555 0 1.005-.504 1.005-1.125S5.035 13.5 4.48 13.5c-.555 0-1.004.504-1.004 1.125s.45 1.125 1.004 1.125zM12.739 15.75c.554 0 1.004-.504 1.004-1.125s-.45-1.125-1.005-1.125c-.554 0-1.004.504-1.004 1.125s.45 1.125 1.005 1.125z"
        fill="#1F71F4"
      />
      <Path d="M20.163 10l.48 3.5-1.92 2v3" stroke="#1F71F4" strokeWidth={1.5} strokeLinecap="round" />
      <Path
        d="M21.12 19.5l-.48-3.5v-2.5M17.281 12c.32-.5 2.496-2 2.88-2 .384 0 1.76 1.167 2.4 2 .32.667.48 1.6.48 2"
        stroke="#1F71F4"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <Ellipse cx={20.1627} cy={7.5} rx={1.44} ry={1.5} fill="#1F71F4" />
    </Svg>
  );
}

export default SvgComponent;
