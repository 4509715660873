import { AppText, CurrencyText, useWindowDimensions } from "components";
import { CONSTANTS } from "constants/constants";
import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { MobileExpExpenseRequestDetailQuery } from "types";
import isEmpty from "lodash/isEmpty";
import { CustomTable } from "components/Table";
import sumBy from "lodash/sumBy";

type SelectedVendorInfoProps = {
  data: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]["priceComparisonVendors"];
};
const SelectedVendorInfo: FC<SelectedVendorInfoProps> = ({ data }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const { width } = useWindowDimensions();

  const columns = useMemo(() => {
    const cols = [
      {
        key: "index",
        title: t("index"),
        width: 45,
        textAlign: "center" as any,
        render: (_, index) => <AppText style={[Fonts.BodyMedium, styles.textCenter]}>{index + 1}</AppText>,
      },
      {
        key: "vendorName",
        title: t("vendor_name"),
        width: 160,
        textAlign: "left" as any,
      },
      {
        key: "totalAmount",
        title: t("vendor_total_amount"),
        width: 0,
        textAlign: "right" as any,
        render: (item) => <CurrencyText style={[Fonts.BodyMedium, styles.textRight]}>{item.totalAmount}</CurrencyText>,
      },
    ];
    cols[2].width = width - cols[0].width - cols[1].width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING;
    return cols;
  }, [t, width]);
  const totalAmount = useMemo(() => {
    return sumBy(data, "totalAmount");
  }, [data]);

  return (
    <View style={styles.container}>
      <AppText style={[Fonts.SentenceSubtitleLarge, styles.mb16]}>{t("selected_vendor")}</AppText>
      {isEmpty(data) ? (
        <AppText style={[Fonts.BodyMedium, styles.emptyText]} color={Colors.grayscale60}>
          {t("empty_selected_vendor")}
        </AppText>
      ) : (
        <CustomTable
          isScrollTable={false}
          columns={columns}
          dataSource={data}
          footerData={[
            "",
            t("total_amount_vendor"),
            <CurrencyText style={[Fonts.BodyMedium, styles.textRight]}>{totalAmount}</CurrencyText>,
          ]}
        />
      )}
    </View>
  );
};

export default memo(SelectedVendorInfo);

const styles = StyleSheet.create({
  mb16: {
    marginBottom: 16,
  },
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  emptyText: { textAlign: "center" },
  textCenter: { textAlign: "center" },
  textRight: { textAlign: "right" },
});
