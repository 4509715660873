import { FC, Fragment, ReactNode, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { AppText, CheckBox, IconCustom, Line, RadioButton } from "components";
import { Colors, Fonts } from "theme";
import { CheckBoxMinus } from "assets/images/svg/icons";
import { startLayoutAnimation } from "utils";
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

export enum SectionCheckboxStatus {
  ALL = "ALL",
  SOME = "SOME",
  NONE = "NONE",
}

interface ExpenseSelectSectionProps {
  section: any;
  status: typeof SectionCheckboxStatus.ALL | SectionCheckboxStatus.SOME | SectionCheckboxStatus.NONE;
  isChecked?: boolean;
  handleSelectExpenseSection?: (section: any) => void;
  isRadioSelect?: boolean;
  renderItem?: ({ item: any }) => ReactNode;
}

const ExpenseSelectSection: FC<ExpenseSelectSectionProps> = ({
  section,
  status,
  handleSelectExpenseSection,
  isRadioSelect,
  renderItem,
}) => {
  const animationController = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${interpolate(animationController?.value, [0, 1], [0, 180], Extrapolate.CLAMP)}deg`,
        },
      ],
    };
  }, [animationController.value]);
  const [isExpand, setIsExpand] = useState(true);
  const handleToggleExpand = () => {
    startLayoutAnimation();
    animationController.value = withTiming(!isExpand ? 0 : 1, {
      duration: 150,
      easing: Easing.linear,
    });

    setIsExpand((prevState) => !prevState);
  };
  return (
    <>
      <View style={styles.expenseItem}>
        <View style={styles.expenseBody}>
          <TouchableOpacity
            style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            onPress={() => handleSelectExpenseSection(section)}
          >
            <View style={styles.leftIcon}>
              {
                {
                  [SectionCheckboxStatus.ALL]: isRadioSelect ? <RadioButton checked /> : <CheckBox checked />,
                  [SectionCheckboxStatus.SOME]: <CheckBoxMinus />,
                  [SectionCheckboxStatus.NONE]: isRadioSelect ? <RadioButton /> : <CheckBox />,
                }[status]
              }
            </View>
            <View style={styles.contentContainer}>
              <AppText style={Fonts.BodyMedium}>{section.title}</AppText>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={styles.viewMoreButton} onPress={handleToggleExpand}>
            <Animated.View style={animatedStyle}>
              <IconCustom name="expand-less" />
            </Animated.View>
          </TouchableOpacity>
        </View>
      </View>
      {isExpand &&
        section?.data?.map((expense, i, expenses) => {
          const notLastInGroup = i < expenses.length - 1;
          return (
            <Fragment key={expense.expenseId}>
              {renderItem({ item: expense })}
              {notLastInGroup && <Line color={Colors.grayscale05} />}
            </Fragment>
          );
        })}
    </>
  );
};
export default ExpenseSelectSection;
const styles = StyleSheet.create({
  expenseItem: {
    paddingLeft: 10,
    backgroundColor: Colors.grayscale0,
  },
  leftIcon: { alignItems: "center", width: 48 },
  contentContainer: {
    flex: 1,
  },
  expenseBody: { flexDirection: "row", alignItems: "center", gap: 10 },
  viewMoreButton: { paddingRight: 20, paddingLeft: 10, paddingVertical: 16 },
});
