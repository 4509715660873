import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";
import { Divider } from "react-native-paper";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useBottomSheetInternal, WINDOW_WIDTH } from "@gorhom/bottom-sheet";
import { ScrollView, StyleSheet, TouchableOpacity, TextInput as RNTextInput, View } from "react-native";
import { ForwardedRef, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import {
  AppText,
  Line,
  Button,
  TextInput,
  SearchInput,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewCustom,
  IconCustom,
} from "components";
import { Colors, Fonts } from "theme";
import { Keys } from "constants/Keys";
import { isIphoneX } from "constants/Layout";
import { CONSTANTS } from "constants/constants";
import { SearchInputProps } from "components/SearchInput";
import useCompanyEmployeesQuery from "hooks/useCompanyEmployeesQuery";
import { ArrowDownIcon, ArrowLeftIcon, CloseIcon, LineApproverIcon, SearchIcon } from "assets/images/svg/icons";

interface ApproverChooserProps {
  approverId?: string;
  onSubmit?: () => void;
  onApproverId?: (approverId: string) => void;
}

export interface ApproverChooserMethods {
  present: () => void;
}

const ApproverSearchInput = forwardRef(
  ({ onFocus, onBlur, ...props }: SearchInputProps, ref: ForwardedRef<RNTextInput>) => {
    const { shouldHandleKeyboardEvents } = useBottomSheetInternal();

    const handleOnFocus = (args: any) => {
      shouldHandleKeyboardEvents.value = true;
      onFocus?.(args);
    };

    const handleOnBlur = (args: any) => {
      shouldHandleKeyboardEvents.value = false;
      onBlur?.(args);
    };

    return <SearchInput ref={ref} {...props} onFocus={handleOnFocus} onBlur={handleOnBlur} />;
  }
);

const ApproverChooser = forwardRef<ApproverChooserMethods, ApproverChooserProps>(
  ({ onApproverId, onSubmit, approverId }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
      },
    }));
    const { t } = useTranslation("app/screens/Expense/components/ApproverChooser");
    const { employees } = useCompanyEmployeesQuery();
    const [recentChosen, setRecentChosen] = useState<CompanyEmployees[]>([]);
    const [employeeSelected, setEmployeeSelected] = useState<CompanyEmployees>();

    const [keyword, setKeyword] = useState("");

    const scrollViewRef = useRef<ScrollView>(null);
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);

    const handleChangeText = (text: string) => {
      setKeyword(text);
    };

    const handleViewApprover = () => {
      scrollViewRef.current?.scrollTo({
        x: WINDOW_WIDTH,
        animated: true,
      });
    };

    const handleBack = () => {
      scrollViewRef.current?.scrollTo({
        x: 0,
        animated: true,
      });
    };

    const onSelectEmployee = useCallback(
      (employee: CompanyEmployees) => async () => {
        onApproverId?.(employee.employee_id);
        setEmployeeSelected(employee);
        const listRecentChosen = uniqBy([employee, ...recentChosen], "employee_id").slice(0, 3);

        await AsyncStorage.setItem(Keys.RECENT_CHOOSE_APPROVAL, JSON.stringify(listRecentChosen));
        setRecentChosen(listRecentChosen);
        setKeyword("");
        handleBack();
      },
      [onApproverId, recentChosen]
    );

    const getRecentChosen = async () => {
      const employeeLocals = await AsyncStorage.getItem(Keys.RECENT_CHOOSE_APPROVAL);

      if (employeeLocals && Array.isArray(JSON.parse(employeeLocals)) && employeeLocals.length > 0) {
        setRecentChosen(JSON.parse(employeeLocals));
      }
    };

    useEffect(() => {
      getRecentChosen();
    }, []);

    useEffect(() => {
      if (approverId) {
        const approver = employees.find(({ employee_id }) => employee_id === approverId);
        setEmployeeSelected(approver);
      }
    }, [approverId, employees]);

    const renderHeaderHandle = () => {
      return null;
    };

    const renderItem = (employee: CompanyEmployees, showDivider: boolean) => {
      const { employee_id, fullname, email } = employee;
      return (
        <TouchableOpacity
          key={employee_id}
          onPress={onSelectEmployee(employee)}
          style={{
            backgroundColor: employee_id === approverId ? Colors.primary0 : Colors.while,
          }}
        >
          <View style={styles.item}>
            <AppText style={StyleSheet.flatten([Fonts.BodyMedium, { color: Colors.grayscale80 }])}>{fullname}</AppText>
            <AppText style={StyleSheet.flatten([Fonts.BodySmall, { color: Colors.grayscale60 }])}>{email}</AppText>
          </View>
          {showDivider && <Divider />}
        </TouchableOpacity>
      );
    };
    const employeeViews = employees
      .filter((employee) => !recentChosen?.includes(employee.employee_id) || keyword)
      .filter(
        (employee) =>
          !recentChosen?.some(({ employee_id }) => employee_id === employee.employee_id) &&
          (!keyword || (keyword && (employee.fullname?.includes(keyword) || employee.email.includes(keyword))))
      );

    return (
      <BottomSheetModalCustom
        handleComponent={renderHeaderHandle}
        title={t("submit_expense_approval")}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        ref={bottomSheetRef}
      >
        <ScrollView
          ref={scrollViewRef}
          horizontal
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          bounces={false}
          pagingEnabled
        >
          <View style={styles.modalContent}>
            <View style={styles.header}>
              <AppText style={Fonts.H500}>{t("submit_expense_approval")}</AppText>
              <TouchableOpacity style={styles.buttonClose} onPress={() => bottomSheetRef?.current?.close()}>
                <CloseIcon />
              </TouchableOpacity>
            </View>

            <View style={styles.container}>
              <View style={styles.wrapperIcon}>
                <LineApproverIcon />
              </View>
              <AppText style={styles.description}>{t("description")}</AppText>
              <TouchableOpacity onPress={handleViewApprover}>
                <TextInput
                  editable={false}
                  pointerEvents="none"
                  value={employeeSelected?.fullname ?? ""}
                  right={
                    <TouchableOpacity onPress={handleViewApprover}>
                      <IconCustom name="expand-more" />
                    </TouchableOpacity>
                  }
                  label={`${t("approved_by")}`}
                />
              </TouchableOpacity>
            </View>

            <View style={styles.footer}>
              <View style={styles.wrapperButton}>
                <Button type="ghost" style={styles.flex} onPress={() => bottomSheetRef?.current?.close()}>
                  {t("back_btn")}
                </Button>
                <Button type="primary" style={styles.buttonSubmit} onPress={onSubmit} disabled={!approverId}>
                  {t("submit_btn")}
                </Button>
              </View>
            </View>
          </View>
          <View style={styles.modalContent}>
            <View style={styles.headerApprover}>
              <TouchableOpacity style={styles.buttonBack} onPress={handleBack}>
                <ArrowLeftIcon />
                <AppText style={[Fonts.H500, styles.approver]}>{t("choose_a_approver")}</AppText>
              </TouchableOpacity>
              <TouchableOpacity style={styles.buttonClose} onPress={() => bottomSheetRef?.current?.close()}>
                <CloseIcon />
              </TouchableOpacity>
            </View>
            <ApproverSearchInput style={styles.searchInput} onChangeText={handleChangeText} />
            <BottomSheetScrollViewCustom showsVerticalScrollIndicator={false}>
              {!keyword && !isEmpty(recentChosen) && (
                <>
                  <View style={styles.employeesContainer}>
                    <AppText style={Fonts.H200}>{t("recent_approver")}</AppText>
                    {recentChosen.map((employee, index) => {
                      return renderItem(employee, index < employees.length - 1);
                    })}
                  </View>
                  <Line size="lg" />
                </>
              )}
              <View style={styles.employeesContainer}>
                <View>
                  {!keyword && <AppText style={Fonts.H200}>{t("list_approver")}</AppText>}
                  {employeeViews.map((employee, index) => {
                    return renderItem(employee, index < employees.length - 1);
                  })}
                </View>
              </View>
            </BottomSheetScrollViewCustom>
          </View>
        </ScrollView>
      </BottomSheetModalCustom>
    );
  }
);
export default memo(ApproverChooser);

const styles = StyleSheet.create({
  item: {
    paddingVertical: 15,
  },
  searchInput: {
    marginHorizontal: 20,
  },
  approver: { marginLeft: 15 },
  description: {
    marginVertical: 20,
  },
  flex: {
    flex: 1,
  },
  buttonSubmit: {
    flex: 1,
    marginLeft: 20,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 20,
    paddingLeft: 20,
    paddingRight: 15,
  },

  wrapperButton: {
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    position: "absolute",
    width: WINDOW_WIDTH,
    bottom: isIphoneX() ? 45 : 30,
  },
  modalContent: { width: WINDOW_WIDTH },
  container: {
    padding: 20,
  },
  wrapperIcon: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 30,
  },

  employeesContainer: {
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  headerApprover: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 17,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 20,
  },
  buttonClose: { padding: 5 },
  buttonBack: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
  },
});
