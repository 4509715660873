// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from "react";
import { useMemo } from "react";
import { BottomTabNavigationOptions, createBottomTabNavigator } from "@react-navigation/bottom-tabs";
// import { FeatureFlagContext } from "contexts/FeatureFlagContext";
import ReportsScreen from "screens/Report/ReportListScreen/ReportListScreen";
import HomeScreen from "screens/Home/HomeScreen";
import {
  CardBottomActiveIcon,
  CardBottomIcon,
  ExpenseActiveIcon,
  ExpenseIcon,
  HomeActiveIcon,
  HomeIcon,
  PaymentBottomActiveIcon,
  PaymentBottomIcon,
  RequestBottomActiveIcon,
  RequestBottomIcon,
} from "assets/images/svg/icons";
import RequestScreen from "screens/ExpenseRequest/ExpenseRequestListScreen/ExpenseRequestListScreen";
import ExpensesScreen from "screens/Expense/ExpenseListScreen/ExpenseListScreen";

import { SCREEN_BOTTOM_TAB, TBottomTabScreen } from "./ScreenName";
import { HomeBottomTabNavigationProp, HomeBottomTabRouteProp, TabParamList } from "./type";
import { useTranslation } from "react-i18next";
import { useIsExpenseRequestEnabled, useIsFeatureEnabled } from "contexts/FeatureManagementContext";
import { useAuth } from "contexts/AuthContext";
import { COMPANIES } from "constants/Companies";
import BottomTabBar from "navigation/BottomTabBar";
import CardListScreen from "screens/Card/CardListScreen/CardListScreen";
import { FEATURE_FLAGS } from "constants/FeatureFlags";

const BottomTab = createBottomTabNavigator<TabParamList>();
const INITIAL_ROUTE_NAME = SCREEN_BOTTOM_TAB.Home;

interface IBottomTab {
  name: TBottomTabScreen;
  component: React.ComponentType<any>;
  options: BottomTabNavigationOptions;
}

interface IHomeBottomTabNavigator {
  navigation: HomeBottomTabNavigationProp;
  route: HomeBottomTabRouteProp;
}

const HomeBottomTabNavigator: React.FC<IHomeBottomTabNavigator> = ({ navigation, route }) => {
  const { t } = useTranslation("navigation/HomeBottomTabNavigator");
  const { company } = useAuth();
  // const { FEATURE_SHOW_REPORT_BOTTOM_TAB } = useContext(FeatureFlagContext);
  const isExpenseRequestEnabled = useIsExpenseRequestEnabled();
  const FEATURE_CARD = useIsFeatureEnabled(FEATURE_FLAGS.FEATURE_CARD);

  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );

  const BOTTOM_TAB: IBottomTab[] = useMemo(
    () =>
      [
        {
          name: SCREEN_BOTTOM_TAB.Home,
          component: HomeScreen,
          options: {
            headerShown: false,
            title: t("home_screen"),
            tabBarIcon: {
              active: <HomeActiveIcon />,
              inactive: <HomeIcon />,
            },
          },
        },
        !isTLNExpenseCompany &&
          isExpenseRequestEnabled && {
            headerShown: false,
            name: SCREEN_BOTTOM_TAB.RequestScreen,
            component: RequestScreen,
            options: {
              title: t("request"),
              tabBarIcon: {
                active: <RequestBottomActiveIcon />,
                inactive: <RequestBottomIcon />,
              },
            },
            //initialParams: { tab: ExpenseRequestListTabs.MINE },
          },

        {
          name: SCREEN_BOTTOM_TAB.ExpensesScreen,
          component: ExpensesScreen,
          options: {
            headerShown: false,
            title: t("expense"),
            tabBarIcon: {
              active: <ExpenseActiveIcon />,
              inactive: <ExpenseIcon />,
            },
          },
        },

        !isTLNExpenseCompany && {
          name: SCREEN_BOTTOM_TAB.ReportsScreen,
          component: ReportsScreen,
          options: {
            headerShown: false,
            title: t("report"),
            tabBarIcon: {
              active: <PaymentBottomActiveIcon />,
              inactive: <PaymentBottomIcon />,
            },
          },
        },
        FEATURE_CARD && {
          name: SCREEN_BOTTOM_TAB.CardScreen,
          component: CardListScreen,
          options: {
            headerShown: false,
            title: t("card"),
            tabBarIcon: {
              active: <CardBottomActiveIcon />,
              inactive: <CardBottomIcon />,
            },
          },
        },
      ].filter(Boolean),
    [t, isTLNExpenseCompany, isExpenseRequestEnabled, FEATURE_CARD]
  );

  /*useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);*/

  return (
    <BottomTab.Navigator
      backBehavior="history"
      tabBar={(props) => <BottomTabBar {...props} />}
      initialRouteName={route?.params?.tab || INITIAL_ROUTE_NAME}
      screenOptions={{
        headerShown: false,
      }}
    >
      {BOTTOM_TAB.map((item) => (
        <BottomTab.Screen key={item.name} {...item} />
      ))}
    </BottomTab.Navigator>
  );
};
export default HomeBottomTabNavigator;
