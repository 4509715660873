import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={25} height={25}>
        <Path fill="#D9D9D9" d="M0.617188 0.0996094H24.617188V24.0996094H0.617188z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12.139 14.274l-3.151-3.151a.51.51 0 01-.103-.146.457.457 0 01.087-.492.423.423 0 01.328-.135h6.638c.135 0 .245.045.328.136a.46.46 0 01.124.32c0 .03-.047.136-.14.317l-3.151 3.15a.639.639 0 01-.48.209.638.638 0 01-.48-.208z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
