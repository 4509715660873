import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  RadioButton,
} from "components";
import React, { FC, ReactNode, useRef, useState } from "react";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";
import FilterInput from "./FilterInput";
import { useTranslation } from "react-i18next";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";

interface RadioSelectFilterProps {
  title?: string;
  data?: SelectItem[];
  value?: SelectItem;
  setValue?: (value: SelectItem) => void;
  minWidth?: number;
  renderIconButton?: () => ReactNode;
  showClearButton?: boolean;
  onPress?: () => void;
  showClearFilter?: boolean;
}
const RadioSelectFilter: FC<RadioSelectFilterProps> = ({
  data,
  title,
  value,
  setValue,
  minWidth,
  renderIconButton,
  showClearButton = true,
  onPress,
  showClearFilter = true,
}) => {
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const [currentValue, setCurrentValue] = useState<SelectItem>(null);
  const { t } = useTranslation("app/components/AdvanceSearch");

  const handleRadioSelect = (item) => () => {
    // const statusIsExist = currentValue.value === item?.value;
    setCurrentValue(item);
  };
  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    if (currentValue) {
      setValue?.(null);
    }
  };
  const handleApplyFilter = () => {
    bottomSheetRef?.current?.close();
    setValue?.(currentValue);
  };
  const handleOpenModal = () => {
    setCurrentValue(value);
    bottomSheetRef?.current?.present();
    onPress?.();
  };
  return (
    <>
      {renderIconButton ? (
        <TouchableOpacity onPress={handleOpenModal}>{renderIconButton?.()}</TouchableOpacity>
      ) : (
        <FilterInput
          title={title}
          value={value ? value.label : ""}
          onPress={handleOpenModal}
          onClear={showClearFilter ? handleClearFilter : undefined}
          minWidth={minWidth}
        />
      )}

      <BottomSheetScrollViewModalCustom wrapperByScrollView={false} ref={bottomSheetRef} title={title}>
        {data?.map((item, index) => {
          const isLastItem = index === data.length - 1;
          const isChecked = currentValue?.value === item?.value;
          return (
            <View key={item?.value}>
              <TouchableOpacity style={styles.selectItem} onPress={handleRadioSelect(item)}>
                <View style={styles.labelContainer}>
                  {item?.leftIcon ? item?.leftIcon : null}
                  <AppText style={Fonts.BodyLarge}>{item?.label}</AppText>
                </View>
                <RadioButton checked={Boolean(isChecked)} />
              </TouchableOpacity>
              {!isLastItem && <View style={styles.divider} />}
            </View>
          );
        })}
        <View style={styles.footer}>
          {showClearButton && (
            <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
              {t("clear_filter")}
            </Button>
          )}
          <Button style={styles.flex} onPress={handleApplyFilter} disabled={!currentValue}>
            {t("apply")}
          </Button>
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default RadioSelectFilter;
export type { RadioSelectFilterProps };
const styles = StyleSheet.create({
  selectItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
  },
  flex: { flex: 1 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  footer: {
    flexDirection: "row",
    gap: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginTop: 20,
  },
  labelContainer: { flexDirection: "row", flex: 1, alignItems: "center", gap: 16 },
});
