import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={16} height={16}>
        <Path fill="#D9D9D9" d="M0 0H16V16H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M1.242 13.667L8 2l6.756 11.667H1.242zM8 11.872c.152 0 .28-.052.383-.155a.52.52 0 00.155-.384.521.521 0 00-.155-.383.521.521 0 00-.383-.155.521.521 0 00-.384.155.521.521 0 00-.155.383c0 .153.052.28.155.384a.521.521 0 00.384.155zm-.5-1.744h1V6.795h-1v3.333z"
          fill="#F59E0B"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
