import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_5803_386364)">
        <Path d="M6.364 15.172l9.192-9.193 1.415 1.414L6.364 18 0 11.636l1.414-1.414 4.95 4.95z" fill="#94A3B8" />
      </G>
      <Path d="M12.673 15.193L21.865 6l1.415 1.414-10.607 10.607-2.501-2.521 1.5-1.308 1 1.001z" fill="#94A3B8" />
      <Defs>
        <ClipPath id="clip0_5803_386364">
          <Path fill="#fff" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
