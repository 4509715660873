import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View, TextInput } from "react-native";

import {
  AppText,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  SearchInput,
  EmptyData,
  Button,
  SkeletonListLoading,
  IconCustom,
} from "components";

import { Fonts, Colors } from "theme";
import { CloseIcon, CloseSearchIcon, SearchIcon, EmptyAccountBookIcon, PlusSquareIcon } from "assets/images/svg/icons";
import { ACCOUNT_TYPES, CONSTANTS } from "constants/constants";
import { BankAccount } from "../ExpenseForm/types";
import useBankAccountBookQuery from "screens/Expense/hooks/useBankAccountBookQuery";
import { useAuth } from "contexts/AuthContext";
import AccountListView from "../AccountListView";
import { useTranslation } from "react-i18next";
import { convertViToEn } from "utils";
import useBankAccountBookByVendorTaxCode from "screens/Expense/hooks/useBankAccountBookByPartnerTaxCode";

export interface PaymentChooserFormRef {
  present: (type: ACCOUNT_TYPES, metadata?: any) => void;
  close: () => void;
}
interface PaymentChooserProps {
  onSelect?: (values: BankAccount) => void;
  handleOpenPaymentForm?: () => void;
}

const BankAccountChooser = forwardRef<PaymentChooserFormRef, PaymentChooserProps>(
  ({ onSelect, handleOpenPaymentForm }, ref) => {
    useImperativeHandle(ref, () => ({
      present: handlePresent,
      close: handleClose,
    }));
    const { t } = useTranslation("app/screens/Expense/components/BankAccountChooser");
    const {
      user: {
        company: { company_id: companyId },
        employee_id: employeeId,
      },
    } = useAuth();
    const [accountType, setAccountType] = useState<ACCOUNT_TYPES>();

    const handleClose = () => {
      paymentChooserBottomSheetRef.current?.close();
      setTextSearch("");
    };

    const [fetchVendorBankAccounts, { data: vendorBankAccounts, loading: vendorBankAccountsLoading }] =
      useBankAccountBookByVendorTaxCode();
    const [, { data: bankAccounts, refetch, loading: bankAccountsLoading }] = useBankAccountBookQuery({
      employeeIds: [employeeId],
      accountType,
    });
    const loading = bankAccountsLoading || vendorBankAccountsLoading;

    const handlePresent = (type: ACCOUNT_TYPES, metadata) => {
      const sellerTaxCode = metadata?.invoiceElectronic?.sellerTaxCode;
      // sellerTaxCode ex: "0315497184"
      if (sellerTaxCode) {
        fetchVendorBankAccounts({
          variables: {
            companyId,
            partnerTaxCode: sellerTaxCode,
          },
        });
      }
      setAccountType(type);
      refetch();
      paymentChooserBottomSheetRef?.current?.present();
    };

    const snapPoints = useMemo(() => [CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT], []);
    const paymentChooserBottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
    const textSearchRef = useRef<TextInput>();

    const [textSearch, setTextSearch] = useState("");
    const [filteredBankAccounts, setFilteredBankAccounts] = useState([]);
    const [filteredVendorBankAccounts, setFilteredVendorBankAccounts] = useState([]);

    useEffect(() => {
      if (textSearch) {
        const textSearchInLowerCase = convertViToEn(textSearch).toLocaleLowerCase();
        const filterCallback = (account) =>
          [
            account.description,
            account.aliasName,
            account.bankShortName,
            account.accountHolderName,
            account.accountNumber,
            account.bankCode,
          ].some((t) => convertViToEn(String(t)).toLocaleLowerCase().includes(textSearchInLowerCase));

        const filteredBankAccounts = bankAccounts.filter(filterCallback);
        const filteredVendorBankAccounts = vendorBankAccounts.filter(filterCallback);

        setFilteredBankAccounts(filteredBankAccounts);
        setFilteredVendorBankAccounts(filteredVendorBankAccounts);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    const handleSelect = (account) => {
      onSelect({ ...account, type: accountType });
    };

    const dataSource = useMemo(() => {
      const sourceOfTruth = textSearch ? filteredBankAccounts : bankAccounts;
      const result: any = {
        mainSection: {
          title: t("saved_accounts"),
          data: sourceOfTruth.filter(({ isMine }) => !isMine),
        },
      };

      const myAccounts = sourceOfTruth.filter(({ isMine }) => !!isMine);
      if (accountType === ACCOUNT_TYPES.PERSONAL && myAccounts.length) {
        result.headerSection = {
          title: t("my_accounts"),
          data: myAccounts,
        };
      }

      if (accountType === ACCOUNT_TYPES.ENTERPRISE && vendorBankAccounts.length) {
        result.headerSection = {
          title: t("vendor_accounts"),
          data: textSearch ? filteredVendorBankAccounts : vendorBankAccounts,
        };
      }

      return result;
    }, [
      t,
      textSearch,
      filteredBankAccounts,
      bankAccounts,
      accountType,
      vendorBankAccounts,
      filteredVendorBankAccounts,
    ]);

    return (
      <BottomSheetModalCustom ref={paymentChooserBottomSheetRef} snapPoints={snapPoints} showHeader={false}>
        <View style={styles.header}>
          <AppText style={Fonts.H400}>{t("title")}</AppText>
          <TouchableOpacity style={styles.buttonClose} onPress={handleClose}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <View style={styles.container}>
          {loading ? (
            <SkeletonListLoading />
          ) : bankAccounts.length || vendorBankAccounts.length ? (
            <>
              <View style={styles.filterContainer}>
                <SearchInput
                  ref={textSearchRef}
                  placeholder={t("search")}
                  onChangeText={setTextSearch}
                  right={
                    textSearch ? (
                      <TouchableOpacity
                        onPress={() => {
                          textSearchRef?.current?.clear();
                          setTextSearch("");
                        }}
                      >
                        <IconCustom name="cancel" />
                      </TouchableOpacity>
                    ) : null
                  }
                />
              </View>
              <AccountListView dataSource={dataSource} handleSelect={handleSelect} />
            </>
          ) : (
            <>
              <EmptyData
                style={styles.emptyImage}
                icon={<EmptyAccountBookIcon />}
                title={t("empty_bank")}
                description={t("description")}
              />
              <View style={styles.buttonContainer}>
                <Button
                  icon={() => <PlusSquareIcon color={Colors.white} width={24} height={24} />}
                  style={styles.bottomButton}
                  onPress={handleOpenPaymentForm}
                >
                  {t("add_account")}
                </Button>
              </View>
            </>
          )}
        </View>
      </BottomSheetModalCustom>
    );
  }
);

export default memo(BankAccountChooser);

const styles = StyleSheet.create({
  filterContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  container: {
    flex: 1,
    flexDirection: "column",
  },
  emptyImage: {
    marginTop: 120,
  },
  buttonContainer: {
    flex: 1,
    alignContent: "center",
    alignItems: "center",
  },
  bottomButton: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    marginTop: 8,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 15,
    paddingBottom: 15,
  },
  buttonClose: { padding: 5 },
});
