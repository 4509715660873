import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#0F172A" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M5.361 12.74h15.397c.212 0 .39-.071.534-.215a.726.726 0 00.216-.535.725.725 0 00-.216-.534.726.726 0 00-.534-.215H5.352l4.336-4.352a.73.73 0 00.214-.514.699.699 0 00-.214-.532.702.702 0 00-.52-.206.71.71 0 00-.524.207l-5.513 5.514a.826.826 0 00-.198.297 1 1 0 000 .678.81.81 0 00.198.3l5.513 5.513a.692.692 0 00.513.208c.206 0 .383-.07.531-.208a.699.699 0 00.228-.534.747.747 0 00-.228-.535L5.361 12.74z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
