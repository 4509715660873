import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { MobileBokIssueTicketQuery, MobileBokIssueTicketQueryVariables } from "types";

const MUTATION = gql`
  query MobileBokIssueTicket($input: BokIssueTicketInput!) {
    bokIssueTicket(input: $input) {
      orderCode
      success
    }
  }
`;

const useBokIssueTicket = (variables?: MobileBokIssueTicketQueryVariables) => {
  return useLazyQuery<MobileBokIssueTicketQuery, MobileBokIssueTicketQueryVariables>(MUTATION, {
    variables,
  });
};

export default useBokIssueTicket;
