import React, { FC, ReactElement, RefObject, useRef, useState } from "react";
import {
  KeyboardTypeOptions,
  Platform,
  StyleProp,
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Colors, Fonts, FontTypes } from "theme";
import { TextInput as PaperTextInput } from "react-native-paper";
import AppText from "components/AppText";
import { CONSTANTS } from "constants/constants";
import { IconCustom, useSetRelativePosition } from "components";

interface TextInputCustomProps
  extends Pick<TextInputProps, "multiline" | "maxLength" | "editable" | "placeholder" | "autoCapitalize"> {
  style?: StyleProp<ViewStyle>;
  label?: string;
  name?: string;
  control: Control<any>;
  rules?: Record<string, any>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  placeholder?: string;
  multiline?: boolean;
  keyboardType?: KeyboardTypeOptions;
  absoluteRight?: ReactElement;
  autoHeight?: boolean;
  setPosition?: (y: number) => void;
  onFocus?: () => void;
  containerRef?: RefObject<any>;
  defaultValue?: string | number;
}
const TextInputCustom: FC<TextInputCustomProps> = ({
  style,
  label,
  name,
  control,
  rules,
  error,
  keyboardType,
  absoluteRight,
  autoHeight = false,
  setPosition,
  onFocus,
  containerRef,
  defaultValue,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const inputContainerRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef: inputContainerRef, setPosition });

  const handleOnFocus = () => {
    onFocus?.();
    setIsFocus(true);
  };
  const onBlur = () => {
    setIsFocus(false);
  };
  const isError = Boolean(error);
  const showCharacterCount = props.multiline && rules?.maxLength?.value;

  return (
    <View style={style} ref={inputContainerRef}>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        defaultValue={defaultValue as never}
        render={({ field: { onChange, value, ref } }: any) => {
          return (
            <>
              <View style={absoluteRight && styles.row}>
                <PaperTextInput
                  ref={ref}
                  mode="outlined"
                  onChangeText={(value) => onChange(value as any)}
                  label={label}
                  defaultValue={value ? value : undefined}
                  value={value}
                  onBlur={onBlur}
                  style={[
                    styles.textInput,
                    absoluteRight && styles.textInputAbsoluteRight,
                    props.editable !== undefined && !props.editable && { backgroundColor: Colors.grayscale05 },
                  ]}
                  outlineColor={Colors.grayscale10}
                  activeOutlineColor={Colors.primary50}
                  onFocus={handleOnFocus}
                  right={
                    value && !props.multiline ? (
                      <PaperTextInput.Icon
                        name={() =>
                          props.editable !== undefined && !props.editable ? null : (
                            <TouchableOpacity
                              style={[styles.clearButton, props.multiline && { marginTop: 0 }]}
                              onPress={() => onChange("" as any)}
                            >
                              <IconCustom name="cancel" />
                            </TouchableOpacity>
                          )
                        }
                      />
                    ) : null
                  }
                  theme={{
                    roundness: 8,
                    colors: {
                      error: Colors.alert50,
                      background: Colors.while,
                      text: CONSTANTS.COMMON.TEXT_INPUT_COLOR,
                      placeholder: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
                    },
                    fonts: {
                      regular: {
                        fontFamily: FontTypes.medium,
                      },
                    },
                  }}
                  error={isError}
                  render={({ onChangeText, ...innerProps }) => {
                    return (
                      <RNTextInput
                        {...innerProps}
                        onChangeText={onChangeText}
                        style={[
                          innerProps.style,
                          styles.innerInput,
                          props?.multiline && styles.multiline,
                          autoHeight && Platform.OS !== "web" && styles.autoHeight,
                          autoHeight && Platform.OS === "ios" && styles.autoHeightIos,
                        ]}
                        keyboardType={keyboardType}
                      />
                    );
                  }}
                  {...props}
                />
                {value && props.multiline && (
                  <TouchableOpacity
                    style={[styles.clearButtonTextArea, autoHeight && Platform.OS !== "web" && { top: 21 }]}
                    onPress={() => onChange("" as any)}
                  >
                    <IconCustom name="cancel" />
                  </TouchableOpacity>
                )}
                {Boolean(absoluteRight) && (
                  <View
                    style={[
                      styles.absoluteRight,
                      isFocus && { borderLeftColor: Colors.primary50 },
                      error && { borderLeftColor: Colors.alert50 },
                    ]}
                  >
                    {absoluteRight}
                  </View>
                )}
              </View>
              <View style={styles.characterCount}>
                {error?.message ? (
                  <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                    {error.message as string}
                  </AppText>
                ) : (
                  <View />
                )}
                {showCharacterCount && (
                  <View>
                    <AppText style={Fonts.BodyXSmall} color={isError ? Colors.alert50 : Colors.grayscale100}>
                      {`${value?.length || 0}/`}
                      <AppText style={Fonts.BodyXSmall} color={Colors.grayscale80}>
                        {rules?.maxLength?.value}
                      </AppText>
                    </AppText>
                  </View>
                )}
              </View>
            </>
          );
        }}
      />
    </View>
  );
};
export default TextInputCustom;

const styles = StyleSheet.create({
  errorText: { marginLeft: 0, marginTop: 4 },
  textInput: {
    ...Fonts.BodyLarge,
    height: 48,
    textAlignVertical: "top",
  },
  textInputAbsoluteRight: {
    flex: 1,
    marginRight: -7,
  },
  innerInput: {
    margin: 0,
    zIndex: 1,
    height: 48,
    flexGrow: 1,
    fontSize: 16,
    paddingTop: 10,
    textAlign: "left",
    paddingBottom: 10,
    paddingHorizontal: 14,
    color: Colors.grayscale80,
    textAlignVertical: "top",
    fontFamily: FontTypes.medium,
  },
  multiline: {
    height: 100,
  },
  autoHeight: {
    minHeight: 48,
    maxHeight: 200,
    height: "auto",
    textAlignVertical: "center",
  },
  autoHeightIos: {
    minHeight: 48,
    height: "auto",
    paddingTop: 13,
  },
  row: { flexDirection: "row" },
  clearButton: { marginTop: 7 },
  clearButtonTextArea: { position: "absolute", top: 14, right: 14 },
  absoluteRight: {
    backgroundColor: Colors.grayscale0,
    borderWidth: 1,
    //borderLeftWidth: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: Colors.grayscale10,
    top: 6,
    height: 50,
    width: 48,
    justifyContent: "center",
    alignItems: "center",
    right: 0,
  },
  characterCount: {
    flexDirection: "row",
    gap: 8,
    paddingRight: 8,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
