import { AppText, useWindowDimensions } from "components";
import { CONSTANTS } from "constants/constants";
import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Colors, Fonts } from "theme";
import { MobileExpExpenseRequestDetailQuery } from "types";
import isEmpty from "lodash/isEmpty";
import { CustomTable } from "components/Table";

type PassengerListSectionProps = {
  passengers: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"]["expenseTravels"][0]["passengers"];
};
const PassengerListSection: FC<PassengerListSectionProps> = ({ passengers }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
  const { width } = useWindowDimensions();

  const columns = useMemo(() => {
    const cols = [
      {
        key: "index",
        title: t("index"),
        width: 55,
        textAlign: "center" as any,
        render: (_, index) => <AppText style={[Fonts.BodyMedium, styles.textCenter]}>{index + 1}</AppText>,
      },
      {
        key: "fullname",
        title: t("passenger_fullname"),
        textAlign: "left" as any,
      },
    ];
    cols[1].width = width - cols[0].width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING;
    return cols;
  }, [t, width]);

  return (
    <View style={styles.container}>
      <AppText style={[Fonts.SentenceSubtitleLarge, styles.mb16]}>{t("passenger_list")}</AppText>
      {isEmpty(passengers) ? (
        <AppText style={[Fonts.BodyMedium, styles.emptyText]} color={Colors.grayscale60}>
          {t("empty_passenger")}
        </AppText>
      ) : (
        <CustomTable isScrollTable={false} columns={columns} dataSource={passengers} />
      )}
    </View>
  );
};

export default memo(PassengerListSection);

const styles = StyleSheet.create({
  mb16: {
    marginBottom: 16,
  },
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
    marginTop: 8,
  },
  emptyText: { textAlign: "center" },
  textCenter: { textAlign: "center" },
});
