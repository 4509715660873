// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useRef } from "react";
import { Keyboard, Platform, ScrollView, StyleSheet, TextInput, View } from "react-native";
import Constants from "expo-constants";
import { Formik } from "formik";
import { useRoute } from "@react-navigation/native";
import * as Yup from "yup";
import { BizziButton, PaperInput } from "app/components";
import { KeyboardAvoidingView } from "app/layouts";
import SCREEN_NAME from "navigation/ScreenName";
import { Colors } from "constants/Colors";
import { BriefcaseAltIcon, EmailIcon, LineEmailIcon, LocationIcon } from "assets/images/svg/icons";
import { HEADER_BAR_HEIGHT, SPACING_DEFAULT } from "constants/Layout";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Appbar } from "react-native-paper";
import Fonts from "constants/Fonts";
import { Toast } from "@ant-design/react-native";
import { useTranslation } from "react-i18next";
import { AppText } from "components";

const NewCompanyScreen = ({ navigation }) => {
  const route = useRoute();
  const { email, password, ...company } = route?.params ?? {};
  const navigateionRef = useRef(null);
  const { t } = useTranslation("screens/Auth/NewCompanyScreen");
  const { bottom, top } = useSafeAreaInsets();

  const yupSchema = Yup.object().shape({
    tax_code: Yup.string()
      .trim()
      .matches(/^\d{10}(-\d{3})?$/, t("invalid_tax_code"))
      .required(t("required")),
    company_name: Yup.string().min(5, t("string_min")).required(t("required")),
    address: Yup.string().min(10, t("string_min")).required(t("required")),

    accountant_email: Yup.string().email(t("please_input_correct_email")).required(t("required")),
  });

  const companyNameRef = useRef<TextInput>(null);
  const addressRef = useRef<TextInput>(null);

  const accountingEmailRef = useRef<TextInput>(null);

  const initialValues: {
    tax_code: string;
    company_name: string;
    address: string;
    accountant_email: string;
  } = {
    ...company,
  };

  const onSubmit = useCallback(
    async (values) => {
      Keyboard.dismiss();
      Toast.loading();

      navigateionRef.current = setTimeout(() => {
        navigation.navigate(SCREEN_NAME.NewEmployeeInfoScreen, { ...values, email, password });
      }, 1000);
    },
    [email, password, navigation]
  );

  useEffect(() => {
    return () => {
      Toast.removeAll();
      clearTimeout(navigateionRef.current);
    };
  }, []);

  return (
    <KeyboardAvoidingView
      style={styles.mainContainer}
      enabled
      behavior={Constants?.platform?.ios ? "padding" : "height"}
    >
      <Appbar
        style={[
          styles.navbarStyle,
          { height: Platform.OS === "ios" ? HEADER_BAR_HEIGHT : HEADER_BAR_HEIGHT + top, paddingTop: top },
        ]}
      >
        <Appbar.Action icon="keyboard-backspace" onPress={navigation.goBack} />
        <Appbar.Content
          title={<AppText style={styles.headerTitle}>{t("NewCompanyScreen")}</AppText>}
          style={{ alignItems: "center" }}
        />
        <Appbar.Action icon="keyboard-backspace" style={{ opacity: 0 }} />
      </Appbar>
      <Formik
        initialValues={initialValues}
        validateOnMount
        enableReinitialize
        validationSchema={yupSchema}
        onSubmit={onSubmit}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            isValid,

            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <View style={styles.container}>
              <ScrollView keyboardDismissMode="on-drag" showsVerticalScrollIndicator={false}>
                <View style={styles.container}>
                  <View style={styles.header}>
                    <AppText style={styles.description}>{t("description")}</AppText>
                  </View>
                  <View style={styles.body}>
                    <PaperInput
                      label={t("tax_code_full")}
                      placeholder={t("tax_code_placeholder")}
                      keyboardType="number-pad"
                      value={values.tax_code}
                      error={errors.tax_code}
                      touched={touched.tax_code}
                      onBlur={handleBlur("tax_code")}
                      onChangeText={handleChange("tax_code")}
                      onSubmitEditing={() => companyNameRef.current?.focus()}
                      leftImage={<LineEmailIcon />}
                      returnKeyLabel={t("next")}
                    />
                    <PaperInput
                      ref={companyNameRef}
                      label={t("company_name")}
                      placeholder={t("company_name_placeholder")}
                      value={values.company_name}
                      error={errors.company_name}
                      touched={touched.company_name}
                      onBlur={handleBlur("company_name")}
                      onChangeText={handleChange("company_name")}
                      onSubmitEditing={() => addressRef.current?.focus()}
                      leftImage={<BriefcaseAltIcon />}
                      autoCorrect={false}
                      returnKeyLabel={t("next")}
                    />
                    <PaperInput
                      ref={addressRef}
                      label={t("address")}
                      placeholder={t("address_placeholder")}
                      value={values.address}
                      error={errors.address}
                      touched={touched.address}
                      onBlur={handleBlur("address")}
                      onChangeText={handleChange("address")}
                      onSubmitEditing={() => accountingEmailRef.current?.focus()}
                      leftImage={<LocationIcon />}
                      returnKeyLabel={t("next")}
                      autoCorrect={false}
                    />

                    <PaperInput
                      ref={accountingEmailRef}
                      label={t("accountant_email")}
                      placeholder={t("accountant_email_placeholder")}
                      keyboardType="email-address"
                      textContentType="emailAddress"
                      autoCapitalize="none"
                      autoCorrect={false}
                      value={values.accountant_email}
                      error={errors.accountant_email}
                      touched={touched.accountant_email}
                      onBlur={handleBlur("accountant_email")}
                      onChangeText={handleChange("accountant_email")}
                      onSubmitEditing={handleSubmit}
                      leftImage={<EmailIcon />}
                      returnKeyLabel={t("next")}
                      noUnderLine
                    />
                  </View>
                </View>
              </ScrollView>
              <View style={[styles.wrapperInput, { paddingBottom: SPACING_DEFAULT + bottom }]}>
                <BizziButton disabled={!isValid} onPress={handleSubmit} title={t("next")} />
              </View>
            </View>
          );
        }}
      </Formik>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.white500,
  },
  container: {
    flex: 1,
  },
  header: {
    marginTop: SPACING_DEFAULT,
    paddingHorizontal: SPACING_DEFAULT,
  },
  headerTitle: {
    color: Colors.Ink500,
    fontFamily: Fonts.fontFamilyBold,
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "600",
  },
  body: {
    marginTop: SPACING_DEFAULT,
    backgroundColor: Colors.white500,
    paddingHorizontal: SPACING_DEFAULT,
  },
  navbarStyle: { shadowOpacity: 0, elevation: 0, backgroundColor: "transparent" },
  wrapperInput: {
    paddingHorizontal: SPACING_DEFAULT,
    paddingTop: 10,
    backgroundColor: Colors.white500,
  },
  description: {
    color: Colors.Ink300,
    fontSize: 14,
    lineHeight: 18,
    textAlign: "center",
  },
});

export default NewCompanyScreen;
