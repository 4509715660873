import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const EXPENSE_REJECT_REQUEST = gql`
  mutation MobileRejectExpenseRequest($requestId: UUID!, $reason: String) {
    expenseRejectRequest(request_id: $requestId, reason: $reason) {
      message
      success
    }
  }
`;

const useExpenseRejectRequest = () => {
  const [rejectRequest, { data }] = useMutation(EXPENSE_REJECT_REQUEST);

  return {
    rejectRequest,
    data,
  };
};

export default useExpenseRejectRequest;
