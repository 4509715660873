import { CSSProperties, FC, useState } from "react";
import { ImageProps, StyleProp, ViewStyle } from "react-native";
import { Portal } from "react-native-paper";
import { Colors } from "theme";
import { CancelStatusIcon } from "assets/images/svg/icons";
interface ProgressiveImageProps extends ImageProps {
  source: { uri: string };
  containerStyle: StyleProp<ViewStyle>;
}

const ProgressiveImage: FC<ProgressiveImageProps> = ({ source: { uri } }) => {
  const [preview, setPreview] = useState(false);
  return (
    <>
      <img style={{ ...styles.thumbnail, ...styles.border }} src={uri} alt="" onClick={() => setPreview(true)} />
      {preview && (
        <Portal>
          <div style={styles.preview}>
            <div style={styles.closeButton} onClick={() => setPreview(false)}>
              <CancelStatusIcon width={40} height={40} stroke={Colors.white} />
            </div>
            <img style={styles.image} src={uri} alt="" />
          </div>
        </Portal>
      )}
    </>
  );
};

export default ProgressiveImage;

const styles: { [key: string]: CSSProperties } = {
  border: {
    borderRadius: 8,
  },
  thumbnail: {
    width: "100%",
    height: "100%",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  preview: {
    background: Colors.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  closeButton: {
    color: Colors.white,
    position: "absolute",
    top: 8,
    right: 8,
    background: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
  },
};
