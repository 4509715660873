import { Platform, StyleSheet, View } from "react-native";
import {
  AppText,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  CurrencyInput,
} from "components";
import React, { FC, useMemo, useRef } from "react";
import FilterInput from "./FilterInput";
import { useTranslation } from "react-i18next";
import { formatMoney, isNilOrEmpty } from "utils";
import { CONSTANTS } from "constants/constants";
import { VNDIcon } from "assets/images/svg/icons";
import { useForm } from "react-hook-form";
import { Colors, Fonts } from "theme";
import { isEmpty } from "lodash";

type SelectTotalAmountWithVatProps = {
  minWidth?: number;
  title?: string;
  value?: { min?: number; max?: number };
  setValue?: (value: any) => void;
};

type FormValues = { min?: string; max?: string };

const SelectTotalAmountWithVat: FC<SelectTotalAmountWithVatProps> = ({ title, value, setValue, minWidth }) => {
  const { t } = useTranslation("app/components/AdvanceSearch");
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();

  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    reset({
      min: "",
      max: "",
    });
    setValue({});
  };

  const handleApplyFilter = (data) => {
    const { min, max } = data;
    if ([min, max].every((x) => isNilOrEmpty(x))) {
      setError("root", { message: t("please_fill_min_or_max_amount") });
      return;
    }

    if ([min, max].every((x) => !isNilOrEmpty(x)) && Number(min) > Number(max)) {
      setError("min", { message: t("min_max_amount_invalid") });
      return;
    }

    bottomSheetRef?.current?.close();
    setValue({ min: isNilOrEmpty(min) ? undefined : Number(min), max: isNilOrEmpty(max) ? undefined : Number(max) });
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: {
      min: "",
      max: "",
    },
    mode: CONSTANTS.COMMON.VALIDATE_RULE,
  });

  const min = watch("min");
  const max = watch("max");

  const displayText = useMemo(() => {
    const { min, max } = value ?? {};
    if ([min, max].every((x) => !isNilOrEmpty(x))) {
      return `${t("amount_from")} ${formatMoney(min)} ${t("amount_to")} ${formatMoney(max)}`;
    }
    if (isNilOrEmpty(min) && !isNilOrEmpty(max)) {
      return `${t("max_total_amount_with_vat")}: ${formatMoney(max)}`;
    }
    if (!isNilOrEmpty(min) && isNilOrEmpty(max)) {
      return `${t("min_total_amount_with_vat")}: ${formatMoney(min)}`;
    }
    return "";
  }, [value, t]);

  return (
    <>
      <FilterInput
        title={title}
        value={displayText}
        onPress={() => bottomSheetRef?.current?.present()}
        onClear={handleClearFilter}
        minWidth={minWidth}
      />
      <BottomSheetScrollViewModalCustom wrapperByScrollView={false} title={t("amount")} ref={bottomSheetRef}>
        <View style={styles.contentBody}>
          <View style={[styles.flexRow, styles.gap8]}>
            <CurrencyInput
              label={t("min_total_amount_with_vat")}
              bottomSheetMode={true}
              style={styles.flex}
              control={control}
              name="min"
              error={errors?.min}
              errorStyle="summary"
              right={<VNDIcon />}
              keyboardType={Platform.OS === "ios" ? "numbers-and-punctuation" : "numeric"}
            />
            <View style={styles.minusSymbolContainer}>
              <AppText style={Fonts.BodyMedium} color={Colors.grayscale40}>
                -
              </AppText>
            </View>
            <CurrencyInput
              label={t("max_total_amount_with_vat")}
              bottomSheetMode={true}
              style={styles.flex}
              control={control}
              name="max"
              right={<VNDIcon />}
            />
          </View>
          <View style={styles.errorsContainer}>
            {!isEmpty(errors) && (
              <AppText style={Fonts.BodySmall} color={Colors.alert50}>
                {Object.values(errors)?.[0].message}
              </AppText>
            )}
          </View>
        </View>
        <View style={styles.footerButton}>
          <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
            {t("clear_filter")}
          </Button>
          <Button style={styles.flex} onPress={handleSubmit(handleApplyFilter)} disabled={!min && !max}>
            {t("apply")}
          </Button>
        </View>
      </BottomSheetScrollViewModalCustom>
    </>
  );
};
export default SelectTotalAmountWithVat;
const styles = StyleSheet.create({
  gap8: {
    gap: 8,
  },
  flex: { flex: 1 },
  flexRow: { flexDirection: "row", alignItems: "center" },
  contentBody: {
    paddingTop: 12,
    paddingBottom: 32,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  footerButton: {
    flexDirection: "row",
    gap: 8,
    marginTop: "auto",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  errorsContainer: {
    marginTop: 8,
  },
  minusSymbolContainer: {
    marginTop: 6,
    alignSelf: "stretch",
    justifyContent: "center",
  },
});
