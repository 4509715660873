import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#475569" }) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Path
        d="M9.997 18.333c4.584 0 8.334-3.75 8.334-8.334 0-4.583-3.75-8.333-8.334-8.333-4.583 0-8.333 3.75-8.333 8.333 0 4.584 3.75 8.334 8.333 8.334zM10 6.666v4.167"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M9.992 13.334H10" stroke={color} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
}

export default SvgComponent;
