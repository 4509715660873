import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  MobileCrdAssignableExpenseCardTransactionsQuery,
  MobileCrdAssignableExpenseCardTransactionsQueryVariables,
} from "types";

const QUERY_ASSIGNABLE_TRANSACTIONS = gql`
  query MobileCrdAssignableExpenseCardTransactions(
    $condition: AssignableExpenseCardTransactionCondition!
    $limit: Int!
    $offset: Int!
  ) {
    crdAssignableExpenseCardTransactions(input: { condition: $condition, limit: $limit, offset: $offset }) {
      transactions {
        status
        companyId
        amount
        cardTransactionId
        transactionDate
        location
        card {
          cardNumber
          cardId
          maskedCardNumber
          prefixCardNumber
          suffixCardNumber
        }
        cardTransactionExpenses {
          expenseId
          cardTransactionId
          expense {
            docSequence
          }
        }
        unreconciledAmount
      }
      totalEntries
    }
  }
`;

const useAssignableTransactionsQuery = (
  variables: MobileCrdAssignableExpenseCardTransactionsQueryVariables,
  onCompleted: (data: MobileCrdAssignableExpenseCardTransactionsQuery) => void
) => {
  return useLazyQuery<
    MobileCrdAssignableExpenseCardTransactionsQuery,
    MobileCrdAssignableExpenseCardTransactionsQueryVariables
  >(QUERY_ASSIGNABLE_TRANSACTIONS, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted,
  });
};

export default useAssignableTransactionsQuery;
