import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={20} height={20}>
        <Path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M8.817 11.788L6.881 9.852a.604.604 0 00-.435-.177.589.589 0 00-.443.177.598.598 0 00-.181.44c0 .172.06.318.181.439l2.287 2.287c.15.15.326.225.527.225s.377-.075.527-.226l4.637-4.636a.604.604 0 00.177-.435.589.589 0 00-.177-.443.598.598 0 00-.44-.181.598.598 0 00-.439.181l-4.285 4.285zm1.184 6.128a7.711 7.711 0 01-3.087-.623A7.996 7.996 0 014.4 15.601a7.991 7.991 0 01-1.693-2.513 7.704 7.704 0 01-.624-3.087c0-1.095.208-2.124.624-3.087A7.997 7.997 0 014.398 4.4a7.993 7.993 0 012.514-1.693 7.704 7.704 0 013.087-.624c1.094 0 2.124.208 3.087.624.963.415 1.802.98 2.514 1.691a7.992 7.992 0 011.693 2.514c.416.963.623 1.992.623 3.087a7.712 7.712 0 01-.623 3.087 7.996 7.996 0 01-1.692 2.514 7.99 7.99 0 01-2.513 1.693 7.703 7.703 0 01-3.087.623zM10 16.666c1.861 0 3.437-.645 4.73-1.937 1.29-1.292 1.937-2.868 1.937-4.73 0-1.86-.646-3.437-1.938-4.728-1.292-1.292-2.868-1.938-4.73-1.938-1.86 0-3.437.646-4.728 1.938C3.979 6.562 3.333 8.139 3.333 10c0 1.861.646 3.437 1.938 4.73 1.291 1.29 2.868 1.937 4.729 1.937z"
          fill="#37B24D"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
