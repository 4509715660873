import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24}>
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M5.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V3.154c0-.22.074-.402.22-.55a.745.745 0 01.55-.22c.219 0 .402.074.549.22.146.148.22.33.22.55V4.5h7.577V3.135c0-.213.071-.391.215-.535a.726.726 0 01.535-.215c.212 0 .39.072.534.215a.726.726 0 01.216.535V4.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .506-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308zm0-1.5h13.384a.294.294 0 00.212-.096.294.294 0 00.096-.212v-9.384H5v9.384c0 .077.032.148.096.212a.294.294 0 00.212.096zM5 8.808h14v-2.5a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H5.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.5zm7 5.269a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.244.087-.453.259-.626a.853.853 0 01.626-.258c.245 0 .454.086.626.258a.852.852 0 01.259.626.852.852 0 01-.26.626.853.853 0 01-.625.259zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.244.087-.453.259-.626A.853.853 0 018 12.308c.245 0 .453.086.626.258a.853.853 0 01.259.626.853.853 0 01-.26.626.853.853 0 01-.625.259zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.244.087-.453.259-.626a.853.853 0 01.626-.258c.245 0 .453.086.626.258a.852.852 0 01.259.626.852.852 0 01-.26.626.853.853 0 01-.625.259zM12 18a.853.853 0 01-.626-.259.853.853 0 01-.259-.625c0-.245.087-.454.259-.626a.853.853 0 01.626-.26c.245 0 .454.087.626.26a.852.852 0 01.259.626.853.853 0 01-.26.625.853.853 0 01-.625.26zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.625c0-.245.087-.454.259-.626A.852.852 0 018 16.23c.245 0 .453.087.626.26a.852.852 0 01.259.626.853.853 0 01-.26.625.853.853 0 01-.625.26zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.625c0-.245.087-.454.259-.626a.853.853 0 01.626-.26c.245 0 .453.087.626.26a.852.852 0 01.259.626.853.853 0 01-.26.625.853.853 0 01-.625.26z"
          fill="#94A3B8"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
