import { StyleProp, StyleSheet, TextInput as RNTextInput, TouchableOpacity, View, ViewStyle } from "react-native";
import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Fonts, FontTypes } from "theme";

import LanguageStatus from "constants/LanguageStatus";
import dayjs from "dayjs";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { TextInput as PaperTextInput } from "react-native-paper";
import { CONSTANTS } from "constants/constants";
import AppText from "components/AppText";
import { IconCustom } from "components";

interface BirthDayInputProps {
  label: string;
  name: string;
  control: Control<any>;
  rules?: Record<string, any>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  setPosition?: (y: number) => void;
  requiredMessage?: string;
  invalidMessage: string;
  dateFormat: "DD/MM/YYYY" | "MM/DD/YYYY";
}
const BirthDayInput: FC<BirthDayInputProps> = ({
  label,
  name,
  control,
  error,
  setPosition,
  requiredMessage,
  invalidMessage,
  dateFormat = "DD/MM/YYYY",
}) => {
  const { i18n, t } = useTranslation("app/screens/FlightBooking/PassengerInfoScreen/PassengerInfoScreen");
  const [visible, setVisible] = useState(false);
  const handleConfirmDate = (onChange) => (date: Date) => {
    setVisible(false);
    const formattedDate = dayjs(date).format(dateFormat);
    onChange(formattedDate);
    previousTextValue.current = formattedDate;
  };
  const handleCancel = () => setVisible(false);
  const isError = Boolean(error);
  const previousTextValue = useRef("");
  const handleChangeText = (onChange) => (text) => {
    const isDeletedText = previousTextValue.current.length > text.length;
    const cleanedText = text.replace(/\D/g, "");
    let formattedText = text;
    if (isDeletedText) {
      if (cleanedText.length === 2) {
        formattedText = cleanedText.substring(0, 2);
      } else if (cleanedText.length === 4) {
        formattedText = cleanedText.substring(0, 2) + "/" + cleanedText.substring(2, 4);
      }
      onChange(formattedText);
      previousTextValue.current = text;
      return;
    }
    if (cleanedText.length === 2) {
      // For day input
      formattedText = Number(cleanedText) > 31 ? "31/" : `${cleanedText.substring(0, 2)}/`;
    } else if (cleanedText.length === 3) {
      formattedText = `${cleanedText.substring(0, 2)}/${cleanedText.substring(2)}`;
    } else if (cleanedText.length === 4) {
      // For month input
      const month = cleanedText.substring(2, 4);
      formattedText =
        Number(month) > 12
          ? `${cleanedText.substring(0, 2)}/12/`
          : `${cleanedText.substring(0, 2)}/${cleanedText.substring(2, 4)}/`;
    } else if (cleanedText.length === 5) {
      formattedText = `${cleanedText.substring(0, 2)}/${cleanedText.substring(2, 4)}/${cleanedText.substring(4)}`;
    }
    onChange(formattedText);
    previousTextValue.current = formattedText;
  };

  return (
    <>
      <View
        onLayout={(event) => {
          setPosition?.(event.nativeEvent.layout.y);
        }}
      >
        <Controller
          name={name as never}
          control={control}
          rules={{
            validate: {
              required: (value) => {
                if (requiredMessage && !value) {
                  return requiredMessage;
                }
                if (!value || !dayjs(value, dateFormat).isValid()) {
                  return invalidMessage;
                }
              },
            },
          }}
          render={({ field: { onChange, value, ref } }: any) => {
            return (
              <>
                <View>
                  <PaperTextInput
                    maxLength={10}
                    ref={ref}
                    mode="outlined"
                    onChangeText={onChange}
                    label={label}
                    defaultValue={value ? value : undefined}
                    value={value}
                    style={styles.textInput}
                    outlineColor={Colors.grayscale10}
                    activeOutlineColor={Colors.primary50}
                    right={
                      <PaperTextInput.Icon
                        name={() => (
                          <TouchableOpacity style={styles.clearButton} onPress={() => setVisible(true)}>
                            <IconCustom name="calendar-month" />
                          </TouchableOpacity>
                        )}
                      />
                    }
                    theme={{
                      roundness: 8,
                      colors: {
                        error: Colors.alert50,
                        background: Colors.while,
                        text: CONSTANTS.COMMON.TEXT_INPUT_COLOR,
                        placeholder: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
                      },
                      fonts: {
                        regular: {
                          fontFamily: FontTypes.medium,
                        },
                      },
                    }}
                    error={isError}
                    render={({ onChangeText, ...innerProps }) => {
                      return (
                        <RNTextInput
                          {...innerProps}
                          onChangeText={handleChangeText(onChangeText)}
                          style={[innerProps.style, styles.innerInput]}
                          keyboardType="numeric"
                        />
                      );
                    }}
                  />
                </View>
                {error?.message ? (
                  <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                    {error.message as string}
                  </AppText>
                ) : (
                  <View />
                )}
                <DateTimePickerModal
                  isVisible={visible}
                  maximumDate={dayjs().toDate()}
                  onConfirm={handleConfirmDate(onChange)}
                  date={
                    value && dayjs(value, dateFormat).isValid() ? dayjs(value, dateFormat).toDate() : dayjs().toDate()
                  }
                  onCancel={handleCancel}
                  accentColor={Colors.primary50}
                  display="spinner"
                  locale={i18n.language === LanguageStatus.VN ? "vi-VN" : "en-US"}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  confirmTextIOS={t("common:confirm")}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  cancelTextIOS={t("common:cancel")}
                />
              </>
            );
          }}
        />
      </View>
    </>
  );
};

export default BirthDayInput;

const styles = StyleSheet.create({
  errorText: { marginLeft: 0, marginTop: 4 },
  textInput: {
    ...Fonts.BodyLarge,
    height: 48,
    textAlignVertical: "top",
  },
  innerInput: {
    margin: 0,
    zIndex: 1,
    height: 48,
    flexGrow: 1,
    fontSize: 16,
    paddingTop: 10,
    textAlign: "left",
    paddingBottom: 10,
    paddingHorizontal: 14,
    color: Colors.grayscale80,
    textAlignVertical: "top",
    fontFamily: FontTypes.medium,
  },
  clearButton: { marginTop: 7 },
});
