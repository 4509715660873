import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 48, height = 48, color = "#1F71F4" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <Path
        d="M47.673 12.444c.605-2.844.373-5.289-.72-7.322C45.14 1.802 41.166.102 35.044 0H10.662L8.45 9.947 0 48H22.737c2.572 0 4.99-.219 7.266-.656a17.92 17.92 0 006.212-2.407c1.659-1.016 3.125-2.277 4.437-3.783 1.993-2.265 3.318-4.8 3.948-7.657.604-2.754.476-5.109-.386-7.04-.874-1.93-2.482-3.345-4.848-4.22 1.864-.824 3.318-1.737 4.385-2.728 1.955-1.776 3.254-4.131 3.922-7.065zM19.613 48h-1.776l1.196-5.353 2.534-11.44.128-.618H12.68l3.318-14.529h-5.324l12.449-8.043 8.54 8.043h-4.116l-1.286 5.804h11.857L19.612 48z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
