import React, { FC, useMemo, useState, RefObject, useRef } from "react";

import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import dayjs from "dayjs";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { AppText, IconCustom, useSetRelativePosition } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts, FontTypes } from "theme";
import { DatePickerModal } from "react-native-paper-dates";
import { ValidRangeType } from "react-native-paper-dates/src/Date/Calendar";

interface DatePickerCustomProps {
  style?: StyleProp<ViewStyle>;
  label?: string;
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  placeholder?: string;
  maximumDate?: Date;
  minimumDate?: Date;

  mode?: "date" | "month";
  disabled?: boolean;
  setPosition?: (value: number) => void;
  formatValue?: string;
  containerRef?: RefObject<any>;
}
const DatePickerCustom: FC<DatePickerCustomProps> = ({
  label,
  minimumDate,
  maximumDate,
  placeholder,
  style,
  name,
  rules,
  control,
  error,
  mode = "date",
  disabled = false,
  setPosition,
  formatValue = CONSTANTS.FORMAT_DAY,
  containerRef,
}) => {
  const childRef = useRef(null);
  useSetRelativePosition({ containerRef, childRef, setPosition });
  const [visible, setVisible] = useState(false);
  const validRange = useMemo(() => {
    if (!minimumDate && !maximumDate) {
      return undefined;
    }
    const range = {} as ValidRangeType;
    if (minimumDate) {
      range.startDate = dayjs(minimumDate).toDate();
    }
    if (maximumDate) {
      range.endDate = dayjs(maximumDate).toDate();
    }
    return range;
  }, [minimumDate, maximumDate]);
  return (
    <View style={style} ref={childRef}>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          if (disabled) {
            return (
              <View style={styles.disabledContainer}>
                <AppText style={[Fonts.Caption, styles.labelDisabled]}>{label}</AppText>
                <AppText style={[Fonts.BodyLarge, styles.flex]} color={Colors.grayscale80}>
                  {value ? dayjs(value).format(mode === "month" ? "MM/YYYY" : formatValue) : placeholder}
                </AppText>
                <IconCustom name="calendar-month" />
              </View>
            );
          }
          return (
            <View>
              <TouchableOpacity
                onPress={() => setVisible(true)}
                style={[styles.input, Boolean(error) && { borderColor: Colors.alert50 }]}
              >
                {Boolean(value) && <AppText style={styles.label}>{label}</AppText>}
                <AppText
                  style={styles.categoryValue}
                  color={value ? CONSTANTS.COMMON.TEXT_INPUT_COLOR : CONSTANTS.COMMON.PLACEHOLDER_COLOR}
                  numberOfLines={1}
                >
                  {value ? dayjs(value).format(mode === "month" ? "MM/YYYY" : formatValue) : placeholder}
                </AppText>
                <IconCustom name="calendar-month" />
              </TouchableOpacity>
              {Boolean(error?.message) && (
                <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
                  {error?.message}
                </AppText>
              )}
              <DatePickerModal
                validRange={validRange}
                locale={"vi"}
                mode="single"
                visible={visible}
                date={value ? dayjs(value).toDate() : dayjs().toDate()}
                onDismiss={() => setVisible(false)}
                onConfirm={(date) => {
                  setVisible(false);
                  onChange(date?.date as any);
                }}
                label={label}
              />
            </View>
          );
        }}
      />
    </View>
  );
};

export default DatePickerCustom;
const styles = StyleSheet.create({
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    flexDirection: "row",
  },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    fontFamily: FontTypes.medium,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  categoryValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
  disabledContainer: {
    paddingVertical: 12,
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 48,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Colors.grayscale0,
    alignItems: "center",
    marginTop: 6,
  },
  flex: { flex: 1 },
  labelDisabled: {
    position: "absolute",
    top: -8,
    left: 16,
    color: Colors.grayscale60,
  },
});
