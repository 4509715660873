import { useState, useEffect } from "react";
import { Dimensions } from "react-native";
const MAX_WINDOW_WIDTH = 800;

const useWindowDimensionsCustom = () => {
  const [width, setWidth] = useState(Dimensions.get("window").width);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth === MAX_WINDOW_WIDTH) {
        return;
      }
      setWidth(window.innerWidth > MAX_WINDOW_WIDTH ? MAX_WINDOW_WIDTH : window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return { width, height: Dimensions.get("window").height, isTablet: width >= 600 };
};
export default useWindowDimensionsCustom;
