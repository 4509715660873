import React, { FC, memo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";

import { AppText, CurrencyText } from "components";
import { CONSTANTS, TRANSACTION_CARD_STATUS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { TransactionIcon } from "assets/images/svg/icons";
import TransactionStatus from "./TransactionStatus";

interface TransactionItemProps {
  item: {
    updatedAt?: Date;
    transactionType?: string;
    transactionId?: string;
    companyId?: string;
    transactionDate?: Date;
    status?: TRANSACTION_CARD_STATUS;
    amount?: number;
    cardId: string;
    cardTransactionId: string;
    createdAt?: Date;
    location?: string;
    mcc?: string;
    employee?: { fullname?: string };
    isLastItem?: boolean;
    cardTransactionExpenses: any[];
    unreconciledAmount: number;
  };
  onPress?: () => void;
}

const TransactionItem: FC<TransactionItemProps> = ({ item, onPress }) => {
  const { location, amount, isLastItem } = item;
  const { t } = useTranslation("app/screens/Card/TransactionHistoryListScreen");
  return (
    <>
      <TouchableOpacity onPress={onPress}>
        <View style={styles.transactionItem}>
          <View style={styles.leftIcon}>
            <TransactionIcon />
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.titleContainer}>
              <AppText style={[Fonts.BodyMedium, styles.title]} numberOfLines={1}>
                {location}
              </AppText>
              <CurrencyText
                labelLeft={amount > 0 ? "+" : ""}
                style={Fonts.H200}
                color={amount > 0 ? Colors.success100 : Colors.grayscale100}
              >
                {amount}
              </CurrencyText>
            </View>
            <View style={styles.statusContainer}>
              <TransactionStatus isCreatedExpense={Boolean(item?.cardTransactionExpenses?.length)} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <View style={[styles.divider, isLastItem && { marginBottom: 20 }]} />
    </>
  );
};

export default memo(TransactionItem);

const styles = StyleSheet.create({
  transactionItem: { flexDirection: "row", alignItems: "center", paddingVertical: 16 },
  leftIcon: { marginRight: 16 },
  contentContainer: {
    flex: 1,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 43,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 2,
  },
  title: { flex: 1, paddingRight: 5 },
  statusContainer: { alignSelf: "flex-start", marginTop: 2 },
});
