import { AppText, IconCustom, Section, UploadFileView } from "components";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { COMPANY_ATTACHMENT_TYPES } from "constants/constants";
import { UploadFileItem } from "components/UploadFiles/types";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import SCREEN_NAME from "navigation/ScreenName";

const AllocatedFileSection = ({ companyAttachments }) => {
  const { t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");
  const navigation = useNavigation<any>();

  const allocatedDocuments = useMemo(() => {
    if (!companyAttachments) {
      return [];
    }
    return companyAttachments
      ?.filter((item) => item.attachmentType === COMPANY_ATTACHMENT_TYPES.ALLOCATED_DOCUMENT)
      .map((att) => ({
        id: att.companyAttachmentId,
        uri: att.fileUrl,
        name: att.fileName,
        size: att.fileSize,
        fileType: att.mimeType,
        createdAt: att.createdAt,
        customFields: att.customFields,
      })) as unknown as UploadFileItem[];
  }, [companyAttachments]);

  const allocatedExpenses = useMemo(() => {
    return allocatedDocuments?.[0]?.customFields?.allocatedExpenses ?? [];
  }, [allocatedDocuments]);

  if (isEmpty(allocatedDocuments)) {
    return null;
  }

  const handleViewDetail = () => {
    navigation.navigate(SCREEN_NAME.AllocatedExpenseScreen, {
      expenses: allocatedExpenses,
    });
  };
  return (
    <Section style={styles.container} title={t("allocated_expense")}>
      <View style={styles.body}>
        <UploadFileView title={t("allocated_expense")} files={allocatedDocuments} />
        <TouchableOpacity onPress={handleViewDetail} style={styles.seeMoreButton}>
          <AppText style={Fonts.SentenceSubtitleXLarge} color={Colors.primary50}>
            {t("allocated_num_expense", { num: allocatedExpenses.length })}
          </AppText>
          <IconCustom name="arrow-right" width={30} height={30} fill={Colors.primary50} />
        </TouchableOpacity>
      </View>
    </Section>
  );
};
export default AllocatedFileSection;
const styles = StyleSheet.create({
  container: { marginTop: 0 },
  body: { flexDirection: "row", gap: 14 },
  seeMoreButton: { flexDirection: "row", paddingTop: 45 },
});
