import { Section, UploadFileView } from "components";
import { useTranslation } from "react-i18next";
import { UploadFileItem } from "components/UploadFiles/types";
import { FC, useMemo } from "react";
import { MobileExpenseReportDetailsQuery } from "types";
import { getFileNameFromUrl } from "utils";
import { v1 } from "uuid";
import isEmpty from "lodash/isEmpty";

interface DownloadAttachmentProps {
  expenseReport?: MobileExpenseReportDetailsQuery["expExpenseReportDetails"];
  refetch?: () => void;
}
const DownloadAttachment: FC<DownloadAttachmentProps> = ({ expenseReport }) => {
  const { t } = useTranslation("app/screens/Report/ReportDetailScreen");

  const fileDownloads = useMemo<UploadFileItem[]>(() => {
    if (!expenseReport) {
      return [];
    }
    const { pdf, xlsx, zip } = expenseReport;
    return [
      Boolean(xlsx.url) && {
        id: v1(),
        uri: xlsx.url,
        name: getFileNameFromUrl(xlsx.url),
        fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      Boolean(pdf.url) && {
        id: v1(),
        uri: pdf.url,
        name: getFileNameFromUrl(pdf.url),
        fileType: "application/pdf",
      },
      Boolean(zip.url) && {
        id: v1(),
        uri: zip.url,
        name: getFileNameFromUrl(zip.url),
        fileType: "application/zip",
      },
    ].filter(Boolean);
  }, [expenseReport]);

  if (isEmpty(fileDownloads)) {
    return null;
  }

  return (
    <Section title={`${t("download")}`}>
      <UploadFileView maxFile={6} files={fileDownloads} title={`${t("download")}`} />
    </Section>
  );
};
export default DownloadAttachment;
