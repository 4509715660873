import { SORT_OPTIONS } from "./types";

export const sortChartData = (a, b, currentSort) => {
  if (currentSort?.value === SORT_OPTIONS.EXPENSE_DEC) {
    return b.amount - a.amount !== 0 ? b.amount - a.amount : b.limitedAmount - a.limitedAmount;
  }
  if (currentSort?.value === SORT_OPTIONS.EXPENSE_ASC) {
    return a.amount - b.amount !== 0 ? a.amount - b.amount : b.limitedAmount - a.limitedAmount;
  }
  if (currentSort?.value === SORT_OPTIONS.BUDGET_DEC) {
    return b.limitedAmount - a.limitedAmount;
  }
  if (currentSort?.value === SORT_OPTIONS.BUDGET_ASC) {
    return a.limitedAmount - b.limitedAmount;
  }
  return a?.title?.localeCompare(b?.title);
};
export const getLastName = (fullname: string) => {
  const fullnames = fullname.split(" ");
  return fullnames[fullnames.length - 1];
};
