import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 9, height = 18, color = "#0F172A" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 9 18" fill="none">
      <Path
        d="M.91 16.918l6.52-6.52c.77-.77.77-2.03 0-2.8L.91 1.078"
        stroke={color}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
