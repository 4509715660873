import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "@apollo/client";
import { MobileExpInsertCompanyAttachmentsMutation, MobileExpInsertCompanyAttachmentsMutationVariables } from "types";

const MUTATION = gql`
  mutation MobileExpInsertCompanyAttachments($input: ExpInsertCompanyAttachmentsInput!) {
    expInsertCompanyAttachments(input: $input) {
      success
    }
  }
`;

export const useInsertCompanyAttachments = (
  options?: MutationHookOptions<
    MobileExpInsertCompanyAttachmentsMutation,
    MobileExpInsertCompanyAttachmentsMutationVariables
  >
) => {
  return useMutation(MUTATION, { ...options });
};
