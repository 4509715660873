import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  MobileAssignableCardTransactionExpensesQuery,
  MobileAssignableCardTransactionExpensesQueryVariables,
} from "types";

const QUERY = gql`
  query MobileAssignableCardTransactionExpenses(
    $cardTransactionId: UUID!
    $companyId: UUID!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
  ) {
    expAssignableCardTransactionExpenses(
      input: {
        condition: { cardTransactionId: $cardTransactionId, companyId: $companyId }
        limit: $limit
        offset: $offset
      }
    ) {
      total
      expenses {
        note
        category
        expenseDate
        expenseId
        employeeId
        totalAmountWithVat
        expenseRequest {
          docSequence
        }
      }
    }
  }
`;
const useAssignableCardTransactionExpenses = (variables: MobileAssignableCardTransactionExpensesQueryVariables) => {
  return useLazyQuery<
    MobileAssignableCardTransactionExpensesQuery,
    MobileAssignableCardTransactionExpensesQueryVariables
  >(QUERY, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
};

export default useAssignableCardTransactionExpenses;
