import { EXPENSE_REPORT_PAYMENT_METHOD } from "constants/constants";
import i18n from "i18next";
import { CreditCardOutline, DollarCircleOutline, MoneyTransferOutline } from "assets/images/svg/icons";
import { IconCustom } from "components";
import React from "react";
import { View } from "react-native";

export const PAYMENT_METHOD_TEXT = {
  [EXPENSE_REPORT_PAYMENT_METHOD.CASH]: i18n.t("common:cash"),
  [EXPENSE_REPORT_PAYMENT_METHOD.BANK_CARD]: i18n.t("common:bank_card"),
  [EXPENSE_REPORT_PAYMENT_METHOD.BANK_TRANSFERS]: i18n.t("common:bank_transfers"),
  [EXPENSE_REPORT_PAYMENT_METHOD.USER_BALANCE]: i18n.t("common:user_balance"),
  [EXPENSE_REPORT_PAYMENT_METHOD.LI_XI_COIN]: i18n.t("common:li_xi_coin"),
  [EXPENSE_REPORT_PAYMENT_METHOD.CLEARING_DEBT]: i18n.t("common:clearing_debt"),
};

export const PAYMENT_METHOD_ICON = {
  [EXPENSE_REPORT_PAYMENT_METHOD.CASH]: DollarCircleOutline,
  [EXPENSE_REPORT_PAYMENT_METHOD.BANK_CARD]: CreditCardOutline,
  [EXPENSE_REPORT_PAYMENT_METHOD.BANK_TRANSFERS]: MoneyTransferOutline,
  [EXPENSE_REPORT_PAYMENT_METHOD.USER_BALANCE]: () => (
    <View style={{ marginRight: 11, marginLeft: -2 }}>
      <IconCustom width={28} height={28} name="account-balance-wallet" fill="#94A3B8" />
    </View>
  ),
  [EXPENSE_REPORT_PAYMENT_METHOD.LI_XI_COIN]: DollarCircleOutline,
  [EXPENSE_REPORT_PAYMENT_METHOD.CLEARING_DEBT]: () => (
    <View style={{ marginRight: 11, marginLeft: -2 }}>
      <IconCustom width={28} height={28} name="account-balance-wallet" fill="#94A3B8" />
    </View>
  ),
};

export enum REQUEST_PAYMENT_STATUS {
  PENDING = "pending",
  PAID = "paid",
}
