import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { ApprovalFlowTooltip, ApprovalTimeLine, AppText, Section } from "components";
import { MobileExpExpenseRequestDetailQuery } from "types";
import { APPROVAL_METHOD, CONSTANTS, REQUEST_STATUS, REQUEST_TYPE } from "constants/constants";
import { ApprovalTimeLineItem } from "components/ApprovalTimeLine";
import AmountInfo from "./AmountInfo";
import GeneralInfo from "./GeneralInfo";
import TravelRequestInfo from "./TravelRequestInfo";
import TransportationSection, { TransportationTypes } from "./TransportationSection";
import HotelReservationSection from "./HotelReservationSection";
import UploadFileView from "components/UploadFiles/UploadFileView";
import { UploadFileItem } from "components/UploadFiles/types";
import ScreenShotInfo from "./ScreenShotInfo";
import { ScrollViewCustom } from "components/ScrollViewCustom/ScrollViewCustom";
import SelectedVendorInfo from "./SelectedVendorInfo";
import SupportingDocument from "./SupportingDocument";
import DownloadAttachments from "./DownloadAttachments";
import ExpenseListInfo from "screens/ExpenseRequest/ExpenseRequestDetailScreen/components/SpendPlanningTab/ExpenseListInfo";
import { ExpenseListGroupByCategory } from "screens/ExpenseRequest/ExpenseRequestDetailScreen/types";
import PassengerListSection from "./PassengerListSection";
import { useMasterData } from "contexts/MasterDataContext";

interface DetailTabProps {
  expenseRequest: MobileExpExpenseRequestDetailQuery["expExpenseRequestDetail"];
  isApproverView?: boolean;
  contentContainerStyle?: StyleProp<ViewStyle>;
  refetch?: () => void;
  expenseListGroupByCategory?: ExpenseListGroupByCategory[];
}
const DetailTab = forwardRef<any, DetailTabProps>(
  ({ contentContainerStyle, isApproverView, expenseRequest, refetch, expenseListGroupByCategory }, ref) => {
    useImperativeHandle(ref, () => ({
      share: () => {
        viewShotRef?.current?.share?.();
      },
    }));
    const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen");
    const {
      setting: { request: requestSetting },
    } = useMasterData();
    const viewShotRef = useRef<any>();
    const canDownloadAttachment = [REQUEST_STATUS.SUBMITTED, REQUEST_STATUS.APPROVED].includes(
      expenseRequest?.status as REQUEST_STATUS
    );
    const isPurchaseRequest = expenseRequest?.expenseRequestType === REQUEST_TYPE.purchase;
    const isComparisonRequest = expenseRequest?.expenseRequestType === REQUEST_TYPE.price_comparison;

    const attachmentOthers: UploadFileItem[] =
      expenseRequest?.expenseRequestAttachments?.map((att: any) => ({
        id: att.expenseAttachmentId,
        uri: att.fileUrl,
        name: att.fileName,
        size: att.fileSize,
        fileType: att.fileType,
        createdAt: att.createdAt,
      })) ?? [];

    const approvalTimeLineData: ApprovalTimeLineItem[] = useMemo(() => {
      if (!expenseRequest) {
        return [];
      }
      return expenseRequest?.expenseApprovalRules?.map((item) => {
        const expenseApprovalLog = expenseRequest?.expenseApprovalLogs?.find(
          (i) => i.expenseApprovalRuleId === item.expenseApprovalRuleId
        );
        const approvers = item?.expenseApprovalRuleEmployees?.map((approver) => {
          return {
            isRequestor: approver?.employeeId === expenseRequest?.employeeId,
            approvalMethod: (approver.approvalMethod as APPROVAL_METHOD) ?? APPROVAL_METHOD.MANUAL,
            employeeId: approver?.employeeId as string,
            employee: {
              avatar: approver?.employee?.avatarUrl || approver?.employee?.defaultAvatarUrl,
              fullname: approver?.employee?.fullname,
            },
            status: approver.status as number,
          };
        });

        return {
          approvalFlowName: item?.settings?.approvalFlow?.title ?? "",
          name: item?.name,
          status: item?.status as number,
          updatedAt: item?.updatedAt,
          approvers,
          reason: expenseApprovalLog?.reason,
        };
      });
    }, [expenseRequest]);

    return (
      <View style={styles.container}>
        {expenseRequest?.expenseRequestType === REQUEST_TYPE.travel &&
          expenseRequest?.status === REQUEST_STATUS.APPROVED && (
            <ScreenShotInfo
              ref={viewShotRef}
              isApproverView={isApproverView}
              expenseRequest={expenseRequest}
              approvalTimeLineData={approvalTimeLineData}
            />
          )}
        <ScrollViewCustom
          contentContainerStyle={contentContainerStyle}
          style={{ backgroundColor: Colors.grayscale05 }}
          showsVerticalScrollIndicator={false}
          renderContent={({ setLayoutData }) => (
            <>
              <GeneralInfo isApproverView={isApproverView} expenseRequest={expenseRequest} />
              {isPurchaseRequest && (
                <View style={styles.productListContainer}>
                  <ExpenseListInfo
                    isApproverView={isApproverView}
                    expenseRequest={expenseRequest}
                    expenseListByGroup={expenseListGroupByCategory}
                  />
                  {canDownloadAttachment && (
                    <DownloadAttachments
                      style={styles.productFileDownloadContainer}
                      expenseRequest={expenseRequest}
                      mode="purchase-request"
                    />
                  )}
                </View>
              )}
              {isComparisonRequest && (
                <View style={styles.vendorContainer}>
                  <SelectedVendorInfo data={expenseRequest?.priceComparisonVendors} />
                  {canDownloadAttachment && (
                    <DownloadAttachments
                      style={styles.productFileDownloadContainer}
                      expenseRequest={expenseRequest}
                      mode="purchase-request"
                    />
                  )}
                </View>
              )}
              {expenseRequest?.status === REQUEST_STATUS.APPROVED && !isComparisonRequest && (
                <AmountInfo expenseRequest={expenseRequest} />
              )}
              {expenseRequest?.expenseRequestType === REQUEST_TYPE.travel &&
                Boolean(requestSetting?.travel?.withPassengerInfo) && (
                  <PassengerListSection passengers={expenseRequest?.expenseTravels?.[0]?.passengers} />
                )}
              {expenseRequest?.expenseRequestType === REQUEST_TYPE.travel && (
                <TravelRequestInfo expenseRequest={expenseRequest} />
              )}
              <TransportationSection
                expenseRequest={expenseRequest}
                title={t("airplane_ticket")}
                type={TransportationTypes.Airplane}
              />
              <TransportationSection
                expenseRequest={expenseRequest}
                title={t("bus_ticket")}
                type={TransportationTypes.Bus}
              />
              <HotelReservationSection title={t("hotel_reservation")} expenseRequest={expenseRequest} />

              <Section
                title={
                  attachmentOthers?.length
                    ? `${t("attachment_other")} (${attachmentOthers.length})`
                    : t("attachment_other")
                }
              >
                {attachmentOthers.length ? (
                  <UploadFileView
                    maxFile={6}
                    files={attachmentOthers}
                    title={
                      attachmentOthers?.length
                        ? `${t("attachment_other")} (${attachmentOthers.length})`
                        : t("attachment_other")
                    }
                  />
                ) : (
                  <AppText style={[Fonts.BodyMedium, styles.emptyText]} color={Colors.grayscale60}>
                    {t("no_attachment")}
                  </AppText>
                )}
              </Section>
              <SupportingDocument expenseRequest={expenseRequest} refetch={refetch} />
              {canDownloadAttachment && !isPurchaseRequest && !isComparisonRequest && (
                <DownloadAttachments expenseRequest={expenseRequest} />
              )}
              <Section
                title={t("approval_flow")}
                icon={<ApprovalFlowTooltip />}
                onLayout={(event) => {
                  setLayoutData("approval-flow-section", event.nativeEvent.layout);
                }}
              >
                <ApprovalTimeLine data={approvalTimeLineData} />
              </Section>
            </>
          )}
        />
      </View>
    );
  }
);
export default DetailTab;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  emptyText: { textAlign: "center" },
  productListContainer: { marginTop: 8 },
  productFileDownloadContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingBottom: 10,
    paddingTop: 6,
    backgroundColor: Colors.white,
  },
  vendorContainer: { backgroundColor: Colors.white, marginTop: 8 },
});
