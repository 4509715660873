import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileExpenseRequestsHistoryQuery, MobileExpenseRequestsHistoryQueryVariables } from "types";

const QUERY_REQUEST_HISTORY = gql`
  query MobileExpenseRequestsHistory(
    $where: ExpMobileExpenseRequestCondition!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
  ) {
    expExpenseRequestsHistory: expMobileExpenseRequests(where: $where, limit: $limit, offset: $offset) {
      total
      requests {
        createdAt
        closedAt
        closedBy
        docSequence
        expenseRequestId
        expenseRequestType
        isClosed
        status
        title
        totalAmount
        requestedAt
        updatedAt
        companyEmployee {
          fullname
        }
      }
    }
  }
`;
const useExpenseRequestsHistory = (variables: MobileExpenseRequestsHistoryQueryVariables) => {
  return useQuery<MobileExpenseRequestsHistoryQuery, MobileExpenseRequestsHistoryQueryVariables>(
    QUERY_REQUEST_HISTORY,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: "network-only",
    }
  );
};
export default useExpenseRequestsHistory;
