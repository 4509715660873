import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#1C1B1F" }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M12 21.231l-.923-2.769H4.616c-.508 0-.943-.18-1.304-.542a1.777 1.777 0 01-.542-1.303V4.619c0-.507.18-.942.542-1.305a1.775 1.775 0 011.304-.544h5.538l.808 2.768h8.423c.538 0 .98.173 1.327.52.346.345.519.788.519 1.326V19.38c0 .508-.173.943-.52 1.306-.345.363-.788.544-1.326.544H12zm-4.47-6.833c1.063 0 1.936-.342 2.616-1.027.681-.684 1.022-1.573 1.022-2.665 0-.14-.003-.257-.008-.353a1.165 1.165 0 00-.054-.29H7.45v1.426h2.062a1.804 1.804 0 01-.705 1.01c-.343.24-.76.359-1.254.359-.6 0-1.117-.217-1.548-.652a2.172 2.172 0 01-.647-1.59c0-.626.215-1.156.645-1.59.43-.435.945-.653 1.544-.653.277 0 .537.05.781.148.244.1.468.25.672.454l1.13-1.079a3.42 3.42 0 00-1.168-.782 3.742 3.742 0 00-1.441-.281c-1.03 0-1.911.367-2.642 1.1-.73.735-1.096 1.629-1.096 2.683 0 1.053.366 1.947 1.099 2.681.732.734 1.615 1.101 2.648 1.101zm6.176.462l.512-.487a23.938 23.938 0 01-.595-.762 9.216 9.216 0 01-.519-.778l.602 2.027zm1.154-1.179c.432-.507.76-.99.984-1.451.223-.46.374-.823.45-1.087h-3.667l.275.972h.917c.122.231.268.481.438.75.17.268.371.54.603.816zm-1.937 6.627h6.462c.277 0 .5-.088.67-.265a.914.914 0 00.253-.658V7.39c0-.277-.085-.5-.254-.671-.169-.17-.392-.256-.669-.256h-8.142l1.083 3.742h1.82v-.97h.945v.97h3.37v.939h-1.178a7.562 7.562 0 01-.688 1.705 8.728 8.728 0 01-1.083 1.55l2.513 2.473-.667.668-2.498-2.489-.833.848.743 2.564-1.847 1.846z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
