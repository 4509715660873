import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <Mask id="a" width={16} height={16} x={0} y={0} maskUnits="userSpaceOnUse">
      <Path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill="#1F71F4"
        d="M7.299 4.333h1.397V2.936H7.3v1.397Zm.698 3.795c-.78 0-1.529-.15-2.246-.448a15.611 15.611 0 0 1-2.087-1.06V5.538c0-.331.118-.615.354-.851a1.16 1.16 0 0 1 .851-.354H6.3V2.525c0-.163.057-.302.172-.417a.568.568 0 0 1 .417-.172h2.218c.164 0 .303.057.418.172a.568.568 0 0 1 .172.417v1.808h1.43c.331 0 .615.118.85.354.237.236.355.52.355.851V6.62c-.674.408-1.37.761-2.087 1.06a5.79 5.79 0 0 1-2.247.448ZM4.87 13.833v-.167a1.16 1.16 0 0 1-.85-.354 1.16 1.16 0 0 1-.355-.85v-4.7c.597.351 1.213.647 1.848.886a7.775 7.775 0 0 0 1.985.46v.186c0 .142.048.26.144.357a.484.484 0 0 0 .357.143c.141 0 .26-.047.356-.143a.484.484 0 0 0 .143-.357v-.187a7.774 7.774 0 0 0 1.986-.46 11.798 11.798 0 0 0 1.848-.886v4.7c0 .332-.118.615-.354.851a1.16 1.16 0 0 1-.851.354v.167c0 .142-.048.26-.145.356a.487.487 0 0 1-.357.144.309.309 0 0 1-.293-.174 2.498 2.498 0 0 0-.205-.326v-.167H5.869v.167c0 .142-.048.26-.144.356a.487.487 0 0 1-.358.144.309.309 0 0 1-.293-.174 2.5 2.5 0 0 0-.205-.326Z"
      />
    </G>
  </Svg>
);
export default SvgComponent;
