import * as Font from "expo-font";
import { useCallback, useEffect, useState } from "react";
import { Platform } from "react-native";

const useCachedResources = () => {
  /*-- NOTE: in the case web app need use this hook to load font --*/
  const [isLoadingComplete, setLoadingComplete] = useState(Platform.OS !== "web");

  const onFinishLoading = () => {
    setLoadingComplete(true);
  };

  const loadResourcesAndDataAsync = useCallback(async () => {
    const fonts = {
      antoutline: require("@ant-design/icons-react-native/fonts/antoutline.ttf"),
      antfill: require("@ant-design/icons-react-native/fonts/antfill.ttf"),
      "Roboto-Bold": require("app/assets/fonts/Roboto-Bold.ttf"),
      "Roboto-Medium": require("app/assets/fonts/Roboto-Medium.ttf"),
      Roboto: require("app/assets/fonts/Roboto-Regular.ttf"),
      "Roboto-Bolder": require("app/assets/fonts/Roboto-Black.ttf"),
      "Roboto-Regular": require("app/assets/fonts/Roboto-Regular.ttf"),
      Inter: require("app/assets/fonts/Inter.ttf"),
      "Inter-Medium": require("app/assets/fonts/Inter-Medium.ttf"),
      "Inter-SemiBold": require("app/assets/fonts/Inter-SemiBold.ttf"),
      "Inter-Bold": require("app/assets/fonts/Inter-Bold.ttf"),
    };
    try {
      Promise.all([Font.loadAsync(fonts)])
        .then(onFinishLoading)
        .catch(onFinishLoading);
    } catch (e) {
      onFinishLoading();
    }
  }, [onFinishLoading]);

  useEffect(() => {
    if (Platform.OS === "web") {
      loadResourcesAndDataAsync();
    }
  }, []);

  return isLoadingComplete;
};

export default useCachedResources;
