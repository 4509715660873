import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#004C8F", size = 45, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 45 45" fill="none" {...props}>
      <Path fill="#fff" d="M0 0h45v45H0z" />
      <Path
        d="M40.782 25.617c-4.202-6.435-3.755-13.03-5.78-16.131-2.026-3.102-8.063-8.448-22.326.868C-1.588 19.671 2.04 29.8 4.269 33.21c10.431 15.969 43.957 3.803 36.513-7.592z"
        fill="#E6F4FF"
      />
      <Path
        d="M8.667 10.798A1.669 1.669 0 017 9.13c0-.919.748-1.667 1.667-1.667s1.666.748 1.666 1.667-.747 1.667-1.666 1.667zm0-2.222a.556.556 0 100 1.111.556.556 0 000-1.111zM29.393 7.018l.785-.786 1.572 1.572-.786.785-1.571-1.57zM33.91 11.536l.785-.786 1.571 1.572-.785.785-1.572-1.571zM33.713 7.804l1.571-1.571.786.786-1.571 1.57-.786-.785z"
        fill={color}
      />
      <Path
        d="M34.5 19.278v2.2l-3.333 1.744V16.5l2.456 1.311c.533.289.877.856.877 1.467zM12.833 16.5v6.722L9.5 21.478v-2.2c0-.611.344-1.178.878-1.467l2.455-1.311z"
        fill="#fff"
      />
      <Path
        d="M34.5 21.478v11.244c0 .922-.744 1.667-1.667 1.667H11.167A1.664 1.664 0 019.5 32.722V21.478l3.333 1.744h.011l8.123 4.245a2.26 2.26 0 002.066 0l8.123-4.245h.01l3.334-1.744z"
        fill="#fff"
      />
      <Path
        d="M31.166 12.722v10.5h-.01l-8.123 4.245a2.26 2.26 0 01-2.067 0l-8.122-4.245h-.011v-10.5c0-1.222 1-2.222 2.222-2.222h13.89c1.221 0 2.221 1 2.221 2.222z"
        fill="#B3DCFF"
      />
      <Path d="M33.667 17.843c-.016-.009-.028-.023-.044-.032L31.167 16.5v6.722l2.5-1.308v-4.07z" fill="#D5DBE1" />
      <Path
        d="M12 21.478v-2.2c0-.592.328-1.138.833-1.435V16.5l-2.455 1.311a1.68 1.68 0 00-.878 1.467v2.2l3.333 1.744v-1.308L12 21.478z"
        fill="#E6E8EA"
      />
      <Path
        d="M12 32.722v-9.936l-2.5-1.308v11.244c0 .922.744 1.667 1.667 1.667h2.5A1.664 1.664 0 0112 32.722z"
        fill="#E6E8EA"
      />
      <Path
        d="M15.344 23.222h-.011v-10.5c0-1.222 1-2.222 2.222-2.222h-2.5c-1.222 0-2.222 1-2.222 2.222v10.5h.011l8.122 4.245a2.26 2.26 0 002.067 0l.217-.114-7.906-4.13z"
        fill="#7FC3FF"
      />
      <Path
        d="M17.278 20.778h9.445v1.666h-9.445v-1.666zM17.278 17.444h9.445v1.667h-9.445v-1.667zM17.278 14.111h5v1.667h-5V14.11z"
        fill={color}
      />
      <Path
        d="M32.833 35.222H11.166a2.502 2.502 0 01-2.5-2.5V19.278a2.5 2.5 0 011.315-2.2l2.459-1.312.785 1.47-2.455 1.31a.844.844 0 00-.437.732v13.444c0 .46.373.834.833.834h21.667c.46 0 .833-.374.833-.834V19.278a.845.845 0 00-.44-.734l-2.453-1.308.785-1.47 2.456 1.31a2.5 2.5 0 011.319 2.202v13.444c0 1.38-1.121 2.5-2.5 2.5z"
        fill={color}
      />
      <Path
        d="M21.998 28.551c-.486 0-.972-.114-1.414-.343l-11.47-5.992.771-1.477 11.467 5.989c.398.205.898.205 1.299-.002l11.463-5.987.771 1.477-11.467 5.988a3.08 3.08 0 01-1.42.347z"
        fill={color}
      />
      <Path
        d="M32 23.222h-1.667v-10.5a1.39 1.39 0 00-1.39-1.389H15.056a1.39 1.39 0 00-1.389 1.39v10.5h-1.667v-10.5a3.059 3.059 0 013.056-3.056h13.889a3.059 3.059 0 013.055 3.055v10.5zM4.222 33.556h35.555v1.666H4.222v-1.666z"
        fill={color}
      />
    </Svg>
  );
}

export default SvgComponent;
