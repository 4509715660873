import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "@apollo/client";
import { MobileExpDeleteCompanyAttachmentsMutation, MobileExpDeleteCompanyAttachmentsMutationVariables } from "types";

const MUTATION = gql`
  mutation MobileExpDeleteCompanyAttachments($input: ExpDeleteCompanyAttachmentsInput!) {
    expDeleteCompanyAttachments(input: $input) {
      success
    }
  }
`;

export const useDeleteCompanyAttachments = (
  options?: MutationHookOptions<
    MobileExpDeleteCompanyAttachmentsMutation,
    MobileExpDeleteCompanyAttachmentsMutationVariables
  >
) => {
  return useMutation(MUTATION, { ...options });
};
