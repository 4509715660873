import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ size }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 0c-.825 0-1.5.675-1.5 1.5v21c0 .825.675 1.5 1.5 1.5h15c.825 0 1.5-.675 1.5-1.5V6l-6-6H6z"
        fill="#E2E5E7"
      />
      <Path d="M18 6h4.5l-6-6v4.5c0 .825.675 1.5 1.5 1.5z" fill="#B0B7BD" />
      <Path d="M22.5 10.5L18 6h4.5v4.5z" fill="#CAD1D8" />
      <Path
        d="M19.5 19.5c0 .413-.337.75-.75.75H2.25a.752.752 0 01-.75-.75V12c0-.412.337-.75.75-.75h16.5c.413 0 .75.338.75.75v7.5z"
        fill="#50BEE8"
      />
      <Path
        d="M4.34 17.997c-.198 0-.414-.108-.414-.372V14.22c0-.216.216-.372.414-.372h1.373c2.74 0 2.68 4.15.053 4.15H4.34zm.378-3.418v2.686h.995c1.62 0 1.69-2.686 0-2.686h-.995zM10.687 18.059c-1.11.048-2.261-.69-2.261-2.16 0-1.474 1.151-2.2 2.261-2.2 1.05.053 2.147.78 2.147 2.2 0 1.423-1.098 2.16-2.147 2.16zm-.066-3.58c-.672 0-1.403.474-1.403 1.421 0 .943.732 1.423 1.403 1.423.69 0 1.427-.48 1.427-1.423 0-.947-.737-1.42-1.427-1.42zM13.54 15.895c0-1.158.725-2.153 2.104-2.153.522 0 .936.156 1.374.534.162.15.18.413.018.581a.407.407 0 01-.552-.018c-.246-.258-.498-.329-.84-.329-.923 0-1.366.654-1.366 1.385 0 .744.437 1.428 1.366 1.428.342 0 .66-.14.936-.384a.403.403 0 01.552.072c.096.132.143.354-.066.563-.42.39-.924.469-1.422.469-1.45 0-2.105-.991-2.105-2.148z"
        fill="#fff"
      />
      <Path d="M18.75 20.25H4.5V21h14.25c.413 0 .75-.337.75-.75v-.75c0 .413-.337.75-.75.75z" fill="#CAD1D8" />
    </Svg>
  );
}

export default SvgComponent;
