import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileNtfNotificationsQuery, MobileNtfNotificationsQueryVariables } from "types";

const QUERY = gql`
  query MobileNtfNotifications($page: Int!) {
    ntfNotifications(input: { page: $page }) {
      pageSize
      hasMore
      totalCount
      notifications {
        content
        createdAt
        payload
        title
        read
        messageId
        seen
      }
    }
  }
`;

const useNotificationsQuery = (
  variables: MobileNtfNotificationsQueryVariables,
  onCompleted: (data: MobileNtfNotificationsQuery) => void
) => {
  return useQuery<MobileNtfNotificationsQuery, MobileNtfNotificationsQueryVariables>(QUERY, {
    variables,
    onCompleted,
    notifyOnNetworkStatusChange: true,
  });
};

export default useNotificationsQuery;
