import { FC, useRef } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { Colors, Fonts, FontTypes } from "theme";
import { AppText, AvatarUser } from "components/index";
import EmployeeModalView, { EmployeeModalViewMethods } from "components/EmployeeInfoView/EmployeeModalView";

interface EmployeeTextViewProps {
  style?: StyleProp<ViewStyle>;
  employeeId: string;
  fullname?: string;
  label?: string;
  type?: "TextView" | "Tag";
  avatar?: string;
}

const EmployeeTextView: FC<EmployeeTextViewProps> = ({
  type = "TextView",
  style,
  label,
  employeeId,
  fullname,
  avatar,
}) => {
  const employeeModalViewRef = useRef<EmployeeModalViewMethods>();
  const onPress = () => {
    employeeModalViewRef?.current?.present({ employeeId, avatar });
  };
  const isTagStyle = type === "Tag";
  return (
    <View style={style}>
      {Boolean(label) && <AppText style={[Fonts.SentenceCaptionMedium, styles.label]}>{label}</AppText>}
      <View>
        <TouchableOpacity style={[styles.outline, isTagStyle && styles.tag]} onPress={onPress}>
          <AvatarUser
            url={avatar}
            textStyle={isTagStyle ? styles.textAvatarTag : Fonts.BodyRegular}
            size={isTagStyle ? 16 : 24}
          />
          <AppText color={Colors.primary50} style={isTagStyle ? styles.employeeTagText : [Fonts.SentenceBodyMedium]}>
            {fullname}
          </AppText>
        </TouchableOpacity>
      </View>
      <EmployeeModalView ref={employeeModalViewRef} />
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    marginBottom: 4,
    color: Colors.grayscale60,
  },
  outline: {
    alignSelf: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    gap: 8,
    borderColor: Colors.grayscale0,
    paddingBottom: 7,
  },
  tag: {
    alignItems: "center",
    paddingHorizontal: 8,
    backgroundColor: Colors.grayscale05,
    alignSelf: "center",
    justifyContent: "center",
    paddingVertical: 4,
    borderRadius: 4,
    paddingBottom: 0,
    paddingTop: 0,
    gap: 4,
    borderBottomWidth: 0,
    minHeight: 28,
  },
  textAvatarTag: { fontSize: 8, fontFamily: FontTypes.semiBold },
  employeeTagText: { ...Fonts.BodyMedium },
});

export default EmployeeTextView;
