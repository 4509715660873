// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useNavigation, useRoute } from "@react-navigation/native";
import BizziIcon from "assets/images/svg/icons/BizziIcon";
import { BizziButton } from "components";
import InputFieldFlat from "components/Input/PaperInputFlat";
import Colors from "constants/Colors";
import Layout, { SPACING_DEFAULT, WIDTH_WINDOW } from "constants/Layout";
import Constants from "expo-constants";
import { Formik } from "formik";
import useCheckIsMobile from "hooks/useCheckIsMobile";
import CustomDropdownPicker from "layouts/CustomDropdownPicker";
import SCREEN_NAME from "navigation/ScreenName";
import { RegisterInviteColleagueScreenNavigationProp, RegisterInviteColleagueScreenRouteProp } from "navigation/type";
import { RegisterSetupCompanyScreenNavigationProp, RegisterSetupCompanyScreenRouteProp } from "navigation/type";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";

import * as Yup from "yup";
import { stylesCommon } from "./commonStyle";
import RegisterSatus from "./RegisterSatus";
import { BackButtonIcon } from "assets/images/svg/icons";

export default function RegisterCompanyScreen() {
  const navigation = useNavigation<RegisterInviteColleagueScreenNavigationProp>();
  const route = useRoute<RegisterInviteColleagueScreenRouteProp>();

  const { token, key, email, password, phone, fullname, referralBy, employee_title } = route?.params ?? {};
  const { width } = useWindowDimensions();
  const IsMobile = width < 768;

  const addressRef = useRef<TextInput>(null);

  const { t } = useTranslation("screens/RegisterOnboarding/RegisterCompanyScreen");

  const [loading, setLoading] = useState(false);

  const initialValues = {
    tax_code: "",
    company_name: "",
    address: "",
    business_areas: "",
    company_size: "",
  };
  const yupSchema = Yup.object().shape({
    tax_code: Yup.string().matches(/^\d+$/, t("tax_code_not_valid")).required(t("you_need_input_to_continue")),
    company_name: Yup.string().required(t("you_need_input_to_continue")),
    address: Yup.string().required(t("you_need_input_to_continue")),
    business_areas: Yup.string().required(t("you_need_select_to_continue")),
    company_size: Yup.string().required(t("you_need_select_to_continue")),
  });

  const CompanyFields = [
    {
      label: t("construction"),
      value: "construction",
    },
    {
      label: t("logistics"),
      value: "logistics",
    },
    {
      label: t("manufacturing"),
      value: "manufacturing",
    },
    {
      label: t("pharmacy"),
      value: "pharmacy",
    },
    {
      label: t("retail"),
      value: "retail",
    },
    {
      label: t("technology"),
      value: "technology",
    },
    {
      label: t("other"),
      value: "other",
    },
  ];

  const colorList = [
    {
      label: t("less_than_10"),
      value: "less_than_10",
    },
    {
      label: t("from_11_to_30"),
      value: "from_11_to_30",
    },
    {
      label: t("from_31_to_100"),
      value: "from_31_to_100",
    },
    {
      label: t("from_101_to_300"),
      value: "from_101_to_300",
    },
    {
      label: t("over_301"),
      value: "over_301",
    },
  ];

  const onSubmit = useCallback(
    async (values) => {
      Keyboard.dismiss();
      requestAnimationFrame(() => {
        navigation.navigate(SCREEN_NAME.RegisterInviteColleagueScreen, {
          email,
          password,
          tax_code: values.tax_code,
          company_name: values.company_name,
          address: values.address,
          business_areas: values.business_areas,
          company_size: values.company_size,
          phone,
          fullname,
          referralBy,
          employee_title,
        });
      });
    },
    [navigation, phone, fullname, email, password, referralBy, employee_title]
  );

  return (
    <KeyboardAvoidingView style={Layout.main} behavior={Constants?.platform?.ios ? "padding" : "height"} enabled>
      <ScrollView>
        <View style={stylesCommon.container}>
          <View style={IsMobile ? stylesCommon.contentMobile : stylesCommon.content}>
            <View style={stylesCommon.logoContainer}>
              <TouchableOpacity onPress={() => navigation.goBack()} style={stylesCommon.backButton}>
                <BackButtonIcon />
              </TouchableOpacity>
              <BizziIcon />
            </View>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnMount validationSchema={yupSchema}>
              {(props) => {
                const {
                  values,
                  errors,
                  isValid,
                  touched,
                  status,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setValues,
                  setTouched,
                } = props;
                return (
                  <View style={IsMobile ? stylesCommon.registerFormMobile : stylesCommon.registerForm}>
                    <Text style={stylesCommon.title}>{t("title")}</Text>
                    <Text style={[stylesCommon.description, styles.description]}>{t("description")}</Text>
                    <InputFieldFlat
                      label={t("tax_code")}
                      placeholder={t("tax_code")}
                      onBlur={handleBlur("tax_code")}
                      onChangeText={handleChange("tax_code")}
                      // onSubmitEditing={handleSubmit}
                      value={values.tax_code}
                      error={errors?.tax_code}
                      touched={touched?.tax_code}
                      onFocus={() => {
                        setTouched({ tax_code: false });
                      }}
                      keyboardType="numeric"
                    />
                    <InputFieldFlat
                      label={t("company_name")}
                      onBlur={handleBlur("company_name")}
                      onChangeText={handleChange("company_name")}
                      value={values.company_name}
                      error={errors?.company_name}
                      touched={touched?.company_name}
                      // inputStyle={styles.inputField}
                      onFocus={() => {
                        setTouched({ company_name: false });
                      }}
                    />
                    <InputFieldFlat
                      label={t("address")}
                      onBlur={handleBlur("address")}
                      onChangeText={handleChange("address")}
                      value={values.address}
                      error={errors?.address}
                      touched={touched?.address}
                      onSubmitEditing={() => addressRef.current?.focus()}
                      onFocus={() => {
                        setTouched({ address: false });
                      }}
                    />
                    <View style={{ marginTop: 20 }}>
                      <CustomDropdownPicker
                        placeholder={t("business_areas")}
                        value={values.business_areas}
                        onSelectItem={handleChange("business_areas")}
                        items={CompanyFields}
                      />
                    </View>
                    <View style={{ marginTop: 20 }}>
                      <CustomDropdownPicker
                        placeholder={t("company_size")}
                        value={values.company_size}
                        onSelectItem={handleChange("company_size")}
                        items={colorList}
                      />
                    </View>

                    <BizziButton
                      disabled={!isValid || loading}
                      title={t("register_company")}
                      style={styles.btnRegister}
                      onPress={handleSubmit}
                    />
                  </View>
                );
              }}
            </Formik>
          </View>
          {!IsMobile && (
            <View style={stylesCommon.status}>
              <RegisterSatus activeStep={2} />
            </View>
          )}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  description: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray80,
    marginBottom: 24,
  },
  btnRegister: {
    marginTop: 32,
  },
});
