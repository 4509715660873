import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg";

const SVGComponent = ({ width = 63, height = 58 }) => (
  <Svg width={width} height={height} viewBox="0 0 63 58" fill="none">
    <G clipPath="url(#clip0_5780_51273)">
      <Path
        d="M25.6836 57.1755C27.2689 57.6802 29.0742 57.9649 30.996 57.9649C32.9178 57.9649 34.7231 57.6802 36.3084 57.1755C34.7749 55.7584 32.9437 54.8525 30.996 54.8525C29.0483 54.8525 27.2365 55.7455 25.6836 57.1755Z"
        fill="#C0CAD8"
      />
      <Path
        d="M58.6676 36.3786C57.8459 49.2682 45.3899 55.0788 30.5333 54.1276C15.6767 53.1829 4.0748 45.8258 4.89657 32.9492C5.71835 20.0726 18.5885 7.81073 33.4451 8.76192C48.2952 9.70663 59.4894 23.4891 58.6676 36.3786Z"
        fill="#E4EAF1"
      />
      <Path
        d="M22.0236 10.6453L20.1406 40.1256C23.4924 41.4391 27.2583 42.2738 31.2831 42.5326C35.3014 42.7915 39.1514 42.4421 42.6455 41.562L44.5285 12.0818C41.2091 10.2182 37.4561 9.02117 33.4443 8.76234C29.426 8.50351 25.5501 9.21529 22.0236 10.6453Z"
        fill="#1F71F4"
      />
      <Path
        d="M31.0308 21.4712L30.4679 21.4323L30.9597 19.737L31.995 16.1264L32.0467 15.9323L29.1867 15.7511L30.5326 11.1634L28.8438 11.0534L32.9591 8.73047L35.502 11.4805L34.195 11.3964L33.6644 13.2276L37.4238 13.467L31.0308 21.4712Z"
        fill="white"
      />
      <Path
        d="M11.7647 40.8304C8.56168 36.3074 13.2335 22.2338 18.1059 19.6067C24.6542 16.0802 43.1861 17.7561 48.7962 22.622C52.8597 26.155 54.9756 40.4551 51.4297 43.9492C44.3508 50.9246 17.4782 48.8993 11.7647 40.8304Z"
        fill="#1B1B1D"
      />
      <Path
        d="M6.6306 37.1682L3.58939 36.1846C3.03292 36.0099 2.65762 35.3629 2.70292 34.6576L3.03292 29.481C3.07821 28.7757 3.53116 28.1804 4.11999 28.0769L7.25825 27.4881C8.0412 27.3457 8.72062 28.0963 8.65591 29.054L8.22885 35.7964C8.15767 36.7346 7.38766 37.4141 6.6306 37.1682Z"
        fill="#C0CAD8"
      />
      <Path
        d="M4.90406 36.6446L2.11522 35.7581C1.59756 35.5963 1.26109 35.0075 1.29991 34.3475L1.60403 29.611C1.64286 28.9574 2.05698 28.4269 2.58757 28.3298L5.46701 27.7992C6.18525 27.6633 6.79996 28.3621 6.7482 29.2227L6.35349 35.3957C6.30172 36.2693 5.58995 36.871 4.90406 36.6446Z"
        fill="#E4EAF1"
      />
      <Path
        d="M56.3856 40.7593L59.5238 40.1705C60.1126 40.067 60.5656 39.4717 60.6109 38.7664L60.9409 33.5899C60.9862 32.8846 60.6109 32.2375 60.0415 32.0563L57.0003 31.0728C56.2432 30.8334 55.4732 31.4934 55.415 32.4446L54.9879 39.1934C54.9297 40.1446 55.6026 40.9146 56.3856 40.7593Z"
        fill="#C0CAD8"
      />
      <Path
        d="M58.1749 40.4686L61.0543 39.938C61.5914 39.8409 61.999 39.2974 62.0378 38.6568L62.342 33.9203C62.3808 33.2668 62.0443 32.6844 61.5267 32.5226L58.7378 31.6362C58.039 31.4097 57.3401 32.0244 57.2884 32.885L56.8937 39.0515C56.8419 39.9057 57.4566 40.6045 58.1749 40.4686Z"
        fill="#E4EAF1"
      />
      <Path
        d="M25.8152 37.5309C27.9094 37.5309 29.607 35.8332 29.607 33.7391C29.607 31.6449 27.9094 29.9473 25.8152 29.9473C23.7211 29.9473 22.0234 31.6449 22.0234 33.7391C22.0234 35.8332 23.7211 37.5309 25.8152 37.5309Z"
        fill="white"
      />
      <Path
        d="M39.0301 38.2593C41.1242 38.2593 42.8218 36.5617 42.8218 34.4676C42.8218 32.3734 41.1242 30.6758 39.0301 30.6758C36.9359 30.6758 35.2383 32.3734 35.2383 34.4676C35.2383 36.5617 36.9359 38.2593 39.0301 38.2593Z"
        fill="white"
      />
      <Path d="M11.9197 0.908421L6.2494 16.6933L10.5404 18.2258L17.0792 2.69635L11.9197 0.908421Z" fill="#FF6D5A" />
      <Path d="M2.37717 9.04807L18.1621 14.7184L19.6946 10.4273L4.1651 3.88861L2.37717 9.04807Z" fill="#FF6D5A" />
    </G>
    <Defs>
      <ClipPath id="clip0_5780_51273">
        <Rect width={61.6847} height={57.9446} fill="white" transform="translate(0.660156 0.0205078)" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SVGComponent;
