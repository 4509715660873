import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileBokSearchFlightQuery, MobileBokSearchFlightQueryVariables } from "types";

const QUERY = gql`
  query MobileBokSearchFlight($input: BokSearchFlightInput!) {
    bokSearchFlight(input: $input) {
      session
      bokFares {
        airline {
          code
          logoUrl
          name
        }
        currency
        fareId
        flights {
          airline {
            code
            logoUrl
            name
          }
          arrival {
            code
            name
            nameEn
            location {
              code
              name
              nameEn
            }
          }
          cabinClass
          departure {
            code
            location {
              code
              name
              nameEn
            }
            name
            nameEn
          }
          duration
          endDate
          flightId
          flightNumber
          flightValue
          noRefund
          operating {
            code
            logoUrl
            name
          }
          segments {
            allowanceBaggage {
              pieces
              weight
              weightUnit
            }
            airline {
              code
              logoUrl
              name
            }
            arrival {
              code
              location {
                code
                name
                nameEn
              }
              name
              nameEn
            }
            cabinClass
            changeAirport
            changeStation
            dayChange
            departure {
              code
              location {
                code
                name
                nameEn
              }
              name
              nameEn
            }
            duration
            endDate
            flightNumber
            handBaggage {
              pieces
              weight
              weightUnit
            }
            hasStop
            operating {
              code
              logoUrl
              name
            }
            segmentId
            startDate
            stopPoint {
              code
              location {
                code
                name
                nameEn
              }
              name
              nameEn
            }
            stopTime
          }
          startDate
        }
        providerCode
        session
        totalDiscount
        totalPrice
      }
    }
  }
`;

const useBokSearchFlight = (
  variables?: MobileBokSearchFlightQueryVariables,
  onCompleted?: (data: MobileBokSearchFlightQuery) => void
) => {
  return useQuery<MobileBokSearchFlightQuery, MobileBokSearchFlightQueryVariables>(QUERY, {
    variables,
    onCompleted,
  });
};

export default useBokSearchFlight;
