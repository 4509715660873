import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={67} height={66} viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M29.348 6.133l-13.723 5.17c-3.162 1.182-5.747 4.922-5.747 8.277v20.433c0 3.245 2.145 7.507 4.757 9.46L26.46 58.3c3.878 2.915 10.258 2.915 14.135 0l11.825-8.828c2.613-1.952 4.758-6.215 4.758-9.46V19.58c0-3.382-2.585-7.122-5.748-8.305L37.708 6.133c-2.338-.853-6.078-.853-8.36 0z"
        stroke="#2B8A3E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M25.388 32.643l4.427 4.427L41.64 25.245"
        stroke="#2B8A3E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
