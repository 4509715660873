import { Dayjs } from "dayjs";

export interface TransactionFilterData {
  startDate?: Dayjs;
  endDate?: Dayjs;
}

export enum TYPE_TIME_FILTER {
  ONE_LAST_MONTH = "ONE_LAST_MONTH",
  SIX_LAST_MONTHS = "SIX_LAST_MONTHS",
  NINE_LAST_MONTHS = "NINE_LAST_MONTHS",
  ONE_LAST_YEAR = "ONE_LAST_YEAR",
  CUSTOM = "CUSTOM",
}
export interface TimeFilterData {
  title: string;
  value: string;
}
