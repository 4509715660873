import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { MobileBanksQuery } from "types";

const QUERY_BANKS = gql`
  query MobileBanks {
    expBanks {
      banks {
        bankId
        bankCode
        bankName
        bankShortName
        isActive
        logoUrl
        swiftCode
        isSupportedPayment
      }
    }
  }
`;

const useBanksQuery = () => {
  return useLazyQuery<MobileBanksQuery>(QUERY_BANKS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
};

export default useBanksQuery;
