import { FC, ReactElement, useMemo } from "react";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { useFeatureManagement } from "contexts/FeatureManagementContext";

type FeatureWrapperProps = {
  children: ReactElement;
  featureName: (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];
};

const FeatureWrapper: FC<FeatureWrapperProps> = ({ children, featureName }) => {
  const { features } = useFeatureManagement();
  const enabled = useMemo(() => {
    const { enabled } = features?.[featureName] ?? {};
    return enabled;
  }, [featureName, features]);

  if (!enabled) {
    return null;
  }

  return children;
};

export default FeatureWrapper;
