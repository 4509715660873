import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Route } from "react-native-tab-view";
import { CreateCircleButton, HeaderPageBottomTab, IconCustom, TopTabView, CopilotStepCustom } from "components";
import SCREEN_NAME from "navigation/ScreenName";
import { ExpenseSearchScreenNavigationProp, NewExpenseScreenNavigationProp } from "navigation/type";
import ClaimedExpenseTab from "screens/Expense/ExpenseListScreen/components/ClaimedExpenseTab";
import NewExpenseTab from "screens/Expense/ExpenseListScreen/components/NewExpenseTab";
import { NewExpenseTabMethods } from "screens/Expense/ExpenseListScreen/types";
import { Colors } from "theme";
import { EVENT } from "constants/Tracking";
import { CONSTANTS } from "constants/constants";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";

export enum EXPENSE_LIST_TAB {
  NEW = "NEW",
  CLAIMED = "CLAIMED",
}

const ExpenseListScreen = ({ route }) => {
  const { top } = useSafeAreaInsets();
  const navigation = useNavigation<NewExpenseScreenNavigationProp | ExpenseSearchScreenNavigationProp>();

  const { t } = useTranslation("app/screens/Expense/ExpenseListScreen");
  const [index, setIndex] = useState<number>(route?.params?.tab === EXPENSE_LIST_TAB.CLAIMED ? 1 : 0);
  const newExpenseTabRef = useRef<NewExpenseTabMethods>(null);

  const routes = [
    { key: EXPENSE_LIST_TAB.NEW, title: t("new_expense") },
    { key: EXPENSE_LIST_TAB.CLAIMED, title: t("reported") },
  ];

  /*-- start walkthrough flow --*/
  const { shouldShowWalkthrough = false } = route?.params ?? {};
  const { start, steps } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) =>
      steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME &&
      steps[key].order === CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step1
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [route?.params, stepNameToStartWalkthrough]);
  /*-- end --*/

  useEffect(() => {
    if (route?.params?.tab === EXPENSE_LIST_TAB.NEW) {
      setIndex(0);
    } else if (route?.params?.tab === EXPENSE_LIST_TAB.CLAIMED) {
      setIndex(1);
    }
  }, [route]);
  const routeParams = route.params;
  console.log("routeParams", routeParams);
  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case EXPENSE_LIST_TAB.NEW:
        return <NewExpenseTab ref={newExpenseTabRef} />;
      case EXPENSE_LIST_TAB.CLAIMED:
        return <ClaimedExpenseTab />;
      default:
        return null;
    }
  };

  const navigateToCreateExpense = (shouldShowWalkthrough = false) => {
    navigation.navigate(SCREEN_NAME.NewExpenseScreen, {
      onRefreshData: newExpenseTabRef?.current?.refreshData,
      shouldShowWalkthrough,
    });
  };

  const navigateToExpenseSearchScreen = () => {
    const previousScreen = { 0: "new", 1: "claimed" }[index] as "new" | "claimed";
    if (previousScreen) {
      navigation.navigate(SCREEN_NAME.ExpenseSearchScreen, { previousScreen });
    }
  };

  const renderRightComponent = () => {
    return (
      <TouchableOpacity style={styles.rightButtonIcon} onPress={navigateToExpenseSearchScreen}>
        <IconCustom name="search" />
      </TouchableOpacity>
    );
  };

  return (
    <View style={[styles.container, { paddingTop: top }]}>
      <HeaderPageBottomTab title={t("expense")} rightComponent={renderRightComponent()} />
      <TopTabView routes={routes} index={index} setIndex={setIndex} renderScene={renderScene} />
      <CopilotStepCustom
        title={"walkthrough_expense_title_step1"}
        text={"walkthrough_expense_description_step1"}
        style={styles.floatButton}
        order={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.Step1}
        image={BizziBotSticker01}
        walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_EXPENSE.NAME}
        onConfirm={() => navigateToCreateExpense(true)}
        confirmText={t("continue")}
        singleStep={true}
      >
        <CreateCircleButton eventName={EVENT.EXPENSE.TAP_CREATE} onPress={() => navigateToCreateExpense(false)} />
      </CopilotStepCustom>
    </View>
  );
};

export default ExpenseListScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  floatButton: { position: "absolute", right: 15, bottom: 20 },
  rightButtonIcon: { padding: 10 },
});
