export const TRACKING = {
  EVENTS: {
    LOGIN_SCREEN: {
      LOGIN: "login_screen_btnLogin",
      REGISTER: "login_screen_btnRegister",
      VIEW_PASSWORD: "login_screen_viewPass",
      LOGIN_SUCCESS: "login_screen_loginSuccess",
    },
    REGISTER_SCREEN: {
      LOGIN: "register_screen_btnLogin",
      REGISTER: "register_screen_btnRegister",
      REGISTER_SUCCESS: "register_screen_register_success",
      REGISTER_FAILED: "register_screen_register_failed",
    },
    INPUT_PERSONAL_INFO_SCREEN: {
      START: "input_personal_info_screen_btnStart",
      BACK: "input_personal_info_screen_btnBack",
    },
    HOME_SCREEN: {
      PULL_REFRESH: "home_screen_pullRefresh",
      CREATE_FIRST_EXPENSE: "home_screen_create_first_expense",
      CREATE_FIRST_COMPANY: "home_screen_create_first_company",
    },
    EXPENSE_LISTING_SCREEN: {
      PULL_REFRESH: "expense_listing_screen_pullRefresh",
    },
    ADD_EXPENSE_SCREEN: {
      CHANGE_CATEGORY: "add_expense_screen_changeTypeCost",
      ADD_ATTACHMENT: "add_expense_screen_addAttachment",
      OPEN_GALLERY: "add_expense_screen_openGalleryScreen",
      OPEN_CAMERA: "add_expense_screen_openCameraScreen",
      COMPLETE_CREATE: "add_expense_screen_success",
      SELECT_INVOICE_TYPE: "add_expense_screen_selectInvoiceType",
      COMPLETE_UPDATE: "update_expense_screen_success",
      FAILED_UPDATE: "update_expense_screen_failed",
    },
    COMPANY_LISTING_SCREEN: {
      SHARE_COMPANY_INFO: "company_listing_screen_shareCompanyInfo",
      ADD_FIRST_COMPANY_INFO: "company_listing_screen_addFirstCompanyInfo",
    },
    COMPANY_INFO_FORM_SCREEN: {
      COMPLETE_CREATE_COMPANY_INFO: "company_info_form_screen_btnCompletedCreateCompanyInfo",
      COMPLETE_UPDATE_COMPANY_INFO: "company_info_form_screen_btnCompletedUpdateCompanyInfo",
    },
    REPORT_SCREEN: {
      SEND_REPORT_EMAIL: "send_report_email",
      SEND_REPORT_EMAIL_FAILED: "send_report_email_failed",
      REPORT_SAVE_DRAFT: "report_save_draft",
      COMPLETE_CREATE: "add_report_screen_success",
      COMPLETE_UPDATE: "update_report_screen_success",
    },
    UNCLAIMED_EXPENSE_TAB: {
      CLAIM_EXPENSE_SUCCESS: "unclaimed_expense_tab_claimExpenseSuccess",
    },
    AUTH: {
      LOG_IN: "auth_logged_in_successful",
      LOG_OUT: "auth_log_out",
    },
    ADD_REQUEST_SCREEN: {
      CHANGE_CATEGORY: "add_request_screen_changeTypeCost",
      ADD_ATTACHMENT: "add_request_screen_addAttachment",
      OPEN_GALLERY: "add_request_screen_openGalleryScreen",
      OPEN_CAMERA: "add_request_screen_openCameraScreen",
      COMPLETE_CREATE: "add_request_screen_success",
      SELECT_INVOICE_TYPE: "add_request_screen_selectInvoiceType",
      COMPLETE_UPDATE: "update_request_screen_success",
      FAILED_UPDATE: "update_request_screen_failed",
      FAILED_CREATE: "create_request_screen_failed",
      SENT_CLAIMED_SUCCESS: "sent_claimed_request_screen_success",
    },
    VAT_INFO_SCREEN: {
      PAGE_VIEW: "page_view",
      SHARE: "share",
    },
  },
};
