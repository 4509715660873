import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <G clipPath="url(#clip0_5284_330393)">
        <Path
          d="M8 14.667A6.667 6.667 0 118 1.333a6.667 6.667 0 010 13.334zm0-7.61L6.115 5.171l-.944.944L7.057 8 5.171 9.885l.944.944L8 8.943l1.885 1.886.944-.944L8.943 8l1.886-1.885-.944-.944L8 7.057z"
          fill="#E11934"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5284_330393">
          <Path fill="#fff" d="M0 0H16V16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
