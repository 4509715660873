// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { ComponentType } from "react";

import AccountDeletedScreen from "screens/Auth/AccountDeletedScreen";
import ForgotPasswordScreen from "screens/Auth/ForgotPasswordScreen";
import LoginOTPScreen from "screens/Auth/LoginOTPScreen";
import LoginScreen from "screens/Auth/LoginScreen";
import LoginWithSSOScreen from "screens/Auth/LoginWithSSOScreen";
import NewPasswordScreen from "screens/Auth/NewPasswordScreen";
import OTPScreen from "screens/Auth/OTPScreen";
import OnboardingScreen from "screens/Onboarding/OnboardingScreen";

import BizziQRScreen from "screens/CompanyTab/BizziQRScreen";
import CompanyDetailScreen from "screens/CompanyTab/CompanyDetailScreen";
import CompanyFormScreen from "screens/CompanyTab/CompanyFormScreen";
import NotificationScreen from "screens/Notification/NotificationScreen";
import ProfileDetailScreen from "screens/ProfileTab/ProfileDetailScreen/ProfileDetailScreen";

import NewReportScreen from "screens/Report/ReportCreateScreen/ReportCreateScreen";
import EditReportScreen from "screens/Report/ReportEditScreen/ReportEditScreen";
import ReportDetailScreen from "screens/Report/ReportDetailScreen/ReportDetailScreen";
import HistoryReportScreen from "screens/Report/HistoryReportScreen/HistoryReportScreen";

import CreateExpenseScreen from "screens/Expense/CreateExpenseScreen/CreateExpenseScreen";
import EditExpenseScreen from "screens/Expense/EditExpenseScreen/EditExpenseScreen";

import NewCompanyScreen from "screens/Auth/NewCompanyScreen";
import NewEmployeeInfoScreen from "screens/Auth/NewEmployeeInfo";

import CompanyScreen from "screens/CompanyTab/CompanyScreen";
import ProfileScreen from "screens/ProfileTab/ProfileScreen";
import UpdateAvatarScreen from "screens/ProfileTab/UpdateAvatarScreen";

import { StackNavigationOptions, TransitionPresets } from "@react-navigation/stack";
import SelectCompanyScreen from "app/screens/Company/SelectCompanyScreen/SelectCompanyScreen";
import SwitchCompanyScreen from "app/screens/Company/SwitchCompanyScreen/SwitchCompanyScreen";
import ForgotPasswordSuccessScreen from "screens/Auth/ForgotPasswordSuccessScreen";
import VerifyCompanyScreen from "screens/Auth/VerifyCompanyScreen";
import CameraScreen from "screens/CameraScreen";
import ImageBrowserScreen from "screens/ImageBrowser/ImageBrowserScreen";
import ImageGalleryScreen from "screens/ImageGalleryScreen";
import InboxScreen from "screens/Inbox/InboxScreen/InboxScreen";
import InvoiceDetailScreen from "screens/Inbox/InvoiceDetailScreen/InvoiceDetailScreen";

import SuggestionAddressScreen from "screens/SuggestionAddress";
import PolicyEmployeeScreen from "screens/PolicyEmployee/PolicyEmployeeScreen";

//request
import DetailRequestScreen from "screens/ExpenseRequest/ExpenseRequestDetailScreen/ExpenseRequestDetailScreen";

import HistoryRequestScreen from "screens/ExpenseRequest/HistoryRequestScreen/HistoryRequestScreen";
import NotFound from "screens/NotFound";
import LocalAuthenticationScreen from "screens/ProfileTab/LocalAuthenticationScreen";
import RegisterCompanyScreen from "screens/RegisterOnboarding/RegisterCompanyScreen";
import RegisterInformScreen from "screens/RegisterOnboarding/RegisterInformScreen";
import RegisterInviteColleagueScreen from "screens/RegisterOnboarding/RegisterInviteColleagueScreen";
import RegisterOTPScreen from "screens/RegisterOnboarding/RegisterOTPScreen";
import RegisterOnboardingScreen from "screens/RegisterOnboarding/RegisterOnboardingScreen";
import RegisterPasswordScreen from "screens/RegisterOnboarding/RegisterPasswordScreen";
import RegisterSetupCompanyScreen from "screens/RegisterOnboarding/RegisterSetupCompanyScreen";
import RegisterWarningEnabledExpenseScreen from "screens/RegisterOnboarding/RegisterWarningEnabledExpense";
import HomeBottomTabNavigator from "./HomeBottomTabNavigator";
import SCREEN_NAME, { TStackScreen } from "./ScreenName";
import { options } from "./Setting";

import { TFunction } from "react-i18next";
// import { COMPANIES, TCompaniesValue } from "constants/Companies";
import VatInfoScreen from "screens/VatInfo/VatInfoScreen";
import TransactionDetailScreen from "screens/Card/TransactionDetailScreen/TransactionDetailScreen";
import TransactionHistoryListScreen from "screens/Card/TransactionHistoryListScreen/TransactionHistoryListScreen";
import ExpenseDetailScreen from "screens/Expense/ExpenseDetailsScreen/ExpenseDetailsScreen";

import { AddAllowanceScreen } from "screens/ExpenseRequest/AddAllowanceScreen";
import CreateExpenseRequestScreen from "screens/ExpenseRequest/CreateExpenseRequestScreen/CreateExpenseRequestScreen";
import UpdateExpenseRequestScreen from "screens/ExpenseRequest/UpdateExpenseRequestScreen";
import ReportSearchScreen from "screens/Report/ReportSearchScreen/ReportSearchScreen";
import RequestSearchScreen from "screens/ExpenseRequest/RequestSearchScreen/RequestSearchScreen";
import EmailDetailScreen from "screens/Inbox/EmailDetailScreen/EmailDetailScreen";
import WaitingApprovalScreen from "screens/WaitingApproval/WaitingApprovalList/WaitingApprovalScreen";
import WaitingApprovalSearchScreen from "screens/WaitingApproval/WaitingApprovalSearchScreen/WaitingApprovalSearchScreen";
import ExpenseOverviewScreen from "screens/ExpenseOverview/ExpenseOverviewScreen";
import ExpenseInsightScreen from "screens/ExpenseInsight/ExpenseInsightScreen";
import DashboardDetailScreen from "screens/ExpenseOverview/DashboardDetailScreen/DashboardDetailScreen";
import ApprovalFlowScreen from "screens/ApprovalFlowScreen/ApprovalFlowScreen";
import AttachmentFileScreen from "screens/AttachmentFileScreen/AttachmentFileScreen";
import CommentScreen from "screens/CommentScreen/CommentScreen";
import ExpenseSearchScreen from "screens/Expense/ExpenseSearchScreen/ExpenseSearchScreen";
import ScanQrCodeScreen from "screens/ScanQrCodeScreen/ScanQrCodeScreen";
import FlightSearchScreen from "screens/FlightBooking/FlightSearchScreen/FlightSearchScreen";
import PassengerInfoScreen from "screens/FlightBooking/PassengerInfoScreen/PassengerInfoScreen";
import LuggageScreen from "screens/FlightBooking/LuggageScreen/LuggageScreen";
import ReservationOverviewScreen from "screens/FlightBooking/ReservationOverviewScreen/ReservationOverviewScreen";
import CheckoutScreen from "screens/FlightBooking/CheckoutScreen/CheckoutScreen";
import FlightTicketDetailScreen from "screens/FlightBooking/FlightTicketDetailScreen/FlightTicketDetailScreen";
import ReportBatchApprovalScreen from "screens/Report/ReportListScreen/ReportBatchApprovalScreen";
import RequestBatchApprovalScreen from "screens/ExpenseRequest/ExpenseRequestListScreen/RequestBatchApprovalScreen";
import StartGuideScreen from "screens/StartGuideScreen/StartGuideScreen";
import ActionItemDetailScreen from "screens/StartGuideScreen/ActionItemDetailScreen/ActionItemDetailScreen";
import DetailAllowanceScreen from "screens/ExpenseRequest/DetailAllowanceScreen/DetailAllowanceScreen";
import AllocatedExpenseScreen from "screens/Expense/AllocatedExpenseScreen/AllocatedExpenseScreen";
import LoginWithZaloScreen from "screens/LoginWithZalo/LoginWithZaloScreen";
import InvoiceSearchScreen from "screens/Inbox/InvoiceSearchScreen/InvoiceSearchScreen";

// advance
interface IStack {
  name: TStackScreen;
  component: ComponentType<any>;
  options?: StackNavigationOptions | ((params: any) => StackNavigationOptions);
}

export const STACK_PUBLIC_WEB = [
  {
    name: SCREEN_NAME.VatInfoScreen,
    component: VatInfoScreen,
    options: {
      headerShown: false,
      title: "Thông tin xuất hoá đơn",
    },
  },
  {
    name: SCREEN_NAME.LoginWithZaloScreen,
    component: LoginWithZaloScreen,
    options: {
      headerShown: false,
      title: "Đăng nhập với Zalo",
    },
  },
];
const TransitionNavigateDefault = TransitionPresets.SlideFromRightIOS;

export const STACK_LOGIN = ({ t }) => [
  {
    name: SCREEN_NAME.SelectCompanyScreen,
    component: SelectCompanyScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("SelectCompanyScreen"),
    }),
  },
  {
    name: SCREEN_NAME.HomeBottomTabNavigator,
    component: HomeBottomTabNavigator,
    options: {
      // disable navigate animation when replace Select Company Screen to Home Screen
      transitionSpec: {
        open: { animation: "timing", config: { duration: 0 } },
        close: { animation: "timing", config: { duration: 0 } },
      },
      headerShown: false,
    },
  },
  {
    name: SCREEN_NAME.CompanyFormScreen,
    component: CompanyFormScreen,
    options: (params: any) => options(params, t),
  },
  {
    name: SCREEN_NAME.CompanyDetailScreen,
    component: CompanyDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("CompanyDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.BizziQRScreen,
    component: BizziQRScreen,
    options: () => ({
      ...TransitionPresets.FadeFromBottomAndroid,
      headerShown: false,
      title: t("BizziQRScreen"),
    }),
  },
  {
    name: SCREEN_NAME.EditExpenseScreen,
    component: EditExpenseScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("EditExpenseScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ExpenseDetailScreen,
    component: ExpenseDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("ExpenseDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ProfileDetailScreen,
    component: ProfileDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("ProfileDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.NotificationScreen,
    component: NotificationScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("NotificationScreen"),
    }),
  },
  {
    name: SCREEN_NAME.InboxScreen,
    component: InboxScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: false,
      title: t("InboxScreen"),
    },
  },
  {
    name: SCREEN_NAME.ProfileScreen,
    component: ProfileScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("ProfileScreen"),
    }),
  },
  {
    name: SCREEN_NAME.UpdateAvatarScreen,
    component: UpdateAvatarScreen,
    options: () => ({
      headerShown: false,
      ...TransitionPresets.ModalPresentationIOS,
      title: t("UpdateAvatarScreen"),
    }),
  },
  {
    name: SCREEN_NAME.EmailDetailScreen,
    component: EmailDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("InboxItemDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.NewExpenseScreen,
    component: CreateExpenseScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("NewExpenseScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ReportDetailScreen,
    component: ReportDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("ReportDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.NewReportScreen,
    component: NewReportScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("NewReportScreen"),
    }),
  },
  {
    name: SCREEN_NAME.EditReportScreen,
    component: EditReportScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("EditReportScreen"),
    }),
  },
  {
    name: SCREEN_NAME.NewCompanyScreen,
    component: NewCompanyScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("NewCompanyScreen"),
    }),
  },
  {
    name: SCREEN_NAME.CompanyScreen,
    component: CompanyScreen,
    options: (params: any) => options(params, t),
  },
  {
    name: SCREEN_NAME.ImageBrowserScreen,
    component: ImageBrowserScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("ImageBrowserScreen"),
    }),
  },
  {
    name: SCREEN_NAME.CameraScreen,
    component: CameraScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("CameraScreen"),
    }),
  },
  {
    name: SCREEN_NAME.InvoiceDetailScreen,
    component: InvoiceDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("InvoiceDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ImageGalleryScreen,
    component: ImageGalleryScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("ImageGalleryScreen"),
    }),
  },
  {
    name: SCREEN_NAME.SuggestionAddressScreen,
    component: SuggestionAddressScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("SuggestionAddressScreen"),
    }),
  },
  {
    name: SCREEN_NAME.HistoryReportScreen,
    component: HistoryReportScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("HistoryReportScreen"),
    }),
  },
  {
    name: SCREEN_NAME.WaitingApprovalScreen,
    component: WaitingApprovalScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("WaitingApprovalScreen"),
    }),
  },
  {
    name: SCREEN_NAME.NewRequestScreen,
    component: CreateExpenseRequestScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("NewRequestScreen"),
    }),
  },
  {
    name: SCREEN_NAME.DetailRequestScreen,
    component: DetailRequestScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("DetailRequestScreen"),
    }),
  },
  {
    name: SCREEN_NAME.EditRequestScreen,
    component: UpdateExpenseRequestScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("EditRequestScreen"),
    }),
  },
  {
    name: SCREEN_NAME.HistoryRequestScreen,
    component: HistoryRequestScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("HistoryRequestScreen"),
    }),
  },
  {
    name: SCREEN_NAME.LocalAuthenticationScreen,
    component: LocalAuthenticationScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("LocalAuthenticationScreen"),
    }),
  },
  {
    name: SCREEN_NAME.RegisterCompanyScreen,
    component: RegisterCompanyScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterCompanyScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterSetupCompanyScreen,
    component: RegisterSetupCompanyScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterSetupCompanyScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterInviteColleagueScreen,
    component: RegisterInviteColleagueScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterInviteColleagueScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterWarningEnabledExpenseScreen,
    component: RegisterWarningEnabledExpenseScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterWarningEnabledExpenseScreen),
    },
  },
  {
    name: SCREEN_NAME.AddAllowanceScreen,
    component: AddAllowanceScreen,
    options: {
      ...TransitionNavigateDefault,
    },
  },
  {
    name: SCREEN_NAME.SwitchCompanyScreen,
    component: SwitchCompanyScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("SwitchCompanyScreen"),
    }),
  },
  {
    name: SCREEN_NAME.TransactionHistoryListScreen,
    component: TransactionHistoryListScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("TransactionHistoryListScreen"),
    }),
  },
  {
    name: SCREEN_NAME.TransactionDetailScreen,
    component: TransactionDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("TransactionDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ReportSearchScreen,
    component: ReportSearchScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: false,
      title: t("ReportSearchScreen"),
    },
  },
  {
    name: SCREEN_NAME.WaitingApprovalSearchScreen,
    component: WaitingApprovalSearchScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: false,
      title: t("WaitingApprovalSearchScreen"),
    },
  },
  {
    name: SCREEN_NAME.RequestSearchScreen,
    component: RequestSearchScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: false,
      title: t("RequestSearchScreen"),
    },
  },
  {
    name: SCREEN_NAME.ExpenseSearchScreen,
    component: ExpenseSearchScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: false,
      title: t("ExpenseSearchScreen"),
    },
  },
  {
    name: SCREEN_NAME.PolicyEmployeeScreen,
    component: PolicyEmployeeScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: true,
      title: "",
    },
  },
  {
    name: SCREEN_NAME.ExpenseOverviewScreen,
    component: ExpenseOverviewScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: true,
      title: "Báo cáo chi tiêu",
    },
  },
  {
    name: SCREEN_NAME.ExpenseInsightScreen,
    component: ExpenseInsightScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: true,
      title: t("ExpenseOverviewScreen"),
    },
  },
  {
    name: SCREEN_NAME.DashboardDetailScreen,
    component: DashboardDetailScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: false,
      title: t("CategoryDetailChartScreen"),
    },
  },
  {
    name: SCREEN_NAME.ApprovalFlowScreen,
    component: ApprovalFlowScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: true,
      title: t("ApprovalFlowScreen"),
    },
  },
  {
    name: SCREEN_NAME.AttachmentFileScreen,
    component: AttachmentFileScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: true,
    },
  },
  {
    name: SCREEN_NAME.CommentScreen,
    component: CommentScreen,
    options: {
      ...TransitionNavigateDefault,
      title: t("CommentScreen"),
      headerShown: true,
    },
  },
  {
    name: SCREEN_NAME.ScanQrCodeScreen,
    component: ScanQrCodeScreen,
    options: {
      ...TransitionNavigateDefault,
      headerShown: false,
    },
  },
  {
    name: SCREEN_NAME.FlightSearchScreen,
    component: FlightSearchScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("FlightSearchScreen"),
    }),
  },
  {
    name: SCREEN_NAME.PassengerInfoScreen,
    component: PassengerInfoScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("PassengerInfoScreen"),
    }),
  },
  {
    name: SCREEN_NAME.LuggageScreen,
    component: LuggageScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("LuggageScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ReservationOverviewScreen,
    component: ReservationOverviewScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("ReservationOverviewScreen"),
    }),
  },
  {
    name: SCREEN_NAME.CheckoutScreen,
    component: CheckoutScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("CheckoutScreen"),
    }),
  },
  {
    name: SCREEN_NAME.FlightTicketDetailScreen,
    component: FlightTicketDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("FlightTicketDetailScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ReportBatchApprovalScreen,
    component: ReportBatchApprovalScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("ReportBatchApprovalScreen"),
    }),
  },
  {
    name: SCREEN_NAME.RequestBatchApprovalScreen,
    component: RequestBatchApprovalScreen,
    options: (params: any) => ({
      ...options(params, t),
      headerShown: false,
      title: t("RequestBatchApprovalScreen"),
    }),
  },
  {
    name: SCREEN_NAME.StartGuideScreen,
    component: StartGuideScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("StartGuideScreen"),
    }),
  },
  {
    name: SCREEN_NAME.ActionItemDetailScreen,
    component: ActionItemDetailScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("StartGuideScreen"),
    }),
  },
  {
    name: SCREEN_NAME.DetailAllowanceScreen,
    component: DetailAllowanceScreen,
    options: {
      ...TransitionNavigateDefault,
      title: t("DetailAllowanceScreen"),
    },
  },
  {
    name: SCREEN_NAME.AllocatedExpenseScreen,
    component: AllocatedExpenseScreen,
    options: (params: any) => ({
      ...options(params, t),
      title: t("AllocatedExpenseScreen"),
    }),
  },
  {
    name: SCREEN_NAME.InvoiceSearchScreen,
    component: InvoiceSearchScreen,
    options: () => ({
      ...TransitionNavigateDefault,
      headerShown: false,
      title: t("InvoiceSearchScreen"),
    }),
  },
];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const STACK_ONBOARD_WEB = (t: TFunction<"navigation/AppStack", undefined>) => [
  {
    name: SCREEN_NAME.OnboardingScreen,
    component: OnboardingScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: SCREEN_NAME.RegisterOnboardingScreen,
    component: RegisterOnboardingScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterOnboardingScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterOTPScreen,
    component: RegisterOTPScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterOTPScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterPasswordScreen,
    component: RegisterPasswordScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterPasswordScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterCompanyScreen,
    component: RegisterCompanyScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterCompanyScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterSetupCompanyScreen,
    component: RegisterSetupCompanyScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterSetupCompanyScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterInviteColleagueScreen,
    component: RegisterInviteColleagueScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterInviteColleagueScreen),
    },
  },
  {
    name: SCREEN_NAME.RegisterInformScreen,
    component: RegisterInformScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterInformScreen),
    },
  },
  {
    name: SCREEN_NAME.NotFound,
    component: NotFound,
    options: {
      headerShown: false,
      title: "Not found",
    },
  },
  {
    name: SCREEN_NAME.RegisterWarningEnabledExpenseScreen,
    component: RegisterWarningEnabledExpenseScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.RegisterWarningEnabledExpenseScreen),
    },
  },
];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const STACK_NOT_LOGIN = (t: TFunction<"navigation/AppStack", undefined>) => [
  {
    name: SCREEN_NAME.LoginScreen,
    component: LoginScreen,
    options: {
      headerShown: false,
      title: t(SCREEN_NAME.LoginScreen),
    },
  },
  {
    name: SCREEN_NAME.LoginWithSSOScreen,
    component: LoginWithSSOScreen,
    options: {
      headerShown: false,
      title: "Login With SSO",
      ...TransitionPresets.ModalPresentationIOS,
    },
  },
  {
    name: SCREEN_NAME.ForgotPasswordScreen,
    component: ForgotPasswordScreen,
    options: {
      headerShown: false,
      title: t("ForgotPasswordScreen"),
    },
  },
  {
    name: SCREEN_NAME.ForgotPasswordSuccessScreen,
    component: ForgotPasswordSuccessScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: SCREEN_NAME.LoginOTPScreen,
    component: LoginOTPScreen,
    options: {
      headerShown: false,
      title: t("LoginOTPScreen"),
    },
  },
  {
    name: SCREEN_NAME.AccountDeletedScreen,
    component: AccountDeletedScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: SCREEN_NAME.OTPScreen,
    component: OTPScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: SCREEN_NAME.NewPasswordScreen,
    component: NewPasswordScreen,
    options: (params: any) => options(params, t),
  },
  {
    name: SCREEN_NAME.NewCompanyScreen,
    component: NewCompanyScreen,
    options: {
      headerShown: false,
    },
  },
  {
    name: SCREEN_NAME.VerifyCompanyScreen,
    component: VerifyCompanyScreen,
    options: (params: any) => options(params, t),
  },
  {
    name: SCREEN_NAME.NewEmployeeInfoScreen,
    component: NewEmployeeInfoScreen,
    options: {
      headerShown: false,
    },
  },
  ...STACK_ONBOARD_WEB(t),
];
