import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { calculateDurationInDays, formatTime, getPolicyTextBooking, getTitleFromLocation, getTitleI18n } from "utils";
import { Linking } from "react-native";

const ScreenShotInfo = forwardRef<any, any>(({ expenseRequest, bookingSections }, ref) => {
  useImperativeHandle(ref, () => ({
    share: handleViewShot,
  }));
  const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen/ExpenseRequestDetailScreen");
  const handleViewShot = () => {
    const expenseTravel = expenseRequest?.expenseTravels?.[0];
    let message = `[${expenseRequest?.docSequence}] ${expenseRequest?.title}`;
    //"%0D%0A": break line character on web;
    /*-- travel info --*/
    message += `%0D%0A%0D%0A${t("detail_request_info")}`;
    message += `%0D%0A- ${t("location_from")}: ${getTitleFromLocation(expenseTravel?.fromLocation)} - ${t(
      "to"
    )}: ${getTitleFromLocation(expenseTravel?.toLocation)}`;
    message += `%0D%0A- ${
      expenseTravel?.travelDuration > 1
        ? t("times", { day: expenseTravel?.travelDuration })
        : t("time", { day: expenseTravel?.travelDuration })
    }: ${
      expenseTravel?.travelDuration > 1
        ? `${formatTime(expenseTravel?.fromDate, "ddd, DD/MM/YYYY")} - ${formatTime(
            expenseTravel?.toDate,
            "ddd, DD/MM/YYYY"
          )}`
        : formatTime(expenseTravel?.fromDate, "ddd, DD/MM/YYYY")
    }`;
    message += "%0D%0A%0D%0A";
    /*-- end --*/
    /*-- flight booking--*/
    if (bookingSections.ExpFlightBooking?.length) {
      const bookingSettings = bookingSections.ExpFlightBooking[0].bookingSettings;
      message += `${t("airplane_ticket_info")}`;
      message += `%0D%0A- ${t("location_from")}: ${bookingSettings?.departure?.station?.code} - ${getTitleI18n({
        vi: bookingSettings?.departure?.station?.name,
        en: bookingSettings?.departure?.station?.nameEn,
      })} - ${t("to")}: ${bookingSettings?.arrival?.station?.code} - ${getTitleI18n({
        vi: bookingSettings?.arrival?.station?.name,
        en: bookingSettings?.arrival?.station?.nameEn,
      })}`;
      message += `%0D%0A- ${t("departure_date")}: ${formatTime(bookingSettings.departureDate, "ddd, DD/MM/YYYY")}`;
      if (bookingSettings?.departureNote) {
        message += `%0D%0A- ${t("departure_note")}: ${bookingSettings.departureNote}`;
      }
      if (bookingSettings?.isRoundTrip) {
        message += `%0D%0A- ${t("return_date")}: ${formatTime(bookingSettings.returnDate, "ddd, DD/MM/YYYY")}`;
      }
      if (bookingSettings?.returnNote) {
        message += `%0D%0A- ${t("return_note")}: ${bookingSettings.returnNote}`;
      }
      message += `%0D%0A- ${t("policy")}:%0D%0A`;
      const flightBookingPolicy = bookingSections.ExpFlightBooking?.[0]?.bookingPolicies;
      message += `${getPolicyTextBooking(flightBookingPolicy, t)}%0D%0A%0D%0A`;
    }
    /*-- end --*/
    /*-- bus booking--*/
    if (bookingSections.ExpTransportationBooking?.length) {
      const bookingSettings = bookingSections.ExpTransportationBooking[0].bookingSettings;
      message += `${t("bus_ticket_info")}`;
      message += `%0D%0A- ${t("location_from")}: ${getTitleFromLocation(bookingSettings?.departure?.location)} - ${t(
        "to"
      )}: ${getTitleFromLocation(bookingSettings?.arrival?.location)}`;
      message += `%0D%0A- ${t("departure_date")}: ${formatTime(bookingSettings.departureDate, "ddd, DD/MM/YYYY")}`;
      if (bookingSettings?.departureNote) {
        message += `%0D%0A- ${t("departure_note")}: ${bookingSettings.departureNote}`;
      }
      if (bookingSettings?.isRoundTrip) {
        message += `%0D%0A- ${t("return_date")}: ${formatTime(bookingSettings.returnDate, "ddd, DD/MM/YYYY")}`;
      }
      if (bookingSettings?.returnNote) {
        message += `%0D%0A- ${t("return_note")}: ${bookingSettings.returnNote}`;
      }
      message += `%0D%0A- ${t("policy")}:%0D%0A`;
      const busBookingPolicy = bookingSections.ExpTransportationBooking?.[0]?.bookingPolicies;
      message += `${getPolicyTextBooking(busBookingPolicy, t)}%0D%0A%0D%0A`;
    }
    /*-- end --*/
    /*-- hotel booking--*/
    if (bookingSections.ExpHotelBooking?.length) {
      const bookingSettings = bookingSections.ExpHotelBooking[0].bookingSettings;
      message += `${t("hotel_info")}`;
      message += `%0D%0A- ${t("location_to")}: ${getTitleFromLocation(bookingSettings?.location)}`;
      message += `%0D%0A- ${t("duration_of_stay", {
        days: calculateDurationInDays(bookingSettings.checkInDate, bookingSettings.checkOutDate),
      })}: ${formatTime(bookingSettings.checkInDate, "ddd, DD/MM/YYYY")} - ${formatTime(
        bookingSettings.checkOutDate,
        "ddd, DD/MM/YYYY"
      )}`;
      if (bookingSettings?.note) {
        message += `%0D%0A- ${t("note")}: ${bookingSettings.note}`;
      }
      message += `%0D%0A- ${t("policy")}:%0D%0A`;
      const hotelBookingPolicy = bookingSections.ExpHotelBooking?.[0]?.bookingPolicies;
      message += `${getPolicyTextBooking(hotelBookingPolicy, t)}`;
    }

    Linking.openURL(`mailto:?body=${message}`).catch((error) => {
      console.log(error);
    });
  };
  return null;
});
export default ScreenShotInfo;
