import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ActivityIndicator,
  Keyboard,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";

import {
  AppText,
  CheckboxSelect,
  EmptyData,
  FlatListCustom,
  IconCustom,
  RadioSelectFilter,
  SearchInput,
  SelectCompanyTeam,
  SelectDate,
  SkeletonListLoading,
  ToggleCheck,
} from "components";
import { AdvanceRequestIcon, BackButtonIcon, NoAdvanceRequestIcon, SearchNotFoundIcon } from "assets/images/svg/icons";
import isNil from "lodash/isNil";
import { Colors, Fonts } from "theme";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { FilterData } from "./types";
import SelectEmployee from "../../../components/AdvanceSearch/SelectEmployee";
import { CONSTANTS, REPORT_STATUS_FILTER_REQUEST, REQUEST_STATUS, REQUEST_TYPE } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import SCREEN_NAME from "navigation/ScreenName";
import useExpenseRequestSearchQuery from "hooks/request/useExpenseRequestSearchQuery";
import ExpenseRequestItem from "components/ExpenseRequestItem";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { goBack } from "navigation/RootNavigation";
import { NetworkStatus } from "@apollo/client";
import { REQUEST_SORT_OPTIONS } from "screens/ExpenseRequest/ExpenseRequestListScreen/types";
import { getRequestIconByType } from "screens/ExpenseRequest/helper";
import { buildRequestSearchFilterCondition } from "./helper";
import SelectTotalAmountWithVat from "components/AdvanceSearch/SelectTotalAmountWithVat";
import { REQUEST_PAYMENT_STATUS } from "screens/ExpenseRequest/constants";
import { executeRouteFunction } from "utils/route";

const getStatusList = (t): SelectItem[] => [
  { label: t("draft"), value: REQUEST_STATUS.DRAFT },
  {
    label: t("submitted"),
    value: REQUEST_STATUS.SUBMITTED,
  },
  { label: t("approval"), value: REQUEST_STATUS.APPROVED },
  { label: t("rejected"), value: REQUEST_STATUS.REJECTED },
  { label: t("cancelled"), value: REQUEST_STATUS.CANCELLED },
];

const getPaymentStatusList = (t): SelectItem[] => [
  { label: t("paid"), value: REQUEST_PAYMENT_STATUS.PAID },
  {
    label: t("unpaid"),
    value: REQUEST_PAYMENT_STATUS.PENDING,
  },
];

const getComparisonOptions = (t): SelectItem[] => [
  { label: t("has_price_comparison"), value: "has_price_comparison" },
  {
    label: t("no_price_comparison"),
    value: "no_price_comparison",
  },
];
const getReportStatusOptions = (t): SelectItem[] => [
  {
    label: t("no_report"),
    value: REPORT_STATUS_FILTER_REQUEST.NOT_YET,
  },
  {
    label: t("report_draft"),
    value: REPORT_STATUS_FILTER_REQUEST.DRAFT,
  },
  {
    label: t("report_submitted"),
    value: REPORT_STATUS_FILTER_REQUEST.SUBMITTED,
  },
  {
    label: t("report_approved"),
    value: REPORT_STATUS_FILTER_REQUEST.APPROVED,
  },
  {
    label: t("report_rejected"),
    value: REPORT_STATUS_FILTER_REQUEST.REJECTED,
  },
  {
    label: t("report_cancelled"),
    value: REPORT_STATUS_FILTER_REQUEST.CANCELLED,
  },
];

const sortOptions = (t) =>
  [
    { label: t("created_at_desc"), value: REQUEST_SORT_OPTIONS.CREATED_AT_DESC },
    { label: t("created_at_asc"), value: REQUEST_SORT_OPTIONS.CREATED_AT_ASC },
    { label: t("requested_at_desc"), value: REQUEST_SORT_OPTIONS.REQUESTED_AT_DESC },
    { label: t("requested_at_asc"), value: REQUEST_SORT_OPTIONS.REQUESTED_AT_ASC },
    {
      label: t("expense_asc"),
      value: REQUEST_SORT_OPTIONS.AMOUNT_ASC,
    },
    {
      label: t("expense_desc"),
      value: REQUEST_SORT_OPTIONS.AMOUNT_DESC,
    },
  ].reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
  }, {});

const RequestSearchScreen = ({ route }) => {
  const { awaitingMyApproval, fromScreen, iApprovedOrRejected, employee } = route?.params;
  const fromIsHistoryScreen = fromScreen === SCREEN_NAME.HistoryRequestScreen;
  const { t } = useTranslation("app/screens/ExpenseRequest/RequestSearchScreen");
  const [keyword, setKeyword] = useState("");
  const { top } = useSafeAreaInsets();
  const searchInputRef = useRef<TextInput>();
  const flashListRef = useRef<FlashList<any>>(null);
  const disableLoadMore = useRef(true);
  const [FEATURE_EXP_CASH_ADVANCE, FEATURE_PROCUREMENT] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_EXP_CASH_ADVANCE,
    FEATURE_FLAGS.FEATURE_PROCUREMENT,
  ]);
  const [filter, setFilter] = useState<FilterData>({
    awaitingMyApproval,
    iApprovedOrRejected,
    employee,
    sort: awaitingMyApproval
      ? sortOptions(t)[REQUEST_SORT_OPTIONS.REQUESTED_AT_ASC]
      : sortOptions(t)[REQUEST_SORT_OPTIONS.CREATED_AT_DESC],
  });
  const debounceSearchTimeoutId = useRef(null);
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const [_, { data, loading, refetch, fetchMore, networkStatus }] = useExpenseRequestSearchQuery({
    limit: 10,
    offset: 0,
    sortOption: filter?.sort?.value,
    where: buildRequestSearchFilterCondition({
      companyId,
      employeeId,
      keyword,
      filters: filter,
    }),
  });
  const requestList = data?.expMobileExpenseRequests?.requests ?? [];
  const total = data?.expMobileExpenseRequests?.total ?? 0;

  const isEnabledPurchaseRequest = FEATURE_PROCUREMENT;
  const expenseRequestTypeOptions: SelectItem[] = useMemo(() => {
    return [
      { label: t("travel"), value: REQUEST_TYPE.travel, leftIcon: getRequestIconByType(REQUEST_TYPE.travel) },
      { label: t("other"), value: REQUEST_TYPE.other, leftIcon: getRequestIconByType(REQUEST_TYPE.other) },
      isEnabledPurchaseRequest && {
        label: t("purchase"),
        value: REQUEST_TYPE.purchase,
        leftIcon: getRequestIconByType(REQUEST_TYPE.purchase),
      },
      isEnabledPurchaseRequest && {
        label: t("price_comparison"),
        value: REQUEST_TYPE.price_comparison,
        leftIcon: getRequestIconByType(REQUEST_TYPE.price_comparison),
      },
    ].filter(Boolean);
  }, [t, isEnabledPurchaseRequest]);

  const isFiltering =
    keyword.trim() ||
    [
      filter?.paidStatus,
      filter?.expenseRequestType,
      filter?.companyTeam,
      filter?.fromDate,
      filter?.toDate,
      filter?.status?.length,
      filter?.employee,
      filter?.awaitingMyApproval,
      filter?.iApprovedOrRejected,
      filter?.createdDate?.fromDate && filter?.createdDate?.toDate,
      filter?.type,
      filter?.reportStatus,
      !isNil(filter.totalAmount?.min) || !isNil(filter.totalAmount?.max),
      !isNil(filter.cashAdvanceTotalAmount?.min) || !isNil(filter.cashAdvanceTotalAmount?.max),
      filter?.priceComparison,
      filter?.paymentStatus,
    ].some(Boolean);

  useEffect(() => {
    if (isFiltering) {
      disableLoadMore.current = true;
      refetch?.();
      setTimeout(() => {
        disableLoadMore.current = false;
      }, 1000);
    }
  }, [filter, keyword]);
  const onChangeFilter = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      setKeyword(text);
    }, 400);
  };
  const onResetSearch = () => {
    setKeyword("");
    searchInputRef?.current?.setNativeProps({ text: "" });
  };
  const onClearFilter = () => {
    setKeyword("");
    setFilter(null);
  };
  const renderEmpty = () => {
    return <EmptyData icon={<SearchNotFoundIcon />} title={t("search_not_found")} />;
  };
  const onRefreshDataWhenGoBack = () => {
    flashListRef.current?.scrollToOffset({ animated: false, offset: 0 });
    refetch?.();
    executeRouteFunction(route, "onRefreshData");
  };
  const renderItem = ({ item, index }) => (
    <ExpenseRequestItem
      item={item}
      isLast={index === total - 1}
      showCreator={filter?.awaitingMyApproval}
      showApprovalFlow={
        !filter?.awaitingMyApproval ? [REQUEST_STATUS.SUBMITTED, REQUEST_STATUS.REJECTED].includes(item.status) : false
      }
      isRejected={item.status === REQUEST_STATUS.REJECTED}
      onRefreshData={onRefreshDataWhenGoBack}
    />
  );
  const renderFooter = () => {
    if (loading && requestList.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const handleLoadMore = () => {
    if (loading || disableLoadMore.current || requestList?.length === 0 || requestList?.length >= total) {
      return;
    }
    disableLoadMore.current = true;
    fetchMore?.({
      variables: { offset: requestList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        disableLoadMore.current = false;
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expMobileExpenseRequests: {
            requests: [...prev.expMobileExpenseRequests.requests, ...fetchMoreResult.expMobileExpenseRequests.requests],
            total: fetchMoreResult.expMobileExpenseRequests.total,
          },
        };
      },
    });
  };
  return (
    <View style={styles.container}>
      <View style={[styles.headerContainer, { paddingTop: top || 20 }]}>
        <View style={styles.searchContainer}>
          <TouchableOpacity style={{ marginTop: 6 }} onPress={goBack}>
            <BackButtonIcon />
          </TouchableOpacity>
          <SearchInput
            numberOfLines={1}
            ref={searchInputRef}
            style={styles.searchInput}
            placeholder={t("search")}
            onChangeText={onChangeSearch}
            autoCapitalize="none"
            placeholderTextColor={Colors.grayscale60}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            right={
              keyword ? (
                <TouchableOpacity onPress={onResetSearch}>
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : null
            }
          />
        </View>
        <ScrollView keyboardShouldPersistTaps="always" horizontal showsHorizontalScrollIndicator={false}>
          <View style={styles.searchItemContainer}>
            {!fromIsHistoryScreen ? (
              <ToggleCheck
                value={filter?.awaitingMyApproval}
                setValue={(value) => onChangeFilter("awaitingMyApproval", value)}
                title={t("waiting_for_your_approval")}
              />
            ) : (
              <ToggleCheck
                value={filter?.iApprovedOrRejected}
                setValue={(value) => onChangeFilter("iApprovedOrRejected", value)}
                title={t("me_approved_or_rejected")}
              />
            )}
            <SelectEmployee
              value={filter?.employee}
              setValue={(value) => onChangeFilter("employee", value)}
              title={t("employee")}
            />
            <CheckboxSelect
              title={t("status")}
              data={getStatusList(t)}
              value={filter?.status as SelectItem[]}
              setValue={(value) => onChangeFilter("status", value)}
            />
            <RadioSelectFilter
              title={t("payment_status")}
              data={getPaymentStatusList(t)}
              value={filter?.paymentStatus}
              setValue={(value) => onChangeFilter("paymentStatus", value)}
            />
            {isEnabledPurchaseRequest && (
              <RadioSelectFilter
                title={t("price_comparison_title")}
                data={getComparisonOptions(t)}
                value={filter?.priceComparison}
                setValue={(value) => onChangeFilter("priceComparison", value)}
              />
            )}
            <SelectTotalAmountWithVat
              minWidth={130}
              title={t("total_amount")}
              value={filter.totalAmount}
              setValue={(value) => onChangeFilter("totalAmount", value)}
            />
            <SelectTotalAmountWithVat
              minWidth={130}
              title={t("cash_advance_total_amount")}
              value={filter.cashAdvanceTotalAmount}
              setValue={(value) => onChangeFilter("cashAdvanceTotalAmount", value)}
            />
            <SelectDate
              title={t("created_date")}
              labelDateValue={filter?.createdDate?.labelDateValue}
              setValue={(label, from, to) => {
                onChangeFilter("createdDate", { fromDate: from, toDate: to, labelDateValue: label });
              }}
            />
            <SelectDate
              title={t("submitted_date")}
              labelDateValue={filter?.labelDateValue}
              setValue={(label, from, to) => {
                onChangeFilter("fromDate", from);
                onChangeFilter("toDate", to);
                onChangeFilter("labelDateValue", label);
              }}
            />
            <SelectCompanyTeam
              title={t("unit")}
              value={filter?.companyTeam}
              setValue={(value) => onChangeFilter("companyTeam", value)}
            />
            <RadioSelectFilter
              title={t("expense_request_type")}
              data={expenseRequestTypeOptions}
              value={filter?.expenseRequestType}
              setValue={(value) => onChangeFilter("expenseRequestType", value)}
            />
            {FEATURE_EXP_CASH_ADVANCE && (
              <RadioSelectFilter
                title={t("advance")}
                data={[
                  {
                    label: t("advance_request"),
                    value: "ADVANCE",
                    leftIcon: <AdvanceRequestIcon />,
                  },
                  {
                    label: t("no_advance_request"),
                    value: "NO_ADVANCE",
                    leftIcon: <NoAdvanceRequestIcon />,
                  },
                ]}
                value={filter?.type}
                setValue={(value) => onChangeFilter("type", value)}
              />
            )}
            <RadioSelectFilter
              minWidth={145}
              title={t("report_status")}
              data={getReportStatusOptions(t)}
              value={filter?.reportStatus}
              setValue={(value) => onChangeFilter("reportStatus", value)}
            />
            <RadioSelectFilter
              title={t("closed_status")}
              data={[
                {
                  label: t("open"),
                  value: "OPEN",
                  leftIcon: <IconCustom name="lock-open-right" />,
                },
                {
                  label: t("closed"),
                  value: "CLOSED",
                  leftIcon: <IconCustom name="lock" />,
                },
              ]}
              value={filter?.paidStatus}
              setValue={(value) => onChangeFilter("paidStatus", value)}
            />
            <RadioSelectFilter
              showClearFilter={false}
              showClearButton={false}
              title={t("sort_by")}
              minWidth={100}
              data={Object.values(sortOptions(t))}
              value={filter?.sort}
              setValue={(value) => onChangeFilter("sort", value)}
            />
            <TouchableOpacity onPress={onClearFilter}>
              <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                {t("clear_filter")}
              </AppText>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
      {!isFiltering ? (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.emptyContainer}>
            <EmptyData icon={<SearchNotFoundIcon />} title={t("search_title")} description={t("search_description")} />
          </View>
        </TouchableWithoutFeedback>
      ) : (loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={{ marginTop: 5 }} />
      ) : (
        <FlatListCustom
          ref={flashListRef}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.contentContainer}
          data={requestList}
          renderItem={renderItem}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
          ListEmptyComponent={renderEmpty}
          keyExtractor={(item, index) => item?.expenseReportId ?? index}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.2}
          ListFooterComponent={renderFooter}
          estimatedItemSize={88}
        />
      )}
    </View>
  );
};
export default RequestSearchScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingTop: 10, paddingBottom: 20, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  headerContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 4,
  },
  searchContainer: {
    paddingRight: 20,
    paddingLeft: 18,
    justifyContent: "space-between",
    gap: 8,
    alignItems: "center",
    flexDirection: "row",
  },
  searchInput: { ...Fonts.BodySmall, flex: 1, color: Colors.grayscale100, backgroundColor: Colors.white },
  searchItemContainer: {
    flexDirection: "row",
    gap: 8,
    marginTop: 16,
    marginHorizontal: 20,
    alignItems: "center",
    paddingBottom: 16,
  },
  loadingMore: { marginTop: 10 },
  emptyContainer: { flex: 1 },
});
