import { TouchableOpacity, StyleSheet, Keyboard } from "react-native";
import { AppText } from "components";
import { FC } from "react";
import { Colors, Fonts } from "theme";
interface ToggleCheckProps {
  title?: string;
  value?: boolean;
  setValue?: (bool: boolean) => void;
}
const ToggleCheck: FC<ToggleCheckProps> = ({ title, value, setValue }) => {
  return (
    <TouchableOpacity
      style={[styles.container, value && { borderColor: Colors.primary50 }]}
      onPress={() => {
        Keyboard.dismiss();
        setValue(!value);
      }}
    >
      <AppText style={Fonts.BodySmall} color={value ? Colors.primary50 : Colors.grayscale100}>
        {title}
      </AppText>
    </TouchableOpacity>
  );
};
export default ToggleCheck;
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    borderRadius: 999,
    borderWidth: 1,
    borderColor: Colors.grayscale10,
    height: 36,
    alignItems: "center",
    justifyContent: "center",
  },
});
