import Svg, { Path } from "react-native-svg";

const SVGComponent = ({ width = 67, height = 66, color = "#F59E0B" }) => (
  <Svg width={width} height={height} viewBox="0 0 67 66" fill="none">
    <Path
      d="M33.5 62.5625C17.1925 62.5625 3.9375 49.3075 3.9375 33C3.9375 16.6925 17.1925 3.4375 33.5 3.4375C49.8075 3.4375 63.0625 16.6925 63.0625 33C63.0625 49.3075 49.8075 62.5625 33.5 62.5625ZM33.5 7.5625C19.475 7.5625 8.0625 18.975 8.0625 33C8.0625 47.025 19.475 58.4375 33.5 58.4375C47.525 58.4375 58.9375 47.025 58.9375 33C58.9375 18.975 47.525 7.5625 33.5 7.5625Z"
      fill={color}
    />
    <Path
      d="M33.5 37.8125C32.3725 37.8125 31.4375 36.8775 31.4375 35.75V22C31.4375 20.8725 32.3725 19.9375 33.5 19.9375C34.6275 19.9375 35.5625 20.8725 35.5625 22V35.75C35.5625 36.8775 34.6275 37.8125 33.5 37.8125Z"
      fill={color}
    />
    <Path
      d="M33.5 46.7499C33.1425 46.7499 32.785 46.6674 32.455 46.5299C32.125 46.3924 31.8225 46.1999 31.5475 45.9524C31.3 45.6774 31.1075 45.4024 30.97 45.0449C30.8325 44.7149 30.75 44.3574 30.75 43.9999C30.75 43.6424 30.8325 43.2849 30.97 42.9549C31.1075 42.6249 31.3 42.3224 31.5475 42.0474C31.8225 41.7999 32.125 41.6074 32.455 41.4699C33.115 41.1949 33.885 41.1949 34.545 41.4699C34.875 41.6074 35.1775 41.7999 35.4525 42.0474C35.7 42.3224 35.8925 42.6249 36.03 42.9549C36.1675 43.2849 36.25 43.6424 36.25 43.9999C36.25 44.3574 36.1675 44.7149 36.03 45.0449C35.8925 45.4024 35.7 45.6774 35.4525 45.9524C35.1775 46.1999 34.875 46.3924 34.545 46.5299C34.215 46.6674 33.8575 46.7499 33.5 46.7499Z"
      fill={color}
    />
  </Svg>
);
export default SVGComponent;
