import Svg, { Path } from "react-native-svg";

function SvgComponent({ color = "#ffffff", size = 24, ...props }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 3a9 9 0 11-8.963 8.183 1 1 0 111.992.18A6.962 6.962 0 007.05 16.95 7.004 7.004 0 0012.008 19a7 7 0 10-4.91-11.998h1.4a1 1 0 01.116 1.995l-.116.006H4.496a1 1 0 01-.993-.883l-.007-.117v-4a1 1 0 011.994-.116l.006.117v1.774A8.983 8.983 0 0112 3z"
        fill={color}
      />
      <Path d="M11 9a1 1 0 112 0v4a1 1 0 11-2 0V9z" fill={color} />
      <Path d="M15 12a1 1 0 110 2h-3a1 1 0 110-2h3z" fill={color} />
    </Svg>
  );
}

export default SvgComponent;
