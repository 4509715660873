import { FC, ReactNode } from "react";
import { View } from "react-native";

interface FlashListItemProps {
  children?: ReactNode;
}

const FlashListItem: FC<FlashListItemProps> = ({ children }) => {
  return <View>{children}</View>;
};
export default FlashListItem;
