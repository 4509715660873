enum ExpenseApproval {
  EXPENSE_REPORT = "expense_reports",
  EXPENSE_REQUEST = "expense_requests",
}

export const EXPENSE_APPROVAL_TYPE = {
  REPORT: "expense:report_approval_templates",
  REQUEST: "expense:request_approval_templates",
};

export enum ApprovalType {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum ApprovalStatus {
  AWAITING = 0,
  APPROVED = 1,
  REJECTED = 2,
  CANCELLED = 3,
  PENDING = 4,
}

export enum ApprovalEmployeeStatus {
  AWAITING = 0,
  APPROVED = 1,
  REJECTED = 2,
  CANCELLED = 3,
  SKIPPED = 4,
}

export enum InvoiceType {
  ELECTRONIC = 1,
  PAPER = 2,
  NO_INVOICE = 3,
}

export enum PaymentType {
  COMPANY = "company",
  PERSONAL = "personal",
}

export enum ExpenseStatus {
  AWAITING_INVOICE = 1,
  NEED_DETAILS = 2,
  READY = 3,
  REQUESTED_PAYMENT = 4,
}

export enum ExpenseReportStatus {
  DRAFT = 0,
  SUBMITTED = 1,
  REJECTED = 2,
  AWAITING_APPROVAL = 3,
  APPROVED = 4,
  CANCELLED = 5,
}

export default ExpenseApproval;
