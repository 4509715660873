import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ fill = "#fff" }) {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={14} height={14}>
        <Path transform="rotate(-180 14 14)" fill="#D9D9D9" d="M14 14H28V28H14z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M7.24 9.698l3.015-3.015a.411.411 0 01.305-.128c.116 0 .22.047.31.137.085.09.128.193.131.307a.408.408 0 01-.13.308l-3.7 3.698a.485.485 0 01-.369.15.485.485 0 01-.37-.15L2.736 7.307a.417.417 0 01-.124-.3.436.436 0 01.124-.315.429.429 0 01.312-.136c.117 0 .221.046.312.136l3.006 3.006V3.062c0-.124.042-.228.126-.312a.423.423 0 01.311-.126c.125 0 .229.042.312.126a.423.423 0 01.126.312v6.636z"
          fill={fill}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
