import React, { useEffect, useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  ActivityIndicator,
  Keyboard,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useTranslation } from "react-i18next";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { FlashList } from "@shopify/flash-list";

import {
  AppText,
  CheckboxSelect,
  EmptyData,
  FlatListCustom,
  IconCustom,
  RadioSelectFilter,
  SearchInput,
  SelectCompanyTeam,
  SelectDate,
  SkeletonListLoading,
  ToggleCheck,
} from "components";
import { BackButtonIcon, EmptyResultFound, SearchNotFoundIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { FilterData } from "./types";
import SelectEmployee from "../../../components/AdvanceSearch/SelectEmployee";
import {
  ALLOCATED_STATUS,
  CONSTANTS,
  EXPENSE_REPORT_PAYMENT_STATUS,
  EXPENSE_REPORT_STATUS,
  KIND_OF_APPROVAL,
} from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import ReportItem from "screens/Report/ReportListScreen/components/ReportItem";
import SCREEN_NAME from "navigation/ScreenName";
import useExpenseReportSearchQuery from "./hooks/useExpenseReportSearchQuery";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { goBack } from "navigation/RootNavigation";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { REPORT_SORT_OPTIONS } from "screens/Report/ReportListScreen/types";
import { NetworkStatus } from "@apollo/client";
import { executeRouteFunction } from "utils/route";
import { MobileExpenseReportSearchQueryVariables } from "types";

const getStatusList = (t): SelectItem[] => [
  { label: t("draft"), value: EXPENSE_REPORT_STATUS.DRAFT },
  {
    label: t("waiting"),
    value: EXPENSE_REPORT_STATUS.AWAITING_MANAGER_APPROVAL,
  },
  { label: t("approval"), value: EXPENSE_REPORT_STATUS.APPROVED },
  { label: t("rejected"), value: EXPENSE_REPORT_STATUS.REJECTED },
  { label: t("cancelled"), value: EXPENSE_REPORT_STATUS.CANCEL },
];

const getPaymentStatusList = (t): SelectItem[] => [
  { label: t("payment_status_not_recorded"), value: EXPENSE_REPORT_PAYMENT_STATUS.NOT_RECORDED },
  {
    label: t("payment_status_pending"),
    value: EXPENSE_REPORT_PAYMENT_STATUS.PENDING,
  },
  { label: t("payment_status_paid"), value: EXPENSE_REPORT_PAYMENT_STATUS.PAID },
];

const sortOptions = (t) =>
  [
    { label: t("created_at_desc"), value: REPORT_SORT_OPTIONS.CREATED_AT_DESC },
    { label: t("created_at_asc"), value: REPORT_SORT_OPTIONS.CREATED_AT_ASC },
    { label: t("requested_at_desc"), value: REPORT_SORT_OPTIONS.REQUESTED_AT_DESC },
    { label: t("requested_at_asc"), value: REPORT_SORT_OPTIONS.REQUESTED_AT_ASC },
    {
      label: t("expense_asc"),
      value: REPORT_SORT_OPTIONS.AMOUNT_ASC,
    },
    {
      label: t("expense_desc"),
      value: REPORT_SORT_OPTIONS.AMOUNT_DESC,
    },
  ].reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
  }, {});
const ReportSearchScreen = ({ route }) => {
  const { awaitingMyApproval, fromScreen, iApprovedOrRejected, employee } = route?.params;
  const fromIsHistoryScreen = fromScreen === SCREEN_NAME.HistoryReportScreen;
  const { t } = useTranslation("app/screens/Report/ReportSearchScreen");
  const [keyword, setKeyword] = useState("");
  const navigation = useNavigation<any>();
  const { top } = useSafeAreaInsets();
  const searchInputRef = useRef<TextInput>();
  const flashListRef = useRef<FlashList<any>>(null);
  const disableLoadMore = useRef(true);

  const [filter, setFilter] = useState<FilterData>({
    awaitingMyApproval,
    iApprovedOrRejected,
    employee,
    sort: awaitingMyApproval
      ? sortOptions(t)[REPORT_SORT_OPTIONS.REQUESTED_AT_ASC]
      : sortOptions(t)[REPORT_SORT_OPTIONS.CREATED_AT_DESC],
  });
  const debounceSearchTimeoutId = useRef(null);
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();
  const [FEATURE_EXP_EXPENSE_ALLOCATION, FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_EXP_EXPENSE_ALLOCATION,
    FEATURE_FLAGS.FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS,
  ]);
  const [_, { data, loading, refetch, fetchMore, networkStatus }] = useExpenseReportSearchQuery({
    limit: 10,
    offset: 0,
    sortOption: filter?.sort?.value,
    where: {
      companyId,
      companyEmployeeId: employeeId,
      senderId: filter?.employee?.employeeId,
      companyTeamId: filter?.companyTeam?.teamId,
      keyword,
      statuses: filter?.status?.length ? filter.status.map((i) => i?.value) : undefined,
      paymentStatuses: filter?.paymentStatus?.length ? filter.paymentStatus.map((i) => i?.value) : undefined,
      createdDate: filter?.createdDate?.fromDate
        ? {
            fromDate: filter.createdDate.fromDate,
            toDate: filter.createdDate.toDate,
          }
        : undefined,
      submittedDate: filter?.fromDate
        ? {
            fromDate: filter?.fromDate,
            toDate: filter?.toDate,
          }
        : undefined,
      paidStatus: filter?.paidStatus?.value,
      kindOfApproval: filter?.iApprovedOrRejected
        ? KIND_OF_APPROVAL.PARTICIPATED
        : filter?.awaitingMyApproval
        ? KIND_OF_APPROVAL.AWAITING_MY_APPROVAL
        : undefined,
      allocationStatus: filter?.isAllocated ? ALLOCATED_STATUS.IS_ALLOCATED : undefined,
    } as MobileExpenseReportSearchQueryVariables["where"],
  });
  const reportList = data?.expMobileExpenseReports?.reports ?? [];
  const total = data?.expMobileExpenseReports?.total ?? 0;

  const isFiltering =
    keyword.trim() ||
    filter?.paidStatus ||
    filter?.iApprovedOrRejected ||
    filter?.status?.length ||
    filter?.awaitingMyApproval ||
    filter?.employee ||
    filter?.fromDate ||
    filter?.toDate ||
    (filter?.createdDate?.fromDate && filter?.createdDate?.toDate) ||
    filter?.companyTeam ||
    filter?.isAllocated;
  useEffect(() => {
    if (isFiltering) {
      disableLoadMore.current = true;
      refetch?.();
      setTimeout(() => {
        disableLoadMore.current = false;
      }, 1000);
    }
  }, [filter, keyword]);
  const onChangeFilter = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };
  const onChangeSearch = (text) => {
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      setKeyword(text);
    }, 400);
  };
  const onResetSearch = () => {
    setKeyword("");
    searchInputRef?.current?.setNativeProps({ text: "" });
  };
  const onClearFilter = () => {
    setKeyword("");
    setFilter(null);
  };
  const renderEmpty = () => {
    return <EmptyData icon={<SearchNotFoundIcon />} title={t("search_not_found")} />;
  };
  const onRefreshDataWhenGoBack = () => {
    flashListRef.current?.scrollToOffset({ animated: false, offset: 0 });
    refetch?.();
    executeRouteFunction(route, "onRefreshData");
  };
  const handleClickItem = (item) => () => {
    const { expenseReportId, status, companyEmployeeId } = item;
    const isEdit = employeeId === companyEmployeeId && status === EXPENSE_REPORT_STATUS.DRAFT;
    if (isEdit) {
      analyticService.logEvent({ name: EVENT.REPORT.TAP_UPDATE });
      navigation.navigate(SCREEN_NAME.EditReportScreen, {
        expenseReportId: expenseReportId,
        onRefreshData: onRefreshDataWhenGoBack,
      });
      return;
    }
    analyticService.logEvent({ name: EVENT.REPORT.TAP_VIEW_DETAIL });
    navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
      expenseReportId: expenseReportId,
      onRefreshData: onRefreshDataWhenGoBack,
    });
  };
  const renderItem = ({ item }) => (
    <ReportItem
      showCreator={filter?.awaitingMyApproval}
      onPress={handleClickItem(item)}
      item={item}
      showApprovalFlow={
        filter?.awaitingMyApproval
          ? false
          : [EXPENSE_REPORT_STATUS.SUBMITTED, EXPENSE_REPORT_STATUS.REJECTED].includes(item.status)
      }
      isRejected={item.status === EXPENSE_REPORT_STATUS.REJECTED}
    />
  );
  const renderFooter = () => {
    if (loading && reportList.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };
  const handleLoadMore = () => {
    if (loading || disableLoadMore.current || reportList?.length === 0 || reportList?.length >= total) {
      return;
    }
    disableLoadMore.current = true;

    fetchMore?.({
      variables: { offset: reportList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        disableLoadMore.current = false;

        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expMobileExpenseReports: {
            reports: [...prev.expMobileExpenseReports.reports, ...fetchMoreResult.expMobileExpenseReports.reports],
            total: fetchMoreResult.expMobileExpenseReports.total,
          },
        };
      },
    });
  };
  return (
    <View style={styles.container}>
      <View style={[styles.headerContainer, { paddingTop: top || 20 }]}>
        <View style={styles.searchContainer}>
          <TouchableOpacity style={{ marginTop: 6 }} onPress={goBack}>
            <BackButtonIcon />
          </TouchableOpacity>
          <SearchInput
            numberOfLines={1}
            ref={searchInputRef}
            style={styles.searchInput}
            placeholder={t("search")}
            onChangeText={onChangeSearch}
            autoCapitalize="none"
            placeholderTextColor={Colors.grayscale60}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            right={
              keyword ? (
                <TouchableOpacity onPress={onResetSearch}>
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : null
            }
          />
        </View>
        <ScrollView keyboardShouldPersistTaps="always" horizontal showsHorizontalScrollIndicator={false}>
          <View style={styles.searchItemContainer}>
            {!fromIsHistoryScreen ? (
              <ToggleCheck
                value={filter?.awaitingMyApproval}
                setValue={(value) => onChangeFilter("awaitingMyApproval", value)}
                title={t("waiting_for_your_approval")}
              />
            ) : (
              <ToggleCheck
                value={filter?.iApprovedOrRejected}
                setValue={(value) => onChangeFilter("iApprovedOrRejected", value)}
                title={t("me_approved_or_rejected")}
              />
            )}
            <SelectEmployee
              value={filter?.employee}
              setValue={(value) => onChangeFilter("employee", value)}
              title={t("employee")}
            />
            <CheckboxSelect
              title={t("status")}
              data={getStatusList(t)}
              value={filter?.status as SelectItem[]}
              setValue={(value) => onChangeFilter("status", value)}
            />
            {FEATURE_EXP_EXPENSE_REPORT_PAYMENT_STATUS && (
              <CheckboxSelect
                title={t("payment_status")}
                data={getPaymentStatusList(t)}
                value={filter?.paymentStatus as SelectItem[]}
                setValue={(value) => onChangeFilter("paymentStatus", value)}
              />
            )}
            <SelectDate
              title={t("created_date")}
              labelDateValue={filter?.createdDate?.labelDateValue}
              setValue={(label, from, to) => {
                onChangeFilter("createdDate", { fromDate: from, toDate: to, labelDateValue: label });
              }}
            />
            <SelectDate
              title={t("submitted_date")}
              labelDateValue={filter?.labelDateValue}
              setValue={(label, from, to) => {
                onChangeFilter("fromDate", from);
                onChangeFilter("toDate", to);
                onChangeFilter("labelDateValue", label);
              }}
            />
            <SelectCompanyTeam
              title={t("unit")}
              value={filter?.companyTeam}
              setValue={(value) => onChangeFilter("companyTeam", value)}
            />
            {FEATURE_EXP_EXPENSE_ALLOCATION && (
              <ToggleCheck
                value={filter?.isAllocated}
                setValue={(value) => onChangeFilter("isAllocated", value)}
                title={t("is_allocated")}
              />
            )}
            <RadioSelectFilter
              showClearFilter={false}
              showClearButton={false}
              title={t("sort_by")}
              data={Object.values(sortOptions(t))}
              minWidth={100}
              value={filter?.sort}
              setValue={(value) => onChangeFilter("sort", value)}
            />
            <TouchableOpacity onPress={onClearFilter}>
              <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                {t("clear_filter")}
              </AppText>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
      {!isFiltering ? (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.emptyContainer}>
            <EmptyData icon={<EmptyResultFound />} title={t("search_title")} description={t("search_description")} />
          </View>
        </TouchableWithoutFeedback>
      ) : (loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={{ marginTop: 5 }} />
      ) : (
        <FlatListCustom
          ref={flashListRef}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.contentContainer}
          data={reportList}
          renderItem={renderItem}
          refreshControl={<RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetch} />}
          ListEmptyComponent={renderEmpty}
          keyExtractor={(item, index) => item?.expenseReportId ?? index}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.2}
          ListFooterComponent={renderFooter}
          estimatedItemSize={88}
        />
      )}
    </View>
  );
};
export default ReportSearchScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
  contentContainer: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, paddingTop: 10, paddingBottom: 20 },
  headerContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.grayscale60,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 4,
  },
  searchContainer: {
    paddingRight: 20,
    paddingLeft: 18,
    justifyContent: "space-between",
    gap: 8,
    alignItems: "center",
    flexDirection: "row",
  },
  searchInput: { ...Fonts.BodySmall, flex: 1, color: Colors.grayscale100, backgroundColor: Colors.white },
  searchItemContainer: {
    flexDirection: "row",
    gap: 8,
    marginTop: 16,
    marginHorizontal: 20,
    alignItems: "center",
    paddingBottom: 16,
  },
  loadingMore: { marginTop: 10 },
  emptyContainer: { flex: 1 },
});
