import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobilePmsCompanyProductsQuery, MobilePmsCompanyProductsQueryVariables } from "types";

const QUERY = gql`
  query MobilePmsCompanyProducts($where: PmsCompanyProductsWhereInput!, $limit: Int!, $offset: Int!) {
    pmsCompanyProducts(where: $where, limit: $limit, offset: $offset) {
      items {
        companyProductId
        companyId
        productName
        productNameEn
        productCode
        unitPrices {
          unit
          unitPrice
          conversionRate
        }
        updatedAt
        createdAt
      }
      total
    }
  }
`;

const useCompanyProductsQuery = (
  variables?: MobilePmsCompanyProductsQueryVariables,
  onCompleted?: (data: MobilePmsCompanyProductsQuery) => void
) => {
  return useQuery<MobilePmsCompanyProductsQuery, MobilePmsCompanyProductsQueryVariables>(QUERY, {
    variables,
    onCompleted,
  });
};

export default useCompanyProductsQuery;
