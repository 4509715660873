import { useEffect, useState } from "react";

export enum SelectAllStatus {
  ALL = "ALL",
  SOME = "SOME",
  NONE = "NONE",
}

enum ItemClickAction {
  SELECT = "SELECT",
  DESELECT = "DESELECT",
}

const useSelectList = (data: any[], keyExtractor: (item: any) => string) => {
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [selectStatus, setSelectStatus] = useState<SelectAllStatus>(SelectAllStatus.NONE);

  useEffect(() => {
    const nextSelected = new Set(selected);
    const mismatch = [...selected].filter((key) => !data.find((item) => key === keyExtractor(item)));

    // check mismatch
    if (mismatch.length > 0) {
      mismatch.forEach((key) => nextSelected.delete(key));
      setSelected(nextSelected);
    }

    if (selectStatus === SelectAllStatus.ALL && data.some((item) => !nextSelected.has(keyExtractor(item)))) {
      setSelectStatus(SelectAllStatus.SOME);
    }

    if (selectStatus === SelectAllStatus.SOME && data.every((item) => !nextSelected.has(keyExtractor(item)))) {
      setSelectStatus(SelectAllStatus.NONE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickItem = (key) => {
    const nextSelected = new Set(selected);
    const action = nextSelected.has(key) ? ItemClickAction.DESELECT : ItemClickAction.SELECT;
    switch (action) {
      case ItemClickAction.SELECT:
        nextSelected.add(key);
        break;
      case ItemClickAction.DESELECT:
        nextSelected.delete(key);
        break;
    }

    setSelected(nextSelected);
    if (nextSelected.size === data.length) {
      setSelectStatus(SelectAllStatus.ALL);
    }
    if (nextSelected.size === 0) {
      setSelectStatus(SelectAllStatus.NONE);
    }
    if (nextSelected.size > 0 && nextSelected.size < data.length) {
      setSelectStatus(SelectAllStatus.SOME);
    }
  };

  const handleSelectAll = () => {
    switch (selectStatus) {
      case SelectAllStatus.ALL:
        setSelected(new Set());
        setSelectStatus(SelectAllStatus.NONE);
        break;
      case SelectAllStatus.SOME:
      case SelectAllStatus.NONE:
        setSelected(new Set(data.map(keyExtractor)));
        setSelectStatus(SelectAllStatus.ALL);
        break;
    }
  };
  const onClear = () => {
    setSelected(new Set());
    setSelectStatus(SelectAllStatus.NONE);
  };

  return { selected, selectStatus, handleClickItem, handleSelectAll, onClear };
};

export default useSelectList;
