import {
  AppText,
  CurrencyInput,
  IconCustom,
  SelectBottomCustom,
  SwitchButton,
  TextInputCustom,
  useSetRelativePosition,
} from "components";
import { Colors, Fonts } from "theme";
import { StyleSheet, View } from "react-native";
import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";
import { ALLOWANCE_TYPE, BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { countNumberOfDays, formatMoney, startLayoutAnimation } from "utils";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { ExpenseRequestFormValues, HOTEL_BOOKING_TYPE } from "screens/ExpenseRequest/types";
import LocationInput from "screens/ExpenseRequest/components/ExpenseRequestForm/LocationInput";
import { Keys } from "constants/Keys";
import { v4 } from "uuid";
import useExpensePoliciesBookingByLocation from "screens/ExpenseRequest/hooks/useExpensePoliciesBookingByLocation";
import { useAuth } from "contexts/AuthContext";
import CalendarDatePicker from "components/InputCustom/CalendarDatePicker";
import { DatePickerMode } from "constants/DateSelectConstant";
import { useMasterData } from "contexts/MasterDataContext";
import { SelectBottomCustomItem } from "components/InputCustom/SelectBottomCustom";
import debounce from "lodash/debounce";
import { getNewAllowancesByHotel } from "screens/ExpenseRequest/helper";

interface HotelSectionProps {
  name?: string;
  control?: Control<ExpenseRequestFormValues>;
  rules?: RegisterOptions<ExpenseRequestFormValues, "paymentInfo">;
  errors?: FieldErrors<ExpenseRequestFormValues>;
  setValue?: UseFormSetValue<ExpenseRequestFormValues>;
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
  watch?: UseFormWatch<ExpenseRequestFormValues>;
  totalAmount?: number;
  setPosition?: (key: string) => (y: number) => void;
  containerRef?: RefObject<any>;
}
const HotelSection = forwardRef<any, HotelSectionProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleLoadAllowances: handleLoadPoliciesPerdiem,
  }));
  const { setValue, control, errors, setPosition, getValues, containerRef } = props;
  const [policyHotelAmount, setPolicyHotelAmount] = useState(undefined);
  const {
    setting: { allowPastTimeOnExpenseRequestCreation, request: requestSetting },
  } = useMasterData();
  const childRef = useRef(null);
  useSetRelativePosition({
    containerRef,
    childRef,
    setPosition: (y) => {
      setPosition("hotelBooking")(y);
      setPosition("hotelBookingDate")(y);
    },
  });

  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const hotelBooking = useWatch({ name: "hotelBooking", control });
  const { hotelType } = hotelBooking ?? {};

  const {
    user: { employee_id },
  } = useAuth();

  const hotelList: SelectBottomCustomItem[] = useMemo(() => {
    if (!requestSetting?.hotels) {
      return [];
    }
    return requestSetting.hotels
      .filter((item) => item.location.code === hotelBooking?.location?.metadata?.code)
      .map((item) => ({
        label: item.name,
        value: item.code,
        leftIcon: <IconCustom name="hotel" />,
      }));
  }, [requestSetting, hotelBooking]);

  const [onFetchPoliciesBooking] = useExpensePoliciesBookingByLocation();

  const handleLoadPoliciesPerdiem = async () => {
    const hotelBooking = getValues("hotelBooking");
    const allowances = getValues("allowances")?.filter((item) => item?.type !== ALLOWANCE_TYPE.BOOKING_HOTEL);
    if (!hotelBooking?.location?.metadata?.code) {
      setValue("allowances", allowances);
      return;
    }
    const hotelBookingDate = getValues("hotelBookingDate");
    const numOfNights = hotelBookingDate?.fromDate
      ? countNumberOfDays(hotelBookingDate?.fromDate, hotelBookingDate?.toDate) - 1
      : 0;
    const rs = await onFetchPoliciesBooking({
      variables: {
        input: {
          employeeId: employee_id,
          locationCode: hotelBooking?.location?.metadata?.code,
          bookingType: BOOKING_TYPE.HOTEL,
        },
      },
    });
    // in the case hotel booking not setup perdiem --> no auto generate allowances
    if (!rs?.data?.expExpensePoliciesBookingByLocation?.expenseCategory || !getValues("hotelBooking.isOn")) {
      startLayoutAnimation();
      setValue("allowances", allowances);
      setValue("hotelBooking.location", null);
      return;
    }
    const policy = rs?.data?.expExpensePoliciesBookingByLocation;
    setPolicyHotelAmount(policy?.amount ?? 0);
    console.log("hotel90", policy?.amount);
    const allowanceItem = {
      id: v4(),
      amount: numOfNights > 0 ? policy?.amount * numOfNights : 0,
      expenseCategoryId: policy?.expenseCategory?.expenseCategoryId,
      type: ALLOWANCE_TYPE.BOOKING_HOTEL,
      expenseCategory: {
        title: policy?.expenseCategory?.title,
        titleEn: policy?.expenseCategory?.titleEn,
      },
      numberOfRooms: undefined,
    };
    console.log("hotel91", allowanceItem);

    /*-- TMG company use case: enable select internal hotel -> Mobile calculator perdiem hotel -> not using amount from API get perdiem hotel --*/
    if (requestSetting?.hotelBooking?.withHotelInfo) {
      const hotelExternal = requestSetting?.hotels?.find(
        (item) => item.location.code === hotelBooking?.location?.metadata?.code
      );
      console.log("hotelExternal", hotelExternal);
      if (hotelExternal && hotelExternal?.roomRate <= policy?.amount) {
        setValue("hotelBooking.hotelType", HOTEL_BOOKING_TYPE.INTERNAL);
        setValue("hotelBooking.companyHotelCode", hotelExternal?.code);
        setValue("hotelBooking.hotelName", hotelExternal?.name);
        setValue("hotelBooking.roomRate", hotelExternal?.roomRate);
        allowanceItem.amount = hotelExternal?.roomRate;
      } else {
        setValue("hotelBooking.hotelType", HOTEL_BOOKING_TYPE.EXTERNAL);
        setValue("hotelBooking.companyHotelCode", null);
      }
    }
    /*--- end ---*/

    const travelPerdiemAllowances = allowances.filter((i) => i?.type === ALLOWANCE_TYPE.PERDIEM);
    const flightBooking = allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_FLIGHT);
    const transporterBooking = allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_BUS);
    let insertToIndex = travelPerdiemAllowances?.length || 0;
    if (flightBooking) {
      insertToIndex += 1;
    }
    if (transporterBooking) {
      insertToIndex += 1;
    }
    // insert hotel booking perdiem after flight booking, transporter booking and travel perdiem allowances for each day if exist
    allowances.splice(insertToIndex, 0, allowanceItem);
    startLayoutAnimation();
    const newAllowances = getNewAllowancesByHotel({
      hotelBooking: getValues("hotelBooking"),
      hotelBookingDate: getValues("hotelBookingDate"),
      allowances,
      policyAmount: policy?.amount,
    });
    setValue("allowances", newAllowances);
  };
  const onConfirmDate = () => {
    handleLoadPoliciesPerdiem();
  };
  const onToggleHotelBooking = () => {
    const allowances = getValues("allowances");
    const isEnable = !hotelBooking?.isOn;
    startLayoutAnimation();
    if (!isEnable) {
      setValue(
        "allowances",
        allowances?.filter((item) => item?.type !== ALLOWANCE_TYPE.BOOKING_HOTEL)
      );
    } else if (!allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_HOTEL)) {
      handleLoadPoliciesPerdiem();
    }
    setValue("hotelBooking.isOn", !hotelBooking?.isOn);
  };
  const handleSetLocation = (key, value, config) => {
    setValue(key, value, config);
    handleLoadPoliciesPerdiem();
  };

  const onSelectHotelInternal = (value) => {
    const hotelConfig = requestSetting?.hotels?.find((item) => item.code === value);
    setValue("hotelBooking.roomRate", hotelConfig?.roomRate ?? 0);
    setValue("hotelBooking.hotelName", hotelConfig?.name);
    handleLoadPoliciesPerdiem();
  };

  const handleSelectHotelType = () => {
    setValue("hotelBooking.hotelName", "", { shouldValidate: true });
    setValue("hotelBooking.companyHotelCode", "");
  };

  const timeoutId = useRef(null);
  const handleFetchPerDiemHotelWhenChangeValue = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    const allowances = getValues("allowances");
    timeoutId.current = setTimeout(() => {
      const newAllowances = getNewAllowancesByHotel({
        hotelBooking: getValues("hotelBooking"),
        hotelBookingDate: getValues("hotelBookingDate"),
        allowances,
      });
      setValue("allowances", newAllowances);
    }, 500);
  };

  return (
    <View style={styles.container} ref={childRef}>
      <View style={styles.titleContainer}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("hotel")}</AppText>
        {!requestSetting?.hotelBooking?.requireHotelInfo && (
          <SwitchButton onValueChange={onToggleHotelBooking} value={hotelBooking?.isOn} />
        )}
      </View>
      {hotelBooking?.isOn && (
        <>
          <View style={styles.contentContainer}>
            <LocationInput
              name={"hotelBooking.location"}
              control={control}
              label={t("location")}
              setValue={handleSetLocation}
              error={errors?.hotelBooking?.location}
              rules={{ required: t("required") }}
              leftIcon={<IconCustom name="hotel" />}
              value={hotelBooking?.location}
              getValues={getValues}
              recentLocationKey={`${employee_id}${Keys.RECENT_HOTEL_LOCATION}`}
              searchDescription={t("search_location_from_description")}
              bookingType={BOOKING_TYPE.HOTEL}
              notFoundDescription={t("search_airline_not_found_description")}
            />
            {requestSetting?.hotelBooking?.withHotelInfo && (
              <>
                <SelectBottomCustom
                  snapPoints={[250]}
                  label={t("hotel_type")}
                  name="hotelBooking.hotelType"
                  control={control}
                  options={[
                    {
                      key: HOTEL_BOOKING_TYPE.INTERNAL,
                      label: t("hotel_internal"),
                      value: HOTEL_BOOKING_TYPE.INTERNAL,
                    },
                    {
                      key: HOTEL_BOOKING_TYPE.EXTERNAL,
                      label: t("hotel_external"),
                      value: HOTEL_BOOKING_TYPE.EXTERNAL,
                    },
                  ]}
                  onSelect={handleSelectHotelType}
                />
                {hotelType === HOTEL_BOOKING_TYPE.INTERNAL ? (
                  <SelectBottomCustom
                    snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
                    label={t("hotel_internal")}
                    name="hotelBooking.companyHotelCode"
                    control={control}
                    options={hotelList}
                    rules={{ required: t("required") }}
                    error={errors?.hotelBooking?.companyHotelCode}
                    onSelect={onSelectHotelInternal}
                  />
                ) : (
                  <TextInputCustom
                    name="hotelBooking.hotelName"
                    control={control}
                    label={t("hotel_external_name")}
                    placeholder={t("hotel_external_name")}
                    rules={{ required: t("required") }}
                    error={errors?.hotelBooking?.hotelName}
                  />
                )}
                <CurrencyInput
                  defaultValue={1}
                  name="hotelBooking.numberOfRooms"
                  placeholder={t("number_of_rooms")}
                  control={control}
                  rules={{
                    required: t("required"),
                  }}
                  label={t("number_of_rooms")}
                  error={errors?.hotelBooking?.numberOfRooms}
                  onChange={handleFetchPerDiemHotelWhenChangeValue}
                />
                <CurrencyInput
                  disabled={hotelType === HOTEL_BOOKING_TYPE.INTERNAL}
                  name="hotelBooking.roomRate"
                  placeholder={t("room_rate")}
                  control={control}
                  rules={
                    typeof policyHotelAmount !== "undefined"
                      ? {
                          required: t("required"),
                          max: {
                            value: policyHotelAmount,
                            message: t("room_rate_over_budget", { max: `${formatMoney(policyHotelAmount)} ₫` }),
                          },
                        }
                      : undefined
                  }
                  label={t("room_rate")}
                  error={errors?.hotelBooking?.roomRate}
                  onChange={handleFetchPerDiemHotelWhenChangeValue}
                />
              </>
            )}
            <CalendarDatePicker
              format="ddd, DD/MM/YYYY"
              datePickerModalProps={{
                validRange: allowPastTimeOnExpenseRequestCreation ? undefined : { startDate: new Date() },
              }}
              style={{ marginTop: 6 }}
              mode={DatePickerMode.RANGE}
              label={t("day_of_stay")}
              name="hotelBookingDate"
              control={control}
              rules={{ required: t("required") }}
              error={errors?.hotelBookingDate}
              onConfirm={onConfirmDate}
            />
            <TextInputCustom
              multiline
              autoHeight
              name="hotelBooking.note"
              control={control}
              label={t("bus_hotel_note")}
              placeholder={t("bus_hotel_note")}
            />
          </View>
        </>
      )}
    </View>
  );
});
export default HotelSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentContainer: { overflow: "hidden", marginTop: 10, gap: 8, paddingTop: 10 },
});
