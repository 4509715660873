import { LazyQueryResultTuple, OperationVariables, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileAccountBookByPartnerTaxCodeQuery, MobileAccountBookByPartnerTaxCodeQueryVariables } from "types";

const QUERY_ACCOUNT_BOOK_BY_PARTNER_TAX_CODE = gql`
  query MobileAccountBookByPartnerTaxCode($companyId: UUID!, $partnerTaxCode: String!) {
    expPartnerBankAccounts(input: { companyId: $companyId, partnerTaxCode: $partnerTaxCode }) {
      accountHolderName
      accountNumber
      bankCode
      bankId
      bankName
      bankShortName
      branchName
      isDefault
      logoUrl
      partnerBankAccountId
    }
  }
`;

const useBankAccountBookByVendorTaxCode = (variables?: MobileAccountBookByPartnerTaxCodeQueryVariables) => {
  const [fetch, { data, ...rest }] = useLazyQuery<
    MobileAccountBookByPartnerTaxCodeQuery,
    MobileAccountBookByPartnerTaxCodeQueryVariables
  >(QUERY_ACCOUNT_BOOK_BY_PARTNER_TAX_CODE, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return [
    fetch,
    {
      data: data?.expPartnerBankAccounts ?? [],
      ...rest,
    },
  ] as LazyQueryResultTuple<any, OperationVariables>;
};
export default useBankAccountBookByVendorTaxCode;
