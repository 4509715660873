import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent({ width = 24, height = 24, fill = "#0F172A" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path d="M8.017 21.653l-1.42-1.419L14.833 12 6.598 3.765l1.419-1.42L17.67 12l-9.654 9.653z" fill={fill} />
      </G>
    </Svg>
  );
}

export default SvgComponent;
