import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import RequestStatus from "constants/RequestStatus";
import { Colors } from "theme";

const useExpenseRequestStatusList = () => {
  const { t } = useTranslation("app/components/ExpenseRequestItem/StatusExpenseRequest");
  return useMemo<Record<RequestStatus, { uniqueKey: string; bgcolor: string; color: string; text: string }>>(
    () => ({
      [RequestStatus.DRAFT]: {
        uniqueKey: RequestStatus.DRAFT,
        bgcolor: Colors.grayscale10,
        color: Colors.grayscale80,
        text: t("draft"),
      },
      [RequestStatus.READY]: {
        uniqueKey: RequestStatus.SUBMITTED,
        bgcolor: Colors.success0,
        color: Colors.success50,
        text: t("submitted"),
      },
      [RequestStatus.SUBMITTED]: {
        uniqueKey: RequestStatus.SUBMITTED,
        bgcolor: Colors.primary05,
        color: Colors.primary50,
        text: t("submitted"),
      },

      [RequestStatus.WAITING]: {
        uniqueKey: RequestStatus.SUBMITTED,
        bgcolor: Colors.warning0,
        color: Colors.warning50,
        text: t("submitted"),
      },

      [RequestStatus.STORED]: {
        uniqueKey: RequestStatus.SUBMITTED,
        bgcolor: Colors.success0,
        color: Colors.success50,
        text: t("submitted"),
      },

      [RequestStatus.APPROVED]: {
        uniqueKey: RequestStatus.APPROVED,
        bgcolor: Colors.success0,
        color: Colors.success50,
        text: t("approved"),
      },
      [RequestStatus.REJECTED]: {
        uniqueKey: RequestStatus.REJECTED,
        bgcolor: Colors.alert05,
        color: Colors.alert50,
        text: t("rejected"),
      },
      [RequestStatus.CANCELLED]: {
        uniqueKey: RequestStatus.CANCELLED,
        bgcolor: Colors.grayscale05,
        color: Colors.grayscale100,
        text: t("cancelled"),
      },
      [RequestStatus.CLOSED]: {
        uniqueKey: RequestStatus.CLOSED,
        bgcolor: Colors.success0,
        color: Colors.success50,
        text: t("closed"),
      },
    }),
    [t]
  );
};

export default useExpenseRequestStatusList;
