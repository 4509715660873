import { useNavigation, useRoute } from "@react-navigation/native";
import * as ImageManipulator from "expo-image-manipulator";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { v4 } from "uuid";

import ImageBrowser from "./components/ImageBrowser/ImageBrowser";
import { AppText } from "components";
import { ImageBrowserScreenRouteProp } from "navigation/type";
import { Colors } from "theme";
import { Toast } from "@ant-design/react-native";

const ImageBrowserScreen = () => {
  const navigation = useNavigation();
  const route: ImageBrowserScreenRouteProp = useRoute();

  const { startNumber = 0, max = 4 } = route.params ?? ({} as any);

  const customMax = max - startNumber;

  const getHeaderLoader = () => (
    <ActivityIndicator style={{ paddingHorizontal: 15 }} size="small" color={Colors.while} />
  );

  const imagesCallback = (callback: Promise<any>) => {
    navigation.setOptions({
      headerRight: getHeaderLoader,
    });
    Toast.loading("");
    callback
      .then(async (photos) => {
        const cPhotos = [];
        for (const photo of photos) {
          const pPhoto = await processImageAsync(photo);
          cPhotos.push({
            id: v4(),
            uri: pPhoto.uri,
            name: photo.filename,
            fileType: "image/jpeg",
            height: pPhoto.height,
            width: pPhoto.width,
          });
        }
        if (route?.params?.callBack) {
          route?.params?.callBack(cPhotos);
          navigation.goBack();
        }
        Toast.removeAll();
      })
      .catch(() => {
        Toast.removeAll();
      });
  };

  const processImageAsync = async ({ uri }: { uri: string }) => {
    const file = await ImageManipulator.manipulateAsync(uri, [{ resize: { width: 1280 } }], {
      compress: 0.5,
      format: ImageManipulator.SaveFormat.JPEG,
    });
    return file;
  };

  const renderSelectedComponent = (number: number) => {
    const startNumber = route?.params?.startNumber ?? 0;
    return (
      <View style={styles.countBadge}>
        <AppText style={styles.countBadgeText}>{number + startNumber}</AppText>
      </View>
    );
  };

  return (
    <View style={[styles.flex, styles.container]}>
      <ImageBrowser max={customMax} callback={imagesCallback} renderSelectedComponent={renderSelectedComponent} />
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    position: "relative",
  },
  countBadge: {
    paddingHorizontal: 8.6,
    paddingVertical: 5,
    borderRadius: 50,
    position: "absolute",
    right: 3,
    bottom: 3,
    justifyContent: "center",
    backgroundColor: Colors.primary50,
  },
  countBadgeText: {
    fontWeight: "bold",
    alignSelf: "center",
    padding: "auto",
    color: Colors.while,
  },
});

export default ImageBrowserScreen;
