import { createContext, useContext, useRef } from "react";
import FeatureManagementContext from "contexts/FeatureManagementContext";
import { BottomSheetModalCustomMethods, InAppFeedbackModal } from "components";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { Keys } from "constants/Keys";
import AsyncStorage from "@react-native-async-storage/async-storage";
import dayjs from "dayjs";

import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

interface Feedback {
  stars: number[];
  title: string;
  options: string[];
}

interface Configuration {
  title: string;
  message: string;
  feedbacks: Feedback[];
}

interface Configurations {
  vi: Configuration;
  en: Configuration;
}

export enum FeedbackObjectType {
  ExpenseRequest = "ExpenseRequest",
  ExpenseReport = "ExpenseReport",
  ApprovalObject = "ApprovalObject",
}

export enum FeedbackTriggerEvent {
  OnSubmitted = "OnSubmitted",
  SubmittedBatchApproval = "SubmittedBatchApproval",
  OnClicked = "OnClicked",
}
interface Trigger {
  event: FeedbackTriggerEvent;
  object: FeedbackObjectType;
  times: number;
  period: number;
  unit: string;
  countdown: number;
  countdownKey: string;
}

export interface FeedbackConfig {
  id: string;
  trigger: Trigger;
  configurations: Configurations;
}

interface IFeedbackContext {
  requestInAppFeedback: (feedbackObjectType?: FeedbackObjectType) => void;
  openFeedbackModal: () => void;
}

const InAppFeedBackContext = createContext<IFeedbackContext>({} as IFeedbackContext);

InAppFeedBackContext.displayName = "InAppFeedBackContext";

function InAppFeedbackProvider({ children }) {
  const ratingModalRef = useRef<BottomSheetModalCustomMethods>();
  const { features } = useContext(FeatureManagementContext);
  const migrateOldNumberSubmitSuccessIfExist = async (newKey) => {
    const oldNumberSuccess = await AsyncStorage.getItem(Keys.COUNT_SUBMIT_SUCCESS);
    if (oldNumberSuccess) {
      await AsyncStorage.setItem(newKey, oldNumberSuccess);
      await AsyncStorage.removeItem(Keys.COUNT_SUBMIT_SUCCESS);
    }
  };
  const requestInAppFeedback = async (feedbackObjectType: FeedbackObjectType) => {
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_EXP_INAPP_FEEDBACK] ?? {};
    if (!enabled || !customFields?.settings) {
      return;
    }
    const feedbackConfigurations = customFields?.settings;
    const feedbackConfig = feedbackConfigurations.find((item) => item.trigger.object === feedbackObjectType);
    if (feedbackConfig?.trigger?.countdownKey) {
      await migrateOldNumberSubmitSuccessIfExist(feedbackConfig.trigger.countdownKey);
      const numSubmitSuccessStorage = await AsyncStorage.getItem(feedbackConfig.trigger.countdownKey);
      const numSubmitSuccess = numSubmitSuccessStorage ? parseInt(numSubmitSuccessStorage) : 0;
      const lastSubmitDateFeedback = await AsyncStorage.getItem(Keys.LAST_SUBMIT_DATE_RATING_INTERNAL);
      await AsyncStorage.setItem(
        feedbackConfig.trigger.countdownKey,
        numSubmitSuccess ? `${numSubmitSuccess + 1}` : "1"
      );
      if (
        numSubmitSuccessStorage === null ||
        (numSubmitSuccess >= feedbackConfig?.trigger?.countdown - 1 &&
          lastSubmitDateFeedback &&
          dayjs().diff(dayjs(lastSubmitDateFeedback), feedbackConfig?.trigger?.unit)) >=
          feedbackConfig?.trigger?.period ||
        (numSubmitSuccess >= feedbackConfig?.trigger?.countdown - 1 && !lastSubmitDateFeedback)
      ) {
        analyticService.logEvent({
          name: EVENT.OTHER.OPEN_RATING_INTERNAL,
        });
        ratingModalRef?.current?.present(feedbackConfig);
        // reset count down key
        AsyncStorage.setItem(feedbackConfig.trigger.countdownKey, "0");
      }
    }
  };
  const openFeedbackModal = () => {
    const { enabled, customFields } = features?.[FEATURE_FLAGS.FEATURE_EXP_INAPP_FEEDBACK] ?? {};
    if (!enabled || !customFields?.settings) {
      return;
    }
    const feedbackConfigurations = customFields?.settings;
    const feedbackConfig = feedbackConfigurations.find((item) => item.name === "ManualClicked");
    if (feedbackConfig) {
      ratingModalRef?.current?.present(feedbackConfig);
    }
  };
  return (
    <InAppFeedBackContext.Provider
      value={{
        requestInAppFeedback,
        openFeedbackModal,
      }}
    >
      {children}
      <InAppFeedbackModal ref={ratingModalRef} />
    </InAppFeedBackContext.Provider>
  );
}

function useInAppFeedback(): IFeedbackContext {
  const context = useContext(InAppFeedBackContext);
  if (context === undefined) {
    throw new Error(`useInAppFeedback must be used within a InAppFeedbackProvider`);
  }
  return context;
}

export { InAppFeedbackProvider, useInAppFeedback };
