import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  MobileQueryCrdEmployeeCardTransactionsQuery,
  MobileQueryCrdEmployeeCardTransactionsQueryVariables,
} from "types";

export const QUERY_EMPLOYEE_CARD_TRANSACTIONS = gql`
  query MobileQueryCrdEmployeeCardTransactions(
    $where: EmployeeCardTransactionsWhere
    $cardId: UUID!
    $companyId: UUID!
    $limit: Int!
    $offset: Int!
  ) {
    crdEmployeeCardTransactions(
      input: { condition: { where: $where, cardId: $cardId, companyId: $companyId }, limit: $limit, offset: $offset }
    ) {
      transactions {
        updatedAt
        transactionType
        transactionId
        transactionDate
        status
        amount
        cardId
        cardTransactionId
        companyId
        createdAt
        groupId
        location
        mcc
        refCode
        employee {
          fullname
        }
        cardTransactionExpenses {
          expenseId
          cardTransactionId
          expense {
            docSequence
          }
        }
        unreconciledAmount
      }
      totalEntries
    }
  }
`;
const useEmployeeCardTransactionsQuery = (
  variables: MobileQueryCrdEmployeeCardTransactionsQueryVariables,
  onCompleted: (data: MobileQueryCrdEmployeeCardTransactionsQuery) => void
) => {
  return useLazyQuery<
    MobileQueryCrdEmployeeCardTransactionsQuery,
    MobileQueryCrdEmployeeCardTransactionsQueryVariables
  >(QUERY_EMPLOYEE_CARD_TRANSACTIONS, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted,
  });
};

export default useEmployeeCardTransactionsQuery;
