import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";

import {
  AlertNotification,
  AppText,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  BottomSheetTextInputCustom,
  Button,
  ToastManager,
} from "components";
import { CancelReportWarningIcon } from "assets/images/svg/icons";
import { Colors, Fonts } from "theme";
import { SERVER_ERROR_CODE } from "utils/serverCode";
import useCancelExpenseRequest from "hooks/request/useCancelExpenseRequest";
import i18n from "i18next";
import { goBack } from "navigation/RootNavigation";
import { analyticService } from "services/AnalyticsService";
import SCREEN_NAME from "navigation/ScreenName";
import { EVENT } from "constants/Tracking";
import { executeRouteFunction } from "utils/route";
import { useRoute } from "@react-navigation/native";

interface CancelExpenseRequestModalProps {
  requestId: string;
  title?: string;
  onRefreshData?: () => void;
}

const CancelExpenseRequestModal = forwardRef<Partial<BottomSheetModalCustomMethods>, CancelExpenseRequestModalProps>(
  ({ title, requestId, onRefreshData }, ref) => {
    useImperativeHandle(ref, () => ({
      present: () => {
        bottomSheetModalRef?.current?.present();
      },
    }));
    const route = useRoute();
    const { t } = useTranslation("app/screens/ExpenseRequest/ExpenseRequestDetailScreen/ExpenseRequestDetailScreen");
    const alertNotificationRef = useRef(null);
    const bottomSheetModalRef = useRef<BottomSheetModalCustomMethods>(null);

    const { expenseCancelRequest } = useCancelExpenseRequest();
    const [reason, setReason] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);

    const handleCancel = async () => {
      setDisabledButton(true);
      Keyboard.dismiss();
      bottomSheetModalRef?.current?.close();
      alertNotificationRef?.current?.loading();
      try {
        const results = await expenseCancelRequest({
          request_id: requestId,
          reason,
        });
        if (!results.data?.expenseCancelRequest?.success) {
          throw new Error(SERVER_ERROR_CODE.CANCEL_EXPENSE_REQUEST_FAILED);
        }
        alertNotificationRef?.current?.close();
        analyticService.logEvent({
          sessionId: SCREEN_NAME.DetailRequestScreen,
          name: EVENT.REQUEST.CANCELED_SUCCESS,
        });
        ToastManager.success(t("cancel_expense_request_success"));
        goBack();
        executeRouteFunction(route, "onRefreshData");
        setDisabledButton(false);
      } catch (errors) {
        setDisabledButton(false);

        let message = i18n.t("common:internal_error");
        if (errors?.message === SERVER_ERROR_CODE.CANNOT_CANCEL_EXPENSE_REQUEST) {
          message = t("cannot_cancel_request");
        }
        alertNotificationRef.current?.error({
          onTry: handleCancel,
          title: t("cancel_request_failed"),
          description: message,
        });
        analyticService.logEvent({
          sessionId: SCREEN_NAME.DetailRequestScreen,
          name: EVENT.REQUEST.CANCELED_FAILED,
          properties: { message },
        });
      }
    };

    return (
      <>
        <BottomSheetScrollViewModalCustom ref={bottomSheetModalRef} wrapperByScrollView={false}>
          <TouchableWithoutFeedback onPress={Platform.OS !== "web" && Keyboard.dismiss}>
            <View style={styles.container}>
              <CancelReportWarningIcon />
              <View style={styles.labelContainer}>
                <AppText style={Fonts.H400}>{t("cancel_request")}</AppText>
                <AppText style={[Fonts.BodyMedium, styles.confirmText]} numberOfLines={2}>
                  {t("cancel_request_description")}
                  <AppText style={Fonts.H200}>{` ${title}?`}</AppText>
                </AppText>
              </View>
              <BottomSheetTextInputCustom
                autoFocus
                value={reason}
                onChangeText={setReason}
                placeholder={t("reason_cancel")}
              />
            </View>
          </TouchableWithoutFeedback>

          <View style={styles.footerButton}>
            <Button type="white" style={styles.buttonClose} onPress={() => bottomSheetModalRef?.current?.close()}>
              {t("close")}
            </Button>
            <Button type="danger" disabled={disabledButton} style={styles.buttonCancel} onPress={handleCancel}>
              {t("cancel")}
            </Button>
          </View>
        </BottomSheetScrollViewModalCustom>
        <AlertNotification ref={alertNotificationRef} />
      </>
    );
  }
);
export default CancelExpenseRequestModal;
const styles = StyleSheet.create({
  container: { paddingHorizontal: 20, paddingTop: 40, justifyContent: "center", alignItems: "center" },
  labelContainer: { alignItems: "center", marginTop: 20 },
  confirmText: { marginTop: 8, textAlign: "center" },
  textInput: {
    color: Colors.grayscale100,
    borderWidth: 1,
    padding: 10,
    borderColor: Colors.primary50,
    width: "100%",
    height: 120,
    borderRadius: 4,
    marginTop: 20,
  },
  footerButton: { flexDirection: "row", paddingHorizontal: 20, marginTop: 20, paddingBottom: 10 },
  buttonClose: { flex: 1, marginRight: 8 },
  buttonCancel: { flex: 1, marginLeft: 8 },
});
