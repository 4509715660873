import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query MobileNtfUnseenNotifications {
    ntfUnseenNotificationsCount {
      count
    }
  }
`;
type UnSeenNotificationsQueryResponse = {
  ntfUnseenNotificationsCount: { count: number };
};
const useUnSeenNotificationsQuery = () => {
  return useQuery<UnSeenNotificationsQueryResponse>(QUERY);
};

export default useUnSeenNotificationsQuery;
