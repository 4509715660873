import { FC, ReactElement, ReactNode } from "react";
import { LayoutChangeEvent, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { AppText } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";

interface SectionProps {
  title: string;
  icon?: ReactElement;
  children?: ReactNode;
  style?: ViewStyle;
  titleStyle?: TextStyle;
  onLayout?: (event: LayoutChangeEvent) => void;
}
const Section: FC<SectionProps> = ({ style, title, icon, children, titleStyle, onLayout }) => {
  return (
    <View onLayout={onLayout} style={[styles.container, style]}>
      <View style={styles.title}>
        <AppText style={[Fonts.SentenceSubtitleLarge, titleStyle]}>{title}</AppText>
        {icon}
      </View>
      {children}
    </View>
  );
};
export default Section;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginTop: 8,
    backgroundColor: Colors.white,
    paddingVertical: 16,
  },
  title: { marginBottom: 16, flexDirection: "row", alignItems: "center", gap: 4 },
});
