import { Platform, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { EXTENSION_FILE, UploadFileItem } from "./types";
import { AppText, ImageViewCustom, useWindowDimensions } from "components";
import { CONSTANTS } from "constants/constants";
import FileItem from "./FileItem";
import React, { useState } from "react";
import { Colors, Fonts } from "theme";
import * as mime from "react-native-mime-types";
import SCREEN_NAME from "navigation/ScreenName";
import { useNavigation } from "@react-navigation/native";

interface UploadFileViewProps {
  files: UploadFileItem[];
  title?: string;
  maxFile?: number;
  style?: ViewStyle;
}
const COLUMN_GAP = 14;

export const ImagePreviewFooter = ({ imageIndex, imagesCount }) => (
  <View style={styles.imagePreviewFooter}>
    <View style={styles.imagePreviewCount}>
      <AppText style={Fonts.BodyMedium} color={Colors.white}>{`${imageIndex + 1} / ${imagesCount}`}</AppText>
    </View>
  </View>
);
const UploadFileView = ({ title, files, maxFile, style }: UploadFileViewProps) => {
  const { width, isTablet } = useWindowDimensions();
  const numberItemPerRow = isTablet ? 6 : 3;
  const navigation = useNavigation<any>();
  const WIDTH_FILE = Math.floor(
    (width - 2 * CONSTANTS.COMMON.CONTAINER_PADDING - COLUMN_GAP * (numberItemPerRow - 1)) / numberItemPerRow
  );
  const [visibleImagePreview, setVisibleImagePreview] = useState({ index: 0, visible: false });
  const imageFiles = files
    ?.filter((file) =>
      [EXTENSION_FILE.PNG, EXTENSION_FILE.JPG, EXTENSION_FILE.JPEG].includes(
        mime.extension(file.fileType) as EXTENSION_FILE
      )
    )
    .map((file) => ({
      uri: file?.uri,
    }));
  const handlePreviewImage = (uri) => () => {
    if (Platform.OS === "web") {
      window.open(uri, "_blank", "noopener noreferrer");
      return;
    }
    const foundImageIndex = imageFiles?.findIndex((file) => file.uri === uri);
    setVisibleImagePreview({ visible: true, index: foundImageIndex });
  };
  const handleSeeMore = () => {
    navigation.navigate(SCREEN_NAME.AttachmentFileScreen, { title, files });
  };
  return (
    <>
      <View style={[styles.container, style]}>
        {(!maxFile ? files : files?.slice(0, files?.length > maxFile ? maxFile - 1 : maxFile))?.map((file) => {
          return (
            <FileItem
              handlePreviewImage={handlePreviewImage(file.uri)}
              width={WIDTH_FILE}
              height={WIDTH_FILE}
              key={file.id}
              file={file}
            />
          );
        })}
        {maxFile && files?.length > maxFile && (
          <TouchableOpacity
            onPress={handleSeeMore}
            style={[{ width: WIDTH_FILE, height: WIDTH_FILE }, styles.seeMoreButton]}
          >
            <AppText style={Fonts.SentenceSubtitleXLarge} color={Colors.primary50}>
              {`${files.length - 5}+`}
            </AppText>
          </TouchableOpacity>
        )}
      </View>
      <ImageViewCustom
        images={imageFiles ?? []}
        imageIndex={visibleImagePreview.index}
        visible={visibleImagePreview.visible}
        FooterComponent={({ imageIndex }) => (
          <ImagePreviewFooter imageIndex={imageIndex} imagesCount={imageFiles?.length} />
        )}
        onRequestClose={() => setVisibleImagePreview({ index: 0, visible: false })}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    flexDirection: "row",
    rowGap: 16,
    columnGap: COLUMN_GAP,
    //justifyContent: "space-between",
  },
  imagePreviewFooter: {
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 30,
  },
  imagePreviewCount: {
    backgroundColor: "#00000077",
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 20,
  },
  seeMoreButton: {
    borderRadius: 8,
    backgroundColor: Colors.grayscale05,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
});

export default UploadFileView;
