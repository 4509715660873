import React, { useLayoutEffect, useState } from "react";
import { BackHeader, TopTabLineView } from "components";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Colors } from "theme";
import { useTranslation } from "react-i18next";
import { Route } from "react-native-tab-view";
import RequestTab from "screens/ApprovalFlowScreen/components/RequestTab";
import ReportTab from "screens/ApprovalFlowScreen/components/ReportTab";

const ApprovalFlowScreen = ({ route }) => {
  const { index: indexRoute = 0 } = route?.params ?? {};
  const navigation = useNavigation<any>();
  const { t } = useTranslation("app/screens/ApprovalFlowScreen");
  const [index, setIndex] = useState(indexRoute);

  const routes = [
    { key: "request", title: t("expense_request") },
    { key: "report", title: t("expense_report") },
  ];
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader containerStyle={{ paddingBottom: 5 }} headerTitle={t("approval_flow")} />,
    } as any);
  }, []);

  const renderScene = ({ route: innerRoute }: { route: Route }) => {
    switch (innerRoute.key) {
      case "request":
        return <RequestTab />;
      case "report":
        return <ReportTab />;
      default:
        return null;
    }
  };
  return (
    <View style={styles.container}>
      <TopTabLineView lazy={false} renderScene={renderScene} routes={routes} index={index} setIndex={setIndex} />
    </View>
  );
};
export default ApprovalFlowScreen;
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.white },
});
