import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpInsightsSpendingEmployeesQuery, MobileExpInsightsSpendingEmployeesQueryVariables } from "types";

const QUERY = gql`
  query MobileExpInsightsSpendingEmployees($input: ExpInsightsInput!) {
    expInsights(input: $input) {
      topSpendingEmployees(top: 4) {
        employees {
          fullname
          employeeId
          totalAmount
          totalAmountPrevious
          totalAmountDiffPercentage
        }
        otherAmount
        totalEmployees
      }
    }
  }
`;

const useSpendingEmployeesQuery = (variables: MobileExpInsightsSpendingEmployeesQueryVariables, skip = true) => {
  return useQuery<MobileExpInsightsSpendingEmployeesQuery, MobileExpInsightsSpendingEmployeesQueryVariables>(QUERY, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip,
  });
};

export default useSpendingEmployeesQuery;
