import { FC } from "react";
import { Image, StyleSheet, View } from "react-native";
import { BizziCard } from "assets/images";
import Carousel from "react-native-reanimated-carousel";
import { useSharedValue } from "react-native-reanimated";
import ContentLoader, { Rect } from "react-content-loader/native";
import { useTranslation } from "react-i18next";

import { Colors, Fonts, FontTypes } from "theme";
import { AppText, useWindowDimensions } from "components";
import { Card } from "../types";
import { CARD_STATUS } from "constants/constants";
import { BizziTextCardIcon, CardLogoIcon, ShinhanBankCardText, VisaCardText } from "assets/images/svg/icons";
import PaginationDot from "./PaginationDot";
import { convertViToEn } from "utils";

interface CardCarouselListProps {
  list: Card[];
  handleChangeCard: (cardId: string) => void;
  loading?: boolean;
}

const CardStatus = ({ status }) => {
  const { t } = useTranslation("app/screens/Card/CardListScreen");

  if (status === CARD_STATUS.ACTIVATED) {
    return null;
  }
  const getStatusTitleAndStyle = () => {
    let title = t("closed");
    let backgroundColor = Colors.grayscale0;
    let textColor = Colors.grayscale60;
    if (status === CARD_STATUS.BLOCKED) {
      title = t("blocked");
      backgroundColor = Colors.alert05;
      textColor = Colors.alert50;
    } else if (status === CARD_STATUS.EXPIRED) {
      title = t("expired");
      backgroundColor = Colors.grayscale05;
      textColor = Colors.grayscale60;
    } else if (status === CARD_STATUS.INACTIVE) {
      title = t("inactive");
      backgroundColor = Colors.primary05;
      textColor = Colors.primary50;
    }
    return { title, backgroundColor, textColor };
  };
  const { title, backgroundColor, textColor } = getStatusTitleAndStyle();
  return (
    <View style={[styles.cardStatus, { backgroundColor }]}>
      <AppText style={Fonts.BodySmall} color={textColor}>
        {title}
      </AppText>
    </View>
  );
};

export const CardItem = ({ item }) => {
  const { width, isTablet } = useWindowDimensions();

  const CARD_WIDTH = isTablet ? width / 2 : width;
  const CARD_HEIGHT = CARD_WIDTH / 1.58;

  return (
    <View
      style={[
        styles.cardItem,
        { width: CARD_WIDTH },
        isTablet && {
          marginLeft: (width - CARD_WIDTH) / 2 + 5,
        },
      ]}
    >
      <Image source={BizziCard} style={[styles.cardImage, { width: CARD_WIDTH, height: CARD_HEIGHT }]} />
      <View style={styles.leftHeaderCard}>
        <BizziTextCardIcon />
      </View>
      <View style={styles.rightHeaderCard}>
        <ShinhanBankCardText />
      </View>
      <View style={styles.cardRightLogo}>
        <CardLogoIcon />
      </View>
      <View style={styles.visaCardText}>
        <VisaCardText />
      </View>
      {item?.status !== CARD_STATUS.ACTIVATED && (
        <View
          style={[styles.cardImage, { width: CARD_WIDTH, height: CARD_HEIGHT, backgroundColor: "rgba(0,0,0,0.7)" }]}
        />
      )}
      <View style={styles.accountHolderInfo}>
        <AppText style={styles.cardNumberText} color={Colors.white}>
          {Boolean(item?.prefixCardNumber) &&
            `${item?.prefixCardNumber?.substring(0, 4)} ${item?.prefixCardNumber?.substring(4, 6)}** **** `}
          <AppText style={[styles.cardNumberText, styles.textBold]} color={Colors.white}>
            {item?.suffixCardNumber ?? ""}
          </AppText>
        </AppText>
        <AppText style={styles.fullname} color={Colors.white}>
          {item?.cardholderEmployee?.fullname
            ? convertViToEn(item?.cardholderEmployee?.fullname).toUpperCase()
            : `*** ${item?.cardholderName}`}
        </AppText>
      </View>
      <CardStatus status={item?.status} />
    </View>
  );
};

const CardCarouselList: FC<CardCarouselListProps> = ({ list, handleChangeCard, loading }) => {
  const progressValue = useSharedValue<number>(0);
  const { width, isTablet } = useWindowDimensions();
  const modeConfig = isTablet
    ? { parallaxScrollingScale: 1, parallaxScrollingOffset: -10 }
    : { parallaxScrollingScale: 0.9, parallaxScrollingOffset: 45 };
  const CARD_WIDTH = isTablet ? width / 2 : width;
  const CARD_HEIGHT = CARD_WIDTH / 1.58;
  const onSnapToItem = (index) => {
    handleChangeCard(list[index]?.cardId);
  };

  if (loading) {
    return (
      <ContentLoader animate={false} style={{ transform: [{ scale: 0.9 }] }} width={width} height={CARD_HEIGHT + 1}>
        <Rect x="0" y="0" rx="16" ry="16" width={width} height={CARD_HEIGHT + 1} />
      </ContentLoader>
    );
  }
  return (
    <View>
      <Carousel
        windowSize={5}
        loop={isTablet}
        width={CARD_WIDTH}
        style={{ width: "100%" }}
        height={CARD_HEIGHT}
        autoPlay={false}
        mode="parallax"
        modeConfig={modeConfig}
        data={list ?? []}
        onProgressChange={(_, absoluteProgress) => (progressValue.value = absoluteProgress)}
        scrollAnimationDuration={500}
        onSnapToItem={onSnapToItem}
        renderItem={({ item }) => <CardItem key={item?.cardId} item={item} />}
      />
      {list?.length > 1 && (
        <View style={[styles.paginationContainer, isTablet && { marginTop: 20 }]}>
          {list?.map((item, index) => {
            return <PaginationDot key={item?.cardId} length={list.length} animValue={progressValue} index={index} />;
          })}
        </View>
      )}
    </View>
  );
};
export default CardCarouselList;

const styles = StyleSheet.create({
  cardItem: {
    flex: 1,
    shadowColor: "rgba(68, 120, 255, 0.8)",
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5,
  },
  cardImage: { borderRadius: 10, position: "absolute" },
  paginationContainer: { flexDirection: "row", alignSelf: "center", marginTop: 5 },
  cardNumberText: { fontSize: 17, fontFamily: FontTypes.medium },
  textBold: { fontFamily: FontTypes.bold },
  fullname: { fontSize: 17, fontFamily: FontTypes.semiBold, marginTop: 7 },
  cardStatus: {
    position: "absolute",
    alignSelf: "center",
    borderRadius: 20,
    paddingHorizontal: 12,
    paddingVertical: 6,
    top: "50%",
  },
  leftHeaderCard: { left: 12, top: 12, position: "absolute" },
  accountHolderInfo: { left: 12, top: 38, position: "absolute" },
  rightHeaderCard: { right: 12, top: 12, position: "absolute" },
  cardRightLogo: { right: 30, top: 40, position: "absolute" },
  visaCardText: { right: 13, bottom: 15, position: "absolute" },
});
