import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetModalCustomMethods,
  EmptyData,
  IconCustom,
  TextInputCustom,
  TextInputFlatListModal,
  useDebounce,
} from "components";
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from "react-native";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useCompanyProductsQuery from "screens/ExpenseRequest/AddAllowanceScreen/hooks/useCompanyProductsQuery";
import { useAuth } from "contexts/AuthContext";
import { getTitleI18n } from "utils";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import { EmptyResultFound, SearchNotFoundIcon } from "assets/images/svg/icons";
import isEmpty from "lodash/isEmpty";

const PAGE_SIZE = 20;
const ProductNameSelectInput = ({ control, setValue, rules, getValues, error, unitPrices, setUnitPrices }) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/AddAllowanceScreen/AddAllowanceScreen");
  const modalRef = useRef<BottomSheetModalCustomMethods>();
  const {
    groupId,
    company: { company_id: companyId },
  } = useAuth();
  const [textSearch, setTextSearch] = useState("");
  const debouncedTextSearch = useDebounce<string>(textSearch);
  const { loading, data, fetchMore } = useCompanyProductsQuery(
    {
      where: {
        groupId,
        companyId,
        filter: debouncedTextSearch
          ? {
              productName: debouncedTextSearch,
            }
          : undefined,
      },
      limit: PAGE_SIZE,
      offset: 0,
    },
    (data) => {
      if (isEmpty(unitPrices)) {
        const item = data?.pmsCompanyProducts?.items?.find(
          (item) => item.productName === getValues("productName") || item.productNameEn === getValues("productName")
        );
        if (item) {
          setUnitPrices(item.unitPrices);
        }
      }
    }
  );
  const companyProducts = data?.pmsCompanyProducts?.items ?? [];
  const total = data?.pmsCompanyProducts?.total ?? 0;

  const handleLoadMore = () => {
    if (loading || !companyProducts?.length || companyProducts?.length === total) {
      return;
    }
    fetchMore?.({
      variables: { offset: companyProducts?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          pmsCompanyProducts: {
            items: [...prev.pmsCompanyProducts.items, ...fetchMoreResult.pmsCompanyProducts.items],
            total: fetchMoreResult.pmsCompanyProducts.total,
          },
        };
      },
    });
  };

  const handleOpenModal = () => {
    modalRef.current.present();
    setTextSearch("");
  };

  const handleSelectItem = (item) => () => {
    setValue({ value: getTitleI18n({ vi: item.productName, en: item.productNameEn }), unitPrices: item.unitPrices });
    modalRef?.current?.close();
  };

  const renderItem = ({ item }) => (
    <>
      <TouchableOpacity style={styles.productItem} onPress={handleSelectItem(item)}>
        <IconCustom name="inventory-2" />
        <AppText style={[Fonts.BodyLarge, styles.flex]}>
          {getTitleI18n({ vi: item.productName, en: item.productNameEn })}
        </AppText>
      </TouchableOpacity>
      <View style={styles.divider} />
    </>
  );

  return (
    <>
      <TextInputCustom
        name="productName"
        control={control}
        rules={rules}
        error={error}
        label={t("product_name")}
        placeholder={t("product_name")}
        absoluteRight={
          <TouchableOpacity activeOpacity={1} style={styles.rightButton} onPress={handleOpenModal}>
            <IconCustom name="inventory-2" fill={Colors.grayscale60} />
          </TouchableOpacity>
        }
      />
      <BottomSheetFlatListModalCustom
        loading={loading}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={t("choose_product")}
        ref={modalRef}
        searchHeight={55}
        renderSearch={() => (
          <TextInputFlatListModal
            value={textSearch}
            onChangeText={setTextSearch}
            style={styles.searchInput}
            placeholder={t("search")}
          />
        )}
        listProps={{
          keyboardDismissMode: "on-drag",
          keyboardShouldPersistTaps: "always",
          showsVerticalScrollIndicator: false,
          data: data?.pmsCompanyProducts?.items ?? [],
          contentContainerStyle: styles.contentContainerStyle,
          keyExtractor: (item) => item.companyProductId,
          onEndReached: handleLoadMore,
          onEndReachedThreshold: 0.15,
          ListFooterComponent:
            loading && companyProducts?.length > 0 ? <ActivityIndicator size="small" color={Colors.primary50} /> : null,
          ListEmptyComponent: () => (
            <EmptyData
              title={t("search_not_found")}
              icon={!debouncedTextSearch ? <EmptyResultFound /> : <SearchNotFoundIcon />}
            />
          ),
          renderItem,
        }}
      />
    </>
  );
};
export default ProductNameSelectInput;

const styles = StyleSheet.create({
  contentContainerStyle: { paddingTop: 4, paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING },
  searchInput: {
    marginHorizontal: 20,
  },
  rightButton: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  productItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingVertical: 16,
  },
  flex: { flex: 1 },

  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
