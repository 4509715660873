import Svg, { Path, Rect } from "react-native-svg";

function ExpenseItemIcon({ width = 48, height = 48, iconColor = "#1F71F4", backgroundColor = "#F0F6FF" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <Rect width={width} height={height} rx="24" fill={backgroundColor} />
      <Path
        d="M27.78 14H20.22C16.44 14 15.5 15.01 15.5 19.04V30.3C15.5 32.96 16.96 33.59 18.73 31.69L18.74 31.68C19.56 30.81 20.81 30.88 21.52 31.83L22.53 33.18C23.34 34.25 24.65 34.25 25.46 33.18L26.47 31.83C27.19 30.87 28.44 30.8 29.26 31.68C31.04 33.58 32.49 32.95 32.49 30.29V19.04C32.5 15.01 31.56 14 27.78 14ZM27 23.75H21C20.59 23.75 20.25 23.41 20.25 23C20.25 22.59 20.59 22.25 21 22.25H27C27.41 22.25 27.75 22.59 27.75 23C27.75 23.41 27.41 23.75 27 23.75ZM28 19.75H20C19.59 19.75 19.25 19.41 19.25 19C19.25 18.59 19.59 18.25 20 18.25H28C28.41 18.25 28.75 18.59 28.75 19C28.75 19.41 28.41 19.75 28 19.75Z"
        fill={iconColor}
      />
    </Svg>
  );
}

export default ExpenseItemIcon;
