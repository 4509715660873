import * as React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={24} height={24}>
        <Path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </Mask>
      <G mask="url(#a)">
        <Path
          d="M18.658 15.756L3.5 11.548V6.587l1.154.365.7 2.12 4.896 1.378V2.5l1.558.385 2.75 8.75 4.884 1.37c.326.088.584.262.773.522.19.26.285.553.285.879 0 .466-.193.84-.579 1.123a1.39 1.39 0 01-1.263.227zM3.5 20.5V19h17v1.5h-17z"
          fill="#1F71F4"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
