import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.943 2.223c.408.204.739.535.943.943l5.89 11.783A2.109 2.109 0 0115.892 18H4.109a2.109 2.109 0 01-1.886-3.052l5.89-11.782a2.108 2.108 0 012.83-.943zM10 14a1 1 0 100 2 1 1 0 000-2zm0-8a1 1 0 00-.993.883L9 7v4a1 1 0 001.993.117L11 11V7a1 1 0 00-1-1z"
        fill="#F59E0B"
      />
    </Svg>
  );
}

export default SvgComponent;
