import { useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import gql from "graphql-tag";

export const GET_EMPLOYEE_POLICIES = gql`
  query MobileGetEmployeePolicies($employee_id: uuid!) {
    expense_policies(
      where: { deactived_at: { _is_null: true }, expense_policies_employees: { employee_id: { _eq: $employee_id } } }
    ) {
      expense_policy_id
    }
  }
`;
const useGetEmployeePolicies = (skip = false) => {
  const { user } = useAuth();
  const { data, loading } = useQuery<{
    expense_policies: ExpensePolicies[];
  }>(GET_EMPLOYEE_POLICIES, {
    variables: { employee_id: user?.employee_id },
    skip: !user?.employee_id || skip,
    fetchPolicy: "network-only",
  });

  return {
    data: data?.expense_policies[0]?.expense_policy_id ?? null,
    loading,
  };
};

export default useGetEmployeePolicies;
