import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SVGComponent = () => (
  <Svg width={56} height={28} viewBox="0 0 56 28" fill="none">
    <Path
      d="M21.504 0.328468L14.3374 17.3469L9.65072 17.3469L6.13574 3.76791C5.92093 2.92845 5.74519 2.62532 5.08125 2.27166C3.69351 1.61024 2.22791 1.12452 0.71875 0.825913L0.824207 0.328468L8.35018 0.328468C8.84289 0.328103 9.3195 0.502954 9.69405 0.821501C10.0686 1.14005 10.3164 1.58131 10.3928 2.06567L12.2557 11.9138L16.8565 0.328468L21.504 0.328468ZM39.8289 11.7894C39.8484 7.30064 33.58 7.05192 33.6308 5.04655C33.6308 4.43638 34.2283 3.78734 35.5054 3.62023C37.0034 3.48298 38.5108 3.75156 39.8679 4.3975L40.649 0.790949C39.327 0.287053 37.9248 0.0237727 36.5091 0.0136717C32.1388 0.0136717 29.0613 2.3455 29.0339 5.64115C29.0066 8.08957 31.2328 9.4576 32.9082 10.2699C34.5837 11.0821 35.2086 11.6379 35.2047 12.384C35.2047 13.5266 33.8299 14.0319 32.5567 14.0513C30.9737 14.0858 29.4084 13.7151 28.0107 12.9748L27.2101 16.7057C28.7792 17.3102 30.4484 17.6161 32.131 17.6073C36.7747 17.6073 39.8171 15.3222 39.8328 11.7778M51.3775 17.3353L55.4666 17.3353L51.897 0.316797L48.1242 0.316797C47.7207 0.314327 47.3257 0.432234 46.9902 0.655291C46.6546 0.878348 46.3939 1.19633 46.2418 1.56823L39.6023 17.3353L44.246 17.3353L45.1677 14.7936L50.8581 14.7936L51.3775 17.3353ZM46.437 11.3192L48.7804 4.92607L50.12 11.3192L46.437 11.3192ZM27.8154 0.328468L24.1559 17.3469L19.7466 17.3469L23.4021 0.328468L27.8154 0.328468Z"
      fill="white"
    />
    <Path
      d="M24.6387 27.4434L24.6387 22.0257L26.8336 22.0257C27.2459 22.0042 27.6518 22.1342 27.974 22.3911C28.1137 22.51 28.2246 22.6588 28.2983 22.8264C28.3721 22.9939 28.4067 23.176 28.3997 23.3588C28.4032 23.5023 28.3776 23.645 28.3246 23.7785C28.2716 23.912 28.1922 24.0336 28.0912 24.136C27.8897 24.3466 27.6228 24.4835 27.3335 24.5247C27.6898 24.5513 28.0256 24.7012 28.2825 24.9483C28.4054 25.0738 28.5011 25.223 28.5635 25.3868C28.626 25.5506 28.654 25.7254 28.6458 25.9005C28.6495 26.1701 28.5879 26.4368 28.4661 26.6777C28.3449 26.9111 28.1539 27.1013 27.9193 27.2218C27.6268 27.3684 27.3014 27.438 26.9742 27.4239L24.6387 27.4434ZM26.6617 22.5504L25.3339 22.5504L25.3339 24.342L26.6617 24.342C27.3335 24.342 27.6694 24.0389 27.6694 23.4365C27.6803 23.3129 27.6628 23.1885 27.6181 23.0726C27.5734 22.9568 27.5028 22.8526 27.4116 22.768C27.1953 22.6096 26.9297 22.5325 26.6617 22.5504ZM26.7594 26.8993C27.0764 26.9298 27.3932 26.8395 27.6459 26.6466C27.7435 26.5434 27.818 26.4207 27.8644 26.2867C27.9109 26.1528 27.9283 26.0105 27.9154 25.8694C27.9275 25.7273 27.9086 25.5843 27.8601 25.4502C27.8116 25.316 27.7345 25.1939 27.6342 25.0921C27.3837 24.9037 27.0723 24.8138 26.7594 24.8395L25.3339 24.8395L25.3339 26.8837L26.7594 26.8993Z"
      fill="white"
    />
    <Path
      d="M31.369 26.9934C31.5788 26.9969 31.7864 26.9503 31.9743 26.8574C32.1538 26.7697 32.3063 26.6354 32.4156 26.4688C32.528 26.2953 32.5852 26.0921 32.5797 25.8858L32.5797 23.4141L33.2163 23.4141L33.2163 27.4404L32.607 27.4404L32.607 26.5931L32.5797 26.5931C32.5133 26.7937 32.4001 26.9757 32.2494 27.1243C32.0987 27.2729 31.9147 27.3839 31.7126 27.4481C31.5298 27.5075 31.3387 27.5376 31.1463 27.5375C30.7689 27.5594 30.3973 27.437 30.1075 27.1955C29.9699 27.0583 29.8639 26.8932 29.7965 26.7114C29.729 26.5296 29.7019 26.3355 29.7169 26.1423L29.7169 23.4218L30.3535 23.4218L30.3535 26.0063C30.3304 26.2753 30.4068 26.5434 30.5683 26.7603C30.6782 26.8523 30.8061 26.9205 30.944 26.9607C31.0818 27.0008 31.2265 27.012 31.369 26.9934Z"
      fill="white"
    />
    <Path
      d="M34.293 24.4926C34.2841 24.2565 34.3542 24.0242 34.4921 23.8319C34.6282 23.6544 34.8125 23.5196 35.0233 23.4433C35.2727 23.3496 35.5379 23.3047 35.8044 23.3111C36.0277 23.3084 36.2506 23.3319 36.4684 23.3811C36.6196 23.4155 36.7673 23.4636 36.9097 23.5249L36.937 24.1001L36.9175 24.1001C36.8184 24.0374 36.7122 23.9865 36.6011 23.9485C36.3349 23.8607 36.0574 23.811 35.7771 23.8008C35.5554 23.7908 35.3345 23.8334 35.1327 23.9252C35.0504 23.972 34.9838 24.042 34.9414 24.1264C34.899 24.2108 34.8827 24.3057 34.8944 24.3993C34.8879 24.5221 34.9252 24.6433 34.9999 24.7413C35.0837 24.8338 35.1899 24.9033 35.3084 24.9434C35.4451 24.9939 35.613 25.0522 35.8122 25.1067C36.0856 25.187 36.3238 25.2647 36.5269 25.3398C36.7133 25.4006 36.8804 25.5092 37.0112 25.6546C37.1406 25.8324 37.2028 26.0498 37.187 26.2687C37.1999 26.4455 37.1714 26.6228 37.1036 26.7867C37.0358 26.9506 36.9307 27.0966 36.7964 27.2131C36.4697 27.4456 36.0726 27.5595 35.6716 27.5356C35.4087 27.5393 35.1463 27.5106 34.8905 27.4501C34.6863 27.4005 34.4904 27.322 34.3086 27.217L34.2578 26.5951C34.4409 26.7532 34.657 26.8687 34.8905 26.9333C35.1301 27.005 35.3785 27.0429 35.6287 27.046C35.877 27.0646 36.1255 27.0106 36.3434 26.8905C36.4214 26.8333 36.4836 26.7573 36.5239 26.6695C36.5642 26.5818 36.5814 26.4853 36.5738 26.3892C36.5816 26.2642 36.5395 26.1413 36.4566 26.0472C36.3639 25.9543 36.25 25.8849 36.1247 25.8451C35.9802 25.7906 35.7966 25.7324 35.5779 25.6702C35.3592 25.608 35.1131 25.5264 34.9179 25.4525C34.7367 25.3883 34.5762 25.277 34.4531 25.13C34.3293 24.9414 34.2729 24.7169 34.293 24.4926Z"
      fill="white"
    />
    <Path
      d="M38.0277 22.4283C38.0265 22.3796 38.0356 22.3313 38.0545 22.2865C38.0733 22.2416 38.1014 22.2012 38.137 22.1679C38.1748 22.1335 38.2191 22.1068 38.2674 22.0895C38.3156 22.0722 38.3667 22.0645 38.4179 22.0668C38.5234 22.0637 38.6263 22.0997 38.7067 22.1679C38.7426 22.202 38.7711 22.243 38.7906 22.2884C38.8102 22.3338 38.8202 22.3827 38.8202 22.4321C38.8202 22.4816 38.8102 22.5304 38.7906 22.5759C38.7711 22.6213 38.7426 22.6623 38.7067 22.6964C38.6277 22.7674 38.5242 22.805 38.4179 22.8014C38.3665 22.8032 38.3151 22.7949 38.2669 22.7769C38.2187 22.7588 38.1745 22.7315 38.137 22.6964C38.1004 22.6622 38.0717 22.6205 38.0528 22.5743C38.034 22.528 38.0254 22.4782 38.0277 22.4283ZM38.7418 23.4154L38.7418 27.4417L38.1057 27.4417L38.1057 23.4154L38.7418 23.4154Z"
      fill="white"
    />
    <Path
      d="M42.027 23.3288C42.4007 23.3068 42.769 23.4261 43.058 23.663C43.1948 23.7999 43.3005 23.9644 43.3678 24.1454C43.4352 24.3265 43.4628 24.5198 43.4486 24.7123L43.4486 27.4561L42.8198 27.4561L42.8198 24.8833C42.8422 24.6066 42.7647 24.3309 42.6011 24.106C42.4957 24.0121 42.3719 23.9411 42.2373 23.8976C42.1028 23.8541 41.9607 23.8391 41.82 23.8534C41.6091 23.8514 41.4005 23.8979 41.2107 23.9894C41.0263 24.0767 40.8713 24.2152 40.7646 24.3884C40.6578 24.5616 40.6039 24.7618 40.6093 24.9649L40.6093 27.4328L39.9727 27.4328L39.9727 23.4181L40.5819 23.4181L40.5819 24.2731L40.6171 24.2731C40.6841 24.0628 40.8037 23.873 40.9647 23.7213C41.1156 23.5836 41.2939 23.479 41.488 23.4142C41.6621 23.3579 41.8439 23.329 42.027 23.3288Z"
      fill="white"
    />
    <Path
      d="M46.445 27.028C46.7458 27.038 47.046 26.9945 47.3315 26.8997C47.5533 26.8145 47.7602 26.6952 47.9447 26.5461L47.8861 27.1213C47.7171 27.2471 47.5284 27.3442 47.3276 27.4089C47.0222 27.5051 46.7027 27.5497 46.3825 27.541C46.0055 27.5493 45.6332 27.4567 45.3045 27.2728C44.9986 27.0941 44.7485 26.8345 44.582 26.5227C44.4125 26.1841 44.3242 25.811 44.3242 25.4326C44.3242 25.0543 44.4125 24.6811 44.582 24.3425C44.7403 24.035 44.9791 23.7759 45.2733 23.5924C45.5721 23.408 45.9176 23.3123 46.2692 23.3165C46.6268 23.3006 46.9823 23.3783 47.3003 23.5419C47.558 23.6812 47.7625 23.9011 47.8822 24.1676C48.0096 24.4633 48.0722 24.7826 48.0658 25.1042C48.0658 25.1548 48.0658 25.2092 48.0658 25.2752C48.0628 25.3354 48.055 25.3951 48.0423 25.454L44.9999 25.454C44.9843 25.8662 45.1236 26.2694 45.3905 26.5849C45.5236 26.7302 45.6868 26.8451 45.8689 26.9216C46.0509 26.9981 46.2475 27.0344 46.445 27.028ZM46.2887 23.8023C46.1295 23.7951 45.9705 23.8204 45.8214 23.8766C45.6723 23.9327 45.5363 24.0185 45.4217 24.1287C45.1914 24.3804 45.0537 24.7024 45.0311 25.042L47.4721 25.042L47.4721 24.9721C47.4774 24.7613 47.4416 24.5514 47.3667 24.3542C47.291 24.1889 47.1636 24.0526 47.0035 23.9655C46.7835 23.8504 46.5372 23.7941 46.2887 23.8023Z"
      fill="white"
    />
    <Path
      d="M48.9023 24.4927C48.8941 24.2572 48.9626 24.0255 49.0976 23.832C49.2347 23.6539 49.4205 23.519 49.6327 23.4433C49.882 23.3494 50.1473 23.3045 50.4138 23.3112C50.6371 23.3085 50.8599 23.3319 51.0777 23.3812C51.229 23.4155 51.3767 23.4636 51.5191 23.5249L51.5464 24.1001L51.523 24.1001C51.4238 24.0374 51.3177 23.9865 51.2066 23.9486C51.0723 23.9004 50.9337 23.8653 50.7926 23.8436C50.6639 23.8183 50.5332 23.8039 50.4021 23.8009C50.1804 23.7908 49.9595 23.8335 49.7577 23.9252C49.6754 23.9721 49.6088 24.0421 49.5664 24.1264C49.524 24.2108 49.5077 24.3058 49.5194 24.3994C49.5129 24.5222 49.5502 24.6434 49.6249 24.7414C49.708 24.8346 49.8145 24.9043 49.9334 24.9435C50.0984 25.0065 50.2666 25.061 50.4372 25.1067C50.7067 25.1883 50.9488 25.266 51.1519 25.3399C51.3383 25.4007 51.5054 25.5093 51.6362 25.6547C51.7656 25.8324 51.8278 26.0499 51.812 26.2687C51.8249 26.4455 51.7964 26.6229 51.7286 26.7868C51.6608 26.9507 51.5557 27.0966 51.4214 27.2131C51.0935 27.4462 50.695 27.5601 50.2927 27.5357C50.0363 27.5378 49.7806 27.5091 49.5311 27.4502C49.3106 27.4037 49.0985 27.3238 48.9023 27.2131L48.8516 26.5913L48.8711 26.5913C49.0542 26.7494 49.2703 26.8649 49.5038 26.9294C49.7433 27.0012 49.9918 27.0391 50.2419 27.0421C50.4903 27.0608 50.7387 27.0067 50.9567 26.8867C51.0347 26.8295 51.0968 26.7534 51.1372 26.6657C51.1775 26.578 51.1947 26.4815 51.1871 26.3853C51.1949 26.2604 51.1528 26.1375 51.0699 26.0433C50.9765 25.9512 50.8629 25.882 50.7379 25.8412C50.5934 25.7868 50.4099 25.7285 50.1912 25.6664C49.9725 25.6042 49.7264 25.5226 49.5311 25.4487C49.35 25.3845 49.1895 25.2731 49.0664 25.1261C48.9421 24.9392 48.8843 24.7161 48.9023 24.4927Z"
      fill="white"
    />
    <Path
      d="M52.582 24.4927C52.5738 24.2572 52.6423 24.0255 52.7773 23.832C52.9144 23.6539 53.1002 23.519 53.3124 23.4433C53.5617 23.3494 53.827 23.3045 54.0935 23.3112C54.3168 23.3087 54.5396 23.3321 54.7574 23.3812C54.9087 23.4155 55.0564 23.4636 55.1987 23.5249L55.2261 24.1001L55.2027 24.1001C55.1035 24.0374 54.9974 23.9865 54.8863 23.9486C54.752 23.9004 54.6134 23.8653 54.4723 23.8436C54.3436 23.8183 54.2129 23.8039 54.0818 23.8009C53.8601 23.7908 53.6392 23.8335 53.4373 23.9252C53.3551 23.9721 53.2885 24.0421 53.2461 24.1264C53.2037 24.2108 53.1874 24.3058 53.1991 24.3994C53.1926 24.5222 53.2299 24.6434 53.3046 24.7414C53.3877 24.8346 53.4941 24.9043 53.6131 24.9435C53.7781 25.0065 53.9463 25.061 54.1169 25.1067C54.3864 25.1883 54.6285 25.266 54.8316 25.3399C55.018 25.4007 55.1851 25.5093 55.3159 25.6547C55.4453 25.8324 55.5075 26.0499 55.4917 26.2687C55.5029 26.4472 55.4716 26.6257 55.4003 26.7898C55.3291 26.9539 55.2199 27.099 55.0816 27.2131C54.7554 27.4468 54.3579 27.5608 53.9568 27.5357C53.7004 27.5378 53.4447 27.5091 53.1952 27.4502C52.9908 27.4013 52.7947 27.3227 52.6133 27.217L52.5625 26.5952L52.582 26.5952C52.7651 26.7532 52.9812 26.8687 53.2147 26.9333C53.4543 27.005 53.7027 27.043 53.9529 27.046C54.2013 27.0647 54.4497 27.0106 54.6676 26.8905C54.7457 26.8334 54.8078 26.7573 54.8481 26.6696C54.8884 26.5819 54.9056 26.4854 54.898 26.3892C54.9059 26.2643 54.8637 26.1414 54.7809 26.0472C54.6875 25.9551 54.5738 25.8859 54.4489 25.8451C54.3044 25.7907 54.1208 25.7324 53.9021 25.6702C53.6834 25.6081 53.4373 25.5264 53.2421 25.4526C53.0609 25.3884 52.9004 25.277 52.7773 25.13C52.6394 24.9468 52.5703 24.7213 52.582 24.4927Z"
      fill="white"
    />
  </Svg>
);
export default SVGComponent;
