// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { StyleSheet, Text, View } from "react-native";
import { WhiteSpace, Flex } from "@ant-design/react-native";

import Checked from "app/assets/images/svg/icons/Checked";
import { useTranslation } from "react-i18next";
import Fonts from "constants/Fonts";

const styles = StyleSheet.create({
  passRule: {
    marginLeft: 5,
    fontFamily: Fonts.fontFamily,
    fontSize: 14,
    color: "#5E6C84",
  },
});

const PasswordRules = ({ password }) => {
  const { t } = useTranslation("layouts/PasswordRules");
  const rule1 = password.length >= 8;
  const rule2 = RegExp("[A-Z]+").test(password);
  const rule3 = RegExp("[a-z]+").test(password);
  return (
    <View>
      <Flex>
        <Checked color={rule1 ? "#0086FF" : "#5E6C84"} />
        <Text style={styles.passRule}>{t("password_rule_1")}</Text>
      </Flex>
      <WhiteSpace size="md" />
      <Flex>
        <Checked color={rule2 ? "#0086FF" : "#5E6C84"} />
        <Text style={styles.passRule}>{t("password_rule_2")}</Text>
      </Flex>
      <WhiteSpace size="md" />
      <Flex>
        <Checked color={rule3 ? "#0086FF" : "#5E6C84"} />
        <Text style={styles.passRule}>{t("password_rule_3")}</Text>
      </Flex>
    </View>
  );
};

export default PasswordRules;
