import { MobileBokSearchFlightQuery, MobileBokBaggageQuery, MobileBokBookingFlightQuery } from "types";

enum PlaneTicketSortOptions {
  PriceAsc = "price_asc",
  DepartureAsc = "departure_asc",
  DepartureDesc = "departure_desc",
  ArrivalAsc = "arrival_asc",
  ArrivalDesc = "arrival_desc",
  DurationAsc = "duration_asc",
}

enum BokCabinClass {
  Economy = "Economy",
  DeluxeEconomy = "DeluxeEconomy",
  Business = "Business",
  First = "First",
  Suites = "Suites",
}

enum FlightTicketType {
  Departure = "departure",
  Return = "return",
}

enum BokPassengerType {
  Adult = "Adult",
  Children = "Children",
  Infant = "Infant",
}

enum ReservationStatus {
  Hold = "hold",
  Issued = "issued",
  Expired = "expired",
}

enum BaggageType {
  HandBaggage = "HandBaggage",
  AllowanceBaggage = "AllowanceBaggage",
  BoughtBaggage = "BoughtBaggage",
}

type FlightTicket = MobileBokSearchFlightQuery["bokSearchFlight"]["bokFares"][0];
type Reservation = MobileBokBookingFlightQuery["bokBookingFlight"];
type BaggageBooking = { type?: BaggageType } & MobileBokBaggageQuery["bokBaggage"]["baggage"][0];

type BookingInformation = {
  price: number;
  orderCode: string;
  contact: {
    gender: string;
    firstName: string;
    lastName: string;
    area: string;
    phone: string;
    email: string;
    address: string;
  };
  listBooking: {
    fares: {
      fareId?: number;
      airline: {
        code: string;
        name?: string;
        logoUrl?: string;
      };
      flights: FlightTicket["flights"];
      currency: string;
      totalPrice?: number;
      providerCode?: string;
      totalDiscount?: number;
      bookingInfo?: {
        bookingCode?: string;
        baggage?: BaggageBooking[];
        status?: string;
        price: number;
      };
    }[];
    airline: {
      code: string;
      name: string;
      logoUrl: string;
    };
    currency: string;
    expiredAt: string;
    passengers: {
      type: string;
      gender: string;
      baggage: BaggageBooking[];
      lastName: string;
      firstName: string;
      birthday: string;
    }[];
    totalPrice: number;
    bookingCode: string;
    flightValue: string;
    expireDuration: number;
  }[];
};

type FlightBookingContextData = {
  expenseRequestId?: string;
  contact?: { name: string; phone: string; email: string };
  passenger?: {
    firstName: string;
    lastName: string;
    gender: string;
    birthday: string;
  };
  tickets?: {
    departure: FlightTicket;
    return?: FlightTicket;
  };
  baggages?: {
    departure?: BaggageBooking;
    return?: BaggageBooking;
  };
};

export { PlaneTicketSortOptions, BokCabinClass, FlightTicketType, BokPassengerType, ReservationStatus, BaggageType };
export type { FlightTicket, FlightBookingContextData, Reservation, BookingInformation, BaggageBooking };
