import Svg, { Path } from "react-native-svg";

function SvgComponent({ width = 20, height = 20, color = "#94A3B8" }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <Path d="M10 11.192a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2z" stroke={color} strokeWidth={1.5} />
      <Path
        d="M3.017 7.076C4.658-.14 15.35-.132 16.983 7.085c.959 4.233-1.675 7.816-3.983 10.033a4.328 4.328 0 01-6.008 0c-2.3-2.217-4.934-5.808-3.975-10.042z"
        stroke={color}
        strokeWidth={1.5}
      />
    </Svg>
  );
}

export default SvgComponent;
