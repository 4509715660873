import { gql, QueryHookOptions, useLazyQuery } from "@apollo/client";
import { MobileSuggestInvoicesQuery, MobileSuggestInvoicesQueryVariables } from "types";

const SUGGEST_INVOICES = gql`
  query MobileSuggestInvoices(
    $companyId: UUID!
    $invoiceFilters: ExpSuggestInvoicesFilters
    $invoiceId: UUID
    $prefer: ExpSuggestInvoicesPreferInput
    $userId: UUID!
  ) {
    expSuggestInvoices(
      input: {
        companyId: $companyId
        invoiceFilters: $invoiceFilters
        invoiceId: $invoiceId
        prefer: $prefer
        userId: $userId
      }
    ) {
      invoices {
        sellerTaxCode
        invoiceId
        invoiceNumber
        sellerLegalName
        sellerAddressLine
        totalAmountWithVat
        totalAmountWithoutVat
        receivedAt
        issuedDate
        expense {
          expenseId
        }
        invoiceItems {
          invoiceItemId
          itemName
        }
        invoiceValidations {
          isExpenseInvoiceValid
        }
      }
    }
  }
`;

export const useSuggestInvoicesQuery = (
  options?: QueryHookOptions<MobileSuggestInvoicesQuery, MobileSuggestInvoicesQueryVariables>
) => {
  return useLazyQuery(SUGGEST_INVOICES, {
    ...options,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
};
