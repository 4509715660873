import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import {
  MobileExpExpensePoliciesPerdiemByLocationQuery,
  MobileExpExpensePoliciesPerdiemByLocationQueryVariables,
} from "types";

const QUERY = gql`
  query MobileExpExpensePoliciesPerdiemByLocation($input: ExpExpensePoliciesPerdiemByLocationInput!) {
    expExpensePoliciesPerdiemByLocation(input: $input) {
      perdiemByLocations {
        amount
        expenseCategory {
          expenseCategoryId
          title
          titleEn
        }
      }
    }
  }
`;

const useExpensePoliciesPerdiemByLocation = (variables?: MobileExpExpensePoliciesPerdiemByLocationQueryVariables) => {
  return useLazyQuery<
    MobileExpExpensePoliciesPerdiemByLocationQuery,
    MobileExpExpensePoliciesPerdiemByLocationQueryVariables
  >(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpensePoliciesPerdiemByLocation;
