import { StyleProp, TextStyle } from "react-native";
import { Colors } from "theme";
import { FC } from "react";
import { formatMoney } from "utils";
import { AppText } from "components/index";

interface CurrencyTextProps {
  style?: StyleProp<TextStyle>;
  color?: string;
  children: number;
  labelLeft?: string;
  suffix?: string;
  foreignAmount?: number;
  foreignAmountStyle?: StyleProp<TextStyle>;
}

const CurrencyText: FC<CurrencyTextProps> = ({
  style,
  color = Colors.grayscale100,
  labelLeft = "",
  suffix = "₫",
  foreignAmount = "",
  children,
  foreignAmountStyle,
}) => {
  return (
    <AppText style={[style, color && { color }]}>
      {Boolean(foreignAmount) && (
        <AppText style={[foreignAmountStyle, color && { color }]}>{`($${formatMoney(foreignAmount)})`} </AppText>
      )}
      {`${labelLeft}${formatMoney(children)} ${suffix}`}
    </AppText>
  );
};
export default CurrencyText;
