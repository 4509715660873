import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

interface EmployeeProfileInput {
  email?: string;
  phone?: string;
  fullname?: string;
  title?: string;
  refCode?: string;
  expenseEmail?: string;
  employeeCode?: string;
  customFields?: Record<string, any>;
  managerEmployeeId?: string;
  bankAccountBook?: Record<string, any>;
}

const UPDATE_EMPLOYEE_PROFILE = gql`
  mutation MobileUpdateEmployeeProfile($employeeId: UUID!, $input: EmployeeProfileInput!) {
    updatedProfile: expUpdateEmployeeProfile(employeeId: $employeeId, input: $input) {
      companyId
    }
  }
`;
const useUpdateEmployeeProfile = () => {
  const [updateEmployeeProfile, { loading }] = useMutation(UPDATE_EMPLOYEE_PROFILE);

  const mutation = async ({ employeeId, input }: { employeeId: string; input: EmployeeProfileInput }) => {
    const { data } = await updateEmployeeProfile({
      variables: {
        employeeId,
        input,
      },
    });
    return data;
  };

  return { updateEmployeeProfile: mutation, loading };
};

export default useUpdateEmployeeProfile;
