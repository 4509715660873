import { StyleSheet, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import Login from "app/screens/Auth/Login";
import { BizziTextIcon, CircleLoginIcon } from "assets/images/svg/icons";
import { AppText, KeyboardAwareScrollViewCustom } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";

import { getAppVersion } from "app/config";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const LoginScreen = ({ route }) => {
  const { t } = useTranslation("screens/ProfileTab/ProfileScreen");

  const appVersion = useMemo(() => {
    const version = getAppVersion();
    if (version) {
      return t("version", { version: getAppVersion() });
    }
    return "";
  }, [t]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.circleIcon}>
        <CircleLoginIcon />
      </View>
      <KeyboardAwareScrollViewCustom
        enableResetScrollToCoords
        contentContainerStyle={styles.wrapper}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.body}>
          <View style={styles.bizziTextIcon}>
            <BizziTextIcon />
          </View>
          <Login errorFromOtp={route?.params?.error} />
        </View>
      </KeyboardAwareScrollViewCustom>
      <View style={styles.appInfo}>
        <AppText style={Fonts.BodyMedium} color={Colors.grayscale100}>
          {appVersion}
        </AppText>
      </View>
    </SafeAreaView>
  );
};
export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  bizziTextIcon: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, marginBottom: 24 },
  wrapper: { paddingBottom: 50, flexGrow: 1 },
  circleIcon: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  body: {
    justifyContent: "center",
    flex: 1,
  },
  appInfo: { position: "absolute", bottom: 25, alignSelf: "center" },
});
