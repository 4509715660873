import * as React from "react";
import Svg, { Path, Rect, Ellipse } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={273} height={123} viewBox="0 0 273 123" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.1 12.872C90.1 5.763 95.861 0 102.97 0h157.156C267.236 0 273 5.763 273 12.872c0 7.11-5.764 12.872-12.873 12.872H201.96a12.848 12.848 0 014.781 10.012c0 7.052-5.672 12.78-12.702 12.871h36.943c7.109 0 12.872 5.763 12.872 12.872 0 7.11-5.763 12.872-12.872 12.872H77.245c7.109 0 12.872-5.763 12.872-12.872s-5.763-12.872-12.872-12.872H36.542c-7.03-.091-12.702-5.819-12.702-12.871 0-7.11 5.763-12.872 12.872-12.872H94.88a12.847 12.847 0 01-4.78-10.012zM0 87.245c0-7.109 5.763-12.872 12.872-12.872h183.664c.633 0 1.255.046 1.863.134h-5.061c-6.31 0-11.425 5.115-11.425 11.424 0 6.31 5.115 11.425 11.425 11.425h20.705c6.349.792 11.262 6.208 11.262 12.772 0 7.109-5.763 12.872-12.872 12.872H73.832c-7.11 0-12.873-5.763-12.873-12.872 0-4.043 1.864-7.651 4.78-10.011H12.872C5.763 100.117 0 94.354 0 87.245z"
        fill="#F0F6FF"
      />
      <Rect x={192.984} y={101.52} width={2.12729} height={7.81221} rx={1.06364} fill="#75A4FE" />
      <Rect x={192.984} y={111.936} width={2.12729} height={7.81221} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={195.61}
        y={111.936}
        width={2.11731}
        height={7.84902}
        rx={1.05866}
        transform="rotate(-90 195.61 111.936)"
        fill="#75A4FE"
      />
      <Rect
        x={183.836}
        y={111.936}
        width={2.11731}
        height={7.84902}
        rx={1.05866}
        transform="rotate(-90 183.836 111.936)"
        fill="#75A4FE"
      />
      <Path
        d="M188.673 74.372c0 2.599-2.118 4.713-4.74 4.713-2.622 0-4.74-2.114-4.74-4.713 0-2.598 2.118-4.713 4.74-4.713 2.622 0 4.74 2.115 4.74 4.713z"
        fill="#fff"
        stroke="#1F71F4"
        strokeWidth={2.01559}
      />
      <Ellipse cx={82.7853} cy={37.053} rx={4.25457} ry={4.23462} fill="#75A4FE" />
      <Rect x={96.6128} y={5.29297} width={2.12729} height={7.41059} rx={1.06364} fill="#75A4FE" />
      <Rect x={96.7456} y={15.2559} width={2.12729} height={7.41059} rx={1.06364} fill="#75A4FE" />
      <Rect
        x={98.7397}
        y={14.8213}
        width={2.11731}
        height={7.44551}
        rx={1.05866}
        transform="rotate(-90 98.74 14.821)"
        fill="#75A4FE"
      />
      <Path d="M90.226 15.256a1.059 1.059 0 010-2.117h5.328a1.059 1.059 0 010 2.117h-5.328z" fill="#75A4FE" />
      <Path
        d="M155.076 52.591v23.485c0 7.476-6.064 13.507-13.507 13.507h-23.645c-7.444 0-13.507-6.064-13.507-13.507V52.591c0-7.475 6.063-13.507 13.507-13.507h23.645c7.443 0 13.507 6.064 13.507 13.507z"
        fill="#fff"
        stroke="#1F71F4"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M115.646 31.833V26.22M128.479 31.833V26.22M141.312 31.833V26.22M168.583 61.221c0 7.444-6.063 13.508-13.507 13.508V47.713c7.444 0 13.507 6.032 13.507 13.507zM104.417 57.5h49.761"
        stroke="#1F71F4"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119 44a9 9 0 00-9 9h39a9 9 0 00-9-9h-21zM110 62v15a9 9 0 009 9h21a9 9 0 009-9V62h-39z"
        fill="#F0F6FF"
      />
    </Svg>
  );
}

export default SvgComponent;
