import React, { FC } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { AppText, RadioButton, AvatarUser } from "components";
import { Colors, Fonts } from "theme";
import { CONSTANTS } from "constants/constants";

export type EmployeeOption = Pick<
  OrgEmployee,
  "employeeId" | "email" | "fullname" | "avatarUrl" | "defaultAvatarUrl"
> & { region: string };

interface EmployeeItemProps {
  employee: EmployeeOption;
  onPress: (employee: EmployeeOption) => void;
  checked: boolean;
}

const EmployeeItem: FC<EmployeeItemProps> = ({ checked, employee, onPress }) => {
  return (
    <>
      <TouchableOpacity style={styles.listItem} onPress={() => onPress(employee)}>
        <AvatarUser fullname={employee.fullname} url={employee?.avatarUrl || employee?.defaultAvatarUrl} />
        <View style={styles.flex}>
          <AppText style={Fonts.H200} color={Colors.grayscale100}>
            {employee.fullname}
          </AppText>
          <View style={styles.rowItem}>
            <AppText style={[Fonts.BodySmall, styles.title]} color={Colors.grayscale60} numberOfLines={1}>
              {employee.email}
            </AppText>
          </View>
        </View>
        <RadioButton checked={checked} />
      </TouchableOpacity>
    </>
  );
};

export default EmployeeItem;

const styles = StyleSheet.create({
  flex: { flex: 1 },
  title: { flex: 1, paddingRight: 5 },
  rowItem: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10, marginTop: 4 },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    gap: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
