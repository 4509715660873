import * as React from "react";
import Svg, { G, Path, Rect, Defs, ClipPath } from "react-native-svg";

function SvgComponent() {
  return (
    <Svg width={107} height={107} viewBox="0 0 107 107" fill="none">
      <G clipPath="url(#clip0_11630_281779)">
        <Path
          d="M44.066 99.18c2.57.818 5.497 1.28 8.613 1.28 3.116 0 6.042-.462 8.612-1.28-2.486-2.297-5.454-3.766-8.612-3.766-3.158 0-6.095 1.448-8.613 3.766z"
          fill="#C0CAD8"
        />
        <Path
          d="M97.546 65.458C96.213 86.355 76.02 95.775 51.934 94.233 27.848 92.702 9.039 80.774 10.37 59.898c1.333-20.875 22.198-40.754 46.283-39.212 24.076 1.531 42.224 23.876 40.892 44.772z"
          fill="#E4EAF1"
        />
        <Path
          d="M38.139 23.74l-3.053 47.793c5.434 2.13 11.54 3.483 18.064 3.902 6.515.42 12.756-.147 18.421-1.573l3.053-47.794c-5.382-3.021-11.466-4.962-17.97-5.382-6.515-.42-12.798.735-18.515 3.053z"
          fill="#1F71F4"
        />
        <Path
          d="M52.741 41.288l-.913-.063.798-2.748 1.678-5.854.084-.314-4.637-.294 2.182-7.438-2.738-.178 6.672-3.766 4.123 4.458-2.12-.136-.86 2.969 6.095.388-10.364 12.976z"
          fill="#fff"
        />
        <Path
          d="M21.501 72.675c-5.192-7.333 2.382-30.15 10.28-34.408 10.617-5.717 40.661-3 49.756 4.888 6.588 5.728 10.018 28.912 4.27 34.576C74.33 89.04 30.764 85.756 21.5 72.675z"
          fill="#1B1B1D"
        />
        <Path
          d="M13.182 66.737l-4.93-1.594c-.902-.284-1.51-1.333-1.437-2.476l.535-8.392c.073-1.144.807-2.109 1.762-2.277l5.088-.954c1.27-.231 2.37.986 2.266 2.539l-.693 10.93c-.115 1.521-1.363 2.623-2.59 2.224z"
          fill="#C0CAD8"
        />
        <Path
          d="M10.383 65.891l-4.521-1.437c-.84-.262-1.385-1.217-1.322-2.287l.493-7.679c.063-1.06.734-1.92 1.595-2.077l4.668-.86c1.164-.22 2.16.913 2.077 2.308l-.64 10.008c-.084 1.416-1.238 2.391-2.35 2.024z"
          fill="#E4EAF1"
        />
        <Path
          d="M93.842 72.557l5.088-.954c.954-.168 1.689-1.133 1.762-2.277l.535-8.392c.074-1.143-.535-2.192-1.458-2.486l-4.93-1.595c-1.228-.388-2.476.682-2.57 2.224l-.693 10.942c-.094 1.542.997 2.79 2.266 2.538z"
          fill="#C0CAD8"
        />
        <Path
          d="M96.746 72.091l4.668-.86c.871-.157 1.532-1.038 1.595-2.077l.493-7.679c.063-1.06-.483-2.004-1.322-2.266l-4.521-1.437c-1.133-.367-2.266.63-2.35 2.025l-.64 9.997c-.084 1.385.913 2.517 2.077 2.297z"
          fill="#E4EAF1"
        />
        <Path
          d="M44.284 67.326a6.147 6.147 0 100-12.295 6.147 6.147 0 000 12.295zM66.443 66.574a3.388 3.388 0 100-6.777 3.388 3.388 0 000 6.777z"
          fill="#fff"
        />
      </G>
      <Path
        d="M10.66 25.39c0-6.974 5.67-12.644 12.644-12.644 6.973 0 12.643 5.67 12.643 12.644 0 6.973-5.67 12.643-12.643 12.643-6.974 0-12.644-5.67-12.644-12.643zm9.206 1.724h6.875c.794 0 1.45-.655 1.45-1.45 0-.794-.655-1.449-1.45-1.449h-6.875c-.794 0-1.449.655-1.449 1.45 0 .794.655 1.449 1.45 1.449z"
        fill="#E11934"
        stroke="#fff"
        strokeWidth={0.835938}
      />
      <Rect x={15.8828} y={23.8203} width={14.8379} height={3.76172} rx={1.67188} fill="#fff" />
      <Defs>
        <ClipPath id="clip0_11630_281779">
          <Path fill="#fff" transform="translate(3.5 6.516)" d="M0 0H100.004V93.9405H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
