// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ForwardedRef, forwardRef, useCallback, useState } from "react";
import { Icon, IInputField, PaperInput } from "app/components";
import Colors from "app/constants/Colors";
import { TextInput } from "react-native-paper";
import Lock from "app/assets/images/svg/icons/Lock";
import { useTranslation } from "react-i18next";

export default forwardRef(
  (
    props: IInputField & { showPlaceholder?: boolean; hideLabel?: boolean; hideExtra?: boolean; hideLeftImg?: boolean },
    ref: ForwardedRef<TextInput>
  ) => {
    const { t } = useTranslation("layouts/PasswordInputField");
    const { textContentType = "password", hideLabel, hideExtra, hideLeftImg, ...rest } = props;
    const [inputType, setInputType] = useState({ name: "eye-slash", type: "password", color: Colors.grey });

    const { label, showPlaceholder } = props;

    const onnPressIcon = useCallback(() => {
      if (inputType.type === "password") {
        setInputType({ name: "eye", type: "text", color: Colors.grey });
      } else {
        setInputType({ name: "eye-slash", type: "password", color: Colors.blue });
      }
    }, [inputType]);

    return (
      <PaperInput
        ref={ref}
        placeholder={showPlaceholder ? t("password_rules_placeholder") : t("enter_password")}
        {...rest}
        label={hideLabel ? "" : label || t("password")}
        secureTextEntry={inputType.type === "password"}
        autoCapitalize="none"
        textContentType={textContentType}
        clearTextOnFocus={false}
        clearButtonMode="never"
        right={
          <TextInput.Affix
            text={
              !hideExtra && (
                <Icon
                  type="FontAwesome"
                  name={inputType.name}
                  size={18}
                  color={inputType.color}
                  onPress={onnPressIcon}
                />
              )
            }
          />
        }
        leftImage={!hideLeftImg && <Lock />}
      />
    );
  }
);
