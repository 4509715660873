// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ActivityIndicator, Toast } from "@ant-design/react-native";
import { useTranslation } from "react-i18next";

import { Colors, Fonts } from "theme";
import { AppText, Button } from "components";
import SCREEN_NAME from "navigation/ScreenName";
import { useAuth } from "contexts/AuthContext";
import { EmailSuccessIcon, BizziExpenseTextIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { isIphoneX } from "constants/Layout";

const ForgotPasswordSuccessScreen = () => {
  const { forgetPassword } = useAuth();
  const navigation = useNavigation();
  const route = useRoute();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("screens/Auth/ForgotPasswordScreen");

  const onResendEmail = useCallback(async () => {
    const email = route.params?.email;
    setLoading(true);
    try {
      const result = await forgetPassword(email);
      if (result?.sendResetPasswordToken?.message === "OK") {
        Toast.success(t("resent_success"));
      } else {
        const message = result?.sendResetPasswordToken?.errors
          ? t(`${result?.sendResetPasswordToken?.errors}`)
          : t("server_error_description");

        Toast.fail(message);
      }
    } catch (error) {
      Toast.fail(t(`${error?.message}`) ?? t("server_error_description"));
    }
    setLoading(false);
  }, [route, forgetPassword, t]);

  return (
    <View style={styles.container}>
      <BizziExpenseTextIcon />
      <EmailSuccessIcon />
      <AppText style={[Fonts.H200, styles.emailSendToLabel]}>
        {t("email_sent_to", { email: route?.params?.email })}
      </AppText>
      <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
        {t("not_received_email")}
        <AppText style={[Fonts.H200, styles.resentLabel]} onPress={onResendEmail} color={Colors.primary50}>
          {` ${t("resent")}`}
        </AppText>
      </AppText>
      <Button
        style={styles.buttonLogin}
        onPress={() => {
          navigation.navigate(SCREEN_NAME.LoginScreen);
        }}
      >
        {t("login")}
      </Button>
      {loading && <ActivityIndicator animating toast />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    paddingTop: 60,
    alignItems: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  buttonLogin: { position: "absolute", bottom: isIphoneX() ? 42 : 30, width: "100%" },
  emailSendToLabel: { textAlign: "center", marginVertical: 8 },
  resentLabel: { marginLeft: 50 },
});

export default ForgotPasswordSuccessScreen;
