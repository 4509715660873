import { AppText, BottomSheetModalCustomMethods, Button, CurrencyText, IconCustom, SwitchButton } from "components";
import { Colors, Fonts } from "theme";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { DeleteIcon, TransactionIcon } from "assets/images/svg/icons";
import { formatCardNumber, startLayoutAnimation } from "utils";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import SCREEN_NAME from "navigation/ScreenName";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import SelectTransactionModal from "screens/Expense/components/SelectTransactionModal/SelectTransactionModal";
import { ExpenseFormValues } from "screens/Expense/components/ExpenseForm/types";
import TransactionStatus from "screens/Card/TransactionHistoryListScreen/components/TransactionStatus";
import dayjs from "dayjs";

interface TransactionSectionProps {
  expenseId: string;
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  amount: number;
  setAmount: (value: number) => void;
  expenseDate?: string;
  setExpenseDate?: (date: string) => void;
}
const TransactionSection: FC<TransactionSectionProps> = ({
  expenseId,
  name,
  control,
  rules,
  amount,
  setAmount,
  expenseDate,
  setExpenseDate,
}) => {
  const { t } = useTranslation("app/screens/Expense/components/ExpenseForm");

  const navigation = useNavigation<any>();
  const selectTransactionRef = useRef<BottomSheetModalCustomMethods>();
  const handleNavigateTransactionDetail = (cardTransaction) => () => {
    navigation.navigate(SCREEN_NAME.TransactionDetailScreen, {
      cardTransactionId: cardTransaction?.cardTransactionId,
      companyId: cardTransaction?.companyId,
    });
  };
  const openTransactionModal = () => {
    selectTransactionRef?.current?.present?.();
  };

  return (
    <>
      <Controller
        name={name as never}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          const cardTransaction = value as ExpenseFormValues["cardTransaction"];
          return (
            <View>
              <View style={styles.titleContainer}>
                <AppText style={Fonts.SentenceSubtileXLarge}>{t("transaction_card")}</AppText>
                <SwitchButton
                  value={cardTransaction?.isOn}
                  onValueChange={() => {
                    startLayoutAnimation();
                    onChange({ ...cardTransaction, isOn: !cardTransaction?.isOn } as any);
                  }}
                />
              </View>
              {cardTransaction?.isOn && cardTransaction?.cardTransactionId && (
                <>
                  {Boolean(cardTransaction?.cardTransactionExpenses?.[0]?.expense) &&
                    cardTransaction?.cardTransactionExpenses?.[0]?.expenseId !== expenseId && (
                      <View style={styles.transactionMessage}>
                        <AppText style={Fonts.BodySmall} color={Colors.grayscale80}>
                          {t("expense")}
                          <AppText
                            style={Fonts.H200}
                            color={Colors.primary50}
                            onPress={() =>
                              navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, {
                                expenseId: cardTransaction.cardTransactionExpenses[0].expenseId,
                              })
                            }
                          >
                            {` ${cardTransaction.cardTransactionExpenses[0].expense.docSequence} `}
                          </AppText>
                          {t("is_auto_created")}
                        </AppText>
                      </View>
                    )}
                  <TouchableOpacity style={styles.transactionItem} onPress={handleNavigateTransactionDetail(value)}>
                    <TransactionIcon />
                    <View style={styles.flex}>
                      <View style={styles.location}>
                        <AppText style={Fonts.BodyMedium}>
                          {formatCardNumber(
                            cardTransaction?.card?.prefixCardNumber,
                            cardTransaction?.card?.suffixCardNumber
                          )}
                        </AppText>
                      </View>
                      <View style={styles.transactionItemRow}>
                        <AppText style={[Fonts.BodyMedium, styles.flex]} numberOfLines={1}>
                          {cardTransaction?.location}
                        </AppText>
                        <CurrencyText style={Fonts.H200}>{Math.abs(cardTransaction?.amount)}</CurrencyText>
                      </View>
                      <View style={styles.transactionStatus}>
                        <TransactionStatus
                          isCreatedExpense={Boolean(cardTransaction?.cardTransactionExpenses?.length)}
                        />
                        <View style={styles.transactionDateRow}>
                          <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
                            {dayjs(cardTransaction?.transactionDate).format("DD/MM/YYYY")}
                          </AppText>
                          <TouchableOpacity
                            style={{ paddingVertical: 5, paddingLeft: 5 }}
                            onPress={() => onChange({ isOn: true } as any)}
                          >
                            <DeleteIcon />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </>
              )}
              {cardTransaction?.isOn && !cardTransaction?.cardTransactionId && (
                <Button
                  style={{ marginTop: 20 }}
                  onPress={openTransactionModal}
                  type="secondary"
                  icon={() => <IconCustom name="add-circle-outline" width={32} height={32} />}
                >
                  {t("select_transaction")}
                </Button>
              )}
              <SelectTransactionModal
                onSelectTransaction={(value) => {
                  setAmount(Math.abs(value?.amount));
                  setExpenseDate(value?.transactionDate);
                  onChange({ isOn: true, ...value } as any);
                }}
                ref={selectTransactionRef}
                amount={amount}
                expenseDate={expenseDate}
              />
            </View>
          );
        }}
      />
    </>
  );
};
export default TransactionSection;

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  transactionItem: { flexDirection: "row", gap: 8, alignItems: "center", marginTop: 20 },
  transactionItemRow: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginVertical: 4,
    justifyContent: "space-between",
  },
  flex: { flex: 1 },
  location: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
    alignItems: "center",
  },
  transactionMessage: {
    marginTop: 20,
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: Colors.grayscale0,
    borderRadius: 8,
  },
  transactionStatus: { flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
  transactionDateRow: { flexDirection: "row", alignItems: "center" },
});
