/* eslint-disable react-native/no-inline-styles */
import { BottomTabDescriptorMap } from "@react-navigation/bottom-tabs/lib/typescript/src/types";
import { ParamListBase, TabNavigationState } from "@react-navigation/native";
import React, { FC, memo, ReactElement, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { useTranslation } from "react-i18next";

import { AppText, CopilotStepCustom, useWindowDimensions } from "components";
import { Colors, FontTypes } from "theme";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";
import { CONSTANTS } from "constants/constants";
import { BizziBotSticker01, BizziBotSticker02, BizziBotSticker03 } from "assets/images";

interface TabBarProps {
  state: TabNavigationState<ParamListBase>;
  descriptors: BottomTabDescriptorMap;
  navigation: any;
}

const POSITION_LEFT_INDICATOR = 12;

const getInfoWalkthrough = (routeName: string) => {
  let step = CONSTANTS.WALK_THROUGH.OVERVIEW.Step6;
  let title = "walkthrough_overview_step6_title";
  let description = "walkthrough_overview_step6_description";
  let image = BizziBotSticker03;
  if (routeName === SCREEN_BOTTOM_TAB.ExpensesScreen) {
    step = CONSTANTS.WALK_THROUGH.OVERVIEW.Step7;
    title = "walkthrough_overview_step7_title";
    description = "walkthrough_overview_step7_description";
    image = BizziBotSticker01;
  } else if (routeName === SCREEN_BOTTOM_TAB.ReportsScreen) {
    step = CONSTANTS.WALK_THROUGH.OVERVIEW.Step8;
    title = "walkthrough_overview_step8_title";
    description = "walkthrough_overview_step8_description";
    image = BizziBotSticker02;
  } else if (routeName === SCREEN_BOTTOM_TAB.CardScreen) {
    step = CONSTANTS.WALK_THROUGH.OVERVIEW.Step9;
    title = "walkthrough_overview_step9_title";
    description = "walkthrough_overview_step9_description";
    image = BizziBotSticker03;
  }
  return { step, title, description, image };
};
const BottomTabBar: FC<TabBarProps> = ({ state, descriptors, navigation }) => {
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { width } = useWindowDimensions();

  const translateX = useSharedValue(0);
  const indicatorStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: translateX.value,
        },
      ],
    };
  });
  const indicatorWith = width / state?.routes?.length - POSITION_LEFT_INDICATOR * 2;
  useEffect(() => {
    const index = state?.index || 0;
    translateX.value = withTiming((width / state.routes.length) * index, { duration: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, state?.index]);

  const { bottom } = useSafeAreaInsets();
  const bottomTabHeight = bottom ? bottom + 48 : 60;
  const positionBottomIndicator = bottomTabHeight - 2;

  return (
    <View
      style={[styles.container, { maxWidth: width, height: bottomTabHeight, paddingBottom: bottom ? bottom - 8 : 5 }]}
    >
      {state?.routes?.map((route, index) => {
        const { options } = descriptors[route.key];
        const icon = options?.tabBarIcon as unknown as { active: ReactElement; inactive: ReactElement };

        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;
        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };
        const { step, title, description, image } = getInfoWalkthrough(route?.name);

        return (
          <CopilotStepCustom
            walkthroughName={CONSTANTS.WALK_THROUGH.OVERVIEW.NAME}
            order={step}
            title={t(title as any)}
            text={t(description as any)}
            key={index}
            image={image}
            style={styles.tabBarButtonContainer}
          >
            <TouchableOpacity onPress={onPress} style={styles.tabBarButton} key={index}>
              {isFocused ? icon?.active : icon?.inactive}
              <AppText style={styles.text} color={isFocused ? Colors.primary50 : Colors.grayscale60}>
                {label as string}
              </AppText>
            </TouchableOpacity>
          </CopilotStepCustom>
        );
      })}
      <Animated.View
        style={[
          { width: `${(indicatorWith / width) * 100}%`, bottom: positionBottomIndicator },
          styles.indicator,
          indicatorStyle,
        ]}
      />
    </View>
  );
};
export default memo(BottomTabBar);
const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignSelf: "center",
    flexDirection: "row",
    backgroundColor: Colors.white,
    justifyContent: "space-between",
    paddingHorizontal: 0,
    shadowColor: Colors.grayscale80,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  indicator: {
    position: "absolute",
    height: 2,
    left: POSITION_LEFT_INDICATOR,
    backgroundColor: Colors.primary50,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  tabBarButtonContainer: {
    paddingTop: 8,
    flex: 1,
  },
  tabBarButton: { flex: 1, alignItems: "center" },
  text: { fontFamily: FontTypes.regular, fontSize: 10, lineHeight: 16 },
});
