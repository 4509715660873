import EmployeeChooserPopup, {
  EmployeeChooserPopupRef,
} from "components/AdvanceSearch/EmployeeChooser/EmployeeChooserPopup";
import React, { useRef } from "react";
import FilterInput from "./FilterInput";
const SelectEmployee = ({ title, value, setValue }) => {
  const selectEmployeeRef = useRef<EmployeeChooserPopupRef>();
  const handleClearFilter = () => {
    selectEmployeeRef?.current?.close();
    if (value) {
      setValue(null);
    }
  };
  return (
    <>
      <FilterInput
        title={title}
        value={value ? value?.fullname : ""}
        onPress={() => selectEmployeeRef?.current?.present()}
        onClear={handleClearFilter}
      />
      <EmployeeChooserPopup
        initialValue={value}
        onSubmit={(value) => setValue(value)}
        onClear={handleClearFilter}
        ref={selectEmployeeRef}
        title={title}
      />
    </>
  );
};
export default SelectEmployee;
