import React, { FC, ReactNode, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import Animated, { useSharedValue, withTiming } from "react-native-reanimated";
import { Colors } from "theme";
import { Circle, Svg, Text as TextSVG } from "../Chart/SvgWeb";

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

export type ProgressBarCircleDataItem = {
  color: string;
  textColor?: string;
  percent: number;
  showPercent?: boolean;
};
type ProgressBarCircleProps = {
  size?: number;
  strokeWidth?: number;
  percent: number;
  children?: ReactNode;
  strokeColor: string;
};
const ProgressBarCircle: FC<ProgressBarCircleProps> = ({
  size = 180,
  strokeWidth = 35,
  percent = 0,
  children,
  strokeColor,
}) => {
  const progress = useSharedValue(0);
  const center = size / 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    progress.value = withTiming(1, {
      duration: 1000,
    });
  }, []);
  return (
    <View style={[{ width: size, height: size }, styles.rotate]}>
      <View
        style={[
          styles.childrenContainer,
          {
            width: size,
            height: size,
          },
        ]}
      >
        {children}
      </View>
      <Svg viewBox={`0 0 ${size} ${size}`}>
        <Svg>
          <Circle
            fill="transparent"
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            stroke={Colors.grayscale05}
            strokeDasharray={`${circumference}`}
            originX={center}
            originY={center}
          />
          <AnimatedCircle
            fill="transparent"
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            stroke={strokeColor}
            strokeDasharray={`${circumference}`}
            strokeDashoffset={`${circumference * (1 - percent)}`}
            originX={center}
            originY={center}
          />
        </Svg>
      </Svg>
    </View>
  );
};
export default ProgressBarCircle;
const styles = StyleSheet.create({
  rotate: {
    transform: [{ rotateZ: "-90deg" }],
  },
  childrenContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    transform: [{ rotateZ: "90deg" }],
  },
});
