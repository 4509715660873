import { MobileExpenseDetailsByPkQuery } from "types";
import { getFileExtension, isForeignCurrency } from "utils";
import { ExpenseFormValues, ImageInfo, LOCATION_TYPE } from "./types";
import { ACCOUNT_TYPES, COMPANY_ATTACHMENT_TYPES, EXPENSE_ATTACHMENT_TYPE } from "constants/constants";
import isEmpty from "lodash/isEmpty";
export const formatDataImages = (
  type: number,
  images?: MobileExpenseDetailsByPkQuery["expExpenseDetails"]["expenseAttachments"]
): Array<ImageInfo> | undefined => {
  return images
    ?.filter((i) => i.type === type)
    ?.map(({ fileUrl, expenseAttachmentId, fileName, fileType, createdAt, fileSize }) => ({
      uri: fileUrl,
      name: fileName,
      expenseAttachmentId,
      id: expenseAttachmentId,
      type: getFileExtension(fileName),
      size: fileSize,
      fileType,
      createdAt,
    }));
};
export const formatExpenseFormValues = ({
  expenseDetail,
  isRequiredPaymentInfo,
}: {
  expenseDetail: MobileExpenseDetailsByPkQuery["expExpenseDetails"];
  isRequiredPaymentInfo: boolean;
}) => {
  const formValues = {
    amount: expenseDetail?.totalAmountWithVat ?? 0,
    companyTeam: expenseDetail?.companyTeam,
    category: expenseDetail?.expenseCategory?.expenseCategoryId,
    request: expenseDetail?.expenseRequest
      ? {
          expenseRequestId: expenseDetail?.expenseRequest?.expenseRequestId,
          docSequence: expenseDetail?.expenseRequest?.docSequence,
          isAdvanceRequest: Boolean(expenseDetail?.expenseRequest?.cashAdvances?.[0]),
          title: expenseDetail?.expenseRequest?.title,
        }
      : undefined,
    note: expenseDetail?.note,
    expenseDate: expenseDetail?.expenseDate,
    placeDetails: expenseDetail?.placeDetails?.location_name
      ? { type: LOCATION_TYPE.AVAILABLE, ...expenseDetail.placeDetails }
      : { type: LOCATION_TYPE.NONE },
    fileAttachments: formatDataImages(EXPENSE_ATTACHMENT_TYPE.DOCUMENT, expenseDetail?.expenseAttachments),
    invoiceFiles: formatDataImages(EXPENSE_ATTACHMENT_TYPE.INVOICE, expenseDetail?.expenseAttachments),
    invoice: {
      invoiceType: expenseDetail?.invoiceType,
      invoiceElectronic: expenseDetail?.invoice as ExpenseFormValues["invoice"]["invoiceElectronic"],
    },
    paymentInfo: {
      type: isRequiredPaymentInfo
        ? (expenseDetail?.paymentInfo?.type as ACCOUNT_TYPES) || ACCOUNT_TYPES.PERSONAL
        : (expenseDetail?.paymentInfo?.type as ACCOUNT_TYPES) || ACCOUNT_TYPES.NONE,
      bank: expenseDetail?.paymentInfo?.bankId
        ? {
            key: expenseDetail?.paymentInfo?.bankId,
            label: `${expenseDetail?.paymentInfo?.bankShortName} (${expenseDetail?.paymentInfo?.bankCode})`,
            metadata: {
              bankCode: expenseDetail?.paymentInfo?.bankCode,
              bankId: expenseDetail?.paymentInfo?.bankId,
              bankName: expenseDetail?.paymentInfo?.bankName,
              bankShortName: expenseDetail?.paymentInfo?.bankShortName,
              logoUrl: expenseDetail?.paymentInfo?.logoUrl,
            },
          }
        : null,
      accountNumber: expenseDetail?.paymentInfo?.accountNumber,
      accountHolderName: expenseDetail?.paymentInfo?.accountHolderName,
      bankName: expenseDetail?.paymentInfo?.bankName,
      branchName: expenseDetail?.paymentInfo?.branchName,
      description: expenseDetail?.paymentInfo?.description,
    },
    taxCode: expenseDetail?.customFields?.tax_code,
    invoiceCode: expenseDetail?.customFields?.invoice_code,
    vatRate: expenseDetail?.customFields?.vat_rate,
    cardTransaction: expenseDetail?.expenseCardTransactions?.[0]?.cardTransaction
      ? {
          isOn: true,
          cardTransactionExpenses: [
            {
              expenseId: expenseDetail?.expenseId,
              expense: {
                docSequence: expenseDetail?.docSequence,
              },
            },
          ],
          ...expenseDetail?.expenseCardTransactions?.[0]?.cardTransaction,
        }
      : undefined,
    allocatedExpense: undefined,
    categorySubAccount: expenseDetail?.subAccount?.accountCode
      ? { title: expenseDetail.subAccount.title, accountCode: expenseDetail.subAccount.accountCode }
      : undefined,
    currency: isForeignCurrency(expenseDetail?.currency)
      ? { isOn: true, name: expenseDetail.currency, exchangeRate: expenseDetail.exchangeRate }
      : undefined,
    foreignTotalAmountWithVat: isForeignCurrency(expenseDetail?.currency)
      ? expenseDetail.foreignTotalAmountWithVat
      : undefined,
  };
  const allocatedDocuments = expenseDetail?.companyAttachments?.filter(
    (item) => item.attachmentType === COMPANY_ATTACHMENT_TYPES.ALLOCATED_DOCUMENT
  );
  if (!isEmpty(allocatedDocuments)) {
    formValues.allocatedExpense = {
      isOn: true,
      allocatedDocuments: allocatedDocuments.map((item) => ({
        uri: item.fileUrl,
        name: item.fileName,
        id: item.companyAttachmentId,
        size: item.fileSize,
        fileType: item.mimeType,
        createdAt: item.createdAt,
      })),
      allocatedExpenses: allocatedDocuments[0].customFields?.allocatedExpenses ?? [],
    };
  }
  return formValues;
};
