import { FC, memo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import ContentLoader, { Rect, Circle } from "react-content-loader/native";

import { Colors } from "theme";

interface SkeletonListLoadingProps {
  style?: StyleProp<ViewStyle>;
}
const SkeletonListLoading: FC<SkeletonListLoadingProps> = ({ style }) => {
  return (
    <View style={[styles.container, style]}>
      {new Array(4).fill(null).map((_, index) => (
        <ContentLoader
          animate={false}
          key={index}
          style={styles.loadingItem}
          height={65}
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <Circle cx="27" cy="27" r="27" />
          <Rect x="70" y="5" rx="4" ry="4" width="100%" height="13" />
          <Rect x="70" y="35" rx="4" ry="4" width="200" height="10" />
        </ContentLoader>
      ))}
    </View>
  );
};

export default memo(SkeletonListLoading);

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: Colors.white,
  },
  loadingItem: { marginBottom: 15 },
});
