import { FlashList } from "@shopify/flash-list";
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, RefreshControl, StyleSheet, View } from "react-native";
import Animated, {
  useAnimatedScrollHandler,
  useSharedValue,
  useAnimatedStyle,
  Extrapolation,
  interpolate,
  withTiming,
} from "react-native-reanimated";

import { EmptyExpense } from "assets/images/svg/icons";
import {
  AnimatedFlatList,
  EmptyData,
  ExpenseItem,
  RadioSelectFilter,
  SkeletonListLoading,
  SortingLabel,
} from "components";
import { CONSTANTS, EXPENSE_STATUS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { Colors } from "theme";
import { clampReanimated } from "utils";
import useExpenseUnClaimQuery from "../hooks/useExpenseUnClaimQuery";
import { EXPENSE_SORT_OPTIONS, NewExpenseTabMethods, NewExpenseTabProps } from "../types";
import StatusList from "./StatusList";
import FlashListItem from "components/FlashListItem";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { SelectItem } from "components/AdvanceSearch/CheckboxSelect";
import { NetworkStatus } from "@apollo/client";
import { useCopilot } from "react-native-copilot";

const FILTER_CONTAINER_HEIGHT = 82;

const LIST_STATUS = [
  {
    title: "ready",
    value: EXPENSE_STATUS.READY,
  },
  {
    title: "awaiting_invoice",
    value: EXPENSE_STATUS.AWAITING_INVOICE,
  },
  {
    title: "need_detail",
    value: EXPENSE_STATUS.NEED_DETAIL,
  },
];

const NewExpenseTab = forwardRef<NewExpenseTabMethods, NewExpenseTabProps>((_, ref) => {
  useImperativeHandle(ref, () => ({
    refreshData: onRefreshAfterCreate,
  }));
  const {
    user: { employee_id: employeeId },
  } = useAuth();
  const { t } = useTranslation("app/screens/Expense/ExpenseListScreen");
  const sortOptions = useMemo(
    () =>
      [
        { label: t("created_at_desc"), value: EXPENSE_SORT_OPTIONS.CREATED_AT_DESC },
        { label: t("created_at_asc"), value: EXPENSE_SORT_OPTIONS.CREATED_AT_ASC },
        {
          label: t("expense_asc"),
          value: EXPENSE_SORT_OPTIONS.AMOUNT_ASC,
        },
        {
          label: t("expense_desc"),
          value: EXPENSE_SORT_OPTIONS.AMOUNT_DESC,
        },
      ].reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {}),
    [t]
  );
  const [currentStatus, setCurrentStatus] = useState<number | undefined>();
  const [sortOption, setSortOption] = useState<SelectItem>(sortOptions[EXPENSE_SORT_OPTIONS.CREATED_AT_DESC]);
  const flashListRef = useRef<FlashList<any>>(null);

  const variables = useMemo(() => {
    return {
      where: {
        employeeId: employeeId,
        statuses: currentStatus ? [currentStatus] : undefined,
      },
      sortOption: sortOption.value,
      offset: 0,
    };
  }, [employeeId, currentStatus, sortOption]);
  const { loading, data, refetch, fetchMore, called, networkStatus } = useExpenseUnClaimQuery(variables);

  const expenseList = data?.expUnClaimedExpenses?.expenses ?? [];
  const total = data?.expUnClaimedExpenses?.total ?? 0;

  const handleChangeStatus = (status: number) => {
    translationY.value = withTiming(0);
    setCurrentStatus(currentStatus !== status ? status : undefined);
  };

  const handleLoadMore = () => {
    if (loading || expenseList?.length === 0 || expenseList?.length >= total) {
      return;
    }
    fetchMore?.({
      variables: { offset: expenseList?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          expUnClaimedExpenses: {
            expenses: [...prev?.expUnClaimedExpenses?.expenses, ...fetchMoreResult?.expUnClaimedExpenses?.expenses],
            total: fetchMoreResult?.expUnClaimedExpenses?.total,
          },
        };
      },
    });
  };

  const renderItem = ({ item }) => (
    <FlashListItem>
      <ExpenseItem onRefreshData={onRefreshAfterUpdate} item={item} />
    </FlashListItem>
  );

  const renderEmpty = () => {
    if (loading || !called) {
      return null;
    }
    return (
      <EmptyData icon={<EmptyExpense />} title={t("empty_expense")} description={t("empty_expense_description")} />
    );
  };

  const renderFooter = () => {
    if (loading && expenseList?.length > 0) {
      return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
    }
    return null;
  };

  const onRefreshAfterCreate = () => {
    translationY.value = 0;
    flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
    setCurrentStatus(undefined);
    refetch();
  };

  const onRefreshAfterUpdate = () => {
    translationY.value = 0;
    flashListRef.current?.scrollToOffset({ animated: true, offset: 0 });
    refetch();
  };

  const translationY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event, ctx) => {
      let { y } = event.contentOffset;
      if (y < 0) {
        y = 0;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const dy = y - (ctx?.prevY ?? 0);
      translationY.value = clampReanimated(translationY.value + dy, 0, FILTER_CONTAINER_HEIGHT);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ctx.prevY = y;
    },
  });
  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      translationY.value,
      [0, FILTER_CONTAINER_HEIGHT],
      [0, -FILTER_CONTAINER_HEIGHT],
      Extrapolation.CLAMP
    );
    return {
      transform: [{ translateY: translateY }],
    };
  });

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.floatHeader, animatedStyle]}>
        <StatusList data={LIST_STATUS} currentStatus={currentStatus} handleChangeStatus={handleChangeStatus} />
        <View style={styles.sortingContainer}>
          <SortingLabel
            sortingModalProps={{
              showClearButton: false,
              title: t("sort_by"),
              data: Object.values(sortOptions),
              value: sortOption,
              setValue: setSortOption,
              onPress: () =>
                analyticService.logEvent({ name: EVENT.REQUEST.TAP_OPEN_SORT_MODAL, properties: { tab: "reported" } }),
            }}
          />
        </View>
      </Animated.View>

      {(loading || !data) && networkStatus !== NetworkStatus.fetchMore ? (
        <SkeletonListLoading style={styles.loadingContainer} />
      ) : (
        <AnimatedFlatList
          onScroll={scrollHandler}
          ref={flashListRef}
          keyExtractor={(item, index) => (item as any)?.expenseId ?? index}
          data={expenseList}
          contentContainerStyle={styles.listContainer}
          renderItem={renderItem}
          estimatedItemSize={88}
          refreshControl={
            <RefreshControl
              progressViewOffset={CONSTANTS.EXPENSE_LIST.HEADER_STATUS_HEIGHT + 10}
              tintColor={Colors.primary50}
              refreshing={false}
              onRefresh={refetch}
            />
          }
          onEndReached={handleLoadMore}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.15}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={renderFooter}
        />
      )}
    </View>
  );
});
export default memo(NewExpenseTab);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listContainer: {
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingTop: FILTER_CONTAINER_HEIGHT - 10,
    paddingBottom: 50,
  },
  loadingMore: { marginTop: 10 },
  loadingContainer: { marginTop: FILTER_CONTAINER_HEIGHT - 10 },
  floatHeader: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1,
    backgroundColor: Colors.white,
    height: FILTER_CONTAINER_HEIGHT,
  },
  sortingContainer: {
    paddingTop: 6,
    paddingBottom: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
});
