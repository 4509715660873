import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { MobileEmailQuery, MobileEmailQueryVariables } from "types";

const QUERY_EMAIL = gql`
  query MobileEmail($userId: uuid!, $companyId: uuid!, $emailId: uuid!) {
    emails(
      where: {
        deleted_at: { _is_null: true }
        user_id: { _eq: $userId }
        company_id: { _eq: $companyId }
        email_id: { _eq: $emailId }
      }
      order_by: { created_at: desc }
      limit: 1
      offset: 0
    ) {
      to
      text
      subject
      status
      sender
      html
      from
      is_duplicated
      duplicated_invoice_id
      email_id
      created_at
      invoices {
        invoice_id
      }
      email_attachments {
        file_name
        file_type
        file_url
        status
        email_attachment_id
        file_content
        file_size
      }
    }
  }
`;

const useEmailDetailQuery = ({ variables, skip = false, onCompleted }) => {
  return useQuery<MobileEmailQuery, MobileEmailQueryVariables>(QUERY_EMAIL, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip,
    onCompleted,
  });
};

export default useEmailDetailQuery;
