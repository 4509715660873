import { NavigationProp, useNavigation } from "@react-navigation/native";
import { CheckCircleFillIcon } from "assets/images/svg/icons";
import MoneySendIcon from "assets/images/svg/icons/MoneySendIcon";
import { ApprovalStage, AppText, CurrencyText, IconCustom, TouchableOpacityCustom } from "components";
import RequestCategoryStatus from "constants/RequestCategoryStatus";
import RequestStatus from "constants/RequestStatus";
import { CONSTANTS, REQUEST_TYPE } from "constants/constants";
import dayjs from "dayjs";
import SCREEN_NAME from "navigation/ScreenName";
import { RootStackParamList } from "navigation/type";
import { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors, Fonts } from "theme";
import StatusExpenseRequest from "./StatusExpenseRequest";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { MobileQueryExpenseRequestsQuery } from "types";
export enum RequestItemDisplayMode {
  View = "view",
  Selectable = "selectable",
}

type ExpenseRequestListItemProps = {
  item: {
    closedAt?: Date;
    closedBy?: string;
    docSequence: string;
    expenseRequestId: string;
    expenseRequestType?: string;
    isClosed?: boolean;
    status?: string;
    title: string;
    totalAmount: number;
    requestedAt: string;
    updatedAt?: Date;
    createdAt: Date;
    companyEmployee?: { fullname?: string };
    expenseApprovalRules: MobileQueryExpenseRequestsQuery["expMobileExpenseRequests"]["requests"][0]["expenseApprovalRules"];
  };
  isLast?: boolean;
  showCreator?: boolean;
  overrideStatus?: RequestStatus;
  onRefreshData?: () => void;
  showApprovalFlow?: boolean;
  isRejected?: boolean;
  onPress?: (event: { target: "view-detail" | "container"; value: string }) => void;
  mode?: RequestItemDisplayMode;
  selected?: boolean;
};

const ExpenseRequestItem = ({
  item,
  isLast,
  showCreator = false,
  overrideStatus,
  onRefreshData,
  showApprovalFlow = false,
  isRejected = false,
  mode = RequestItemDisplayMode.View,
  selected = false,
  onPress,
}: ExpenseRequestListItemProps) => {
  const { i18n, t } = useTranslation("app/components/ExpenseRequestItem/ExpenseRequestItem");
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const handleItemPress = useCallback(() => {
    if (onPress) {
      onPress({ target: "container", value: item.expenseRequestId });
      return;
    }
    // always view only with price comparison request
    if (item?.expenseRequestType === REQUEST_TYPE.price_comparison) {
      navigation.navigate(SCREEN_NAME.DetailRequestScreen, {
        expenseRequestId: item.expenseRequestId,
        onRefreshData,
      });
      return;
    }

    switch (item.status) {
      case RequestStatus.REJECTED:
      case RequestStatus.SUBMITTED:
      case RequestStatus.CANCELLED:
        analyticService.logEvent({ name: EVENT.REQUEST.TAP_VIEW_DETAIL });
        navigation.navigate(SCREEN_NAME.DetailRequestScreen, {
          expenseRequestId: item.expenseRequestId,
          isTravelRequest: item.expenseRequestType === RequestCategoryStatus.TRAVEL_REQUEST,
          onRefreshData,
        });
        break;
      case RequestStatus.APPROVED:
        analyticService.logEvent({ name: EVENT.REQUEST.TAP_VIEW_DETAIL });
        navigation.navigate(SCREEN_NAME.DetailRequestScreen, {
          expenseRequestId: item.expenseRequestId,
          onRefreshData,
        });
        break;
      case RequestStatus.READY:
        analyticService.logEvent({ name: EVENT.REQUEST.TAP_UPDATE });
        navigation.navigate(SCREEN_NAME.EditRequestScreen, { expenseRequestId: item.expenseRequestId, onRefreshData });
        break;
      default:
        navigation.navigate(SCREEN_NAME.EditRequestScreen, { expenseRequestId: item.expenseRequestId, onRefreshData });
        break;
    }
  }, [item.status, item.expenseRequestId, item.expenseRequestType, navigation, onRefreshData, onPress]);

  const approvers = item?.expenseApprovalRules?.[0]?.expenseApprovalRuleEmployees?.map((item) => item?.employee?.user);

  const LeftIcon =
    mode === RequestItemDisplayMode.View ? (
      <MoneySendIcon background={Colors.primary0} color={Colors.primary50} />
    ) : (
      <View>
        {selected ? (
          <IconCustom name="selected-category" />
        ) : (
          <MoneySendIcon background={Colors.primary0} color={Colors.primary50} />
        )}
      </View>
    );
  const Footer =
    mode === RequestItemDisplayMode.View ? (
      <View style={styles.titleContainer}>
        <StatusExpenseRequest status={overrideStatus ? overrideStatus : item.status} />
        {showApprovalFlow && <ApprovalStage users={approvers} limit={3} type={isRejected ? "danger" : "primary"} />}
        {(showCreator || item.isClosed) && (
          <View style={styles.titleContainer}>
            {showCreator && (
              <AppText style={[Fonts.BodySmall, styles.creator]}>{item.companyEmployee?.fullname ?? ""}</AppText>
            )}
            {item.isClosed && (
              <>
                {showCreator && item.companyEmployee?.fullname && (
                  <AppText style={[Fonts.BodySmall, styles.creator]}> - </AppText>
                )}
                <AppText style={[Fonts.BodySmall, styles.isClosed]} color={Colors.success100}>
                  {t("closed")}
                </AppText>
                <CheckCircleFillIcon fill={Colors.success50} width={16} />
              </>
            )}
          </View>
        )}
      </View>
    ) : (
      <View style={styles.titleContainer}>
        <AppText style={[Fonts.BodySmall, styles.creator]}>{item.companyEmployee?.fullname ?? ""}</AppText>
        <TouchableOpacity
          style={styles.gotoDetail}
          onPress={() => onPress({ target: "view-detail", value: item.expenseRequestId })}
        >
          <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
            {i18n.t("common:view_detail").toString()}
          </AppText>
          <IconCustom name="arrow-right-alt" />
        </TouchableOpacity>
      </View>
    );

  return (
    <TouchableOpacityCustom onPress={handleItemPress}>
      <View style={styles.container}>
        {LeftIcon}
        <View style={styles.info}>
          <View style={styles.titleContainer}>
            <AppText
              style={StyleSheet.flatten([
                Fonts.BodyMedium,
                styles.title,
                item.title.length === 0 && { fontStyle: "italic" },
              ])}
              numberOfLines={1}
              ellipsizeMode={"tail"}
            >
              {item.title.length > 0 ? item.title : t("no_name")}
            </AppText>
            <CurrencyText style={Fonts.NumericN200}>{item?.totalAmount}</CurrencyText>
          </View>
          <View style={styles.titleContainer}>
            <AppText style={Fonts.BodySmall}>{item.docSequence}</AppText>
            <AppText style={[Fonts.BodySmall, styles.date]}>
              {dayjs(item.createdAt).format(CONSTANTS.FORMAT_DAY)}
            </AppText>
          </View>
          {Footer}
        </View>
      </View>
      {!isLast && <View style={styles.divider} />}
    </TouchableOpacityCustom>
  );
};

export default memo(ExpenseRequestItem);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  info: {
    paddingLeft: 16,
    flex: 1,
    marginVertical: -2,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 2,
  },
  title: {
    flex: 1,
    marginRight: 8,
  },
  date: {
    color: Colors.grayscale60,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 45,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  creator: {
    color: Colors.grayscale60,
  },
  isClosed: {
    marginRight: 6,
  },
  gotoDetail: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
});
