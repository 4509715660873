import { StyleSheet, View } from "react-native";
import Colors from "app/constants/Colors";

const blueCircle = 80;
const outerCircle = blueCircle + 10;

const styles = StyleSheet.create({
  container: {
    width: outerCircle,
    height: outerCircle,
    borderRadius: outerCircle / 2,
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
  },
  circleStyle: {
    width: blueCircle,
    height: blueCircle,
    borderRadius: blueCircle / 2,
    backgroundColor: Colors.blue3,
    alignItems: "center",
    justifyContent: "center",
  },
});

const BizziCircle = ({ style, children }) => {
  return (
    <View style={StyleSheet.flatten([style, styles.container])}>
      <View style={styles.circleStyle}>{children}</View>
    </View>
  );
};

export default BizziCircle;
