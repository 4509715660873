export const COMPANIES = {
  TLN_EXPENSE_COMPANY_SCRUM: "bc4d36b3-c125-41d6-9309-5140f6fccc0a",
  TLN_EXPENSE_COMPANY_DEV: "32360905-2f83-4afd-a51c-985a95ed3219",
  MDLZ_SOUTH_EXPENSE_COMPANY_DEV: "6331fdcf-1ff9-4cc2-b126-aedd5a9e82b8",
  MDLZ_NORTH_EXPENSE_COMPANY_DEV: "f89ae5ca-df16-42ff-b7bb-280f33d4acb5",

  MDLZ_SOUTH_EXPENSE_COMPANY_UAT: "6ade6e8f-d3da-42b6-aaa2-07f83a17fc69",
  MDLZ_NORTH_EXPENSE_COMPANY_UAT: "c51ad3d4-a479-4bc6-a008-0092fa6f5a1a",

  MDLZ_SOUTH_EXPENSE_COMPANY_PRODUCTION: "eecc0822-f292-4c01-91a0-79f6650d9e60",
  MDLZ_NORTH_EXPENSE_COMPANY_PRODUCTION: "19bf7276-46f5-4a44-9c02-9e22e23c2d7d",
  MDLZ_TRADE_EXPENSE_COMPANY_PRODUCTION: "95e089c5-b8a8-4b98-a901-776b53040b72",

  TLN_EXPENSE_COMPANY_PRODUCTION: "ba260e95-7cd1-4387-bf8f-8f2bba4e5e2b",

  KEWPIE_PRODUCTION: "6f06358a-4e40-4db2-b431-de96208dd134",
};

export type TCompanies = typeof COMPANIES;
export type TCompaniesValue = TCompanies[keyof TCompanies];
